ngTouchstart.$inject = [];

function ngTouchstart() {
  return {
    controller: [
      "$scope",
      "$element",
      ($scope, $element) => {
        const onTouchStart = (event) => {
          const method = $element.attr("ng-touchstart");
          $scope.$event = event;
          $scope.$apply(method);
        };

        $element.bind("touchstart", onTouchStart);
      },
    ],
  };
}

export default {
  type: "directive",
  name: "ngTouchstart",
  value: ngTouchstart,
};
