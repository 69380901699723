import angular from 'angular';

inputContainerAdapterDirective.$inject = [];

function inputContainerAdapterDirective() {

    return {
        restrict: 'A',
        controller: InputContainerAdapterController,
        require: ['lvInputContainerAdapter', '^^lvInputContainer'],
        compile: compileDirective
    }

    function compileDirective(tElement) {
        tElement.addClass('lv-input-container__editor');
        return linkDirective;
    }

    function linkDirective(scope, element, attrs, ctrls) {
        let inputContainerAdapterCtrl = ctrls[0];
        let inputContainerCtrl = ctrls[1];

        // Define editor element tag name. Add this name to editor container as modificator class.
        // Now editor container know about it's content and can specify styles.
        let editorTagName = element[0].tagName.toLowerCase();
        inputContainerCtrl.element.addClass('lv-input-container--' + editorTagName);

        inputContainerAdapterCtrl.init(inputContainerCtrl);
    }
}

InputContainerAdapterController.$inject = [];
function InputContainerAdapterController() {

    let self = this;

    this.init = init;

    function init(editorContainerCtrl) {
        // Add methods to adapter which handle inputContainer methods.
        angular.forEach(['setDisabled', 'setReadonly', 'setFocused', 'setTouched', 'setInvalid', 'setHasValue'], function(methodName) {
            handleInputContainerMethod(methodName);
        });

        function handleInputContainerMethod(methodName) {
            // Create adapter method.
            self[methodName] = function() {
                // Call editor container method with adapter method arguments.
                editorContainerCtrl[methodName].apply(editorContainerCtrl, arguments);
            };
        }
    }

}

export default {
    type: 'directive',
    name: 'lvInputContainerAdapter',
    value: inputContainerAdapterDirective
};