import angular from "angular";

lvBtn.$inject = [];

function lvBtn() {
    function getStyle( oElm, css3Prop ) {
        var strValue = "";

        if ( window.getComputedStyle ) {
            strValue = getComputedStyle( oElm ).getPropertyValue( css3Prop );
        }
        //IE
        else if ( oElm.currentStyle ) {
            try {
                strValue = oElm.currentStyle[css3Prop];
            } catch ( e ) {
            }
        }

        return strValue;
    }

    function _getStyle( el, styleProp ) {
        //var x = document.getElementById(el);
        var y;

        if ( el.currentStyle ) {
            y = el.currentStyle[styleProp];
        } else if ( window.getComputedStyle ) {
            y = document.defaultView.getComputedStyle( el, null ).getPropertyValue( styleProp );
        }
        return y;
    }

    /**
     *
     *взятие позиции элемента
     */
    function findPosX( obj ) {
        var curleft = 0;
        if ( obj.offsetParent ){
            while ( 1 ) {
                curleft += obj.offsetLeft;
                if ( !obj.offsetParent ){
                    break;
                }
                obj = obj.offsetParent;
            }
        }
        else if ( obj.x ){
            curleft += obj.x;
        }

        return curleft;
    }

    function findPosY( el ) {
        var el2 = el;
        var curtop = 0;
        var curleft = 0;
        if ( document.getElementById || document.all ) {
            do {
                curleft += el.offsetLeft - el.scrollLeft;
                curtop += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
                el2 = el2.parentNode;
                while ( el2 != el ) {
                    curleft -= el2.scrollLeft;
                    curtop -= el2.scrollTop;
                    el2 = el2.parentNode;
                }
            } while ( el && el.offsetParent );

        } else if ( document.layers ) {
            curtop += el.y;
            curleft += el.x;
        }
        return curtop;
    }

    function posXY( obj ) {
        return {
            x: findPosX( obj ),
            y: findPosY( obj )
        };
    }

    return {
        restrict: 'AC',
        link: function ( scope, el, attr ) {
            var delay = 0.5;
            var widthHeight;


            function _init() {
                var absolutePosition = posXY(el[0] );
                var absoluteX = absolutePosition.x;
                var absoluteY = absolutePosition.y;


                var specEl = angular.element( document.createElement( 'specel' ) );
                var elExplosion = angular.element( document.createElement( 'div' ) );
                //el.css( 'position', 'relative' );
                specEl.css( 'position', 'absolute' );
                specEl.css( 'display', 'block' );
                specEl.css( 'top', absoluteY+'px' );
                specEl.css( 'left', absoluteX+'px' );
                elExplosion.css( 'transition', 'all ' + delay + 's' );
                var width = el.css( 'width' );
                var height = el.css( 'height' );
                var radius = '0';

                elExplosion.css( 'background', 'radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 81%,rgba(255,255,255,0) 100%)' );
                elExplosion.css( 'boxShadow', '0 0 5px rgba(0,0,0,0.2)' );
                elExplosion.css( 'position', 'absolute' );
                elExplosion.css( 'top', parseInt( width ) / 2 + 'px' );
                elExplosion.css( 'borderRadius', '10%' );


                //console.log(posXY(el[0]));

                var borderWidth = parseInt( _getStyle( el[0], 'border-width' ) || _getStyle( el[0], 'border-top-width' ) );
                radius = getStyle( el[0], 'border-radius' ); //el.css('border-top-right-radius');
                width = el.css( 'width' ) || el[0].clientWidth + 'px';
                height = el.css( 'height' ) || el[0].clientHeight + 'px';

                elExplosion.css( 'top', parseInt( height ) / 2 - borderWidth + 'px' );
                elExplosion.css( 'left', parseInt( width ) / 2 - borderWidth + 'px' );
                //console.log(parseInt(width)/2 - borderWidth +'px');
                elExplosion.css( 'width', '0' );
                elExplosion.css( 'height', '0' );
                elExplosion.css( 'opacity', '1' );
                elExplosion.css( 'border-radius', radius );

                specEl.css( 'border-radius', radius );
                specEl.css( 'width', width );
                specEl.css( 'height', height );
                specEl.css( 'overflow', 'hidden' );
                specEl.css( 'z-index', '1000' );

                return {
                    elExplosion: elExplosion,
                    specEl: specEl,
                    width: width,
                    height:height

                };
            }

            function click( e ) {
                if (attr['disabled']) {
                    return;
                }
                var els = _init();
                els.elExplosion.css( 'transition', 'all 0s' );
                els.elExplosion.css( 'transition', 'all ' + delay + 's' );
                window.document.body.appendChild(els.specEl[0]);
                els.specEl.append( els.elExplosion );
                setTimeout( function () {
                    widthHeight = parseInt( els.height ) < parseInt( els.width ) ? els.width : els.height;
                    els.elExplosion.css( 'top', (parseInt( els.height ) / 2 - parseInt( widthHeight ) / 2 ) + 'px' );
                    els.elExplosion.css( 'left', ( parseInt( els.width ) / 2 - parseInt( widthHeight ) / 2) + 'px' );
                    els.elExplosion.css( 'width', widthHeight );
                    els.elExplosion.css( 'height', widthHeight );
                    els.elExplosion.css( 'opacity', '0' );
                    setTimeout( function () {
                        window.document.body.removeChild(els.specEl[0]);
                        for(var opt in els){
                            if(els.hasOwnProperty(opt)){
                                delete els[opt];
                            }
                        }
                    }, delay * 1000 + 1 );
                }, 1 );
            }

            el.on( 'click', click );

            scope.$on( '$destroy', function () {
                el.off( 'click', click );
            } );
        }
    };
}

export default lvBtn;