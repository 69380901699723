/**
 * Created by yarosh on 07.12.17.
 */

duplicatedValuesError.$inject = ['arrayOperation'];

function duplicatedValuesError(arrayOperation) {

    return {
        restrict: 'A',
        link: linkFn,
        require: '^ngModel'
    };

    function linkFn(scope, element, attr, controller) {

        //the first option in attr.propertyNameError is an exact name of a property inside an object that we are passing like an entity for comparison, the second one - is a camelCase variant of a             //key for i18 files to be translated after it is transformed into a constant notation.
        var arrayForI18Key,
            variableMessageKeyForTranslate = attr.duplicatedValuesError;

            //use regex to split a string by capitalized letters and then transform each element of an array into uppercase words
            //in order to join it with an underscore symbol, having eventually a key for i18 files and further translations

            arrayForI18Key = variableMessageKeyForTranslate.split(/(?=[A-Z])/);
            variableMessageKeyForTranslate = arrayForI18Key.map(function(particle){
               return particle.toUpperCase();
            });
            variableMessageKeyForTranslate = variableMessageKeyForTranslate.join("_");


            element.on('blur', function () {

                var value = $(element).val();
                var inputName = attr.name;

                var arr = JSON.parse(attr.options);


                if(Array.isArray(arr)){
                  var sortedArray =  arrayOperation.sortArray(arr),
                      result = [];

                    sortedArray.forEach(function(element, index) {

                        // Find if there is a duplicate or not
                        if (sortedArray.indexOf(element, index + 1) > -1) {

                            // Find if the element is already in the result array or not
                            if (result.indexOf(element) === -1) {
                                result.push(element);
                            }
                        }
                    });

                    if((result.length > 0 && result.indexOf(value) !== -1) || (value === "" || value.length === 0)) {
                        return errorStyle();
                    } else {
                        return validStyle();
                    }

                }



                function errorStyle(){
                    controller.$setValidity(inputName, false);
                    scope.alertMessage = true;
                    scope.$emit('duplicatedValuesErrorMessage', { alertMessage: scope.alertMessage });

                    if(value === "" || value.length === 0){
                        controller.setAlertMessage('FIELD_CANT_BE_EMPTY');
                    } else {
                        controller.setAlertMessage(variableMessageKeyForTranslate);
                    }

                }

                function validStyle(){
                    scope.alertMessage = false;
                    scope.$emit('duplicatedValuesErrorMessage', scope.alertMessage);

                    controller.$setValidity(inputName, true);
                    controller.$invalid = false;
                    controller.$error = {};
                    controller.err = {};
                    controller.$valid = true;
                    $(element).parent().removeClass('has-error');
                    $(element).removeClass('ng-invalid ng-invalid-name');
                    controller.setAlertMessage(null);
                }

            });

    }
}

export default {
    type: 'directive',
    name: 'duplicatedValuesError',
    value: duplicatedValuesError
};