/**
 * @ngdoc module
 * @name lv.calendar
 * Календарь
 */

import angular from 'angular';
import localHoursPattern from './constant/constant-local-hours-pattern';
import constantTimePattern from './service/service-constant-time-pattern';
import timeRangeModuleRun from './lv-time-range.module.run';
import timeRange from './directive/directive-time-range';
import timeRangeLabel from './directive/directive-time-range-label';
import timeRangeView from './directive/directive-time-range-view';

export default angular
    .module( 'lv.timeRange', [])
    .constant('localHoursPattern', localHoursPattern)
    .service('constantTimePattern', constantTimePattern)
    .directive('timeRange', timeRange)
    .directive('timeRangeLabelTemplate', timeRangeLabel.timeRangeLabelTemplate)
    .directive('wholeDayLabelTemplate', timeRangeLabel.wholeDayLabelTemplate)
    .directive('timeRangeView', timeRangeView)
    .run(timeRangeModuleRun)
    .name;