function plusToNumber() {
    function format (val) {
        var cleareval = val.replace(/\s|,/g, "");
        if(Number(cleareval) > 0) {
            if(typeof val !== 'string'){
                val = val.toString();
            }
            val = '+'+val;
        } else {
            val = val;
        }
        return val;
    }
    return format;
}

export default {
    type: 'filter',
    name: 'plusToNumber',
    value: plusToNumber
};