/**
 * Created by sirosh on 15.03.16.
 */
/**
 * Created by sirosh on 15.03.16.
 */

getPrimeCostAll.$inject = ['$filter'];

function getPrimeCostAll($filter) {

    var currency = $filter('coinToCurrency');

    function output(val, quantity, arr) {

        var currencyVal = currency(val, '.');
        var arrSum = 0;
        var result;
        var primeCost = parseFloat(currencyVal);
        var primeCostQuantity = parseFloat(quantity);

        if (arr && arr.length === 0) {
            if (!isNaN(primeCostQuantity) && !isNaN(primeCost)) {
                result = primeCost * primeCostQuantity;
                result = result.toFixed(2);
                return result;
            } else {
                return '';
            }
        } if (arr == undefined) {
            return '';
        } else {
            for (var i = 0, len = arr.length; i < len; i++) {
                var primeCostItem = parseFloat(currency(arr[i].primeCost, '.'));
                var itemQuantity = parseFloat(arr[i]._quantity);

                arrSum += (primeCostItem * itemQuantity);
            }

            if (!isNaN(primeCostQuantity) && !isNaN(primeCost)) {
                result = primeCost * primeCostQuantity;
                return (parseFloat(result) + arrSum).toFixed(2);

            } else {
                return arrSum.toFixed(2);
            }
        }
    }

    return output;
}


export default {
    type: 'filter',
    name: 'getPrimeCostAll',
    value: getPrimeCostAll
};