/**
 * Common methods for different payment gateways controllers
 * Created by Oleksii Shestakov on 18.02.19
 */

import angular from 'angular';
import { SubscriptionRenewData } from "../controller/settings/billing/components/billing-subcriptions-comp";

serviceBillingCommonMethods.$inject = [
    '$state',
    '$stateParams',
    '$filter',
    'lodash',
    'serviceBillingMixpanel',
    'serviceDialog',
    'serviceToast',
    'serviceBillingError',
    'serviceBillingDifferentPlaces',
    'getEmployees',
    'factoryBilling',
    'factoryEnvironment'
];
function serviceBillingCommonMethods(
    $state,
    $stateParams,
    $filter,
    lodash,
    serviceBillingMixpanel,
    serviceDialog,
    serviceToast,
    serviceBillingError,
    serviceBillingDifferentPlaces,
    getEmployees,
    factoryBilling,
    factoryEnvironment
) {
    var translate = $filter('translate');

    // возврат на ту страницу где была активация подписки с нижней строки без карты владельца
    function goToOldUrl(urlForReload) {
        setTimeout(function () {
            window.location.replace(urlForReload);
            window.location.reload(true);
        }, 1000);
    }
    // возврат на ту страницу где была активация подписки с нижней строки без карты владельца


    // уход с этой страницы
    function onCancel(newUser, addSubscribe, subscriptionId, urls) {
        if (!lodash.isEmpty(newUser)) {
            $state.go('employees.createemployee', $stateParams, {});
        }
        else if (!lodash.isEmpty(addSubscribe)) {
            if (urls.urlForReload !== "") {
                goToOldUrl(urls.urlForReload);
            }
            else {
                $state.go( 'settings.account', $stateParams, {});
            }
        }
        else if (!lodash.isEmpty(subscriptionId)) {
            const redirectUrl = urls.urlForSuccessReload !== "" ? urls.urlForSuccessReload : 'settings.account';
            $state.go(redirectUrl, $stateParams, {});
        }
        else {
            $state.go('settings.account', $stateParams, {});
        }
    }
    // уход с этой страницы


    // функция получения/присваивания VAT
    function getVat(token) {
        if (token.vat !== null) {
            return token.vat;
        } else {
            return "";
        }
    }
    // функция получения/присваивания VAT



    // создание юзера
    function createUser(user, employeeStat, typeSubscription) {
        var createNewUser       = angular.copy(user);
        createNewUser.planId    = createNewUser.planId.id;
        createNewUser.estimate  = false;

        function actErrorCreateUser() {
            $state.go('employees.list', { page: 0, limit: 10}, { reload: true });
        }

        getEmployees.setEmployee(createNewUser).then(function (res) {
            serviceBillingMixpanel.setMixpanelBillingActivateInPaymentMethod(
                res.result,
                typeSubscription,
                user.planId,
                employeeStat,
                null,
                null);

            if (res.result === "ok") {
                serviceToast.show('EMPLOYEE.EMPLOYEE_ADDED', 'success');
                $state.go('employees.list', {page: 0, limit: 10}, { reload: true });
            } else if (res.result === "error") {
                if (res.message === "pin_already_exists") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        title:          translate('EMPLOYEE.HAS_PIN_TITLE'),
                        content1:        translate('EMPLOYEE.HAS_PIN_MSG', {pin: user.pin.join('')}),
                        buttons:    [{  class: 'md-primary',
                            text: 'ON_OK',
                            action: function () {
                                $state.go('employees.createemployee', $stateParams, {});
                            }
                        }]
                    });
                } else if (res.message === "merchant_with_this_email_exists") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        title:          translate('EMPLOYEE.ERROR_EMPLOYEE_ADDED'),
                        content1:        translate('ERROR.merchant_with_this_email_exists'),
                        buttons:    [{  class: 'md-primary',
                            text: 'ON_OK',
                            action: function () {
                                $state.go('employees.createemployee', $stateParams, {});
                            }
                        }]
                    });
                } else if (res.message === "activation_error") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        class:          'billing-base-384',
                        content1:        translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_ACTIVATION_ERROR'),
                        buttons: [  {   text:   'ACCOUNT.B2_SERVICE_DIALOG.B2_REFRESH',
                            class:  'md-primary',
                            action: function () {
                                serviceBillingError.reloadRoute();
                            }
                        }]
                    });
                } else {
                    serviceBillingError.knownError(res.message, actErrorCreateUser);
                }
            }
            else {
                serviceBillingError.unKnownError(actErrorCreateUser);
            }
        });
    }
    // создание юзера



    // отправка на сервер создание подписки на услугу и/или активация услуги (перезагружаем всегда страницу)
    function createSubscribeActivate(addSubscribeData, statuses, urls, source) {
        var data = {planId: addSubscribeData.plan.id,
                    activate: addSubscribeData.activate,
                    coupon: addSubscribeData.coupon,
                    estimate: false};

        function actErrorCreateSubscribeActivate() {
            (urls.urlForReload !== "")? goToOldUrl(urls.urlForReload) : $state.go('settings.account', $stateParams, {});
        }

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {

            serviceBillingMixpanel.setMixpanelBillingActivateInPaymentMethod(res.result, addSubscribeData.type,
                addSubscribeData.plan, statuses.EMPLOYEE, statuses.INVENTORY, statuses.INTEGRATION, statuses.SALESHISTORY, source);

            if (res.result === "ok") {
                var types = Object.keys(statuses);
                types.forEach(function (type) {
                    if (addSubscribeData.type === type && (statuses[type] === "INTRIAL_SUB_NOACT" || statuses[type] === "TRIALEND_SUB_NOACT" )) {
                        serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                        (urls.urlForReload !== "")? goToOldUrl(urls.urlForReload) : $state.go('settings.account', { reloadToUnblock: true });
                    }
                    if (addSubscribeData.type === type && statuses[type] === "TRIALEND_NOSUB_NOACT") {
                        serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_CREATED', 'success');
                        if (type === "INVENTORY" && urls.urlForSuccessReload === "inventory.management") {
                            $state.go("inventory.management");
                        } else if (type === "INTEGRATION" && urls.urlForSuccessReload === "integrations.management") {
                            $state.go("integrations.management");
                        } else if (type === "SALESHISTORY" && urls.urlForSuccessReload.startsWith("report.")) {
                            $state.go(urls.urlForSuccessReload);
                        } else {
                            $state.go('settings.account', {reloadToUnblock: true});
                        }
                    }
                });
            }
            else if (res.result === "error") {
                if (res.message === "activation_error") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        class:          'billing-base-384',
                        content1:        translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_ACTIVATION_ERROR'),
                        buttons:    [{  text:   'ACCOUNT.B2_SERVICE_DIALOG.B2_REFRESH',
                            class:  'md-primary',
                            action: function () {
                                (urls.urlForReload !== "")? goToOldUrl(urls.urlForReload) : $state.go('settings.account', { reloadToUnblock: true });
                            }
                        }]
                    });
                }
                else if (res.message === "must_add_employees") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        class:          'billing-base-384',
                        content1:        translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ADD_EMPLOYEE_TO_SUBSCRIBE'),
                        buttons:    [{
                                text:   'ON_CANCEL',
                                action: function() {
                                    (urls.urlForReload !== "")? goToOldUrl(urls.urlForReload) : $state.go('settings.account', $stateParams, {});
                                }
                            },
                            {   class:  'primary',
                                text:   'EMPLOYEE.ADD_EMPLOYEE',
                                action: function () {
                                    $state.go('employees.list', { page: 0, limit: 10}, { reload: true });
                                }
                            }]
                    });
                }
                else {
                    serviceBillingError.knownError(res.message, actErrorCreateSubscribeActivate);
                }
            }
            else {
                serviceBillingError.unKnownError(actErrorCreateSubscribeActivate);
            }
        });
    }
    // отправка на сервер создание подписки на услугу и/или активация услуги (перезагружаем всегда страницу)


    // функция получает с сервера информацию о hosted-странице Chargebee, и вызывает ее, либо выдает ошибку
    function getChargebeeHostedPageForAdd(ownerId, plan, coupon, closeCallback, errorCallback, newUserCallback) {
        var data = {
            ownerId: ownerId,
            planId: plan,
            coupon: coupon
        };

        factoryBilling.getChargebeeHostedPage(data).then(function (res) {
            if(res.result === "ok") {
                showAndSubscribeChargebeeHostedPage(plan, closeCallback, errorCallback, newUserCallback, res);
            } else if(res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        })
    }
    // функция получает с сервера информацию о hosted-странице Chargebee, и вызывает ее, либо выдает ошибку


    // подписка с помощью hosted-страницы Chargebee
    function showAndSubscribeChargebeeHostedPage(plan, closeCallback, errorCallback, newUserCallback, instanceObj) {

        var siteName = "";

        factoryEnvironment.getEnv().then(function (data) {
            siteName = data.chargebeeSite;

            Chargebee.init({
                site: siteName
            });
            var instance = Chargebee.getInstance();
            instance.openCheckout({
                hostedPage: function () {
                    return new Promise(function (resolve) {
                        resolve(instanceObj);
                    })
                },
                success: function (hostedPageId) {
                    factoryBilling.completeChargebeeCheckout({planId: plan}).then(function (res) {
                        if(res.result === "ok") {
                            serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                            instance.closeAll();
                            if(newUserCallback) {
                                newUserCallback();
                            } else {
                                serviceBillingError.reloadRoute();
                            }
                        } else {
                            if(errorCallback) errorCallback();
                            serviceBillingError.unKnownError();
                        }
                    });
                },
                close: function () {
                    closeCallback();
                }
        });
        });
    }
    // подписка с помощью hosted-страницы Chargebee


    // предпросмотр счета к оплате для сотрудников
    function showEstimatedInvoiceForEmployees(user, employeeStat, typeSubscription) {
        var userParams = angular.copy(user);
        userParams.planId = userParams.planId.id;
        userParams.estimate = true;

        getEmployees.setEmployee(userParams).then(function (res) {
            if (res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createUser.bind(null, user, employeeStat, typeSubscription));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // предпросмотр счета к оплате для сотрудников


    // предпросмотр счета к оплате для Advanced Inventory
    function showEstimatedInvoiceForNewSubscribe(addSubscribeData, statuses, urls, source) {
        var data = {planId: addSubscribeData.plan.id,
                    activate: addSubscribeData.activate,
                    coupon: addSubscribeData.coupon,
                    estimate: true};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            if (res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createSubscribeActivate.bind(null, addSubscribeData, statuses, urls, source));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // предпросмотр счета к оплате для Advanced Inventory

    // estimated invoice for renew subscription
    const showEstimatedInvoiceForRenew = (data, urlForSuccessReload) => {
        factoryBilling.renewSubscription(data).then((res) => {
            if (res.result === "ok") {
                const renewSubscriptionData = new SubscriptionRenewData(data.subscriptionId, false);
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, renewSubscription.bind(null, renewSubscriptionData, urlForSuccessReload));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        })
    }


    // renew subscription
    const renewSubscription = (data, urlForSuccessReload) => {
        const actRenew = () => {
            const redirectState = urlForSuccessReload !== "" ? urlForSuccessReload : 'settings.account';
            $state.go(redirectState, $stateParams, {});
        }

        factoryBilling.renewSubscription(data).then(function (res) {

            if (res.result === "ok") {
                actRenew();
            }
            else if (res.result === "error") {
                serviceBillingError.knownError(res.message, actRenew);
            }
            else {
                serviceBillingError.unKnownError(actRenew);
            }
        });
    }


    // создание объекта с информацией о подписке
    function getAddSubscribeData(plan, type, activate, coupon) {
        return {
            plan: plan,
            type: type,
            activate: activate,
            coupon: coupon
        }
    }
    // создание объекта с информацией о подписке


    // создание объекта со статусами подписки
    function getStatuses(employeeStat, inventoryStat, integrationStat, salesHistoryStat) {
        return {
            EMPLOYEE: employeeStat,
            EMPSTORE: employeeStat,
            INVENTORY: inventoryStat,
            INTEGRATION: integrationStat,
            SALESHISTORY: salesHistoryStat
        }
    }
    // создание объекта со статусами подписки


    // создание объекта со списком ссылок после успешной подписки
    function getUrls(urlForReload, urlForSuccessReload) {
        return {urlForReload: urlForReload, urlForSuccessReload: urlForSuccessReload};
    }
    // создание объекта со списком ссылок после успешной подписки


    this.goToOldUrl = goToOldUrl;
    this.onCancel = onCancel;
    this.getVat = getVat;
    this.createUser = createUser;
    this.createSubscribeActivate = createSubscribeActivate;
    this.getChargebeeHostedPageForAdd = getChargebeeHostedPageForAdd;
    this.showEstimatedInvoiceForEmployees = showEstimatedInvoiceForEmployees;
    this.showEstimatedInvoiceForNewSubscribe = showEstimatedInvoiceForNewSubscribe;
    this.getAddSubscribeData = getAddSubscribeData;
    this.getStatuses = getStatuses;
    this.getUrls = getUrls;
    this.showEstimatedInvoiceForRenew = showEstimatedInvoiceForRenew

}

export class Urls {
    constructor(urlForReload, urlForSuccessReload) {
        this.urlForReload = urlForReload;
        this.urlForSuccessReload = urlForSuccessReload;
    }
}

export default {
    type: 'service',
    name: 'serviceBillingCommonMethods',
    value: serviceBillingCommonMethods
};
