/**
 * Created by K.Yarosh on 26.05.2017.
 * @description getting a certain langCode from constant-lang-code by cabinetLang of 'getprofile' request to a server
 * @memberof
 * @ngdoc service
 * @name retrieveLangCode
 */

retrieveLangCode.$inject = [
    'langCode',
    'constantDateFormatCollection',
    '$rootScope',
    '$filter',
    'constantTimeZoneCollection'
];
function retrieveLangCode(
    langCode,
    constantDateFormatCollection,
    $rootScope,
    $filter,
    constantTimeZoneCollection
) {

    var tz = jstz.determine();
    var langCountry = defineLangCountryCode($rootScope.cabinetLang),
        dateFormat,
        timeFormat,
        options;
    const timeOnlyRegex = /^\d{1,2}\D\d{1,2}$/;
    const langCountryAmPm = 'en-US';
    const langCountryTimeOnly = 'en-GB';


    function retrieve2DigitCode(rootScopeCabinetLang) {
        for (let code in langCode) {

            if (rootScopeCabinetLang === code) {
                return langCode[code];
            }
        }
    }


    //the native JS way to define the date format by a certain locale from the browser
    function retrieveBrowserDateFormat( date, option ) {
        switch ( option ) {
            case '':
                options = {};
                break;
            case 'day':
                options = {day: 'numeric', month: 'short', year: 'numeric'};
                break;
            case 'monthYear':
                options = {year: 'numeric', month: 'short'};
                break;
            case 'quarter':
                options = {year: 'numeric', month: 'short'};
                break;
            case 'year':
                options = {year: 'numeric'};
                break;
            case 'short':
                options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
                break;
            case 'long':
                options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
                break;
            case 'dayMonthYearLong':
                options = {day: 'numeric', month: 'long', year: 'numeric'};
                break;
            case 'tableLabel':
                options = {day: '2-digit', month: 'short', year: 'numeric'};
                break;
            case 'dailyForCharts':
                options = {day: '2-digit', month: 'short'};
                break;
            case 'dailyForChartsTooltip':
                options = {weekday: 'short', day: '2-digit', month: 'short'};
                break;
            case 'dailyForChartTooltipYear':
                options = {weekday: 'short', day: '2-digit', month: 'short', year: "numeric"};
                break;
            case 'dailyForTables':
                options = {day: '2-digit', month: 'short', year: "numeric"};
                break;
            case 'weeklyForCharts':
                options = {day: '2-digit', month: 'short'};
                break;
            case 'weeklyForTables':
                options = {day: '2-digit', month: 'short', year: "numeric"};
                break;
            case 'monthlyForCharts':
                options = {month: 'short'};
                break;
            case 'monthlyForTables':
                options = {month: 'short', year: "numeric"};
                break;
            case 'quarterlyForCharts':
                options = {month: 'short', day: '2-digit'};
                break;
            case 'quarterlyForTables':
                options = {month: 'short', day: '2-digit', year: "numeric"};
                break;
            case 'tableLabelAndTime':
                options = {month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit'};
                break;
            case 'tableLabelShortMonthYear':
                options = {month: 'short', year: 'numeric'};
                break;
            case 'tableLabelYear':
                options = {year: 'numeric'};
                break;
            case 'calendarLabel':
                options = {month: 'short', day: 'numeric', year: 'numeric'};
                break;
            case 'calendarInput':
                options = {day: '2-digit', month: '2-digit', year: 'numeric'};
                break;
            case 'calendarMonthAndYear':
                options = {month: 'long', year: 'numeric'};
                break;
            case 'calendarDay':
                options = {day: '2-digit'};
                break;
            case 'calendarWeekDay':
                options = {weekday: 'short'};
                break;
            case 'weekDay':
                options = {weekday: 'long'};
                break;
            default:
                options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        }

        if ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
            let format = $filter('date');
            switch (option) {
                case '':
                    options = 'dd/MM/yyyy';
                    break;
                case 'day':
                    options = 'dd MMM yy';
                    break;
                case 'monthYear':
                    options = 'MMM yy';
                    break;
                case 'quarter':
                    options = 'MMM yy';
                    break;
                case 'year':
                    options = 'yy';
                    break;
                case 'short':
                    options = 'EEE dd MMM yy';
                    break;
                case 'longWithWeekday':
                    options = 'EEEE dd MMM yyyy';
                    break;
                case 'long':
                    options = 'dd MMM yyyy';
                    break;
                case 'dayMonthYearLong':
                    options = 'DD MMM YYYY';
                    break;
                case 'tableLabel':
                    options = 'dd MMM yy';
                    break;
                case 'dailyForCharts':
                    options = 'dd MMM';
                    break;
                case 'dailyForTables':
                    options = 'dd MMM yy';
                    break;
                case 'weeklyForCharts':
                    options = 'dd MMM';
                    break;
                case 'weeklyForTables':
                    options = 'dd MMM yy';
                    break;
                case 'monthlyForCharts':
                    options = 'MMM';
                    break;
                case 'monthlyForTables':
                    options = 'MMM yy';
                    break;
                case 'quarterlyForCharts':
                    options = 'dd MMM';
                    break;
                case 'quarterlyForTables':
                    options = 'dd MMM yy';
                    break;
                case 'tableLabelAndTime':
                    options = 'dd MMM yyyy HH:mm';
                    break;
                case 'tableLabelShortMonthYear':
                    options = 'MMM yyyy';
                    break;
                case 'tableLabelYear':
                    options = 'yyyy';
                    break;
                case 'calendarLabel':
                    options = 'dd MMM yy';
                    break;
                case 'calendarInput':
                    options = 'dd/MM/yyyy';
                    break;
                case 'calendarMonthAndYear':
                    options = 'MMM yyyy';
                    break;
                case 'calendarDay':
                    options = 'd';
                    break;
                case 'calendarWeekDay':
                    options = 'EEE';
                    break;
                case 'weekDay':
                    options = 'EEEE';
                    break;
                default:
                    options = 'EEE DD MMM yyyy';
            }

            return format(date, options);

        } else {
            var dateToBeFiltered = (typeof(date) === 'number') ? (date) : (Date.parse(date) !== 'NaN') ? (Date.parse(date)) : dateParseForSafari(date);
            dateFormat = (dateToBeFiltered) ? new Date(dateToBeFiltered).toLocaleDateString(langCountry, options) : null;

            return dateFormat;
        }
    }

    function dateParseForSafari(date){
        var dateTime = date.split('T');
        var dateBits = dateTime[0].split('-');
        var timeBits = dateTime[1].split(':');
        return new Date(dateBits[0], parseInt(dateBits[1]) - 1, dateBits[2], timeBits[0], timeBits[1], timeBits[2]).valueOf();

    }

    //the alternative way to define the week format via moment.js library
    // function passing static timestamps associated with week days
    // from an array as arguments
    function retrieveTimeFormat(date, option) {

        if ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
            var format = $filter('date');
            switch (option) {
                case 'short':
                    options = 'HH:mm';
                    break;
                case 'shortHourDate':
                    options = 'HH:mm dd MMM';
                    break;
                case 'shortHourDateYear':
                    options = 'HH:mm dd MMM yyyy';
                    break;
                case 'hour':
                    options = 'HH';
                    break;
                case '12HourFormat':
                    options = 'HH:mm';
                    break;
                case '2Digit':
                    options = 'HH:mm:';
                    break;
                case 'numeric':
                    options = 'HH:mm:ss';
                    break;
                default:
                    options = 'HH:mm';
            }
            return format(date, options);
        } else if (toLocaleTimeStringSupportsLocales()) {
            switch (option) {
                case 'short':
                    options = {hour: '2-digit', minute: '2-digit'};
                    break;
                case 'shortHourDate':
                    options = {hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'short'};
                    break;
                case 'shortHourDateYear':
                    options = {hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'short', year: 'numeric'};
                    break;
                case 'hour':
                    options = {hour: '2-digit'};
                    break;
                case '12HourFormat':
                    options = {hour: '2-digit', minute: '2-digit'};
                    break;
                case '2Digit':
                    options = {hour: '2-digit', minute: '2-digit', second: "2-digit"};
                    break;
                case 'numeric':
                    options = {hour: 'numeric', minute: 'numeric', second: "numeric"};
                    break;
                default:
                    options = {hour: '2-digit', minute: '2-digit', second: "2-digit", hour12: false};
            }

            timeFormat = (date) ? new Date(date).toLocaleTimeString(langCountry, options) : null;

            if (option === 'short') {
                if (langCountry === 'bg') {
                    timeFormat = (date) ? new Date(date).toLocaleTimeString(langCountryTimeOnly, options) : null;
                }
                else if (!timeOnlyRegex.test(timeFormat)) {
                    timeFormat = (date) ? new Date(date).toLocaleTimeString(langCountryAmPm, options) : null;
                }
                if(timeFormat) {
                    timeFormat = timeFormat.replace('.', ':');
                }
            }

            return timeFormat;
        }
    }

    function is12HourFormat() {
        if(langCountry === 'bg') {
            // Exception for Bulgarian language
            // It is 24 hours format but includes an addition: "14:00 ч."
            return false;
        }
        let localeTimeString = new Date().toLocaleTimeString(langCountry, {hour: '2-digit', minute: '2-digit'});
        return !timeOnlyRegex.test(localeTimeString);
    }

    function defineWhetherItIs12HourFormat(val) {
        if ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
            return false;
        } else if (toLocaleTimeStringSupportsLocales()) {
            return is12HourFormat();
        }
    }

    function retrieveDayPart(val) {

        if ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
            return false;
        } else if (toLocaleTimeStringSupportsLocales()) {
            if (is12HourFormat()) {
                let date = new Date(val).toLocaleTimeString(langCountryAmPm, {hour: '2-digit', minute: '2-digit'});
                return (date.search("AM") !== -1) ? 'AM' : (date.search("PM") !== -1) ? 'PM' : null;
            }
            else {
                return null;
            }

        }
    }

    function toLocaleTimeStringSupportsLocales() {
        try {
            new Date().toLocaleTimeString('i');
        } catch (e) {
            return e.name === 'RangeError';
        }
        return false;
    }

    function toLocaleStringSupportsLocales() {
        try {
            new Date().toLocaleString('i');
        } catch (e) {
            return e instanceof RangeError;
        }
        return false;
    }

    function defineLangCountryCode( cabinetLang ) {
        var timeZoneName = tz.name();
        var langFromBrowserLocale = window.navigator.languages
        && window.navigator.languages.length
            ? window.navigator.languages[0].split('-')[0]
            : window.navigator.userLanguage
                ? window.navigator.userLanguage
                : window.navigator.language;
        var biDigitLangCode = retrieve2DigitCode( cabinetLang );
        if ( langFromBrowserLocale && biDigitLangCode === langFromBrowserLocale
            && biDigitLangCode !== 'ar' && biDigitLangCode !== 'th'
            &&  biDigitLangCode !== 'bn' ) {
            langCountry = window.navigator.languages[0] ? window.navigator.languages[0]
                : window.navigator.userLanguage
                    ? window.navigator.userLanguage
                    : window.navigator.language;
            if( langFromBrowserLocale === "en" && langCountry !== "en-US" ){
                langCountry = 'en-GB';
            }
        } else {
            if (biDigitLangCode === 'ar' || biDigitLangCode === 'th'
                || biDigitLangCode === 'bn' || biDigitLangCode === 'ur') {
                langCountry = biDigitLangCode;
            } else {
                langCountry = 'en-GB';
            }
        }
        $rootScope.langCountry = langCountry;

    }

    return {
        defineLangCountryCode: defineLangCountryCode,
        retrieveBrowserDateFormat: retrieveBrowserDateFormat,
        retrieveTimeFormat: retrieveTimeFormat,
        is12HourFormat: is12HourFormat,
        retrieveDayPart: retrieveDayPart,
        defineWhetherItIs12HourFormat:  defineWhetherItIs12HourFormat,
        toLocaleStringSupportsLocales: toLocaleStringSupportsLocales
    }
}

export default {
    type: 'service',
    name: 'retrieveLangCode',
    value: retrieveLangCode
};
