/**
 * Created by k.yarosh on 05.09.16.
 */

notificationController.$inject=[
    '$state',
    '$stateParams',
    'terminalOperationsFactory',
    'serviceDialog',
    '$filter',
    '$cookies'
];

function notificationController($state,
                                $stateParams,
                                terminalOperationsFactory,
                                serviceDialog,
                                $filter,
                                $cookies) {

    var notifyCtrl = this,
        $translateFilter = $filter('translate');

    notifyCtrl.serialNumber = null;
    notifyCtrl.status = '';

    notifyCtrl.result = $stateParams.result;
    notifyCtrl.previousState = $stateParams.previousState;
    notifyCtrl.cashboxParams = $stateParams.cashboxParams;
    // notifyCtrl.terminalData = $stateParams.terminalData;

    notifyCtrl.cancel = cancel;
    notifyCtrl.retry = retry;
    notifyCtrl.finish = finish;

    /**
     * @ngdoc function
     * @description
     * This function launches the detach of the current terminal from the last chosen POS in case of a failed test transaction,
     * sending data to the server due to a specific factory with an embedded promise operation.
     * The function is triggered by click on 'CANCEL' button.
     */
    // $rootScope.rrrrr = notifyCtrl.cashboxParams;

// создаем переменные в куках с данными из этого контроллера, для корректной работы рефреша браузера
    $cookies.putObject('resultFromNotifyCtrl', notifyCtrl.result);
    $cookies.putObject('cashboxParamsFromNotifyCtrl', notifyCtrl.cashboxParams);



    function cancel () {
        $cookies.remove('cashboxParamsFromNotifyCtrl');
        $cookies.remove('resultFromNotifyCtrl');
        terminalOperationsFactory.cancelLinkingTerminalToCashbox({
            'linkResponseStatus': notifyCtrl.cashboxParams.linkResponseStatus,
            'linkedCashRegisterId': notifyCtrl.cashboxParams.linkedCashRegisterId,
            'terminalId': notifyCtrl.cashboxParams.terminalId
        });
        $state.go('settings.paytypes');
    }

    /**
     * @ngdoc function
     * @description
     * This function launches a repeated test transaction sending data to the server due to a specific factory with an embedded promise operation.
     * The function is triggered by click on 'CONTINUE' button.
     */

    function retry () {
        terminalOperationsFactory.initTestTransaction({
            'cashRegisterId': $stateParams.cashboxParams.idOfSelectedCashbox || $stateParams.cashboxParams.cashRegisterId,
            'terminalId': $stateParams.cashboxParams.terminalId
        }).then(successHandler, errorHandler);

        function successHandler () {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: $translateFilter('PAYMENT_TYPES.TEST_SALE'),
                content: $translateFilter('PAYMENT_TYPES.IS_TERMINAL_SHOWING_TEST_SALE'),
                buttons: [
                    {
                        text: 'PAYMENT_TYPES.NO',
                        action: cancelAction
                    },
                    {
                        class: 'primary',
                        text: 'PAYMENT_TYPES.YES',
                        action: continueAction
                    }
                ]
            });

            function cancelAction() {
                $state.go($state.current, {
                    result: 'failure'
                });
            }

            function continueAction() {
                $state.go('vantiv.notification', {
                    result: 'success'
                });
            }
        }

        function errorHandler (error) {
            console.log(error);
        }

        // $state.go(notifyCtrl.previousState);
    }

    /**
     * @ngdoc function
     * @description
     * This function just redirects a user to the menu with payment types settings.
     * The function is triggered by click on 'OK' button of a notification about the successful test transaction.
     */

    function finish () {
        $cookies.remove('cashboxParamsFromNotifyCtrl');
        $cookies.remove('resultFromNotifyCtrl');
        $state.go('settings.paytypes');
    }
}

export default {
    type: 'controller',
    name: 'notificationController',
    value: notificationController
};