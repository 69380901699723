/*** Created by Alex 23.11.16 ***/

/**
* Created by Alex 23.11.16
* @memberof createCashRegisterCtrl
* @ngdoc controller
* @name createCashRegisterCtrl
* @requires $scope {service} controllers scope
* @requires $state {service} UI Router servise
* @requires $stateParams {service} UI Router servise
* @requires $filter {service} filter servise
* @requires serviceDialog {service} Add modal dialog
* @requires serviceToast {service} Add toast
* @requires getCashRegister {service} POST operation with CashRegister
* @requires $shortOutlets {service} Get shortOutlets list before load page
*/

import angular from 'angular';

createCashRegisterCtrl.$inject = [
	"$scope",
	"$state",
	"$stateParams",
	"$filter",
	"serviceDialog",
	"serviceToast",
	"getCashRegister",
	'$shortOutlets',
	'$translate',
	'dialogs'
];
function createCashRegisterCtrl(
	$scope,
	$state,
	$stateParams,
	$filter,
	serviceDialog,
	serviceToast,
	getCashRegister,
	$shortOutlets,
	$translate,
	dialogs
) {

	let translate = $filter( 'translate' );
	$scope.clicked = true;
	$scope.shortOutlets = $shortOutlets.slice();
	$scope.progress = false;

	if($scope.shortOutlets.length === 1){
		$scope.newCashRegister = {name: '', outletId: $scope.shortOutlets[0].id};
	}else {
		$scope.newCashRegister = {};
	}

	let initParams = {
		name: $scope.newCashRegister.name,
		outletId: $scope.newCashRegister.outletId
	};

	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

	function isChanged() {
		let currentParams = {
			name: $scope.newCashRegister.name,
			outletId: $scope.newCashRegister.outletId
		};
		return !angular.equals(initParams, currentParams);
	}


	/**
	* @ngdoc function
	* @name onSaveCreatePOS
	* @description
	* creating or editing new cashregister
	* @returns $state.go('settings.cashregister')
	*/

	function send() {
		$scope.clicked = false;
		$scope.progress = true;
		getCashRegister.setCashRegister( $scope.newCashRegister ).then( function ( res ) {
			if ( res.result === "ok" && !res.message ) {
				offStateChangeStart();
				serviceToast.show( 'MULTISHOP.CASH_REGISTER.TOSTS.CREATE_CASH_REGISTER', 'success' );
				$state.go( 'settings.cashregister', $stateParams, {
					reload: true
				} );
			}
			if (res.result === "error" && res.message === "too_many_cash_registers") {
				serviceDialog.add({
					templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
					title: translate('NOTIFICATION'),
					content: translate('MULTISHOP.CASH_REGISTER.ONLY_THOUSAND_CASH_REGISTERS'),
					buttons: [
						{
							class: 'primary',
							text: 'ON_OK',
							action: function () {
								$scope.clicked = true;
							}
						}
					]
				});
			}
		}, function () {
			$scope.clicked = true;
		})
		.finally(() => {
			$scope.progress = false;
		})
	}

	$scope.onSaveCreatePOS = () => {

		if ($scope.createPOSForm.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.createPOSForm.focusFirstInvalidControl();
			return false;
		}

		if ($scope.clicked === false) {
			return false;
		}
		else send();
	};

	/**
	* @ngdoc function
	* @name onCancelCreatePOS
	* @description
	* cancel the changes and return to the list cashregister
	* @returns $state.go('settings.cashregister')
	*/
	$scope.onCancelCreatePOS = () => {
		$state.go( 'settings.cashregister', $stateParams, {} );
	};
}

export default {
	type: 'controller',
	name: 'createCashRegisterCtrl',
	value: createCashRegisterCtrl
};