/**
 * Created by mars on 10/28/15.
 */

import template from '../templates-pug/cropper.pug';

cropperToCenter.$inject = [];
function cropperToCenter() {
	return {
		restrict: 'AC',
		template: template,
		transclude: true,
		controller: 'cropperControl',
		link: function ( scope, el, contrl ) {
			//console.log(scope.validRatioSize)

			var elH = el[0].clientHeight;
			var w = window,
				d = document,
				e = d.documentElement,
				g = d.getElementsByTagName('body')[0],
				x = w.innerWidth || e.clientWidth || g.clientWidth,
				y = w.innerHeight|| e.clientHeight|| g.clientHeight;

			var winH = y;
			el.css('marginTop', (winH - elH)/2 + 'px');
			el.css('opacity', 1);
		}
	};
}

export default cropperToCenter;