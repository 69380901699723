/**
 * Created by sirosh on 29.02.16.
 * Change the label on the chart based on the data.
 * @memberof promiseOperation
 * @ngdoc service
 * @name promiseOperation
 * @requires {service} $q promise wrapper
 * @requires {service} $http - http service
 * @requires {service} factoryErrorAlert - error handler
 * @returns {Promise} getPromiseData- return Promise from http query
 *                                      or result Promise if data have already load
 *                                      and @param reload {boolean} false
 * @returns {Promise} getPromiseHTTP - return Promise from http query
 * @returns {Promise} getPromiseDataFromQ - return Promise
 *
 * Need to refactoring
 */

import angular from 'angular';

promiseOperation.$inject = ['$q', '$http', 'factoryErrorAlert'];

function promiseOperation($q, $http, factoryErrorAlert) {
    var promiseDB = {};

    return {
        getPromiseDataFromQ: getPromiseDataFromQ,
        getPromiseWithQ: getPromiseWithQ,
        getPromiseHTTP: getPromiseHTTP,
        getPromiseData: getPromiseData,
        post: post,
        get: get
    };

    /**
     * Asynchronous function call
     * @param data
     * @returns {Promise}
     */
    function getPromiseWithQ(data) {

        return $q(function (resolve) {
            resolve(data);
        });
    }

    /**
     * Asynchronous get data from promise or cal function
     * @param data
     * @param attr - key for promiseDB object
     * @returns {Promise}
     */
    function getPromiseDataFromQ(data, attr) {
        if (promiseDB[attr] && promiseDB[attr].$$state.status === 0) {
            return promiseDB[attr];
        } else {
            promiseDB[attr] = getPromiseWithQ(data);
            return promiseDB[attr];
        }
    }


    /**
     * Get promise with data
     * @param method - 'POST' || 'GET'
     * @param url - url query
     * @param params if have params to getPromiseHTTP || {}
     * @param handlerSuccess - success handler
     * @param handlerError - error handler
     * @returns {Promise} - promise with data
     */
    function getPromiseHTTP(method, url, params, handlerSuccess, handlerError) {

        let headers = {}
        if (!_.isEmpty(params)) {
            headers = {'Content-Type': 'application/json'};
        }
        let promise;

        switch (method) {
            case 'POST':
                promise = $http.post(url, params);
                break;
            case 'GET':
                promise = $http.get(url);
                break;
            case 'DELETE':

                promise = $http.delete(url, {data: params, headers: headers});
                break;
            case 'PUT':
                promise = $http.put(url, params);
                break;
            case 'PATCH':
                promise = $http.patch(url, params);
                break;
        }

        return promise.then(function (d) {
            return handlerSuccess(d);
        }, function (error) {
            if (handlerError) {
                return handlerError(error);
            } else {
                factoryErrorAlert.onError(error);
                return error;
            }
        }).catch(function (e) {
            throw e;
        });
    }

    /**
     * Get promise
     * @param {string} method - 'POST' || 'GET'
     * @param {string} url - url query
     * @param params - if have params to getPromiseHTTPP || {}
     * @param {function} handlerSuccess - success handler
     * @returns {Promise} - promise with data
     */
    function getPromiseData(method, url, params, handlerSuccess) {
        if (promiseDB[url] && promiseDB[url].$$state.status === 0) {
            return promiseDB[url];
        } else {
            promiseDB[url] = getPromiseHTTP(method, url, params, handlerSuccess);
            return promiseDB[url];
        }
    }

    /**
     *
     * @param url
     * @param data
     * @param afterResponse
     * @param debugResolveData //для отладки
     * @returns {*}
     */
    function post(url, data, afterResponse, debugResolveData) {
        return $q(function (resolve, reject) {
            $http.post(url, data)
                .then(function (d) {
                    d.data = debugResolveData || d.data;
                    if (!angular.isObject(d.data)) {
                        d.data = {};
                    }
                    if (afterResponse) {
                        afterResponse(d.data);
                    }
                    resolve(d.data);
                }, function (err) {
                    factoryErrorAlert.onError(err);
                    reject(err);
                });
        });
    }

    function get(url, data, afterResponse, debugResolveData) {
        return $q(function (resolve, reject) {
            $http.get(url, data || null)
                .then(function (d) {
                    d.data = debugResolveData || d.data;
                    if (!angular.isObject(d.data)) {
                        d.data = {};
                    }
                    if (afterResponse) {
                        afterResponse(d.data);
                    }
                    resolve(d.data);
                }, function (err) {
                    factoryErrorAlert.onError(err);
                    reject(err);
                });
        });
    }
}

export default {
    type: 'factory',
    name: 'promiseOperation',
    value: promiseOperation
};
