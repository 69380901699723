createDirective.$inject = ['$parse'];

function createDirective($parse) {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        if (!ngModel) {
            return;
        }

        const options = $parse(attrs['lvBrandName'])(scope);
        const regExpBrandName = new RegExp('loyverse', 'i');

        ngModel.$validators.brandname = function(modelValue, viewValue) {
            let value = modelValue || viewValue;
            if (ngModel.$isEmpty(value)) {
                return true;
            }

            if (options) {
                if (value.endsWith(options.end)){
                    value = value.slice(-value.length, -options.end.length);
                }
            }

            return !regExpBrandName.test(value);
        }
    }
}

export default  createDirective;