/**
 * Created by mars on 10/22/15.
 */

import angular from 'angular';

function orderObjectBy() {
	return function ( items, field, reverse ) {
		var filtered = [];
		angular.forEach( items, function ( item ) {
			filtered.push( item );
		} );
		filtered.sort( function ( a, b ) {
			return (a[field] > b[field] ? 1 : -1);
		} );
		if ( reverse ) filtered.reverse();
		return filtered;
	};
}

export default {
	type: 'filter',
	name: 'orderObjectBy',
	value: orderObjectBy
};