/**
 * Created by IvanS on 28.09.2018
 *
 * Языки в которых нужно сделать:
 Японский
 Корейский
 Арабский
 Китайский
 Тайский
 Хинди
 Грузинский
 Урду
 Кхмер

 */

const constantGreenExport = [
    "jpn",
    "kor",
    "ara",
    "zha",
    "tha",
    "hin",
    "grn",
    "urd",
    "vie",
    "lao",
    "ben",
    "zho",
    "chn"
];

export default {
    type: 'constant',
    name: 'constantGreenExport',
    value: constantGreenExport
};