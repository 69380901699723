underTitleLine.$inject = [];

function underTitleLine() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind("wheel", function() {
                var dialogTitle = element[0].parentElement.previousElementSibling;
                var dialogTitleTopPosition = dialogTitle.offsetTop + 60;
                if (dialogTitleTopPosition > element[0].offsetTop - element[0].parentElement.scrollTop) {
                    dialogTitle.style.borderBottom = "1px solid #EAEAEA";
                } else {
                    dialogTitle.style.borderBottom = "none";
                }
            });
        }
    };


}

export default {
    type: 'directive',
    name: 'underTitleLine',
    value: underTitleLine
};