/**
 * Created by A.Sirosh on 30.10.2015.
 * Edited by K.Yarosh on 11.04.2015
 * Set the chart period
 * @memberof resolveTimezone
 * @ngdoc servise
 * @name resolveTimezone
 * @description - Service for resolve problem with offset and winter/summer daytime
 */

resolveTimezone.$inject = [];
function resolveTimezone() {

	this.timeZoneResolveMinus = timeZoneResolveMinus;
	this.timeZoneResolvePlus = timeZoneResolvePlus;
	this.timeZoneOffsetForReports = timeZoneOffsetForReports;

	/**
	 * Minus timezone offset
	 * @param resolveVal
	 * @returns {number} result - timestamp
	 */
	function timeZoneResolveMinus(resolveVal) {
		var result;
		var currentDate = new Date();
		var offset = currentDate.getTimezoneOffset();
		var resolveTime = resolveVal.getTime();
		var resolveOffset = resolveVal.getTimezoneOffset();

		result = resolveTime - (resolveOffset - offset) * 60 * 1000;
		return result;
	}

	/**
	 * Add timezone offset
	 * @param resolveVal
	 * @returns {number} result - timestamp
	 */
	function timeZoneResolvePlus(resolveVal) {
		var result;
		var currentDate = new Date();
		var offset = currentDate.getTimezoneOffset();
		var resolveTime = resolveVal.getTime();
		var resolveOffset = resolveVal.getTimezoneOffset();

		result = resolveTime + (resolveOffset - offset) * 60 * 1000;
		return result;
	}


	function timeZoneOffsetForReports() {
		var offset = - (new Date).getTimezoneOffset() * 60000;
		return offset;
	}


}

export default {
	type: 'service',
	name: 'resolveTimezone',
	value: resolveTimezone
};