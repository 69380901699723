/**
 * Created by olehkss on 06.09.16.
 */

vantivTerminalController.$inject = [
    '$state',
    '$stateParams',
    'terminalOperationsFactory',
    '$filter',
    '$cashboxesList',
    'serviceDialog',
    '$cookies'
];

function vantivTerminalController($state,
                                  $stateParams,
                                  terminalOperationsFactory,
                                  $filter,
                                  $cashboxesList,
                                  serviceDialog,
                                  $cookies) {
    var vm = this,
        $translateFilter = $filter('translate');

    vm.serialNumber = null;
    vm.terminalForm = {};
    //this string will be shown in message about assigning terminal to another cashbox
    vm.clickHereLink = '<span class = "click-here">' + $translateFilter('PAYMENT_TYPES.HERE') + '</span>';
    //the cashbox name the terminal is registered to(name will be shown together with error message)
    // vm.applyStyleToPOS = applyStyleToPOS;

    vm.cashboxParams= {};
    vm.cashboxName = '';
    vm.terminalData = $stateParams.terminalData;
    // console.log("terminalData = ", vm.terminalData);

    vm.onCancel = onCancel;
    vm.onContinue = onContinue;
    vm.clickHereEventHandler = clickHereEventHandler;
    vm.makeValid = makeValid;
    vm.isFormValid = isFormValid;



    /**
     * @ngdoc function
     * @description
     * Pressing of the cancel button will be processed by this button
     */
    function onCancel() {
        $state.go('settings.paytypes');
        $cookies.remove('cashboxParamsFromVantivTerminalCtrl');
    }

    /**
     * @ngdoc function
     * @description
     * This function processes the result of terminal linking (server response).
     * The function is triggered by click on 'CONTINUE' button.
     */
    function onContinue() {

        var linkingParams = vm.terminalData;
        linkingParams.terminalType = 'ECONDUIT';
        linkingParams.serialNumber = vm.serialNumber;

        terminalOperationsFactory.linkTerminal(linkingParams).then(successLinkingHandler, connectionErrorHandler);

        function successLinkingHandler(response) {
            vm.cashboxParams.terminalId = response.terminalId;
            // vm.cashboxParams.linkedCashRegisterName = response.linkedCashRegisterName;
            vm.cashboxParams.linkedCashRegisterName = response.linkedCashRegisterName;
            vm.cashboxParams.linkedCashRegisterId = response.linkedCashRegisterId;
            vm.cashboxParams.linkResponseStatus = response.status;
            vm.cashboxParams.linkResponseMessage = response.errorMessage;


            switch (response.status) {
                case 'ERROR':
                    //invalid serial number of terminal
                    if(response.errorMessage === "This terminal is already configured to a different user, please contact support") {
                        vm.terminalForm.serialNumber.$error.terminalAlreadyExists = true;
                        vm.terminalForm.serialNumber.$invalid = true;
                        // console.log(response);
                        // break;
                    } else {
                        //terminal is already connected to current user
                        vm.terminalForm.serialNumber.$error.terminalNotRegistered = true;
                        vm.terminalForm.serialNumber.$invalid = true;
                        // console.log(response);
                        // break;
                    }
                    break;

                // vm.terminalForm.serialNumber.$error.terminalNotRegistered = true;
                // vm.terminalForm.serialNumber.$invalid = true;
                // console.log(response);
                // break;

                //an obsolete case according to previous business logic
                // case 'ALREADY_EXISTS':
                //     vm.terminalForm.serialNumber.$error.terminalAlreadyExists = true;
                //     vm.terminalForm.serialNumber.$invalid = true;
                //     console.log(response);
                //     break;
                //terminal is already connected to another cashbox
                case 'ALREADY_EXISTS_WITH_LINK':
                {
                    vm.terminalForm.serialNumber.$error.terminalConnectedToCashbox = true;
                    vm.terminalForm.serialNumber.$invalid = true;

                    vm.cashboxParams.linkedCashRegisterId = response.linkedCashRegisterId;
                    vm.cashboxParams.linkResponseStatus = response.status;
                    vm.cashboxParams.terminalId = response.terminalId;

                    //searching for correct name of cashbox

                    $cashboxesList.forEach(function (cashbox) {
                        if (cashbox.cashRegisterId === response.linkedCashRegisterId) {
                            // vm.cashboxParams.linkedCashRegisterName = cashbox.cashRegisterName || $translateFilter('CASHBOX_ONE');
                            vm.cashboxParams.linkedCashRegisterName = cashbox.name || $translateFilter('CASHBOX_ONE');
                            vm.cashboxName = '<span class = "cashbox-name">' + vm.cashboxParams.linkedCashRegisterName + '</span>';
                            return vm.cashboxName;
                        }
                    });
                }
                    break;
                //terminal successfully linked
                case 'SUCCESS':
                case 'ALREADY_EXISTS':
                {
                    //if user have several cashboxes he will need to choose the to link terminal to
                    if ($cashboxesList.length > 1) {
                        vm.cashboxParams.linkedCashRegisterId = response.linkedCashRegisterId;
                        //you need to put actual id here
                        $state.go('vantiv.cashbox', {
                            cashboxParams: vm.cashboxParams,
                            terminalData: vm.terminalData
                        });
                    }
                    //if user have one cashbox terminal automatically connects to that one cashbox
                    else {
                        vm.cashboxParams.cashRegisterId = $cashboxesList[0].cashRegisterId;

                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                            title: $translateFilter('PAYMENT_TYPES.TEST_SALE'),
                            content: $translateFilter('PAYMENT_TYPES.IS_TERMINAL_SHOWING_TEST_SALE'),
                            buttons: [
                                {
                                    text: 'PAYMENT_TYPES.NO',
                                    action: function () {
                                        $state.go('vantiv.notification', {
                                            result: 'failure',
                                            previousState: $state.current.name,
                                            cashboxParams: vm.cashboxParams,
                                            terminalData: vm.terminalData
                                        });
                                    }
                                },
                                {
                                    class: 'primary',
                                    text: 'PAYMENT_TYPES.YES',
                                    action: function () {
                                        $state.go('vantiv.notification', {
                                            result: 'success',
                                            previousState: $state.current.name,
                                            cashboxParams: vm.cashboxParams,
                                            terminalData: vm.terminalData
                                        });
                                    }
                                }
                            ]
                        });
                    }
                }
                    break;
            }

            // создаем переменные в куках с данными из этого контроллера, для корректной работы рефреша браузера
            $cookies.putObject('cashboxParamsFromVantivTerminalCtrl', vm.cashboxParams);
            // console.log(vm.cashboxParams);

        }

        // console.log($cookies.getObject('cashboxParamsFromVantivTerminalCtrl'));

        function connectionErrorHandler(error) {
            console.log(error);
        }

    }

    /**
     * @ngdoc function
     * @param {Object} event click event object
     * @description
     * Shows dialog asking whether user really would like to link terminal to another cashbox
     */
    function clickHereEventHandler(event) {
        //dialog pop-ups only if user has clicked click here link
        if (event.target.className === 'click-here') {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: $translateFilter('PAYMENT_TYPES.TERMINAL_CONNECTION_TO_ANOTHER_POS'),
                content: $translateFilter('PAYMENT_TYPES.ARE_YOU_SURE_ABOUR_TERMINAL_CONNECTION_TO_ANOTHER_POS'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'CONTINUE',
                        action: continueAction
                    }
                ]
            });
        }


        function continueAction() {
            $state.go('vantiv.cashbox', {
                cashboxParams: vm.cashboxParams,
                terminalData: vm.terminalData
            });
        }

    }

    // function applyStyleToPOS (pieceOfContent) {
    //     $('div.cashboxname').html().replace(pieceOfContent, pieceOfContent).addClass('cashbox-name');
    // }

    // function applyStyleToPOS (pieceOfContent) {
    //     $('div:contains(pieceOfContent)').addClass('cashbox-name');
    // }




    /**
     * @ngdoc function
     * @returns {boolean}
     * @description
     * Checks the validity of form, if form valid and untouched the error messages will be hidden
     */
    function isFormValid() {
        var key;

        if (!vm.terminalForm.serialNumber.$touched)  return true;
        //looping through the object of errors
        for (key in vm.terminalForm.serialNumber.$error) {
            if (!vm.terminalForm.serialNumber.$error.hasOwnProperty(key)) continue;
            if (vm.terminalForm.serialNumber.$error[key]) {
                return false;
            }
        }

        return true;
    }

    /**
     * @ngdoc function
     * @description
     * This function sets customs errors to false so they won't be shown after user clicks the form and start changing serialNumber
     */
    function makeValid() {

        if (vm.terminalForm.serialNumber.$error.terminalConnectedToCashbox ||
            vm.terminalForm.serialNumber.$error.terminalNotRegistered ||
            vm.terminalForm.serialNumber.$error.terminalAlreadyExists) {
            vm.terminalForm.serialNumber.$error.terminalConnectedToCashbox = false;
            vm.terminalForm.serialNumber.$error.terminalNotRegistered = false;
            vm.terminalForm.serialNumber.$error.terminalAlreadyExists = false;
        }

        if (vm.serialNumber) {
            vm.terminalForm.serialNumber.$invalid = false;
            vm.terminalForm.serialNumber.$valid = true;
        }
    }
}

export default {
    type: 'controller',
    name: 'vantivTerminalController',
    value: vantivTerminalController
};