/**
 * Created by mars on 11/2/15.
 */

serviceCropper.$inject = ['$compile'];
function serviceCropper($compile) {
	var elContainerDiv = null;

	var elStyle = null;

	function onCreate( scope ) {
		var str = '<div class="cropper-container " ng-if="cropperIsNeeded">' +
			'<div class="cropper-to-center" style="opacity: 1">' +
			'</div>' +
			'</div>';
		scope.elContainerDiv = $compile( str )( scope );
		document.body.appendChild( scope.elContainerDiv[0] );


		scope.$on( '$destroy', function () {
			scope.elContainerDiv = null
		} );
	}

	// TODO: Oleksii: styles already in bundle
	// function onStyle() {
	// 	elStyle = document.createElement( 'link' );
	// 	elStyle.setAttribute( 'href', '../module-dynamic/img-maintenance/css/img-maintenance.css' );
	// 	elStyle.setAttribute( 'rel', 'stylesheet' );
	// 	document.head.appendChild( elStyle );
	// }

	this.onCreate = function ( scope ) {

		if(!scope.elContainerDiv) onCreate( scope );

		//if ( !elContainerDiv ) onCreate( scope );

		// if ( !elStyle ) onStyle();

	}


}

export default serviceCropper;