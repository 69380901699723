/**
 * Created by Alex on 16.08.2017.
 */

weightFormatOnlyShow.$inject = ['currencyFormat'];
function weightFormatOnlyShow(currencyFormat) {

    let countOptionsShow = {};
    let parameters = {};

    currencyFormat.observable.countOptionsShow.observe(result => {
        countOptionsShow = result;
        parameters = {
            symbol: '',	// дефолтный символ валюты - нам не нужен
            decimal: countOptionsShow.decimalCharacter,  	// разделитель десятичных
            group: countOptionsShow.digitGroupSeparator	// разделитель тысяч и миллионов
        };
    }, true);

    format.$stateful = true;
    function format(val) {
        // console.log("в самом начале val = ", val);

        val = parseFloat(val);
        var formatVal = val.toString();
        var arr = formatVal.split('.');
        // console.log("arr = ", arr);

        if (arr.length == 1) {
          parameters.pattern = (countOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.' : '#,##0.';
          // parameters.pattern = '#,##0.';
        }
        if (arr.length == 2 && arr[1].length == 1) {
          parameters.pattern = (countOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.0' : '#,##0.0';
          // parameters.pattern = '#,##0.0';
        }
        if (arr.length == 2 && arr[1].length == 2) {
          parameters.pattern = (countOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.00' : '#,##0.00';
          // parameters.pattern = '#,##0.00';
        }
        if (arr.length == 2 && arr[1].length == 3) {
          parameters.pattern = (countOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.000' : '#,##0.000';
          // parameters.pattern = '#,##0.000';
        }

        val = OSREC.CurrencyFormatter.format(val, parameters);
        // console.log("OSREC val = ", val);

        return val;
    }

    return format;
}

export default {
    type: 'filter',
    name: 'weightFormatOnlyShow',
    value: weightFormatOnlyShow
};
