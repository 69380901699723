serviceNavBoard.$inject = [];
function serviceNavBoard() {
	this.deployedNav = null;
	this.deployMenu = null;
	this.navBoardElement = null;
}

export default {
	type: 'service',
	name: 'serviceNavBoard',
	value: serviceNavBoard
};