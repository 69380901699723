/**
 * Сервис обработки ошибок в отчетах
 * Created by Shestakov on 10.01.21
 */

serviceReportsError.$inject = [];
function serviceReportsError() {

    function reportsError(err) {
        if (err.status !== -1 && err.status.toString().match(/^5/)) {
            return {unableToLoadData: true};
        } else {
            return {unableToLoadData: false};
        }
    }

    this.reportsError = reportsError;

}

export default {
    type: 'service',
    name: 'serviceReportsError',
    value: serviceReportsError
};
