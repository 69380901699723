/**
 * Created by mars on 12/16/15.
 * Abstract
 */

lvPager.$inject = [];
function lvPager() {

	this.constructor = function($scope, _offset){
		$scope.pager.page = (function () {
			return _offset / $scope.pager.limit + 1;
		}());

		$scope.setPage = function () {
			try {
				$scope.pager.page = parseInt( $scope.pager.page );
				if ( $scope.pager.page < 1 ) {
					$scope.pager.page = 1;
				} else if ( $scope.pager.pages < $scope.pager.page ) {
					$scope.pager.page = $scope.pager.pages || 1;
				}
			} catch ( err ) {
				$scope.pager.page = 1;
				console.error( err );
			}
			$scope.pager.offset = ($scope.pager.page - 1) * $scope.pager.limit;
			$scope.stateGo({
				offset: $scope.pager.offset
			})
		};


		$scope.stepBack = function () {
			$scope.pager.page--;
			$scope.setPage();
		};

		$scope.stepForward = function () {
			$scope.pager.page++;
			$scope.setPage();
		};
		/**
		 @Abctract
		 $scope.stateGo = function()

		 */

	};

}

export default {
	type: 'service',
	name: '$lvPager',
	value: lvPager
};