/**
 * Validator compares model value with list of values and is invalid when the list contains one.
 * Created by shestakov on 21.10.21
 */

lvValuesList.$inject = [];

function lvValuesList() {

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          excludedValues: '='
        },
        link: link
    }

    function link(scope, el, attrs, ctrl) {
        ctrl.$validators.valuesList = function (modelValue, viewValue) {
            if (ctrl.$isEmpty(modelValue)) {
                return true;
            }

            if (!angular.isDefined(attrs.excludedValues) || !angular.isArray(scope.excludedValues)) {
                return false;
            }

            return !scope.excludedValues.includes(modelValue);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvValuesList',
    value: lvValuesList
};
