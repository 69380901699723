placeholderOnFocus.$inject = ['domUtils', '$parse'];
function placeholderOnFocus(domUtils, $parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            const placeholder = $parse(attrs['placeholderOnFocus'])(scope);
            if (placeholder) {
                domUtils.observeFocusState(element,
                    () => element.attr('placeholder', placeholder),
                    () => element.attr('placeholder', null));
            }
        }
    };
}

export default {
    type: 'directive',
    name: 'placeholderOnFocus',
    value: placeholderOnFocus
};