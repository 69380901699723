/**
 * Created by Alex on 03.11.17
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/billing-display-alert-world.pug'
import {SubscriptionRenewData} from "../controller/settings/billing/components/billing-subcriptions-comp";

billingDisplayAlertWorldDirective.$inject = ['$filter',
                                            '$rootScope',
                                            'factoryBilling',
                                            'serviceToast',
                                            'serviceDialog',
                                            '$state',
                                            'factoryErrorAlert',
                                            'lodash',
                                            'serviceBillingMixpanel',
                                            '$timeout',
                                            'serviceBillingError',
                                            'serviceBillingCommonMethods',
                                            'serviceBillingDifferentPlaces',
                                            'strGwConst',
                                            'declensionsConst',
                                            'serviceStrings'];

function billingDisplayAlertWorldDirective($filter,
                                           $rootScope,
                                           factoryBilling,
                                           serviceToast,
                                           serviceDialog,
                                           $state,
                                           factoryErrorAlert,
                                           lodash,
                                           serviceBillingMixpanel,
                                           $timeout,
                                           serviceBillingError,
                                           serviceBillingCommonMethods,
                                           serviceBillingDifferentPlaces,
                                           strGwConst,
                                           declensionsConst,
                                           serviceStrings) {
    return {
        restrict: 'E',
        template: template,
        scope: {
            date: '<',
            ownerId: '='
        },
        link: function(scope){

            // блок инициализации данных
            const translate           = $filter('translate');
            const stripeGateways      = strGwConst;
            const ownerId = scope.ownerId;

            scope.editProfile       = $rootScope.permits.editProfile;

            scope.$watch('date', dataUpdated);

            dataUpdated();

            function dataUpdated() {
                scope.alert             = angular.copy(scope.date.dateBilling.alert);
                scope.utcOffsetSec      = moment().utcOffset()*60000;
                scope.now               = new Date().getTime();
                scope.emptyPlan         = {id:null, cycle:null, unlim:null, price:null};
                scope.emptyCoupon       = {showInput:false, error:"", name:"", tempName:"", haveCoupon:false, code:null};
                scope.coupon = {
                    EMPLOYEE: angular.copy(scope.emptyCoupon),
                    EMPSTORE: angular.copy(scope.emptyCoupon),
                    INVENTORY: angular.copy(scope.emptyCoupon),
                    INTEGRATION: angular.copy(scope.emptyCoupon),
                    SALESHISTORY: angular.copy(scope.emptyCoupon)
                };
                const textConst = scope.alert.type === "EMPSTORE" ? "EMPLOYEE" : scope.alert.type;

                scope.alert.trialEnd      = parseInt( (scope.alert.trialEndsOn + scope.utcOffsetSec - scope.now) / 86400000);
                scope.alert.pastDueFrom   = parseInt( (scope.now - scope.alert.pastDueSince + scope.utcOffsetSec) / 86400000);
                scope.alert.pastDueEnd    = parseInt( (scope.alert.blocksOn + scope.utcOffsetSec - scope.now) / 86400000);

                if( scope.alert.hasOwnProperty('endsOn') && scope.alert.endsOn != null ) {
                    scope.alert.subscrEnd     = parseInt( (scope.alert.endsOn + scope.utcOffsetSec - scope.now) / 86400000);
                    scope.dayDeclension = 'DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(scope.alert.subscrEnd);
                }
                if(scope.alert.category === 'TRIAL_ENDS') {
                    scope.dayDeclension = 'DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(scope.alert.trialEnd);
                }
                if(scope.alert.category === 'PAST_DUE' && scope.alert.type != null) {
                    if (scope.alert.pastDueFrom > 1 && scope.alert.pastDueEnd === 0) {
                        scope.dayDeclension = 'DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(scope.alert.pastDueFrom);
                    } else {
                        scope.dayDeclension = 'DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(scope.alert.pastDueEnd);
                        if(scope.alert.pastDueFrom > 1 && scope.alert.pastDueEnd > 0) {
                            scope.dayFromDeclension = 'DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(scope.alert.pastDueFrom);
                        }
                    }
                }

                scope.trialText1    = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_TRIAL_1";
                scope.trialText2    = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_TRIAL_2";
                scope.text1         = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_PAST_DUE_1";
                scope.text2         = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_PAST_DUE_2";
                scope.text3         = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_PAST_DUE_3";
                scope.text4         = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_PAST_DUE_4";
                scope.expires1      = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_EXPIRES_1";
                scope.expires2      = "ACCOUNT.B2_LINE_ALERT_WORLD.B2_" + textConst + "_EXPIRES_2";
            }

            factoryBilling.getMinAccountInfo({}).then(
                function (data) {
                    if (data.subscriptions) {
                        const existingSubscriptions = Object.keys(data.subscriptions);
                        const subscriptions = serviceBillingDifferentPlaces.getSubscriptionConstants(existingSubscriptions);

                        subscriptions.forEach(function (subscription) {
                            data.subscriptions[subscription].plan === null ? data.subscriptions[subscription].plan = angular.copy(scope.emptyPlan) : "";
                        })
                    }
                    scope.miniBillingInfo = angular.copy(data);
                },
                function (err) {
                    factoryErrorAlert.onError(err);
                }
            );
            // блок инициализации данных


            // диалоговое окно - активация одной из подписок
            scope.activateSubscription = function (type) {
                let createNewPlan;
                let couponCode;
                let tempCoupon, tempPlan, msgTitle, msgContent1, msgContent3, msgContent4, msgContent5, msgContent6,
                    msgSingle1, msgSingle12, msgUnlim1, msgUnlim12, msgNewPlan, msgCouponDate;
                const textConst = type === "EMPSTORE" ? "EMPLOYEE" : type;
                const payPerConst = type === "EMPLOYEE" ? "EMPLOYEE" : "STORES";
                if(!lodash.some(scope.miniBillingInfo.plans[type],{"unlim":true}) && scope.miniBillingInfo.subscriptions[type].plan.unlim) {
                    scope.miniBillingInfo.subscriptions[type].plan.id = null;
                }
                tempCoupon = angular.copy(scope.coupon[type]);
                tempPlan = angular.copy(scope.miniBillingInfo.subscriptions[type].plan);
                msgTitle        =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + textConst + '_ACTIVATE');
                msgContent1     =  translate('ACCOUNT.B2_' + textConst + '_SUBSCRIBE_TEXT');
                msgContent3     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER');
                msgContent4     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_MONTH');
                msgContent5     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_YEAR');
                msgContent6     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_UNLIMITED');
                msgSingle1 = lodash.find(scope.miniBillingInfo.plans[type], { 'cycle': 1, 'unlim': false });
                msgSingle12 = lodash.find(scope.miniBillingInfo.plans[type], { 'cycle': 12, 'unlim': false });
                msgUnlim1 = lodash.find(scope.miniBillingInfo.plans[type], { 'cycle': 1, 'unlim': true });
                msgUnlim12 = lodash.find(scope.miniBillingInfo.plans[type], { 'cycle': 12, 'unlim': true });
                msgNewPlan = scope.miniBillingInfo.subscriptions[type].plan;
                msgCouponDate   =  scope.coupon[type];

                serviceDialog.add({
                    templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
                    title:          msgTitle,
                    content1:       msgContent1,
                    content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
                    content3:       msgContent3,
                    content4:       msgContent4,
                    content5:       msgContent5,
                    content6:       msgContent6,
                    content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
                    content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
                    single1:        msgSingle1,
                    single12:       msgSingle12,
                    unlim1:         msgUnlim1,
                    unlim12:        msgUnlim12,
                    newPlan:        msgNewPlan,
                    paymentType:    scope.miniBillingInfo.paymentMethod,
                    inEU:           scope.miniBillingInfo.taxMessage,
                    couponDate:     msgCouponDate,
                    showFeeText:    type === 'INTEGRATION',
                    buttons: [  {   text:  'ON_CANCEL',
                                    action: function () {
                                                serviceBillingMixpanel.setMixpanelBillingActivateInAlert("cancel", scope.emptyPlan, type, scope.miniBillingInfo.paymentMethod);
                                                cancelSubscriptionDialog(type, tempPlan, tempCoupon);
                                            }
                                },
                                {   text:   (scope.miniBillingInfo.paymentMethod === null)? 'ACCOUNT.CONTINUE' : 'ACCOUNT.B2_ACTIVATE',
                                    action: function () {
                                                scope.miniBillingInfo.subscriptions[type].plan = lodash.find(scope.miniBillingInfo.plans[type], { 'id': scope.miniBillingInfo.subscriptions[type].plan.id });
                                                createNewPlan = scope.miniBillingInfo.subscriptions[type].plan;
                                                (scope.coupon[type].haveCoupon === true && scope.coupon[type].code !== null)? couponCode = scope.coupon[type].code : couponCode = null;

                                                if (scope.miniBillingInfo.paymentMethod === null) {
                                                    const urlWithHash = window.location.href;
                                                    const stateParams = {
                                                        addSubscribe        : createNewPlan,
                                                        typeSubscriptions   : type,
                                                        urlForReload        : urlWithHash,
                                                        activate            : true,
                                                        coupon              : couponCode};
                                                    if(scope.date.dateBilling.gw === "bt")  { $state.go( 'addPayMethodBt', stateParams) }
                                                    if(stripeGateways.indexOf(scope.date.dateBilling.gw) !== -1) { $state.go( 'addPayMethodStr', stateParams) }
                                                    if(scope.date.dateBilling.gw === "bs" || scope.date.dateBilling.gw === "wc")  {
                                                        serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, createNewPlan.id, couponCode, cancelSubscriptionDialog.bind(null,type, tempPlan, tempCoupon), null, null);
                                                    }

                                                }
                                                else {
                                                    showEstimatedInvoice(createNewPlan, type, couponCode, scope.miniBillingInfo.paymentMethod, tempPlan, tempCoupon);
                                                }
                                            }
                                },
                                {   text:           'APPLY_APPLY',
                                    closeOnAction:  false,
                                    action:         function () {
                                                        addCoupon(scope.miniBillingInfo.subscriptions[type].plan, scope.coupon[type], scope.coupon[type].name);
                                                    }
                                }
                            ]
                });
            };
            // диалоговое окно - активация одной из подписок


            // проверка, разрешен ли выбор тарифного плана
            scope.checkIfPlanAllowed = function () {
                serviceBillingDifferentPlaces.checkIfPlanAllowed(scope.miniBillingInfo.paymentMethod, scope.activateSubscription.bind(null, scope.alert.type))
            };
            // проверка, разрешен ли выбор тарифного плана


            // отправка на сервер активации услуги (перезагружаем всегда страницу)
            function createSubscribeActivate (plan, type, couponCode, paymentType) {
                const data = {planId: plan.id,
                            activate: true,
                            coupon: couponCode,
                            estimate: false};
                factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
                    serviceBillingMixpanel.setMixpanelBillingActivateInAlert(res.result, plan, type, paymentType);

                    if (res.result === "ok") {
                        serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                        serviceBillingError.reloadRoute();
                    }
                    else if (res.result === "error") {
                        serviceBillingError.knownError(res.message);
                    }
                    else {
                        serviceBillingError.unKnownError();
                    }
                });
            }
            // отправка на сервер активации услуги (перезагружаем всегда страницу)


            // установка старых данных по КУПОНУ
            function setOldCoupon(type, oldCoupon) {
                scope.coupon[type]?  scope.coupon[type]  = oldCoupon : "";
            }
            // установка старых данных по КУПОНУ


            // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ
            function addCoupon(plan, coupon, nameNew) {
                serviceBillingDifferentPlaces.addCoupon(plan, coupon, nameNew);
            }
            // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ


            // отключение диалога выбора подписки
            function cancelSubscriptionDialog(type, plan, coupon) {
                setOldCoupon(type, coupon);
                scope.miniBillingInfo.subscriptions[type].plan  = plan;
            }
            // отключение диалога выбора подписки


            // предпросмотр счета к оплате
            function showEstimatedInvoice(plan, type, couponCode, paymentType, tempPlan, tempCoupon) {
                const data = {planId: plan.id,
                            activate: true,
                            coupon: couponCode,
                            estimate: true};
                factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
                    if(res.result === "ok") {
                        serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createSubscribeActivate.bind(null, plan, type, couponCode, paymentType), cancelEstimatedInvoice.bind(null, type, tempPlan, tempCoupon));
                    } else if (res.result === "error") {
                        serviceBillingError.knownError(res.message);
                    } else {
                        serviceBillingError.unKnownError();
                    }
                });
            }
            // предпросмотр счета к оплате


            // отмена предпросмотра счета к оплате
            function cancelEstimatedInvoice(type, tempPlan, tempCoupon) {
                cancelSubscriptionDialog(type, tempPlan, tempCoupon);
                scope.activateSubscription(type);
            }
            // отмена предпросмотра счета к оплате


            // activates subscription renew, go to add payment method page or show estimated invoice depending on if payment method exists or not
            scope.startRenewSubscription = (type) => {
                if (scope.miniBillingInfo.paymentMethod === null) {
                    const stateParams = {
                        subscriptionId: scope.miniBillingInfo.subscriptions[type].id,
                        urlForSuccessReload: $state.current.name
                    };

                    if (scope.date.dateBilling.gw === "bt")  { $state.go( 'addPayMethodBt',  stateParams) }
                    if (stripeGateways.includes(scope.date.dateBilling.gw)) { $state.go( 'addPayMethodStr', stateParams) }
                    if (scope.date.dateBilling.gw === "bs")  {
                        serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, scope.miniBillingInfo.subscriptions[type].plan.id, null);
                    }
                } else {
                    showEstimatedInvoiceForRenew(scope.miniBillingInfo.subscriptions[type].id);
                }
            }

            // show estimated invoice for renewing subscription
            const showEstimatedInvoiceForRenew = (subscriptionId) => {
                const data = new SubscriptionRenewData(subscriptionId, true);

                factoryBilling.renewSubscription(data).then((res) => {
                    if (res.result === "ok") {
                        const renewSubscriptionData = new SubscriptionRenewData(subscriptionId, false);
                        serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, renewSubscription.bind(null, renewSubscriptionData));
                    } else if (res.result === "error") {
                        serviceBillingError.knownError(res.message);
                    } else {
                        serviceBillingError.unKnownError();
                    }
                });
            }

            // renew subscription
            const renewSubscription = (data) => {
                factoryBilling.renewSubscription(data).then((res) => {
                    if (res.result === "ok") {
                        serviceBillingError.reloadRoute();
                    } else if (res.result === "error") {
                        serviceBillingError.knownError(res.message);
                    } else {
                        serviceBillingError.unKnownError();
                    }
                });
            }

        }
    };

}

export default {
    type: 'directive',
    name: 'billingDisplayAlertWorld',
    value: billingDisplayAlertWorldDirective
};
