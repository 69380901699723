factoryPurchaseOrders.$inject = ['factoryErrorAlert', 'serviceFile', '$http', 'lodash', '$state'];
function factoryPurchaseOrders(factoryErrorAlert, serviceFile, $http, lodash, $state) {
  const getPurchaseOrderPDF = (data) => {
    return $http.get(`/data/ownercab/downloadPurchasePdf/${data.orderId}?tzOffset=${data.tzOffset}`, { responseType: 'arraybuffer' }).then(
      (res) => {
        const contentType = res.headers("Content-Type");
        if (contentType === "application/pdf") {
          serviceFile.saveFile(contentType, res.headers("Content-Disposition"), res.data);
          return "";
        } else if (contentType === "application/json") {
          const jsonString = new TextDecoder().decode(res.data);
          return JSON.parse(jsonString);
        } else {
          factoryErrorAlert.onError(res);
        }
      },
      (err) => {
        factoryErrorAlert.onError(err);
      }
    )
  }

  const getPurchaseOrderCSV = (data) => {
    return $http.get(`/data/ownercab/downloadPurchaseCsv/${data.orderId}?tzOffset=${data.tzOffset}`, { responseType: 'arraybuffer' }).then (
      (res) => {
        const contentType = res.headers("Content-Type");
        if (contentType === "text/csv") {
          serviceFile.saveFile(contentType, res.headers("Content-Disposition"), res.data);
          return "";
        } else if (contentType === "application/json") {
          const jsonString = new TextDecoder().decode(res.data);
          return JSON.parse(jsonString);
        } else {
          factoryErrorAlert.onError(res);
        }
      },
      (err) => {
        factoryErrorAlert.onError(err);
      }
    )
  }

  const backToPurchaseOrdersList = (stateFromList) => {
    let params;
    if (lodash.isEmpty(stateFromList)) {
      params = {
        page:	0,
        limit: 10
      }
    } else {
      params = {
        page: stateFromList.page,
        limit: stateFromList.limit,
        outletId:	stateFromList.outletId,
        supplier: stateFromList.supplier,
        status: stateFromList.status
      }
    }

    $state.go('inventory.purchase', params, {reload: true})
  }

  return {
    getPurchaseOrderPDF: getPurchaseOrderPDF,
    getPurchaseOrderCSV: getPurchaseOrderCSV,
    backToPurchaseOrdersList: backToPurchaseOrdersList
  }
}

export default {
  type: 'factory',
  name: 'factoryPurchaseOrders',
  value: factoryPurchaseOrders
}
