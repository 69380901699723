reportTimeClockRecordController.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$filter',
    '$timeout',
    '$hoursOptionsShow',
    '$timeCardsReport',
    'constantGreenExport',
    '$profile',
    'isBannerDisplayed'
];

function reportTimeClockRecordController(
    $scope,
    $stateParams,
    $state,
    $filter,
    $timeout,
    $hoursOptionsShow,
    $timeCardsReport,
    constantGreenExport,
    $profile,
    isBannerDisplayed
) {
    // console.log("$timeCardsReport = ", $timeCardsReport);
    var translate = $filter('translate');
    var secondsToHours = $filter('secondsToHours');
    $scope.timeCardsReport = $timeCardsReport.rows;
    $scope.pages = $timeCardsReport.pages;
    $scope.outlets = $timeCardsReport.fillSelectData.outlets;
    $scope.startDate = $timeCardsReport.startDate;
    $scope.endDate = $timeCardsReport.endDate;
    $scope.employeesTimecards = true;

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    $scope.getMerchantsList = function () {
        var outletsList = $scope.outlets;
        var merchantsList = [];
        var merchantsInOutlet={};
        for(var i = 0; i < outletsList.length; i++) {
            merchantsInOutlet = outletsList[i].merchants;
            for(var l = 0; l < merchantsInOutlet.length; l++){
                merchantsList.push(merchantsInOutlet[l]);
            }
        }
        return merchantsList;
    };
    $scope.merchants = $scope.getMerchantsList();
    $scope.downloadHrefBase = '/data/ownercab/exporttimecardsreport';
    $scope.hoursOptionsShow = $hoursOptionsShow;

    for(var i = 0; i < $scope.timeCardsReport.length; i++){
        $scope.timeCardsReport[i]["_hours"] = secondsToHours( $scope.timeCardsReport[i].totalHours, $scope.hoursOptionsShow.decimalCharacter );
    }

    $scope.totalHours = secondsToHours( $timeCardsReport.totalTime, $scope.hoursOptionsShow.decimalCharacter );

    // employee management promo banner data
    $scope.bannerOptions = {
        lang: $profile.cabinetLang,
        show: isBannerDisplayed
    }

    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if ($scope.timeCardsReport.length == 0 && $stateParams.page > 0) {
        $state.go( 'employees.timeclockreport', {page: $scope.pages - 1} );
    }


    $scope.defineDeclension = function(outletCount) {
        if((outletCount > 4 && outletCount < 21) || (outletCount % 10 > 4 && outletCount % 10 <= 9) || outletCount % 10 === 0) {
            return translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_2");
        } else if (outletCount % 10 > 1 && outletCount % 10 < 5){
            return translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_1");
        }
    }


    // //склонения слов
    // console.log($scope.timeCardsReport);

    // for (var outlet in $scope.timecardReport){
    //     $scope.timeCardsReport(outlet).declension = defineDeclension(outlet(outletCount));
    // }

    // console.log($scope.timeCardsReport);
    // function defineDeclension(outlet){
    //     // var outletsDeclension = outlet;
    //     var outletCount = timeCardsReport.outletCount;
    //     if(outletCount) {
    //         var outletsDeclension;
    //         var outletCount = [];

    //         self.getExistingIds(self.stateParamsOutletsIds, self.outlets, outletCount);

    //         var outletsQuantity = outletCount.length;


    //         var modulus = outletCount.length % forModulus;
    //         var allOutlets;
    //         var outletsDeclensionSingle;
    //         var outletsDeclensionPlural;
    //         var outletsDeclensionPlural2;
    //         if (outletCount.length <=20) {
    //             allOutlets = (self.outlets.length === 1) || (outletsQuantity === self.outlets.length -1);
    //             outletsDeclensionSingle = (outletsQuantity === 1);
    //             outletsDeclensionPlural = (outletsQuantity > 1 && outletsQuantity < 5);
    //             outletsDeclensionPlural2 = (outletsQuantity >= 5 && outletsQuantity <= 20);
    //         } else {
    //             allOutlets = (self.outlets.length === 1) || (modulus === self.outlets.length -1);
    //             outletsDeclensionSingle = (modulus === 1);
    //             outletsDeclensionPlural = (modulus > 1 && modulus < 5);
    //         }

    //         switch(true) {
    //             case (outletCount):
    //                 self.name = self.outlets[0].outletName;
    //                 self.outlets[0]._select = true;

    //                 break;

    //             case (outletsDeclensionSingle):
    //                 var singleName;
    //                 self.outlets.forEach(function(val){
    //                     if(val.outletId == $stateParams.outletsIds){
    //                         singleName =  val.outletName;
    //                     }
    //                 });
    //                 outletsDeclension = translate("MULTISHOP.REPORTS.SINGLE_OUTLET");
    //                 self.name = singleName || (outletsQuantity + " " + outletsDeclension);

    //                 break;

    //             case (outletsDeclensionPlural):
    //                 outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_1");
    //                 self.name = outletsQuantity + " " + outletsDeclension;

    //                 break;

    //             case (outletsDeclensionPlural2):
    //                 outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_2");
    //                 self.name = outletsQuantity + " " + outletsDeclension;

    //                 break;

    //             default:
    //                 outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_2");
    //                 self.name = outletsQuantity + " " + outletsDeclension;

    //         }
    //         return outletsDeclension;
    //     }
    // }

}

export default {
    type: 'controller',
    name: 'reportTimeClockRecordController',
    value: reportTimeClockRecordController
};
