/**
 * Created by mars on 12/25/15.
 */

createDirective.$inject = ['$translate', '$rootScope', '$filter'];
function createDirective( $translate , $rootScope, $filter) {
	return {
		restrict: 'E',
		require: '?^mdSelect',
		link: function ( scope, el, attr, contrl ) {
			if ( contrl &&  !attr.class ) {

				$rootScope.$on('$translateChangeSuccess', function(e, data){
					var innerHTML  =  el[0].innerHTML.replace(/^\s*/,'' ).replace(/\s*$/,'');
					if($filter('translate')(innerHTML)){
						el[0].innerHTML = $filter('translate')(innerHTML)
					}
				})
			}
		}
	}
}

export default {
	type: 'directive',
	name: 'span',
	value: createDirective
};