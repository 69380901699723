/**
 * @memberof toggleClass
 * @ngdoc directive
 * @name toggleClass
 * @requires setChartPeriod {service} Set chart label for different periods
 * @requires $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 */

inDevelop.$inject = [];
function inDevelop() {

	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			$(element).hover(function () {
				// on mouseenter
				$(element).tooltip('show');
			}, function () {
				// on mouseleave
				$(element).tooltip('hide');
			});
		}
	};
}

export default {
	type: 'directive',
	name: 'inDevelop',
	value: inDevelop
};