/**
 * Created by mars on 6/21/16.
 */

const buildConstant = {
	env: 'prod-world',
	translateEmptyValue: function(translationID){
		switch (true){
			case /GROUP_TYPE_OWNER/.test(translationID):
			case /LANG_/.test(translationID):
				return translationID;
			default :
				return ' ';
		}
	},
	getTileLayer: function(local){
		return 'https://mt0.googleapis.com/vt/lyrs=m@207000000&hl='+local+'&src=api&x={x}&y={y}&z={z}&s=Galile';
	},
	demandModules: [
		// { // Moved to webpack
		// 	name: 'imgMaintenance',
		// 	script: '../module-dynamic/img-maintenance/img-maintenance.min.js'
		//
		// },
		{
			name: 'hopscotch',
			script: '../module-dynamic/hopscotch-guide/_init.js'
		},
		{
			name: 'introguide',
			script: '../module-dynamic/intro-guide/_init.js'
		},
		{
			name: 'ngDraggable',
			script: '../module-dynamic/ng-draggable/ngDraggable.js'
		},
		{
			name: 'lv.timeZone',
			script: '../module-dynamic/time-zone/lv-time-zone.js'
		}
	]
};

export default {
	type: 'constant',
	name: 'buildConstant',
	value: buildConstant
};