import angular from "angular";

function serviceReports() {

    // Forming object to get Unlimited Sales History data in reports
    this.getSalesHistoryData = function (profile, billingData, permits) {

        let billingDataCopy = angular.copy(billingData);
        billingDataCopy.gateway = profile.billingInfo.gw;
        billingDataCopy.ownerId = profile.ownerId;

        return {
            billingData: billingDataCopy,
            hasBillingAccess: permits.hasBillingAccess,
            enabled: profile.billingInfo.salesHistoryEnabled
        }
    }

    this.getMerchantsList = function (outlets) {
        let outletsList = outlets;
        let merchantsList = [];
        let merchantsInOutlet = {};
        for (let i = 0; i < outletsList.length; i++) {
            merchantsInOutlet = outletsList[i].merchants;
            for (let l = 0; l < merchantsInOutlet.length; l++) {
                merchantsList.push(merchantsInOutlet[l]);
            }

        }
        return merchantsList;
    }
}

export default {
    type: 'service',
    name: 'serviceReports',
    value: serviceReports
}
