/**
 * Created by Oleksii Shestakov on 3.01.19.
 */

const strGwConst = [
    "streu",
    "struk"
];

export default {
    type: 'constant',
    name: 'strGwConst',
    value: strGwConst
};
