/**
 * Created by sirosh on 02.06.16.
 */

optionNameError.$inject = [];

function optionNameError() {

    return {
        restrict: 'A',
        link: linkFn,
        require: '^ngModel'
    };

    function linkFn(scope, element, attr, controller) {

        element.on('blur', function () {

            var value = $(element).val();

            var obj = JSON.parse(attr.options);

            for (var i = 0, len = obj.length; i < len; i++) {
                if (parseInt(attr.index) !== i && obj[i].name.toLowerCase() === value.toLowerCase()) {
                    controller.$setValidity('name', false);
                    controller.setAlertMessage('ERR_CREATE_OPTION');
                    break;
                } else if (obj[i].name.toLowerCase() !== value.toLowerCase()) {
                    controller.$setValidity('name', true);
                    controller.$invalid = false;
                    controller.$error = {};
                    controller.err = {};
                    controller.$valid = true;
                    $(element).parent().removeClass('has-error');
                    $(element).removeClass('ng-invalid ng-invalid-name');
                }
            }
        });
    }
}

export default {
    type: 'directive',
    name: 'optionNameError',
    value: optionNameError
};