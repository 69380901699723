/**
 * Created by Kiskenbassker on 20.02.18
 */

import dialogAddByTemplate from '../templates-pug/dialog/inventory/dialog-add-by.pug'

addItemsBy.$inject = ['serviceDialog', '$filter'];

function addItemsBy(serviceDialog, $filter) {

    var translate = $filter('translate');

    var dataByCategory = {};

    this.addByCategory = function (categories, items, categoriesSelected, outletId, factoryCallback, itemObjCallback) {
        itemsCountBy(categories, items, 'categoryId');
        serviceDialog.add({
            template: dialogAddByTemplate,
            title: translate('INVENTORY.COUNT.ADD_CATEGORY'),
            parents: categories,
            parentsSelected: categoriesSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_CATEGORIES'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        categories.forEach(function (item) {
                            item._checked = false;
                        });
                        categoriesSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(categoriesSelected.length > 0) {
                            var categoriesParams = {
                                outletId: outletId,
                                categoryIds: categoriesSelected
                            };
                            factoryCallback(categoriesParams).then(function (data) {
                                if(data.result === "ok") {
                                    dataByCategory = callbackAddItemsBy(data, categories, categoriesSelected, items, itemObjCallback);
                                }
                            })
                        }
                    }
                }
            ]
        });
    };

    var dataBySupplier = {};

    this.addBySupplier = function (suppliers, items, suppliersSelected, outletId, factoryCallback, itemObjCallback) {
        itemsCountBy(suppliers, items, 'supplierId');
        serviceDialog.add({
            template: dialogAddByTemplate,
            title: translate('INVENTORY.COUNT.ADD_SUPPLIER'),
            parents: suppliers,
            parentsSelected: suppliersSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_SUPPLIERS'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        suppliers.forEach(function (item) {
                            item._checked = false;
                        });
                        suppliersSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(suppliersSelected.length > 0) {
                            var suppliersParams = {
                                outletId: outletId,
                                supplierIds: suppliersSelected
                            };
                            factoryCallback(suppliersParams).then(function (data) {
                                if(data.result === "ok") {
                                    dataBySupplier = callbackAddItemsBy(data, suppliers, suppliersSelected, items, itemObjCallback);
                                }
                            })
                        }
                    }
                }
            ]
        });
    };

    this.dataByCategory = dataByCategory;
    this.dataBySupplier = dataBySupplier;

    function callbackAddItemsBy (data, parents, parentsSelected, items, itemObjCallback) {
        var itemsBy = [];
        data.items.forEach(
            function (item) {
                var itemToAdd;
                var itemsLength = items.length;
                if(itemsLength > 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (items[i].wareId === item.id) {
                            itemToAdd = false;
                            break;
                        } else if (i === itemsLength - 1) {
                            itemToAdd = true;
                        }
                    }
                } else {
                    itemToAdd = true;
                }
                if(itemToAdd) {
                    var itemObj = itemObjCallback(item);
                    itemsBy.push(itemObj);
                }
            }
        );
        items = items.concat(itemsBy);
        parents.forEach(function (item) {
            item._checked = false;
        });
        parentsSelected = [];
        return {
            items: items,
            parents: parents,
            parentsSelected: parentsSelected
        };
    }

    function checkItemsList (item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) {
            list.splice(idx, 1);
        }
        else {
            list.push(item);
        }
    }

    /* Function for counting items in count by category or by supplier and comparing them with total amount of supplier's or category's items */

    function itemsCountBy(arrayObj1, arrayObj2, field) {
        var count = 0;
        arrayObj1.forEach(function (obj1) {
            count = 0;
            arrayObj2.forEach(function (obj2) {
                if(obj1.id === obj2[field]) {
                    count += 1;
                }
            });
            obj1._allAdded = (count === obj1.waresAmount);
        })
    }
}

export default {
    type: 'service',
    name: 'addItemsBy',
    value: addItemsBy
};