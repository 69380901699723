import angular from 'angular';

accountInfo.$inject = [
    'promiseOperation'
];

function accountInfo(promiseOperation) {


    function getAccountInfo(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getAccountInfo',
                params,
                successGetAccountInfo
            );
    }

    function successGetAccountInfo(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function checkPassword(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/checkPassword',
                params,
                successCheckPassword
            );
    }

    function successCheckPassword(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function changeOwnerEmail(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/changeOwnerEmail',
                params,
                successChangeOwnerEmail
            );
    }

    function successChangeOwnerEmail(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function changeOwnerPassword(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/changeOwnerPassword',
                params,
                successChangeOwnerPassword
            );
    }

    function successChangeOwnerPassword(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function setAccountInfo(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/setAccountInfo',
                params,
                successSetAccountInfo,
                errorSetAccountInfo
            );
    }

    function successSetAccountInfo(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
        return response;
    }

    function errorSetAccountInfo(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
        return response;
    }

    function deleteAccount(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/deleteAccount',
                params,
                successDeleteAccount
            );
    }

    function successDeleteAccount(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getCurrencies(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getCurrencies',
                params,
                successGetCurrencies
            );
    }

    function successGetCurrencies(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function checkEmailName(params){
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/checkEmailName',
                params,
                successCheckEmailName
            );
    }

    function successCheckEmailName(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    return {
        getAccountInfo: getAccountInfo,
        checkPassword: checkPassword,
        changeOwnerEmail: changeOwnerEmail,
        changeOwnerPassword: changeOwnerPassword,
        setAccountInfo: setAccountInfo,
        deleteAccount: deleteAccount,
        getCurrencies: getCurrencies,
        checkEmailName: checkEmailName
    }

}

export default {
    type: 'factory',
    name: 'accountInfo',
    value: accountInfo
};
