/**
 * Created by mars on 11/9/15.
 */

factoryExit.$inject = ['$http', '$q', 'factoryErrorAlert'];
function factoryExit($http, $q, factoryErrorAlert) {
	return function(){
		return $q( function ( resolve, regect ) {
			$http.get( '/data/ownercab/logout' ).then( function ( d ) {
				resolve( d.data )
			}, function ( err ) {
				regect( err );
				factoryErrorAlert.onError( err )
			} )
		} )
	} ;
}

export default {
	type: 'factory',
	name: 'factoryExit',
	value: factoryExit
};