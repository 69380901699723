/**
 * Created by mars on 11/19/15.
 */

import angular from 'angular';

lvSearch1.$inject = [
	'$rootScope',
	'$filter'
];
function lvSearch1(
	$rootScope,
	$filter
) {

	return {
		restrict: 'A',
		template: '<lvsearch></lvsearch>',
		scope: {
			lvSearch: '=',
			returnFromSearch: '=',
			showBtnDel: '=',
			lvSearchModel: '=',
			lvSearchOnAddFunction: '=',
			lvSearchOnDelFunction: '=',
			lvSearchHideInclude: '@',
			lvSearchPlaceholder: '@',
			lvSearchShortOutlets: '=',
			stateForReturn: '=',
			lvButtonsStates: '<'
		},
		replace: true,
		transclude: true,
		controllerAs: 'lvSearchCntrl',
		controller: lvSearch1Contrl
	};
}
lvSearch1Contrl.$inject = [
	'$scope',
	'$rootScope',
	'$compile',
	'$stateParams',
	'$state',
	'factoryCategories',
	'$cookies',
	'$timeout',
	'$translate',
	'$window',
	'$mdMenu',
	'lvMixpanel'
];
function lvSearch1Contrl(
	scope,
	$rootScope,
	$compile,
	$stateParams,
	$state,
	factoryCategories,
	$cookies,
	$timeout,
	$translate,
	$window,
	$mdMenu,
	lvMixpanel
) {

	const templateHide =
		'<div class="search-block-hide" ng-class="unshadow"  ng-include="lvSearchHideInclude"></div>';

	const templateShow = '';

	scope.permits = $rootScope.permits;
	scope.inventoryEnabled = $rootScope.inventoryEnabled;
	// данные для сохранение состояния фильтров
	scope.stateFromList = $state.params;

	let lvSearchCntrl = this;
	let outletId = ($stateParams.outletId && typeof(parseInt($stateParams.outletId, 10)) === 'number') ? $stateParams.outletId : "null";

	lvSearchCntrl.$postLink = $postLink;

	lvSearchCntrl.onAdd = scope.lvSearchOnAddFunction;
	lvSearchCntrl.onDel = scope.lvSearchOnDelFunction;
	lvSearchCntrl.inventory = ($stateParams.inventory) ? $stateParams.inventory : 'all';
	lvSearchCntrl.outletId = (outletId) ? outletId : null;
	lvSearchCntrl.category = ($stateParams.category) ? $stateParams.category : null;
	lvSearchCntrl.inventoryList = [
		{id: 'all', name: $translate.instant('RESIDUE_FILTER.all')},
		{id: 'low', name: $translate.instant('RESIDUE_FILTER.low')},
		{id: 'out', name: $translate.instant('RESIDUE_FILTER.out')},
	];
	lvSearchCntrl.hideDotsSubmenu = hideDotsSubmenu

	lvSearchCntrl.selectInventory = function(val) {
		lvMixpanel.track('Filter', { "Property": "Item list/Filter by stock alert" });
		$state.go($state.current.name, { inventory: val, page: 0 });
	};

	// все для фильтра по заведениям, АНАЛОГИЯ С КАТЕГОЯМИ
	lvSearchCntrl.stateP = $stateParams;
	lvSearchCntrl.outlets = angular.copy(scope.lvSearchShortOutlets);
	lvSearchCntrl.outlets.unshift(
		{id:'all', name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')}
	);

	scope.searchundefined = ($state.params.search === undefined) ? true : (outletId === 'null');


	lvSearchCntrl.outletsSelect = (outletId !== undefined && outletId !== 'null') ?
		(outletId === 'all' ? outletId : +outletId) : 'all';

	lvSearchCntrl.outletsOnChange = function(val) {
		lvMixpanel.track('Filter', { "Property": "Item list/Filter by store" });
		$state.go($state.current.name, { outletId: val, page: 0 });
	};
	// end все для фильтра по заведениям, АНАЛОГИЯ С КАТЕГОЯМИ

	lvSearchCntrl.categoriesSelect = $stateParams.category !== undefined ? +$stateParams.category : null;

	lvSearchCntrl.categoriesOnChange = function(val) {
		lvMixpanel.track('Filter', { "Property": "Item list/Filter by category" });
		$state.go($state.current.name, { category: val, page: 0 });
	};

	lvSearchCntrl.getSelectInventoryText = function(value) {
		return $filter('translate')('RESIDUE_FILTER.' + value);
	};

	lvSearchCntrl.getSelectCategoryText = function(val) {
		switch (val) {
			case null:
				return $filter('translate')('ALL_GOODS');
			case "-1":
				return $filter('translate')('WITHOUT_CATEGORY');
			default:
				if (!lvSearchCntrl.categories) {
					return null;
				} else {
					var t = (function() {
						for (var i = 0; i < lvSearchCntrl.categories.length; i++) {
							if (lvSearchCntrl.categories[i].id === val) {
								return lvSearchCntrl.categories[i].name;
							}
						}
					}());
					return t;
				}
		}
	};

	lvSearchCntrl.onOpenSelect = function() {
		//todo
		/*var els = document.getElementsByClassName("selector-inventory");
        var arr = Array.prototype.slice.call(els);
        var el;
        for(var i = 0 ; i< arr.length; i++){
            (function(i){
                setTimeout(function(){
                    if(arr[i].style.display==='block'){
                        el = arr[i];
                        console.info(arr[i].style.display, arr[i]);
                    }
                },100);
            }(i));
        }*/
	};

	lvSearchCntrl.onPrintLabels = function() {
		if (scope.inventoryEnabled) {
			$state.go('goods.printlabels', { stateToSave: scope.stateFromList });
		}
	};

	function hideDotsSubmenu(){
		$mdMenu.hide()
	}

	// SelectController.toggleDropdown = toggleDropdown;


	angular.element($window).on('orientationchange', function() {
		hideDotsSubmenu()
		console.log('hideDotsSubmenu ')
	});


	factoryCategories.getCategories().then(function(categories) {
		lvSearchCntrl.categories = angular.copy(categories);
		lvSearchCntrl.categories.unshift(
			{ id: null, name: $translate.instant('ALL_GOODS')},
			{ id: -1, name: $translate.instant('WITHOUT_CATEGORY')}
		);

	});

	this.elSet = function(el) {
		var contentShow, contentHide;
		contentShow = $compile(templateShow)(scope);
		contentHide = $compile(templateHide)(scope);
		el.append(contentHide);
		el.append(contentShow);
	};

	// export of wares
	scope.href = function() {
		var category = $stateParams.category || "all";
		var outlet = outletId || "all";
		var inventory = $stateParams.inventory || "all";
		location.href = '/data/ownercab/exportwaresfile?outletId=' + outlet + '&category=' + category + '&inventory=' + inventory;
		return location.href;
	};

	lvSearchCntrl.stateReturn = scope.stateForReturn;

	scope.onDel = function() {
		scope.$parent.onDel();
	};

	scope.onAdd = function() {
		scope.$parent.onAdd();
	};

	lvSearchCntrl.exportGoodsMixpanel = function() {
		lvMixpanel.track('Export', { "Property": "Items/Export item list" });
	}

	function $postLink() {
		const buttonsStatesDefault = {
			del: {
				disabled: false
			}
		};
		lvSearchCntrl.buttonsStates = angular.extend(buttonsStatesDefault, scope.lvButtonsStates || {});
	}
}

lvSearch2.$inject = ['$stateParams'];
function lvSearch2($stateParams) {
	return {
		restrict: 'E',
		require: '^lvSearch',
		link: function(scope, el, attr, contrl) {
			contrl.elSet(el);
		}
	};
}

export default [
	{
		type: 'directive',
		name: 'lvSearch',
		value: lvSearch1
	},
	{
		type: 'directive',
		name: 'lvsearch',
		value: lvSearch2
	}
];
