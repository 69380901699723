/**
 * Created by Oleksii Nikitin on 03/09/23.
 */

localizationUtil.$inject = ['$filter'];
function localizationUtil($filter) {

    this.getCityLabel = getCityLabel;
    this.getProvinceLabel = getProvinceLabel;
    this.getPostalCodeLabel = getPostalCodeLabel;

    let translate = $filter('translate');

    function getCityLabel(countryCode) {
        let citylabel;
        switch (countryCode){
            case "au":
                citylabel = translate("CLIENTS.SUBURB");
                break;
            case "hk":
                citylabel = translate("CLIENTS.DISTRICT");
                break;
            case "gb":
                citylabel = translate("CLIENTS.TOWNCITY");
                break;
            default:
                citylabel = translate("CLIENTS.CITY");
        }
        return citylabel;
    }

    function getProvinceLabel(countryCode) {
        let provinceCountries = ["be", "ca", "do", "id", "it", "nl", "ph", "pl", "sa", "za", "kr", "es", "th", "vn"];
        let stateCountries = ["au", "at", "br", "in", "de", "my", "mx", "ng", "us", "ve"];
        let countyCountries = ["ie", "tt", "gb"];

        let provincelabel;
        if(provinceCountries.includes(countryCode)){
            provincelabel = translate("CLIENTS.PROVINCE");
        } else if(stateCountries.includes(countryCode)){
            provincelabel = translate("CLIENTS.STATE");
        } else if( countyCountries.includes(countryCode)) {
            provincelabel = translate("CLIENTS.COUNTY");
        } else {
            switch (countryCode){
                case "ru":
                    provincelabel = translate("CLIENTS.SUBFEDERATION");
                    break;
                case "jp":
                    provincelabel = translate("CLIENTS.PREFECTURE");
                    break;
                case "ro":
                    provincelabel = translate("CLIENTS.JUDET");
                    break;
                case "se":
                    provincelabel = translate("CLIENTS.LAN");
                    break;
                case "ch":
                    provincelabel = translate("CLIENTS.CANTON");
                    break;
                case "co":
                    provincelabel = translate("CLIENTS.DEPARTMENT");
                    break;
                case "ae":
                    provincelabel = translate("CLIENTS.EMIRATE");
                    break;
                default:
                    provincelabel = translate("CLIENTS.REGION");
            }
        }
        return provincelabel;
    }

    function getPostalCodeLabel(countryCode) {
        let postallabel;
        switch (countryCode){
            case "au":
            case "gb":
                postallabel = translate("CLIENTS.POSTCODE");
                break;
            case "us":
                postallabel = translate("CLIENTS.ZIPCODE");
                break;
            default :
                postallabel = translate("CLIENTS.POSTALCODE");
        }
        return postallabel;
    }

}

export default {
    type: 'service',
    name: 'localizationUtil',
    value: localizationUtil
};