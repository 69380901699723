/**
 * Created by galkina 5.05.2022.
 */

purchaseItemsImportControl.$inject = [
    'FileUploader',
    '$state',
    '$stateParams',
    'stateGo',
    '$filter',
    'serviceDialog',
    'clientsDB',
    'serviceToast',
    '$translate',
    '$timeout',
    '$profile',
    'siteLangCode',
    'lvMixpanel'
];

function purchaseItemsImportControl(
                     FileUploader,
                     $state,
                     $stateParams,
                     stateGo,
                     $filter,
                     serviceDialog,
                     clientsDB,
                     serviceToast,
                     $translate,
                     $timeout,
                     $profile,
                     siteLangCode,
                     lvMixpanel) {

    var model = this;
    model.previousState = stateGo.getPreviousStateAlias();

    if(!model.previousState) {
        return $state.go( $stateParams.state, {
            id: $stateParams.id ? $stateParams.id : null
        }, { reload: true } );
    }

    var translate = $filter( 'translate' );
    var outletId = ($stateParams.outletId) ? $stateParams.outletId : null;
    var langCode = siteLangCode[$profile.cabinetLang];
    var plugProductions = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];

    model.helpLink = plugProductions.indexOf(langCode) !== -1
         ? "https://help.loyverse.com/" + langCode + "/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders%20suppliers"
         : "https://help.loyverse.com/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders%20suppliers";

    model.title = translate('INVENTORY.IMPORT.PURCHASE_TITLE');
    model.fillInData = translate('INVENTORY.IMPORT.FILLED_WITH_DATA');
    model.expTemplate = '/data/ownercab/exporttemplatepurchaseorder';
    var addedItems = [];

    model.willBeCreated = {
        one: 'INVENTORY.IMPORT.WILL_BE_ADDED_ONE',
        first: 'INVENTORY.IMPORT.WILL_BE_ADDED_ONE',
        second: 'INVENTORY.IMPORT.WILL_BE_ADDED_TWO',
        third: 'INVENTORY.IMPORT.WILL_BE_ADDED_FEW'
    };



    var includeTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/download-start.html',
        onAttach: 'dashboard-page/templates/import-entity/include/on-attach.html',
        onError: 'dashboard-page/templates/import-entity/include/download-error.html',
        formatError: 'dashboard-page/templates/import-entity/include/format-error.html',
        onLoading: 'dashboard-page/templates/import-entity/include/preloader.html',
        onParserSuccess: 'dashboard-page/templates/inventory/purchase/include/parser-success.html', //не переносить в отдельную фабрику
        onParserError: 'dashboard-page/templates/inventory/purchase/include/parser-error.html', // не переносить в отдельную фабрику
        badEncoding: 'dashboard-page/templates/import-entity/include/bad-encoding.html',
        onConfirmLoading: 'dashboard-page/templates/import-entity/include/confirm-preloader.html'
    };

    var includeHeadTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/head-start.html',
        onSuccess: 'dashboard-page/templates/import-entity/include/head-success.html',
        onParserError: 'dashboard-page/templates/import-entity/include/head-parser-error.html'
    };

    var primaryAction = {
        onStart: function () {
            model.response = null;
            model.includeTemplate = includeTemplate.onStart;
            model.includeHeadTemplate = includeHeadTemplate.onStart;
            model.primaryButtonText = 'DOWNLOAD';
            model.primaryAction = primaryAction.onStart;
            model.onCancel = cancelAction.onStart;
            model.primaryButtonDisabled = true;
        },
        onAttach: function () {
            if ( !checkFileNameValid() ) {
                uploader.removeFromQueue( 0 );
                model.includeTemplate = includeTemplate.formatError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
            } else if ( !checkFileSize() ) {
                uploader.removeFromQueue( 0 );
                model.includeTemplate = includeTemplate.onError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
                model.errorText = {
                    code: "INVENTORY.IMPORT.LARGER_THAN_FIVE_MB"
                };
            } else {
                model.includeTemplate = includeTemplate.onLoading;
                uploader.uploadAll();
                model.primaryButtonDisabled = true;
            }

        },
        onUpdateGoods: function () {
            model.includeTemplate = includeTemplate.onConfirmLoading;
            model.primaryButtonDisabled = true;
            $state.go( model.previousState, {
                id: $stateParams.id ? $stateParams.id : null,
                outletId: ($stateParams.outletId) ? $stateParams.outletId : null,
                purchaseDocData: ($stateParams.purchaseDocData) ? $stateParams.purchaseDocData : {},
                newItems: addedItems
            } );
        }
    };

    var cancelAction = {
        onStart: function () {
            $state.go( model.previousState, {
                id: $stateParams.id ? $stateParams.id : null,
                outletId: ($stateParams.outletId) ? $stateParams.outletId : null,
                purchaseDocData: ($stateParams.purchaseDocData) ? $stateParams.purchaseDocData : {}
            } );
        }
    };

    primaryAction.onStart();

    var uploader = this.uploader = new FileUploader( {
        url: '/data/ownercab/validatepofile',
        outletId: (outletId ? outletId : null),
        queueLimit: 1
    } );

    uploader.onAfterAddingFile = function ( fileItem ) {
        var addedFile = fileItem._file;
        model.includeTemplate = includeTemplate.onAttach;
        model.primaryButtonDisabled = false;
        model.primaryAction = primaryAction.onAttach;
        model.onAttachFileName = addedFile.name;
        model.fileSize = addedFile.size;
        model.outletId = outletId;

    };
    uploader.onAfterAddingAll = function ( addedFileItems ) {
        if ( addedFileItems.length ) {
            model.primaryButtonDisabled = false;
            model.primaryButtonText = 'DOWNLOAD'
        }

    };
    uploader.onBeforeUploadItem = function ( item ) {
        uploader.formData = [{outletId: outletId}];
        Array.prototype.push.apply(item.formData, uploader.formData);
    };

    uploader.onSuccessItem = function ( fileItem, response ) {

        var res = null;
        try {
            res = JSON.parse( response );
        } catch ( err ) {
            console.error( err );
        }
        if ( !res ) {
            return;
        }

        if ( res.result === 'error' ) {
            wrongFileFormat();
        } else if (res.error) {
            switch ( res.error ) {
                case "wrong_header":
                    errorIncludeData("INVENTORY.IMPORT.INVALID_COLUMN_FORMAT");
                    break;
                case "limit_5mb":
                    errorIncludeData("INVENTORY.IMPORT.LARGER_THAN_FIVE_MB");
                    break;
                case "limit_10000":
                    errorIncludeData("INVENTORY.IMPORT.MORE_THAN_10000");
                    break;
                default:
                    wrongFileFormat();
            }
        } else if (!res.canProcess) {
            downloadError( res );
        } else {
            downloadSuccess( res );
        }


        model.primaryButtonDisabled = false;

    };


    //console.info( 'uploader', uploader );

    function errorIncludeData(errorText) {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.onCancel = cancelAction.onStart;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: errorText
        };

    }


    function downloadSuccess( res ) {

        model.includeTemplate = includeTemplate.onParserSuccess;
        model.includeHeadTemplate = includeHeadTemplate.onSuccess;
        model.confirmText = { code: 'INVENTORY.IMPORT.PLEASE_CONFIRM_LIST_CHANGES'};
        var i = 0;
        while ( i < res.warnings.length ) {

            res.warnings[i]._error = translate( 'VALIDATE.ERROR.' + res.warnings[i].columnError, { name: res.warnings[i].columnName})

            i++;
        }
        i = null;


        model.response = res;
        model.primaryButtonText = 'IMPORT_ENTITY.REFRESH_DATA';
        model.onCancel = cancelAction.onStart;
        model.primaryAction = primaryAction.onUpdateGoods;

        addedItems = res.items;

        successImportPurchaseOrderForMixpanel(res);
    }

    function wrongFileFormat() {
        serviceDialog.add( {
            templateUrl: 'dashboard-page/templates/import-entity/include/dialog-list-imported-data-update-error.html',
            mainText: "IMPORT_FAILED_TEXT_4",
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        $state.go( $state.current.name, { outletId: $stateParams.outletId,
                            purchaseDocData: $stateParams.purchaseDocData}, {reload: true} );
                    }
                }
            ]
        } );
    }

    function downloadError( res ) {

        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onParserError;
        model.includeHeadTemplate = includeHeadTemplate.onParserError;
        var i = 0;
        while ( i < res.errorsList.length ) {
            res.errorsList[i]._error = translate( 'ERROR.' + res.errorsList[i].columnError, {name: '"' + res.errorsList[i].columnName + '"' } )
            i++
        }

        i = null;
        model.response = res;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;

        errorImportClientsForMixpanel(res);
    }

    function checkFileSize() {
        return 5 * 1000 * 1000 > model.fileSize;

    }


    function checkFileNameValid() {
        return /\.csv$/.test(model.onAttachFileName);

    }


//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ
    function errorImportClientsForMixpanel(resError) {
        if ((resError.result === "ok" && resError.error != null) || (resError.result === "ok" && resError.error == null && resError.errorsList.length > 0)) {

            lvMixpanel.track('Import', {  "Event type"        : "Import purchase order",
                                        "Event result"     : "Purchase order import error",
                                        "Number of errors purchase order"  : resError.errorsList.length});
        }
    }

    function successImportPurchaseOrderForMixpanel(resSuccess) {
        if (resSuccess.result === "ok" && resSuccess.error == null && resSuccess.errorsList.length === 0) {
            // console.log("successImportClientsForMixpanel, resSuccess = ", resSuccess);
            lvMixpanel.track('Import', {
                "Event type": "Import purchase order",
                "Event result": "Purchase order imported",
                "Number of items purchase order": resSuccess.items.length,
                "Number of warings purchase order": resSuccess.warnings.length
            });
        }

    }

    model.importHelpForMixpanel = function() {
        lvMixpanel.track('Help', {'Property': 'Import purchase order'});
    }
//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ



}

export default {
    type: 'controller',
    name: 'purchaseItemsImportControl',
    value: purchaseItemsImportControl
};
