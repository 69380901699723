/**
 * Created by mars on 9/8/15.
 * Edited by kseniya.yarosh on 13.12.2016
 * @ngdoc service
 * @name factoryReportGoods
 */

factoryReportGoods.$inject = [
		'$http',
		'$rootScope',
		'$stateParams',
		'factoryErrorAlert',
		'factoryProfile',
		'serviceColor',
		'resolveTimezone',
		'$filter',
		'groupBy',
		'$q',
		'$translate',
		'getChartLabel'];
function factoryReportGoods(
			$http,
		   $rootScope,
		   $stateParams,
		   factoryErrorAlert,
		   factoryProfile,
		   serviceColor,
		   resolveTimezone,
		   $filter,
		   groupBy,
		   $q,
		   $translate,
		   getChartLabel) {

	var filterDate = $filter( 'formatGoodsChart' );
	var coinToCurrency = $filter('coinToCurrency');
	var promise;
	var report = {
		_loading: false,
		_top5: true
	};
	var reqData;
	var resData;

	var sort = {
		by: 'profit'
	};

	/**
	 * {string} charType  'pie' || 'line' || 'bar'
	 */
	var charType;
	var showWare = {};

	/**
	 *
	 * @type {{from: {int}, to: {int}}}
	 */
	var period = {
		from: null,
		to: null
	};

	var serviceColors = [
		'#78909c',
		'#9ccc65',
		'#42a5f5',
		'#ec407a',
		'#ffee58'
	];

	function setPeriod() {
		var dTo = new Date( reqData.endDate );

		var dateTo = new Date( dTo.getFullYear(), dTo.getMonth(), dTo.getDate() + 1 );
		var dFrom = new Date( reqData.startDate );
		var dateFrom = dFrom;
		period.to = dateTo.getTime();
		period.from = dateFrom.getTime();
	}

	function setServiceColor( arr ) {
		var length = arr.length;

		for(var i = 0; i < length; i++) {
			if(arr[i]) {
				arr[i]._color = serviceColors[i];
			}
		}
	}

	function formatPeriodWare( obj ) {
		var periodsByWare = obj.periodsByWare;
		for ( var i = 0; i < periodsByWare.length; i++ ) {
			periodsByWare[i]._from = $filter( 'formatDateByBrowser' )( periodsByWare[i].from, 'tableLabelAndTime' );
			periodsByWare[i]._to = $filter( 'formatDateByBrowser' )( periodsByWare[i].to, 'tableLabelAndTime' );
		}
	}

	function formatPeriod( periodsByWare ) {
		for ( var i = 0; i < periodsByWare.length; i++ ) {
			formatPeriodWare( periodsByWare[i] )
		}

	}

	function getColors( arr ) {
		var colors = [];
		var periodsByWare = arr;
		var findColor = function ( id ) {
			var i = report._wares.length;
			while ( 0 < i-- ) {
				if ( report._wares[i].id == id ) {
					return report._wares[i]._color
				}
			}
			return "#000"
		};
		for ( var i = 0; i < periodsByWare.length; i++ ) {
			var id = periodsByWare[i].wareId;
			var color = findColor( id );
			colors.push( color )
		}
		colors.push( "#ebebeb" );
		return colors;
	}

	function removeUnnecessary( wares, periodsByWare ) {
		var _periodsByWare = [];

		function find( wareId ) {
			for ( var i = 0; i < periodsByWare.length; i++ ) {
				if ( periodsByWare[i].wareId == wareId ) {
					return periodsByWare[i];
				}
			}
		}


		for ( var i = 0; i < wares.length; i++ ) {
			_periodsByWare.push( find( wares[i].id ) )
		}

		return _periodsByWare;


	}

	function getCategories(data) {
		var periods = data.periodsByWare[0].periodsByWare;
		return periods.map(function (item) {
			var fromDate = item.fromString.split('T')[0].split('-');
			var toDate = item.toString.split('T')[0].split('-');
			var fromTime = item.fromString.split('T')[1].split(':');
			var toTime = item.toString.split('T')[1].split(':');
			var dateFrom = new Date(fromDate[0], fromDate[1] - 1, fromDate[2], fromTime[0], fromTime[1], 0).getTime();
			var dateTo = new Date(toDate[0], toDate[1] - 1, toDate[2], toTime[0], toTime[1], toTime[2]).getTime();
			return getChartLabel.parseDeliver(resData.data.divider, dateFrom, dateTo, $stateParams.from, $stateParams.to, "chart", false, $stateParams.fromHour, $stateParams.toHour);

		});
	}

	function getCategoriesForPie(top5) {
		var categoriesForPie = top5.map(function (item) {
			return item.name;
		});
		categoriesForPie.push($filter('translate')('OTHER'));

		return categoriesForPie;
	}

	function getDataForSerie(periodsByWare) {
		var dataForSerie = {};
		for(var i = 0, length = periodsByWare.length; i < length; i++) {
			var wareInTopFive = false;
			if(periodsByWare[i].wareId in resData.data._top5) {
				wareInTopFive = true;
			}
			if(wareInTopFive) {
				var dataForSerieValues = [];
				for(var j = 0, dataValuesLength = periodsByWare[i].periodsByWare.length; j < dataValuesLength; j++) {
					dataForSerieValues.push(parseFloat(coinToCurrency(periodsByWare[i].periodsByWare[j].netSales, ".")));
				}
				dataForSerie[periodsByWare[i].wareId] = dataForSerieValues;
			}
		}

		return dataForSerie;
	}

	function getDataForSeriePie(top5, total) {
		var dataForSeriePie = {};

		for(var i = 0, length = top5.length; i < length; i++) {
			dataForSeriePie[top5[i].id] = parseFloat(coinToCurrency(top5[i].earningSum, "."));
		}

		var top5TotalNetSales = top5.reduce(function (sum, current) {
			return sum + current.earningSum;
		}, 0);
		dataForSeriePie[0] = parseFloat(coinToCurrency(total.netSales - top5TotalNetSales, "."));

		return dataForSeriePie;

	}

	function onDraw(periodAllowed) {
		for ( var opt in resData.data ) {
			report[opt] = resData.data[opt]
		}

		if (periodAllowed) {
			report._wares = [];

			report._wares = report.top5;
			report._periodsByWare = removeUnnecessary(report.top5, report.periodsByWare);
			formatPeriod(report._periodsByWare);
			setServiceColor(report._wares);
			report._colors = getColors(report._periodsByWare);
			report._format = (function () {
				var i = groupBy.length;
				while (0 < i--) {
					if (groupBy[i].key == reqData.divider) {
						return groupBy[i].format
					}
				}
			}());
			report._categories = report.periodsByWare.length > 0 ? getCategories(report) : [];
			report._categoriesForPie = getCategoriesForPie(report.top5);
			report._dataForSerie = getDataForSerie(report.periodsByWare);
			report._dataForSeriePie = getDataForSeriePie(report.top5, report.total);

			/**
			 * Название таблицы
			 * @type {string}
			 * @private
			 */
			report._title = '';
		}
	}

	function success( d ) {
		resData = d;
		if (d.data.periodAllowed) {
			d.data._wares = {};
			var i = d.data.wares.length;
			while (0 < i--) {
				d.data._wares[d.data.wares[i].id] = d.data.wares[i]
			}
			d.data._top5 = {};
			i = d.data.top5.length;
			while (0 < i--) {
				d.data._top5[d.data.top5[i].id] = d.data.top5[i]
			}
		}

		onDraw(d.data.periodAllowed);
	}


	function clearReport() {
		for ( var opt in report ) {
			delete report[opt];
		}
	}


	/**
	 * @param stateParams {object}
	 * @returns {{}}
	 */
	var tz = jstz.determine();

	function getWaresReport( stateParams ) {
		promise = (!promise || promise.$$state.status === 1) ? $q((resolve, reject) => {
			let startWeek;
			if (Number.isInteger(stateParams.startWeek)) {
				startWeek = stateParams.startWeek;
			} else if (Number.isInteger($rootScope.startWeek)) {
				startWeek = $rootScope.startWeek;
			} else {
				startWeek = 0;
			}
			const data = {
				"ownerId": stateParams.id,
				"startDate": stateParams.from,
				"endDate": stateParams.to,
				"startWeek": startWeek,
				"tzOffset": resolveTimezone.timeZoneOffsetForReports(),
				"tzName": tz.name(),
				"startTime": (stateParams.fromHour === 0 && stateParams.toHour === 0) ? null : stateParams.fromHour,
				"endTime": (stateParams.fromHour === 0 && stateParams.toHour === 0) ? null : stateParams.toHour,
				"divider": stateParams.group,
				'merchantsIds': (stateParams.merchantsIds && stateParams.merchantsIds !== "clear" && stateParams.merchantsIds !== "all") ? stateParams.merchantsIds.split(',') : (stateParams.merchantsIds === "clear") ? [] : (stateParams.merchantsIds === "all") ? "all" : null,
				'outletsIds': (stateParams.outletsIds && stateParams.outletsIds !== "clear" && stateParams.outletsIds !== "all") ? stateParams.outletsIds.split(',') : (stateParams.outletsIds === "clear") ? [] : (stateParams.outletsIds === "all") ? "all" : null,
				"predefinedPeriod": stateParams.predefinedPeriod,
				"customPeriod": stateParams.customPeriod
			};

			reqData = data;
			setPeriod();
			report._loading = true;

			$http.post( '/data/ownercab/getwaresreport', data ).then(d => {
					charType = stateParams.chart;
					clearReport();
					success(d);
					report._loading = false;
					resolve(report);
				},
				err => {
					report._loading = false;
					reject(err);
				});
		}) : promise;

		return promise
	}

	function changeChart( chart ) {
		if ( !resData ) return;
		charType = chart;
		factoryProfile.getProfile().then( function () {
			onDraw(true);
		})
	}

	return {
		getWaresReport: getWaresReport,
		changeChart: changeChart,
		report: report
	};
}

export default {
	type: 'factory',
	name: 'factoryReportGoods',
	value: factoryReportGoods
};
