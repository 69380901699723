/**
 * Created by mars on 9/7/15.
 * Edited by Kseniya Yarosh on 6/9/17
 */

serviceOwnerInfo.$inject = [];
function serviceOwnerInfo() {
	this.lang = null;
	/**
	 * тип устройства превью
	 * @type {string}
	 */
	this.gadget = 'ios';
	// this.managerRole = function(boolean){
	// 	return boolean;
	// }

}

export default {
	type: 'service',
	name: 'serviceOwnerInfo',
	value: serviceOwnerInfo
};