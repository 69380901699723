datePickerSettings.$inject = [];

function datePickerSettings() {
     let params = {
         config: {
             datePickerLabelTemplate: "{{after | formatDateByBrowser: 'calendarLabel'}}"
         }
     };
    return params
}

export default datePickerSettings;