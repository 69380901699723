import angular from 'angular';

editDetailsController.$inject = ['$scope', '$state', '$stateParams', 'factoryBilling', '$billingDetails', 'serviceToast', 'dialogs'];

function editDetailsController($scope, $state, $stateParams, factoryBilling, $billingDetails, serviceToast, dialogs) {

    let initData;
    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {
        let currentData = {
            companyName: $scope.data.companyName,
            companyAddress: $scope.data.companyAddress
        };
        return !angular.equals(initData, currentData);
    }

    // блок инициализации данных
    $scope.billingDetails   = angular.copy($billingDetails);
    $scope.data             = {companyName:    $scope.billingDetails.companyName,
                               companyAddress: $scope.billingDetails.companyAddress};
    initData = {
        companyName: $scope.billingDetails.companyName,
        companyAddress: $scope.billingDetails.companyAddress
    };
    // console.log("$scope.data = ", $scope.data);
    // блок инициализации данных

    $scope.validateBusinessName = (value) => {
        const businessNameEmojis = /[\u{1F600}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F300}-\u{1F5FF}]/u;
        return !businessNameEmojis.test(value)
    }

    $scope.onCancel = function () {
        $state.go('settings.account', $stateParams, {} );
    };


    $scope.onSave = function () {
        factoryBilling.updateBillingDetails($scope.data).then(function (res) {
            if (res.result === "ok") {
                offStateChangeStart();
                serviceToast.show('ACCOUNT.BILLING_DETAILS_SUCCESS', 'success');
                $state.go('settings.account', $stateParams, {});
            } else {
                serviceToast.show('ACCOUNT.BILLING_DETAILS_ERROR', 'error');
            }
        })
    }

}

export default {
    type: 'controller',
    name: 'editDetailsController',
    value: editDetailsController
};