/**
 * Created by mars on 12/14/15.
 */

lvEditableValue.$inject = ['$filter'];
function lvEditableValue($filter) {
	return {
		scope: {
			lvEditableValue: '@', //редактируемое значение
			lvEditableFoo: '=', //функция запускаемая по нажатии Ок
			lvFreePrice: '=',
			lvEditableValueNew: '@',
			lvCurrencyOptionsEdit: '='
		},
		transclude: true,
		template: 	'<div class="inline-block width-100">' +
						'<div ng-if="model.freePrice == false", class="relative width-100 model-value nowrap" ng-click="setEdit(); $event.stopPropagation()">' +
							'<span  ng-bind="model.value | currencyFormatOnlyShow"></span>' +
						'</div>' +
						'<div ng-if="model.freePrice == true", class="relative width-100 model-value nowrap" ng-click="setEdit(); $event.stopPropagation()">' +
							'<span  ng-bind="model.value"></span>' +
						'</div>' +
						'<div class="absolute" lv-editable-container ng-if="isEdit" style="{{style}}" ng-click="$event.stopPropagation()"></div>' +
					'</div>',
		link: function (scope, el, attr) {

			scope.style = attr.style;

			if (scope.lvFreePrice) {
				scope.model = { value: scope.lvEditableValue,
								freePrice: scope.lvFreePrice};
			} else {
				var modelKey1 = scope.lvEditableValue.split( ":" )[0];
				var modelKey2 = scope.lvEditableValue.split( ":" )[1];
				var modelValue = scope.$parent[modelKey1][modelKey2];
				scope.model = { value: $filter( 'coinToCurrency' )( modelValue, '.' ),
								freePrice: false};

				scope.$parent.$watch(modelKey1+"."+modelKey2, function (val) {
					scope.model = { value: (val !== "" && val !== null) ? $filter( 'coinToCurrency' )( val, '.' ) : val, freePrice: false};
				})
			}

			scope.setEdit = function () {
				scope.isEdit = true;
			};
		},
		controller: lvEditableValueController
	};
}

lvEditableValueController.$inject = ['$scope', '$filter'];
function lvEditableValueController($scope, $filter) {

	var currencyToCoin = $filter( 'currencyToCoin' );

	this.setValue = function ( value ) {
		$scope.model.value = value;

		if ($scope.lvFreePrice) {
			$scope.$parent[$scope.lvEditableValueNew.split( ":" )[0]][$scope.lvEditableValueNew.split( ":" )[1]] = (value === "") ? "" : currencyToCoin( value );
		} else {
			$scope.$parent[$scope.lvEditableValue.split( ":" )[0]][$scope.lvEditableValue.split( ":" )[1]] = (value === "") ? "" : currencyToCoin( value );
		}

		if ($scope.lvFreePrice) {
			$scope.lvEditableFoo( $scope.$parent[$scope.lvEditableValueNew.split( ":" )[0]] );
		} else {
			$scope.lvEditableFoo( $scope.$parent[$scope.lvEditableValue.split( ":" )[0]] );
		}

		$scope.isEdit = false;
	};
	this.hide = function () {
		$scope.isEdit = false;
	};
}

lvEditableContainer.$inject = ['serviceMinMaxValues'];
function lvEditableContainer(serviceMinMaxValues) {
	return {
		template: 	'<div>' +
			'<form name="lvEditableContainerForm">' +
			'<table class="lv-editable-container">' +
			'<tr>' +
			'<td> <div class="btns btn-cancel" ng-click="onCancel(); $event.stopPropagation()"></div> </td>' +
			'<td> <div class="btns btn-ok" ng-click="onOk($event); $event.stopPropagation()"></div> </td>' +
			'<td>' +
			'<input type="text" name ="editValue" ng-model="model.editValue" currency-localization="lvCurrencyOptionsEdit" ng-click="$event.stopPropagation()">' +
			'</td>' +
			'</tr>' +
			'</table>' +
			'</form>' +
			'</div>',
		transclude: true,
		require: '^lvEditableValue',
		link: function ( scope, el, attr, cntrl ) {

			if (scope.model.hasOwnProperty("freePrice") && scope.model.freePrice === true) {
				scope.model.editValue = "";
			} else {
				scope.model.editValue = serviceMinMaxValues.getValidValue(scope.model.value, scope.lvCurrencyOptionsEdit);
			}

			scope.onOk = function () {
				if ( scope.lvEditableContainerForm.$invalid ) return;

				scope.model.freePrice = scope.model.editValue.length <= 0;

				cntrl.setValue( scope.model.editValue );
				scope.isEdit = false;
			};

			scope.onCancel = function () {
				cntrl.hide();
			};

			var els = el.find( '*' );

			function click( e ) {
				for ( var i = 0; i < els.length; i++ ) {
					if ( els[i] == e.target ) {
						return;
					}
				}
				scope.onCancel();
				scope.$parent.$digest();
			}

			if ( !el._listiner ) {
				el._listiner = click;
				setTimeout( function () {
					document.body.addEventListener( 'click', click );
				}, 1 );
			}

			scope.$on( '$destroy', function ( e ) {
				document.body.removeEventListener( 'click', click );
				el._listiner = null;
			} );

		}
	};
}

export default [
	{
		type: 'directive',
		name: 'lvEditableValue',
		value: lvEditableValue
	},
	{
		type: 'directive',
		name: 'lvEditableContainer',
		value: lvEditableContainer
	}
];
