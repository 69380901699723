/**
 * Created by mars on 10/30/15.
 */

serviceIntercom.$inject = ['$q', '$cookies', 'serviceLang', 'factoryIsManager', 'serviceMenu', 'factoryEnvironment'];
function serviceIntercom($q, $cookies, serviceLang, factoryIsManager, serviceMenu, factoryEnvironment) {
	var scope = this;
	var app_id;
	var useIntercom = false;
	var intercomSettings = {
		app_id: null,
		name: null,
		user_id: null,
		created_at: null,
		language_override: $cookies.get( 'lang' ) || null,
		custom_launcher_selector: '#intercom-launcher'
	};

	factoryEnvironment.getEnv().then(function (data) {
		useIntercom = data.useIntercom === 'true';
		app_id = data.intercomAppId;
	});

	this.load = function (profile) {
		return $q( function ( resolve, reject ) {

			if (!useIntercom) {
				reject();
				return;
			}

			var w = window;
			var ic = w.Intercom;
			if ( typeof ic === "function" ) {
				ic( 'reattach_activator' );
				ic( 'update', intercomSettings );
				resolve( w.Intercom );

			} else {
				var d = document;
				var i = function () {
					i.c( arguments )
				};
				i.q = [];
				i.c = function ( args ) {
					i.q.push( args )
				};
				w.Intercom = i;
				var s = d.createElement( 'script' );
				s.type = 'text/javascript';
				s.async = true;
				s.src = 'https://widget.intercom.io/widget/re5afjv3';
				var x = d.getElementsByTagName( 'script' )[0];

				s.onload = function () {
					resolve( w.Intercom );
					window.Intercom( 'boot', intercomSettings );
					window.Intercom( 'onUnreadCountChange', function(unreadCount) {
						serviceMenu.setUnreadCount(unreadCount, profile);
					} );
				};

				x.parentNode.insertBefore( s, x );
			}
		} )
	};


	this.setup = function ( profile ) {
		return $q( function ( resolve, reject ) {
			factoryIsManager().then( function ( isManager ) {
				if ( isManager || !useIntercom ) {
					reject();
					return;
				}

				intercomSettings.app_id = app_id;
				intercomSettings.name = profile.name;
				intercomSettings.user_id = profile.ownerId;
				intercomSettings.user_hash = profile.intercomUserHash;
				intercomSettings.created_at = profile.created / 1000;
				intercomSettings.language_override = serviceLang.from3to2( profile.cabinetLang );
				intercomSettings.custom_launcher_selector = '#intercom-launcher';
				resolve( intercomSettings );
				scope.load(profile);
			} )
		} )
	};


	this.update = function () {
		if ( window.Intercom ) {
			window.Intercom( 'update' );
		}
	}
}

export default {
	type: 'service',
	name: 'serviceIntercom',
	value: serviceIntercom
};