/**
 * Created by K.Yarosh on 14.02.2017.
 * @description Setting and getting data of import and export references for passing between controllers and directives, in particular for a client database
 * @memberof
 * @ngdoc service
 * @name imp-exp-refs
 */

import angular from 'angular';

impExpRefs.$inject = [];
function impExpRefs() {

    var impExpRefsData;

    this.getImpExpData = function()
    {
        return impExpRefsData;
    };

    this.setImpExpData = function(params)
    {
        impExpRefsData = angular.copy(params);
        return impExpRefsData;
    };
}

export default {
    type: 'service',
    name: 'impExpRefs',
    value: impExpRefs
};