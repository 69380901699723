/**
 * Created by A.Sirosh on 24.09.2015.
 * @memberof changeDivider
 * @ngdoc service
 * @name changeDivider
 * @requires resolveTimezone {service} - resolve timezone problems
 */

changeDivider.$inject = ['resolveTimezone', '$stateParams', 'dateCustomMethods', 'filtersData'];
function changeDivider(resolveTimezone, $stateParams, dateCustomMethods, filtersData) {

	var timeZoneResolve = resolveTimezone.timeZoneResolveMinus;

	/**
	 * changeDividerItem - return possible item || false
	 * @param item
	 * @param dateFrom
	 * @param dateTo
	 * @returns {Object}
	 */
	function changeDividerItem(item, dateFrom, dateTo){

		// парсим начальную и конечную даты, чтобы создать объект Date из чисел: год, месяц, день, час, минуты, секунды
		// НЕ создавать объект Date из строки, т.к. это не работает в браузере Safari
		var fDate = dateFrom.split(' ')[0].split('-');
		var tDate = dateTo.split(' ')[0].split('-');
		var fTime = dateFrom.split(' ')[1].split(':');
		var tTime = dateTo.split(' ')[1].split(':');
		var fromYear = parseInt(fDate[0]);
		var fromMonth = parseInt(fDate[1]) - 1;
		var fromDay = parseInt(fDate[2]);
		var fromHours = parseInt(fTime[0]);
		var fromMinutes = parseInt(fTime[1]);
		var fromSeconds = parseInt(fTime[2]);
		var toYear = parseInt(tDate[0]);
		var toMonth = parseInt(tDate[1]) - 1;
		var toDay = parseInt(tDate[2]);
		var toHours = parseInt(tTime[0]);
		var toMinutes = parseInt(tTime[1]);
		var toSeconds = parseInt(tTime[2]);

		var from = new Date(fromYear, fromMonth, fromDay, fromHours, fromMinutes, fromSeconds);
		var to = new Date(toYear, toMonth, toDay, toHours, toMinutes, toSeconds);

		var resolveFromDate = new Date(from.getFullYear(), from.getMonth(), from.getDate());
		var fromDate = timeZoneResolve(resolveFromDate);

		var resolveToDate = new Date(to.getFullYear(), to.getMonth(), to.getDate() + 1, 0, -1);
		var toDate = timeZoneResolve(resolveToDate);

		var resolveCompareFromToDay = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1, 0, -1);
		var compareFromToDay = timeZoneResolve(resolveCompareFromToDay);

		var resolveDayFrom = new Date(from.getFullYear(), from.getMonth(), from.getDate());
		var dayFrom = timeZoneResolve(resolveDayFrom);

		var resolveCompareFromToThisWeek = new Date(from.getFullYear(), from.getMonth(), (from.getDate() - resolveDayFrom.getDate()) + 1);
		var compareFromToThisWeek = timeZoneResolve(resolveCompareFromToThisWeek);

		var resolveCompareFromToWeek = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 5, 0, -1);
		var compareFromToWeek = timeZoneResolve(resolveCompareFromToWeek);

		var resolveCompareFromToMonth = new Date(to.getFullYear(), to.getMonth() - 1, to.getDate());
		var compareFromToMonth = timeZoneResolve(resolveCompareFromToMonth);

		var resolveCompareFromToQuarter = new Date(to.getFullYear(), to.getMonth() - 3, to.getDate());
		var compareFromToQuarter = timeZoneResolve(resolveCompareFromToQuarter);

		var resolveCompareFromToYear = new Date(to.getFullYear() - 1, to.getMonth(), to.getDate());
		var compareFromToYear = timeZoneResolve(resolveCompareFromToYear);


		if (fromDate === compareFromToDay) {
			if (item.key !== "hour") {
				return false;
			}
		} else if (fromDate <= compareFromToYear) {
			if (item.key === "hour") {
				return false;
			}
		} else if (fromDate <= compareFromToQuarter) {
			// if (item.key === "year") {
			if (item.key === "hour" || item.key === "year") {
				return false;
			}
		} else if (fromDate <= compareFromToMonth) {
			if (item.key === "hour" || item.key === "year" || item.key === "quarter") {
				return false;
			}
		} else if (fromDate <= compareFromToWeek) {
			if (item.key === "hour" || item.key === "month" || item.key === "quarter" || item.key === "year") {
				return false;
			}
		} else if (fromDate <= compareFromToThisWeek) {
			if (item.key !== "day") {
				return false;
			}
		} else {
			if (item.key === "hour" || item.key === "month" || item.key === "quarter" || item.key === "year") {
				return false;
			}
		}

		return item;
	}


	function changeDividerLabel(groupBy, from, to){

		var groupByKey = (groupBy) ? groupBy.key : $stateParams.group;

		var startDate = from ? from : new Date($stateParams.from);
		var endDate = to ? to : new Date($stateParams.to);

		var resolveFromDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
		var fromDate = timeZoneResolve(resolveFromDate);

		var resolveToDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1, 0, -1);
		var toDate = timeZoneResolve(resolveToDate);

		var resolveCompareFromToDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1, 0, -1);
		var compareFromToDay = timeZoneResolve(resolveCompareFromToDay);

		var resolveCompareFromToWeek = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 5, 0, -1);
		var compareFromToWeek = timeZoneResolve(resolveCompareFromToWeek);

		// var dayFrom = startDate.getDay();
		//
		// var resolveCompareFromToThisWeek = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - dayFrom + 1);
		// var compareFromToThisWeek = timeZoneResolve(resolveCompareFromToThisWeek);

		var resolveCompareFromToMonth = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());
		var compareFromToMonth = timeZoneResolve(resolveCompareFromToMonth);

		if(startDate.getYear() !== endDate.getYear()){
			if(groupByKey === 'year'){
				$stateParams.group = 'year';
			} else if(groupByKey === 'week') {
				$stateParams.group = 'week';
			} else if (groupByKey === 'month') {
				$stateParams.group = 'month';
			} else if(groupByKey === 'quarter'){
				$stateParams.group = 'quarter';
			} else {
				$stateParams.group = 'day';
			}
		}
		else if((startDate.setHours(0,0,0,0) === endDate.setHours(0,0,0,0))
			&& (($stateParams.fromHour === null && $stateParams.toHour === null)
				|| ($stateParams.fromHour === 0 && $stateParams.toHour === 0)
				|| (toDate === compareFromToDay))) {
			$stateParams.group = 'hour';
		} else if((startDate.setHours(0,0,0,0) !== endDate.setHours(0,0,0,0)) && startDate.getYear() === endDate.getYear()) {
			if(groupByKey === 'hour'){
				$stateParams.group = 'day'
			} else if(groupByKey !=='hour'  && startDate.getYear() === endDate.getYear()) {

				if (dateCustomMethods.getQuarter(startDate) !== dateCustomMethods.getQuarter(endDate)){

					if(groupByKey === 'quarter'){
						$stateParams.group = 'quarter';
					} else if(groupByKey === 'week') {
						$stateParams.group = 'week';
					} else if (groupByKey === 'month') {
						if(fromDate <= compareFromToMonth) {
							$stateParams.group = 'month';
						} else {
							$stateParams.group = 'day';
						}
					} else {
						$stateParams.group = 'day';
					}

				} else if (startDate.getMonth() !== endDate.getMonth()){

					if(groupByKey === 'month'){
						if(fromDate <= compareFromToMonth) {
							$stateParams.group = 'month';
						} else {
							$stateParams.group = 'day';
						}
					} else if(groupByKey === 'week') {
						$stateParams.group = 'week';
					} else {
						$stateParams.group = 'day';
					}

				} else if((dateCustomMethods.getWeek(startDate) !== dateCustomMethods.getWeek(endDate))
					|| (dateCustomMethods.getWeek(startDate) === dateCustomMethods.getWeek(endDate) && fromDate <= compareFromToWeek)
					|| (dateCustomMethods.getWeek(startDate) === dateCustomMethods.getWeek(endDate) && toDate !== compareFromToDay)){

					if(groupByKey === 'week'){
						$stateParams.group = 'week';
					} else {
						$stateParams.group = 'day';
					}
				} else if (dateCustomMethods.getWeek(startDate) === dateCustomMethods.getWeek(endDate) && startDate.getYear() === endDate.getYear()){
					$stateParams.group = 'day';
				} else {
					$stateParams.group = 'day';
				}
			}
		}

		return $stateParams.group;
	}


	this.changeDividerItem = changeDividerItem;
	this.changeDividerLabel = changeDividerLabel;

}

export default {
	type: 'service',
	name: 'changeDivider',
	value: changeDivider
};