/**
 * Created by olehkss on 07.09.16.
 */

vantivCashboxController.$inject = [
    '$state',
    '$stateParams',
    '$cashboxesList',
    'terminalOperationsFactory',
    'serviceDialog',
    '$filter',
    '$cookies'
];

function vantivCashboxController($state,
                                 $stateParams,
                                 $cashboxesList,
                                 terminalOperationsFactory,
                                 serviceDialog,
                                 $filter,
                                 $cookies) {
    var vm = this,
        $translateFilter = $filter('translate');

    vm.cashboxes = $cashboxesList;
    vm.cashboxParams = $stateParams.cashboxParams;
    vm.cashboxParams.idOfSelectedCashbox = null;
    vm.terminalData = $stateParams.terminalData;

    vm.onContinue = onContinue;
    vm.onCancel = onCancel;

    // создаем переменные в куках с данными из этого контроллера, для корректной работы рефреша браузера
    $cookies.putObject('cashboxParamsFromVantivCashboxCtrl', vm.cashboxParams);

    /**
     * @ngdoc function
     * @description
     * Pressing of the cancel button will be processed by this button
     */
    function onCancel() {
        $cookies.remove('cashboxParamsFromVantivCashboxCtrl');
        $state.go('settings.paytypes');
    }

    function onContinue() {
        terminalOperationsFactory.linkingTerminalToCashbox({
            'cashRegisterId': vm.cashboxParams.idOfSelectedCashbox || $stateParams.cashboxParams.id,
            'terminalId': vm.cashboxParams.terminalId,
            'paymentTypeId': vm.terminalData.paymentTypeId
        });

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: $translateFilter('PAYMENT_TYPES.TEST_SALE'),
            content: $translateFilter('PAYMENT_TYPES.IS_TERMINAL_SHOWING_TEST_SALE'),
            buttons: [
                {
                    text: 'PAYMENT_TYPES.NO',
                    action: cancelAction
                },
                {
                    class: 'primary',
                    text: 'PAYMENT_TYPES.YES',
                    action: continueAction
                }
            ]
        });

        function cancelAction() {
            // console.log("resultAction = failure");
            $state.go('vantiv.notification', {
                result: 'failure',
                previousState: $state.current.name,
                cashboxParams: vm.cashboxParams
            });
        }

        function continueAction() {
            // console.log("resultAction = success");
            $state.go('vantiv.notification', {
                result: 'success',
                previousState: $state.current.name,
                cashboxParams: vm.cashboxParams
            });
        }
    }
}

export default {
    type: 'controller',
    name: 'vantivCashboxController',
    value: vantivCashboxController
};