/**
 * Created by A.Sirosh on 15.12.2015.
 */

goodsCategoriesCtrl.$inject = ['$scope', '$state', '$stateParams', 'goodsCategories'];
function goodsCategoriesCtrl($scope, $state, $stateParams, goodsCategories) {
	var limit = 10;

	$scope.goodsCategories = goodsCategories;

	$scope.user = {
		search: $stateParams.search,
		page: $stateParams.page + 1
	};


	$scope.getData = function () {

		$scope.params = {
			limit: limit,
			offset: $stateParams.page * limit
		};

		$scope.goodsCategories.getCategoriesList($scope.params).then(function (res) {

			$scope.categories = res.categories;

			$scope.noData = $scope.categories.length;

			$scope.pages = res.pages;

			if ($scope.pages === 0) {
				$scope.pages = 1;
			}
		});

	};

	$scope.getData();

	$scope.nextPage = function () {

		if ($stateParams.page + 1 === $scope.pages || $scope.pages === 0) {
			return false;
		}
		$stateParams.page++;
		$scope.user.page = $stateParams.page + 1;

		$scope.getData();

		reload();

	};

	$scope.prevPage = function () {

		if ($stateParams.page === 0) {
			return false;
		}
		$stateParams.page--;
		$scope.user.page = $stateParams.page + 1;

		$scope.getData();

		reload();

	};

	$scope.goToPage = function () {

		if ($scope.user.page > $scope.pages) {
			$scope.user.page = $scope.pages;
		} else if ($scope.user.page == 0 || $scope.user.page < 0) {
			$scope.user.page = 1;
		}

		$stateParams.page = $scope.user.page - 1;

		$scope.getData();

	};

}

export default {
	type: 'controller',
	name: 'goodsCategoriesCtrl',
	value: goodsCategoriesCtrl
};