/**
 * Created by A.Sirosh on 08.10.2015.
 * @memberof getSelectReport
 * @ngdoc factory
 * @name getSelectReport
 * @requires $http {service} http servise
 * @requires $q {service} promise servise
 * @requires $location {service} location servise
 * @returns getSelectItemData {Promise} outlets and merchants
 */

import angular from 'angular';

getSelectReport.$inject = ["$http", "$q", "factoryErrorAlert"];

function getSelectReport($http, $q, factoryErrorAlert) {
	return {
		getSelectItemData: function () {
			return $http.post('/data/ownercab/fillselectors', {}).
				then(function (response) {
					if (angular.isObject(response.data)) {
						if (response.data.fillSelectorsResponse === "ok") {
							return response.data;
						} else {
							return $q.reject(response.data);
						}
					} else {
						return $q.reject(response.data);
					}
				}, factoryErrorAlert.onError);
		}
	};

}

export default {
	type: 'factory',
	name: 'getSelectReport',
	value: getSelectReport
};