/**
 * Created by A.Sirosh on 14.12.2015.
 * @memberof clientsDB
 * @ngdoc factory
 * @name clientsDB
 * @param {service} $http  - http service
 * @param {service} $q - promise service
 * @param {service} factoryErrorAlert - error handler service
 * @param {service} promiseOperation - promise operations factory
 * @returns {Promise} getClientsDBData- client DB data
 */

import angular from 'angular';
import {omitBy} from "lodash";

clientsDB.$inject = [
    '$q',
    '$http',
    'promiseOperation',
    'serviceDialog',
    '$state',
    '$timeout',
    'stateGo',
    'factoryErrorAlert'
];

function clientsDB(
    $q,
    $http,
    promiseOperation,
    serviceDialog,
    $state,
    $timeout,
    stateGo,
    factoryErrorAlert) {

    var timePromise;
    var clientsProcessStatus = {
        "isDone": null,
        "exists": false,
        "completed": null,
        "clientsFileProcessorResult": null
    };

    return {
        getClientsDBData: getClientsDBData,
        getClientsProcessStatus: getClientsProcessStatus,
        dialogEndProcessShow: dialogEndProcessShow,
        processClientsFile: processClientsFile,
        getTimePromise: getTimePromise,
        deleteClients: deleteClients,
        addCustomerCard: addCustomerCard,
        getClientById: getClientById,
        adjustPoints: adjustPoints,
        editcustomercard: editcustomercard,
        dialogEndProcessShowError: dialogEndProcessShowError
    };

    /**
     * @param {Object} response
     * @returns {{ clients: Array, pages: Number }}
     */
    function success(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return {
                    clients: response.data.clients,
                    pages: response.data.pages
                };
            }
        }
    }


    function getClientsDBData(params) {
        const query = omitBy(params, (x) => x === "" || x === undefined);
        let url = `/data/backoffice/customers?${new URLSearchParams(query)}`;
        if (params.offset >= 0) {
            return promiseOperation
                .getPromiseHTTP(
                    'GET',
                    url,
                    params,
                    success
                );
        }
        if (params.offset == -10) {
            params.offset = 0;

            return promiseOperation
                .getPromiseHTTP(
                    'GET', url,
                    params,
                    success
                );
        }

    }


    function getClientsProcessStatus() {
        return $q(function (resolve, reject) {
            $http
                .post('/data/ownercab/getdataprocessstatus', {importFileType: "CLIENTS"})
                .then(function (d) {
                    //d.data.exists = true;
                    //todo убрать
                    /*							d.data.exists = true;
                     cc++;
                     if(5<cc){
                     d.data.exists = false;
                     }
                     if(cc == 6){
                     d.data.isDone = true;
                     }*/

                    for (var opt in d.data) {
                        clientsProcessStatus[opt] = d.data[opt];
                    }
                    resolve(clientsProcessStatus);
                }, function (err) {
                    console.log(err);
                    reject(err);
                    factoryErrorAlert.onError(err);
                });

        });
    }

    function getTimePromise() {
        timePromise = $timeout(function () {
            getClientsProcessStatus()
                .then(function (d) {
                    if (d.exists && !d.isDone) {
                        getTimePromise();
                    } else if (d.exists && d.isDone && d.success === true) {
                        dialogEndProcessShow(true, d.inputFileName);
                    } else if (d.exists && d.isDone && d.success === false) {
                        dialogEndProcessShowError();
                    } else {
                        $state.go('clients.import', {reload: true});
                    }
                }, function (err) {
                    // console.log( err );
                    getTimePromise();
                });
        }, 1000);
        return timePromise;
    }


    function dialogEndProcessShow(fileName) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/import-entity/include/dialog-list-imported-data-update.html',
            content: 'GODS_SUCCESS_UPDATE',
            contentPrefix: 'IMPORT_SUCCESS_PREFIX',
            contentSuffix: 'IMPORT_SUCCESS_SUFFIX',
            fileName: fileName,
            class: "success-update-gods",
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        if ($state.current.name == 'clients.import') {
                            $state.go('clients.database', {page: 0, limit: 10});
                        }
                    }
                }
            ]
        });
    }


    function dialogEndProcessShowError() {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/import-entity/include/dialog-list-imported-data-update-error.html',
            mainText: "IMPORT_FAILED_TEST_3",
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        if ($state.current.name == 'clients.import') {
                            $state.go('clients.database', {page: 0, limit: 10});
                        }
                    }
                }
            ]
        });
    }

    function processClientsFile() {
        return $q(function (resolve, reject) {
            $http.post('/data/backoffice/customers/import', null)
                .then(function (d) {
                    resolve(d.data);
                }, function (err) {
                    reject(err);
                });
        });
    }

    /**
     * @ngdoc function
     * @param {Object} params {"paymentTypesIds":[11,12]}
     * @returns {Promise}
     */
    function deleteClients(params) {

        return promiseOperation.getPromiseHTTP(
            'DELETE',
            '/data/backoffice/customers',
            params,
            successDeleteClients
        );
    }

    function successDeleteClients(response) {
        if (angular.isObject(response.data)) {
            if (response.data) {
                return response.data;
            } else {
                return response;
            }
        }
    }

    function addCustomerCard(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/backoffice/customers',
            params,
            successAddCustomerCard
        );
    }

    function successAddCustomerCard(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok' || response.data.result === "error") {
                return response.data;
            }
        }
    }

    function adjustPoints(params) {

        return promiseOperation.getPromiseHTTP(
            'PUT',
            `/data/backoffice/customers/${params.id}/points`,
            params,
            successAdjustPoints,
            errorAdjustPoints
        );
    }

    function successAdjustPoints(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok' || response.data.result === 'error') {
                return response.data;
            }
        }
    }

    function errorAdjustPoints(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
        return response;
    }

    function getClientById(params) {

        return promiseOperation.getPromiseHTTP(
            'GET',
            `/data/backoffice/customers/${params.id}`,
            params,
            successGetClientById
        );
    }

    function successGetClientById(response) {
        if (angular.isObject(response.data)) {
            if (response.data) {
                return response.data;
            } else {
                return response;
            }
        }
    }

    function editcustomercard(params) {

        return promiseOperation.getPromiseHTTP(
            'PUT',
            `/data/backoffice/customers/${params.id}`,
            params,
            successeditcustomercard
        );
    }

    function successeditcustomercard(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok' || response.data.result === "error") {
                return response.data;
            }
        }
    }

}

export default {
    type: 'factory',
    name: 'clientsDB',
    value: clientsDB
};
