/**
 * Created by sirosh on 19.01.16.
 * @memberof tableEditClick
 * @ngdoc directive
 * @name tableEditClick
 * @requires $stateParams {service} UI Router servise
 */

import angular from 'angular';

tableEditClick.$inject = [ '$state'];
function tableEditClick($state) {
    return {

        restrict: 'A',
        scope:{
            tableEditClick: "="
        },
        /**
         *
         * @param scope
         * @param element
         * @param attr
         */
        link: function (scope, element, attr) {

            let clicks = 0;
            element.on('click', function (event) {

                const cancellable = angular.element(event.target).closest('[table-edit-click-cancel]');
                if (cancellable.length > 0) {
                    return;
                }

                clicks++;
                if (clicks === 1) {
                    const stateParams = {
                        stateToSave: angular.fromJson(attr.stateForSave),
                        checkToSave: angular.fromJson(attr.checkForSave)
                    };
                    if(attr.uuid) {
                        stateParams.uuid = attr.uuid;
                    } else {
                        stateParams.id = attr.id;
                    }
                    $state.go(scope.tableEditClick, stateParams)
                        .catch(() => clicks--);
                }
            });
        }
    };
}

export default {
    type: 'directive',
    name: 'tableEditClick',
    value: tableEditClick
};