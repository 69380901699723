/**
 * Works with min / max values
 * Created by Shestakov on 9.03.22
 */

function serviceMinMaxValues() {

    function getValidValue(value, options) {

        if (options) {
            if (options.minimumValue && value < options.minimumValue) {
                return options.minimumValue.toString();
            }

            if (options.maximumValue && value > options.maximumValue) {
                return options.maximumValue.toString();
            }
        }

        return value;
    }

    this.getValidValue = getValidValue;

}

export default {
    type: 'service',
    name: 'serviceMinMaxValues',
    value: serviceMinMaxValues
}
