// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../img/ic_chevron_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../../img/ic_keyboard_arrow_right.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../../img/baseline-event_note-24px.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "\n<div class=\"calendar-label\">\n  <div class=\"buttons-block\">\n    <md-button class=\"md-raised nav-arrow-box nav-arrow-box-left\" ng-click=\"previous()\" ng-disabled=\"noPrev\" aria-label=\"previous\" id=\"calendar-prev-button\">\n      <md-icon class=\"calendar-label-btn-icon\" ng-if=\"!aranav\" md-svg-src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" aria-label=\"calendar\"></md-icon>\n      <md-icon class=\"calendar-label-btn-icon\" ng-if=\"aranav\" md-svg-src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" aria-label=\"calendar\"></md-icon>\n    </md-button>\n    <md-button class=\"md-raised calendar-label-btn\" ng-click=\"click()\" aria-label=\"calendar\" id=\"calendar-open-button\">\n      <md-icon class=\"calendar-label-btn-icon calendar-label-btn-icon-them\" md-svg-src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" aria-label=\"calendar\"></md-icon>\n      <div class=\"label-template\" ng-if=\"templateView === 'labelTemplate'\"></div>\n      <div class=\"label-template-for-day\" ng-if=\"templateView === 'labelTemplateForDay'\"></div>\n      <div class=\"label-template-exact\" ng-if=\"templateView === 'labelTemplateExact'\"></div>\n      <div class=\"label-template-for-month\" ng-if=\"templateView === 'labelTemplateForMonth'\"></div>\n    </md-button>\n    <md-button class=\"md-raised nav-arrow-box nav-arrow-box-right\" ng-click=\"next()\" ng-disabled=\"noNext\" aria-label=\"next\" id=\"calendar-next-button\">\n      <md-icon class=\"calendar-label-btn-icon\" ng-if=\"!aranav\" md-svg-src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" aria-label=\"calendar\"></md-icon>\n      <md-icon class=\"calendar-label-btn-icon\" ng-if=\"aranav\" md-svg-src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" aria-label=\"calendar\"></md-icon>\n    </md-button>\n  </div>\n</div>";
// Exports
export default code;