/**
 * Created by Kiskenbassker on 07.02.18
 */

import angular from 'angular';

countListController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'serviceToast',
    '$shortOutlets',
    '$statusConst',
    '$filter',
    'siteLangCode',
    '$profile',
    '$invCountList',
    '$translate',
    'lvMixpanel'
];

function countListController(
    $scope,
    $state,
    $stateParams,
    serviceToast,
    $shortOutlets,
    $statusConst,
    $filter,
    siteLangCode,
    $profile,
    $invCountList,
    $translate,
    lvMixpanel
)
{
    var vm = this,
    translate = $filter('translate');

    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.selectStatuses = {code: $stateParams.status || null};
    $scope.selectStatusesCode = $scope.selectStatuses.code;
    $scope.constStatuses = $statusConst.invCountStatus.map(val => {
        return {
            code: val,
            name: $translate.instant('INVENTORY.COUNT.' + val)
        };
    });
    $scope.constStatuses.unshift({
        code: null,
        name: $translate.instant('INVENTORY.STATUS_OLL')
    });

    $scope.selectOutlet = {id: $stateParams.outletId ? +$stateParams.outletId : null};
    $scope.selectOutletId = $scope.selectOutlet.id;

    // данные для сохранение состояния фильтров
    $scope.stateFromList = $state.params;

    $scope.invCountList = angular.copy($invCountList);

    $scope.editTableState = 'inventory.countdetail';
    //if need to test empty search
    // $stateParams.search = null;

    //Task # 19957 Change stubs on Advanced inventory screens
    vm.plugsFilters = !!(!$scope.selectStatusesCode && !$scope.selectOutlet.id);
    vm.plugsSearch = !!$stateParams.search;
    //Plugs for extended inventory accounting Inventory Counts
    $scope.profile = angular.copy( $profile );
    var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];
    //"de" - German
    var plugInventoryCounts = ["ru", "es", "jp", "ko", "idn", "de", "ro", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];
    $scope.plugInventoryCountsHelpLink = plugInventoryCounts.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-work-inventory-count?utm_source=Back%20Office&utm_medium=Inventory%20counts"
        : "https://help.loyverse.com/help/how-work-inventory-count?utm_source=Back%20Office&utm_medium=Inventory%20counts";

    $scope.trackHelpInvCount = function() {
        lvMixpanel.track('Help', {"Property": "Inventory counts"});
    };

    $scope.onAdd = function() {
        $state.go('inventory.createcount', {stateToSave: $scope.stateFromList});
    };

    $scope.$watch('selectStatuses', function(newval, oldval){
        if (oldval != newval) {
            $state.go($state.current.name, {status: newval.code, page:0, search: $stateParams.search});
        }
    }, true);

    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            $state.go($state.current.name, {outletId: newval.id || null, page:0, search: $stateParams.search}).then(function() {
                $scope.selectOutletId = newval.id;
            });
        }
    }, true);
}

export default {
    type: 'controller',
    name: 'countListController',
    value: countListController
};
