/**
 * Created by Nikitin Oleksii
 * @description Represents a service for create API directive.
 * @ngdoc service
 * @name directiveApi
 */

import angular from 'angular';

directiveApi.$inject = ['$parse'];

function directiveApi($parse) {
        return function(directiveName) {
            return {
                restrict: 'A',
                require: directiveName,
                link: function(scope, element, attrs, ctrl) {
                    const fieldName = attrs[directiveName + 'Api'];
                    if (angular.isUndefined(fieldName)) {
                        return;
                    }

                    const getter = $parse(fieldName);
                    getter.assign(scope, ctrl);
                }
            };
        }
    }

export default {
    type: 'service',
    name: 'directiveApi',
    value: directiveApi
};