/**
 * Created by mars on 10/15/15.
 */

import angular from 'angular';

settingGroupMenu.$inject = ['$state', '$rootScope'];
function settingGroupMenu($state, $rootScope) {

	return {
		restrict: 'A',
		link: function(scope,el,attr){
			// console.log("scope = ", scope);
			var elChildren, destroyArr = [];

			function click( _el, i ) {
				function _event() {
					// console.log("_el.attr('name') = ", _el.attr('name'));
					// console.log("scope.menuIsBlocked = ", scope.menuIsBlocked);
					// console.log("_el.attr('noClickWhenBlocAccount') = ", _el.attr('noClickWhenBlocAccount'));

					if( (_el.attr('name') === undefined) || (scope.menuIsBlocked && _el.attr('noClickWhenBlocAccount') === 'true') ) {
						return;
					}  else {
						$state.go('settings.'+_el.attr('name'), {
							id: $rootScope.params.ownerId,
							page: 0
						});
					}
				}

				_el.on( 'click', _event );


				/**
				 * массив для удаления событий просле уничтожения элемента
				 */
				destroyArr.push( {
					el: _el,
					event: _event
				} )
			}

			function getChildren(){
				var els = [];
				var elements = el.children();
				var i = elements.length;
				while ( 0 < i-- ) {
					var _el = angular.element( elements[i] );
					els.unshift( _el );
					click( _el, i )
				}
				return els
			}
			elChildren = getChildren();

			function setActive(name){
				var i = elChildren.length;
				while(0<i--){
					var aName = elChildren[i].attr('name' ).split('.')[0];
					if($state.includes("**."+aName+".**" )){
						elChildren[i].addClass('active')
					}else{
						elChildren[i].removeClass('active')
					}
				}
			}
			scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams ){
				setActive(toState.name.replace(/^.+\./,''))
			})
		}
	}
}

export default {
	type: 'directive',
	name: 'settingGroupMenu',
	value: settingGroupMenu
};