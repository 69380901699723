webHList.$inject = [
    '$state',
    '$hooksList',
    '$scope',
    'serviceDialog',
    '$filter',
    'serviceToast',
    'factoryWebHooks'
];

function webHList(
    $state,
    $hooksList,
    $scope,
    serviceDialog,
    $filter,
    serviceToast,
    factoryWebHooks
) {
    var vm = this;
    $scope.hookslist = {};
    $scope.hookslist._select = false;
    vm.webhList = $hooksList.webHooks;
    $scope.showDel = false;
    $scope.selectedHooks = [];
    var translate = $filter('translate');
    $scope.editTableState = 'whookdetails';

    vm.onAdd = function () {
        if (vm.webhList.length < 40)
            $state.go('createwhook', {});
        else
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('WEBHOOKS.UNABLE_TO_CREATE_TITLE'),
                content: translate('WEBHOOKS.UNABLE_TO_CREATE_TEXT'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
    };

    $scope.changeAll = function (all) {
        if (all === true) {
            for (var k = 0; k < vm.webhList.length; k++) {
                vm.webhList[k]["_select"] = true;
                $scope.selectedHooks.push(vm.webhList[k].id);
            }
            $scope.showDel = true;


        } else {
            for (var m = 0; m < vm.webhList.length; m++) {
                vm.webhList[m]["_select"] = false;
                for ( var a = 0;a < $scope.selectedHooks.length; a++) {
                    if(vm.webhList[m].id === $scope.selectedHooks[a]) {
                        $scope.selectedHooks.splice(a,1);
                        a--;
                    }
                }
            }
            $scope.showDel = false;
        }
    };



    $scope.changeItem = function(hook) {
        if (hook._select) {
            $scope.selectedHooks.push(hook.id);
            $scope.showDel = true;

            if ($scope.selectedHooks.length === vm.webhList.length) {
                $scope.hookslist._select = true;
            }
        } else {
            $scope.selectedHooks = $scope.selectedHooks.filter(function (selectedHook) {
                return hook.id !== selectedHook;
            });

            if ($scope.hookslist._select) $scope.hookslist._select = false;

            if (!$scope.selectedHooks.length)
                $scope.showDel = false;
        }
    };

    $scope.onDel = function() {
        var deleteTitle = 'WEBHOOKS.DEL_HOOK_TITLE',
            deleteInfo = 'WEBHOOKS.DEL_HOOK_TEXT';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   translate(deleteTitle),
            content:  translate(deleteInfo),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary',
                    text: 'ON_DEL',
                    action: deleteHook
                }
            ]
        });
    };

    function deleteHook() {

        factoryWebHooks.deleteWebHooks({
            deleted: $scope.selectedHooks
        }).then(function (res) {
            if (res.result === "ok") {
                serviceToast.show('WEBHOOKS.DELETED', 'success');
                window.location.reload(true);
            }
        });
    }
}

export default {
    type: 'controller',
    name: 'webHList',
    value: webHList
};