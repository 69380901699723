/**
 * Created by A.Sirosh on 27.10.2015.
 */

function coinToCurrency() {
	/**
	 * Filter coin to currency
	 * @param val
	 * @param delimiter
	 * @param notCoin
	 * @param notNull
	 * @returns {String}
	 */
	function format(val, delimiter, notCoin, notNull) {

		if(val !== undefined && val !== null){
			// console.log("val = ", val);
			// console.log("delimiter = ", delimiter);

			var modulo = delimiter || ',';
			var noCoin = notCoin || false;
			var noNull = notNull || false;
			var result, lastTwo, first;
			var value = val.toString();
			value = value.replace('.', '');
			var negative = '';
			var length = value.length;

			if(length === 1){
				value = '00' + value;
				lastTwo = value.substring(length);
				first = value.substring(0, length);
			}else if(length === 2){

				if (value[0] === '-' && length === 2) {
					value = '-0' + value[1];
					first = value.substring(0, length);
				} else {
					value = '0' + value;
					first = value.substring(0, length - 1);
				}
				lastTwo = value.substring(length - 1);

			}else{
				if (value[0] === '-' && length === 3) {
					first = value.substring(0, length - 2) + '0';
				} else {
					first = value.substring(0, length - 2);
				}
				lastTwo = value.substring(length - 2);
				// console.log("lastTwo = ", lastTwo);

			}

			if(noCoin){
				result = negative + first;
			} else if (noNull) {
				if (lastTwo === '00') {
					result = negative + first;
				} else {
					result = negative + first + modulo + lastTwo;
				}
			}
			else {
				result = negative + first + modulo + lastTwo;
			}

			// console.log("result = ", result);

			return result;
		}

	}

	return format;
};

export default {
	type: 'filter',
	name: 'coinToCurrency',
	value: coinToCurrency
};