/**
 * Created by sirosh on 30.05.16.
 * @ngdoc controller
 * @name app.: modificatorsOperation
 * @requires {service} - promiseOperation - service for promise wrapper and http query
 * @requires {service} - $filter
 * @returns {Promise} createModificator
 * @returns {Promise} editModificator
 * @returns {Promise} getModifierById
 * @returns {Promise} getModifiersList
 * @returns {Promise} deleteModifier
 * @description
 * This service create for using, creating, editing and deleting modificators
 *
 * Need to refactoring!
 */

import angular from 'angular';

modifierCardCreateCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    '$state',
    '$modificator',
    'serviceToast',
    'serviceDialog',
    'promiseOperation',
    'modificatorsOperation',
    '$shortOutlets',
    '$currencyOptionsEditModif',
    '$timeout',
    '$mdUtil',
    'dialogs'
];

function modifierCardCreateCtrl($scope,
                                $rootScope,
                                $filter,
                                $state,
                                $modificator,
                                serviceToast,
                                serviceDialog,
                                promiseOperation,
                                modificatorsOperation,
                                $shortOutlets,
                                $currencyOptionsEditModif,
                                $timeout,
                                $mdUtil,
                                dialogs) {

    let toastNotName,
        toastMax100Options,
        toastUnableToSaveChanges;
    const modificator = angular.copy($modificator);
    const translate = $filter('translate');

    $scope.modificator = $modificator;

    _init();
    function _init() {
        /*** формат ввода валюты ***/
        $scope.currencyOptionsEditModif = $currencyOptionsEditModif;
        $timeout(() => {

            // читаем колличество знаков после запятой в валюте
            $scope.numberOfSigns = $scope.currencyOptionsEditModif.decimalPlacesOverride;

            // добавил блок if для нормальной работы кнопки ОТМЕНА когда валюта без сотых - модифицирую обьект "modificator"
            if ($scope.numberOfSigns === 0) {
                modificator.options[0].price = parseInt(modificator.options[0].price).toString();
            }
        }, 0)
    }

    $scope.options = {
        showDelBtn: false
    };

    //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ
    $scope.outlets = $shortOutlets;
    $scope.waresCount = 0;
    $scope.outletSelect = [];
    $scope.allOutlets = {};
    $scope.uniqueName = true;

    $scope.isSavingNow = false;

    $scope.$watch('outlets', (newVal) => {
        for(let j = 0; j < newVal.length; j++){
            if(newVal[j]._select === true){
                $scope.outletSelect.push(newVal[j].id);
                $scope.waresCount += newVal[j].waresCount;
            }
        }

        $scope.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;
        // ложим айдишники выбранных заведений в свойство обьекта modificator для отправки на сервер
        $scope.modificator.outletsIds = $scope.outletSelect;
        $scope.modificator.allowedForAllOutlets = $scope.allOutlets._select;
        $scope.outletSelect = [];
    }, true);

    for (let i = 0; i < $scope.outlets.length; i++){
        $scope.outlets[i]["_select"] = true;
    }

    const outletsInit = angular.copy($scope.outlets);

    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop
    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true
    };
    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop

    $scope.changeAll = () => {
        if ($scope.allOutlets._select === true){
            for (let k = 0; k < $scope.outlets.length; k++){
                $scope.outlets[k]["_select"] = true;
            }
            $scope.allOutlets._select = true;
        } else {
            for (let m = 0; m < $scope.outlets.length; m++){
                $scope.outlets[m]["_select"] = false;
            }
            $scope.allOutlets._select = false;
        }
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ


    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
    $scope.showLine = true; // показывать линию в главном блоке
    $scope.showAllOutlets = false;   // показывать весь блок с заведениями

    $scope.showBlock = flag => {
        $scope.showAllOutlets = flag;
        $scope.showLine = !flag;
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА

    const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {
        return (!angular.equals(modificator.name, $scope.modificator.name)) ||
            (!angular.equals(modificator.options, $scope.modificator.options)) ||
            (!angular.equals(outletsInit, $scope.outlets));
    }

    $scope.$on('$destroy', () => {
        offStateChangeStart();
    });

    $('form').keydown((event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    $scope.addOption = () => {

        if ($scope.modificator.options.length === 100) {
            if (toastMax100Options) {
                serviceToast.hide(toastMax100Options);
            }
            toastMax100Options = serviceToast.show('MODIFIERS.MAX_100_OPTIONS', 'success');
            return;
        }

        const lastIndex = $scope.modificator.options.length - 1;
        const priority = $scope.modificator.options[lastIndex] ? $scope.modificator.options[lastIndex].priority + 1 : 1;

        const option = {
            priority: priority,
            name    : '',
            price   : '0.00'
        };

        if ($scope.modifierForm.$invalid) {
            $mdUtil.nextTick(() => {
                $scope.modifierForm.validateControls();
                $scope.modifierForm.setInvalidTouchedControls();
            });
            if ($scope.optionsForm.$error.required) {
                if (toastNotName) {
                    serviceToast.hide(toastNotName);
                }
                toastNotName = serviceToast.show('MODIFIERS.SET_OPTION_NAME', 'success');
                $scope.$broadcast('validate', {});
            }
        } else {
            $scope.modificator.options.push(option);
        }
    };

    $scope.delOption = index => {
        $scope.modificator.options.splice(index, 1);
        if ($scope.optionsForm.$invalid) {
            $mdUtil.nextTick(() => {
                $scope.validateOptions();
            })
        }
    };


    $scope.onSaveModifier = (editProfile) => {
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );

        } else if ($scope.modifierForm.$invalid || $scope.modificator.options.length === 0){
            $scope.modifierForm.setInvalidTouchedControls();

            if (toastUnableToSaveChanges) {
                serviceToast.hide(toastUnableToSaveChanges);
            }

            toastUnableToSaveChanges = serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
        } else {
            $scope.isSavingNow = true;

            $timeout(() => {
                modificatorsOperation.createModificator($scope.modificator).then(function (res) {
                    if (res.result === 'error' && res.message === 'modifier_exists') {
                        $scope.isSavingNow = false;
                        $scope.uniqueName = false;
                        serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                    } else {
                        offStateChangeStart();
                        serviceToast.show('MODIFIERS.CREATE_MODIFIER', 'success');
                        $state.go('goods.modifiers-list').finally(() => {
                            $scope.isSavingNow = false;
                        });
                    }
                });
            }, 0 );
        }
    };

    $scope.onCancelModifier = () => {
        $state.go('goods.modifiers-list');
    };

    $scope.modifierOptionsGetter = () => $scope.modificator.options.map(modifier => modifier.name);

    $scope.validateOptions = () => {
        $scope.optionsForm.validateControls();
        $scope.optionsForm.setInvalidTouchedControls();
    }
}

export default {
    type: 'controller',
    name: 'modifierCardCreateCtrl',
    value: modifierCardCreateCtrl
};
