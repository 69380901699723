/**
 * Created by A.Sirosh on 17.12.2015.
 */

function multipleEllipsis() {
	/**
	 * Filter coin to currency
	 * @param val
	 * @param symbolLength
	 * @returns {String}
	 */
	function format(val, symbolLength) {

		if (val !== undefined && val !== null) {
			var str;
			if(val.length > symbolLength){
				str = val.substr(0, symbolLength) + '...';
			}else{
				str = val;
			}
			return str;
		}
	}
	return format;
}

export default {
	type: 'filter',
	name: 'multipleEllipsis',
	value: multipleEllipsis
};