import angular from 'angular';

navBoard.$inject = ['serviceMenu','serviceNavBoard'];
function navBoard(serviceMenu, serviceNavBoard) {

	return {
		restrict: 'C',
		controller: navBoardController,
		link: function ( scope, el, attr ) {
			serviceNavBoard.navBoardElement = el;
		}
	}
}

navBoardController.$inject = ['$element'];
function navBoardController($element) {
	this.$element = $element;
}

deployedNav.$inject = ['serviceNavBoard'];
function deployedNav( serviceNavBoard ) {
	return {
		restrict: 'C',
		require: '?&navBoard',
		replace: true,
		link: function ( $scope, el, attr, ctrl ) {
			$scope.closeDep = function(){
				$scope.model.serviceNavBoard.deployedNav = null;
				//console.log('oloo')
			};


			var i; //number
			function click(e){
				var a = false; //boolean
				var boardElements = el.find('*');
				var navBoardElements  = serviceNavBoard.navBoardElement.find('*');

				for(i = 0; i<navBoardElements.length; i++){
					boardElements.push(navBoardElements[i]);
				}

				if ( e.target == el[0] ) {
					a = true
				}
				for(i = 0 ; i< boardElements.length; i++){
					if(boardElements[i] == e.target){
						a = true
					}
				}
				if(!a){
					$scope.$applyAsync(() => {
						$scope.model.serviceNavBoard.deployedNav = null;
						serviceNavBoard.deployMenu = null;
						angular.element(document.getElementsByClassName('hover-color')[0]).removeClass('hover-color');
						document.removeEventListener( 'click', click );
					});
				}
			}

			function watch(){
				return $scope.model.serviceNavBoard.deployedNav;
			}

			$scope.$watch(watch, function(val){
				if(val===null){
					document.removeEventListener( 'click', click );
				}
			});

			setTimeout( function () {
				document.addEventListener( 'click', click );
			},1);
			$scope.$on( '$destroy', function () {
				document.removeEventListener( 'click', click );
			} );



			/*var navElements = el.find( '*' );
			var boardElements = ctrl.$element.find('*');
			navElements.push(boardElements.context);
			for(var i = 0 ; i<boardElements.length; i++){
				navElements.push(boardElements[i])
			}

			var inProgress = true;

			function click( e ) {
				var a = false;
				var i = 0;
				if ( e.target == el[0] ) {
					a = true;
				} else {
					while ( i < navElements.length && a == false ) {
						if ( navElements[i] == e.target ) {
							a = true;
						}
						i++;
					}
				}
				if ( !a && inProgress ) {
					document.removeEventListener( 'click', click );
					serviceMenu.depVenu = false;
					$scope.$apply();
					inProgress = false;

				} else {
					inProgress = true;
				}
			}

			$scope.$on( '$destroy', function () {
				document.removeEventListener( 'click', click );
				inProgress = false;
			} );


			setTimeout( function () {
				document.addEventListener( 'click', click );
			}, 1 );*/

		}
	}
}

export default [
	{
		type: 'directive',
		name: 'navBoard',
		value: navBoard
	},
	{
		type: 'directive',
		name: 'deployedNav',
		value: deployedNav
	}
];
