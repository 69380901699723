import angular from 'angular';

factoryTokens.$inject = ['promiseOperation'];

function factoryTokens(promiseOperation) {


    function getApiAuthTokens(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getApiAuthTokens',
                params,
                successGetApiAuthTokens
            );
    }

    function createApiAuthToken(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createApiAuthToken',
                params,
                successCreateApiAuthToken
            );
    }

    function editApiAuthToken(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editApiAuthToken',
                params,
                successEditApiAuthToken
            );
    }

    function getApiAuthTokenById(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getApiAuthTokenById',
                params,
                successGetApiAuthTokenById
            );
    }

    function deleteApiAuthToken(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/deleteApiAuthToken',
                params,
                successDeleteApiAuthToken
            );
    }

    function successGetApiAuthTokens(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successCreateApiAuthToken(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successEditApiAuthToken(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successGetApiAuthTokenById(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successDeleteApiAuthToken(response) {
        if (angular.isObject(response.data)) {
            return response.data.result;
        }
    }

    return {
        getApiAuthTokens: getApiAuthTokens,
        createApiAuthToken: createApiAuthToken,
        getApiAuthTokenById: getApiAuthTokenById,
        deleteApiAuthToken: deleteApiAuthToken,
        editApiAuthToken: editApiAuthToken
    };
}

export default {
    type: 'factory',
    name: 'factoryTokens',
    value: factoryTokens
};