/**
 * Created by olehkss on 28.05.16.
 * Edited by kseniya.yarosh on 14.12.16
 *
 * @ngdoc controller
 * @name app.controller:reportModifiersController
 * @description
 * Controls modifiers sales view
 * @requires $state
 * @requires $stateParams
 * @requires {object} $modifiers list of the modifiers sales report and number of pages, object is resolved by ui-router
 */

reportModifiersController.$inject = [
    '$modifiers',
    '$tableProp',
    'waresReportTableModifiers',
    '$window',
    '$scope',
    '$stateParams',
    '$state',
    '$currencyOptionsShow',
    'constantGreenExport',
    '$profile',
    '$rootScope',
    'serviceReports',
    '$permitsManager',
    '$miniBillingInfo',
    '$reportCopyAllowed'
    ];

function reportModifiersController(
    $modifiers,
    $tableProp,
    waresReportTableModifiers,
    $window,
    $scope,
    $stateParams,
    $state,
    $currencyOptionsShow,
    constantGreenExport,
    $profile,
    $rootScope,
    serviceReports,
    $permitsManager,
    $miniBillingInfo,
    $reportCopyAllowed) {


    let vm = this;
    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.reportError = $modifiers.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && $modifiers.unableToLoadData;

    $scope.pageWrapHeight = document.getElementsByClassName('report-modifiers')[0];
    $scope.isMobile = $rootScope.isMobile;
    $scope.isSafari = $rootScope.isSafari;
    vm.waresReportTable = waresReportTableModifiers;
    vm.downloadHrefBase = '/data/ownercab/modifiersreportdownload';
    vm.reportTypeForColumns = "MODIFIERS";
    vm.tableProp = $tableProp.dataArr;
    vm.tableConfig = $tableProp.config;

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    vm.windowWidth = $window.outerWidth;

    vm.size = function(obj) {
        let size = 0, key;
        for (key in obj) {
            if (obj[key] == true) size++;
        }
        return size;
    };

    vm.countColums = vm.size(vm.tableConfig);

    $scope.$on('howManyColumns', function(data){
        vm.countColums = vm.size(data.targetScope.vmTableProps.configValue);
    });

    if (!vm.reportError) {
        vm.periodAllowed = $modifiers.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($modifiers.startDateString && $modifiers.endDateString) {
            let fromParse = $modifiers.startDateString.split('-');
            let toParse = $modifiers.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $modifiers.startDate;
            vm.endDate = $modifiers.endDate;
        }
        $scope.outlets = $modifiers.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        if (vm.periodAllowed) {
            vm.modifiers = $modifiers.modifiersData;
            vm.pages = $modifiers.pages;

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            vm.dataHover;

            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.modifiers.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
                $state.go('report.modifiers', {page: vm.pages - 1});
            } else if (vm.modifiers.length === 0 && $stateParams.page > 0 && vm.pages === 0) {
                $state.go('report.modifiers', {page: 0});
            }

            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
        }
    }
}

export default {
    type: 'controller',
    name: 'reportModifiersController',
    value: reportModifiersController
};
