import template from '../templates-pug/for-directive/employee-management-promo-banner.pug';

employeeManagementPromoBanner.$inject = [];

function employeeManagementPromoBanner() {
    return {
        restrict: 'E',
        template: template,
        controller: employeeManagementPromoBannerCtrl,
        controllerAs: 'promoBannerCtrl',
        bindToController: true,
        scope: {
            options: '<lvOptions'
        }
    }
}


employeeManagementPromoBannerCtrl.$inject = ['$scope', 'siteLangCode', '$window', 'getEmployees', 'lvMixpanel'];
function employeeManagementPromoBannerCtrl($scope, siteLangCode, $window, getEmployees, lvMixpanel) {

    const ctrl = this;

    ctrl.sendMixpanelEventForButtons = value => {
        lvMixpanel.track('Help', {
            'Property': 'Employee management',
            'Value': value
        });
    }

    ctrl.$onChanges = (changes) => {
        if (changes.options && changes.options.currentValue.lang) {
            ctrl.lang = changes.options.currentValue.lang;

            const siteUrl = 'https://help.loyverse.com';
            const urlPage = '/help/employee-loyverses?utm_source=Back_Office&utm_medium=Employee+management+banner';
            const urlLang = siteLangCode[ctrl.lang] ? `/${siteLangCode[ctrl.lang]}` : '';

            ctrl.learnMoreLink = `${siteUrl}${urlLang}${urlPage}`;
        }
    }

    // Go Help site while clicking Learn More button
    ctrl.goLearnMore = () => {
        if (ctrl.learnMoreLink) {
            ctrl.sendMixpanelEventForButtons('Learn more')
            $window.open(ctrl.learnMoreLink, '_blank');
        }
    }

    // Hide banner while clicking Got it button
    ctrl.hideBanner = () => {
        getEmployees.hideEmployeesPromoBanner().then((res) => {
            if (res.result === 'ok') {
                ctrl.sendMixpanelEventForButtons('Got it');
                ctrl.options.show = false;
            }
        })
    }
}

export default {
    type: 'directive',
    name: 'employeeManagementPromoBanner',
    value: employeeManagementPromoBanner
}
