import angular from 'angular';

reportTimeCardsController.$inject = [
    'timeTrackingFactory',
    '$scope',
    '$stateParams',
    '$state',
    'serviceDialog',
    'serviceToast',
    '$filter',
    '$hoursOptionsShow',
    '$timeCards',
    '$profile',
    'factoryPopover',
    '$window',
    'isBannerDisplayed'
];

function reportTimeCardsController(timeTrackingFactory,
                                   $scope,
                                   $stateParams,
                                   $state,
                                   serviceDialog,
                                   serviceToast,
                                   $filter,
                                   $hoursOptionsShow,
                                   $timeCards,
                                   $profile,
                                   factoryPopover,
                                   $window,
                                   isBannerDisplayed) {

    var translate = $filter('translate');
    var secondsToHours = $filter('secondsToHours');
    $scope.timeCards = $timeCards.timeCards;
    $scope.pages = $timeCards.pages;
    $scope.outlets = $timeCards.fillSelectData.outlets;
    $scope.startDate = $timeCards.startDate;
    $scope.endDate = $timeCards.endDate;
    $scope.employeesTimecards = true;
    $scope.editTableState = 'employees.edittimecard';
    $scope.delete = false;
    $scope.timeCardsSelect = [];
    $scope.alltimeCards = {};
    $scope.hoursOptionsShow = $hoursOptionsShow;
    var profile = angular.copy($profile);
    $scope.arapro = profile.cabinetLang === 'ara' || profile.cabinetLang === 'urd' || profile.cabinetLang === 'heb';

    // employee management promo banner data
    $scope.bannerOptions = {
        lang: profile.cabinetLang,
        show: isBannerDisplayed
    }

    $scope.getMerchantsList = function () {
        var outletsList = $scope.outlets;
        var merchantsList = [];
        var merchantsInOutlet={};
        for(var i = 0; i < outletsList.length; i++) {
            merchantsInOutlet = outletsList[i].merchants;
            for(var l = 0; l < merchantsInOutlet.length; l++){
                merchantsList.push(merchantsInOutlet[l]);
            }
        }
        return merchantsList;
    };
    $scope.merchants = $scope.getMerchantsList();
    $scope.downloadHrefBase = '/data/ownercab/exporttimecards';

    for(var i = 0; i < $scope.timeCards.length; i++){
        $scope.timeCards[i]["_select"] = false;
        $scope.timeCards[i]["_hours"] = secondsToHours( $scope.timeCards[i].totalHours, $scope.hoursOptionsShow.decimalCharacter );
    };


    // данные для сохранение состояния фильтров и ЧЕКБОКСОВ
    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;
    // console.log("stateParams = ", $stateParams);

    if ($scope.checkFromList.length == undefined || $scope.checkFromList.length == 0) {
        for (var i = 0; i < $scope.timeCards.length; i++) {
            $scope.timeCards[i]._select = false;
        }
    } else {
        for (var i = 0; i < $scope.checkFromList.length; i++) {

            for (var q = 0; q < $scope.timeCards.length; q++) {
                ($scope.checkFromList[i] == $scope.timeCards[q].id)? $scope.timeCards[q]._select = true : "";
                (!$scope.timeCards[q]._select)? $scope.timeCards[q]._select = false: "";
            }
        }
        // console.log("timeCards =", $scope.timeCards);
    }
    // данные для сохранение состояния фильтров и ЧЕКБОКСОВ


    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if ($scope.timeCards.length == 0 && $stateParams.page > 0 && $scope.pages > 0) {
        $state.go( 'employees.timecards', {page: $scope.pages - 1, outletsIds: $scope.stateFromList.outletsIds, merchantsIds: $scope.stateFromList.merchantsIds} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


    // КОД следилка за чекбоксами с учетом сохранения массива ID выбранных СМЕН
    $scope.$watch('timeCards', function(newval, oldval){
        for (var k = 0; k < newval.length; k++) {
            if (newval[k]._select == true) {
                var idx = $scope.timeCardsSelect.indexOf(newval[k].id);
                if (idx > -1) {
                    $scope.timeCardsSelect.splice(idx, 1);
                }
                $scope.timeCardsSelect.push(newval[k].id);
            } else {
                var idx = $scope.timeCardsSelect.indexOf(newval[k].id);
                if (idx > -1) {
                $scope.timeCardsSelect.splice(idx, 1);
                }
            }
        }
        // console.log("$scope.timeCardsSelect = ", $scope.timeCardsSelect);
        ($scope.timeCardsSelect.length == 0)? $scope.delete = false : $scope.delete = true;
        ($scope.timeCardsSelect.length == $scope.timeCards.length)? $scope.alltimeCards._select = true : $scope.alltimeCards._select = false;

    }, true);
    // КОД следилка за чекбоксами с учетом сохранения массива ID выбранных СМЕН


    // $scope.$watch('timeCards', function(newVal){
    //     for(var j = 0; j < newVal.length; j++){
    //         if(newVal[j]._select === true){
    //             $scope.timeCardsSelect.push(newVal[j]._select);
    //             $scope.waresCount += newVal[j].waresCount;
    //         }
    //     }
    //     if($scope.timeCardsSelect.length > 0){
    //         $scope.delete = true;
    //     }else{
    //         $scope.delete = false;
    //     }
    //     if($scope.timeCardsSelect.length === $scope.timeCards.length ){
    //         $scope.alltimeCards._select = true;
    //     }else{
    //         $scope.alltimeCards._select = false;
    //     }
    //     $scope.timeCardsSelect = [];
    //     console.log("$scope.timeCardsSelect = ", $scope.timeCardsSelect);

    // }, true);





    $scope.changeAll = function(){
        if($scope.alltimeCards._select === true){
            for(var k = 0; k < $scope.timeCards.length; k++){
                $scope.timeCards[k]["_select"] = true;
            }
            $scope.alltimeCards._select = true;
        }else{
            $scope.alltimeCards._select = false;
            for(var m = 0; m < $scope.timeCards.length; m++){
                $scope.timeCards[m]["_select"] = false;
            }
        }
    };



    $scope.onAddTimeCard = function() {
        $state.go( 'employees.createtimecard', {stateToSave: $scope.stateFromList, checkToSave: $scope.timeCardsSelect} );
    };



    $scope.onDel = function(){
        $scope.timeCardIds = [];
        for(var i = 0; i < $scope.timeCards.length; i++){
            if($scope.timeCards[i]._select === true){
                $scope.timeCardIds.push($scope.timeCards[i].id);
            }
        }
        if($scope.timeCardIds.length === 1){
            $scope.msgTitle =  translate('REPORT_EMPLOYEE.DELETE_TIMECARD');
            $scope.tostTitle = 'REPORT_EMPLOYEE.TOSTS.DELETED_TIMECARD';
            $scope.contentNoWares = translate('REPORT_EMPLOYEE.DELETE_MESSAGE_TIMECARD');
        }else {
            $scope.msgTitle = translate('REPORT_EMPLOYEE.DELETE_TIMECARDS');
            $scope.tostTitle = 'REPORT_EMPLOYEE.TOSTS.DELETED_TIMECARDS';
            $scope.contentNoWares = translate('REPORT_EMPLOYEE.DELETE_MESSAGE_TIMECARDS');
        }
        $scope.onSendData = function(){
            timeTrackingFactory.deleteTimeCards({ids: $scope.timeCardIds}).then(function(res){
                if(res.result === "ok"){
                    serviceToast.show($scope.tostTitle, 'success');
                    $state.go('employees.timecards', {  page:           $scope.stateFromList.page,
                                                        limit:          $scope.stateFromList.limit,
                                                        outletsIds:     $scope.stateFromList.outletsIds,
                                                        merchantsIds:   $scope.stateFromList.merchantsIds,
                                                        checkFromList:  []},
                                                     {  reload: true });
                }
            });
        };

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: $scope.msgTitle,
            content: $scope.contentNoWares,
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: $scope.onSendData
                }
            ]
        });
    };

    $scope.popover = factoryPopover;

    $scope.openPopovers = {};
  
    const onResize = () => {
      $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };
  
    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);
}

export default {
    type: 'controller',
    name: 'reportTimeCardsController',
    value: reportTimeCardsController
};
