/**
 * Created by mars on 12/15/15.
 */

const constantShapeGoodsCollection = [
	{
		value: 'ROUNDED_SQUARE'
	},
	{
		value: 'CIRCLE'
	},
	{
		value: 'SUN_SHAPE'
	},
	{
		value: 'HEXAGON'
	}
];

export default {
	type: 'constant',
	name: 'constantShapeGoodsCollection',
	value: constantShapeGoodsCollection
};