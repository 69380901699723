/**
 * Created by olehkss on 22.08.16.
 * @ngdoc function
 * @name app.factory: payTypesOperationsFactory
 * @description
 * Operations with payment types: creation, deletion, editing and receiving of the payment operation types objects itself
 * @requires promiseOperation
 * @returns {{getPayTypes: getPayTypes, getPayTypeById: getPayTypeById, createPayType: createPayType, editPayType: editPayType, deletePayType: deletePayType}}
 */

import angular from 'angular';

payTypesOperationsFactory.$inject = ['promiseOperation'];

function payTypesOperationsFactory(promiseOperation) {

    return {
        getPayTypes: getPayTypes,
        getPayTypeById: getPayTypeById,
        createPayType: createPayType,
        editPayType: editPayType,
        deletePayType: deletePayType,
        sumUpLogout: sumUpLogout,
        changePayTypesOrder: changePayTypesOrder
    };

    // Success callback
    function success(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    // Success callback that returns data only when response result == "ok"
    function successWithResultOk(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data;
            }
        }
    }

    function successWithResult(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    /**
     * @ngdoc function
     * @param {Object} params {"limit":10, "offset":0}
     * @returns {Promise}
     */
    function getPayTypes(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getpaymenttypes',
            params,
            successWithResultOk
        );
    }

    /**
     * @ngdoc function
     * @param {Object} params {"paymentTypeId":11}
     * @returns {Promise}
     */
    function getPayTypeById(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getpaymenttypebyid',
            params,
            successWithResult
        );
    }

    /**
     * @ngdoc function
     * @param {Object} params {"method":"VANTIV", "name":"Оплата Vantiv 2"}
     * @returns {Promise}
     */
    function createPayType(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/createpaymenttype',
            params,
            success
        );
    }

    /**
     * @ngdoc function
     * @param {Object} params {"method":"VANTIV", "name":"Оплата Vantiv 2", "paymentTypeId":11 }
     * @returns {Promise}
     */
    function editPayType(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/editpaymenttype',
            params,
            success
        );
    }

    /**
     * @ngdoc function
     * @param {Object} params {"paymentTypesIds":[11,12]}
     * @returns {Promise}
     */
    function deletePayType(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/deletepaymenttypes',
            params,
            successWithResult
        );
    }

    /**
     * @ngdoc function
     * @param {Object} params {"paymentTypeId":11}
     * @returns {Promise}
     */
    function sumUpLogout(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/sumUpLogout',
            params,
            successWithResultOk
        );
    }


    function changePayTypesOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/changePaymentTypesOrder',
            params,
            successWithResultOk
        );
    }

}

export default {
    type: 'factory',
    name: 'payTypesOperationsFactory',
    value: payTypesOperationsFactory
};