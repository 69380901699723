/**
 * Created by sirosh on 12.01.16.
 * Edited by kseniya.yarosh on 13.12.16.
 * @memberof getTaxList
 * @ngdoc factory
 * @name getTaxList
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param factoryErrorAlert {service} error handler service
 */

import angular from 'angular';

getTaxList.$inject = ["$http", "$q", "factoryErrorAlert"];

function getTaxList($http, $q, factoryErrorAlert) {


    var responseData = [];
    var responseDataCreate = {};
    var responseDataById = {};
    var responseDataEdit = {};
    var responseDataDelete = {};
    var responseDataCategoriesListForDO = {};
    var responseDataItemsListForDO = {};
    var responseDiningOptionsList = {};
    var responseSearchItemsListDining = {};

    function success(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseData = response.data;
            }
        }
    }

    function successCreate(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result) {
                responseDataCreate = response.data;
            }
        }
    }

    function successDelete(response) {
        if (angular.isObject(response.data)) {
            responseDataDelete = response.data;
        }
    }

    function successGetById(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseDataById = response.data.tax;
            } else {
                responseDataById = response.data;
            }
        }
    }
    function successEditTax(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result) {
                responseDataEdit = response.data;
            }
        }
    }

    function successGetCategoriesListForDiningOptions(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseDataCategoriesListForDO = response.data;
            }
        }
    }

    function successGetItemsListForDiningOptions(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseDataItemsListForDO = response.data;
            }
        }
    }

    function successGetDiningOptionsListForTaxDependence(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseDiningOptionsList = response.data;
            }
        }
    }

    function successSearchItemsListForDiningOptions(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseSearchItemsListDining = response.data;
            }
        }
    }

    function getTaxListData(params) {

        if(params.outletsIds == "" || params.outletsIds == undefined){
          params.outletsIds = null;
        } else {
          params.outletsIds = [params.outletsIds];
        }

        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/gettaxlist', params).then(function (d) {
                success(d);
                resolve(responseData);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function setTax(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/createtax', params).then(function (d) {
                successCreate(d);
                resolve(responseDataCreate);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
    function editTax(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/edittax', params).then(function (d) {
                successEditTax(d);
                resolve(responseDataEdit);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function deleteTax(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deletetaxes', params).then(function (d) {
                successDelete(d);
                resolve(responseDataDelete);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function getTaxById(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/gettaxbyid', params).then(function (d) {
                successGetById(d);
                resolve(responseDataById);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }


// получаем краткий список торговых точек
    function getShortOutlets(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getshortoutlets', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.outlets);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем краткий список торговых точек

    function getCategoriesListForDiningOptions(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getCategoriesListForDiningOptions', params).then(function (d) {
                successGetCategoriesListForDiningOptions(d);
                resolve(responseDataCategoriesListForDO);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function getItemsListForDiningOptions(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getItemsListForDiningOptions', params).then(function (d) {
                successGetItemsListForDiningOptions(d);
                resolve(responseDataItemsListForDO);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function getDiningOptionsListForTaxDependence(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getDiningOptionsListForTaxDependence', params).then(function (d) {
                successGetDiningOptionsListForTaxDependence(d);
                resolve(responseDiningOptionsList);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function searchItemsListForDiningOptions(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/searchItemsListForDiningOptions', params).then(function (d) {
                successSearchItemsListForDiningOptions(d);
                resolve(responseSearchItemsListDining);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    return {
        setTax: setTax,
        getTaxListData: getTaxListData,
        deleteTax: deleteTax,
        getTaxById: getTaxById,
        editTax: editTax,
        getShortOutlets: getShortOutlets,
        getCategoriesListForDiningOptions: getCategoriesListForDiningOptions,
        getItemsListForDiningOptions: getItemsListForDiningOptions,
        getDiningOptionsListForTaxDependence: getDiningOptionsListForTaxDependence,
        searchItemsListForDiningOptions: searchItemsListForDiningOptions
    };

}

export default {
    type: 'factory',
    name: 'getTaxList',
    value: getTaxList
};