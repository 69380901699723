/**
 * Created by olehkss on 31.08.16.
 */

vantivRegistrationController.$inject = ['$state', '$stateParams', '$ownerData', '$cookies'];

function vantivRegistrationController($state, $stateParams, $ownerData, $cookies) {

    var vm = this;
    vm.terminalData = JSON.parse($ownerData) || {};
    vm.terminalData.paymentTypeId = $stateParams.paymentTypeId;
    vm.terminalData.paymentSystemMerchantNumber = $stateParams.merchantNumber;
    vm.terminalData.paymentSystemWSPassword = $stateParams.wsPassword
    // console.log("vm.terminalData = ", vm.terminalData);

    vm.onCancel = onCancel;
    vm.onContinue = onContinue;

// При заходе на регистрицию обнуляем переменные в куках, которые исплользовались только notificationController
    $cookies.remove('cashboxParamsFromNotifyCtrl');
    $cookies.remove('resultFromNotifyCtrl');
// При заходе на регистрицию обнуляем переменные в куках, которые исплользовались только notificationController


    function onCancel() {
        $state.go('settings.paytypes');
    }

    function onContinue() {
        $state.go('vantiv.terminal', {terminalData: vm.terminalData});
    }

}

export default {
    type: 'controller',
    name: 'vantivRegistrationController',
    value: vantivRegistrationController
};