/**
 * Created by k.yarosh on 10/03/16.
 */

import angular from 'angular';

createPaymentTypeControl.$inject = [
    '$stateParams',
    '$scope',
    '$state',
    '$filter',
    'payTypesOperationsFactory',
    '$payConst',
    'serviceDialog',
    '$profile',
    'serviceToast',
    '$cookies',
    '$translate',
    '$shortOutlets',
    'paymentMethodsWithTips',
    '$paymentTypesInfo',
    'dialogs'
];

function createPaymentTypeControl($stateParams,
                                  $scope,
                                  $state,
                                  $filter,
                                  payTypesOperationsFactory,
                                  $payConst,
                                  serviceDialog,
                                  $profile,
                                  serviceToast,
                                  $cookies,
                                  $translate,
                                  $shortOutlets,
                                  paymentMethodsWithTips,
                                  $paymentTypesInfo,
                                  dialogs
                                  ) {

    let vm = this;
    let translate = $filter('translate');
    let paymentNameChanged = false;

    let initParams;
    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
    function isChanged() {
        let currentParams = {
            paymentTypeMethod: vm.paymentTypeMethod,
            paymentTypeName: vm.paymentTypeName,
            onePayPublicKey: vm.onePayPublicKey,
            onePayMerchantId: vm.onePayMerchantId,
            tipsData: {
                tipsEnable: vm.tipsData.tipsEnable,
                tipsCustomEnable: vm.tipsData.tipsCustomEnable,
            },
            tipsRoundedForInput: angular.copy(vm.tipsRoundedForInput),
            outlets: $scope.outlets.map(item => {
                return {
                    select: item._select,
                    name: item.name
                }
            })
        };

        return !angular.equals(initParams, currentParams);
    }

    const maxCount = $paymentTypesInfo.maxPaymentTypesCount;

    vm.payMethods = [];
    if ($scope.$parent.payMethods) {
        vm.payMethods = $scope.$parent.payMethods.map(val => {
            return {
                code: val,
                name: $translate.instant('PAYMENT_TYPES.' + val)
            };
        });
        const payPriority = [
            'NONINTEGRATEDCARD',
            'CHEQUE',
            'OTHER'
        ];

        vm.payMethods = vm.payMethods.sort((item1, item2) => {
            const index1 = payPriority.indexOf(item1.code);
            const index2 = payPriority.indexOf(item2.code);
            if (index1 > -1 && index2 > -1){
                if (index1 !== index2) {
                    return index1 - index2;
                }
                return item1.name.localeCompare(item2.name);
            }
            if (index1 > -1) {
                return -1;
            }
            if (index2 > -1) {
                return 1;
            }
            return item1.name.localeCompare(item2.name);
        });
    }

    $scope.outlets = $shortOutlets;
    vm.paymentsMethodsExists = $scope.$parent.paymentsMethodsExists;
    vm.paymentTypeId = null;
    vm.paymentTypeMethod = null;
    vm.paymentTypeName = null;
    vm.onePayPublicKey = null;
    vm.onePayMerchantId = null;
    vm.vantivExists = $scope.$parent.vantivExists;
    vm.isSavingNow = true;
    vm.tipsData = {};
    vm.tipsData.tipsEnable = false;
    vm.tipsData.tipsCustomEnable = true;
    vm.tipsAmounts = angular.copy($payConst.tips);
    vm.tipsRoundedForInput = roundTipsForInput(vm.tipsAmounts);
    vm.uniqueName = true;

    $scope.outletSelect = [];
    vm.allOutlets = {};

    vm.paymentMethodUsingTips = ["WORLDPAY", "SUMUP", "YOCO", "IZETTLE", "KICC"];

    $scope.$watch('outlets', function(newVal){

        $scope.outletSelect = [];
        newVal.forEach((outlet) => {
            if (outlet._select === true) {
                $scope.outletSelect.push(outlet.id);
            }
        })

        vm.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;

    }, true);

    for(let i = 0; i < $scope.outlets.length; i++){
        $scope.outlets[i]["_select"] = true;
    }

    initParams = {
        paymentTypeMethod: vm.paymentTypeMethod,
        paymentTypeName: vm.paymentTypeName,
        onePayPublicKey: vm.onePayPublicKey,
        onePayMerchantId: vm.onePayMerchantId,
        tipsData: {
            tipsEnable: vm.tipsData.tipsEnable,
            tipsCustomEnable: vm.tipsData.tipsCustomEnable,
        },
        tipsRoundedForInput: angular.copy(vm.tipsRoundedForInput),
        allOutletsSelected: vm.allOutlets._select,
        outlets: $scope.outlets.map(item => {
            return {
                select: item._select,
                name: item.name
            }
        })
    };

    $scope.changeAll = function(){
        if (vm.allOutlets._select === true) {
            $scope.outlets.forEach((outlet) => {
                outlet["_select"] = true;
            })
            vm.allOutlets._select = true;
        } else {
            $scope.outlets.forEach((outlet) => {
                outlet["_select"] = false;
            })
            vm.allOutlets._select = false;
        }
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ

    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
    $scope.showLine = true; // показывать линию в главном блоке
    $scope.showAllOutlets = false;   // показывать весь блок с заведениями
    vm.allOutlets._select = true;

    $scope.showBlock = function (flag) {
        $scope.showAllOutlets = flag;
        $scope.showLine = !flag;
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА


    vm.onSave = onSave;
    vm.onCancel = onCancel;
    vm.definePaymentTypeName = definePaymentTypeName;
    vm.notifyAboutNameChanged = notifyAboutNameChanged;


    // *** КОД НАПИСАННЫЙ ПОД SUMUP ***
    vm.realState = $state.current.name;
    vm.profile = $profile.sumup;
    vm.sumupExists = $scope.$parent.sumupExists;
    // *** КОД НАПИСАННЫЙ ПОД SUMUP ***


    // *** КОД НАПИСАННЫЙ ПОД izettle - запрет создания 2-го типа оплат ***
    vm.izettleExists = $scope.$parent.izettleExists;
    // *** КОД НАПИСАННЫЙ ПОД izettle - запрет создания 2-го типа оплат ***

    vm.tyroExists = $scope.$parent.tyroExists;

    vm.paymenttype = 'newtype';

    vm.progress = false;

    function definePaymentTypeName() {
        if (!paymentNameChanged) {
            vm.paymentTypeName = vm.paymentTypeMethod !== null ? translate("PAYMENT_TYPES." + vm.paymentTypeMethod) : "";
        }
        defineClass(vm.paymentTypeMethod);
    }


    function notifyAboutNameChanged() {
        let nameFromTemplate = translate("PAYMENT_TYPES." + vm.paymentTypeMethod);
        paymentNameChanged = nameFromTemplate !== vm.paymentTypeName
    }


    function roundTipsForInput(tipsArray) {
        let roundedTipsArray = [];
        for (let i = 0; i < tipsArray.length; i++) {
            let roundedTip = $filter('coinToCurrency')(tipsArray[i], ".");
            roundedTipsArray.push(roundedTip);
        }
        return roundedTipsArray;
    }


    function roundTipsForServer(tipsArray) {
        let roundedTipsArray = [];
        for (let i = 0; i < tipsArray.length; i++) {
            let roundedTip = $filter('currencyToCoin')(tipsArray[i]);
            roundedTipsArray.push(roundedTip);
        }
        return roundedTipsArray;
    }


    function onSave(saveButton) {

        if($scope.editPaymentTypeForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.editPaymentTypeForm.focusFirstInvalidControl();
            return;
        }

        if (vm.paymentTypeMethod === "CASH") {
            singleBtnDialoguePopUp(
                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                'PAYMENT_TYPES.CANNOT_CREATE_PAYMENTTYPE', {name: '"' + translate(vm.paymentTypeMethod) + '"'}
            );
        } else if (vm.paymentTypeMethod === "WORLDPAY" && vm.vantivExists == true) {
            singleBtnDialoguePopUp(
                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.WORLDPAY')}
            );
        } else if (vm.paymentsMethodsExists.indexOf(vm.paymentTypeMethod) > -1 && vm.paymentTypeMethod !== "NONINTEGRATEDCARD") {
            singleBtnDialoguePopUp(
                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.' + vm.paymentTypeMethod)}
            );
        } else {
            if (vm.isSavingNow === true) {
                vm.isSavingNow = false;

                if(paymentMethodsWithTips.indexOf(vm.paymentTypeMethod) !== -1) {
                    if(vm.tipsData.tipsEnable && parseFloat(roundTipsForServer(vm.tipsRoundedForInput[0])) === 0 &&
                        parseFloat(roundTipsForServer(vm.tipsRoundedForInput[1])) === 0 && parseFloat(roundTipsForServer(vm.tipsRoundedForInput[2])) === 0 &&
                        !vm.tipsData.tipsCustomEnable) {
                        vm.tipsData.tipsEnable = false;
                    }
                }

                let paymentTypeName = null;
                let paymentTypeNameChanged = ["WORLDPAY", "COINEY"]
                if (vm.paymentTypeName && (paymentNameChanged || paymentTypeNameChanged.indexOf(vm.paymentTypeMethod) !== -1)) {
                    paymentTypeName = vm.paymentTypeName;
                }
                vm.paymentTypeMethod = (vm.paymentTypeMethod === "WORLDPAY") ? "VANTIV" : vm.paymentTypeMethod;


                let rand = function() {
                    return Math.random().toString(36).substr(2); // remove `0.`
                };

                let token = rand() + rand(); // to make it longer

                vm.progress = true;
                payTypesOperationsFactory.createPayType({
                    'method': vm.paymentTypeMethod,
                    'name': paymentTypeName,
                    'onePayPublicKey': vm.onePayPublicKey,
                    'onePayMerchantId': vm.onePayMerchantId,
                    'tipsConfig': {
                        'tips': (paymentMethodsWithTips.indexOf(vm.paymentTypeMethod) !== -1) ? roundTipsForServer(vm.tipsRoundedForInput) : null
                    },
                    'tipsEnable': vm.tipsData.tipsEnable,
                    'tipsCustomEnable': vm.tipsData.tipsCustomEnable,
                    'state':  (vm.paymentTypeMethod === "SUMUP" && !saveButton) ? token : null,
                    'allowedForAllOutlets': vm.allOutlets._select,
                    'outletIds': $scope.outletSelect
                })
                    .then(function (res) {
                        if (res.result === "ok") {
                            offStateChangeStart();
                            vm.isSavingNow = false;
                            serviceToast.show('PAYMENT_TYPES.PAYMENT_TYPE_CREATED', 'success');
                            if (vm.paymentTypeMethod === "SUMUP" && !saveButton) {
                                document.location.href = "https://api.sumup.com/authorize?client_id="+ vm.profile.clientId +"&response_type=code&redirect_uri="+ vm.profile.redirectUri+"&state="+token;
                            } else {
                                $state.go('settings.paytypes');
                            }
                        } else if (res.result === "error" && res.message === 'payment_type_exists') {
                            vm.uniqueName = false;
                            vm.isSavingNow = true;
                            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                        } else if (res.result === "error" && res.message === 'vantiv_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.WORLDPAY')}
                            );
                        } else if (res.result === "error" && res.message === 'cardconnect_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.FIRSTDATA')}
                            );
                        } else if (res.result === "error" && res.message === 'max_payment_type_count') {
                            singleBtnDialoguePopUp(
                                'NOTIFICATION',
                                'PAYMENT_TYPES.ONLY_TEN_PAYTYPES',
                                {maxCount: maxCount}
                            );
                        } else if (res.result === "error" && res.message === 'sumup_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.SUMUP')}
                            );
                        }else if (res.result === "error" && res.message === 'tyro_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.TYRO')}
                            );
                        } else if (res.result === "error" && res.message === 'izettle_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.IZETTLE')}
                            );
                        } else if (res.result === "error" && res.message === 'smartpay_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.SMARTPAY')}
                            );
                        } else if (res.result === "error" && res.message === 'nice_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.NICEPAY')}
                            );
                        } else if (res.result === "error" && res.message === 'yoco_already_exists') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.YOCO')}
                            );
                        } else if (res.result === "error" && res.message === 'not_valid_public_key') {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                'PAYMENT_TYPES.NOT_VALID_PUBLIC_KEY'
                            );
                        } else {
                            serviceToast.show('SAVE_ERROR', 'error');
                        }
                    })
                    .finally(() => {
                        vm.progress = false;
                    })
            }
        }
    }


    function onCancel() {
        // removeCookies();
        $state.go('settings.paytypes');
    }


    function singleBtnDialoguePopUp(title, content, variable) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate(title),
            content: translate(content, variable),
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        vm.isSavingNow = true;
                    }
                }
            ]
        })
    }

    vm.icon = document.getElementsByClassName('img-box')[0];
    function defineClass (paymentType) {
        if (paymentType === 'OTHER') {
            vm.paymenttype = 'othertype';
        } else if (paymentType === 'CHEQUE') {
            vm.paymenttype = 'chequetype';
        } else  if (paymentType === null) {
            vm.paymenttype = 'newtype';
        } else {
            vm.paymenttype = 'cardtype';
        }
    }




}

export default {
    type: 'controller',
    name: 'createPaymentTypeControl',
    value: createPaymentTypeControl
};
