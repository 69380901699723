/**
 * Created by mars on 4/5/16.
 */

importFile.$inject = [
	'FileUploader',
	'$state',
	'$stateParams',
	'$filter',
	'serviceDialog',
	'factoryGoods',
	'serviceToast',
	'$translate',
	'factoryCategories',
	'$timeout',
	'$profile',
	'siteLangCode',
	'lvMixpanel'
];

function importFile( FileUploader,
					 $state,
					 $stateParams,
					 $filter,
					 serviceDialog,
					 factoryGoods,
					 serviceToast,
					 $translate,
					 factoryCategories,
					 $timeout,
					 $profile,
					 siteLangCode,
					 lvMixpanel) {

	var model = this;

	var translate = $filter( 'translate' );
	var outletId = ($stateParams.outletId) ? $stateParams.outletId : null;
	var langCode = siteLangCode[$profile.cabinetLang];
	var plugProductions = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];

	model.helpLink = plugProductions.indexOf(langCode) !== -1
		? "https://help.loyverse.com/" + langCode + "/help/importing-and-exporting?utm_source=Back%20Office&utm_medium=Exporting%20and%20Importing"
		: "https://help.loyverse.com/help/importing-and-exporting?utm_source=Back%20Office&utm_medium=Exporting%20and%20Importing";
	model.wareCreated = {
		one: 'WILL_BE_CREATED_WARE_SINGULAR',
		first: 'WILL_BE_CREATED_WARE_SINGULAR',
		second: 'WILL_BE_CREATED_WARE_PAUCAL',
		third: 'WILL_BE_CREATED_WARE_PLURAL'
	};

	model.wareEdited = {
		one: 'WILL_BE_EDITED_WARE_SINGULAR',
		first: 'WILL_BE_EDITED_WARE_SINGULAR',
		second: 'WILL_BE_EDITED_WARE_PAUCAL',
		third: 'WILL_BE_EDITED_WARE_PLURAL'
	};


	model.categoryCreated = {
		one: 'WILL_BE_CREATED_CATEGORY_SINGULAR',
		first: 'WILL_BE_CREATED_CATEGORY_SINGULAR',
		second: 'WILL_BE_CREATED_CATEGORY_PAUCAL',
		third: 'WILL_BE_CREATED_CATEGORY_PLURAL'
	};

	model.supplierCreated = {
		one: 'WILL_BE_CREATED_SUPPLIER_SINGULAR',
		first: 'WILL_BE_CREATED_SUPPLIER_SINGULAR',
		second: 'WILL_BE_CREATED_SUPPLIER_PAUCAL',
		third: 'WILL_BE_CREATED_SUPPLIER_PLURAL'
	};


	var includeTemplate = {
		onStart: 'dashboard-page/templates/goods/include/download-start.html',
		onAttach: 'dashboard-page/templates/goods/include/on-attach.html',
		onError: 'dashboard-page/templates/goods/include/download-error.html',
		formatError: 'dashboard-page/templates/goods/include/format-error.html',
		onShortEntry: 'dashboard-page/templates/goods/include/short-entry.html',
		limit10000: 'dashboard-page/templates/goods/include/limit-10000.html',
		onMaxFileSize: 'dashboard-page/templates/goods/include/max-file-size.html',
		onLoading: 'dashboard-page/templates/goods/include/preloader.html',
		onParserSuccess: 'dashboard-page/templates/goods/include/parser-success.html',
		onParserError: 'dashboard-page/templates/goods/include/parser-error.html',
		onWrongHeader: 'dashboard-page/templates/goods/include/download-wrong-header.html',
		onLimit200variants: 'dashboard-page/templates/goods/include/download-limit-200-variants.html',
		wrongTaxesList: 'dashboard-page/templates/goods/include/wrong-tax-list.html',
		badEncoding: 'dashboard-page/templates/goods/include/bad-encoding.html'
	};

	var includeHeadTemplate = {
		onStart: 'dashboard-page/templates/goods/include/head-start.html',
		onSuccess: 'dashboard-page/templates/goods/include/head-success.html',
		onParserError: 'dashboard-page/templates/goods/include/head-parser-error.html'
	};

	var primaryAction = {
		onStart: function () {
			model.response = null;
			model.includeTemplate = includeTemplate.onStart;
			model.includeHeadTemlate = includeHeadTemplate.onStart;
			model.primaryButtonText = 'DOWNLOAD';
			model.primaryAction = primaryAction.onStart;
			model.onCancel = cancelAction.onStart;
			model.primaryButtonDisabled = true;
			model.templateWidtn = null;
			// console.log("model in onStart = ", model);
		},
		onAttach: function () {
			if ( !checkFileNameValid() ) {
				uploader.removeFromQueue( 0 );
				model.includeTemplate = includeTemplate.formatError;
				model.primaryButtonText = 'REPEAT_LOADING';
				model.primaryAction = primaryAction.onStart;
				model.primaryButtonDisabled = false;
			} else if ( !checkFileSize() ) {
				uploader.removeFromQueue( 0 );
				model.templateWidtn = null;
				model.includeTemplate = includeTemplate.onMaxFileSize;
				model.primaryButtonText = 'REPEAT_LOADING';
				model.primaryAction = primaryAction.onStart;
				model.primaryButtonDisabled = false;
			} else {
				model.includeTemplate = includeTemplate.onLoading;
				uploader.uploadAll();
				model.primaryButtonDisabled = true;
			}
		},
		onUpdateGoods: function () {
			model.includeTemplate = includeTemplate.onLoading;
			model.primaryButtonDisabled = true;

			factoryGoods
				.processWaresFile()
				.then( function ( d ) {
					if ( d && d.result == 'ok' ) {

						$timeout( function () {

							successImportGoodsForMixpanel(model.response);
							// console.log("место для микспанели при удачной загрузке model.response = ", model.response);

							$state.go( $state.current.name, {}, {reload: true} );
						}, 2000 );
					} else {
						serviceToast.show( 'UNKNOWN_ERROR', 'error' );
						model.primaryButtonDisabled = false;
						primaryAction.onStart();
					}
				}, function ( err ) {
					serviceToast.show( 'UNKNOWN_ERROR', 'error' );
					primaryAction.onStart();
				} );
		}
	};


	var cancelAction = {
		onStart: function () {
			$state.go( 'goods.price', {page: 0, limit: 10, inventory: 'all', outletId: ($stateParams.outletId) ? $stateParams.outletId : null } );
		},
		onParserError: function () {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'WARNING' ) + "!",
				content: translate( 'DETECT_CHANGES' ),
				buttons: [
					{
						text: 'ON_CANCEL'
					},
					{
						class: 'primary',
						text: 'ON_OK',
						action: function () {
							$state.go( 'goods.price', {page: 0, limit: 10, inventory: 'all', outletId: ($stateParams.outletId) ? $stateParams.outletId : null} );
						}
					}
				]
			} );
		}
	};

	primaryAction.onStart();

	var uploader = this.uploader = new FileUploader( {
		// url: '/data/ownercab/validatemultishopwaresfile',
		url: '/data/ownercab/validatewaresfile',
		// outletId: outletId,
		// url: '/data/ownercab/validatewaresfile?outletId='+ outletId,
		queueLimit: 1
		//todo для node
		//
		// enctype:   "multipart/form-data",
		/*url: '/upload.node',
		 headers: {
		 'Content-Type': "application/x-www-form-urlencoded",
		 },
		 name: 'myfile'
		 */
	} );

	// CALLBACKS
	uploader.onWhenAddingFileFailed = function ( item /*{File|FileLikeObject}*/, filter, options ) {
		//console.info( 'onWhenAddingFileFailed', item, filter, options );
		// console.log( 'uploader in controller');
	};
	uploader.onAfterAddingFile = function ( fileItem ) {
		//console.info( 'onAfterAddingFile', fileItem );
		var addedFile = fileItem._file;
		model.includeTemplate = includeTemplate.onAttach;
		model.primaryButtonDisabled = false;
		model.primaryAction = primaryAction.onAttach;
		model.onAttachFileName = addedFile.name;
		model.fileSize = addedFile.size;
		//закомментировано в связи с ненадобностью параметра, который был добавлен в начале эпохи мултишопа
		// model.outletId = outletId;
		/*;
		 if ( !/\.csv$/.test( addedFile.name ) ) {
		 uploader.removeFromQueue( 0 );
		 model.includeTemplate = includeTemplate.formatError;
		 model.primaryButtonText = 'REPEAT_LOADING';
		 model.primaryAction = primaryAction.onStart;
		 model.primaryButtonDisabled = false;
		 } else if ( 5 * 1000 * 1000 < addedFile.size ) {
		 maxFileSize();
		 } else {
		 model.includeTemplate = includeTemplate.onAttach;
		 model.primaryAction = primaryAction.onAttach;
		 model.onAttachFileName = addedFile.name;
		 model.primaryAction = primaryAction.onAttach
		 }*/
	};


	uploader.onAfterAddingAll = function ( addedFileItems ) {
		//console.info( 'onAfterAddingAll', addedFileItems );
		if ( addedFileItems.length ) {
			model.primaryButtonDisabled = false;
			model.primaryButtonText = 'DOWNLOAD'
		}
		//var addedFile = addedFileItems[0]._file;
		/*//ограничение 5 мб
		 if(/\.csv$/.test(addedFile.name)){
		 model.includeTemplate = includeTemplate.onAttach;
		 model.primaryAction = primaryAction.onAttach;
		 model.onAttachFileName = addedFile.name;
		 }else{
		 uploader.cancelAll();
		 model.includeTemplate = includeTemplate.formatError;
		 model.primaryButtonText = 'REPEAT_LOADING';
		 model.primaryAction = primaryAction.onStart;
		 }*/
	};
	uploader.onBeforeUploadItem = function ( item ) {
		uploader.formData = [{}];
		//закомментировано в связи с ненадобностью параметра, который был добавлен в начале эпохи мултишопа
		// uploader.formData = [{outletId: outletId}];
		Array.prototype.push.apply(item.formData, uploader.formData);
		// console.info('onBeforeUploadItem', item );
	};
	uploader.onProgressItem = function ( fileItem, progress ) {
		//console.info( 'onProgressItem', fileItem, progress );
	};
	uploader.onProgressAll = function ( progress ) {
		//console.info( 'onProgressAll', progress );
	};



//*** функция onSuccessItem запускаеться после нажатия на кнопку ЗАГРУЗИТЬ и в ответ мы получаем все возможные варианты res.error и res.result
	uploader.onSuccessItem = function ( fileItem, response, status, headers ) {
		var res = null;
		try {
			res = JSON.parse( response );
		} catch ( err ) {
			console.error( err );
		}
		if ( !res ) {
			return;
		}

		//todo убрать
		//res.error = 'limit10000';
		if ( res.result == 'error' ) {
			if ( res.message == 'file_bad_encoding' ) {
				badEncoding();
			}
		} else {

			errorImportGoodsForMixpanel(res);
			// console.log("все варианты res.error СЮДА ВСЮ МИКПАНЕЛЬ если res.error != null");

			switch ( res.error ) {
				case "wrong_taxes_list":
					wrongTaxesList( res );
					break;
				case "wrong_header":
					wrongHeader( res );
					break;
				case "limit_200_variants":
					limit200variants( res );
					break;
				case "short_entry":  //Файл содержит неправильную информацию в названии столбцов. Убедитесь, что Вы используете тот же формат, как и файл шаблона.
					shortEntryResult();
					break;
				case "limit_10000":
					limit10000();
					break;
				case "error_list":
					downloadError( res );
					break;
				case null:
					downloadSuccess( res );
					break;
				default:
					wrongTaxesList( res );
			}
		}

		model.primaryButtonDisabled = false;
		//console.info( 'onSuccessItem', fileItem, response, status, headers );
	};
//*** функция onSuccessItem запускаеться после нажатия на кнопку ЗАГРУЗИТЬ и в ответ мы получаем все возможные варианты res.error и res.result



	uploader.onErrorItem = function ( fileItem, response, status, headers ) {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.onError;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
		model.primaryButtonDisabled = false;
		//console.info( 'onErrorItem', fileItem, response, status, headers );
	};
	uploader.onCancelItem = function ( fileItem, response, status, headers ) {
		//console.log( response );
		//console.info( 'onCancelItem', fileItem, response, status, headers );
	};
	uploader.onCompleteItem = function ( fileItem, response, status, headers ) {

		////console.info( 'onCompleteItem', fileItem, response, status, headers );
	};
	uploader.onCompleteAll = function () {
		if ( model.includeTemplate != includeTemplate.onError ) {
			//model.includeTemplate = includeTemplate.onStart;
			//model.includeHeadTemlate = includeHeadTemplate.onSuccess
		}
		//console.info( 'onCompleteAll' );
	};
	//console.info( 'uploader', uploader );


//***res.error = short_entry*** shortEntryResult запускаеться если res.error = short_entry
	function shortEntryResult() {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.onShortEntry;
		model.onCancel = cancelAction.onStart;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = short_entry*** shortEntryResult запускаеться если res.error = short_entry


//***res.error = limit10000*** limit10000 запускаеться если res.error = limit10000
	function limit10000() {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.limit10000;
		model.onCancel = cancelAction.onStart;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = limit10000*** limit10000 запускаеться если res.error = limit10000


//***res.error = null*** downloadSuccess запускаеться если файл нормально загружен и можно подтвердить импорт
	function downloadSuccess( res ) {
		/*res = {
		 result: 'ok',
		 error :null,
		 created: {
		 category: 4, //будет создано 4 категории
		 goods: 254, //будет создано товаров
		 edit: 85  // Будет отредактировано товаров
		 },
		 warnings: [
		 {
		 "columnError": "you_set_empty_field",
		 "columnName": "TITLE_NAME",  // соответсвует "Название"
		 replacement: "WILL_BE_ZERO", // будет присвоено значение 0,
		 type: "CELL", // ????
		 items: [    // ячейки
		 'D445',
		 'C445'
		 ],
		 andMore: 5 // еще 5 ячеек
		 }
		 ]
		 };*/
		var i = 0;
		while ( i < res.warnings.length ) {
			(function ( i ) {
				if ( res.warnings[i].columnName != null) {
					$translate(res.warnings[i].columnName)
						.then(function (val) {
							if (/^\s$/.test(val)) {
								res.warnings[i]._error = translate('ERROR.' + res.warnings[i].columnError, {name: '"' + res.warnings[i].columnName + '"'})
							} else {
								res.warnings[i]._error = translate('ERROR.' + res.warnings[i].columnError, {name: '"' + val + '"'})
							}
						});
				} else {
					res.warnings[i]._error = translate('ERROR.' + res.warnings[i].columnError, {name: '"' + res.warnings[i].columnName + '"'})
				}
			})( i );
			i++;
		}
		i = null;

		// console.log("res in downloadSuccess = ", res);

		model.includeTemplate = includeTemplate.onParserSuccess;
		model.includeHeadTemlate = includeHeadTemplate.onSuccess;
		model.response = res;
		model.primaryButtonText = 'REFRESH_GOODS';
		model.onCancel = cancelAction.onStart;
		model.primaryAction = primaryAction.onUpdateGoods;
	}
//***res.error = null*** downloadSuccess запускаеться если файл нормально загружен и можно подтвердить импорт


//***res.error = wrong_taxes_list*** wrongTaxesList запускаеться если res.error = wrong_taxes_list
	function wrongTaxesList() {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.wrongTaxesList;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = wrong_taxes_list*** wrongTaxesList запускаеться если res.error = wrong_taxes_list


//***res.error = wrong_header*** wrongHeader запускаеться если res.error = wrong_header
	function wrongHeader() {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.onWrongHeader;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = wrong_header*** wrongHeader запускаеться если res.error = wrong_header


//***res.error = limit_200_variants*** limit200variants запускаеться если res.error = limit_200_variants
	function limit200variants() {
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.onLimit200variants;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = limit_200_variants*** limit200variants запускаеться если res.error = limit_200_variants


//***res.error = error_list*** downloadError запускаеться если res.error = error_list
	function downloadError( res ) {
		/*res = {
		 "result": "ok",
		 error: "error_list",
		 totalError: 255,
		 "errorsList": [
		 {
		 "columnError": "max_price",
		 "columnName": "PRICE",
		 "type": "COLUMN",// : "" // столбцы
		 "items": [   // строки
		 "2D"
		 ],
		 "andMore": 10
		 },
		 {
		 "columnError": "max_price",
		 "columnName": "PRICE",
		 "type": "ROW",// : "" // столбцы
		 "items": [
		 "7K"
		 ],
		 "andMore": 0
		 }
		 ]
		 };*/
		uploader.removeFromQueue( 0 );
		model.includeTemplate = includeTemplate.onParserError;
		model.includeHeadTemlate = includeHeadTemplate.onParserError;
		var i = 0;
		while ( i < res.errorsList.length ) {
			(function ( _i ) {
				if ( res.errorsList[_i].columnName != null ) {
					$translate( res.errorsList[_i].columnName )
						.then( function ( val ) {
							if ( /^\s$/.test( val ) ) {
								res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
							} else {
								res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + val + '"'} )
							}
						});
				} else {
					res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
				}
			})( i );
			i++
		}
		i = null;
		model.response = res;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
	}
//***res.error = error_list*** downloadError запускаеться если res.error = error_list


//***res.result == 'error'*** badEncoding запускаеться если res.result == 'error'
	function badEncoding() {
		uploader.removeFromQueue( 0 );
		model.templateWidtn = null;
		model.includeTemplate = includeTemplate.badEncoding;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
		model.primaryButtonDisabled = false;
	}
//***res.result == 'error'*** badEncoding запускаеться если res.result == 'error'




	function maxFileSize() {
		uploader.removeFromQueue( 0 );
		model.templateWidtn = null;
		model.includeTemplate = includeTemplate.onMaxFileSize;
		model.primaryButtonText = 'REPEAT_LOADING';
		model.primaryAction = primaryAction.onStart;
		model.primaryButtonDisabled = false;
	}


	function checkFileSize() {
		if ( 5 * 1000 * 1000 <= model.fileSize ) {
			return false
		}
		return true;
	}


	function checkFileNameValid() {
		if ( /\.csv$/.test( model.onAttachFileName ) ) {
			return true
		}
		return false
	}



//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ
	function errorImportGoodsForMixpanel(resError) {
		if (resError.result == "ok" && resError.error != null) {
			// console.log("errorImportGoodsForMixpanel resError = ", resError);
			lvMixpanel.track('Import', {	"Event type"		: "Import items",
										"Event result"		: "Items import error",
										"Number of errors"	: resError.totalError});
		}
	}

	function successImportGoodsForMixpanel(resSuccess) {
		if (resSuccess.result == "ok" && resSuccess.error == null) {
			// console.log("successImportGoodsForMixpanel, resSuccess = ", resSuccess);
			lvMixpanel.track('Import', {	"Event type"			: "Import items",
										"Event result"			: "Items imported",
										"Number of items"		: resSuccess.created.goods + resSuccess.created.edit,
										"Number of warnings"	: resSuccess.warnings.length});
		}
	}

	model.importHelpForMixpanel = function() {
		lvMixpanel.track('Help', {'Property': 'Import items'});
	}
//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ


}

export default {
	type: 'controller',
	name: 'goodsImportControl',
	value: importFile
};
