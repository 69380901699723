/**
 * Created by kseniya.yarosh on 13.03.17.
 *
 * @ng-doc filter
 * @name app.filter: orderByTranslated
 *
 * @description
 *
 * This filter returns alphabetically sorted values of customized translated data for ng-repeat
 *
 * @returns {string} The string to be operated by filter
 * @returns {string}
 *
 * @example
 *
 * ng-repeat = 'paymentType in vmController.paymentTypes | orderByTranslated: "PAYMENT_TYPES.":"name"'
 */

import angular from 'angular';

orderByTranslated.$inject = ['$translate', '$filter'];
function orderByTranslated($translate, $filter) {
    return function (array, i18nKeyPrefix, objKeyAndBoolean) {
        let result = [];
        let translated = [];
        angular.forEach(array, function(value) {
            let i18nKeySuffix = objKeyAndBoolean[0] ? value[objKeyAndBoolean[0]] : value;
            translated.push({
                key: value,
                label: (objKeyAndBoolean[1] == true) ? (i18nKeyPrefix + i18nKeySuffix) : ($translate.instant(i18nKeyPrefix + i18nKeySuffix))
            });
        });
        angular.forEach($filter('orderBy')(translated, 'label'), function(sortedObject) {
            result.push(sortedObject.key);
        });
        return result;
    };
}

export default {
    type: 'filter',
    name: 'orderByTranslated',
    value: orderByTranslated
};
