/**
 * Created by A.Sirosh on 26.09.2015.
 * Edited by olehkss 13.07.2016
 * Edited by kseniya.yarosh 13.12.2016
 */

import angular from 'angular';

reportReceiptsControl.$inject = [
    '$state',
    '$stateParams',
    'factoryProfile',
    '$report',
    '$mdSidenav',
    'shareReceipt',
    '$exportParams',
    '$scope',
    '$currencyOptionsShow',
    '$percentShowTax',
    '$filter',
    'constantGreenExport',
    '$profile',
    'filtersData',
    '$location',
    'serviceShareReceipt',
    '$rootScope',
    '$permitsManager',
    '$miniBillingInfo',
    'serviceReports',
    '$reportCopyAllowed'
];

function reportReceiptsControl(
    $state,
    $stateParams,
    factoryProfile,
    $report,
    $mdSidenav,
    shareReceipt,
    $exportParams,
    $scope,
    $currencyOptionsShow,
    $percentShowTax,
    $filter,
    constantGreenExport,
    $profile,
    filtersData,
    $location,
    serviceShareReceipt,
    $rootScope,
    $permitsManager,
    $miniBillingInfo,
    serviceReports,
    $reportCopyAllowed
) {
    let vm              = this;
    let taxPercentCommon = $filter('taxPercentCommon');
    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.report           = $report;
    vm.reportError = vm.report.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && vm.report.unableToLoadData;

    vm.downloadHrefBase1 = '/data/ownercab/receiptsreportdownload';
    vm.downloadHrefBase2 = '/data/ownercab/downloadReceiptsDetail';

    $scope.profile = $profile;
    $scope.permits = $rootScope.permits;

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($scope.profile.cabinetLang) !== -1;

    if (!vm.reportError) {
        vm.periodAllowed = $report.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        $scope.outlets = $report.fillSelectData.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        vm.stateParams = $stateParams;
        if ($report.startDateString && $report.endDateString) {
            let fromParse = $report.startDateString.split('-');
            let toParse = $report.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $report.startDate;
            vm.endDate = $report.endDate;
        }

        if (vm.periodAllowed) {
            $scope.receiptsTypes = [
                {
                    typeName: "SALE"
                },
                {
                    typeName: "REFUND"
                }
            ];
            vm.selectReceipt = selectReceipt;
            vm.onFilter = onFilter;
            vm.pages = $report.pages;

            //Task # 19957 Change stubs on Advanced inventory screens
            vm.plugsSearch = !!$stateParams.search;

            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.report.receipts.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
                $state.go('report.receipts', {page: vm.pages - 1});
            } else if (vm.report.receipts.length === 0 && $stateParams.page > 0 && vm.pages === 0) {
                $state.go('report.receipts', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            /*** формат отображения процентов ***/
            $scope.percentShowTax = $percentShowTax;

            /*поиска элемента ACCESS_CANCEL_RECEIPTS в массиве $permits */
            $scope.accessCancelReceipts = $permitsManager.canCancelReceipts;

            $scope.arapro = $scope.profile.cabinetLang === 'ara' || $scope.profile.cabinetLang === 'urd' || $scope.profile.cabinetLang === 'heb';

            vm.report.receipts.forEach(
                function (receipt) {
                    if (receipt.taxAmount.taxesDataList.length > 0) {
                        receipt.taxAmount.taxesDataList.forEach(function (tax) {
                            tax.value = taxPercentCommon(tax.value, $scope.currencyOptionsShow.decimalCharacter);
                        });
                    }
                }
            );

            function receptFromStateParams(receiptId) {
                for (let i = 0; i < vm.report.receipts.length; i++) {
                    if (vm.report.receipts[i].receiptId === receiptId) {
                        return vm.report.receipts[i];
                    }
                }
            }

            if (vm.report.receipts.length && $stateParams.receiptId) {
                let receipt = receptFromStateParams(parseFloat($stateParams.receiptId));
                selectReceipt(receipt, $scope.currencyOptionsShow, $scope.percentShowTax,
                    $scope.profile.receiptFormat, $scope.accessCancelReceipts, $scope.arapro);
                let stateParams = filtersData.getStateParams();
                stateParams.receiptId = null;
                filtersData.setStateParams(stateParams);
                $location.search($stateParams);
            }

            vm.exportParams = $exportParams;
            $stateParams.loyalty = vm.exportParams.loyalty;
            $stateParams.type = vm.exportParams.type;

            function onFilter(filter) {
                $state.go('report.receipts', {type: filter, offset: 0, page: 0});
            }

            /*Task #20594 Убрать фильтр Receipts и сделать табы
            * по дефолту ALL activated если кляцаем на др таб то после перезагрузки стр проверяется условие по
            * $stateParams.type если проходит то убираем класс activated у перв элеманта и присваеваем одному из 3х */
            let element = angular.element(document.querySelectorAll('.r-n-i'));
            element[0].classList.add("activated");

            //check in filter in head report-receipts.jade
            if ($stateParams.type === "SALE") {
                element[0].classList.remove("activated");
                element[1].classList.add("activated")
            } else if ($stateParams.type === "REFUND") {
                element[0].classList.remove("activated");
                element[2].classList.add("activated")
            }
        }


        function selectReceipt(receipt, currencyOptionsShow, percentShowTax,
                               receiptFormat, accessCancelReceipts, arapro) {
            serviceShareReceipt.selectReceipt(receipt, currencyOptionsShow, percentShowTax,
                receiptFormat, accessCancelReceipts, arapro);
        }
    }
}

export default {
    type: 'controller',
    name: 'reportReceiptsControl',
    value: reportReceiptsControl
};
