/**
 * Created by olehkss on 11.05.16.
 * @ngdoc factory
 * @name app.factory:discountsSummary
 * @description
 * A factory that gets the discounts reports from server
 */

import angular from 'angular';

discountsSummary.$inject = ['promiseOperation', 'serviceReportsError'];

function discountsSummary(promiseOperation, serviceReportsError) {
    return {
        getDiscountsSummary: getDiscountsSummary
    };

    /**
     * @ngdoc function
     * @param {Object} params parameters for server request
     * @returns {Promise}
     */
    function getDiscountsSummary(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getdiscountsreport',
            params,
            successData,
            serviceReportsError.reportsError
        );
    }

    /**
     * @ngdoc function
     * @param response
     * @returns {Object} preprocessed data from server
     */
    function successData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                let discountsResponse = {
                    outlets: response.data.fillSelectData.outlets,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    startDateString: response.data.startDateString,
                    endDateString: response.data.endDateString,
                    periodAllowed: response.data.periodAllowed
                };
                if (response.data.periodAllowed) {
                    discountsResponse.data = response.data.reportItems;
                    discountsResponse.pages = response.data.pages;
                }

                return discountsResponse;
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'discountsSummary',
    value: discountsSummary
};
