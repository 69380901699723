/**
 * Константа возможных склонений согласно правилам русского языка
 * Created by Shestakov on 15.01.20.
 */

const declensionsConst = {
    onlyOne: "ONLY_ONE",
    everyFirst: "EVERY_FIRST",
    twoToFour: "TWO_TO_FOUR",
    fiveToTen: "FIVE_TO_TEN"
};

export default {
    type: 'constant',
    name: 'declensionsConst',
    value: declensionsConst
};