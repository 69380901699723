createDirective.$inject = [];

function createDirective() {
    let resultDirective = {
        restrict: 'E',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, ctrl) {
        if (attrs['containerClass']) {
            element.find('.md-select-menu-container').addClass(attrs['containerClass']);
        }
    }

    return resultDirective;
}

export default {
    type: 'directive',
    name: 'mdSelect',
    value: createDirective
};
