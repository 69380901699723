/**
 * Created by sirosh on 13.01.16.
 * Edited by kseniya.yarosh on 13.12.16
 * @memberof createtaxCtrl
 * @ngdoc controller
 * @name createtaxCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter servise
 * @requires $state {service} UI Router servise
 * @requires $stateParams {service} UI Router servise
 * @requires constTaxOptions {service} Tax types
 * @requires constTaxActions {service}
 * @requires getTaxList {service} Get tax list / operation with tax
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 * @requires $tax {service} Get tax list before load page
 */

import angular from "angular";

taxCtrl.$inject = [
  "$scope",
  "$filter",
  "$state",
  "$stateParams",
  "getTaxList",
  "serviceDialog",
  "$tax",
  "serviceToast",
  "$profile",
  "siteLangCode",
  "$shortOutlets",
  "$percentShowTax",
  "$translate",
  "lvMixpanel",
  "dialogs",
  "factoryPopover",
  "$window",
];

function taxCtrl(
  $scope,
  $filter,
  $state,
  $stateParams,
  getTaxList,
  serviceDialog,
  $tax,
  serviceToast,
  $profile,
  siteLangCode,
  $shortOutlets,
  $percentShowTax,
  $translate,
  lvMixpanel,
  dialogs,
  factoryPopover,
  $window
) {
  var translate = $filter("translate"),
    taxPercentFormatShowInEdit = $filter("taxPercentFormatShowInEdit"),
    alphabetFirstSeven = ["A", "B", "C", "D", "E", "F", "G"],
    romanaGroups = ["A", "B", "C", "D", "E", "#", "S"],
    greeceGroups = ["Α", "Β", "Γ", "Δ", "Ε"],
    fiscalCountries = ["pl", "sk", "ru", "ro", "it", "gr"],
    countryIsFiscal = fiscalCountries.indexOf($profile.country) > -1;
  $scope.profile = angular.copy($profile);
  $scope.editTableState = "edittax";

  // Локализация процентов для налогов
  $scope.percentShowTax = $percentShowTax;

  $scope.taxes = $tax.taxes.map(function (item) {
    return {
      allowedForAllOutlets: item.allowedForAllOutlets,
      applyForAllNewWares: item.applyForAllNewWares,
      byOutlets: item.byOutlets,
      id: item.id,
      isPrintedToFiscal: item.isPrintedToFiscal
        ? item.isPrintedToFiscal
        : item.taxGroup !== null,
      name: item.name,
      taxGroup:
        item.taxGroup && countryIsFiscal && $profile.country === "ro"
          ? romanaGroups[item.taxGroup - 1]
          : item.taxGroup && countryIsFiscal && $profile.country === "gr"
          ? greeceGroups[item.taxGroup - 1]
          : item.taxGroup &&
            countryIsFiscal &&
            alphabetFirstSeven[item.taxGroup - 1]
          ? alphabetFirstSeven[item.taxGroup - 1]
          : item.taxGroup,
      type: item.type,
      value: parseFloat(taxPercentFormatShowInEdit(item.value)),
      waresCount: item.waresCount,
    };
  });
  $scope.waresCount = 0;
  $scope.delete = false;
  $scope.taxesSelect = [];
  $scope.allTax = {};
  $scope.profile = $profile;

  for (var i = 0; i < $scope.taxes.length; i++) {
    $scope.taxes[i]["_select"] = false;
  }

  //-- CODE to save the array of IDs of the selected TAXES
  $scope.$watch(
    "taxes",
    function (newVal) {
      for (var j = 0; j < newVal.length; j++) {
        if (newVal[j]._select === true) {
          $scope.taxesSelect.push(newVal[j]._select);
          $scope.waresCount += newVal[j].waresCount;
        }
      }
      $scope.delete = $scope.taxesSelect.length > 0;

      $scope.allTax._select = $scope.taxesSelect.length === $scope.taxes.length;

      $scope.taxesSelect = [];
    },
    true
  );
  //-- END CODE to save the array of IDs of the selected TAXES

  // BLOCK WRITTEN UNDER MULTISHOP
  $scope.shortOutlets = $shortOutlets.slice();
  $scope.shortOutlets.unshift({
    id: null,
    name: $translate.instant("MULTISHOP.CASH_REGISTER.OLL_OUTLETS"),
  });

  $scope.selectOutlet = {
    id: $stateParams.outletsIds ? +$stateParams.outletsIds : null,
  };

  $scope.selectOutletId = $scope.selectOutlet.id;

  //  state check load content or not for show empty state between chose shop
  $scope.dataIsLoading = false;

  //-- BLOCK FOLLOWING SELECT OF THE INSTITUTION AND THE TRANSITION
  // WHEN SELECTING THE INSTITUTION
  $scope.$watch(
    "selectOutlet",
    function (newval, oldval) {
      if (oldval != newval) {
        $scope.dataIsLoading = true;
        $state
          .go("settings.tax", {outletsIds: newval.id, page: 0})
          .then(function () {
            $scope.selectOutletId = newval.id;
            $scope.dataIsLoading = false;
          });
      }
    },
    true
  );
  //-- END BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE
  // TRANSITION WHEN SELECTING THE INSTITUTION
  //-- END BLOCK WRITTEN UNDER MULTISHOP

  $scope.changeAll = function () {
    if ($scope.allTax._select === true) {
      for (var k = 0; k < $scope.taxes.length; k++) {
        $scope.taxes[k]["_select"] = true;
      }
      $scope.allTax._select = true;
    } else {
      $scope.allTax._select = false;
      for (var m = 0; m < $scope.taxes.length; m++) {
        $scope.taxes[m]["_select"] = false;
      }
    }
  };

  $scope.onAdd = function () {
    if ($scope.taxes.length > 99) {
      serviceDialog.add({
        templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
        title: translate("SETTINGS.TAX.UNABLE_ADD_TAX"),
        content: translate("SETTINGS.TAX.ONLY_TEN_TAX"),
        buttons: [
          {
            class: "primary",
            text: "ON_OK",
          },
        ],
      });
    } else {
      $state.go("createtax", $stateParams, {});
    }
  };

  $scope.onDel = function () {
    $scope.taxIds = [];
    for (var i = 0; i < $scope.taxes.length; i++) {
      if ($scope.taxes[i]._select === true) {
        $scope.taxIds.push($scope.taxes[i].id);
      }
    }

    if ($scope.taxIds.length === 1) {
      $scope.msgTitle = translate("SETTINGS.TAX.DELETE_TAX_TITLE_ONE");
      $scope.tostTitle = "SETTINGS.TAX.TOSTS.DELETED_TAX_ONE";
      $scope.contentNoWares = translate(
        "SETTINGS.TAX.DELETE_MESSAGE_NO_WARES_ONE"
      );
      $scope.contentHasWares = translate(
        "SETTINGS.TAX.DELETE_MESSAGE_HAS_WARES_ONE"
      );
    } else {
      $scope.msgTitle = translate("SETTINGS.TAX.DELETE_TAX_TITLE_SEVERAL");
      $scope.tostTitle = "SETTINGS.TAX.TOSTS.DELETED_TAX_SEVERAL";
      $scope.contentNoWares = translate(
        "SETTINGS.TAX.DELETE_MESSAGE_NO_WARES_FEW"
      );
      $scope.contentHasWares = translate(
        "SETTINGS.TAX.DELETE_MESSAGE_HAS_WARES_FEW"
      );
    }

    $scope.onSendData = function () {
      getTaxList.deleteTax({taxIds: $scope.taxIds}).then(function (data) {
        if (data.result === "ok") {
          serviceToast.show($scope.tostTitle, "success");
          refreshList();
        }
        if (data.result === "error") {
          if (data.message === "tax_deleted") {
            dialogs.listItemDeletedEarlier().then(refreshList);
          }
        }
      });
    };

    if ($scope.waresCount === 0) {
      serviceDialog.add({
        templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
        title: $scope.msgTitle,
        content: $scope.contentNoWares,
        buttons: [
          {
            text: "ON_CANCEL",
          },
          {
            class: "primary",
            text: "ON_DEL",
            action: $scope.onSendData,
          },
        ],
      });
    } else {
      serviceDialog.add({
        templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
        title: $scope.msgTitle,
        content: $scope.contentHasWares,
        buttons: [
          {
            text: "ON_CANCEL",
          },
          {
            class: "primary",
            text: "ON_DEL",
            action: $scope.onSendData,
          },
        ],
      });
    }
  };

  function refreshList() {
    $state.go("settings.tax", $stateParams, {
      reload: true,
    });
  }

  //-- Task #25633 Update stubs for empty states
  emptyStateLink();
  $scope.emptyStateLink = emptyStateLink;

  function emptyStateLink() {
    let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
    // url lang where we have info
    let urlLang = [
      "ru",
      "es",
      "jp",
      "ko",
      "idn",
      "de",
      "ro",
      "th",
      "vn",
      "fr",
      "ar",
      "br",
      "it",
      "pl",
      "gr",
      "zh",
    ];
    //emptyStateCategory Learn more
    $scope.emptyStateLearnMoreTax =
      urlLang.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" +
          siteUrlLang +
          "/help/how-configur-taxes?utm_source=Back%20Office&utm_medium=Taxes "
        : "https://help.loyverse.com/help/how-configur-taxes?utm_source=Back%20Office&utm_medium=Taxes ";
  }
  //-- END Task #25633 Update stubs for empty states
  //-- Task #25853 Add events to Mixpanel for transitions to help
  $scope.trackTaxesHelp = function () {
    lvMixpanel.track("Help", {Property: "Taxes"});
  };
  //-- END Task #25853 Add events to Mixpanel for transitions to help

  $scope.popover = factoryPopover;

  $scope.openPopovers = {
    tax: false,
  };

  $scope.breakpoints = {
    0: "left",
    400: "top-right",
  };

  const onResize = () => {
    $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
  };

  this.$onInit = () => $window.addEventListener("resize", onResize);
  this.$onDestroy = () => $window.addEventListener("resize", onResize);
}

export default {
  type: "controller",
  name: "taxCtrl",
  value: taxCtrl,
};
