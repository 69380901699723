/**
 * Created by alex on 18.10.16.
 */

const waresReportTableModifiers = {
    modifier         : 'MODIFIERS.MODIFIER',
    quantitySold     : 'REPORT_SALES.QTY_SOLD',
    grossSales       : 'REPORT_SALES.GROSS_SALES',
    quantityRefunded : 'MODIFIERS.QUANTITY_REFUNDED',
    refunds          : 'REPORT_SALES.REFUNDS',
    netSales         : 'REPORT_SALES.NET_SALES'
};

export default {
    type: 'constant',
    name: 'waresReportTableModifiers',
    value: waresReportTableModifiers
}