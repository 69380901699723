createDirective.$inject = [];

function createDirective() {

    return {
        restrict: 'A',
        controller: AdaptiveItemCloneController,
        bindToController: true,
        require: {
            container: '^lvAdaptiveContainer'
        },
    };
}

AdaptiveItemCloneController.$inject = ['$element', '$attrs', '$parse', 'domUtils'];
function AdaptiveItemCloneController($element, $attrs, $parse, domUtils) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;

    let self = this;
    let api = {
        isOverflowed: isOverflowed,
        setFont: setFont
    };
    let contentElement;

    function isOverflowed() {
        return domUtils.isElementOverflowed(contentElement);
    }

    function setFont(selectedIndex, classes) {
        classes.forEach((fontClass, index) => {
            contentElement.toggleClass(fontClass, index === selectedIndex);
        });
    }

    function $onInit() {
        $element.addClass('adaptive-container-item');
    }

    function $postLink() {
        self.container.registerClone(api);
        contentElement = $element.find('[lv-adaptive-item-content]');
    }

}

export default {
    type: 'directive',
    name: 'lvAdaptiveItemClone',
    value: createDirective
};
