/**
 * Created by olehkss on 14.04.16.
 *
 * @ng-doc filter
 * @name app.filter: abs
 *
 * @description
 *
 * This filter returns the absolute value of the given number | string
 *
 * @returns {number|string} The number to be operated by filter
 * @returns {Number}
 *
 * @example
 *
 * {{-600 | abs}}
 */

function getAbsoluteValue () {
    return function (val) {
        return Math.abs(val);
    };
}

export default {
    type: 'filter',
    name: 'abs',
    value: getAbsoluteValue
};
