import angular from 'angular';
import ngLocalStorage from 'angular-local-storage';
import ngTranslate from 'angular-translate';

require('angular-translate/dist/angular-translate-loader-url/angular-translate-loader-url');
require('angular-translate/dist/angular-translate-storage-cookie/angular-translate-storage-cookie');
require('angular-translate/dist/angular-translate-storage-local/angular-translate-storage-local');

import serviceLang from './service-lang';

export default angular
    .module('lv.langs', [ngLocalStorage, ngTranslate])
    .service('serviceLang', serviceLang)
    .name;