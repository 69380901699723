/**
 * Created by alex on 22.12.16.
 */

import angular from 'angular'

recieptsCtrl.$inject = ['$reciepts',
    'getReceipt',
    'constantReceiptLangCollection',
    'serviceDialog',
    'serviceToast',
    '$shortOutlets',
    '$stateParams',
    '$scope',
    '$state',
    '$rootScope',
    '$timeout',
    '$window',
    '$profile',
    'promiseOperation',
    'FileUploader',
    'factoryPopover',
    '$translate'
];

function recieptsCtrl(
    $reciepts,
    getReceipt,
    constantReceiptLangCollection,
    serviceDialog, serviceToast,
    $shortOutlets,
    $stateParams,
    $scope,
    $state,
    $rootScope,
    $timeout,
    $window,
    $profile,
    promiseOperation,
    FileUploader,
    factoryPopover,
    $translate
) {
    const vm = this;
    $scope.profile = angular.copy($profile);
    $scope.isMobile = $rootScope.isMobile;

    vm.opts = $reciepts;

    const noLang = !vm.opts.lang || vm.opts.lang === "";
    vm.opts.lang = noLang ? $scope.profile.lang : vm.opts.lang;
    vm.opts._emailLogo = null;
    vm.opts._printLogo = null;

    let optsCopy = angular.copy($reciepts),
        offStateChangeStart;
    const hideDelButton = (logo, logoUrl) => !logo && !logoUrl;

    $scope.emailLogoDeleted = optsCopy.emailLogoUrl === null;
    $scope.printLogoDeleted = optsCopy.printLogoUrl === null;
    $scope.emailLogoHovered = false;
    $scope.printLogoHovered = false;
    $scope.emailLogoHideDelButton = hideDelButton(vm.opts._emailLogo, vm.opts.emailLogoUrl);
    $scope.printLogoHideDelButton = hideDelButton(vm.opts._printLogo, vm.opts.printLogoUrl);

    $scope.constantLangCollection = constantReceiptLangCollection;
    $scope.receiptsLoader = true;
    $scope.isSaudi = $scope.profile.country === "sa";

    let arapro = $scope.profile.cabinetLang === 'ara' || $scope.profile.cabinetLang === 'urd' || $scope.profile.cabinetLang === 'heb';

    $scope.hintPlacement = arapro ? "left" : "right";

    $scope.shortOutlets = $shortOutlets;

    $scope.selectOutlet = {
        id:     ($stateParams.outletId) ? $stateParams.outletId : $scope.shortOutlets[0].id,
        name:   ($stateParams.outletName) ? $stateParams.outletName : $scope.shortOutlets[0].name
    };

    $scope.changeOutlet = () => {
        defineOutletName($scope.selectOutlet.id)
        $state.go("settings.reciepts", {outletId: $scope.selectOutlet.id, outletName: $scope.selectOutlet.name})
          .then(() => defineOutletName($scope.selectOutlet.id));
    }

    $scope.$watch('recCtrl.opts._emailLogo', function (newVal, oldVal) {
        if (newVal != oldVal) {
            $scope.emailLogoHideDelButton = hideDelButton(newVal, vm.opts.emailLogoUrl)
        }
    });

    $scope.$watch('recCtrl.opts._printLogo', function (newVal, oldVal) {
        if (newVal != oldVal) {
            $scope.printLogoHideDelButton = hideDelButton(newVal, vm.opts.printLogoUrl)
        }
    });

    $scope.$watch('emailLogoDeleted', function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
            $scope.emailLogoHideDelButton = true;
            vm.opts.emailLogoUrl = null;
            vm.opts.emailLogoBlob = null;
        }
    });

    $scope.$watch('printLogoDeleted', function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
            $scope.printLogoHideDelButton = true;
            vm.opts.printLogoUrl = null;
            vm.opts.printLogoBlob = null;
        }
    });



    $scope.onSave = () => {

        vm.opts.emailLogoDeleted = optsCopy.emailLogoUrl !== vm.opts.emailLogoUrl && optsCopy.emailLogoUrl !== null;
        vm.opts.printLogoDeleted = optsCopy.printLogoUrl !== vm.opts.printLogoUrl && optsCopy.printLogoUrl !== null;

        const attributesToSet = Object.fromEntries(Object.entries(vm.opts)
          .filter(([key, value]) => key !== 'emailLogoUrl' && key !== 'printLogoUrl' && !key.match(/^_/)));

        getReceipt.setReceiptAttributes(attributesToSet)
            .then((res) => {
                if (res.result === "ok") {
                    serviceToast.show('RECIEPT.EDITED', 'success');
                    optsCopy = angular.copy(vm.opts);
                    offStateChangeStart();
                    $state.go($state.current.name, {}, {reload: true});
                }
            });
    };


    $scope.onCancel = function() {
        $scope.emailLogoDeleted = optsCopy.emailLogoUrl == null;
        $scope.printLogoDeleted = optsCopy.printLogoUrl == null;
        vm.opts = angular.copy(optsCopy);
    };

    function defineOutletName(outletId) {
        const outlet = $scope.shortOutlets.find(outlet => outlet.id === outletId);
        $scope.selectOutlet.name = outlet.name;
    }

    $scope.uploader1 = new FileUploader({
        autoUpload: false
    });
    $scope.uploader2 = new FileUploader({
        autoUpload: false
    });

    $scope.uploader1.onAfterAddingFile = (fileItem)=> {
        vm.opts.emailLogoBlob = fileItem._file;
        vm.opts.emailLogoUrl = URL.createObjectURL(fileItem._file);
        $scope.emailLogoDeleted = false;
        $scope.$apply();
    };

    $scope.uploader2.onAfterAddingFile = ( fileItem) => {
        vm.opts.printLogoBlob = fileItem._file;
        vm.opts.printLogoUrl = URL.createObjectURL(fileItem._file);
        $scope.printLogoDeleted = false;
        $scope.$apply();
    };

    offStateChangeStart = $rootScope.$on( '$stateChangeStart', function ( event, toState, toParam, fromState, fromParam ) {
        const toCompare = {
            emailLogoUrl: vm.opts.emailLogoUrl,
            includeCustomerInfo: vm.opts.includeCustomerInfo,
            includeNotes: vm.opts.includeNotes,
            lang: vm.opts.lang,
            outletId: vm.opts.outletId,
            printLogoUrl: vm.opts.printLogoUrl,
            receiptFooter: vm.opts.receiptFooter,
            receiptHeader: vm.opts.receiptHeader,
            _emailLogo: vm.opts._emailLogo,
            _printLogo: vm.opts._printLogo
        };

        if (!angular.equals(optsCopy, toCompare) && !vm.opts.emailLogoImg && !vm.opts.printLogoImg) {
            event.preventDefault();
            $timeout( function () {
                serviceDialog.add({
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: $translate.instant('UNSAVED_TITLE'),
                    content: $translate.instant('UNSAVED_LEAVE'),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {
                                var firstOutlet = typeof ($scope.shortOutlets[0].id) === "number" ? ($scope.shortOutlets[0].id).toString() : $scope.shortOutlets[0].id;
                                $scope.selectOutlet.id = ($stateParams.outletId) ? $stateParams.outletId : firstOutlet;
                                defineOutletName($scope.selectOutlet.id);
                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go(toState, toParam);
                            }
                        }
                    ]
                });
            } );
        }
    });


    $scope.$on('$destroy', function(){
        offStateChangeStart();
    });


    $scope.goStoresPage = function () {
        $state.go('settings.outlet', $stateParams);
    }

    $scope.popover = factoryPopover;

    $scope.openPopovers = {
        vat: false
    };

    const onResize = () => {
      $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };

    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);
}

export default {
    type: 'controller',
    name: 'recieptsCtrl',
    value: recieptsCtrl
};
