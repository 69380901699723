/**
 * Created by sirosh on 27.01.16.
 * @memberof digitPixels
 * @ngdoc directive
 * @name digitPixels
 * @param arrayOperation {service} - operations with array
 */

digitPixels.$inject = [ "arrayOperation"];

function digitPixels(arrayOperation) {

    return {

        restrict: 'A',
        scope: {
            digitPixels: '=',
            digits: '='
        },
        /**
         * @param scope
         * @param element
         */
        link: function (scope, element) {

            var max = arrayOperation.getMaxOfArray(scope.digits);
            var maxLength = max.toString().length;
            var i;
            var len = scope.digitPixels.length;

            for(i=0; i < len; i++){

                    if(maxLength >= scope.digitPixels[i].from && maxLength <= scope.digitPixels[i].to  ){
                        $(element).css({
                            "font-size" : scope.digitPixels[i].px + 'px'
                        });
                }
            }

            i = null;
            len = null;
        }
    };
}

export default {
    type: 'directive',
    name: 'digitPixels',
    value: digitPixels
};