/**
 * Created by Alex on 14.03.17.
 */

vantivAuthenticationController.$inject = ['$scope', '$state', '$stateParams', 'terminalOperationsFactory', 'serviceDialog', '$filter'];

function vantivAuthenticationController($scope, $state, $stateParams, terminalOperationsFactory, serviceDialog, $filter) {

    $scope.isSavingNow = false;
    $scope.authenticationDate = {};
    var translate = $filter( 'translate' );

    $scope.onCancel = function() {
        $state.go('settings.paytypes');
    }

    $scope.onContinue = function() {
        if ($scope.authenticationDate.merchantNumber.length > 0 &&  $scope.authenticationDate.wsPassword.length > 0) {
            $scope.isSavingNow = true;
            terminalOperationsFactory
                .checkAuthentication( $scope.authenticationDate )
                .then( function ( d ) {
                    if ( d.result === 'ok' && d.validCredentials === true ) {
                        $state.go('vantiv.registration', {  paymentTypeId:  $stateParams.paymentTypeId,
                                                            merchantNumber: $scope.authenticationDate.merchantNumber,
                                                            wsPassword:     $scope.authenticationDate.wsPassword});
                    } else if ( d.result === 'ok' && d.validCredentials === false ) {
                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                            title      : translate('VANTIV_AUTHENTICATION.ERROR_MESSAGE_HEADER'),
                            content    : translate('VANTIV_AUTHENTICATION.ERROR_MESSAGE_BODY'),
                            buttons    : [
                                {
                                    class : 'primary',
                                    text  : 'VANTIV_AUTHENTICATION.ERROR_MESSAGE_CONFIRM',
                                    action: function () {
                                        $scope.isSavingNow = false;
                                    }
                                }
                            ]
                        });
                    }
                });
        }
    }

}

export default {
    type: 'controller',
    name: 'vantivAuthenticationController',
    value: vantivAuthenticationController
};