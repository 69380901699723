/**
 * Created by mars on 3/3/16.
 */

decorator.$inject = ['$provide'];
function decorator($provide) {
	$provide.decorator('factoryProfile', factoryProfile);

	factoryProfile.$inject = ['$delegate'];
	function factoryProfile($delegate) {
		$delegate.env = "world";

		return $delegate;
	}
}

config.$inject = ['factoryProfileProvider'];
function config(factoryProfileProvider) {
	 factoryProfileProvider.env = "world";
}

export default [
	{
		type: 'config',
		value: decorator
	},
	{
		type: 'config',
		value: config
	}
];