/*** Created by Alex 16.11.16 ***/

import angular from 'angular';

editKitchenCtrl.$inject = ["$scope", "$state", "$stateParams", "$filter",
    "getKitchen", "serviceDialog", "serviceToast", "$kitchenForEdit",
	'dialogs'];
function editKitchenCtrl($scope, $state, $stateParams, $filter,
						 getKitchen, serviceDialog, serviceToast, $kitchenForEdit,
						 dialogs) {

	var translate = $filter( 'translate' );
	$scope.clicked = true;
	$scope.categoriesList = {};
	$scope.newKitchenPrint = {
								name: '',
								wareCategories: [],
								isNameChanged: false
							 };
	$scope.uniqueName = true;
	$scope.progress = false;

	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
	function isChanged() {
		return !angular.equals($scope.newKitchenPrint, $scope.cloneNewKitchenPrint);
	}

	$scope.kitchenForEdit = $kitchenForEdit;

	$scope.categoriesList = $scope.kitchenForEdit.categories;
	$scope.newKitchenPrint.name = $scope.kitchenForEdit.name;
	$scope.newKitchenPrint.id = $scope.kitchenForEdit.id;

	var i = 0;
	while ( i < $scope.kitchenForEdit.categories.length ) {
		if ( $scope.kitchenForEdit.categories[i].isThisPrinterCategory === true ) {
			$scope.newKitchenPrint.wareCategories.push($scope.kitchenForEdit.categories[i].id);
		}
		i++
	};

	$scope.cloneNewKitchenPrint = angular.copy($scope.newKitchenPrint);

	$scope.toggle = function ($index, id, wareCategories) {

		var idx = wareCategories.indexOf(id);
		if (idx > -1) {
			wareCategories.splice(idx, 1);

			$scope.categoriesList[$index].isAvailable = true;
			$scope.categoriesList[$index].isThisPrinterCategory = false;

		}
		else {
			wareCategories.push(id);
			$scope.categoriesList[$index].isAvailable = false;
			$scope.categoriesList[$index].isThisPrinterCategory = true;

		}
	};

	$scope.addClassForCategory = function (category) {
		if(category.isAvailable === false && category.isThisPrinterCategory === false){
			return "disabled";
		} else if(category.isAvailable === false && category.isThisPrinterCategory === true){
			return "md-checked";
		}
	};

	$scope.onSave = function () {
		if ( $scope.clicked == false ) {
			return false;
		}

		if ($scope.createKitchen.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.createKitchen.focusFirstInvalidControl();
			return false;
		}

		if ( $scope.newKitchenPrint.name != $scope.cloneNewKitchenPrint.name ) {
			$scope.newKitchenPrint.isNameChanged = true;
		}

		function send() {
			$scope.clicked = false;
			$scope.progress = true;
			getKitchen.editKitchen( $scope.newKitchenPrint ).then( function ( res ) {
				if ( res.result === "ok" && !res.message ) {
					offStateChangeStart();
					serviceToast.show( 'KITCHEN.TOSTS.EDIT_KITCHEN_PRINTER', 'success' );
					backToList();
				} else if(res.result === "error"){
					if (res.message === 'kitchen_printer_deleted') {
						offStateChangeStart();
						dialogs.itemDeletedEarlier().then(backToList);
					} else if(res.message === 'printer with same name already exists') {
						$scope.uniqueName = false;
						serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
					} else {
						$scope.clicked = true;
						showErrorAlert( res.message )
					}
				}
			}, function () {
				$scope.clicked = true;
			})
			.finally(() => {
				$scope.progress = false;
			})
		}

		send();
	};


	function showErrorAlert( message ) {
		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate( 'ATTENTION' ),
			content: translate( 'KITCHEN.ERR_EDIT_KITCHEN_PRINTER' ),
			buttons: [{	class: 'primary',
						text: 'ON_OK'
					}]
		} );
	}

	$scope.onCancel = function () {
		backToList();
	};

	$scope.onDel = function(){
		$scope.msgTitle =  translate('KITCHEN.DELETE_KITCHEN_PRINTER');
		$scope.contentNoWares = translate('KITCHEN.DELETE_MESSAGE_KITCHEN_PRINTER');
		$scope.tostTitle = 'KITCHEN.TOSTS.DELETED_KITCHEN_PRINTER';

		$scope.onSendData = function(){
			getKitchen.deleteKitchen({printerIds: [$scope.newKitchenPrint.id]}).then(res => {
				if (res.result === "ok"){
					offStateChangeStart();
					serviceToast.show($scope.tostTitle, 'success');
					backToList();
				}
				if (res.result === 'error' && res.message === 'kitchen_printer_deleted') {
					offStateChangeStart();
					dialogs.itemDeletedEarlier().then(backToList);
				}
			});
		};

		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: $scope.msgTitle,
			content: $scope.contentNoWares,
			buttons: [  { 	text: 'ON_CANCEL' },
						{ 	class: 'primary l-margin-left-8-i',
							text: 'ON_DEL',
							action: $scope.onSendData }
					]
		});

	};

	function backToList() {
		$state.go('settings.kitchen', $stateParams, {
			reload: true
		});
	}

}

export default {
	type: 'controller',
	name: 'editKitchenCtrl',
	value: editKitchenCtrl
};