serviceBackEvent.$inject = ['$document'];

function serviceBackEvent($document) {
    var onPrevent = false;

    function f(e){
        // console.log("e= ", e);
        if(e.which === 8 && e.target.nodeName !== "INPUT"
            && e.target.nodeName !== "SELECT"
            && !e.target.classList.contains("md-chip-content")
            && e.target.nodeName !== "TEXTAREA"){ // you can add others here.
            e.preventDefault();
        }

        if(e.keyCode==32 && e.target.nodeName !== "INPUT" && e.target.nodeName !== "TEXTAREA"){return false;}

        if(e.keyCode==13 && e.target.nodeName !== "TEXTAREA" && e.target.nodeName !== "BUTTON"){return false;}

        // if (e.which == 9) {
        //     var dialog = document.getElementsByClassName("dialogs-container")[0];
        //     if (dialog.getElementsByTagName("input").length > 0) {
        //         dialog.getElementsByTagName("input")[0].focus();
        //     } else if (dialog.getElementsByTagName("textarea").length > 0) {
        //         dialog.getElementsByTagName("textarea")[0].focus();
        //     } else if (dialog.getElementsByTagName("button").length > 0) {
        //         dialog.getElementsByTagName("button")[0].focus();
        //     }
        // }

    }

    this.prevent = function(){
        if(onPrevent) return;
        onPrevent = true;
        $document.on('keydown', f);
    };

    this.resum = function(){
        $document.off('keydown', f);
        onPrevent = false;
    };
}

export default serviceBackEvent;