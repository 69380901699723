dpInfoLabelExact.$inject = [];

function dpInfoLabelExact() {
    return {
        restrict: 'C',
        require: 'ngModel',
        link: function ( $scope, $element, attr, ngModel ) {
            $scope.$watch( function () {
                return ngModel.$viewValue
            }, function ( val, oldVal ) {
                if ( val != oldVal ) {
                    if ( val && ngModel.$valid ) {
                        $scope.$parent.setDateValue( 'exact' )
                    }
                }
            } )
        }
    }
}

export default dpInfoLabelExact;