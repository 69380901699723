import angular from 'angular';

factoryApps.$inject = ['promiseOperation'];

function factoryApps(promiseOperation) {


    function getApiApps(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getApiApps',
                params,
                successGetApiApps
            );
    }

    function getApiAppById(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getApiAppById',
                params,
                successGetApiAppById
            );
    }

    function disconnectApp(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/disconnectApp',
                params,
                successDisconnectApp
            );
    }

    function successGetApiApps(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successGetApiAppById(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successDisconnectApp(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    return {
        getApiApps: getApiApps,
        getApiAppById: getApiAppById,
        disconnectApp: disconnectApp
    };
}

export default {
    type: 'factory',
    name: 'factoryApps',
    value: factoryApps
};