/**
 * Created by sirosh on 11.07.16.
 * @memberof renderDataChart
 * @ngdoc factory
 * @name renderDataChart
 * @requires $filter {service} angular filter service
 * @requires getChartLabel {service} format chart label
 * @returns renderDataToChart {Object} data chart
 */

renderDataChart.$inject = [
    '$filter',
    'getChartLabel',
    '$stateParams'
];

function renderDataChart($filter, getChartLabel, $stateParams){

    return {
        renderDataToChart: renderDataToChart
    };


    /**
     * @param data
     * @returns {{sales: {col: Array, row: Array}}}
     */
    function renderDataToChart(data) {


        var i, k;
        var earningsSum = {};
        var discountSum = {};
        var returnSum = {};
        var salesSum = {};
        var grossProfit = {};

        var earningsSumRow;
        var discountSumRow;
        var returnSumRow;
        var salesSumRow;
        var grossProfitRow;
        var tooltipForDay;
        earningsSum.row = [];
        discountSum.row = [];
        returnSum.row = [];
        salesSum.row = [];
        grossProfit.row = [];

        var divider = data.divider;

        var dataCharts = data.earningsRows;
        var len = dataCharts.length;

        var stateParamsFrom = data.startDate;
        var stateParamsTo = data.endDate;
        var category;
        var categories = [];
        var tooltipsForDays = [];

        for (i = 0; i < len; i++) {
            k = dataCharts[i];

            var fromDate = k.fromString.split('T')[0].split('-');
            var toDate = k.toString.split('T')[0].split('-');
            var fromTime = k.fromString.split('T')[1].split(':');
            var toTime = k.toString.split('T')[1].split(':');
            var dateFrom = new Date(fromDate[0], fromDate[1] - 1, fromDate[2], fromTime[0], fromTime[1], 0).getTime();
            var dateTo = new Date(toDate[0], toDate[1] - 1, toDate[2], toTime[0], toTime[1], toTime[2]).getTime();
            tooltipForDay = getChartLabel.parseDeliver(divider, dateFrom, dateTo, stateParamsFrom, stateParamsTo, "chart", true, $stateParams.fromHour, $stateParams.toHour);
            category = getChartLabel.parseDeliver(divider, dateFrom, dateTo, stateParamsFrom, stateParamsTo, "chart", false, $stateParams.fromHour, $stateParams.toHour);
            earningsSumRow = $filter('coinToCurrencyChart')(k.earningsSum);
            discountSumRow = $filter('coinToCurrencyChart')(k.discountSum);
            returnSumRow = $filter('coinToCurrencyChart')(k.returnSum);
            salesSumRow = $filter('coinToCurrencyChart')(k.totalSum);
            grossProfitRow = $filter('coinToCurrencyChart')(k.grossProfit);

            earningsSum.row.push(earningsSumRow);
            discountSum.row.push(discountSumRow);
            returnSum.row.push(returnSumRow);
            salesSum.row.push(salesSumRow);
            grossProfit.row.push(grossProfitRow);
            categories.push(category);
            tooltipsForDays.push(tooltipForDay);

        }

        // sales chart
        var charts = {
            netSalesSum: earningsSum,
            discountSum: discountSum,
            refundSum: returnSum,
            saleSum: salesSum,
            grossProfit: grossProfit,
            categories: categories,
            tooltips: tooltipsForDays
        };

        return charts;

    }

}

export default {
    type: 'factory',
    name: 'renderDataChart',
    value: renderDataChart
};