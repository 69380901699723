import angular from 'angular';

getTickets.$inject = ['promiseOperation'];

function getTickets(promiseOperation) {


    return {
        getPredefinedTickets: getPredefinedTickets,
        setPredefinedTickets: setPredefinedTickets,
        getShortOutlets: getShortOutlets
    };

    /**
     * Success get predefined tickets handler
     * @param {Object} response
     * @returns {{usePredefinedTickets: Boolean, outletId: String, tickets: Arrey }}
     */
    function successGetPredefinedTickets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    /**
     * Success set predefined tickets handler
     * @param {Object} response
     * @returns {String}
     */
    function successSetPredefinedTickets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    /**
     * Return predefined tickets promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPredefinedTickets(params) {
        if(params.outletId == undefined){
            params.outletId = null;
        }
        if(params.supplier == undefined){
            params.supplier = null;
        }
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getpredefinedtickets',
                params,
                successGetPredefinedTickets
            );
    }

    /**
     * Save predefined tickets promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function setPredefinedTickets(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/processpredefinedtickets',
                params,
                successSetPredefinedTickets
            );
    }


// получаем краткий список торговых точек
    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getshortoutlets',
                params,
                successGetShortOutlets
            );
    }
// получаем краткий список торговых точек


}

export default {
    type: 'factory',
    name: 'getTickets',
    value: getTickets
};