/**
 * Created by olehkss on 31.03.16.
 *
 * @ngdoc directive
 * @name app.directive:checkView
 * @scope
 * @restrict E
 *
 * @description
 *
 * This directive shows detail information about selected receipt
 *
 *@example
 *
 * <check-view></check-view>
 *
 */

import template from '../templates-pug/for-directive/check-view.pug';

checkViewDirective.$inject = ['$timeout'];

function checkViewDirective ($timeout) {
    return {
        restrict: 'E',
        controller: controller,
        link: link,
        scope: {},
        controllerAs: 'vmCheckView',
        template: template
    };

   function link(scope) {
       $timeout(function() {
           scope.arapro = scope.$parent.arapro;
           scope.permits = scope.$parent.permits;
       }, 1000)
   }
}

controller.$inject = ['$scope', 'shareReceipt', 'serviceShareReceipt', '$mdSidenav'];
function controller ($scope, shareReceipt, serviceShareReceipt, $mdSidenav)  {

    var vm = this;
    vm.receipt = shareReceipt.getReceipt;
    vm.closeReceipt = closeReceipt;
    vm.receiptAddStatusCancelled = receiptAddStatusCancelled;
    vm.findSubTotal = findSubTotal;
    vm.isHaveAdded = isHaveAdded;
    vm.totalPrice = totalPrice;
    vm.findChange = findChange;
    vm.subtotalWithNoTax = subtotalWithNoTax;
    /*** формат отображения валюты ***/
    vm.currencyOptionsShow = shareReceipt.getCurrencyShow;

    /*** формат отображения процентов налога ***/
    vm.percentShowTax = shareReceipt.getPercentShowTax;

    vm.receiptFormat = shareReceipt.getReceiptFormat;

    /*получаем данные о доступе с factory shareReceipt из файла factory-share-receipt.js*/
    vm.accessCancelReceipts = shareReceipt.getAccessCancelReceipts;

    vm.arapro = $scope.arapro;

    vm.cancelingProcessStarted = serviceShareReceipt.cancelingReceipt;

    function closeReceipt() {
        var mdSidenavRight = document.getElementsByClassName('md-sidenav-right');
        var mdSidenavLeft = document.getElementsByClassName('md-sidenav-left');
        //if check slide from right side we foud class and if this true we close $mdSidenav
        (mdSidenavLeft.length === 1) ?  $mdSidenav('left').close() : (mdSidenavRight.length === 1) ?  $mdSidenav('right').close() : "";

        shareReceipt.setReceipt(null);
        shareReceipt.setCurrencyShow(null);
        shareReceipt.setPercentShowTax(null);
        shareReceipt.setReceiptFormat(null);
        /*неясно зачем, но присваем при закрытии чека null
        возможно что бы при открытии чека взять новые данные*/
        shareReceipt.setAccessCancelReceipts(null);
    }


    /*Task #21099 Отмена оплаченных чеков в бэк-офисе*/
    /*связываю дерективу с сервисом и передаю в него accessCancelReceipts которые тяну с shareReceipt*/
    function receiptAddStatusCancelled(receipt, currencyOptionsShow, percentShowTax,
                                       receiptFormat, accessCancelReceipts, arapro) {
        serviceShareReceipt.receiptAddStatusCancelled(receipt, currencyOptionsShow,
                            percentShowTax, receiptFormat, accessCancelReceipts, arapro);
    }



    /**
     * @ngdoc function
     * @param {object} item ware or its option
     * @param {number} optionPrice price of the option
     * @returns {number}
     */
    function totalPrice (item, optionPrice) {
        var total;

        //here Math.round() were used because final price should be integer and in coins per kg
        if (optionPrice) {
            if (item.divisible) {
                total = Math.round((item.quantity * optionPrice) / 1000);
            } else {
                total = parseInt(item.quantity / 1000) * optionPrice;
            }
        } else {

            if ((item.divisible) || ((!item.divisible) && (item.quantity % 1000) >= 0)) {
                total = Math.round((item.quantity * item.amount) / 1000) + item.optionsAmountSum;
            } else {
                total = parseInt(item.quantity / 1000) * item.amount + item.optionsAmountSum;
            }
        }

        return total;
    }

    function findSubTotal (receipt) {
        var subTotal = 0, totalDiscountsSum = 0;

        receipt.itemRows.forEach( function (item) {
            subTotal += totalPrice(item);
        });

        receipt.totalDiscounts.forEach(function (discount) {
            totalDiscountsSum += discount.amount;
        });

        return subTotal - receipt.bonusAmount - totalDiscountsSum;

    }

    function subtotalWithNoTax(receipt) {
        var subtotal = findSubTotal(receipt);
        var allIncludedTaxes = 0;
        receipt.taxAmount.taxesDataList.forEach(function (tax) {
            if (tax.type === "INCLUDED") {
                allIncludedTaxes += tax.amount;
            }
        });

        return subtotal - allIncludedTaxes;
    }

    //checking if we have any ADDED taxes and sort taxes list
    function isHaveAdded (taxes) {

        if (taxes && taxes.length !== 0) {

            return  taxes.some( function (tax) {
                return tax.type === 'ADDED';
            });
        } else return false;

    }

    //here we calculate the change
    function findChange (receipt) {
        return (receipt.cardAmount + receipt.cashAmount - receipt.totalAmount);
    }
}

export default {
    type: 'directive',
    name: 'checkView',
    value: checkViewDirective
};
