import angular from 'angular';

productionListController.$inject = [
    '$scope',
    '$state',
    '$shortOutlets',
    '$stateParams',
    '$countOptionsShow',
    '$prodactList',
    '$timeout',
    'siteLangCode',
    '$profile',
    '$translate',
    'lvMixpanel'
];

function productionListController(
    $scope,
    $state,
    $shortOutlets,
    $stateParams,
    $countOptionsShow,
    $prodactList,
    $timeout,
    siteLangCode,
    $profile,
    $translate,
    lvMixpanel
){
    var vm = this;
    var profile = angular.copy($profile);

    $scope.types = [
        {
            type: null,
            name: $translate.instant('INVENTORY.STATUS_OLL')
        },
        {
            type: 'PRODUCTION',
            name: $translate.instant('INVENTORY.PRODUCTION.PRODUCTION')
        },
        {
            type: 'DISASSEMBLY',
            name: $translate.instant('INVENTORY.PRODUCTION.DISASSEMBLY')
        }
    ];

    $scope.arab = (profile.cabinetLang === 'ara' || profile.cabinetLang === 'urd' || profile.cabinetLang === 'heb') ? "" : "target-right target";

    $scope.defoult = true;
    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.selectOutlet = {id: $stateParams.outletId ? +$stateParams.outletId : null};
    $scope.selectOutletId = $scope.selectOutlet.id;
    $scope.selectType = {type: $stateParams.type || null};

    $scope.stateFromList = $state.params;

    //Task # 19957 Change stubs on Advanced inventory screens
    vm.plugsFilters = !!(!$scope.selectType.type && !$scope.selectOutlet.id);
    vm.plugsSearch = !!$stateParams.search;
    //Plugs for extended inventory accounting Productions
    $scope.profile = angular.copy( $profile );
    var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];
    //"de" - German
    var plugProductions = ["ru", "es", "jp", "vn", "fr", "ar", "br", "pl", "idn", "it", "ro", "gr", "ko", "zh"];
    $scope.plugProductionsHelpLink = plugProductions.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-work-production?utm_source=Back%20Office&utm_medium=Productions"
        : "https://help.loyverse.com/help/how-work-production?utm_source=Back%20Office&utm_medium=Productions";

    $scope.trackHelpProducnion = function() {
        lvMixpanel.track('Help', {"Property": "Productions"});
    };

    $scope.editTableState = function(type){
        if(type === 'PRODUCTION') {
            return 'inventory.productiondetail';
        } else if (type === 'DISASSEMBLY') {
            return 'inventory.disassemblydetail';
        }
    };

    vm.countOptionsShow = $countOptionsShow;

    vm.prodactList = angular.copy($prodactList);
    // if need to check empty purchaseList
    // vm.prodactList.orders = [];


    vm.onAdd = function(production){
        if (production) {
            $state.go('inventory.createproduction', {stateToSave: $scope.stateFromList});
        } else if (!production) {
            $state.go('inventory.createdisassembly', {stateToSave: $scope.stateFromList});
        }
    };

    vm.addClassToMenu = function () {
        $timeout( function () {
            angular.element( document.querySelector( '.md-open-menu-container.md-active' ) ).addClass("menuwidth");
        }, 100 );
    };

    // vm.chooseButton = function(){
    //     if ($scope.defoult === true) {
    //         $scope.defoult = false;
    //     } else {
    //         $scope.defoult = true;
    //     }
    // }
    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            // console.log("$scope.selectOutlet.id = ", $scope.selectOutlet.id);
            $state.go('inventory.productions', {outletId: newval.id || null, page:0}).then(function() {
                $scope.selectOutletId = newval.id;
            });
        }
    }, true);

    $scope.$watch('selectType', function(newval, oldval){
        if (oldval != newval) {
            // console.log("$scope.selectOutlet.id = ", $scope.selectOutlet.id);
            $state.go('inventory.productions', {type: newval.type, page:0});
        }
    }, true);

    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    $scope.pages = vm.prodactList.pages;
    if (vm.prodactList.orders.length == 0 && $scope.stateFromList.page > 0) {
        $state.go( 'inventory.productions', {
            page:       $scope.pages - 1,
            outletId:   $scope.stateFromList.outletId,
            type:       $scope.stateFromList.type} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
}

export default {
    type: 'controller',
    name: 'productionListController',
    value: productionListController
};
