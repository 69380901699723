/**
 * Created by mars on 5/20/16.
 */

unsafe.$inject = ['$sce'];
function unsafe($sce) {
	return function(val) {
		return $sce.trustAsHtml(val);
	};
}

export default {
	type: 'filter',
	name: 'unsafe',
	value: unsafe
};