/**
 * Created by sirosh on 01.06.16.
 * @ngdoc service
 * @name app.service: modificatorsOperation
 * @requires {service} - promiseOperation - service for promise wrapper and http query
 * @requires {service} - $filter
 * @returns {Promise} createModificator
 * @returns {Promise} editModificator
 * @returns {Promise} getModifierById
 * @returns {Promise} getModifiersList
 * @returns {Promise} deleteModifier
 * @description
 * This service create for using, creating, editing and deleting modificators
 */

import angular from 'angular';

modificatorsOperation.$inject = [
    'promiseOperation',
    '$filter',
    'currencyFormat',
    'serviceMinMaxValues'
];

function modificatorsOperation(promiseOperation , $filter, currencyFormat, serviceMinMaxValues) {
    let currencyToCoin = $filter('currencyToCoin');
    let coinToCurrency = $filter('coinToCurrency');

    return {
        createModificator: createModificator,
        editModificator: editModificator,
        getModifierById: getModifierById,
        getModifiersList: getModifiersList,
        deleteModifier : deleteModifier,
        getShortOutlets: getShortOutlets,
        changeModifiersOrder: changeModifiersOrder
    };


// получаем краткий список торговых точек
    function getShortOutlets(params) {
       return promiseOperation
           .getPromiseHTTP(
           'POST',
           '/data/ownercab/getshortoutlets',
           params,
           successListOutlets
       );
    }

    function successListOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data.outlets;
            }
        }
    }

// получаем краткий список торговых точек



    /**
     * @name successCreateEditModificator
     * @param {Object} response
     * @returns {Object} - response data
     * @describe
     * Create/edit handler
     */
    function successCreateEditModificator(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result) {
                return response.data;
            }
        }
    }


    /**
     * @name successList
     * @param response
     * @returns {{modifiers: Array}}
     * @describe
     * Get modifiers list handler
     */
    function successList(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data.modifiers;
            }
        }
    }

    /**
     * @name successDelete
     * @param response
     * @returns {Object}
     * @describe
     * Delete handler
     */
    function successDelete(response){
        if (angular.isObject(response.data)) {
            return response.data;
        } else {
            return response;
        }
    }

    function successChangeModifiersOrder(response){
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data.result;
            }
        }
    }

    /**
     * @name createModificator
     * @param {Object} params
     * @returns {Promise}
     * @describe
     * This handler returns Promise with filtered price
     */
    function createModificator(params) {
        // console.log("params = ", params);

        var paramsObj = {};
        paramsObj.name = params.name;
        paramsObj.outletsIds = params.outletsIds;
        paramsObj.allowedForAllOutlets = params.allowedForAllOutlets;
        paramsObj.options = [];

        for (var i = 0, len = params.options.length; i < len; i++) {

            var option = {
                priority: i+1,
                name: params.options[i].name,
                price: currencyToCoin(params.options[i].price)
            };

            paramsObj.options.push(option);
        }
        // console.log("paramsObj = ", paramsObj);
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createmodifier',
                paramsObj,
                successCreateEditModificator
            );
    }

    /**
     * @name editModificator
     * @param {Object} modifier - modifier object
     * @returns {Promise}
     * @describe
     * This handler returns Promise with filtered price
     */
   function editModificator(modifier){
        const { name, id, outletsIds, allowedForAllOutlets, options } = modifier;

        const params = {
            name,
            id,
            outletsIds,
            allowedForAllOutlets,
            options: options.map(({ id, name, permanentId, price }, index) => ({
                id: id ?? null,
                name,
                permanentId: permanentId ?? null,
                price: parseInt(currencyToCoin(price)),
                priority: index + 1
            }))
        };


       return promiseOperation
           .getPromiseHTTP(
               'POST',
               '/data/ownercab/editmodifier',
               params,
               successCreateEditModificator
           );
   }

    /**
     * @name getModifierById
     * @param {Object} params
     * @returns {Promise}
     * @describe
     * This handler returns Promise of modifier by id
     */
   function getModifierById(params){

        return currencyFormat.getCurrencyOptionsEditModif()
            .then(currencyOptionsEditModif => {
                return promiseOperation
                    .getPromiseHTTP(
                        'POST',
                        '/data/ownercab/getmodifierbyid',
                        params,
                        successById
                    );

                /**
                 * @name successById
                 * @param response
                 * @returns {{id: Number, name: String, options: Array}}
                 * @describe
                 * Get modifier by id handler
                 */
                function successById(response){
                    if(angular.isObject(response.data)){
                        if (response.data.result === 'ok') {

                            let options = [];

                            for(let i= 0, len = response.data.modifier.options.length; i < len; i++){
                                let option = response.data.modifier.options[i];
                                option.price = serviceMinMaxValues.getValidValue(coinToCurrency(option.price, '.'), currencyOptionsEditModif);

                                options.push(option);
                            }

                            return {
                                id: response.data.modifier.id,
                                name: response.data.modifier.name,
                                options: options,
                                byOutlets: response.data.modifier.byOutlets
                            };
                        } else {
                            return response.data;
                        }
                    }
                }
            });
   }

    /**
     * @name getModifiersList
     * @param params
     * @returns {Promise}
     * @describe
     * This handler returns Promise of modifiers list
     */
   function getModifiersList(params) {
        // console.log("params = ", params);
        var paramsForPost = {};

        if(!params.outletsIds || params.outletsIds == ""){
          paramsForPost.outletsIds = null;
        } else {
          paramsForPost.outletsIds = [params.outletsIds];
        }
        // console.log("paramsForPost AFTER = ", paramsForPost);

        return promiseOperation
           .getPromiseHTTP(
           'POST',
           '/data/ownercab/getmodifiers',
           paramsForPost,
           successList
        );
   }

    /**
     * @name deleteModifier
     * @param params
     * @returns {Promise}
     * @describe
     * This handler returns Promise as the result of deleting modifier
     */
  function deleteModifier(params){

      return promiseOperation.getPromiseHTTP(
          'POST',
          '/data/ownercab/deletemodifiers',
          params,
          successDelete
      );

  }

    function changeModifiersOrder(params){

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/changeModifiersOrder',
            params,
            successChangeModifiersOrder
        );

    }

}

export default {
    type: 'factory',
    name: 'modificatorsOperation',
    value: modificatorsOperation
};
