/**
 * Created by k.yarosh on 06/30/17.
 */

const arabToDigits = {
	'٠': 0,
	'٠١': 1,
	'٠٢': 2,
	'٠٣': 3,
	'٠٤': 4,
	'٠٥': 5,
	'٠٦': 6,
	'٠٧': 7,
	'٠٨': 8,
	'٠٩': 9,
	'١٠': 10,
	'١١': 11,
	'١٢': 12,
	'١٣': 13,
	'١٤': 14,
	'١٥': 15,
	'١٦': 16,
	'١٧': 17,
	'١٨': 18,
	'١٩': 19,
	'٢٠': 20,
	'٢١': 21,
	'٢٢': 22,
	'٢٣': 23,
	'٢٤': 24,
	'٢٥': 25,
	'٢٦': 26,
	'٢٧': 27,
	'٢٨': 28,
	'٢٩': 29,
	'٣٠': 30,
	'٣١': 31,
	'٢٠١٠': 2010,
	'٢٠١١': 2011,
	'٢٠١٢': 2012,
	'٢٠١٣': 2013,
	'٢٠١٤': 2014,
	'٢٠١٥': 2015,
	'٢٠١٦': 2016,
	'٢٠١٧': 2017,
	'٢٠١٨': 2018,
	'٢٠١٩': 2019,
	'٢٠٢١': 2020,
	'٢٠٢٢': 2023,
	'٢٠٢٤': 2024,
	'٢٠٢٥': 2025,
	'٢٠٢٦': 2026,
	'٢٠٢٧': 2027,
	'٢٠٢٨': 2028,
	'٢٠٢٩': 2029,
	'٢٠٣٠': 2030,
	'٢٠٣١': 2031,
	'٢٠٣٢': 2032,
	'٢٠٣٣': 2033,
	'٢٠٣٤': 2034,
	'٢٠٣٥': 2035
};

export default {
	type: 'constant',
	name: 'arabToDigits',
	value: arabToDigits
};