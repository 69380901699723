import angular from 'angular';

factoryProduction.$inject = ['promiseOperation'];

function factoryProduction (promiseOperation) {

    function createOrderProduction(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createOrderProduction',
                params,
                successCreateOrderProduction
            );
    }

    function successCreateOrderProduction(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getProductionsList(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getProductionsList',
                params,
                successGetProductionsList
            );
    }

    function successGetProductionsList(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getProductionOrderDetail(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getProductionOrderDetail',
                params,
                successGetProductionOrderDetail
            );
    }

    function successGetProductionOrderDetail(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function searchItemsForProductionOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/searchItemsForProductionOrder',
                params,
                successSearchItemsForProductionOrder
            );
    }

    function successSearchItemsForProductionOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    return {
        getProductionsList : getProductionsList,
        getProductionOrderDetail: getProductionOrderDetail,
        searchItemsForProductionOrder: searchItemsForProductionOrder,
        createOrderProduction: createOrderProduction
    }

}

export default {
    type: 'factory',
    name: 'factoryProduction',
    value: factoryProduction
};