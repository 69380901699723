/**
 * Created by Kiskenbassker on 16.03.18
 * */

const provincesCan = [
    {
        code: 'ab',
        name: 'Alberta'
    },
    {
        code: 'bc',
        name: 'British Columbia'
    },
    {
        code: 'mb',
        name: 'Manitoba'
    },
    {
        code: 'nb',
        name: 'New Brunswick'
    },
    {
        code: 'nl',
        name: 'Newfoundland and Labrador'
    },
    {
        code: 'ns',
        name: 'Nova Scotia'
    },
    {
        code: 'nt',
        name: 'Northwest Territories'
    },
    {
        code: 'nu',
        name: 'Nunavut'
    },
    {
        code: 'on',
        name: 'Ontario'
    },
    {
        code: 'pe',
        name: 'Prince Edward Island'
    },
    {
        code: 'qc',
        name: 'Quebec'
    },
    {
        code: 'sk',
        name: 'Saskatchewan'
    },
    {
        code: 'yt',
        name: 'Yukon'
    }
];

export default {
    type: 'constant',
    name: 'provincesCan',
    value: provincesCan
};