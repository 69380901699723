import angular from 'angular';

customerDetailsController.$inject = [
    '$state',
    'serviceDialog',
    '$filter',
    '$translate',
    '$pointsCurrencyEdit',
    '$pointsCurrencyShow',
    '$currencyOptionsShow',
    'clientsDB',
    'serviceToast',
    '$stateParams',
    '$clientDetail',
    '$scope',
    'lodash',
    '$profile',
    'dialogs'
];

function customerDetailsController($state,
                                   serviceDialog,
                                   $filter,
                                   $translate,
                                   $pointsCurrencyEdit,
                                   $pointsCurrencyShow,
                                   $currencyOptionsShow,
                                   clientsDB,
                                   serviceToast,
                                   $stateParams,
                                   $clientDetail,
                                   $scope,
                                   lodash,
                                   $profile,
                                   dialogs) {
    var vm = this;
    var translate = $filter('translate');
    vm.pointsCurrencyEdit = $pointsCurrencyEdit;
    vm.pointsCurrencyShow = $pointsCurrencyShow;
    vm.currencyOptionsShow = $currencyOptionsShow;
    var profile = $profile;
    vm.country = null;
    vm.addressObject = [];
    // данные для сохранение состояния фильтров
    $scope.stateFromList = $stateParams.stateToSave;
    vm.goBack = function () {
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go('clients.database', {
                page: 0,
                limit: 10
            });
        } else {
            $state.go('clients.database', {
                page: $scope.stateFromList.page,
                limit: $scope.stateFromList.limit
            });
        }
    };

    vm.clientData = angular.copy($clientDetail.client);
    vm.clientData.points = $filter('coinToCurrency')(vm.clientData.points, vm.pointsCurrencyShow.decimalCharacter);
    vm.showAddress = vm.clientData.address || vm.clientData.city || vm.clientData.province || vm.clientData.postalCode || vm.clientData.country;


    if (vm.clientData.country) {
        vm.country = vm.clientData.country.toUpperCase();

        // $translate("COUNTRY." + vm.clientData.country.toUpperCase()).then(function (translation) {
        //     vm.country = translation;
        //     updateAdressObject();
        // })

    }

    function updateAdressObject() {
        vm.addressObject = [];
        var addressParts = [vm.clientData.address, vm.clientData.city, vm.clientData.province, vm.clientData.postalCode];
        addressParts.forEach(function (part) {
            if (part != null && part != "") {
                vm.addressObject.push(part);
            }
        });
    }

    if (vm.showAddress) {
        updateAdressObject();

    }

    vm.isPoland = profile.country === 'pl';
    vm.isSaudi = profile.country === 'sa';

    vm.editProfile = function () {
        $state.go('clients.editcustomer', {id: $stateParams.id});
    };

    vm.pointsNumber = {
        points: vm.clientData.points
    };

    vm.adjustPoints = function () {

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/adjust-points-dialog.html',
            title: translate('CLIENTS.EDITING_POINTS_DIALOG'),
            pointsNumber: vm.pointsNumber,
            pointsCurrencyEdit: vm.pointsCurrencyEdit,
            buttons: [
                {
                    id: "edit_points_balance_dialog-cancel-button",
                    text: 'ON_CANCEL',
                    action: function () {

                    }
                },
                {
                    id: "edit_points_balance_dialog-save-button",
                    // class: 'primary',
                    class: $scope.arapro ? "primary arabic-save" : "primary",
                    text: 'ON_SAVE',
                    action: function () {
                        var pointsNumber = vm.pointsNumber;
                        var forAdjust = {
                            clientId: vm.clientData.id,
                            valueBonus: $filter('currencyToCoin')(pointsNumber.points, vm.currencyOptionsShow.decimalCharacter)
                        };
                        clientsDB.adjustPoints(forAdjust).then(function (data) {
                            if (data.result === "ok") {
                                serviceToast.show('CLIENTS.POINTS_EDITED', 'success');
                                vm.clientData.points = pointsNumber.points;
                            } else if (data.result === "error" && data.message === "forbidden_for_support_team") {
                                serviceToast.show('NO_EDIT_PERMISSION', 'error');
                            }
                        });
                    }
                }
            ]
        });
    }

    vm.onDel = function () {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('CLIENTS.DB.DELETE_CLIENT_TITLE'),
            content: translate('CLIENTS.DB.DELETE_CLIENT_TEXT_NO_LO'),
            buttons: [
                {
                    id: "delete_customer_dialog-cancel-button",
                    text: 'ON_CANCEL'
                },
                {
                    id: "delete_customer_dialog-delete-button",
                    class: 'primary',
                    text: 'ON_DEL',
                    action: function () {
                        clientsDB.deleteClients({
                            clientsIds: [$stateParams.id]
                        }).then(function (data) {
                            if (data.result === "ok") {
                                serviceToast.show('CLIENTS.DB.DELETE_CLIENT', 'success');
                                backToList();
                            }
                            if (data.result === 'error') {
                                if (data.message === 'customer_deleted') {
                                    dialogs.itemDeletedEarlier().then(backToList);
                                }
                            }
                        });
                    }
                }
            ]
        });
    }

    function backToList() {
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go('clients.database', {
                page: 0,
                limit: 10
            });
        } else {
            $state.go('clients.database', {
                page: $scope.stateFromList.page,
                limit: $scope.stateFromList.limit
            });
        }
    }
}

export default {
    type: 'controller',
    name: 'customerDetailsController',
    value: customerDetailsController
};
