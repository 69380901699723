/**
 * Created by Kiskenbassker on 18.02.18
 */

import angular from 'angular';

createLabels.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    '$window',
    'serviceToast',
    'serviceDialog',
    '$shortOutlets',
    'printLabels',
    '$filter',
    '$profile',
    'itemsAutocomplete',
    '$countQuantityForLabels',
    'factoryPrintLabels',
    'factoryInventory',
    '$categories',
    '$suppliers',
    'lodash',
    '$timeout',
    'resolveTimezone',
    '$printParams',
    'lvMixpanel',
    '$preloadedData',
    'dialogs'
];

function createLabels(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $window,
    serviceToast,
    serviceDialog,
    $shortOutlets,
    printLabels,
    $filter,
    $profile,
    itemsAutocomplete,
    $countQuantityForLabels,
    factoryPrintLabels,
    factoryInventory,
    $categories,
    $suppliers,
    lodash,
    $timeout,
    resolveTimezone,
    $printParams,
    lvMixpanel,
    $preloadedData,
    dialogs
) {
    var vm = this,
        translate = $filter('translate'),
        wareCount = $filter('wareCount'),
        offStateChangeStart,
        finalParams,
        createLabelsData,
        nextPrintsData,
        tzOffset = resolveTimezone.timeZoneOffsetForReports(),
        barcodeMaxlength,
        printParams = $printParams.columnsView;

    $scope.stateFromList = $stateParams.stateToSave;
    $scope.profile = angular.copy($profile);
    vm.outlets = angular.copy($shortOutlets);

    var showLetter = false,
        showA4 = false;

    var countriesForLetter = ["us", "ca", "cl", "mx", "do", "ph"];
    if(countriesForLetter.indexOf($profile.country) !== -1) {
        showLetter = true;
    } else {
        showA4 = true;
    }

    vm.labelsTypes = printLabels.labelsTypes()
        .filter(function (item) {
            if((item.id === 'AVERY_23X1_34' || item.id === 'AVERY_1X2_58' || item.id === 'AVERY_1X4') && showLetter === false) {
                return false;
            } else if ((item.id === 'A4_525X297' || item.id === 'A4_70X297' || item.id === 'A4_70X37') && showA4 === false) {
                return false;
            }
            return true;
        });

    vm.countQuantityForLabels = $countQuantityForLabels;

    // console.log('State Params: ', $stateParams);

    vm.actualOutlet = vm.outlets.length > 1 ? returnOutlet().actualOutlet : vm.outlets[0].name;
    vm.params = {
        labelsType: printParams[0] && printParams[0].labelsType
            ? printParams[0].labelsType
            : null,
        actualOutletId: vm.outlets.length > 1
            ? returnOutlet().actualOutletId
            : vm.outlets[0].id,
        barcodeSource: printParams[0] && printParams[0].barcodeSource
            ? printParams[0].barcodeSource
            : "sku",
        printPrice: printParams[0] && (printParams[0].printPrice !== undefined)
            ? printParams[0].printPrice
            : true,
        printName: printParams[0] && (printParams[0].printName !== undefined)
            ? printParams[0].printName
            : true,
        items: returnItems()
    };

    var startParams = angular.copy(vm.params);

    setBarcodeMaxlength();

    vm.setAlertStatus = setAlertStatus;
    vm.setBarcodeMaxlength = setBarcodeMaxlength;

    if(vm.params.items.length > 0) {
        setAlertStatus(vm.params.items);
    }

    vm.categories = angular.copy($categories).map(function (item) {
        return {
            id: item.id,
            name: item.name,
            waresAmount: item.waresAmount,
            _checked: false,
            _allAdded: false
        }
    });
    vm.suppliers = angular.copy($suppliers).map(function (item) {
        return {
            id: item.id,
            name: item.name,
            waresAmount: item.waresAmount,
            _checked: false,
            _allAdded: false
        }
    });
    vm.categoriesSelected = [];
    vm.suppliersSelected = [];

    vm.barcodeSources = [
        {
            title: "ARTICLE",
            value: "sku"
        },
        {
            title: "BARCODE",
            value: "barcode"
        },
        {
            title: "NOTHING",
            value: "none"
        }
    ];

    /* Select actual outlet */

    vm.selectActualOutlet = function(actualOutlet){
        if(vm.actualOutlet !== null){
            vm.actualOutlet = angular.copy(actualOutlet);
            vm.params.actualOutletId = vm.actualOutlet.id;
        } else {
            vm.actualOutlet = {};
            vm.params.actualOutletId = null;
        }
    };

    vm.factorySearchCallback = factoryPrintLabels.searchItemsForPrintLabels;

    vm.itemObjCallback = function (item) {
        var obj = {
            wareId: item.id,
                name: item.name,
            sku: item.sku,
            barcode: item.barcode,
            categoryId: item.categoryId,
            supplierId: item.supplierId,
            quantity: 1,
            alertSymbols: (vm.params.barcodeSource === "sku")
            ? returnSourceAlert(item.sku, barcodeMaxlength).alertSymbols
            : (vm.params.barcodeSource === "barcode")
                ? returnSourceAlert(item.barcode, barcodeMaxlength).alertSymbols
                : false,
            alertLength: (vm.params.barcodeSource === "sku")
            ? returnSourceAlert(item.sku, barcodeMaxlength).alertLength
            : (vm.params.barcodeSource === "barcode")
                ? returnSourceAlert(item.barcode, barcodeMaxlength).alertLength
                : false
        };
        obj.alertMessage = (function () {
            if(vm.params.barcodeSource === "sku") {
                if(obj.alertSymbols) {
                    return translate('INVENTORY.PRINT_LABELS.WARNING_SKU_ALPHANUMERIC');
                } else if(obj.alertLength) {
                    return translate('INVENTORY.PRINT_LABELS.WARNING_SKU_LONG');
                } else {
                    return "";
                }
            } else if(vm.params.barcodeSource === "barcode") {
                if(obj.alertSymbols) {
                    return translate('INVENTORY.PRINT_LABELS.WARNING_BARCODE_ALPHANUMERIC');
                } else if(obj.alertLength) {
                    return translate('INVENTORY.PRINT_LABELS.WARNING_BARCODE_LONG');
                } else {
                    return "";
                }
            }
            return "";
        })();
        return obj
    };

    vm.autocompleteItems = {
        searchModels: itemsAutocomplete.searchModels,
        getMatches: itemsAutocomplete.getMatches,
        selectedItemChange: itemsAutocomplete.selectedItemChange
    };

    vm.deleteItem = itemsAutocomplete.deleteItem;
    vm.sameText = translate('INVENTORY.PRINT_LABELS.ERROR_SAME_ITEM');


    /* Add by categories dialog and functions */
    vm.addByCategory = function () {
        itemsCountBy(vm.categories, vm.params.items, 'categoryId');
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/inventory/dialog-add-by.html',
            title: translate('INVENTORY.COUNT.ADD_CATEGORY'),
            parents: vm.categories,
            parentsSelected: vm.categoriesSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_CATEGORIES'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        vm.categories.forEach(function (item) {
                            item._checked = false;
                        });
                        vm.categoriesSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(vm.categoriesSelected.length > 0) {
                            var categoriesParams = {
                                outletId: vm.params.actualOutletId,
                                categoryIds: vm.categoriesSelected
                            };
                            factoryPrintLabels.getWaresByCategories(categoriesParams).then(function (data) {
                                if(data.result === "ok") {
                                    vm.categoriesSelected = vm.callbackAddItemsBy(data, vm.categories, vm.categoriesSelected);
                                }
                            })
                        }
                    }
                }
            ]
        })
    };

    vm.addBySupplier = function () {
        itemsCountBy(vm.suppliers, vm.params.items, 'supplierId');
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/inventory/dialog-add-by.html',
            title: translate('INVENTORY.COUNT.ADD_SUPPLIER'),
            parents: vm.suppliers,
            parentsSelected: vm.suppliersSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_SUPPLIERS'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        vm.suppliers.forEach(function (item) {
                            item._checked = false;
                        });
                        vm.suppliersSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(vm.suppliersSelected.length > 0) {
                            var suppliersParams = {
                                outletId: vm.params.actualOutletId,
                                supplierIds: vm.suppliersSelected
                            };
                            factoryPrintLabels.getWaresBySuppliers(suppliersParams).then(function (data) {
                                if(data.result === "ok") {
                                    vm.suppliersSelected = vm.callbackAddItemsBy(data, vm.suppliers, vm.suppliersSelected);
                                }
                            })
                        }
                    }
                }
            ]
        })
    };

    vm.callbackAddItemsBy = function (data, parents, parentsSelected) {
        var itemsBy = [];
        data.items.forEach(
            function (item) {
                var itemToAdd;
                var itemsLength = vm.params.items.length;
                if(itemsLength > 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (vm.params.items[i].wareId === item.id) {
                            itemToAdd = false;
                            break;
                        } else if (i === itemsLength - 1) {
                            itemToAdd = true;
                        }
                    }
                } else {
                    itemToAdd = true;
                }
                if(itemToAdd) {
                    itemsBy.push({
                        id: null,
                        wareId: item.id,
                        name: item.name,
                        sku: item.sku,
                        barcode: item.barcode,
                        categoryId: item.categoryId,
                        supplierId: item.supplierId,
                        quantity: 1
                    });
                }
            }
        );
        vm.params.items = vm.params.items.concat(itemsBy);
        setAlertStatus(vm.params.items);
        parents.forEach(function (item) {
            item._checked = false;
        });
        parentsSelected = [];
        return parentsSelected;
    };

    const goInventoryOrder = () => {
        let state;

        switch ($stateParams.orderType) {
            case "PURCHASE_ORDER":
                state = "inventory.orederdetail";
                break;
            case "TRANSFER_ORDER":
                state = "inventory.transferdetail";
                break;
            case "STOCK_ADJUSTMENT":
                state = "inventory.adjustdetails";
                break;
            case "PRODUCTION":
                state = "inventory.productiondetail";
                break;
            case "DISASSEMBLY":
                state = "inventory.disassemblydetail";
                break;
            default:
                return;
        }

        $state.go(state, {id: $stateParams.orderId});
    }

    /* Cancel button */
    vm.cLabelsCancel = function() {
        $stateParams.page = 0;
        offStateChangeStart();
        if ($stateParams.orderId) {
            goInventoryOrder();
        } else {
            if (lodash.isEmpty($scope.stateFromList)) {
                $state.go('goods.price', {
                    page: 0,
                    limit: 10
                });
            } else {
                $state.go('goods.price', {
                    page: $scope.stateFromList.page,
                    limit: $scope.stateFromList.limit,
                    outletId: $scope.stateFromList.outletId,
                    category: $scope.stateFromList.category,
                    inventory: $scope.stateFromList.inventory
                });
            }
        }
    };

    function checkItemsList (item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) {
            list.splice(idx, 1);
        }
        else {
            list.push(item);
        }
    }

    /* Function for counting items in count by category or by supplier and comparing them with total amount of supplier's or category's items */
    function itemsCountBy(arrayObj1, arrayObj2, field) {
        var count = 0;
        arrayObj1.forEach(function (obj1) {
            count = 0;
            arrayObj2.forEach(function (obj2) {
                if(obj1.id === obj2[field]) {
                    count += 1;
                }
            });
            obj1._allAdded = (count === obj1.waresAmount);
        })
    }

    /* Return items */
    function returnItems() {
        var initialItems = angular.copy($preloadedData.items),
            proceededItems = [],
            proceededItemsObj = {};
        if ($stateParams.orderType === "PURCHASE_ORDER" || $stateParams.orderType === "TRANSFER_ORDER") {
            for(var i = 0, initialItemsLength = initialItems.length; i < initialItemsLength; i++) {
                //Добавляем в объект ключ-id товара в виде "+123" для того, чтобы сохранить порядок товаров
                var wareIdStr = "+" + initialItems[i].wareId;
                var itemObj = {
                    wareId: initialItems[i].wareId,
                    name: initialItems[i].name,
                    sku: initialItems[i].article || initialItems[i].sku,
                    barcode: initialItems[i].barcode,
                    categoryId: initialItems[i].categoryId,
                    supplierId: initialItems[i].supplierId,
                    deleted: initialItems[i].deleted ? initialItems[i].deleted : false,
                    quantity: initialItems[i].quantity <= 9999000 ? Math.ceil(wareCount(initialItems[i].quantity, ".", false)) : 9999
                };
                if(i !== 0) {
                    if(proceededItemsObj.hasOwnProperty(wareIdStr)) {
                        proceededItemsObj[wareIdStr].quantity += Math.ceil(wareCount(initialItems[i].quantity, ".", false))
                    } else {
                        proceededItemsObj[wareIdStr] = itemObj;
                    }
                } else {
                    proceededItemsObj[wareIdStr] = itemObj;
                }
            }
            for(var key in proceededItemsObj) {
                proceededItems.push(proceededItemsObj[key]);
            }
        } else if ($stateParams.orderType === "STOCK_ADJUSTMENT" || $stateParams.orderType === "PRODUCTION" || $stateParams.orderType === "DISASSEMBLY") {
            proceededItems = initialItems.map(function (item) {
                return {
                    wareId: item.wareId,
                    name: item.name,
                    sku: item.article || item.sku,
                    barcode: item.barcode,
                    categoryId: item.categoryId,
                    supplierId: item.supplierId,
                    deleted: item.deleted ? item.deleted : false,
                    quantity: item.quantity <= 9999000 ? Math.ceil(wareCount(item.quantity, ".", false)) : 9999
                }
            })
        }
        return proceededItems;
    }

    /* Return flag show alert message or not */
    function returnSourceAlert(str, maxlength) {
        var regEx = /[^A-Z0-9]/i;
        return {
            alertSymbols: str.match(regEx) !== null,
            alertLength: str.length > maxlength && str.match(regEx) === null
        }
    }

    /* Set alert status for items */
    function setAlertStatus(items) {
        items.forEach(function (item) {
            if(vm.params.barcodeSource === "sku") {
                item.alertSymbols = returnSourceAlert(item.sku, barcodeMaxlength).alertSymbols;
                item.alertLength = returnSourceAlert(item.sku, barcodeMaxlength).alertLength;
                item.alertMessage = item.alertSymbols
                    ? translate("INVENTORY.PRINT_LABELS.WARNING_SKU_ALPHANUMERIC")
                    : translate("INVENTORY.PRINT_LABELS.WARNING_SKU_LONG");
            } else if(vm.params.barcodeSource === "barcode") {
                item.alertSymbols = returnSourceAlert(item.barcode, barcodeMaxlength).alertSymbols;
                item.alertLength = returnSourceAlert(item.barcode, barcodeMaxlength).alertLength;
                item.alertMessage = item.alertSymbols
                    ? translate("INVENTORY.PRINT_LABELS.WARNING_BARCODE_ALPHANUMERIC")
                    : translate("INVENTORY.PRINT_LABELS.WARNING_BARCODE_LONG");
            } else {
                item.alertSymbols = false;
                item.alertLength = false;
                item.alertMessage = "";
            }
        });
    }

    /* Set barcode maxlength */
    function setBarcodeMaxlength() {
        barcodeMaxlength = (vm.params.labelsType !== null) && (vm.params.labelsType === "DYMO_1_X2_18" || vm.params.labelsType === "ZEBRA_1_14X1" || vm.params.labelsType === "ZEBRA_2X1" || vm.params.labelsType === "AVERY_23X1_34" || vm.params.labelsType === "A4_525X297") ? 20 : 40;
    }

    /* Return outlet */
    function returnOutlet() {
        switch ($stateParams.orderType) {
            case "PURCHASE_ORDER":
                return {
                    actualOutlet: $preloadedData.orderData.store,
                    actualOutletId: $preloadedData.orderData.storeId
                };
            case "TRANSFER_ORDER":
                return {
                    actualOutlet: $preloadedData.orderData.destinationStore,
                    actualOutletId: $preloadedData.orderData.destinationStoreId
                };
            case "STOCK_ADJUSTMENT":
                return {
                    actualOutlet: $preloadedData.adjustData.store,
                    actualOutletId: $preloadedData.adjustData.storeId || null
                };
            case "PRODUCTION":
            case "DISASSEMBLY":
                return {
                    actualOutlet: $preloadedData.orderData.storeName,
                    actualOutletId: $preloadedData.orderData.outletId
                };
            default:
                return {
                    actualOutlet: {},
                    actualOutletId: null
                };
        }
    }

    /* Check before save */
    function checkEmptyNumberInput(item) {
        return item.quantity !== undefined;
    }

    function checkBeforeSave() {
        vm.errItemsNo = vm.params.items.every(checkEmptyNumberInput);

        if(vm.params.actualOutletId === null
            || vm.params.labelsType === null
            || vm.params.items.length < 1
            || !vm.errItemsNo
        )
        {
            serviceToast.show('INVENTORY.PRINT_LABELS.ERROR_ENABLE_TO_CREATE', 'error');
            return false;
        }
        return true;
    }

    /* Check total amount of labels */
    function checkTotalAmount() {
        var labelsTotal = vm.params.items.reduce(function (prev, curr) {
            var previous = parseInt(prev);
            var current = parseInt(curr.quantity);
            if(!isNaN(previous) && !isNaN(current)) {
                return previous + current;
            } else {
                return 0;
            }
        }, 0);
        if(labelsTotal > 10000) {
            serviceToast.show('INVENTORY.PRINT_LABELS.ERROR_MAX_LABELS', 'error');
            return false;
        }
        return true;
    }

    /* Check are all items deleted or not */
    function checkAllDeleted(items) {
        var count = 0;
        items.forEach(
            function (item) {
                if(item.deleted === true) {
                    count++;
                }
            }
        );
        return count > 0 && count === items.length;
    }

    /* Return 'all deleted' error message for different cases */
    function showAllDeletedError() {
        let errorMessage;

        switch ($stateParams.orderType) {
            case "PURCHASE_ORDER":
                errorMessage = 'INVENTORY.PRINT_LABELS.ERROR_ALL_DELETED_PURCHASE';
                break;
            case "TRANSFER_ORDER":
                errorMessage = 'INVENTORY.PRINT_LABELS.ERROR_ALL_DELETED_TRANSFER';
                break;
            case "STOCK_ADJUSTMENT":
                errorMessage = 'INVENTORY.PRINT_LABELS.ERROR_ALL_DELETED_ADJUSTMENT';
                break;
            case "PRODUCTION":
                errorMessage = 'INVENTORY.PRINT_LABELS.ERROR_ALL_DELETED_PRODUCTION';
                break;
            case "DISASSEMBLY":
                errorMessage = 'INVENTORY.PRINT_LABELS.ERROR_ALL_DELETED_DISASSEMBLY';
                break;
            default:
                return;
        }
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('INVENTORY.PRINT_LABELS.ERROR_UNABLE_TO_CREATE'),
            content: translate(errorMessage),
            buttons: [
                {
                    class: 'md-primary',
                    text: 'ON_OK',
                    action: function () {
                        return false;
                    }
                }
            ]
        })
    }

    const sendLabelsAndSetFieldsData = () => {
        createLabelsData = {
            labelType: vm.params.labelsType,
            barcodeSource: vm.params.barcodeSource,
            outletId: vm.params.actualOutletId,
            printPrice: vm.params.printPrice,
            printName: vm.params.printName,
            items: vm.params.items.map(({ wareId, quantity }) => (
              { id: wareId, quantity: parseInt(quantity, 10) })
            ),
            tzOffset: tzOffset
        };
        document.getElementById('fieldForServer').value = JSON.stringify(createLabelsData);
        document.getElementById('formForServer').submit();

        nextPrintsData = {
            "reportType": "LABELS",
            "columnsView": [{
                "labelsType": vm.params.labelsType,
                "barcodeSource": vm.params.barcodeSource,
                "printPrice": vm.params.printPrice,
                "printName": vm.params.printName
            }]
        };
        factoryInventory.setReportColumns(nextPrintsData);
    }

    /* Create labels */
    vm.createLabels = function ( editProfile ) {
        // Do not allow a manager to Create labels via CRM
        if ( !editProfile ) {
            serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
            return;
        }

        if ($stateParams.orderType === "PURCHASE_ORDER") {
            //Inventory management/Print labels for purchase order
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Print labels for purchase order"
            });
        } else if ($stateParams.orderType === "TRANSFER_ORDER") {
            // Triggered when users visit the “Create labels” page by
            // clicking on the “Print labels” button in the “Transfer order details” page.
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Print labels for transfer order"
            });
        } else if ($stateParams.orderType === "STOCK_ADJUSTMENT") {
            // Triggered when users visit the “Print labels” page by
            // clicking on the “Create labels” button for stock adjustments.
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Print labels for stock adjustment"
            });
        } else if ($stateParams.orderType === "PRODUCTION") {
            //Triggered when users visit the “Print labels” page
            // by clicking on the “Create labels” button for productions.
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Print labels for production"
            });
        } else if ($stateParams.orderType === "DISASSEMBLY") {
            //Triggered when users visit the “Print labels” page
            // by clicking on the “Create labels” button for disassembly
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Print labels for disassembly"
            });
        }

        if (checkBeforeSave() && checkTotalAmount()) {
            if (checkAllDeleted(vm.params.items)) {
                showAllDeletedError();
            } else if (vm.params.printName === false && vm.params.printPrice === false && vm.params.barcodeSource === "none") {
                serviceToast.show('INVENTORY.PRINT_LABELS.ERROR_NO_ATTRIBUTES_SELECTED', 'error');
            } else {
                if ($stateParams.orderId && $stateParams.orderType) {
                    factoryInventory.isInventoryOrderDeleted({inventoryOrder: {id: $stateParams.orderId, type: $stateParams.orderType}})
                      .then((deleted) => {
                          if (deleted) {
                              offStateChangeStart();
                              goInventoryOrder();
                          } else {
                              sendLabelsAndSetFieldsData();
                          }
                      })
                } else {
                    sendLabelsAndSetFieldsData();
                }


            }
        }
    };

    /* Changing start params */
    offStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, _toState, _toParam, fromState, fromParam) {
        finalParams = {
            labelsType: vm.params.labelsType,
            actualOutletId: vm.params.actualOutletId,
            barcodeSource: vm.params.barcodeSource,
            printPrice: vm.params.printPrice,
            printName: vm.params.printName,
            items: vm.params.items
        };

        if (!angular.equals(startParams, finalParams)) {
            event.preventDefault();
            $timeout(function () {
                serviceDialog.add( {
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: $filter('translate')('UNSAVED_TITLE'),
                    content: $filter('translate')('UNSAVED_LEAVE'),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {
                                return false;
                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go( _toState, _toParam );
                            }
                        }
                    ]
                } );
            } );
        } else {
            offStateChangeStart();
        }
    });
}

export default {
    type: 'controller',
    name: 'createLabels',
    value: createLabels
};
