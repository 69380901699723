/**
 * Created by mars on 9/2/15.
 * deps angular
 */

import angular from 'angular';

(function (angular) {

	/**
	 * Преобразует все переменные обьекта равные String 'null' в null
	 * @param obj
	 */
	function stringNullToNull( obj ) {
		function _set( _obj ) {
			for ( var opt  in _obj ) {
				if ( typeof _obj[opt] == "object" ) {
					_set( _obj[opt] )
				} else if ( _obj[opt] == "null" ) {
					_obj[opt] = null
				}
			}
		}

		_set( obj );
	}

	function isEmpty( obj ) {
		for ( var opt in obj ) {
			return false
		}
		return true;
	}

	/**
	 * Формирует объект с одной вложенностью  из многовложенного объекта
	 *
	 * @param obj
	 * @param _map
	 * @param _opt
	 * @returns {*|{}} возвращает  объект с одной вложенностью
	 */

	function objToMap( obj, _map, _opt ) {
		var map = _map || {};
		for ( var opt in obj ) {
			switch ( true ) {
				case obj[opt] instanceof Date:
					map[(_opt ? _opt + "." : "") + opt] = obj[opt].toString();
					break;
				case obj[opt] instanceof Array:
					objToMap( obj[opt], map, opt );
					break;
				case obj[opt] instanceof Object:
					if ( obj instanceof Array ) {
						objToMap( obj[opt], map, _opt + "." + opt );
					} else {
						objToMap( obj[opt], map, opt );
					}
					break;
				default :
					map[(_opt ? _opt + "." : "") + opt] = obj[opt];
			}
		}
		return map
	}




	var date = {
		add: function () {
			var argLength = arguments.length;

			for ( var i = 0; i < argLength / 2; i++ ) {

			}

		}
	};

	Date.prototype.add = function () {
		var argLength = arguments.length;
		var d = this;
		var date;

		function _set( yy, MM, dd, HH, mm, ss, mss ) {
			var _d = date || d;
			date = new Date( _d.getFullYear() + yy, _d.getMonth() + MM, _d.getDate() + dd, _d.getHours() + HH, _d.getMinutes() + mm, _d.getSeconds() + ss, _d.getMilliseconds() + mss );
		}

		for ( var i = 0; i < argLength / 2; i++ ) {

			switch ( arguments[i] ) {
				case 'yy':
					_set( arguments[i + argLength / 2], 0, 0, 0, 0, 0, 0 );
					break;
				case 'MM':
					_set( 0, arguments[i + argLength / 2], 0, 0, 0, 0, 0 );
					break;
				case 'dd':
					_set( 0, 0, arguments[i + argLength / 2], 0, 0, 0, 0 );
					break;
				case 'HH':
					_set( 0, 0, 0, arguments[i + argLength / 2], 0, 0, 0 );
					break;
				case 'mm':
					_set( 0, 0, 0, 0, arguments[i + argLength / 2], 0, 0 );
					break;
				case 'ss':
					_set( 0, 0, 0, 0, 0, arguments[i + argLength / 2], 0 );
					break;
				case 'mss':
					_set( 0, 0, 0, 0, 0, 0, arguments[i + argLength / 2] );
					break;
			}
		}

		return date || d
	};

	String.prototype.toDash = function(){
		return this.replace(/([A-Z])/g, function($1){return "-"+$1.toLowerCase();});
	};
	String.prototype.toCamel = function(){
		return this.replace(/(\-[a-z])/g, function($1){return $1.toUpperCase().replace('-','');});
	};
	String.prototype.trim = function(){
		return this.replace(/^\s+|\s+$/g, "");
	};
	String.prototype.toUnderscore = function(){
		return this.replace(/([A-Z])/g, function($1){return "_"+$1.toLowerCase();});
	};
	function elPosition(obj){
		function findPosX( obj ) {
			var curleft = 0;
			if ( obj.offsetParent )
				while ( 1 ) {
					curleft += obj.offsetLeft;
					if ( !obj.offsetParent )
						break;
					obj = obj.offsetParent;
				}
			else if ( obj.x )
				curleft += obj.x;
			return curleft;
		}

		function findPosY( el ) {
			var el2 = el;
			var curtop = 0;
			var curleft = 0;
			if ( document.getElementById || document.all ) {
				do {
					curleft += el.offsetLeft - el.scrollLeft;
					curtop += el.offsetTop - el.scrollTop;
					el = el.offsetParent;
					el2 = el2.parentNode;
					while ( el2 != el ) {
						curleft -= el2.scrollLeft;
						curtop -= el2.scrollTop;
						el2 = el2.parentNode;
					}
				} while ( el && el.offsetParent );

			} else if ( document.layers ) {
				curtop += el.y;
				curleft += el.x;
			}
			return curtop;
		}

		function posXY( obj ) {
			return {
				x: findPosX( obj ),
				y: findPosY( obj )
			}
		}
		return posXY(obj)
	}

	function toFixed(a, n){
		if(typeof a != 'number') a = parseFloat(a);
		return  parseFloat(a.toFixed(n));
	}
	angular.extend( angular, {
		elPosition: elPosition,
		stringNullToNull: stringNullToNull,
		isEmpty: isEmpty,
		isEmptyObject: isEmpty,
		objToMap: objToMap,
		date: date,
		toFixed: toFixed
	} );


	angular.extend( $ , {
		toFixed: toFixed
	});

	angular.element.prototype.parent = function () {
		var el = this[0];
		return el ? angular.element( el.parentElement ) : null;
	}

})(angular);