/**
 * Created by mars on 10/6/15.
 */

import angular from 'angular';

import lvBtn from './directive-lv-btn';

export default angular
	.module('lv.pretty', [])
	.directive('lvBtn', lvBtn)
	.name;