import angular from 'angular';

validCal.$inject = ['$compile','$timeout', '$filter', '$rootScope', 'dateCustomMethods'];
// TODO: Oleksii: dateCustomMethods is in app module
function validCal($compile, $timeout, $filter, $rootScope, dateCustomMethods){
    function stringToDate( val  ) {
        var fixedLocaleDate = $filter('formatDateByBrowser')(new Date(1525737600000), 'calendarInput');
        var dateFormatDivider = fixedLocaleDate.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/);
        var fixedLocaleDateFormat = fixedLocaleDate.split(dateFormatDivider);
        var refinedFixedLocaleDateFormat = [];
        fixedLocaleDateFormat.forEach(function(val){
            refinedFixedLocaleDateFormat.push(parseInt(val) + '');
        });
        var indexOfDay = (fixedLocaleDateFormat.indexOf("8") !== -1) ? fixedLocaleDateFormat.indexOf("8") : (fixedLocaleDateFormat.indexOf("7") !== -1) ? fixedLocaleDateFormat.indexOf("7") : (fixedLocaleDateFormat.indexOf("9") !== -1) ? fixedLocaleDateFormat.indexOf("9") : (refinedFixedLocaleDateFormat.indexOf("8") !== -1) ? refinedFixedLocaleDateFormat.indexOf("8") : (refinedFixedLocaleDateFormat.indexOf("7") !== -1) ? refinedFixedLocaleDateFormat.indexOf("7") : refinedFixedLocaleDateFormat.indexOf("9");
        var indexOfMonth = (fixedLocaleDateFormat.indexOf("5") !== -1) ? fixedLocaleDateFormat.indexOf("5") : refinedFixedLocaleDateFormat.indexOf("5");
        var indexOfYear = (fixedLocaleDateFormat.indexOf("2018")!== -1) ? fixedLocaleDateFormat.indexOf("2018") : refinedFixedLocaleDateFormat.indexOf("2018");
        var splitVal = fixedLocaleDate.split(dateFormatDivider);
        var charsInDate = [];
        splitVal.forEach(function(arrElement){
            if(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)){
                charsInDate.push(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)[0]);
            }
        });

        return {
            firstPartForRegex1: refinedFixedLocaleDateFormat[0],
            firstPartForRegex2: fixedLocaleDateFormat[0],
            secondPartForRegex1: refinedFixedLocaleDateFormat[1],
            secondPartForRegex2: fixedLocaleDateFormat[1],
            thirdPartForRegex1: refinedFixedLocaleDateFormat[2],
            thirdPartForRegex2: fixedLocaleDateFormat[2],
            dateFormatDivider: dateFormatDivider,
            charsInDate: charsInDate,
            indexOfDay: indexOfDay,
            indexOfMonth: indexOfMonth,
            indexOfYear: indexOfYear,
            dd: parseInt( val[indexOfDay] ),
            MM: parseInt( val[indexOfMonth] ),
            yyyy: parseInt( val[indexOfYear] )
        };
    }

    var valid = {
        date: function( val){
            var regExpPattern;
            var st = true;
            var mess = null;
            var eventualPattern;
            var patternOrder = [];
            var currentDateForStandardizedPattern = $filter( 'formatDateByBrowser' )( new Date(), '' );

            patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfDay, 0, $filter('translate')("date_format.dd"));
            patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfMonth, 0, $filter('translate')("date_format.mm"));
            patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfYear, 0, $filter('translate')("date_format.yyyy"));
            eventualPattern = patternOrder.join(stringToDate(currentDateForStandardizedPattern).dateFormatDivider);


            if ( val ) {
                val = '' + val;
            } else {
               return {
                    st: false,
                    val: val,
                    mess: 'pattern_localized_date_err',
                    eventualPattern: eventualPattern
                };
            }

            var arabicIdentificator = new RegExp("[\u0660-\u0669]"); //solely for Arabic digits in a date
            var localeDivider = stringToDate(val).dateFormatDivider;
            var symbols = localeDivider[0].split(' ').join('');
            var chars  = stringToDate(val).charsInDate.join('');

            function correspondToRexEx (element) {
                var specialLocalDateFormat = new RegExp('[0-9]' + '[' + symbols + ']');
                if(element.match(specialLocalDateFormat)){
                    return true;
                }
            }

            if($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
                regExpPattern = '[0-9]{2}\/[0-9]{2}\/[0-9]{4}';
            } else if(val.match(arabicIdentificator)) {

            } else {
               var specialPattern = val.split(localeDivider).some(correspondToRexEx);
               if(specialPattern){
                   // console.log("I am special");
                   regExpPattern = '[0-9' + symbols + '\s]{' + stringToDate(val).firstPartForRegex2.length +'}' + '\\'+localeDivider + '{1}' + '[0-9' + symbols + '\s]{' + stringToDate(val).secondPartForRegex2.length + '}' + '\\'+ localeDivider + '{1}' + '[0-9' + symbols + '\s]{' + stringToDate(val).thirdPartForRegex2.length + '}';
               } else {
                   regExpPattern = '[0-9' + chars + ']{' + stringToDate(val).firstPartForRegex1.length +',' + stringToDate(val).firstPartForRegex2.length + '}'
                       + '\\'+ localeDivider + '{1}' + '[0-9' + chars + ']{' + stringToDate(val).secondPartForRegex1.length + ','+ stringToDate(val).secondPartForRegex2.length + '}'
                       + '\\'+ localeDivider + '{1}' + '[0-9' + chars + ']{' + stringToDate(val).thirdPartForRegex1.length + ',' + stringToDate(val).thirdPartForRegex2.length + '}';
               }
            }

            var dateRegExp = (regExpPattern) ? new RegExp(regExpPattern) : false;

            var dateArray = val.split(""+localeDivider+"");
            var indexOdDay = stringToDate(currentDateForStandardizedPattern).indexOfDay;
            var indexOfMonth = stringToDate(currentDateForStandardizedPattern).indexOfMonth;
            var indexOfYear = stringToDate(currentDateForStandardizedPattern).indexOfYear;
            var dateForCheck = new Date(parseInt( dateArray[indexOfYear] ), parseInt( dateArray[indexOfMonth] ), 0).getDate();
            if(!val.match(dateRegExp)) {
                return  {
                    st: false,
                    val: val,
                    mess: 'pattern_localized_date_err',
                    eventualPattern: eventualPattern
                }
            }
            if(dateArray[indexOfMonth] > 12 || parseInt(dateArray[indexOfMonth]) === 0){
                return {
                    st: false,
                    val: val,
                    mess: 'pattern_localized_date_err',
                    eventualPattern: eventualPattern
                };
            } else if (dateArray[indexOdDay] > dateForCheck || parseInt(dateArray[indexOdDay]) === 0) {
                return {
                    st: false,
                    val: val,
                    mess: 'pattern_localized_date_err',
                    eventualPattern: eventualPattern
                };
            }

            return {
                st: st,
                val: val,
                mess: mess,
                eventualPattern: eventualPattern
            };
        }
    };

    function createElementAlert( parent, scope) {
        var template;

        var dateForPattern = new Date().toLocaleDateString();
        scope.patternForMessage = dateForPattern.toString();
        parent.addClass( 'relative' );

        if ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
            template = "<div class='err-mess animate-err-mess' ng-if='showErrorMessage && alertMessage'>{{'ERROR.'+alertMessage | translate}}</div>";
        } else {
            template = "<div class='err-mess animate-err-mess' ng-if='showErrorMessage && alertMessage'>{{('ERROR.'+ alertMessage  | translate)  + eventualPattern }}</div>";
        }

        var linkFn = $compile( template );
        var content = linkFn( scope );
        parent.append( content );

        return content;
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            valid: '@validCal',
            validMessage: '@'
        },
        link: function ( scope, el, attr, ctrl ) {
            var isAfter = false,
                isBefore = false,
                isExact = false;

            scope.showErrorMessage = false;
            var minDate = $filter('formatDateByBrowser')(scope.$parent.minDate, 'calendarInput');
            var maxDate = $filter('formatDateByBrowser')(scope.$parent.maxDate, 'calendarInput');
            var validMessage = eval(scope.validMessage);
            if (validMessage) {
                createElementAlert(el.parent(), scope);
            }

            if (el.hasClass('info-label-after')) {
                isAfter = true;
            }
            if (el.hasClass('info-label-before')) {
                isBefore = true;
            }
            if (el.hasClass('info-label-exact')) {
                isExact = true;
            }



            /**срабатывает при старте*/
            // ctrl.$formatters.unshift(checkForEven);
            /**срабатывает при редактировании*/
            // ctrl.$parsers.unshift(checkForEven);

            ctrl.err = {};
            var position;
            var valueLength; //boolean
            var maxLength;


            function checkForEven(value) {
                var neWval;
                var _valid;

                minDate = scope.$parent.minDate;
                maxDate = scope.$parent.maxDate;
                if (isAfter) {
                    if (maxDate) {
                        if (scope.$parent.beforeLabelValue < maxDate) {
                            maxDate = scope.$parent.beforeLabelValue
                        }
                    } else {
                        maxDate = scope.$parent.beforeLabelValue
                    }
                }

                if (isBefore) {
                    if (minDate) {
                        if (minDate < scope.$parent.afterLabelValue) {
                            minDate = scope.$parent.afterLabelValue
                        }
                    } else {
                        minDate = scope.$parent.afterLabelValue
                    }
                }

                if (isExact) {
                    if (maxDate) {
                        if (scope.$parent.exactLabelValue < maxDate) {
                            maxDate = scope.$parent.exactLabelValue
                        }
                    } else {
                        maxDate = scope.$parent.exactLabelValue
                    }
                }

                if (value === valid[scope.valid](value).val) {
                    neWval = value;
                } else {
                    try {
                        position = doGetCaretPosition(el[0]);
                        _valid = valid[scope.valid](value);
                        neWval = _valid.val;
                        maxLength = _valid.maxLength;
                        valueLength = (value.length === neWval.length || maxLength < value.length);

                        if (valueLength) {
                            setCaretPosition(el[0], position);
                        } else {
                            setCaretPosition(el[0], position - 1);
                        }
                        ctrl.$setViewValue(neWval);
                        ctrl.$render();

                    } catch (err) {
                        console.log(err);
                    }

                }

                scope.eventualPattern = valid[scope.valid](neWval);

                if (valid[scope.valid](neWval).st) {
                    ctrl.err[scope.valid] = null;
                    scope.alertMessage = null;
                    var editedValue = dateCustomMethods.fromFormattedStringToDateObject(ctrl.$viewValue);
                    if (!isExact && ((isAfter && editedValue > scope.$parent.beforeLabelValue) || (isBefore && scope.$parent.afterLabelValue > editedValue) && !scope.templateErrorTime)) {
                        scope.startEndErrorTime = true;
                        scope.isError = true;
                        scope.$parent.startEndError = true;
                        angular.element(document.querySelector(".info-label-before")).parent().addClass("has-error");
                        angular.element(document.querySelector(".info-label-before")).parent().addClass("md-input-invalid");
                        document.getElementsByClassName('save-calendar-data')[0].setAttribute("disabled", "disabled");
                    } else {
                        scope.isError = false;
                        ctrl.$setValidity(scope.valid, true);
                        el.parent().removeClass("has-error");
                        if (scope.$parent.startEndError) scope.$parent.startEndError = false;
                        if(!isExact) {
                            angular.element(document.querySelector(".info-label-before")).parent().removeClass("has-error");
                            angular.element(document.querySelector(".info-label-before")).parent().removeClass("md-input-invalid");
                        } else {
                            angular.element(document.querySelector(".info-label-exact")).parent().removeClass("has-error");
                            angular.element(document.querySelector(".info-label-exact")).parent().removeClass("md-input-invalid");
                        }
                    }

                    if (!scope.$parent.formInputf.$invalid && !scope.$parent.startEndError)
                        document.getElementsByClassName('save-calendar-data')[0].removeAttribute("disabled");
                } else {
                    if(!scope.startEndErrorTime) {
                        scope.isError = true;
                        scope.templateErrorTime = true;
                        ctrl.$setValidity(scope.valid, false);
                        ctrl.err[scope.valid] = valid[scope.valid](neWval).mess;
                        scope.alertMessage = ctrl.err[scope.valid];
                        scope.eventualPattern = valid[scope.valid](neWval).eventualPattern;
                        if (scope.$parent.startEndError) scope.$parent.startEndError = false;
                        el.parent().addClass("has-error");
                        document.getElementsByClassName('save-calendar-data')[0].setAttribute("disabled", "disabled");
                    }
                }
                return neWval;
            }

            el
                .on('blur', function () {
                    scope.templateErrorTime = false;
                    scope.startEndErrorTime = false;
                    checkForEven(ctrl.$viewValue);
                    scope.showErrorMessage = scope.isError;
                    if(scope.showErrorMessage) {
                        /**срабатывает при редактировании*/
                        ctrl.$parsers.unshift(checkForEven);
                    } else {
                        ctrl.$parsers.splice(ctrl.$parsers.indexOf(checkForEven), 1);
                    }
                    $timeout(function () {
                        scope.$apply();
                    });
                });

            scope.$on('validateDate', function () {
                    scope.showErrorMessage = false;
                    ctrl.$parsers.splice(ctrl.$parsers.indexOf(checkForEven), 1);
                });


            function doGetCaretPosition(element) {
                var CaretPos = 0;	// IE Support
                if (document.selection) {
                    element.focus();
                    var Sel = document.selection.createRange();
                    Sel.moveStart('character', -element.value.length);
                    CaretPos = Sel.text.length;
                }
                // Firefox support
                else if (element.selectionStart || element.selectionStart === '0')
                    CaretPos = element.selectionStart;
                return (CaretPos);
            }

            function setCaretPosition(element, pos) {
                if (element.setSelectionRange) {
                    element.focus();
                    element.setSelectionRange(pos, pos);
                }
                else if (element.createTextRange) {
                    var range = element.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', pos);
                    range.moveStart('character', pos);
                    range.select();
                }
            }
        }
    }
}

export default validCal;