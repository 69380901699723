/**
 * Created by Kiskenbassker on 8.04.18
 */

function taxPercentCommon() {
    function format(val, delimiter, decimalQuantity) {
        if(typeof(val) === "string") {
            val = parseInt(val, 10);
        }
        if(typeof(val) === "number" && !isNaN(val)) {
            var modulo = delimiter || ',';
            var decimal = decimalQuantity || 5;
            var toDivide = Math.pow(10, decimal);
            var value = (val / toDivide).toString();
            var result;
            var valArray;

            if(value.indexOf(".") !== -1) {
                valArray = value.split(".");
                result = valArray.join(modulo);
            } else {
                result = value;
            }

            return result;
        }
    }

    return format;
}

export default {
    type: 'filter',
    name: 'taxPercentCommon',
    value: taxPercentCommon
};