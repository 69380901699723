/**
 * Created by  Alex on 31.05.17.
 */

accountBlockController.$inject = [];

function accountBlockController() {}

export default {
    type: 'controller',
    name: 'accountBlockController',
    value: accountBlockController
};