tableViewColumnCustomDirective.$inject = [];

function tableViewColumnCustomDirective(){
    return {
        restrict: 'E',
        transclude: true,
        require: '^lvTableView',
        scope: {
            title: '@lvTitle'
        },
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, tableCtrl, transcludeFn){
        let column = {
            title: scope.title,
            transcludeFn: transcludeFn
        };
        tableCtrl.addColumn(column);
    }
}

export default {
    type: 'directive',
    name: 'lvTableViewColumnCustom',
    value: tableViewColumnCustomDirective
};