/**
 * Created by sirosh on 12.01.16.
 * @memberof getKitchen
 * @ngdoc factory
 * @name getTaxList
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param factoryErrorAlert {service} error handler service
 */

import angular from 'angular';

getKitchen.$inject = ["$http", "$q", "factoryErrorAlert"];

function getKitchen($http, $q, factoryErrorAlert) {


// получаем список всех кухонных принтеров
    function getKitchenList(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getownercabprinters', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.ownercabPrinters);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем список всех кухонных принтеров


// получаем список категорий, которые можно привязать к принтеру
    function getCategoriesForPrinter(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getcategoriesforprinter', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.categories);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем список категорий, которые можно привязать к принтеру


// создаем новый кухоннный принтер
    function setKitchen(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/createownercabprinter', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result) {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// создаем новый кухоннный принтер



// Удаление кухонного принтера
    function deleteKitchen(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deleteownercabprinters', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    resolve(response.data);
                    return;
                }
                resolve(response);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Удаление кухонного принтера



// Получение кухонного принтера по ID
    function getKitchenById(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getownercabprinterbyid', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    resolve(response.data);
                    return;
                }
                resolve(response);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Получение кухонного принтера по ID




// Редактирование кухонного принтера
    function editKitchen(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/editownercabprinter', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result) {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Редактирование кухонного принтера


    return {
        getKitchenList: getKitchenList,
        getCategoriesForPrinter: getCategoriesForPrinter,
        setKitchen: setKitchen,
        deleteKitchen: deleteKitchen,
        getKitchenById: getKitchenById,
        editKitchen: editKitchen
    };

}

export default {
    type: 'factory',
    name: 'getKitchen',
    value: getKitchen
};