import angular from 'angular';

receiveOrder.$inject = [
    '$scope',
    '$stateParams',
    '$state',
    '$receiveOrder',
    '$critCountOptionsEdit',
    'factoryInventory',
    '$filter',
    'serviceToast',
    '$countQuantityForOrder',
    '$currencyOptionsShow',
    'serviceDialog',
    '$permitsManager',
    'dialogs',
    'factoryPurchaseOrders',
    'constantStateErrors'
];

function receiveOrder(
    $scope,
    $stateParams,
    $state,
    $receiveOrder,
    $critCountOptionsEdit,
    factoryInventory,
    $filter,
    serviceToast,
    $countQuantityForOrder,
    $currencyOptionsShow,
    serviceDialog,
    $permitsManager,
    dialogs,
    factoryPurchaseOrders,
    constantStateErrors
) {
    var vm = this;
    var translate = $filter('translate');
    vm.receiveOrder = angular.copy($receiveOrder);
    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    $scope.receiveItems = vm.receiveOrder.items;
    vm.displayCost = $permitsManager.costAllowed;
    vm.landedCosts = vm.receiveOrder.landedCosts;
    vm.disableButton = false;
    $scope.countQuantityForOrder = $countQuantityForOrder;
    $scope.currencyOptionsShow = $currencyOptionsShow;
    var viewError = false;
    var wareCount = $filter( 'wareCount' );

    for (var i =0; i<$scope.receiveItems.length; i++) {
        $scope.receiveItems[i].toReceive = 0;
        $scope.receiveItems[i].errMessege = false;
    }

    vm.forSend = {
        orderId: $stateParams.id,
        items: [],
        landedCostIds: []
    };

    const goPurchaseOrdersList = () => {
        factoryPurchaseOrders.backToPurchaseOrdersList([]);
    }

    vm.landCostChange = function(item) {
        if(item.selected === true) {
            vm.forSend.landedCostIds.push(item.id);
        } else if (item.selected === false) {
            vm.forSend.landedCostIds.forEach(function(obj, $index){
                if(obj === item.id) {
                    vm.forSend.landedCostIds.splice($index, 1);
                }
            })
        }
    };

    function isAllReceived() {
        var ordered = 0;
        var received = 0;
        $scope.receiveItems.forEach(function(item){
            ordered += item.ordered;
            received += item.received + parseInt(wareCount(item.toReceive, ".", true));
        });
        return ordered - received === 0;
    }

    $scope.markAll = function() {
        vm.forSend.landedCostIds = [];
        for(var i =0; i<$scope.receiveItems.length; i++) {
            if($scope.receiveItems[i].received >= $scope.receiveItems[i].ordered){
                $scope.receiveItems[i].toReceive = 0;
            } else {
                $scope.receiveItems[i].toReceive = wareCount($scope.receiveItems[i].ordered - $scope.receiveItems[i].received, ".", false);
            }
        }
        for (var j = 0; j < vm.landedCosts.length; j++) {
            vm.landedCosts[j].selected = true;
            vm.forSend.landedCostIds.push(vm.landedCosts[j].id);
        }
    };

    function forSend() {
        for(var i =0; i<$scope.receiveItems.length; i++) {
            vm.forSend.items[i] = {
                id: $scope.receiveItems[i].id,
                toReceive: wareCount($scope.receiveItems[i].toReceive, ".", true) || 0
            }
        }
    }

    function changes() {
        for(var i =0; i<$scope.receiveItems.length; i++) {
            if($scope.receiveItems[i].toReceive !== 0) {
                return true;
            }
        }
        return false;
    }


    $scope.$watch('receiveItems', function(changed){
        for(var i = 0; i < changed.length; i++) {
            var errorInput = document.getElementsByClassName('resultOfCounting')[i];
            var toReceive = parseInt(wareCount(changed[i].toReceive, ".", true));
            var orderedNotReceived = changed[i].ordered - changed[i].received;
            if(toReceive > orderedNotReceived) {
                serviceToast.show('ERROR.not_less_then_ordered', 'error');
                angular.element(errorInput).addClass('md-input-invalid');
                viewError = true;
                break;
            } else {
                angular.element(errorInput).removeClass('md-input-invalid');
                viewError = false;
            }
        }
    }, true);

    const sendRequestOnReceive = () => {
        factoryInventory.receiveOrder(vm.forSend).then((data) => {
            if (data.result === "ok") {
                serviceToast.show("INVENTORY.ORDER_RECEIVED", 'success');
                $state.go('inventory.orederdetail', {
                    id: $stateParams.id
                });
            } else if (data.result === "error") {
                if (data.message === "received_more_than_ordered") {
                    serviceToast.show("ERROR.not_less_then_ordered", "error");
                } else if (data.message === "purchase_order_closed") {
                    dialogs.purchaseOrderClosed().then($scope.onCancel);
                }
                vm.disableButton = false;
            }
        })
    }

    $scope.onReceive = function() {
        if(!changes()) {
            vm.disableButton = true;
            $state.go('inventory.orederdetail', {
                id:$stateParams.id
            });
        } else {
            if (viewError) return;
            forSend();
            const allReceived = isAllReceived();
            if (allReceived && vm.forSend.landedCostIds.length < vm.landedCosts.length) {
                vm.disableButton = true;
                serviceDialog.add({
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    content: translate('INVENTORY.UNRECEIVED_LAND_COST'),
                    buttons: [
                        {
                            text: 'ON_CANCEL',
                            action: function () {
                                vm.disableButton = false;
                                return false;
                            }
                        },
                        {
                            class: 'primary',
                            text: 'CONFIRM',
                            action: () => {
                                sendRequestOnReceive();
                            }
                        }
                    ]
                });
            } else {
                vm.disableButton = true;
                sendRequestOnReceive();
            }
        }
    };

    $scope.onCancel = () => {
        $state.go('inventory.orederdetail', {
            id: $stateParams.id
        });
    }
}

export default {
    type: 'controller',
    name: 'receiveOrder',
    value: receiveOrder
};
