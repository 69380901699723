/**
 * Created by olehkss on 05.04.16.
 * @ngdoc factory
 * @name app.factory: shareReceipt
 * @description
 * Shares selected receipt across whole module app
 */

shareReceipt.$inject = [];
function shareReceipt() {

    var selectedReceipt;
    var currencyShow;
    var percentShowTax;
    var receiptFormat;
    var cancelled;
    var merchantCancelled;
    var accessCancelReceipts;
    var cancelingProcessStarted;

    return {
        setReceipt: setRes,
        getReceipt: getRes,
        setCurrencyShow: setCurrencyShow,
        getCurrencyShow: getCurrencyShow,
        setPercentShowTax: setPercentShowTax,
        getPercentShowTax: getPercentShowTax,
        setReceiptFormat: setReceiptFormat,
        getReceiptFormat: getReceiptFormat,
        setCancelled: setCancelled,
        getCancelled: getCancelled,
        setMerchantCancelled: setMerchantCancelled,
        getMerchantCancelled: getMerchantCancelled,
        /*для проверки наличия права доступа */
        setAccessCancelReceipts: setAccessCancelReceipts,
        getAccessCancelReceipts: getAccessCancelReceipts,
        setCancelingProcessStarted: setCancelingProcessStarted,
        getCancelingProcessStarted: getCancelingProcessStarted
    };

    /**
     * @ngdoc function
     * @param {Object} res The receipt object
     * @returns {*}
     * @description
     * Sets selected receipt to selectedReceipt variable
     *
     */
    function setRes (res) {
        selectedReceipt = res;
    }

    function setCurrencyShow (res) {
        currencyShow = res;
    }

    function setPercentShowTax (res) {
        percentShowTax = res;
    }

    function setReceiptFormat(res) {
        receiptFormat = res;
    }

    function setCancelled(res) {
        cancelled = res;
    }

    function setMerchantCancelled(res) {
        merchantCancelled = res;
    }

    function setAccessCancelReceipts(res) {
        accessCancelReceipts = res;
    }

    function setCancelingProcessStarted(flag) {
        cancelingProcessStarted = flag;
    }

    /**
     * @ngdoc function
     * @returns {*}
     * @description
     * Returns selectedReceipt value
     */
    function getRes () {
        return selectedReceipt;
    }

    function getCurrencyShow () {
        return currencyShow;
    }

    function getPercentShowTax () {
        return percentShowTax;
    }

    function getReceiptFormat () {
        return receiptFormat;
    }

    function getCancelled() {
        return cancelled;
    }

    function getMerchantCancelled() {
        return merchantCancelled;
    }

    function getAccessCancelReceipts() {
        return accessCancelReceipts;
    }

    function getCancelingProcessStarted() {
        return cancelingProcessStarted;
    }

}

export default {
    type: 'factory',
    name: 'shareReceipt',
    value: shareReceipt
};