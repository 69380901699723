/**
 * Created by olehkss on 12.05.16.
 *
 * @ngdoc directive
 * @name app.directive:calendarTop
 * @scope
 * @restrict E
 * @description
 * Adds calendar and four buttons(today, yesterday, week, month) to the report page.
 * Uses $stateParams and $state objects in order to reload page with new information.
 * @example
 * <calendar-top></calendar-top>
 */

import template from '../templates-pug/for-directive/calendar-top.pug';

CalendarTopOneDateDirective.$inject = ['$stateParams', '$state', 'filtersData', 'changeDivider', 'getChartLabel', 'lvMixpanel'];

function CalendarTopOneDateDirective($stateParams, $state, filtersData, changeDivider, getChartLabel, lvMixpanel) {

    return {
        restrict: 'E',
        controller: controller,
        link: link,
        scope: {
            date: '='
        },
        controllerAs: 'vmCalendarTop',
        template: template
    };

    function link(scope, element, attrs, vm) {
        vm.calendarAction = calendarAction;
        vm.previousPeriod = previousPeriod;
        vm.nextPeriod = nextPeriod;
        scope.invValuation = scope.$parent.invValuation;
        var body = document.querySelector('body');
        scope.topButtonsForArab = body.classList.contains('aranav');
        var today = new Date();
        var formatedToday = today.getFullYear()+"-"+fixDigit(today.getMonth()+1)+"-"+fixDigit(today.getDate());

        var stateParams;



        /**
         * @ngdoc function
         * @param {Date} from the start of time period
         * @param {Date} to the end of time period
         * @description
         * After selection of the time period via calendar this function will be triggered. It have been passed as a
         * parameter to calendar directive
         */
        function calendarAction(from, to, exact) {
            var fromString = exact.getFullYear()+"-"+fixDigit(exact.getMonth()+1)+"-"+fixDigit(exact.getDate());
            if ( $stateParams.date !== fromString) {
                var stateParamsForSave = filtersData.getStateParams();
                $stateParams.date = formatedToday !== fromString ? fromString : null;

                stateParamsForSave.date = $stateParams.date
                stateParams = filtersData.setStateParams(stateParamsForSave);
                $state.go($state.current.name, stateParams, {
                    reload: true
                });
            }

        }

        function previousPeriod(from) {

            var exact = from ? from : new Date();
            var date = new Date(exact.getFullYear(), exact.getMonth(), exact.getDate()-1);
            $stateParams.date = date.getFullYear()+"-"+fixDigit(date.getMonth()+1)+"-"+fixDigit(date.getDate());

            if(new Date($stateParams.date) < new Date("Jan 1 2000 00:00:00")) {
                $stateParams.date = "2000-01-01";
            }
            var stateParamsForSave = filtersData.getStateParams();
            stateParamsForSave.date = $stateParams.date
            stateParams = filtersData.setStateParams(stateParamsForSave);

            // if($state.current.name === 'inventory.history') {
            //     lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Previous date range"});
            // } else {
            //     lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Previous date range"});
            // }
            $state.go($state.current.name, stateParams, {
                reload: true
            });

        }

        function nextPeriod(to) {

            var date = new Date(to.getFullYear(),to.getMonth(), to.getDate()+1);
            $stateParams.date = date.getFullYear()+"-"+fixDigit(date.getMonth()+1)+"-"+fixDigit(date.getDate());

            if(new Date($stateParams.date) > new Date() || formatedToday === $stateParams.date ) {
                $stateParams.date = null;
            }

            var stateParamsForSave = filtersData.getStateParams();
            stateParamsForSave.date = $stateParams.date
            stateParams = filtersData.setStateParams(stateParamsForSave);

            // if($state.current.name === 'inventory.history') {
            //     lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Next date range"});
            // } else {
            //     lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Next date range"});
            // }
            $state.go($state.current.name, stateParams, {
                reload: true
            });
        }

        function fixDigit(val){
            return val.toString().length === 1 ? "0" + val : val;
        }
    }
}

controller.$inject = ['$scope', '$stateParams'];
function controller($scope, $stateParams) {
    var vm = this;

    this.$onInit = function() {
        var parsDate = $scope.date.split("-");
        vm.exact = new Date(new Date(parsDate[0], parsDate[1] -1, parsDate[2]).getTime());
        vm.dateMax = new Date();
        vm.dateMin = new Date("Jan 1 2000 00:00:00");
        vm.templateView = "labelTemplateExact";
        vm.noNext = new Date(vm.exact.getFullYear(), vm.exact.getMonth(), vm.exact.getDate()).getTime() === new Date(vm.dateMax.getFullYear(), vm.dateMax.getMonth(), vm.dateMax.getDate()).getTime();
        vm.noPrev = new Date(vm.exact.getFullYear(), vm.exact.getMonth(), vm.exact.getDate()).getTime() === new Date(vm.dateMin.getFullYear(), vm.dateMin.getMonth(), vm.dateMin.getDate()).getTime();
        vm.noPredefined = true;
    }
}

export default {
    type: 'directive',
    name: 'calendarTopOneDate',
    value: CalendarTopOneDateDirective
};
