import angular from 'angular';
import calendarLabelExactTemplate from '../templates-pug/calendar-label-exact.pug';
import calendarLabelTemplate from '../templates-pug/calendar-label.pug';
import calendarViewTemplate from '../templates-pug/calendar-view.pug';

calendar.$inject = ['$compile', '$templateFactory', '$mdUtil'];

function calendar($compile ,$templateFactory, $mdUtil) {
	return {
		restrict: 'E',
		template: calendarLabelTemplate,
		scope: {
			before: '=before',
			after: '=after',
			link: '@link',
			viewMonths: '@viewMonths',
			formatDate: '@formatDate',
			maxDate: '=',
			minDate: '=',
			startWeek: '@',
			endWeek: '@',
			exact: '=',
			lock: '=',
			applyAction: '=',
			labelViewFormat: '=',
			previousPeriod: '=',
			nextPeriod: '=',
			noNext: '=',
			noPrev: '=',
			noPredefined: '=',
			unlimitedSalesHistory: '<?'
		},
		controller: 'calendarContrl',
		link: function ( $scope, $element, $rootScope ) {

			let restoreScroll = null;
			let backdrop = null;

			$scope.name = 'calendar';
			let content = null;
			$scope.show = false;
			$scope.lock = eval( $scope.lock );
			$scope.elParent = $element;
			let body = document.querySelector('body');
			$scope.aranav = body.classList.contains('aranav');
			let bodyEl = angular.element(body);

			// if(window.innerWidth<600){
			$scope.viewMonths = 1;
			// }
			$scope.templateView = $scope.labelViewFormat;

			function init() {
				let linkFn = $compile(calendarViewTemplate);
				$scope.content = content = linkFn( $scope );
				content.css( 'display', 'none' );
				content.css( 'visibility', 'hidden' );

				var body = angular.element( document.body );
				body.append( content );

				$scope.calendarElement = content;
				$scope.show = true;

				// TODO: Oleksii: use template instead templateFactory
				// var linkFn = null;
				// $templateFactory.fromUrl('../module/lv-calendar/templates/calendar-view.html' ).then(function(d){
				// 	linkFn = $compile( d );
				// 	$scope.content = content = linkFn( $scope );
				// 	content.css( 'display', 'none' );
				// 	content.css( 'visibility', 'hidden' );
				//
				// 	var body = angular.element( document.body );
				// 	body.append( content );
				//
				// 	$scope.calendarElement = content;
				// 	$scope.show = true;
				//
				// });
			}

			$scope.click = function () {
				if ( $scope.lock === true ) return;
				if ( !content ) {
					init();
					return;
				}
				$scope.show = !$scope.show;
			};

			$scope.$watch('show', val => {
				if ($scope.show) {
					if (restoreScroll) {
						restoreScroll();
					}
					restoreScroll = $mdUtil.disableScrollAround(
						null,
						document.querySelector('.main-content'),
						{ disableScrollMask: true }
					);
					backdrop = $mdUtil.createBackdrop($scope, "md-click-catcher md-backdrop--transparent");
					bodyEl.append(backdrop);
				}
				else {
					if (restoreScroll) {
						restoreScroll();
						restoreScroll = null;
					}
					if (backdrop) {
						backdrop.remove();
						backdrop = null;
					}
				}
			});

			$scope.previous = function() {
				if($scope.after)
					$scope.previousPeriod($scope.after);
				else
					$scope.previousPeriod($scope.exact);
			};

			$scope.next = function() {
				if($scope.before)
					$scope.nextPeriod($scope.before);
				else
					$scope.nextPeriod($scope.exact);
			}
		}
	};
}

export default calendar;
