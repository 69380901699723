import template from '../templates-pug/for-directive/feature-settings-editor.pug';

FeatureSettingsEditorController.$inject = ['$element', 'siteLangCode', 'langSets', 'lvMixpanel'];

function FeatureSettingsEditorController($element, siteLangCode, langSets, lvMixpanel) {

    this.valueChanged = valueChanged;
    this.$onInit = $onInit;
    this.$onChanges = $onChanges;

    this.trackShiftsHelp = function () {
        lvMixpanel.track('Help', {"Property": "Shifts"});
    };

    this.trackTimeClockHelp = function () {
        lvMixpanel.track('Help', {"Property": "Time clock"});
    };

    this.trackOpenTicketsHelp = function () {
        lvMixpanel.track('Help', {"Property": "Open tickets"});
    };

    this.trackKitchenPrintersHelp = function () {
        lvMixpanel.track('Help', {"Property": "Kitchen printers"});
    };

    this.trackCustomerDisplayHelp = function () {
        lvMixpanel.track('Help', {"Property": "Customer displays"});
    };

    this.trackDiningHelp = function () {
        lvMixpanel.track('Help', {"Property": "Dining options"});
    };

    this.trackStockNotificationsHelp = function () {
        lvMixpanel.track('Help', {"Property": "Low stock notifications"});
    };

    this.negativeInventoryHelp = function () {
        lvMixpanel.track('Help', {"Property": "Negative inventory alerts"});
    };

    this.weightEmbeddedBarcodesHelp = function () {
        lvMixpanel.track('Help', {"Property": "Weight embedded barcodes"});
    };

    let self = this;

    function valueChanged() {
        self.ngModel.$setViewValue({
            shiftsEnabled: self.shiftsEnabled,
            timeCardEnabled: self.timeCardEnabled,
            useOpenedReceipts: self.useOpenedReceipts,
            fiscalEnable: self.fiscalEnable,
            useKitchenPrinter: self.useKitchenPrinter,
            useCustomerDisplay: self.useCustomerDisplay,
            useDiningOptions: self.useDiningOptions,
            notifyCritical: self.notifyCritical,
            posInventoryAlertEnabled: self.posInventoryAlertEnabled,
            weightBarcode: self.weightBarcode
        });
    }

    function $onInit() {
        $element.addClass('feature-settings-editor');
        self.ngModel.$render = render;
    }

    function $onChanges(changes) {
        if(changes.options){
            if (self.options) {
                const siteUrlLang = siteLangCode[self.options.cabinetLang];
                self.shiftsHelpLink = langSets.shiftHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/shift-management-loyverse-pos?utm_source=Back%20Office&utm_medium=Shifts"
                    : "https://help.loyverse.com/help/shift-management-loyverse-pos?utm_source=Back%20Office&utm_medium=Shifts";

                self.timeClockHelpLink = langSets.timeClockLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/time-clock?utm_source=Back%20Office&utm_medium=Time%20clock"
                    : "https://help.loyverse.com/help/time-clock?utm_source=Back%20Office&utm_medium=Time%20clock";

                self.openTicketsHelpLink = langSets.openTicketsHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/open-tickets?utm_source=Back%20Office&utm_medium=Open%20tickets"
                    : "https://help.loyverse.com/help/open-tickets?utm_source=Back%20Office&utm_medium=Open%20tickets";

                self.kitchenPrintersHelpLink = langSets.kitchenPrintersHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/using-kitchen-printers?utm_source=Back%20Office&utm_medium=Kitchen%20printers"
                    : "https://help.loyverse.com/help/using-kitchen-printers?utm_source=Back%20Office&utm_medium=Kitchen%20printers";

                self.customerDisplaysHelpLink = langSets.customerDisplayHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-customer-display-works?utm_source=Back%20Office&utm_medium=Customer%20displays"
                    : "https://help.loyverse.com/help/how-customer-display-works?utm_source=Back%20Office&utm_medium=Customer%20displays";

                self.diningHelpLink = langSets.diningHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/dining-options?utm_source=Back%20Office&utm_medium=Dining%20options"
                    : "https://help.loyverse.com/help/dining-options?utm_source=Back%20Office&utm_medium=Dining%20options";

                self.stockNotificationsHelpLink = langSets.stockNotificationsHelpLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/low-stocks?utm_source=Back%20Office&utm_medium=Low%20stock"
                    : "https://help.loyverse.com/help/low-stocks?utm_source=Back%20Office&utm_medium=Low%20stock";

                self.negativeInventoryHelpLink = langSets.negativeInventoryHelpLinkLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/negative-stock-alerts?utm_source=Back%20Office&utm_medium=Stock%20alerts"
                    : "https://help.loyverse.com/help/negative-stock-alerts?utm_source=Back%20Office&utm_medium=Stock%20alerts";

                self.weightBarcodeHelpLink = langSets.weightBarcodeHelpLinkLangs.indexOf(siteUrlLang) !== -1
                    ? "https://help.loyverse.com/" + siteUrlLang + "/help/barcodes-with-embedded-weight?utm_source=Back%20Office&utm_medium=Barcodes%20weight"
                    : "https://help.loyverse.com/help/barcodes-with-embedded-weight?utm_source=Back%20Office&utm_medium=Barcodes%20weight";
            }
        }
    }

    function render() {
        let currentValue = self.ngModel.$viewValue;
        if (currentValue) {
            self.shiftsEnabled = currentValue.shiftsEnabled;
            self.timeCardEnabled = currentValue.timeCardEnabled;
            self.useOpenedReceipts = currentValue.useOpenedReceipts;
            self.fiscalEnable = currentValue.fiscalEnable;
            self.useKitchenPrinter = currentValue.useKitchenPrinter;
            self.useCustomerDisplay = currentValue.useCustomerDisplay;
            self.useDiningOptions = currentValue.useDiningOptions;
            self.notifyCritical = currentValue.notifyCritical;
            self.posInventoryAlertEnabled = currentValue.posInventoryAlertEnabled;
            self.weightBarcode = currentValue.weightBarcode;
        }

    }
}

const featureSettingsEditorComponent = {
    template: template,
    controller: FeatureSettingsEditorController,
    require: {
        ngModel: 'ngModel'
    },
    bindings: {
        options: '<lvOptions',
        profile: '<lvProfile'
    }
}

export default {
    type: 'component',
    name: 'lvFeatureSettingsEditor',
    value: featureSettingsEditorComponent
};