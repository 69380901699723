myDay.$inject = ['serviceEvents', '$timeout'];

function myDay(serviceEvents, $timeout) {
    return {
        restrict: 'A',
        scope: {
            beforeValue: '=',
            myDay: '='
        },
        //controller: 'calendarContrl',
        link: function ( scope, el, attr, contrl ) {
            // $timeout(function(){
            // var firstWeekDay = document.getElementsByClassName('days')[0];
            // firstWeekDayWidth = parseFloat(window.getComputedStyle(firstWeekDay,null).getPropertyValue("width"))/7;
            // el.css('width',firstWeekDayWidth);
            // el.css('text-overflow', 'none');
            // },1);
            el.on( 'mouseover', function ( e ) {
                if ( serviceEvents.mouseDown ) {
                    serviceEvents.hoverDay = scope.myDay;
                    if ( !serviceEvents.startDay ) {
                        serviceEvents.startDay = scope.myDay;
                    }
                    scope.$apply();
                }
            } );
        }
    }
}

export default myDay;