serviceDialog.$inject = ['serviceBackEvent', '$sce'];

function serviceDialog(serviceBackEvent, $sce) {
    var s = this;
    var count = 0;
    this.isShown = false;
    this.dialogs = [];
    function dialogRemove( index ) {
        for ( var i = 0; i < s.dialogs.length; i++ ) {
            if ( s.dialogs[i]._index == this._index ||  s.dialogs[i]._index == index ) {
                s.dialogs.splice( i, 1 );
                count--;
                break;
            }
        }
        if ( count <= 0 ) {
            s.isShown = false;
            s.actionShow && s.actionShow();
            serviceBackEvent.resum();

        }

    }

    function addCloseAction( button, action, obj ) {
        var index = obj._index;
        button.action = function (closeOnActionArg, data) {
            button.closeOnAction = (closeOnActionArg!==undefined) ? closeOnActionArg : button.closeOnAction;
            if(button.closeOnAction!==false){
                dialogRemove.call(obj, index );
            }
            action && action.call(obj, data);
        };
    }

    this.add = function ( obj) {

        if (s.dialogs.length > 0) {
            for (let i = s.dialogs.length - 1; i>= 0; i--){
                this.close(s.dialogs[i])
            }
        }

        serviceBackEvent.prevent();
        obj._index = count++;
        s.isShown = true;
        s.actionShow && s.actionShow();
        for ( var i = 0; i < obj.buttons.length; i++ ) {
            addCloseAction( obj.buttons[i], obj.buttons[i].action, obj );
        }
        obj.close = function () {
            s.close( obj );
        };

        s.dialogs.push( obj );
        return obj;
    };
    this.close = function ( obj ) {
        dialogRemove.call(obj, obj._index );
    };
    this.remove = this.close;
}

export default serviceDialog;