/**
 * Created by mars on 2/8/16.
 * Edited by olehkss 13.07.2016
 * Edited by kseniya.yarosh on 13.12.2016
 * @ngdoc factory
 * @name app.factory: factoryEmployeeReport
 * @description
 * Factory retrieves from server report about employee's sales
 * @require promiseOperation
 */

import angular from 'angular';

factoryEmployeeReport.$inject = ['promiseOperation', 'serviceReportsError'];

function factoryEmployeeReport(promiseOperation, serviceReportsError) {

	return {
		getEmployeeReport: getEmployeeReport
	};

	/**
	 * @ngdoc function
	 * @param  {Object} params server call parameters
	 * @returns {Promise}
	 */
	function getEmployeeReport(params) {
		return promiseOperation.getPromiseHTTP(
			'POST',
			'/data/ownercab/getmerchantsreport',
			params,
			successData,
			serviceReportsError.reportsError
		);
	}

	/**
	 * @ngdoc function
	 * @param {Object} response server response
	 * @description
	 * Rearrange data from server
	 * @returns {{pages: *, report: *}}
	 */
	function successData(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === 'ok') {
				let employeesData = {
					periodAllowed: response.data.periodAllowed,
					outlets: response.data.fillSelectData.outlets,
					startDate: response.data.startDate,
					endDate: response.data.endDate,
					startDateString: response.data.startDateString,
					endDateString: response.data.endDateString
				}
				if (response.data.periodAllowed) {
					employeesData.pages = response.data.pages;
					employeesData.report = response.data.report;
				}

				return employeesData;
			}
		}
	}
}

export default {
	type: 'factory',
	name: 'factoryEmployeeReport',
	value: factoryEmployeeReport
};
