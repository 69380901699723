import angular from 'angular'

createDirective.$inject = [];

function createDirective() {

    const model2ObserverActionMap = {
        '$setValidity': 'onValidChanged',
        '$setPristine': 'onDirtyChanged',
        '$setDirty': 'onDirtyChanged',
        '$setUntouched': 'onUntouchChanged',
        '$setTouched': 'onUntouchChanged'
    };

    return {
        restrict: 'A',
        require: 'ngModel',
        priority: 0,
        compile: compileDirective
    }

    function compileDirective(tElement) {
        tElement.addClass('ng-model');
        return linkDirective;
    }

    function linkDirective(scope, element, attr, ngModelCtrl) {
        initObserveModelStateMechanism();
        handleValidationBehaviour();

        function initObserveModelStateMechanism() {
            let observers;
            /*
             * @name addObserver
             * @desc add model state change observer
             */
            ngModelCtrl.addObserver = addObserver;

            // Map model to observer actions.
            for (let modelActionName in model2ObserverActionMap) {
                let observerActionName = model2ObserverActionMap[modelActionName];
                mapModel2ObserverAction(modelActionName, observerActionName);
            }

            function mapModel2ObserverAction(modelActionName, observerActionName) {
                // Hold original action.
                let originalAction = ngModelCtrl[modelActionName];

                // Override original action.
                ngModelCtrl[modelActionName] = function() {
                    // Call original method.
                    originalAction.apply(this, arguments);

                    if (observers) {
                        // Call mapped observer method.
                        angular.forEach(observers, observer => {
                            if (observer[observerActionName]) {
                                observer[observerActionName]();
                            }
                        });
                    }
                };
            }

            function addObserver(observer) {
                // Create observers array if necessary.
                observers = observers || [];
                observers.push(observer);

                return function removeObserver() {
                    //Remove container from list.
                    let index = observers.indexOf(observer);
                    if (index !== -1) {
                        observers.splice(index, 1);
                    }
                };
            }
        }

        function handleValidationBehaviour() {
            let originalSetValidity;
            let validationErrorKeys;

            /*
             * @name suspendValidation
             * @desc Skip validation behaviour and remove its error.
             */
            ngModelCtrl.suspendValidation = suspendValidation;
            /*
             * @name restoreValidation
             * @desc Restore validation behaviour and remove its error.
             */
            ngModelCtrl.restoreValidation = restoreValidation;

            function suspendValidation() {
                // Do nothing if validation is olready suspended.
                if (originalSetValidity) {
                    return;
                }

                //Hold original method.
                originalSetValidity = ngModelCtrl.$setValidity;
                // Hold validation error
                validationErrorKeys = [];
                for (let errorKey in ngModelCtrl.$error) {
                    // Hold error key.
                    validationErrorKeys.push(errorKey);
                    // Mark validation key as valid.
                    ngModelCtrl.$setValidity(errorKey, true);
                }

                // Mock original setValidity implementation.
                ngModelCtrl.$setValidity = function(key) {
                    originalSetValidity.apply(ngModelCtrl, [key, true]);
                };
            }

            function restoreValidation() {
                // Do nothing if validation is not suspended.
                if (!originalSetValidity) {
                    return;
                }

                // Restore original method.
                ngModelCtrl.$setValidity = originalSetValidity;

                // Clear validity function.
                originalSetValidity = null;

                // Restore error keys.
                angular.forEach(validationErrorKeys, function(errorKey) {
                    // Mark validation key as invalid.
                    ngModelCtrl.$setValidity(errorKey, false);
                });
                ngModelCtrl.$validate();
            }
        }
    }
}

export default {
    type: 'directive',
    name: 'ngModel',
    value: createDirective
};