/**
 * Methods for reCAPTCHA
 * Created by Oleksii Shestakov on 8.06.19
 */

serviceReCaptcha.$inject = ['$state', '$stateParams', 'langCode', 'vcRecaptchaService'];

function serviceReCaptcha($state, $stateParams, langCode, vcRecaptchaService) {

    function getLangForReCaptcha(cabinetLang) {
        var langCodes = angular.copy(langCode);
        langCodes.ind = "id";
        langCodes.zho = "zh-TW";
        langCodes.chn = "zh-CN";

        return langCodes[cabinetLang] ? langCodes[cabinetLang] : "en";
    }

    function setResponse(response, recaptchaInfo) {
        recaptchaInfo.response = response;
    }

    function setWidgetId (widgetId, recaptchaInfo) {
        recaptchaInfo.widgetId = widgetId;
    }

    function cbExpiration(recaptchaInfo) {
        vcRecaptchaService.reload(recaptchaInfo.widgetId);
        if(recaptchaInfo.hasOwnProperty('response')) {
            recaptchaInfo.response = null;
        }
    }

    function executeReCaptcha(widgetId) {
        vcRecaptchaService.execute(widgetId);
    }

    this.getLangForReCaptcha = getLangForReCaptcha;
    this.setResponse = setResponse;
    this.setWidgetId = setWidgetId;
    this.cbExpiration = cbExpiration;
    this.executeReCaptcha = executeReCaptcha;
}

export default serviceReCaptcha;