factoryInvoicing.$inject = [];

function factoryInvoicing() {
  return {
    hasNumericInvoicingUnit: (organisationId) => organisationId?.[10] === "1",
  };
}

export default {
  type: "factory",
  name: "factoryInvoicing",
  value: factoryInvoicing,
};
