/**
 * Created by A.Sirosh on 23.09.2015.
 * @memberof disableGroupItem
 * @ngdoc directive
 * @name disableGroupItem
 * @requires resolveTimezone {service} - resolve timezone problems
 */

import angular from 'angular';

disableGroupItem.$inject = ['resolveTimezone'];
function disableGroupItem(resolveTimezone) {
	return{
		restrict: 'A',
		/**
		 * @param scope
		 * @param element
		 * @param attrs
		 */
		link: function(scope, element, attrs){
			var id = attrs.id;
			var timeZoneResolve = resolveTimezone.timeZoneResolveMinus;
			// follow the changes in the calendarPeriod parameters
			scope.$watchCollection('calendarPeriod', function (newVal, oldVal) {

				var from = new Date(newVal.from);
				var to = new Date(newVal.to);

				var resolveFromDate = new Date(from.getFullYear(), from.getMonth(), from.getDate());
				var fromDate = timeZoneResolve(resolveFromDate);

				var resolveToDate = new Date(to.getFullYear(), to.getMonth(), to.getDate() + 1, 0, -1);
				var toDate = timeZoneResolve(resolveToDate);

				var resolveCompareFromToDay = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1, 0, -1);
				var compareFromToDay = timeZoneResolve(resolveCompareFromToDay);

				var resolveDayFrom = new Date(from.getFullYear(), from.getMonth(), from.getDate());
				var dayFrom = timeZoneResolve(resolveDayFrom);

				var resolveCompareFromToThisWeek = new Date(from.getFullYear(), from.getMonth(), from.getDate() - dayFrom + 1);
				var compareFromToThisWeek = timeZoneResolve(resolveCompareFromToThisWeek);

				var resolveCompareFromToWeek = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 5, 0, -1);
				var compareFromToWeek = timeZoneResolve(resolveCompareFromToWeek);

				var resolveCompareFromToMonth = new Date(to.getFullYear(), to.getMonth() - 1, to.getDate());
				var compareFromToMonth = timeZoneResolve(resolveCompareFromToMonth);

				var resolveCompareFromToQuarter = new Date(to.getFullYear(), to.getMonth() - 3, to.getDate());
				var compareFromToQuarter = timeZoneResolve(resolveCompareFromToQuarter);

				var resolveCompareFromToYear = new Date(to.getFullYear() - 1, to.getMonth(), to.getDate());
				var compareFromToYear = timeZoneResolve(resolveCompareFromToYear);


				if (toDate === compareFromToDay) {
						if (id !== "hour") {
							angular.element(element).attr('disabled', 'disabled');
						}
					} else if (fromDate <= compareFromToYear) {
						if (id === "hour") {
							angular.element(element).attr('disabled', 'disabled');
						}
					} else if (fromDate <= compareFromToQuarter) {
						if (id=== "hour" || id === "year") {
							angular.element(element).attr('disabled', 'disabled');
						}
					} else if (fromDate <= compareFromToMonth) {
						if ( id === "hour" || id === "quarter" || id === "year") {
							angular.element(element).attr('disabled', 'disabled');
						}
					} else if (fromDate <= compareFromToWeek) {
						// if (id === "month" ||  id === "quarter" || id === "year") {
						if (id === "hour" || id === "month" ||  id === "quarter" || id === "year") {
							angular.element(element).attr('disabled', 'disabled');
						}
					}  else if(fromDate <= compareFromToThisWeek){
						// if (id !== "day" || id !== "hour") {
						if (id !== "day") {
							angular.element(element).attr('disabled', 'disabled');
						}
					} else{
						// if (id === "month" || id === "quarter" || id === "year") {
						if (id === "hour" || id === "month" || id === "quarter" || id === "year") {
							angular.element(element).attr('disabled', 'disabled');
						}
					}

				});
		}
	};
}

export default {
	type: 'directive',
	name: 'disableGroupItem',
	value: disableGroupItem
};