import angular from 'angular';

calendarContrl.$inject = ['$scope', '$rootScope', '$element', '$timeout',
	'serviceDaysWeek', 'constantCalendar', 'formatMonth', 'serviceEvents', '$filter',
	'factoryPosition', 'dateCustomMethods'];
// TODO: Oleksii: dateCustomMethods is in app module
// TODO: Oleksii: webpack convert templates
function calendarContrl($scope, $rootScope, $element, $timeout,
						serviceDaysWeek, constantCalendar, formatMonth, serviceEvents, $filter,
						factoryPosition, dateCustomMethods ) {

	var link = $scope.link === 'true';


	var _viewMonths = 1;


	// переменная для определения того что мы сейчас выбираем: окончание периода или новый период
	var numberOfClick = 2;

	if ( $scope.exact ) {
		serviceDaysWeek.exact = true
	}
	$scope.show = false;
	$scope.constantCalendar = constantCalendar;
	$scope.months = [];
	$scope.beforeLabelValue = $scope.before || new Date();
	$scope.afterLabelValue = $scope.after || new Date();
	$scope.exactLabelValue = $scope.exact || new Date();
	var currentViewDate = $scope.exactLabelValue ? new Date($scope.exactLabelValue.getTime())
						: new Date($scope.beforeLabelValue.getTime());


	$scope.includeMonths = 'module/lv-calendar/templates/container-months.html';

	function render( date ) {
		function getdate( i ) {
			var k = (-1 * Math.ceil( _viewMonths / 2 )) + i + 1;
			return new Date( date.getFullYear(), date.getMonth() + k );
		}

		for ( var i = 0; i < _viewMonths; i++ ) {
			var d = getdate( i );
			var btnBack = false, btnForward = false;
			var currentMounth = new Date( $scope.maxDate.getFullYear(), $scope.maxDate.getMonth());
			if ( i === 0 ) {
				btnBack = true
			}
			if ( i === _viewMonths - 1 && d.getTime() !== currentMounth.getTime()) {
				btnForward = true
			}

			var month = {
				formatMonth: formatMonth.formatMonth( d, {
					after: $scope.afterLabelValue,
					before: $scope.beforeLabelValue,
					exact: $scope.exactLabelValue,
					maxDate: $scope.maxDate,
					minDate: $scope.minDate,
					startWeek: $rootScope.startWeek,
					endWeek: $rootScope.endWeek
				} ),
				value: d.getTime(),
				btnBack: btnBack,
				btnForward: btnForward
			};

			if ( $scope.months[i] ) {
				var oldMonth = $scope.months[i];

				for ( var opt in oldMonth ) {
					switch ( opt ) {
						case 'formatMonth':
							comparisonWeeeck( oldMonth[opt], month[opt] );
							break;
						default :
							if ( oldMonth[opt] !== month[opt] ) {
								oldMonth[opt] = month[opt];
							}
					}
				}
			} else {
				$scope.months[i] = month;
			}
		}
	}


	function comparisonWeeeck( oldWeeks, newWeeks ) {
		if (oldWeeks && newWeeks) {
			for ( var a = 0; a < newWeeks.length; a++ ) {
				oldWeeks[a] = oldWeeks[a] ? oldWeeks[a] : [];
				for ( var b = 0; b < newWeeks[a].length + 2; b++ ) {
					if ( newWeeks[a][b] !== oldWeeks[a][b] ) {
						oldWeeks[a][b] = newWeeks[a][b]
					}
				}
			}
			var length = newWeeks.length;
			oldWeeks.splice( length, 2 );
		}
	}

	render( currentViewDate );
	//console.log(currentViewDate)

	$scope.stepBack = function () {
		var date = new Date( currentViewDate.getFullYear(), currentViewDate.getMonth() - 1 );
		currentViewDate = date;
		render( date );
	};
	$scope.stepForward = function () {
		var date = new Date( currentViewDate.getFullYear(), currentViewDate.getMonth() + 1 );
		currentViewDate = date;
		render( date );
	};

	$scope.serviceEvents = serviceEvents;

	$scope.$watch( 'serviceEvents.hoverDay', function ( day ) {
		if ( !day || day.blocked ) return;
		var maxDate = new Date();
		var dateMin = new Date("Jan 1 2000 00:00:00");
		if ( $scope.beforeLabelValue && $scope.afterLabelValue && $scope.serviceEvents.startDay ) {
			if ( $scope.serviceEvents.startDay.value < day.value ) {
				$scope.afterLabelValue = dateMin.getTime() < $scope.serviceEvents.startDay.value ? new Date( $scope.serviceEvents.startDay.value ) : dateMin;
				$scope.beforeLabelValue = maxDate.getTime() > day.value ? new Date( day.value ) : maxDate;
			} else if ( day.value < $scope.serviceEvents.startDay.value ) {
				$scope.beforeLabelValue = maxDate.getTime() > $scope.serviceEvents.startDay.value ? new Date( $scope.serviceEvents.startDay.value ) : maxDate;
				$scope.afterLabelValue = dateMin.getTime() < day.value ? new Date( day.value ) : dateMin;
			}
		}

	} );
	$scope.$watch( 'serviceEvents.startDay', function ( day) {
		if ( day ) {
			$scope.selectEvent( day )
		}
	} );
	$scope.input = {};
	$scope.selectEvent = function ( day ) {
		if ( !day || day.blocked ) {
			return
		}
		if ( $scope.exactLabelValue !== undefined ) {
			$scope.exactLabelValue = new Date( day.value );
			// console.log($scope.exactLabelValue);
			if ( link ) {
				$scope.exact = $scope.exactLabelValue;
			}
			return
		}
		if(numberOfClick && numberOfClick === 1 || !numberOfClick) {
			if ($scope.beforeLabelValue && $scope.beforeLabelValue.getTime() < day.value) {
				$scope.beforeLabelValue = new Date(day.value);
				dateCustomMethods.saveAfterDateString($scope.afterLabelValue.setHours(0,0,0,0));
				$scope.input.after =  $filter( 'formatDateByBrowser' )( dateCustomMethods.getAfterDateString(), 'calendarInput');
				// console.log($scope.beforeLabelValue);
				link && ( $scope.before = $scope.beforeLabelValue );
			} else if ($scope.afterLabelValue && day.value < $scope.afterLabelValue.getTime()) {
				$scope.afterLabelValue = new Date(day.value);
				dateCustomMethods.saveBeforeDateString($scope.beforeLabelValue.setHours(0,0,0,0));
				$scope.input.before = $filter('formatDateByBrowser')(dateCustomMethods.getBeforeDateString(), 'calendarInput');

				link && ($scope.after = $scope.afterLabelValue );
			} else {
				$scope.afterLabelValue = new Date(day.value);

				$scope.beforeLabelValue = new Date(day.value);
				if (link) {
					$scope.before = $scope.beforeLabelValue;
					$scope.after = $scope.afterLabelValue;
				}
			}
		} else if(numberOfClick && numberOfClick === 2) {
			$scope.afterLabelValue = new Date(day.value);
			$scope.beforeLabelValue = new Date(day.value);
		}
		$timeout(function() {
			$scope.$broadcast('validateDate', {});
		}, 0);
		numberOfClick = numberOfClick === 2 ? 1 : 2;
	};

	$scope.apply = function () {
		$scope.before = $scope.beforeLabelValue;
		$scope.after = $scope.afterLabelValue;
		$scope.exact = $scope.exactLabelValue;
		var maxDateTime = new Date();
		var maxDateEnd = new Date(maxDateTime.getFullYear(), maxDateTime.getMonth(), maxDateTime.getDate() + 1, 0, 0, -1);
		var dateMin = new Date("Jan 1 2000 00:00:00");
		$scope.click();
		if($scope.applyAction && (($scope.before >= $scope.after && $scope.before <= maxDateEnd && $scope.after >= dateMin)
			|| (!$scope.before && !$scope.after && $scope.exact))){
			$scope.applyAction($scope.after, $scope.before, $scope.exact)
		}
	};

	$scope.$watch( 'before', function ( val ) {
		// console.log(val);
		// console.log($scope.beforeLabelValue);
		if ( val !== $scope.beforeLabelValue) {
			$scope.beforeLabelValue = val;
		}
		render( currentViewDate )
	} );


	$scope.$watch( 'after', function ( val ) {
		// console.log(val);
		// console.log($scope.afterLabelValue);
		if ( val !== $scope.afterLabelValue ) {
			$scope.afterLabelValue = val;
		}
		render( currentViewDate )
	} );


	$scope.$watch( 'exact', function ( val ) {
		if ( val !== $scope.exactLabelValue) {
			$scope.exactLabelValue = val;
		}
		render( currentViewDate )
	} );


	$scope.$watch( 'exactLabelValue', function ( val ) {
		if ( val !== $scope.exactLabelValue ) {
			$scope.exactLabelValue = val;
		}
		if ( val && angular.isDate( val ) ) {
			$scope.input.exact = $filter( 'formatDateByBrowser' )( val, 'calendarInput' );
		}
		render( currentViewDate )
	} );

	$scope.$watch( 'afterLabelValue', function ( val, test) {
		const dateMin = new Date("Jan 1 2000 00:00:00");
		if (val < dateMin) {
			val = dateMin;
		}
		dateCustomMethods.saveAfterDateString(val.setHours(0, 0, 0, 0));

		$scope.input.after = $filter('formatDateByBrowser')(dateCustomMethods.getAfterDateString(), 'calendarInput');
	} );

	$scope.$watch( 'beforeLabelValue', function ( val ) {
		const maxDate = new Date();
		if (val > maxDate) {
			val = maxDate;
		}
		dateCustomMethods.saveBeforeDateString(val.setHours(0, 0, 0, 0));

		$scope.input.before = $filter('formatDateByBrowser')(dateCustomMethods.getBeforeDateString(), 'calendarInput');
	} );

	$scope.valid = {};
	$scope.setDateValue = function ( param ) {


		switch ( param ) {
			case 'exact':

				$scope.exactLabelValue = dateCustomMethods.fromFormattedStringToDateObject($scope.input.exact);
				break;
			case 'after':

				$scope.afterLabelValue = dateCustomMethods.fromFormattedStringToDateObject($scope.input.after);

				break;
			case 'before':

				$scope.beforeLabelValue = dateCustomMethods.fromFormattedStringToDateObject($scope.input.before);

		}

	}




	$scope.$watchCollection( '[beforeLabelValue,afterLabelValue,maxDate, minDate]',
		function () {
			render( currentViewDate )
		} );

	function listen( e ) {
		var val = false;
		angular.forEach( $scope.calendarElement.find( '*' ), function ( el ) {
			if ( el === e.target ) {
				val = true;
				return;
			}
		} );

		if ( !val ) {
			angular.forEach( $element.find( '*' ), function ( el ) {
				if ( el === e.target ) {
					val = true;
					return;
				}
			} )
		}
		if ( !val ) {
			($scope.show = false);
			$scope.$apply();
		}
	}

	$scope.cancel = function () {
		$scope.show = false;
	};

	function cleanup() {
		window.document.removeEventListener( 'click', listen );
		scrollOn();
	}

	function scrollMove(e){
		e.preventDefault();
	}

	function scrollOff(){
		document.addEventListener('mousewheel', scrollMove);
		document.addEventListener('DOMMouseScroll', scrollMove)
	}

	function scrollOn(){
		document.removeEventListener('mousewheel', scrollMove );
		document.removeEventListener('DOMMouseScroll', scrollMove )
	}

	$scope.$watch( 'show', function ( val ) {
		if ( val ) {
			scrollOff();
			window.document.addEventListener( 'click', listen );

		} else {

			$scope.beforeLabelValue = $scope.before;
			$scope.afterLabelValue = $scope.after;
			$scope.exactLabelValue = $scope.exact;

			numberOfClick = 2;

			if($scope.afterLabelValue){

				$scope.input.after = $filter( 'formatDateByBrowser' )( $scope.afterLabelValue, 'calendarInput' );
			}
			if($scope.beforeLabelValue){

				$scope.input.before =  $filter( 'formatDateByBrowser' )( $scope.beforeLabelValue, 'calendarInput' );
			}
			if($scope.exactLabelValue){

				$scope.input.exact = $filter( 'formatDateByBrowser' )( $scope.exactLabelValue, 'calendarInput' );
			}
			$scope.$broadcast('validateDate', {});
			scrollOn();
			currentViewDate = $scope.exactLabelValue ? new Date($scope.exactLabelValue.getTime())
										: new Date($scope.beforeLabelValue.getTime());
			render( currentViewDate );
			window.document.removeEventListener( 'click', listen );

		}
	} );
	$scope.$on( '$destroy', function () {
		$scope.show = false;
		$scope.calendarElement && $scope.calendarElement[0].parentNode.removeChild( $scope.calendarElement[0] );
		cleanup();
	} );

}

export default calendarContrl;