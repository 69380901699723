import angular from 'angular';

createClass.$inject = ['TableViewColumnBase'];
function createClass(TableViewColumnBase) {
    return class TableViewColumnPropertyView extends TableViewColumnBase {
        constructor(title, valueGetter, options) {
            super(title, options);
            this.valueGetter = valueGetter;
        }

        getElement() {

            let result;

            const baseElement = super.getElement();
            let bindValue;
            if (angular.isString(this.valueGetter)) {
                bindValue = 'ctrl.item.' + this.valueGetter;
            }
            if (angular.isFunction(this.valueGetter)) {
                bindValue = 'ctrl.column.valueGetter(ctrl.item)';
            }

            if (this.options.viewFilters) {
                for(let key in this.options.viewFilters) {
                    bindValue += ' | ' + key;
                    if (this.options.viewFilters[key]) {
                        bindValue += ` : ctrl.column.options.viewFilters[\'${key}\']`;
                    }
                }
            }
            baseElement.attr('ng-bind', bindValue);

            const icon = this.options.icon;
            if (icon) {
                const containerElement = angular.element('<div>');
                containerElement.addClass('l-flex-row l-flex-align-center');

                let iconElement = angular.element('<md-icon>');
                if (icon.class) {
                    iconElement.addClass(icon.class);
                    iconElement.attr('md-svg-src', icon.src);
                }
                if (icon.attrs){
                    for (let key in icon.attrs) {
                        iconElement.attr(key, icon.attrs[key]);
                    }
                }
                if (icon.isVisible) {
                    iconElement.attr('ng-if', 'ctrl.column.options.icon.isVisible(ctrl.item)')
                }

                if (icon.tooltip) {
                    const tooltip = angular.element('<md-tooltip>');
                    tooltip.text(icon.tooltip.text);
                    if (icon.tooltip.class) {
                        tooltip.addClass(icon.tooltip.class);
                    }
                    iconElement.append(tooltip);
                }

                containerElement.append(baseElement);
                const iconContainer = angular.element('<div>');
                iconContainer.append(iconElement)
                containerElement.append(iconContainer);
                result = containerElement;
            } else {
                result = baseElement;
            }

            return result;
        }

        getBaseElement() {
            return angular.element('<div>');
        }
    }
}

export default {
    type: 'factory',
    name: 'TableViewColumnPropertyView',
    value: createClass
};
