import angular from 'angular';
import ngTranslate from 'angular-translate';
import serviceBackEvent from './services/service-back-event';
import serviceDialog from './services/service-dialog';
import dialogsContainer from './directives/directive-dialogs-container';
import dialogScope from './directives/directive-dialog-scope';
import content from './directives/directive-content';

export default angular
    .module('lv.dialog', [ngTranslate])
    .service('serviceBackEvent', serviceBackEvent)
    .service('serviceDialog', serviceDialog)
    .directive('dialogsContainer', dialogsContainer)
    .directive('dialogScope', dialogScope)
    .directive('content', content)
    .name;