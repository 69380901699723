lvNumberNormalizer.$inject = ['currencyLocalizationUtil'];

function lvNumberNormalizer(currencyLocalizationUtil) {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, ngModelCtrl) {
        if (ngModelCtrl) {
            ngModelCtrl.$parsers.push((source) => currencyLocalizationUtil.replaceHiraganaToArabic(source));
        }
    }
}

export default {
    type: 'directive',
    name: 'lvNumberNormalizer',
    value: lvNumberNormalizer
};