import angular from 'angular';

createWHook.$inject = [
    '$state',
    'factoryWebHooks',
    'serviceToast',
    'serviceDialog',
    '$stateParams',
    '$hookInfo',
    '$filter'
];

function createWHook(
    $state,
    factoryWebHooks,
    serviceToast,
    serviceDialog,
    $stateParams,
    $hookInfo,
    $filter
) {
    var vm = this;
    var translate = $filter('translate');

    vm.wbEvents = ['INVENTORY_LEVELS_UPDATE', 'ITEMS_UPDATE', 'CUSTOMERS_UPDATE', 'RECEIPTS_UPDATE', 'SHIFTS_CREATE'];

    if($state.current.name === 'createwhook') {
        vm.selectedEvent = 'INVENTORY_LEVELS_UPDATE';
        vm.url = '';
        vm.enabled = true;
    } else {
        vm.webHook = angular.copy($hookInfo);
        vm.selectedEvent = vm.webHook.event;
        vm.url = vm.webHook.url;
        vm.enabled = vm.webHook.enabled;
    }

    vm.onCancel = function () {
        if($state.current.name === 'createwhook')
            $state.go('webhookslist');
        else
            $state.go('whookdetails', { id: $stateParams.id});
    };

    vm.onSave = function () {
        vm.webHook = {
            event: vm.selectedEvent,
            url: vm.url,
            enabled: vm.enabled
        };

        if($state.current.name === 'createwhook') {
            factoryWebHooks.createWebHook(vm.webHook).then(function (res) {
                if (res.result === "ok") {
                    serviceToast.show('WEBHOOKS.CREATED', 'success');
                    $state.go('whookdetails', { id: res.newId})
                } else {
                    if(res.result === "hook_exist") {
                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                            content: translate('WEBHOOKS.WEBHOOK_EXIST'),
                            buttons: [
                                {
                                    class: 'primary',
                                    text: 'ON_OK'
                                }
                            ]
                        });
                    }
                }
            })
        } else {
            if($hookInfo.event === vm.webHook.event && $hookInfo.url === vm.webHook.url && $hookInfo.enabled === vm.webHook.enabled)
                $state.go('whookdetails', { id: $stateParams.id});
            else {
                vm.webHook.id = $stateParams.id;
                factoryWebHooks.editWebHook(vm.webHook).then(function (res) {
                    if (res.result === "ok") {
                        serviceToast.show('WEBHOOKS.EDITED_SUCCESS', 'success');
                        $state.go('whookdetails', {id: $stateParams.id})
                    } else {
                        if (res.result === "hook_exist") {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                content: translate('WEBHOOKS.WEBHOOK_EXIST'),
                                buttons: [
                                    {
                                        class: 'primary',
                                        text: 'ON_OK'
                                    }
                                ]
                            });
                        }
                    }
                })
            }
        }

    };

}

export default {
    type: 'controller',
    name: 'createWHook',
    value: createWHook
};