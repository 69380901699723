/**
 * Константа хранит обозначения подписок для биллинга в необходимой последовательности
 * Created by Shestakov on 11.01.21
 */

const constBillingSubscriptions = [
    "SALESHISTORY",
    "EMPLOYEE",
    "EMPSTORE",
    "INVENTORY",
    "INTEGRATION"
];

export default {
    type: 'constant',
    name: 'constBillingSubscriptions',
    value: constBillingSubscriptions
};
