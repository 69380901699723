/**
 * Created by mars on 4/5/16.
 */

// TODO: Oleksii: not used

importFiles.$inject = [
    'FileUploader',
    '$state',
    '$stateParams',
    '$filter',
    'serviceDialog',
    'serviceToast',
    '$translate',
    '$timeout'
];

function importFiles(FileUploader,
                     $state,
                     $stateParams,
                     $filter,
                     serviceDialog,
                     serviceToast,
                     $translate,
                     $timeout) {


    var translate = $filter( 'translate' );

    // var self = this;

    return {
        includeTemplate: includeTemplate,
        includeHeadTemplate: includeHeadTemplate,
        primaryAction: primaryAction,
        cancelAction: cancelAction,
        uploader: uploader
    };

    function includeTemplate () {
        return {
            onStart: 'dashboard-page/templates/goods/include/download-start.html',
            onAttach: 'dashboard-page/templates/goods/include/on-attach.html',
            onError: 'dashboard-page/templates/goods/include/download-error.html',
            formatError: 'dashboard-page/templates/goods/include/format-error.html',
            onShortEntry: 'dashboard-page/templates/goods/include/short-entry.html',
            limit10000: 'dashboard-page/templates/goods/include/limit-10000.html',
            onMaxFileSize: 'dashboard-page/templates/goods/include/max-file-size.html',
            onLoading: 'dashboard-page/templates/goods/include/preloader.html',
            onParserSuccess: 'dashboard-page/templates/goods/include/parser-success.html',
            onParserError: 'dashboard-page/templates/goods/include/parser-error.html',
            onWrongHeader: 'dashboard-page/templates/goods/include/download-wrong-header.html'
            // wrongTaxesList: 'dashboard-page/templates/goods/include/wrong-tax-list.html',
            // badEncoding: 'dashboard-page/templates/goods/include/bad-encoding.html'
        }
    }

    function includeHeadTemplate () {
        return {
            onStart: 'dashboard-page/templates/goods/include/head-start.html',
            onSuccess: 'dashboard-page/templates/goods/include/head-success.html',
            onParserError: 'dashboard-page/templates/goods/include/head-parser-error.html'
        }
    }

    function primaryAction (data) {
        return {
            onStart: function (data) {
                self.response = null;
                includeTemplate.onStart();
                includeHeadTemplate.onStart();
                self.primaryButtonText = 'DOWNLOAD';
                primaryAction.onStart();
                cancelAction.onStart();
                self.primaryButtonDisabled = true;
                self.templateWidtn = null;
            },
            onAttach: function () {
                if ( !checkFileNameValid() ) {
                    uploader.removeFromQueue( 0 );
                    includeTemplate.formatError();
                    self.primaryButtonText = 'REPEAT_LOADING';
                    primaryAction.onStart();
                    self.primaryButtonDisabled = false;
                } else if ( !checkFileSize() ) {
                    uploader.removeFromQueue( 0 );
                    self.templateWidtn = null;
                    includeTemplate.onMaxFileSize();
                    self.primaryButtonText = 'REPEAT_LOADING';
                    primaryAction.onStart();
                    self.primaryButtonDisabled = false;
                } else {
                    includeTemplate.onLoading();
                    uploader.uploadAll();
                    self.primaryButtonDisabled = true;
                }

            }
        }
    }

    function cancelAction (data) {
        return {
            onStart: function (data) {
                console.log('I am here' + data);
                $state.go( $state.parent, data);
            },
            onParserError: function (data) {
                serviceDialog.add( {
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: translate( 'WARNING' ) + "!",
                    content: translate( 'DETECT_CHANGES' ),
                    buttons: [
                        {
                            text: 'ON_CANCEL'
                        },
                        {
                            class: 'primary',
                            text: 'ON_OK',
                            action: function () {
                                $state.go( $state.parent, data );
                            }
                        }
                    ]
                } );
            }
        }
    }


    cancelAction.onStart('my friend');

    function uploader (data) {
        var uploader = new FileUploader( data );
        // CALLBACKS
        uploader.onWhenAddingFileFailed = function ( item /*{File|FileLikeObject}*/, filter, options ) {
            //console.info( 'onWhenAddingFileFailed', item, filter, options );
            console.log( 'uploader in factory');
        };
        uploader.onAfterAddingFile = function ( fileItem ) {
            //console.info( 'onAfterAddingFile', fileItem );
            var addedFile = fileItem._file;
            self.includeTemplate = includeTemplate.onAttach;
            self.primaryButtonDisabled = false;
            self.primaryAction = primaryAction.onAttach;
            self.onAttachFileName = addedFile.name;
            self.fileSize = addedFile.size;
            self.outletId = outletId;


            /*;

             if ( !/\.csv$/.test( addedFile.name ) ) {
             uploader.removeFromQueue( 0 );
             self.includeTemplate = includeTemplate.formatError;
             self.primaryButtonText = 'REPEAT_LOADING';
             self.primaryAction = primaryAction.onStart;
             self.primaryButtonDisabled = false;
             } else if ( 5 * 1000 * 1000 < addedFile.size ) {
             maxFileSize();
             } else {
             self.includeTemplate = includeTemplate.onAttach;
             self.primaryAction = primaryAction.onAttach;
             self.onAttachFileName = addedFile.name;
             self.primaryAction = primaryAction.onAttach
             }*/
        };
        uploader.onAfterAddingAll = function ( addedFileItems ) {
            //console.info( 'onAfterAddingAll', addedFileItems );
            if ( addedFileItems.length ) {
                self.primaryButtonDisabled = false;
                self.primaryButtonText = 'DOWNLOAD'
            }
            //var addedFile = addedFileItems[0]._file;
            /*//ограничение 5 мб
             if(/\.csv$/.test(addedFile.name)){
             self.includeTemplate = includeTemplate.onAttach;
             self.primaryAction = primaryAction.onAttach;
             self.onAttachFileName = addedFile.name;
             }else{
             uploader.cancelAll();
             self.includeTemplate = includeTemplate.formatError;
             self.primaryButtonText = 'REPEAT_LOADING';
             self.primaryAction = primaryAction.onStart;
             }*/

        };
        uploader.onBeforeUploadItem = function ( item ) {
            uploader.formData = [{outletId: outletId}];
            Array.prototype.push.apply(item.formData, uploader.formData);

            // console.info('onBeforeUploadItem', item );
        };
        uploader.onProgressItem = function ( fileItem, progress ) {
            //console.info( 'onProgressItem', fileItem, progress );
        };
        uploader.onProgressAll = function ( progress ) {
            //console.info( 'onProgressAll', progress );
        };
        uploader.onSuccessItem = function ( fileItem, response, status, headers ) {

            var res = null;
            try {
                res = JSON.parse( response );
            } catch ( err ) {
                console.error( err );
            }
            if ( !res ) {
                return;
            }


            //todo убрать
            //res.error = 'limit10000';

            if ( res.result == 'error' ) {
                if ( res.message == 'file_bad_encoding' ) {
                    badEncoding();
                }
            } else {
                switch ( res.error ) {
                    case "wrong_taxes_list":
                        wrongTaxesList( res );
                        break;
                    case "wrong_header":
                        wrongHeader( res );
                        break;
                    case "short_entry":  //Файл содержит неправильную информацию в названии столбцов. Убедитесь, что Вы используете тот же формат, как и файл шаблона.
                        shortEntryResult();
                        break;
                    case "limit10000":
                        limit10000();
                        break;
                    case "error_list":
                        downloadError( res );
                        break;
                    case null:
                        downloadSuccess( res );
                        break;
                    default:
                        wrongTaxesList( res );
                }
            }


            self.primaryButtonDisabled = false;
            //console.info( 'onSuccessItem', fileItem, response, status, headers );
        };
        uploader.onErrorItem = function ( fileItem, response, status, headers ) {
            uploader.removeFromQueue( 0 );
            self.includeTemplate = includeTemplate.onError;
            self.primaryButtonText = 'REPEAT_LOADING';
            self.primaryAction = primaryAction.onStart;
            self.primaryButtonDisabled = false;
            //console.info( 'onErrorItem', fileItem, response, status, headers );
        };
        uploader.onCancelItem = function ( fileItem, response, status, headers ) {

            //console.log( response );

            //console.info( 'onCancelItem', fileItem, response, status, headers );
        };
        uploader.onCompleteItem = function ( fileItem, response, status, headers ) {

            ////console.info( 'onCompleteItem', fileItem, response, status, headers );
        };
        uploader.onCompleteAll = function () {

            if ( self.includeTemplate != includeTemplate.onError ) {
                //self.includeTemplate = includeTemplate.onStart;
                //self.includeHeadTemlate = includeHeadTemplate.onSuccess
            }

            //console.info( 'onCompleteAll' );
        };

    }




    //console.info( 'uploader', uploader );

    function shortEntryResult() {
        uploader.removeFromQueue( 0 );
        self.includeTemplate = includeTemplate.onShortEntry;
        self.onCancel = cancelAction.onStart;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;

    }

    function limit10000() {
        uploader.removeFromQueue( 0 );
        self.includeTemplate = includeTemplate.limit10000;
        self.onCancel = cancelAction.onStart;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
    }


    function downloadSuccess( res ) {

        /*res = {
         result: 'ok',
         error :null,
         created: {
         category: 4, //будет создано 4 категории
         goods: 254, //будет создано товаров
         edit: 85  // Будет отредактировано товаров
         },
         warnings: [
         {

         "columnError": "you_set_empty_field",
         "columnName": "TITLE_NAME",  // соответсвует "Название"
         replacement: "WILL_BE_ZERO", // будет присвоено значение 0,
         type: "CELL", // ????
         items: [    // ячейки
         'D445',
         'C445'
         ],
         andMore: 5 // еще 5 ячеек
         }
         ]
         };*/

        var i = 0;
        while ( i < res.warnings.length ) {
            (function ( i ) {
                $translate( res.warnings[i].columnName )
                    .then( function ( val ) {
                        if ( /^\s$/.test( val ) ) {
                            res.warnings[i]._error = translate( 'ERROR.' + res.warnings[i].columnError, {name: '"' + res.warnings[i].columnName + '"'} )
                        } else {
                            res.warnings[i]._error = translate( 'ERROR.' + res.warnings[i].columnError, {name: '"' + val + '"'} )
                        }
                    }, function ( err ) {
                        res.warnings[i]._error = translate( 'ERROR.' + res.warnings[i].columnError, {name: '"' + res.warnings[i].columnName + '"'} )
                    } );
            })( i );
            i++;
        }
        i = null;

        self.includeTemplate = includeTemplate.onParserSuccess;
        self.includeHeadTemlate = includeHeadTemplate.onSuccess;
        self.response = res;
        self.primaryButtonText = 'REFRESH_GOODS';
        self.onCancel = cancelAction.onStart;
        self.primaryAction = primaryAction.onUpdateGoods;
    }

    function wrongTaxesList() {
        uploader.removeFromQueue( 0 );
        self.includeTemplate = includeTemplate.wrongTaxesList;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
    }

    function wrongHeader() {
        uploader.removeFromQueue( 0 );
        self.includeTemplate = includeTemplate.onWrongHeader;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
    }

    function downloadError( res ) {


        /*res = {
         "result": "ok",
         error: "error_list",
         totalError: 255,
         "errorsList": [
         {
         "columnError": "max_price",
         "columnName": "PRICE",
         "type": "COLUMN",// : "" // столбцы
         "items": [   // строки
         "2D"
         ],
         "andMore": 10
         },
         {
         "columnError": "max_price",
         "columnName": "PRICE",
         "type": "ROW",// : "" // столбцы
         "items": [
         "7K"
         ],
         "andMore": 0
         }
         ]
         };*/


        uploader.removeFromQueue( 0 );
        self.includeTemplate = includeTemplate.onParserError;
        self.includeHeadTemlate = includeHeadTemplate.onParserError;
        var i = 0;
        while ( i < res.errorsList.length ) {
            (function ( _i ) {
                $translate( res.errorsList[_i].columnName )
                    .then( function ( val ) {
                        if ( /^\s$/.test( val ) ) {
                            res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
                        } else {
                            res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + val + '"'} )
                        }
                    }, function ( err ) {
                        res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
                    } );
            })( i );
            i++
        }
        i = null;
        self.response = res;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
    }

    function maxFileSize() {
        uploader.removeFromQueue( 0 );
        self.templateWidtn = null;
        self.includeTemplate = includeTemplate.onMaxFileSize;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
        self.primaryButtonDisabled = false;
    }

    function badEncoding() {
        uploader.removeFromQueue( 0 );
        self.templateWidtn = null;
        self.includeTemplate = includeTemplate.badEncoding;
        self.primaryButtonText = 'REPEAT_LOADING';
        self.primaryAction = primaryAction.onStart;
        self.primaryButtonDisabled = false;
    }

    function checkFileSize() {
        if ( 5 * 1000 * 1000 <= self.fileSize ) {
            return false
        }
        return true;
    }


    function checkFileNameValid() {
        if ( /\.csv$/.test( self.onAttachFileName ) ) {
            return true
        }
        return false
    }
}

export default null;

// export default {
//     type: 'factory',
//     name: 'importFiles',
//     value: importFiles
// };