/**
 * Created by sirosh on 05.08.16.
 */

import angular from 'angular';

tableColumnProp.$inject = ['promiseOperation', 'factoryPermits'];

function tableColumnProp(promiseOperation, factoryPermits){

        return {
            getProps: getProps,
            setProp: setProp,
            setProps: setProps,
            hideMaintenanceBanner: hideMaintenanceBanner
        };

        function successGet(response){
            if (angular.isObject(response.data)) {
                if (response.data.result === 'ok') {

                    var propObj = {};

                    for(var i= 0, len = response.data.columnsView.length; i < len; i++){
                        if(response.data.columnsView[i].name) {
                            propObj[response.data.columnsView[i].name] = response.data.columnsView[i].value;
                        }
                    }

                    return{
                        dataArr: response.data.columnsView,
                        config: propObj
                    };
                }
            }
        }

        function successSet(response){
            if (angular.isObject(response.data)) {
                if (response.data.result === 'ok') {
                    return response.data;
                }
            }
        }


        function successHideMaintenanceBanner(response){
            if (angular.isObject(response.data)) {
                if (response.data.result === 'ok') {
                    return response.data;
                }
            }
        }

        function getProps(params){
            return promiseOperation
                .getPromiseHTTP(
                    'POST',
                    '/data/ownercab/getreportcolumns',
                    params,
                    successGet
                );
        }

        function setProps(params){

            return promiseOperation
                .getPromiseHTTP(
                    'POST',
                    '/data/ownercab/setreportcolumns',
                    params,
                    successSet
                );
        }

        function hideMaintenanceBanner(params) {
            return promiseOperation
                .getPromiseHTTP(
                    'POST',
                    '/data/ownercab/hideMaintenanceBanner',
                    params,
                    successHideMaintenanceBanner
                );
        }

        function setProp(value, name, config, data, type){
            var params = {};

            config[name] = value;

            data.map(function(i, v){
                if(v.name === name){
                    v.value = value;
                }
            });

            if(factoryPermits.editProfile) {
                params.reportType = type;
                params.columnsView = data;

                // console.log("params in setProp = ", params);

                setProps(params);
            }

        }

    }

export default {
    type: 'factory',
    name: 'tableColumnProp',
    value: tableColumnProp
};