/**
 * Created by olehkss on 05.07.16.
 *
 * @ngdoc directive
 * @name app.directive: cashboxShiftView
 * @scope
 * @restrict E
 * @description
 * Shows details of selected cashbox shift in the sidebar menu provided by angular material
 * @requires $mdSidenav
 * @requires shareCashboxShift
 */

import template from '../templates-pug/for-directive/cashbox-shift-view.pug';

cashboxShiftView.$inject = [];

function cashboxShiftView() {
    return {
        restrict: 'E',
        scope: {},
        controller: controller,
        controllerAs: 'vmCashboxShiftView',
        template: template
    };
}

controller.$inject = ['$mdSidenav', 'shareCashboxShift'];
function controller($mdSidenav, shareCashboxShift) {

    let vm = this;
    vm.getCashboxShift = shareCashboxShift.getCashboxShift;
    vm.closeCashboxShift = closeCahsboxShift;

    /*** формат отображения валюты ***/
    vm.currencyOptionsShow = shareCashboxShift.getCurrencyShow;

    vm.receiptFormat = shareCashboxShift.getReceiptFormat;

    /*** формат отображения процентов налога ***/
    vm.percentShowTax = shareCashboxShift.getPercentShowTax;

    /*** Receipt formats where taxes block needs to be shown ***/
    vm.formatsForTaxesBlock = ["JAPANESE", "SPANISH", "GERMAN", "EUROPEAN_UNION"];

    /*** Receipt formats where tax base needs to be shown ***/
    vm.formatsForTaxBase = ["SPANISH", "GERMAN", "EUROPEAN_UNION"];
    /**
     * @ngdoc function
     * @description
     * Closes sidebar with detail view. We need to set selected cashbox shift to null
     * in order to clear the sidenav (hide directive template)
     */
    function closeCahsboxShift() {
        var mdSidenavRight = document.getElementsByClassName('md-sidenav-right');
        var mdSidenavLeft = document.getElementsByClassName('md-sidenav-left');
        //if check slide from right side we foud class and if this true we close $mdSidenav
        (mdSidenavLeft.length === 1) ?  $mdSidenav('left').close() : (mdSidenavRight.length === 1) ?  $mdSidenav('right').close() : "";

        // $mdSidenav('right').close();
        shareCashboxShift.setCashboxShift(null);
        shareCashboxShift.setCurrencyShow(null);
        shareCashboxShift.setReceiptFormat(null);
        shareCashboxShift.setPercentShowTax(null);
    }
}

export default {
    type: 'directive',
    name: 'cashboxShiftView',
    value: cashboxShiftView
};
