/**
 * @ngdoc directive
 * @name app.directive:noDataMessageForReports
 * @scope
 * @restrict E
 *
 * @description
 * Adds simple message about with picture and two lines of text. Can be used in order to be shown if the server response
 * returns nothing(no data).
 *
 * @param {string} message  second message sting which will be translated via translate module
 * @param {string} imageSource  image path that will replace default one
 * @param {string} customTitle first message to be shown
 * @param {string} iconSource path to the svg image to be shown using md-icon directive
 * @param {boolean} borderTop show top border
 * @param {boolean} borderBottom show bottom border
 *
 * @example
 * <no-data-message-for-reports = "Some_message" img-src = 'path_to_new_image'></no-data-message-for-reports>
 */

import template from '../templates-pug/for-directive/no-data-message-for-reports.pug';
import imageSourceDefault from '../../img/baseline-receipt-24px.svg';

noDataMessage.$inject = [];
function noDataMessage() {
    return {
        restrict: 'E',
        template: template,
        transclude: true,
        scope: {
            customTitle: '@',
            message: '@',
            imageSource: '@',
            iconSource: '@',
            borderTop: '@',
            borderBottom: '@'
        },
        link: function(scope) {
            scope.imageSourceDefault = imageSourceDefault;
        }
    };
}

export default {
    type: 'directive',
    name: 'noDataMessageForReports',
    value: noDataMessage
};