/**
 * Created by A.Sirosh on 27.10.2015.
 */

currencyToCoin.$inject = ['currencyFormat'];
function currencyToCoin(currencyFormat) {

	let _format = {};
	let cleanupRegExp = null;
	currencyFormat.observable.countQuantityForOrderDivisible.observe(result => {
		_format = result
		cleanupRegExp = RegExp("[^\\-\\" + _format.decimalCharacter + "\\" + _format.decimalCharacterAlternative + "\\" + _format.digitGroupSeparator + "\\." + "\\d]", "g");
	}, true);

	format.$stateful = true;
	function format(string, delimiter) {

		function clearDelimiters( val) {
			val = ""+val;

			// Remove all non-numeric and non separators characters.
			val = val.replace(cleanupRegExp, "");

			if ((val.includes(',')) && _format.decimalCharacter === "," && _format.digitGroupSeparator === " ") {
				val = val.replace(',', '.').replace(/\s/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			if (val.includes(',') && _format.decimalCharacter === "," && _format.digitGroupSeparator === ".") {
				val = val.replace(/\./g, '').replace(',', '.');
				// console.log("выход = ", val);
				// return val;
			}
			if ((val.includes(',')) && _format.digitGroupSeparator === ",") {
				val = val.replace(/,/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			if ((val.includes(' ')) && _format.digitGroupSeparator === " ") {
				val = val.replace(/\s/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			return val;
		}

		string = ""+clearDelimiters(string);

		if(typeof string != 'string'){
			string = string.toString();
		}
		string = string.replace(',','.');
		var pos = string.indexOf('.');
		if(pos == -1){
			return string+='00';
		}

		string = string.replace('.', '');
		string += '00';
		var arr = string.split('');
		if (0 < pos) {
			arr.splice(pos + 2, 0, '.');
		}
		string = arr.join('');
		string = parseFloat(string);
		string = ''+string;
		return string;

	}

	return format;
};

export default {
	type: 'filter',
	name: 'currencyToCoin',
	value: currencyToCoin
};
