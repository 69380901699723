/**
 * Created by mars on 1/26/16.
 */

config.$inject = ['$mdThemingProvider'];
function config($mdThemingProvider) {
	var greenTheme = $mdThemingProvider.theme( 'green-theme', 'default' );
	var greenPalette = $mdThemingProvider.extendPalette( 'blue', {
		'500': '4BAF4F',
		'600': '4BAF4F'
	} );

	$mdThemingProvider.definePalette( 'greenPalette', greenPalette );
	greenTheme.primaryPalette( 'greenPalette' )
		.accentPalette( 'purple', {
			'default': '200' // use shade 200 for default, and keep all other shades the same
		} );
}

export default {
	type: 'config',
	value: config
};