/**
 * Created by Kiskenbassker on 14.06.18
 * List of languages having images with translated text for Play Market / App Store download links
 */

const constantAppImages = [
        'ara',
        'bul',
        'deu',
        'ell',
        'eng',
        'est',
        'fra',
        'hun',
        'ind',
        'ita',
        'jpn',
        'kor',
        'msa',
        'nld',
        'nor',
        'pol',
        'por',
        'ron',
        'rus',
        'spa',
        'tha',
        'tur',
        'vie',
        'zho'
];

export default {
        type: 'constant',
        name: 'constantAppImages',
        value: constantAppImages
};