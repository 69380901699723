/**
 * @memberof getPurchases
 * @ngdoc factory
 * @name getPurchases
 * @param $http {service} http servise
 * @param $q {service} promise servise
 * @param $location {service} location servise
 * @returns getReceiptsData {Promise} data for sales report
 */

import angular from 'angular';

getPurchases.$inject = ["$http", "$q", 'factoryErrorAlert'];

function getPurchases($http, $q, factoryErrorAlert) {

	function success(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === "ok") {
				return response.data;

			} else {
				return $q.reject(response.data);
			}
		} else {
			// invalid response
			return $q.reject(response.data);
		}
	}

	function getPurchasesData(params) {
		return $http.post('/data/ownercab/getpurchasesreport', params).then(success, factoryErrorAlert.onError);
	}

	return {
		getPurchasesData: getPurchasesData
	};

}

export default {
	type: 'factory',
	name: 'getPurchases',
	value: getPurchases
};