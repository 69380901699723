/**
 * Created by olehkss on 12.05.16.
 *
 * @ngdoc directive
 * @name app.directive: datePickerInput
 * @scope
 * @restrict E
 * @description
 * Adds calendar and four buttons(today, yesterday, week, month) to the report page.
 * Interact with all states where it is included in order to pass a date via input or calendar picker and save it inside any shared scope for further processing
 * @example
 * <date-picker-input></date-picker-input>
 */

import template from '../templates-pug/for-directive/date-picker-input.pug';

datePickerInputDirective.$inject = ['$rootScope', '$timeout'];

function datePickerInputDirective($rootScope, $timeout) {
    return {
        restrict: 'E',
        controller: controller,
        link: link,
        scope: true,
        controllerAs: 'vmDatePickerInput',
        template: template
    };

    function link(scope, elem, attrs) {
        $timeout(function(){
            scope.$parent.datePickerId = attrs.id;
            scope.startWeek = $rootScope.startWeek;
            // console.log(scope.$parent.datePickerId === attrs.id);
            if(scope.startDateId === attrs.id){
                scope.dateFrom = scope.$parent.startDate;
                // console.log('scope.dateFrom' + scope.dateFrom);
            } else if (scope.endDateId === attrs.id){
                scope.dateFrom = scope.$parent.endDate;
                // console.log('scope.dateTo' +  scope.dateFrom);
            }

            scope.$watch('dateFrom', function(newVal, oldVal){
                if(newVal!== oldVal){
                    scope.$parent.datePickerId = attrs.id;
                    // console.log(newVal);
                    scope.$parent.dateFrom = newVal;
                }
            })
        }, 1);


    }
}

controller.$inject = ['$scope'];
function controller($scope) {
    $scope.startDateId = $scope.$parent.startDateId;
    $scope.endDateId = $scope.$parent.endDateId;
    $scope.maxDate = $scope.$parent.maxDate ? $scope.$parent.maxDate : null;
    $scope.minDate = $scope.$parent.minDate ? $scope.$parent.minDate : null;
}

export default {
    type: 'directive',
    name: 'datePickerInput',
    value: datePickerInputDirective
};