/**
 * Общие методы биллинга для действий, совершаемых из разных мест бэк-офиса:
 * - страница биллинга,
 * - плашка алерта,
 * - карточка сотрудника,
 * - заглушка инвентори,
 * - заглушка интеграций
 * Created by Shestakov on 03.07.20
 */

import angular from 'angular';

serviceBillingDifferentPlaces.$inject = [
    'factoryBilling',
    'serviceBillingError',
    'serviceDialog',
    '$filter',
    'getChartLabel',
    'constBillingSubscriptions',
    'factoryEnvironment'
];

function serviceBillingDifferentPlaces(factoryBilling,
                                       serviceBillingError,
                                       serviceDialog,
                                       $filter,
                                       getChartLabel,
                                       constBillingSubscriptions,
                                       factoryEnvironment) {


    var translate = $filter( 'translate' );
    var site = "https://loyverse.com/";

    factoryEnvironment.getEnv().then(function (data) {
        if (data.site) {
            site = data.site;
        }
    });

    // проверка, разрешен ли выбор тарифного плана
    function checkIfPlanAllowed (paymentMethod, okCallback) {
        if(paymentMethod === null) {
            factoryBilling.planSelectionAllowed({}).then(function (res) {
                if(res.result === "ok") {
                    if(res.planSelectionAllowed) {
                        okCallback();
                    } else {
                        serviceBillingError.knownError("attempt_limit_exceeded");
                    }
                }
            })
        } else {
            okCallback();
        }
    }
    // проверка, разрешен ли выбор тарифного плана


    // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ
    function addCoupon(plan, coupon, nameNew) {
        if (plan.id === null) {
            coupon.error      = 'ACCOUNT.B2_COUPON.B2_COUPON_SELECT_PLAN';
            coupon.tempName   = angular.copy(nameNew);
            coupon.noPlan     = true;
        }
        if (plan.id !== null) {
            coupon.error      = "";
            coupon.noPlan     = false;

            factoryBilling.testCoupon({planId:plan.id, coupon:nameNew}).then(function (res) {
                if (res.result === "ok") {
                    coupon.code       = nameNew;
                    coupon.showInput  = false;
                    coupon.name       = res.name;
                    coupon.haveCoupon = true;
                }
                else {
                    if (res.result === "error" && res.message === "wrong_coupon") {
                        coupon.error      = 'ACCOUNT.B2_COUPON.B2_COUPON_INVALID_COUPON';
                        coupon.tempName   = angular.copy(nameNew);
                    }
                    if (res.result === "error" && res.message === "already_applied") {
                        coupon.error       = 'ACCOUNT.B2_COUPON.B2_COUPON_WAS_APPLIED';
                        coupon.tempName    = angular.copy(nameNew);
                    }
                    if (res.result === "error" && res.message !== "wrong_coupon" && res.message !== "already_applied") {
                        coupon.error      = 'ACCOUNT.B2_COUPON.B2_COUPON_TRY_LATER';
                        coupon.tempName   = angular.copy(nameNew);
                    }
                }
            });
        }
    }
    // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ


    // показ диалогового окна предпросмотра счёта
    function showEstimatedInvoiceDialog(data, applyCallback, cancelCallback, backCallback) {
        let summaryText,
            immediatePayment,
            showSubtotal = (data.coupons && data.coupons.length > 0) || (data.taxes && data.taxes.length > 0),
            itemsPrice = showSubtotal ? data.subtotal : data.total,
            showBackButton = backCallback !== undefined;

        if (data.applicableAt === "UPON_PAYMENT") {
            summaryText = 'ACCOUNT.B2_ESTIMATE.B2_ESTIMATE_SUMMARY_CONFIRMATION';
            immediatePayment = true;
        } else if (data.applicableAt === "END_OF_TRIAL") {
            summaryText = 'ACCOUNT.B2_ESTIMATE.B2_ESTIMATE_SUMMARY_TRIALEND';
            immediatePayment = false;
        } else {
            summaryText = 'ACCOUNT.B2_ESTIMATE.B2_ESTIMATE_SUMMARY_NEXT_CYCLE';
            immediatePayment = false;
        }

        let showCredits = data.creditsApplied && data.creditsApplied !== 0 && immediatePayment,
            showPrepayment = data.paymentApplied && data.paymentApplied !== 0 && immediatePayment,
            showCreditsInfo = data.creditsAvailable && !data.creditsAndPrepaymentAvailable,
            showCreditsPrepaymentInfo = !data.creditsAvailable && data.creditsAndPrepaymentAvailable,
            showPromotionalCredits = data.promoCredits !== 0;

        let estimateDialog = {
            templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-estimated-invoice.html',
            title: 'ACCOUNT.B2_ESTIMATE.B2_ESTIMATE_INVOICE_PREVIEW',
            summary: summaryText,
            estimatedData: data,
            itemsPrice: itemsPrice,
            showSubtotal: showSubtotal,
            showCredits: showCredits,
            showPrepayment: showPrepayment,
            showCreditsInfo: showCreditsInfo,
            showCreditsPrepaymentInfo: showCreditsPrepaymentInfo,
            showPromotionalCredits: showPromotionalCredits,
            getChartLabel: getChartLabel,
            getSubscriptionName: getSubscriptionName,
            buttons: [{
                text:  'ON_CANCEL',
                action: function () {
                    if(cancelCallback) {
                        cancelCallback();
                    }
                }
            },
            {
                text: 'APPLY',
                class: 'md-primary',
                action: function () {
                    applyCallback();
                }
            }]
        };

        if (showBackButton) {
            estimateDialog.showBackButton = showBackButton;
            estimateDialog.back = backCallback;
        }

        serviceDialog.add(estimateDialog)
    }

    // перевод названия подписки в зависимости от типа, приходящего с сервера
    function getSubscriptionName(type) {
        var name;
        if (type === "EMPSTORE") {
            name = translate('ACCOUNT.B2_SUBSCRIPTION_NAME_EMPLOYEE');
        } else {
            name = translate('ACCOUNT.B2_SUBSCRIPTION_NAME_' + type);
        }

        return name;
    }

    // получаем ссылку на условия использования интеграций
    function getTermsUrl() {
        var utm = encodeURI("utm_source=Back Office&utm_medium=Terms of third party integration");
        let endingSlash = site[site.length - 1] === '/';
        return site + (endingSlash ? '' : '/') + "terms-third-party-integration?" + utm;
    }

    // фильтруем значения, содержащиеся в константе всех подписок, по подпискам, существующим в данном аккаунте
    // получаем массив существующих в данном аккаунте подписок в нужном порядке.
    function getSubscriptionConstants(subscriptionKeys) {
        var allSubscriptions = angular.copy(constBillingSubscriptions);

        return allSubscriptions.filter(function (subscription) {
            return subscriptionKeys.indexOf(subscription) !== -1;
        });
    }

    this.checkIfPlanAllowed = checkIfPlanAllowed;
    this.addCoupon = addCoupon;
    this.showEstimatedInvoiceDialog = showEstimatedInvoiceDialog;
    this.getTermsUrl = getTermsUrl;
    this.getSubscriptionConstants = getSubscriptionConstants;

}



export default {
    type: 'service',
    name: 'serviceBillingDifferentPlaces',
    value: serviceBillingDifferentPlaces
};
