export const wrap = (text, limit) => {
    text = text ?? "";
    
    if (text === "") {
        return text;
    }
    
    const { buffer, lines } = text
        .split(" ")
        .reduce(({ buffer, lines }, x) =>
                buffer.length + x.length >= limit
                    ? { buffer: x, lines: lines.concat(buffer) }
                    : { buffer: buffer + (buffer === "" ? "" : " ") + x, lines },
            { buffer: "", lines: [] });

    let allLines = lines
        .concat(buffer)
        .filter(line => line.length > 0);

    const wrapped = [];
    allLines.forEach(line => {
        if (line.length > limit) {
            let currentLine = line;
            do {
                const firstPart = currentLine.slice(0, limit);
                currentLine = currentLine.slice(limit);
                wrapped.push(firstPart);
            } while (currentLine.length > limit)
            wrapped.push(currentLine);
        } else {
            wrapped.push(line);
        }
    });

    if (wrapped.length > 2) {
        return wrapped.slice(0, 2).join("\n") + "...";
    }
    
    return wrapped.join("\n");
}
