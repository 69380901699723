/**
 * Created by olehkss on 07.09.16.
 *
 * @ngdoc factory
 * @name app.factory: cashBoxesListFactory
 * @require promiseOperation
 * @description
 * Returns list of cashboxes(cash registers)
 */

import angular from 'angular';

cashBoxesListFactory.$inject = ['promiseOperation'];

function cashBoxesListFactory(promiseOperation) {

    return {
        getCashBoxesList: getCashBoxesList
    };

    function getCashBoxesList(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getcashregisters',
            params,
            success
        );
    }

    function success(response) {
        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data.registers;

            }
        }
    }
}

export default {
    type: 'factory',
    name: 'cashBoxesListFactory',
    value: cashBoxesListFactory
};