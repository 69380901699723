/**
 * @memberof getReceipts
 * @ngdoc factory
 * @name getReceipts
 * @param $http {service} http servise
 * @param $q {service} promise servise
 * @param $location {service} location servise
 * @returns getReceiptsData {Promise} data for sales report
 */

import angular from 'angular';

getReceipts.$inject = ["$http", "$q", 'factoryErrorAlert'];
function getReceipts($http, $q, factoryErrorAlert ) {

	function success(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === "ok") {

				return response.data;

			} else {
				return $q.reject(response.data);
			}
		} else {
			// invalid response
			return $q.reject(response.data);
		}
	}

	function getReceiptsData(params) {
		return $http.post('/data/ownercab/getreceiptsarchive', params).then(success, factoryErrorAlert.onError);
	}


	/*парамс будет видно в контроллере*/
	function getRefundReceiptsInfo (params) {
		return $http.post('/data/ownercab/getRefundReceiptsInfo', params).then(success, factoryErrorAlert.onError);
	}

	/*Если у чека продажи нет возвратов, или это чек возврата, нужно проверить, оплачен
	ли он одной из интегрированных платежных систем, и есть ли в нем товары, у которых
	включен учет остатков*/
	function getReceiptInfoForCancelMessage (params) {
		return $http.post('/data/ownercab/getReceiptInfoForCancelMessage', params).then(success, factoryErrorAlert.onError);
	}

	/*Если пользователь подтвердил отмену и у чека продажи нет возвратов, то отменяем чек.
	 Для этого нужно использовать запрос*/
	function cancelReceipt (params) {
		return $http.post('/data/ownercab/cancelReceipt', params).then(success, factoryErrorAlert.onError);
	}


	return {
		getReceiptsData: getReceiptsData,
		getRefundReceiptsInfo: getRefundReceiptsInfo,
		getReceiptInfoForCancelMessage: getReceiptInfoForCancelMessage,
		cancelReceipt: cancelReceipt,
	};


}

export default {
	type: 'factory',
	name: 'getReceipts',
	value: getReceipts
};