import { BreakpointHandler } from "../../common/events/breakpoint-handler";

windowResizeUtil.$inject = [];

function windowResizeUtil() {

    const resizeHandlers = {};

    this.addResizeListener = addResizeListener;

    function addResizeListener(breakpoint, cb) {
        let handler = resizeHandlers[breakpoint];
        if (!handler) {
            handler = new BreakpointHandler(breakpoint);
            resizeHandlers[breakpoint] = handler;
        }
        handler.subscribe(cb);

        return () => {
            handler.unsubscribe(cb);
            if (handler.subscribers.length === 0) {
                handler.destroy();
                delete resizeHandlers[breakpoint];
            }
        }
    }
}

export default {
    type: 'service',
    name: 'windowResizeUtil',
    value: windowResizeUtil
};