import angular from "angular";

createEditToken.$inject = [
    '$scope',
    '$state',
    'factoryTokens',
    'serviceToast',
    '$stateParams',
    '$tokenInfo',
    'dateCustomMethods',
    'lvMixpanel',
    'serviceDialog',
    'dialogs'
];

function createEditToken(
    $scope,
    $state,
    factoryTokens,
    serviceToast,
    $stateParams,
    $tokenInfo,
    dateCustomMethods,
    lvMixpanel,
    serviceDialog,
    dialogs
) {

    var vm = this;
    vm.tokenData = {};
    $scope.minDate = new Date();

    let initParams;
    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {
        let currentParams = {
            name: vm.tkName,
            hasExpirationDate: vm.hasExpirationDate,
            tokenExpiry: vm.hasExpirationDate ? new Date($scope.tokenExpiry) : null
        };
        return !angular.equals(currentParams,initParams);
    }

    var toDate = new Date();

    if($state.current.name === 'integrations.editToken') {
        vm.tokenInfo = $tokenInfo;
        vm.hasExpirationDate = !vm.tokenInfo.eternalToken;
        vm.tkName = vm.tokenInfo.name;
        $scope.tokenExpiry = vm.tokenInfo.expiryDate ? vm.tokenInfo.expiryDate : formatDate(new Date(toDate.getFullYear() + 1, toDate.getMonth(), toDate.getDate()));
        $scope.startDate = new Date($scope.tokenExpiry);
    } else {
        $scope.startDate = new Date(toDate.getFullYear() + 1, toDate.getMonth(), toDate.getDate());
        $scope.tokenExpiry = formatDate($scope.startDate);
        vm.hasExpirationDate = true;
    }

    initParams = {
        name: vm.tkName,
        hasExpirationDate: vm.hasExpirationDate,
        tokenExpiry: vm.hasExpirationDate ? new Date($scope.tokenExpiry) : null
    };

    $scope.endDate = null;

    if(!vm.hasExpirationDate) {
        var calendar = document.getElementById('tokenDate');
        calendar.classList.add('eternalToken');
    }

    vm.setTime = function(hasDate) {
        var calendar = document.getElementById('tokenDate');
        if (hasDate === false) {
            calendar.classList.add('eternalToken');
        } else {
            calendar.classList.remove('eternalToken');
        }

    };

    var target = document.querySelector('#token-create-form');

    $(target).on("DOMNodeInserted", function () {
        $scope.startDateId = document.getElementById('startDateInput') ? document.getElementById('startDateInput').id : "startDateInput";
    });

    vm.onCancel = function() {
        if($state.current.name === 'integrations.createToken')
            $state.go('integrations.tokens');
        else
            $state.go('integrations.tokenDetail', { id: $stateParams.id});
    };

    /* Date picker */

    $scope.$watch( 'dateFrom', function(newVal, oldVal){
        if(newVal!== undefined && newVal !== oldVal){
            if($scope.startDateId && ($scope.datePickerId === $scope.startDateId)){
                $scope.startDate = newVal;
                $scope.tokenExpiry = formatDate($scope.startDate);
            }
        }
    });

    function formatDate(date) {
        return dateCustomMethods.setTimeToZero(Date.parse(date));
    }


    vm.onSaveAccessToken = function() {

        if ($scope.createEditForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.createEditForm.focusFirstInvalidControl();
            return;
        }

        //Triggered when users click on "Save" button in the
        // "Create access token page.It shouldn't be triggerd when
        // they click on "Save" button in the "Edit access token" page
        lvMixpanel.track('Back office page visit', {
            "Property": "Integrations/Access token created"
        });
        vm.tokenData = {
            name: vm.tkName,
            eternalToken: !vm.hasExpirationDate,
            tokenExpiry: vm.hasExpirationDate ? $scope.tokenExpiry : null
        };
        if($state.current.name === 'integrations.createToken') {
            factoryTokens.createApiAuthToken(vm.tokenData).then(function (res) {
                if (res.result === "ok") {
                    offStateChangeStart();
                    serviceToast.show('TOKENS.TOKEN_CREATED', 'success');
                    $state.go('integrations.tokenDetail', { id: res.authTokenId})
                } else {
                    if(res.message === "MAXIMUM_NUMBERS_TOKENS") {
                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                            title: translate('TOKENS.UNABLE_ADD_TOKEN_TITLE'),
                            content: translate('TOKENS.UNABLE_ADD_TOKEN_TEXT'),
                            buttons: [
                                {
                                    class: 'primary',
                                    text: 'ON_OK'
                                }
                            ]
                        });
                    }
                    serviceToast.show('TOKENS.CREATED_TOKEN_ERROE', 'error');
                }
            });
        } else {
            vm.tokenData.tokenId = $stateParams.id;
            factoryTokens.editApiAuthToken(vm.tokenData).then(function (res) {
                if (res.result === "ok") {
                    serviceToast.show('TOKENS.TOKEN_EDITED', 'success');
                    offStateChangeStart();
                    $state.go('integrations.tokenDetail', { id: $stateParams.id})
                } else {
                    serviceToast.show('TOKENS.EDITED_TOKEN_ERROE', 'error');
                }
            });
        }
    }

}

export default {
    type: 'controller',
    name: 'createEditToken',
    value: createEditToken
};