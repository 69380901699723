/**
 * Created by A.Sirosh on 11.09.2015.
 */

/**
 * Change the label on the chart based on the data.
 * @memberof getChartLabel
 * @ngdoc service
 * @name getChartLabel
 * @requires $filter {service} angular filter
 * @requires resolveTimezone {service} - resolve timezone problems
 */

getChartLabel.$inject = ['$filter', 'resolveTimezone'];
function getChartLabel($filter, resolveTimezone) {

	var timeZoneResolve = resolveTimezone.timeZoneResolvePlus;


	function formatDate(date, isFrom) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		if(isFrom)
			return [year, month, day].join('-') + " 00:00:00";
		else
			return [year, month, day].join('-') + " 23:59:59";
	}


	/**
	 *
	 * @param date
	 * @returns {string}
	 */
	function getMonth(date){
		var month = '';
		if (date.getMonth() > 8) {
			month = (date.getMonth() + 1).toString();
		} else {
			month = '0' + (date.getMonth() + 1).toString();
		}
		return month;
	}

	/**
	 *
	 * @param date
	 * @returns {string}
	 */
	function getDate(date){
		var d = '';
		if(date.getDate() < 10){
			d = '0' + date.getDate().toString();
		}else{
			d = date.getDate().toString();
		}
		return d;
	}

	/**
	 *
	 * @param to
	 * @param offset
	 * @returns {string}
	 */
	function hour(to, offset){
		var label = '';
		var date = new Date(to + offset);
		label = date.getHours().toString() + ':' + date.getMinutes().toString() + '0';
		return label;
	}


	/**
	 *
	 * @param to
	 * @returns {string}
	 */
	function day(to){
		var label = '';
		var date = new Date(to);
		var month = getMonth(date);
		label = getDate(date) + '.' + month;
		return label;
	}

	/**
	 *
	 * @param from
	 * @param to
	 * @returns {string}
	 */
	function week(from, to){
		var label = '';
		var dateFrom = new Date(from);
		var dateTo = new Date(to);
		var monthFrom = getMonth(dateFrom);
		var monthTo = getMonth(dateTo);
		label = getDate(dateFrom) + '.' + monthFrom + '-' + getDate(dateTo) + '.' + monthTo;
		return label;
	}

	/**
	 *
	 * @param to
	 * @returns {string}
	 */
	function month(to){
		var label = '';
		var dateTo = new Date(to);
		var monthTo = getMonth(dateTo);
		label = monthTo + '-' + dateTo.getFullYear().toString();
		return label;
	}

	/**
	 *
	 * @param from
	 * @param to
	 * @returns {string}
	 */
	function quarter(from, to){
		var label = '';
		var dateFrom = new Date(from);
		var dateTo = new Date(to);
		var monthFrom = getMonth(dateFrom);
		var monthTo = getMonth(dateTo);
		label = getDate(dateFrom) + '.' + monthFrom + '.' + dateFrom.getFullYear().toString()  + '-'
			  + getDate(dateTo) + '.' + monthTo + '.' + dateTo.getFullYear().toString();
		return label;
	}

	/**
	 *
	 * @param to
	 * @returns {string}
	 */
	function year(to){
		var label = '';
		var dateTo = new Date(to);
		label = dateTo.getFullYear().toString();
		return label;
	}

	/**
	 * Parse divider
	 * @param divider - custom divider chart options
	 * @param from - start period
	 * @param to - end period
	 * @returns {string}
	 */

	function switchLabels(divider, fromDay, toDay, fromYear, toYear, fromWithOffset, toWithOffset, entity, isTooltip, fromHour, toHour){
		var label;
		var currentYear = (new Date()).getFullYear();

		switch(divider){
			case 'hour':
				if(entity === 'table' || (entity === 'chart' && isTooltip)){
					toDay = (toHour && toHour <= fromHour) ? toDay + 1 : toDay;
					label = ((fromDay !== toDay) && (fromYear === toYear) && currentYear === toYear) ? $filter('formatTimeByBrowser')(fromWithOffset, 'shortHourDate') : ((fromDay !== toDay) && (fromYear !== toYear || currentYear !== toYear) ) ? $filter('formatTimeByBrowser')(fromWithOffset, 'shortHourDateYear') : $filter('formatTimeByBrowser')(fromWithOffset, 'short');
				} else {
					label = $filter('formatTimeByBrowser')(fromWithOffset, 'short');
				}

				break;
			case 'hourLabel':
				// label = $filter('formatTimeByBrowser')(fromWithOffset, 'short') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'long');
				label = $filter('formatTimeByBrowser')(fromWithOffset, 'short') + ' - ' + $filter('formatTimeByBrowser')(toWithOffset, 'short');
				break;
			case 'day':
				//this label has been commented since in a case of an upside down time range we should display an appropriate distinguished from any common case parameter.
// label = $filter('date')(toWithOffset, 'dd.MM.yy');
				if(fromYear === toYear && currentYear === toYear){
					if(isTooltip) {
						label = $filter('formatDateByBrowser')(fromWithOffset, 'dailyForChartsTooltip');
					} else {
						label = $filter('formatDateByBrowser')(fromWithOffset, 'dailyForCharts');
					}
				} else {

					if(entity === 'table'){
						label = $filter('formatDateByBrowser')(fromWithOffset, 'dailyForTables');
					} else {
						// label = $filter('formatDateByBrowser')(fromWithOffset, 'tableLabel');
						if(isTooltip) {
							label = $filter('formatDateByBrowser')(fromWithOffset, 'dailyForChartTooltipYear');
						} else {
							label = $filter('formatDateByBrowser')(fromWithOffset, 'dailyForCharts');
						}
					}

				}
//label = parseInt($filter('date')(toWithOffset, 'dd') + '' + $filter('date')(toWithOffset, 'MM'));
				break;
			case 'week':
				if(fromYear === toYear && currentYear === toYear){
					label = $filter('formatDateByBrowser')(fromWithOffset, 'weeklyForCharts') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'weeklyForCharts');
				} else {

					if(entity === 'table' || (entity === 'chart' && isTooltip)){
						label = $filter('formatDateByBrowser')(fromWithOffset, 'weeklyForTables') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'weeklyForTables');
					} else {
						// label = $filter('formatDateByBrowser')(fromWithOffset, 'tableLabel') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'tableLabel');
						label = $filter('formatDateByBrowser')(fromWithOffset, 'weeklyForCharts') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'weeklyForCharts');
					}
				}
				break;
			case 'month':
				if(fromYear === toYear && currentYear === toYear){
					label = $filter('formatDateByBrowser')(toWithOffset, 'monthlyForCharts');
				} else {
					label = $filter('formatDateByBrowser')(toWithOffset, 'tableLabelShortMonthYear');
				}
				break;
			case 'quarter':
				if(fromYear === toYear && currentYear === toYear){
					label = $filter('formatDateByBrowser')(fromWithOffset, 'quarterlyForCharts') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'quarterlyForCharts');
				} else {

					if(entity === 'table' || (entity === 'chart' && isTooltip)){
						label = $filter('formatDateByBrowser')(fromWithOffset, 'quarterlyForTables') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'quarterlyForTables');
					} else {
						// label = $filter('formatDateByBrowser')(fromWithOffset, 'tableLabel') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'tableLabel');
						label = $filter('formatDateByBrowser')(fromWithOffset, 'quarterlyForCharts') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'quarterlyForCharts');
					}

				}
				break;
			case 'year':
				label = $filter('formatDateByBrowser')(toWithOffset, 'tableLabelYear');
				break;
			case 'some':
				if(fromYear === toYear){
					label = $filter('formatDateByBrowser')(fromWithOffset, 'quarterlyForCharts') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'quarterlyForTables');
				} else {

					label = $filter('formatDateByBrowser')(fromWithOffset, 'quarterlyForTables') + ' - ' + $filter('formatDateByBrowser')(toWithOffset, 'quarterlyForTables');

				}
				break;
		}

		return label;
	}

	/**
	 * Parse divider
	 * @param divider - custom divider chart options
	 * @param from - start period
	 * @param to - end period
	 * @returns {string}
	 */
	function parseDeliver(divider, from, to, stateParamsFrom, stateParamsTo, entity, isTooltip, fromHour, toHour){
		var date = new Date();

		var fromDate = new Date(from);
		var toDate = new Date(to);

		var resolveFrom = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes());
		var resolveTo = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes());

		//this way we used data with timezone offset before that moment when it was added to the server side processing
		// var fromWithOffset = timeZoneResolve(resolveFrom);
		// var toWithOffset = timeZoneResolve(resolveTo);
		var fromWithOffset = resolveFrom;
		var toWithOffset = resolveTo;
		var fromYear  = new Date(stateParamsFrom).getFullYear();
		var fromDay  = new Date(stateParamsFrom).getDate();
		var toYear = new Date(stateParamsTo).getFullYear();
		var toDay = new Date(stateParamsTo).getDate();
		var label = '';

		if(divider === undefined || divider === null){
			divider = 'day';
		}

		label = switchLabels(divider, fromDay, toDay, fromYear, toYear, fromWithOffset, toWithOffset, entity, isTooltip, fromHour, toHour);


	/*	switch (divider) {
			case 'hour':
				label = hour(toWithOffset, offset);
				break;
			case 'day':
				label =  day(toWithOffset);
				break;
			case 'week':
				label = week(fromWithOffset, toWithOffset);
				break;
			case 'month':
				label = month(toWithOffset) ;
				break;
			case 'quarter':
				label = quarter(fromWithOffset, toWithOffset);
				break;
			case 'year':
				label = year(toWithOffset);
				break;
		}*/
	return label;

	}

	function parseDeliverForTable(entity, divider, from, to, fromString, toString, stateParamsFrom, stateParamsTo, fromHour, toHour){
		var date = new Date();
		stateParamsFrom = Number(stateParamsFrom);
		stateParamsTo = Number(stateParamsTo);

		if (fromString != null && toString != null) {
			var fromParse = fromString.split('T')[0].split('-');
			var toParse = toString.split('T')[0].split('-');
			var fromTime = fromString.split('T')[1].split(':');
			var toTime = toString.split('T')[1].split(':');
			from = new Date(fromParse[0], fromParse[1] - 1, fromParse[2], fromTime[0], fromTime[1], 0).getTime();
			to = new Date(toParse[0], toParse[1] - 1, toParse[2], toTime[0], toTime[1], toTime[2]).getTime();
		}

		var fromDate = new Date(from);
		var toDate = new Date(to);

		var resolveFrom = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes());
		var resolveTo = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes());

		//this way we used data with timezone offset before that moment when it was added to the server side processing
		// var fromWithOffset = timeZoneResolve(resolveFrom);
		// var toWithOffset = timeZoneResolve(resolveTo);
		var fromWithOffset = resolveFrom;
		var toWithOffset = resolveTo;
		var fromYear  = new Date(stateParamsFrom).getFullYear();
		var fromDay  = new Date(stateParamsFrom).getDate();
		var toYear = new Date(stateParamsTo).getFullYear();
		var toDay = new Date(stateParamsTo).getDate();
		var label = '';

		if(divider === undefined || divider === null){
			divider = 'day';
		}

		label = switchLabels(divider, fromDay, toDay, fromYear, toYear, fromWithOffset, toWithOffset, entity, false, fromHour, toHour);

		return label;

	}


	this.parseDeliver = parseDeliver;
	this.parseDeliverForTable = parseDeliverForTable;
	this.formatDate = formatDate;
	this.getMonth = getMonth;
	this.getDate = getDate;

}

export default {
	type: 'service',
	name: 'getChartLabel',
	value: getChartLabel
};