/**
 * Created by olehkss on 23.04.16.
 *
 * @ngdoc factory]
 * @name app.factory: getBillingData
 * @requires promiseOperation
 * @description
 *
 * Calls server and returns billing(tariff, payments etc.) information of the user
 *
 * @return {Object} getBillingStatus function returns result of the response
 */

getBillingData.$inject = ['promiseOperation', 'serviceMenu'];

function getBillingData(promiseOperation, serviceMenu) {
    var accessStatus = { billingStatus: 'ACTIVE'};

    return {
        getAccessStatus: getAccessStatus,
        setAccessStatus: setAccessStatus
    };

    /**
     * @ngdoc function
     * @param {string} status represents access rights of the user,
     * when status equals 'blocked' any user activity will be suppressed
     * @description sets the access status of the user
     */
    function setAccessStatus(status) {
        if(status) {
            accessStatus = status;
            serviceMenu.menuIsBlocked = status.billingStatus === 'BLOCKED' || status.billingStatus === 'HOLD';
            serviceMenu.showInfo = status.billingStatus === 'EXPIRED' || status.billingStatus === 'TRIAL';
        }
    }

    /**
     * @ngdoc function
     * @returns {string}
     */
    function getAccessStatus() {
        return accessStatus;
    }
}

export default {
    type: 'factory',
    name: 'getBillingData',
    value: getBillingData
};
