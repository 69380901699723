import angular from 'angular';

headControl.$inject = [
    "$scope",
    "factoryProfile",
    "serviceMenu",
    "buildConstant"
];

function headControl(
    $scope,
    factoryProfile,
    serviceMenu,
    buildConstant
) {
    const menu = serviceMenu.constantMenu;

    const setPaymentMethodTitle = () => factoryProfile.getProfile().then(
        ({ billingInfo }) => {
            if (billingInfo.paymentMethod) {
                $scope.title = "MENU_GROUP.EDIT_CARD_PAYMENT";
            } else {
                $scope.title = "MENU_GROUP.MANAGE_CARD_PAYMENT";
            }
        }
    );

	var states = {
		'myaccount': function () {
			$scope.title = 'MENU_GROUP.MY_ACCOUNT';
		},
		'employees.createemployee': function () {
			$scope.title = 'EMPLOYEE.TITLE_CREATE';
		},
		'employees.list': function () {
			$scope.title = 'MENU_GROUP.EMPLOYEES_LIST';
		},
		'employees.editemployee': function () {
			$scope.title = 'EMPLOYEE.TITLE_EDIT';
		},
		'employees.timecards': function () {
			$scope.title = 'MENU_GROUP.EMPLOYEES_TIMECARDS';
		},
		'clients.database': function(){
			$scope.title = 'MENU_GROUP.CLIENTS_BASE';
		},
		'clients.import': function(){
			$scope.title = 'MENU_GROUP.CLIENTS_BASE';
		},
		'clients.createcustomer': function(){
			$scope.title = 'MENU_GROUP.ADD_CUSTOMER';
		},
		'clients.editcustomer': function(){
			$scope.title = 'MENU_GROUP.EDIT_CUSTOMER';
		},
		'clients.customerdetail': function(){
			$scope.title = 'MENU_GROUP.CUSTOMER_PROFILE';
		},
		'goods.edit': function () {
			$scope.title = 'MENU_GROUP.GOODS_EDIT';
		},
		'settings.paytypes': function () {
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'paymenttypes.createpaymenttype': function () {
		$scope.title = 'MENU_GROUP.APPEND_PAYMENT_TYPE';
		},
		'inventory.purchase': function () {
		$scope.title = 'MENU_GROUP.PURCHASE_ORDERS';
		},
		'inventory.supplierlist': function () {
		$scope.title = 'MENU_GROUP.SUPPLIER_LIST';
		},
		'inventory.transfer': function () {
		$scope.title = 'MENU_GROUP.TRANSFER_LIST';
		},
		'inventory.productions': function () {
		$scope.title = 'MENU_GROUP.PRODUCTIONS_LIST';
		},
		'paymenttypes.editpaymenttype': function () {
		$scope.title = 'MENU_GROUP.EDIT_PAYMENT_TYPE';
		},
		'settings.loyalty': function(){
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'settings.account': function () {
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'addPayMethodStr': setPaymentMethodTitle,
		'addPayMethodBt': setPaymentMethodTitle,
		'editBillingDetails': function () {
			$scope.title = 'MENU_GROUP.EDIT_BILLING_DETAILS';
		},
		'accountblock': function () {
			$scope.title = 'MENU_GROUP.ACCOUNT_BLOCK';
		},
		'settings.outlet': function(){
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'createoutlet': function(){
			$scope.title = 'MENU_GROUP.CREATE_STORE';
		},
		'editoutlet': function(){
			$scope.title = 'MENU_GROUP.EDIT_STORE';
		},
		'settings.reciepts': function () {
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'settings.predefined': function () {
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'nostores': function () {
			$scope.title = 'MENU_GROUP.ACCOUNT_BLOCK';
		},

		// header in the page header when creating and editing TAXES
		'settings.tax': function(){
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'createtax': function(){
			$scope.title = 'SETTINGS.TAX.CREATE_TAX';
		},
		'edittax': function(){
			$scope.title = 'SETTINGS.TAX.EDIT_TAX';
		},
		//--END header in the page header when creating and editing TAXES


		// title in the page header when creating and editing KITCHEN PRINTERS
		'settings.kitchen': function(){
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'createkitchen': function(){
			$scope.title = 'KITCHEN.CREATE_KITCHEN_PRINTERS';
		},
		'editkitchen': function(){
			$scope.title = 'KITCHEN.EDIT_KITCHEN_PRINTERS';
		},
		//-- END title in the page header when creating and editing KITCHEN PRINTERS

		// title in the page header when creating and editing ORDER TYPES
		'settings.dining': function(){
			$scope.title = 'MENU_GROUP.SETTINGS';
		},
		'createdining': function(){
			$scope.title = 'DINING.NEW_DINING_OPTIONS';
		},
		'editdining': function(){
			$scope.title = 'DINING.EDIT_DINING_OPTIONS';
		},
		// END title in the page header when creating and editing ORDER TYPES

		// title in the page header when creating, editing and displaying the cash register list
		'settings.cashregister': function(){
			$scope.title = 'MULTISHOP.CASH_REGISTER.CASH_REGISTERS';
		},
		'createcashregister': function(){
			$scope.title = 'MULTISHOP.CASH_REGISTER.CREATE_CASH_REGISTER';
		},
		'editcashregister': function(){
			$scope.title = 'MULTISHOP.CASH_REGISTER.EDIT_CASH_REGISTER';
		},
		// END // title in the page header when creating, editing and displaying the cash register list

		'goods.create': function(){
			$scope.title = 'MENU_GROUP.GOODS_CREATE';
		},
		'goods.categorycreate': function(){
			$scope.title = 'MENU_GROUP.CATEGORY_CREATE';
		},
		'goods.categoryedit': function(){
			$scope.title = 'MENU_GROUP.CATEGORY_EDIT';
		},
		'goods.export': function(){
			$scope.title = 'MENU_GROUP.EXPORT';
		},
		'goods.import': function(){
			$scope.title = 'MENU_GROUP.IMPORT';
		},

		'employees.editpermits': function(){
			$scope.title = 'PERMITS.EDIT_GROUP';
		},
		'employees.createpermits': function(){
			$scope.title = 'PERMITS.CREATE_GROUP';
		},
		'employees.permitslist': function(){
			$scope.title = 'MENU_GROUP.EMPLOYEES_PERMITS';
		},
		'goods.discountcreate': function(){
			$scope.title = 'MENU_GROUP.DISCOUNT_CREATE';
		},
		'goods.discountedit': function(){
			$scope.title = 'MENU_GROUP.DISCOUNT_EDIT';
		},
		'goods.discountlist': function () {
			$scope.title = 'MENU_GROUP.DISCOUNTS';
		},
		'goods.modifiercreate': function(){
		$scope.title = 'MODIFIERS.CREATE_MODIFIER_STATE';
		},
		'goods.modifieredit': function(){
			$scope.title = 'MODIFIERS.EDIT_MODIFIER_STATE';
		},
		'goods.modifier-list': function(){
			$scope.title = 'MENU_GROUP.MODIFIERS';
		},
		'home': function () {
			$scope.title = '';
		},

		'vantiv.registration': function () {
			$scope.title = 'PAYMENT_TYPES.TERMINAL_SETTING';
		},
		'vantiv.terminal': function () {
			$scope.title = 'PAYMENT_TYPES.TERMINAL_SETTING';
		},
		'vantiv.notification': function () {
			$scope.title = 'PAYMENT_TYPES.TERMINAL_SETTING';
		},
		'vantiv.cashbox': function () {
			$scope.title = 'PAYMENT_TYPES.TERMINAL_SETTING';
		},

		'inventory.createorder': function () {
			$scope.title = 'MENU_GROUP.CREATE_ORDER';
		},
		'inventory.editorder': function () {
			$scope.title = 'MENU_GROUP.EDIT_ORDER';
		},
		'inventory.duplicateorder': function () {
			$scope.title = 'MENU_GROUP.CREATE_ORDER';
		},
		'inventory.orederdetail': function () {
			$scope.title = 'MENU_GROUP.ORDER_DETAIL';
		},
		'inventory.suppliercreate': function () {
			$scope.title = 'MENU_GROUP.CREATE_SUPPLIER';
		},
		'inventory.supplieredit': function () {
			$scope.title = 'MENU_GROUP.EDIT_SUPPLIER';
		},
		'inventory.receiveorder': function () {
			$scope.title = 'MENU_GROUP.RECEIVE_ITEMS';
		},
		'inventory.createtransfer': function () {
			$scope.title = 'MENU_GROUP.CREATE_TRANSFER';
		},
		'inventory.duplicatetransfer': function () {
			$scope.title = 'MENU_GROUP.CREATE_TRANSFER';
		},
		'inventory.edittransfer': function () {
			$scope.title = 'MENU_GROUP.EDIT_TRANSFER';
		},
		'inventory.transferdetail': function () {
			$scope.title = 'MENU_GROUP.TRANSFER_DETAILS';
		},
		'inventory.adjustment': function () {
			$scope.title = 'MENU_GROUP.ADJASTMENT_LIST';
		},
		'inventory.adjustdetails': function () {
			$scope.title = 'MENU_GROUP.ADJASTMENT_DETAILS';
		},
		'inventory.createadjustment': function () {
			$scope.title = 'MENU_GROUP.CREATE_ADJASTMENT';
		},
		'inventory.history': function () {
			$scope.title = 'MENU_GROUP.INVENTORY_HISTORY';
		},
		'inventory.valuation': function () {
			$scope.title = 'MENU_GROUP.INVENTORY_VALUATION';
		},
		'inventory.productiondetail': function () {
			$scope.title = 'MENU_GROUP.PROD_DETAILS';
		},
		'inventory.disassemblydetail': function () {
			$scope.title = 'MENU_GROUP.DISASS_DETAILS';
		},
		'goods.printlabels': function () {
			$scope.title = 'MENU_GROUP.PRINT_LABELS';
		},
		'goods.itemHistory': function () {
			$scope.title = 'MENU_GROUP.ITEM_HISTORY';
		},
		'inventory.createcount': function () {
			$scope.title = 'MENU_GROUP.CREATE_STOCKTAKE';
		},
		'inventory.editcount': function () {
			$scope.title = 'MENU_GROUP.EDIT_STOCKTAKE';
		},
		'inventory.inventCount': function () {
			$scope.title = 'MENU_GROUP.INVENTORY_COUNT';
		},
		'inventory.countstock': function () {
			$scope.title = 'MENU_GROUP.COUNT_STOCK';
		},
		'inventory.counts': function () {
			$scope.title = 'MENU_GROUP.INVENTORY_COUNTS';
		},
		'inventory.countdetail': function () {
			$scope.title = 'MENU_GROUP.COUNT_DETAILS';
		},
		'inventory.createproduction': function () {
			$scope.title = 'MENU_GROUP.CREATE_PRODUCTION';
		},
		'inventory.createdisassembly': function () {
			$scope.title = 'MENU_GROUP.CREATE_DISASSEMBLY';
		},
		'inventory.productionduplicate': function () {
			$scope.title = 'MENU_GROUP.CREATE_PRODUCTION';
		},
		'inventory.disassemblyduplicate': function () {
			$scope.title = 'MENU_GROUP.CREATE_DISASSEMBLY';
		},
		'inventory.management': function () {
			$scope.title = 'MENU_GROUP.INVENTORY';
		},
		// 'inventory.invmanagement': function () {
		// 	$scope.title = 'MENU_GROUP.INVENTORY';
		// },
		'inventory.printlabels': function () {
			$scope.title = 'MENU_GROUP.PRINT_LABELS';
		},
		'integrations.tokens': function () {
			$scope.title = 'MENU_GROUP.ACCESS_TOKENS';
		},
		'integrations.createToken': function () {
			$scope.title = 'MENU_GROUP.CREATE_TOKENS';
		},
		'integrations.editToken': function () {
			$scope.title = 'MENU_GROUP.EDIT_TOKEN';
		},
		'integrations.tokenDetail': function () {
			$scope.title = 'MENU_GROUP.TOKENS_DETAILS';
		},
		'integrations.apps': function () {
			$scope.title = 'MENU_GROUP.APPS_LIST';
		},
		'integrations.appDetails': function () {
			$scope.title = 'MENU_GROUP.APP_DETAILS';
		},
		'integrations.management': function () {
			$scope.title = 'MENU_GROUP.ACCESS_TOKENS_INT';
		},
		'webhookslist': function () {
			$scope.title = 'MENU_GROUP.WEBHOOKS';
		},
		'createwhook': function () {
			$scope.title = 'MENU_GROUP.CREATE_WEBHOOK';
		},
		'whookdetails': function () {
			$scope.title = 'MENU_GROUP.WEBHOOK_DETAIL';
		},
		'editwhook': function () {
			$scope.title = 'MENU_GROUP.EDIT_WEBHOOK';
		},
		'inventory.purchaseImport': function(){
			$scope.title = 'MENU_GROUP.IMPORT';
		},
		'inventory.transferImport': function(){
			$scope.title = 'MENU_GROUP.IMPORT';
		}
	};

	$scope.showBuildDate = buildConstant.isDev;
	if ($scope.showBuildDate) {
		$scope.buildDate = buildConstant.buildDate;
	}

	$scope.$watch( 'params.currentState', function ( val ) {
		if(val){
			if (typeof states[val.name] !== 'function') {
				//throw new Error('Invalid action.');
				$scope.title ='MENU_GROUP.'+ getName( menu, val.name );
			} else{
				states[val.name]();
			}
		}
	} );

	function normalName( name ) {
		var arr = name.split( '.' );
		var text = '';
		if ( 2 < arr.length ) {
			text = arr[0] + '.' + arr[1];
			return text;
		}
		return name;

	}

	function getName( _menu, name ) {
		var _res;
		(function _getName( _menu, name ) {
			for ( var i = 0; i < _menu.length; i++ ) {
				if ( _menu[i].name === normalName( name ) ) {
					_res = _menu[i].key
				} else if ( _menu[i].children && _menu[i].children.length ) {
					_getName( _menu[i].children, name )
				}
			}
		}( _menu, name ));
		return _res;
	}

	var overlay = document.getElementsByClassName('droverOverlay')[0];
	$scope.boardClick = function () {
		if ( serviceMenu.navBoard === 'nav-close' ) {
			serviceMenu.navBoard = 'nav-open';
			if(window.innerWidth < 601) {
				overlay.style.display = 'block';
			}
		} else {
			serviceMenu.navBoard = 'nav-close';
			if(window.innerWidth < 601) {
				overlay.style.display = 'none';
			}
		}
	};

	var layOverlay = angular.element(document.querySelector('.droverOverlay'));
	layOverlay.on('click', function () {
		serviceMenu.navBoard = 'nav-close';
		overlay.style.display = 'none';
	});


	var _clientY = null; // remember Y position on touch start

	overlay.addEventListener('touchstart', function (event) {

		if (event.targetTouches.length === 1) {
			_clientY = event.targetTouches[0].clientY;
		}

	}, false);

	overlay.addEventListener('touchmove', function (event) {

		if (event.targetTouches.length === 1) {
			disableRubberBand(event);
		}

	}, false);

	function disableRubberBand(event) {

		var clientY = event.targetTouches[0].clientY - _clientY;

		if (overlay.scrollTop === 0 && clientY > 0) {
			event.preventDefault();
		}

		if (isOverlayTotallyScrolled() && clientY < 0) {
			event.preventDefault();
		}

	}

	function isOverlayTotallyScrolled() {
		return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
	}

}

export default {
	type: 'controller',
	name: 'headControl',
	value: headControl
};
