/**
 * Created by olehkss on 20.07.16.
 * Edited by kseniya.yarosh on 07.12.2016
 *
 * @ngdoc factory
 * @name app.factory: categoriesSummary
 * @description
 * Requests categories sales report from server
 * @requires promiseOperation
 */

import angular from 'angular'

categoriesSummary.$inject = ['promiseOperation', 'serviceReportsError'];

function categoriesSummary(promiseOperation, serviceReportsError) {
    return {
        getCategoriesSummary: getCategoriesSummary
    };

    /**
     * @ngdoc function
     * @param params http request parameters
     * @returns {Promise}
     */
    function getCategoriesSummary(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getcategoriesreport',
            params,
            success,
            serviceReportsError.reportsError
        );
    }

    /**
     * @ngdoc function
     * @param response information from server
     * @returns {{data: [], pages: number}}
     */
    function success(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                let categoriesData = {
                    periodAllowed: response.data.periodAllowed,
                    outlets: response.data.fillSelectData.outlets,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    startDateString: response.data.startDateString,
                    endDateString: response.data.endDateString
                }
                if (response.data.periodAllowed) {
                    categoriesData.data = response.data.categories;
                    categoriesData.pages = response.data.pages;
                }

                return categoriesData;
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'categoriesSummary',
    value: categoriesSummary
};
