/**
 * Created by Shestakov on 14.11.19
 */

serviceShareReceipt.$inject = ['getReceipts', 'shareReceipt', 'serviceDialog', '$filter', '$mdSidenav', '$timeout', 'lvMixpanel'];

function serviceShareReceipt(getReceipts, shareReceipt, serviceDialog, $filter, $mdSidenav, $timeout, lvMixpanel) {

    this.receiptAddStatusCancelled = receiptAddStatusCancelled;
    this.cancelReceipt = cancelReceipt;
    this.selectReceipt = selectReceipt;

    var self = this;

    self.cancelingReceipt = {
        cancelingProcessStarted: false
    };
    var translate = $filter('translate');

    /*Task #21099 Отмена оплаченных чеков в бэк-офисе*/
    function receiptAddStatusCancelled(receipt, currencyOptionsShow, percentShowTax,
                                       receiptFormat, accessCancelReceipts, arapro) {
        /*Если это чек продажи, вначале необходимо проверить, есть ли у него возвраты.*/

        updateCancelingFlag(true);
        if (receipt.type === "SALE"){
            getReceipts.getRefundReceiptsInfo({receiptId: receipt.receiptId}).then( function(result) {

                //Предупреждения об ошибке для чека продажи, по которому есть возврат:
                if (result.result === "ok" && result.receiptsInfo.length){
                    warningReceiptsInfo(result);

                } else {
                    /*Если у чека продажи нет возвратов, или это чек возврата, нужно проверить, оплачен ли он
                     одной из интегрированных платежных систем,и есть ли в нем товары, у которых включен учет остатков.*/
                    getReceipts.getReceiptInfoForCancelMessage({receiptId: receipt.receiptId}).then( function(result) {
                        if (result.result === "ok"){
                            /*hasItemsWithCountStock Возвращает true, если в чеке есть товары, у которых
                       включен учет остатков, либо составные товары;
                       paidWithIntegratedSystem Возвращает true, если чек оплачен одним из интегрированных типов оплаты*/
                            var paidWithIntegratedSystem = result.paidWithIntegratedSystem ? translate('RECIEPT.CANCEL_RECEIPT_S1') : "";
                            var checkWillNotBeTaken = result.paidWithIntegratedSystem || result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_S2') : "";
                            var hasItemsWithCountStock = result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_S3') : "";
                            var sureText = result.paidWithIntegratedSystem || result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_SURE') : "";
                            var conformText = !result.paidWithIntegratedSystem && !result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_CONFORM') : "";

                            receiptIntegrateOrItemsStock(receipt, currencyOptionsShow, percentShowTax,
                                receiptFormat, accessCancelReceipts, arapro, paidWithIntegratedSystem, checkWillNotBeTaken,
                                hasItemsWithCountStock, sureText, conformText);
                        }


                    });
                    /* end Если у чека продажи нет возвратов, или это чек возврата, нужно проверить, оплачен ли он
                    одной из интегрированных платежных систем,и есть ли в нем товары, у которых включен учет остатков.*/

                }
                // end Предупреждения об ошибке для чека продажи, по которому есть возврат:
            });
        } else {
            /*чек возврата, нужно проверить, оплачен ли он одной из интегрированных платежных систем,
            и есть ли в нем товары, у которых включен учет остатков*/
            getReceipts.getReceiptInfoForCancelMessage({receiptId: receipt.receiptId}).then( function(result) {
                if (result.result === "ok"){
                    /*hasItemsWithCountStock Возвращает true, если в чеке есть товары, у которых включен учет остатков, либо составные товары;
                paidWithIntegratedSystem Возвращает true, если чек оплачен одним из интегрированных типов оплаты*/
                    var paidWithIntegratedSystem = result.paidWithIntegratedSystem ? translate('RECIEPT.CANCEL_RECEIPT_R1') : "";
                    var checkWillNotBeTaken = result.paidWithIntegratedSystem || result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_R2') : "";
                    var hasItemsWithCountStock = result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_R3') : "";
                    var sureText = result.paidWithIntegratedSystem || result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_SURE') : "";
                    var conformText = !result.paidWithIntegratedSystem && !result.hasItemsWithCountStock ? translate('RECIEPT.CANCEL_RECEIPT_CONFORM') : "";

                    receiptIntegrateOrItemsStock(receipt, currencyOptionsShow, percentShowTax,
                        receiptFormat, accessCancelReceipts, arapro, paidWithIntegratedSystem,
                        checkWillNotBeTaken, hasItemsWithCountStock, sureText, conformText);
                }

            });
            /*чек возврата, нужно проверить, оплачен ли он одной из интегрированных платежных систем,
             и есть ли в нем товары, у которых включен учет остатков*/

        }

    }

    function warningReceiptsInfo(result) {
        var attentionText = result.receiptsInfo.length === 1 ? translate('RECIEPT.CANCEL_RECEIPT_ATTENTION') : translate('RECIEPT.CANCEL_RECEIPT_ATTENTIONS');
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-cancel-receipt.html',
            title: translate('RECIEPT.CANCEL_RECEIPT'),
            attention:  attentionText,
            checknumber: result.receiptsInfo,
            buttons: [
                {
                    text: 'ON_OK',
                    action: function () {
                        updateCancelingFlag(false);
                    }
                }
            ]
        });

    }


    function receiptIntegrateOrItemsStock(receipt, currencyOptionsShow, percentShowTax,
                                          receiptFormat, accessCancelReceipts, arapro,
                                          paidWithIntegratedSystem, checkWillNotBeTaken, hasItemsWithCountStock,
                                          sureText, conformText) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-cancel-receipt.html',
            title: translate('RECIEPT.CANCEL_RECEIPT'),
            contentfirstline: paidWithIntegratedSystem,
            contentsecondline: checkWillNotBeTaken,
            contentthirdline: hasItemsWithCountStock,
            sure: sureText,
            conform: conformText,
            buttons: [
                {
                    text: 'PAYMENT_TYPES.NO',
                    action: function () {
                        updateCancelingFlag(false);
                    }
                },
                {
                    class: 'primary',
                    text: 'PAYMENT_TYPES.YES',
                    action: function () {
                        $timeout(function () {
                            cancelReceipt(receipt, currencyOptionsShow, percentShowTax,
                                receiptFormat, accessCancelReceipts, arapro);
                        }, 100);
                    }
                },
            ]
        });
    }


    function cancelReceipt(receipt, currencyOptionsShow, percentShowTax,
                           receiptFormat, accessCancelReceipts, arapro) {
        getReceipts.cancelReceipt({receiptId: receipt.receiptId})
            .then(function (result) {
                updateCancelingFlag(false);
                if(result.result === "ok") {
                    if(result.cancelSuccess) {
                        lvMixpanel.track('Other activity', {"Property": "Cancel receipt"});

                        shareReceipt.setCancelled(result.cancelled);
                        shareReceipt.setMerchantCancelled(result.merchantCancelled);

                        receipt.cancelled = shareReceipt.getCancelled();
                        receipt.merchantCancelled = shareReceipt.getMerchantCancelled();
                        selectReceipt(receipt, currencyOptionsShow, percentShowTax,
                            receiptFormat, accessCancelReceipts, arapro);
                    } else {
                        warningReceiptsInfo(result);
                    }
                }
            })
    }


    function selectReceipt(receipt, currencyOptionsShow, percentShowTax,
                           receiptFormat, accessCancelReceipts, arapro) {
        if (receipt.diningOption != null && receipt.diningOption.name == null) {
            receipt.diningOption.name = translate("DINING." + receipt.diningOption.type);
        }

        if (!arapro) {
            $mdSidenav('right').open();
        } else {
            $mdSidenav('left').open();
        }

        shareReceipt.setReceipt(receipt);
        shareReceipt.setCurrencyShow(currencyOptionsShow);
        shareReceipt.setPercentShowTax(percentShowTax);
        shareReceipt.setReceiptFormat(receiptFormat);
        shareReceipt.setAccessCancelReceipts(accessCancelReceipts);
    }

    function updateCancelingFlag(flag) {
        self.cancelingReceipt.cancelingProcessStarted = flag;
    }

}

export default {
    type: 'service',
    name: 'serviceShareReceipt',
    value: serviceShareReceipt
};
