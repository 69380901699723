/**
 * Created by Kiskenbassker on 18.02.18
 */

printLabels.$inject = ['$filter'];
function printLabels($filter) {

    this.labelsTypes = function () {
        var millimetres = $filter('translate')('MILLIMETRES'),
            averySheet = $filter('translate')('INVENTORY.PRINT_LABELS.AVERY_SHEET'),
            a4Sheet = $filter('translate')('INVENTORY.PRINT_LABELS.A4_SHEET');

        return [
            {
                id: "DYMO_34_X2_12",
                name: 'Dymo - 3/4\u2033 \u00D7 2-1/2\u2033 (19 ' + millimetres + ' \u00D7 64 ' + millimetres + ')'
            },
            {
                id: "DYMO_1_X2_18",
                name: 'Dymo - 1\u2033 \u00D7 2-1/8\u2033 (25 ' + millimetres + ' \u00D7 54 ' + millimetres + ')'
            },
            {
                id: "DYMO_1_18X3_12",
                name: 'Dymo - 1-1/8\u2033 \u00D7 3-1/2\u2033 (28 ' + millimetres + ' \u00D7 89 ' + millimetres + ')'
            },
            {
                id: "ZEBRA_1_14X1",
                name: 'Zebra - 1-1/4\u2033 \u00D7 1\u2033 (32 ' + millimetres + ' \u00D7 25 ' + millimetres + ')'
            },
            {
                id: "ZEBRA_2X1",
                name: 'Zebra - 2\u2033 \u00D7 1\u2033 (51 ' + millimetres + ' \u00D7 25 ' + millimetres + ')'
            },
            {
                id: "ZEBRA_2_14X1_14",
                name: 'Zebra - 2-1/4\u2033 \u00D7 1-1/4\u2033 (57 ' + millimetres + ' \u00D7 32 ' + millimetres + ')'
            },
            {
                id: "AVERY_23X1_34",
                name: averySheet + ' - 2/3\u2033 \u00D7 1-3/4\u2033 (17 ' + millimetres + ' \u00D7 44 ' + millimetres + ')'
            },
            {
                id: "AVERY_1X2_58",
                name: averySheet + ' - 1\u2033 \u00D7 2-5/8\u2033 (25 ' + millimetres + ' \u00D7 67 ' + millimetres + ')'
            },
            {
                id: "AVERY_1X4",
                name: averySheet + ' - 1\u2033 \u00D7 4\u2033 (25 ' + millimetres + ' \u00D7 102 ' + millimetres + ')'
            },
            {
                id: "A4_525X297",
                name: a4Sheet + ' - 52,5 ' + millimetres + ' \u00D7 29,7 ' + millimetres
            },
            {
                id: "A4_70X297",
                name: a4Sheet + ' - 70 ' + millimetres + ' \u00D7 29,7 ' + millimetres
            },
            {
                id: "A4_70X37",
                name: a4Sheet + ' - 70 ' + millimetres + ' \u00D7 37 ' + millimetres
            }
        ]

    }
}

export default {
    type: 'service',
    name: 'printLabels',
    value: printLabels
};