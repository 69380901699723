/**
 * Created by A.Sirosh on 14.12.2015.
 * Edited by K.Yarosh on 02.05.2017
 */

import angular from 'angular'

supplierListContr.$inject = [
    '$scope',
    '$state',
    'factoryPermits',
    '$filter',
    '$stateParams',
    '$supplierslistData',
    'serviceDialog',
    'serviceToast',
    'siteLangCode',
    '$profile',
    'factorySuppliers'
];

function supplierListContr(
    $scope,
    $state,
    factoryPermits,
    $filter,
    $stateParams,
    $supplierslistData,
    serviceDialog,
    serviceToast,
    siteLangCode,
    $profile,
    factorySuppliers
    ) {
    $scope.supplierlist = {};
    $scope.selectedSuppliers = [];
    $scope.supplierlist._select = false;
    $scope.showDel = false;
    $scope.suppliersData = angular.copy($supplierslistData);
    $scope.translate = $filter('translate');
    $scope.editTableState = 'inventory.supplieredit';

    //Task # 19957 Change stubs on Advanced inventory screens
    var vm = this;
    vm.plugsSearch = !!$stateParams.search;

    //Plugs for extended inventory accounting Suppliers
    $scope.profile = angular.copy( $profile );

    var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];

    var plugSuppliers = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];
    $scope.plugSuppliersHelpLink = plugSuppliers.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders"
        : "https://help.loyverse.com/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders";


    // $scope.changeItem = changeItem;
    $scope.pages = $scope.suppliersData.pages;

    $scope.suppliers = $scope.suppliersData.suppliers;
    if ($stateParams.checkFromList.length) {
        $scope.selectedSuppliers = $stateParams.checkFromList;
        $scope.showDel = true;
        if ($scope.selectedSuppliers.length === $scope.suppliers.length) {
            $scope.supplierlist._select = true;
        }
    }


    $scope.onAdd = function() {
        $state.go('inventory.suppliercreate', {stateToSave: $scope.stateFromList, checkToSave: $scope.selectedSuppliers});
    }


    $scope.changeAll = function () {
        if ($scope.supplierlist._select === true) {
            for (var k = 0; k < $scope.suppliers.length; k++) {
                $scope.suppliers[k]["_select"] = true;
                $scope.selectedSuppliers.push($scope.suppliers[k].id);
            }
            // $scope.supplierlist._select = true;
            $scope.showDel = true;


        } else {
            //$scope.supplierlist._select = false;
            for (var m = 0; m < $scope.suppliers.length; m++) {
                $scope.suppliers[m]["_select"] = false;
                for ( var a = 0;a < $scope.selectedSuppliers.length; a++) {
                    if($scope.suppliers[m].id === $scope.selectedSuppliers[a]) {
                        $scope.selectedSuppliers.splice(a,1);
                        a--;
                    }
                }
            }
            $scope.showDel = false;
        }
    };



    $scope.changeItem = function(supplier) {
        if (supplier._select) {
            $scope.selectedSuppliers.push(supplier.id);
            $scope.showDel = true;

            if ($scope.selectedSuppliers.length === $scope.suppliers.length) {
                $scope.supplierlist._select = true;
            }
        } else {
            $scope.selectedSuppliers = $scope.selectedSuppliers.filter(function (selectedSuppliers) {
                return supplier.id !== selectedSuppliers;
            });

            if ($scope.supplierlist._select) $scope.supplierlist._select = false;

            if (!$scope.selectedSuppliers.length)
                $scope.showDel = false;
        }
    }

// данные для сохранение состояния фильтров и ЧЕКБОКСОВ
    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;
    // console.log("stateParams = ", $stateParams);


// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ поставщиков ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if ($scope.suppliers.length == 0 && $scope.stateFromList.page > 0) {
        $state.go( 'inventory.supplierlist', {page: $scope.pages - 1} );
    }
// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ поставщиков ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


    if ($scope.checkFromList.length == undefined || $scope.checkFromList.length == 0) {
        for (var i = 0; i < $scope.suppliers.length; i++) {
            $scope.suppliers[i]._select = false;
        }
    } else {
        for (var i = 0; i < $scope.checkFromList.length; i++) {

            for (var q = 0; q < $scope.suppliers.length; q++) {
                ($scope.checkFromList[i] == $scope.suppliers[q].id)? $scope.suppliers[q]._select = true : "";
                (!$scope.suppliers[q]._select)? $scope.suppliers[q]._select = false: "";
            }
        }
        // console.log("$scope.suppliers =", $scope.suppliers);
    }
// данные для сохранение состояния фильтров и ЧЕКБОКСОВ

    $scope.onDel = function() {
        var deleteTitle = ($scope.selectedSuppliers.length > 1) ? 'INVENTORY.DELETE_SUPPLIERS_TITLE' : 'INVENTORY.DELETE_SUPPLIER_TITLE',
            deleteInfo = 'INVENTORY.DELETE_SUPPLIERS_TEXT';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   $scope.translate(deleteTitle),
            content:   $scope.translate(deleteInfo),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: deleteSuppliers
                }
            ]
        });
    }

    function deleteSuppliers() {

        // var selectedSupplierIds = $scope.selectedSuppliers.map(function (supplier) {
        //     return supplier.id;
        // }),
        var deleteMessage = ($scope.selectedSuppliers.length > 1) ? 'INVENTORY.DELETE_SUPPLIERS' : 'INVENTORY.DELETE_SUPPLIER';

        factorySuppliers.deleteSupplier({
            id: $scope.selectedSuppliers
        }).then(function (result) {

            if (result === "ok") {
                serviceToast.show(deleteMessage, 'success');
                $state.go($state.current.name, {page:           $scope.stateFromList.page,
                                                limit:          $scope.stateFromList.limit,
                                                checkFromList:  []}, {
                    reload: true
                });
            }
        });
    }
}

export default {
    type: 'controller',
    name: 'supplierListContr',
    value: supplierListContr
};