/**
 * Created by yarosh on 16.11.17.
 * @memberof createEditVariantController
 * @ngdoc controller
 */

import angular from 'angular';

createEditVariantControl.$inject = [
    '$scope',
    'shareScope',
    '$state',
    '$stateParams',
    'factoryGoods',
    'getCombinationsOfMultidimensionalArrValues',
    'arrayOperation',
    'serviceToast',
    'serviceDialog',
    '$filter',
    '$rootScope',
    'factoryValidationFormat',
    '$mdConstant',
    'currencyFormat',
    '$currencyOptionsEdit',
    '$countOptionsEdit',
    '$critCountOptionsEdit',
    '$quantOptionsEdit',
    '$countOptionsEditDivisible',
    'serviceMinMaxValues',
    '$mdUtil',
    '$permitsManager',
    'factoryPopover',
    '$window'
];

function createEditVariantControl(
    $scope,
    shareScope,
    $state,
    $stateParams,
    factoryGoods,
    getCombinationsOfMultidimensionalArrValues,
    arrayOperation,
    serviceToast,
    serviceDialog,
    $filter,
    $rootScope,
    factoryValidationFormat,
    $mdConstant,
    currencyFormat,
    $currencyOptionsEdit,
    $countOptionsEdit,
    $critCountOptionsEdit,
    $quantOptionsEdit,
    $countOptionsEditDivisible,
    serviceMinMaxValues,
    $mdUtil,
    $permitsManager,
    factoryPopover,
    $window
) {    
    let translate = $filter('translate'),
        currencyToCoin = $filter( 'currencyToCoin' ),
        coinToCurrency = $filter( 'coinToCurrency' ),
        wareCount = $filter( 'wareCount' ),
        filterRepairCurrenFormatWhenFocus = $filter( 'filterRepairCurrenFormatWhenFocus' ),
        emptyOption = {},
        copyOfVariationsArr = {},
        copyOfOutletsWithVariantsArr,
        initialVariantsByOutlets,
        vmGoodsListCtrl = $scope.vmGoodsListCtrl,
        initialOptionsBeforeEditingOptions,
        initialVariantsBeforeEditingOptions,
        initialByOutletsBeforeEditingOptions,
        initialGeneratedOptionValuesArrayBeforeEditingOptions = [],
        idGenerator = 0,
        articlesScore,
        editVariantWindow,
        variantBeforeEdit,
        currencyOptionsEdit = $currencyOptionsEdit;

    emptyOption.name = '';
    emptyOption.values = [];
    copyOfVariationsArr.options = [];
    copyOfVariationsArr.variants = [];

    $scope.costAllowed = $permitsManager.costAllowed;

    //!!!!!!!!!!! Don't wrap this scope into a variable, otherwise it will stop to interact with createEditWareControl!!!!!!!!!!!

    $scope.$on('handleBroadCast', function(){
        $scope = shareScope.sharedScope;
    });

    $scope.forms = {};

    $scope.addVariants = addVariants;
    $scope.editVariants = editVariants;
    $scope.delVariantOption = delVariantOption;
    $scope.addVariantOption = addVariantOption;
    $scope.checkEmptyOptionValues = checkEmptyOptionValues;
    $scope.checkChipsLimit = checkChipsLimit;
    $scope.viewAllVariants = viewAllVariants;
    $scope.compareArticles = compareArticles;
    $scope.filterStoresAndVariants = filterStoresAndVariants;
    $scope.delVariant = delVariant;
    $scope.checkWhetherVariantOptionValExists = checkWhetherVariantOptionValExists;
    $scope.editVariant = editVariant;
    $scope.blockFromBeingAppended = blockFromBeingAppended;
    $scope.upDateVariantOutletSalePriceOnTheFly = upDateVariantOutletSalePriceOnTheFly;
    $scope.unblockAfterVariantExistsError = unblockAfterVariantExistsError;
    $scope.transformChip = transformChip;
    $scope.compareWithExistingVariants = compareWithExistingVariants;
    $scope.checkIfChanged = checkIfChanged;
    $scope.consoleMe = consoleMe;
    $scope.unblockAfterSkuExistsError = unblockAfterSkuExistsError;


    $scope.noVariationsFromTheStart = false;
    $scope.generatedVariantOptions = [];
    $scope.generatedOptionValuesArray = [];
    $scope.generatedOptionStringValuesArray = [];
    $scope.formRequiredError = {};
    $scope.maximumVariantsExceeded = false;
    $scope.closeOnAction = false;
    $scope.showAllVariants = false;
    $scope.showVariantsLine = 5; // show 5 variants by default
    $scope.variantOrderNo = 0;
    $scope.outletOrderNo = 0;
    $scope.showOutletsWithVariants = 5;
    $scope.askedArticles = [];

    $scope.variantArticle = null;
    $scope.outletId = null;
    $scope.variantOptionValueExists = false;
    $scope.preEditedValues = [];
    $scope.containerForChangedArticles = [];
    $scope.deletedVariants = [];
    $scope.variantsHover;
    $scope.factoryValidationFormat = factoryValidationFormat;
    var tab = 9;
    $scope.keys = [$mdConstant.KEY_CODE.ENTER, tab];
    $scope.validateOnExistingVariants = false;
    $scope.skuAlreadyExists = false;
    $scope.chackSkuBeforeSave = false;
    $scope.dontClose = false;
    $scope.openDialog = false;


    $scope.articlesGetter = function() {
        return $scope.ware.variations.variants.map(variant => variant.article);
    }


    if(($state.current.name === 'goods.create') || ($state.current.name === 'goods.edit' && (!$scope.ware.variations || $scope.ware.variations.variants.length < 1 || $scope.ware.variations.variants === null))) {

        $scope.noVariationsFromTheStart = true;
        $scope.allowEditChipValue = true;

        if ( !$scope.ware ) {
            $scope.ware = {};
        }

        if (!$scope.ware.variations)
            $scope.ware.variations = {};
        $scope.ware.variations.options = [];
        $scope.ware.variations.variants = [];

        $scope.sortableOptions = {
            handle: '.grab-handle',
            animation: 50,
            forceFallback: true,
            onUpdate: function (evt) {
                var i = 0,
                    length = evt.models.length;

                $scope.generatedVariantOptions = [];

                for(i; i<length; i++){
                    $scope.generatedVariantOptions[i] = evt.models[i];
                    $scope.generatedVariantOptions[i].currentIndex = Object.keys(evt.models)[i];
                }

                return $scope.generatedVariantOptions;
            }
        }

    } else if(($state.current.name === 'goods.edit' && ($scope.ware.variations && $scope.ware.variations.variants.length > 0)) || $state.current.name === 'goods.price'){

        $scope.allowEditChipValue = false;

        $scope.viewLine = ($scope.ware.variations.variants.length > 5); //show line to handle variants if their length is more than five at least

        $scope.viewMore = translate('VIEW_MORE');
        $scope.viewLess = translate('VIEW_LESS');
        $scope.viewLineAllOutlets = (($scope.ware.variations.variants.length * $scope.outlets.length) > $scope.showLine);

        $scope.ware.variations.variants.forEach(function(variant){
            variant.salePrice = (variant.salePrice !== null && variant.salePrice !== "") ? serviceMinMaxValues.getValidValue(coinToCurrency( variant.salePrice, '.' ), currencyOptionsEdit) : null;
            variant.primeCost = variant.primeCost ? serviceMinMaxValues.getValidValue(coinToCurrency( variant.primeCost, '.' ), currencyOptionsEdit) : '000';
            variant.purchaseCost = variant.purchaseCost ? coinToCurrency( variant.purchaseCost, '.' ) : null;

            if($scope.outlets.length === 1){
                $scope.ware.byOutlets.forEach(function(varOutlet){
                    if(varOutlet.variantArticle === variant.article){
                        variant.enable = varOutlet.enable;
                    }
                })

            }
        });

        copyOfVariationsArr.variants = $scope.ware.variations.variants;
        copyOfVariationsArr.options = $scope.ware.variations.options;

        $scope._variantsByOutlets = angular.copy($scope.ware.byOutlets);
        $scope._variantsByOutlets.forEach(function(outlet){
            outlet.values = outlet.variantValues;
            outlet.salePrice = (outlet.salePrice !== null && outlet.salePrice !== "") ? serviceMinMaxValues.getValidValue(coinToCurrency(outlet.salePrice, '.'), currencyOptionsEdit) : null;
            outlet.count = outlet.count ? wareCount( outlet.count, '.', false ) : 0;
            outlet.criticalCount = outlet.criticalCount !== null ? wareCount( outlet.criticalCount, '.', false ) : null;
            outlet.idealStock = outlet.idealStock !== null ? wareCount( outlet.idealStock, '.', false ) : null;
        });


        copyOfOutletsWithVariantsArr = $scope._variantsByOutlets;
        initialVariantsByOutlets = angular.copy($scope._variantsByOutlets);

        $scope.ware._outlets = $scope._variantsByOutlets;

        $scope.generatedVariantOptions = $scope.ware.variations.options;

        $scope.ware.variations.options = ($scope.ware.variations.options !== null) ? $scope.ware.variations.options : [];
        $scope.ware.variations.variants = ($scope.ware.variations.variants !== null) ? $scope.ware.variations.variants : [];

        variantFromScratch();
        sortVariants();

    }

    $scope.onGrey = function (index) {
        $scope.dataHover = index;
    };

    $scope.offGrey = function () {
        $scope.dataHover = NaN;
    };

    $scope.getPlaceholder = function(index) {
        const placeholder = [
            'VARIATIONS.OPTION_NAME_PLACEHOLDER_1',
            'VARIATIONS.OPTION_NAME_PLACEHOLDER_2',
            'VARIATIONS.OPTION_NAME_PLACEHOLDER_3'
        ][index % 3];
        return translate(placeholder);
    }

    $scope.outletsLength = $scope.outlets.length;

    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true,
        disabled: $state.$current.name === "goods.edit" && !$scope.noVariationsFromTheStart,
        onUpdate: function (evt) {
            var i = 0,
                length = evt.models.length;

            $scope.generatedVariantOptions = [];

            for(i; i<length; i++){
                $scope.generatedVariantOptions[i] = evt.models[i];
                $scope.generatedVariantOptions[i].currentIndex = Object.keys(evt.models)[i];
            }

            return $scope.generatedVariantOptions;
        }
    };

    $scope.$watch('generatedVariantOptions', function(newVal, oldVal){
        var currentConcatenated = [],
            currentCombinations;
        newVal.forEach(function(obj){
            if(obj instanceof Object) {
                currentConcatenated.push(newVal.values);
            }
        });

        currentCombinations = [];

        // currentCombinations = getCombinationsOfMultidimensionalArrValues.getCombinations(currentConcatenated);

        $scope.maximumVariantsExceeded = currentCombinations.length > 200;

        // $rootScope.$emit("maximumVariantsExceeded", $scope.maximumVariantsExceeded);
        $rootScope.$broadcast("maximumVariantsExceeded", $scope.maximumVariantsExceeded);

        $scope.$on('maximumVariantsExceeded', function(data){
            $scope.maximumVariantsExceeded = data;
        });
    });

    //this function is used solely for test to observe the behavior in a model;

    function consoleMe() {
        console.log("Have you called me?");
    }

    function unblockAfterVariantExistsError () {
        $scope.variantAlreadyExists = false;
    }

    function unblockAfterSkuExistsError () {
        if ($scope.skuAlreadyExists === true) {
            $scope.skuAlreadyExists = false;
        } else {
            $scope.chackSkuBeforeSave = true;
        }
    }

    /**
     * Return the proper object when the append is called.
     */
    function transformChip(chip) {
        // If it is an object, it's already a known chip
        if (angular.isObject(chip)) {
            return chip;
        }

        // Otherwise, create a new one
        return {
            name: chip,
            type: 'new'
        }
    }


    //include variants into each outlet object if it is multishop and if there are variants

    // if($scope.outlets.length > 1 && $scope.ware.variations.variants.length > 0){


    function variantFromScratch () {

        $scope.singleVariant = {};
        $scope.singleVariant.variant = {};
        $scope.singleVariant.variant.article = '';
        $scope.singleVariant.variant.barcode = '';
        $scope.singleVariant.variant.salePrice = '';
        $scope.singleVariant.variant.primeCost = '';
        $scope.singleVariant.variant.freePrice = ($scope.singleVariant.salePrice === '' || $scope.singleVariant.salePrice === null );
        $scope.singleVariant.variant.allowedForAllOutlets = false;
        $scope.singleVariant.variant.values = [];

        $scope.singleVariant.options = $scope.ware.variations.options.map(function(option){
            var currentOption = {};
            currentOption.id = idGenerator +=1;
            currentOption.name = option.name;
            currentOption.values = null;
            return currentOption;
        });

        $scope.singleVariant.variantsByOutlets = angular.copy($scope.outlets);
        $scope.singleVariant.variantsByOutlets.forEach(function(outlet){
            outlet.outletName = outlet.name;
            outlet.salePrice = null;
            outlet.count = 0;
            outlet.criticalCount = null;
            outlet.variantArticle = $scope.singleVariant.variant.article;
            outlet.variantValues = $scope.singleVariant.variant.values;
            outlet.enable = false;
            outlet.freePrice = ($scope.singleVariant.variant.salePrice === null || $scope.singleVariant.variant.salePrice === "");

        })

    }

    function viewAllVariants (boolean) {
        $scope.showAllVariants = boolean;
        if(boolean === true){
            $scope.showVariantsLine = $scope.ware.variations.variants.length;
        } else {
            $scope.showVariantsLine = 5;
        }
    }

    function compareArticles (variant, index) {
        var currentChangedArticle = {};
        // console.log(variantArticle);
        // console.log(oldVariantArticle);

        if(variant.oldVariantArticle !== variant.article){

            //assign to an article, which name is its old value, new current value for searches and further reassignment in an array _variantsByOutlets;
            var variantName = "";
            variant.values.forEach(function (value) {
                    if (variantName === "")
                        variantName = "" + value;
                    else
                        variantName = variantName + "/" + value;
                }
            );
            currentChangedArticle[variantName] = variant.article;

            $scope.containerForChangedArticles.some(function(obj, index2){
                // console.log(obj);
                var objProperty = Object.getOwnPropertyNames(obj);
                if(obj[objProperty] === variantName){
                    // console.log(obj);
                    currentChangedArticle[objProperty] = variant.article;
                    delete currentChangedArticle[variantName];
                    $scope.containerForChangedArticles.splice(index2, 1);
                }
            });

            $scope.containerForChangedArticles.push(currentChangedArticle);
        }


        variant.oldVariantArticle = variant.article;

        if ($scope.forms.variantListForm) {
            $scope.forms.variantListForm.validateControls();
        }
    }

    function checkEmptyOptionValues(variantOptionValues) {
        if( variantOptionValues.values.length <1 ){
            $scope.isEmptyOptionValues = true;
        }
    }

    function checkChipsLimit () {
        if ($scope.generatedVariantOptions.length > 0) {
            var arrayForCheckingNames = [];
            $scope.generatedOptionValuesArray = [];

            //put optionNames into an array in order to check any duplicates and prevent them from saving
            $scope.generatedVariantOptions.forEach(function (variantOption) {
                arrayForCheckingNames.push(variantOption.name);
            });

            if (checkDuplicateOptions(arrayForCheckingNames) !== false) {
                $scope.generatedVariantOptions.forEach(function (variantOption) {
                    if (Array.isArray(variantOption.values)) {
                        if (checkDuplicateOptions(variantOption.values) !== false) {
                            $scope.generatedOptionValuesArray = generateCombinations($scope.generatedVariantOptions);
                        }
                    }
                })

                // if ($scope.generatedOptionValuesArray.length > 200) {
                //     $scope.maximumVariantsExceeded = true;
                //     return false;
                // } else if($state.$current.name === "goods.create" || ($state.$current.name === "goods.edit" && $scope.noVariationsFromTheStart)){
                //     $scope.maximumVariantsExceeded = false;
                //     return true;
                // } else if(($state.$current.name === "goods.edit" && !$scope.noVariationsFromTheStart)){
                //     assignAttributesToVariants($scope.generatedVariantOptions);
                //     if($scope.variantsDeletionForbidden === true){
                //         return false
                //     } else {
                //         return true;
                //     }
                //     //block window if we are on this state since we need to check up the case whether all variants were removed or not
                // } else if(argument === true){
                //     return true
                // } else {
                //     return true;
                // }

                // if(!$scope.noVariationsFromTheStart && $scope.generatedOptionValuesArray.length < $scope.ware.variations.variants.length) {
                if(!$scope.noVariationsFromTheStart) {

                   //  var toBeDeletedCombinations = [],
                   //      actualCombinations = [];
                   //
                   //  if($scope.deletedVariants.length > 0){
                   //      toBeDeletedCombinations =  $scope.deletedVariants.map(function(toBeDeleted){
                   //          return "["+arrayOperation.sortArray(toBeDeleted).toString()+"]";
                   //      });
                   //
                   //  }
                   //
                   // $scope.generatedOptionValuesArray.forEach(function(combination){
                   //      var stringifyLiteralNotation = "["+arrayOperation.sortArray(combination).toString()+"]";
                   //
                   //
                   //      if(toBeDeletedCombinations.indexOf(stringifyLiteralNotation) === -1){
                   //          actualCombinations.push(combination);
                   //      }
                   //
                   //
                   //  });
                   //

                    // $scope.variantsDeletionForbidden = false;
                   //
                   //
                   //  if(actualCombinations.length === 0){
                   //      $scope.variantsDeletionForbidden = true;
                   //  } else {
                   //      actualCombinations.forEach(function (generatedValues){
                   //          // $scope.generatedOptionValuesArray.forEach(function (generatedValues){
                   //          // $scope.generatedOptionValuesArray.every(function (generatedValues){
                   //          $scope.variantsDeletionForbidden = $scope.ware.variations.variants.every(function (variant) {
                   //              if (!angular.equals(arrayOperation.sortArray(generatedValues), arrayOperation.sortArray(variant.values))) {
                   //                  return true;
                   //              } else {
                   //                  return false;
                   //              }
                   //          })
                   //      });
                   //  }

                    $scope.variantsDeletionForbidden = false;

                    $scope.variantsDeletionForbidden = $scope.ware.variations.variants.every(function(existingVariant){

                         var combinationsInArrayLiteralNotation = [],
                            stringifyLiteralNotation = "["+arrayOperation.sortArray(existingVariant.values).toString()+"]";

                         if($scope.generatedOptionValuesArray.length > 0){
                             combinationsInArrayLiteralNotation =  $scope.generatedOptionValuesArray.map(function(toBeStringified){
                                 return "["+arrayOperation.sortArray(toBeStringified).toString()+"]";
                             });

                         }

                             if(combinationsInArrayLiteralNotation.indexOf(stringifyLiteralNotation) === -1){
                                 return true;
                             }

                    });


                }

                if ($scope.generatedOptionValuesArray.length > 200) {
                    $scope.maximumVariantsExceeded = true;
                    return false;
                } else if($scope.variantsDeletionForbidden) {
                    return false;
                } else {
                    $scope.variantsDeletionForbidden = false;
                    $scope.maximumVariantsExceeded = false;
                    return true;
                }

            }
        };
    }

    function checkForEmptyVariantOptionsValue(array){
        if(array.length > 0 && Array.isArray(array)){
            array.some(function(variantOption){
                if(variantOption.name === '' || variantOption.name === undefined){
                    return true;
                } else if(Array.isArray(variantOption.values) && variantOption.values.length < 1){
                    return true;
                }
            });
        }
    }

    function addVariantOption(){
        emptyOption.currentIndex = ($scope.generatedVariantOptions && $scope.generatedVariantOptions.length > 0) ?
            $scope.generatedVariantOptions.length : 0;
        // (($scope.generatedVariantOptions[$scope.generatedVariantOptions.length-1].currentIndex) + 1) : 0;

        var arrayOfOptionsNames = [];
        idGenerator +=1;
        emptyOption.id = idGenerator;



        if(checkForEmptyVariantOptionsValue($scope.generatedVariantOptions) !==true ){
            $scope.generatedVariantOptions.push(angular.copy(emptyOption));
        }

        return $scope.generatedVariantOptions;

        $state.go($state.current.name, {reload: false});


    }

    function delVariantOption(prop){

        $scope.generatedVariantOptions = $scope.generatedVariantOptions.filter(function(variantOption, index){

            if(index !== prop){
                return variantOption;
            }
        });
    }

    function checkDuplicateOptions(array) {
        var i,
            length = array.length,
            sortedArray,
            result = [];

        sortedArray = arrayOperation.sortArray(array);

        // for ( i = 1; i < length; i++ ){
        //     if(sortedArray[i-1] === sortedArray[i])
        //         return false;
        // }

        sortedArray.forEach(function(element, index) {

            // Find if there is a duplicate or not
            if (sortedArray.indexOf(element, index + 1) > -1) {

                // Find if the element is already in the result array or not
                if (result.indexOf(element) === -1) {
                    result.push(element);
                }
            }
        });

        if(result.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    function generateCombinations(array){
        var concatenated = [],
            _generatedOptionValuesArray;

        array.forEach(function(variantObject){
            if(variantObject instanceof Object) {
                concatenated.push(variantObject.values);
            } else if(Array.isArray(variantObject)){
                concatenated.push(variantObject);
            }
        })

        _generatedOptionValuesArray = getCombinationsOfMultidimensionalArrValues.getCombinations(concatenated);
        // console.log($scope.generatedOptionValuesArray);
        // $scope.generatedOptionValuesArrayConcat = $scope.generatedOptionValuesArray.map(function(array){
        //     return array.join(" / ");
        // });

        // console.log(generated);
        // console.log($scope.generatedOptionStringValuesArray);
        //
        // return $scope.generatedOptionValuesArrayConcat;
        return _generatedOptionValuesArray;
    }

    function upDateVariantOutletSalePriceOnTheFly (variant, newSingleVariantOutlets){
        // console.log(oldPrice);
        if(newSingleVariantOutlets){
            newSingleVariantOutlets.some(function(outletWithVariant){
                var newVariant = true;
                updateOutlets(outletWithVariant, newVariant);
            });
        } else {
            $scope._variantsByOutlets.some(function(outletWithVariant){
                updateOutlets(outletWithVariant);
            });
        }

        function updateOutlets(outletWithVariant, newVar){
            if(newVar){
                if(variant.oldPrice === outletWithVariant.salePrice){
                    outletWithVariant.salePrice = variant.salePrice;
                } else if(!variant.oldPrice && outletWithVariant.salePrice === null){
                    outletWithVariant.salePrice = variant.salePrice;
                }
            } else {
                if((variant.article === outletWithVariant.variantArticle || variant.article === outletWithVariant.article)  && variant.oldPrice === outletWithVariant.salePrice){
                    outletWithVariant.salePrice = variant.salePrice;
                }

            }

        }

        variant.oldPrice = variant.salePrice;

    }

    function editVariants() {

        initialOptionsBeforeEditingOptions =  angular.copy($scope.ware.variations.options);
        initialVariantsBeforeEditingOptions =  angular.copy($scope.ware.variations.variants);
        initialByOutletsBeforeEditingOptions =  angular.copy($scope._variantsByOutlets);
        $scope.variantsDeletionForbidden = false;
        $scope.editOptions = true;

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-variations-add-options.html',
            title: translate('VARIATIONS.VARIANT_EDIT'),
            scope: $scope,
            variationsOptionsEditForm: $scope.variationsOptionsEditForm,
            noAdd: true,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function(){
                        $scope.generatedVariantOptions = $scope.ware.variations.options = initialOptionsBeforeEditingOptions;
                        $scope.ware.variations.variants = initialVariantsBeforeEditingOptions;
                        $scope._variantsByOutlets = initialByOutletsBeforeEditingOptions;
                        validateList();
                    }
                },
                {
                    // class: 'green',
                    class: $scope.arapro ? "green arabic-save" : "green",
                    text: 'ON_SAVE',
                    action: function() {
                        saveVariants();
                        // если опции удалены, то перегенерируем варианты по магазинам
                        if($scope.generatedVariantOptions.length === 0) {
                            createVariantsByOutlets();
                        }

                        validateList();
                    }
                }
            ]
        });

        $scope.$on('dialogScopeData', function (event, data) {
            $scope.variationsOptionsEditForm = data.variationsOptionsEditForm;
        })
    }

    function addVariants () {

        if($scope.ware.variations.variants.length < 1){
            addVariantOption();
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-variations-add-options.html',
                title: translate('VARIATIONS.CREATE_OPTIONS'),
                scope: $scope,
                variantsOptionsGeneration: $scope.variantsOptionsGeneration,
                keys: $scope.keys,
                buttons: [
                    {
                        text: 'ON_CANCEL',
                        action: function(){
                            $scope.generatedVariantOptions = [];
                            // $scope.generatedVariantOptions.push(emptyOption);
                        }
                    },
                    {
                        class: $scope.arapro ? "green arabic-save" : "green",
                        text: 'ON_SAVE',
                        action: saveVariants
                    }
                ]
            });

            $scope.$on('dialogScopeData', function (event, data) {
                $scope.variationsOptionsGenerationForm = data.variationsOptionsGenerationForm;
                // console.log($scope);
            })
        } else {
            // filterStoresAndVariants('allOutlets');
            variantFromScratch();
            $scope.existingVariants = angular.copy($scope.ware.variations.variants);
            $scope.variantOptionValueExists = false;
            $scope.validateOnExistingVariants = true;
            $scope.singleVariant.variantsByOutlets.forEach(function(outlet){
                outlet.enable = true;
            });
            $scope.singleVariant.variant.allowedForAllOutlets = true;
            $scope.selectSingle = $scope.singleVariant.variantsByOutlets.length;
            if($scope.outlets.length === 1) {
                $scope.singleVariant.allowedForSale = true;
            }

            factoryGoods.getAutoArticles({  count: 1, used: $scope.askedArticles}).then(function(data) {
                if (data.result === 'ok') {
                    $scope.singleVariant.variant.article = data.autoWareArticles[0];
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-variations-addvariant.html',
                        title: translate('VARIATIONS.CREATE_VARIANT'),
                        currencyOptionsEdit: $currencyOptionsEdit,
                        countOptionsEdit: $countOptionsEdit,
                        critCountOptionsEdit: $critCountOptionsEdit,
                        quantOptionsEdit: $quantOptionsEdit,
                        countOptionsEditDivisible: $countOptionsEditDivisible,
                        scope: $scope,
                        addSingleVariant: $scope.addSingleVariant,
                        buttons: [
                            {
                                text: 'ON_CANCEL',
                                action: function(){
                                    $scope.variantAlreadyExists = false;
                                    $scope.skuAlreadyExists = false;

                                    // $scope.generatedVariantOptions = [];
                                    // variantFromScratch();
                                    // $scope.generatedVariantOptions.push(emptyOption);
                                }
                            },
                            {
                                class: $scope.arapro ? "green arabic-save" : "green",
                                text: 'ON_SAVE',
                                action: saveSingleVariant
                            }
                        ]
                    });
                }
            })

            $scope.$on('dialogScopeData', function (event, data) {
                $scope.addSingleVariantForm = data.addSingleVariantForm;
                // console.log($scope);
            })
        }

    }

    function createAttributesForVariants(val, index){
        var objVariant = {},
            existingVariantObj = {};
            $scope.existingVariant = false;

        if(copyOfVariationsArr.variants.length > 0){
            copyOfVariationsArr.variants.some(function(priorVar){
                if(angular.equals(arrayOperation.sortArray(val), arrayOperation.sortArray(priorVar.values))){
                    $scope.existingVariant = true;
                    existingVariantObj = priorVar;
                }
            });

            if($scope.existingVariant){
                objVariant.allowedForAllOutlets = existingVariantObj.allowedForAllOutlets;
                objVariant.article = existingVariantObj.article;
                objVariant.values = val;
                objVariant.salePrice = existingVariantObj.salePrice;
                objVariant.purchaseCost = existingVariantObj.purchaseCost;
                objVariant.freePrice = (existingVariantObj.salePrice === '' || existingVariantObj.salePrice === null );
                objVariant.primeCost = existingVariantObj.primeCost;
                objVariant.barcode = existingVariantObj.barcode;
                objVariant.enable = existingVariantObj.enable;
                if(existingVariantObj.id) {
                    objVariant.id = existingVariantObj.id;
                }
                // objVariant.count = priorVal[0].count;
                // objVariant.criticalCount = priorVal[0].criticalCount;
                $scope.ware.variations.variants.push(angular.copy(objVariant));

            } else {
                if($scope.noVariationsFromTheStart){
                    generateNew(val, index);
                }
            }


        } else {
            generateNew(val, index);
        }


        function generateNew (val, index2){

                objVariant.allowedForAllOutlets = ($scope.outlets.length > 1 && $scope.ware.allowedForSale === true);
                objVariant.article = $scope.autoGeneratedArticles[index2];
                objVariant.enable = ($scope.outlets.length > 1 && $scope.ware.variations.allowedForAllOutlets) ? $scope.ware.variations.allowedForAllOutlets : ($scope.outlets.length === 1 && $scope.ware.allowedForSale === true);
                objVariant.values = val;
                objVariant.barcode = (index2 === 0) ? $scope.ware.barcode : '';
                // objVariant.salePrice = $scope.ware._salePrice ? $scope.ware._salePrice : '';

                objVariant.salePrice = ($scope.outlets.length === 1 && !$scope.ware._complex && $scope.ware._outlets) ? $scope.ware._outlets[0].salePrice : ($scope.outlets.length === 1 && !$scope.ware._complex && !$scope.ware._outlets) ? $scope.ware._salePrice : ($scope.outlets.length > 1 && !$scope.ware._complex) ? $scope.ware._salePrice : null;
                objVariant.purchaseCost = ($scope.ware.purchaseCost !== null) ? $scope.ware.purchaseCost : null;
                objVariant.primeCost = ($scope.ware._primeCost) ? $scope.ware._primeCost : null;
                objVariant.freePrice = ($scope.ware._salePrice === "" || $scope.ware._salePrice === null);
                objVariant.count = ($scope.outlets.length === 1 && !$scope.ware._complex && index2 === 0 && $scope.ware._outlets)
                    ? $scope.ware._outlets[0].count
                    : ($scope.outlets.length === 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0 && !$scope.ware._outlets)
                        ? $scope.ware._count
                        : ($scope.outlets.length > 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0)
                            ? $scope.ware._outlets[index2].count
                            : 0;
                objVariant.criticalCount = ($scope.outlets.length === 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0 && $scope.ware._outlets)
                    ? $scope.ware._outlets[0].criticalCount
                    : ($scope.outlets.length === 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0 && !$scope.ware._outlets)
                        ? $scope.ware._criticalCount
                        : ($scope.outlets.length > 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0)
                            ? $scope.ware._outlets[index2].criticalCount
                            : null;
                objVariant.idealStock = ($scope.outlets.length === 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0 && $scope.ware._outlets)
                    ? $scope.ware._outlets[0].idealStock
                    : ($scope.outlets.length === 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0 && !$scope.ware._outlets)
                        ? $scope.ware._idealStock
                        : ($scope.outlets.length > 1 && $scope.ware.keepCount === true && !$scope.ware._complex && index2 === 0)
                            ? $scope.ware._outlets[index2].idealStock
                            : null;

                $scope.ware.variations.variants.push(angular.copy(objVariant));

        }
    }

    function chipsAndVariantsInteraction(){

        var sortedAndLowerCasedGeneratedOptions = $scope.generatedOptionValuesArray.map(function (values) {
            values = arrayOperation.sortArray(values);
            return values.map(function (val) {
                return val.toLowerCase();
            });

        });


        //remove variants if they cannot be combined of the new generated options


        if(initialOptionsBeforeEditingOptions && initialVariantsBeforeEditingOptions && initialByOutletsBeforeEditingOptions && initialGeneratedOptionValuesArrayBeforeEditingOptions) {
            sortedAndLowerCasedGeneratedOptions.forEach(function (variant) {
                var variantForDeletion = sortedAndLowerCasedGeneratedOptions.every(function (generatedCombination) {
                    if(variant.hasOwnProperty('values')){
                        if (!angular.equals(generatedCombination, variant.values)) {
                            return true;
                        }
                    } else if(Array.isArray(variant)) {
                        if (!angular.equals(generatedCombination, variant)) {
                            return true;
                        }
                    }
                });

                //delete this variant from variants and variantsByOutlets if it doesn't exist anymore among generated combinations after editing options

                if (variantForDeletion) {
                    $scope.ware.variations.variants.forEach(function (currVariant, index) {
                        if (currVariant.article === variant.article) {
                            $scope.ware.variations.variants.splice(index, 1);
                        }
                    });
                }

            })


        }
        // if (sortedAndLowerCasedGeneratedOptions.length < initialGeneratedOptionValuesArrayBeforeEditingOptions.length) {
            // initialLowerCasedGeneratedVariantsValues.forEach(function (variant) {

        // }

        if ($scope.ware.variations.variants.length === 0 || $scope.generatedVariantOptions.length === 0) {
            if ($state.$current.name === ("goods.create" && $scope.noVariationsFromTheStart) || ($state.$current.name = "goods.edit" && $scope.noVariationsFromTheStart)) {
                $scope.generatedVariantOptions = $scope.ware.variations.options = [];
                $scope.ware.variations.variants = [];
                $scope._variantsByOutlets = angular.copy($scope.outlets);
            } else {
                $scope.variantsDeletionForbidden = true;
            }
        }


        $scope.ware.variations.options.forEach(function(option){
            var optionForDeletion = false;
            var forbidOptionToBeDeleted = false;


            forbidOptionToBeDeleted = $scope.ware.variations.options.every(function(option){
                if(option.values.length === 1){
                   return true;
                }
            });

            option.values.forEach(function(optionVal){
                if(forbidOptionToBeDeleted && !$scope.noVariationsFromTheStart){
                    return false;
                } else {
                    optionForDeletion = $scope.ware.variations.variants.every(function(variant){
                        if(variant.values.indexOf(optionVal) === -1){
                            return true;
                        }

                    });

                    if(optionForDeletion){
                        var position = option.values.indexOf(optionVal);
                        option.values.splice(position, 1);
                    }
                }
            });

        });

        //!!!!!!!!!!!!!!remove chips if they are not met among recently generated variants


        $scope.generatedVariantOptions.forEach(function (option, index1) {
            option.values.forEach(function (optionVal) {
                var optionValueForDeletion = $scope.ware.variations.variants.every(function (variant) {
                    var lowerCasedValues = variant.values.map(function (val) {
                        return val.toLowerCase();
                    });
                    if (lowerCasedValues.indexOf(optionVal.toLowerCase()) === -1) {
                        return true;
                    }

                });

                if (optionValueForDeletion) {
                    $scope.generatedVariantOptions[index1].values.forEach(function (currOptionVal, index2) {
                        if (currOptionVal.toLowerCase() === optionVal.toLowerCase()) {
                            $scope.generatedVariantOptions[index1].values.splice(index2, 1);
                        }
                    });
                }
            });
        });

        if($scope.ware.variations.variants.length === 0) {
            $scope.viewMore = translate('MULTISHOP.GOODS.VIEW_ALL_STORES');
            $scope.viewLess = translate('MULTISHOP.GOODS.VIEW_LESS_STORES');
        }
    }

    function assignAttributesFromScratch(arrayOfOptions, generatedOptionValuesArray){
        // initialGeneratedOptionValuesArrayBeforeEditingOptions = initialGeneratedOptionValuesArrayBeforeEditingOptions.concat(generatedOptionValuesArray);
        var objOption = {};
            articlesScore = generatedOptionValuesArray.length;

            if($scope.autoGeneratedArticles){

                $scope.autoGeneratedArticles.forEach(function(article){
                    $scope.askedArticles.push(article);
                })

            }

            factoryGoods.getAutoArticles({count: articlesScore, used: $scope.askedArticles}).then(function(data) {
                if (data.result === 'ok') {

                //clean the array for re-generation if it's the case, otherwise it will be empty from the start all the same;
                // $scope.ware.variations.options = [];
                $scope.ware.variations.variants = [];

                $scope.autoGeneratedArticles = data.autoWareArticles;

                $scope.ware.variations.options = angular.copy(arrayOfOptions);

                generatedOptionValuesArray.forEach(function (val, index) {
                    $scope.deletedVariant = false;

                    if($scope.deletedVariants.length > 0){
                        $scope.deletedVariants.some(function(deletedVar){
                            if(angular.equals(arrayOperation.sortArray(val), arrayOperation.sortArray(deletedVar))) {
                                // console.log("has found");
                                $scope.deletedVariant = true;
                            }
                        });

                        if($scope.deletedVariant){
                            return false;
                        } else {
                            createAttributesForVariants(val, index);
                        }

                    } else {
                        createAttributesForVariants(val, index);
                    }

                });

                $scope.viewLine = $scope.ware.variations.variants.length > 5;
            }

            // $scope.ware.variations.allowedForAllOutlets = ($scope.outlets.length > 1) ? $scope.ware.allowedForSale : null;

            if(initialOptionsBeforeEditingOptions && initialVariantsBeforeEditingOptions && initialByOutletsBeforeEditingOptions && initialGeneratedOptionValuesArrayBeforeEditingOptions) {

                chipsAndVariantsInteraction();

            }

            if($scope.ware.variations.variants.length === 0){

                $scope.ware.variations.variants = copyOfVariationsArr.variants;
                $scope.generatedVariantOptions = copyOfVariationsArr.options;
                $scope._variantsByOutlets = copyOfOutletsWithVariantsArr;
                $scope.variantsDeletionForbidden = true;

            } else {
               $scope.variantsDeletionForbidden = false;

                copyOfVariationsArr.variants = $scope.ware.variations.variants;
                copyOfVariationsArr.options = $scope.ware.variations.options;
                // $scope.viewLine = ($scope.ware.variations.variants.length > 5);
                // console.log($scope.viewLine);

                createVariantsByOutlets();
                copyOfOutletsWithVariantsArr = $scope._variantsByOutlets;

            }

            // console.log($scope.ware.variations.variants);

            $scope.variantOptionsLabel = angular.copy($scope.generatedVariantOptions);

            if($scope.ware.variations.variants.length > 0) {
                // // $scope.showLine = 10;
                // $scope.showOutletsWithVariants = 10;
                $scope.viewMore = translate('VIEW_MORE');
                $scope.viewLess = translate('VIEW_LESS');
                $scope.viewLineAllOutlets = (($scope.ware.variations.variants.length * $scope.outlets.length) > $scope.showLine);
            }

            if($scope.outlets.length > 1) {
                $scope.variantsSelect = 0;
                for (var i = 0; i < $scope._variantsByOutlets.length; i++) {
                    if ($scope._variantsByOutlets[i].enable === true) {
                        $scope.variantsSelect++;
                    }
                }
                if($scope.variantsSelect === $scope._variantsByOutlets.length) {
                    $scope.ware.variations.allowedForAllOutlets = true;
                } else {
                    $scope.ware.variations.allowedForAllOutlets = false;
                }
            }

            validateList();
        })

    }

    function assignAttributesToVariants(arrayOfOptions){

        assignAttributesFromScratch(arrayOfOptions, $scope.generatedOptionValuesArray);

        $state.go($state.current.name, {reload: false});

    }

    function fillNewByOutlets(variants, outlets) {

        copyOfOutletsWithVariantsArr = angular.copy($scope._variantsByOutlets);
        var regeneratedOutlets = [];

        //for veiled reasons $scope.ware._outlets is an object instead of an array, but with properties equal to indices if it were still the array.

        variants.forEach(function (variant, index0) {
            var currentObject = {};
            outlets.forEach(function (outlet, index) {
                var indexAugmented = index0 + index,
                    variantArticle = (variant.article) ? variant.article : variant.variantArticle,
                    outletId = (outlet.id) ? outlet.id : outlet.outletId,
                    indicator = variantArticle + outletId,
                    currentOutletInWare = ($scope.ware._outlets) ? $scope.ware._outlets[index] : $scope.ware; //for veiled reasons $scope.ware._outlets is an object instead of an array, but with properties equal to indices if it were still the array.
                indexAugmented += 1;

                function pushNewBond(){
                    currentObject[indexAugmented] = angular.copy(variant);
                    // currentObject[indexAugmented].salePrice = currentOutletInWare.salePrice ? currentOutletInWare.salePrice  : currentOutletInWare._salePrice ? currentOutletInWare._salePrice : null;
                    // currentObject[indexAugmented].freePrice = (currentOutletInWare.salePrice !== "" && currentOutletInWare.salePrice !== null) ? false :
                    //                                           (currentOutletInWare._salePrice !== "" && currentOutletInWare._salePrice !== null) ? false :
                    //                                           (currentOutletInWare.freePrice) ? currentOutletInWare.freePrice : true;
                    currentObject[indexAugmented].enable = (currentOutletInWare.enable) ? currentOutletInWare.enable : (currentOutletInWare._enable) ? currentOutletInWare._enable : null;
                    currentObject[indexAugmented].count = (currentOutletInWare.count && index0 === 0 && currentOutletInWare.count !== "0") ? currentOutletInWare.count :
                        (currentOutletInWare._count && index0 === 0) ? currentOutletInWare._count : 0;
                    currentObject[indexAugmented].criticalCount = (currentOutletInWare.criticalCount && index0 === 0) ? currentOutletInWare.criticalCount :
                        (currentOutletInWare._criticalCount && index0 === 0) ? currentOutletInWare._criticalCount : null;
                    currentObject[indexAugmented].idealStock = (currentOutletInWare.idealStock && index0 === 0) ? currentOutletInWare.idealStock :
                        (currentOutletInWare._idealStock && index0 === 0) ? currentOutletInWare._idealStock : null;

                    for (var prop in outlet) {
                        if(outlet.hasOwnProperty(prop)){
                            if(prop === 'name'){
                                var prop1 = 'outletName';
                                currentObject[indexAugmented][prop1] = angular.copy(outlet[prop]);
                            } else {
                                currentObject[indexAugmented][prop] = angular.copy(outlet[prop]);
                            }
                        }
                    }

                    regeneratedOutlets.push(angular.copy(currentObject[indexAugmented]));
                }

                var existingOutletVariant;
                if(copyOfOutletsWithVariantsArr){
                    copyOfOutletsWithVariantsArr.forEach(function(priorOutletVar){
                        var priorVariantArticle = (priorOutletVar.article) ? priorOutletVar.article : priorOutletVar.variantArticle,
                            priorOutletId = (priorOutletVar.id) ? priorOutletVar.id : priorOutletVar.outletId,
                            priorIndicator = priorVariantArticle + priorOutletId;

                        if(indicator == priorIndicator){
                            priorOutletVar.values = variant.values;
                            currentObject[indexAugmented] = angular.copy(priorOutletVar);
                            regeneratedOutlets.push(angular.copy(currentObject[indexAugmented]));
                            existingOutletVariant = true;
                            return true;
                        }
                    });

                    if(!existingOutletVariant){
                        // console.log(variant.values);
                        pushNewBond();
                    }
                } else {
                    pushNewBond();
                }

            });

        })

        // console.log(regeneratedOutlets);
        $scope._variantsByOutlets = angular.copy(regeneratedOutlets);
        return $scope._variantsByOutlets;
    }

    function createVariantsByOutlets(){

        //an alternative implementation for nested ng-repeats

        // $scope._outlets = angular.copy($scope.outlets);
        // $scope.variantsByOutlets = [];
        // $scope.variantsByOutletsIndicesForFasterFilters = {};
        //
        // var k = 0,
        //     outletsLength = $scope._outlets.length;
        //
        // for(; k < outletsLength; k++){
        //     var indexProperty = $scope._outlets[k].id;
        //     $scope._outlets[k].variants = angular.copy($scope.ware.variations.variants);
        //     $scope.variantsByOutlets.push($scope._outlets[k]);
        //     $scope.variantsByOutletsIndicesForFasterFilters[indexProperty] = $scope.variantsByOutlets[k];
        //
        //     var currentOutletInWare = $scope.ware._outlets[k], //for veiled reasons $scope.ware._outlets is an object instead of an array, but with properties equal to indices if it were still the array.
        //         currentIdInWareOutlets = currentOutletInWare.outletId;
        //
        //     $scope.variantsByOutletsIndicesForFasterFilters[currentIdInWareOutlets].variants.forEach(function(variant, index){
        //         variant.salePrice = (currentOutletInWare.salePrice) ? currentOutletInWare.salePrice : null;
        //         variant.freePrice = (currentOutletInWare.salePrice) ? false : (currentOutletInWare.freePrice) ? currentOutletInWare.freePrice : true;
        //         variant.enable = (currentOutletInWare.enable) ? currentOutletInWare.enable : null;
        //         variant.count = (currentOutletInWare.count && index === 0) ? currentOutletInWare.count : 0;
        //         variant.criticalCount = (currentOutletInWare.criticalCount && index === 0) ? currentOutletInWare.criticalCount : null;
        //     })
        // }

        //a current implementation with an array filled with plain objects

        fillNewByOutlets( $scope.ware.variations.variants, $scope.outlets );

        copyOfOutletsWithVariantsArr = angular.copy($scope._variantsByOutlets);


        if($scope.ware.variations.variants.length > 0) {
            // // $scope.showLine = 10;
            // $scope.showOutletsWithVariants = 10;
            $scope.viewMore = translate('VIEW_MORE');
            $scope.viewLess = translate('VIEW_LESS');
            $scope.viewLineAllOutlets = (($scope.ware.variations.variants.length * $scope.outlets.length) > $scope.showLine);
        }
    }

    function saveVariants() {

        if( $scope.generatedVariantOptions.length < 1){
            $scope.ware.variations.variants = [];
            $scope.ware.variations.options = [];
            return false;
        }

        if($scope.maximumVariantsExceeded === false && !$scope.variantsDeletionForbidden){
            assignAttributesToVariants($scope.generatedVariantOptions);
            $scope.viewLine = ($scope.ware.variations.variants.length > 5);
            //show line to handle variants if their length is more than five at least
        } else {
            return false;
        }
    }

    // функция ни где не вызывается т.к. в диалоговых окнах нет кнопок more/less, а на сколько я поняла, она использовалась толоко для определения показывать эту кнопку или нет в списке магазинов
    function filterStoresAndVariants(model){

        // $scope.showAllOutlets = false;


        if(typeof model === 'string' && model === 'allVariants'){
            $scope.variantArticle = null;

        } else if(typeof model === 'string' && model === 'allOutlets'){
            $scope.outletId = null;

        } else if(model.hasOwnProperty('id')){
            $scope.outletId = model.id;

        } else if(model.hasOwnProperty('article')) {
            $scope.variantArticle = model.article;
        } else if(typeof model === 'string' && model === 'outletPreview'){
            $scope.showOutletsWithVariants = 5;
        }

        //in case of both available filters - for outlets and variants (incomplete logic)
        // if($scope.variantArticle === null && $scope.outletId === null){
        //
        //     $scope.showAllOutlets = ($scope.showOutlets < ($scope.ware.variations.variants.length * $scope.outlets.length)); //if it works => true? otherwise it will be false
        //     // $scope.showOutlets = $scope.outlets.length;
        //     $scope.showOutlets = $scope.outlets.length;
        //     // $scope.showVariants = $scope.ware.variations.variants.length;
        //     $scope.showVariants = $scope.ware.variations.variants.length;
        //     $scope.showAllOutlets = ($scope.showOutlets*$scope.showVariants >= ($scope.ware.variations.variants.length * $scope.outlets.length)); //if it works => true? otherwise it will be false
        //     $scope.viewLineAllOutlets = (($scope.ware.variations.variants.length * $scope.outlets.length) >= $scope.showLine);
        //     $scope.viewMore = translate('VIEW_MORE');
        //     $scope.viewLess = translate('VIEW_LESS');
        //
        // } else if($scope.variantArticle !== null && $scope.outletId === null){
        //
        //     $scope.showVariants = 1;
        //     $scope.showOutlets = ($scope.outlets.length <= 100) ? $scope.outlets.length : 100;
        //     $scope.showAllOutlets = ($scope.showOutlets >= $scope.outlets.length); //if it works => true? otherwise it will be false
        //     $scope.viewLineAllOutlets = ($scope.outlets.length >= $scope.showLine);
        //     $scope.viewMore = translate('MULTISHOP.GOODS.VIEW_ALL_STORES');
        //     $scope.viewLess = translate('MULTISHOP.GOODS.VIEW_LESS_STORES');
        //
        // } else if($scope.variantArticle === null && $scope.outletId !== null){
        //
        //     $scope.showVariants = ($scope.ware.variations.variants.length <= 100) ? $scope.ware.variations.variants.length : 100;
        //     $scope.showOutlets = 1;
        //     $scope.showAllOutlets = ($scope.showVariants >= $scope.ware.variations.variants.length); //if it works => true? otherwise it will be false
        //     $scope.viewLineAllOutlets = ($scope.ware.variations.variants.length >= $scope.showLine);
        //     $scope.viewMore = translate('VARIATIONS.VIEW_ALL_VARIANTS');
        //     $scope.viewLess = translate('VARIATIONS.VIEW_LESS_VARIANTS');
        // } else {
        //
        //     $scope.showVariants = 1;
        //     $scope.showOutlets = 1;
        //     $scope.showAllOutlets = true;
        //     $scope.viewLineAllOutlets = false;
        //     $scope.viewMore = translate('VARIATIONS.VIEW_ALL_VARIANTS');
        //     $scope.viewLess = translate('VARIATIONS.VIEW_LESS_VARIANTS');
        // }

        //in case of a single filter by outlets
        if($scope.outletId === null && (model !== 'outletPreview')){

            $scope.showOutletsWithVariants = $scope._variantsByOutlets.length || $scope.outlets.length;
            // $scope.showOutletsWithVariants = $scope.outlets.length;

            $scope.showAllOutlets = ($scope.showOutletsWithVariants >= $scope._variantsByOutlets.length); //if it works => true? otherwise it will be false
            $scope.viewLineAllOutlets = ($scope._variantsByOutlets.length >= $scope.showLine);
            $scope.viewMore = translate('VIEW_MORE');
            $scope.viewLess = translate('VIEW_LESS');

        } else if($scope.outletId !== null){

            $scope.showOutletsWithVariants = $scope.ware.variations.variants.length;

            $scope.showAllOutlets = ($scope.showOutletsWithVariants < $scope._variantsByOutlets.length); //if it works => true? otherwise it will be false
            $scope.viewLineAllOutlets = $scope.ware.variations.variants.length >= $scope.showLine;
            $scope.viewMore = translate('VARIATIONS.VIEW_ALL_VARIANTS');
            $scope.viewLess = translate('VARIATIONS.VIEW_LESS_VARIANTS');
        }

    }

    function delVariant(_index){

        let prop = $scope.ware.variations.variants[_index];

        // console.log($scope.generatedOptionValuesArray);
        $scope.generatedOptionValuesArray = $scope.generatedOptionValuesArray.filter(function(variant, index){
            if(!angular.equals(variant, prop.values)) {
                return variant;
            }
        });
        // console.log("Generated Option Values: ", $scope.generatedOptionValuesArray);
        // console.log($scope.ware.variations.variants);
        $scope.ware.variations.variants = $scope.ware.variations.variants.filter(function(variant, index){
            if(index !== _index) {
                return true;
            } else {
                $scope.deletedVariants.push(variant.values);
                return false;
            }

            //hidden in a comment since formerly a server wanted seeing these data in a request but then a server guru changed his mind :P
            // else if($state.current.name === 'goods.edit') {
            // $scope.ware.variations.deletedVariants.push({
            //     values: variant.values
            // });
            // console.log($scope.ware.variations.deletedVariants);
            // }
        });

        chipsAndVariantsInteraction();
        createVariantsByOutlets();
        copyOfOutletsWithVariantsArr = $scope._variantsByOutlets;


        // if($scope.ware.variations.variants.length === 0) {
        //     $scope.viewMore = translate('MULTISHOP.GOODS.VIEW_ALL_STORES');
        //     $scope.viewLess = translate('MULTISHOP.GOODS.VIEW_LESS_STORES');
        // }

        //it was implemented in case of the nested ng-repeat
        // $scope.variantsByOutlets.forEach(function(outlet){
        //     outlet.variants.some(function(variant, index){
        //         if(variant.article === prop.article){
        //             outlet.variants.splice(index, 1);
        //         }
        //     });
        // });


        //with a plain objects in an array for ng-repeat directive
        $scope._variantsByOutlets = $scope._variantsByOutlets.filter(function(outletVariant, index){
            if((outletVariant.article !== prop.article) || (outletVariant.variantArticle !== prop.article)){
                return outletVariant;
            }
        });

        if ($scope.forms.variantListForm) {
            $mdUtil.nextTick(() => {
                $scope.forms.variantListForm.validateControls();
            });
        }
    }

    function checkWhetherVariantOptionValExists(event, index, variantOption, name){
        $scope.variantOptionValueExists = false;

        if(variantOption){
            // $scope.ware.variations.options.some(function(option, index){
            if((variantOption.name && $scope.ware.variations.options[index].name === $scope.ware.variations.options[index].name) ||
                (!variantOption.name && $scope.ware.variations.options[index].name === name)) {
                if (
                    ($scope.preEditedValues.indexOf(variantOption.values) === -1 || $scope.preEditedValues.indexOf(variantOption) === -1)
                    &&
                    ($scope.ware.variations.options[index].values.indexOf(variantOption.values) !== -1 ||
                    $scope.ware.variations.options[index].values.indexOf(variantOption) !== -1)
                ) {
                    $scope.variantOptionValueExists = true;
                    // console.log($scope.variantOptionValueExists);
                    // console.log(event);
                    event.target.attributes.valid = "variant-option-value-true";
                    event.target.attributes.$invalid = true;
                    // event.target.classList.add('ng-invalid');
                    $scope.$broadcast('validate', {});
                    // return 'variant-option-value-true'
                    return true;
                }
            } else {
                return false;
            }


            // });
        }
    }

    function checkIfChanged(currentVal, index){
        $scope.validateOnExistingVariants = false;
        // console.log(currentVal);
        $scope.preEditedValues.some(function(initialOptionValue, indexOfLoop){
            if(index === indexOfLoop && currentVal !== initialOptionValue){
                // console.log(initialOptionValue);
                $scope.validateOnExistingVariants = true;
            }
        });

        // console.log($scope.validateOnExistingVariants);

    }

    function compareWithExistingVariants(obj, index) {

        function processAndCompareWithExistingVariants(){

            var i,
                _existingVariants = angular.copy($scope.existingVariants);
            if(index || index === 0)
                _existingVariants.splice(index, 1);
            var existingVariantsLen = _existingVariants.length;
            $scope.variantAlreadyExists = false;

            // console.log(_existingVariants);


            $scope.preparedVariantInitialCase = preparedValuesInitialCase.join(" / ");
            preparedVariantNameLoweredCase = preparedValuesCaseInsensitive.join(" / ");
            // console.log(preparedVariantNameLoweredCase);

            for(i = 0; i < existingVariantsLen; i++){
                var existingVariantValuesLoweredCase = [],
                    existingVariantNameLoweredCase;

                _existingVariants[i].values.forEach(function(value){
                    existingVariantValuesLoweredCase.push(value.toLowerCase());
                });

                existingVariantNameLoweredCase = existingVariantValuesLoweredCase.join(" / ");

                if(existingVariantNameLoweredCase === preparedVariantNameLoweredCase){
                    $scope.variantAlreadyExists = true;
                    break;
                }
            }


        }

        // function compareArticles() {
        //     var _existingVariants = angular.copy($scope.existingVariants);
        //
        //     if($scope.variantBeforeEdit && $scope.variantBeforeEdit.article) {
        //         _existingVariants.forEach(function(element, index){
        //             if(element.article === $scope.variantBeforeEdit.article) {
        //                 _existingVariants.splice(index, 1);
        //             }
        //         })
        //         var existingVariantsLen = _existingVariants.length;
        //         for (var i = 0; i < existingVariantsLen; i++) {
        //             if( _existingVariants[i].article === preparedVariant.article) {
        //                 $scope.skuAlreadyExists = true;
        //                 break;
        //             }
        //         }
        //     } else {
        //         var existingVariantsLen = _existingVariants.length;
        //         for (var i = 0; i < existingVariantsLen; i++) {
        //             if (_existingVariants[i].article === preparedVariant.variant.article) {
        //                 $scope.skuAlreadyExists = true;
        //                 break;
        //             }
        //         }
        //     }
        // }

        if ($state.current.name === 'goods.edit' && $scope.chackSkuBeforeSave) {
            // var preparedVariant = angular.copy(obj);
            // compareArticles();
        } else if ($state.current.name === 'goods.price') {
            $scope.dontClose = true;
        }


        if($scope.validateOnExistingVariants){
            var preparedVariant = angular.copy(obj),
                preparedValuesInitialCase = [],
                preparedValuesCaseInsensitive,
                preparedVariantNameLoweredCase;


            // console.log(obj);

            if(typeof obj === 'object' && obj.hasOwnProperty('options')){
                preparedValuesCaseInsensitive = preparedVariant.options.map(function(option){
                    if(option.values && option.values !== null && option.values.length > 0){
                        preparedValuesInitialCase.push(option.values);
                        return option.values.toLowerCase();
                    }
                });



            } else if(typeof obj === 'object' && obj.hasOwnProperty('values')) {
                if(preparedVariant.values && preparedVariant.values.length > 0){
                    preparedValuesCaseInsensitive = preparedVariant.values.map(function(value){
                        preparedValuesInitialCase.push(value);
                        return value.toLowerCase();

                    });
                }

            }

            $scope.preparedVariant = preparedValuesInitialCase.join(" / ");
            processAndCompareWithExistingVariants();
        }
        $scope.canSaveVariant = !($scope.variantAlreadyExists === true || $scope.skuAlreadyExists === true);

        return !($scope.variantAlreadyExists === true || $scope.skuAlreadyExists === true || $scope.dontClose === true);
    }

    function blockFromBeingAppended(chip){
        if($state.current.name === 'goods.edit' && !$scope.noVariationsFromTheStart){
            return null;
        }
    }

    function saveSingleVariant(){

        if($scope.variantAlreadyExists){
            return false;
        }

        var outletsWithThisVariantBeforeEditing = [];

        $scope.singleVariant.options.forEach(function(option, index){
            if($scope.generatedVariantOptions[index].name === option.name){
                var valuesLength = $scope.generatedVariantOptions[index].values.length;
                for(var i=0; i < valuesLength; i++) {
                    if($scope.generatedVariantOptions[index].values[i].toLowerCase() === option.values.toLowerCase() && $scope.generatedVariantOptions[index].values[i] !== option.values) {
                        $scope.generatedVariantOptions[index].values[i] = option.values;
                        $scope.ware.variations.variants.forEach(function(variant, index) {
                            for(var val=0; val < variant.values.length; val++) {
                                if(variant.values[val].toLowerCase() === option.values.toLowerCase()) {
                                    $scope.ware.variations.variants[index].values[val] = option.values;


                                    var outletsWithVariant = $scope._variantsByOutlets.filter(function(outlet, i){
                                        if(outlet.variantArticle === $scope.ware.variations.variants[index].article || outlet.article === $scope.ware.variations.variants[index].article){
                                            var filteredOutlet = {};
                                            filteredOutlet.index = i;
                                            filteredOutlet.data = angular.copy(outlet);
                                            outletsWithThisVariantBeforeEditing.push(angular.copy(filteredOutlet));
                                            return outlet;
                                        }
                                    });

                                    outletsWithVariant.forEach(function(currOutlet){
                                        currOutlet.values = $scope.ware.variations.variants[index].values;
                                        currOutlet.variantValues = $scope.ware.variations.variants[index].values;
                                        currOutlet.variantArticle = $scope.ware.variations.variants[index].article;
                                        currOutlet.modified = true;
                                    });
                                }
                            }
                        });

                    }
                }

                if($scope.generatedVariantOptions[index].values.indexOf(option.values) === -1) {
                    $scope.generatedVariantOptions[index].values.push(option.values);
                    $scope.ware.variations.options = $scope.generatedVariantOptions;
                }
                $scope.singleVariant.variant.values.push(option.values);
            }
        });


        $scope.singleVariant.variantsByOutlets.forEach(function(outlet){
            outlet.modified = true;
            //костыль, можно сделать более грамотно
            outlet.variantArticle = $scope.singleVariant.variant.article;
            outlet.outletName = outlet.name;
            outlet.outletId = outlet.id;
            //костыль, можно сделать более грамотно
            $scope._variantsByOutlets.push(outlet);
        });

        if ($scope.outlets.length === 1) {
            $scope.singleVariant.variant.enable = $scope.singleVariant.allowedForSale;
        }

        $scope.ware.variations.variants.push($scope.singleVariant.variant);
        sortVariants();

        $scope.viewLine = ($scope.ware.variations.variants.length > 5);
        if($scope.showAllVariants) $scope.showVariantsLine++;

        if ($scope.outlets.length > 1) {
            $scope.variantsSelect = 0;
            for (var i = 0; i < $scope._variantsByOutlets.length; i++) {
                if ($scope._variantsByOutlets[i].enable === true) {
                    $scope.variantsSelect++;
                }
            }
            $scope.ware.variations.allowedForAllOutlets = $scope.variantsSelect === $scope._variantsByOutlets.length;
        }
        $scope.askedArticles.push($scope.singleVariant.variant.article);
    }

    function validateList() {
        if ($scope.forms.variantListForm) {
            $mdUtil.nextTick(function(){
                if ($scope.forms.variantListForm.$invalid) {
                    $scope.forms.variantListForm.setInvalidTouchedControls();
                }
            });
        }
    }

    $scope.selectNewVarForOutlet = function(outlet) {
        if (outlet.enable) {
            $scope.selectSingle++;

            if ($scope.selectSingle === $scope.singleVariant.variantsByOutlets.length) {
                $scope.singleVariant.variant.allowedForAllOutlets = true;
            }
        } else {
            $scope.selectSingle--;

            if ($scope.singleVariant.variant.allowedForAllOutlets === true) $scope.singleVariant.variant.allowedForAllOutlets = false;
        }
    };

    $scope.selectVar = function (outlet) {
        // itemsSelect = itemsSelect();
        if (outlet.enable) {
            $scope.selectChack++;

            if ($scope.selectChack === $scope.outletsWithVariantForEditing.length) {
                $scope.variantForEditing.allowedForAllOutlets = true;
            }
        } else {
            $scope.selectChack--;

            if ($scope.variantForEditing.allowedForAllOutlets === true) $scope.variantForEditing.allowedForAllOutlets = false;
        }
    };

    function variantsSelectFunction(){
        $scope.selectChack = 0;
        if ($scope.outletsWithVariantForEditing && $scope.outletsWithVariantForEditing.length > 1) {
            for (var i = 0; i < $scope.outletsWithVariantForEditing.length; i++) {
                if ($scope.outletsWithVariantForEditing[i].enable === true) {
                    $scope.selectChack++;
                }
            }
            $scope.variantForEditing.allowedForAllOutlets = $scope.selectChack === $scope.outletsWithVariantForEditing.length;
        }

        return $scope.selectChack;
    }

    $scope.changeAllVars = function() {
        if($scope.variantForEditing.allowedForAllOutlets) {
            for (var j = 0; j < $scope.outletsWithVariantForEditing.length; j++) {
                $scope.outletsWithVariantForEditing[j].enable = true;
            }
            $scope.selectChack = $scope.outletsWithVariantForEditing.length;
        } else {
            for (var j = 0; j < $scope.outletsWithVariantForEditing.length; j++) {
                $scope.outletsWithVariantForEditing[j].enable = false;
            }
            $scope.selectChack = 0;
        }
    };

    $scope.selectNewVarForAllOutlets = function() {
        if($scope.singleVariant.variant.allowedForAllOutlets) {
            $scope.singleVariant.variantsByOutlets.forEach(function(outlet){
                outlet.enable = true;
            });
            $scope.selectSingle = $scope.singleVariant.variantsByOutlets.length;
        } else {
            $scope.singleVariant.variantsByOutlets.forEach(function(outlet){
                outlet.enable = false;
            });
            $scope.selectSingle = 0;
        }
    };

    function editVariant(variant, index){

        var outletsWithThisVariantBeforeEditing = [],
            variantBeforeEdit = $scope.variantBeforeEdit = angular.copy(variant);
        $scope.existingVariants = angular.copy($scope.ware.variations.variants);
        $scope.preEditedValues = variantBeforeEdit.values;

        $scope.variantForEditing = variant;
        $scope.index = index;
        var oldValues = angular.copy(variant.values);
        var editedVariant = angular.copy(variant);

        // $scope.variantForEditing.allowedForSale =  $scope.variantForEditing.enable;
        $scope.showAverageCostEditVariant = true;
        $scope.variantAverageCostDisabled = true;

        $scope.variantAverageCostNotDisabled = function(){
            $scope.variantAverageCostDisabled = false;
            $scope.showAverageCostEditVariant = false;
        }

        $scope.outletsWithVariantForEditing = $scope._variantsByOutlets.filter(function(outlet, index){
            if(outlet.variantArticle === $scope.variantForEditing.article || outlet.article === $scope.variantForEditing.article){
                var filteredOutlet = {};
                filteredOutlet.index = index;
                filteredOutlet.data = angular.copy(outlet);
                outletsWithThisVariantBeforeEditing.push(angular.copy(filteredOutlet));
                return outlet;
            }
        });

        $scope.validateOnExistingVariants = false;

        // filterStoresAndVariants('allOutlets');

        function getDataBeforeEdit() {
            $scope.openDialog = false;

            $scope.variantAlreadyExists = false;
            $scope.skuAlreadyExists = false;

            $scope.existingVariants.some(function(currVariant, index){
                if(currVariant.article === variantBeforeEdit.article){
                    $scope.ware.variations.variants[index] = variantBeforeEdit;
                }
            });

            outletsWithThisVariantBeforeEditing.forEach(function(oldVersionedOutlet){
                $scope._variantsByOutlets[oldVersionedOutlet.index] = angular.copy(oldVersionedOutlet.data);

            });

            // filterStoresAndVariants('outletPreview');

        }

        function saveEditedVariant() {
            if(!$scope.canSaveVariant)
                return;

            var replaceOptionValue = $scope.ware.variations.options.every(function(option){
                if(option.values.length === 1){
                    return true;
                }
            });


            $scope.openDialog = false;
            $scope.variantForEditing.values.forEach(function(value, index){

                var varForChangeCase = "";

                $scope.ware.variations.options[index].values.forEach(function(optionValue, index) {
                    if(optionValue.toLowerCase() === value.toLowerCase() && optionValue !== value) {
                        varForChangeCase = index;
                    }
                });

                if(varForChangeCase !== "") {
                    $scope.ware.variations.options[index].values[varForChangeCase] = value;
                    var variantsLength = $scope.ware.variations.variants.length;
                    for(var i = 0; i < variantsLength; i++) {
                        $scope.ware.variations.variants[i].values.forEach(function(optionValue, index) {
                            if(optionValue.toLowerCase() === value.toLowerCase()) {
                                $scope.ware.variations.variants[i].values[index] = value;
                            }
                        });
                        var outletsWithVariant = $scope._variantsByOutlets.filter(function(outlet, index){
                            if(outlet.variantArticle === $scope.ware.variations.variants[i].article || outlet.article === $scope.ware.variations.variants[i].article){
                                var filteredOutlet = {};
                                filteredOutlet.index = index;
                                filteredOutlet.data = angular.copy(outlet);
                                outletsWithThisVariantBeforeEditing.push(angular.copy(filteredOutlet));
                                return outlet;
                            }
                        });

                        outletsWithVariant.forEach(function(currOutlet){
                            currOutlet.values = $scope.ware.variations.variants[i].values;
                            currOutlet.variantValues = $scope.ware.variations.variants[i].values;
                            currOutlet.variantArticle = $scope.ware.variations.variants[i].article;
                            currOutlet.modified = true;
                        });
                    }
                }

                if($scope.ware.variations.options[index].values.indexOf(value) === -1){
                    if(replaceOptionValue && !$scope.noVariationsFromTheStart && $scope.ware.variations.variants.length === 1){
                        //in that certain case of editing an item with variants, existed from the very start, and if it is a single option value in the current array, then it should be replaced instead of pushing the current option value
                        $scope.ware.variations.options[index].values.splice(0, 1, value);
                    } else {
                        $scope.ware.variations.options[index].values.push(value);
                        //hidden in a comment since formerly a server wanted seeing these data in a request but then a server guru changed his mind :P
                        // $scope.ware.variations.deletedVariants.push({
                        //     values: oldValues
                        //     // currentName: $scope.variantForEditing.values
                        // });
                    }
                }
            });

            $scope.outletsWithVariantForEditing.forEach(function(currOutlet){
                currOutlet.values = $scope.variantForEditing.values;
                currOutlet.variantValues = $scope.variantForEditing.values;
                currOutlet.variantArticle = $scope.variantForEditing.article;
                currOutlet.modified = true;
            });


            if ($state.current.name === 'goods.price') {

                var stateP = $stateParams;
                sortVariants();
                $scope.variantsWithStockAndCurrencyValuesProcessedForServer = angular.copy($scope.ware.variations.variants);
                $scope.variantsWithStockAndCurrencyValuesProcessedForServer.forEach(function(variant){
                    variant.salePrice = (variant.salePrice && variant.salePrice !== null &&  variant.salePrice !== "") ? currencyToCoin( filterRepairCurrenFormatWhenFocus(variant.salePrice) ) : null;
                    variant.freePrice = (variant.salePrice === null || variant.salePrice === "");
                    if(variant.primeCost && variant.primeCost !== null){
                        variant.primeCost = variant.primeCost ? currencyToCoin( variant.primeCost ) : '000';
                        // console.log(variant.primeCost);
                    }

                    if(variant.purchaseCost && variant.purchaseCost !== null){
                        variant.purchaseCost = (variant.purchaseCost && $scope.ware.keepCount) ? currencyToCoin( variant.purchaseCost ) : null;
                    }
                });
                if($scope.outlets.length < 2 && $scope.ware.variations.variants.length > 0) {
                    for(var i = 0; i < $scope._variantsByOutlets.length; i++) {
                        $scope._variantsByOutlets[i].variantArticle = $scope.ware.variations.variants[i].article;
                        $scope._variantsByOutlets[i].enable = $scope.ware.variations.variants[i].enable;
                        $scope._variantsByOutlets[i].salePrice = $scope.ware.variations.variants[i].salePrice;
                    }
                }

                for (let i = 0; i < $scope._variantsByOutlets.length; i++) {
                    let prevWareState = initialVariantsByOutlets.find(outlet => {
                        return outlet.variantArticle === $scope._variantsByOutlets[i].variantArticle &&
                            outlet.outletId === $scope._variantsByOutlets[i].outletId;
                    });
                    if (prevWareState) {
                        $scope._variantsByOutlets[i].stockChanged = $scope._variantsByOutlets[i].count !== prevWareState.count;
                    } else {
                        $scope._variantsByOutlets[i].stockChanged = true;
                    }
                }

                var wareObj = angular.copy($scope.ware);
                wareObj.variations.variants = $scope.variantsWithStockAndCurrencyValuesProcessedForServer;
                wareObj._variantsByOutlets = $scope._variantsByOutlets;
                factoryGoods.editWare(wareObj).then(function(data) {
                    if (data.result === 'ok') {
                        $scope.skuAlreadyExists = false;
                        $scope.dontClose = false;
                        serviceToast.show('EDIT_VARIANT_SUCCESS', 'success');
                        var wareController = vmGoodsListCtrl;

                        var wareFromList = wareController.wares.find(function (w) {
                            return w.id == wareObj.id
                        });

                        wareFromList.expanded=false;
                        /*    var variantsFromList = wareFromList.variants.find(function (w) {
                         return w.variationId == variant.id
                         });*/


                        //  wareController.callDirFunc();

                        wareController.updatingDataAfterEditingAnOption(variant);

                        serviceDialog.close(editVariantWindow);


                        // console.log(wareController);

                        // $state.go($state.$current.name, {   page:           $scope.stateFromList.page,
                        //                                                     limit:          $scope.stateFromList.limit,
                        //                                                     outletId:       $scope.stateFromList.outletId,
                        //                                                     category:       $scope.stateFromList.category,
                        //                                                     inventory:      $scope.stateFromList.inventory,
                        //                                                     checkFromList:  []
                        //                                                     // expandFromList:  []
                        //                                                 }, {reload: true});
                    } else if (data.result === "error" && data.message === "article_exists") {
                        $scope.skuAlreadyExists = true;
                    }
                })
            } else {
                $scope.viewLine = ($scope.ware.variations.variants.length > 5);

                if ($scope.outletsWithVariantForEditing.length > 1) {
                    $scope.variantsSelect = 0;
                    for (var i = 0; i < $scope._variantsByOutlets.length; i++) {
                        if ($scope._variantsByOutlets[i].enable === true) {
                            $scope.variantsSelect++;
                        }
                    }
                    if($scope.variantsSelect === $scope._variantsByOutlets.length) {
                        $scope.ware.variations.allowedForAllOutlets = true;
                    } else {
                        $scope.ware.variations.allowedForAllOutlets = false;
                    }
                } else {
                    variant.enable = $scope.variantForEditing.enable;
                }
            }

            $scope.variantBeforeEdit = {};
            $scope.generatedVariantOptions =  $scope.ware.variations.options;
            copyOfVariationsArr.options = $scope.generatedVariantOptions;
            copyOfVariationsArr.variants = $scope.ware.variations.variants;
            compareArticles ($scope.variantForEditing, index);
            chipsAndVariantsInteraction();
            sortVariants();



        }

        editVariantWindow = serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-variations-editvariant.html',
            title: translate('VARIATIONS.EXISTING_VARIANT_EDIT'),
            currencyOptionsEdit: $currencyOptionsEdit,
            countOptionsEdit: $countOptionsEdit,
            critCountOptionsEdit: $critCountOptionsEdit,
            quantOptionsEdit: $quantOptionsEdit,
            countOptionsEditDivisible: $countOptionsEditDivisible,
            scope: $scope,
            selectChack: variantsSelectFunction(),
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: getDataBeforeEdit
                },
                {
                    class: $scope.arapro ? "green arabic-save" : "green",
                    text: 'ON_SAVE',
                    action: saveEditedVariant
                }
            ]
        });

        $scope.$on('dialogScopeData', function (event, data) {
            $scope.editSingleVariantForm = data;
        })
    }

    function sortVariants() {

        var sortedVariants = [];
        var sortedVariantsByOutlets = [];
        var outletsIds = [];
        var values = [];

        $scope.generatedVariantOptions[0].values.forEach(function (value1) {
            if($scope.generatedVariantOptions[1]) {
                $scope.generatedVariantOptions[1].values.forEach(function (value2) {
                    if($scope.generatedVariantOptions[2]) {
                        $scope.generatedVariantOptions[2].values.forEach(function (value3) {
                            values.push([value1, value2, value3]);
                        })
                    } else {
                        values.push([value1, value2])
                    }
                })
            } else {
                values.push([value1])
            }
        });

        $scope._variantsByOutlets.forEach(function (variant) {
            if(outletsIds.indexOf(variant.outletId) === -1) {
                outletsIds.push(variant.outletId);
            }
        });

        values.forEach(function (valuesArr) {
            for(var i = 0, length = $scope.ware.variations.variants.length; i < length; i++) {
                if(angular.equals(valuesArr, $scope.ware.variations.variants[i].values)) {
                    sortedVariants.push($scope.ware.variations.variants[i]);
                    break;
                }
            }
        });

        outletsIds.forEach(function (outletId) {
            var variantByCurrOutlet = $scope._variantsByOutlets.filter(function (variant) {
                return variant.outletId === outletId;
            });

            values.forEach(function (valuesArr) {
                for(var i = 0, length = variantByCurrOutlet.length; i < length; i++) {
                    if(angular.equals(valuesArr, variantByCurrOutlet[i].variantValues)) {
                        sortedVariantsByOutlets.push(variantByCurrOutlet[i]);
                        break;
                    }
                }
            })
        });

        $scope.ware.variations.variants = sortedVariants;
        $scope._variantsByOutlets = sortedVariantsByOutlets;

    }

    $scope.$on('duplicatedValuesErrorMessage', function(data){
        $scope.alertMessage = data.targetScope.alertMessage;
    });

    shareScope.prepForBroadcast($scope);
    $scope.$on('currencyLocalizationData', function(data){
        console.log(data);
    });

    $scope.popover = factoryPopover;

    $scope.openPopovers = {
        cost: false
    };
  
    const onResize = () => {
      $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };
  
    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);

    return this;
}

export default {
    type: 'controller',
    name: 'createEditVariantControl',
    value: createEditVariantControl
};
