/**
 * Created by k.yarosh on 10/03/16.
 */

import angular from 'angular';

editPaymentTypeControl.$inject = [
    '$stateParams',
    '$scope',
    '$state',
    '$filter',
    'payTypesOperationsFactory',
    '$tipsData',
    '$payConst',
    'serviceToast',
    'serviceDialog',
    '$paymentTypesInfo',
    '$cookies',
    '$profile',
    'paymentRound',
    '$currencyOptionsShow',
    'lodash',
    '$translate',
    '$shortOutlets',
    'paymentMethodsWithTips',
    'dialogs'
];

function editPaymentTypeControl(
    $stateParams,
    $scope,
    $state,
    $filter,
    payTypesOperationsFactory,
    $tipsData,
    $payConst,
    serviceToast,
    serviceDialog,
    $paymentTypesInfo,
    $cookies,
    $profile,
    paymentRound,
    $currencyOptionsShow,
    lodash,
    $translate,
    $shortOutlets,
    paymentMethodsWithTips,
    dialogs) {


    let vm = this,
        translate = $filter('translate');
    let initParams;
    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
    function isChanged() {
        let currentParams = {
            paymentTypeMethod: vm.paymentTypeMethod,
            paymentTypeName: vm.paymentTypeName,
            onePayPublicKey: vm.onePayPublicKey,
            onePayMerchantId: vm.onePayMerchantId,
            tipsData: {
                tipsEnable: vm.tipsData.tipsEnable,
                tipsCustomEnable: vm.tipsData.tipsCustomEnable,
            },
            tipsRoundedForInput: angular.copy(vm.tipsRoundedForInput),
            outlets: $scope.outlets.map(item => {
                return {
                    select: item._select,
                    name: item.name
                }
            })
        };

        return !angular.equals(initParams, currentParams);
    }

    window.$translate = translate;
    vm.local = $profile.cashFractionDigits;

    vm.payMethods = $scope.$parent.payMethods.map(val => {
        return {
            code: val,
            name: $translate.instant('PAYMENT_TYPES.' + val)
        };
    });
    vm.paymentsMethodsExists = $scope.$parent.paymentsMethodsExists;
    vm.tipsData = angular.copy($tipsData);
    vm.intervalsWithCoins = angular.copy(paymentRound.intervalsWithCoins);
    vm.roundingRuleConst = paymentRound.roundingRuleConst
    vm.roundingRule = [];
    vm.currencyOptionsShow = $currencyOptionsShow;

    vm.paymentTypes = angular.copy($paymentTypesInfo.paymentTypes);

    $scope.outlets = $shortOutlets;

    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
    $scope.showLine = true; // показывать линию в главном блоке
    $scope.showAllOutlets = false;   // показывать весь блок с заведениями

    $scope.outletSelect = $scope.outlets;
    vm.allOutlets = {};

    vm.uniqueName = true;

    $scope.$watch('outlets', function(newVal){

        $scope.outletSelect = [];
        for(let j = 0; j < newVal.length; j++){
            if(newVal[j]._select === true){
                $scope.outletSelect.push(newVal[j].id);
            }
        }

        vm.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;
    }, true);


    $scope.outlets.forEach((outlet) => {
        if (vm.tipsData.outletIds.indexOf(outlet.id) > -1)
            outlet["_select"] = true;
    })

    $scope.changeAll = function(){
        if(vm.allOutlets._select === true) {
            $scope.outlets.forEach((outlet) => {
                outlet["_select"] = true;
            });
            vm.allOutlets._select = true;
        } else {
            $scope.outlets.forEach((outlet) => {
                outlet["_select"] = false;
            });
            vm.allOutlets._select = false;
        }
    };

    $scope.showBlock = function (flag) {
        $scope.showAllOutlets = flag;
        $scope.showLine = !flag;
    };
//** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
    if (vm.tipsData.rounding !== null){
        vm.actualInterval = (vm.local === 0) ? vm.tipsData.rounding.value : $filter('coinToCurrency')(vm.tipsData.rounding.value, vm.currencyOptionsShow.decimalCharacter);
        $scope.changeRulesList = changeRulesList;
        $scope.values = values;
        vm.actualRule = (vm.tipsData.rounding.value === 5 && vm.tipsData.rounding.type === 'MIDDLE_UP') ? 'NEAREST' : vm.tipsData.rounding.type;
        changeRulesList(vm.tipsData.rounding.value, vm.actualRule);
        $scope.noRule = (vm.tipsData.rounding.value === 1);
        $scope.fiveNearest = (vm.tipsData.rounding.value === 5 && vm.tipsData.rounding.type === 'MIDDLE_UP');
        $scope.allCases = (vm.tipsData.rounding.value !== 1 && !(vm.tipsData.rounding.value === 5 && vm.tipsData.rounding.type === 'MIDDLE_UP'));
    }

    vm.allOutlets._select =  vm.tipsData.allowedForAllOutlets;

    if(vm.local !== 0){
        vm.intervalsWithCoins.map(function(item){
            item.int = $filter('coinToCurrency')(item.int,vm.currencyOptionsShow.decimalCharacter);
        });
    }
    vm.showDelBucket = true;
    vm.tipsAmounts = (vm.tipsData && vm.tipsData.tipsConfig && vm.tipsData.tipsConfig.tips) ? angular.copy(vm.tipsData.tipsConfig.tips) : angular.copy($payConst.tips);
    vm.tipsRoundedForInput = roundTipsForInput(vm.tipsAmounts);
    vm.tipsData.tipsEnable = angular.copy($tipsData.tipsEnable) || false;
    vm.tipsData.tipsCustomEnable = $tipsData.tipsCustomEnable !== null && vm.tipsData.tipsEnable === true ? angular.copy($tipsData.tipsCustomEnable) : true;

    vm.paymentTypeId = vm.tipsData.id || $state.params.paymentTypeId;
    vm.paymentTypeMethod = vm.tipsData.method;
    vm.paymentTypeMethod = (vm.paymentTypeMethod === "VANTIV") ? "WORLDPAY" : vm.paymentTypeMethod;

    vm.paymentTypeMethodLowerCase = vm.paymentTypeMethod.toLowerCase();
    vm.paymentTypeName = vm.tipsData.name || translate('PAYMENT_TYPES.'+ vm.paymentTypeMethod);
    vm.onePayPublicKey = vm.tipsData.onePayPublicKey;
    vm.onePayMerchantId = vm.tipsData.onePayMerchantId;
    vm.vantivExists =  $scope.$parent.vantivExists;
    vm.vantivId = $scope.$parent.vantivId;
    vm.isSavingNow = true;
    vm.progress = false;

    vm.paymentMethodUsingTips = ["WORLDPAY", "SUMUP", "YOCO", "IZETTLE", "KICC"];

    let paymentNameChanged = vm.tipsData.method !== "VANTIV" ? vm.paymentTypeName !== translate('PAYMENT_TYPES.'+vm.tipsData.method)
                                : vm.paymentTypeName !== translate('PAYMENT_TYPES.WORLDPAY');
    let initialPayMethod = angular.copy(vm.tipsData.method);


    vm.onSave = onSave;
    vm.onCancel = onCancel;
    vm.onDel = onDel;
    vm.definePaymentTypeName = definePaymentTypeName;
    vm.notifyAboutNameChanged = notifyAboutNameChanged;
    vm.sumUpLogout = sumUpLogout;


// *** КОД НАПИСАННЫЙ ПОД SUMUP ***
    vm.realState = $state.current.name;
    vm.typeOfstate = vm.tipsData.method;
    vm.profile = $profile.sumup;
    vm.sumupExists = $scope.$parent.sumupExists;
    (vm.tipsData.name == null)? vm.tempName = vm.paymentTypeName : vm.tempName = vm.tipsData.name;
// *** КОД НАПИСАННЫЙ ПОД SUMUP ***

// *** КОД НАПИСАННЫЙ ПОД izettle - запрет создания 2-го типа оплат ***
    vm.izettleExists = $scope.$parent.izettleExists;
// *** КОД НАПИСАННЫЙ ПОД izettle - запрет создания 2-го типа оплат ***

    initParams = {
        paymentTypeMethod: vm.paymentTypeMethod,
        paymentTypeName: vm.paymentTypeName,
        onePayPublicKey: vm.onePayPublicKey,
        onePayMerchantId: vm.onePayMerchantId,
        tipsData: {
            tipsEnable: vm.tipsData.tipsEnable,
            tipsCustomEnable: vm.tipsData.tipsCustomEnable,
        },
        tipsRoundedForInput: angular.copy(vm.tipsRoundedForInput),
        outlets: $scope.outlets.map(item => {
            return {
                select: item._select,
                name: item.name
            }
        })
    };

    defineClass (vm.paymentTypeMethod);

    function notifyAboutNameChanged() {
        let nameFromTemplate = translate("PAYMENT_TYPES." + vm.paymentTypeMethod);
        paymentNameChanged = nameFromTemplate !== vm.paymentTypeName;
    }

    function definePaymentTypeName() {
        if (!paymentNameChanged) {
            vm.paymentTypeName = translate("PAYMENT_TYPES." + vm.paymentTypeMethod);
        }
        defineClass(vm.paymentTypeMethod);
    }

    function roundTipsForInput (tipsArray) {
        let roundedTipsArray = [];
        for(let i = 0; i < tipsArray.length; i++) {
            let roundedTip = $filter( 'coinToCurrency' )( tipsArray[i], ".");
            roundedTipsArray.push(roundedTip);
        }
        return roundedTipsArray;

    }


    function roundTipsForServer (tipsArray) {
        let roundedTipsArray = [];
        for(let i = 0; i < tipsArray.length; i++) {
            let roundedTip = $filter( 'currencyToCoin' )(tipsArray[i]);
            roundedTipsArray.push(roundedTip);
        }
        return roundedTipsArray;

    }


    function onSave (saveButton) {

        if($scope.editPaymentTypeForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.editPaymentTypeForm.focusFirstInvalidControl();
            return;
        }

        vm.paymentTypeMethod = (vm.paymentTypeMethod === "WORLDPAY") ? "VANTIV" : vm.paymentTypeMethod;
        let redirectSumupOauth = !vm.profile.isSumupConnected && !saveButton;

        if (vm.vantivId != vm.paymentTypeId && vm.paymentTypeMethod =="VANTIV" && vm.vantivExists == true) {
            singleBtnDialoguePopUp(
                'PAYMENT_TYPES.EDITING_IS_PROHIBITED',
                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.WORLDPAY')}
            );
        } else if (initialPayMethod =="VANTIV" && vm.paymentTypeMethod !="VANTIV") {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title:  translate('PAYMENT_TYPES.EDITING_PAYTYPE'),
                content: translate('PAYMENT_TYPES.VANTIV_UNBIND_POS'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'CONTINUE',
                        action: function() {
                            vm.isSavingNow = true;
                            savePayType()
                        }
                    }
                ]
            });
        } else if (vm.paymentsMethodsExists.indexOf(vm.paymentTypeMethod) > -1 && vm.typeOfstate != vm.paymentTypeMethod && vm.paymentTypeMethod !== "NONINTEGRATEDCARD") {
            singleBtnDialoguePopUp(
                'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.' + vm.paymentTypeMethod)}
            );
        } else if (vm.tipsData.method == vm.paymentTypeMethod && vm.tempName == vm.paymentTypeName
            && lodash.isEqual(lodash.sortBy(vm.tipsAmounts), lodash.sortBy(roundTipsForServer(vm.tipsRoundedForInput)))
            && (vm.typeOfstate == "SUMUP" || vm.typeOfstate == "YOCO" || vm.typeOfstate == "IZETTLE" || vm.typeOfstate == "KICC")
            && !redirectSumupOauth && vm.tipsData.tipsEnable === $tipsData.tipsEnable
            && vm.tipsData.tipsCustomEnable === $tipsData.tipsCustomEnable && vm.tipsData.outletIds === $scope.outletSelect
            && vm.tipsData.allowedForAllOutlets === vm.allOutlets._select) {
            onCancel();
        }
        else if (vm.typeOfstate === "ONEPAY" && vm.tipsData.method === vm.paymentTypeMethod && vm.tempName === vm.paymentTypeName
            && $tipsData.onePayMerchantId === vm.onePayMerchantId && $tipsData.onePayPublicKey === vm.onePayPublicKey
            && vm.tipsData.outletIds === $scope.outletSelect && vm.tipsData.allowedForAllOutlets === vm.allOutlets._select) {
            onCancel();
        }
        else if (vm.tipsData.method === vm.paymentTypeMethod
            && vm.tempName === vm.paymentTypeName && vm.tipsData.outletIds === $scope.outletSelect
            && vm.tipsData.allowedForAllOutlets === vm.allOutlets._select && vm.typeOfstate !== "SUMUP"
            && vm.typeOfstate !== "VANTIV" && vm.typeOfstate !== "YOCO"
            && vm.typeOfstate !== "IZETTLE" && vm.typeOfstate !== "KICC"
            && vm.typeOfstate !== "CASH" && vm.typeOfstate !== "ONEPAY") {
            onCancel();
        } else {
            savePayType();
        }

        function rounding() {
            if (vm.typeOfstate !== "CASH") {
                return null;
            } else {
                return {value: (vm.local === 0) ? parseFloat(vm.actualInterval) : parseFloat($filter('currencyToCoin')(vm.actualInterval, vm.currencyOptionsShow.decimalCharacter)),
                        type: ((vm.actualInterval === 1 && vm.local === 0) || (vm.actualInterval === 0.01 && vm.local !== 0)) ? null : (vm.actualRule === 'NEAREST') ? 'MIDDLE_UP' : vm.actualRule}
            }

        }

        function savePayType () {
            if (vm.isSavingNow === true) {
                vm.isSavingNow = false;

                if(paymentMethodsWithTips.indexOf(vm.paymentTypeMethod) !== -1) {
                    if(vm.tipsData.tipsEnable && parseFloat(roundTipsForServer(vm.tipsRoundedForInput[0])) === 0 &&
                        parseFloat(roundTipsForServer(vm.tipsRoundedForInput[1])) === 0 && parseFloat(roundTipsForServer(vm.tipsRoundedForInput[2])) === 0 &&
                        !vm.tipsData.tipsCustomEnable) {
                        vm.tipsData.tipsEnable = false;
                    }
                }

                let paymentTypeName = null;
                if ((vm.paymentTypeName && paymentNameChanged) || vm.paymentTypeMethod === 'VANTIV' || vm.paymentTypeMethod === 'COINEY') {
                    paymentTypeName = vm.paymentTypeName;
                }

                let rand = function() {
                    return Math.random().toString(36).substr(2); // remove `0.`
                };

                let token = rand() + rand(); // to make it longer

                vm.progress = true;
                payTypesOperationsFactory.editPayType({
                    'method': vm.paymentTypeMethod,
                    'name': paymentTypeName,
                    'paymentTypeId': vm.paymentTypeId,
                    'onePayPublicKey': vm.onePayPublicKey,
                    'onePayMerchantId': vm.onePayMerchantId,
                    'tipsConfig': {
                        'tips': (paymentMethodsWithTips.indexOf(vm.paymentTypeMethod) !== -1) ? roundTipsForServer(vm.tipsRoundedForInput) : null
                    },
                    'tipsEnable': vm.tipsData.tipsEnable,
                    'tipsCustomEnable': vm.tipsData.tipsCustomEnable,
                    'rounding': rounding(),
                    'state':  (vm.paymentTypeMethod === "SUMUP" && !saveButton) ? token : null,
                    'allowedForAllOutlets': vm.allOutlets._select,
                    'outletIds': $scope.outletSelect
                })
                    .then(function (res) {
                        if (res.result === "ok") {
                            offStateChangeStart();
                            vm.isSavingNow = false;
                            serviceToast.show('PAYMENT_TYPES.PAYMENT_TYPE_EDITED', 'success');
                            if (vm.paymentTypeMethod == "SUMUP" && redirectSumupOauth) {
                                document.location.href = "https://api.sumup.com/authorize?client_id="+ vm.profile.clientId +"&response_type=code&redirect_uri="+ vm.profile.redirectUri+"&state="+token;
                            } else {
                                backToList({
                                    paymentTypeId: vm.paymentTypeId,
                                    paymentTypeMethod: vm.paymentTypeMethod,
                                    paymentTypeName: vm.paymentTypeName })

                            }
                        } else if (res.result === "error") {
                            switch (res.message) {
                                case 'payment_type_exists':
                                    vm.uniqueName = false;
                                    vm.isSavingNow = true
                                    serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                                    break;
                                case 'vantiv_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.EDITING_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.WORLDPAY')}
                                    );
                                    break;
                                case 'cardconnect_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.FIRSTDATA')}
                                    );
                                    break;
                                case 'sumup_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.SUMUP')}
                                    );
                                    break;
                                case 'tyro_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.TYRO')}
                                    );
                                    break;
                                case 'izettle_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.IZETTLE')}
                                    );
                                    break;
                                case 'smartpay_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.SMARTPAY')}
                                    );
                                    break;
                                case 'nice_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.NICEPAY')}
                                    );
                                    break;
                                case 'yoco_already_exists':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.SAME_PAYMENT_TYPE', {paymentType: translate('PAYMENT_TYPES.YOCO')}
                                    );
                                    break;
                                case 'not_valid_public_key':
                                    singleBtnDialoguePopUp(
                                        'PAYMENT_TYPES.CREATION_IS_PROHIBITED',
                                        'PAYMENT_TYPES.NOT_VALID_PUBLIC_KEY'
                                    );
                                    break;
                                case 'payment_type_deleted':
                                    offStateChangeStart();
                                    dialogs.itemDeletedEarlier().then(backToList);
                                    break;
                                default:
                                    serviceToast.show('SAVE_ERROR', 'error');
                            }
                        } else {
                            serviceToast.show('SAVE_ERROR', 'error');
                        }
                    })
                    .finally(() => {
                        vm.progress = false;
                    })
            }
        }

    }

    function onCancel () {
        // removeCookies();
        backToList();
    }

    function onDel() {

       let selectedPaymentTypeId = [],
           deleteMessage = 'PAYMENT_TYPES.PAYMENT_TYPE_DELETED';
        selectedPaymentTypeId.push(parseFloat(vm.paymentTypeId));

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE'),
            content: translate((vm.paymentTypeMethod === "VANTIV") ? 'PAYMENT_TYPES.DELETE_PAYMENTTYPE_VANTIV_TEXT' : (vm.paymentTypeMethod === "POSLINK") ? 'PAYMENT_TYPES.DELETE_PAYMENTTYPE_POSLINK_TEXT' : 'PAYMENT_TYPES.DELETE_PAYMENTTYPE_TEXT'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: delPayType
                }
            ]
        });

        function delPayType() {
            payTypesOperationsFactory.deletePayType({
                paymentTypesIds: selectedPaymentTypeId
            })
                .then(function (res) {
                    if (res.result === "ok") {
                        offStateChangeStart();
                        serviceToast.show(deleteMessage, 'success');
                        backToList();
                    } else if (res.result === "error" && res.message === 'payment_type_deleted') {
                        offStateChangeStart();
                        dialogs.itemDeletedEarlier().then(backToList);
                    } else if ((vm.paymentTypeMethod === "CASH") || (res.result === "error") &&(res.message="cash_type_can_not_be_deleted")) {
                        singleBtnDialoguePopUp(
                            'PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE',
                            'PAYMENT_TYPES.DELETE_PAYMENTTYPE_IMPOSSIBLE', {name: '"' + vm.paymentTypeMethod + '"'}
                        );
                    } else {
                        serviceToast.show( 'SAVE_ERROR', 'error' );
                    }
                });
        }
    }

    function singleBtnDialoguePopUp (title, content, variable) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate(title),
            content: translate(content, variable),
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        vm.isSavingNow = true;
                    }
                }
            ]
        })
    }
    function changeRulesList(int, rule){
        if(typeof int  === "string" && int !== "1" && int !== "5" && int !== "10" && int !== "50" && int !== "100") {
            int = parseFloat($filter('currencyToCoin')(int, vm.currencyOptionsShow.decimalCharacter));
        }
        vm.roundingRule = [];
        if((int === 1 && vm.local === 0) || ((int === 0.01 || int === 1) && vm.local !== 0)) {
            $scope.noRule = true;
            $scope.fiveNearest = false;
            $scope.allCases = false;
            $scope.justOne = false;
            $scope.middleValue = false;
        } else if ((int === 5 && vm.local === 0) || ((int === 0.05 || int === 5) && vm.local !== 0)) {
            $scope.noRule = false;
            $scope.justOne = false;
            $scope.middleValue = false;
            vm.roundingRule = vm.roundingRuleConst
              .filter(item => ['NEAREST', 'ALWAYS_UP', 'ALWAYS_DOWN'].includes(item.rule))
              .map(item => ({
                  rule: item.rule,
                  text: $translate.instant(`PAYMENT_TYPES.${item.rule}`)
              }));
            vm.actualRule = (vm.actualRule === null || vm.actualRule === 'MIDDLE_DOWN' || vm.actualRule === 'MIDDLE_UP') ? 'NEAREST' : vm.actualRule;
            if (rule === 'NEAREST' || rule === 'MIDDLE_UP' || rule === 'MIDDLE_DOWN' || rule === null) {
                $scope.fiveNearest = true;
                $scope.allCases = false;
            } else {
                $scope.allCases = true;
                $scope.fiveNearest = false;
                vm.valuesArr = [values(1), values(4), values(6), values(9)];
                if (rule === 'ALWAYS_DOWN') {
                    vm.value = [values(0), values(5)];
                } else if (rule === 'ALWAYS_UP') {
                    vm.value = [values(5), values(10)];
                }
            }
            if (vm.actualRule === null) {
                vm.actualRule = vm.roundingRule[0];
            }
        } else {
            $scope.fiveNearest = false;
            $scope.noRule = false;
            $scope.justOne = false;
            $scope.middleValue = false;
            $scope.allCases = true;
            vm.actualRule = (vm.actualRule === null || vm.actualRule === 'NEAREST') ? 'MIDDLE_UP' : vm.actualRule;
            if((int === 10 && vm.local === 0) || ((int === 0.10 || int === 10) && vm.local !== 0)){
                $scope.denom = values(5);
                if (rule === 'MIDDLE_UP' || rule === 'NEAREST' || rule === null){
                    vm.valuesArr = [values(1), values(4), values(5), values(9)];
                    vm.value = [values(0), values(10)];
                } else if (rule === 'MIDDLE_DOWN') {
                    vm.valuesArr = [values(1), values(5), values(6), values(9)];
                    vm.value = [values(0), values(10)];
                } else{
                    $scope.justOne = true;
                    vm.valuesArr = [values(1), values(9)];
                    if (rule === 'ALWAYS_DOWN') {
                        vm.value = [values(0)];
                    } else if (rule === 'ALWAYS_UP') {
                        vm.value = [values(10)];
                    }
                }

            } else if ((int === 50 && vm.local === 0) || ((int === 0.50 || int === 50) && vm.local !== 0)) {
                $scope.denom = values(25);
                if (rule === 'MIDDLE_UP' || rule === 'NEAREST' || rule === null) {
                    $scope.middleValue = true;
                    vm.valuesArr = [values(1), values(24), values(75), values(99), values(25), values(49), values(51), values(74)];
                    vm.value = [values(0), values(100), values(50)];
                } else if (rule === 'MIDDLE_DOWN') {
                    $scope.middleValue = true;
                    vm.valuesArr = [values(1), values(25), values(76), values(99), values(26), values(49), values(51), values(75)];
                    vm.value = [values(0), values(100), values(50)];
                } else {
                    vm.valuesArr = [values(1), values(49), values(51), values(99)];
                    if (rule === 'ALWAYS_DOWN') {
                        vm.value = [values(0), values(50)];
                    } else if (rule === 'ALWAYS_UP') {
                        vm.value = [values(50), values(100)];
                    }
                }
            } else if ((int === 100 && vm.local === 0) || ((int === 1 || int === 100) && vm.local !== 0)) {
                $scope.denom = values(50);
                if (rule === 'MIDDLE_UP' || rule === 'NEAREST' || rule === null) {
                    vm.valuesArr = [values(1), values(49), values(50), values(99)];
                    vm.value = [values(0), values(100)];
                } else if (rule === 'MIDDLE_DOWN') {
                    vm.valuesArr = [values(1), values(50), values(51), values(99)];
                    vm.value = [values(0), values(100)];
                } else {
                    $scope.justOne = true;
                    vm.valuesArr = [values(1), values(99)];
                    if (rule === 'ALWAYS_DOWN') {
                        vm.value = [values(0)];
                    } else if (rule === 'ALWAYS_UP') {
                        vm.value = [values(100)];
                    }
                }
            }
            vm.roundingRule = vm.roundingRuleConst.filter(item => item.rule !== 'NEAREST').map(item => ({
                rule: item.rule,
                text: $translate.instant(`PAYMENT_TYPES.${item.rule}`, {denom: $scope.denom})
            }));
        }
    }
    function values(val1, val2, val3, val4){
        if (vm.local === 0) {
            val1 = val1.toString();
            val2 = (val2) ? ", " + val2.toString() : "";
            val3 = (val3) ? ", " + val3.toString() : "";
            val4 = (val4) ? ", " + val4.toString() : "";
            return val1 + val2 + val3 + val4;
        } else {
            val1 = $filter('coinToCurrency')(val1, vm.currencyOptionsShow.decimalCharacter);
            val2 = (val2) ? ", " + $filter('coinToCurrency')(val2, vm.currencyOptionsShow.decimalCharacter) : "";
            val3 = (val3) ? ", " + $filter('coinToCurrency')(val3, vm.currencyOptionsShow.decimalCharacter) : "";
            val4 = (val4) ? ", " + $filter('coinToCurrency')(val4, vm.currencyOptionsShow.decimalCharacter) : "";
            return val1 + val2 + val3 + val4;
        }
    }
    function defineClass (paymentType) {
        if (paymentType === 'OTHER') {
            vm.paymenttype = 'othertype';
        } else if (paymentType === 'CHEQUE') {
            vm.paymenttype = 'chequetype';
        } else if (paymentType === 'CASH') {
            vm.paymenttype = '';
        } else {
            vm.paymenttype = 'cardtype';
        }
    }

    function sumUpLogout(event) {
        event.preventDefault();
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('PAYMENT_TYPES.SUMUP_DISCONNECT_TITLE'),
            content: translate('PAYMENT_TYPES.SUMUP_DISCONNECT_MESSAGE'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'md-primary',
                    text: 'CONTINUE_CONTINUE',
                    action: function () {
                        payTypesOperationsFactory.sumUpLogout({
                            paymentTypeId: vm.paymentTypeId
                        }).then(function (res) {
                            if(res.result === "ok") {
                                vm.profile.isSumupConnected = false;
                            }
                        })
                    }
                }
            ]
        })
    }

    function backToList(params) {
        if (!params) {
            params = {};
        }
        $state.go('settings.paytypes', params);
    }
}

export default {
    type: 'controller',
    name: 'editPaymentTypeControl',
    value: editPaymentTypeControl
};
