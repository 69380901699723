/**
 * Created by kseniya.y on 22.11.16.
 * @memberof getOutletsList
 * @ngdoc factory
 * @name getOutletsList
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param factoryErrorAlert {service} error handler service
 */

import angular from 'angular';

getOutletsList.$inject = ['promiseOperation', '$filter'];

function getOutletsList(promiseOperation, $filter) {

    var id;

    return {

        getOutlets: getOutlets,
        saveNewOutlets: saveNewOutlets,
        getOutletById: getOutletById,
        editOutlet: editOutlet,
        // delCashBox: delCashBox,
        addOutlet: addOutlet,
        delOutlet: delOutlet,
        getShortOutlets: getShortOutlets
    };


    /**
     * @ngdoc function
     * @name getOutlets
     * @param {Object} params {"limit":10, "offset":0}
     * @returns {Promise}
     */

    function getOutlets(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getoutlets',
            params,
            successGetOutlets
        );
    }

    function successGetOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data;
            }
        }
    }


    /**
     * @ngdoc function
     * @name saveNewOutlets
     * @param {Object} params {"name":"Outlet1", "address":"Kiev", "comment":"Comment", "location":"55.7571;37.6047", "phone":"+380508989898", "schedule":[{"m1":0,"m2":0,"d":124,"h1":10,"h2":20}], "receiptHeader":"Header", "receiptFooter":"Footer", "receiptLang":"rus"}
     * @returns {Promise}
     */

    function saveNewOutlets(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/createoutlet',
            params,
            successsaveNewOutlets
        );
    }

    function successsaveNewOutlets(response) {

        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data;

            }
        }
    }


    /**
     * @ngdoc function
     * @name getOutletById
     * @description
     * Send request to a server in order to get data of a certain outlet by id
     * @param {Object} params {"outletId":"112"}
     * @returns {Promise}
     */

    function getOutletById(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getoutletbyid',
            params,
            successGetOutletById
        );
    }

    function successGetOutletById(response) {

        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {
                response.data.comment   = (response.data.comment === "" || response.data.comment === null)? null : response.data.comment;
                response.data.phone     = (response.data.phone === ""   || response.data.phone === null)?   null : response.data.phone;
                response.data.address   = (response.data.address === "" || response.data.address === null)? null : response.data.address;

                return response.data;

            }
        }
    }


    /**
     * @ngdoc function
     * @name editOutlet
     * @param {Object} params {"outletId":"112","name":"Outlet1", "address":"Kiev", "comment":"Comment", "location":"55.7571;37.6047", "phone":"+380508989898", "schedule":[{"m1":0,"m2":0,"d":124,"h1":10,"h2":20}], "receiptHeader":"Header", "receiptFooter":"Footer", "receiptLang":"rus"}
     * @returns {Promise}
     */
    function editOutlet(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/editoutlet',
            params,
            successEditOutlet
        );
    }

    function successEditOutlet(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if (response.data.result === "error") {
                return response.data;
            }
        }
    }


    /**
     * @ngdoc function
     * @name delOutlet
     * @param {Object} params {"outletsIds":[1,2,3]}}
     * @returns {Promise}
     */


    function delOutlet(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/deleteoutlets',
            params,
            successDelOutlet
        );
    }

    function successDelOutlet(response) {
        return response.data;
    }


    /**
     * @ngdoc function
     * @name addOutlet
     * @param {Object} params {"name":"Outlet1", "address":"Kiev", "comment":"Comment", "location":"55.7571;37.6047", "phone":"+380508989898", "schedule":[{"m1":0,"m2":0,"d":124,"h1":10,"h2":20}], "receiptHeader":"Header", "receiptFooter":"Footer", "receiptLang":"rus"}
     * @returns {Promise}
     */

    function addOutlet(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/createoutlet',
            params,
            successCreateOutlet
        );
    }

    function successCreateOutlet(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if (response.data.result === "error") {
                return response.data;
            }
        }
    }


    // получаем краткий список торговых точек
    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getshortoutlets',
                params,
                successGetShortOutlets
            );
    }
    // получаем краткий список торговых точек
}

export default {
    type: 'factory',
    name: 'getOutletsList',
    value: getOutletsList
};