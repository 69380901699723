/**
 * Created by A.Sirosh on 11.12.2015.
 * parse img for server;
 * @memberof parseImgUrl
 * @ngdoc servise
 * @name parseImgUrl
 */

parseImgUrl.$inject = [];

function parseImgUrl() {

	/**
	 * Return img string for url
	 * @param img
	 * @returns {String}
	 */
	function parseImgURL(img){
		var imgString;

		if (img === null || img === undefined) {
			imgString = null;
		} else {
			if (img.indexOf('http') !== -1) {
				imgString = img;
			} else {
				imgString = null;
			}
		}

		return imgString;
	}

	function parseImgString(imgString){
		var imgBase64;

		if(imgString === null || imgString === undefined){
			imgBase64 = null;
		} else{
			if(/^data:image\/.+;base64,/.test(imgString)){
				imgBase64 = imgString.replace(/^data:image\/.+;base64,/, '');
			} else {
				imgBase64 = null;
			}
		}

		return imgBase64;
	}

	this.parseImgURL = parseImgURL;
	this.parseImgString = parseImgString;
}

export default {
	type: 'service',
	name: 'parseImgUrl',
	value: parseImgUrl
};