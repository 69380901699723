dialogsContainer.$inject = ['serviceDialog'];

function dialogsContainer(serviceDialog) {
    return {
        restrict: 'C',
        controller: dialogsContainerController,
        template: `
            <div>
                <div ng-repeat="dialog in serviceDialog.dialogs | orderBy:\'_index\'" ng-class="dialog.class">
                    <div ng-include="dialog.templateUrl" dialog-scope="dialog" ng-class="dialog.templateClass"></div>
                </div>
            </div>`,
        link: function ( scope, el, attr ) {

            scope.serviceDialog = serviceDialog;
            serviceDialog.actionShow = function () {
                if ( this.isShown ) {
                    el.css( 'display', 'block' );
                    el.children().css( 'opacity', '0' );
                    setTimeout( function () {
                        let tabPressCount = 0;
                        el.children().css( 'opacity', '1' );

                        window.addEventListener( 'keyup', function( e ) {
                            if (e.which == 9 && tabPressCount === 0) {
                                const dialog = document.getElementsByClassName("dialogs-container")[0];
                                if (dialog.getElementsByTagName("input").length > 0) {
                                    dialog.getElementsByTagName("input")[0].focus();
                                } else if (dialog.getElementsByTagName("textarea").length > 0) {
                                    dialog.getElementsByTagName("textarea")[0].focus();
                                } else if (dialog.getElementsByTagName("button").length > 0) {
                                    dialog.getElementsByTagName("button")[0].focus();
                                }
                                tabPressCount ++;
                            }
                        });
                        scope.$apply();
                    }, 1 );
                } else {
                    el.children().css( 'opacity', '0' );
                    setTimeout( function () {
                        if (el.children()[0].style.opacity === "0")
                            el.css( 'display', 'none' );
                    }, 222 );
                }
            };
            serviceDialog.actionShow();
        }
    };
}

dialogsContainerController.$inject = ['$scope'];
function dialogsContainerController($scope) {
    this.getScope = function () {
        return $scope;
    };
}

export default dialogsContainer;
