import angular from 'angular';

createDirective.$inject = ['$parse'];

function createDirective($parse) {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        if (!ngModel) {
            return;
        }
        let itemsGetter = $parse(attrs.lvUniqueItem)(scope);
        let currentItemIndex = $parse(attrs.lvUniqueItemIndex);

        ngModel.$validators.unique = function(modelValue, viewValue) {
            if (viewValue) {
                let items = angular.isFunction(itemsGetter) ? itemsGetter() : itemsGetter;
                for (let i = 0; i < items.length; i++) {
                    if (currentItemIndex(scope) === i) {
                        continue;
                    }
                    if (items[i] == null) {
                        continue;
                    }
                    if (items[i].toLowerCase() === viewValue.toLowerCase()) {
                        return false;
                    }
                }
            }
            return true;
        }
    }

}

export default {
    type: 'directive',
    name: 'lvUniqueItem',
    value: createDirective
};
