/**
 * Created by Kiskenbassker on 5.01.18
 *
 * Compares children elements width to parent element's one and changes font-size if children are wider than parent.
 *
 * Id 'widthToCompareWith' must be added to parent element you compare children element to.
 * Class 'width-compared' must be added to elements whose total width you want to compare to parent element's one.
 */

fontSizeFromWidth.$inject = [];

function fontSizeFromWidth() {
    return {
        restrict: 'A',
        scope: {
            fontSizeFromWidth: "=fontSizeFromWidth"
        },
        link: linkFn,
    };

    function linkFn(scope, element, attr, controller) {
        var previousPosition = element[0].getBoundingClientRect();
        // console.log(previousPosition);

        onFrame();

        function onFrame() {
            var currentPosition = element[0].getBoundingClientRect();

            if (previousPosition.width !== currentPosition.width) {
                console.log("It changed from ", previousPosition.width, " to ", currentPosition.width);

                // if(scope.$parent.changeFont === true && !element[0].classList.contains('total-24')) {
                //     // console.log("Change Font changed from ", oldValue, " to ", newValue);
                //     element[0].classList.add('total-24');
                // } else if(scope.$parent.changeFont === false && element[0].classList.contains('total-24')) {
                //     element[0].classList.remove('total-24');
                // }

                scope.$parent.offsetsArray[scope.fontSizeFromWidth] = Math.round(currentPosition.width);
            }

            previousPosition = currentPosition;
            requestAnimationFrame(onFrame);
        }
        // console.log(scope);
        // console.log(element);
        // console.log(attr);

        // scope.$watch(function () {
        //     return scope.elementWidth = element[0].offsetWidth;
        // }, function(value) {
        //     scope.$parent.offsetsArray[scope.fontSizeFromWidth] = scope.elementWidth;
        // });
        //
        // // scope.$watch(scope.getElementWidth, function (newValue, oldValue) {
        // //     scope.$parent.offsetsArray[scope.fontSizeFromWidth] = scope.elementWidth;
        // // });
        //
        scope.$watch('$parent.changeFont', function (newValue, oldValue) {
            if(oldValue === false && newValue === true) {
                // console.log("Change Font changed from ", oldValue, " to ", newValue);
                if(!element[0].classList.contains('total-24')) {
                    element[0].classList.add('total-24');
                }
            } else if(oldValue === true && newValue === false) {
                if(element[0].classList.contains('total-24')) {
                    element[0].classList.remove('total-24');
                }
            }
        });

        // element.bind('load resize', function () {
        //     scope.$apply();
        // });
    }
}

export default {
    type: 'directive',
    name: 'fontSizeFromWidth',
    value: fontSizeFromWidth
};