tableViewRowDirective.$inject = [];
function tableViewRowDirective(){
    return {
        restrict: 'E',
        template: `
            <lv-table-view-row-cell ng-repeat="column in ctrl.columns" 
                                    lv-column="column"
                                    lv-item="ctrl.item"
                                    lv-item-index="ctrl.itemIndex">
            </lv-table-view-row-cell>                
        `,
        controller: TableViewRowController,
        controllerAs: 'ctrl',
        bindToController: true,
        require: {
            tableViewCtrl: '^lvTableView'
        },
        scope: {
            item: '<lvItem',
            itemIndex: '<lvItemIndex',
            columns: '<lvColumns'
        }
    }
}

TableViewRowController.$inject=['$element'];
function TableViewRowController($element){
    this.$onInit = $onInit;
    this.$onDestroy = $onDestroy;

    const self = this;
    let unsubscribe;

    function $onInit() {
        $element.addClass('lv-table-view__row');
        unsubscribe = self.tableViewCtrl.addRow(getHeight);
    }

    function $onDestroy(){
        if (unsubscribe){
            unsubscribe();
            unsubscribe = null;
        }
    }

    function getHeight() {
        return $element[0].getBoundingClientRect().height;
    }
}

export default {
    type: 'directive',
    name: 'lvTableViewRow',
    value: tableViewRowDirective
};