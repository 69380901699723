/**
 * Created by Oleksii Niktin on 04/18/2024.
 *
 * @ngdoc directive
 * @name app.directive: datePickerInput
 * @restrict A
 * @description
 * Clear input value of first focus
 */

import angular from 'angular';

clearOnFirstFocusDirective.$inject = ['$parse'];

function clearOnFirstFocusDirective($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: link
    };

    function link(scope, elem, attrs, modelCtrl) {
        let options = {
            enabled: true,
            value: null
        };
        if (attrs['firstFocusClear']) {
            options = angular.extend({}, options, $parse(attrs['firstFocusClear'])(scope));
        }
        if (options.enabled) {
            elem.on('focus', onFocus);
        }

        scope.$on('$destroy', () => {
            elem.off('focus', onFocus);
        })

        function onFocus() {
            let clearValue;
            if (Array.isArray(options.value)) {
                clearValue = options.value.includes(modelCtrl.$viewValue);
            } else {
                clearValue = options.value === null || options.value === modelCtrl.$viewValue;
            }
            if (clearValue) {
                elem.val('');
                modelCtrl.$setViewValue('');
            }
            // Unsubscribe after first focus event
            elem.off('focus', onFocus);
        }
    }
}

export default {
    type: 'directive',
    name: 'firstFocusClear',
    value: clearOnFirstFocusDirective
};