/**
 * Created by olehkss on 09.08.16.
 */

config.$inject = ['$compileProvider'];
function config($compileProvider) {
    $compileProvider.debugInfoEnabled(false);
}

export default {
    type: 'config',
    value: config
};