createDirective.$inject = ['$parse'];
function createDirective($parse) {
    return {
        restrict: 'A',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs) {
        if (attrs.lvSetSizeMethod) {
            $parse(attrs.lvSetSizeMethod).assign(scope, function(size) {
                element.css(size);
            });
        }
    }
}

export default {
    type: 'directive',
    name: 'lvSetSizeMethod',
    value: createDirective
};