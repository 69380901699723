/**
 * Created by mars on 10/7/15.
 */

datePickerView.$inject = ['factoryPosition', '$timeout'];

function datePickerView(factoryPosition, $timeout){
	return{
		restrict: 'C',

		link: function($scope, el, attr){
			var elParent  = $scope.elParent;

			$scope.$watch( 'show',function(val){
				el.css( 'display', 'block' );
				if ( val ) {
					$timeout(function(){
						var w = window,
							d = document,
							e = d.documentElement,
							g = d.getElementsByTagName('body')[0],
							screenWidth = w.innerWidth || e.clientWidth || g.clientWidth,
							y = w.innerHeight|| e.clientHeight|| g.clientHeight;
						// 	days = d.getElementsByClassName('col-day'),
						// 	dayWeek = d.getElementsByClassName('dayWeek')[0],
						// 	dayWeekWidth = dayWeek.clientWidth;
						// 	// Array.from(days).forEach(function(val){
						// 	// 	val.style.width = dayWidth + 'px';
						// 	// });
						// console.log(dayWeek);
						// console.log(getComputedStyle(dayWeek).width);
						// console.log(dayWeekWidth);
						// var index;
						// 	for(index = 0; index < days.length; index++){
						// 		days[index].style.width = dayWeekWidth + 'px';
						// 		console.log(days[index].style.width);
						// 	}
						var calendarWidth = el[0].clientWidth;
						var offsetLeft = factoryPosition( elParent[0] ).x + 92;

						if(screenWidth - calendarWidth - offsetLeft < 0){
							var dx = -(screenWidth - calendarWidth - offsetLeft);
							el.css('left', 'inherit');
							el.css('right', '5px');
						}else{
							el.css( 'display', 'inherit' );
							el.css( 'left', factoryPosition( elParent[0] ).x + 92 + 'px' );
							el.css( 'top', factoryPosition( elParent[0] ).y + 25 + 'px' );
						}
						el.css( 'top', factoryPosition( elParent[0] ).y + 25 + 'px' );
						el.css( 'visibility', 'visible' );
					},1);
				}
			});
		}
	};
}

export default datePickerView;