/**
 * Created by K.Yarosh on 26.04.2017.
 * @description Letting to pick up from timestamp the number of a week and quarter stateParams
 * @memberof
 * @ngdoc service
 * @name DateCustomMethods
 */

salesIndicatorsAdjustment.$inject = ['$rootScope', '$filter', '$timeout', 'arrayOperation'];
function salesIndicatorsAdjustment($rootScope, $filter, $timeout, arrayOperation) {

    var vm = this,
        i,
        k,
        l,
        initialWidthToCompare,
        widthToCompare,
        newFontSizeProperty,
        oldFontSizeProperty = oldFontSizeProperty ? oldFontSizeProperty : newFontSizeProperty,
        newWidthComparedToNumber,
        oldWidthComparedToNumber = oldWidthComparedToNumber ? oldWidthComparedToNumber : newWidthComparedToNumber;


    modifyCellsAndContent();

    window.addEventListener('resize', function () {
        modifyCellsAndContent();
    });

    function modifyCellsAndContent() {
        $timeout(function () {
            var elementsWithValue = document.getElementsByClassName('totalValue'),
                elementsWrapper = document.getElementsByClassName('adjustableWidth'),
                adjustableCol = document.getElementsByClassName('adjustableCol'),
                initialWidthToCompare = document.getElementsByClassName('initialWidthToCompareWidth')[0],
                elementsWrapperMaxWidth,
                elementsWrapperAverageWidth,
                ratio;

            if (elementsWithValue && elementsWrapper && adjustableCol && initialWidthToCompare) {
                var elementsWrapperLength = elementsWrapper.length,
                    elementsWrapperWidth = document.getElementsByClassName('adjustableWidth')[0].offsetWidth,
                    widthToCompare = initialWidthToCompare.offsetWidth - 48;

                oldFontSizeProperty = newFontSizeProperty;
                oldWidthComparedToNumber = newWidthComparedToNumber;


                getRelevantTotalValueContainerWidth();
                getRelevantFontSize();
            }


            function getRelevantTotalValueContainerWidth() {
                var elementsWrapperWidthArray = [];

                for (l = 0; l < elementsWrapperLength; l++) {
                    elementsWrapperWidthArray.push(parseInt(adjustableCol[l].offsetWidth));
                }

                elementsWrapperMaxWidth = arrayOperation.getMaxOfArray(elementsWrapperWidthArray);
                elementsWrapperAverageWidth = arrayOperation.getAverageOfArray(elementsWrapperWidthArray);

                ratio = elementsWrapperAverageWidth / initialWidthToCompare.offsetWidth;

                if ((initialWidthToCompare.offsetWidth / elementsWrapperLength) < elementsWrapperAverageWidth) {
                    initialWidthToCompare.style.overflowX = 'scroll';
                } else {
                    initialWidthToCompare.style.overflowX = 'unset';
                }

                if(ratio > 0.2) {
                    vm.font24 = false;
                    vm.font16 = true;
                } else {
                    vm.font24 = true;
                    vm.font16 = false;
                }
            }

            function getRelevantFontSize() {
                if (vm.font34) {
                    newFontSizeProperty = 'fz-34';
                    newWidthComparedToNumber = 'width-compared-34';
                } else if (vm.font24) {
                    newFontSizeProperty = 'fz-24';
                    newWidthComparedToNumber = 'width-compared-24';
                } else {
                    newFontSizeProperty = 'fz-16';
                    newWidthComparedToNumber = 'width-compared-16';
                }

                for (k = 0; k < elementsWrapperLength; k++) {
                    elementsWithValue[k].classList.remove(oldFontSizeProperty);
                    elementsWrapper[k].classList.remove(oldWidthComparedToNumber);
                    elementsWithValue[k].classList.add(newFontSizeProperty);
                    elementsWrapper[k].classList.add(newWidthComparedToNumber);
                }

            }


        }, 0);
    }

    return {
        modifyCellsAndContent: modifyCellsAndContent
    }

}

export default {
    type: 'service',
    name: 'salesIndicatorsAdjustment',
    value: salesIndicatorsAdjustment
};