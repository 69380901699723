/**
 * Created by mars on 9/4/15.
 *
 * английский, русский, испанский, французский, польский, румынский, украинский, корейский
 *
 *
 *

 */

const constantReceiptLangCollection = [
    {
        key: "ind",
        value: 'Bahasa Indonesia'
    },
    {
        key: "msa",
        value: "Bahasa Melayu"
    },
    {
        key: "ces",
        value: "Čeština"
    },
    {
        key: "dan",
        value: 'Dansk'
    },
    {
        key: "deu",
        value: "Deutsch"
    },
    {
        key: "est",
        value: 'Eesti'
    },
    {
        key: "eng",
        value: "English"
    },
    {
        key: "spa",
        value: "Español"
    },
    {
        key: "fra",
        value: "Français"
    },
    {
        key: "hrv",
        value: "Hrvatski"
    },
    {
        key: "isl",
        value: "Íslenska"
    },
    {
        key: "ita",
        value: "Italiano"
    },
    {
        key: "lav",
        value: "Latviešu"
    },
    {
        key: "lit",
        value: "Lietuvių"
    },
    {
        key: "hun",
        value: "Magyar"
    },
    {
        key: "nld",
        value: 'Nederlands'
    },
    {
        key: "nor",
        value: "Norsk bokmål"
    },
    {
        key: "uzb",
        value: "O’zbek"
    },
    {
        key: "pol",
        value: "Polski"
    },
    {
        key: "por",
        value: "Português"
    },
    {
        key: "ron",
        value: "Română"
    },
    {
        key: "sqi",
        value: "Shqip"
    },
    {
        key: "slk",
        value: "Slovenčina"
    },
    {
        key: "slv",
        value: "Slovenščina"
    },
    {
        key: "srp",
        value: "Srpski (latinica)"
    },
    {
        key: "fin",
        value: "Suomi"
    },
    {
        key: "swe",
        value: "Svenska"
    },
    {
        key: "vie",
        value: "Tiếng Việt"
    },
    {
        key: "tur",
        value: "Türkçe"
    },
    {
        key: "ell",
        value: 'Ελληνικά'
    },
    {
        key: "bul",
        value: 'Български'
    },
    {
        key: "mkd",
        value: "Македонски"
    },
    {
        key: "mon",
        value: "Монгол"
    },
    {
        key: "rus",
        value: "Русский"
    },
    {
        key: "kat",
        value: "ქართული"
    },
    {
        key: "heb",
        value: "עברית"
    },
    {
        key: "urd",
        value: "اردو"
    },
    {
        key: "ara",
        value: 'اللغة العربية‎'
    },
    {
        key: "fas",
        value: 'فارسی'
    },
    {
        key: "kur",
        value: 'کوردی‎'
    },
    {
        key: "hin",
        value: "हिन्दी"
    },
    {
        key: "ben",
        value: "বাংলা"
    },
    {
        key: "tha",
        value: 'ภาษาไทย'
    },
    {
        key: "lao",
        value: "ພາສາລາວ"
    },
    {
        key: "mya",
        value: "မြန်မာစာ"
    },
    {
        key: "khm",
        value: "ភាសាខ្មែរ"
    },
    {
        key: "kor",
        value: "한국어"
    },
    {
        key: "jpn",
        value: "日本語"
    },
    {
        key: "chn",
        value: '中文 (简体)'
    },
    {
        key: "zho",
        value: '中文 (繁體)'
    }
];

export default {
    type: 'constant',
    name: 'constantReceiptLangCollection',
    value: constantReceiptLangCollection
};