/*** Created by Alex 23.11.16 ***/

/**
* Created by Alex 23.11.16
* @memberof editCashRegisterCtrl
* @ngdoc controller
* @name editCashRegisterCtrl
* @requires $scope {service} controllers scope
* @requires $state {service} UI Router servise
* @requires $stateParams {service} UI Router servise
* @requires $filter {service} filter servise
* @requires serviceDialog {service} Add modal dialog
* @requires serviceToast {service} Add toast
* @requires getCashRegister {service} POST operation with CashRegister
* @requires $cashRegisterForEdit {service} Get cashRegisterForEdit before load page
* @requires $shortOutlets {service} Get shortOutlets list before load page
*/

import angular from 'angular';

editCashRegisterCtrl.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$filter",
    "serviceDialog",
    "serviceToast",
    "getCashRegister",
    '$cashRegisterForEdit',
    '$shortOutlets',
    '$compile',
    'dialogs',
    "$profile",
    "outlet",
];
function editCashRegisterCtrl(
    $scope,
    $state,
    $stateParams,
    $filter,
    serviceDialog,
    serviceToast,
    getCashRegister,
    $cashRegisterForEdit,
    $shortOutlets,
    $compile,
    dialogs,
    $profile,
    outlet
) {
    let translate = $filter( 'translate' );
    $scope.clicked = true;
    $scope.cashRegisterForEdit = $cashRegisterForEdit;
    $scope.shortOutlets = $shortOutlets;
    $scope.newCashRegister = {
        name: 			$scope.cashRegisterForEdit.name,
        cashRegisterId: $scope.cashRegisterForEdit.id,
        outletId: 		$scope.cashRegisterForEdit.outletId
    };
    $scope.cloneNewCashRegister = angular.copy($scope.newCashRegister);
    $scope.isPOSActivated = $scope.cashRegisterForEdit.deviceId;
    $scope.cashRegisterStatus = $scope.isPOSActivated ? translate('MULTISHOP.CASH_REGISTER.ACTIVE') : translate('MULTISHOP.CASH_REGISTER.NO_ACTIVE');

    $scope.progress = false;

    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
    function isChanged() {
        return !angular.equals($scope.newCashRegister, $scope.cloneNewCashRegister);
    }


    /**
    * @ngdoc function
    * @name onSave
    * @description
    * creating or editing new cashregister
    * @returns $state.go('settings.cashregister')
    */
    $scope.onSaveEditPOS = function () {

        if ( $scope.clicked === false ) {
            return false;
        }

        if ($scope.cashRegisterForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.cashRegisterForm.focusFirstInvalidControl();
            return false;
        }

        function send() {
            $scope.clicked = false;
            $scope.progress = true;
            getCashRegister.editCashRegister( $scope.newCashRegister ).then( function ( res ) {
                if ( res.result === "ok" && !res.message ) {
                    offStateChangeStart();
                    serviceToast.show( 'MULTISHOP.CASH_REGISTER.TOSTS.EDIT_CASH_REGISTER', 'success' );
                    $state.go( 'settings.cashregister', $stateParams, {
                        reload: true
                    } );
                }
            }, function () {
                $scope.clicked = true;
            })
            .finally(() => {
                $scope.progress = false;
            })
        }

        if( $scope.newCashRegister.name.length > 40 ){
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
        } else {
            send();
        }
    };


    /**
    * @ngdoc function
    * @name onCancel
    * @description
    * cancel the changes and return to the list cashregister
    * @returns $state.go('settings.cashregister')
    */
    $scope.onCancelEditPOS = () => {
        $state.go( 'settings.cashregister', $stateParams, {} );
    };

    /**
    * @ngdoc function
    * @name onDeleteEditPOS
    * @description
    * Send id cashRegister to a server for delete this cashRegister
    * @param {Object} params {"cashRegistersIds":"777"}
    * @returns $state.go('settings.cashregister')
    */
    $scope.onDeleteEditPOS = () => {
        $scope.msgTitle =  translate('MULTISHOP.CASH_REGISTER.DELETE_CASH_REGISTER');
        $scope.contentNoWares = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTER');
        $scope.contentNoWaresNext = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTER_NEXT');
        $scope.tostTitle = 'MULTISHOP.CASH_REGISTER.TOSTS.DELETED_CASH_REGISTER';

        $scope.onSendData = () => {
            getCashRegister.deleteCashRegister({cashRegistersIds: [$scope.cashRegisterForEdit.id]}).then(function(res){
                if(res.result === "ok"){
                    offStateChangeStart();
                    serviceToast.show($scope.tostTitle, 'success');
                    $state.go('settings.cashregister', $stateParams, {
                        reload: true
                    });
                }
            });
        };

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-del-cash-register.html',
            title: $scope.msgTitle,
            content: $scope.contentNoWares,
            contentNext: $scope.contentNoWaresNext,
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary',
                    text: 'ON_DEL',
                    action: $scope.onSendData
                }
            ]
        });

    };

    // Dialog when clicking on Deactivate button
    $scope.onDeactivate = ( cashRegisterId, editProfile ) => {

        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-del-cash-register.html',
            title: translate('MULTISHOP.CASH_REGISTER.DEACTIVATE_POS_TITLE'),
            content: translate('MULTISHOP.CASH_REGISTER.DEACTIVATE_POS_TEXT_1'),
            contentNext: translate('MULTISHOP.CASH_REGISTER.DEACTIVATE_POS_TEXT_2'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary',
                    text: 'MULTISHOP.CASH_REGISTER.DEACTIVATE',
                    action: function () {
                        $scope.deactivatePOS(cashRegisterId)
                    }
                }
            ]
        })
    }

    // Deactivating POS
    $scope.deactivatePOS = ( cashRegisterId ) => {
        let data = { cashRegisterId: cashRegisterId }

        getCashRegister.deactivateCashRegister(data).then(function (res) {
            if (res.result === 'ok' && !res.message) {
                offStateChangeStart();
                $state.go($state.current.name, $stateParams, {reload: true})
            }
        })
    }

    $scope.outlet = outlet;
    $scope.features = $profile.features;
}

export default {
    type: 'controller',
    name: 'editCashRegisterCtrl',
    value: editCashRegisterCtrl
};
