datePickerDay.$inject = ['serviceDatePickerEvents'];

function datePickerDay(serviceDatePickerEvents) {
    return {
        restrict: 'A',
        scope: {
            beforeValue: '=',
            datePickerDay: '='
        },
        //controller: 'datePickerContrl',
        link: function ( scope, el ) {
            el.on( 'mouseover', function ( e ) {
                if ( serviceDatePickerEvents.mouseDown ) {
                    serviceDatePickerEvents.hoverDay = scope.datePickerDay;
                    scope.$apply();
                }
            } );
        }
    }
}

export default datePickerDay;