/**
 * Created by kseniya.yarosh on 15.12.16
 *
 * @memberof lvExpImpPanel
 * @ngdoc directive
 * @name app.directive:lvExpImpPanel
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Merchants filter (ul li list)
 *
 * <div lv-export-import-panel="ctrl.filesImportControl"></div>
 */

import template from '../templates-pug/for-directive/exp-imp-panel.pug';

lvExpImpPanel.$inject = [];

function lvExpImpPanel() {
    return{
        restrict: 'E',
        controller: lvExpImpPanelController,
        controllerAs: 'lvExpImpPanelCtrl',
        template: template
    };
}

lvExpImpPanelController.$inject = ['$rootScope', '$filter', 'impExpRefs', 'lvMixpanel'];
function lvExpImpPanelController($rootScope, $filter, impExpRefs, lvMixpanel) {

    var self = this;
    var translate = $filter('translate');
    self.permits = $rootScope.permits;

    self.impExpHrefs = impExpRefs.getImpExpData();
    // console.log(self.impExpHrefs);

    self.exportClientsMixpanel = function () {
        lvMixpanel.track('Export', {"Property": "Customers/Export customer base"});
    }

    self.href = function() {
        location.href = self.impExpHrefs.expHref;
        return location.href;
    }

}

export default {
    type: 'directive',
    name: 'lvExpImpPanel',
    value: lvExpImpPanel
};
