/**
 * Created by Nikitin Oleksii
 *
 * @ngdoc directive
 * @name app.directive:lvFocusMethod
 * @restrict A
 *
 * @description
 *
 * Directive for publish to scope element focus method.
 *
 *@example
 *
 * <some-element lv-focus-element="focusElement"></some-element>
 *
 */

createDirective.$inject = ['$parse', '$mdUtil'];

function createDirective($parse, $mdUtil) {
    return {
        restrict: 'A',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs) {
        if (attrs.lvFocusMethod) {
            let parsedExpression = $parse(attrs.lvFocusMethod);
            parsedExpression.assign(scope, function() {
                if (attrs.disabled) {
                    $mdUtil.nextTick(function() {
                        element[0].focus();
                    });
                } else {
                    element[0].focus();
                }
            });

            scope.$on('$destroy', function() { parsedExpression.assign(scope, undefined); });
        }
    }
}

export default {
    type: 'directive',
    name: 'lvFocusMethod',
    value: createDirective
};