require("jquery");

import angular from "angular";
require("../extend/extend-http-angular");
require("./extend/angular-extend");

require("angular-aria");

import ngAnimate from "angular-animate";
import uiRouter from "angular-ui-router";
import ngCookies from "angular-cookies";
import ngMessages from "angular-messages";
import ngMaterial from "angular-material";
import ngLocalStorage from "angular-local-storage";
import ngReCaptcha from "angular-recaptcha";
import ngDeviceDetector from "ng-device-detector";

require("angular-ui-bootstrap");
require("angular-file-upload");
require("../modules/angular-legacy-sortablejs-maintained/angular-legacy-sortable");
require("lodash");
window.moment = require("moment");
window.braintree = require("braintree-web");
window.jstz = require("jstimezonedetect");

import CurrencyFormatter from "currencyformatter.js";
window.OSREC = { CurrencyFormatter: CurrencyFormatter };

import Highcharts from "highcharts/highcharts";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
import HighchartsExporting from "highcharts/modules/exporting";
HighchartsExporting(Highcharts);

window.Highcharts = Highcharts;

import lvValid from "../common/webpack/lv-validation/lv-valid";
import lvLang from "../common/webpack/lv-langs/lv-lang";
import lvPretty from "../common/webpack/lv-pretty/lv-pretty";
import lvToast from "../common/webpack/lv-toast/lv-toast";

import lvDialog from "../modules/lv-dialog/lv-dialog";
import lvDatepicker from "../modules/lv-date-picker/lv-date-picker.module";
import lvCalendar from "../modules/lv-calendar/lv-calendar.module";
import lvTimeRange from "../modules/lv-calendar/lv-time-range.module";
import fileReader from "../modules/file-reader/file-reader";
import imgMaintenance from "../modules/img-maintenance/img-maintenance";

// import loadOnDemand from './extend/loadOnDemand/loadOnDemand';

import factoryEnvironment from "../common/webpack/factory/factory-environment";

import serviceReCaptcha from "../common/webpack/service/service-recaptcha";
import itemsReportTable from "./components/items-report-table/items-report-table.component";

const appModuleDependencies = [
  uiRouter,
  ngAnimate,
  ngCookies,
  ngMessages,
  ngMaterial,
  ngLocalStorage,
  ngReCaptcha,
  lvValid,
  lvLang,
  lvPretty,
  lvToast,
  lvDialog,
  lvDatepicker,
  lvCalendar,
  lvTimeRange,
  fileReader,
  imgMaintenance,
  // loadOnDemand,
  "ui.bootstrap",
  "angularFileUpload",
  ngDeviceDetector,
  "ng-sortable",
];

const isDev = process.env.NODE_ENV === "development";

const appModule = angular
  .module("app", appModuleDependencies)
  .factory("factoryEnvironment", factoryEnvironment)
  .service("serviceReCaptcha", serviceReCaptcha)
  .service("serviceBillingSalesHistory", serviceBillingSalesHistory)
  .component("itemsReportTable", itemsReportTable);

// Constants
const constants = require.context("./constant", false, /\.js$/);
addComponents(appModule, constants);

import constDev from "./constant/build-constant/_const-dev";
import constProd from "./constant/build-constant/_const-prod";

if (isDev) {
  appModule[constDev.type](constDev.name, constDev.value);
} else {
  appModule[constProd.type](constProd.name, constProd.value);
}

// Add to bundle all files from img/server folder
require.context("../img/server", true);

// Filters
const filters = require.context("./filter", false, /\.js$/);
addComponents(appModule, filters);

// Services
const services = require.context("./service", false, /\.js$/);
addComponents(appModule, services);

// Factories
const factories = require.context("./factory", false, /\.js$/);
addComponents(appModule, factories);

// Controllers
const controllers = require.context("./controller", true, /\.js$/);
addComponents(appModule, controllers);

// Directives
const directives = require.context("./directive", true, /\.js$/);
addComponents(appModule, directives);

// Configurators
const configurators = require.context("./config", false, /\.js$/);
addComponents(appModule, configurators);

if (!isDev) {
  const configProd =
    require("./config/production/config-production-mode").default;
  applyToModule(appModule, configProd);
}

import moduleRun from "./module.run";
import moduleRunTemplate from "./module.run.templates";
import serviceBillingSalesHistory from "../common/webpack/service/service-billing-saleshistory";
import { environment } from "../common/containers/environment";

appModule.run(moduleRun);
appModule.run(moduleRunTemplate);

function addComponents(angularModule, ctx) {
  const keys = ctx.keys();
  keys.forEach((key) => {
    const item = ctx(key);
    if (item) {
      const component = item.default;
      if (component) {
        if (Array.isArray(component)) {
          component.forEach((item) => {
            applyToModule(angularModule, item);
          });
        } else {
          applyToModule(angularModule, component);
        }
      }
    }
  });
}

function applyToModule(angularModule, item) {
  if (item.type === "config") {
    angularModule.config(item.value);
  } else {
    angularModule[item.type](item.name, item.value);
  }
}

console.log("Bootstrapped dashboard app");
console.table(environment);
