tokenDetail.$inject = [
    '$tokenInfo',
    '$scope',
    '$state',
    '$stateParams',
    'serviceDialog',
    'factoryTokens',
    'serviceToast',
    '$filter'
];

function tokenDetail(
    $tokenInfo,
    $scope,
    $state,
    $stateParams,
    serviceDialog,
    factoryTokens,
    serviceToast,
    $filter
) {

    var vm = this;

    vm.tokenInfo = $tokenInfo;

    var translate = $filter('translate');
    $scope.stateFromList = $stateParams.stateToSave;
    $scope.selectedTokens = $stateParams.checkToSave;

    $scope.onBack = function() {
        $state.go('integrations.tokens', {stateToSave: $scope.stateFromList, checkToSave: $scope.selectedTokens});
    };

    vm.onEdit = function () {
        $state.go('integrations.editToken', {id: $stateParams.id});
    };

    vm.onDelete = function() {
        var deleteTitle = 'TOKENS.DELETE_MESS_TITLE',
            deleteInfo = 'TOKENS.DELETE_MESSAGE';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   translate(deleteTitle),
            content:  translate(deleteInfo),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary',
                    text: 'CONTINUE',
                    action: deleteToken
                }
            ]
        });
    };
    function deleteToken() {

        var deleteMessage = 'TOKENS.TOKEN_DELETED';

        factoryTokens.deleteApiAuthToken({
            tokenIds: [$stateParams.id]
        }).then(function (result) {

            if (result === "ok") {
                serviceToast.show(deleteMessage, 'success');
                $state.go('integrations.tokens', {stateToSave: $scope.stateFromList, checkToSave: $scope.selectedTokens});
            }
        });
    }


}

export default {
    type: 'controller',
    name: 'tokenDetail',
    value: tokenDetail
};