/**
 * Created by Kiskenbassker on 13.03.18
 */

import angular from 'angular';

createProductionController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'serviceDialog',
    'serviceToast',
    '$shortOutlets',
    '$currencyOptionsShow',
    '$countQuantityForOrderDivisible',
    '$countQuantityForOrder',
    'factoryProduction',
    '$q',
    'lodash',
    '$rootScope',
    '$timeout',
    '$orderData',
    '$permitsManager',
    'dialogs',
    'factoryPopover',
    '$window'
];

function createProductionController(
    $scope,
    $state,
    $stateParams,
    $filter,
    serviceDialog,
    serviceToast,
    $shortOutlets,
    $currencyOptionsShow,
    $countQuantityForOrderDivisible,
    $countQuantityForOrder,
    factoryProduction,
    $q,
    lodash,
    $rootScope,
    $timeout,
    $orderData,
    $permitsManager,
    dialogs,
    factoryPopover,
    $window
) {
    var vm = this;
    var searchDefer,
        enterKeydown = false,
        searchInput,
        itemsFound = null;
    $scope.costAllowed = $permitsManager.costAllowed;
    $scope.stateFromList = $stateParams.stateToSave;
    $scope.isSave = false;
    vm.currencyOptionsShow = $currencyOptionsShow;
    vm.countQuantityForOrderDivisible = $countQuantityForOrderDivisible;
    vm.countQuantityForOrder = $countQuantityForOrder;
    vm.coinToCurrency = $filter('coinToCurrency');
    vm.wareCount = $filter('wareCount');
    var translate = $filter('translate');
    // $scope.formName = "cProductionForm";

    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {

        vm.cProductionFinalParams = {
            actualOutletId: vm.outlets.length > 1 ? vm.actualOutletId : vm.outlets[0].id,
            comment: vm.comment,
            items: vm.items.map(item => {
                return {
                    name: item.name,
                    quantity: item.quantity
                }
            })
        };

        return !angular.equals(vm.cProductionStartParams, vm.cProductionFinalParams);
    }

    vm.outlets = angular.copy($shortOutlets);

    if ($state.current.name === 'inventory.createproduction' || $state.current.name === 'inventory.createdisassembly') {
        vm.items = [];
        vm.actualOutlet = {};
        vm.actualOutletId = vm.outlets.length > 1 ? null : vm.outlets[0].id;
        vm.comment = '';

        vm.cProductionStartParams = {
            actualOutletId: vm.outlets.length > 1 ? null : vm.outlets[0].id,
            comment: "",
            items: []
        }
    } else if ($state.current.name === 'inventory.productionduplicate' || $state.current.name === 'inventory.disassemblyduplicate') {
        vm.orderData = angular.copy($orderData);
        vm.items = vm.orderData.items;
        vm.items.forEach(function(item){
            item.quantity = parseFloat(vm.wareCount(item.quantity, vm.countQuantityForOrder.decimalCharacter, false));
        });

        vm.data = vm.orderData.orderData;
        for (var i = 0; i < vm.outlets.length; i++) {
            if (vm.outlets[i].id === vm.data.outletId){
                vm.actualOutlet = vm.outlets[i].id;
            }
        }
        vm.comment = vm.data.comment;
        vm.actualOutletId = vm.outlets.length > 1 ? vm.data.outletId : vm.outlets[0].id;

        vm.cProductionStartParams = {
            actualOutletId: vm.outlets.length > 1 ? vm.data.outletId : vm.outlets[0].id,
            comment: vm.data.comment,
            items: vm.items.map(item => {
                return {
                    name: item.name,
                    quantity: item.quantity
                }
            })
        }
    }

    vm.autocompleteItems = {
        searchText: null,
        selectedItem: null,
        getMatches: function ( query ) {
            searchDefer = $q.defer();
            factoryProduction
                .searchItemsForProductionOrder( {
                    search: query,
                    type: ($state.current.name === 'inventory.createproduction') ? 'PRODUCTION' : 'DISASSEMBLY'
                })
                .then(function (items) {
                    searchDefer.resolve(items);
                    itemsFound = angular.copy(items);
                })
                .then(function () {
                    if(enterKeydown) {
                        if(Array.isArray(itemsFound) && itemsFound.length === 1 && itemsFound[0].barcode === vm.autocompleteItems.searchText) {
                            vm.autocompleteItems.selectedItemChange(itemsFound[0]);
                            searchInput.focus();
                            enterKeydown = false;
                        }
                    }
                });
            return searchDefer.promise;
        },
        selectedItemChange: function(item) {
            if(item) {
                var itemsLength = vm.items.length;
                if(itemsLength !== 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (item.id === vm.items[i].wareId) {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                content: translate('INVENTORY.ADDING_SAME_PRODUCT_MESSAGE', {document: ($state.current.name === 'inventory.createproduction') ? translate('INVENTORY.PRODUCTION.DOCUMENT_PRODUCTION') : translate('INVENTORY.PRODUCTION.DOCUMENT_DISASSEMBLY')}),
                                buttons: [
                                    {
                                        text: 'ON_OK',
                                        action: function () {
                                            return true;
                                        }
                                    }
                                ]
                            });
                            break;
                        } else if (i === itemsLength - 1) {
                            pushItemToArray(item);
                        }
                    }
                } else {
                    pushItemToArray(item);
                }
                $('[name="autocompleteItems"]').blur();
                this.selectedItem = null;
                this.searchText = null;
            }
        }
    };

    vm.selectActualOutlet = function(selectModel){
        vm.actualOutlet = angular.copy(selectModel);
        vm.actualOutletId = vm.actualOutlet.id;
    }

    function pushItemToArray(item) {
        var itemObj = {
            id: null, /* Identifies that position is newly added */
            wareId: item.id,
            name: item.name,
            sku: item.sku,
            divisible: item.divisible,
            quantity: null,
            cost: item.cost
        };
        vm.items.push(itemObj);
    }

    vm.onCancel = function() {
        $stateParams.page = 0;
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go( 'inventory.productions', {
                page:           0,
                limit:          10} );
        } else {
            $state.go( 'inventory.productions', {
                page:           $scope.stateFromList.page,
                limit:          $scope.stateFromList.limit,
                outletId:       $scope.stateFromList.outletId,
                type:           $scope.stateFromList.type} );
        }
    };

    function checkEmptyNumberInput(item) {
        return (parseFloat(item.quantity) !== 0)
            && item.quantity !== undefined
            && item.quantity !== null;
    }

    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    function checkBeforeSave(event) {
        vm.errItemsNo = vm.items.every(checkEmptyNumberInput);

        if((vm.outlets.length > 1 && vm.actualOutletId === null)
            || (vm.items.length < 1)
            || !vm.errItemsNo
            || vm.comment.length > 500) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            return false;
        }

        return true;
    }

    vm.onSave = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if (checkBeforeSave()) {
            $scope.isSave = true;
            vm.dataForSave = {
                outletId: vm.actualOutletId,
                comment: vm.comment,
                type: ($state.current.name === 'inventory.createproduction' || $state.current.name === 'inventory.productionduplicate') ? 'PRODUCTION' : 'DISASSEMBLY',
                items: vm.items.map(
                    function(item) {
                        return {
                            wareId: item.wareId,
                            quantity: parseInt(vm.wareCount(item.quantity, ".", true), 10)
                        }
                })
            }
            factoryProduction.createOrderProduction(vm.dataForSave)
                .then(function(data){
                    if (data.result === 'ok') {
                        offStateChangeStart();
                        if($state.current.name === 'inventory.createproduction' || $state.current.name === 'inventory.productionduplicate') {
                            serviceToast.show('INVENTORY.PRODUCTION.ITEMS_PRODUSED', 'success');
                            $state.go('inventory.productiondetail', {
                                id: data.newId
                            })
                        } else if ($state.current.name === 'inventory.createdisassembly' || $state.current.name === 'inventory.disassemblyduplicate') {
                            serviceToast.show('INVENTORY.PRODUCTION.DISASSEMBLY_COMPLETED', 'success');
                            $state.go('inventory.disassemblydetail', {
                                id: data.newId
                            })
                        }

                    } else {
                        serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                        $scope.isSave = false;
                    }
                })
                .catch(function(error){
                    $scope.isSave = false;
                    return $q.reject(error);
                });
        }
    }

    vm.deleteItem = function (item) {
        angular.forEach(vm.items, function ( val, i ) {
            if (item === val) {
                vm.items.splice(i, 1);
            }
        });
    };

    vm.addByBarcode = function ($event) {
        if($event.keyCode === 13) {
            enterKeydown = true;
            searchInput = $event.target;
            angular.element(searchInput).focus();
        }
    }

    $scope.popover = factoryPopover;

    $scope.openPopovers = {
        createProduction: false,
        createDisassembly: false
    };

    const onResize = () => {
      $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };

    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);
}

export default {
    type: 'controller',
    name: 'createProductionController',
    value: createProductionController
};
