/**
 * Created by mars on 9/8/15.
 * @ngdoc service
 * @name factoryReportGoods
 * @description
 * принимает номер элемента и общее количество элементов. Возвращает цвет от зеленого к красному
 */

serviceColor.$inject = [];
function serviceColor() {
	var f = parseFloat;

	function componentToHex(c) {
		var hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	}

	function rgbToHex(color)
	{
		color = color.replace(/\s/g,"");
		var aRGB = color.match(/^rgb\((\d{1,3}[%]?),(\d{1,3}[%]?),(\d{1,3}[%]?)\)$/i);

		if(aRGB)
		{
			color = '';
			for (var i=1;  i<=3; i++) color += Math.round((aRGB[i][aRGB[i].length-1]=="%"?2.55:1)*parseInt(aRGB[i])).toString(16).replace(/^(.)$/,'0$1');
		}
		else color = color.replace(/^#?([\da-f])([\da-f])([\da-f])$/i, '$1$1$2$2$3$3');

		return "#"+color;
	}
	//var RBG="rgb(0, 255, 0)";
	//alert( getHexRGBColor(RBG));

	function getColor ( n, amount ) {

		var limit = f(amount);
		var step = limit / 5;
		var speed = n;
		var c,s;

		if (speed < step) { //(255,255,0) -> (0,255,0)
			c = 255 - (255 / step) * speed;
			c = c.toFixed(0);
			return 'rgb(' + c + ',255,0)';
			/*return {
				r: c,
				g: 255,
				b: 0
			}*/

		} else if (speed < 2 * step) { //(0,255,0) -> (0,255,255)
			s = speed - step;
			c = (255 / step) * s;
			c = c.toFixed(0);
			return 'rgb(0,255,' + c + ')';
			/*return {
				r: 0,
				g: 255,
				b: c
			}*/
		} else if (speed < 3 * step) { //(0,255,255) - >(0,0,255)
			s = speed - (2 * step);
			c = 255 - (255 / step) * s;
			c = c.toFixed(0);
			return 'rgb(0,' + c + ',255)';
			/*return {
				r: 0,
				g: c,
				b: 255
			}*/
		} else if (speed < 4 * step) { //(0,0,255) - >(255,0,255)
			s = speed - (3 * step);
			c = (255 / step) * s;
			c = c.toFixed(0);
			return 'rgb(' + c + ',0,255)';
			/*return {
				r: c,
				g: 0,
				b: 255
			}*/
		} else if (speed < 5 * step) {//(255,0,255) - >(255,0,0)
			s = speed - (4 * step);
			c = 255 - (255 / step) * s;
			c = c.toFixed(0);
			return 'rgb(255,0,' + c + ')';
			/*return {
				r: 255,
				g: 0,
				b: c
			}*/
		} else {
			return 'rgb(255,0, 0)';
			/*return {
				r: 255,
				g: 0,
				b: 0
			}*/
		}

	}

	getColor.rgbToHex = rgbToHex;

	this.rgbToHex = function(){
		return rgbToHex.apply(this, arguments)
	} ;
	this.getColor = getColor;


}

export default {
	type: 'service',
	name: 'serviceColor',
	value: serviceColor
};