/**
 * Created by Kiskenbassker on 20.12.17
 */

import angular from "angular";

inventoryValuation.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "$filter",
  "$countOptionsShow",
  "$currencyOptionsShow",
  "$percentShowInfinityToHundred",
  "$valuation",
  "$shortOutlets",
  "$categories",
  "$timeout",
  "$window",
  "constantGreenExport",
  "$profile",
  "getChartLabel",
  "$permitsManager",
  "factoryPopover",
];

function inventoryValuation(
  $scope,
  $state,
  $stateParams,
  $filter,
  $countOptionsShow,
  $currencyOptionsShow,
  $percentShowInfinityToHundred,
  $valuation,
  $shortOutlets,
  $categories,
  $timeout,
  $window,
  constantGreenExport,
  $profile,
  getChartLabel,
  $permitsManager,
  factoryPopover
) {
  var vm = this;
  var today = new Date();

  vm.date = $stateParams.date
    ? $stateParams.date
    : today.getFullYear() +
      "-" +
      getChartLabel.getMonth(today) +
      "-" +
      getChartLabel.getDate(today);
  $scope.invValuation = true;
  vm.wareCount = $filter("wareCount");
  vm.coinToCurrency = $filter("coinToCurrency");
  vm.filterPercent = $filter("filterPercent");
  vm.countOptionsShow = $countOptionsShow;
  vm.currencyOptionsShow = $currencyOptionsShow;
  vm.percentShowInfinityToHundred = $percentShowInfinityToHundred;
  vm.valuation = angular.copy($valuation);

  vm.totalInventoryHint = vm.valuation.showHints.totalInventory;
  vm.totalRetailAndProfitHint =
    vm.valuation.showHints.totalRetailAndPotentialProfit;
  vm.tzOffset =
    vm.valuation.tzOffset !== null ? timeFormat(vm.valuation.tzOffset) : null;

  //change color by btn Export
  $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;
  $scope.displayCost = $permitsManager.costAllowed;

  $scope.outlets = angular.copy($shortOutlets).map(function (outlet) {
    return {
      outletId: outlet.id,
      outletName: outlet.name,
    };
  });
  $scope.categories = angular.copy($categories).map(function (category) {
    return {
      categoryId: category.id,
      categoryName: category.name,
    };
  });

  vm.total = {
    inventory: vm.coinToCurrency(
      vm.valuation.totalInventory,
      vm.currencyOptionsShow.decimalCharacter
    ),
    retail: vm.coinToCurrency(
      vm.valuation.totalRetail,
      vm.currencyOptionsShow.decimalCharacter
    ),
    potentialProfit: vm.coinToCurrency(
      vm.valuation.potentialProfit,
      vm.currencyOptionsShow.decimalCharacter
    ),
    margin: vm.filterPercent(
      vm.valuation.margin,
      vm.currencyOptionsShow.decimalCharacter
    ),
  };

  vm.valuationItems = vm.valuation.items.map(function (item) {
    return {
      name: item.name,
      inStock: vm.wareCount(
        item.inStock,
        vm.countOptionsShow.decimalCharacter,
        false
      ),
      avgCost: vm.coinToCurrency(
        item.avgCost,
        vm.currencyOptionsShow.decimalCharacter
      ),
      inventoryValue:
        item.inventoryValue !== null
          ? vm.coinToCurrency(
              item.inventoryValue,
              vm.currencyOptionsShow.decimalCharacter
            )
          : null,
      retailValue:
        item.retailValue !== null
          ? vm.coinToCurrency(
              item.retailValue,
              vm.currencyOptionsShow.decimalCharacter
            )
          : null,
      potentialProfit:
        item.potentialValue !== null
          ? vm.coinToCurrency(
              item.potentialValue,
              vm.currencyOptionsShow.decimalCharacter
            )
          : null,
      margin:
        item.margin !== null
          ? vm.filterPercent(
              item.margin,
              vm.currencyOptionsShow.decimalCharacter
            )
          : null,
      variations: item.variations.map(function (variation) {
        return {
          name: variation.name,
          inStock: vm.wareCount(
            variation.inStock,
            vm.countOptionsShow.decimalCharacter,
            false
          ),
          avgCost: vm.coinToCurrency(
            variation.avgCost,
            vm.currencyOptionsShow.decimalCharacter
          ),
          inventoryValue:
            variation.inventoryValue !== null
              ? vm.coinToCurrency(
                  variation.inventoryValue,
                  vm.currencyOptionsShow.decimalCharacter
                )
              : null,
          retailValue:
            variation.retailValue !== null
              ? vm.coinToCurrency(
                  variation.retailValue,
                  vm.currencyOptionsShow.decimalCharacter
                )
              : null,
          potentialProfit:
            variation.potentialValue !== null
              ? vm.coinToCurrency(
                  variation.potentialValue,
                  vm.currencyOptionsShow.decimalCharacter
                )
              : null,
          margin:
            variation.margin !== null
              ? vm.filterPercent(
                  variation.margin,
                  vm.currencyOptionsShow.decimalCharacter
                )
              : null,
        };
      }),
    };
  });

  vm.downloadHrefBase = "/data/ownercab/downloadInventoryCostCsv";

  vm.totalOneRow = true;
  vm.totalTwoRows = false;
  vm.totalOneCol = false;
  vm.font34 = true;
  vm.font24 = false;
  vm.font14 = false;
  vm.popoverPlacement = "right";

  // modifyCellsAndContent();
  // $window.addEventListener('resize', modifyCellsAndContent);

  function modifyCellsAndContent() {
    if ($state.current.name === "inventory.valuation") {
      $timeout(function () {
        var widthToCompare =
          angular.element("#widthToCompareWidth")[0].offsetWidth - 48;

        /* Task #17819 В отчете Inventory valuation сделать чтобы подсказка всплывала в другую сторону */
        if (widthToCompare < 360) {
          vm.popoverPlacement = "top";
        }
        /* end Task #17819 В отчете Inventory valuation сделать чтобы подсказка всплывала в другую сторону */

        var widthCompared34 = angular.element(".width-compared-34");
        var widthCompared24 = angular.element(".width-compared-24");
        var widthCompared14 = angular.element(".width-compared-14");

        var widthComparedTotal34 = getCellsWidthTotal(widthCompared34, false);
        var widthComparedTotal24 = getCellsWidthTotal(widthCompared24, false);
        var widthComparedTotal14 = getCellsWidthTotal(widthCompared14, false);

        var widthComparedTotalHalf24 = getCellsWidthTotal(
          widthCompared24,
          true
        );

        if (
          widthComparedTotal34 > widthToCompare &&
          widthComparedTotal24 < widthToCompare
        ) {
          vm.totalOneRow = true;
          vm.totalTwoRows = false;
          vm.totalOneCol = false;
          vm.font34 = false;
          vm.font24 = true;
          vm.font14 = false;
        } else if (
          widthComparedTotal34 > widthToCompare &&
          widthComparedTotal24 > widthToCompare
        ) {
          if (
            widthComparedTotalHalf24[0] > widthToCompare ||
            widthComparedTotalHalf24[1] > widthToCompare
          ) {
            for (var i = 0; i < widthCompared24.length; i++) {
              if (widthCompared24[i].offsetWidth > widthToCompare) {
                vm.totalOneRow = false;
                vm.totalTwoRows = false;
                vm.totalOneCol = true;
                vm.font34 = false;
                vm.font24 = false;
                vm.font14 = true;
                break;
              } else if (
                i === widthCompared24.length - 1 &&
                widthCompared24[i].offsetWidth <= widthToCompare
              ) {
                vm.totalOneRow = false;
                vm.totalTwoRows = false;
                vm.totalOneCol = true;
                vm.font34 = false;
                vm.font24 = true;
                vm.font14 = false;
              }
            }
          } else {
            vm.totalOneRow = false;
            vm.totalTwoRows = true;
            vm.totalOneCol = false;
            vm.font34 = false;
            vm.font24 = true;
            vm.font14 = false;
          }
        } else {
          vm.totalOneRow = true;
          vm.totalTwoRows = false;
          vm.totalOneCol = false;
          vm.font34 = true;
          vm.font24 = false;
          vm.font14 = false;
        }

        // console.log("Width To Compare With", widthToCompareWidth);
        // console.log("Width Compared Total", widthComparedTotal);

        // if(widthComparedTotal > widthToCompareWidth) {
        //     vm.changeFont = true;
        //     // $timeout(callback(), 60000);
        // } else {
        //     vm.changeFont = false
        // }

        // (widthComparedTotal > widthToCompareWidth) ? vm.totalTwoRows = true : vm.totalTwoRows = false;
      }, 1);
    }
  }

  var modifyCellsTwoRows = function () {
    var widthToCompareAfterFontSizing = document.getElementById(
      "widthToCompareWidth"
    ).offsetWidth;
    var widthAfterFontSizingCompared =
      document.getElementsByClassName("width-compared");
    var widthToModifyTwoRows = getCellsWidthTotal(widthAfterFontSizingCompared);

    if (widthToModifyTwoRows > widthToCompareAfterFontSizing) {
      vm.totalTwoRows = true;
    } else {
      vm.totalTwoRows = false;
    }
  };

  function getCellsWidthTotal(elements, half) {
    if (half) {
      var widthComparedTotalArray = [];
      var widthComparedTotal = 0;

      for (var i = 0; i < 4; i++) {
        widthComparedTotal =
          widthComparedTotal + Math.round(elements[i].offsetWidth);
        if (i === 1 || i === 3) {
          widthComparedTotal = widthComparedTotal + 96;
          widthComparedTotalArray.push(widthComparedTotal);
          widthComparedTotal = 0;
        }
      }

      return widthComparedTotalArray;
    } else {
      var widthComparedTotal = 0;

      for (var i = 0; i < 4; i++) {
        // console.log("Element " + i, widthCompared[i].offsetWidth);
        widthComparedTotal =
          widthComparedTotal + Math.round(elements[i].offsetWidth);
        if (i === 3) {
          widthComparedTotal = widthComparedTotal + 192;
        }
      }

      return widthComparedTotal;
    }
  }

  function timeFormat(offset) {
    var minus;
    if (offset < 0) {
      offset = Math.abs(offset);
      minus = true;
    }

    if (offset % 1 > 0) {
      var timeParts = offset.toString().split(".");
      if (timeParts[1] === "25") timeParts[1] = "15";
      else if (timeParts[1] === "75") timeParts[1] = "45";
      else timeParts[1] = "30";

      if (timeParts[0].length === 1) {
        timeParts[0] = "0" + timeParts[0];
      }
      return minus
        ? "-" + timeParts[0] + ":" + timeParts[1]
        : timeParts[0] + ":" + timeParts[1];
    } else {
      if (offset.toString().length === 1) {
        offset = "0" + offset;
      }
      return minus ? "-" + offset + ":00" : offset + ":00";
    }
  }

  $scope.popover = factoryPopover;

  $scope.openPopovers = {
    inventory: false,
    retail: false,
    profit: false,
  };

  $scope.breakpoints = {
    retail: {
      0: "right",
      530: "top-left",
      600: "top-right",
    },
    profit: {
      0: "right",
      600: "top-right",
    },
  };

  const onResize = () => {
    $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
  };

  this.$onInit = () => $window.addEventListener("resize", onResize);
  this.$onDestroy = () => $window.addEventListener("resize", onResize);

  $scope.$watch(
    "openPopovers",
    function () {},
    true
  );
}

export default {
  type: "controller",
  name: "inventoryValuation",
  value: inventoryValuation,
};
