export class BreakpointHandler {
    constructor(breakpoint) {
        this.mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);
        this.subscribers = [];

        this.boundNotify = this.notify.bind(this);

        this.mediaQuery.addEventListener("change", this.boundNotify);
    }

    destroy() {
        this.mediaQuery.removeEventListener("change", this.boundNotify);
    }

    notify(e) {
        this.subscribers.forEach(subscriber => subscriber(e.matches));
    }

    subscribe(cb) {
        this.subscribers.push(cb);
        cb(this.mediaQuery.matches);
    }

    unsubscribe(cb) {
        const index = this.subscribers.indexOf(cb);
        if (index >= 0) {
            this.subscribers.splice(index, 1);
        }
    }
}