import angular from "angular";

factoryPopover.$inject = ["domUtils"];

export function factoryPopover(domUtils) {
  const mobile = "ontouchstart" in document.documentElement;

  const disable = (popovers) =>
      Object.keys(popovers).forEach((i) => (popovers[i] = false));

  return {
    mobile,
    trigger: mobile ? "'none'" : {mouseenter: "mouseleave"},
    animation: true,
    delay: 0,

    responsive: (breakpoints) => {
      const size = domUtils.size();
      const side = Object.entries(breakpoints)
        .reverse()
        .find(([breakpoint]) => size.width > breakpoint);

      return side ? side[1] : "top";
    },

    disable: disable,

    mobileManual: ($scope, popovers, key) => {
      if (mobile) {
        disable(popovers);

        popovers[key] = true;

        setTimeout(() => {
            $scope.$evalAsync(() => {
                popovers[key] = false;
            });
        }, 1000);
      }
    }
  };
}

export default {
  type: "factory",
  name: "factoryPopover",
  value: factoryPopover,
};
