/**
 * Created by A.Sirosh on 16.09.2015.
 */

/**
 * Set the chart period
 * @memberof setChartPeriod
 * @ngdoc service
 * @name setChartPeriod
 * @requires resolveTimezone {service} - resolve timezone problems
 */

setChartPeriod.$inject = ['resolveTimezone', '$rootScope', '$filter', '$stateParams', '$state', 'filtersData', 'lvMixpanel'];
function setChartPeriod(resolveTimezone, $rootScope, $filter, $stateParams, $state, filtersData, lvMixpanel) {
	var timeZoneResolve = resolveTimezone.timeZoneResolveMinus;

	/**
	 * Set from/to parameters
	 * @param Val
	 * @param stateParams
	 */
	function setCalendarPeriod(Val, stateParams) {

		var calendarDateF = new Date(Val.from);
		var calendarDateT = new Date(Val.to);

		var year = calendarDateF.getFullYear();
		var month = calendarDateF.getMonth();
		var date = calendarDateF.getDate();

		var resolveFrom = new Date(year, month, date);
		// var resolveFrom =  new Date(calendarDateF.getFullYear(), calendarDateF.getMonth(), calendarDateF.getDate() + 1, 0, 0, -1);

		//this way we used data with timezone offset before that moment when it was added to the server side processing
		// var dateFrom = timeZoneResolve(resolveFrom);
		var dateFrom = resolveFrom.getTime();

		var resolveTo = new Date(calendarDateT.getFullYear(), calendarDateT.getMonth(), calendarDateT.getDate() + 1, 0, 0, -1);
		// var dateTo = timeZoneResolve(resolveTo);
		var dateTo = resolveTo.getTime();

		var compare = new Date(year, month, date + 1, 0, 0, -1);
		// var compareFromTo = timeZoneResolve(compare);
		var compareFromTo = compare.getTime();

		if (dateTo === compareFromTo) {
			stateParams.group = 'hour';
		} else {
			stateParams.group = 'day';
		}
		stateParams.from = dateFrom;
		stateParams.to = dateTo;
		stateParams.page = 0;
	}

	/**
	 * Set from/to parameters to depends on quick button
	 * @param params
	 * @returns {{from: null, to: null, group: string, divider: string}}
	 */
	function setButtonPeriod(params, _currentParams) {
		let currentParams = _currentParams || {};
		var stateParams = {
			from: null,
			to: null,
			group: '',
			divider: '',
			page: 0
		};
		var currentDate = new Date();

		var year = currentDate.getFullYear();
		var month = currentDate.getMonth();
		var day = currentDate.getDay();
		var date = currentDate.getDate();
		var mm = currentDate.getMinutes();
		var hh = currentDate.getHours();
		var offset = currentDate.getTimezoneOffset();

		function today() {
			// var resolveFrom = new Date(year, month, date);
			// var resolveTo = new Date(year, month, date + 1, 0, 0, -1);
			stateParams.from = null;
			stateParams.to = null;
			stateParams.group = 'hour';
			stateParams.divider = 'today';
			stateParams.periodName = "day";
			stateParams.periodLength = "1d";
			stateParams.arg = 0;
		}

		function yesterday() {
			// var resolveFrom = new Date(year, month, date - 1);
			// var resolveTo = new Date(year, month, date, 0, 0, -1);
			stateParams.from = null;
			stateParams.to = null;
			stateParams.group = 'hour';
			stateParams.divider = 'yesterday';
			stateParams.periodName = "day";
			stateParams.periodLength = "1d";
			stateParams.arg = -1;
		}

		function thisWeek(params) {

			stateParams.from = null;
			stateParams.to = null;

			if (($rootScope.startWeek === 1 && day === 1) || ($rootScope.startWeek === 0 && day === 0)) {
				stateParams.group = 'hour';
				stateParams.divider = 'day';
			} else {
				stateParams.group = 'day';
				stateParams.divider = 'week';
			}
			if (params.group === 'hour' || params.group === 'day' || params.group === 'week') {
				stateParams.group = params.group;
			}
			stateParams.periodName = "week";
			stateParams.periodLength = "7d";
			stateParams.arg = 0;
		}

		function lastWeek(params) {

			// var currentDayFrom;
			//
			// if(($rootScope.startWeek === 1 && day === 0) || ($rootScope.startWeek === 0 && day === 6)){
			 //    currentDayFrom = date - 13;
			 //    currentDateTo = date - 6;
			// } else if ($rootScope.startWeek === 1) {
			 //    currentDayFrom = date - day - 6;
			 //    currentDateTo = date - day + 1;
			// } else if ($rootScope.startWeek === 0){
			 //    currentDayFrom = date - day - 7;
			 //    currentDateTo = date - day;
			// }

			// var resolveFrom = new Date(year, month, currentDayFrom);
			//
			// var resolveTo = new Date(year, month, currentDateTo, 0, 0, -1);

			stateParams.from = null;
			stateParams.to = null;
			let group = (params.group === 'day' || params.group === 'week') ? params.group : 'day';
			stateParams.group = group;
			stateParams.divider = 'lastWeek';
			stateParams.periodName = "week";
			stateParams.periodLength = "7d";
			stateParams.arg = -1;
		}

		function sevenDays(params){
			// var resolveFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6);
			// var resolveTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, -1);

			stateParams.from = null;
			stateParams.to = null;
			let group = (params.group === 'day' || params.group === 'week') ? params.group : 'day';
			stateParams.group = group;
			stateParams.divider = 'sevenDays';
			stateParams.periodName = "lastSeven";
			stateParams.periodLength = "7d";
			stateParams.arg = null;
		}

		function thirtyDays(params){
			// var resolveFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 29);
			// var resolveTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, -1);

			stateParams.from = null;
			stateParams.to = null;
			let group = (params.group === 'day' || params.group === 'week') ? params.group : 'day';
			stateParams.group = group;
			stateParams.divider = 'thirtyDays';
			stateParams.periodName = "lastThirty";
			stateParams.periodLength = "30d";
			stateParams.arg = null;
		}

		function thisMonth(params) {
			// var resolveFrom = new Date(year, month, 1);
			// var resolveTo = new Date(year, month, date + 1, 0, 0, -1);
			var resolveCompare = new Date(year, month, 2, 0, 0, -1);

			stateParams.from = null;
			stateParams.to = null;

			var compare = new Date(year, month, date + 1, 0, 0, -1);
			var compareFromTo = compare.getTime();
			var resolveCompareTo = resolveCompare.getTime();

			if (resolveCompareTo === compareFromTo) {
				stateParams.group = 'hour';
				stateParams.divider = 'month';
			} else {
				stateParams.group = 'day';
				stateParams.divider = 'month';
			}
			if (params.group === 'hour' || params.group === 'day' || params.group === 'week') {
				stateParams.group = params.group;
			}
			stateParams.periodName = "month";
			stateParams.periodLength = "1m";
			stateParams.arg = 0;
		}

		function lastMonth(params) {
			// var resolveFrom = new Date(year, month-1, 1);
			// var resolveTo = new Date(year, month, 1, 0, 0, -1);

			stateParams.from = null;
			stateParams.to = null;

			let group = (params.group === 'day' || params.group === 'week') ? params.group : 'day';

			stateParams.group = group;
			stateParams.divider = 'lastMonth';
			stateParams.periodName = "month";
			stateParams.periodLength = "1m";
			stateParams.arg = -1;
		}

		switch (params) {
			case 'today':
				today();
				break;
			case 'yesterday':
				yesterday();
				break;
			case "week":
				thisWeek(currentParams);
				break;
			case "month":
				thisMonth(currentParams);
				break;
			case "lastWeek":
				lastWeek(currentParams);
				break;
			case "lastMonth":
				lastMonth(currentParams);
				break;
			case "sevenDays":
				sevenDays(currentParams);
				break;
			case "thirtyDays":
				thirtyDays(currentParams);
				break;
		}
		return stateParams;
	}


	/**
	 *
	 * @param {string} period today || yesterday || month || week
	 * @returns {{from: {int}, to: {int}}}
	 */
	function getDates(period, arg) {
		var _d = {
			from: null,
			to: null
		};
		var currentDate = new Date();
		switch (period) {
			case 'day':
				switch (arg){
					case 0:
						_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
						break;
					case -1:
						_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1).getTime();
						break;
				}
				break;
			case 'week':
				switch (arg){
					case 0:
						var day = currentDate.getDay();
						if($rootScope.startWeek === 0) {
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day).getTime();
						} else if ($rootScope.startWeek === 1 && day === 0){
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6).getTime();
						} else {
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day +1).getTime();
						}
						_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
						break;
					case -1:
						var day = currentDate.getDay();
						if($rootScope.startWeek === 0) {
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day - 7).getTime();
							_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day - 1).getTime();
						} else if ($rootScope.startWeek === 1 && day === 0){
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 13).getTime();
							_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6).getTime();
						} else {
							_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day - 6).getTime();
							_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - day).getTime();
						}

						break;
				}
				break;
			case 'month':
				switch (arg) {
					case 0:
						var year = currentDate.getFullYear();
						var month = currentDate.getMonth() + 1;
						_d.from = year + "-" + month;
						break;
					case -1:
						var year = currentDate.getFullYear();
						var month = currentDate.getMonth();
						_d.from = year + "-" + month;
						break;
					default:
						var parts =arg.split('-');
						_d.from = parts[0] + "-" + parts[1];
						break;
				}
				break;
			case 'lastSeven':
				_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6).getTime();
				_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
				break;
			case 'lastThirty':
				_d.from = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 29).getTime();
				_d.to = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
				break;

		}
		return _d
	}

    function onChangeChartPeriod(period) {
        const chartPeriod = setButtonPeriod(period, $stateParams);

        $stateParams.receiptId = null;
        $stateParams.from = chartPeriod.from;
        $stateParams.to = chartPeriod.to;

        $stateParams.group = chartPeriod.group;
        $stateParams.page = chartPeriod.page;
        $stateParams.periodName = chartPeriod.periodName;
        $stateParams.periodLength = chartPeriod.periodLength;
        $stateParams.arg = chartPeriod.arg;

        let savedStateParams = filtersData.getStateParams();

        $stateParams.serverChartType = savedStateParams.serverChartType;

        if (chartPeriod.divider === "today")     {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Today"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Today"});
            }
        }
        if (chartPeriod.divider === "yesterday") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter',   {"Property": "Inventory history/Select date range/Yesterday"});
            } else {
                lvMixpanel.track('Filter',   {"Property": "Reports/Select date range/Yesterday"});
            }
        }
        if (chartPeriod.divider === "week") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/This week"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/This week"});
            }
        }
        if (chartPeriod.divider === "lastWeek") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Last week"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Last week"});
            }
        }
        if (chartPeriod.divider === "month") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/This month"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/This month"});
            }
        }
        if (chartPeriod.divider === "lastMonth") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Last month"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Last month"});
            }
        }
        if (chartPeriod.divider === "sevenDays") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Last 7 days"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Last 7 days"});
            }
        }
        if (chartPeriod.divider === "thirtyDays") {
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Last 30 days"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Last 30 days"});
            }
        }

        $state.go($state.current.name, filtersData.setStateParams($stateParams), {reload: true});
    }

	this.setButtonPeriod = setButtonPeriod;
	this.setCalendarPeriod = setCalendarPeriod;
	this.getDates = getDates;
	this.onChangeChartPeriod = onChangeChartPeriod;
}

export default {
	type: 'service',
	name: 'setChartPeriod',
	value: setChartPeriod
};
