/**
 * Created by mars on 10/27/15.
 */

import angular from 'angular';

factoryProfile.$inject = [
	'$http',
	'$q',
	'factoryErrorAlert',
	'constantGroupTypeOwner',
	'$filter',
	'constantLangCollection',
	'serviceLang',
	'promiseOperation',
	'$injector',
	'$rootScope',
	'deviceDetector',
	'resolveTimezone',
	'retrieveLangCode',
	'dateCustomMethods',
	'constantTimeZoneCollection',
	'$translate',
	'lvCountries',
	'currencyFormat',
	'lvMixpanel'];

function factoryProfile( $http,
						 $q,
						 factoryErrorAlert,
						 constantGroupTypeOwner,
						 $filter,
						 constantLangCollection,
						 serviceLang,
						 promiseOperation,
						 $injector,
						 $rootScope,
						 deviceDetector,
						 resolveTimezone,
						 retrieveLangCode,
						 dateCustomMethods,
						 constantTimeZoneCollection,
						 $translate,
						 lvCountries,
						 currencyFormat,
						 lvMixpanel
						) {
	var data = {_load: false};
	var promise;

	$translate.use('empty');

	function success( resolve, d, reload ) {

		var currentDate = dateCustomMethods.setTimeToZero(new Date());
			currentDate = new Date(currentDate);

		if (angular.isObject(d)) {

			// использовалось фильтром "currenciesFormat" и контроллерами до 18,05,17 для стран без знаков после запяой
			$rootScope.numberOfSigns = d.cashFractionDigits;


			for (var opt in d) {
				if (d.hasOwnProperty(opt)) {
					data[opt] = d[opt];
				}
			}

			data._logo = null;
			data._background = null;
			data._category = (function () {
				var i = constantGroupTypeOwner.length;
				while (0 < i--) {
					if (data.categoryId === constantGroupTypeOwner[i]._id) {
						return constantGroupTypeOwner[i];
					}
				}
				return constantGroupTypeOwner[constantGroupTypeOwner.length - 1];
			}());

			data._creditRate = $filter('coinToCurrency')(data.creditRate, '.');
			data._initialCredits = $filter('coinToCurrency')(data.initialCredits, '.');

			data._load = true;


			var localeCode,
				localeArray;

			$rootScope.translateIssue = {};
			$rootScope.isMobile = deviceDetector.isMobile();
			$rootScope.offsetTS = resolveTimezone.timeZoneOffsetForReports();
			$rootScope.timeZoneName = data.timeZoneName;
			$rootScope.cabinetLang = data.cabinetLang;
			$rootScope.startWeek = 1;
			retrieveLangCode.defineLangCountryCode(data.cabinetLang, data.country);

			localeCode = window.navigator.languages ? (window.navigator.languages[0]) :
				window.navigator.browserLanguage ? window.navigator.browserLanguage :
					window.navigator.userLanguage ? window.navigator.userLanguage : "en-GB";



			for (var country in constantTimeZoneCollection) {
				if (constantTimeZoneCollection[country].locale) {
					localeArray = constantTimeZoneCollection[country].locale;
					if (localeArray.indexOf(localeCode) > -1) {
						$rootScope.startWeek = constantTimeZoneCollection[country].startWeek;
						break;
					}
				}
			}

			$rootScope.is12HourFormat = retrieveLangCode.defineWhetherItIs12HourFormat(currentDate);

			data.cabinetLang = (function ( lang ) {
				var i = 0;
				do {
					if ( constantLangCollection[i].key === lang ) {
						return lang;
					}
				} while (++i < constantLangCollection.length);
				return serviceLang.defaultLang;
			})(data.cabinetLang);

			data.lang = (function (lang) {
				var i = 0;
				do {
					if (constantLangCollection[i].key === lang) {
						return lang;
					}
				} while (++i < constantLangCollection.length);
				return serviceLang.defaultLang;
			})(data.lang);


			if (reload) {
				currencyFormat.reset();
			}

			/*** установка формата ввода валюты ***/
			currencyFormat.setCurrencyOptionsEdit(data);
			currencyFormat.setCurrencyOptionsEditModif(data);
			/** установка формата ввода баланса баллов клиента **/
			currencyFormat.setPointsCurrencyEdit(data);
			/** установка формата отображения баланса баллов клиента **/
			currencyFormat.setPointsCurrencyShow(data);
			/*** установка формата отображения валюты ***/
			currencyFormat.setCurrencyOptionsShow(data);
			/*** установка формата ввода остатка товара ***/
			currencyFormat.setCountOptionsEdit(data);
			/*** установка формата ввода критического остатка товара ***/
			currencyFormat.setCritCountOptionsEdit(data);
			/***формат ввода количества элемента составного товара***/
			currencyFormat.setQuantOptionsEdit(data);
			/*** установка формата отображения остатка товара  ***/
			currencyFormat.setCountOptionsShow(data);
			/*** установка формата отображения остатка товара c "плюсиком"  ***/
			currencyFormat.setCountOptionsShowWithPlus(data);
			/*** установка формата отображения часов  ***/
			currencyFormat.setHoursOptionsShow(data);
			/***установка формата отображения остатка элемента составного весового товара***/
			currencyFormat.setCountOptionsEditDivisible(data);
			/***установка формата отображения количества штучного товара при заказе и получении товара***/
			currencyFormat.setCountQuantityForOrder(data);
			/***установка формата отображения количества весового товара при заказе и получении товара***/
			currencyFormat.setCountQuantityForOrderDivisible(data);
			/***установка формата отображения денежных значений в БИЛЛИНГЕ***/
			currencyFormat.setCurrencyShowBilling(data);
			/***установка формата отображения количества для этикеток - без дробной части и максимум 4 знака***/
			currencyFormat.setCountQuantityForLabels(data);
			/***установка формата отображения процентов - "от бесконечности до бесконечности"***/
			currencyFormat.setPercentShow(data, true, false, false, false);
			/***установка формата отображения процентов - "от бесконечности до 100%"***/
			currencyFormat.setPercentShow(data, false, true, false, false);
			/***установка формата отображения процентов - "от 0 до 100%"***/
			currencyFormat.setPercentShow(data, false, false, true, false);
			/***установка формата отображения процентов - для налогов с пятью знаками после запятой***/
			currencyFormat.setPercentShow(data, false, false, false, true);

			$rootScope.cabinetLang = data.cabinetLang;

			$translate.use( data.cabinetLang || serviceLang.getLocalStorageLang() || serviceLang.getSystemLang3() );

			resolve(data);
			promise = null;


		}
	}

	function grToCop( string ) {
		if ( typeof string != 'string' ) {
			string = new String( string );
		}
		var pos = string.indexOf( '.' );
		string = string.replace( '.', '' );
		string += '00';
		var arr = string.split( '' );
		if ( 0 < pos ) {
			arr.splice( pos + 2, 0, '.' );
		}
		string = arr.join( '' );
		string = parseFloat( string );
		string = '' + string;
		return string;
	}

	//-- FOR WORKING WITH MIXPANEL
	function timeConverter(UNIX_timestamp){
		var a = new Date(UNIX_timestamp);
		var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		var year = a.getFullYear();
		var month = months[a.getMonth()];
		var date = a.getDate();
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();
		var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
		return time;
	}

	function getCountryName(array, search) {
		var i = array.length;
		while (i--) {
			if (array[i].code === search) {
				return array[i].name;
			}
		}
	}

	function getUILang( lang ) {
		for ( let i = 0; i < constantLangCollection.length; i++) {
			if ( constantLangCollection[i].key === lang ) {
				return constantLangCollection[i].value;
			}
		}
	}

	function setMixpanelDate( dateCreated, countryReg, uiLanguage, d ) {
		if ( angular.isObject( d ) ) {
			lvMixpanel.identify( d.ownerId );
			lvMixpanel.people.set({
				"$name": d.name,
				"$email": d.email,
				"$created": dateCreated,
				"Country of registration": countryReg,
				"$ID": d.ownerId,
				"UI Language": uiLanguage,
			});
		}
	}
	//-- END FOR WORKING WITH MIXPANEL

	function getLoadPromise( reload ) {
		return $q((resolve, reject) => {
			promiseOperation.post( '/data/ownercab/getprofile', {} )
				.then(function( d ){
					success( resolve, d, reload );
					var dateCreated = timeConverter( d.created );
					var countryReg = getCountryName( lvCountries, d.country );
					var uilang = getUILang( d.cabinetLang );
					setMixpanelDate( dateCreated, countryReg, uilang, d );
				});
		});
	}

	function getProfile( reload ) {
		if ( reload ) {
			data._load = false;
		}
		if ( data._load ) {
			promise = (!promise || promise.$$state.status === 1) ? $q( function (resolve, reject ) {
				resolve( data );
			} ) : promise;
		} else {
			promise = (!promise || promise.$$state.status === 1) ? getLoadPromise(reload) : promise;
		}
		return promise;
	}

	function dataBeforeSave( editProfile ) {
		var _data = angular.copy( editProfile );

		_data.initialCredits = grToCop( _data._initialCredits );
		_data.creditRate = grToCop( _data._creditRate );

		for ( var opt in _data ) {
			switch ( opt ) {
				case 'logoUrl':
					if ( !_data._logo ) {
						delete  _data[opt];
						_data.logoImg = 'untouched';
					}
					break;
				case 'result':
					delete  _data[opt];
					break;

				case '_logo':
					if ( _data[opt] ) {
						_data.logoImg = _data[opt].replace( /^data.+,/, "" );
					}

					break;
				case '_background':
					if ( _data[opt] ) {
						_data.backgroundImg = _data[opt].replace( /^data.+,/, "" );
					}
					break;

			}


			if ( opt.match( /^_/ ) ) {
				delete  _data[opt];
			}
		}
		return _data;
	}

	function setProfile( editProfile ) {
		let _data = dataBeforeSave( editProfile );
		return $q( function ( resolve, reject ) {
			$http.post( '/data/ownercab/setprofile', _data ).then( function ( d ) {
				for (let opt in editProfile ) {
					if ( data.hasOwnProperty( opt ) ) {
						data[opt] = editProfile [opt];
					}
				}
				data._load = false;
				promise = null;
				resolve( d.data );
			}, function ( d ) {
				resolve( d.data );
				factoryErrorAlert.onError( d );
			} );
		} );
	}

	return {
		getProfile: getProfile,
		setProfile: setProfile,
		profile: data
	};

}

export default {
	type: 'factory',
	name: 'factoryProfile',
	value: factoryProfile
};
