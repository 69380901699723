/**
 * Created by mars on 12/15/15.
 *
 * Red - #F44336
 Pink - #E91E63
 Blue - #2196f3
 Green - #4CAF50
 Orange - #FF9800
 Grey - #E2E2E2
 Lime - #CDDC39
 Purple - #9C27B0
 *
 */

const constantColorsGoodsCollection =
{
	GREY: "#E2E2E2",
	RED: "#F44336",
	PINK: "#E91E63",
	ORANGE: "#FF9800",
	LIME: "#CDDC39",
	GREEN: "#4CAF50",
	BLUE: "#2196F3",
	PURPLE: "#9C27B0"
};

export default {
	type: 'constant',
	name: 'constantColorsGoodsCollection',
	value: constantColorsGoodsCollection
};
