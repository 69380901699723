/**
 * Edited by kseniya.yarosh on 13.12.2016
 */

import angular from 'angular';

reportGoodsControl.$inject = [
	'$scope',
	'$stateParams',
	'factoryReportGoods',
	'$rootScope',
	'$state',
	'groupBy',
	'setChartPeriod',
	'changeDivider',
	'getGroupBySelect',
	'stateGo',
	'$report',
	'$tableProp',
	'waresReportTable',
	'$window',
	'filtersData',
	'$currencyOptionsShow',
	'$percentShowInfinityToHundred',
	'dateCustomMethods',
	'$filter',
	'$profile',
	'constantGreenExport',
	'tableColumnProp',
	'factoryPermits',
	'$timeout',
	'$permitsManager',
	'$miniBillingInfo',
	'serviceReports',
	'windowResizeUtil',
	'$reportCopyAllowed'
];
function reportGoodsControl(
	$scope,
	$stateParams,
	factoryReportGoods,
	$rootScope,
	$state,
	groupBy,
	setChartPeriod,
	changeDivider,
	getGroupBySelect,
	stateGo,
	$report,
	$tableProp,
	waresReportTable,
	$window,
	filtersData,
	$currencyOptionsShow,
	$percentShowInfinityToHundred,
	dateCustomMethods,
	$filter,
	$profile,
	constantGreenExport,
	tableColumnProp,
	factoryPermits,
	$timeout,
	$permitsManager,
	$miniBillingInfo,
	serviceReports,
	windowResizeUtil,
	$reportCopyAllowed) {

	let vm = this;
	let body = document.querySelector('body');
	let translate = $filter('translate');
	let container = $("#container");
	let currencyFormatOnlyShow = $filter('currencyFormatOnlyShow');
	let cleanupFn = [];

	$scope.blockDataCopy = !$reportCopyAllowed;

	$scope.displayCost = $permitsManager.costAllowed;

	$scope.tableProp = $tableProp.dataArr;

	$stateParams.chart = $scope.tableProp[13] ? $scope.tableProp[13].chartType : $stateParams.chart;
	if ($scope.tableProp.length === 14) {
		$scope.tableProp.splice(13, 1);
	}

	$scope.hiddenColumns = $scope.displayCost ? [] : ['primeCost', 'grossProfit', 'margin'];
	$scope.tableProp = angular.copy($scope.tableProp);

	$scope.tableConfig = $tableProp.config;
	$scope.isMobile = $rootScope.isMobile;
	$scope.isSafari = $rootScope.isSafari;
	$scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

	$scope.isRTLLang = body.classList.contains('aranav');

	$scope.pageWrapHeight = document.getElementsByClassName('report-goods')[0];

	$scope.countColums = size($scope.tableConfig);

	$scope.$on('howManyColumns', function(data){
		$scope.countColums = size(data.targetScope.vmTableProps.configValue);
	});

	$scope.$on("$destroy", () => {
		if (cleanupFn.length > 0) {
			cleanupFn.forEach(fn => fn());
			cleanupFn = [];
		}
	});

	$scope.nameLengthLimit = 25;
	cleanupFn.push(
		windowResizeUtil.addResizeListener(600, match => {
			$scope.$applyAsync(() => {
				$scope.nameLengthLimit = match ? 60 : 40;
			});
		}));
	cleanupFn.push(
		windowResizeUtil.addResizeListener(992, match => {
			$scope.$applyAsync(() => {
				$scope.nameLengthLimit = match ? 18 : 60;
			});
		}));
	cleanupFn.push(
		windowResizeUtil.addResizeListener(1320, match => {
			$scope.$applyAsync(() => {
				$scope.nameLengthLimit = match ? 25 : 18;
			});
		}));

	$scope.selectedToppings = [];
	$scope.waresReportTable = waresReportTable;
	$scope.reportTypeForColumns = "WARES";
	$scope.windowWidth = $window.outerWidth;

	$scope.report = $report;
	$scope.reportError = $scope.report.hasOwnProperty('unableToLoadData');
	$scope.reportErrorServer = $scope.reportError && $scope.report.unableToLoadData;

	$scope.stateParams = $stateParams;

	$scope.showContainer = false;

	if (!$scope.reportError) {
		$scope.periodAllowed = $report.periodAllowed;
		$scope.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

		$scope.pages = $scope.report.pages;

		if ($scope.report.startDateString && $scope.report.endDateString) {
			let fromParse = $scope.report.startDateString.split('-');
			let toParse = $scope.report.endDateString.split('-');
			$scope.calendarPeriod = {
				from: new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime(),
				to: new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime(),
				maxDate: new Date()
			};
		} else {
			$scope.calendarPeriod = {
				from: $scope.report.startDate,
				to: $scope.report.endDate,
				maxDate: new Date()
			};
		}

		$scope.outlets = $scope.report.fillSelectData.outlets;
		$scope.merchants = serviceReports.getMerchantsList($scope.outlets);

		if ($scope.periodAllowed) {
			$rootScope.country = 'in';

			$scope.charts = [
				{
					type: 'line',
					name: translate('GROUP_BY.line')
				},
				{
					type: 'bar',
					name: translate('GROUP_BY.column')
				},
				{
					type: 'pie',
					name: translate('GROUP_BY.pie')
				}
			];

			// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
			if ($scope.report.wares.length === 0 && $stateParams.page > 0 && $scope.pages > 0) {
				$state.go('report.goods', {page: $scope.pages - 1});
			} else if ($scope.report.wares.length === 0 && $stateParams.page > 0 && $scope.pages === 0) {
				$state.go('report.goods', {page: 0});
			}
			// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


			/*** формат отображения валюты ***/
			$scope.currencyOptionsShow = $currencyOptionsShow;
			if ($scope.currencyOptionsShow.digitalGroupSpacing === 2) {
				$scope.currencyPattern = '#,##,###.00';
			} else {
				$scope.currencyPattern = ($scope.currencyOptionsShow.decimalPlacesOverride === 0) ? '#,##0' : '#,##0.00';
			}
			$scope.currencyPattern = ($rootScope.indianCurrencyPattern) ? $rootScope.indianCurrencyPattern : $scope.currencyPattern;


			/*** формат отображения процентов ***/
			$scope.percentShowInfinityToHundred = $percentShowInfinityToHundred;

			$scope.setChartPeriod = setChartPeriod;
			$scope.stateGo = stateGo;
			$scope.changeDivider = changeDivider;
			$scope.changeDivider.changeDividerLabel = changeDivider.changeDividerLabel;
			$scope.groupBy = groupBy;
			$scope.groupBy.select = getGroupBySelect.getSelect($scope.groupBy, $stateParams.group);

			$stateParams.group = $scope.changeDivider.changeDividerLabel($scope.groupBy.select);

			$scope.dataHover;
			$scope.variantsHover;

			$scope.onGrey = function (index) {
				$scope.dataHover = index;
			};

			$scope.offGrey = function () {
				$scope.dataHover = NaN;
			};

			$scope.onHov = function (index) {
				$scope.variantsHover = index;
			};

			$scope.offHov = function () {
				$scope.variantsHover = NaN;
			};

			$scope.changeGroupBy = function (item) {

				$scope.item = $scope.changeDivider.changeDividerItem(item, $stateParams.from, $stateParams.to);
				if ($scope.item === false) {
					return false;
				}

				$scope.groupBy.select = $scope.item;

				$stateParams.group = $scope.changeDivider.changeDividerLabel($scope.groupBy.select);
				$stateParams.page = 0;
				filtersData.setStateParams($stateParams);

				$scope.stateGo.go($state, $stateParams, true);
			};

			$scope.allPieValuesNegative = checkNegativeValuesForPie($scope.report._dataForSeriePie);

			$scope.menuWidth = function () {
				let menuButtonWidth = document.getElementsByClassName('chartType')[0].offsetWidth - 10;
				let groupMenuList = document.getElementsByClassName('chooseGroup')[0];
				let chartTypeMenuList = document.getElementsByClassName('chooseChartType')[0];
				if (groupMenuList) angular.element(groupMenuList.parentNode).addClass('noAnimate');
				if (chartTypeMenuList) {
					chartTypeMenuList.style.width = menuButtonWidth + 32 + 'px';
					angular.element(chartTypeMenuList.parentNode).addClass('noAnimate');
				}
			};

			$scope.setChart = function (charType) {
				$stateParams.chart = charType;

				let tablePropsCopy = $scope.tableProp.slice();
				tablePropsCopy[13] = {chartType: charType};

				let params = {};
				params.reportType = $scope.reportTypeForColumns;
				params.columnsView = tablePropsCopy;

				factoryReportGoods.changeChart(charType);
				if (factoryPermits.editProfile) {
					tableColumnProp.setProps(params);
				}

				showChartOrHideContainer();
			};

			showChartOrHideContainer();

			changeSelectWidthForMobile();
			$window.addEventListener('resize', changeSelectWidthForMobile);

			$scope.$on('$destroy', () => {
				$window.removeEventListener('resize', changeSelectWidthForMobile);
			});
		}
	}

	function showChartOrHideContainer() {
		if ($scope.report._wares.length > 0) {
			if ($stateParams.chart !== 'pie' || ($stateParams.chart === 'pie' && !$scope.allPieValuesNegative)) {
				$scope.series = $stateParams.chart === 'pie'
					? [{data: setSeriesForPie($scope.report._dataForSeriePie, $scope.report._wares)}]
					: setSeries($scope.report._dataForSerie, $scope.report._wares);

				$timeout(function () {
					if (!$scope.showContainer) {
						$scope.showContainer = true
					}
					drawChart();
					$('.highcharts-credits').hide();
				}, 0);
			} else {
				$scope.showContainer = false;
			}
		} else {
			$scope.showContainer = false;
		}
	}

	function drawChart() {
		Highcharts.chart('container', {
			chart: {
				type: (function () {
					if($stateParams.chart === 'bar') {
						return 'column'
					} else {
						return $stateParams.chart
					}
				}()),
				height: 292,
				spacingTop: 40,
				spacingLeft: 24,
				spacingRight:24,
				spacingBottom: 20
			},
			title: {
				text: ""
			},
			xAxis: {
				type: "category",
				categories: $stateParams.chart === 'pie' ? $scope.report._categoriesForPie : $scope.report._categories,
				lineColor: "#EAEAEA",
				gridLineColor: '#EAEAEA',
				tickColor: null,
				tickmarkPlacement: "on",
				reversed: $scope.isRTLLang,
				labels: {
					style: {
						fontSize: "12px",
						fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
						color: "#333333"
					}
				}
			},
			yAxis: {
				title: {
					text: ""
				},
				labels: {
					formatter: function() {
						return currencyFormatOnlyShow(this.value, ".");
					},
					style: {
						fontSize: "12px",
						fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
						color: "#333333"
					}
				},
				opposite: $scope.isRTLLang
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				line: {},
				column: {
					pointPadding: 0.1,
					groupPadding: 0.01,
					borderWidth: 0,
					shadow: false,
					stacking: 'normal'
				},
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						enabled: false
					}
				}
			},
			series: $scope.series,
			exporting: {
				enabled: false
			},
			tooltip: {
				hideDelay: 0,
				borderColor: "#FFFFFF",
				backgroundColor: "#FFFFFF",
				borderRadius: 2,
				padding: 7,
				height: "48px",
				useHTML: true,
				formatter: function() {
					let tooltipFormat;
					if($stateParams.chart === 'pie') {
						tooltipFormat = '<span style="display: inline-block; padding-bottom: 7px">' + this.key + '</span><br><span style="display: inline-block;"><b>' + currencyFormatOnlyShow(this.y, ".") + '</b></span>';
					} else {
						tooltipFormat = '<span style="display: inline-block; padding-bottom: 7px">' + this.key + '</span><br><span style="display: inline-block; padding-bottom: 7px">'+this.series.name+'</span><br><span style="display: inline-block;"><b>' + currencyFormatOnlyShow(this.y, ".") + '</b></span>';
					}

					return tooltipFormat;
				},
				style: {
					fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
					lineHeight: 1,
					fontSize: "12px"
				}
			},
		})
	}

	function setSeries(dataForSerie, wares) {
		let seriesForBar = wares.map(function (ware) {
			return {
				name: ware.name,
				color: ware._color,
				data: dataForSerie[ware.id],
			}
		});

		if ($stateParams.chart === 'bar') {
			seriesForBar.forEach(function (item) {
				item.stack = 0;
			})
		} else if($stateParams.chart === 'line') {
			seriesForBar.forEach(function (item) {
				item.marker = {
					enabled: false,
					symbol: "circle",
					states: {
						hover: {
							enabled: true,
							fillColor: item.color,
							radius: 3,
							lineColor: item.color
						}
					}
				}
			})
		}

		return seriesForBar.reverse()
	}

	function setSeriesForPie(dataForSeriePie, wares) {
		let seriesForPie = wares.map(function (ware) {
			return {
				y: dataForSeriePie[ware.id],
				name: ware.name,
				color: ware._color
			}
		});

		seriesForPie.push({
			y: dataForSeriePie[0],
			name: translate('OTHERS'),
			color: "#EBEBEB"
		});

		return seriesForPie;
	}

	/* Функция возвращает true, если все значения для Pie Chart отрицательные, иначе возвращает false */

	function checkNegativeValuesForPie(values) {
		let allNegative = false;

		for(let key in values) {
			if(key !== "0" && !isNaN(parseFloat(values[key])) && isFinite(values[key])) {
				allNegative = values[key] < 0;
			}
		}

		return allNegative;
	}

	function changeSelectWidthForMobile() {
		$scope.windowWidth = $window.outerWidth;
		let selects = document.getElementsByClassName("title-graph-container-select");
		if($window.innerWidth < 600) {
			$timeout(function () {
				let selectsBlock = document.getElementsByClassName("title-graph-container-selects")[0];
				let oneLineSelectWidth = (selectsBlock.offsetWidth - 56) / 2;
				let selectTexts = document.getElementsByClassName("select-menu-text");

				let length = selectTexts.length;

				for (let i = 0; i < length; i++) {
					if(selectTexts[i].offsetWidth + 39 > oneLineSelectWidth) {
						for (let j = 0; j < length; j++) {
							selects[j].style.width = '100%';
							selects[j].style.paddingTop = '20px';
						}
						break;
					} else if(i === (length - 1) && selectTexts[i].offsetWidth + 39 <= oneLineSelectWidth) {
						for (let k = 0; k < length; k++) {
							selects[k].style.width = oneLineSelectWidth.toFixed(0) + 'px';
						}
					}
				}
			}, 0);
		} else {
			for (let i = 0, length = selects.length; i < length; i++) {
				if(selects[i].hasAttribute('style')) {
					selects[i].removeAttribute('style');
				}
			}
		}
	}

	function size(obj) {
		let size = 0, key;
		for (key in obj) {
			if (obj[key] === true) size++;
		}
		return size;
	}

}

export default {
	type: 'controller',
	name: 'reportGoodsControl',
	value: reportGoodsControl
};
