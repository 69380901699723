/**
 * Created by mars on 5/11/16.
 */

import angular from 'angular';

discountCreateEditControl.$inject = [
	'$stateParams',
	'factoryDiscount',
	'$discount',
	'$state',
	'serviceToast',
	'$filter',
	'serviceDialog',
	'$scope',
	'$shortOutlets',
	'lodash',
	'$currencyOptionsEdit',
	'$percentShowZeroToHundred',
	'$timeout',
	'$translate',
	'lvMixpanel',
	'dialogs'
];

function discountCreateEditControl(
	$stateParams,
	factoryDiscount,
	$discount,
	$state,
	serviceToast,
	$filter,
	serviceDialog,
	$scope,
	$shortOutlets,
	lodash,
	$currencyOptionsEdit,
	$percentShowZeroToHundred,
	$timeout,
	$translate,
	lvMixpanel,
	dialogs
) {

	// $scope.createDiscountForm = undefined;
	let translate = $filter( 'translate' );
	let discount,
		offStateChangeStart;

	//-- data to save the state of the filters
	$scope.stateFromList = $stateParams.stateToSave;
	$scope.checkFromList = $stateParams.checkToSave;
	//-- END data to save the state of the filters

	/*** формат ввода валюты ***/
	$scope.currencyOptionsEdit = $currencyOptionsEdit;

	/*** формат ввода процентов ***/
	$scope.percentShowZeroToHundred = $percentShowZeroToHundred;


	//-- read the number of decimal places in the currency
	$scope.numberOfSigns = $scope.currencyOptionsEdit.decimalPlacesOverride;
	$scope.percent = {
		min: 0.01,
		max: $scope.percentShowZeroToHundred.maximumValue,
		value: ''
	};
	const amountNotLessThen = ($scope.numberOfSigns === 0) ?
		1 : $filter('coinToCurrency')(1, $scope.currencyOptionsEdit.decimalCharacter);
	$scope.amount = {
		min: parseFloat(amountNotLessThen),
		max: $scope.currencyOptionsEdit.maximumValue,
		minMsg: $translate.instant(
			'ERROR.discount_amount_min', {amount: amountNotLessThen}),
		maxMsg: $scope.numberOfSigns === 0 ?
			$translate.instant('ERROR.max_price_value_limit_zero_signs') :
			$translate.instant('ERROR.max_price_value_limit'),
		value: ''
	};

	$scope.discount = $discount;
	$scope.percent.value = $scope.discount._value;
	$scope.amount.value = $scope.discount._value;

	discount = angular.copy($discount);

	$scope.showDel = $stateParams.id !== undefined || $stateParams.uuid !== undefined;


	//-- Container for FACILITIES - SELECT
	$scope.state = $stateParams;

	if ( $stateParams.id || $stateParams.uuid ) {
		$scope.outlets = $scope.discount.byOutlets;
	} else {
		$scope.outlets = $shortOutlets;
	}

	$scope.waresCount = 0;
	$scope.outletSelect = [];
	$scope.allOutlets = {};

	$scope.$watch('outlets', function(newVal){

		for(let j = 0; j < newVal.length; j++){
			if ( $stateParams.id || $stateParams.uuid) {
				if(newVal[j].enable === true){
					$scope.outletSelect.push(newVal[j].outletId);
					$scope.waresCount += newVal[j].waresCount;
				}
			} else {
				if(newVal[j]._select === true){
					$scope.outletSelect.push(newVal[j].id);
					$scope.waresCount += newVal[j].waresCount;
				}
			}
		}

		$scope.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;

		// put the IDs of the selected establishments in the property of
		// the discount object to send to the server
		$scope.discount.outletsIds = $scope.outletSelect;
		$scope.discount.allowedForAllOutlets = $scope.allOutlets._select;
		$scope.outletSelect = [];
	}, true);

	for(let i = 0; i < $scope.outlets.length; i++){
		$scope.outlets[i]["_select"] = true;
	}

	const outletsInit = angular.copy($scope.outlets);

	$scope.changeAll = function(){
		if($scope.allOutlets._select === true){
			for(let k = 0; k < $scope.outlets.length; k++){
				if ( $stateParams.id || $stateParams.uuid ) {
					$scope.outlets[k]["enable"] = true;
				} else {
					$scope.outlets[k]["_select"] = true;
				}
			}
			$scope.allOutlets._select = true;
		}else{
			for(let m = 0; m < $scope.outlets.length; m++){
				if ( $stateParams.id || $stateParams.uuid ) {
					$scope.outlets[m]["enable"] = false;
				} else {
					$scope.outlets[m]["_select"] = false;
				}
			}
			$scope.allOutlets._select = false;
		}
	};
	//-- END Container for FACILITIES - SELECT


	//** Container for FACILITIES - SHOW THE WHOLE LIST
	$scope.showLine = true; // показывать линию в главном блоке
	$scope.showAllOutlets = false;   // показывать весь блок с заведениями
	$scope.singleDisabledOutlet = $scope.outlets.length === 1 && $scope.outlets[0].enable === false;

	$scope.showBlock = function (flag) {
		$scope.showAllOutlets = flag;
		$scope.showLine = !flag;
		$scope.singleDisabledOutlet = !flag;
	};
	//** END Container for FACILITIES - SHOW THE WHOLE LIST

	offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

	function isChanged() {

		//-- END added an if-else block for the normal operation of the CANCEL
		// button when the currency is without hundredths
		if($scope.numberOfSigns === 0){
			if(parseInt($scope.discount._value)*100 === discount.value){
				$scope.discount._value = discount._value;
			}
		}

		if (!angular.equals(outletsInit, $scope.outlets)) {
			return true;
		}

		return (!angular.equals(discount.name, $scope.discount.name))
			|| (!angular.equals(discount._value, $scope.discount._value))
			|| (!angular.equals(discount.calculationType, $scope.discount.calculationType))
			|| (!angular.equals(discount.limitedAccess, $scope.discount.limitedAccess));
	}

	$scope.$on('$destroy', function(){
		offStateChangeStart();
	});

	$scope.updateValue = function() {
		if ($scope.discount.calculationType === 'PERCENT') {
			if ($scope.amount.value && $scope.createDiscountForm.discountAmount.$touched) {
				let amountNumVal = parseFloat($scope.amount.value);
				if (amountNumVal <= $scope.percent.max &&
					amountNumVal >= $scope.percent.min) {
					$scope.percent.value = amountNumVal.toString();
				}
				else {
					$scope.percent.value = '';
				}
			}
			$scope.setValue($scope.percent.value);
		}
		else {
			if ($scope.percent.value && $scope.createDiscountForm.discountPercent.$touched) {
				let percentNumVal = parseFloat($scope.percent.value);
				if (percentNumVal <= $scope.amount.max &&
					percentNumVal >= $scope.amount.min) {
					$scope.amount.value = percentNumVal.toString();
				}
				else {
					$scope.amount.value = '';
				}
			}
			$scope.setValue($scope.amount.value);
		}
	}

	$scope.setValue = function(val) {
		$scope.discount._value = val;
	}

	$scope.onDeleteDiscount = function () {
		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate( 'DISCOUNT_LIST.DELETE_DISCOUNT' ),
			content: translate( 'DISCOUNT_LIST.DELETE_DISCOUNT_TEXT' ),
			buttons: [
				{
					text: 'ON_CANCEL'
				},
				{
					class: 'primary l-margin-left-8-i',
					text: 'ON_DEL',
					action: function () {
						factoryDiscount
							.delDiscount( [$scope.discount.id] )
							.then( function ( d ) {
								if ( d.result === 'ok' ) {
									discount =angular.copy( $scope.discount);
									serviceToast.show( 'DISCOUNT_LIST.DELETE_DISCOUNT_SUCCESS', 'success' );
									offStateChangeStart();
									backToList();
								} else if (d.result === 'error' && d.message === 'discount_deleted') {
									offStateChangeStart();
									dialogs.itemDeletedEarlier().then(backToList);
								} else {
									serviceToast.show( 'SAVE_ERROR', 'error' );
								}
							} );
					}
				}
			]
		} );
	};

	function backToList() {
		if (lodash.isEmpty($scope.stateFromList)) {
			return $state.go( 'goods.discountlist', {
				page: 			0,
				limit: 			10,
				checkFromList:	$scope.checkFromList} );
		} else {
			return $state.go( 'goods.discountlist', {
				page: 			$scope.stateFromList.page,
				limit: 			$scope.stateFromList.limit,
				outletsIds: 	$scope.stateFromList.outletsIds,
				checkFromList:	$scope.checkFromList} );
		}
	}

	$scope.onCancelDiscount = function () {
		backToList();
	};

	$scope.createEditDiscountForMixpanel = function (discount, type) {
		lvMixpanel.track('Discount created/edited', {
			"Type": (discount.calculationType === "PERCENT")? "Percentage" : "Amount",
			"Restricted access": (discount.limitedAccess === true)? "Yes" : "No",
			"Event type": type});
	};

	$scope.progress = false;
	$scope.onSaveDiscount = function () {

		if ($scope.createDiscountForm.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.createDiscountForm.focusFirstInvalidControl();
			return false;
		}

		$scope.progress = true;
		if ( $stateParams.id || $stateParams.uuid ) {
			$timeout( function () {
				factoryDiscount
					.editDiscount( {
						"discountId": 			$scope.discount.id,
						"name": 				$scope.discount.name,
						"_value": 				$scope.discount._value,
						"type": 				$scope.discount._value === "" ? "OPENED" : "FIXED", //FIXED
						"calculationType": 		$scope.discount.calculationType, //AMOUNT
						"limitedAccess": 		$scope.discount.limitedAccess,
						"outletsIds": 			$scope.discount.outletsIds,
						"allowedForAllOutlets": $scope.allOutlets._select
					} )
					.then( function ( d ) {
						if ( d.result === 'ok' ) {
							$scope.createEditDiscountForMixpanel($scope.discount, "Discount edited");
							discount =angular.copy( $scope.discount);
							serviceToast.show( 'DISCOUNT_EDITED', 'success' );
							offStateChangeStart();
							backToList().finally(() => { $scope.progress = false; });
						} else if ( d.result === 'error') {
							if(d.message === 'discount_exists') {
								//todo скидка с таким именем и значением существует
								serviceDialog.add({
									templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
									title      : translate('DISCOUNT.ERR_EDIT_DISCOUNT'),
									content    : translate('DISCOUNT.ERR_DISCOUNT_EXIST_TEXT'),
									buttons    : [
										{
											class : 'primary',
											text  : 'ON_OK'
										}
									]
								});
								$scope.progress = false;
							} else if (d.message === 'discount_deleted') {
								offStateChangeStart();
								dialogs.itemDeletedEarlier().then(backToList);
							} else {
								serviceToast.show( 'SAVE_ERROR', 'error' );
								$scope.progress = false;
							}
						} else {
							serviceToast.show( 'SAVE_ERROR', 'error' );
							$scope.progress = false;
						}
					} )
					.catch(() => {
						$scope.progress = false;
					});
			}, 0);
		} else {
			$timeout( function () {
				factoryDiscount
					.createDiscount( {
						"name": 				$scope.discount.name,
						"_value": 				$scope.discount._value,
						"type": 				$scope.discount._value === "" ? "OPENED" : "FIXED", //FIXED
						"calculationType": 		$scope.discount.calculationType, //AMOUNT
						"limitedAccess": 		$scope.discount.limitedAccess,
						"outletsIds": 			$scope.discount.outletsIds,
						"allowedForAllOutlets": $scope.allOutlets._select
					} )
					.then( function ( d ) {
						if ( d.result === 'ok' ) {
							$scope.createEditDiscountForMixpanel($scope.discount, "Discount created");
							discount = angular.copy($scope.discount);
							serviceToast.show( 'DISCOUNT_CREATED', 'success' );
							offStateChangeStart();
							backToList().finally(() => { $scope.progress = false; });;
						} else if ( d.result === 'error' && d.message === 'discount_exists' ) {
							//todo скидка с таким именем и значением существует
							serviceDialog.add({
								templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
								title      : translate('DISCOUNT.ERR_CREATE_DISCOUNT'),
								content    : translate('DISCOUNT.ERR_DISCOUNT_EXIST_TEXT'),
								buttons    : [
									{
										class : 'primary',
										text  : 'ON_OK'
									}
								]
							});
							$scope.progress = false;
						} else {
							serviceToast.show( 'SAVE_ERROR', 'error' );
							$scope.progress = false;
						}
					} )
					.catch(function (){
						$scope.progress = false;
					});
			}, 0);
		}
	};
}

export default {
	type: 'controller',
	name: 'discountCreateEditControl',
	value: discountCreateEditControl
};
