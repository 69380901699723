/**
 * Created by sirosh on 06.07.16.
 *
 * @memberof lvExport
 * @ngdoc directive
 * @name app.directive:lvExport
 *
 * @scope
 * @param lvMerchant {Object} - options for href
 * @restrict A
 *
 * @description
 * Export data to csv-file.
 *
 * <div lv-export="ctrl.export"></div>
 */

import template from '../templates-pug/for-directive/lv-export.pug';
import angular from "angular";

lvExport.$inject = ['lvMixpanel'];
function lvExport(lvMixpanel) {
    return {
        restrict: 'EA',
        scope: {
            hrefBase: '=',
            buttonText: '@',
            buttonDisabled: '=',
            buttonsList: '=',
            unlimitedSalesHistory: '<?'
        },

        controller: lvExportCtrl,
        controllerAs: 'lvExportCtrl',
        bindToController: true,
        template: template
    };
}

lvExportCtrl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    'setChartPeriod',
    'filtersData',
    'lvMixpanel',
    'serviceDialog',
    'serviceBillingSalesHistory',
    '$translate',
    'serviceBillingMixpanel'
];
function lvExportCtrl(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    setChartPeriod,
    filtersData,
    lvMixpanel,
    serviceDialog,
    serviceBillingSalesHistory,
    $translate,
    serviceBillingMixpanel
) {

    let self = this;

    self.$onInit = function () {
        self.useButtonsList = self.buttonsList && Array.isArray(self.buttonsList);
    }

    self.href = function(hrefBase) {

        if (self.unlimitedSalesHistory && !self.unlimitedSalesHistory.enabled) {
            showSalesHistoryDialog(self.unlimitedSalesHistory);
            return;
        }

        let hrefLink = !hrefBase ? self.hrefBase : hrefBase;

        let formatTime = function (unixTimestamp) {
            let dt = new Date(unixTimestamp);

            let year = dt.getFullYear();
            let month = dt.getMonth() + 1;
            let date = dt.getDate();

            if (month < 10)
                month = '0' + month;
            if (date < 10)
                date = '0' + date;

            return year + "-" + month + "-" + date;
        };

        let calcTimezoneOffset = function () {
            let dt = new Date();
            let offset = dt.getTimezoneOffset();

            return (offset * 60000) * -1;
        };

        let stateParams = filtersData.getStateParams();
        let periodLength = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
        if(periodLength === "0" || periodLength === "-1") parseFloat(periodLength);
        let periodName = $stateParams.periodName || null;
        let predefinedPeridDates = setChartPeriod.getDates(periodName, periodLength);

        let startDate = !periodName ? formatTime($stateParams.from) : formatTime(predefinedPeridDates.from);
        let endDate = !periodName ? formatTime($stateParams.to) : predefinedPeridDates.to ? formatTime(predefinedPeridDates.to) : periodName === "day" ? formatTime(predefinedPeridDates.from)
            : periodName === "month" && periodLength === 0 ? formatTime(new Date()) : formatTime(new Date(new Date(predefinedPeridDates.from).getFullYear(), new Date(predefinedPeridDates.from).getMonth() + 1, new Date(predefinedPeridDates.from).getDate() - 1));
        let startTime = ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour;
        let endTime = ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour;
        let name = (endDate) ? startDate + "-" + endDate : startDate + "-" + startDate;
        let timezoneOffset = calcTimezoneOffset();
        let merchantsIds = ($stateParams.merchantsIds && $stateParams.merchantsIds !== "clear" && $stateParams.merchantsIds !== "all") ? '[' + $stateParams.merchantsIds + ']' : ($stateParams.merchantsIds === "clear") ? '[]' : ($stateParams.merchantsIds === "all") ? "all" : null;
        let outletsIds = ($stateParams.outletsIds && $stateParams.outletsIds !== "clear" && $stateParams.outletsIds !== "all") ? '[' + $stateParams.outletsIds + ']' : ($stateParams.outletsIds === "clear") ? '[]' : ($stateParams.outletsIds === "all") ? "all" : null;
        let categoryIds = ($stateParams.categoryIds && $stateParams.categoryIds !== "clear" && $stateParams.categoryIds !== "all") ? '[' + $stateParams.categoryIds + ']' : ($stateParams.categoryIds === "clear") ? '[]' : ($stateParams.categoryIds === "all") ? "all" : null;
        let tz = jstz.determine();
        let browserLang = window.navigator.languages && window.navigator.languages.length ? window.navigator.languages[0] : window.navigator.userLanguage ? window.navigator.userLanguage : window.navigator.language;
        if (browserLang.split("-").length === 2) {
            browserLang = browserLang.split("-")[1];
        }

        let receiptType = $state.params.type ? $state.params.type : null;
        let loyalty = $state.params.loyalty ? $state.params.loyalty : null;
        let date = $stateParams.date ? $stateParams.date : null;

        if ($state.current.name === 'report.categories') {
            location.href = hrefLink + '?merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&periodName=' + periodName + '&periodLength=' + periodLength + '&startWeek=' + $rootScope.startWeek + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name() + '&sortBy=name';
        }
        else if (($state.current.name === 'report.payTypes') || ($state.current.name === 'report.modifiers')) {
            location.href = hrefLink + '?merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&periodName=' + periodName + '&periodLength=' + periodLength + '&startWeek=' + $rootScope.startWeek + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name();
        }
        else if ($state.current.name === 'report.receipts') {
            location.href = hrefLink + '?startWeek=' + $rootScope.startWeek + '&periodName=' + periodName + '&periodLength=' + periodLength + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&type=' + receiptType + '&loyalty=' + loyalty + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name() + '&browserLocal=' + browserLang;
        }
        else if ($state.current.name === 'report.employee') {
            location.href = hrefLink + '?startWeek=' + $rootScope.startWeek + '&periodName=' + periodName + '&periodLength=' + periodLength + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name();
        }
        else if ($state.current.name === 'employees.timecards' || $state.current.name === 'employees.timeclockreport') {
            location.href = hrefLink + '?startWeek=' + $rootScope.startWeek + '&periodName=' + periodName + '&periodLength=' + periodLength + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name() + '&browserLocal=' + browserLang;
        }
        else if ($state.current.name === 'inventory.valuation') {
            location.href = hrefLink + '?tzOffset=' + timezoneOffset + '&outletId=' + (outletsIds) + '&categoryId=' + (categoryIds) + '&date='+ date;
        }
        else if($state.current.name === 'report.sales'){
            location.href = hrefLink + '?merchantsIds=' + (merchantsIds) + '&periodName=' + periodName + '&outletsIds=' + (outletsIds) + '&periodLength=' + periodLength + '&divider=' + $stateParams.group + '&startWeek=' + $rootScope.startWeek + '&fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name() + '&browserLocal=' + browserLang;
        }
        else if($state.current.name === 'inventory.history') {
            let reasons = $stateParams.reasons;
            location.href = hrefLink + '?fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&periodName=' + periodName + '&periodLength=' + periodLength + '&tzName=' + tz.name() + '&startWeek=' + $rootScope.startWeek + '&merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&tzOffset=' + timezoneOffset + '&browserLocal=' + browserLang + '&dateStr=' + name + '&reasons=' + reasons;
        }
        else {
            location.href = hrefLink + '?fromDate=' + $stateParams.from + '&toDate=' + $stateParams.to + '&startTime=' + startTime + '&endTime=' + endTime + '&periodName=' + periodName + '&periodLength=' + periodLength + '&startWeek=' + $rootScope.startWeek + '&merchantsIds=' + (merchantsIds) + '&outletsIds=' + (outletsIds) + '&dateStr=' + name + '&tzOffset=' + timezoneOffset + '&tzName=' + tz.name() + '&browserLocal=' + browserLang;
        }

        if ($state.current.name === "inventory.valuation")  {
            lvMixpanel.track('Export', {"Property": "Inventory management/Export inventory valuation"});
        }
        if ($state.current.name === "employees.timecards") {
            lvMixpanel.track('Export', {"Property": "Employees/Export timecards"});
        }
        if ($state.current.name === "employees.timeclockreport") {
            lvMixpanel.track('Export', {"Property": "Employees/Export total hours worked"});
        }
        if ($state.current.name === "inventory.history") {
            lvMixpanel.track('Export', {"Property": "Inventory management/Export inventory history"});
        }

        if ($state.current.name === "report.modifiers")     { lvMixpanel.track('Export',    {"Property": "Reports/Export sales by modifier"}); }
        if ($state.current.name === "report.discounts")     { lvMixpanel.track('Export',    {"Property": "Reports/Export discounts"}); }
        if ($state.current.name === "report.tax")           { lvMixpanel.track('Export',    {"Property": "Reports/Export taxes"}); }
        if ($state.current.name === "report.goods")         { lvMixpanel.track('Export',    {"Property": "Reports/Export sales by item"}); }
        if ($state.current.name === "report.categories")    { lvMixpanel.track('Export',    {"Property": "Reports/Export sales by category"}); }
        if ($state.current.name === "report.employee")      { lvMixpanel.track('Export',    {"Property": "Reports/Export sales by employee"}); }
        if ($state.current.name === "report.payTypes")      { lvMixpanel.track('Export',    {"Property": "Reports/Export sales by payment type"}); }

        if ($state.current.name === "report.receipts") {
            if (hrefLink === '/data/ownercab/receiptsreportdownload') {
                lvMixpanel.track('Export', {"Property": "Reports/Export receipts"});
            } else if (hrefLink === '/data/ownercab/downloadReceiptsDetail') {
                lvMixpanel.track('Export', {"Property": "Reports/Export receipts by item"});
            }
        }

        if ($state.current.name === "report.shift") {
            if (hrefLink === '/data/ownercab/ShiftReportDownload') {
                lvMixpanel.track('Export', {"Property": "Reports/Export shifts summary"});
            } else if (hrefLink === '/data/ownercab/shiftExportPayInPayOut') {
                lvMixpanel.track('Export', {"Property": "Reports/Export pay ins and payouts"});
            }
        }

        return location.href;
    }

    /**
     * Shows dialog with billing advertisement when user has no Unlimited Sales History subscription
     * @param unlimitedSalesHistory - object { hasBillingAccess: true, enabled: false, billingData: {} }
     */
    function showSalesHistoryDialog( unlimitedSalesHistory ) {
        let hasBillingAccess = unlimitedSalesHistory.hasBillingAccess;
        let billingData = angular.copy(unlimitedSalesHistory.billingData);
        let errorChargebee = !billingData || billingData.message === "temporary_unavailable";

        const emptyPlan= {id:null, cycle:null, unlim:null, price:null};

        (!errorChargebee
            && billingData.subscriptions.SALESHISTORY
            && billingData.subscriptions.SALESHISTORY.plan === null)
            ? billingData.subscriptions.SALESHISTORY.plan = angular.copy(emptyPlan) : "";

        serviceBillingSalesHistory.setBillingData(billingData);

        let beforeTrial = billingData.subscriptions.SALESHISTORY.status === "NONE";

        let dialogTitle = $translate.instant('UNLIMITED_SALES.EXPORT_DIALOG_TITLE');

        let dialogText;
        if (hasBillingAccess && beforeTrial) {
            dialogText = $translate.instant('UNLIMITED_SALES.EXPORT_DIALOG_TEXT_BEFORE_TRIAL');
        } else if (hasBillingAccess && !beforeTrial) {
            dialogText = $translate.instant('UNLIMITED_SALES.EXPORT_DIALOG_TEXT_AFTER_TRIAL');
        } else {
            dialogText = $translate.instant('UNLIMITED_SALES.EXPORT_DIALOG_TEXT_NO_BILLING_ACCESS');
        }

        let dialogButtons = [];
        if (!hasBillingAccess) {
            dialogButtons.push({
                class: 'primary',
                text: 'ON_OK'
            })
        } else {
            dialogButtons.push({
                text: 'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp('cancel', null, 'SALESHISTORY',
                        'NONE', null, null, null, null, "Export dialog");
                }
            });
            if (beforeTrial) {
                dialogButtons.push({
                    class: 'primary',
                    text: 'TRY_FOR_FREE',
                    action: function () {
                        serviceBillingSalesHistory.subscribeSalesHistory("Export dialog");
                    }
                })
            } else {
                dialogButtons.push({
                    class: 'primary',
                    text: 'SUBSCRIBE',
                    action: function () {
                        serviceBillingSalesHistory.checkIfPlanAllowed('Export dialog');
                    }
                })
            }
        }

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: dialogTitle,
            content: dialogText,
            buttons: dialogButtons
        })
    }

}

export default {
    type: 'directive',
    name: 'lvExport',
    value: lvExport
};
