/**
 * Created by  Alex on 16.05.18.
 */

serviceBillingMixpanel.$inject = ['lvMixpanel'];
function serviceBillingMixpanel(lvMixpanel) {

    //-- MIXPANEL event when calling invoice view in BILLING state
    this.setMixpanelBillingInvoiceInBillinMain = function() {
        lvMixpanel.track('Billing', {"View invoice in PDF": "View invoice in PDF"});
    };
    //-- END MIXPANEL event when calling invoice view in BILLING state


    //-- MIXPANEL events on CREATION or ACTIVATION or SUBSCRIPTION WITH ACTIVATION
    // or CANCEL of the previous paragraphs and all errors in the BILLING state
    this.setMixpanelBillingCreateActivateInComp = function(res, plan, type, status, activate, payType, bp, tempPlan, source) {
        if (res === "cancel" && type === "EMPLOYEE") {
            if (payType === null) {
                if (type === 'EMPSTORE') {
                    track("Activate employee management per store (no card)", "Cancel (Select pricing plan)");
                } else {
                    track("Activate employee management (no card)", "Cancel (Select pricing plan)");
                }
            }
            if (payType != null) {
                if (type === 'EMPSTORE') {
                    lvMixpanel.track('Billing', {"Activate employee management per store (with card)": "Cancel"});
                } else {
                    lvMixpanel.track('Billing', {"Activate employee management (with card)": "Cancel"});
                }
            }
        }
        if (res === "cancel" && (type === "INVENTORY" || type === "INTEGRATION" || type === "SALESHISTORY")) {
            if (status === "NONE") {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {
                        "Subscribe to advanced inventory (trial started)": "Cancel"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {
                        "Subscribe to integrations (trial started)": "Cancel"});
                }
                if (type === "SALESHISTORY") {
                    track("Subscribe to unlimited sales history (trial started)", "Cancel", source);
                }
            }
            if (status === "INTRIAL_NOSUB_NOACT") {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {
                        "Continue using advanced inventory (in trial)": "Cancel"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {
                        "Continue using integrations (in trial)": "Cancel"});
                }
                if ( type === "SALESHISTORY" ) {
                    lvMixpanel.track('Billing', {
                        "Continue using unlimited sales history (in trial)": "Cancel"});
                }
            }
            if (payType === null && (status === "INTRIAL_SUB_NOACT" || status === "TRIALEND_SUB_NOACT")) {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {"Activate advanced inventory (no card)": "Cancel (Select pricing plan)"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {"Activate integrations (no card)": "Cancel (Select pricing plan)"});
                }
                if (type === "SALESHISTORY") {
                    lvMixpanel.track('Billing', {
                        "Activate unlimited sales history (no card)": "Cancel (Select pricing plan)"});
                }
            }
            if (payType === null && status === "TRIALEND_NOSUB_NOACT") {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {
                        "Subscribe to advanced inventory (trial ended | no card)": "Cancel (Select pricing plan)"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {
                        "Subscribe to integrations (trial ended | no card)": "Cancel (Select pricing plan)"});
                }
                if ( type === "SALESHISTORY" ) {
                    track(
                        "Subscribe to unlimited sales history (trial ended | no card)",
                        "Cancel (Select pricing plan)", source
                    );
                }
            }
            if (payType != null && (status === "INTRIAL_SUB_NOACT" || status === "TRIALEND_SUB_NOACT")) {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {
                        "Activate advanced inventory (with card)": "Cancel"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {
                        "Activate integrations (with card)": "Cancel"});
                }
                if (type === "SALESHISTORY") {
                    lvMixpanel.track('Billing', {
                        "Activate unlimited sales history (with card)": "Cancel"});
                }
            }
            if (payType != null && status === "TRIALEND_NOSUB_NOACT") {
                if (type === "INVENTORY") {
                    lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial ended | with card)": "Cancel"});
                }
                if (type === "INTEGRATION") {
                    lvMixpanel.track('Billing', {"Subscribe to integrations (trial ended | with card)": "Cancel"});
                }
                if ( type === "SALESHISTORY" ) {
                    track(
                        "Subscribe to unlimited sales history (trial ended | with card)",
                        "Cancel", source);
                }
            }
        }
        if (res === "ok" && !activate) {
            if (type === "INVENTORY" && status === "NONE") {
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial started)": "Subscribe"});
            }
            if (type === "INVENTORY" && status === "INTRIAL_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Continue using advanced inventory (in trial)": "Subscribe"});
            }
            if (type === "INTEGRATION" && status === "NONE") {
                lvMixpanel.track('Billing', {"Subscribe to integrations (trial started)": "Subscribe"});
            }
            if (type === "INTEGRATION" && status === "INTRIAL_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Continue using integrations (in trial)": "Subscribe"});
            }
            if ( type === "SALESHISTORY" && status === "NONE" ) {
                track(
                    "Subscribe to unlimited sales history (trial started)",
                    "Subscribe", source
                );
            }
            if ( type === "SALESHISTORY" && status === "INTRIAL_NOSUB_NOACT" ) {
                lvMixpanel.track('Billing', {
                    "Continue using unlimited sales history (in trial)": "Subscribe"
                });
            }
        }
        if (res === "ok" && activate) {
            if (type === "EMPLOYEE"  && plan.cycle === 1) {
                lvMixpanel.track('Billing', {"Activate employee management (with card)": "Activate (monthly)"});
            }
            if (type === "EMPLOYEE"  && plan.cycle === 12) {
                lvMixpanel.track('Billing', {"Activate employee management (with card)": "Activate (annualy)"});
            }
            if (type === "EMPSTORE"  && plan.cycle === 1) {
                lvMixpanel.track('Billing', {"Activate employee management per store (with card)": "Activate (monthly)"});
            }
            if (type === "EMPSTORE"  && plan.cycle === 12) {
                lvMixpanel.track('Billing', {"Activate employee management per store (with card)": "Activate (annualy)"});
            }
            if (type === "INVENTORY" && plan.cycle === 1  && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate advanced inventory (with card)": "Activate (monthly)"});
            }
            if (type === "INVENTORY" && plan.cycle === 12 && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate advanced inventory (with card)": "Activate (annualy)"});
            }
            if (type === "INVENTORY" && plan.cycle === 1  &&  status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial ended | with card)": "Subscribe (monthly)"});
            }
            if (type === "INVENTORY" && plan.cycle === 12 &&  status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial ended | with card)": "Subscribe (annualy)"});
            }
            if (type === "INTEGRATION" && plan.cycle === 1  && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate integrations (with card)": "Activate (monthly)"});
            }
            if (type === "INTEGRATION" && plan.cycle === 12 && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate integrations (with card)": "Activate (annualy)"});
            }
            if ( type === "SALESHISTORY" && plan.cycle === 1
                && ( status === "INTRIAL_SUB_NOACT"
                    || status === "TRIALEND_SUB_NOACT" )) {
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (with card)": "Activate (monthly)"
                });
            }
            if ( type === "SALESHISTORY" && plan.cycle === 12
                && ( status === "INTRIAL_SUB_NOACT"
                    || status === "TRIALEND_SUB_NOACT" )) {
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (with card)": "Activate (annualy)"
                });
            }
            if (type === "INTEGRATION" && plan.cycle === 1  &&  status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to integrations (trial ended | with card)": "Subscribe (monthly)"});
            }
            if (type === "INTEGRATION" && plan.cycle === 12 &&  status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to integrations (trial ended | with card)": "Subscribe (annualy)"});
            }
            if ( type === "SALESHISTORY" && plan.cycle === 1
                && status === "TRIALEND_NOSUB_NOACT" ) {
                track(
                    "Subscribe to unlimited sales history (trial ended | with card)",
                    "Subscribe (monthly)", source);
            }
            if ( type === "SALESHISTORY" && plan.cycle === 12
                && status === "TRIALEND_NOSUB_NOACT" ) {
                track(
                    "Subscribe to unlimited sales history (trial ended | with card)",
                    "Subscribe (annualy)", source);
            }
        }
        if ((res === "error" || res === undefined) && !activate) {
            if (type === "INVENTORY" && status === "NONE") {
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial started)": "Fail"});
            }
            if (type === "INVENTORY" && status === "INTRIAL_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Continue using advanced inventory (in trial)": "Fail"});
            }
            if (type === "INTEGRATION" && status === "NONE") {
                lvMixpanel.track('Billing', {"Subscribe to integrations (trial started)": "Fail"});
            }
            if (type === "INTEGRATION" && status === "INTRIAL_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Continue using integrations (in trial)": "Fail"});
            }
            if ( type === "SALESHISTORY" && status === "NONE" ) {
                track(
                    "Subscribe to unlimited sales history (trial started)",
                    "Fail", source
                );
            }
            if (type === "SALESHISTORY" && status === "INTRIAL_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Continue using unlimited sales history (in trial)": "Fail"});
            }
        }
        if ((res === "error" || res === undefined) && activate) {
            if (type === "EMPLOYEE") {
                lvMixpanel.track('Billing', {"Activate employee management (with card)": "Fail"});
            }
            if (type === "EMPSTORE") {
                lvMixpanel.track('Billing', {"Activate employee management per store (with card)": "Fail"});
            }
            if (type === "INVENTORY" && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate advanced inventory (with card)": "Fail"});
            }
            if (type === "INVENTORY" && status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial ended | with card)": "Fail"});
            }
            if (type === "INTEGRATION" && (status==="INTRIAL_SUB_NOACT" || status==="TRIALEND_SUB_NOACT")) {
                lvMixpanel.track('Billing', {"Activate integrations (with card)": "Fail"});
            }
            if (type === "INTEGRATION" && status==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to integrations (trial ended | with card)": "Fail"});
            }
            if ( type === "SALESHISTORY" && ( status === "INTRIAL_SUB_NOACT"
                || status === "TRIALEND_SUB_NOACT" )) {
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (with card)": "Fail"});
            }
            if ( type === "SALESHISTORY" && status === "TRIALEND_NOSUB_NOACT" ) {
                track(
                    "Subscribe to unlimited sales history (trial ended | with card)",
                    "Fail", source
                );
            }
        }
    };
    //-- END MIXPANEL events on CREATION or ACTIVATION or SUBSCRIPTION WITH ACTIVATION
    // or CANCEL of the previous paragraphs and all errors in the BILLING state


    //-- MIXPANEL events when UNSUBSCRIBING from any SUBSCRIPTION or CANCELING
    // and all errors in the BILLING state
    this.setMixpanelBillingDelSubscriptionInComp = function(res, type) {
        if (res === "cancel") {
            if (type === "EMPLOYEE")  lvMixpanel.track('Billing', {"Unsubscribe from employee management (billing menu)": "Cancel"});
            if (type === "EMPSTORE")  lvMixpanel.track('Billing', {"Unsubscribe from employee management per store (billing menu)": "Cancel"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {"Unsubscribe from advanced inventory": "Cancel"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Unsubscribe from integration": "Cancel"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Unsubscribe from unlimited sales history": "Cancel"});
        }
        if (res === "ok") {
            if (type === "EMPLOYEE")  lvMixpanel.track('Billing', {
                "Unsubscribe from employee management (billing menu)": "Unsubscribe"});
            if (type === "EMPSTORE")  lvMixpanel.track('Billing', {
                "Unsubscribe from employee management per store (billing menu)": "Unsubscribe"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Unsubscribe from advanced inventory": "Unsubscribe"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Unsubscribe from integration": "Unsubscribe"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Unsubscribe from unlimited sales history": "Unsubscribe"});
        }
        if ( res === "error" || res === undefined) {
            if (type === "EMPLOYEE")  lvMixpanel.track('Billing', {
                "Unsubscribe from employee management (billing menu)": "Fail"});
            if (type === "EMPSTORE")  lvMixpanel.track('Billing', {
                "Unsubscribe from employee management per store (billing menu)": "Fail"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Unsubscribe from advanced inventory": "Fail"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Unsubscribe from integration": "Fail"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Unsubscribe from unlimited sales history": "Fail"});
        }
    };
    //-- END MIXPANEL events when UNSUBSCRIBING from any SUBSCRIPTION or CANCELING
    // and all errors in the BILLING state


    //-- MIXPANEL events when EMPLOYEES are UNPUNCHED when the latter is
    // deleted in the CREATE EMPLOYEE or EMPLOYEE LIST state
    this.setMixpanelBillingDelSubscriptionInEmployee = function(res, type) {
        if (res === "ok") {
            if (type === 'EMPSTORE') {
                lvMixpanel.track('Billing', {"Unsubscribe from employee management per store (delete last employee)": "Unsubscribe"});
            } else {
                lvMixpanel.track('Billing', {"Unsubscribe from employee management (delete last employee)": "Unsubscribe"});
            }
        }
        if (res === "error" || res === undefined) {
            if (type === 'EMPSTORE') {
                lvMixpanel.track('Billing', {"Unsubscribe from employee management per store (delete last employee)": "Fail"});
            } else {
                lvMixpanel.track('Billing', {"Unsubscribe from employee management (delete last employee)": "Fail"});
            }
        }
    };
    //-- END MIXPANEL events when EMPLOYEES are UNPUNCHED when the latter is
    // deleted in the CREATE EMPLOYEE or EMPLOYEE LIST state


    //-- MIXPANEL events when changing the tariff plan or CANCEL and
    // related errors in the BILLING state
    this.setMixpanelBillingChangePlanInComp = function(res, planCycle, type) {
        if (res === "cancel") {
            if (type === "EMPLOYEE") lvMixpanel.track('Billing', {
                "Change plan (employee management)": "Cancel"});
            if (type === "EMPSTORE") lvMixpanel.track('Billing', {
                "Change plan (employee management per store)": "Cancel"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Change plan (advanced inventory)": "Cancel"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Change plan (integration)": "Cancel"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Change plan (unlimited sales history)": "Cancel"});
        }
        if (res === "ok") {
            if (planCycle === 1) {
                switch (type) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Change plan (employee management)": "Save (monthly)"});
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {"Change plan (employee management per store)": "Save (monthly)"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Change plan (advanced inventory)": "Save (monthly)"});
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Change plan (integration)": "Save (monthly)"});
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Change plan (unlimited sales history)": "Save (monthly)"});
                }
            }

            if (planCycle === 12) {
                switch (type) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Change plan (employee management)": "Save (annualy)"
                        });
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {"Change plan (employee management per store)": "Save (annualy)"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Change plan (advanced inventory)": "Save (annualy)"
                        });
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Change plan (integration)": "Save (annualy)"
                        });
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Change plan (unlimited sales history)": "Save (annualy)"
                        });
                }
            }
        }
        if (res === "error" || res === undefined) {
            if (type === "EMPLOYEE") lvMixpanel.track('Billing', {
                "Change plan (employee management)": "Fail"});
            if (type === "EMPSTORE") lvMixpanel.track('Billing', {
                "Change plan (employee management per store)": "Fail"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Change plan (advanced inventory)": "Fail"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Change plan (integration)": "Fail"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Change plan (unlimited sales history)": "Fail"});
        }
    };
    //-- END MIXPANEL events when changing the tariff plan or CANCEL and
    // related errors in the BILLING state


    //-- MIXPANEL events when starting a re-payment for any SUBSCRIPTION and
    // all errors in the BILLING state
    this.setMixpanelBillingRetryPayInComp = function(res, type) {
        if (res === "ok") {
            if (type === "EMPLOYEE")  lvMixpanel.track('Billing', {
                "Retry payment (employee management)": "Success"});
            if (type === "EMPSTORE")  lvMixpanel.track('Billing', {
                "Retry payment (employee management per store)": "Success"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Retry payment (advanced inventory)": "Success"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Retry payment (integration)": "Success"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Retry payment (unlimited sales history)": "Success"});
        }
        if ( res === "error" || res === undefined) {
            if (type === "EMPLOYEE")  lvMixpanel.track('Billing', {
                "Retry payment (employee management)": "Fail"});
            if (type === "EMPSTORE")  lvMixpanel.track('Billing', {
                "Retry payment (employee management per store)": "Fail"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Retry payment (advanced inventory)": "Fail"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Retry payment (integration)": "Fail"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Retry payment (unlimited sales history)": "Fail"});
        }
    };
    //-- END MIXPANEL events when starting a re-payment for any SUBSCRIPTION and
    // all errors in the BILLING state


    //-- MIXPANEL events upon ACTIVATION of any trial SUBSCRIPTION or CANCEL
    // and all errors anywhere in the project from the info line
    this.setMixpanelBillingActivateInAlert = function( res, plan, type, paymentType ) {
        console.log('test IM ', res, plan, type, paymentType )
        if ( res === "cancel" ) {
            if ( paymentType === null ) {
                switch ( type ) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management (no card)": "Cancel (Select pricing plan)"});
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {"Activate employee management per store (no card)": "Cancel (Select pricing plan)"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Activate advanced inventory (no card)": "Cancel (Select pricing plan)"});
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Activate integrations (no card)": "Cancel (Select pricing plan)"
                        });
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Activate unlimited sales history (no card)": "Cancel (Select pricing plan)"
                        });
                }
            } else {
                switch ( type ) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management (with card)": "Cancel"});
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management per store (with card)": "Cancel"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Activate advanced inventory (with card)": "Cancel"});
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Activate integrations (with card)": "Cancel"
                        });
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Activate unlimited sales history (with card)": "Cancel"
                        });
                }
            }
        }
        if ( res === "ok" ) {
            if ( plan.cycle === 1 ) {
                switch ( type ) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management (with card)": "Activate (monthly)"
                        });
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management per store (with card)": "Activate (monthly)"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Activate advanced inventory (with card)": "Activate (monthly)"
                        });
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Activate integrations (with card)": "Activate (monthly)"
                        });
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Activate unlimited sales history (with card)": "Activate (monthly)"
                        });
                }
            }
            if (plan.cycle === 12) {
                switch (type) {
                    case "EMPLOYEE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management (with card)": "Activate (annualy)"
                        });
                        break;
                    case "EMPSTORE":
                        lvMixpanel.track('Billing', {
                            "Activate employee management per store (with card)": "Activate (annualy)"});
                        break;
                    case "INVENTORY":
                        lvMixpanel.track('Billing', {
                            "Activate advanced inventory (with card)": "Activate (annualy)"
                        });
                        break;
                    case "INTEGRATION":
                        lvMixpanel.track('Billing', {
                            "Activate integrations (with card)": "Activate (annualy)"
                        });
                        break;
                    case "SALESHISTORY":
                        lvMixpanel.track('Billing', {
                            "Activate unlimited sales history (with card)": "Activate (annualy)"
                        });

                }
            }
        }
        if (res === "error" || res === undefined) {
            if (type === "EMPLOYEE") lvMixpanel.track('Billing', {
                "Activate employee management (with card)": "Fail"});
            if (type === "EMPSTORE") lvMixpanel.track('Billing', {
                "Activate employee management per store (with card)": "Fail"});
            if (type === "INVENTORY") lvMixpanel.track('Billing', {
                "Activate advanced inventory (with card)": "Fail"});
            if (type === "INTEGRATION") lvMixpanel.track('Billing', {
                "Activate integrations (with card)": "Fail"});
            if (type === "SALESHISTORY") lvMixpanel.track('Billing', {
                "Activate unlimited sales history (with card)": "Fail"});
        }
    };
    //-- END MIXPANEL events upon ACTIVATION of any trial SUBSCRIPTION or CANCEL
    // and all errors anywhere in the project from the info line


    //-- MIXPANEL events about PAYMENT when creating an EMPLOYEE or
    // ESTABLISHMENT (single rates) or CANCEL and all errors on the CREATE
    // EMPLOYEE OR CREATE ESTABLISHMENT pages
    this.setMixpanelBillingPaymentWhenCreate = function(res, plan, type, status) {
        if (status==="ACTIVE" || status==="PAST_DUE") {
            if (res === "cancel") {
                if (type === "EMPLOYEE")   lvMixpanel.track('Billing', {
                    "Payment for an employee": "Cancel"});
                if (type === "INVENTORY" || type === "INTEGRATION" || type === "SALESHISTORY" || type === "EMPSTORE")
                    lvMixpanel.track('Billing', {"Payment for a store": "Cancel" });
            }
            if (res === "ok") {
                if (type === "EMPLOYEE")   lvMixpanel.track('Billing', {
                    "Payment for an employee": "Pay"});
                if (type === "INVENTORY" || type === "INTEGRATION"|| type === "SALESHISTORY"  || type === "EMPSTORE")
                    lvMixpanel.track('Billing', {"Payment for a store": "Pay"});
            }
            if (res === "error" || res === undefined) {
                if (type === "EMPLOYEE")   lvMixpanel.track('Billing', {
                    "Payment for an employee": "Fail"});
                if (type === "INVENTORY" || type === "INTEGRATION" || type === "SALESHISTORY" || type === "EMPSTORE")
                    lvMixpanel.track('Billing', {"Payment for a store": "Fail"});
            }
        }
    };
    //-- END MIXPANEL events about PAYMENT when creating an EMPLOYEE or
    // ESTABLISHMENT (single rates) or CANCEL and all errors on the CREATE
    // EMPLOYEE OR CREATE ESTABLISHMENT pages

    // ивенты МИКСПАНЕЛИ при СОЗДАНИИ или ПОДПИСКИ С АКТИВАЦИЕЙ и все ошибки в стейте СОЗДАНИЯ СОТРУДНИКА
    this.setMixpanelBillingInCreateUser = function(res, plan, status, payType, subscriptionType) {
        const mainMsg = "Subscribe to employee management";
        const msgAddition = subscriptionType === 'EMPSTORE' ? 'per store' : null;
        if (res === "cancel") {
            if (status === "NONE") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial started)", "Cancel");
                } else {
                    track("Subscribe to employee management (trial started)", "Cancel");
                }
            }
            if (status === "INTRIAL_NOSUB_NOACT")  {
                if (subscriptionType === 'EMPSTORE') {
                    track("Continue using employee management per store (in trial)", "Cancel");
                } else {
                    track("Continue using employee management (in trial)", "Cancel");
                }
            }
            if (payType === null) {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial ended | no card)", "Cancel (Select pricing plan)");
                } else {
                    track("Subscribe to employee management (trial ended | no card)", "Cancel (Select pricing plan)");
                }
            }
            if (payType != null) {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial ended | with card)", "Cancel");
                } else {
                    track("Subscribe to employee management (trial ended | with card)", "Cancel");
                }
            }
        }
        if (res === "ok") {
            if (status === "NONE") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial started)", "Subscribe");
                } else {
                    track("Subscribe to employee management (trial started)", "Subscribe");
                }
            }
            if (status === "INTRIAL_NOSUB_NOACT") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Continue using employee management per store (in trial)", "Subscribe");
                } else {
                    track("Continue using employee management (in trial)", "Subscribe");
                }
            }
            if (status === "TRIALEND_NOSUB_NOACT" && plan.cycle === 1) {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial ended | with card)", "Subscribe (monthly)");
                } else {
                    track("Subscribe to employee management (trial ended | with card)", "Subscribe (monthly)");
                }
            }
            if (status === "TRIALEND_NOSUB_NOACT" && plan.cycle === 12) {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial ended | with card)", "Subscribe (annualy)");
                } else {
                    track("Subscribe to employee management (trial ended | with card)", "Subscribe (annualy)");
                }
            }
        }
        if ( res === "error" || res === undefined) {
            if (status === "NONE") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial started)", "Fail");
                } else {
                    track("Subscribe to employee management (trial started)", "Fail");
                }
            }
            if (status === "INTRIAL_NOSUB_NOACT") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Continue using employee management per store (in trial)", "Fail");
                } else {
                    track("Continue using employee management (in trial)", "Fail");
                }
            }
            if (status === "TRIALEND_NOSUB_NOACT") {
                if (subscriptionType === 'EMPSTORE') {
                    track("Subscribe to employee management per store (trial ended | with card)", "Fail");
                } else {
                    track("Subscribe to employee management (trial ended | with card)", "Fail");
                }
            }
        }
    };
    // ивенты МИКСПАНЕЛИ при СОЗДАНИИ или ПОДПИСКИ С АКТИВАЦИЕЙ и все ошибки в стейте СОЗДАНИЯ СОТРУДНИКА


    //-- MIXPANEL events upon ACTIVATION or SUBSCRIPTION WITH ACTIVATION of
    // the previous paragraphs and all errors in the state ADDING A PAYMENT TYPE
    this.setMixpanelBillingActivateInPaymentMethod = function( res, type, plan, empStat, invStat, integrStat, salesHistoryStat, source ) {
        if (res === "ok" && type === "EMPLOYEE" && empStat) {
            if (plan.cycle === 1  && (empStat==="INTRIAL_SUB_NOACT"
                || empStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {
                    "Activate employee management (no card)": "Activate (monthly)"});
            if (plan.cycle === 12 && (empStat==="INTRIAL_SUB_NOACT"
                || empStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {
                    "Activate employee management (no card)": "Activate (annualy)"});
            if (plan.cycle === 1  && empStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to employee management (trial ended | no card)": "Subscribe (monthly)"});
            if (plan.cycle === 12 && empStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to employee management (trial ended | no card)": "Subscribe (annualy)"});
        }
        if (res === "ok" && type === "EMPSTORE" && empStat) {
            if (plan.cycle === 1  && (empStat==="INTRIAL_SUB_NOACT" || empStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {"Activate employee management per store (no card)": "Activate (monthly)"});
            if (plan.cycle === 12 && (empStat==="INTRIAL_SUB_NOACT" || empStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {"Activate employee management per store (no card)": "Activate (annualy)"});
            if (plan.cycle === 1  && empStat==="TRIALEND_NOSUB_NOACT")                                  lvMixpanel.track('Billing', {"Subscribe to employee management per store (trial ended | no card)": "Subscribe (monthly)"});
            if (plan.cycle === 12 && empStat==="TRIALEND_NOSUB_NOACT")                                  lvMixpanel.track('Billing', {"Subscribe to employee management per store (trial ended | no card)": "Subscribe (annualy)"});
        }
        if (res === "ok" && type === "INVENTORY" && invStat) {
            if (plan.cycle === 1  && (invStat==="INTRIAL_SUB_NOACT"
                || invStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {"Activate advanced inventory (no card)": "Activate (monthly)"});
            if (plan.cycle === 12 && (invStat==="INTRIAL_SUB_NOACT"
                || invStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {"Activate advanced inventory (no card)": "Activate (annualy)"});
            if (plan.cycle === 1  && invStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to advanced inventory (trial ended | no card)": "Subscribe (monthly)"});
            if (plan.cycle === 12 && invStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to advanced inventory (trial ended | no card)": "Subscribe (annualy)"});
        }
        if (res === "ok" && type === "INTEGRATION" && integrStat) {
            if (plan.cycle === 1  && (integrStat==="INTRIAL_SUB_NOACT"
                || integrStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {"Activate integrations (no card)": "Activate (monthly)"});
            if (plan.cycle === 12 && (integrStat==="INTRIAL_SUB_NOACT"
                || integrStat==="TRIALEND_SUB_NOACT"))
                lvMixpanel.track('Billing', {"Activate integrations (no card)": "Activate (annualy)"});
            if (plan.cycle === 1  && integrStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to integrations (trial ended | no card)": "Subscribe (monthly)"});
            if (plan.cycle === 12 && integrStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to integrations (trial ended | no card)": "Subscribe (annualy)"});
        }
        if ( res === "ok" && type === "SALESHISTORY" && salesHistoryStat ) {
            if ( plan.cycle === 1  && ( salesHistoryStat === "INTRIAL_SUB_NOACT"
                || salesHistoryStat === "TRIALEND_SUB_NOACT" ))
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (no card)": "Activate (monthly)"});
            if ( plan.cycle === 12 && ( salesHistoryStat === "INTRIAL_SUB_NOACT"
                || salesHistoryStat === "TRIALEND_SUB_NOACT" ))
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (no card)": "Activate (annualy)"});
            if ( plan.cycle === 1  && salesHistoryStat === "TRIALEND_NOSUB_NOACT" )
                track(
                    "Subscribe to unlimited sales history (trial ended | no card)",
                    "Subscribe (monthly)", source);
            if ( plan.cycle === 12 && salesHistoryStat ==="TRIALEND_NOSUB_NOACT" )
                track(
                    "Subscribe to unlimited sales history (trial ended | no card)",
                    "Subscribe (annualy)", source);
        }
        if ( res === "error" || res === undefined) {
            if (type === "EMPLOYEE" && empStat  && (empStat==="INTRIAL_SUB_NOACT"
                || empStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {
                    "Activate employee management (no card)": "Fail"});
            if (type === "EMPSTORE" && empStat  && (empStat==="INTRIAL_SUB_NOACT" ||
                empStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {
                    "Activate employee management per store (no card)": "Fail"});
            if (type === "EMPLOYEE" && empStat  && empStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {"Subscribe to employee management (trial ended | no card)": "Fail"});
            if (type === "EMPSTORE" && empStat  && empStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {"Subscribe to employee management per store (trial ended | no card)": "Fail"});
            if (type === "INVENTORY" && invStat && (invStat==="INTRIAL_SUB_NOACT"
                || invStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {
                    "Activate advanced inventory (no card)": "Fail"});
            if (type === "INVENTORY" && invStat && invStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {"Subscribe to advanced inventory (trial ended | no card)": "Fail"});
            if (type === "INTEGRATION" && integrStat && (integrStat==="INTRIAL_SUB_NOACT"
                || integrStat==="TRIALEND_SUB_NOACT")) lvMixpanel.track('Billing', {
                    "Activate integrations (no card)": "Fail"});
            if (type === "INTEGRATION" && integrStat && integrStat==="TRIALEND_NOSUB_NOACT")
                lvMixpanel.track('Billing', {
                    "Subscribe to integrations (trial ended | no card)": "Fail"
                });
            if (type === "SALESHISTORY" && salesHistoryStat && salesHistoryStat ==="TRIALEND_NOSUB_NOACT")
                track("Subscribe to unlimited sales history (trial ended | no card)",
                    "Fail", source
                );
        }
    };
    //-- END  MIXPANEL events upon ACTIVATION or SUBSCRIPTION WITH ACTIVATION of
    // the previous paragraphs and all errors in the state ADDING A PAYMENT TYPE


    //-- MIXPANEL events when leaving (CANCEL) from the ADD PAYMENT TYPE page
    this.setMixpanelBillingOnCancelInPaymentMethod = function( type, empStat, invStat, integrStat, salesHistoryStat, source ) {
        if (type === "EMPLOYEE" && empStat) {
            if (empStat==="INTRIAL_SUB_NOACT" || empStat==="TRIALEND_SUB_NOACT") {
                lvMixpanel.track('Billing', {"Activate employee management (no card)": "Cancel (Add payment method)"});
            }
            if (empStat==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Subscribe to employee management (trial ended | no card)": "Cancel (Add payment method)"});
            }
        }
        if (type === "EMPSTORE" && empStat) {
            if (empStat==="INTRIAL_SUB_NOACT" || empStat==="TRIALEND_SUB_NOACT") {
                lvMixpanel.track('Billing', {"Activate employee management per store (no card)": "Cancel (Add payment method)"});
            }
            if (empStat==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {"Subscribe to employee management per store (trial ended | no card)": "Cancel (Add payment method)"});
            }
        }
        if (type === "INVENTORY" && invStat) {
            if (invStat==="INTRIAL_SUB_NOACT" || invStat==="TRIALEND_SUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Activate advanced inventory (no card)": "Cancel (Add payment method)"});
            }
            if (invStat==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Subscribe to advanced inventory (trial ended | no card)": "Cancel (Add payment method)"});
            }
        }
        if (type === "INTEGRATION" && integrStat) {
            if (integrStat==="INTRIAL_SUB_NOACT" || invStat==="TRIALEND_SUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Activate integrations (no card)": "Cancel (Add payment method)"});
            }
            if (integrStat==="TRIALEND_NOSUB_NOACT") {
                lvMixpanel.track('Billing', {
                    "Subscribe to integrations (trial ended | no card)": "Cancel (Add payment method)"});
            }
        }
        if ( type === "SALESHISTORY" && salesHistoryStat ) {
            if ( salesHistoryStat === "INTRIAL_SUB_NOACT"
                || salesHistoryStat==="TRIALEND_SUB_NOACT" ) {
                lvMixpanel.track('Billing', {
                    "Activate unlimited sales history (no card)": "Cancel (Add payment method)"});
            }
            if ( salesHistoryStat ==="TRIALEND_NOSUB_NOACT" ) {
                track("Subscribe to unlimited sales history (trial ended | no card)",
                    "Cancel (Add payment method)", source
                );
            }
        }
    };
    //-- END MIXPANEL events when leaving (CANCEL) from the ADD PAYMENT TYPE page

    function track(propertyName, propertyValue, source) {
        lvMixpanel.track('Billing', buildTrackParams(propertyName, propertyValue, source));
    }

    function buildTrackParams(propertyName, propertyValue, source) {
        const result = {};
        result[propertyName] = propertyValue;
        if (source) {
            result['Event type'] = source;
        }
        return result;
    }
}

export default {
    type: 'service',
    name: 'serviceBillingMixpanel',
    value: serviceBillingMixpanel
};
