import angular from 'angular';

transferOrders.$inject = [
    '$shortOutlets',
    '$scope',
    '$stateParams',
    '$state',
    '$critCountOptionsEdit',
    '$transfersList',
    'localStorageService',
    'siteLangCode',
    '$profile',
    '$countOptionsShow',
    '$translate',
    'lvMixpanel'
];

function transferOrders(
  $shortOutlets,
  $scope,
  $stateParams,
  $state,
  $critCountOptionsEdit,
  $transfersList,
  localStorageService,
  siteLangCode,
  $profile,
  $countOptionsShow,
  $translate,
  lvMixpanel
) {
  var vm = this;

  $scope.statuses = [
      {
          code: null,
          name: $translate.instant('INVENTORY.STATUS_OLL')
      },
      {
          code: 'DRAFT',
          name: $translate.instant('INVENTORY.DRAFT')
      },
      {
          code: 'IN_TRANSIT',
          name: $translate.instant('INVENTORY.IN_TRANSIT')
      },
      {
          code: 'TRANSFERRED',
          name: $translate.instant('INVENTORY.TRANSFERRED')
      }
  ];

  $scope.shortOutlets = $shortOutlets.slice();
  $scope.shortOutlets.unshift({
      id: null,
      name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
  });
  $scope.selectOutletFrom = {id: $stateParams.outletFrom ? +$stateParams.outletFrom : null};
  $scope.selectOutletFromId = $scope.selectOutletFrom.id;
  $scope.selectOutletTo = {id: $stateParams.outletTo ? +$stateParams.outletTo : null};
  $scope.selectOutletToId = $scope.selectOutletTo.id;
  $scope.critCountOptionsEdit = $critCountOptionsEdit;
  $scope.selectStatuses = {code: $stateParams.status || null};
  $scope.selectStatusesCode = $scope.selectStatuses.code;
  $scope.editTableState = 'inventory.transferdetail';
  $scope.countOptionsShow = $countOptionsShow;


  //Task # 19957 Change stubs on Advanced inventory screens
  vm.plugsFilters = !!(!$scope.selectStatusesCode && !$scope.selectOutletFrom.id && !$scope.selectOutletTo.id);
  vm.plugsSearch = !!$stateParams.search;
  //Plugs for extended inventory accounting Transfer orders
  $scope.profile = angular.copy( $profile );
  var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];
  var plugTransferOrder = ["ru", "es", "jp", "ko", "idn", "ro", "th", "vn", "fr", "ar", "br", "pl", "de", "it", "gr", "zh"];
  $scope.plugTransferOrderHelpLink = plugTransferOrder.indexOf(siteUrlLang) !== -1
    ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-work-transfer-orders?utm_source=Back%20Office&utm_medium=Transfer%20orders"
    : "https://help.loyverse.com/help/how-work-transfer-orders?utm_source=Back%20Office&utm_medium=Transfer%20orders";

    $scope.trackHelpTransferOrders = function() {
        lvMixpanel.track('Help', {"Property": "Transfer orders"});
    };

    $scope.transferOrders = angular.copy($transfersList);
  // console.log('$scope.transferOrders', $scope.transferOrders);

  // данные для сохранение состояния фильтров
  $scope.stateFromList = $state.params;

  // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
  $scope.pages = $scope.transferOrders.pages;
  if ($scope.transferOrders.orders.length == 0 && $scope.stateFromList.page > 0) {
      $state.go( $state.current.name, {   page:        $scope.pages - 1,
                                          outletFrom:  $scope.stateFromList.outletFrom,
                                          outletTo:    $scope.stateFromList.outletTo,
                                          status:      $scope.stateFromList.status} );
  }
  // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

  $scope.$watch('selectOutletFrom', function(newval, oldval){
      if (oldval != newval) {
          // console.log("$scope.selectOutletFrom.id = ", $scope.selectOutletFrom.id);
          $state.go('inventory.transfer', {outletFrom: newval.id || null, page:0}).then(function() {
              $scope.selectOutletFromId = newval.id;
          });
      }
  }, true);


  $scope.$watch('selectOutletTo', function(newval, oldval){
      if (oldval != newval) {
          // console.log("$scope.selectOutletTo.id = ", $scope.selectOutletTo.id);
          $state.go('inventory.transfer', {outletTo: newval.id || null, page:0}).then(function() {
              $scope.selectOutletToId = newval.id;
          });
      }
  }, true);

  $scope.$watch('selectStatuses', function(newval, oldval){
      if (oldval != newval) {
          $state.go($state.current.name, {status: newval.code, page:0});
      }
  }, true);

  $scope.addTransferOrder = function () {
      $state.go('inventory.createtransfer', {});
  }

}

export default {
    type: 'controller',
    name: 'transferOrders',
    value: transferOrders
};
