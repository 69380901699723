datePickerValid.$inject = ['$compile','$timeout', '$filter', '$rootScope', 'dateCustomMethods'];

function datePickerValid($compile, $timeout, $filter, $rootScope, dateCustomMethods) {
	function stringToDate( val  ) {
		var fixedLocaleDate = $filter('formatDateByBrowser')(new Date(1481673600000), 'calendarInput');
		var dateFormatDivider = fixedLocaleDate.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/);
		var fixedLocaleDateFormat = fixedLocaleDate.split(dateFormatDivider);
		var refinedFixedLocaleDateFormat = [];
		fixedLocaleDateFormat.forEach(function(val){
			refinedFixedLocaleDateFormat.push(parseInt(val) + '');
		});
		// var indexOfDay = (fixedLocaleDateFormat.indexOf("11") !== -1) ? fixedLocaleDateFormat.indexOf("11") : refinedFixedLocaleDateFormat.indexOf("11");
		var indexOfDay = (fixedLocaleDateFormat.indexOf("14") !== -1) ? fixedLocaleDateFormat.indexOf("14") : (fixedLocaleDateFormat.indexOf("13") !== -1) ? fixedLocaleDateFormat.indexOf("13") : (fixedLocaleDateFormat.indexOf("15") !== -1) ? fixedLocaleDateFormat.indexOf("15") : (refinedFixedLocaleDateFormat.indexOf("14") !== -1) ? refinedFixedLocaleDateFormat.indexOf("14") : (refinedFixedLocaleDateFormat.indexOf("13") !== -1) ? refinedFixedLocaleDateFormat.indexOf("13") : refinedFixedLocaleDateFormat.indexOf("15");
		var indexOfMonth = (fixedLocaleDateFormat.indexOf("12") !== -1) ? fixedLocaleDateFormat.indexOf("12") : refinedFixedLocaleDateFormat.indexOf("12");
		var indexOfYear = (fixedLocaleDateFormat.indexOf("2016")!== -1) ? fixedLocaleDateFormat.indexOf("2016") : refinedFixedLocaleDateFormat.indexOf("2016");
		var splitVal = fixedLocaleDate.split(dateFormatDivider);
		var charsInDate = [];
		splitVal.forEach(function(arrElement){
			if(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)){
				charsInDate.push(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)[0]);
			}
		});


		// var isIE = ((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) ? true : false; //IF IE > 10
		// var isEdge = (!isIE && !!window.StyleMedia) ? true : false;

			// var currentLocaleDate = $filter('formatDateByBrowser')(new Date(), 'tableLabel');
			// var currentLocaleDateFormat = currentLocaleDate.split(dateFormatDivider);
			// var D = val.split(dateFormatDivider[0]);
			// var timestampFromVal = (new Date(val.split(dateFormatDivider).reverse().join(dateFormatDivider)).getTime());
			return {
				firstPartForRegex: fixedLocaleDateFormat[0],
				secondPartForRegex: fixedLocaleDateFormat[1],
				thirdPartForRegex: fixedLocaleDateFormat[2],
				dateFormatDivider: dateFormatDivider,
				charsInDate: charsInDate,
				indexOfDay: indexOfDay,
				indexOfMonth: indexOfMonth,
				indexOfYear: indexOfYear,
				dd: parseInt( val[indexOfDay] ),
				MM: parseInt( val[indexOfMonth] ),
				yyyy: parseInt( val[indexOfYear] )
			};
	}

	var valid = {
		date: function( val, maxDate ){
			var valDate;
			var regExpPattern;
			var st = true;
			var mess = null;
			var dateFormatDivider = stringToDate(val).dateFormatDivider;
			var eventualPattern;
			var patternOrder = [];
			var currentDateForStandardizedPattern = $filter( 'formatDateByBrowser' )( new Date(), '' );

			patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfDay, 0, "dd");
			patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfMonth, 0, "MM");
			patternOrder.splice(stringToDate(currentDateForStandardizedPattern).indexOfYear, 0, "yyyy");
			eventualPattern = patternOrder.join(stringToDate(currentDateForStandardizedPattern).dateFormatDivider);


			if ( val ) {
				val = '' + val;
			} else {
			   return {
					st: false,
					val: val,
					mess: 'pattern_localized_date_err',
					eventualPattern: eventualPattern
				};
			}

			var arabicIdentificator = new RegExp("[\u0660-\u0669]"); //solely for Arabic digits in a date
			var localeDivider = stringToDate(val).dateFormatDivider;
			var symbols = localeDivider[0].split(' ').join('');
			var chars  = stringToDate(val).charsInDate.join('');

			function correspondToRexEx (element) {
				var specialLocalDateFormat = new RegExp('[0-9]' + '[' + symbols + ']');
				if(element.match(specialLocalDateFormat)){
					return true;
				}
			}

		   if($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
			   regExpPattern = '[0-9]{2}\/[0-9]{2}\/[0-9]{4}';
		   } else if(val.match(arabicIdentificator)) {

				// console.log("I am arabic");
				// regExpPattern = '{'+ stringToDate(val).thirdPartForRegex.length +'}[\u0600-\u06FF]\.{' + stringToDate(val).secondPartForRegex.length + '}[\u0600-\u06FF]\.{' + stringToDate(val).firstPartForRegex.length + '}[\u0600-\u06FF]';
				// regExpPattern = '[\u0660-\u0669]{'+ stringToDate(val).thirdPartForRegex.length +'}' + localeDivider + '[\u0660-\u0669]{' + (stringToDate(val).secondPartForRegex.length-1) + '}' + localeDivider + '[\u0660-\u0669]{' + (stringToDate(val).firstPartForRegex.length-1) + '}';
				// regExpPattern = '[\u0660-\u0669\]{4}' + '|[ ]{1}' + localeDivider + '[\u0660-\u0669\]{2}' + '[ ]{1}' + localeDivider + '[\u0660-\u0669\]{2}' +'[ ]{1}';
			} else {
			   var specialPattern = val.split(localeDivider).some(correspondToRexEx);
			   if(specialPattern){
				   // console.log("I am special");
				   regExpPattern = '[0-9' + symbols + '\s]{' + stringToDate(val).firstPartForRegex.length +'}' + localeDivider + '[0-9' + symbols + '\s]{' + stringToDate(val).secondPartForRegex.length + '}' + localeDivider + '[0-9' + symbols + '\s]{' + stringToDate(val).thirdPartForRegex.length + '}';
			   } else {
					regExpPattern = '[0-9' + chars + ']{' + stringToDate(val).firstPartForRegex.length +'}' + localeDivider + '[0-9' + chars + ']{' + stringToDate(val).secondPartForRegex.length + '}' + localeDivider + '[0-9' + chars + ']{' + stringToDate(val).thirdPartForRegex.length + '}';
				}
			}
			 //    regExpPattern = '[0-9]{' + stringToDate(val).firstPartForRegex.length +'}' + stringToDate(val).dateFormatDivider + '[0-9]{' + stringToDate(val).secondPartForRegex.length + '}' + stringToDate(val).dateFormatDivider + '[0-9]{' + stringToDate(val).thirdPartForRegex.length + '}';
			// }

			var dateRegExp = (regExpPattern) ? RegExp(regExpPattern) : false;

			if(val.match(dateRegExp)){
				if($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge){
					var D = val.split("/");
					valDate = new Date(parseInt( D[2] ), parseInt( D[1] )-1,parseInt( D[0] ));
				} else {
					valDate = dateCustomMethods.fromFormattedStringToDateObject(val);
				}
				st = true;


				if(maxDate){
					if(maxDate<valDate && typeof(valDate == 'object')){
						st = false;
						mess = 'calendar_format_date';
					}
				}
			} else {
				st = false;
				mess = 'pattern_localized_date_err';
			}

			return {
				st: st,
				val: val,
				mess: mess,
				eventualPattern: eventualPattern
			};
		}
	};

	function createElementAlert( parent, scope ) {
		var template;

		var dateForPattern = new Date().toLocaleDateString();
		scope.patternForMessage = dateForPattern.toString();
		parent.addClass( 'relative' );

		if($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge) {
			template = "<div class='err-mess animate-err-mess' ng-if='focus && alertMessage'>{{'ERROR.'+alertMessage | translate}}</div>";
		} else {
			template = "<div class='err-mess animate-err-mess' ng-if='focus && alertMessage'>{{('ERROR.'+ alertMessage  | translate)  + eventualPattern }}</div>";
		}
		var linkFn = $compile( template );
		var content = linkFn( scope );
		parent.append( content );

		return content;
	}

	return {
		restrict: 'A',
		require: 'ngModel',
		scope: {
			valid: '@datePickerValid',
			validMessage: '@'
		},
		link: function ( scope, el, attr, ctrl ) {
			var isAfter = false,
				// isBefore = false,
				isExact = false;

			var minDate = $filter('formatDateByBrowser')(scope.$parent.minDate, 'calendarInput');
			var maxDate = $filter('formatDateByBrowser')(scope.$parent.maxDate, 'calendarInput');
			var validMessage = eval( scope.validMessage );
			if ( validMessage ) {
				new createElementAlert( el.parent(), scope, ctrl )
			}

			if(el.hasClass('dp-info-label-after')){
				isAfter = true;
			}

			/**срабатывает при старте*/
			ctrl.$formatters.unshift( checkForEven );
			/**срабатывает при редактировании*/
			ctrl.$parsers.unshift( checkForEven );

			ctrl.err = {};
			var position;
			var valueLength; //boolean
			var maxLength;



			function checkForEven( value ) {
				var neWval;
				var _valid;
				minDate = scope.$parent.minDate;
				maxDate = scope.$parent.maxDate;
				if ( value == valid[scope.valid]( value, maxDate ).val ) {
					neWval = value;
				} else {
					try {
						position = doGetCaretPosition( el[0] );
						_valid = valid[scope.valid]( value );
						neWval = _valid.val;
						maxLength = _valid.maxLength;
						if ( value.length == neWval.length || maxLength < value.length ) {
							valueLength = true;
						} else {
							valueLength = false;
						}

						if ( valueLength ) {
							setCaretPosition( el[0], position );
						} else {
							setCaretPosition( el[0], position - 1 );
						}
						ctrl.$setViewValue( neWval );
						ctrl.$render();

					} catch ( err ) {
						console.log( err );
					}

				}

				scope.eventualPattern = valid[scope.valid]( neWval, maxDate );

				if ( valid[scope.valid]( neWval, maxDate ).st ) {
					ctrl.$setValidity(scope.valid, true);
					ctrl.err[scope.valid] = null;
					el.parent().removeClass("has-error")
					scope.alertMessage = null;
				} else {
					ctrl.$setValidity( scope.valid, false );
					ctrl.err[scope.valid] = valid[scope.valid]( neWval, maxDate ).mess;
					scope.alertMessage = ctrl.err[scope.valid];
					scope.eventualPattern = valid[scope.valid]( neWval, maxDate ).eventualPattern;
					el.parent().addClass( "has-error" );
				}
				return neWval;
			}

			el
				.bind( 'focus', function () {
					ctrl.focus = true;
					scope.focus = true;
					$timeout( function () {
						scope.$apply();
					} );
				} )
				.on( 'blur', function () {
					ctrl.focus = false;
					scope.focus = false;
					$timeout( function () {
						scope.$apply();
					} );
				} );


			function doGetCaretPosition( element ) {
				var CaretPos = 0;	// IE Support
				if ( document.selection ) {
					element.focus();
					var Sel = document.selection.createRange();
					Sel.moveStart( 'character', -element.value.length );
					CaretPos = Sel.text.length;
				}
				// Firefox support
				else if ( element.selectionStart || element.selectionStart == '0' )
					CaretPos = element.selectionStart;
				return (CaretPos);
			}

			function setCaretPosition( element, pos ) {
				if ( element.setSelectionRange ) {
					element.focus();
					element.setSelectionRange( pos, pos );
				}
				else if ( element.createTextRange ) {
					var range = element.createTextRange();
					range.collapse( true );
					range.moveEnd( 'character', pos );
					range.moveStart( 'character', pos );
					range.select();
				}
			}
		}
	}
}

export default datePickerValid;