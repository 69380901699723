import angular from 'angular';

factoryWebHooks.$inject = ['promiseOperation'];

function factoryWebHooks(promiseOperation) {


    function getWebHooksList(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getWebHooksList',
                params,
                successGetWebHooksList
            );
    }

    function createWebHook(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createWebHooks',
                params,
                successCreateWebHook
            );
    }

    function editWebHook(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editWebHooks',
                params,
                successEditWebHook
            );
    }

    function getWebHookById(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getWebHookById',
                params,
                successGetWebHookById
            );
    }

    function deleteWebHooks(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/deleteWebHooks',
                params,
                successDeleteWebHooks
            );
    }

    function testPostRequest(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/testPostRequest',
                params,
                successTestPostRequest
            );
    }


    function successGetWebHooksList(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successCreateWebHook(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successEditWebHook(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successGetWebHookById(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successDeleteWebHooks(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function successTestPostRequest(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    return {
        createWebHook: createWebHook,
        getWebHookById: getWebHookById,
        editWebHook: editWebHook,
        deleteWebHooks: deleteWebHooks,
        getWebHooksList: getWebHooksList,
        testPostRequest: testPostRequest
    };
}

export default {
    type: 'factory',
    name: 'factoryWebHooks',
    value: factoryWebHooks
};