import angular from 'angular';
import deleteImg from '../../../img/ic_delete.svg';
import reportProblemImg from '../../../img/ic_report_problem.svg';
import factoryIsManager from "../../factory/factory-is-manager";

countStock.$inject = [
    '$countOptionsEdit',
    '$countOptionsShow',
    '$scope',
    '$critCountOptionsEdit',
    '$state',
    'factoryAdjustment',
    '$q',
    '$filter',
    'serviceDialog',
    '$countCard',
    'serviceToast',
    '$stateParams',
    'factoryInventoryCount',
    '$countOptionsShowWithPlus',
    '$countOptionsEditDivisible',
    '$rootScope',
    '$profile',
    '$timeout',
    'tableViewColumnFactory',
    '$translate',
    'lvMixpanel',
    '$mdUtil',
    '$permitsManager'
];

function countStock(
    $countOptionsEdit,
    $countOptionsShow,
    $scope,
    $critCountOptionsEdit,
    $state,
    factoryAdjustment,
    $q,
    $filter,
    serviceDialog,
    $countCard,
    serviceToast,
    $stateParams,
    factoryInventoryCount,
    $countOptionsShowWithPlus,
    $countOptionsEditDivisible,
    $rootScope,
    $profile,
    $timeout,
    tableViewColumnFactory,
    $translate,
    lvMixpanel,
    $mdUtil,
    $permitsManager
){

    var vm = this;
    var translate = $filter('translate');
    var startTime = Date.now();
    var offStateChangeStart;
    vm.countCardData = $countCard;
    vm.countOptionsEdit = $countOptionsEdit;
    vm.countOptionsShow = $countOptionsShow;
    vm.critCountOptionsEdit = $critCountOptionsEdit;
    vm.countOptionsShowWithPlus = $countOptionsShowWithPlus;
    vm.countOptionsEditDivisible = $countOptionsEditDivisible;
    vm.searching = false;
    $scope.currentQuant = parseFloat($filter( 'wareCount' )(1000, ".", false));
    $scope.addTo = addTo;
    $scope.alert = angular.copy($profile.billingInfo.alert);
    $scope.isManager = angular.copy($profile.isManager);
    $scope.inProgress = false;
    var histObject = {};
    var listElem = {};
    var searchDefer;
    var errorInput = document.getElementsByName('itemForCount');
    var heightScreen = window.innerHeight,
        hiddenTwo = document.getElementById('hiddenTwo'),
        fixedBlock = document.getElementById('fixedButtons'),
        canSubmit = false,
        scrollWindow = document.getElementsByClassName('main-content')[0];
    var sync = {
        orderId: vm.countCardData.orderId,
        lastSyncTS: startTime,
        modified: [],
        deleted: [],
        addedHistoryItems: [],
        deletedHistoryItems: []
    };
    let syncTimeout;
    vm.itemsFound = null;
    vm.itemList = vm.countCardData.items.slice();
    vm.counthistory = (vm.countCardData.historyItems.length > 0) ? vm.countCardData.historyItems : [];

    $scope.$on('$viewContentLoaded', function(){
        $timeout(
            function () {
                fixedButtons();
            }, 200)
    });

    $scope.$on('$destroy', function () {
        $timeout.cancel(syncTimeout);
    });

    window.addEventListener('resize', onUpdateSize);

    for(var i = 0; i < vm.itemList.length; i++) {
        vm.itemList[i].count = (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? parseFloat($filter( 'wareCount' )(vm.itemList[i].count, ".", false)) : null;
        vm.itemList[i].diff = (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? (parseFloat($filter( 'wareCount' )(vm.itemList[i].count, ".", true) - vm.itemList[i].expected))/1000 : "-";
        vm.itemList[i].costDiff = (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? parseFloat($filter( 'coinToCurrency' )(((vm.itemList[i].diff*1000 * vm.itemList[i].cost)/100000).toFixed(2), ".")) : "-";
    }

    function onUpdateSize() {
        heightScreen = window.innerHeight;
        fixedButtons();
    }

    function addTo(item, searchText){
        if (searchText !== null && item !== null) {
            if (existence(item.id, vm.itemList)) {
                recountExistItemQuantity(item);
            } else {
                listElem = {
                    wareId: item.id,
                    name: item.name,
                    sku: item.sku,
                    expected: item.inStock,
                    cost: item.primeCost,
                    count: $scope.currentQuant,
                    planned: false
                }
                listElem.diff = (listElem.count *1000 - item.inStock)/1000;
                listElem.costDiff = parseFloat($filter( 'coinToCurrency' )(((listElem.diff*1000 * item.primeCost)/100000).toFixed(2), "."));
                vm.itemList = vm.itemList.concat(listElem);
                addToHistory(false, item);
                addToModified(listElem);
                $scope.totalDiff += listElem.diff;
                $scope.totalDiffCost += listElem.costDiff;
                $timeout(onUpdateSize, 50);
            }
        } else if (searchText !== null && searchText !== "" && item === null) {
            histObject = {
                id: null,
                count: parseFloat($filter('wareCount')($scope.currentQuant, ".", true)),
                created: Date.now(),
                inputValue: searchText
            }
            vm.counthistory.unshift(histObject);
            sync.addedHistoryItems.push({
                created: histObject.created,
                id: histObject.id,
                inputValue: histObject.inputValue,
                count: histObject.count
            });
        }
        angular.element(errorInput).focus();
        fixedButtons();
        updateTableColumns();
    }

    $scope.addToList = function($event) {
        if ($event.keyCode === 13) {
            canSubmit = true;
            if (vm.autocompleteItems.selectedItem && vm.autocompleteItems.selectedItem.name === vm.autocompleteItems.searchText) {
                addTo(vm.autocompleteItems.selectedItem, vm.autocompleteItems.searchText);
            } else {
                angular.element(errorInput).focus();
            }
        } else {
            canSubmit = false;
        }
    }

    function addToHistory(planned, item) {
        histObject = {
            id: item.id,
            count: parseFloat($filter('wareCount')($scope.currentQuant, ".", true)),
            name: item.name,
            created: Date.now(),
            planned: planned
        }
        vm.counthistory.unshift(histObject);
        sync.addedHistoryItems.push({
            created: histObject.created,
            id: histObject.id,
            inputValue: null,
            count: histObject.count
        });
    }

    function recountExistItemQuantity (item) {
        for (var i = 0; i < vm.itemList.length; i++) {
            if (item.id === vm.itemList[i].wareId) {
                vm.itemList[i].count = (vm.itemList[i].count) ? ((parseFloat(vm.itemList[i].count)*1000 + parseFloat($scope.currentQuant)*1000)/1000).toString() : parseFloat($scope.currentQuant);
                vm.itemList[i].diff = (vm.itemList[i].count*1000 - vm.itemList[i].expected)/1000;
                vm.itemList[i].costDiff = parseFloat($filter( 'coinToCurrency' )(((vm.itemList[i].diff*1000 * item.primeCost)/100000).toFixed(2), "."));
                totalRecount();
                angular.element(errorInput).focus();
                addToHistory(vm.itemList[i].planned, item);
                addToModified(vm.itemList[i]);
                return;
            }
        }
    }

    function addToModified(item) {
        if(sync.modified.length > 0) {
            for(i=0; i < sync.modified.length; i++){
                if(sync.modified[i].id === item.wareId) {
                    if(typeof item.count === 'string') {
                        sync.modified[i].count = (item.count !== "") ? parseFloat($filter( 'wareCount' )(item.count, ".", true)) : null;
                    } else {
                        sync.modified[i].count = item.count;
                    }
                    return;
                }
            }
            sync.modified.push({
                id: item.wareId,
                count: item.count === null ? null : parseFloat($filter('wareCount')(item.count, ".", true)),
                planned: item.planned
            });

        } else {
            sync.modified.push({
                id: item.wareId,
                count: item.count === null ? null : parseFloat($filter( 'wareCount' )(item.count, ".", true)),
                planned: item.planned
            });
        }
    }

    var alertHeight = $scope.alert ? 48 : 0;

    $scope.closeLeftPanel = function() {
        document.querySelector('.history-right-panel').style.display = 'none';
        document.querySelector('.history-button').style.display = 'block';
    };

    $scope.showHistory = function() {
        document.querySelector('.history-right-panel').style.display = 'block';
        document.querySelector('.history-button').style.display = 'none';
    };


    function fixedButtons() {
        var fixedBlockPosition = hiddenTwo.getBoundingClientRect().bottom;
        if(fixedBlock.classList.contains('fixed') && fixedBlockPosition <= (heightScreen - alertHeight)) {
            fixedBlock.classList.remove('fixed');
            fixedBlock.style.display = 'none';
        } else if (fixedBlockPosition > (heightScreen - alertHeight)) {
            fixedBlock.classList.add('fixed');
            fixedBlock.style.display = 'flex';
            fixedBlock.style.width = hiddenTwo.offsetWidth + "px";
        }
    }

    scrollWindow.onscroll=function() {
        fixedButtons();
    };

    vm.autocompleteItems = {
        searchText: null,
        selectedItem: null,
        getMatches: function ( query ) {
            searchDefer = $q.defer();
            vm.searching = true;
            factoryAdjustment
                .searchItemsForAdjustmentOrder( {
                    search: query,
                    outletId: vm.countCardData.outletId
                })
                .then(function (items) {
                    searchDefer.resolve(items);
                    vm.itemsFound = angular.copy(items);
                    if(canSubmit && vm.autocompleteItems.selectedItem === null){
                        Promise.resolve({
                            then: function(){
                            if(vm.itemsFound.length > 0) {
                                vm.autocompleteItems.selectedItem = vm.itemsFound[0];
                                addTo(vm.itemsFound[0], vm.autocompleteItems.searchText);
                            } else {
                                addTo(null, vm.autocompleteItems.searchText);
                            }
                            canSubmit = false;
                         }
                        });

                    }
                })
                .finally(function() {
                    vm.searching = false;
                });
            return searchDefer.promise;
        }
    };

    function recountAndHistoryPush(item) {

        $mdUtil.nextTick(() => {

            item.count = (item.count !== "") ? item.count : null;

            if ((parseFloat(item.count) !== (item.diff + parseFloat($filter('wareCount')(item.expected, ".", false))))) {
                var histObject = {
                    id:item.wareId,
                    count: (item.diff !== "-") ? parseFloat($filter( 'wareCount' )(item.count, ".", true)) - parseFloat($filter( 'wareCount' )(item.diff, ".", true)) - item.expected : parseFloat($filter( 'wareCount' )(item.count, ".", true)),
                    name: item.name,
                    created: Date.now(),
                    planned: item.planned
                }
                if (histObject.count !== 0) {
                    vm.counthistory.unshift(histObject);
                    sync.addedHistoryItems.push({
                        created: histObject.created,
                        id: histObject.id,
                        inputValue: null,
                        count: histObject.count
                    });
                }
            }

            item.diff = (item.count !== null) ? (item.count * 1000 - item.expected)/1000 : '-';
            item.costDiff = (item.count !== null) ? parseFloat($filter( 'coinToCurrency' )(((item.diff*1000 * item.cost)/100000).toFixed(2), ".")) : '-';

            addToModified(item);
            totalRecount();
        });
    }

    function totalRecount() {
        $scope.totalNumbers = {
            totalDiff: 0,
            totalDiffCost: 0
        };
        for (var i = 0; i < vm.itemList.length; i++) {
            if (vm.itemList[i].count !== null && vm.itemList[i].count !== "" && vm.itemList[i].diff !== "-") {
                $scope.totalNumbers.totalDiff += (vm.itemList[i].diff*1000);
                $scope.totalNumbers.totalDiffCost += parseFloat($filter( 'currencyToCoin' )(vm.itemList[i].costDiff, "."));
            }
        }
        return $scope.totalNumbers = {
            totalDiff: $scope.totalNumbers.totalDiff/1000,
            totalDiffCost: parseFloat($filter( 'coinToCurrency' )($scope.totalNumbers.totalDiffCost, "."))
        };
    }
    totalRecount();

    function existence(itemId,  array) {
        for (var i=0; i < array.length; i++) {
            if(itemId === array[i].id || itemId === array[i].wareId) {
                return true;
            }
        }
        return false;
    }

    $scope.onDelHistory = function(item) {
        vm.counthistory.forEach(function(elem, index){
            if (elem.created === item.created) {
                vm.counthistory.splice(index,1);
            }
        });

        vm.itemList.forEach(function(elem){
            if (item.id !== null && elem.wareId === item.id && existence(item.id, vm.counthistory)) {
                elem.count = ((elem.count*1000 - item.count)/1000).toString();
                elem.diff = (elem.count*1000 - elem.expected)/1000;
                elem.costDiff = parseFloat($filter( 'coinToCurrency' )(((elem.diff*1000*elem.cost)/100000).toFixed(2), "."));
                addToModified(elem);
            } else if (item.id !== null && elem.wareId === item.id && !existence(item.id, vm.counthistory)) {
                elem.count = "";
                elem.diff = '-';
                elem.costDiff = '-';
                addToModified(elem);
            }
        });
        totalRecount();
        sync.deletedHistoryItems.push(item.created);
    };

    $scope.onDelete = onDelete;
    function onDelete(item) {

        vm.itemList = vm.itemList.filter(currItem => currItem.wareId !== item.wareId);

        for (let i = vm.counthistory.length - 1; i >= 0; i--) {
            let historyItem = vm.counthistory[i];
            let deleteCurrentItem = false;

            if (historyItem.id !== null) {
                deleteCurrentItem = historyItem.id === item.wareId
            } else {
                deleteCurrentItem = historyItem.inputValue === item.name;
            }

            if (deleteCurrentItem) {
                sync.deletedHistoryItems.push(historyItem.created);
                vm.counthistory.splice(i,1);
            }
        }

        totalRecount();
        $timeout(onUpdateSize, 50);
        sync.deleted.push(item.wareId);
        updateTableColumns();
    }

    $scope.onSave = function(complete, editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        vm.count = 0;
        vm.affected = 0;
        vm.notCounted = 0;
        vm.isModified = true;
        vm.deleted = 0;
        vm.notTracked = 0;
        vm.notProduced = 0;
        vm.onSave = {
            items:[],
            historyItems: sync.addedHistoryItems,
            deleted: sync.deleted,
            deletedHistoryItems: sync.deletedHistoryItems,
            orderId:vm.countCardData.orderId,
            action: complete ? "COMPLETE" : "SAVE",
            isModified: vm.isModified
        };
        for(var i = 0; i < vm.itemList.length; i++) {
            if(vm.itemList[i].planned === false) {
                vm.count++;
            }
            if(vm.itemList[i].deleted === true) {
                vm.deleted++;
            }
            if(vm.itemList[i].count === null || vm.itemList[i].count === "") {
                vm.notCounted++;
            }
            if(vm.itemList[i].type !== 'COMPLEX' && vm.itemList[i].keepCount===false){
                vm.notTracked++;
            }
            if(vm.itemList[i].type === 'COMPLEX' && vm.itemList[i].useProduction === false) {
                vm.notProduced++;
            }
            if(complete) {
                vm.affected++;
            }
            vm.onSave.items.push({
                id: vm.itemList[i].wareId,
                count: (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? $filter( 'wareCount' )(vm.itemList[i].count, ".", true) : null,
                planned: true
            })
        }

        if(complete) {
            //Triggered when users click on the “Complete” button in the “Count stock” page.
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Complete inventory count"
            });
            vm.affected -= vm.notCounted;
        }

        if(vm.notCounted === vm.itemList.length) {
            vm.onSave.isModified = false;
        }

        vm.buttonName= ["<span style='font-weight: 500;'>"+translate('ON_SAVE')+"</span>",
                        "<span style='font-weight: 500;'>"+translate('ON_CANCEL')+"</span>",
                        "<span style='font-weight: 500;'>"+translate('INVENTORY.CONFIRM_COUNT')+"</span>"];

        if (complete && vm.notCounted === vm.itemList.length) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                content: translate('INVENTORY.COUNT.ENTER_QUANTITY'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else if ((vm.count > 0 && !complete) || (complete && (vm.count > 0 || vm.affected > 0 || vm.notCounted > 0 || vm.notTracked > 0 || vm.notProduced > 0))) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/inventory/dialog-count-save.html',
                title: (!complete) ? translate('INVENTORY.SAVE_COUNT') : translate('INVENTORY.CONFIRM_INVENTORY_COUNT'),
                count: vm.count,
                affected: vm.affected,
                notCounted: vm.notCounted,
                deleted: vm.deleted,
                notPartOnQuantity : notPartOnQuantity(vm.count),
                buttonName: vm.buttonName,
                notTracked: vm.notTracked,
                notProduced: vm.notProduced,
                content: (vm.count % 10 === 1) ? translate('INVENTORY.ITEM_WERE_NO_PART', {number: vm.count, items: notPartOnQuantity(vm.count)[0]}) : translate('INVENTORY.ITEMS_WERE_NO_PART', {number: vm.count, items: notPartOnQuantity(vm.count)[0]}),
                content0: translate('INVENTORY.CONFIRM_CHANGES'),
                content2: (vm.affected === 1) ? translate('INVENTORY.LEVEL_WILL_BE_AFFECTED') : translate('INVENTORY.LEVELS_WILL_BE_AFFECTED', {number: vm.affected, items: stockLevelOnQuantity(vm.affected)[0]}),
                content3: (vm.notCounted === 1) ? translate('INVENTORY.ITEM_WERE_NOT_COUNTED', {number: vm.notCounted, items: notPartOnQuantity(vm.notCounted)[0], Its: notPartOnQuantity(vm.notCounted)[3]}) : translate('INVENTORY.ITEMS_WERE_NOT_COUNTED', {number: vm.notCounted, items: notPartOnQuantity(vm.notCounted)[0], Its: notPartOnQuantity(vm.notCounted)[3]}),
                content5: (vm.deleted === 1) ? translate('INVENTORY.DELETED_ITEM', {number: vm.deleted, items: notPartOnQuantity(vm.deleted)[0]}) : translate('INVENTORY.DELETED_ITEMS', {number: vm.deleted, items: notPartOnQuantity(vm.deleted)[0]}),
                content6: translate('INVENTORY.NOTCOUNTED_ITEMS_CONFIRM_MESSAGE', {number: vm.notTracked, items: stockLevelOnQuantity(vm.notTracked)[0], Its: notPartOnQuantity(vm.notTracked)[3]}),
                content7: translate('INVENTORY.NOTPRODUCED_ITEMS_CONFIRM_MESSAGE', {number: vm.notProduced, items: stockLevelOnQuantity(vm.notProduced)[0], Its: notPartOnQuantity(vm.notProduced)[3], composite: stockLevelOnQuantity(vm.notProduced)[1]}),
                complete: complete,
                buttons: [
                    {
                        id      : 'count_stock_save_dialog__cancel_btn',
                        text 	: 'ON_CANCEL'
                    },
                    {
                        id      : 'count_stock_save_dialog__confirm_btn',
                        // class 	: 'primary',
                        class 	: $scope.arapro ? "primary arabic-save" : "primary",
                        text 	: complete ? 'INVENTORY.CONFIRM_COUNT' : 'ON_SAVE',
                        action 	: function(){
                            $scope.inProgress = true;
                            factoryInventoryCount.editInventoryCountStock(vm.onSave)
                                .then(function(data){
                                    if(data.result==='ok' || data.result === 'error' && data.message ==='order_already_completed') {
                                        $state.go('inventory.countdetail', {id: $stateParams.id});
                                    } else {
                                        $scope.inProgress = false;
                                    }
                                })
                                .catch(function(error) {
                                    $scope.inProgress = false;
                                    return $q.reject(error);
                                });
                        }
                    }
                ]
            });
        } else {
            $scope.inProgress = true;
            factoryInventoryCount.editInventoryCountStock(vm.onSave)
                .then(function(data){
                    if(data.result==='ok' || data.result === 'error' && data.message ==='order_already_completed') {
                        $state.go('inventory.countdetail', {id: $stateParams.id});
                    } else {
                        $scope.inProgress = false;
                    }
                })
                .catch(function(error) {
                    $scope.inProgress = false;
                });
        }
    };

    function stockLevelOnQuantity(quantity) {
        var modulo = quantity % 10;
        if (quantity === 1) {
            return [
                translate("ITEMS_DECLINE.FOR_ONE_ITEM"),
                translate("INVENTORY.QUANT.FOR_COMPOSIT_ITEM")
            ];
        } else if ((quantity > 1 && quantity <=20) || modulo !== 1) {
            return [
                translate("ITEMS_DECLINE.ITEMS_5_TO_10"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEMS")
            ];
        } else if (quantity > 20 && modulo === 1) {
            return [
                translate("ITEMS_DECLINE.ITEMS_1"),
                translate("INVENTORY.QUANT.FOR_COMPOSIT_ITEM")
            ];
        }
    }

    function notPartOnQuantity(quantity) {
        var modulo = quantity % 10;
        if (quantity === 1) {
            return [
                translate("ITEMS_DECLINE.ONE_ITEM"),
                translate("INVENTORY.QUANT.THIS_ITEM"),
                translate("INVENTORY.QUANT.ONE"),
                translate("INVENTORY.QUANT.ITS"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEM")
            ];
        } else if (modulo > 1 && modulo < 5) {
            return [
                translate("ITEMS_DECLINE.ITEMS_2_TO_4"),
                translate("INVENTORY.QUANT.THIS_ITEMS"),
                translate("INVENTORY.QUANT.MENY"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEMS")
            ];
        }else if ((modulo > 4 && modulo < 10) || modulo === 0 || (quantity > 10 && quantity <= 20)) {
            return [
                translate("ITEMS_DECLINE.ITEMS_5_TO_10"),
                translate("INVENTORY.QUANT.THIS_ITEMS"),
                translate("INVENTORY.QUANT.MENY"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEMS")
            ];
        } else if (quantity > 1 && modulo === 1) {
            return [
                translate("ITEMS_DECLINE.ITEMS_1"),
                translate("INVENTORY.QUANT.THIS_ITEMS"),
                translate("INVENTORY.QUANT.MENY"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEM")
            ];
        }
    }

    function synchronization(){
        if ($scope.isManager === false) {
            syncTimeout = $timeout(function () {
                if ($state.current.name === 'inventory.countstock') {
                    factoryInventoryCount.getSyncInventoryCount(sync).then(function (data) {
                        if (data.result === 'ok') {
                            if (data.modified.length > 0) {
                                for (var i = 0; i < vm.itemList.length; i++) {
                                    for (var j = 0; j < data.modified.length; j++) {
                                        if (vm.itemList[i].wareId === data.modified[j].id) {
                                            vm.itemList[i].expected = data.modified[j].expected;
                                            vm.itemList[i].cost = data.modified[j].cost;
                                            vm.itemList[i].diff = (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? (vm.itemList[i].count * 1000 - vm.itemList[i].expected) / 1000 : "-";
                                            vm.itemList[i].costDiff = (vm.itemList[i].count !== null && vm.itemList[i].count !== "") ? parseFloat($filter('coinToCurrency')(((vm.itemList[i].diff * 1000 * data.modified[j].cost) / 100000).toFixed(2), ".")) : "-";
                                        }
                                    }
                                }
                                totalRecount();
                            }
                            sync.modified = [];
                            sync.deleted = [];
                            sync.addedHistoryItems = [];
                            sync.deletedHistoryItems = [];
                            sync.lastSyncTS = Date.now();
                            if ($state.current.name === 'inventory.countstock') {
                                synchronization();
                            }
                        }
                    });
                }
            }, 30000);
        }
    }

    synchronization();``

    updateTableColumns();

    function buildColumns() {
        let result = [];

        // Item
        const nameOptions = {
            name: 'name',
            minWidth: 184,
            icon: {
                src: reportProblemImg,
                class: 'orange inlineBlock pointer',
                isVisible: item => item.planned === false,
                attrs: {
                    'aria-label': 'Not planned item'
                },
                tooltip: {
                    text: $translate.instant('INVENTORY.ITEM_NOT_PART'),
                    class: 'md-origin-top orange-triangle'
                }
            }
        };
        const nameFields = [];
        nameFields.push({
            displayValue: $translate.instant('ARTICLE'),
            valueGetter: 'sku',
            class: 'l-comment'
        });
        result.push(tableViewColumnFactory.createPropertyViewExtendedColumn(
            $translate.instant('INVENTORY.ITEMS_TABLE.ITEM'), 'name', nameFields, nameOptions));

        // Expected stock
        const stockOptions = {
            name: 'expected_stock',
            width: 96,
            viewFilters: {
                'divideByThousand': null,
                'weightFormatOnlyShow': null
            },
            class: 'text-right'
        };
        result.push(tableViewColumnFactory.createPropertyViewColumn(
            $translate.instant('INVENTORY.EXPECKTED'), 'expected', stockOptions));

        // Counted
        const countedOptions = {
            name: 'counted',
            minWidth: 96,
            attrs: {
                'name': 'counted',
                'aria-label': 'counted'
            },
            referenceAttrs: {
                'currency-localization': item => item.divisible ? vm.countOptionsEditDivisible : vm.countOptionsEdit,
                'ng-blur': item => recountAndHistoryPush(item)
            },
            footer: {
                value: $translate.instant('IN_TOTAL'),
                class: 'text-right'
            }
        };
        result.push(tableViewColumnFactory.createInputColumn(
            $translate.instant('INVENTORY.COUNTED'), 'count', countedOptions));

        // Difference
        const differenceOptions = {
            name: 'difference',
            minWidth: 96,
            class: 'text-right',
            footer: {
                id: 'difference_total',
                value: function() {
                    return $filter('weightFormatOnlyShow')($scope.totalNumbers.totalDiff)
                },
                class: 'text-right'
            }
        };
        function differenceViewGetter(item) {
            return (item.diff !== '-') ? $filter('weightFormatOnlyShow')(item.diff) : '—';
        }
        result.push(tableViewColumnFactory.createPropertyViewColumn(
            $translate.instant('CASHBOX.SUBTRACT'), differenceViewGetter, differenceOptions));

        // Cost difference
        if ($permitsManager.costAllowed) {
            const costDifferenceOptions = {
                name: 'cost_difference',
                minWidth: 96,
                class: 'text-right',
                footer: {
                    id: 'cost_difference_total',
                    value: function() {
                        return $filter('currencyFormatOnlyShow')($scope.totalNumbers.totalDiffCost)
                    },
                    class: 'text-right'
                }
            };
            function costDifferenceViewGetter(item) {
                return (item.costDiff !== '-') ? $filter('currencyFormatOnlyShow')(item.costDiff) : '—';
            }
            result.push(tableViewColumnFactory.createPropertyViewColumn(
                $translate.instant('INVENTORY.COST_DIFF'), costDifferenceViewGetter, costDifferenceOptions));
        }

        // Delete
        let removableItem = vm.itemList.find(item => item.planned === false);
        if (removableItem) {
            const delColumnOptions = {
                width: 48,
                noShrink: true,
                isVisible: item => item.planned === false
            };
            result.push(tableViewColumnFactory.createActionColumn(
                null, deleteImg, onDelete, delColumnOptions));
        }

        return result;
    }

    function updateTableColumns() {
        vm.columns = buildColumns();
    }
}

export default {
    type: 'controller',
    name: 'countStock',
    value: countStock
};
