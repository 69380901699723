/**
 * Created by sirosh on 27.01.16.
 * @memberof digitPixelsAddClass
 * @name app.directive: digitPixelsAddClass
 * @ngdoc directive
 * @name digitPixelsAddClass
 * @restrict A
 * @requires arrayOperation {service} - service operations with array
 * @scope
 */

import angular from 'angular';

digitPixelsAddClass.$inject = [ "arrayOperation"];
function digitPixelsAddClass(arrayOperation) {
    return {

        restrict: 'A',
        scope: {
            digits: '='
        },
        /**
         * @param scope
         * @param element
         */
        link: function (scope, element) {

            var max = arrayOperation.getMaxOfArray(scope.digits);
            var min = arrayOperation.getMinOfArray(scope.digits);
            var maxLengthMax = max.toString().length;
            var maxLengthMin = min.toString().length;

            if(maxLengthMax > 7 || maxLengthMin > 7){
                var img =  Array.prototype.slice.call(element.find('img'));
                var len = img.length;

                while(len--){
                    var elem = angular.element(img[len]).parent(),
                    nextChild = elem.next().children();
                    nextChild.eq(0).addClass('element-font');
                    elem.next().addClass('clear-float');
                    elem.addClass('prevent-icon icon-wrap-directive');
                    nextChild.eq(1).addClass('children-span');
                }
            }
        }
    };
}

export default {
    type: 'directive',
    name: 'digitPixelsAddClass',
    value: digitPixelsAddClass
};