/**
 * Created by olehkss on 19.08.16.
 */

import angular from 'angular';

settingsPayTypes.$inject = [
    '$state',
    '$scope',
    '$stateParams',
    '$filter',
    '$paytypePermissions',
    '$paymentTypesInfo',
    'payTypesOperationsFactory',
    'serviceMenu',
    'serviceDialog',
    'serviceToast',
    '$profile',
    '$shortOutlets',
    'dialogs'
];

function settingsPayTypes($state,
                          $scope,
                          $stateParams,
                          $filter,
                          $paytypePermissions,
                          $paymentTypesInfo,
                          payTypesOperationsFactory,
                          serviceMenu,
                          serviceDialog,
                          serviceToast,
                          $profile,
                          $shortOutlets,
                          dialogs) {

    let translate = $filter('translate');
    let vm = this;

    vm.paytypesPermissions = angular.copy($paytypePermissions);
    $scope.vantivExists = false;

    vm.paymentTypes = angular.copy($paymentTypesInfo.paymentTypes).filter(function (val) {
        $scope.vantivExists = true;
        if(val.method === "VANTIV" && val.name === null) val.name = "Worldpay";
        return val;
    });

    vm.paymentTypes.forEach(function (pt) {
            if (!pt.name) {
                pt.name = translate("PAYMENT_TYPES." + pt.method)
            }
        }
    );

    vm.permitSettings = serviceMenu.settingsObj;
    vm.registerVantivTerminal = registerVantivTerminal;

    if (vm.paymentTypes) {
        vm.paymentTypes._select = false;
        vm.selectedPaymentTypes = [];
        vm.showDel = false;
        vm.onAdd = onAdd;
        vm.onDel = onDel;
        vm.changePaymentType = changePaymentType;
        vm.changeAll = changeAll;
        vm.editPaymentTypes = editPaymentTypes;
        vm.editTableState = 'editPayType';

        selectAll(false);
    }

    // Stores filter for multishop
    vm.shortOutlets = $shortOutlets.slice();
    vm.isMultishop = vm.shortOutlets.length > 1;
    vm.shortOutlets.unshift({
        id: null,
        name: translate('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });

    $scope.selectOutlet = {id: $stateParams.outletId ? +$stateParams.outletId : null};

    vm.selectOutletId = $scope.selectOutlet.id;

    vm.noDataMessage = vm.selectOutletId || $shortOutlets.length === 1 ? 'PAYMENT_TYPES.NO_DATA_MESSAGE' : 'PAYMENT_TYPES.NO_DATA_MESSAGE_MULTIPLE_STORES';

    //-- BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    $scope.$watch('selectOutlet', function(newVal, oldVal){
        if (oldVal !== newVal) {
            $state.go('settings.paytypes', {outletId: newVal.id}).then(function() {
                vm.selectOutletId = newVal.id;
            });
        }
    }, true);
    //-- END BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    // Stores filter for multishop


    //-- Drag & Drop options
    vm.isManager = angular.copy($profile.isManager);
    vm.sortableOptions = {
        handle: '.grab-handle',
        animation: 200,
        forceFallback: true,
        // Do not allow a manager to change via CRM the order of payment types
        sort: !vm.isManager,
        onUpdate: function (event) {
            if (event.model && event.model.id && angular.isNumber(event.newIndex)) {
                let data = {};
                data.changedPaymentType = { id: event.model.id, newOrdering: event.newIndex + 1 }
                payTypesOperationsFactory.changePayTypesOrder(data).then(function (res) {
                    if (res.result === "ok") {
                        $state.go($state.current.name, {}, {reload: true});
                    }
                })
            }
        }
    };
    $scope.clickDragAndDrop = function ( editProfile ){
        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
        }
    }
    //-- END Drag & Drop options


    //there is a temporary "if" condition in this function since we need
    // now other payment types (except "VANTIV") to be disabled for editing
    function editPaymentTypes(paymentType) {

        if(paymentType.uuid)
            $state.go('paymenttypes.editpaymenttype', {
                uuid: paymentType.uuid
            });
        else
            $state.go('paymenttypes.editpaymenttype', {
                paymentTypeId: paymentType.id
            });
    }

    function registerVantivTerminal(paymentType) {
        $state.go('vantiv.registration', {paymentTypeId: paymentType.id});
    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all paytypes in list using checkbox.
     * These paytypes will be added and deleted from vm.selectedpaytypes array.
     */
    function changeAll() {
        if (vm.paymentTypes._select) {
            selectAll(true);
            vm.showDel = true;
            vm.selectedPaymentTypes = vm.paymentTypes;
        } else {
            selectAll(false);
            vm.showDel = false;
            vm.selectedPaymentTypes = [];
        }
    }

    /**
     * @ngdoc function
     * @description
     * Adds _select property to the vm.paytypes with value specified by param.
     * @param {boolean} param value of the _select property
     */
    function selectAll(param) {
        let i,
            l = vm.paymentTypes.length;

        if (vm.paymentTypes.length) {
            for (i = 0; i < l; i++) {
                if (vm.paymentTypes[i].method !== "CASH") {
                    vm.paymentTypes[i]._select = param;
                }
            }
        }
    }

    /**
     * @ngdoc function
     * @description
     * Adds to or removes from vm.selectedpaytypes array selected item and
     * controls showing/hiding of the delete button.
     * @param {object} paytype
     */
    function changePaymentType(paytype) {
        if (paytype._select) {
            vm.selectedPaymentTypes.push(paytype);
            vm.showDel = true;

            if (vm.selectedPaymentTypes.length === vm.paymentTypes.length) {
                vm.paymentTypes._select = true;
            }
        } else {
            vm.selectedPaymentTypes = vm.selectedPaymentTypes.filter(function (selectedPaytypes) {
                return paytype.id !== selectedPaytypes.id;
            });

            if (vm.paymentTypes._select) vm.paymentTypes._select = false;

            if (!vm.selectedPaymentTypes.length) vm.showDel = false;
        }
    }

    function onAdd() {
        return payTypesOperationsFactory.getPayTypes({})
            .then(function (res) {
                if (res.paymentTypes.length >= res.maxPaymentTypesCount) {
                    singleBtnDialoguePopUp(
                        'PAYMENT_TYPES.ADDING_IMPOSSIBLE',
                        'PAYMENT_TYPES.ONLY_TEN_PAYTYPES',
                        {maxCount: res.maxPaymentTypesCount}
                    );
                }
                else {
                    $state.go('paymenttypes.createpaymenttype');
                }
            })
    }

    /**
     * @ngdoc function
     * @description
     * Sends delete request to the server with Ids of selected outlets,
     * triggers  success information message and page reload.
     */
    function onDel() {
        let filteredIds = [];
        let vantiv;
        let poslink;
        let selectedPaymentTypesIds = vm.selectedPaymentTypes.map(function (paytype) {
            /*pick all payment type Ids besides "CASH" since we don't need it
             to participate in creation/editing/deletion operations.
              It's just the default payment type.*/
            if (paytype.method !== "CASH") {
                return paytype.id;
            }
        });

        selectedPaymentTypesIds.forEach(function (val) {
            if (val !== undefined) {
                filteredIds.push(val);
            }
        });


        /*In addition we need to check whether we have Vantiv payment type
         among a list for deletion, since we must make our user sure that
          he wants to delete this payment type indeed*/
        vm.selectedPaymentTypes.some(function (paytype) {
            if (paytype.method === "VANTIV") {
                vantiv = true;
            }
            if (paytype.method === "POSLINK") {
                poslink = true;
            }
        });

        if (vantiv) {
            confirmVantivDeletion();
        } if(poslink) {
            confirmPoslinkDeletion();
        } else {
            confirm();
        }

        function confirmPoslinkDeletion() {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: (filteredIds.length > 1) ? translate('PAYMENT_TYPES.DELETE_PAYMENTTYPES_TITLE') : translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE'),
                content: translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_POSLINK_TEXT'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: delOutlet
                    }
                ]
            });
        }

        function confirmVantivDeletion() {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: (filteredIds.length > 1) ? translate('PAYMENT_TYPES.DELETE_PAYMENTTYPES_TITLE') : translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE'),
                content: (filteredIds.length > 1) ? translate('PAYMENT_TYPES.DELETE_PAYMENTTYPES_AND_VANTIV_TEXT') : translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_VANTIV_TEXT'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: delOutlet
                    }
                ]
            });
        }

        function confirm() {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: (filteredIds.length > 1) ? translate('PAYMENT_TYPES.DELETE_PAYMENTTYPES_TITLE') : translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE'),
                content: (filteredIds.length > 1) ? translate('PAYMENT_TYPES.DELETE_PAYMENTTYPES_TEXT') : translate('PAYMENT_TYPES.DELETE_PAYMENTTYPE_TEXT'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: delOutlet
                    }
                ]
            });
        }

        function delOutlet(paytype) {
            payTypesOperationsFactory.deletePayType({
                paymentTypesIds: filteredIds
            })
                .then(function (res) {
                    if (res.result === "ok") {
                        let deleted = (filteredIds.length > 1) ? 'PAYMENT_TYPES.PAYMENT_TYPES_DELETED' : 'PAYMENT_TYPES.PAYMENT_TYPE_DELETED';
                        serviceToast.show(deleted, 'success');
                        refreshList();
                    } else if (res.result === "error") {
                        if (res.message === "cash_type_can_not_be_deleted") {
                            singleBtnDialoguePopUp(
                                'PAYMENT_TYPES.DELETE_PAYMENTTYPE_TITLE',
                                'PAYMENT_TYPES.DELETE_PAYMENTTYPE_IMPOSSIBLE', {name: '"' + paytype.method + '"'}
                            );
                        } else if (res.message === "payment_type_deleted") {
                            dialogs.listItemDeletedEarlier().then(refreshList);
                        } else {
                            serviceToast.show('SAVE_ERROR', 'error')
                        }
                    } else {
                        serviceToast.show('SAVE_ERROR', 'error')
                    }
                });

        }
    }

    function refreshList() {
        $state.go($state.current.name, {}, {reload: true});
    }

    function singleBtnDialoguePopUp(title, content, variable) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate(title),
            content: translate(content, variable),
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK'
                }
            ]
        })
    }
}

export default {
    type: 'controller',
    name: 'settingsPayTypes',
    value: settingsPayTypes
};
