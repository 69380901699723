/**
 * Created by Kiskenbassker on 25.12.17 for reusing suppliers autocomplete block
 */

import template from '../templates-pug/for-directive/suppliers-autocomplete.pug';

suppliersAutocompleteDirective.$inject = ['$timeout', '$q'];
function suppliersAutocompleteDirective($timeout, $q) {

    return {
        restrict: 'E',
        scope: {
            suppliers:'=',
            supplierChange: '='
        },
        template: template,
        link: linkFn
    };

    function linkFn(scope, element, attr, controller) {
        // console.log("Scope: ", scope, "Element: ", element, "Attr: ", attr, "Controller: ", controller);

        scope.suppliersLoad = searchValuesLoad(scope.suppliers);

        scope.suppliersSearchText = null;
        scope.suppliersSelectedItem = scope.$parent.suppliersSelectedItem ? scope.$parent.suppliersSelectedItem : null;

        scope.suppliersSearchQuery = function (query) {
            return query ? scope.suppliersLoad.filter(createFilterFor(query)) : scope.suppliersLoad;
        };

        function searchValuesLoad(arr) {
            return arr.map(function (arrField) {
                return {
                    value: arrField.name.toLowerCase(),
                    display: arrField.name,
                    id: arrField.id
                }
            })
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();

            return function filterFn(arrObj) {
                return (arrObj.value.indexOf(lowercaseQuery) === 0);
            };
        }

    }
}

export default {
    type: 'directive',
    name: 'suppliersAutocomplete',
    value: suppliersAutocompleteDirective
};