/***
 * @example
 * serviceToast.show( 'ERROR_EDIT', 'error' );
 */

import angular from 'angular';
import ngTranslate from 'angular-translate';

import serviceToast from './service-toast';

import lvToast from './directive-lv-toast';

export default angular
	.module( 'lv.toast', [ngTranslate])
	.service('serviceToast', serviceToast)
	.directive('lvToast', lvToast)
	.name;