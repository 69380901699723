/**
 * Created by mars on 9/4/15.
 *
 * английский, русский, испанский, французский, польский, румынский, украинский, корейский
 *
 *
 *

 */

const constantTimeZoneCollection = [
    {
        countryCode: "CI",
        timeZoneName: "Africa/Abidjan",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "GH",
        timeZoneName: "Africa/Accra",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "ET",
        timeZoneName: "Africa/Addis_Ababa",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "DZ",
        timeZoneName: "Africa/Algiers",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "ER",
        timeZoneName: "Africa/Asmara",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "ER",
        timeZoneName: "Africa/Asmera",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "ML",
        timeZoneName: "Africa/Bamako",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "CF",
        timeZoneName: "Africa/Bangui",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GM",
        timeZoneName: "Africa/Banjul",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "GW",
        timeZoneName: "Africa/Bissau",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "MW",
        timeZoneName: "Africa/Blantyre",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "CG",
        timeZoneName: "Africa/Brazzaville",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "BI",
        timeZoneName: "Africa/Bujumbura",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "EG",
        timeZoneName: "Africa/Cairo",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "MA",
        timeZoneName: "Africa/Casablanca",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "ES",
        timeZoneName: "Africa/Ceuta",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GN",
        timeZoneName: "Africa/Conakry",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "SN",
        timeZoneName: "Africa/Dakar",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "TZ",
        timeZoneName: "Africa/Dar_es_Salaam",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "DJ",
        timeZoneName: "Africa/Djibouti",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "CM",
        timeZoneName: "Africa/Douala",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "EH",
        timeZoneName: "Africa/El_Aaiun",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "SL",
        timeZoneName: "Africa/Freetown",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "BW",
        timeZoneName: "Africa/Gaborone",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "ZW",
        timeZoneName: "Africa/Harare",
        utcOffset: 2,
        startWeek: 0,
        locale: ["sn", "en-ZW"]
    },
    {
        countryCode: "ZA",
        timeZoneName: "Africa/Johannesburg",
        utcOffset: 2,
        startWeek: 0,
        locale: ["af", "af-ZA", "en-ZA"]
    },
    {
        countryCode: "SS",
        timeZoneName: "Africa/Juba",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "UG",
        timeZoneName: "Africa/Kampala",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "SD",
        timeZoneName: "Africa/Khartoum",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "RW",
        timeZoneName: "Africa/Kigali",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "CD",
        timeZoneName: "Africa/Kinshasa",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "NG",
        timeZoneName: "Africa/Lagos",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GA",
        timeZoneName: "Africa/Libreville",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "TG",
        timeZoneName: "Africa/Lome",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "AO",
        timeZoneName: "Africa/Luanda",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "CD",
        timeZoneName: "Africa/Lubumbashi",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "ZM",
        timeZoneName: "Africa/Lusaka",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "GQ",
        timeZoneName: "Africa/Malabo",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "MZ",
        timeZoneName: "Africa/Maputo",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "LS",
        timeZoneName: "Africa/Maseru",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "SZ",
        timeZoneName: "Africa/Mbabane",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "SO",
        timeZoneName: "Africa/Mogadishu",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "LR",
        timeZoneName: "Africa/Monrovia",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "KE",
        timeZoneName: "Africa/Nairobi",
        utcOffset: 3,
        startWeek: 0,
        locale: ["sw", "sw-KE"]
    },
    {
        countryCode: "TD",
        timeZoneName: "Africa/Ndjamena",
        utcOffset: 1
    },
    {
        countryCode: "NE",
        timeZoneName: "Africa/Niamey",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "MR",
        timeZoneName: "Africa/Nouakchott",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "BF",
        timeZoneName: "Africa/Ouagadougou",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "BJ",
        timeZoneName: "Africa/Porto-Novo",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "ST",
        timeZoneName: "Africa/Sao_Tome",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "ST",
        timeZoneName: "Africa/Timbuktu",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "LY",
        timeZoneName: "Africa/Tripoli",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "TN",
        timeZoneName: "Africa/Tunis",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "NA",
        timeZoneName: "Africa/Windhoek",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/Adak",
        utcOffset: -10,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Anchorage",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "AI",
        timeZoneName: "America/Anguilla",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "AG",
        timeZoneName: "America/Antigua",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Araguaina",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Buenos_Aires",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Catamarca",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/ComodRivadavia",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Cordoba",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Jujuy",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/La_Rioja",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Mendoza",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Rio_Gallegos",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Salta",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/San_Juan",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/San_Luis",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Tucuman",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AR",
        timeZoneName: "America/Argentina/Ushuaia",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-AR"]
    },
    {
        countryCode: "AW",
        timeZoneName: "America/Aruba",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "PY",
        timeZoneName: "America/Asuncion",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Atikokan",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Atka",
        utcOffset: -10,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Bahia",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Bahia_Banderas",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-MX", "es-419", "es-XL"]
    },
    {
        countryCode: "BB",
        timeZoneName: "America/Barbados",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Belem",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "BZ",
        timeZoneName: "America/Belize",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-BZ", "en-US"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Blanc-Sablon",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Boa_Vista",
        utcOffset: -4,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "CO",
        timeZoneName: "America/Bogota",
        utcOffset: -5,
        startWeek: 0,
        locale: ["es-CO", "es-419", "es-XL"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Boise",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Buenos_Aires",
        utcOffset: -3,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Cambridge_Bay",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Campo_Grande",
        utcOffset: -4,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Cancun",
        utcOffset: -5,
        startWeek: 0
    },
    {
        countryCode: "VE",
        timeZoneName: "America/Caracas",
        utcOffset: -4,
        startWeek: 0,
        locale: ["es-VE", "es-419", "es-XL"]
    },
    {
        countryCode: "VE",
        timeZoneName: "America/Catamarca",
        utcOffset: -3,
        startWeek: 0,
        locale: ["es-VE", "es-419", "es-XL"]
    },
    {
        countryCode: "GF",
        timeZoneName: "America/Cayenne",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "KY",
        timeZoneName: "America/Cayman",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/Chicago",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Chihuahua",
        utcOffset: -7,
        startWeek: 0
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Coral_Harbour",
        utcOffset: -5,
        startWeek: 0
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Cordoba",
        utcOffset: -3,
        startWeek: 0
    },
    {
        countryCode: "CR",
        timeZoneName: "America/Costa_Rica",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-CR", "es-419", "es-XL"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Creston",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Cuiaba",
        utcOffset: -4,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "CW",
        timeZoneName: "America/Curacao",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "GL",
        timeZoneName: "America/Danmarkshavn",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Dawson",
        utcOffset: -8,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Dawson_Creek",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Denver",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Detroit",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "DM",
        timeZoneName: "America/Dominica",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Edmonton",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Eirunepe",
        utcOffset: -5,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "SV",
        timeZoneName: "America/El_Salvador",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-SV", "es-419", "es-XL"]
    },
    {
        countryCode: "SV",
        timeZoneName: "America/Ensenada",
        utcOffset: -8,
        startWeek: 0,
        locale: ["es-SV", "es-419", "es-XL"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Fort_Nelson",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Fort_Wayne",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Fortaleza",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Glace_Bay",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "GL",
        timeZoneName: "America/Godthab",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Goose_Bay",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "TC",
        timeZoneName: "America/Grand_Turk",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "GD",
        timeZoneName: "America/Grenada",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "GP",
        timeZoneName: "America/Guadeloupe",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "GT",
        timeZoneName: "America/Guatemala",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-GT", "es-419", "es-XL"]
    },
    {
        countryCode: "EC",
        timeZoneName: "America/Guayaquil",
        utcOffset: -5,
        startWeek: 0,
        locale: ["es-EC", "es-419", "es-XL"]
    },
    {
        countryCode: "GY",
        timeZoneName: "America/Guyana",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Halifax",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CU",
        timeZoneName: "America/Havana",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Hermosillo",
        utcOffset: -7,
        startWeek: 0
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Indianapolis",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Knox",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Marengo",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Petersburg",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Tell_City",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Vevay",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Vincennes",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indiana/Winamac",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Indianapolis",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Inuvik",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Iqaluit",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "JM",
        timeZoneName: "America/Jamaica",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "JM",
        timeZoneName: "America/Jujuy",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/Juneau",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Kentucky/Louisville",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Kentucky/Monticello",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Knox_IN",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "BQ",
        timeZoneName: "America/Kralendijk",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "BO",
        timeZoneName: "America/La_Paz",
        utcOffset: -4,
        startWeek: 0,
        locale: ["es-BO", "es-419", "es-XL"]
    },
    {
        countryCode: "PE",
        timeZoneName: "America/Lima",
        utcOffset: -5,
        startWeek: 0,
        locale: ["es-PE", "es-419", "es-XL"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Los_Angeles",
        utcOffset: -8,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Louisville",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "SX",
        timeZoneName: "America/Lower_Princes",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Maceio",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "NI",
        timeZoneName: "America/Managua",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-NI", "es-419", "es-XL"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Manaus",
        utcOffset: -4,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "MF",
        timeZoneName: "America/Marigot",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "MQ",
        timeZoneName: "America/Martinique",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Matamoros",
        utcOffset: -6,
        startWeek: 0
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Mazatlan",
        utcOffset: -7,
        startWeek: 0
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Mendoza",
        utcOffset: -3,
        startWeek: 0
    },
    {
        countryCode: "US",
        timeZoneName: "America/Menominee",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Merida",
        utcOffset: -6,
        startWeek: 0
    },
    {
        countryCode: "US",
        timeZoneName: "America/Metlakatla",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Mexico_City",
        utcOffset: -6,
        startWeek: 0
    },
    {
        countryCode: "PM",
        timeZoneName: "America/Miquelon",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Moncton",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Monterrey",
        utcOffset: -6,
        startWeek: 0
    },
    {
        countryCode: "UY",
        timeZoneName: "America/Montevideo",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "UY",
        timeZoneName: "America/Montreal",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "MS",
        timeZoneName: "America/Montserrat",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "BS",
        timeZoneName: "America/Nassau",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/New_York",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Nipigon",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Nome",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Noronha",
        utcOffset: -2,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/North_Dakota/Beulah",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/North_Dakota/Center",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/North_Dakota/New_Salem",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Ojinaga",
        utcOffset: -7,
        startWeek: 0
    },
    {
        countryCode: "PA",
        timeZoneName: "America/Panama",
        utcOffset: -5,
        startWeek: 0,
        locale: ["es-PA", "es-419", "es-XL"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Pangnirtung",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "SR",
        timeZoneName: "America/Paramaribo",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/Phoenix",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "TT",
        timeZoneName: "America/Port_of_Spain",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "HT",
        timeZoneName: "America/Port-au-Prince",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "HT",
        timeZoneName: "America/Porto_Acre",
        utcOffset: -5,
        startWeek: 1
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Porto_Velho",
        utcOffset: -4,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "PR",
        timeZoneName: "America/Puerto_Rico",
        utcOffset: -4,
        startWeek: 0,
        locale: ["es-PR", "es-419", "es-XL"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Rainy_River",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Rankin_Inlet",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Recife",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Regina",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Resolute",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Rio_Branco",
        utcOffset: -5,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Rosario",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Santa_Isabel",
        utcOffset: -8,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Santarem",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "CL",
        timeZoneName: "America/Santiago",
        utcOffset: -4,
        startWeek: 0,
        locale: ["es-CL", "es-419", "es-XL"]
    },
    {
        countryCode: "DO",
        timeZoneName: "America/Santo_Domingo",
        utcOffset: -4,
        startWeek: 0,
        locale: ["es-DO", "es-419", "es-XL"]
    },
    {
        countryCode: "BR",
        timeZoneName: "America/Sao_Paulo",
        utcOffset: -3,
        startWeek: 0,
        locale: ["pt-BR"]
    },
    {
        countryCode: "GL",
        timeZoneName: "America/Scoresbysund",
        utcOffset: -1,
        startWeek: 1
    },
    {
        countryCode: "GL",
        timeZoneName: "America/Shiprock",
        utcOffset: -7,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "America/Sitka",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "BL",
        timeZoneName: "America/St_Barthelemy",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "KN",
        timeZoneName: "America/St_Kitts",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "LC",
        timeZoneName: "America/St_Lucia",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "VI",
        timeZoneName: "America/St_Thomas",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "VC",
        timeZoneName: "America/St_Vincent",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Swift_Current",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "HN",
        timeZoneName: "America/Tegucigalpa",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-HN", "es-419", "es-XL"]
    },
    {
        countryCode: "GL",
        timeZoneName: "America/Thule",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Thunder_Bay",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "MX",
        timeZoneName: "America/Tijuana",
        utcOffset: -8,
        startWeek: 0
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Toronto",
        utcOffset: -5,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "VG",
        timeZoneName: "America/Tortola",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Vancouver",
        utcOffset: -8,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Virgin",
        utcOffset: -4,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Whitehorse",
        utcOffset: -8,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Winnipeg",
        utcOffset: -6,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "US",
        timeZoneName: "America/Yakutat",
        utcOffset: -9,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "CA",
        timeZoneName: "America/Yellowknife",
        utcOffset: -7,
        startWeek: 0,
        locale: ["en-CA"]
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Casey",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Davis",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/DumontDUrville",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "AU",
        timeZoneName: "Antarctica/Macquarie",
        utcOffset: 11,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Mawson",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/McMurdo",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Palmer",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Rothera",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/South_Pole",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Syowa",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Troll",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "AQ",
        timeZoneName: "Antarctica/Vostok",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "SJ",
        timeZoneName: "Arctic/Longyearbyen",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "YE",
        timeZoneName: "Asia/Aden",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "KZ",
        timeZoneName: "Asia/Almaty",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "JO",
        timeZoneName: "Asia/Amman",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Moscow",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Anadyr",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "KZ",
        timeZoneName: "Asia/Aqtau",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "KZ",
        timeZoneName: "Asia/Aqtobe",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "TM",
        timeZoneName: "Asia/Ashgabat",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "TM",
        timeZoneName: "Asia/Ashkhabad",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "IQ",
        timeZoneName: "Asia/Baghdad",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "BH",
        timeZoneName: "Asia/Bahrain",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "AZ",
        timeZoneName: "Asia/Baku",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "TH",
        timeZoneName: "Asia/Bangkok",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Barnaul",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "LB",
        timeZoneName: "Asia/Beirut",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "KG",
        timeZoneName: "Asia/Bishkek",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "BN",
        timeZoneName: "Asia/Brunei",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Chita",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "MN",
        timeZoneName: "Asia/Choibalsan",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "MN",
        timeZoneName: "Asia/Chongqing",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "MN",
        timeZoneName: "Asia/Chungking",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "LK",
        timeZoneName: "Asia/Dacca",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "SY",
        timeZoneName: "Asia/Damascus",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "BD",
        timeZoneName: "Asia/Dhaka",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "TL",
        timeZoneName: "Asia/Dili",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "AE",
        timeZoneName: "Asia/Dubai",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "TJ",
        timeZoneName: "Asia/Dushanbe",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "PS",
        timeZoneName: "Asia/Gaza",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "PS",
        timeZoneName: "Asia/Harbin",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "PS",
        timeZoneName: "Asia/Hebron",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "VN",
        timeZoneName: "Asia/Ho_Chi_Minh",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "HK",
        timeZoneName: "Asia/Hong_Kong",
        utcOffset: 8,
        startWeek: 0,
        locale: ["zh-HK"]
    },
    {
        countryCode: "MN",
        timeZoneName: "Asia/Hovd",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Irkutsk",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: 0,
        timeZoneName: "Asia/Istanbul",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "ID",
        timeZoneName: "Asia/Jakarta",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "ID",
        timeZoneName: "Asia/Jayapura",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "IL",
        timeZoneName: "Asia/Jerusalem",
        utcOffset: 2,
        startWeek: 0,
        locale: ["he", "he-IL"]
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Kamchatka",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "PK",
        timeZoneName: "Asia/Karachi",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "PK",
        timeZoneName: "Asia/Kashgar",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Khandyga",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Krasnoyarsk",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "MY",
        timeZoneName: "Asia/Kuala_Lumpur",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "MY",
        timeZoneName: "Asia/Kuching",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "KW",
        timeZoneName: "Asia/Kuwait",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "MO",
        timeZoneName: "Asia/Macao",
        utcOffset: 8,
        startWeek: 0,
        locale: ["zh-MO"]
    },
    {
        countryCode: "MO",
        timeZoneName: "Asia/Macau",
        utcOffset: 8,
        startWeek: 1,
        locale: ["zh-MO"]
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Magadan",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "ID",
        timeZoneName: "Asia/Makassar",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "PH",
        timeZoneName: "Asia/Manila",
        utcOffset: 8,
        startWeek: 0,
        locale: ["fil", "en-PH"]
    },
    {
        countryCode: "OM",
        timeZoneName: "Asia/Muscat",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "CY",
        timeZoneName: "Asia/Nicosia",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Novokuznetsk",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Novosibirsk",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Omsk",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "KZ",
        timeZoneName: "Asia/Oral",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "KH",
        timeZoneName: "Asia/Phnom_Penh",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "ID",
        timeZoneName: "Asia/Pontianak",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "QA",
        timeZoneName: "Asia/Qatar",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "KZ",
        timeZoneName: "Asia/Qyzylorda",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "SA",
        timeZoneName: "Asia/Riyadh",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "SA",
        timeZoneName: "Asia/Saigon",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Sakhalin",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "UZ",
        timeZoneName: "Asia/Samarkand",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "KR",
        timeZoneName: "Asia/Seoul",
        utcOffset: 9,
        startWeek: 0,
        locale: ["ko", "ko-KR"]
    },
    {
        countryCode: "CN",
        timeZoneName: "Asia/Shanghai",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "SG",
        timeZoneName: "Asia/Singapore",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Srednekolymsk",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "TW",
        timeZoneName: "Asia/Taipei",
        utcOffset: 8,
        startWeek: 0,
        locale: ["zh-TW"]
    },
    {
        countryCode: "UZ",
        timeZoneName: "Asia/Tashkent",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "GE",
        timeZoneName: "Asia/Tbilisi",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "IR",
        timeZoneName: "Asia/Tel_Aviv",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "IR",
        timeZoneName: "Asia/Thimbu",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "BT",
        timeZoneName: "Asia/Thimphu",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "JP",
        timeZoneName: "Asia/Tokyo",
        utcOffset: 9,
        startWeek: 0,
        locale: ["ja", "ja-JP"]
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Tomsk",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "IR",
        timeZoneName: "Asia/Ujung_Pandang",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "MN",
        timeZoneName: "Asia/Ulaanbaatar",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "IR",
        timeZoneName: "Asia/Ulan_Bator",
        utcOffset: 8,
        startWeek: 1
    },
    {
        countryCode: "CN",
        timeZoneName: "Asia/Urumqi",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Ust-Nera",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "LA",
        timeZoneName: "Asia/Vientiane",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Vladivostok",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Yakutsk",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Asia/Yekaterinburg",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "AM",
        timeZoneName: "Asia/Yerevan",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "PT",
        timeZoneName: "Atlantic/Azores",
        utcOffset: -1,
        startWeek: 1
    },
    {
        countryCode: "BM",
        timeZoneName: "Atlantic/Bermuda",
        utcOffset: -4,
        startWeek: 1
    },
    {
        countryCode: "ES",
        timeZoneName: "Atlantic/Canary",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "CV",
        timeZoneName: "Atlantic/Cape_Verde",
        utcOffset: -1,
        startWeek: 1
    },
    {
        countryCode: "CV",
        timeZoneName: "Atlantic/Faeroe",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "FO",
        timeZoneName: "Atlantic/Faroe",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "FO",
        timeZoneName: "Atlantic/Jan_Mayen",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "PT",
        timeZoneName: "Atlantic/Madeira",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "IS",
        timeZoneName: "Atlantic/Reykjavik",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "GS",
        timeZoneName: "Atlantic/South_Georgia",
        utcOffset: -2,
        startWeek: 1
    },
    {
        countryCode: "SH",
        timeZoneName: "Atlantic/St_Helena",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "FK",
        timeZoneName: "Atlantic/Stanley",
        utcOffset: -3,
        startWeek: 1
    },
    {
        countryCode: "FK",
        timeZoneName: "Australia/ACT",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Brisbane",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Canberra",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Currie",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Hobart",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Lindeman",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Melbourne",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/NSW",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Perth",
        utcOffset: 8,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Queensland",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Sydney",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Tasmania",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/Victoria",
        utcOffset: 10,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "AU",
        timeZoneName: "Australia/West",
        utcOffset: 8,
        startWeek: 0,
        locale: ["en-AU"]
    },
    {
        countryCode: "NL",
        timeZoneName: "Europe/Amsterdam",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "AD",
        timeZoneName: "Europe/Andorra",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Astrakhan",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "GR",
        timeZoneName: "Europe/Athens",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "GR",
        timeZoneName: "Europe/Belfast",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "RS",
        timeZoneName: "Europe/Belgrade",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "DE",
        timeZoneName: "Europe/Berlin",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "SK",
        timeZoneName: "Europe/Bratislava",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "BE",
        timeZoneName: "Europe/Brussels",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "RO",
        timeZoneName: "Europe/Bucharest",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "HU",
        timeZoneName: "Europe/Budapest",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "DE",
        timeZoneName: "Europe/Busingen",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "MD",
        timeZoneName: "Europe/Chisinau",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "DK",
        timeZoneName: "Europe/Copenhagen",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "IE",
        timeZoneName: "Europe/Dublin",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "GI",
        timeZoneName: "Europe/Gibraltar",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GG",
        timeZoneName: "Europe/Guernsey",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "FI",
        timeZoneName: "Europe/Helsinki",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "IM",
        timeZoneName: "Europe/Isle_of_Man",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "TR",
        timeZoneName: "Europe/Istanbul",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "JE",
        timeZoneName: "Europe/Jersey",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Kaliningrad",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "UA",
        timeZoneName: "Europe/Kiev",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Kirov",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "PT",
        timeZoneName: "Europe/Lisbon",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "SI",
        timeZoneName: "Europe/Ljubljana",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GB",
        timeZoneName: "Europe/London",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "LU",
        timeZoneName: "Europe/Luxembourg",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "ES",
        timeZoneName: "Europe/Madrid",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "MT",
        timeZoneName: "Europe/Malta",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "AX",
        timeZoneName: "Europe/Mariehamn",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "BY",
        timeZoneName: "Europe/Minsk",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "MC",
        timeZoneName: "Europe/Monaco",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "NO",
        timeZoneName: "Europe/Oslo",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "FR",
        timeZoneName: "Europe/Paris",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "ME",
        timeZoneName: "Europe/Podgorica",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "CZ",
        timeZoneName: "Europe/Prague",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "LV",
        timeZoneName: "Europe/Riga",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "IT",
        timeZoneName: "Europe/Rome",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Samara",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "SM",
        timeZoneName: "Europe/San_Marino",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "BA",
        timeZoneName: "Europe/Sarajevo",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Simferopol",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "MK",
        timeZoneName: "Europe/Skopje",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "BG",
        timeZoneName: "Europe/Sofia",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "SE",
        timeZoneName: "Europe/Stockholm",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "EE",
        timeZoneName: "Europe/Tallinn",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "AL",
        timeZoneName: "Europe/Tirane",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "MD",
        timeZoneName: "Europe/Tiraspol",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Ulyanovsk",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "UA",
        timeZoneName: "Europe/Uzhgorod",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "LI",
        timeZoneName: "Europe/Vaduz",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "VA",
        timeZoneName: "Europe/Vatican",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "AT",
        timeZoneName: "Europe/Vienna",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "LT",
        timeZoneName: "Europe/Vilnius",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "RU",
        timeZoneName: "Europe/Volgograd",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "PL",
        timeZoneName: "Europe/Warsaw",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "HR",
        timeZoneName: "Europe/Zagreb",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "UA",
        timeZoneName: "Europe/Zaporozhye",
        utcOffset: 2,
        startWeek: 1
    },
    {
        countryCode: "CH",
        timeZoneName: "Europe/Zurich",
        utcOffset: 1,
        startWeek: 1
    },
    {
        countryCode: "GB",
        timeZoneName: "GB",
        utcOffset: 0,
        startWeek: 1
    },
    {
        countryCode: "MG",
        timeZoneName: "Indian/Antananarivo",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "IO",
        timeZoneName: "Indian/Chagos",
        utcOffset: 6,
        startWeek: 1
    },
    {
        countryCode: "CX",
        timeZoneName: "Indian/Christmas",
        utcOffset: 7,
        startWeek: 1
    },
    {
        countryCode: "KM",
        timeZoneName: "Indian/Comoro",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "TF",
        timeZoneName: "Indian/Kerguelen",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "SC",
        timeZoneName: "Indian/Mahe",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "MV",
        timeZoneName: "Indian/Maldives",
        utcOffset: 5,
        startWeek: 1
    },
    {
        countryCode: "MU",
        timeZoneName: "Indian/Mauritius",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "YT",
        timeZoneName: "Indian/Mayotte",
        utcOffset: 3,
        startWeek: 1
    },
    {
        countryCode: "RE",
        timeZoneName: "Indian/Reunion",
        utcOffset: 4,
        startWeek: 1
    },
    {
        countryCode: "NZ",
        timeZoneName: "Pacific/Auckland",
        utcOffset: 12,
        startWeek: 0,
        locale: ["en-NZ"]
    },
    {
        countryCode: "PG",
        timeZoneName: "Pacific/Bougainville",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "FM",
        timeZoneName: "Pacific/Chuuk",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "CL",
        timeZoneName: "Pacific/Easter",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-CL", "es-419", "es-XL"]
    },
    {
        countryCode: "VU",
        timeZoneName: "Pacific/Efate",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "FJ",
        timeZoneName: "Pacific/Fiji",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "TV",
        timeZoneName: "Pacific/Funafuti",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "EC",
        timeZoneName: "Pacific/Galapagos",
        utcOffset: -6,
        startWeek: 0,
        locale: ["es-EC", "es-419", "es-XL"]
    },
    {
        countryCode: "PF",
        timeZoneName: "Pacific/Gambier",
        utcOffset: -9,
        startWeek: 1
    },
    {
        countryCode: "SB",
        timeZoneName: "Pacific/Guadalcanal",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "GU",
        timeZoneName: "Pacific/Guam",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "US",
        timeZoneName: "Pacific/Honolulu",
        utcOffset: -10,
        startWeek: 0,
        locale: ["en-US"]
    },
    {
        countryCode: "UM",
        timeZoneName: "Pacific/Johnston",
        utcOffset: -10,
        startWeek: 1
    },
    {
        countryCode: "FM",
        timeZoneName: "Pacific/Kosrae",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "MH",
        timeZoneName: "Pacific/Kwajalein",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "MH",
        timeZoneName: "Pacific/Majuro",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "UM",
        timeZoneName: "Pacific/Midway",
        utcOffset: -11,
        startWeek: 1
    },
    {
        countryCode: "NR",
        timeZoneName: "Pacific/Nauru",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "NU",
        timeZoneName: "Pacific/Niue",
        utcOffset: -11,
        startWeek: 1
    },
    {
        countryCode: "NF",
        timeZoneName: "Pacific/Norfolk",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "NC",
        timeZoneName: "Pacific/Noumea",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "AS",
        timeZoneName: "Pacific/Pago_Pago",
        utcOffset: -11,
        startWeek: 1
    },
    {
        countryCode: "PW",
        timeZoneName: "Pacific/Palau",
        utcOffset: 9,
        startWeek: 1
    },
    {
        countryCode: "PN",
        timeZoneName: "Pacific/Pitcairn",
        utcOffset: -8,
        startWeek: 1
    },
    {
        countryCode: "FM",
        timeZoneName: "Pacific/Pohnpei",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "FM",
        timeZoneName: "Pacific/Ponape",
        utcOffset: 11,
        startWeek: 1
    },
    {
        countryCode: "PG",
        timeZoneName: "Pacific/Port_Moresby",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "CK",
        timeZoneName: "Pacific/Rarotonga",
        utcOffset: -10,
        startWeek: 1
    },
    {
        countryCode: "MP",
        timeZoneName: "Pacific/Saipan",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "MP",
        timeZoneName: "Pacific/Samoa",
        utcOffset: -11,
        startWeek: 1
    },
    {
        countryCode: "PF",
        timeZoneName: "Pacific/Tahiti",
        utcOffset: -10,
        startWeek: 1
    },
    {
        countryCode: "KI",
        timeZoneName: "Pacific/Tarawa",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "TO",
        timeZoneName: "Pacific/Truk",
        utcOffset: 10
    },
    {
        countryCode: "UM",
        timeZoneName: "Pacific/Wake",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "WF",
        timeZoneName: "Pacific/Wallis",
        utcOffset: 12,
        startWeek: 1
    },
    {
        countryCode: "WF",
        timeZoneName: "Pacific/Yap",
        utcOffset: 10,
        startWeek: 1
    },
    {
        countryCode: "IN",
        timeZoneName: "",
        utcOffset: null,
        startWeek: 0,
        locale: ["hi", "hi-IN", "en-IN"]
    }
];

export default {
    type: 'constant',
    name: 'constantTimeZoneCollection',
    value: constantTimeZoneCollection
};