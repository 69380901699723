/**
 * Created by mars on 12/13/15.
 */

lvMenuClass.$inject = ['serviceMenu', '$state'];

function lvMenuClass(serviceMenu, $state){
	return{
		restrict: 'A',
		scope: {
			lvMenuClass: '='
		},
		link: function(scope, el, attr){
			scope.$on('$stateChangeSuccess',  function ( event, toState, toParams, fromState, fromParams ) {
				if($state.includes(scope.lvMenuClass.split('.')[0])){
					el.addClass('active')
				}else{
					el.removeClass('active')
				}
			});
			scope.$parent.$watch(function(){
				return serviceMenu.navBoard
			}, function(val){
				if($state.includes(scope.lvMenuClass.split('.')[0])){
					el.addClass('active')
				}else{
					el.removeClass('active')
				}
			})
		}
	}
}

export default {
	type: 'directive',
	name: 'lvMenuClass',
	value: lvMenuClass
};