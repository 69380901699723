import angular from 'angular';
import datePickerLabelTemplate from '../templates-pug/date-picker-label.pug';
import datePickerViewTemplate from '../templates-pug/date-picker-view.pug';

datePicker.$inject = ['$compile', '$templateFactory'];

function datePicker($compile ,$templateFactory){
	return {
		restrict: 'EA',
		replace: true,
		template: datePickerLabelTemplate,
		scope: {
			before: '=before',
			after: '=after',
			link: '@link',
			viewMonths: '@viewMonths',
			formatDate: '@formatDate',
			maxDate: '=',
			minDate: '=',
			startWeek: '@',
			endWeek: '@',
			exact: '=',
			lock: '=',
			applyAction: '=',
		},
		controller: 'datePickerContrl',
		link: function ( $scope, $element ) {
			$scope.name = 'datePicker';
			var content = null;
			$scope.show = false;
			$scope.lock = eval( $scope.lock );
			$scope.elParent = $element;
			var body = document.querySelector('body');
			$scope.aranav = body.classList.contains('aranav');

			// if(window.innerWidth<600){
			$scope.viewMonths = 1;
			// }

			// console.log($scope.startWeek);

			function init() {
				let linkFn = $compile(datePickerViewTemplate);
				$scope.content = content = linkFn( $scope );
				content.css( 'display', 'none' );
				content.css( 'visibility', 'hidden' );

				var body = angular.element( document.body );
				body.append( content );

				$scope.calendarElement = content;
				$scope.show = true;

				// TODO: Oleksii: use template instead templateFactory
				// var linkFn = null;
				// $templateFactory.fromUrl('../module/lv-date-picker/templates/date-picker-view.html' ).then(function(d){
				// 	linkFn = $compile( d );
				// 	$scope.content = content = linkFn( $scope );
				// 	content.css( 'display', 'none' );
				// 	content.css( 'visibility', 'hidden' );
				//
				// 	var body = angular.element( document.body );
				// 	body.append( content );
				//
				// 	$scope.calendarElement = content;
				// 	$scope.show = true;
				//
				// });

			}
			$scope.click = function () {
				if ( $scope.lock === true ) return;
				if ( !content ) {
					init();
					return;
				}
				$scope.show = !$scope.show;

			};
		}
	};
}

export default datePicker;