appsList.$inject = [
    '$appsList',
    '$scope',
    'lvMixpanel',
    '$environment'
];

function appsList(
    $appsList,
    $scope,
    lvMixpanel,
    $environment
) {
    var vm = this;
    vm.appsList = $appsList.apps;
    $scope.editTableState = 'integrations.appDetails';

    vm.href = $environment && $environment.site
        ? $environment.site + "/marketplace"
        : "https://dev.loyverse.com/marketplace"

    vm.goToAppMarketplace = function () {
        //  Triggered when users click on the “Go to app marketplace”
        lvMixpanel.track('Other activity', {
            "Property": "Integrations/Go to app marketplace"
        });
    }

}

export default {
    type: 'controller',
    name: 'appsList',
    value: appsList
};