/**
 * Created by Alex on 15.12.2016.
 * @memberof secondsToHours
 * @ngdoc filter
 * @name secondsToHours
 */

function secondsToHours() {
    function format(val, delimiter) {

            function decimalAdjust(type, value, exp) {
                // Если степень не определена, либо равна нулю...
                if (typeof exp === 'undefined' || +exp === 0) {
                  return Math[type](value);
                }
                value = +value;
                exp = +exp;
                // Если значение не является числом, либо степень не является целым числом...
                if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                  return NaN;
                }
                // Сдвиг разрядов
                value = value.toString().split('e');
                value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
                // Обратный сдвиг
                value = value.toString().split('e');
                return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
            }

            if (!Math.round10) {
                Math.round10 = function(value, exp) {
                  return decimalAdjust('round', value, exp);
                };
            }


        // console.log("Math.round10(55.549, -1) = ", Math.round10(55.549, -2));

        if (val !== undefined && val !== null) {
            var result;
            result = Math.trunc(val/60000)/60;
            if (result < 0.01) {
                result = 0.00;
            }

            result = Math.round10(result, -2);
            // console.log("result = ", result);
            result = result.toString();
            result = result.replace(/[.]/g, delimiter);

            return result;
        }
    }

    return format;

}

export default {
    type: 'filter',
    name: 'secondsToHours',
    value: secondsToHours
};