/**
 * Created by olehkss on 29.03.16.
 *
 * @ngdoc directive
 * @name app.directive:tableFooter
 * @restrict E
 * @scope
 * @param {number} pages total number of pages
 * @requires $state
 * @requires $stateParams
 * @requires stateGo
 * @description
 * Adds navigation bar at the table footer based on state, stateParams and total number of pages
 * @example
 * <table-footer pages = 'NUMBER_OF_PAGES'><table-footer>
 */

import template from '../templates-pug/for-directive/table-footer.pug';

tableFooterDirective.$inject = [];
function tableFooterDirective() {
    return {
        restrict: 'E',
        bindToController: true,
        controller: tableFooterController,
        controllerAs: 'vmTF',
        template: template,
        scope: {
            pages: '='
        }
    };
}

tableFooterController.$inject = ['$state', '$stateParams', 'stateGo', 'lvMixpanel'];
function tableFooterController($state, $stateParams, stateGo, lvMixpanel) {

    var vm = this;

    vm.stateGo = stateGo;

    vm.currentPage = $stateParams.page + 1;

    vm.nextPage = nextPage;
    vm.prevPage = prevPage;
    vm.goToPage = goToPage;
    vm.pageValidator = pageValidator;
    vm.excessiveState = excessiveState();


    function nextPage() {
        // console.log("$stateParams.checkFromList = ", $stateParams.checkFromList);
        ($stateParams.checkFromList)? $stateParams.checkFromList = {} : "";

        if (vm.currentPage === vm.pages || vm.pages === 0) {
            return false;
        }
        $stateParams.page++;
        vm.currentPage++;

        vm.stateGo.go($state, $stateParams, true);
    }

    function prevPage() {
        // console.log("$stateParams.checkFromList = ", $stateParams.checkFromList);
        ($stateParams.checkFromList)? $stateParams.checkFromList = {} : "";

        if (vm.currentPage === 1) {
            return false;
        }
        if (vm.currentPage > 1) {
            $stateParams.page--;
            vm.currentPage--;
            if ($stateParams.page < 0 || vm.currentPage < 1) { $stateParams.page = 0; vm.currentPage = 1; }
            vm.stateGo.go($state, $stateParams, true);
        }
    }

    function goToPage() {
        ($stateParams.checkFromList)? $stateParams.checkFromList = {} : "";
        if ( vm.currentPage < 1) {
            vm.currentPage = 1;
        }

        $stateParams.page = vm.currentPage - 1;
        vm.stateGo.go($state, $stateParams, true);
    }

    function pageValidator() {
        if ( vm.currentPage > vm.pages) {
            vm.currentPage = vm.pages;
        }
    }

    function excessiveState() {
        if( $state.current.name == 'goods.modifiers-list' ||
            $state.current.name == 'goods.discountlist' ||
            $state.current.name == 'employees.permitslist'){
            return true;
        }
    }



//* код для выбора кол-ва отображаемых строк
    vm.limits = [10, 25, 50, 100];
    vm.selectedLimit = $stateParams.limit;
    vm.clicked = false;

    vm.editSelectedLimit = function(limit) {
        vm.clicked = true;
        // vm.selectedLimit = limit;
        // // console.log("vm.selectedLimit = ", vm.selectedLimit);
        // $stateParams.limit = vm.selectedLimit;
        $stateParams.limit = limit;
        lvMixpanel.track('Filter', {"Property": "Select number of rows in table"});
        vm.stateGo.go($state, $stateParams, true);
    };
//* код для выбора кол-ва отображаемых строк



}

export default {
    type: 'directive',
    name: 'tableFooter',
    value: tableFooterDirective
};
