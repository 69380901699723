/**
 * Created by Shestakov on 29.05.21
 *
 * @ngdoc directive
 * @name app.directive:messageWarning
 * @restrict E
 *
 * @description
 * Directive for warning message over content
 *
 * @example
 * <message-warning></message-warning>
 */

import template from '../templates-pug/for-directive/message-warning.pug';

messageWarning.$inject = [];
function messageWarning() {
    return {
        restrict: 'E',
        template: template,
        scope: {
            message: "@",
            isRtl: '@'
        },
        link: link
    };

    function link(scope) {
        scope.arapro = scope.isRtl === "true";

        scope.$watch('isRtl', (newVal, oldVal) => {
            if (newVal !== oldVal) scope.arapro = newVal === "true";
        })
    }
}

export default {
    type: 'directive',
    name: 'messageWarning',
    value: messageWarning
};
