tableViewRowCellDirective.$inject = [];
function tableViewRowCellDirective() {
    return {
        restrict: 'E',
        controller: TableViewRowCellController,
        controllerAs: 'ctrl',
        bindToController: true,
        require: {
            tableViewCtrl: '?^^lvTableView'
        },
        scope: {
            item: '<lvItem',
            itemIndex: '<lvItemIndex',
            column: '<lvColumn'
        }
    }
}

TableViewRowCellController.$inject = ['$scope', '$element', '$mdUtil', '$compile'];
function TableViewRowCellController($scope, $element, $mdUtil, $compile) {
    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onDestroy = $onDestroy;

    let unsubscribe = null;
    let self = this;

    function $onInit() {
        $element.addClass('lv-table-view__row-cell');
        if (this.tableViewCtrl) {
            unsubscribe = this.tableViewCtrl.addResizeListener(updateWidth);
        }
    }

    function $postLink() {

        if (this.column.transcludeFn) {
            this.column.transcludeFn((clone, cloneScope) => {
                $element.append(clone);
                cloneScope.item = this.item;
            });
        } else {
            let cellElement = this.column.getElement();
            $element.append(cellElement);
            const style = this.column.getStyle();
            $element.css(style);

            if (this.column.options.noShrink) {
                $element.addClass('lv-table-view__row-cell--no-shrink');
            }

            $compile(cellElement)($scope);
        }
    }

    function $onDestroy() {
        if (unsubscribe) {
            unsubscribe();
            unsubscribe = null;
        }
    }

    function updateWidth() {
        const width = self.column.getSize().width + 'px';
        $element.css('width', width);
        $element.css('min-width', width);
    }
}

export default {
    type: 'directive',
    name: 'lvTableViewRowCell',
    value: tableViewRowCellDirective
};