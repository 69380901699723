/**
 * Created by sirosh on 26.04.16.
 * @memberof lvPlural
 * @ngdoc filter
 * @name lvPlural
 * @returns {String} - pluralize string with translation
 */

function lvPlural() {

    return format;

    /**
     * Pluralize numbers
     * @param {Number} val - number like 1,2, 121, 112, 11...
     * @param {Object} data - Object with text depends on pattern
     * @returns {String} output - result string
     */
    function format(val, data) {

        var PointFlag = val % 10;
        var output;

        // configure number 1
        if (val === 1) {
            output = data.one;
        } // 11, 21 ...
        else if (PointFlag === 1 && val !== 11) {
            output = data.first;
        } // 22, 23 ...
        else if ((PointFlag === 2 || PointFlag === 3 || PointFlag === 4) && (val !== 12 && val !== 13 && val !== 14)) {
            output = data.second;
        } // 17, 10, 777 ...
        else {
            output = data.third;
        }

        return output;

    }
}

export default {
    type: 'filter',
    name: 'lvPlural',
    value: lvPlural
};