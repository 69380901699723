const paymentRound = {
    intervalsWithCoins: [
        {
            int: 1
        },
        {
            int: 5
        },
        {
            int: 10
        },
        {
            int: 50
        },
        {
            int: 100
    }],

    roundingRuleConst:[
        {
            rule: 'NEAREST'
        },
        {
            rule: 'MIDDLE_UP'
        },
        {
            rule: 'MIDDLE_DOWN'
        },
        {
            rule: 'ALWAYS_UP'
        },
        {
            rule: 'ALWAYS_DOWN'
        }
    ]
};

export default {
    type: 'constant',
    name: 'paymentRound',
    value: paymentRound
}
