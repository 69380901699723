/**
 * Created by K.Yarosh on 26.04.2017.
 * @description Letting to pick up from timestamp the number of a week and quarter stateParams
 * @memberof
 * @ngdoc service
 * @name DateCustomMethods
 */

import angular from 'angular';

dateCustomMethods.$inject = ['$rootScope', '$filter', 'arabToDigits', 'retrieveLangCode', 'langCode'];
function dateCustomMethods($rootScope, $filter, arabToDigits, retrieveLangCode, langCode) {
    var currentAfterDateString,
        currentBeforeDateString;
    var indexOfDay;
    var indexOfMonth;
    var indexOfYear;
    var dd;
    var MM;
    var yyyy;
    var year;
    var month;
    var day;

    Date.prototype.getWeek = function() {
        var date = new Date(this.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    };


    this.getWeek = function(timestamp)
    {
        // console.log(timestamp.getWeek());
        return timestamp.getWeek();
    };

    this.getQuarter = function(timestamp)
    {
        // console.log(Math.floor((timestamp.getMonth() + 3) / 3));
        return Math.floor((timestamp.getMonth() + 3) / 3);
    };

    this.saveAfterDateString = function(dateString){
        currentAfterDateString = new Date(dateString);
        // return currentTimestamp;
    }

    this.saveBeforeDateString = function(dateString){
        currentBeforeDateString = new Date(dateString);
        // return currentTimestamp;
    }

    this.getAfterDateString = function(){
        // console.log(currentAfterDateString);
        return new Date(currentAfterDateString.getTime());
    }

    this.getBeforeDateString = function(){
        // console.log(currentBeforeDateString);
        return new Date(currentBeforeDateString.getTime());
    }

    this.convertTimeStringToMilliseconds = function(val, timeFormatSuffix) {

        var is12HourFormat = retrieveLangCode.is12HourFormat();
        if(val !== null && val !== ""){
            var converted,
                timeParticles;
            if(is12HourFormat){
                converted = moment((val  + " " + timeFormatSuffix), ["h:mm A"]).format("HH:mm");
            } else {
                converted = moment(val, ["h:mm A"]).format("HH:mm");
            }

            timeParticles = converted.split(":");
            // if(timeParticles[0] === '00' && timeParticles[1] === '00'){
            //     converted = (timeParticles[0] * (60000 * 60)) + (+1 * 60000);
            // } else {
                converted = (timeParticles[0] * (60* 60 *1000)) + (timeParticles[1] * 60 * 1000);
            // }
            return converted;
        }
    }

    this.millisecondsToUTC = function(milliseconds){
        var msToUTC = new Date(milliseconds).setUTCMilliseconds(milliseconds);
        return msToUTC;

    }

    this.setTimeToZero = function(milliseconds){
        var timeToZero = new Date(milliseconds).setHours(0,0,0,0);
        return timeToZero;

    }

    this.timeValidate = function(val){
        var arr,
            arr0,
            arr1,
            arr4,
            hours = [],
            minutes = [],
            delimiter = ":",
            currentDelimiterPosition,
            dueDelimiterPosition,
            hoursFormatted,
            minutesFormatted,
            hoursLength = 2,
            minutesLength = 2,
            entireLength = 5,
            formattedTime,
            startTimeInput = document.getElementById('startTimeInput'),
            endTimeInput = document.getElementById('endTimeInput'),
            browserLocale = window.navigator.languages ? window.navigator.languages[0] : window.navigator.userLanguage ? window.navigator.userLanguage : window.navigator.language,
            localeTimeString = new Date().toLocaleTimeString(browserLocale),
            timeFormat = (localeTimeString.toString().search("AM") !== -1 || localeTimeString.toString().search("PM") !== -1) ? '12h' : '24h';


        val = this.cut(val);
        if (val) {
            val = val.replace(/^[a-zA-Z]*$/, "");
            val = val.replace(/((?!:))[\D\W]/, ''); //excluding every symbol, except ":"
            val = val.replace(/\:{2,}/, ''); //forbidding more than one utilization of ":";
        }


        startSelection(startTimeInput, -1);
        startSelection(endTimeInput, -1);

        this.value = val;

        function shiftCaretPosition(element, value) {
            // console.log(element.selectionStart);
            if ((element.selectionStart === 0 || element.selectionStart === 1) && value.length === 5) {
                // console.log(element.selectionStart);
                element.selectionStart = 2;
                // element.setSelectionRange(1,2);



            } else if ((element.selectionStart === 3 || element.selectionStart === 4) && value.length === 5) {
                // console.log(element.selectionStart);
                //
                element.selectionStart = 5;

            }
            // else if((startTimeInput.selectionStart === 4) && val.length === 5){
            //     startTimeInput.selectionStart = 5;
            //     startTimeInput.selectionEnd = 5;
            //     console.log(startTimeInput.selectionStart);
            // }
            // else if((startTimeInput.selectionStart === 3) && val.length === 5){
            //     startTimeInput.selectionStart = 5;
            //     startTimeInput.selectionEnd = 5;
            //     console.log(startTimeInput.selectionStart);
            // }
        }




        function startSelection(element, position){
            element.selectionStart += element.selectionStart + position;
            element.selectionEnd += element.selectionEnd + position;

            if(position === 0){
                element.selectionStart = 0;
                element.selectionEnd = 0;
            }

        }

        function removeFromArray(array, element) {
            if(array){
                var index = array.indexOf(element);
                if (index !== -1) {
                    array.splice(index, 1);
                }
            }
        }

        function insertAtArrayIndex(array, index, item){
            if(array){
                array.splice( index, 0, item );
                return array;
            }
        }

        /**
         * Return an
         * @param {DOMElement} el A dom element of a textarea or input text.
         * @return {Object} reference Object with 2 properties (start and end) with the identifier of the location of the cursor and selected text.
         **/
        // function getInputSelection(el) {
        //     var start = 0, end = 0, normalizedValue, range,
        //         textInputRange, len, endRange;
        //
        //     if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        //         start = el.selectionStart;
        //         end = el.selectionEnd;
        //     } else {
        //         range = document.selection.createRange();
        //
        //         if (range && range.parentElement() == el) {
        //             len = el.value.length;
        //             normalizedValue = el.value.replace(/\r\n/g, "\n");
        //
        //             // Create a working TextRange that lives only in the input
        //             textInputRange = el.createTextRange();
        //             textInputRange.moveToBookmark(range.getBookmark());
        //
        //             // Check if the start and end of the selection are at the very end
        //             // of the input, since moveStart/moveEnd doesn't return what we want
        //             // in those cases
        //             endRange = el.createTextRange();
        //             endRange.collapse(false);
        //
        //             if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
        //                 start = end = len;
        //             } else {
        //                 start = -textInputRange.moveStart("character", -len);
        //                 start += normalizedValue.slice(0, start).split("\n").length - 1;
        //
        //                 if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
        //                     end = len;
        //                 } else {
        //                     end = -textInputRange.moveEnd("character", -len);
        //                     end += normalizedValue.slice(0, end).split("\n").length - 1;
        //                 }
        //             }
        //         }
        //     }
        //
        //     return {
        //         start: start,
        //         end: end
        //     };
        // }

        // input.selectionStart += 1;
        //

        // document.getElementById("startTimeInput").addEventListener("mousemove", function(){

        // var inputContent = input.value.length;
        // You may want to focus the textbox in case it's not
        // var result = getInputSelection(input);
        // var resultSpan = document.getElementById("result");
        //
        // if(result.start == result.end){
        //     resultSpan.innerHTML = "No text selected. Position of cursor is " + result.start +" of " +inputContent;
        // }else{
        //     resultSpan.innerHTML = "You've selected text ("+result.start+" to "+ result.end +") from a total length of " + inputContent;
        // }

        // }, false);

        function insertDelimiter(){
            formattedTime = formattedTime ? formattedTime : [];


            if(formattedTime.length<1){
                formattedTime.push(val);
            }


            if(formattedTime[0] === "" && formattedTime[1] === "" && formattedTime[2] !== "" && formattedTime[3] !== ""){
                dueDelimiterPosition = 1;
                startSelection(startTimeInput, 0);
                startSelection(endTimeInput, 0);
                // input.selectionStart = 0;
                // input.selectionEnd = 0;
            } else {
                dueDelimiterPosition = (val.length > 1 && val.length < 4) ? (val.length +1 ) : 2;
            }


            if(formattedTime.join("") === ""){
                formattedTime = "";
            } else {
                insertAtArrayIndex(formattedTime, dueDelimiterPosition, delimiter);
            }

            formattedTime = formattedTime.join("");

        }

        function editMinutes(time) {
            var minutesToString = time.split(":")[1];
            var hoursToString = time.split(":")[0];
            if(minutesToString.length === 1 && parseInt(minutesToString) <= 5) {
                minutesToString = minutesToString + "0";
            } else if(minutesToString.length === 1  && parseInt(minutesToString) > 5){
                minutesToString = "00";
            } else if (minutesToString !== undefined && minutesToString.length < 3) {
                minutesToString = "00";
            }
            formattedTime = hoursToString + delimiter + minutesToString;
        }

        function editHours(time) {
            var hoursToString = time.split(":")[0];
            if(hoursToString!== undefined && hoursToString.length < 1){
                formattedTime = "";
            }
        }

        if (val) {
            val = val.replace(/^[a-zA-Z]*$/, "");
            val = val.replace(/((?!:))[\D\W]/, ''); //excluding every symbol, except ":"
            val = val.replace(/\:{2,}/, ''); //forbidding more than one utilization of ":";

            if (val.match(/\:/)) {
                arr0 = (val.length > 1) ? val.split("") : val;
                currentDelimiterPosition = (arr0.indexOf(delimiter));

                if(val.length > 1){
                    removeFromArray(arr0, delimiter);
                }

                arr = arr0;
                if(val.length === 2 && ((timeFormat === "12h" && parseInt(val) >=2 && parseInt(val) <=9) || (timeFormat === "24h" && parseInt(val) >=3 && parseInt(val) <=9))){
                    arr0.unshift('0');
                    startSelection(startTimeInput, 1);
                    startSelection(endTimeInput, 1);
                }

                switch (currentDelimiterPosition) {
                    case 0:
                        hours = ['', ''];
                        minutes.push(arr[0] || "");
                        minutes.push(arr[1] || "");
                        break;
                    case 1:
                        hours = [''];
                        hours.push(arr[0]);
                        minutes.push(arr[1] || "");
                        minutes.push(arr[2] || "");
                        break;
                    case 2:
                        hours.push(arr[0]);
                        hours.push(arr[1]);
                        minutes.push(arr[2] || "");
                        minutes.push(arr[3] || "");
                        break;
                    default:
                        hours = ['0','0'];
                        minutes = ['0','0'];

                }
            }


            hoursFormatted = hoursFormatted ? angular.copy(hoursFormatted) : angular.copy(hours);
            minutesFormatted = minutesFormatted ? angular.copy(minutesFormatted) : angular.copy(minutes);

            if (timeFormat === '12h' && localeTimeString.toString().search("AM") !== -1 && parseInt(hoursFormatted.join('')) > 11) {
                hoursFormatted = [0, 0];
            } else if (timeFormat === '12h' && localeTimeString.toString().search("PM") !== -1 && parseInt(hoursFormatted.join('')) > 12) {
                hoursFormatted = [0, 0];
            } else if(timeFormat === '12h' && (hoursFormatted.join('')) === "00"){
                hoursFormatted = [0, 1];
            } else if (timeFormat === '24h' && parseInt(hoursFormatted.join('')) > 23) {
                hoursFormatted = [2, 3];
            }

            if (parseInt(minutesFormatted.join('')) > 59) {
                minutesFormatted = [0, 0];
            }

            formattedTime = hoursFormatted.concat(minutesFormatted);

            insertDelimiter();


            startTimeInput.addEventListener('click', function(e){
                if(e && this.value.length === 5) {
                    console.log("click");
                    shiftCaretPosition(startTimeInput, this.value);
                }
            });

            endTimeInput.addEventListener('click', function(e){
                if(e && this.value.length === 5) {
                    console.log("click");
                    shiftCaretPosition(endTimeInput, this.value);
                }
            });




            startTimeInput.addEventListener('keyup', function(e){
                if(e && this.value.length === 5) {
                    console.log("keydown");
                    shiftCaretPosition(startTimeInput, this.value);
                }
            })

            endTimeInput.addEventListener('keyup', function(e){
                if(e && this.value.length === 5) {
                    console.log("keydown");
                    shiftCaretPosition(startTimeInput, this.value);
                }
            })



            // });
            //
            //
            //
            // startTimeInput.addEventListener('touchmove', function(e){
            //     if(e){
            //         // console.log("touchmove");
            //         shiftCaretPosition();
            //     }
            //
            //
            // });
            //
            // startTimeInput.addEventListener('touchstart', function(e){
            //     if(e){
            //         // console.log("touchstart");
            //         shiftCaretPosition();
            //     }
            //
            //
            // });

            startTimeInput.addEventListener('blur', function (e) {
                if(this.value){
                    if ((e && (this.value.split(":")[0] === "" || this.value.split(":")[1] === "")) ||
                        (e && (this.value.split(":")[0] === "00" || this.value.split(":")[1] === "00")) ||
                        (e && (this.value.length < 5))) {
                        editMinutes(this.value);
                        editHours(this.value);

                        if(timeFormat === '12h' && (formattedTime === "00:00" || formattedTime === "0:00")){
                            this.value = "";
                        } else {
                            this.value = formattedTime;
                        }

                        return this.value;
                    }
                }
            })

            endTimeInput.addEventListener('blur', function (e) {
                if(this.value){
                    if ((e && (this.value.split(":")[0] === "" || this.value.split(":")[1] === "")) ||
                        (e && (this.value.split(":")[0] === "00" || this.value.split(":")[1] === "00")) ||
                        (e && (this.value.length < 5))) {
                        editMinutes(this.value);
                        editHours(this.value);

                        if(timeFormat === '12h' && (formattedTime === "00:00" || formattedTime === "0:00")){
                            this.value = "";
                        } else {
                            this.value = formattedTime;
                        }

                        return this.value;
                    }
                }
            })

            this.value = val = formattedTime;
        }

        return this.value;
    }

    this.fromFormattedStringToDateObject = function(dateString) {
        var dateFromFormattedString;

        function split(dateString) {
            var dateArrayForFixedDate = [];
            // var localeDate = $filter('formatDateByBrowser')(new Date(1481414400000), 'calendarInput');
            var localeDate = $filter('formatDateByBrowser')(new Date(1481673600000), 'calendarInput');
            var dateFormatDivider = ($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge ) ?  '/' : localeDate.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
            var localeDateFormat = localeDate.split(dateFormatDivider);


                // if ($rootScope.langCountry === 'ar') {
                if ($rootScope.langCountry === 'arabic') { //temporary decision in order to avoid processing of Arab language
                    // console.log('I am arabic');
                    localeDateFormat.forEach(function (element) {
                        dateArrayForFixedDate.push(element);
                    });

                    indexOfDay = 0;
                    indexOfMonth = 1;
                    indexOfYear = 2;
                    dd = ( dateString[indexOfDay] );
                    MM = ( dateString[indexOfMonth] );
                    yyyy = ( dateString[indexOfYear] );

                } else {
                    if($rootScope.localeSupport === false || $rootScope.isIE || $rootScope.isEdge){
                        dd = ( dateString[0] );
                        MM = ( dateString[1] );
                        yyyy = ( dateString[2] );
                    } else {
                    localeDateFormat.forEach(function (element) {
                        if (typeof(parseInt(element)) === 'number') {
                            // dateArrayForFixedDate.push(parseInt(element).toString());
                            dateArrayForFixedDate.push(parseInt(element));
                        }
                    });

                    indexOfDay = (dateArrayForFixedDate.indexOf(14)!== -1) ? dateArrayForFixedDate.indexOf(14) : (dateArrayForFixedDate.indexOf(13) !== -1) ? dateArrayForFixedDate.indexOf(13) : dateArrayForFixedDate.indexOf(15);
                    indexOfMonth = dateArrayForFixedDate.indexOf(12);
                    // Year correction for thai calendar
                    indexOfYear = dateArrayForFixedDate.indexOf($rootScope.langCountry === langCode.tha ? 2559 : 2016);
                    dd = parseInt(dateString[indexOfDay]);
                    MM = parseInt(dateString[indexOfMonth]);
                    yyyy = parseInt(dateString[indexOfYear]);
                }

                return {
                    dateFormatDivider: dateFormatDivider,
                    dd: dd,
                    MM: MM,
                    yyyy: yyyy
                }
            }
        }

        var divider = split(dateString).dateFormatDivider;
        // console.log(divider);
        var dissected = dateString.split(divider);
        var dateArray = [];

        dissected.forEach(function (element) {
            // if ($rootScope.langCountry !== 'ar' && typeof(parseInt(element)) === 'number') {
            if ($rootScope.langCountry !== 'arabic' && typeof(parseInt(element)) === 'number') { //temporary decision in order to avoid processing of Arab language
                dateArray.push(element);
            // } else if ($rootScope.langCountry === 'ar') {
            } else if ($rootScope.langCountry === 'arabic') { //temporary decision in order to avoid processing of Arab language
                dateArray.push(element);
            }
        });

        if($rootScope.langCountry === 'arabic'){ //temporary decision in order to avoid processing of Arab language
            // if($rootScope.langCountry === 'ar'){
            var arabYear = split(dateArray).yyyy;
            var arabMonth = split(dateArray).MM;
            var arabDay = split(dateArray).dd;
            year = convertArabSymbolToDigit(arabYear);
            month = convertArabSymbolToDigit(arabMonth);
            day = convertArabSymbolToDigit(arabDay);
        } else {
            year = split(dateArray).yyyy;
            if ($rootScope.langCountry === langCode.tha) {
                // Year correction for thai calendar
                year -= 543;
            }
            if(year > 19999)
                year = 19999;
            if(year < 2000) {
                year = 2000;
                month = 1;
                day = 1;
            } else {
                month = split(dateArray).MM;
                day = split(dateArray).dd;
            }

            var maxDate = new Date();
            if(new Date( year, month-1, day ) > maxDate) {
                year = maxDate.getFullYear();
                month = maxDate.getMonth() + 1;
                day = maxDate.getDate();
            }
        }

        function convertArabSymbolToDigit(arabic) {
            console.log(arabic);
            if(arabToDigits.hasOwnProperty) {
                for(var arabDigit in arabToDigits) {
                    // console.log(arabDigit);
                    // var reversed = arabDigit.split("").reverse().join("");
                    if(arabDigit == arabic){
                        // console.log('caught it');
                        // console.log(arabToDigits[arabDigit]);
                        return arabToDigits[arabDigit];
                    }
                }
            }
        };


        // console.log(dateArray);
        // console.log(year);
        // console.log(month);
        // console.log(day);

        dateFromFormattedString = new Date( year, month-1, day );

        return dateFromFormattedString;
    }
}

export default {
    type: 'service',
    name: 'dateCustomMethods',
    value: dateCustomMethods
};