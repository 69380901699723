/**
 * Created by mars on 2/3/16.
 * Edited by kseniya.yarosh on 10/17
 */

config.$inject = ['$validProvider'];

function config($validProvider) {

    $validProvider
        .valid( 'pass', {
            min: 6,
            max: 15,
            minMessage: 'low_six'
    })

        /**
         * For a time format
         */

        .valid('time12H', {
            min: 2,
            max: 5,
            minMessage: 'cannot_empty_field'
        }, function (val) {
            val = this.cut(val);
            val = validateTime.call(this, val, 'time12H');

            this.value = val;
            return this.value;
        })


        .valid('time24H', {
            min: 2,
            max: 5,
            minMessage: 'cannot_empty_field'
        }, function (val) {

            val = this.cut(val);

            val = validateTime.call(this, val, 'time24H');

            this.value = val;
            return this.value;

        })


    function validateTime(val, format){

        var arr,
            arr0,
            keyCode,
            hours = [],
            minutes = [],
            delimiter = ":",
            currentDelimiterPosition,
            dueDelimiterPosition,
            hoursFormatted,
            minutesFormatted,
            formattedTime,
            startTimeInput = document.getElementById('startTimeInput'),
            endTimeInput = document.getElementById('endTimeInput'),
            timeFormat = format;



        if (val) {
            val = val.replace(/^[a-zA-Z]*$/, "");
            val = val.replace(/((?!:))[\D\W]/, ''); //excluding every symbol, except ":"
            val = val.replace(/\:{2,}/, ''); //forbidding more than one utilization of ":";
            }


        // startSelection(startTimeInput, -1);
        // startSelection(endTimeInput, -1);

        this.value = val;

        function changeTime(element, value) {
            if ((element.selectionStart === 0 || element.selectionStart === 1)) {
                replaceTime(value, "hours");
            } else if ((element.selectionStart === 3 || element.selectionStart === 4)) {
                replaceTime(value, "minutes");
            } else {
                replaceTime(value, "hours");
            }
        }

        function onkeyup(e) {
            keyCode;
            // some browsers don't pass e, so get it from the window
            if (!e) {
                e = window.event;
            }

            // some browsers use e.keyCode
            if (e.keyCode) {
                keyCode = e.keyCode;
            }
            // others use e.which
            else if (e.which) {
                keyCode = e.which;
            }
        }

        function replaceTime (val, caretPos) {
            if(val.length > 5){
                var returnedValue;
                var position;
                val = val.split("");
                if(caretPos === "hours"){
                    position = (val.indexOf(":")-1);
                } else if(caretPos === "minutes"){
                    position = (val.indexOf(":")+2);
                }

                val.splice(position,1);
                // console.log(val);
                returnedValue = val.join("");
                return returnedValue;
            }
        }


        function startSelection(element, position){
            element.selectionStart = element.selectionStart + position;
            element.selectionEnd = element.selectionEnd + position;

            if(position === 0){
                element.selectionStart = 0;
                element.selectionEnd = 0;
            }

        }

        function removeFromArray(array, element) {
            if(array){
                var index = array.indexOf(element);
                if (index !== -1) {
                    array.splice(index, 1);
                }
            }
        }

        function insertAtArrayIndex(array, index, item){
            if(array){
                array.splice( index, 0, item );
                return array;
            }
        }


        function insertDelimiter(val){

            formattedTime = formattedTime ? formattedTime : [];


            if(formattedTime.length<1){
                formattedTime.push(val);
            }


            if(formattedTime[0] === "" && formattedTime[1] === "" && formattedTime[2] !== "" && formattedTime[3] !== ""){
                dueDelimiterPosition = 1;
                // startSelection(startTimeInput, 0);
                // startSelection(endTimeInput, 0);

            } else {
                dueDelimiterPosition = (val.length > 1 && val.length < 4) ? (val.length +1 ) : 2;
            }


            if(formattedTime.join("") === ""){
                formattedTime = "";
            } else {
                insertAtArrayIndex(formattedTime, dueDelimiterPosition, delimiter);
            }

            formattedTime = formattedTime.join("");

        }

        function editMinutes(time) {
            var minutesToString = time.split(":")[1];
            var hoursToString = time.split(":")[0];
            if(minutesToString.length === 1 && parseInt(minutesToString) <= 5) {
                minutesToString = minutesToString + "0";
            } else if(minutesToString.length === 1  && parseInt(minutesToString) > 5){
                minutesToString = "00";
            } else if (minutesToString !== undefined && minutesToString.length < 1) {
                minutesToString = "00";
            }

            formattedTime = hoursToString + delimiter + minutesToString;
        }

        function editHours(time) {
            var minutesToString = time.split(":")[1];
            var hoursToString = time.split(":")[0];
            if(hoursToString!== undefined && hoursToString.length < 1){
                formattedTime = "";
            } else if(hoursToString.length === 1 && parseInt(hoursToString) <= 9){
                hoursToString =  "0" + hoursToString;
                formattedTime = hoursToString + delimiter + minutesToString;
            }
        }

        if (val) {
            val = val.replace(/^[a-zA-Z]*$/, "");
            val = val.replace(/((?!:))[\D\W]/, ''); //excluding every symbol, except ":"
            val = val.replace(/\:{2,}/, ''); //forbidding more than one utilization of ":";
            //
            // if(val.length > 5){
            //     val = replaceTime(val);
            // }

            if (val.match(/\:/)) {
                arr0 = (val.length > 1 && val.length <= 5) ? val.split("") : val;
                currentDelimiterPosition = (arr0.indexOf(delimiter));

                if (val.length > 1 && val.length <= 5) {
                    removeFromArray(arr0, delimiter);
                }

                arr = arr0;
                if (val.length === 2 && ((timeFormat === "time12H" && parseInt(val) > 1 && parseInt(val) <= 9) || (timeFormat === "time24H" && parseInt(val) > 2 && parseInt(val) <= 9))) {
                    arr0.unshift('0');
                    // startSelection(startTimeInput, 1);
                    // startSelection(endTimeInput, 1);
                }

                switch (currentDelimiterPosition) {
                    case 0:
                        hours = ['', ''];
                        minutes.push(arr[0] || "");
                        minutes.push(arr[1] || "");
                        break;
                    case 1:
                        hours = [''];
                        hours.push(arr[0]);
                        minutes.push(arr[1] || "");
                        minutes.push(arr[2] || "");
                        break;
                    case 2:
                        hours.push(arr[0]);
                        hours.push(arr[1]);
                        minutes.push(arr[2] || "");
                        minutes.push(arr[3] || "");
                        break;
                    default:
                        hours = ['0', '0'];
                        minutes = ['0', '0'];
                }
            }


            hoursFormatted = hoursFormatted ? angular.copy(hoursFormatted) : angular.copy(hours);
            minutesFormatted = minutesFormatted ? angular.copy(minutesFormatted) : angular.copy(minutes);

            if (timeFormat === 'time12H' && parseInt(hoursFormatted.join('')) > 12) {
                hoursFormatted = [1, 2];
            } else if (timeFormat === 'time12H' && (hoursFormatted.join('')) === "00") {
                hoursFormatted = [1, 2];
            } else if (timeFormat === 'time24H' && parseInt(hoursFormatted.join('')) > 23) {
                hoursFormatted = [0, 0];
            }

            if (parseInt(minutesFormatted.join('')) > 59) {
                minutesFormatted = [0, 0];
            }

            formattedTime = hoursFormatted.concat(minutesFormatted);


            insertDelimiter(val);


            startTimeInput.addEventListener('keydown', function (e) {
                onkeyup(e);
                if (keyCode === 46) {
                    e.preventDefault();
                }
            })

            endTimeInput.addEventListener('keydown', function (e) {
                onkeyup(e);
                if (keyCode === 46) {
                    e.preventDefault();
                }
            })

            if (this.value.length === 5) {
                startTimeInput.addEventListener('click', function (e) {
                    if (e && this.value.length === 5) {
                        // onkeyup(e);
                        // console.log(startTimeInput.selectionStart);
                        // console.log("click");
                        if (
                            startTimeInput.selectionStart === 0 ||
                            startTimeInput.selectionStart === 1 ||
                            startTimeInput.selectionStart === 2 ||
                            startTimeInput.selectionStart === 5) {
                            startTimeInput.addEventListener('keypress', function (e) {
                                if (this.value.length === 5 && keyCode !== 8 && keyCode !== 39 && keyCode !== 37) {
                                    e.preventDefault();
                                    return false;
                                }
                            });
                        } else {
                            // changeTime(startTimeInput, this.value);
                        }
                    }
                });
            }


            if(this.value.length === 5) {
                endTimeInput.addEventListener('click', function (e) {
                    if (e && this.value.length === 5) {
                        // onkeyup(e);
                        // console.log(endTimeInput.selectionStart);
                        // console.log("click");
                        if (
                            endTimeInput.selectionStart === 0 ||
                            endTimeInput.selectionStart === 1 ||
                            endTimeInput.selectionStart === 2 ||
                            endTimeInput.selectionStart === 5) {
                            endTimeInput.addEventListener('keypress', function (e) {
                                // if(keyCode === 46){
                                //     e.preventDefault();
                                // }

                                if (this.value.length === 5 && keyCode !== 8 && keyCode !== 39 && keyCode !== 37) {
                                    e.preventDefault();
                                    return false;
                                }
                            });
                        } else {
                            // changeTime(endTimeInput, this.value);
                        }
                    }
                });
            }

            startTimeInput.addEventListener('keyup', function (e) {
                if (e && this.value.length === 5) {
                    // onkeyup(e);
                    // console.log(startTimeInput.selectionStart);
                    // console.log("keyup");
                    if (
                        startTimeInput.selectionStart === 0 ||
                        startTimeInput.selectionStart === 1 ||
                        startTimeInput.selectionStart === 2 ||
                        startTimeInput.selectionStart === 5) {
                        startTimeInput.addEventListener('keypress', function (e) {
                            // if(keyCode === 46){
                            //     e.preventDefault();
                            // }

                            if (this.value.length === 5 && keyCode !== 8 && keyCode !== 39 && keyCode !== 37) {
                                e.preventDefault();
                                return false;
                            }
                        });
                    } else {
                        // changeTime(startTimeInput, this.value);
                    }
                } else if(e && this.value.length === 3 && startTimeInput.selectionStart === 2 && parseInt(this.value).toString().length === 1 && keyCode !== 37){
                    startTimeInput.selectionStart = 3;
                }
            })


            endTimeInput.addEventListener('keyup', function(e){
                if (e && this.value.length === 5) {
                    // onkeyup(e);
                    // console.log(startTimeInput.selectionStart);
                    // console.log("keyup");
                    if (
                        endTimeInput.selectionStart === 0 ||
                        endTimeInput.selectionStart === 1 ||
                        endTimeInput.selectionStart === 2 ||
                        endTimeInput.selectionStart === 5) {
                        endTimeInput.addEventListener('keypress', function (e) {
                            // if(keyCode === 46){
                            //     e.preventDefault();
                            // }

                            if (this.value.length === 5 && keyCode !== 8 && keyCode !== 39 && keyCode !== 37) {
                                e.preventDefault();
                                return false;
                            }
                        });
                    } else {
                        // changeTime(startTimeInput, this.value);
                    }
                } else if(e && this.value.length === 3 && endTimeInput.selectionStart === 2 && parseInt(this.value).toString().length === 1 && keyCode !== 37){
                    endTimeInput.selectionStart = 3;
                }
            })




            if(this.value.length < 5) {
                startTimeInput.addEventListener('blur', function (e) {
                    if (this.value) {
                        if ((e && (this.value.split(":")[0] === "" || this.value.split(":")[1] === "")) ||
                            (e && (this.value.split(":")[0] === "00" || this.value.split(":")[1] === "00")) ||
                            (e && (this.value.length < 5))) {
                            editMinutes(this.value);
                            editHours(this.value);

                            if (timeFormat === 'time12H' && (formattedTime === "00:00" || formattedTime === "0:00")) {
                                val = "";
                            } else {
                                val = formattedTime;
                            }

                            this.value = formattedTime;

                            return this.value;
                        }
                    }
                })
            }

            if(this.value.length < 5){
                endTimeInput.addEventListener('blur', function (e) {
                    if(this.value){
                        if ((e && (this.value.split(":")[0] === "" || this.value.split(":")[1] === "")) ||
                            (e && (this.value.split(":")[0] === "00" || this.value.split(":")[1] === "00")) ||
                            (e && (this.value.length < 5))) {
                            editMinutes(this.value);
                            editHours(this.value);

                            if (timeFormat === 'time12H' && (formattedTime === "00:00" || formattedTime === "0:00")) {
                                val = "";
                            } else {
                                val = formattedTime;
                            }

                            this.value = formattedTime;

                            return this.value;
                        }
                    }
                })
            }



        }

        val = formattedTime;
        return val;
    }

}

export default config;