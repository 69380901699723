adaptiveInputDirective.$inject = [];

function adaptiveInputDirective() {

    return {
        restrict: 'A',
        require: ['ngModel', '?^lvInputContainer'],
        link: function (scope, element, attrs, controllers) {

            const modelCtrl = controllers[0];
            const containerCtrl = controllers[1];
            const fontSizes = {
                sm: '14px',
                lg: '28px'
            };

            let prevViewValue = '';
            let inputFontSize = element.css('font-size') || fontSizes.lg;
            let inputWidth = element.width();
            let testingPanel = angular.element('<div></div>')
            testingPanel.css({
                position: 'absolute',
                zIndex: '-100',
                opacity: '0'
            });

            setFont(inputFontSize);

            element.before(testingPanel);

            modelCtrl.$viewChangeListeners.push(updateFont);

            const renderOrig = modelCtrl.$render;
            modelCtrl.$render = function() {
                renderOrig();
                updateFont();
            }

            function updateFont() {

                const viewValue = angular.isDefined(modelCtrl.$viewValue) ? modelCtrl.$viewValue : '';
                testingPanel.text(viewValue);

                let currentViewWidth = testingPanel.width();
                if (currentViewWidth >= inputWidth - 18 &&
                    viewValue.length > prevViewValue.length &&
                    inputFontSize === fontSizes.lg) {
                    setFont(fontSizes.sm);
                }
                if (currentViewWidth <= 3*inputWidth/4 &&
                    (viewValue.length < prevViewValue.length || viewValue.length === 0 || prevViewValue.length === 0) &&
                    inputFontSize === fontSizes.sm) {
                    setFont(fontSizes.lg);
                }

                prevViewValue = viewValue;
            }

            function setFont(font) {
                inputFontSize = font;
                testingPanel.css('font-size', font);
                element.css('font-size', font);

                if (containerCtrl) {
                    if (font === fontSizes.lg) {
                        containerCtrl.setLayoutLg();
                    } else {
                        containerCtrl.setLayoutSm();
                    }
                }
            }

        }
    };
}

export default {
    type: 'directive',
    name: 'lvAdaptiveInput',
    value: adaptiveInputDirective
};