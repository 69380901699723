/**
 * Created by mars on 2/10/16.
 */

html.$inject = ['$sce'];
function html($sce) {
	return function(input){
		return $sce.trustAsHtml(input);
	}
}

export default {
	type: 'filter',
	name: 'html',
	value: html
};