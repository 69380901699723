/**
 * Created by mars on 2/1/16.
 */

wareCount.$inject = ['currencyFormat'];
function wareCount(currencyFormat) {
	/**
	 * val - "str"
	 * reverse - boolean; true -> умножает на тысячу
	 */
	let $delimiter = ",";
	let settings = {};
	currencyFormat.observable.countQuantityForOrderDivisible.observe(result => {
		settings = result
	}, true);

	format.$stateful = true;
	function format( val, delimiter, reverse ) {
		// console.log("вход wareCount - val ", val);
		// console.log("вход wareCount - delimiter ", delimiter);
		// console.log("вход wareCount - reverse ", reverse);

		if (val === null) {
			val  = 0;
		}

		function clearDelimiters( val) {
			val = ""+val;
			if ((val.includes(',')) && settings.decimalCharacter === "," && settings.digitGroupSeparator === " ") {
				val = val.replace(',', '.').replace(/\s/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			if (val.includes(',') && settings.decimalCharacter === "," && settings.digitGroupSeparator === ".") {
				val = val.replace(/\./g, '').replace(',', '.');
				// console.log("выход = ", val);
				// return val;
			}
			if ((val.includes(',')) && settings.digitGroupSeparator === ",") {
				val = val.replace(/,/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			if ((val.includes(' ')) && settings.digitGroupSeparator === " ") {
				val = val.replace(/\s/g, '');
				// console.log("выход = ", val);
				// return val;
			}
			return val;
		}
		// clearDelimiters( val);

		var arrVal;
		if ( reverse ) {

			val = ""+clearDelimiters( val);
			var index = 0;
			if(/\.|,/.test(val)){
				index = val.indexOf(".") || val.indexOf(",");
				val = val.replace(/\.|,/, "");
				val = val+"000";
				index+=3;
				arrVal= val.split("");
				arrVal.splice(-(arrVal.length-index), 0,  delimiter || $delimiter );
				val = arrVal.join("");
				val =""+ parseInt(val);
			}else{
				val = val+"000";
			}
		} else {
			var _prefix = false;
			val = "" + val;
			if(/^\-/.test(val)){
				_prefix = true;
				val = val.replace(/\-/,'');
			}
			if (val.length<4){
				val="000"+val;
			}
			arrVal = val.split( "" );
			if (arrVal.length<4){
				arrVal.unshift("000")
			}
			arrVal.splice( -3, 0, delimiter || $delimiter );
			val = arrVal.join("");
			val = (_prefix ? '-':'') + val;
			// val = (_prefix ? '-':'') + parseFloat(val);
		}
		// console.log("выход wareCount - val ", val);
		return val;
	}

	return format;
}

export default {
	type: 'filter',
	name: 'wareCount',
	value: wareCount
};
