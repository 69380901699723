/**
 * Created by A.Sirosh on 27.10.2015.
 * @memberof getGroupBySelect
 * @ngdoc service
 * @name getGroupBySelect
 */

getGroupBySelect.$inject = [];
function getGroupBySelect() {

	function getSelect(groupBy, group){
		var i, len = groupBy.length;
		for (i = 0; i < len; i++) {
			if (group == groupBy[i].key) {
				return groupBy[i];
			}
		}

		i = null;
		len = null;
	}

	this.getSelect = getSelect;
}

export default {
	type: 'service',
	name: 'getGroupBySelect',
	value: getGroupBySelect
};