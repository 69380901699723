createDirective.$inject = [];

function createDirective() {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        if (!ngModel) {
            return;
        }

        const regExpEmail = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        ngModel.$validators.email = function(modelValue, viewValue) {
            let value = modelValue || viewValue;
            if (ngModel.$isEmpty(value)) {
                return true;
            }
            return regExpEmail.test(value);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvEmail',
    value: createDirective
};