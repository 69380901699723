/**
 * Created by sirosh on 22.01.16.
 */

const constTaxOptions = [
    {
        id: 'NEW',
        name: 'SETTINGS.TAX.NEW'
    },
    {
        id: 'OLD',
        name: 'SETTINGS.TAX.OLD'
    },
    {
        id: 'OLD_NEW',
        name: 'SETTINGS.TAX.OLD_NEW'
    },
    {
        id: 'NONE',
        name: 'SETTINGS.TAX.NONE'
    }
];

export default {
    type: 'constant',
    name: 'constTaxOptions',
    value: constTaxOptions
};