/**
 * @ngdoc directive
 * @name app.directive:valid
 * @element input
 * @restrict A
 * @description
 * навешивает слушатели для полей имеющих атрибут valid. Сообщает скопу формы валидна форма или нет
 */

import angular from 'angular';
import ngTranslate from 'angular-translate';

import config from './config/config-valid';

import valid from './directive/directive-valid';
import brandNameValidator from "./directive/directive-lv-brand-name-validator";

import factoryValid from './factory/factory-valid';
import factoryValidationFormat from './factory/factory-validation-format';

import validProvider from './provider/provider-$valid'

export default angular
	.module( 'lv.valid', [ngTranslate] )
	.directive('valid', valid)
	.directive('lvBrandName', brandNameValidator)
	.factory('factoryValid', factoryValid)
	.factory('factoryValidationFormat', factoryValidationFormat)
	.provider('$valid', validProvider)
	.config(config)
	.name;




