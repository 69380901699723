/**
 * Created by olehkss on 02.06.16.
 *
 * @ngdoc controller
 * @name app.controller:modifiersListController
 * @description
 * Controls modifiers list view
 * @requires $state
 * @requires $filter
 * @requires $stateParams
 * @requires {object} $modifiersList list of the modifiers resolved by ui-router
 * @requires modificatorsOperation
 * @requires serviceToast
 * @requires serviceDialog
 */

import angular from 'angular';

modifiersListController.$inject = [
    '$scope',
    '$state',
    '$filter',
    '$stateParams',
    '$modifiersList',
    'modificatorsOperation',
    'serviceToast',
    'serviceDialog',
    '$shortOutlets',
    '$profile',
    'siteLangCode',
    '$translate',
    'lvMixpanel',
    'dialogs'
];

function modifiersListController($scope,
                                 $state,
                                 $filter,
                                 $stateParams,
                                 $modifiersList,
                                 modificatorsOperation,
                                 serviceToast,
                                 serviceDialog,
                                 $shortOutlets,
                                 $profile,
                                 siteLangCode,
                                 $translate,
                                 lvMixpanel,
                                 dialogs
) {
    // var vm = this;
    $scope.modifiersList = $modifiersList;

    if ($scope.modifiersList) {

        $scope.modifiersList._select = false;
        $scope.selectedModifiers = [];
        $scope.showDelete = false;
        $scope.translate = $filter('translate');
        $scope.changeAll = changeAll;
        $scope.changeItem = changeItem;
        $scope.addModifier = addModifier;
        $scope.confirmDelete = confirmDelete;

        $scope.editTableState = 'goods.modifieredit';
        selectAll(false);
    }



    // BLOCK WRITTEN UNDER MULTISHOP
    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    // console.log("$scope.shortOutlets = ", $scope.shortOutlets);

    $scope.selectOutlet = {id: $stateParams.outletsIds ? +$stateParams.outletsIds : null};
    // console.log("$scope.selectOutlet = ", $scope.selectOutlet);

    $scope.selectOutletId = $scope.selectOutlet.id;

    //  state what check load content or not for show empty state between chose shop
    $scope.dataIsLoading = false;

    //-- BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            $scope.dataIsLoading = true;
            $state.go('goods.modifiers-list', {outletsIds: newval.id}).then(function() {
                $scope.selectOutletId = newval.id;
                $scope.dataIsLoading = false;
            });
        }
    }, true);
    //-- END BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    // END BLOCK WRITTEN UNDER MULTISHOP

    // FUNCTIONAL FOR drag'n'drop
    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true,
        onUpdate: function () {
            let modifiersForChange = [];
            let forSaveOrder = {};

            $scope.modifiersList.forEach((modifier, index) => {
                if(modifier.priority !== index + 1){
                    modifiersForChange.push({id: modifier.id, priority: index + 1});
                }
            });
            if(modifiersForChange.length > 0) {
                forSaveOrder.modifiers = modifiersForChange;
                modificatorsOperation.changeModifiersOrder(forSaveOrder).then(function(data){
                    if(data === "ok") {
                        refreshList();
                    }
                });
            }
        }
    };
    // END FUNCTIONAL FOR drag'n'drop


    /**
     * @ngdoc function
     * @description
     * Function for notification about the attempt of the deletion of modifier or modifiers
     */
    function confirmDelete() {
        var deleteTitle = ($scope.selectedModifiers.length > 1) ? 'MODIFIERS.DELETE_MODIFIERS_TITLE' : 'MODIFIERS.DELETE_MODIFIER_TITLE',
            deleteInfo =  ($scope.selectedModifiers.length > 1) ? 'MODIFIERS.DELETE_MODIFIERS_TEXT' : 'MODIFIERS.DELETE_MODIFIER_TEXT';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   $scope.translate(deleteTitle),
            content:   $scope.translate(deleteInfo),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: deleteModifiers
                }
            ]
        });
    }

    /**
     * @ngdoc function
     * @description
     * Sends delete request to the server with Ids of selected modifiers, triggers  success information message and
     * page reload.
     */
    function deleteModifiers() {
        var selectedModifiersIds = $scope.selectedModifiers.map(function (modifier) {
            return modifier.id;
        }),
            deleteMessage = (selectedModifiersIds.length > 1) ? 'MODIFIERS.DELETE_MODIFIERS' : 'MODIFIERS.DELETE_MODIFIER';

        modificatorsOperation.deleteModifier({
            modifierIds: selectedModifiersIds
        })
            .then(data => {

                if (data.result === "ok") {
                    serviceToast.show(deleteMessage, 'success');
                    refreshList();
                } else if (data.result === 'error') {
                    if (data.message === 'modifier_deleted') {
                        dialogs.listItemDeletedEarlier().then(refreshList);
                    }
                }
            });
    }

    /**
     * @ngdoc function
     * @description
     * Adds to or removes from $scope.selectedModifiers array selected item and controls showing/hiding of the delete
     * button.
     * @param {object} modifier
     */
    function changeItem(modifier) {
        if (modifier._select) {
            $scope.selectedModifiers.push(modifier);
            $scope.showDelete = true;

            if ($scope.selectedModifiers.length === $scope.modifiersList.length) {
                $scope.modifiersList._select = true;
            }
        } else {
            $scope.selectedModifiers = $scope.selectedModifiers.filter(function (selectedModifier) {
                return modifier.id !== selectedModifier.id;
            });

            if ($scope.modifiersList._select) $scope.modifiersList._select = false;

            if (!$scope.selectedModifiers.length)   $scope.showDelete = false;
        }
    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all modifiers in list using checkbox. This modifiers added and deleted
     * from $scope.selectedModifiers array.
     */
    function changeAll() {
        if ($scope.modifiersList._select) {
            selectAll(true);
            $scope.showDelete = true;
            $scope.selectedModifiers = $scope.modifiersList;
        } else {
            selectAll(false);
            $scope.showDelete = false;
            $scope.selectedModifiers = [];
        }
    }

    /**
     * @ngdoc function
     * @description
     * Redirects to the modifiers creation page.
     */
    function addModifier(editProfile) {
        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        $state.go('goods.modifiercreate');
    }

    /**
     * @ngdoc function
     * @description
     * Adds _select property to the $scope.modifiersList with value specified by param.
     * @param {boolean} param value of the _select property
     */
    function selectAll(param) {
        var i, l = $scope.modifiersList.length;

        if ($scope.modifiersList.length) {
            for (i = 0; i < l; i++) {
                $scope.modifiersList[i]._select = param;
            }
        }
    }


    //-- Task #25633 Update stubs for empty states
    emptyStateLink();
    $scope.emptyStateLink = emptyStateLink;

    function emptyStateLink() {
        let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
        // url lang where we have info
        let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "it", "pl", "gr", "zh"];
        //emptyStateCategory Learn more
        $scope.emptyStateLearnMoreModifiers = urlLang.indexOf(siteUrlLang) !== -1
            ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-set-and-apply-modifiers?utm_source=Back%20Office&utm_medium=Modifiers"
            : "https://help.loyverse.com/help/how-set-and-apply-modifiers?utm_source=Back%20Office&utm_medium=Modifiers";
    }
    //-- END Task #25633 Update stubs for empty states
    //-- Task #25853 Add events to Mixpanel for transitions to help
    $scope.trackModifiersHelp = function () {
        lvMixpanel.track('Help', {"Property": "Modifiers"});
    };
    //-- END Task #25853 Add events to Mixpanel for transitions to help

    function refreshList() {
        $state.go($state.current.name, {}, {
            reload: true
        });
    }
}

export default {
    type: 'controller',
    name: 'modifiersListController',
    value: modifiersListController
};
