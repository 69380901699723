/**
 * Created by ALEX on 2.10.17.
 */

import angular from "angular";

creatediningCtrl.$inject = [
		"$scope",
		"$state",
		"$stateParams",
		"$filter",
		"serviceDialog",
		"serviceToast",
		"$profile",
		"$shortOutlets",
		"getDining",
		'dialogs'];
function creatediningCtrl(	$scope,
			$state,
			$stateParams,
			$filter,
			serviceDialog,
			serviceToast,
			$profile,
			$shortOutlets,
			getDining,
		  	dialogs) {



	$scope.profile = $profile;
// БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ
	var translate 		= $filter( 'translate' );
	$scope.clicked 		= true;
	$scope.useMultishop = $profile.multishopEnable;
	// console.log("$scope.profile =", $scope.profile);
	$scope.dining 		= {};

	$scope.outlets 		= $shortOutlets;
	$scope.waresCount 	= 0;
	$scope.outletSelect = [];
	$scope.allOutlets 	= {};
	$scope.uniqueName = true;
	$scope.progress = false;

	for(var i = 0; i < $scope.outlets.length; i++){
		$scope.outlets[i]["_select"] = true;
	}
// БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ

	let initParams = {
		name: $scope.dining.name,
		outlets: $scope.outlets.map(item => {
			return {
				name: item.name,
				select: item._select
			};
		})
	};
	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
	function isChanged() {
		let currentParams = {
			name: $scope.dining.name,
			outlets: $scope.outlets.map(item => {
				return {
					name: item.name,
					select: item._select
				};
			})
		};

		return !angular.equals(initParams, currentParams);
	}


//** БЛОК СПИСКА ЗАВЕДЕНИЙ  - СЕЛЕКТ
	$scope.$watch('outlets', function(newVal){
		for(var j = 0; j < newVal.length; j++){
			if(newVal[j]._select === true){
				$scope.outletSelect.push(newVal[j].id);
				$scope.waresCount += newVal[j].waresCount;
			}
		}
		$scope.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;
		$scope.dining.outletsIds = $scope.outletSelect;
		$scope.dining.allowedForAllOutlets = $scope.allOutlets._select;
		$scope.outletSelect = [];
	}, true);


	$scope.changeAll = function(){
		if($scope.allOutlets._select === true){
			$scope.outlets.forEach((outlet) => {
				outlet["_select"] = true;
			});
			$scope.allOutlets._select = true;
		} else {
			$scope.outlets.forEach((outlet) => {
				outlet["_select"] = false;
			});
			$scope.allOutlets._select = false;
		}
	};
//** БЛОК СПИСКА ЗАВЕДЕНИЙ  - СЕЛЕКТ



//** Контейнер для СПИСКА ЗАВЕДЕНИЙ  - ПОКАЗЫВАЕМ/ПРЯЧЕМ  ВЕСЬ СПИСОК
	$scope.showLine = true; // показывать линию в главном блоке
	$scope.showAllOutlets = false;   // показывать весь блок с заведениями

	$scope.showBlock = function (flag) {
		$scope.showAllOutlets = flag;
		$scope.showLine = !flag;
	};
//** Контейнер для СПИСКА ЗАВЕДЕНИЙ  - ПОКАЗЫВАЕМ/ПРЯЧЕМ  ВЕСЬ СПИСОК



	$scope.onCancel = function () {
		$state.go( 'settings.dining', $stateParams, {} );
	};



	$scope.onSave = function () {

		if($scope.setDining.$invalid) {
			$scope.setDining.focusFirstInvalidControl();
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			return false;
		}

		if ( $scope.clicked == false ) {
			return false;
		}

		function send() {
			$scope.clicked = false;
			$scope.progress = true;
			getDining.setDining( $scope.dining ).then( function ( res ) {
				if ( res.result === "ok" && !res.message ) {
					offStateChangeStart();
					serviceToast.show( 'DINING.TOSTS.CREATE_DINING', 'success' );
					$state.go( 'settings.dining', $stateParams, {
						reload: true
					} );
				} else if(res.result === "error" && res.message === 'reached_maximum_options_limit'){
					var msgTitle	 =  translate('DINING.ERR_CREATE_DINING');
					var msgContent	 =  translate('DINING.ERR_ONLY_TEN_DININGS');
					showServiceDialog(msgTitle, msgContent)
				} else if(res.result === "error" && res.message === 'option_exist'){
					$scope.uniqueName = false;
					$scope.clicked = true
					serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
				}
				else if ( res.result === "error" && res.message != 'reached_maximum_options_limit' && res.message != 'option_exist' ) {
					serviceToast.show( 'DINING.TOSTS.ERROR_CREATE_DINING', 'error' );
					console.log( res );
					$scope.clicked = true;
				}
			}, function () {
				$scope.clicked = true;
			})
			.finally(() => {
				$scope.progress = false;
			})
		}

		function showServiceDialog(msgTitle, msgContent) {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: msgTitle,
				content: msgContent,
				buttons: [
					{
						class: 'primary',
						text: 'ON_OK',
						action: function () {
							$scope.clicked = true;
						}
					}
				]
			} );
		};


		send();

	};


}

export default {
	type: 'controller',
	name: 'creatediningCtrl',
	value: creatediningCtrl
};
