/**
 * Created by Alex 2.10.17
 */

import angular from 'angular';

getDining.$inject = ["$http", "$q", "factoryErrorAlert"];

function getDining($http, $q, factoryErrorAlert) {

// получаем список всех Типы заказа
    function getDiningList(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getdiningoptions', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем список всех Типы заказа


// получаем краткий список торговых точек
    function getShortOutlets(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getshortoutlets', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.outlets);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем краткий список торговых точек


// создаем новый Тип заказа
    function setDining(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/creatediningoption', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result) {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// создаем новый Тип заказа



// Удаление Типа заказа
    function deleteDining(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deletediningoption', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        resolve(response);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Удаление Типа заказа


// Удаление всех Типов заказа
    function deleteDiningOll(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deletealldiningoption', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Удаление всех Типов заказа


// Получение Типа заказа по ID
    function getDiningById(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getdiningoptionbyid', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    resolve(response.data);
                } else {
                    resolve(response);
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Получение Типа заказа по ID




// Редактирование Типа заказа
    function editDining(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/editdiningoption', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result) {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Редактирование Типа заказа


// Измнение доступности и порядка опций для Типа заказа
    function changeDiningOrder(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/changediningoptionorder', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Измнение доступности и порядка опций для Типа заказа


    return {
        getDiningList: getDiningList,
        getShortOutlets: getShortOutlets,
        setDining: setDining,
        deleteDining: deleteDining,
        deleteDiningOll: deleteDiningOll,
        getDiningById: getDiningById,
        editDining: editDining,
        changeDiningOrder: changeDiningOrder
    };

}

export default {
    type: 'factory',
    name: 'getDining',
    value: getDining
};