createDirective.$inject = ['$parse'];

function createDirective($parse) {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        if (!ngModel) {
            return;
        }

        const isValidFn = $parse(attrs['lvCustomValidator']);

        ngModel.$validators.custom = function (modelValue, viewValue) {

            const value = modelValue || viewValue;

            return isValidFn(scope, { value: value });
        }
    }
}

export default {
    type: 'directive',
    name: 'lvCustomValidator',
    value: createDirective
};