/**
 * Created by Kiskenbassker on 21.11.2017
 */

import angular from 'angular';
import deleteImg from '../../../../img/ic_delete.svg';
import * as Sentry from '@sentry/browser';

createEditOrderController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    '$shortOutlets',
    '$q',
    '$timeout',
    'dateCustomMethods',
    'lodash',
    'serviceDialog',
    '$filter',
    '$getSuppliers',
    'factorySuppliers',
    'factoryInventory',
    'serviceToast',
    '$currencyOptionsEdit',
    '$critCountOptionsEdit',
    '$currencyOptionsShow',
    '$countOptionsShow',
    '$countOptionsEdit',
    '$countOptionsEditDivisible',
    '$quantOptionsEdit',
    '$ordersDetail',
    '$countQuantityForOrder',
    '$countQuantityForOrderDivisible',
    '$profile',
    '$currencyOptionsEditModif',
    '$translate',
    'tableViewColumnFactory',
    'serviceMinMaxValues',
    'lvMixpanel',
    '$permitsManager',
    'dialogs',
    'factoryPurchaseOrders',
    'constantStateErrors',
    'stateGo'
];

    function createEditOrderController($scope,
                                       $rootScope,
                                       $state,
                                       $stateParams,
                                       $shortOutlets,
                                       $q,
                                       $timeout,
                                       dateCustomMethods,
                                       lodash,
                                       serviceDialog,
                                       $filter,
                                       $getSuppliers,
                                       factorySuppliers,
                                       factoryInventory,
                                       serviceToast,
                                       $currencyOptionsEdit,
                                       $critCountOptionsEdit,
                                       $currencyOptionsShow,
                                       $countOptionsShow,
                                       $countOptionsEdit,
                                       $countOptionsEditDivisible,
                                       $quantOptionsEdit,
                                       $ordersDetail,
                                       $countQuantityForOrder,
                                       $countQuantityForOrderDivisible,
                                       $profile,
                                       $currencyOptionsEditModif,
                                       $translate,
                                       tableViewColumnFactory,
                                       serviceMinMaxValues,
                                       lvMixpanel,
                                       $permitsManager,
                                       dialogs,
                                       factoryPurchaseOrders,
                                       constantStateErrors,
                                       stateGo) {

        /* Main Variables */

        // console.log($rootScope);
        $scope.profile = angular.copy($profile);
        var vm = this;
        var translate = $filter('translate');
        var offStateChangeStart;
        var searchDefer;
        var enterKeydown = false;
        var searchInput;
        var itemsFound = null;

        vm.displayCost = $permitsManager.costAllowed;

        vm.suppliers = $getSuppliers.suppliers;
        vm.suppliersLoad = searchValuesLoad(vm.suppliers);
        vm.suppliersSearchText = null;

        vm.outlets = angular.copy($shortOutlets);

        vm.errDate = false;
        vm.errItemsNo = false;
        vm.currencyToCoin = $filter('currencyToCoin');
        vm.coinToCurrency = $filter('coinToCurrency');
        vm.wareCount = $filter('wareCount');
        vm.purchaseOrderCreateData = {};
        vm.purchaseOrderEditData = {};
        vm.currencyOptionsEdit = $currencyOptionsEdit;
        vm.critCountOptionsEdit = $critCountOptionsEdit;
        vm.quantOptionsEdit = $quantOptionsEdit;
        vm.currencyOptionsShow = $currencyOptionsShow;
        vm.countOptionsShow = $countOptionsShow;
        vm.countQuantityForOrder = $countQuantityForOrder;
        vm.countQuantityForOrderDivisible = $countQuantityForOrderDivisible;
        vm.countOptionsEdit = $countOptionsEdit;
        vm.countOptionsEditDivisible = $countOptionsEditDivisible;
        vm.currencyOptionsEditModif = $currencyOptionsEditModif;
        var outletsLength = vm.outlets.length;
        vm.tableHeadNowrap = ($profile.cabinetLang === 'kor' || $profile.cabinetLang === 'jpn' || $profile.cabinetLang === 'zho' || $profile.cabinetLang === 'chn');
        $scope.isSave = false;

        if (Object.keys($stateParams.purchaseDocData).length > 0 ) {
            var dataFromParams = $stateParams.purchaseDocData;
            vm.orderData = $ordersDetail ? $ordersDetail.orderData : {};
            vm.items = dataFromParams.items;
            vm.notes = dataFromParams.notes;
            vm.landedCosts = dataFromParams.landedCosts;
            $scope.startDate = dataFromParams.startDate;
            vm.startDateFormatted = formatDate($scope.startDate);
            $scope.endDate = dataFromParams.endDate;
            vm.endDateFormatted = $scope.endDate !== null ? formatDate($scope.endDate) : null;
            vm.suppliersSelectedItem = dataFromParams.suppliersSelectedItem;

            if (!$stateParams.outletId) {
                if (outletsLength > 1) {
                    vm.actualOutlet = {};
                    vm.actualOutletId = null;
                } else {
                    vm.actualOutlet = vm.outlets[0].name;
                    vm.actualOutletId = vm.outlets[0].id;
                }
            } else {
                vm.orderStoreId = parseInt($stateParams.outletId);
                vm.actualOutlet = dataFromParams.actualOutlet;
                vm.actualOutletId = parseInt($stateParams.outletId);
            }
        } else if ($state.current.name === 'inventory.duplicateorder' || $state.current.name === 'inventory.editorder') {

            // Data for edit state

            var currentSupplierId = $ordersDetail.supplier.id;
            vm.orderData = $ordersDetail.orderData;
            var orderItems = $ordersDetail.items;
            vm.orderStoreId = vm.orderData.storeId;
            var landedCosts = $ordersDetail.landedCosts;
            vm.landedCosts = landedCosts.map(function(land) {
                return {
                    amount: serviceMinMaxValues.getValidValue(vm.coinToCurrency(land.amount, "."), vm.currencyOptionsEditModif),
                    name: land.name,
                    status: $state.current.name === 'inventory.editorder' ? land.status : "PENDING",
                    id: land.id
                }
            });
            vm.items = orderItems.map(
                function(item) {
                    return {
                        id: $state.current.name === 'inventory.editorder' ? item.id : null,
                        wareId: item.wareId,
                        name: item.name,
                        sku: item.article,
                        stock: parseFloat(vm.wareCount(item.inStock, ".", false)),
                        incoming: parseFloat(vm.wareCount(item.incoming, ".", false)),
                        divisible: item.divisible,
                        keepCount: item.keepCount,
                        count: vm.wareCount(item.quantity, ".", false),
                        cost: serviceMinMaxValues.getValidValue(vm.coinToCurrency(item.supplyCost, "."), vm.currencyOptionsEdit),
                        status: $state.current.name === 'inventory.editorder' ? item.status : "PENDING"
                    }
                }
            );
            vm.notes = vm.orderData.comment;
            $scope.startDate = $state.current.name === 'inventory.editorder' ? new Date(vm.orderData.poDate) : new Date();
            $scope.endDate = (vm.orderData.expectedDate !== null && $state.current.name === 'inventory.editorder') ? (new Date(vm.orderData.expectedDate)) : null;
            vm.startDateFormatted = formatDate($scope.startDate);
            vm.endDateFormatted = $scope.endDate !== null ? formatDate($scope.endDate) : null;

            var suppliersLength = vm.suppliers.length;
            for (var i = 0; i < suppliersLength; i++) {
                if (vm.suppliers[i].id === currentSupplierId) {
                    vm.suppliersSelectedItem = {
                        value: vm.suppliers[i].name.toLowerCase(),
                        display: vm.suppliers[i].name,
                        id: vm.suppliers[i].id
                    };
                    break;
                } else if (i === (suppliersLength - 1) && vm.suppliers[i].id !== currentSupplierId) {
                    vm.suppliersSelectedItem = {
                        value: $ordersDetail.supplier.name.toLowerCase(),
                        display: $ordersDetail.supplier.name,
                        id: currentSupplierId
                    }
                }
            }
            if (outletsLength > 1) {
                for (var i = 0; i < outletsLength; i++) {
                    if (vm.outlets[i].id === vm.orderStoreId) {
                        vm.actualOutlet = vm.outlets[i].name;
                        vm.actualOutletId = vm.outlets[i].id;
                        break;
                    }
                }
            } else {
                vm.actualOutlet = vm.outlets[0].name;
                vm.actualOutletId = vm.outlets[0].id;
            }

        } else {

            // Data for create state

            vm.items = [];
            vm.notes = "";
            vm.landedCosts = [];
            $scope.startDate = new Date();
            vm.startDateFormatted = formatDate($scope.startDate);
            $scope.endDate = null;
            vm.endDateFormatted = null;
            vm.suppliersSelectedItem = null;

            if (outletsLength > 1) {
                vm.actualOutlet = {};
                vm.actualOutletId = null;
            } else {
                vm.actualOutlet = vm.outlets[0].name;
                vm.actualOutletId = vm.outlets[0].id;
            }
        }

        $scope.stateFromList = $stateParams.stateToSave;
        $scope.poDate = null;
        // $scope.poDate = $filter('formatDateByBrowser')(null, 'tableLabel');


        $scope.startDateId = document.getElementById('startDateInput').id || "startDateInput";
        $scope.endDateId = document.getElementById('endDateInput').id || "endDateInput";
        // vm.resultsLength;

        vm.cOrderStartParams = $stateParams.purchaseDocData ? $stateParams.purchaseDocData : {
            suppliersSelectedItem: vm.suppliersSelectedItem,
            actualOutlet: vm.actualOutlet,
            startDate: $scope.startDate,
            endDate: $scope.endDate,
            notes: vm.notes,
            items: angular.copy(vm.items),
            landedCosts: vm.landedCosts
        };

        const backToList = () => {
            factoryPurchaseOrders.backToPurchaseOrdersList($scope.stateFromList);
        }


        if($stateParams.newItems.length > 0) {
            var itemsCount = $stateParams.newItems.length;
            for (var j = 0; j < itemsCount; j++) {
                pushItemToArray($stateParams.newItems[j]);
            }
        }

        /* Supplier autocomplete field */

        vm.suppliersSearchQuery = function(query) {
            // var results = query ? vm.suppliersLoad.filter(createFilterFor(query)) : vm.suppliersLoad;
            // var resultsWithIndexes = results.map(function (item, i) { item.index = i; return item });
            // vm.resultsLength = results.length;
            // var deferred = $q.defer();
            // $timeout(function () { deferred.resolve( results ); }, Math.random() * 1000, false);
            return query ? vm.suppliersLoad.filter(createFilterFor(query)) : vm.suppliersLoad;
        };

        function searchValuesLoad(arr) {
            return arr.map(function(arrField) {
                return {
                    value: arrField.name.toLowerCase(),
                    display: arrField.name,
                    id: arrField.id
                }
            })
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();

            return function filterFn(arrObj) {
                return (arrObj.value.indexOf(lowercaseQuery) === 0);
            };
        }

        /* Select outlet */

        vm.selectActualOutlet = function(actualOutlet) {
            if (vm.actualOutlet !== null) {
                vm.actualOutlet = angular.copy(actualOutlet);
                vm.actualOutletId = vm.actualOutlet.id;
            } else {
                vm.actualOutlet = {};
                vm.actualOutletId = null;
            }
        };

        vm.outletChange = function() {
            if (vm.items.length > 0) {
                var outletsData = {
                    outletId: vm.actualOutletId,
                    items: getItemsIds()
                };
                factoryInventory.getOrderItemsForOutlet(outletsData)
                    .then(function(data) {
                        // console.log(data);
                        data.items.forEach(
                            function(item, i, data) {
                                vm.items.forEach(
                                    function(product, j, arr) {
                                        if (item.id === product.wareId) {
                                            product.stock = parseFloat(vm.wareCount(item.stock, ".", false));
                                            product.incoming = parseFloat(vm.wareCount(item.incoming, ".", false));
                                        }
                                    }
                                )
                            }
                        );
                    })
            }
        };

        /* Date picker */

        $scope.$watch('dateFrom', function(newVal, oldVal) {
            if (newVal !== undefined && newVal !== oldVal) {
                if ($scope.startDateId && ($scope.datePickerId === $scope.startDateId)) {
                    $scope.startDate = newVal;
                    vm.startDateFormatted = formatDate($scope.startDate);
                    compareDates(vm.startDateFormatted, vm.endDateFormatted);
                } else if ($scope.endDateId && ($scope.datePickerId === $scope.endDateId)) {
                    $scope.endDate = newVal;
                    vm.endDateFormatted = formatDate($scope.endDate); //+ (3600 * 24 * 1000 - 1); //adding milliseconds to make end date at 23:59
                    compareDates(vm.startDateFormatted, vm.endDateFormatted);
                }
            }
        });

        function formatDate(date) {
            return dateCustomMethods.setTimeToZero(Date.parse(date));
        }

        function compareDates(startDateFormatted, endDateFormatted) {
            vm.errDate = (endDateFormatted !== null) && (startDateFormatted > endDateFormatted);
        }

        /* Add supplier popup */

        function refreshSuppliers() {
            factoryInventory.getSuppliers({ search: null }).then(function(data) {
                vm.suppliers = data.suppliers;
                vm.suppliersLoad = searchValuesLoad(vm.suppliers);
            })
        }

        vm.addSupplier = function() {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-add-supplier.html',
                title: translate('INVENTORY.ADD_SUPPLIER'),
                supplierData: vm.supplierData,
                buttons: [{
                    text: 'ON_CANCEL',
                    action: function() {
                        return true;
                    }
                },
                    {
                        class: $scope.arapro ? "arabic-save" : "",
                        text: 'ON_SAVE',
                        action: function() {
                            var data = vm.supplierData;

                            factorySuppliers.createSupplier(data).then(function(res) {
                                if (res.result === "ok") {
                                    serviceToast.show('INVENTORY.SUPPLIER_ADDED', 'success');
                                    refreshSuppliers();
                                } else {
                                    serviceToast.show('INVENTORY.SUPPLIER_NAME_ALREADY_EXISTS', 'error');
                                }
                            })
                        }
                    }
                ]
            });
        };

        vm.supplierData = {
            name: "",
            contact: null,
            email: null,
            phone: null,
            site: null,
            address1: null,
            address2: null,
            city: null,
            zip: null,
            country: null,
            comment: null
        };

        /* Items autocomplete */

        vm.autocompleteItems = {
            searchText: null,
            selectedItem: null,
            totalAmount: function() {
                return vm.items
                    .filter(function(item) {
                        return item.cost !== null;
                    })
                    .map(function(item) {
                        var count = parseFloat(item.count);
                        var cost = parseFloat(item.cost);

                        if (!isNaN(count) && !isNaN(cost)) {
                            return (Math.round(vm.wareCount(count, ".", true) * vm.currencyToCoin(cost, ".") / 1000) / 100)
                                .toFixed(2);
                        } else {
                            return 0;
                        }
                    })
                    .concat(vm.landedCosts.map(function(elem) {
                        return (elem.amount) ? elem.amount : 0;
                    }))
                    .reduceRight(function(l, r) {
                        if ((parseFloat(l) + parseFloat(r)).toFixed(2) > 0) {
                            document.querySelector('.functional-error').style.display = 'none';
                        }
                        return (parseFloat(l) + parseFloat(r)).toFixed(2);
                    }, vm.currencyOptionsShow.decimalPlacesOverride !== 0 ? "0" + vm.currencyOptionsShow.decimalCharacter + "00" : "0");
            },
            getMatches: function(query) {
                searchDefer = $q.defer();
                factoryInventory
                    .searchItemsForPurchaseOrder({
                        search: query,
                        outletId: vm.actualOutletId
                    })
                    .then(function(items) {
                        searchDefer.resolve(items);
                        itemsFound = angular.copy(items);
                    })
                    .then(function() {
                        if (enterKeydown) {
                            if (Array.isArray(itemsFound) && itemsFound.length === 1 && itemsFound[0].barcode === vm.autocompleteItems.searchText) {
                                vm.autocompleteItems.selectedItemChange(itemsFound[0]);
                                searchInput.focus();
                                enterKeydown = false;
                            }
                        }
                    });
                return searchDefer.promise;
            },
            selectedItemChange: function(item) {
                if (item) {
                    var itemsLength = vm.items.length;
                    if (itemsLength !== 0) {
                        for (var i = 0; i < itemsLength; i++) {
                            if (item.id === vm.items[i].wareId) {
                                serviceDialog.add({
                                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                    content: translate('INVENTORY.ADDING_SAME_MESSAGE'),
                                    buttons: [{
                                        text: 'ON_CANCEL',
                                        action: function() {
                                            return true;
                                        }
                                    },
                                        {
                                            text: 'ON_ADD',
                                            action: function() {
                                                pushItemToArray(item);
                                                return true;
                                            }
                                        }
                                    ]
                                });
                                break;
                            } else if (i === itemsLength - 1) {
                                pushItemToArray(item);
                            }
                        }
                    } else {
                        pushItemToArray(item);
                    }
                    $('[name="autocompleteItems"]').blur();
                    this.selectedItem = null;
                    this.searchText = null;
                }
            }
        };

        function pushItemToArray(item) {
            var itemObj = {
                id: null,
                /* Identifies that position is newly added */
                wareId: item.id,
                name: item.name,
                sku: item.sku,
                stock: parseFloat(vm.wareCount(item.stock, ".", false)),
                incoming: parseFloat(vm.wareCount(item.incoming, ".", false)),
                divisible: item.divisible,
                keepCount: item.keepCount,
                count: item.quantity ? vm.wareCount(item.quantity, ".", false) : null,
                cost: (item.purchaseCost !== null) ? vm.coinToCurrency(item.purchaseCost, ".") : (vm.displayCost ? null : '0'),
                status: null
            };
            vm.items = vm.items.concat(itemObj);
        }

        // vm.getAmount = function(cost, quantity) {
        //
        //     var count = parseFloat(quantity);
        //     var price  = parseFloat(cost);
        //
        //     if (!isNaN(count) && !isNaN(price)) {
        //         return ((Math.round(vm.wareCount(count, ".", true) * vm.currencyToCoin(price, ".") / 1000)) / 100).toFixed(2);
        //     } else {
        //         return vm.currencyOptionsShow.decimalPlacesOverride !== 0 ? "0" + vm.currencyOptionsShow.decimalCharacter + "00" : "0";
        //     }
        // };

        vm.getAmount = function(cost, quantity) {
            return ((Math.round(vm.wareCount(quantity, ".", true) * vm.currencyToCoin(cost, ".") / 1000)) / 100).toFixed(2);
        }



        function getItemsIds() {
            return vm.items.map(
                function(item) {
                    return item.wareId;
                }
            );
        }

        function totalAmountNumCount(amount) {
            var count = 0;
            do {
                amount /= 10;
                count++
            } while (amount >= 1);

            return count;
            // console.log("Numbers Amount: ", vm.totalAmountNumCount);
        }

        /* Items table */

        vm.deleteItem = item => {
            angular.forEach(vm.items, function(val, i) {
                if (item === val) {
                    vm.items = vm.items.filter((item, index) => index !== i);
                }
            });
        };

        vm.columns = buildColumns();

        function buildColumns() {
            const result = [];

            // Item
            const nameOptions = {
                minWidth: 184
            };
            const nameFields = [];
            nameFields.push({
                isVisible: item => item.status === 'RECEIVED',
                displayValue: $translate.instant('INVENTORY.MARK.RECEIVED'),
                class: 'purchase-order-item-status'
            });
            nameFields.push({
                displayValue: $translate.instant('ARTICLE'),
                valueGetter: 'sku',
                class: 'l-comment'
            });
            result.push(tableViewColumnFactory.createPropertyViewExtendedColumn(
                $translate.instant('INVENTORY.ITEMS_TABLE.ITEM'), 'name', nameFields, nameOptions));

            // In stock
            const stockOptions = {
                name: 'inStock',
                width: 'auto',
                attrs: {
                    'name': 'itemStock',
                    'aria-label': 'itemStock',
                    'input-autosize': 'input-autosize',
                    'column-index': 1,
                    'disabled': 'disabled'
                },
                referenceAttrs: {
                    'currency-localization': vm.countOptionsEdit
                },
                isVisible: item => item.keepCount && vm.actualOutletId !== null,
                extraField: {
                    value: '-',
                    class: 'purchase-order-item-nostock',
                    isVisible: item => !item.keepCount
                }
            };

            result.push(tableViewColumnFactory.createInputColumn(
                $translate.instant('INVENTORY.ITEMS_TABLE.STOCK'),
                'stock', stockOptions));

            // Incoming
            const incomingOptions = {
                name: 'incoming',
                width: 'auto',
                attrs: {
                    'name': 'itemIncoming',
                    'aria-label': 'itemIncoming',
                    'input-autosize': 'input-autosize',
                    'column-index': 2,
                    'disabled': 'disabled'
                },
                referenceAttrs: {
                    'currency-localization': vm.critCountOptionsEdit
                },
                isVisible: item => item.keepCount && vm.actualOutletId !== null,
                extraField: {
                    value: '-',
                    class: 'purchase-order-item-nostock',
                    isVisible: item => !item.keepCount
                }
            }
            result.push(tableViewColumnFactory.createInputColumn(
                $translate.instant('INVENTORY.ITEMS_TABLE.INCOMING'),
                'incoming', incomingOptions));

            // Quantity
            const quantityOptions = {
                name: 'quantity',
                width: 104,
                noShrink: true,
                attrs: {
                    'name': 'itemCount',
                    'aria-label': 'itemCount',
                    'ng-model-options': '{updateOn: "blur"}',
                    'non-zero': 'non-zero',
                    'autocomplete': 'off',
                    'lv-input-value': ''
                },
                referenceAttrs: {
                    'currency-localization': item => item.divisible ? vm.countQuantityForOrderDivisible : vm.countQuantityForOrder
                },
                isDisabled: item => item.status === 'RECEIVED',
                isRequired: item => item.status !== 'RECEIVED',
                buildClasses: item => item.status === 'RECEIVED' ? 'checkbox-table-input-disabled' : ''
            };
            result.push(tableViewColumnFactory.createInputColumn(
                $translate.instant('INVENTORY.ITEMS_TABLE.QUANTITY'), 'count', quantityOptions));

            if (vm.displayCost) {
                // Purchase cost
                const costOptions = {
                    name: 'purchaseCost',
                    width: 96,
                    noShrink: true,
                    attrs: {
                        'name': 'itemCost',
                        'aria-label': 'itemCost',
                        'autocomplete': 'off',
                        'ng-model-options': '{updateOn: "blur"}',
                        'lv-input-value': ''
                    },
                    referenceAttrs: {
                        'currency-localization': vm.currencyOptionsEdit
                    },
                    isDisabled: item => item.status === 'RECEIVED',
                    isRequired: item => item.status !== 'RECEIVED',
                    buildClasses: item => item.status === 'RECEIVED' ? 'checkbox-table-input-disabled' : ''
                };
                result.push(tableViewColumnFactory.createInputColumn(
                    $translate.instant('INVENTORY.ITEMS_TABLE.SUPPLY_COST'), 'cost', costOptions));

                // Amount
                const amountOptions = {
                    name: 'amount',
                    width: 96,
                    noShrink: true,
                    attrs: {
                        'name': 'itemAmount',
                        'aria-label': 'itemAmount',
                        'input-autosize': 'input-autosize',
                        'column-index': 5,
                        'ng-model-options': '{ getterSetter: true }',
                        'disabled': 'disabled'
                    },
                    referenceAttrs: {
                        'currency-localization': vm.currencyOptionsShow
                    },
                };
                result.push(tableViewColumnFactory.createInputColumn(
                    $translate.instant('INVENTORY.ITEMS_TABLE.AMOUNT'),
                    angular.bind(vm, getAmountNew), amountOptions));
            }

            // Delete
            const delColumnOptions = {
                width: 48,
                noShrink: true,
                isVisible: item => item.status !== 'RECEIVED'
            };
            result.push(tableViewColumnFactory.createActionColumn(
                null, deleteImg, angular.bind(vm, vm.deleteItem), delColumnOptions));

            return result;
        }

        function getAmountNew(item) {
            return ((Math.round(vm.wareCount(item.count, ".", true) * vm.currencyToCoin(item.cost, ".") / 1000)) / 100).toFixed(2);
        }

        function checkEmptyNumberInput(item) {
            var forCheckQuantityValue = (item.count && item.count.includes(',')) ? item.count.replace(',', '.') : item.count;
            return (parseFloat(forCheckQuantityValue) !== 0) &&
                item.cost !== null &&
                item.count !== undefined &&
                item.count !== null &&
                item.cost !== undefined &&
                item.cost !== "" &&
                item.count !== "";
        }

        function checkEmptyAdditionalInput(item) {
            return item.amount !== null &&
                item.amount !== undefined &&
                item.name.length <= 64 &&
                item.name.length !== 0;
        }

        function checkBeforeSave(event) {
            vm.errItemsNo = vm.items.every(checkEmptyNumberInput);
            vm.errLanded = vm.landedCosts.every(checkEmptyAdditionalInput);

            if (vm.autocompleteItems.totalAmount() < 0) {
                document.querySelector('.functional-error').style.display = 'block';
            }

            if ((vm.actualOutletId === null) ||
                (vm.suppliersSelectedItem === null) ||
                (vm.items.length < 1) ||
                !vm.errItemsNo ||
                ((vm.startDateFormatted > vm.endDateFormatted) && (vm.endDateFormatted !== null)) ||
                vm.notes.length > 500 ||
                (vm.landedCosts.length && !vm.errLanded) ||
                vm.autocompleteItems.totalAmount() < 0) {
                serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                return false;
            }

            return true;
        }

        // $scope.$watch('vm.autocompleteItems.totalAmount()', function(newVal, oldVal){
        //     if (newVal && newVal !== oldVal && document.querySelector('.functional-error').style.display === 'block') {
        //         document.querySelector('.functional-error').style.display = 'none';
        //     }
        // })

        // $scope.showAmount = function(item) { console.log("Amount: ", item.cost) };
        // $scope.showQuantity = function(item) { console.log("Quantity: ", item.count) };

        /* Save button for create order state */
        vm.cOrderSave = function(draft) {
            if (checkBeforeSave()) {
                $scope.isSave = true;
                vm.purchaseOrderCreateData = {
                    supplierId: vm.suppliersSelectedItem.id,
                    outletId: vm.actualOutletId,
                    poDate: vm.startDateFormatted,
                    // poDate: $filter('formatDateByBrowser')(vm.startDateFormatted, 'tableLabel'),
                    expectedDate: vm.endDateFormatted,
                    saveAsDraft: draft,
                    comment: vm.notes,
                    items: vm.items.map(
                        function(item) {
                            return {
                                wareId: item.wareId,
                                quantity: parseInt(vm.wareCount(item.count, ".", true), 10),
                                primeCost: parseInt(vm.currencyToCoin(item.cost), 10)
                            }
                        }
                    ),
                    landedCosts: vm.landedCosts.map(
                        function(elem) {
                            return {
                                name: elem.name,
                                amount: parseInt(vm.currencyToCoin(elem.amount), 10)
                            }
                        }
                    )
                };
                factoryInventory.createPurchaseOrder(vm.purchaseOrderCreateData).then(function(res) {
                    if (res.result === "ok") {
                        offStateChangeStart();
                        if (!draft) {
                            serviceToast.show('INVENTORY.CREATE_ORDER_SUCCESS', 'success');
                        } else {
                            serviceToast.show('INVENTORY.SAVE_ORDER_SUCCESS', 'success');
                        }
                        $state.go('inventory.orederdetail', {
                            id: res.newId
                        })
                    } else {
                        serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                    }
                });
                // console.log(vm.purchaseOrderCreateData);
            }
        };

        /* Save button for edit state */

        vm.eOrderSave = function(draft) {
            if (checkBeforeSave()) {
                $scope.isSave = true;
                vm.purchaseOrderEditData = {
                    id: vm.orderData.id,
                    supplierId: vm.suppliersSelectedItem.id,
                    outletId: vm.actualOutletId,
                    poDate: vm.startDateFormatted,
                    expectedDate: vm.endDateFormatted,
                    saveAsDraft: draft,
                    comment: vm.notes,
                    items: vm.items.map(
                        function(item) {
                            return {
                                id: item.id,
                                wareId: item.wareId,
                                quantity: parseInt(vm.wareCount(item.count, ".", true), 10),
                                primeCost: parseInt(vm.currencyToCoin(item.cost), 10)
                            }
                        }
                    ),
                    landedCosts: vm.landedCosts.map(
                        function(elem) {
                            if (elem.id) {
                                return {
                                    id: elem.id,
                                    name: elem.name,
                                    amount: parseInt(vm.currencyToCoin(elem.amount), 10)
                                }
                            } else {
                                return {
                                    name: elem.name,
                                    amount: parseInt(vm.currencyToCoin(elem.amount), 10)
                                }
                            }
                        }
                    )
                };
                factoryInventory.editPurchaseOrder(vm.purchaseOrderEditData).then(function(res) {
                    if (res.result === "ok") {
                        offStateChangeStart();
                        if ($state.current.name === 'inventory.editorder' && vm.orderData.status === 'DRAFT') {
                            serviceToast.show('INVENTORY.CREATE_ORDER_SUCCESS', 'success');
                        } else {
                            serviceToast.show('INVENTORY.SAVE_ORDER_SUCCESS', 'success');
                        }

                        $state.go('inventory.orederdetail', {
                            id: vm.orderData.id
                        })
                    } else if (res.result === "error") {
                        offStateChangeStart();
                        switch (res.message) {
                            case "purchase_order_deleted":
                                dialogs.itemDeletedEarlier().then(backToList);
                                break;
                            case "purchase_order_closed":
                                dialogs.purchaseOrderClosed().then(
                                  () => {
                                      $state.go('inventory.orederdetail', {
                                          id: $stateParams.id
                                      });
                                  }
                                )
                                break;
                            case "purchase_order_obsolete":
                                dialogs.purchaseOrderObsolete().then(stateGo.refresh, () => { $scope.isSave = false });
                                break;
                            default:
                                serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                        }
                    } else {
                        serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                    }
                });
            }
        };

        /* Preventing form from being sent by Enter button */

        $('form').keydown(function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                return false;
            }
        });

        /* Cancel button */

        vm.cOrderCancel = function() {
            $stateParams.page = 0;
            offStateChangeStart();
            if ($state.current.name === 'inventory.createorder' || $state.current.name === 'inventory.duplicateorder') {
                backToList();
            } else {
                $state.go('inventory.orederdetail', {
                    id: $stateParams.id
                });
            }
        };

        offStateChangeStart = $rootScope.$on('$stateChangeStart', function(event, _toState, _toParam, fromState, fromParam) {
            vm.cOrderFinalParams = {
                suppliersSelectedItem: vm.suppliersSelectedItem,
                actualOutlet: vm.actualOutlet,
                startDate: $scope.startDate,
                endDate: $scope.endDate,
                notes: vm.notes,
                items: vm.items,
                landedCosts: vm.landedCosts
            };

            // console.log(vm.cOrderStartParams, vm.cOrderFinalParams);

            if (!angular.equals(vm.cOrderStartParams, vm.cOrderFinalParams)) {
                event.preventDefault();
                $timeout(function() {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        title: $filter('translate')('UNSAVED_TITLE'),
                        content: $filter('translate')('UNSAVED_LEAVE'),
                        buttons: [{
                            text: 'UNSAVED_CONTINUE',
                            action: function() {

                            }
                        },
                            {
                                class: 'primary',
                                text: 'UNSAVED_DISCARD',
                                action: function() {
                                    offStateChangeStart();
                                    $state.go(_toState, _toParam);
                                }
                            }
                        ]
                    });
                });
            } else {
                offStateChangeStart();
            }
        });

        vm.addLandCost = function() {
            if (vm.landedCosts.length < 10) {
                var object = {
                    id: null,
                    name: '',
                    amount: null,
                    status: 'PENDING'
                };
                vm.landedCosts.push(object);
            }
        };

        vm.deleteLandCost = $index => {
            vm.landedCosts = vm.landedCosts.filter((item, index) => index !== $index);
        };

        vm.addByBarcode = function($event) {
            if ($event.keyCode === 13) {
                enterKeydown = true;
                searchInput = $event.target;
                angular.element(searchInput).focus();
            }
        };

        /* Items auto fill */

        vm.itemsAutoFill = function(lowStock) {
            if ($state.current.name === 'inventory.createorder' || $state.current.name === 'inventory.duplicateorder' || $state.current.name === 'inventory.editorder' && vm.orderData.status === 'DRAFT') {
                //var timer = setTimeout(function () {
                $scope.isLoading = true;
                //}, 2000);
                factoryInventory.searchItemsForAutofillPurchaseOrder({
                    lowStock: lowStock,
                    supplierId: vm.suppliersSelectedItem.id,
                    outletId: vm.actualOutletId
                }).then(function(res) {
                    $scope.isLoading = false;
                    if (res.result === "ok") {
                        // clearTimeout(timer);
                        var itemsBy = [],
                            itemsLength = vm.items.length,
                            itemsAlreadyAdded = [],
                            reordersForItemsAdded = [];

                        res.items.forEach(
                            function(item, j, arr) {
                                var itemToAdd;
                                if (itemsLength > 0) {
                                    vm.items.forEach(function(addedItem, i) {
                                        if (addedItem.wareId === item.id) {
                                            itemToAdd = false;
                                            itemsAlreadyAdded.push(item.id);
                                            reordersForItemsAdded.push(item.reorderQuantity);
                                        } else if (i === itemsLength - 1 && itemToAdd !== false) {
                                            itemToAdd = true;
                                        }
                                    });
                                } else {
                                    itemToAdd = true;
                                }
                                if (itemToAdd) {
                                    itemsBy.push({
                                        id: null,
                                        /* Identifies that position is newly added */
                                        wareId: item.id,
                                        name: item.name,
                                        sku: item.sku,
                                        stock: parseFloat(vm.wareCount(item.stock, ".", false)),
                                        incoming: parseFloat(vm.wareCount(item.incoming, ".", false)),
                                        divisible: item.divisible,
                                        keepCount: item.keepCount,
                                        count: returnCountFromReorderQuantity(item.reorderQuantity, item.divisible),
                                        cost: (item.purchaseCost !== null) ? vm.coinToCurrency(item.purchaseCost, ".") : null,
                                        status: null
                                    });
                                }
                                if (j === arr.length - 1) {
                                    if (itemsAlreadyAdded.length > 0) {
                                        serviceDialog.add({
                                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                            content: translate(returnStringFromNumber(itemsAlreadyAdded.length), { count: itemsAlreadyAdded.length }),
                                            buttons: [{
                                                text: "ON_CANCEL",
                                                action: function() {
                                                    itemsBy.splice(0, itemsBy.length);
                                                    itemsAlreadyAdded.splice(0, itemsAlreadyAdded.length);
                                                    return false;
                                                }
                                            },
                                                {
                                                    text: "PAYMENT_TYPES.CONTINUE",
                                                    class: "md-primary",
                                                    action: function() {
                                                        var uniqueItems = uniqueKeys(itemsAlreadyAdded);
                                                        if (uniqueItems.length !== itemsAlreadyAdded.length) {
                                                            var itemsRenewed = [];
                                                            vm.items.forEach(function(addedItem) {
                                                                var itemsAlreadyAddedIndex = itemsAlreadyAdded.indexOf(addedItem.wareId);
                                                                if (addedItem.status !== 'RECEIVED' && itemsAlreadyAddedIndex !== -1) {
                                                                    if (itemsRenewed.indexOf(addedItem.wareId) !== -1) {
                                                                        addedItem.count = "";
                                                                    } else {
                                                                        addedItem.count = returnCountFromReorderQuantity(reordersForItemsAdded[itemsAlreadyAddedIndex], addedItem.divisible);
                                                                        // addedItem.count = reordersForItemsAdded[itemsAlreadyAddedIndex] !== null
                                                                        //     ? vm.wareCount(reordersForItemsAdded[itemsAlreadyAddedIndex], ".", false)
                                                                        //     : ""
                                                                    }
                                                                    itemsRenewed.push(addedItem.wareId);
                                                                }
                                                            });
                                                            itemsRenewed.splice(0, itemsRenewed.length);
                                                        } else {
                                                            vm.items.forEach(function(addedItem) {
                                                                var itemsAlreadyAddedIndex = itemsAlreadyAdded.indexOf(addedItem.wareId);
                                                                if (addedItem.status !== 'RECEIVED' && itemsAlreadyAddedIndex !== -1) {
                                                                    addedItem.count = returnCountFromReorderQuantity(reordersForItemsAdded[itemsAlreadyAddedIndex], addedItem.divisible);
                                                                    // addedItem.count = reordersForItemsAdded[itemsAlreadyAddedIndex] !== null
                                                                    //     ? vm.wareCount(reordersForItemsAdded[itemsAlreadyAddedIndex], ".", false)
                                                                    //     : ""
                                                                }
                                                            });
                                                        }
                                                        vm.items = vm.items.concat(itemsBy);
                                                        itemsBy.splice(0, itemsBy.length);
                                                        itemsAlreadyAdded.splice(0, itemsAlreadyAdded.length);
                                                    }
                                                }
                                            ]
                                        });
                                    } else {
                                        vm.items = vm.items.concat(itemsBy);
                                        itemsBy.splice(0, itemsBy.length);
                                        itemsAlreadyAdded.splice(0, itemsAlreadyAdded.length);
                                    }
                                }
                            }
                        );
                    } else if (res.data.result === "error") {
                        Sentry.captureMessage("Autofill items error: " + res.data.message);
                    }
                })
            }
        };

        vm.toImportState = function () {
            vm.cOrderFinalParams = {
                suppliersSelectedItem: vm.suppliersSelectedItem,
                actualOutlet: vm.actualOutlet,
                startDate: $scope.startDate,
                endDate: $scope.endDate,
                notes: vm.notes,
                items: vm.items,
                landedCosts: vm.landedCosts
            };
            offStateChangeStart();
            $state.go( 'inventory.purchaseImport', { state: $state.current.name,
                                                    id: $stateParams.id ? $stateParams.id : null,
                                                    outletId: vm.actualOutletId,
                                                    purchaseDocData: vm.cOrderFinalParams});
        }

        function uniqueKeys(arr) {
            var obj = {};
            for (var i = 0; i < arr.length; i++) {
                var str = arr[i];
                obj[str] = true;
            }
            return Object.keys(obj);
        }

        function returnStringFromNumber(count) {
            if ($profile.cabinetLang === 'eng') {
                return count > 1 ? 'INVENTORY.AUTOFILL.WARNING_CHANGE_COUNT' : 'INVENTORY.AUTOFILL.WARNING_CHANGE_COUNT_ONE';
            } else if ($profile.cabinetLang === 'rus') {
                var countString = count.toFixed(0),
                    numberToCheck = countString.length > 2 ?
                        parseInt(countString[countString.length - 2] + countString[countString.length - 1]) :
                        parseInt(countString);
                if (numberToCheck === 1 || ((numberToCheck - 1) % 10 === 0 && numberToCheck !== 11)) {
                    return 'INVENTORY.AUTOFILL.WARNING_CHANGE_COUNT_ONE';
                } else {
                    return 'INVENTORY.AUTOFILL.WARNING_CHANGE_COUNT'
                }
            } else {
                return 'INVENTORY.AUTOFILL.WARNING_CHANGE_COUNT';
            }
        }

        function returnCountFromReorderQuantity(reorderQuantity, divisible) {
            if (reorderQuantity !== null) {
                if (reorderQuantity <= 999999999) {
                    return vm.wareCount(reorderQuantity, ".", false);
                } else if (divisible) {
                    return "999999.999";
                } else {
                    return "999999";
                }
            } else {
                return "";
            }
        }
    }


export default {
    type: 'controller',
    name: 'createEditOrderController',
    value: createEditOrderController
};
