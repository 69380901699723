/**
 * Created by sirosh on 08.02.16.
 * @memberof randomFromTo
 * @ngdoc service
 * @name randomFromTo
 * @returns {service} getRandomInt - return random number between from and to, doesn't use to
 * @this
 */

function randomFromTo() {

    this.getRandomInt = getRandomInt;

    /**
     * @param min
     * @param max
     * @returns {Number}
     */
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
}

export default {
    type: 'service',
    name: 'randomFromTo',
    value: randomFromTo
};