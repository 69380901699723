createDirective.$inject = ['$parse'];

function createDirective($parse) {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        if (!ngModel) {
            return;
        }

        let getIsValid = $parse(attrs['lvManualValidator']);
        let setIsValid = getIsValid.assign;

        scope.$watch(function() { return getIsValid(scope); }, function(value) {
            ngModel.$setValidity('valid', value);
            if (value === false) {
                // Set touched for correct display of invalid state
                ngModel.$setTouched();
            }
        });

        ngModel.$parsers.push(function(viewValue) {
            if (viewValue !== ngModel.$modelValue) {
                setIsValid(scope, true);
            }
            return viewValue;
        });
    }
}

export default {
    type: 'directive',
    name: 'lvManualValidator',
    value: createDirective
};