/**
 * Language sets
 * Created by Nikitin Oleksii on 31.08.2023
 */

const langSets = {
    shiftHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    timeClockLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    openTicketsHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    kitchenPrintersHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    customerDisplayHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    diningHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    stockNotificationsHelpLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    negativeInventoryHelpLinkLangs: ["ru", "es", "jp", "ko", "idn", "de", "ro", "fr", "ar", "br", "pl", "it", "gr", "zh"],
    weightBarcodeHelpLinkLangs: ["ru", "es", "jp", "de", "ro", "th", "fr", "ar", "br", "pl", "it", "gr", "zh"]
}

export default {
    type: 'constant',
    name: 'langSets',
    value: langSets
}
