import angular from 'angular';

createDirective.$inject = ['currencyFormat', '$q'];

function createDirective(currencyFormat, $q) {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {
        let moreThenVal = null;

        attrs.$observe('lvMoreLocalized', function(val) {
            if (!angular.isNumber(val)) {
                val = parseFloat(val, 10);
            }
            moreThenVal = angular.isNumber(val) && !isNaN(val) ? val : null;
            ngModel.$validate();
        });

        ngModel.$asyncValidators.more = function(value) {
            if (moreThenVal == null) {
                return $q.resolve();
            }

            return currencyFormat.getCurrencyOptionsEdit()
                .then(currencyOptionsEdit => {
                    let decSeparator = currencyOptionsEdit.decimalCharacter;
                    let valueProcessed = processLocalizedString(value, decSeparator);
                    if (ngModel.$isEmpty(value) || isNaN(valueProcessed) || valueProcessed > moreThenVal) {
                        return $q.resolve();
                    }
                    return $q.reject();
                });
        };
}

    /**
     * Processes localized value, created by currency-localization, to standard float
     * @param value value to process
     * @param decimalSeparator decimal separator
     *
     * @return value processed to float
     */
    function processLocalizedString(value, decimalSeparator) {
        if (value.match(/^\d+\.*\d{0,2}$/))
            return parseFloat(value);

        const pattern = "[^\\d\\" + decimalSeparator + "]";
        const regex = new RegExp(pattern, 'g');

        let valWithoutSymbols = value.replace(regex, "");

        return parseFloat(valWithoutSymbols.replace(decimalSeparator, "."));
    }
}

export default {
    type: 'directive',
    name: 'lvMoreLocalized',
    value: createDirective
};
