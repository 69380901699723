import angular from 'angular';

factoryDOM.$inject = [];

function factoryDOM() {
  return {
    missing: (selector) => document.querySelector(selector) === null,

    present: (selector) => document.querySelector(selector) !== null,

    remove: (selector) => {
      if (present(selector)) {
        document.querySelector(selector).remove();
      }
    },

    wait: (ready) =>
      new Promise((resolve) => {
        if (ready()) {
          return resolve();
        }

        const observer = new MutationObserver(() => {
          if (ready()) {
            observer.disconnect();
            resolve();
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }),
  };
}

export default {
  type: 'factory',
  name: 'factoryDOM',
  value: factoryDOM,
};
