filterRepairCurrenFormatWhenFocus.$inject = ['currencyFormat'];

function filterRepairCurrenFormatWhenFocus(currencyFormat) {

    // decimalCharacter - разделитель десятичных  .
    // digitGroupSeparator - разделитель тыся  ,
    var arrVal;

    let settings = {};
    currencyFormat.observable.countQuantityForOrderDivisible.observe(result => {
        settings = result;
    }, true);

    format.$stateful = true;
    function format( val) {


        // if(val !== '0.000') {
        //     console.log("format = ", format);
        //     console.log("вход = ", val);
        // }
        if((val.includes(',')) && settings.decimalCharacter === "," && settings.digitGroupSeparator === " ") {
            val = val.replace(',','.').replace(/\s/g,'');
            // console.log("выход = ", val);
            return val;
        }
        if((val.includes(',')) && settings.decimalCharacter === "," && settings.digitGroupSeparator === ".") {
            val = val.replace(/\./g,'').replace(',','.');
            // console.log("выход = ", val);
            return val;
        }
        if((val.includes(',')) && settings.digitGroupSeparator === ",") {
            val = val.replace(/,/g,'');
            // console.log("выход = ", val);
            return val;
        }
        if((val.includes(' ')) && settings.digitGroupSeparator === " ") {
            val = val.replace(/\s/g,'');
            // console.log("выход = ", val);
            return val;
        }
        // if(val.includes('.') && !val.includes(',') && format.decimalCharacter === "," && format.digitGroupSeparator === ".") {
        //     // arrVal= val.split(".");
        //     // console.log("arrVal = ", arrVal);
        //     val = val.replace(/\./g,'');
        //     console.log("выход = ", val);
        //     return val;
        // }
        else {
            // console.log("выход = ", val);
            return val;
        }
        // if(val !== '0.000') {
        //     console.log("выход = ", val);
        // }
        // return val;
    }

    return format;
}

export default {
    type: 'filter',
    name: 'filterRepairCurrenFormatWhenFocus',
    value: filterRepairCurrenFormatWhenFocus
};
