/**
 * Created by sirosh on 29.02.16.
 */

import angular from 'angular';

employeePermitsCtrl.$inject = [
    '$state',
    '$stateParams',
    '$filter',
    '$permits',
    'getPermits',
    'serviceToast',
    'serviceDialog',
    '$profile',
    'lodash',
    '$scope',
    'permitsSettings',
    'dialogs'];
function employeePermitsCtrl(
      $state,
      $stateParams,
      $filter,
      $permits,
      getPermits,
      serviceToast,
      serviceDialog,
      $profile,
      lodash,
      $scope,
      permitsSettings,
      dialogs) {

    const permCtrl = this;
    const translate = $filter('translate');

    permCtrl.options = {};
    permCtrl.role = {};
    permCtrl.clicked = true;
    permCtrl.profile = $profile;
    permCtrl.stateFromList = $stateParams.stateToSave;

    permCtrl.permitsForPOS = angular.copy(permitsSettings.pos);
    permCtrl.permitsForBackOffice = angular.copy(permitsSettings.backOffice);

    const isChanged = () => {
        const currentParams = {
            name: permCtrl.role.name,
            permitsForPOS: angular.copy(permCtrl.permitsForPOS),
            permitsForBackOffice: angular.copy(permCtrl.permitsForBackOffice)
        };
        return !angular.equals(initParams, currentParams);
    }

    const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    const disableRights = (item, rightsEditDisabled) => {
        if(angular.isObject(item) && rightsEditDisabled === true) {
            item.disabled = true;
        }
    }

    permCtrl.userAccessBilling = $profile.billingInfo.hasAccessToBilling;

    if ($state.current.name === 'employees.createpermits') {
        permCtrl.options.showDelBtn = false;
        permCtrl.role.name = '';

        permCtrl.rightsEditDisabled = false;

    } else {
        permCtrl.employeesCount = $permits.merchantsCount;
        permCtrl.permitsArr = $permits.permissions;

        permCtrl.options.type = $permits.type;

        const type = $permits.type.toString();

        const currentlyLogged = $permits.currentlyLogged;

        const types = {
            'OWNER': () => {
                permCtrl.role.name = $permits.name || translate('OWNER');
            },
            'ADMINISTRATOR': () => {
                permCtrl.role.name = $permits.name || translate('ADMINISTRATOR');
            },
            'MANAGER': () => {
                permCtrl.role.name = $permits.name || translate('MANAGER');
            },
            'CASHIER': () => {
                permCtrl.role.name = $permits.name || translate('CASHIER');
            },
            'CUSTOM': () => {
                permCtrl.role.name = $permits.name;
            }
        };

        types[type]();

        permCtrl.options.showDelBtn = true;

        permCtrl.permitsArr.forEach((permission) => {
            for (let i = 0, posLength = permCtrl.permitsForPOS.length; i < posLength; i++) {
                if(permission.name === permCtrl.permitsForPOS[i].name) {
                    permCtrl.permitsForPOS[i].value = true;
                    break;
                } else if(i === posLength - 1) {
                    for (let j = 0, boLength = permCtrl.permitsForBackOffice.length; j < boLength; j++) {
                        if(permission.name === permCtrl.permitsForBackOffice[j].name) {
                            permCtrl.permitsForBackOffice[j].value = true;
                            break;
                        }
                    }
                }
            }
        });

        permCtrl.rightsEditDisabled = permCtrl.options.type === 'OWNER' || currentlyLogged;
    }

    // set inactive states, display and additional text for POS rights
    permCtrl.permitsForPOS.forEach((item) => {
        disableRights(item, permCtrl.rightsEditDisabled);
        // show or not show certain permissions
        switch (item.name) {
            case 'ACCESS_NOT_MY_OPENED_RECEIPTS':
            case 'ACCESS_DELETE_OPEN_RECEIPT':
                item.show = $profile.useOpenedReceipts;
                break;
            case 'ACCESS_VIEW_CURRENT_SHIFT':
                item.show = $profile.shiftsEnabled;
                break;
            default:
                item.show = true;
        }
    });
    //-- END set inactive states, display and additional text for POS rights

    // set inactive states, display and hints for back office permissions
    permCtrl.permitsForBackOffice.forEach((item, i, arr) => {
        disableRights(item, permCtrl.rightsEditDisabled);

        // show or hide some permissions
        switch (item.name) {
            case "ACCESS_KITCHEN_PRINTER":
                item.show = $profile.useKitchenPrinter;
                break;
            case "ACCESS_DINING_OPTIONS":
                item.show = $profile.useDiningOptions;
                break;
            case "ACCESS_BILLING":
                item.show = $profile.billingInfo.disabled === false && permCtrl.userAccessBilling;
                break;
            case "ACCESS_INVENTORY":
                item.show = $profile.inventoryEnabled;
                break;
            default:
                item.show = true;
        }

        // if there's no "View sales reports" permission, "Cancel receipts" becomes inactive
        if (item.name === 'ACCESS_CANCEL_RECEIPTS') {
            const reportsPermission = arr.find(element => element.name === "ACCESS_REPORTS");
            if (!reportsPermission.value) {
                item.disabled = true;
            }
        }

        // add hint where it's needed
        if(item.name === 'ACCESS_CASH_REGISTERS') {
            item.message = 'PERMITS.CESHBOX_INFO';
        } else {
            item.message = null;
        }
    });

    const initParams = {
        name: permCtrl.role.name,
        permitsForPOS: angular.copy(permCtrl.permitsForPOS),
        permitsForBackOffice: angular.copy(permCtrl.permitsForBackOffice)
    };

    permCtrl.change = (item) => {
        if (permCtrl.permitsForBackOffice) {
            // "Cancel receipts" permission depends on "View sales reports"
            if (item.name === 'ACCESS_REPORTS') {
                const cancelReceiptsPermission = permCtrl.permitsForBackOffice.find(element => element.name === 'ACCESS_CANCEL_RECEIPTS');
                if (item.value) {
                    cancelReceiptsPermission.disabled = false;
                } else {
                    cancelReceiptsPermission.value = false;
                    cancelReceiptsPermission.disabled = true;
                }
            }
        }
    };

    permCtrl.onCancel = () => {
        if (lodash.isEmpty(permCtrl.stateFromList)) {
            $state.go( 'employees.permitslist', {   page:   0,
                                                    limit:  10} );
        } else {
            $state.go( 'employees.permitslist', {   page:   permCtrl.stateFromList.page,
                                                    limit:  permCtrl.stateFromList.limit} );
        }
    };

    const changePOS = () => {
        if (permCtrl.options.type === 'OWNER') {
            return false;
        }

        if (permCtrl.permitsForPOS[0].value === false) {
            permCtrl.permitsForPOS.forEach(function (permission, i) {
                if (i > 0) {
                    permission.value = false;
                }
            });
        }
    }

    const changeBackOffice = () => {
        if (permCtrl.options.type === 'OWNER') {
            return false;
        }

        if (permCtrl.permitsForBackOffice[0].value === false) {
            permCtrl.permitsForBackOffice.forEach(function (permission, i) {
                if(i > 0) {
                    permission.value = false;
                }
            });
        }
    }

    const save = () => {

        permCtrl.clicked = false;

        const permissions = [];
        const disabled = [];
        let permissionsOfficeCount = 0;

        changePOS();
        changeBackOffice();

        permCtrl.permitsForPOS.forEach(function (permission) {
            if(permission.value === true) {
                permissions.push(permission.name);
            } else if(permission.value === false) {
                disabled.push(permission.name);
            }
        });

        permCtrl.permitsForBackOffice.forEach(function (permission, i) {
            if(permission.value === true) {
                if(!(permission.name === "ACCESS_KITCHEN_PRINTER" && !$profile.useKitchenPrinter)
                    && !(permission.name === "ACCESS_DINING_OPTIONS" && !$profile.useDiningOptions)) {
                    if(i > 0) {
                        permissionsOfficeCount++;
                    }
                    permissions.push(permission.name);
                }
            } else if(permission.value === false) {
                disabled.push(permission.name);
            }
        });

        if (permCtrl.permitsForBackOffice[0].value === true && permissionsOfficeCount === 0) {
            permissions.splice(-1, 1);
            permCtrl.permitsForBackOffice[0].value = false;
        }

        if ($state.current.name === 'employees.createpermits') {

            const paramsCreate = {
                name: permCtrl.role.name,
                permitsEnabled: permissions,
                permitsDisabled: disabled
            };

            getPermits.getPermitsCreate(paramsCreate).then(function (res) {
                if (res.error === null) {
                    serviceToast.show('PERMITS.CREATED_GROUP', 'success');
                    offStateChangeStart();
                    if (lodash.isEmpty(permCtrl.stateFromList)) {
                        $state.go( 'employees.permitslist', {   page:   0,
                                                                limit:  10}, {reload: true});
                    } else {
                        $state.go( 'employees.permitslist', {   page:   permCtrl.stateFromList.page,
                                                                limit:  permCtrl.stateFromList.limit}, {reload: true});
                    }
                } else if (res.error === "role_name_exists") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        title: translate('PERMITS.UNABLE_SAVE_ROLE'),
                        content: translate('PERMITS.EQUALS_NAME'),
                        buttons: [
                            {
                                class: 'primary',
                                text: 'ON_OK',
                                action: function () {
                                    permCtrl.clicked = true;
                                }
                            }
                        ]
                    });
                }
            });
        } else {
            const paramsEdit = {
                id: $permits.id,
                name: permCtrl.role.name,
                permitsEnabled: permissions,
                permitsDisabled: disabled
            };

            getPermits.getPermitsEdit(paramsEdit).then(function (res) {
                if (res.error === null) {
                    serviceToast.show('PERMITS.EDITED_GROUP', 'success');
                    offStateChangeStart();
                    if (lodash.isEmpty(permCtrl.stateFromList)) {
                        $state.go( 'employees.permitslist', {   page:   0,
                                                                limit:  10}, {reload: true});
                    } else {
                        $state.go( 'employees.permitslist', {   page:   permCtrl.stateFromList.page,
                                                                limit:  permCtrl.stateFromList.limit}, {reload: true});
                    }
                } else if (res.error === "role_name_exists") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        title: translate('PERMITS.UNABLE_SAVE_ROLE'),
                        content: translate('PERMITS.EQUALS_NAME'),
                        buttons: [
                            {
                                class: 'primary',
                                text: 'ON_OK',
                                action: function () {
                                    permCtrl.clicked = true;
                                }
                            }
                        ]
                    });
                }
            });
        }
    }

    permCtrl.onSave =  () => {
        if (permCtrl.clicked === false) {
            return false;
        }

        if ($scope.employeeForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.employeeForm.focusFirstInvalidControl();
            return false;
        }

        save();
    };

    permCtrl.onDel = (editProfile) => {

        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }


        const onSendData = () => {
            const ids = [$permits.id];
            getPermits.getPermitsDelete({ids: ids}).then(function (res) {
                if (res.result === "ok") {
                    offStateChangeStart();
                    serviceToast.show('PERMITS.DELETED_GROUP', 'success');
                    if (lodash.isEmpty(permCtrl.stateFromList)) {
                        $state.go( 'employees.permitslist', {   page:   0,
                                                                limit:  10}, {reload: true});
                    } else {
                        $state.go( 'employees.permitslist', {   page:   permCtrl.stateFromList.page,
                                                                limit:  permCtrl.stateFromList.limit}, {reload: true});
                    }
                }
            });
        }

        if (permCtrl.employeesCount > 0) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('PERMITS.CANNOT_ROLE_DELETE_TITLE'),
                content: translate('PERMITS.CANNOT_ROLE_DELETE_TEXT'),
                buttons: [
                    {
                        text: 'ON_OK'
                    }
                ]
            });
        } else {
            const msgTitle = translate('PERMITS.DELETED_GROUP_TITLE');
            const content = translate('PERMITS.YOU_WANT_DEL_GROUP');

            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: msgTitle,
                content: content,
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'CONTINUE',
                        action: onSendData
                    }
                ]
            });
        }
    };

}

export default {
    type: 'controller',
    name: 'employeePermitsCtrl',
    value: employeePermitsCtrl
};
