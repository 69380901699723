import angular from 'angular';

PinEditorController.$inject = ['$scope', '$element', '$attrs', '$mdUtil', '$window', 'keyCode',
    'InputContainerStateManager'];

function PinEditorController($scope, $element, $attrs, $mdUtil, $window, keyCode,
     InputContainerStateManager) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onDestroy = $onDestroy;

    const itemsNumber = 4;
    let inputList;
    let inputs;
    let inputContainerStateManager

    this.inputItems = [];
    for (let i = 0; i < itemsNumber; i++) {
        this.inputItems.push({
            index: i,
            value: 0,
            prevValue: null
        });
    }

    let self = this;

    function $onInit() {
        $element.addClass('lv-pin-editor');
        self.ngModel.$render = render;
    }

    function $postLink() {

        $element.attr('tabindex', $attrs.tabIndex || '0');

        inputContainerStateManager = new InputContainerStateManager($element, $attrs,
            self.ngModel, self.inputContainerAdapter);

        self.ngModel.$isEmpty = function(value) {
            if (value == null) {
                return true;
            }
            return value.includes(null) || value.includes('');
        };

        $mdUtil.nextTick(() => {
            inputList = $element.find('INPUT');
            inputList.on('input', onInput);
            inputList.on('keyup', onKeyUp);
            inputList.on('keydown', onKeyDown);

            inputList.on('focus', onFocus)
            inputList.on('blur', onBlur);

            inputs = [];
            for (let i = 0; i < inputList.length; i++) {
                inputs.push(inputList[i]);
            }
        });
    }

    function $onDestroy() {
        inputList.off('input', onInput);
        inputList.off('keyup', onKeyUp);
        inputList.off('keydown', onKeyDown);

        inputList.off('focus', onFocus);
        inputList.off('blur', onBlur);
    }

    function onInput(ev) {

        let currentInput = angular.element(ev.target);

        const inputIndex = getInputIndex(ev.target);

        const isLast = inputIndex === itemsNumber - 1;

        let currentVal = currentInput.val();

        if (currentVal.length > 1) {
            const prevValue = self.inputItems[inputIndex].prevValue;
            if (currentVal[0] === prevValue) {
                currentVal = currentVal[1];
            } else {
                currentVal = currentVal[0];
            }
            currentInput.val(currentVal);
        }

        if (currentVal.length === 1) {
            if (!isLast) {
                const nextInput = angular.element(inputs[inputIndex + 1]);
                nextInput.focus();
            }
        }

        valueChanged();
    }

    function onKeyUp(ev) {

        let currentInput = angular.element(ev.target);

        const inputIndex = getInputIndex(ev.target);

        const isFirst = inputIndex === 0;
        const isLast = inputIndex === itemsNumber - 1;

        switch (ev.keyCode) {
            case keyCode.BACKSPACE:
                if (currentInput.val().length === 0) {
                    if (!isFirst) {
                        const prevInput = angular.element(inputs[inputIndex - 1]);
                        prevInput.focus();
                    }
                }
            case keyCode.LEFT:
                if (!isFirst) {
                    const prevInput = angular.element(inputs[inputIndex - 1]);
                    prevInput.focus();
                }
                return;
            case keyCode.RIGHT:
                if (!isLast) {
                    const nextInput = angular.element(inputs[inputIndex + 1]);
                    nextInput.focus();
                }
                return;
        }
    }

    function onKeyDown(ev) {
        let currentInput = angular.element(ev.target);
        let currentVal = currentInput.val();
        const inputIndex = getInputIndex(ev.target);
        self.inputItems[inputIndex].prevValue = currentVal;
    }

    function onFocus(ev) {
        inputContainerStateManager.setFocused(true);
    }

    function onBlur(ev) {
        if (ev.relatedTarget == null || !inputs.includes(ev.target)) {
            inputContainerStateManager.setFocused(false);
            $scope.$applyAsync(() => {
                self.ngModel.$setTouched();
            });
        }
    }

    function getInputIndex(target) {
        let result = -1;
        for (let i = 0; i < inputList.length; i++) {
            if (inputList[i] === target) {
                result = i;
                break;
            }
        }
        return result;
    }

    function valueChanged() {
        const newValue = inputs.map(input => {
            let val = parseInt(angular.element(input).val());
            if(isNaN(val)) {
                val = null;
            }
            return val;
        });
        self.ngModel.$setViewValue(newValue);
    }

    function render() {
        let currentValue = self.ngModel.$viewValue;
        if (currentValue) {
            self.inputItems.forEach((item, index) => {

                let val = parseInt(currentValue[index]);
                if (isNaN(val)) {
                    val = null;
                }

                item.value = val;
                item.prevvalue = val;
            });
        }
    }
}

const lvPinEditorComponent = {
    template: `
                <div class="lv-pin-editor__container">
                    <input ng-repeat="num in $ctrl.inputItems"
                           class="lv-pin-editor__input" 
                           type="number"
                           ng-model="num.value">
                </div>
            `,
    controller: PinEditorController,
    require: {
        ngModel: 'ngModel',
        inputContainerAdapter: '?lvInputContainerAdapter'
    }
}

export default {
    type: 'component',
    name: 'lvPinEditor',
    value: lvPinEditorComponent
};