/**
 * Created by mars on 10/7/15.
 */

import angular from 'angular';

calendarView.$inject = ['factoryPosition', '$timeout', '$state', '$stateParams', 'setChartPeriod'];

function calendarView(factoryPosition, $timeout, $state, $stateParams, setChartPeriod){
		return{
			restrict: 'C',

			link: function($scope, el, attr){
				let elParent  = $scope.elParent;

				$scope.$watch( 'show',function(val){
					el.css( 'display', 'block' );
					if ( val ) {
						$timeout(function(){
							let w = window,
								d = document,
								e = d.documentElement,
								g = d.getElementsByTagName('body')[0],
								screenWidth = w.innerWidth || e.clientWidth || g.clientWidth,
								y = w.innerHeight|| e.clientHeight|| g.clientHeight,
                            	body = document.querySelector('body');
							let calendarWidth = el[0].clientWidth;
							let offsetLeft = factoryPosition( elParent[0] ).x;
                            let offsetRight = screenWidth - factoryPosition( elParent[0] ).x - elParent[0].children[0].offsetWidth;


							if(!body.classList.contains('aranav') && screenWidth - calendarWidth - offsetLeft - 30 < 0) {
								el.css('left', 'inherit');
							} else if(body.classList.contains('aranav') && screenWidth - 600 - offsetRight - 30 < 0) {
                                if($state.current.name !== 'inventory.createorder' && $state.current.name !== 'inventory.editorder' &&
                                    $state.current.name !== 'inventory.createtransfer' && $state.current.name !== 'inventory.edittransfer' &&
                                    $state.current.name !== 'employees.createtimecard' && $state.current.name !== 'employees.edittimecard'
                                    && $state.current.name !== 'inventory.duplicateorder') {
                                    el.css('left', 'auto');
                                    el.css('right', offsetRight);
                                } else {
                                    el.css('left', offsetLeft);
                                }
                            } else if (!body.classList.contains('aranav')) {
								el.css( 'display', 'inherit' );
                                if($state.current.name !== 'inventory.createorder' && $state.current.name !== 'inventory.editorder' &&
                                    $state.current.name !== 'inventory.createtransfer' && $state.current.name !== 'inventory.edittransfer' &&
                                    $state.current.name !== 'employees.createtimecard' && $state.current.name !== 'employees.edittimecard'
                                    && $state.current.name !== 'inventory.duplicateorder') {
                                    el.css('left', factoryPosition(elParent[0]).x + 'px');
                                } else {
                                    el.css('left', factoryPosition(elParent[0]).x + elParent[0].offsetWidth - calendarWidth + 'px');
                                }
							} else if (body.classList.contains('aranav')){
                                el.css( 'display', 'inherit' );
                                if($state.current.name !== 'inventory.createorder' && $state.current.name !== 'inventory.editorder' &&
                                    $state.current.name !== 'inventory.createtransfer' && $state.current.name !== 'inventory.edittransfer'
                                    && $state.current.name !== 'employees.createtimecard' && $state.current.name !== 'employees.edittimecard'
                                    && $state.current.name !== 'inventory.duplicateorder') {
                                    el.css('left', 'auto');
                                    el.css('right', offsetRight)
                                } else {
                                    el.css('left', factoryPosition(elParent[0]).x + 'px');
								}
                            }
                            if($state.current.name !== 'inventory.createorder' && $state.current.name !== 'inventory.editorder' &&
                                $state.current.name !== 'inventory.createtransfer' && $state.current.name !== 'inventory.edittransfer' &&
                                $state.current.name !== 'employees.createtimecard' && $state.current.name !== 'employees.edittimecard'
                                && $state.current.name !== 'inventory.duplicateorder') {
							    if(factoryPosition(elParent[0]).y + 324 > y) {
                                    el.css('top', (y - 348) + 'px');
                                } else
                                    el.css('top', factoryPosition(elParent[0]).y + 'px');
                            } else {
                                if(factoryPosition(elParent[0]).y + 324 > y) {
                                    el.css('top', (y - 348) + 'px');
                                } else
                                    el.css('top', factoryPosition(elParent[0]).y + 40 + 'px');
                            }
							el.css( 'visibility', 'visible' );

						},1);
					}
				});

                $scope.onChangeChartPeriod = (period) => {
                    $scope.show = false;
                    setChartPeriod.onChangeChartPeriod(period);
                }
			}
		};
	}

export default calendarView;
