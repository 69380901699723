/*** Created by Alex 2.10.17 ***/

import angular from 'angular';

diningListCtrl.$inject = [
        "$scope",
        "$filter",
        "$state",
        "$stateParams",
        "serviceDialog",
        "$shortOutlets",
        "getDining",
        "$diningList",
        "$profileNew",
        "lodash",
        "serviceToast",
        '$profile',
        '$translate'];
function diningListCtrl($scope,
         $filter,
         $state,
         $stateParams,
         serviceDialog,
         $shortOutlets,
         getDining,
         $diningList,
         $profileNew,
         lodash,
         serviceToast,
         $profile,
         $translate) {

    $scope.profile = angular.copy($profile);
//-- VARIABLE INITIALIZATION BLOCK
    var translate           = $filter('translate');
    $scope.diningList       = angular.copy($diningList.outlets);
    $scope.сount            = angular.copy($diningList.optionsCount);
    $scope.shortOutlets     = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('DINING.OLL_OUTLETS')
    });
    $scope.editTableState   = 'editdining';
    $scope.useMultishop     = $profileNew.multishopEnable;
    // $scope.useMultishop     = false;

    for(var i = 0; i < $scope.diningList.length; i++){
        for(var j = 0; j < $scope.diningList[i]["options"].length; j++){
            $scope.diningList[i]["options"][j]["outletIndex"] = i;
            if ($scope.diningList[i]["options"][j]["name"] == null) {
                $scope.diningList[i]["options"][j]["name"] = translate("DINING." + $scope.diningList[i]["options"][j]["type"]);
            }
        }
    }
    //--  create 2 arrays inside establishments, available types and NOT available order types in this establishment
    for(var i = 0; i < $scope.diningList.length; i++){
        $scope.optionsFalse = lodash.remove($scope.diningList[i]["options"], function(value) {
          return value.available == false;
        });
        $scope.diningList[i]["optionsFalse"] = $scope.optionsFalse;
    }

    $scope.showDisabledOptionsInSingleShop = $scope.diningList.length === 1 && $scope.diningList[0]["optionsFalse"] && $scope.diningList[0]["optionsFalse"].length > 0;
    //--  END create 2 arrays inside establishments, available types and NOT available order types in this establishment
//-- END VARIABLE INITIALIZATION BLOCK



    // ФУНКЦИЯ РЕАГИРОВАНИЯ НА КЛИК И ПЕРЕМЕЩЕНИЯ ТИПА ЗАКАЗА В СПИСКЕ
    $scope.changeStatus = function(outlet, option, optionType, outletIndex){

        if (optionType == 'options') {
            lodash.pull($scope.diningList[outletIndex].options, option);            // удаляем строку из списка доступных типов заказа в конкретном заведении
            $scope.diningList[outletIndex].optionsFalse.push(option);               // добавляем строку в список НЕ доступных типов заказа в конкретном заведении
            $scope.outletForSave   = angular.copy($scope.diningList[outletIndex]);  // создаем новый обьект для обработки и дальнейшей отправки на сервер

            sendOutletForSave($scope.outletForSave);
        }
        if (optionType == 'optionsFalse') {
            lodash.pull($scope.diningList[outletIndex].optionsFalse, option);
            $scope.diningList[outletIndex].options.push(option);
            $scope.outletForSave   = angular.copy($scope.diningList[outletIndex]);

            sendOutletForSave($scope.outletForSave);
        }
    };
    // ФУНКЦИЯ РЕАГИРОВАНИЯ НА КЛИК И ПЕРЕМЕЩЕНИЯ ТИПА ЗАКАЗА В СПИСКЕ



    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop
    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true,
        onUpdate: function (evt) {
            // console.log("onUpdate evt = ", evt);
            // console.log("onUpdate diningList = ", $scope.diningList);
            $scope.outletForSave   = angular.copy($scope.diningList[evt.model.outletIndex]);

            sendOutletForSave($scope.outletForSave);
        }
    }
    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop



    // ФУНКЦИЯ ОТПРАВКИ ДАННЫХ ПРИ ИЗМЕНЕНИИ ДОСТУПНОСТИ И ИЗМЕНЕНИЯ ПОРЯДКА ОПЦИЙ
    function sendOutletForSave(outletForSave) {
        // обьеденяем массивы доступных и НЕ доступных
        for(var k = 0; k < outletForSave.optionsFalse.length; k++){
            outletForSave.options.push(outletForSave.optionsFalse[k]);
        }

        delete outletForSave.optionsFalse;                               // удаляем список НЕ доступных перед отправкой

        getDining.changeDiningOrder( outletForSave )
    };
    // ФУНКЦИЯ ОТПРАВКИ ДАННЫХ ПРИ ИЗМЕНЕНИИ ДОСТУПНОСТИ И ИЗМЕНЕНИЯ ПОРЯДКА ОПЦИЙ



// BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    $scope.selectOutlet     = {id: $stateParams.outletId ? +$stateParams.outletId : null};
    $scope.selectOutletId   = $scope.selectOutlet.id;

    //  state what check load content or not for show empty state between chose shop
    $scope.dataIsLoading = false;

    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            $scope.dataIsLoading = true;
            $state.go('settings.dining', {outletId: newval.id}).then(function() {
                $scope.selectOutletId = newval.id;
                $scope.dataIsLoading = false;
            });
        }
    }, true);
//-- END BLOCK FOLLOWING THE SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION



    // БЛОК ДОБАВЛЕНИЕ ТИПА ЗАКАЗА
    $scope.onAdd = function(){
        // console.log("$scope.cashRegisters = ", $scope.cashRegisters);
        // console.log("$scope.cashRegisters.length = ", $scope.cashRegisters.length);
        if($scope.сount > 19){
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('DINING.DINING_ALREADY_EXISTS_TITLE'),
                content: translate('DINING.DINING_ALREADY_EXISTS_CONTENT'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else{
            $state.go('createdining', $stateParams, {});
        }
    };
    // БЛОК ДОБАВЛЕНИЕ ТИПА ЗАКАЗА

}

export default {
    type: 'controller',
    name: 'diningListCtrl',
    value: diningListCtrl
};