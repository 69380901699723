permissionObserver.$inject = ['Observable'];
function permissionObserver(Observable) {
    this.updateRequired = new Observable();
}

export default {
    type: 'service',
    name: 'permissionObserver',
    value: permissionObserver
};
