import angular from 'angular';

createDirective.$inject = ['currencyFormat', '$q'];

function createDirective(currencyFormat, $q) {

    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkDirective
    }

    function linkDirective(scope, element, attrs, ngModel) {

        let INT_MAX = 2147483647;
        let INT_MIN = -2147483648;

        // Min value validator
        let minVal = INT_MIN;

        if (angular.isDefined(attrs.min) || angular.isDefined(attrs.ngMin)) {
            attrs.$observe('min', function(val) {
                if (angular.isDefined(val) && !angular.isNumber(val)) {
                    val = parseFloat(val, 10);
                }
                minVal = angular.isNumber(val) && !isNaN(val) ? val : INT_MIN;
                ngModel.$validate();
            });

            ngModel.$asyncValidators.min = function(value) {
                return currencyFormat.getCurrencyOptionsEdit()
                    .then(currencyOptionsEdit => {
                        let decSeparator = currencyOptionsEdit.decimalCharacter;
                        let valueProcessed = processLocalizedString(value, decSeparator);
                        if (ngModel.$isEmpty(value) || angular.isUndefined(minVal) || isNaN(valueProcessed) || valueProcessed >= minVal) {
                            return $q.resolve();
                        } else {
                            return $q.reject();
                        }
                    });
            };
        }

        // Max value validator
        let maxVal = INT_MAX;
        if (angular.isDefined(attrs.max) || angular.isDefined(attrs.ngMax)) {
            attrs.$observe('max', function(val) {
                if (angular.isDefined(val) && !angular.isNumber(val)) {
                    val = parseFloat(val, 10);
                }
                maxVal = angular.isNumber(val) && !isNaN(val) ? val : INT_MAX;
                ngModel.$validate();
            });

            ngModel.$asyncValidators.max = function(value) {
                return currencyFormat.getCurrencyOptionsEdit()
                    .then(currencyOptionsEdit => {
                        let decSeparator = currencyOptionsEdit.decimalCharacter;
                        let valueProcessed = processLocalizedString(value, decSeparator);
                        if (ngModel.$isEmpty(value) || angular.isUndefined(maxVal) || isNaN(valueProcessed) || valueProcessed <= maxVal){
                            return $q.resolve();
                        } else {
                            return $q.reject();
                        }
                    });
            };
        }
    }

    /**
     * Processes localized value, created by currency-localization, to standard float
     * @param value value to process
     * @param decimalSeparator decimal separator
     *
     * @return value processed to float
     */
    function processLocalizedString(value, decimalSeparator) {
        if (value.match(/^\d+\.*\d{0,2}$/))
            return parseFloat(value);

        let pattern = "[^\\d\\" + decimalSeparator + "]";
        let regex = new RegExp(pattern, 'g');

        let valWithoutSymbols = value.replace(regex, "");

        return parseFloat(valWithoutSymbols.replace(decimalSeparator, "."));
    }
}

export default {
    type: 'directive',
    name: 'lvNumber',
    value: createDirective
};
