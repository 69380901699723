myDatePickerMonthContainer.$inject = ['serviceDatePickerEvents'];

function myDatePickerMonthContainer(serviceDatePickerEvents) {
    return {
        restrict: 'A',
        //controller: 'datePickerContrl',
        link: function ( scope, el ) {
            this.params = {el: el};
            scope.myDatePickerMonthContainer = {
                el: el,
                mouse: false
            };
            el
                .on( 'mousedown', function ( e ) {
                    serviceDatePickerEvents.mouseDown = true;
                    scope.$apply()
                } )
                .on( 'mouseup', function ( e ) {
                    serviceDatePickerEvents.mouseDown = false;
                    serviceDatePickerEvents.startDay = null;
                    scope.$apply()
                } )
                .on( 'mouseleave', function ( e ) {
                    serviceDatePickerEvents.mouseDown = false;
                    serviceDatePickerEvents.startDay = null;
                    scope.$apply()
                } );

            scope.$on( "$destroy", function () {
                el.off( 'mousedown mouseup mouseleave' )
            } );

        }
    }
}

export default myDatePickerMonthContainer;