/**
 * Created by Kiskenbassker on 14.12.2017
 */

import angular from 'angular';

createEditTransferController.$inject = [
    '$scope',
    '$rootScope',
    '$state',
    '$stateParams',
    '$sourceOutlets',
    '$destinationOutlets',
    '$q',
    '$timeout',
    'dateCustomMethods',
    'lodash',
    'serviceDialog',
    '$filter',
    'factoryTransfer',
    'serviceToast',
    '$countOptionsEdit',
    '$transferDetail',
    '$countQuantityForOrder',
    '$countQuantityForOrderDivisible',
    '$profile',
    'lvMixpanel',
    'dialogs'
];

function createEditTransferController($scope,
                                      $rootScope,
                                      $state,
                                      $stateParams,
                                      $sourceOutlets,
                                      $destinationOutlets,
                                      $q,
                                      $timeout,
                                      dateCustomMethods,
                                      lodash,
                                      serviceDialog,
                                      $filter,
                                      factoryTransfer,
                                      serviceToast,
                                      $countOptionsEdit,
                                      $transferDetail,
                                      $countQuantityForOrder,
                                      $countQuantityForOrderDivisible,
                                      $profile,
                                      lvMixpanel,
                                      dialogs) {

    /* Main Variables */
    var vm = this;
    var translate = $filter('translate');
    var offStateChangeStart;
    var searchDefer;
    var alert;
    var enterKeydown = false;
    var searchInput;
    var itemsFound = null;

    var wareCount = $filter('wareCount');
    var stateFromList = $stateParams.stateToSave;
    var errItemsNo = false;
    var unavailableOutlets;

    vm.outletsFrom = angular.copy($sourceOutlets);
    vm.outletsTo  = angular.copy($destinationOutlets);

    vm.countOptionsEdit = $countOptionsEdit;
    vm.countQuantityForOrder = $countQuantityForOrder;
    vm.countQuantityForOrderDivisible = $countQuantityForOrderDivisible;

    vm.tableHeadNowrap = ($profile.cabinetLang === 'kor' || $profile.cabinetLang === 'jpn' || $profile.cabinetLang === 'zho' || $profile.cabinetLang === 'chn');

    vm.isEdit = !$transferDetail.hasOwnProperty("isCreate");
    vm.isDuplicate = $state.current.name === 'inventory.duplicatetransfer'
    vm.isSaving = false;

    vm.sameOutletError = false;

    $scope.startDateId = document.getElementById('startDateInput').id || "startDateInput";

    const backToList = () => {
        if (lodash.isEmpty(stateFromList)) {
            $state.go('inventory.transfer',
                {
                    page: 0,
                    limit: 10
                });
        } else {
            $state.go('inventory.transfer', {
                page: stateFromList.page,
                limit: stateFromList.limit,
                outletId: stateFromList.outletId,
                supplier: stateFromList.supplier,
                status: stateFromList.status
            });
        }
    }

    if(vm.isEdit) {
        /* For edit state */

        if (Object.keys($stateParams.transferDocData).length > 0 ) {
            vm.transferData = $stateParams.transferDocData;
            vm.notes = vm.transferData.notes;
            vm.items = vm.transferData.items;
            vm.actualOutletFromId = parseInt($stateParams.sourceOutletId);
            vm.actualOutletToId = parseInt($stateParams.destinationOutletId);
            $scope.startDate =  new Date(vm.transferData.trDate);
        } else {
            vm.transferData = $transferDetail.orderData;
            vm.notes = vm.transferData.comment;
            vm.actualOutletFromId = vm.transferData.sourceStoreId;
            vm.actualOutletToId = vm.transferData.destinationStoreId;
            vm.items = $transferDetail.items.map(
                function(item) {
                    return {
                        id: item.id,
                        wareId: item.wareId,
                        name: item.name,
                        sku: item.article,
                        sourceStock: parseFloat(wareCount(item.inSourceStock, ".", false)),
                        destinationStock: parseFloat(wareCount(item.inDestinationStock, ".", false)),
                        divisible: item.divisible,
                        quantity: wareCount(item.quantity, ".", false),
                    }
                });
            $scope.startDate = vm.isDuplicate ? new Date() : new Date(vm.transferData.trDate);
        }

        vm.actualOutletTo = vm.transferData.destinationStore;
        vm.showDraftButton = vm.isDuplicate || vm.transferData.status === 'DRAFT';
        vm.disableReceiveButton = false;
        vm.saveButtonText = (vm.isDuplicate || vm.transferData.status === 'DRAFT') ? 'ON_CREATE' : 'ON_SAVE';
        vm.onSaveOrReceive = vm.isDuplicate ? cTransferSave : eTransferSave;
        vm.startDateFormatted = vm.isDuplicate ? Date.now() : vm.transferData.trDate;

        unavailableOutlets = [];

        const sourceStoreAvailable = vm.isDuplicate ?
            (vm.outletsFrom.find(outlet => outlet.id === vm.actualOutletFromId) ? true : false) :
            true;
        if (sourceStoreAvailable) {
            vm.actualOutletFrom = vm.transferData.sourceStore;
        } else {
            // Cleanup source store data if user has no access to original source store.
            vm.actualOutletFromId = null;
            vm.actualOutletFrom = null;
            vm.items.forEach(item => {
                item.sourceStock = null;
            });
        }

    } else {

        if (Object.keys($stateParams.transferDocData).length > 0 ) {
            vm.transferData = $stateParams.transferDocData;
            vm.actualOutletFrom = vm.transferData.sourceStore ? vm.transferData.sourceStore : {};
            vm.actualOutletFromId = $stateParams.sourceOutletId ? parseInt($stateParams.sourceOutletId) : null;
            vm.actualOutletTo = vm.transferData.destinationStore ? vm.transferData.destinationStore : {};
            vm.actualOutletToId = $stateParams.destinationOutletId ? parseInt($stateParams.destinationOutletId) : null;
            vm.notes = vm.transferData.notes;
            vm.items = vm.transferData.items;
            $scope.startDate =  new Date(vm.transferData.trDate);
        } else {
            vm.transferData = {};
            vm.actualOutletFrom = {};
            vm.actualOutletFromId = null;
            vm.actualOutletTo = {};
            vm.actualOutletToId = null;
            vm.notes = "";
            vm.items = [];
            $scope.startDate = new Date();
        }
        vm.startDateFormatted = formatDate($scope.startDate);
        vm.showDraftButton = true;
        vm.saveButtonText = 'ON_CREATE';
        vm.onSaveOrReceive = cTransferSave;

        unavailableOutlets = getUnavailableOutlets();
    }

    if($stateParams.newItems.length > 0) {
        var itemsCount = $stateParams.newItems.length;
        var existingItems = vm.items.map(val => {
            return val.wareId;
        });
        for (var j = 0; j < itemsCount; j++) {
            if(existingItems.indexOf($stateParams.newItems[j].id) > -1) {
                var itemIndex = existingItems.indexOf($stateParams.newItems[j].id);
                vm.items[itemIndex].quantity = $stateParams.newItems[j].quantity ? wareCount($stateParams.newItems[j].quantity, ".", false) : null
            } else {
                pushItemToArray($stateParams.newItems[j]);
            }
        }
    }

    vm.transferOrderCreateData = {};
    vm.transferOrderEditData = {};
    vm.ceTransferStartParams = {
        actualOutletFrom: vm.actualOutletFrom,
        actualOutletTo: vm.actualOutleTo,
        startDate: $scope.startDate,
        notes: vm.notes,
        items: angular.copy(vm.items)
    };

    vm.selectActualOutlet = function(actualOutlet, isSourceStore){
        var outletVar = isSourceStore ? (vm.actualOutletFrom || actualOutlet) : vm.actualOutleTo;
        var outletIdVar;

        if(outletVar !== null) {
            outletVar = angular.copy(actualOutlet);
            outletIdVar = outletVar.id;
        } else {
            outletVar = {};
            outletIdVar = null;
        }

        isSourceStore ? vm.actualOutletFrom = outletVar : vm.actualOutleTo = outletVar;
        isSourceStore ? vm.actualOutletFromId = outletIdVar : vm.actualOutletToId = outletIdVar;
    };

    vm.outletChange = outletChange;

    function outletChange(isSourceStore) {
        // если сотрудник выбирает недоступный ему магазин, блокируем кнопку получения заказа на поставку
        if (unavailableOutlets.length > 0) {
            vm.disableReceiveButton = unavailableOutlets.indexOf(vm.actualOutletToId) !== -1;
        }

        if(vm.items.length > 0) {
            var outletsData = {
                sourceOutletId: isSourceStore ? vm.actualOutletFromId : null,
                destinationOutletId: isSourceStore ? null : vm.actualOutletToId,
                items: getItemsIds()
            };
            factoryTransfer.getTransferOrderItemsForOutlet(outletsData)
                .then(function(data) {
                    data.items.forEach(
                        function(item) {
                            vm.items.forEach(
                                function (product) {
                                    if(item.id === product.wareId) {
                                        var itemStock = isSourceStore ? item.sourceStock : item.destinationStock;
                                        var parsed = parseFloat(wareCount(itemStock, ".", false));
                                        isSourceStore ? product.sourceStock = parsed : product.destinationStock = parsed;
                                    }
                                }
                            )
                        }
                    );
                })
        }

        selectChange();
    };

    /* Date picker */

    $scope.$watch('dateFrom', function(newVal, oldVal){
        if(newVal!== undefined && newVal !== oldVal){
            if($scope.startDateId && ($scope.datePickerId === $scope.startDateId)){
                $scope.startDate = newVal;
                vm.startDateFormatted = formatDate($scope.startDate);
            }
        }
    });

    function formatDate(date) {
        return dateCustomMethods.setTimeToZero(Date.parse(date));
    }

    /* Items autocomplete */

    vm.autocompleteItems = {
        searchText: null,
        selectedItem: null,
        getMatches: function (query) {
            searchDefer = $q.defer();
            factoryTransfer
                .searchItemsForTransferOrder( {
                    search: query,
                    sourceOutletId: vm.actualOutletFromId,
                    destinationOutletId: vm.actualOutletToId
                })
                .then(function (items) {
                    searchDefer.resolve(items);
                    itemsFound = angular.copy(items);
                })
                .then(function () {
                    if(enterKeydown) {
                        if(Array.isArray(itemsFound) && itemsFound.length === 1 && itemsFound[0].barcode === vm.autocompleteItems.searchText) {
                            vm.autocompleteItems.selectedItemChange(itemsFound[0]);
                            searchInput.focus();
                            enterKeydown = false;
                        }
                    }
                });
            return searchDefer.promise;
        },
        selectedItemChange: function(item) {
            if(item) {
                var itemsLength = vm.items.length;
                if(itemsLength !== 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (item.id === vm.items[i].wareId) {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                content: translate('INVENTORY.ADDING_SAME_TRANSFER_MESSAGE'),
                                buttons: [
                                    {
                                        text: 'ON_OK',
                                        action: function () {
                                            return true;
                                        }
                                    }
                                ]
                            });
                            break;
                        } else if (i === itemsLength - 1) {
                            pushItemToArray(item);
                        }
                    }
                } else {
                    pushItemToArray(item);
                }
                $('[name="autocompleteItems"]').blur();
                this.selectedItem = null;
                this.searchText = null;
            }
        }
    };

    function pushItemToArray(item) {
        var itemObj = {
            id: null, /* Identifies that position is newly added */
            wareId: item.id,
            name: item.name,
            sku: item.sku,
            sourceStock: vm.actualOutletFromId !== null ? parseFloat(wareCount(item.sourceStock, ".", false)) : null,
            destinationStock: vm.actualOutletFromId !== null ? parseFloat(wareCount(item.destinationStock, ".", false)) : null,
            divisible: item.divisible,
            quantity: item.quantity ? wareCount(item.quantity, ".", false) : null,
        };
        vm.items.push(itemObj);
    }

    function getItemsIds() {
        return vm.items.map(
            function (item) {
                return item.wareId;
            }
        );
    }

    /* Items table */

    vm.deleteItem = function (item) {
        angular.forEach(vm.items, function ( val, i ) {
            if (item === val) {
                vm.items = vm.items.filter((item, index) => index !== i);
            }
        });
    };

    /* Bottom buttons */

    function checkEmptyNumberInput(item) {
        var forCheckQuantityValue = (item.quantity && item.quantity.includes(',')) ? item.quantity.replace(',', '.') : item.quantity;
        return (parseFloat(forCheckQuantityValue) !== 0)
            && item.quantity !== null
            && item.quantity !== undefined;
    }

    function checkBeforeSave() {
        errItemsNo = vm.items.every(checkEmptyNumberInput);

        if(vm.actualOutletFromId === null
            || vm.actualOutletToId === null
            || vm.items.length < 1
            || !errItemsNo
            || vm.actualOutletFromId === vm.actualOutletToId
            || vm.notes.length > 500) {

            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            return false;
        }
        return true;
    }

    /* Preventing form from being sent by Enter button */

    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    /* Save button for create order state */
    function cTransferSave(editProfile, isDraft){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }
        if(checkBeforeSave()) {
            vm.isSaving = true;
            vm.transferOrderCreateData = {
                outletFromId: vm.actualOutletFromId,
                outletToId: vm.actualOutletToId,
                trDate: vm.startDateFormatted,
                status: isDraft ? 'DRAFT' : 'IN_TRANSIT',
                comment: vm.notes,
                items: vm.items.map(
                    function(item) {
                        return {
                            wareId: item.wareId,
                            quantity: parseInt(wareCount(item.quantity, ".", true), 10)
                        }
                    }
                )
            };

            factoryTransfer.createTransferOrder(vm.transferOrderCreateData).then(function (res) {
                if (res.result === "ok") {
                    offStateChangeStart();
                    serviceToast.show(isDraft ? 'INVENTORY.TRANSFER_ORDER_EDIT' : 'INVENTORY.TRANSFER_ORDER_SUCCESS', 'success');

                    lvMixpanel.track('Advanced inventory activity', {"Event type": isDraft ? 'Transfer order created as draft' : 'Transfer order created'});

                    $state.go('inventory.transferdetail', {
                        id: res.newId
                    })
                } else {
                    serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                }
            });
        }
    }

    /* Save button for edit state */
    function eTransferSave (editProfile, isDraft){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }
        if(checkBeforeSave()) {
            vm.isSaving = true;
            vm.transferOrderEditData = {
                id: vm.transferData.id,
                outletFromId: vm.actualOutletFromId,
                outletToId: vm.actualOutletToId,
                trDate: vm.startDateFormatted,
                status: isDraft ? 'DRAFT' : 'IN_TRANSIT',
                comment: vm.notes,
                items: vm.items.map(
                    function(item) {
                        return {
                            id: item.id,
                            wareId: item.wareId,
                            quantity: parseInt(wareCount(item.quantity, ".", true), 10)
                        }
                    }
                )
            };

            factoryTransfer.editTransferOrder(vm.transferOrderEditData).then(function (res) {
                if (res.result === "ok") {
                    offStateChangeStart();
                    serviceToast.show((!isDraft && (vm.transferData.status === 'DRAFT')) ? 'INVENTORY.TRANSFER_ORDER_SUCCESS' : 'INVENTORY.TRANSFER_ORDER_EDIT', 'success');
                    $state.go('inventory.transferdetail', {
                        id: vm.transferData.id
                    })
                } else if (res.result === "error") {
                    if (res.message === "transfer_order_deleted") {
                        offStateChangeStart();
                        dialogs.itemDeletedEarlier().then(backToList);
                    }
                    if (res.message === "transfer_order_transferred") {
                        offStateChangeStart();
                        dialogs.transferOrderTransferred().then(backToList);
                    }
                } else {
                    serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                }
            });
        }
    }

    /* Cancel button */

    vm.ceTransferCancel = function() {
        $stateParams.page = 0;
        offStateChangeStart();
        if ($state.current.name === 'inventory.createtransfer' || $state.current.name === 'inventory.duplicatetransfer') {
            backToList()
        } else if($state.current.name === 'inventory.edittransfer') {
            $state.go('inventory.transferdetail', {
                id: vm.transferData.id
            });
        }
    };

    vm.toImportState = function () {
        vm.ceTransferFinalParams = {
            id: vm.transferData.id ? vm.transferData.id : null,
            sourceStore: vm.actualOutletFrom,
            destinationStore: vm.actualOutletTo,
            trDate: vm.startDateFormatted,
            notes: vm.notes,
            items: angular.copy(vm.items),
            canReceiveOrder: vm.showReceiveButton
        };
        offStateChangeStart();
        $state.go( 'inventory.transferImport', { state: $state.current.name,
                                                id: vm.transferData.id ? vm.transferData.id : null,
                                                sourceOutletId: vm.actualOutletFromId,
                                                destinationOutletId : vm.actualOutletToId,
                                                transferDocData: vm.ceTransferFinalParams});
    }

    /* Selects validation */

    function selectChange() {
        alert = document.getElementById('inventory-transfer_edit-destination_store-select');
        if(vm.actualOutletFromId && vm.actualOutletToId && vm.actualOutletFromId === vm.actualOutletToId) {
            alert.classList.remove('ng-valid', 'ng-pristine');
            alert.classList.add('ng-invalid', 'ng-valid-parse', 'ng-invalid', 'ng-invalid-select-valuer-error');
            alert.parentNode.classList.add('md-input-invalid');
            vm.sameOutletError = true;
        } else if(vm.sameOutletError === true){
            alert.classList.remove('ng-invalid', 'ng-valid-parse', 'ng-invalid', 'ng-invalid-select-valuer-error', 'ng-empty');
            alert.classList.add('ng-valid');
            alert.parentNode.classList.remove('md-input-invalid');
            vm.sameOutletError = false;
        }
    };

    offStateChangeStart = $rootScope.$on( '$stateChangeStart', function ( event, _toState, _toParam, fromState, fromParam ) {
        vm.ceTransferFinalParams = {
            actualOutletFrom: vm.actualOutletFrom,
            actualOutletTo: vm.actualOutleTo,
            startDate: $scope.startDate,
            notes: vm.notes,
            items: angular.copy(vm.items)
        };

        if (!angular.equals(vm.ceTransferStartParams, vm.ceTransferFinalParams)) {
            event.preventDefault();
            $timeout( function () {
                serviceDialog.add( {
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: translate('UNSAVED_TITLE'),
                    content: translate('UNSAVED_LEAVE'),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {

                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go( _toState, _toParam );
                            }
                        }
                    ]
                } );
            } );
        } else {
            offStateChangeStart();
        }
    });

    vm.addByBarcode = function ($event) {
        if($event.keyCode === 13) {
            enterKeydown = true;
            searchInput = $event.target;
            angular.element(searchInput).focus();
        }
    };

    /**
     *  Функция возвращает id магазинов назначения, которые недоступны данному сотруднику
     */
    function getUnavailableOutlets() {
        var outletsFromIds = vm.outletsFrom.map (function (outlet) {
            return outlet.id;
        });
        var outletsToIds = vm.outletsTo.map(function (outlet) {
            return outlet.id;
        });

        return outletsToIds.filter(function (outlet) {
            return outletsFromIds.indexOf(outlet) === -1;
        });
    }
}

export default {
    type: 'controller',
    name: 'createEditTransferController',
    value: createEditTransferController
};
