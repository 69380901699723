function coptogr() {
	return function (text, isString) {

		if (text === undefined){
			return null
		}
		text = text || 0;
		var str = "" + text;
		var strBuild = "";
		var k = 0;
		for ( var i = str.length - 1; i >= -2; i-- ) {
			k++;
			if ( 0 <= i ) {
				strBuild = str[i] + (k == 3 ? "." : "") + strBuild;
			} else if ( str.length <= 1 ) {
				strBuild = str[i] || 0 + (k == 3 ? "." : "") + strBuild;
			} else if ( str.length <= 2 && i >= -1 ) {
				strBuild = str[i] || 0 + (k == 3 ? "." : "") + strBuild;
			}
		}
		return isString=='string' ? strBuild : parseFloat(strBuild);
	}
};

export default {
	type: 'filter',
	name: 'coptogr',
	value: coptogr
};