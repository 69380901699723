const orderStatus = {
    /**
     * 1: statuses of purchase orderse
     */

    status: [{
        code: 'DRAFT'
    },
    {
        code: 'PENDING'
    },
    {
        code:'PARTIALLY'
    },
    {
        code:'CLOSED'
    }],

    /**
    *2: reasons of adjustments
    */

    // reason: ['RECEIVE_ITEMS', 'RE_COUNT', 'LOSS', 'DAMAGED'],
    reason: [{
        code: 'RECEIVE_ITEMS'
    },
    {
        code: 'RE_COUNT'
    },
    {
        code:'LOSS'
    },
    {
        code:'DAMAGED'
    }],

    /**
    *3: reasons of changing items amount in inventory history
    */

    inventoryReasons: [{
        name: "SOLD"
    },
    {
        name: "RETURNED"
    },
    {
        name: "RECEIVED"
    },
    {
        name: "TRANSFERRED"
    },
    {
        name: "RECOUNTED"
    },
    {
        name: "DAMAGED"
    },
    {
        name: "LOST"
    },
    {
        name: "PRODUCED"
    },
    {
        name:"EDIT_ITEM"
    }],

    /**
     *  4: statuses of inventory counts
     */

    invCountStatus: ['PENDING', 'IN_PROGRESS', 'COMPLETED']
};

export default {
    type: 'constant',
    name: 'orderStatus',
    value: orderStatus
};