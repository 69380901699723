/**
 * Connect file-reader with img-src-directive
 */

imgContainer.$inject = [];
function imgContainer() {
    return {
        restrict: 'C',
        controller: imgContainerController
    };
}

imgContainerController.$inject = [];
function imgContainerController() {
    this.isGifImg = false;
}

export default imgContainer;