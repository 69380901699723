/**
 * Created by Alex on 30.06.2017.
 */

function toUpperCaseFirst() {

	function format(val) {
		  if (!val) return val;

		  val = val.toLowerCase();

		  return val[0].toUpperCase() + val.slice(1);
	}

	return format;
}

export default {
	type: 'filter',
	name: 'toUpperCaseFirst',
	value: toUpperCaseFirst
};