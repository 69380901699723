/**
 * Created by Alex on 24.01.2018.
 */

currencyFormatOnlyBilling.$inject = ['$filter', 'currencyFormat'];
function currencyFormatOnlyBilling($filter, currencyFormat) {

    let tempVal;
    let currencyVal;
    let currency = $filter('coinToCurrency');
    let curFormat = {};
    let parameters = {};
    currencyFormat.observable.currencyShowBilling.observe(result => {
        curFormat = result;
        parameters = {
            decimal: curFormat.decimalSeparator,   //разделитель копеек
            group: curFormat.groupSeparator    // разделитель тысяч
        };
    }, true);

    format.$stateful = true;
    function format(val, showDecimal, billSymbol, billCurrency, billDecimal) {

        if(val !== undefined && val !== null){

            // устанавливаем 3 значения для форматирования отображения инвойсов или берем из СЕРВИСА
            if (billSymbol !== undefined && billCurrency !== undefined && billDecimal !== undefined) {
                parameters.symbol           = billSymbol;
                parameters.billingCurrency  = billCurrency;
                parameters.decimalDigits    = billDecimal;
            } else {
                parameters.symbol           = curFormat.billingSymbol;      // символ валюты
                parameters.billingCurrency  = curFormat.billingCurrency;    // буквенное обозначение валюты
                parameters.decimalDigits    = curFormat.decimalDigits;      // показывать ли копейки в диалоговых окнах для этой вылюты
            }
            // устанавливаем 3 значения для форматирования отображения инвойсов или берем из СЕРВИСА

            tempVal = currency(val, '.');

            // два if-а для отображения только в Индии
            if (!curFormat.noIndia && parameters.decimalDigits && showDecimal) {
                parameters.pattern = '!#,##,##0.00';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters) + ' ' + parameters.billingCurrency;
            }
            if (!curFormat.noIndia && parameters.decimalDigits && !showDecimal) {
                parameters.pattern = '!#,##,##0.';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters) + ' ' + parameters.billingCurrency;
            }


            // два if-а для отображения почти во всех странах где валюта расчета будет "USD" или другая похожая в будущем (много стран на одном символе) и с копейками
            if (curFormat.noIndia && parameters.decimalDigits && parameters.billingCurrency.length !== 0 && showDecimal) {
                parameters.pattern = '!#,##0.00';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters) + ' ' + parameters.billingCurrency;
            }
            if (curFormat.noIndia && parameters.decimalDigits && parameters.billingCurrency.length !== 0 && !showDecimal) {
                parameters.pattern = '!#,##0.';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters) + ' ' + parameters.billingCurrency;
            }
            // if для отображения почти во всех странах где валюта расчета будет "USD" или другая похожая в будущем (много стран на одном символе) и БЕЗ копеек
            if (curFormat.noIndia && !parameters.decimalDigits && parameters.billingCurrency.length !== 0) {
                parameters.pattern = '!#,##0.';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters) + ' ' + parameters.billingCurrency;
            }


            // два if-а для отображения в странах где валюта расчета ОТЛИЧНАЯ ОТ "USD" или похожых (много стран на одном символе) и с копейками
            if (curFormat.noIndia && parameters.decimalDigits && parameters.billingCurrency.length === 0 && showDecimal) {
                parameters.pattern = (curFormat.symbolPlaceLeft)? '!#,##0.00': '#,##0.00 !';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters);
            }
            if (curFormat.noIndia && parameters.decimalDigits && parameters.billingCurrency.length === 0 && !showDecimal) {
                parameters.pattern = (curFormat.symbolPlaceLeft)? '!#,##0.': '#,##0. !';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters);
            }


            // if для отображения в странах где валюта расчета ОТЛИЧНАЯ ОТ "USD" или похожых (много стран на одном символе) и БЕЗ копеек
            if (curFormat.noIndia && !parameters.decimalDigits && parameters.billingCurrency.length === 0) {
                parameters.pattern = (curFormat.symbolPlaceLeft)? '!#,##0.': '#,##0. !';
                currencyVal = OSREC.CurrencyFormatter.format(tempVal, parameters);
            }


            // console.log("currencyVal = ", currencyVal);
            return currencyVal;
        }
        // return currencyVal;
    }

    return format;
};

export default {
    type: 'filter',
    name: 'currencyFormatOnlyBilling',
    value: currencyFormatOnlyBilling
};
