import angular from 'angular';
import template from '../templates-pug/date-picker-days.pug';

datePickerDaysweek.$inject = ['constantDatePicker', '$rootScope'];

function datePickerDaysweek(constantDatePicker, $rootScope) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            startWeek: "@"
        },
        //require: '^calendar',
        template: template,
        link: function ( $scope ) {
            $scope.days = [];
            $scope.weekStartsWith = $rootScope.startWeek;
            // if ( $scope.startWeek == 0 ) {
            // 	for ( var i = 1; i < constantDatePicker.days.length; i++ ) {
            // 		$scope.days.push( {
            // 			text: constantDatePicker.days[i],
            // 			n: i - 1
            // 		} )
            // 	}
            // 	$scope.days.push( {
            // 		text: constantDatePicker.days[0],
            // 		n: 0
            // 	} )
            // 	$scope.days
            // } else {
            // 	angular.forEach( constantDatePicker.days, function ( day, i ) {
            // 		$scope.days.push( {
            // 			text: day,
            // 			n: (i === 6) ? 0 : i + 1
            // 		} )
            // 	} )
            // }
            angular.forEach( constantDatePicker.days, function ( day, i ) {
                $scope.days.push( {
                    text: day,
                    n: (i === 6) ? 0 : i + 1
                } )
            } )
        },
        controller: ['$scope', function ( $scope ) {

        }]
    }
}

export default datePickerDaysweek;