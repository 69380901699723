/**
 * Created by sirosh on 23.03.16.
 * Edited by kseniya.yarosh on 14.12.16
 * @param {service} promiseOperation  - promise operations factory
 * @returns {Promise} getShiftsList - promise with shifts cashbox data
 */

import angular from 'angular';

getShifts.$inject = [
    'promiseOperation',
    'serviceReportsError'
];

function getShifts(promiseOperation, serviceReportsError) {

    return {
        getShiftsList: getShiftsList
    };


    /**
     * Success shifts list handler
     * @param {Object} response
     * @returns {{ Object }}
     */
    function successShifts(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                let shiftsResponse = {
                    outlets: response.data.fillSelectData.outlets,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    startDateString: response.data.startDateString,
                    endDateString: response.data.endDateString,
                    periodAllowed: response.data.periodAllowed
                }
                if (response.data.periodAllowed) {
                    shiftsResponse.pages = response.data.pages;
                    shiftsResponse.shifts = response.data.shifts;
                }

                return shiftsResponse;
            }
        }
    }

    /**
     * Return shifts promise
     * @param {object} params -  http query params object
     * @returns {Promise}
     */
    function getShiftsList(params) {

        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getshifts',
                params,
                successShifts,
                serviceReportsError.reportsError
            );
    }
}

export default {
    type: 'factory',
    name: 'getShifts',
    value: getShifts
};
