appDetail.$inject = [
    '$appInfo',
    '$scope',
    '$state',
    'serviceDialog',
    '$filter',
    'factoryApps',
    '$stateParams'
];

function appDetail(
    $appInfo,
    $scope,
    $state,
    serviceDialog,
    $filter,
    factoryApps,
    $stateParams
) {

    var vm = this;

    vm.appInfo = $appInfo;
    var translate = $filter('translate');

    $scope.onBack = function () {
        $state.go('integrations.apps', {});
    };

    vm.onDisconnect = function () {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   translate('APPS.DISCONNECT_DIALOG_TITLE'),
            content:  translate('APPS.DISCONNECT_DIALOG_TEXT'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary',
                    text: 'APPS.DISCONNECT',
                    action: disconnectApp
                }
            ]
        });
    };

    function disconnectApp() {

        factoryApps.disconnectApp({
            id: $stateParams.id
        }).then(function (result) {

            if (result.result === "ok") {
                $state.go('integrations.apps', {});
            }
        });
    }

}

export default {
    type: 'controller',
    name: 'appDetail',
    value: appDetail
};