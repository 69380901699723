function filterMenuName() {
	return function (val){

		return val.replace(/\./g, function($1){return '-';});
	}
}

export default {
	type: 'filter',
	name: 'filterMenuName',
	value: filterMenuName
};