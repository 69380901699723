/**
 * Created by sirosh on 04.02.16.
 */

employeeListCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$filter",
    "$state",
    "$stateParams",
    "serviceDialog",
    '$employees',
    "serviceToast",
    "factoryProfile",
    "getEmployees",
    '$locale',
    '$translate',
    '$shortOutlets',
    '$permits',
    '$profile',
    'serviceBillingMixpanel',
    'permissions',
    '$accountInfo',
    'dialogs',
    'factoryPopover',
    '$window',
    'isBannerDisplayed'
];

function employeeListCtrl(
    $scope,
    $rootScope,
    $filter,
    $state,
    $stateParams,
    serviceDialog,
    $employees,
    serviceToast,
    factoryProfile,
    getEmployees,
    $locale,
    $translate,
    $shortOutlets,
    $permits,
    $profile,
    serviceBillingMixpanel,
    permissions,
    $accountInfo,
    dialogs,
    factoryPopover,
    $window,
    isBannerDisplayed
) {
    $scope.editTableState   = 'employees.editemployee';
    $scope.employees        = $employees.merchants;
    $scope.pages            = $employees.pages;
    const total               = $employees.total;
    const maxEmployees = $profile.employeesCountInTrial;
    $scope.permissions      = $permits;
    $scope.delete           = false;
    $scope.employeesSelect  = [];
    $scope.allEmployees     = {};
    $scope.pin              = {value: ['', '', '', '']};
    $scope.user             = {page: $stateParams.page + 1};
    $scope.accountInBlock   = $profile.billingInfo.billingStatus === "BLOCKED";
    $scope.reloadToUnblock  = $stateParams.reloadToUnblock;

    // employee management promo banner data
    $scope.bannerOptions = {
        lang: $profile.cabinetLang,
        show: isBannerDisplayed
    }

    const billingInfo = $profile.billingInfo.alert;
    const nowDate = new Date().getTime();

    for (let i = 0; i < $scope.employees.length; i++) {
        $scope.employees[i]["_select"] = false;
    }

    $scope.backToBilling = function() {
        $state.go('settings.account', {} );
    };

    $scope.$watch('employees', function (newVal) {
        for (let j = 0; j < newVal.length; j++) {
            if (newVal[j]._select === true) {
                $scope.employeesSelect.push(newVal[j]._select);
            }
        }
        $scope.delete = $scope.employeesSelect.length > 0;

        if ($scope.employeesSelect.length === $scope.employees.length - 1) {
            $scope.allEmployees._select = $scope.employees.length !== 1;
        } else {
            $scope.allEmployees._select = false;
        }
        $scope.employeesSelect = [];
    }, true);

    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;

    if ($scope.employees.length === 0 && $scope.stateFromList.page > 0) {
        $state.go( 'employees.list', {page: $scope.pages - 1, outletsIds: $scope.stateFromList.outletsIds} );
    }

    if ($scope.checkFromList.length === undefined || $scope.checkFromList.length === 0) {
        for (let i = 0; i < $scope.employees.length; i++) {
            $scope.employees[i]._select = false;
        }
    } else {
        for (let i = 0; i < $scope.checkFromList.length; i++) {

            for (let q = 0; q < $scope.employees.length; q++) {
                ($scope.checkFromList[i] === $scope.employees[q].id)? $scope.employees[q]._select = true : "";
                (!$scope.employees[q]._select)? $scope.employees[q]._select = false: "";
            }
        }
    }


    $scope.checkEmployeesList = [];

    $scope.$watch('employees', function(newval, oldval){
        for (let k = 0; k < newval.length; k++) {
            if (newval[k]._select === true) {
                const idx = $scope.checkEmployeesList.indexOf(newval[k].id);
                if (idx > -1) {
                    $scope.checkEmployeesList.splice(idx, 1);
                }
                $scope.checkEmployeesList.push(newval[k].id);
            } else {
                const idx = $scope.checkEmployeesList.indexOf(newval[k].id);
                if (idx > -1) {
                $scope.checkEmployeesList.splice(idx, 1);
                }
            }
        }

        ($scope.checkEmployeesList.length === 0)? $scope.delete = false : $scope.delete = true;

        if (($stateParams.page > 0) && ($scope.checkEmployeesList.length === $scope.employees.length)) {
            $scope.allEmployees._select = true;
        } else {
            $scope.allEmployees._select = ($stateParams.page === 0) && ($scope.checkEmployeesList.length === ($scope.employees.length - 1)) && $scope.employees.length !== 1;
        }
    }, true);


    $scope.shortOutlets     = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.selectOutlet     = {id: $stateParams.outletsIds ? +$stateParams.outletsIds : null};
    $scope.selectOutletId   = $scope.selectOutlet.id;

    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval !== newval) {
            $state.go('employees.list', {outletsIds: newval.id, page: 0}).then(function() {
                $scope.selectOutletId = newval.id;
            });
        }
    }, true);


    $scope.changeAll = function () {
        if ($scope.allEmployees._select === true) {
            for (let k = 0; k < $scope.employees.length; k++) {
                if ($scope.employees[k]["type"] !== 'OWNER') {
                    $scope.employees[k]["_select"] = true;
                }
            }
            $scope.allEmployees._select = true;
        } else {
            $scope.allEmployees._select = false;
            for (let m = 0; m < $scope.employees.length; m++) {
                $scope.employees[m]["_select"] = false;
            }
        }
    };


    function setPin(){
        const toastTitle = 'EMPLOYEE.PIN_SAVED';

        const params = { pin: $scope.pin.value.join('')};

        getEmployees.setOwnerPin(params).then(function(res){
            if (res.result === "ok") {
                serviceToast.show(toastTitle, 'success');
                factoryProfile.getProfile(true).then(function (profile){
                    $state.go('employees.createemployee', $stateParams, {});
                });
            }
        });
    }


    $scope.onAdd = function () {
        if ($scope.employees.length === 1 && $scope.employees[0].pin === null) {
            serviceDialog.add({
                templateUrl:    'dashboard-page/templates/dialog/dialog-pin.html',
                title:          $translate.instant('EMPLOYEE.SET_PIN'),
                content:        $translate.instant('EMPLOYEE.SET_PIN_MESSAGE'),
                pin:            $scope.pin,
                buttons:        [{  text: 'ON_CANCEL',
                                    action: function(){
                                        $scope.pin.value = ['', '', '', ''];
                                        return true;
                                    }
                                },
                                {
                                    class: 'primary',
                                    text: 'CONTINUE',
                                    action: setPin
                                }]
            });
        } else if (total > maxEmployees && (billingInfo !== null && nowDate < billingInfo.trialEndsOn && billingInfo.type === "EMPLOYEE")) {
            serviceDialog.add({
                templateUrl:        'dashboard-page/templates/dialog/dialog-base.html',
                title:              $translate.instant('EMPLOYEE.UNABLE_TO_SAVA_EMPLOYEE'),
                content:            $translate.instant('ERROR.maximum_employees_reached', {maxEmployees: maxEmployees}),
                buttons:        [{  class: 'primary',
                    text: 'ON_OK'}]
            });
        } else {
            $state.go('employees.createemployee', {stateToSave: $scope.stateFromList, checkToSave: $scope.checkEmployeesList});
        }
    };


    $scope.onDel = function () {
        $scope.employeesIds = [];

        for (let i = 0; i < $scope.employees.length; i++) {
            if ($scope.employees[i]._select === true) {
                $scope.employeesIds.push($scope.employees[i].id);
            }
        }
        if ($scope.employeesIds.length === 1) {
            $scope.msgTitle     = $translate.instant('EMPLOYEE.DELETE_EMPLOYEE');
            $scope.tostTitle    = 'EMPLOYEE.EMPLOYEE_DELETED';
            $scope.content      = $translate.instant('EMPLOYEE.DELETE_EMPLOYEE_TEXT');
        } else {
            $scope.msgTitle     = $translate.instant('EMPLOYEE.DELETE_EMPLOYEES');
            $scope.tostTitle    = 'EMPLOYEE.EMPLOYEES_DELETED';
            $scope.content      = $translate.instant('EMPLOYEE.DELETE_EMPLOYEES_TEXT');
        }
        const lastEmployee = $scope.employees.length-1 === $scope.employeesIds.length;

        $scope.onSendData = function () {
            getEmployees.deleteEmployee({merchantIds: $scope.employeesIds}).then(function (res) {

                const freeSalesHistory = $accountInfo.subscriptions['SALESHISTORY'].status === 'FREE';
                if (lastEmployee) {
                    serviceBillingMixpanel.setMixpanelBillingDelSubscriptionInEmployee(res.result, freeSalesHistory ? 'EMPLOYEE' : 'EMPSTORE');
                }

                if (res.result === "ok") {
                    serviceToast.show($scope.tostTitle, 'success');
                    $state.go('employees.list', {   page:               $scope.stateFromList.page,
                                                    limit:              $scope.stateFromList.limit,
                                                    checkFromList:      [],
                                                    reloadToUnblock:    lastEmployee}, {
                        reload: true
                    });
                }
                else if (res.result === "error") {
                    switch (res.message) {
                        case "not_permitted_while_past_due":
                            serviceDialog.add({
                                templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                                class:          'billing-base-384',
                                content1:        $translate.instant('ACCOUNT.NOT_PERMITTED_PAST_DUE'),
                                buttons:        [{  class: 'primary',
                                    text: 'ON_OK',
                                    action: function () {}
                                }]
                            });
                            break;
                        case "temporary_unavailable":
                            serviceDialog.add({
                                templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                                content1:        $translate.instant('ACCOUNT.B2_SERVICE_DIALOG.B2_ERROR_CHARGEBEE'),
                                buttons:        [{  class: 'primary',
                                    text: 'ON_OK',
                                    action: function () {}
                                }]
                            });
                            break;
                        case 'employee_deleted':
                            dialogs.listItemDeletedEarlier().then(() => {
                                $state.go('employees.list', {
                                    page:               $scope.stateFromList.page,
                                    limit:              $scope.stateFromList.limit,
                                    checkFromList:      []},
                                    { reload: true });
                            });
                            break;
                    }

                }
            });
        };

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/dialog-base.html',
            title:          $scope.msgTitle,
            content:        $scope.content,
            buttons:       [{   text: 'ON_CANCEL'},
                            {   class: 'primary l-margin-left-8-i',
                                text: 'ON_DEL',
                                action: $scope.onSendData}
                            ]
        });
    };


    function reloadRoute() {
        setTimeout(function () {
            const urlWithHash = window.location.href;
            window.location.replace(urlWithHash);
            permissions.updatePermissions(true);
            permissions.updateAccountInfo();
        }, 0);
    }

    if ($scope.reloadToUnblock) {
        reloadRoute()
    }

    //-- Mode CLOSE Portrait and Landscape filter dropdown menu
    $scope.popover = factoryPopover;
    $scope.openPopovers = {
        tax: false,
    };
    $scope.breakpoints = {
        0: "left",
        400: "top-right",
    };
    const onResize = () => {
        $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
        console.log('resize')
    };
    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);
    //-- END mode CLOSE Portrait and Landscape filter dropdown menu
}

export default {
    type: 'controller',
    name: 'employeeListCtrl',
    value: employeeListCtrl
};
