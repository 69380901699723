/**
 * Created by alex on 18.10.16.
 */

const waresReportTableCategories = {
    categoryName  : 'CATEGORY',
    itemsSold     : 'REPORT_GOODS.GOODS_SOLD',
    grossSales    : 'REPORT_SALES.GROSS_SALES',
    itemsRefunded : 'REPORT_CATEGOR.ITEMS_REFUNDED',
    refunds       : 'REPORT_SALES.REFUNDS',
    discounts     : 'REPORT_SALES.PAYMENT_OF_BONUSES',
    netSales      : 'REPORT_SALES.NET_SALES',
    costOfGoods   : 'REPORT_CATEGOR.COST_GOODS',
    grossProfit   : 'REPORT_GOODS.GROSS_PROFIT',
    margin        : 'MARGIN_PERCENT',
    taxes         : 'REPORT_GOODS.TAX_SUM'
};

export default {
    type: 'constant',
    name: 'waresReportTableCategories',
    value: waresReportTableCategories
}