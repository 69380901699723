/**
 * Created by sirosh on 04.02.16.
 * @memberof getEmployeesList
 * @ngdoc factory
 * @name getEmployeesList
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param factoryErrorAlert {service} error handler service
 * @returns getCategoriesList{Promise} categories list data
 */

import angular from 'angular';

getEmployees.$inject = ["$http", "$q", "factoryErrorAlert", "promiseOperation"];

function getEmployees($http, $q, factoryErrorAlert, promiseOperation) {


    var responseDataCreate = {};
    var responseDataById = {};
    var responseDataEdit = {};
    var responseDataDelete = {};

// получаем краткий список торговых точек
    function getShortOutlets(params) {
       return promiseOperation
           .getPromiseHTTP(
           'POST',
           '/data/ownercab/getshortoutlets',
           params,
           successListOutlets
       );
    }

    function successListOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data.outlets;
            }
        }
    }
// получаем краткий список торговых точек


    function success(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    const successMultipleResult = (response) => response?.data ?? null

    function successCreate(response) {
        if (angular.isObject(response.data)) {
            responseDataCreate = response.data;
        }
    }

    function successDelete(response) {
        if (angular.isObject(response.data)) {
            responseDataDelete = response.data;
        }
    }

    function successGetById(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                responseDataById = {
                    merchantId: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone,
                    pin: response.data.pin,
                    type: response.data.type,
                    hasOtherMerchants: response.data.hasOtherMerchants,
                    possibleRoles: response.data.possibleRoles,
                    roleId: response.data.roleId,
                    wasLoggedInOwnerCabinet: response.data.wasLoggedInOwnerCabinet,
                    firstCabLogin: response.data.firstCabLogin,
                    emailWasSent: response.data.emailWasSent,
                    byOutlets: response.data.byOutlets,
                    allowedForAllOutlets: response.data.allowedForAllOutlets,
                    employeeId: response.data.publicId,
                    currentlyLogged: response.data.currentlyLogged
                };
            } else {
                responseDataById = response.data;
            }
        }
    }

    function successEditEmployee(response) {
        if (angular.isObject(response.data)) {
            responseDataEdit = response.data;
        }
    }


    function getEmployeesListData(params) {

        if(params.outletsIds == "" || params.outletsIds == undefined){
          params.outletsIds = null;
        } else {
          params.outletsIds = [params.outletsIds];
        }
        // console.log("params = ", params);

        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getmerchants',
                params,
                success
            );
    }


    function setEmployee(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/createmerchant', params).then(function (d) {
                //console.log(d);
                successCreate(d);
                resolve(responseDataCreate);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function editEmployee(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/editmerchant', params).then(function (d) {
                successEditEmployee(d);
                resolve(responseDataEdit);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function deleteEmployee(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deletemerchants', params).then(function (d) {
                successDelete(d);
                resolve(responseDataDelete);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function getEmployeeById(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getmerchantbyid', params).then(function (d) {
                successGetById(d);
                resolve(responseDataById);
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }

    function successSetDesableSettinsHintOnPos(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function setDesableSettinsHintOnPos(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/setDesableSettinsHintOnPos',
            params,
            successSetDesableSettinsHintOnPos
        );
    }

    function getActualStaffReport(params) {
        return $http.post('/data/ownercab/getmerchantswithoutlets', params).then(success, factoryErrorAlert.onError);
    }

    const hideEmployeesPromoBanner = () => {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/hide-employee-management-banner',
            {},
            success)
    }

    const isEmployeesPromoBannerDisplayed = () => {
        return $http.post('/data/ownercab/is-employee-management-banner-displayed', {}).then (
            (res) => angular.isObject(res.data) && res.data.result === "ok" && res.data.bannerDisplayed,
            () => false
        )
    }

    const resendInvitationEmail = (params) => $http.post('/data/ownercab/resend-back-office-invitation', params)
      .then(
          successMultipleResult,
          (error) => {
              factoryErrorAlert.onError(error);
              return error;
          });

    const setOwnerPin = (params) => $http.post('/data/ownercab/set-owner-pin', params)
      .then(
        successMultipleResult,
        (error) => {
            factoryErrorAlert.onError(error);
            return error;
        });


    return {
        setEmployee: setEmployee,
        getEmployeesListData: getEmployeesListData,
        getActualStaffReport: getActualStaffReport,
        deleteEmployee: deleteEmployee,
        getEmployeeById: getEmployeeById,
        editEmployee: editEmployee,
        getShortOutlets: getShortOutlets,
        setDesableSettinsHintOnPos: setDesableSettinsHintOnPos,
        hideEmployeesPromoBanner: hideEmployeesPromoBanner,
        isEmployeesPromoBannerDisplayed: isEmployeesPromoBannerDisplayed,
        resendInvitationEmail: resendInvitationEmail,
        setOwnerPin: setOwnerPin
    };

}

export default {
    type: 'factory',
    name: 'getEmployees',
    value: getEmployees
};
