

createDirective.$inject = [];

function createDirective() {

    return {
        restrict: 'A',
        controller: AdaptiveItemController,
        bindToController: true,
        require: {
            container: '^lvAdaptiveContainer'
        },
    };
}

AdaptiveItemController.$inject = ['$element'];
function AdaptiveItemController($element) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;

    let self = this;
    let api = {
        setFont: setFont
    };
    let contentElement;

    function setFont(selectedIndex, classes) {
        classes.forEach((fontClass, index) => {
            contentElement.toggleClass(fontClass, index === selectedIndex);
        });
    }

    function $onInit() {
        $element.addClass('adaptive-container-item');
    }

    function $postLink() {
        self.container.registerItem(api);
        contentElement = $element.find('[lv-adaptive-item-content]');
    }

}

export default {
    type: 'directive',
    name: 'lvAdaptiveItem',
    value: createDirective
};
