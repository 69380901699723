/**
 * Created by kseniya.yarosh on 21.11.16.
 * @memberof editoutletCtrl
 * @ngdoc controller
 * @name createoutletCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter service
 * @requires $state {service} UI Router service
 * @requires $stateParams {service} UI Router service
 * @requires getOutletsList {service} Get outlets list / operations with outlets
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 // * @requires $outlets {service} Get outlets list before load page
 // * @requires $outlet {service} Get a certain outlet before load page
 */

import angular from 'angular';

createoutletCtrl.$inject = [
    "$scope",
    "$filter",
    "$state",
    "getOutletsList",
    "$outlets",
    "promiseOperation",
    "serviceDialog",
    "serviceToast",
    '$profile',
    '$minAccountInfo',
    'serviceBillingMixpanel',
    'serviceBillingError',
    'permissions',
    'lvCountriesMultiLang',
    'localizationUtil',
    'countriesStoreExclude',
    "factoryInvoicing",
    "factoryLinks"
];

function createoutletCtrl(
    $scope,
    $filter,
    $state,
    getOutletsList,
    $outlets,
    promiseOperation,
    serviceDialog,
    serviceToast,
    $profile,
    $minAccountInfo,
    serviceBillingMixpanel,
    serviceBillingError,
    permissions,
    lvCountriesMultiLang,
    localizationUtil,
    countriesStoreExclude,
    factoryInvoicing,
    factoryLinks
) {
    $scope.profile = angular.copy($profile);
    // блок инициализации данных
    let vm                  = this;
    let translate           = $filter('translate');
    let offStateChangeStart;
    vm.countries = lvCountriesMultiLang.filter(country => {
        return !countriesStoreExclude.includes(country.code);
    })
    vm.profileOutletsLimit  = $profile.outletsCountLimit;
    vm.outlets              = $outlets.outlets;
    vm.showVatNumber        = $outlets.useVatNumber;
    vm.showVatNumberHint    = vm.showVatNumber && $profile.country === "sa";
    
    vm.outletParams = {
        location: '0.0;0.0',
        name: '',
        address: null,
        phone: null,
        comment: null,
        vatNumber: null,
        city: '',
        region: '',
        postcode: '',
        buildingNumber: null,
        district: null,
        country: $profile.country,
        enableEInvoicing: false,
        organizationUnitName: null,
        organizationName: null,
        transactionType: null,
        industry: null
    };

    $scope.helpLink = factoryLinks.help($profile, "help/settings")

    vm.changeLabelsNames = changeLabelsNames;
    vm.onCancelStore = onCancelStore;
    vm.notSaved = false;
    vm.uniqueName = true;
    vm.progress = false;
    
    vm.invoicing = $profile.features.eInvoicing;
    vm.isSaudi = $profile.country === 'sa';

    vm.isEdit = false;
    vm.externalButtons = vm.invoicing && vm.isSaudi;
    vm.inlineButtons = !vm.externalButtons;
    
    const isBlockedByStores = $scope.profile.blockedForNoStores;

    vm.cityLabel = localizationUtil.getCityLabel($profile.country);
    vm.provinceLabel = localizationUtil.getProvinceLabel($profile.country);
    vm.postalLabel = localizationUtil.getPostalCodeLabel($profile.country);

    //-- CODE FOR BILLING
    $scope.emptyPlan        = {id:null, cycle:null, unlim:null, price:null};
    $scope.minAccountInfo   = angular.copy($minAccountInfo);
    const profBillingStatus   = $profile.billingInfo.billingStatus;

    const subscriptions = Object.keys($scope.minAccountInfo.subscriptions).filter((sub) => {
        return ["EMPSTORE", "INVENTORY", "INTEGRATION", "SALESHISTORY"].includes(sub);
    });
    const subscriptionsInfo = {};
    subscriptions.forEach((subscription, index) => {
        const subscrObj = {};
        subscrObj.status = profBillingStatus === "FREE"
            ? "FREE" : angular.copy($scope.minAccountInfo.subscriptions[subscription].status);
        subscrObj.plan = profBillingStatus === "FREE"
            ? angular.copy($scope.emptyPlan) : angular.copy($scope.minAccountInfo.subscriptions[subscription].plan);
        subscrObj.activatedNotUnlimited = (subscrObj.status==="ACTIVE"
            || subscrObj.status==="PAST_DUE") && !subscrObj.plan.unlim

        subscriptionsInfo[subscription] = subscrObj;
    });
    //-- END CODE FOR BILLING


    /**
     * @ngdoc function
     * @name offStateChangeStart
     * @description
     * Checks whether the current state is going to change and if so,
     * then checks whether the data have been changed in comparison with
     * the initial state. If it's true, it invokes serviceDialog with an appropriate notification.
     */
    offStateChangeStart = $scope.$on( '$stateChangeStart', function (
        event,
        toState,
        toParam,
        fromSate,
        fromParam
    ){
        for(let property in vm.outletParams) {
            if (vm.outletParams.hasOwnProperty(property)) {
                if ((property === 'name' ||
                    property === 'address' ||
                    property === 'phone' ||
                    property === 'comment' ||
                    property === 'city' ||
                    property === 'region' ||
                    property === 'postcode' ||
                    property === 'buildingNumber' ||
                    property === 'district' ||
                    property === 'country') && vm.outletParams[property] === "") {
                    vm.outletParams[property] = null;
                }
            }
        }

        if (vm.outletParams.name ||
            vm.outletParams.address ||
            vm.outletParams.phone ||
            vm.outletParams.comment ||
            vm.outletParams.city ||
            vm.outletParams.region ||
            vm.outletParams.postcode ||
            vm.outletParams.buildingNumber ||
            vm.outletParams.district ||
            (vm.outletParams.country && vm.outletParams.country !== $profile.country)){
            event.preventDefault();
            promiseOperation
                .getPromiseWithQ({
                    toState: toState,
                    toParam: toParam
                })
                .then(unSaveAlert);
        }

        function unSaveAlert(d){
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('UNSAVED_TITLE'),
                content: translate('UNSAVED_LEAVE'),
                buttons: [
                    {
                        text: 'UNSAVED_CONTINUE'
                    },
                    {
                        class: 'primary',
                        text: 'UNSAVED_DISCARD',
                        action: function () {
                                    offStateChangeStart();
                                    $state.go( d.toState, d.toParam);
                                }
                    }
                ]
            });
        }
    });

    $scope.$on('$destroy', function(){
        offStateChangeStart();
    });


    // function of CREATING an institution on the server
    function createStore(newStore) {

        vm.progress = true;
        getOutletsList.addOutlet(newStore).then(function (res) {

            vm.notSaved = true;
            if (res.result === "ok") {
                permissions.updatePermissions(true);
                sendMixpanelEvents("ok");
                let stateOptions = {};
                if (isBlockedByStores) {
                    stateOptions.reloadToUnblock = true;
                }
                offStateChangeStart();
                serviceToast.show('MULTISHOP.STORES.STORE_CREATED', 'success');
                $state.go('settings.outlet', stateOptions)
                    .finally(() => {
                        vm.progress = false;
                    });
            }
            else {
                vm.progress = false;
                sendMixpanelEvents("error");

                if (res.result === "error") {
                    vm.notSaved = false;

                    if (res.message === 'outlet_with_name_exists') {
                        vm.uniqueName = false;
                        serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                    }
                    else {
                        serviceBillingError.knownError(res.message);
                    }
                }
                else {
                    vm.notSaved = false;
                    serviceBillingError.unKnownError();
                }
            }
        }).catch(() => {
            vm.progress = false;
        });
    }
    //-- END function of CREATING an institution on the server


    function changeLabelsNames(countryCode) {
        vm.cityLabel = localizationUtil.getCityLabel(countryCode);
        vm.provinceLabel = localizationUtil.getProvinceLabel(countryCode);
        vm.postalLabel = localizationUtil.getPostalCodeLabel(countryCode);
    }

    // the function by clicking on SAVE during CREATION, makes checks and
    // if OK starts CREATING the institution
    function onSaveStore() {
        vm.notSaved = true;
        vm.outletParams.outletId        = null;
        vm.outletParams.schedule        = [{}];
        vm.outletParams.receiptHeader   = '';
        vm.outletParams.receiptFooter   = '';
        vm.outletParams.receiptLang     = $scope.profile.cabinetLang || null;

        let phoneWithoutPlus;

        if (vm.outletParams.phone) {
            phoneWithoutPlus = vm.outletParams.phone.replace(/\+/, '');
        } else {
            phoneWithoutPlus = null;
        }

        if (!vm.outletParams.name || (vm.outletParams.name.length > 40
            || (phoneWithoutPlus && (phoneWithoutPlus.length > 20
                || phoneWithoutPlus.length < 6)) || (vm.outletParams.comment
                && vm.outletParams.comment.length > 128)
            || (vm.outletParams.address && vm.outletParams.address.length > 255)) ){
            angular.element( document.querySelectorAll( '.outlet-options' ) ).addClass('md-input-focused');
            vm.notSaved = false;
        } else {
            vm.progress = true;
            getOutletsList.getOutlets({}).then(function (result) {
                if (result.outlets.length >= vm.profileOutletsLimit || result.message === "too_many_outlets") {

                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        title: translate('MULTISHOP.STORES.UNABLE_TO_ADD_STORE'),
                        content: translate('MULTISHOP.STORES.ONLY_HUNDRED_STORES'),
                        buttons: [
                                    {
                                        class: 'primary',
                                        text: 'ON_OK'
                                    }
                                ]
                    });
                } else {
                    vm.notSaved = true;
                    const activeSubscriptions = Object.entries(subscriptionsInfo)
                        .filter(([type, info]) => angular.isObject(info) && info.activatedNotUnlimited);

                    if (activeSubscriptions.length > 0) {
                        let titleKey;

                        // if only one subscription is activated, title will
                        // contain its name, otherwise common title will be used
                        if (activeSubscriptions.length === 1) {
                            const subscriptionType = activeSubscriptions.map(([type, info]) => type).join();
                            titleKey = `ACCOUNT.B2_SERVICE_DIALOG.B2_PAYMENT_FOR_${subscriptionType}`;
                        } else {
                            titleKey = "ACCOUNT.B2_SERVICE_DIALOG.B2_PAYMENT_FOR_SUBSCRIPTIONS";
                        }

                        serviceDialog.add({
                            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-payment-when-create.html',
                            title:          translate(titleKey),
                            content1:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAYMENT_FOR_STORE_TEXT'),
                            paymentType:    $scope.minAccountInfo.paymentMethod,
                            inEU:           $scope.minAccountInfo.taxMessage,
                            buttons: [  {   text:  'ON_CANCEL',
                                            action: function () {
                                                vm.notSaved = false;
                                                sendMixpanelEvents("cancel");
                                                vm.progress = false;
                                            }
                                        },
                                        {   text:   'ACCOUNT.PAY',
                                            action: function () {
                                                createStore(vm.outletParams);
                                            }
                                        }
                                    ]
                        });
                    } else {
                        createStore(vm.outletParams);
                    }
                }
            }).catch(() => {
                vm.progress = false;
            });
        }
    }
    // END the function by clicking on SAVE during CREATION, makes checks
    // and if OK starts CREATING the institution

    /**
     * @ngdoc function
     * @name onCancelStore
     * @description
     * Gets a user back to previous state - settings
     */
    function onCancelStore() {
        let cancelState = isBlockedByStores ? 'nostores' : 'settings.outlet'
        $state.go(cancelState);
    }

    /**
     * Method sends events for Mixpanel for all active subscriptions
     * @param res response
     */
    function sendMixpanelEvents(res) {
        Object.keys(subscriptionsInfo).forEach((type) => {
            if (((type === "INVENTORY" || type === "INTEGRATION" || type === 'EMPSTORE')
                && subscriptionsInfo[type].activatedNotUnlimited) || type === 'SALESHISTORY') {
                serviceBillingMixpanel.setMixpanelBillingPaymentWhenCreate(
                    res,
                    subscriptionsInfo[type].plan,
                    type,
                    subscriptionsInfo[type].status
                );
            }
        });
    }

    $scope.features = $profile.features;

    $scope.invoicingAuthorized = vm.outletParams.enableEInvoicing;
    $scope.hasNumericInvoicingUnit = factoryInvoicing.hasNumericInvoicingUnit;

    $scope.onToggleInvoicing = () => {
      if (vm.outletParams.enableEInvoicing && !vm.outletParams.transactionType) {
        vm.outletParams.transactionType = "B2C"
      }
    };
  
    $scope.onSubmit = () => {
      if ($scope.outletForm.$invalid) {
          $scope.outletForm.focusFirstInvalidControl();
          serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
          return;
      }

      if (vm.outletParams.enableEInvoicing && !$scope.invoicingAuthorized) {
        serviceDialog.add({
          templateUrl:
            "dashboard-page/templates/dialog/dialog-enable-invoicing.html",
          buttons: [
            {
              class: "primary",
              text: "ON_CANCEL",
              action: () => {
                vm.outletParams.enableEInvoicing = false;
              },
            },
            {
              class: "primary l-margin-left-8-i",
              text: "ON_ENABLE",
              action: () => {
                $scope.invoicingAuthorized = true;
                onSaveStore();
              },
            },
          ],
        });
      } else {
        onSaveStore();
      }
    };
  }

export default {
    type: 'controller',
    name: 'createoutletCtrl',
    value: createoutletCtrl
};
