/**
 * Created by mars on 12/25/15.
 */

const froalaLang = {
	ara: "ar",
	chn: "zh_cn",
	zho: "zh_tw",
	hrv: "hr",
	nld: "nl",
	deu: "de",
	ell: "el",
	est: "et",
	fin: "fi",
	fra: "fr",
	hun: "hu",
	ind: "id",
	ita: "it",
	jpn: "ja",
	kor: "ko",
	nor: "nb",
	pol: "pl",
	por: "pt_pt",
	ron: "ro",
	rus: "ru",
	spa: "es",
	tha: "th",
	tur: "tr",
	vie: "vi"
};

export default {
	type: 'constant',
	name: 'froalaLang',
	value: froalaLang
};