/**
 * Created by sirosh on 28.01.16.
 * options for resize digits - pixes values
 */

const constTaxDigitOptions = [
    {
        px: 28,
        from: 7,
        to: 8
    },
    {
        px: 26,
        from: 9,
        to:  10
    },
    {
        px: 24,
        from: 11,
        to: 12
    }
];

export default {
    type: 'constant',
    name: 'constTaxDigitOptions',
    value: constTaxDigitOptions
};