import angular from 'angular';

createClass.$inject = ['TableViewColumnPropertyView'];

function createClass(TableViewColumnPropertyView) {
    return class TableViewColumnPropertyViewExtended extends TableViewColumnPropertyView {
        constructor(title, valueGetter, fields, options) {
            super(title, valueGetter, options);
            this.fields = fields;
        }

        getElement() {

            const mainElement = super.getElement();

            const containerElement = angular.element('<div>');
            containerElement.append(mainElement);

            if (this.fields) {
                this.fields.forEach((field, index) => {

                    const fieldElement = angular.element('<div>');
                    if (field.isVisible) {
                        fieldElement.attr('ng-if', 'ctrl.column.fields[' + index + '].isVisible(ctrl.item)');
                    }
                    if (field.class) {
                        fieldElement.addClass(field.class);
                    }
                    const innerTemplate = [];
                    if (field.displayValue) {
                        innerTemplate.push(field.displayValue);
                    }
                    if (field.valueGetter) {
                        innerTemplate.push('{{ctrl.item.' + field.valueGetter + '}}');
                    }
                    if (innerTemplate.length > 0) {
                        fieldElement.append(innerTemplate.join(' '));
                    }
                    containerElement.append(fieldElement);
                });
            }

            return containerElement;
        }
    }
}

export default {
    type: 'factory',
    name: 'TableViewColumnPropertyViewExtended',
    value: createClass
};