/**
 * Created by olehkss on 11.05.16.
 * Edited by kseniya.yarosh on 14.12.16
 * @ngdoc controller
 * @name app.controller:reportDiscountsController
 * @description
 * Controls view of discounts report
 * @requires $state
 * @requires $stateParams
 * @requires {object} $discounts  discounts sales report and number of pages, object is resolved by ui-router
 */

reportDiscountsController.$inject = [
    '$discounts',
    '$scope',
    '$stateParams',
    '$state',
    '$currencyOptionsShow',
    'constantGreenExport',
    '$profile',
    '$countOptionsShow',
    'serviceReports',
    '$permitsManager',
    '$miniBillingInfo',
    '$reportCopyAllowed'];

function reportDiscountsController(
    $discounts,
    $scope,
    $stateParams,
    $state,
    $currencyOptionsShow,
    constantGreenExport,
    $profile,
    $countOptionsShow,
    serviceReports,
    $permitsManager,
    $miniBillingInfo,
    $reportCopyAllowed) {

    let vm = this;
    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.reportError = $discounts.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && $discounts.unableToLoadData;

    vm.downloadHrefBase = '/data/ownercab/discountsreportdownload';
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    if(!vm.reportError) {
        vm.periodAllowed = $discounts.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($discounts.startDateString && $discounts.endDateString) {
            let fromParse = $discounts.startDateString.split('-');
            let toParse = $discounts.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $discounts.startDate;
            vm.endDate = $discounts.endDate;
        }

        $scope.outlets = $discounts.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        if (vm.periodAllowed) {
            vm.discounts = $discounts.data;
            vm.pages = $discounts.pages;


            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.discounts.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
                $state.go('report.discounts', {page: vm.pages - 1});
            } else if (vm.discounts.length === 0 && $stateParams.page > 0 && vm.pages === 0) {
                $state.go('report.discounts', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            /*** формат отображения колличества ***/
            $scope.countOptionsShow = $countOptionsShow
        }
    }

}

export default {
    type: 'controller',
    name: 'reportDiscountsController',
    value: reportDiscountsController
};
