/**
 * Created by olehkss on 27.05.16.
 *
 * @ngdoc directive
 * @name app.directive:noDataMessage
 * @scope
 * @restrict E
 *
 * @description
 * Adds simple message about with picture and two lines of text. Can be used in order to be shown if the server response
 * returns nothing(no data).
 *
 * @param {string} message  second message sting which will be translated via translate module
 * @param {string} imageSource  image path that will replace default one
 * @param {string} title first message to be shown
 * @param {string} path to the svg image to be shown using md-icon directive
 *
 * @example
 * <no-data-message message = "Some_message" img-src = 'path_to_new_image'></no-data-message>
 */

import template from '../templates-pug/for-directive/no-data-message.pug';
import defaultImg from '../../img/icon_table_data_flat.png';

noDataMessage.$inject = [];
function noDataMessage() {
    return {
        restrict: 'E',
        template: template,
        transclude: true,
        scope: {
            imageSource: '@',
            iconSource: '@',
            alt: '@',
            customTitle: '@',
            message: '@',
            learnMore: '@',
            emptyStateLink: '@',
            withFilters: '@',
            onLinkClick: '&?'
        },
        link: function(scope, element, attrs) {
            scope.defaultImg = defaultImg;

            scope.linkClick = function() {
                if (scope.onLinkClick) {
                    scope.onLinkClick();
                }
            }
        }
    };
}

export default {
    type: 'directive',
    name: 'noDataMessage',
    value: noDataMessage
};