/**
 * Created by A.Sirosh on 08.10.2015.
 * Edited by kseniya.yarosh on 13.12.2016
 * @memberof getProceeds
 * @ngdoc factory
 * @name getProceeds
 * @returns getProceedsData {Promise} data for sales report
 */

import angular from 'angular'

getProceeds.$inject = ['renderDataChart', 'promiseOperation', 'factoryProfile', 'serviceReportsError'];

function getProceeds(renderDataChart, promiseOperation, factoryProfile, serviceReportsError) {

	const successGetProceedsData = (response) => {
		let responseData = {
			tableRows: []
		};

		if (angular.isObject(response.data)) {
			if (response.data.result === "ok") {
				responseData.periodAllowed = response.data.periodAllowed;
				responseData.startDate = response.data.startDate;
				responseData.endDate = response.data.endDate;
				responseData.startDateString = response.data.startDateString;
				responseData.endDateString = response.data.endDateString;

				if (responseData.periodAllowed) {
					responseData.tableRows = response.data.tableRows;
					responseData.chartData = renderDataChart.renderDataToChart(response.data);
					responseData.totalValues = response.data.totalValues;
					responseData.pages = response.data.pages;
					responseData.outlets = response.data.fillSelectData.outlets;
					responseData.tooltipsData = response.data.tooltipsData;
				}

				return responseData;
			}
		}
	}

	const getProceedsData = (params) => {
		return promiseOperation.getPromiseHTTP(
			'POST',
			'/data/ownercab/getearningsreport',
			params,
			successGetProceedsData,
			serviceReportsError.reportsError
		)
	}

	return {
		getProceedsData: getProceedsData
	};
}

export default {
	type: 'factory',
	name: 'getProceeds',
	value: getProceeds
};
