import angular from 'angular'

setIntegrations.$inject = [
    'serviceDialog',
    'serviceToast',
    '$filter',
    '$profile',
    '$scope',
    '$timeout',
    'factoryBilling',
    'factoryProfile',
    '$state',
    'lodash',
    '$accountInfo',
    '$permitsForUser',
    'strGwConst',
    'serviceBillingCommonMethods',
    'serviceBillingDifferentPlaces',
    'serviceBillingError',
    'serviceBillingMixpanel'
];

function setIntegrations(
    serviceDialog,
    serviceToast,
    $filter,
    $profile,
    $scope,
    $timeout,
    factoryBilling,
    factoryProfile,
    $state,
    lodash,
    $accountInfo,
    $permitsForUser,
    strGwConst,
    serviceBillingCommonMethods,
    serviceBillingDifferentPlaces,
    serviceBillingError,
    serviceBillingMixpanel
) {
    $scope.profile = $profile;

    let vm = this;

    let translate = $filter('translate');
    let accountInfo = angular.copy($accountInfo);
    let errorChargebee = !accountInfo || accountInfo.message === "temporary_unavailable";
    const emptyPlan = {id:null, cycle:null, unlim:null, price:null};
    const emptyCoupon = {showInput:false, error:"", name:"", tempName:"", haveCoupon:false, code:null};
    let dataBilling = $scope.profile.billingInfo;
    const stripeGateways = strGwConst;
    let ownerId = $scope.profile.ownerId;
    let couponIntegration = angular.copy(emptyCoupon);

    vm.errorChargebee = !accountInfo || accountInfo.message === "temporary_unavailable";

    if (!errorChargebee && accountInfo.subscriptions.INTEGRATION) {
        vm.subscriptionStatus = accountInfo.subscriptions.INTEGRATION.status;
        vm.isTrial = vm.subscriptionStatus === 'NONE' || vm.subscriptionStatus === 'INTRIAL_NOSUB_NOACT';
        vm.isOldClient = vm.subscriptionStatus === 'FREE';
        vm.showBillingButton = $permitsForUser.indexOf('ACCESS_BILLING') !== -1;
        vm.trialButtonText = vm.subscriptionStatus === 'NONE' ? 'INVENTORY.START_FREE_TRIAL' : vm.subscriptionStatus === 'INTRIAL_NOSUB_NOACT' ? 'SUBSCRIBE' : '';
    }

    (!errorChargebee && accountInfo.subscriptions.INTEGRATION && accountInfo.subscriptions.INTEGRATION.plan === null) ? accountInfo.subscriptions.INTEGRATION.plan = angular.copy(emptyPlan) : "";


    const currencyValues = {
        "$": 900,
        "€": 900,
        "£": 900,
        "¥": 90000,
        "₩": 900000
    };

    vm.setIntegrations = function () {
        let currencySymbol = $scope.profile.billingInfo.billingCurrencyFormat.symbol;
        let price = currencyValues[currencySymbol];
        if (accountInfo.plans.INTEGRATION) {
            for (let i = 0, length = accountInfo.plans.INTEGRATION.length; i < length; i++) {
                if (accountInfo.plans.INTEGRATION[i].cycle === 1) {
                    price = accountInfo.plans.INTEGRATION[i].price;
                    break;
                }
            }
        }
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('APPS.START_INTEGR_TITLE'),
            content: translate('APPS.START_INTEGR_TEXT', {value: $filter('currencyFormatOnlyBilling')(price, false)}),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary',
                    text: 'APPS.GET_STARTED',
                    action: function () {
                        $scope.profile.integrationsEnabled = true;
                        factoryProfile.setProfile( $scope.profile ).then(function (data) {
                            if(data.result === 'ok') {
                                $scope.$emit('enableIntegrations');
                                $state.go('integrations.apps', {});
                            }
                        })

                    }
                }
            ]
        });
    };

    // диалоговое окно ИНТЕГРАЦИИ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки
    vm.subscribeIntegrations = function (status) {
        let msgTitle, msgContent1, msgContent2, msgContent3, msgDate;
        if (status === "NONE") {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INTEGRATION_TRIAL_FREE_TITLE');
            msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INTEGRATION_TRIAL_FREE_TEXT_1');
            msgContent2  =  null;
            msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INTEGRATION_TRIAL_FREE_TEXT_2');
            msgDate      =  null;
        }
        else if (status === "INTRIAL_NOSUB_NOACT") {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_INTEGRATION_TITLE');
            msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_INTEGRATION_TEXT_1');
            msgContent2  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_INTEGRATION_TEXT_2');
            msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_INTEGRATION_TEXT_3');
            msgDate      =  accountInfo.subscriptions.INTEGRATION.trialEnd;
        }

        let terms = {};
        terms.show = status === "NONE";
        terms.url = serviceBillingDifferentPlaces.getTermsUrl();
        terms.checked = !terms.show;

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       msgContent2,
            content3:       msgContent3,
            date:           msgDate,
            priceOne:       lodash.find(accountInfo.plans.INTEGRATION, { 'cycle': 1, 'unlim': false }),
            terms:          terms,
            buttons: [
                {
                    text:  'ON_CANCEL',
                    action: function () {
                        serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp("cancel", false, "INTEGRATION", vm.subscriptionStatus, false, null);
                    }
                },
                {
                    text: status === "NONE" ? 'START_FREE_TRIAL' : 'CONTINUE_CONTINUE',
                    class: 'md-primary',
                    action: function () {
                        accountInfo.subscriptions.INTEGRATION.plan.id = "INTEGRATION";
                        createSubscribeActivate(accountInfo.subscriptions.INTEGRATION.plan, false, null, accountInfo.paymentMethod);
                    }
                }
            ]
        });
    };
    // диалоговое окно ИНТЕГРАЦИИ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки


    // диалоговое окно ИНТЕГРАЦИИ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация
    function subscribeActivateIntegrations() {
        let createNewPlan;
        let couponCode;
        let tempCoupon      =  angular.copy(couponIntegration);
        let tempPlan        =  angular.copy(accountInfo.subscriptions.INTEGRATION.plan);
        let msgTitle        =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INTEGRATION_TITLE');
        let msgContent1     =  translate('ACCOUNT.B2_INTEGRATION_SUBSCRIBE_TEXT');
        let msgContent3     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER');
        let msgContent4     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_MONTH');
        let msgContent5     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_YEAR');
        let msgContent6     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_UNLIMITED');
        let msgSingle1      =  lodash.find(accountInfo.plans.INTEGRATION, { 'cycle': 1, 'unlim': false });
        let msgSingle12     =  lodash.find(accountInfo.plans.INTEGRATION, { 'cycle': 12, 'unlim': false });
        let msgUnlim1       =  lodash.find(accountInfo.plans.INTEGRATION, { 'cycle': 1, 'unlim': true });
        let msgUnlim12      =  lodash.find(accountInfo.plans.INTEGRATION, { 'cycle': 12, 'unlim': true });
        let msgNewPlan      =  accountInfo.subscriptions.INTEGRATION.plan;
        let msgCouponDate   =  couponIntegration;

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
            content3:       msgContent3,
            content4:       msgContent4,
            content5:       msgContent5,
            content6:       msgContent6,
            content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
            content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
            single1:        msgSingle1,
            single12:       msgSingle12,
            unlim1:         msgUnlim1,
            unlim12:        msgUnlim12,
            newPlan:        msgNewPlan,
            paymentType:    accountInfo.paymentMethod,
            inEU:           accountInfo.taxMessage,
            couponDate:     msgCouponDate,
            showFeeText:    true,
            buttons: [  {   text:  'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingActivateInAlert("cancel", emptyPlan, "INTEGRATION", accountInfo.paymentMethod);
                    cancelSubscriptionDialog(tempPlan, tempCoupon);
                }
            },
                {   text:   (accountInfo.paymentMethod === null)? 'ACCOUNT.CONTINUE' : 'SUBSCRIBE',
                    action: function () {
                        accountInfo.subscriptions.INTEGRATION.plan = lodash.find(accountInfo.plans.INTEGRATION, { 'id': accountInfo.subscriptions.INTEGRATION.plan.id });
                        createNewPlan = accountInfo.subscriptions.INTEGRATION.plan;
                        (couponIntegration.haveCoupon === true && couponIntegration.code !== null)? couponCode = couponIntegration.code : couponCode = null;

                        if (accountInfo.paymentMethod === null) {
                            let urlWithHash = window.location.href;
                            let stateParams = {
                                addSubscribe: createNewPlan,
                                typeSubscriptions: "INTEGRATION",
                                urlForReload: urlWithHash,
                                activate: true,
                                coupon: couponCode,
                                urlForSuccessReload: 'integrations.management'
                            };
                            if(dataBilling.gw === "bt")  { $state.go( 'addPayMethodBt', stateParams) }
                            if(stripeGateways.indexOf(dataBilling.gw) !== -1) { $state.go( 'addPayMethodStr', stateParams) }
                            if(dataBilling.gw === "bs" || dataBilling.gw === "wc")  {
                                serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, createNewPlan.id, couponCode, cancelSubscriptionDialog.bind(null, tempPlan, tempCoupon), null, null);
                            }
                        }
                        else {
                            showEstimatedInvoice(createNewPlan, true, couponCode, accountInfo.paymentMethod, tempPlan, tempCoupon);
                        }
                    }
                },
                {   text:           'APPLY_APPLY',
                    closeOnAction:  false,
                    action:         function () {
                        serviceBillingDifferentPlaces.addCoupon(accountInfo.subscriptions.INTEGRATION.plan, couponIntegration, couponIntegration.name);
                    }
                }]
        });
    }
    // диалоговое окно ИНТЕГРАЦИИ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация


    // проверка, разрешен ли выбор тарифного плана
    vm.checkIfPlanAllowed = function () {
        serviceBillingDifferentPlaces.checkIfPlanAllowed(accountInfo.paymentMethod, subscribeActivateIntegrations);
    };
    // проверка, разрешен ли выбор тарифного плана


    function createSubscribeActivate(plan, activate, couponCode, paymentType) {
        let data = {planId: plan.id,
            activate: activate,
            coupon: couponCode};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(res.result, plan, "INTEGRATION", vm.subscriptionStatus, activate, paymentType);
            if (res.result === "ok") {
                if (activate) {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                } else {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_TRIAL_STARTED', 'success');
                }

                $timeout(function () {
                    window.location.reload(true);
                }, 1000);
            }
            else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            }
            else {
                serviceBillingError.unKnownError();
            }
        });
    }


    // отключение диалога выбора подписки
    function cancelSubscriptionDialog(plan, coupon) {
        couponIntegration = coupon;
        accountInfo.subscriptions.INTEGRATION.plan = plan;
    }
    // отключение диалога выбора подписки


    // предпросмотр счета к оплате
    function showEstimatedInvoice(plan, activate, couponCode, paymentType, tempPlan, tempCoupon) {
        let data = {planId: plan.id,
            activate: activate,
            coupon: couponCode,
            estimate: true};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            if(res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createSubscribeActivate.bind(null, plan, activate, couponCode, paymentType), cancelEstimatedInvoice.bind(null, tempPlan, tempCoupon));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // предпросмотр счета к оплате


    // отмена предпросмотра счета к оплате
    function cancelEstimatedInvoice(tempPlan, tempCoupon) {
        cancelSubscriptionDialog(tempPlan, tempCoupon);
        subscribeActivateIntegrations();
    }
    // отмена предпросмотра счета к оплате

}

export default {
    type: 'controller',
    name: 'setIntegrations',
    value: setIntegrations
};
