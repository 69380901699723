// Represents a mechanism for add and notify listeners.

import angular from 'angular';

createClass.$inject = [];

function createClass() {
    return class Observable {
        constructor() {
            // List of observers.
            this._observers = [];
            this._latestValue = undefined;
        }

        /**
        * @name observe
        * @desc add observer function to array of listeners.
        * @params {Function} observer - the function that was called by notify method.
         * {boolean} notifyInitValue - define if observer will be notified with latest value on subscribe
        * @returns {Function} removeObserver function that remove added observer.
        */
        observe(observer, notifyInitValue) {
            this._observers.push(observer);
            if (notifyInitValue && angular.isDefined(this._latestValue)) {
                observer.apply(this, this._latestValue);
            }

            // Remove observer function
            return function() {
                let observerIndex = this._observers.indexOf(observer);
                if (observerIndex !== -1) {
                    this._observers.splice(observerIndex, 1);
                }
            };
        }

        /**
        * @name notify
        * @desc call all registered observers.
        * @param [Objects] - any objects that used as parameters for listeners functions.
        */
        notify() {
            this._latestValue = arguments;
            for (let i = 0; i < this._observers.length; i++) {
                // Call observer with all arguments.
                this._observers[i].apply(this, arguments);
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'Observable',
    value: createClass
};
