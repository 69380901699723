dialogs.$inject = ['$rootScope', 'serviceDialog', '$translate', '$state', '$q', 'serviceLang', 'stateGo', 'constantStateErrors'];
function dialogs($rootScope, serviceDialog, $translate, $state, $q, serviceLang, stateGo, constantStateErrors) {

    /**
     * @name initUnsaveAlerDialog
     * @desc Displays alert dialog to approve leaving the state without changes.
     * @param {Element} The element to be observe.
     * @param {Function} focus event callback.
     * @param {Function} blur event callback.
     * @return {Boolean}
     */
    function initUnsaveAlertDialog(isChangedFn, scope, onDiscardCb) {
        let unsubscribe = scope.$on('$stateChangeStart', (event, toState, toParam) => {
            if(isChangedFn()) {
                event.preventDefault();
                serviceDialog.add({
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: $translate.instant('UNSAVED_TITLE'),
                    content: $translate.instant('UNSAVED_LEAVE'),
                    buttons: [{
                        text: 'UNSAVED_CONTINUE',
                        action: function() { }
                    },
                        {
                            class: 'primary l-margin-left-8-i',
                            text: 'UNSAVED_DISCARD',
                            action: function() {
                                if(unsubscribe){
                                    unsubscribe();
                                }
                                if (onDiscardCb) {
                                    onDiscardCb();
                                }
                                unsubscribe = undefined;
                                $state.go(toState, toParam);
                            }
                        }
                    ]
                });
            }
            else{
                if(unsubscribe){
                    unsubscribe();
                }
                unsubscribe = undefined;
            }
        });

        scope.$on('$destroy', function(){
            if(unsubscribe){
                unsubscribe();
            }
            unsubscribe = undefined;
        });

        return unsubscribe;
    }

    function listItemDeleted() {
        return listItemDeletedRefresh('LIST_ITEM_DELETED');
    }

    function listItemDeletedEarlier() {
        return listItemDeletedRefresh('LIST_ITEM_DELETED_EARLIER');
    }

    function itemDeletedEarlier() {
        return getDialogPromiseWithOk('ITEM_DELETED_EARLIER');
    }

    function itemsDeletedEarlier() {
        const defer = $q.defer();
        const buttons = [
            {
                text: 'ON_CANCEL',
                action: function() {
                    defer.reject();
                }
            },
            {
                class: 'primary',
                text: 'ACCOUNT.B2_SERVICE_DIALOG.B2_REFRESH',
                action: function() {
                    defer.resolve();
                }
            }
        ];
        showDeletedItemDialog($translate.instant('LIST_ITEMS_DELETED_EARLIER'), buttons)
        return defer.promise;
    }

    function listItemDeletedRefresh(messageKey) {
        const defer = $q.defer();

        // Ensure translates loaded
        // On copy/paste url this dialog can be called before translates loaded
        serviceLang.onLoad()
            .then(() => {
                const message = $translate.instant(messageKey);

                const buttons = [
                    {
                        text: 'ON_CANCEL',
                        action: function() {
                            defer.reject();
                        }
                    },
                    {
                        class: 'primary',
                        text: 'ACCOUNT.B2_SERVICE_DIALOG.B2_REFRESH',
                        action: function() {
                            defer.resolve();
                        }
                    }
                ];
                showDeletedItemDialog(message, buttons)
            });

        return defer.promise;
    }

    function showDeletedItemDialog(message, buttons) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            content: message,
            buttons: buttons
        });
    }

    function goStateOrShowRefreshDialog (routePayload, id, buttonText) {
        const errors = Object.keys(constantStateErrors);
        const data = routePayload.data ?? routePayload;
        if (data.result === 'error' && errors.includes(data.message)) {
            const errorData = constantStateErrors[data.message];
            const dialogPromise = getDialogPromiseWithOk(errorData.message, buttonText);

            if ($state.current.name === errorData.redirectState) {
                dialogPromise.then(() => {
                    stateGo.refresh();
                });
            } else {
                const stateParams = errorData.redirectType === 'ITEM' ? {id: id ?? $state.params.id} : null;
                dialogPromise.finally(() => {
                    stateGo.goToState(errorData.redirectState, stateParams);
                });
            }
            return $q.reject(data);
        }
        return $q.resolve(data);
    }

    const getDialogPromiseWithOk = (message, buttonText) => {
        const defer = $q.defer();
        const buttons = [
            {
                class: "md-primary material-standard",
                text: buttonText ?? 'OK',
                action: () => {
                    defer.resolve();
                }
            }
        ];
        showDeletedItemDialog($translate.instant(message), buttons)
        return defer.promise;
    }

    const purchaseOrderObsolete = () => listItemDeletedRefresh(constantStateErrors["purchase_order_obsolete"].message);

    const purchaseOrderClosed = () => getDialogPromiseWithOk(constantStateErrors["purchase_order_closed"].message);

    const purchaseOrderApproved = () => getDialogPromiseWithOk(constantStateErrors["purchase_order_approved"].message);

    const transferOrderApproved = () => getDialogPromiseWithOk(constantStateErrors["transfer_order_approved"].message);

    const transferOrderTransferred = () => getDialogPromiseWithOk(constantStateErrors["transfer_order_transferred"].message);

    this.initUnsaveAlertDialog = initUnsaveAlertDialog;
    this.listItemDeleted = listItemDeleted;
    this.listItemDeletedEarlier = listItemDeletedEarlier;
    this.itemDeletedEarlier = itemDeletedEarlier;
    this.itemsDeletedEarlier = itemsDeletedEarlier;
    this.goStateOrShowRefreshDialog = goStateOrShowRefreshDialog;
    this.getDialogPromiseWithOk = getDialogPromiseWithOk;
    this.purchaseOrderObsolete = purchaseOrderObsolete;
    this.purchaseOrderClosed = purchaseOrderClosed;
    this.purchaseOrderApproved = purchaseOrderApproved;
    this.transferOrderApproved = transferOrderApproved;
    this.transferOrderTransferred = transferOrderTransferred;
}

export default {
    type: 'service',
    name: 'dialogs',
    value: dialogs
};
