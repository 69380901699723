/**
 * Payment types with tips
 * Created by Shestakov on 21.06.22
 */

const paymentMethodsWithTips = [
    "VANTIV",
    "SUMUP",
    "YOCO",
    "IZETTLE",
    "KICC"
]

export default {
    type: 'constant',
    name: 'paymentMethodsWithTips',
    value: paymentMethodsWithTips
}
