const timeZones = [
    {
        "text":"(UTC-12:00) International Date Line West",
        "value":"Etc/GMT+12"
    },
    {
        "text":"(UTC-11:00) Coordinated Universal Time -11",
        "value":"Etc/GMT+11"
    },
    {
        "text":"(UTC-10:00) Hawaii",
        "value":"Pacific/Honolulu"
    },
    {
        "text":"(UTC-09:00) Alaska",
        "value":"America/Anchorage"
    },
    {
        "text":"(UTC-08:00) Pacific Time (US and Canada)",
        "value":"America/Los_Angeles"
    },
    {
        "text":"(UTC-08:00) Baja California",
        "value":"America/Tijuana"
    },
    {
        "text":"(UTC-07:00) Mountain Time (US and Canada)",
        "value":"America/Denver"
    },
    {
        "text":"(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        "value":"America/Chihuahua"
    },
    {
        "text":"(UTC-07:00) Arizona",
        "value":"America/Phoenix"
    },
    {
        "text":"(UTC-06:00) Saskatchewan",
        "value":"America/Regina"
    },
    {
        "text":"(UTC-06:00) Central America",
        "value":"America/Guatemala"
    },
    {
        "text":"(UTC-06:00) Central Time (US and Canada)",
        "value":"America/Chicago"
    },
    {
        "text":"(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        "value":"America/Mexico_City"
    },
    {
        "text":"(UTC-05:00) Eastern Time (US and Canada)",
        "value":"America/New_York"
    },
    {
        "text":"(UTC-05:00) Bogota, Lima, Quito",
        "value":"America/Bogota"
    },
    {
        "text":"(UTC-05:00) Indiana (East)",
        "value":"America/Indianapolis"
    },
    {
        "text":"(UTC-04:30) Caracas",
        "value":"America/Caracas"
    },
    {
        "text":"(UTC-04:00) Atlantic Time (Canada)",
        "value":"America/Halifax"
    },
    {
        "text":"(UTC-04:00) Cuiaba",
        "value":"America/Cuiaba"
    },
    {
        "text":"(UTC-04:00) Santiago",
        "value":"America/Santiago"
    },
    {
        "text":"(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        "value":"America/La_Paz"
    },
    {
        "text":"(UTC-04:00) Asuncion",
        "value":"America/Asuncion"
    },
    {
        "text":"(UTC-03:30) Newfoundland",
        "value":"America/St_Johns"
    },
    {
        "text":"(UTC-03:00) Brasilia",
        "value":"America/Sao_Paulo"
    },
    {
        "text":"(UTC-03:00) Greenland",
        "value":"America/Godthab"
    },
    {
        "text":"(UTC-03:00) Montevideo",
        "value":"America/Montevideo"
    },
    {
        "text":"(UTC-03:00) Cayenne, Fortaleza",
        "value":"America/Cayenne"
    },
    {
        "text":"(UTC-03:00) Buenos Aires",
        "value":"America/Buenos_Aires"
    },
    {
        "text":"(UTC-02:00) Mid-Atlantic",
        "value":"Etc/GMT+2"
    },
    {
        "text":"(UTC-01:00) Azores",
        "value":"Atlantic/Azores"
    },
    {
        "text":"(UTC-01:00) Cabo Verde Is.",
        "value":"Atlantic/Cape_Verde"
    },
    {
        "text":"(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
        "value":"Europe/London"
    },
    {
        "text":"(UTC+00:00) Monrovia, Reykjavik",
        "value":"Atlantic/Reykjavik"
    },
    {
        "text":"(UTC+00:00) Casablanca",
        "value":"Africa/Casablanca"
    },
    {
        "text":"(UTC+00:00) Coordinated Universal Time",
        "value":"Etc/GMT"
    },
    {
        "text":"(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        "value":"Europe/Budapest"
    },
    {
        "text":"(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        "value":"Europe/Warsaw"
    },
    {
        "text":"(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        "value":"Europe/Paris"
    },
    {
        "text":"(UTC+01:00) West Central Africa",
        "value":"Africa/Lagos"
    },
    {
        "text":"(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "value":"Europe/Berlin"
    },
    {
        "text":"(UTC+01:00) Windhoek",
        "value":"Africa/Windhoek"
    },
    {
        "text":"(UTC+03:00) Minsk",
        "value":"Europe/Minsk"
    },
    {
        "text":"(UTC+02:00) Cairo",
        "value":"Africa/Cairo"
    },
    {
        "text":"(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "value":"Europe/Kiev"
    },
    {
        "text":"(UTC+02:00) Athens, Bucharest",
        "value":"Europe/Bucharest"
    },
    {
        "text":"(UTC+02:00) Jerusalem",
        "value":"Asia/Jerusalem"
    },
    {
        "text":"(UTC+02:00) Amman",
        "value":"Asia/Amman"
    },
    {
        "text":"(UTC+02:00) Beirut",
        "value":"Asia/Beirut"
    },
    {
        "text":"(UTC+02:00) Harare, Pretoria",
        "value":"Africa/Johannesburg"
    },
    {
        "text":"(UTC+02:00) Damascus",
        "value":"Asia/Damascus"
    },
    {
        "text":"(UTC+02:00) Istanbul",
        "value":"Europe/Istanbul"
    },
    {
        "text":"(UTC+03:00) Kuwait, Riyadh",
        "value":"Asia/Riyadh"
    },
    {
        "text":"(UTC+03:00) Baghdad",
        "value":"Asia/Baghdad"
    },
    {
        "text":"(UTC+03:00) Nairobi",
        "value":"Africa/Nairobi"
    },
    {
        "text":"(UTC+02:00) Kaliningrad",
        "value":"Europe/Kaliningrad"
    },
    {
        "text":"(UTC+03:30) Tehran",
        "value":"Asia/Tehran"
    },
    {
        "text":"(UTC+03:00) Moscow, St. Petersburg, Volgograd",
        "value":"Europe/Moscow"
    },
    {
        "text":"(UTC+04:00) Abu Dhabi, Muscat",
        "value":"Asia/Dubai"
    },
    {
        "text":"(UTC+04:00) Baku",
        "value":"Asia/Baku"
    },
    {
        "text":"(UTC+04:00) Yerevan",
        "value":"Asia/Yerevan"
    },
    {
        "text":"(UTC+04:00) Tbilisi",
        "value":"Asia/Tbilisi"
    },
    {
        "text":"(UTC+04:00) Port Louis",
        "value":"Indian/Mauritius"
    },
    {
        "text":"(UTC+04:30) Kabul",
        "value":"Asia/Kabul"
    },
    {
        "text":"(UTC+05:00) Tashkent",
        "value":"Asia/Tashkent"
    },
    {
        "text":"(UTC+05:00) Islamabad, Karachi",
        "value":"Asia/Karachi"
    },
    {
        "text":"(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "value":"Asia/Calcutta"
    },
    {
        "text":"(UTC+05:30) Sri Jayawardenepura",
        "value":"Asia/Colombo"
    },
    {
        "text":"(UTC+05:45) Kathmandu",
        "value":"Asia/Katmandu"
    },
    {
        "text":"(UTC+05:00) Ekaterinburg",
        "value":"Asia/Yekaterinburg"
    },
    {
        "text":"(UTC+06:00) Astana",
        "value":"Asia/Almaty"
    },
    {
        "text":"(UTC+06:00) Dhaka",
        "value":"Asia/Dhaka"
    },
    {
        "text":"(UTC+06:30) Yangon (Rangoon)",
        "value":"Asia/Rangoon"
    },
    {
        "text":"(UTC+06:00) Novosibirsk",
        "value":"Asia/Novosibirsk"
    },
    {
        "text":"(UTC+07:00) Bangkok, Hanoi, Jakarta",
        "value":"Asia/Bangkok"
    },
    {
        "text":"(UTC+07:00) Krasnoyarsk",
        "value":"Asia/Krasnoyarsk"
    },
    {
        "text":"(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "value":"Asia/Shanghai"
    },
    {
        "text":"(UTC+08:00) Kuala Lumpur, Singapore",
        "value":"Asia/Singapore"
    },
    {
        "text":"(UTC+08:00) Taipei",
        "value":"Asia/Taipei"
    },
    {
        "text":"(UTC+08:00) Perth",
        "value":"Australia/Perth"
    },
    {
        "text":"(UTC+08:00) Ulaanbaatar",
        "value":"Asia/Ulaanbaatar"
    },
    {
        "text":"(UTC+08:00) Irkutsk",
        "value":"Asia/Irkutsk"
    },
    {
        "text":"(UTC+09:00) Seoul",
        "value":"Asia/Seoul"
    },
    {
        "text":"(UTC+09:00) Osaka, Sapporo, Tokyo",
        "value":"Asia/Tokyo"
    },
    {
        "text":"(UTC+09:30) Darwin",
        "value":"Australia/Darwin"
    },
    {
        "text":"(UTC+09:30) Adelaide",
        "value":"Australia/Adelaide"
    },
    {
        "text":"(UTC+09:00) Yakutsk",
        "value":"Asia/Yakutsk"
    },
    {
        "text":"(UTC+10:00) Canberra, Melbourne, Sydney",
        "value":"Australia/Sydney"
    },
    {
        "text":"(UTC+10:00) Brisbane",
        "value":"Australia/Brisbane"
    },
    {
        "text":"(UTC+10:00) Hobart",
        "value":"Australia/Hobart"
    },
    {
        "text":"(UTC+10:00) Guam, Port Moresby",
        "value":"Pacific/Port_Moresby"
    },
    {
        "text":"(UTC+10:00) Vladivostok",
        "value":"Asia/Vladivostok"
    },
    {
        "text":"(UTC+11:00) Solomon Is., New Caledonia",
        "value":"Pacific/Guadalcanal"
    },
    {
        "text":"(UTC+10:00) Magadan",
        "value":"Asia/Magadan"
    },
    {
        "text":"(UTC+12:00) Fiji",
        "value":"Pacific/Fiji"
    },
    {
        "text":"(UTC+12:00) Auckland, Wellington",
        "value":"Pacific/Auckland"
    },
    {
        "text":"(UTC+12:00) Coordinated Universal Time +12",
        "value":"Etc/GMT-12"
    },
    {
        "text":"(UTC+13:00) Nuku'alofa",
        "value":"Pacific/Tongatapu"
    },
    {
        "text":"(UTC+13:00) Samoa",
        "value":"Pacific/Samoa"
    }
]

export default {
    type: 'constant',
    name: 'timeZones',
    value: timeZones
}