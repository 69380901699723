/**
 * Created by Kiskenbassker on 23.10.17.
 */

import angular from 'angular';

predefinedController.$inject = ['$predefinedTickets',
    'getTickets',
    'serviceToast',
    '$shortOutlets',
    '$stateParams',
    '$scope',
    '$state',
    'serviceDialog',
    '$filter',
    '$timeout',
    'promiseOperation',
    '$animate',
    'areAllDifferentService',
    '$translate',
    'lvMixpanel',
    '$mdUtil'
];

function predefinedController(  $predefinedTickets,
                                getTickets,
                                serviceToast,
                                $shortOutlets,
                                $stateParams,
                                $scope,
                                $state,
                                serviceDialog,
                                $filter,
                                $timeout,
                                promiseOperation,
                                $animate,
                                areAllDifferentService,
                                $translate,
                                lvMixpanel,
                                $mdUtil) {


    var vm = this;
    var beforeEdit              = {};
    var toSend                  = {};
    var translate               = $filter('translate');
    var savedMessage            = 'SETTINGS.TICKETS_EDITED';
    vm.emptyName                = false;
    vm.defineOutletName         = defineOutletName;

    // $scope.interData            = $predefinedTickets;
    $scope.predefinedTickets    = angular.copy($predefinedTickets);

    vm.shortOutlets             = angular.copy($shortOutlets);
    var predefinedTickets       = angular.copy($scope.predefinedTickets);

    $scope.selectOutlet         = { id:     vm.shortOutlets[0].id,
        name:   vm.shortOutlets[0].name};

    $scope.selectOutlet.id      = ($stateParams.outletId) ? $stateParams.outletId : vm.shortOutlets[0].id;
    $scope.selectOutlet.name    = ($stateParams.outletName) ? $stateParams.outletName : vm.shortOutlets[0].name;

    $scope.selectOutletId       = $scope.selectOutlet.id;

    if (!$scope.selectOutletId){ $scope.selectOutletId = vm.shortOutlets[0].id; }

    var theElement = document.getElementsByClassName('trashbox');
    // $animate.enabled(false, theElement);

    vm.predefinedTickets        = predefinedTickets.tickets;
    vm.usePredefinedTickets     = predefinedTickets.usePredefinedTickets;
    vm.progress = false;
    var offStateChangeStart;
    var toParam                 = toParam;
    var fromParam               = fromParam;


    // beforeEdit.outletId = $scope.selectOutletId;
    beforeEdit.usePredefinedTickets = vm.usePredefinedTickets;
    beforeEdit.added            = [];
    beforeEdit.edited           = [];
    beforeEdit.deleted          = [];
    beforeEdit.order            = [];


    var added   = [];
    var deleted = [];
    var edited  = [];
    var orderChanged = [];

    vm.ticketsNameGetter = function() {
        return vm.predefinedTickets.map(ticket => ticket.name);
    }

    vm.validateTicketNames = function() {
        $scope.ticketsForm.validateControls();
        $scope.ticketsForm.setInvalidTouchedControls();
    }

    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval.id != newval.id) {
            $scope.selectOutletId = newval.id;
            $scope.selectOutletName = defineOutletName($scope.selectOutletId);

            $state.go('settings.predefined', {outletId: newval.id, outletName: newval.name}).then(function() {
                $scope.selectOutletId = newval.id;
                $scope.selectOutletName = defineOutletName($scope.selectOutletId);

            });
        }
    }, true);

    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop
    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true
    };
    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop

    function defineOutletName(outletId) {
        vm.shortOutlets.forEach(function(val){
            if(val.id === outletId){
                $scope.selectOutletName = $scope.selectOutlet.name = val.name;
            }
        });

    }

    $scope.addTicket = function() {

        if($scope.ticketsForm.$invalid) {
            $scope.ticketsForm.focusFirstInvalidControl();
            return;
        }

        if(vm.predefinedTickets.length === 200){

            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('SETTINGS.PREDEFINED_TICKETS_UNABLE_TO_ADD_TITLE'),
                content: translate('SETTINGS.PREDEFINED_TICKETS_UNABLE_TO_ADD_TEXT'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else {

            let option = {
                name: ''
            };

            vm.predefinedTickets.push(option);
            added.push(option);
        }
    };

    function existanseCheck (index) {
        if(vm.predefinedTickets[index].id !== undefined) {
            deleted.push(vm.predefinedTickets[index].id);
        } else {
            for(let a = 0; a < added.length; a++){
                if(vm.predefinedTickets[index].name === added[a].name) {
                    added.splice(a, 1);
                    return;
                    a--;
                }
            }
        }
    }

    $scope.delTicket = function (index) {
        existanseCheck (index);
        vm.predefinedTickets.splice(index, 1);

        $mdUtil.nextTick(() => {
            vm.validateTicketNames();
        });
    };

    offStateChangeStart = $scope.$on( '$stateChangeStart', function ( event, toState, toParam, fromState, fromParam ) {
        // toSend.outletId = $scope.selectOutletId;
        toSend.usePredefinedTickets = vm.usePredefinedTickets;
        toSend.added = added;
        toSend.edited = edited;
        toSend.deleted = deleted;
        toSend.order = orderChanged;
        toParam = toParam;
        fromParam = fromParam;

        pushEdit();

        if (!angular.equals(beforeEdit, toSend)) {
            event.preventDefault();
            promiseOperation
                .getPromiseWithQ({
                    toState: toState,
                    toParam: toParam
                })
                .then(unSaveAlert);
        }


        function unSaveAlert() {
            if (fromParam.outletId === undefined && toParam.outletId === vm.shortOutlets[0].id) {
                return;
            } else {
                serviceDialog.add({
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: translate('UNSAVED_TITLE'),
                    content: translate('UNSAVED_LEAVE'),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {
                                $scope.selectOutlet.id = ($stateParams.outletId) ? $stateParams.outletId : vm.shortOutlets[0].id;
                                defineOutletName($scope.selectOutlet.id);
                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go(toState, toParam);
                            }
                        }
                    ]
                });
            }
        }
    });

    $scope.$on('$destroy', function(){
        offStateChangeStart();
    });

    function isFormValid() {

        if($scope.ticketsForm.$invalid) {
            $scope.ticketsForm.focusFirstInvalidControl();
            return false;
        }

        if(!vm.usePredefinedTickets) {
            for (var i = 0; i < vm.predefinedTickets.length; i++) {
                if (!vm.predefinedTickets[i].name) {
                    existanseCheck (i);
                    vm.predefinedTickets.splice(i, 1);
                    i--;
                }
            }
        }
        return true;
    }


    function pushEdit() {

        function doble() {
            for(var e = 0; e < edited.length; e++) {
                if(vm.predefinedTickets[l].id === edited[e].id){
                    return false;
                }
            }
            return true;
        }

        //сравниваем текущий массив с изначальным

        for (var i = 0; i < $scope.predefinedTickets.tickets.length; i++) {

            for (var l =0; l < vm.predefinedTickets.length; l++){
                // при условии, что элемент не только что созданный
                if(vm.predefinedTickets[l] && vm.predefinedTickets[l].id){

                    if((vm.predefinedTickets[l].name !== $scope.predefinedTickets.tickets[i].name) && (vm.predefinedTickets[l].id === $scope.predefinedTickets.tickets[i].id)){
                        // чтобы повторяющиеся элементы не добавлялись в массив на редактирование, после повторного нажатия на кнопку сохранить
                        if(edited.length > 0) {
                            if(doble()){
                                edited.push(vm.predefinedTickets[l]);
                            }
                        } else {
                            edited.push(vm.predefinedTickets[l]);
                        }
                    }
                }
            }
        }
    }

    $scope.onSave = function() {

        if(!vm.usePredefinedTickets) {
            if(isFormValid()) {
                setData();
            } else {
                vm.usePredefinedTickets = true;
            }
        } else {
            if (!isFormValid()) {
                return;
            }

            setData();
        }

        function setData() {

            for(var k = 0; k < added.length; k++) {
                if (added[k].name === '' || added[k].name === undefined) {
                    added.splice(k, 1);
                    k--;
                }
            }

            for(var i = 0; i < vm.predefinedTickets.length; i++){
                if(vm.predefinedTickets[i].order !== i+1) {
                    vm.predefinedTickets[i].order = i+1;
                    orderChanged.push(vm.predefinedTickets[i]);
                }
            }

            toSend.outletId             = $scope.selectOutletId;
            toSend.usePredefinedTickets = vm.usePredefinedTickets;
            toSend.added                = added;
            toSend.edited               = edited;
            toSend.deleted              = deleted;
            toSend.order                = orderChanged;
            pushEdit();

            if (!added.length && !edited.length && !deleted.length && !orderChanged.length && beforeEdit.usePredefinedTickets === vm.usePredefinedTickets) {
                serviceToast.show(savedMessage, 'success');
                // Delete outletId property to prevent warning dialog on state change without changes
                delete toSend.outletId;
                return;
            }
            vm.progress = true;
            getTickets.setPredefinedTickets(toSend)
                .then(data => {
                    if (data.result === 'ok') {
                        offStateChangeStart();
                        serviceToast.show(savedMessage, 'success');

                        if ($predefinedTickets.usePredefinedTickets === false && toSend.usePredefinedTickets === true) {
                            lvMixpanel.track('Edit setting', {"Property": "Settings/Predefined tickets turned on"});
                        }
                        if ($predefinedTickets.usePredefinedTickets === true && toSend.usePredefinedTickets === false) {
                            lvMixpanel.track('Edit setting', {"Property": "Settings/Predefined tickets turned off"});
                        }

                        $state.go($state.current.name, {}, {reload: true});
                    }
                })
                .finally(() => {
                    vm.progress = false;
                });

        }
    };

    $scope.onCancel = function(){
        offStateChangeStart();
        $state.go( $state.current.name, {}, {reload: true} );
    };
}

export default {
    type: 'controller',
    name: 'predefinedController',
    value: predefinedController
};
