/**
 * New constant with options for a more convenient permissions structure
 * Created by Shestakov by 04/30/20
 * Edited Ivan Shche by 04/20/23
 */

const permitsSettings = {
    pos: [
        /**
         * 0: Право доступа к приложению Loyverse POS
         */
        {
            name: "ACCESS_LPOS",
            title: "",
            value: false,
            disabled: false
        },

        /**
         * 1: Accept payments
         */
        {
            name: "ACCESS_ACCEPT_PAYMENTS",
            title: "PERMITS.ACCEPT_PAYMENTS",
            value: false,
            disabled: false
        },

        /**
         *  2: Apply discounts with restricted access
         */
        {
            name: "ACCESS_DISCOUNT",
            title: "PERMITS.ACCESS_DISCOUNT",
            value: false,
            disabled: false
        },

        /**
         * 3: Change taxes in a sale
         */
        {
            name: "ACCESS_LPOS_CHANGE_TAXES_DURING_SALE",
            title: "PERMITS.TAX_DURING_SALE",
            value: false,
            disabled: false
        },

        /**
         * 4: Manage all open tickets
         */
        {
            name: "ACCESS_NOT_MY_OPENED_RECEIPTS",
            title: "PERMITS.OPENED_RECEIPTS",
            value: false,
            disabled: false
        },

        /**
         * 5: Void saved items in open tickets
         */
        {
            name: "ACCESS_DELETE_OPEN_RECEIPT",
            title: "PERMITS.ACCESS_DELETE_OPEN_RECEIPT",
            value: false,
            disabled: false
        },

        /**
         * 6: Open cash drawer without making a sale
         */
        {
            name: "ACCESS_OPEN_CASH_DRAWER",
            title: "PERMITS.OPEN_CASH_DRAWER",
            value: false,
            disabled: false
        },

        /**
         * 7: View all receipts
         */
        {
            name: "ACCESS_VIEW_RECEIPTS",
            title: "PERMITS.VIEW_RECEIPT",
            value: false,
            disabled: false
        },

        /**
         * 8: Perform refunds
         */
        {
            name: "ACCESS_REFUND",
            title: "PERMITS.RETURN_GOODS",
            value: false,
            disabled: false
        },

        /**
         * 9: Reprint and resend receipts
         */
        {
            name: "ACCESS_REPRINT_RECEIPTS",
            title: "PERMITS.REPRINT_RECEIPTS",
            value: false,
            disabled: false
        },

        /**
         * 10: View shift report
         */
        {
            name: "ACCESS_VIEW_CURRENT_SHIFT",
            title: "PERMITS.ACCESS_VIEW_CURRENT_SHIFT",
            value: false,
            disabled: false
        },

        /**
         * 11: Manage items
         */
        {
            name: "ACCESS_EDIT_WARE",
            title: "PERMITS.EDIT_GOODS",
            value: false,
            disabled: false
        },

        /**
         * 12: View cost of items
         */
        {
            name: "ACCESS_VIEW_COST_POS",
            title: "PERMITS.VIEW_COST",
            value: false,
            disabled: false
        },

        /**
         * 13: Change settings
         */
        {
            name: "ACCESS_CONNECT_PRINTERS",
            title: "PERMITS.SETTINGS_PERMITS",
            value: false,
            disabled: false
        },

        /**
         * 14: Access to livechat support
         */
        {
            name: "ACCESS_LPOS_SUPPORT",
            title: "PERMITS.INTERCOM_PERMITS",
            value: false,
            disabled: false
        }
    ],
    backOffice: [
        /**
         * 0: Access back office
         */
        {
            name: "ACCESS_BACK_OFFICE",
            title: "",
            value: false,
            disabled: false
        },

        /**
         * 1: View sales reports
         */
        {
            name: "ACCESS_REPORTS",
            title: "PERMITS.REPORTS_PERMITS",
            value: false,
            disabled: false
        },

        /**
         * 2: Cancel receipts
         */
        {
            name: "ACCESS_CANCEL_RECEIPTS",
            title: "PERMITS.CANCEL_RECEIPTS",
            value: false,
            disabled: false
        },

        /**
         * 3: Manage items
         */
        {
            name: "ACCESS_WARES",
            title: "PERMITS.MANAGE_ITEMS",
            value: false,
            disabled: false
        },

        /**
         * 4: Manage inventory
         */
        {
            name: "ACCESS_INVENTORY",
            title: "PERMITS.MANAGE_INVENTORY",
            value: false,
            disabled: false
        },

        /**
         * 5: View cost of items
         */
        {
            name: "ACCESS_VIEW_COST",
            title: "PERMITS.VIEW_COST",
            value: false,
            disabled: false
        },

        /**
         * 6: Manage employees
         */
        {
            name: "ACCESS_MERCHANTS",
            title: "PERMITS.EDIT_EMPLOYEE",
            value: false,
            disabled: false
        },

        /**
         * 7: Manage customers
         */
        {
            name: "ACCESS_CLIENTS",
            title: "PERMITS.EDIT_CLIENTS",
            value: false,
            disabled: false
        },

        /**
         * 8: Edit general settings
         */
        {
            name: "ACCESS_EDIT_PROFILE",
            title: "PERMITS.EDIT_GENERAL_SETTINGS",
            value: false,
            disabled: false
        },

        /**
         * 9: Manage billing (Billing page in settings)
         */
        {
            name: "ACCESS_BILLING",
            title: "PERMITS.EDIT_ACCOUNT",
            value: false,
            disabled: false
        },

        /**
         * 10: Manage payment types
         */
        {
            name: "ACCESS_EDIT_PAYMENT_TYPE",
            title: "PERMITS.ACCESS_EDIT_PAYMENT_TYPE",
            value: false,
            disabled: false
        },

        /**
         * 11: Manage loyalty program
         */
        {
            name: "ACCESS_EDIT_LOYALTY",
            title: "PERMITS.EDIT_LOYALTY",
            value: false,
            disabled: false
        },

        /**
         * 12: Manage taxes
         */
        {
            name: "ACCESS_TAXES",
            title: "PERMITS.EDIT_TAX",
            value: false,
            disabled: false
        },

        /**
         * 13: Manage kitchen printers
         */
        {
            name: "ACCESS_KITCHEN_PRINTER",
            title: "KITCHEN.CONFIGURE_KITCHEN_PRINTERS",
            value: false,
            disabled: false
        },

        /**
         * 14: Manage dining options
         */
        {
            name: "ACCESS_DINING_OPTIONS",
            title: "DINING.CONFIGURE_DINING_OPTIONS",
            value: false,
            disabled: false
        },

        /**
         * 15: Manage POS devices
         */
        {
            name: "ACCESS_CASH_REGISTERS",
            title: "PERMITS.EDIT_CESHBOX",
            value: false,
            disabled: false
        },

        /**
         * 16: Access to live chat support (back office)
         */
        {
            name: "ACCESS_BACK_OFFICE_SUPPORT",
            title: "PERMITS.INTERCOM_PERMITS",
            value: false,
            disabled: false
        },

        /**
         * 17: Edit stores
         */
        // {
        //     name: "ACCESS_OUTLETS",
        //     value: false,
        //     disabled: false
        // }
    ]
};

export default {
    type: 'constant',
    name: 'permitsSettings',
    value: permitsSettings
};
