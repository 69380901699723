/**
 * Created by kseniya.yarosh on 09.19.17
 *
 * @memberof lvStartEndTimeInputs
 * @ngdoc directive
 * @name app.directive:lvStartEndTimeInputs
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Start and End Time Inputs (input elements for managing time)
 *
 * <div lv-start-end-time-inputs></div>
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/start-end-time-inputs.pug';

lvStartEndTimeInputs.$inject = [
    '$filter',
    '$timeout',
    '$state',
    'retrieveLangCode',
    'dateCustomMethods'
];

// TODO: Oleksii: controller ???

function lvStartEndTimeInputs($filter, $timeout, $state, retrieveLangCode, dateCustomMethods) {
    return {
        restrict: 'E',
        scope: true,
        controller: lvStartEndTimeInputs,
        controllerAs: 'lvStartEndTimeInputs',
        link: linkFn,
        template: template
    };

    function linkFn($scope, elem, attrs) {

        var translate = $filter("translate"),
            browserLocale,
            localeTimeString,
            startTimeInput,
            endTimeInput,
            startTimeSuffix,
            endTimeSuffix,
            startTimeInputHours = document.getElementById('startTimeInputHours'),
            startTimeInputMinutes = document.getElementById('startTimeInputMinutes');
            $scope.isMobile = $scope.$parent.isMobile;


        browserLocale = window.navigator.languages ? window.navigator.languages[0] : window.navigator.userLanguage ? window.navigator.userLanguage : window.navigator.language;
        localeTimeString = new Date().toLocaleTimeString(browserLocale);
        // timeFormat = (localeTimeString.toString().search("AM") !== -1 || localeTimeString.toString().search("PM") !== -1) ? '12h' : '24h';



        $scope.clockInFormattedTime = $scope.$parent.clockInFormattedTime;
        $scope.clockOutFormattedTime = $scope.$parent.clockOutFormattedTime;
        $scope.clockInTimeForMobile = $scope.$parent.clockInTimeForMobile;
        $scope.clockOutTimeForMobile = $scope.$parent.clockOutTimeForMobile;
        $scope.changeClockInTimeOnMobile = changeClockInTimeOnMobile;
        $scope.changeClockOutTimeOnMobile = changeClockOutTimeOnMobile;

        Object.defineProperty($scope, "clockOutValid", {
            get() {
                return !$scope.$parent.errorTime;
            },
            set(val) {
                $scope.$parent.errorTime = !val;
            }
        });

        // $scope.is12HourFormat = (localeTimeString.toString().search("AM") !== -1 || localeTimeString.toString().search("PM") !== -1);
        $scope.is12HourFormat = $scope.$parent.is12HourFormat;
        $scope.timeFormatSuffixes = ['AM', 'PM'];

        if($scope.is12HourFormat === true){
            $scope.timeValid = "time12H";
        } else {
            $scope.timeValid = "time24H";
        }

        if ($scope.is12HourFormat === true) {
            // $scope.$parent.timeFormatSuffixClockIn = $scope.timeFormatSuffixClockIn = ($scope.clockInFormattedTime !== null && $scope.is12HourFormat === true && localeTimeString.toString().search("AM") !== -1) ? 'AM' : ($scope.clockInFormattedTime !== null && $scope.is12HourFormat === true && localeTimeString.toString().search("PM") !== -1) ? 'PM' : 'AM';
            //
            // $scope.$parent.timeFormatSuffixClockOut = $scope.timeFormatSuffixClockOut = ($scope.clockOutFormattedTime !== null && $scope.is12HourFormat === true && localeTimeString.toString().search("AM") !== -1) ? 'AM' : ($scope.clockOutFormattedTime !== null && $scope.is12HourFormat === true && localeTimeString.toString().search("PM") !== -1) ? 'PM' : 'AM';
            $scope.$parent.timeFormatSuffixClockIn = $scope.timeFormatSuffixClockIn = retrieveLangCode.retrieveDayPart($scope.$parent.startDate);

            $scope.$parent.timeFormatSuffixClockOut = $scope.timeFormatSuffixClockOut = retrieveLangCode.retrieveDayPart($scope.$parent.endDate);

        } else {
            $scope.$parent.timeFormatSuffixClockIn = $scope.timeFormatSuffixClockIn = null;

            $scope.$parent.timeFormatSuffixClockOut = $scope.timeFormatSuffixClockOut = null;
        }

        $scope.select = select;



        $scope.clockInTime = $scope.$parent.clockInTime;
        $scope.clockOutTime = $scope.$parent.clockOutTime;
        $scope.isMobile = $scope.$parent.isMobile;

        function changeClockInTimeOnMobile(val){
            if(val !== undefined){
                // console.log(val);
                $scope.clockInTimeForMobile = $scope.$parent.clockInTimeForMobile = val;
                startTimeInput = document.getElementById('startTimeMobile');
                if(angular.element(startTimeInput.parentNode).hasClass('md-input-invalid')) {
                    angular.element(startTimeInput.parentNode).removeClass('md-input-invalid');
                }
            }
        }

        function changeClockOutTimeOnMobile(val){
            if(val !== undefined) {
                // console.log(val);
                $scope.clockOutTimeForMobile = $scope.$parent.clockOutTimeForMobile = val;
                endTimeInput = document.getElementById('endTimeMobile');
                if(angular.element(endTimeInput.parentNode).hasClass('md-input-invalid')) {
                    angular.element(endTimeInput.parentNode).removeClass('md-input-invalid');
                }
            }
        }


        $timeout(function () {

            if ($scope.isMobile === false) {
                startTimeInput = document.getElementById('startTimeInput');
                endTimeInput = document.getElementById('endTimeInput');
                startTimeSuffix = document.getElementById('startTimeSuffix');
                endTimeSuffix = document.getElementById('endTimeSuffix');

                // console.log(startTimeSuffix);
                // console.log(endTimeSuffix);

                startTimeInput.addEventListener('blur', function (e) {
                    $scope.$parent.clockInFormattedTime = $scope.clockInFormattedTime = e.target.value;
                    if(e.target.value &&  angular.element(startTimeInput.parentNode).hasClass('md-input-invalid')) {
                        angular.element(startTimeInput.parentNode).removeClass('md-input-invalid');
                    }
                    // $scope.$parent.clockInTimeMilliseconds = $scope.clockInTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockInFormattedTime, $scope.timeFormatSuffixClockIn);
                });

                endTimeInput.addEventListener('blur', function (e) {
                    $scope.$parent.clockOutFormattedTime = $scope.clockOutFormattedTime = e.target.value;
                    if(e.target.value && angular.element(endTimeInput.parentNode).hasClass('md-input-invalid')) {
                        angular.element(endTimeInput.parentNode).removeClass('md-input-invalid');
                    }
                    // $scope.$parent.clockOutTimeMilliseconds = $scope.clockOutTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockOutFormattedTime, $scope.timeFormatSuffixClockOut);
                });


                startTimeInput.addEventListener('keyup', function(e) {
                    if (e.target.value==''){
                        $scope.$parent.errorTime=false;
                        $scope.$parent.$apply();
                    }

                });

                endTimeInput.addEventListener('keyup', function(e) {
                    if (e.target.value==''){
                        $scope.$parent.errorTime=false;
                        $scope.$parent.$apply();
                    }

                });


                // startTimeSuffix.addEventListener('blur', function(){
                //     $scope.$parent.clockInTimeMilliseconds = $scope.clockInTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockInFormattedTime, $scope.timeFormatSuffixClockIn);
                //     console.log($scope.clockInTimeMilliseconds);
                // });
                // endTimeSuffix.addEventListener('blur', function(){
                //     $scope.$parent.clockOutTimeMilliseconds = $scope.clockOutTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockOutFormattedTime, $scope.timeFormatSuffixClockOut);
                // });
            }
        }, 1)


        $scope.$watch('timeFormatSuffixClockIn', function (newVal, oldVal) {
            // console.log(newVal);
            if (newVal !== oldVal) {
                $scope.$parent.timeFormatSuffixClockIn = $scope.timeFormatSuffixClockIn = newVal;
                $scope.$parent.clockInTimeMilliseconds = $scope.clockInTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockInFormattedTime, $scope.timeFormatSuffixClockIn);
                // console.log($scope.timeFormatSuffixClockIn);
                // console.log($scope.clockInTimeMilliseconds);
            }

        })

        $scope.$watch('timeFormatSuffixClockOut', function (newVal, oldVal) {
            if (newVal !== oldVal) {
                $scope.$parent.timeFormatSuffixClockOut = $scope.timeFormatSuffixClockOut = newVal;
                $scope.$parent.clockOutTimeMilliseconds = $scope.clockOutTimeMilliseconds = dateCustomMethods.convertTimeStringToMilliseconds($scope.clockOutFormattedTime, $scope.timeFormatSuffixClockOut);
                // console.log($scope.timeFormatSuffixClockOut);
                // console.log($scope.clockOutTimeMilliseconds);
            }
        })


        function select(event, suffix) {
            var targetedElementId = event.currentTarget.id || event.target.id;
            if (targetedElementId === startTimeSuffix.id) {
                $scope.$parent.timeFormatSuffixClockIn = $scope.timeFormatSuffixClockIn = suffix;
                // console.log($scope.timeFormatSuffixClockIn);
            } else if (targetedElementId === endTimeSuffix.id) {
                $scope.$parent.timeFormatSuffixClockOut = $scope.timeFormatSuffixClockOut = suffix;
                // console.log($scope.timeFormatSuffixClockOut);
            }
        }


    }
}

export default {
    type: 'directive',
    name: 'lvStartEndTimeInputs',
    value: lvStartEndTimeInputs
};