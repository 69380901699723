/**
 * Created by  Alex on 31.05.17.
 */

import angular from 'angular';

accountController.$inject = ['$scope',
                            '$rootScope',
                            '$state',
                            '$stateParams',
                            '$profile',
                            '$accountInfo',
                            '$currencyOptionsShow',
                            '$permits',
                            'lodash',
                            '$timeout',
                            '$window',
                            '$filter',
                            'serviceBillingMixpanel',
                            'serviceBillingError',
                            'serviceBillingDifferentPlaces',
                            'serviceToast',
                            'strGwConst',
                            'factoryBilling',
                            'serviceStrings',
                            '$environment',
                            'factoryProfile',
                            'permissions'];

function accountController($scope,
                           $rootScope,
                           $state,
                           $stateParams,
                           $profile,
                           $accountInfo,
                           $currencyOptionsShow,
                           $permits,
                           lodash,
                           $timeout,
                           $window,
                           $filter,
                           serviceBillingMixpanel,
                           serviceBillingError,
                           serviceBillingDifferentPlaces,
                           serviceToast,
                           strGwConst,
                           factoryBilling,
                           serviceStrings,
                           $environment,
                           factoryProfile,
                           permissions) {

    // блок инициализации данных
    $scope.reloadToUnblock          = $stateParams.reloadToUnblock;
    $scope.permits                  = $permits;

    $scope.currencyOptionsShow      = $currencyOptionsShow;
    $scope.userHavePermitForUser    = lodash.indexOf($scope.permits, "ACCESS_MERCHANTS");
    $scope.editProfile              = $rootScope.permits.editProfile;
    $scope.emptyPlan                = {id:null, cycle:null, unlim:null, price:null};

    $scope.utcOffsetSec             = moment().utcOffset()*60000;
    $scope.now                      = new Date().getTime();
    $scope.oneRow                   = false;
    $scope.twoLineF                 = false;
    $scope.twoLineT                 = false;

    $scope.disablePaymentMethodBtn = false;
    $scope.dayDeclension = {};

    let stripeGateways = strGwConst;
    let translate = $filter('translate');

    updateAccountInfo($accountInfo);
    updateProfileInfo($profile);

    permissions.accountUpdate.observe(onAccountInfoChange)
    permissions.profileUpdate.observe(updateProfileInfo)

    function onAccountInfoChange() {
        factoryBilling.getAccountInfo()
            .then(accountInfo => {
                updateAccountInfo(accountInfo);
            });
    }

    function updateAccountInfo(accountInfo) {
        $scope.errorChargebee = accountInfo === undefined || accountInfo.message === "temporary_unavailable";
        $scope.accountInfo = angular.copy(accountInfo);

        let subscriptionTypes = Object.keys($scope.accountInfo.subscriptions);
        $scope.subscriptions = serviceBillingDifferentPlaces.getSubscriptionConstants(subscriptionTypes);

        if (!$scope.errorChargebee) {
            $scope.subscriptions.forEach(function (type) {
                // если есть хотя бы одна подписка, показываем текст о том, что может взиматься налог
                $scope.accountInfo.showInfVat = $scope.accountInfo.subscriptions[type].plan != null;

                // устанавливаем склонения дней для заблокированного аккаунта
                if($scope.accountInfo.subscriptions[type].status === "TRIALEND_SUB_NOACT") {
                    $scope.accountInfo.subscriptions[type].trialEndDay = parseInt(($scope.now - $scope.accountInfo.subscriptions[type].trialEnd + $scope.utcOffsetSec) / 86400000);
                    if($scope.accountInfo.subscriptions[type].trialEndDay > 1) {
                        $scope.dayDeclension[type] = translate('DECLENSIONS.DAYS.' + serviceStrings.getDeclensions($scope.accountInfo.subscriptions[type].trialEndDay));
                    }
                } else {
                    $scope.accountInfo.subscriptions[type].trialEndDay = null;
                }

                if($scope.accountInfo.subscriptions[type].status === "PAST_DUE" || $scope.accountInfo.subscriptions[type].status === "PAUSED") {
                    $scope.accountInfo.subscriptions[type].pastDueSinceDay = parseInt( ($scope.now - $scope.accountInfo.subscriptions[type].pastDueSince + $scope.utcOffsetSec) / 86400000);
                    if($scope.accountInfo.subscriptions[type].pastDueSinceDay > 1) {
                        $scope.dayDeclension[type] = translate('DECLENSIONS.DAYS.' + serviceStrings.getDeclensions($scope.accountInfo.subscriptions[type].pastDueSinceDay));
                    }
                } else {
                    $scope.accountInfo.subscriptions[type].pastDueSinceDay = null;
                }

                if($scope.accountInfo.subscriptions[type].status === "UNRESOLVED") {
                    $scope.accountInfo.subscriptions[type].endsOnDay = parseInt( ($scope.now - $scope.accountInfo.subscriptions[type].endsOn + $scope.utcOffsetSec) / 86400000);
                    if($scope.accountInfo.subscriptions[type].endsOnDay > 1) {
                        $scope.dayDeclension[type] = translate('DECLENSIONS.DAYS.' + serviceStrings.getDeclensions($scope.accountInfo.subscriptions[type].endsOnDay));
                    }
                } else {
                    $scope.accountInfo.subscriptions[type].endsOnDay = null;
                }

                // если отсутствует значение endsOn, устанавливаем его равным null
                if (!$scope.accountInfo.subscriptions[type].hasOwnProperty('endsOn')) {
                    $scope.accountInfo.subscriptions[type].endsOn = null;
                }

                // if there's no plan, set empty object (if subscription has plan, but is non-renewing, also do so)
                const isNonRenewing = $scope.accountInfo.subscriptions[type].status === 'ACTIVE' && $scope.accountInfo.subscriptions[type].endsOn !== null;
                if ($scope.accountInfo.subscriptions[type].plan === null || isNonRenewing) {
                    $scope.accountInfo.subscriptions[type].plan = angular.copy($scope.emptyPlan)
                }

                type === "EMPSTORE" ? $scope.accountInfo.subscriptions[type].textConst = "EMPLOYEE" : $scope.accountInfo.subscriptions[type].textConst = type;
            })
        }
        // блок инициализации данных
    }

    function updateProfileInfo(profile) {
        $scope.profileBillInfo = profile.billingInfo;
        $scope.ownerId = profile.ownerId;
        $scope.hidePaymentMethodBtn = ($scope.profileBillInfo.gw === "bs" || $scope.profileBillInfo.gw === "wc") && $scope.profileBillInfo.paymentMethod === null;
        $scope.isAccountOnHold = $scope.profileBillInfo.billingStatus === "HOLD";
        if(!$scope.hidePaymentMethodBtn && !$scope.isAccountOnHold) {
            blockClassFromPageWidth();
            $window.addEventListener('resize', blockClassFromPageWidth);
        }
    }

    // переход на добавление данных карты
    $scope.goAddPaymentMethod = function () {
        if(stripeGateways.indexOf($scope.profileBillInfo.gw) !== -1) {
            $scope.disablePaymentMethodBtn = true;
            return $state.go('addPayMethodStr', $stateParams, {});
        }
        if($scope.profileBillInfo.gw === "bt")  {
            $scope.disablePaymentMethodBtn = true;
            return $state.go('addPayMethodBt', $stateParams, {});
        }
        if($scope.profileBillInfo.gw === "bs" || $scope.profileBillInfo.gw === "wc")  {
            return getChargebeeHostedPageForUpdate();
        }
    };
    // переход на добавление данных карты



    //проверяем данные stateParams для проверки необходимости перезагрузки страницы
    if ($scope.reloadToUnblock) {
        serviceBillingError.reloadRoute();
    }
    //проверяем данные stateParams для проверки необходимости перезагрузки страницы


    // переход на редактирование реквизитов для счетов
    $scope.goEditBillingDetails = function () {
        return $state.go('editBillingDetails', $stateParams, {});
    };
    // переход на редактирование реквизитов для счетов


    // делаем ивент для МИКСПАНЕЛИ
    $scope.viewInvoice = function () {
        serviceBillingMixpanel.setMixpanelBillingInvoiceInBillinMain();
    };
    // делаем ивент для МИКСПАНЕЛИ


    function blockClassFromPageWidth() {
        $timeout(function () {
            if(document.getElementById('accountBills')) {
                var accountBillsWidth = document.getElementById('accountBills').offsetWidth / 2 - 30,
                    accountBillsWidth2 = window.innerWidth - 40,
                    btnMainWidth = document.getElementById('btn_main').offsetWidth,
                    btnEditDetailsWidth = document.getElementById('btn_edit_details').offsetWidth;

                (btnMainWidth > accountBillsWidth || btnEditDetailsWidth > accountBillsWidth)
                    ? $scope.oneRow = true
                    : $scope.oneRow = false;
                (btnMainWidth > accountBillsWidth2)
                    ? $scope.twoLineF = true
                    : $scope.twoLineF = false;
                (btnEditDetailsWidth > accountBillsWidth2)
                    ? $scope.twoLineT = true
                    : $scope.twoLineT = false;
            }
        }, 0)
    }


    // функция получает с сервера информацию о hosted-странице Chargebee, и вызывает ее, либо выдает ошибку
    function getChargebeeHostedPageForUpdate() {
        factoryBilling.getChargebeeHostedPageForUpdate({}).then(function (res) {
            if(res.result === "ok") {
                updateChargebeeCard(res);
            } else if(res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        })
    }


    // функция вызывает hosted-страницу Chargebee для редактирования
    function updateChargebeeCard(instanceObj) {

        var siteName = $environment.chargebeeSite;
        var previousStep = "";
        var currentStep = "";

        Chargebee.init({
            site: siteName
        });
        var instance = Chargebee.getInstance();
        instance.openCheckout({
            hostedPage: function () {
                return new Promise(function (resolve) {
                    resolve(instanceObj);
                })
            },
            step: function(step) {
                previousStep = currentStep;
                currentStep = step;
                // костыльное решение для того, чтобы заставить фронт отправлять запрос на сервер именно после нажатия кнопки Update.
                // основано на наблюдении, что ТОЛЬКО при нажатии на кнопку Update функция step отрабатывает два раза и названия предыдущего и нынешнего стейтов hosted page одинаковые.
                if(previousStep === 'portal_view_payment_method' && currentStep === 'portal_view_payment_method') {
                    factoryBilling.completeChargebeeCheckout({}).then(function (res) {
                        if(res.result === "ok") {
                            serviceToast.show('ACCOUNT.CARD_EDITED', 'success');
                            instance.closeAll();
                            serviceBillingError.reloadRoute();
                        }
                    });
                }
            }
        });
    }
}

export default {
    type: 'controller',
    name: 'accountController',
    value: accountController
};
