/**
 * Created by mars on 8/21/15.
 */


/**
 *
 category.food=Продукты
 category.eatingOut=Кафе, бары, рестораны
 category.entertainment=Развлечения
 category.healthAndBeauty=Здоровье и красота
 category.clothesAndShoes=Одежда и обувь
 category.electronicsAndAppliances=Техника
 category.services=Услуги
 category.auto=Авто
 category.tourism=Туризм
 category.goodsForKids = Товары для детей
 category.miscellaneous=Другое


 1, food
 2, eatingOut
 3, entertainment
 4, healthAndBeauty
 5, clothesAndShoes
 6, electronicsAndAppliances
 7, services
 8, auto
 9, tourism
 11, goodsForK

 */
const constantGroupTypeOwner = [
	{
		_id: 1,
		index: 0,
		text: "Продукты",
		key: "food",
		logo: null
	},
	{
		_id: 2,
		index: 1,
		text: "Кафе, бары, рестораны",
		key: 'eatingOut',
		logo: null
	},

	{
		_id: 3,
		index: 2,
		text: "Развлечения",
		key: 'entertainment',
		logo: null
	}
	,{
		_id: 4,
		index: 3,
		text: "Здоровье и красота",
		key: 'healthAndBeauty',
		logo: null
	},
	{
		_id: 5,
		index: 4,
		text: "Одежда и обувь",
		key: 'clothesAndShoes',
		logo: null
	},
	{
		_id: 6,
		index: 5,
		text: "Техника",
		key: 'electronicsAndAppliances',
		logo: null
	},
	{
		_id:7,
		index: 6,
		text: "Услуги",
		key: 'services',
		logo: null
	},
	{
		_id: 8,
		index: 7,
		text: "Авто",
		key: 'auto',
		logo: null
	},
	{
		_id:9,
		index: 8,
		text: "Туризм",
		key: 'tourism',
		logo: null
	},
	{
		_id: 11,
		index: 9,
		text: "Товары для детей",
		key: 'goodsForKids',
		logo: null
	},
	{
		_id: 999999,
		index: 10,
		text: "Другое",
		key: 'miscellaneous',
		logo: null
	}
];

export default {
	type: 'constant',
	name: 'constantGroupTypeOwner',
	value: constantGroupTypeOwner
};