factoryBilling.$inject = [
    'factoryErrorAlert',
    '$q',
    '$http',
    'strGwConst',
    'serviceBillingError',
    '$state',
    '$stateParams'];

function factoryBilling(factoryErrorAlert, $q, $http, strGwConst,
                        serviceBillingError, $state, $stateParams) {

    function getToken() {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/gettokenforcardform', {}).then(function (d) {
                if(d.data && d.data.result === "error" && d.data.message === "attempt_limit_exceeded") {
                    serviceBillingError.knownError(d.data.message, function () { $state.go('settings.account', $stateParams, {reload: true});});
                } else {
                    resolve(d.data);
                }
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }

    function sendPaymentNonce(nonce1, data) {
        let hash = "";
        const isStripe = strGwConst.includes(data.typeBillingPayment);

        if (data.typeBillingPayment === "bt") {hash = nonce1.nonce;}
        if (isStripe) {hash = nonce1;}
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/billingattachcard', {
                nonce:      hash,
                country:    data.country,
                vat:        data.vat === "" ? null : data.vat,
                state:      data.state === "" ? null : data.state,
                zip:        data.zip === "" ? null : data.zip,
                voidTid:    data.voidTid ? data.voidTid : null
            }).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                resolve(err.data);
                reject(err);
            });
        });
    }


    // Запрос на получение всех данных для стейта БИЛЛИНГ
    function getAccountInfo(nonce) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getbillinginfo', {
                nonce: nonce
            }).then(function (d) {
                // resolve(newDate);
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на получение всех данных для стейта БИЛЛИНГ


    // Запрос на получение УРЕЗАННЫХ данных для стейта БИЛЛИНГ
    function getMinAccountInfo(nonce) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getminibillinginfo', {
                nonce: nonce
            }).then(function (d) {
                // resolve(newDate);
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на получение УРЕЗАННЫХ данных для стейта БИЛЛИНГ



    // Запрос на создание подписки на услугу или активация тарифного плана
    function setCreateSubscribeActivate(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/subscribe', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                // resolve({result: "ok"});
                factoryErrorAlert.onError(err, true);
                reject(err);
            });
        });
    }
    // Запрос на создание подписки на услугу или активация тарифного плана





    // Запрос для отписки от от любой подписки, указанной нами в запросе
    function deleteSubscription(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/unsubscribe', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос для отписки от от любой подписки, указанной нами в запросе





    // Запрос на изменения тарифа
    function changeNewPlanSubscription(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/updatebillingcycle', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err, true);
                reject(err);
            });
        });
    }
    // Запрос на изменения тарифа


    // Запрос на принудительное списание денег
    function retrySubscriptionTransaction(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/billingretry', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на принудительное списание денег


    // Запрос на проверку купона
    function testCoupon(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/verifycoupon', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на проверку купона


    // Запрос на получение реквизитов
    function getBillingDetails() {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getbillingdetails', {}).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на получение реквизитов


    // Запрос на изменение реквизитов
    function updateBillingDetails(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/updatebillingdetails', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на изменение реквизитов


    // Запрос на получение hosted-страницы для ввода карты Chargebee
    function getChargebeeHostedPage(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/gethostedpage', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на получение hosted-страницы для ввода карты Chargebee


    // Запрос на получение hosted-страницы для редактирования карты Chargebee
    function getChargebeeHostedPageForUpdate(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/hostedpageupdatecard', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на получение hosted-страницы для редактирования карты Chargebee


    // Запрос на завершение подписки через форму Chargebee
    function completeChargebeeCheckout(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/completecheckout', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на завершение подписки через форму Chargebee


    // Запрос на верификацию ReCaptcha
    function verifyReCaptcha(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/verifycaptcha', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на верификацию ReCaptcha


    // Запрос на 3DS-верификацию Страйпа
    function verifyStripeCardThreeDS(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/stripe3ds', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на 3DS-верификацию Страйпа

    // Запрос на возобновление подписки на паузе
    function resumeSubscription(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/billingresume', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос на возобновление подписки на паузе

    // Запрос проверяет, разрешен ли выбор тарифного плана
    function planSelectionAllowed(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/planselectionallowed', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Запрос проверяет, разрешен ли выбор тарифного плана

    // Request to verify VAT number
    function verifyVat(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/verifyVat', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Request to verify VAT number

    // Request to renew subscription
    function renewSubscription(data) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/renewsubscription', data).then(function (d) {
                resolve(d.data);
            }, function (err) {
                factoryErrorAlert.onError(err);
                reject(err);
            });
        });
    }
    // Request to renew subscription

    function isSalesHistoryAllowed() {
        return getMinAccountInfo()
            .then(accountInfo => {
                const shSubscriptiob = accountInfo.subscriptions["SALESHISTORY"]
                if (shSubscriptiob) {
                    const blockedStatuses = ["NONE", "TRIALEND_SUB_NOACT", "TRIALEND_NOSUB_NOACT"];
                    return !blockedStatuses.includes(shSubscriptiob.status);
                } else {
                    return true;
                }
            })
    }

    return {
        getToken: getToken,
        sendPaymentNonce: sendPaymentNonce,
        getAccountInfo: getAccountInfo,
        getMinAccountInfo: getMinAccountInfo,
        changeNewPlanSubscription: changeNewPlanSubscription,
        retrySubscriptionTransaction: retrySubscriptionTransaction,
        getBillingDetails: getBillingDetails,
        updateBillingDetails: updateBillingDetails,
        setCreateSubscribeActivate: setCreateSubscribeActivate,
        deleteSubscription: deleteSubscription,
        testCoupon: testCoupon,
        getChargebeeHostedPage: getChargebeeHostedPage,
        getChargebeeHostedPageForUpdate: getChargebeeHostedPageForUpdate,
        completeChargebeeCheckout: completeChargebeeCheckout,
        verifyReCaptcha: verifyReCaptcha,
        verifyStripeCardThreeDS: verifyStripeCardThreeDS,
        resumeSubscription: resumeSubscription,
        planSelectionAllowed: planSelectionAllowed,
        verifyVat: verifyVat,
        renewSubscription: renewSubscription,
        isSalesHistoryAllowed: isSalesHistoryAllowed
    };

}

export default {
    type: 'factory',
    name: 'factoryBilling',
    value: factoryBilling
};
