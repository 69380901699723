/**
 * Created by Kiskenbassker on 14.03.18
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/select-store.pug';

selectStore.$inject = [];

function selectStore() {
    return {
        restrict: 'E',
        link: linkFn,
        template: template,
        scope: {
            fieldName: "@",
            formName: "@",
            array: "=",
            actualOutletId: "="
        }
    };

    function linkFn(scope, elem, attr, ctrl) {
        scope.selectModel = {};
        scope.selectActualOutlet = selectActualOutlet;
        function selectActualOutlet(selectModel){
            scope.actualOutlet = angular.copy(selectModel);
            scope.actualOutletId = scope.actualOutlet.id;
        }
    }
}

export default {
    type: 'directive',
    name: 'selectStore',
    value: selectStore
};