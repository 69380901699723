/**
 * Created yarosh on 31.05.17.
 */

formatGoodsChart.$inject = ['retrieveLangCode'];
function formatGoodsChart(retrieveLangCode) {
    /**
     * Filter format dates according to local standards and by a chosen divider
     * @param val
     * @returns {String}
     */

    return function (date, divider, stateParamsFrom, stateParamsTo) {
        // console.log(date);
        var startDate = new Date(stateParamsFrom).getFullYear();
        var endDate = new Date(stateParamsTo).getFullYear();

        var format;

        switch(divider){
            case 'hour':
                format = 'short';
                break;
            case 'day':
                if(startDate === endDate){
                    format = 'dailyForCharts';
                } else {
                    // format = 'day';
                    format = 'dailyForCharts';
                }
                break;
            case 'week':
                if(startDate === endDate){
                    format = 'weeklyForCharts';
                } else {
                    // format = 'tableLabel';
                    format = 'weeklyForCharts';
                }
                break;
            case 'month':
                if(startDate === endDate){
                    format = 'monthlyForCharts';
                } else {
                    // format = 'monthYear';
                    format = 'monthlyForCharts';
                }
                break;
            case 'quarter':
                if(startDate === endDate){
                    format = 'quarterlyForCharts';
                } else {
                    // format = 'quarter';
                    format = 'quarterlyForCharts';
                }
                break;
            case 'year':
                format = 'year';
                break;
        }
        // console.log(divider);
        // console.log(format);


        if(divider === "hour") {
            return retrieveLangCode.retrieveTimeFormat(date, format);
        } else {
            return retrieveLangCode.retrieveBrowserDateFormat(date, format);
        }
    }

}

export default {
    type: 'filter',
    name: 'formatGoodsChart',
    value: formatGoodsChart
};