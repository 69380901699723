import angular from 'angular';

    tableViewDirective.$inject = [];

    function tableViewDirective() {
        return {
            restrict: 'E',
            template: `
                <div class='lv-table-view__header'>
                    <div ng-repeat="col in ctrl.currentColumns"
                         class='lv-table-view__header-cell'
                         ng-style="{'min-width': col.options.minWidth || col.options.width, 'flex-grow': col.options.width ? 0 : 1}"
                         lv-get-size-method='col.getSize' lv-set-size-method='col.setSize'>
                         <span lv-get-size-method="col.getHeaderSize">{{col.title}}</span>
                    </div>
                </div>
                <div class='lv-table-view__items'>
                    <md-virtual-repeat-container class="lv-table-view__items-container" 
                                                 lv-virtual-repeat-size-method="ctrl.setHeight"
                                                 md-virtual-repeat-container-api="ctrl.container">
                        <div md-virtual-repeat='item in ctrl.currentItems'
                             lv-virtual-repeat-emitter="ctrl.throttleEmit">
                           <lv-table-view-row lv-item="item"
                                              lv-item-index="$index"
                                              lv-columns="ctrl.currentColumns">
                            </lv-table-view-row>
                        </div>
                    </md-virtual-repeat-container>
                </div>
                <div ng-if="ctrl.footerVisible"
                     class='lv-table-view__footer'>
                    <div ng-repeat="col in ctrl.currentColumns"
                         ng-style="{'min-width': col.options.minWidth || col.options.width, 'flex-grow': col.options.width ? 0 : 1}"
                         lv-set-size-method='col.setFooterSize'>
                        <lv-table-view-footer-cell lv-item="col.options.footer"></lv-table-view-footer-cell>                         
                    </div>
                </div>`,
            controller: TableViewController,
            controllerAs: 'ctrl',
            bindToController: true,
            scope: {
                columns: '<lvColumns',
                items: '<lvItems',
                visibleItemsNumber: '<?lvVisibleItemsNumber'
            }
        }
    }

TableViewController.$inject = ['$scope', '$element', '$timeout', '$mdUtil'];
function TableViewController($scope, $element, $timeout, $mdUtil) {

    this.throttleEmit = _.throttle(emit, 100);
    function emit() {
        $scope.$broadcast('virtualRepeatUpdateScope', {});
    }

    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onChanges = $onChanges;
    this.$onDestroy = $onDestroy;
    this.addColumn = addColumn;
    this.addRow = addRow;
    this.addResizeListener = addResizeListener;
    this.updateColumnWidth = updateColumnWidth

    this.currentColumns = [];
    this.transcludedColumns = [];
    this.currentItems = [];
    this.maxVisibleItemsNumber = 6;
    this.footerVisible = false;

    let self = this;
    let container = null;
    let onResizeDebounced = null;
    let updateHeightDebounced = _.debounce(angular.bind(self, updateHeight), 200);
    let resizeListeners = [];
    let rowSizers = [];
    let itemsContainer = null;
    let overflowed = false;

    const itemHeight = 65;

    function $onInit() {
        $element.addClass('lv-table-view');

        itemsContainer = $element.find('.lv-table-view__items');

        onResizeDebounced = _.debounce(angular.bind(self, onResize));
        window.addEventListener('resize', onResizeDebounced);
    }

    function $onChanges(changes) {
        if (changes.columns) {
            updateColumns();
        }
        if (changes.items) {
            self.currentItems = self.items.slice();
            if (!overflowed && changes.items.currentValue.length > changes.items.previousValue.length) {
                container.addClass('is-updating');
            }
            updateItems();
        }
    }

    function $postLink() {
        container = $element.find('.lv-table-view__items-container');
        if (self.visibleItemsNumber) {
            self.maxVisibleItemsNumber = self.visibleItemsNumber;
        }
        updateItems();
        updateHeight();
        self.container.updateSize = updateSizeDecorator(self.container.updateSize);
    }

    function $onDestroy() {
        window.removeEventListener('resize', onResizeDebounced);
    }

    function updateSizeDecorator(func) {
        return function(skipUpdate){
            if (!skipUpdate){
                updateHeightDebounced();
            }
            func.call(this);
        }
    }

    function updateHeight() {
        const maxHeight = self.maxVisibleItemsNumber * itemHeight;
        const allItemsStandardHeight = self.currentItems.length * itemHeight;
        const currentHeight = rowSizers.reduce((prev, current) => prev + current(), 0);

        let height;
        if (maxHeight < allItemsStandardHeight){
            height = maxHeight;
        }
        else if (allItemsStandardHeight > currentHeight) {
            height = allItemsStandardHeight;
        }
        else {
            height = currentHeight;
        }
        overflowed = Math.max(allItemsStandardHeight, currentHeight) > maxHeight;
        self.setHeight(height);
        container.removeClass('is-updating');
        $mdUtil.nextTick(onResize);
    }

    function addResizeListener(listener) {
        resizeListeners.push(listener);

        return function() {
            const indexToRemove = resizeListeners.indexOf(listener);
            if (indexToRemove >= 0) {
                resizeListeners.splice(indexToRemove, 1);
            }
        }
    }

    function addColumn(column) {
        self.transcludedColumns.push(column);
        updateColumns();
    }

    function addRow(heightGetter) {
        rowSizers.push(heightGetter);
        updateHeightDebounced();
        return function() {
            const indexToRemove = rowSizers.indexOf(heightGetter);
            if (indexToRemove >= 0) {
                rowSizers.splice(indexToRemove, 1);
            }
        }
    }

    function updateColumnWidth(index, width) {
        const col = self.currentColumns[index];
        const currentWidth = col.getSize().width;

        if (width > currentWidth || (width < currentWidth && width > col.widthMinLimit)) {
            col.setSize({ 'width': width });
            if (col.setFooterSize) {
                col.setFooterSize({ 'width': width });
            }
            onResizeDebounced();
        }
    }

    function updateColumns() {
        self.footerVisible = false;
        self.currentColumns = self.columns.concat(self.transcludedColumns);
        self.currentColumns.forEach(col => {
            if (col.options.footer) {
                self.footerVisible = true;
            }
        });
        $mdUtil.nextTick(() => {
            self.currentColumns.forEach(col => {
                const headerTitleWidth = col.getHeaderSize().width;
                const initWidth = col.options.width ? col.options.width : 0;
                const colWidth = Math.max(initWidth, headerTitleWidth) + 'px';
                col.setSize({ 'width': colWidth });
                if (col.setFooterSize) {
                    col.setFooterSize({ 'width': colWidth });
                }
                col.widthMinLimit = col.getSize().width;

                onResize();
            });
        });

    }

    function updateItems() {
        $mdUtil.nextTick(function() {
            updateHeight();
        });
    }

    function onResize() {
        resizeListeners.forEach(listener => {
            listener();
        });
        let widths = self.columns.map(column => column.getSize().width);
        const totalWidth = widths.reduce((a, b) => a + b, 0);
        itemsContainer.css('width', totalWidth + 'px');
    }
}

export default {
    type: 'directive',
    name: 'lvTableView',
    value: tableViewDirective
};
