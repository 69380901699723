factoryItemsReportTable.$inject = [];

function factoryItemsReportTable() {
  return {
    columns: (fields, permissions) =>
      [
        {
          name: "name",
          title: "INVENTORY.ITEMS_TABLE.ITEM",
          sort: { numeric: false, ascending: true },
          format: { currency: false, percent: false },
          show: (fields) => fields.includes("name"),
          cell: (row) => row.name,
        },
        {
          name: "sku",
          title: "ARTICLE",
          sort: { numeric: false, ascending: false },
          format: { currency: false, percent: false },
          show: (fields) => fields.includes("sku"),
          cell: (row) => row.article,
        },
        {
          name: "category",
          title: "CATEGORY",
          sort: { numeric: false, ascending: true },
          format: { currency: false, percent: false },
          show: (fields) => fields.includes("category"),
          cell: (row) => row.category ?? "No category",
        },
        {
          name: "saleQuantity",
          title: "REPORT_GOODS.GOODS_SOLD",
          sort: { numeric: true, ascending: false },
          format: { currency: false, percent: false },
          show: (fields) => fields.includes("saleQuantity"),
          cell: (row) => row.quantity,
        },
        {
          name: "saleSum",
          title: "REPORT_SALES.GROSS_SALES",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields) => fields.includes("saleSum"),
          cell: (row) => row.profit,
        },
        {
          name: "refundQuantity",
          title: "REPORT_CATEGOR.ITEMS_REFUNDED",
          sort: { numeric: true, ascending: false },
          format: { currency: false, percent: false },
          show: (fields) => fields.includes("refundQuantity"),
          cell: (row) => row.returns,
        },
        {
          name: "refundSum",
          title: "REPORT_SALES.REFUNDS",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields) => fields.includes("refundSum"),
          cell: (row) => row.returnsSum,
        },
        {
          name: "discountSum",
          title: "REPORT_SALES.PAYMENT_OF_BONUSES",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields) => fields.includes("discountSum"),
          cell: (row) => row.totalDiscount,
        },
        {
          name: "profitSum",
          title: "REPORT_SALES.NET_SALES",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields) => fields.includes("profitSum"),
          cell: (row) => row.earningSum,
        },
        {
          name: "primeCost",
          title: "REPORT_GOODS.PRIME_COST_ITEM",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields, permissions) =>
            permissions.cost && fields.includes("primeCost"),
          cell: (row) => row.primeCostAmount,
        },
        {
          name: "grossProfit",
          title: "REPORT_GOODS.GROSS_PROFIT",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields, permissions) =>
            permissions.cost && fields.includes("grossProfit"),
          cell: (row) => row.grossProfit,
        },
        {
          name: "margin",
          title: "MARGIN_PERCENT",
          sort: { numeric: true, ascending: false },
          format: { currency: false, percent: true },
          show: (fields, permissions) =>
            permissions.cost && fields.includes("margin"),
          cell: (row) => row.margin,
        },
        {
          name: "taxSum",
          title: "REPORT_GOODS.TAX_SUM",
          sort: { numeric: true, ascending: false },
          format: { currency: true, percent: false },
          show: (fields) => fields.includes("taxSum"),
          cell: (row) => row.totalTax,
        },
      ].reduce(
        (acc, x) => (x.show(fields, permissions) ? acc.concat(x) : acc),
        [],
      ),
  };
}

export default {
  type: "factory",
  name: "factoryItemsReportTable",
  value: factoryItemsReportTable,
};
