/**
 * Created by olehkss on 02.07.16.
 * Edited by kseniya.yarosh on 14.12.16
 *
 * @ngdoc controller
 * @name app.controller: reportPayTypesController
 * @description
 * Controls payment types report view
 * @requires {Object} $payTypes the data of payment types report resolved by ui-router
 */

reportPayTypesController.$inject = [
    '$payTypes',
    '$scope',
    '$currencyOptionsShow',
    '$countOptionsShow',
    'constantGreenExport',
    '$profile',
    '$permitsManager',
    '$miniBillingInfo',
    'serviceReports',
    '$reportCopyAllowed'
];

function reportPayTypesController(
    $payTypes,
    $scope,
    $currencyOptionsShow,
    $countOptionsShow,
    constantGreenExport,
    $profile,
    $permitsManager,
    $miniBillingInfo,
    serviceReports,
    $reportCopyAllowed
) {
    let vm = this;
    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.reportError = $payTypes.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && $payTypes.unableToLoadData;

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;
    vm.downloadHrefBase = '/data/ownercab/paymentstypesreportdownload';

    if (!vm.reportError) {
        vm.periodAllowed = $payTypes.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($payTypes["startDateString"] && $payTypes["endDateString"]) {
            let fromParse = $payTypes["startDateString"].split('-');
            let toParse = $payTypes["endDateString"].split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $payTypes["startDate"];
            vm.endDate = $payTypes["endDate"];
        }
        $scope.outlets = $payTypes.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        if (vm.periodAllowed) {
            vm.paymentTypes = $payTypes.items;
            vm.total = $payTypes.total;

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            /*** формат отображения колличества ***/
            $scope.countOptionsShow = $countOptionsShow;
        }
    }
}

export default {
    type: 'controller',
    name: 'reportPayTypesController',
    value: reportPayTypesController
};
