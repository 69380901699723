wHookDetail.$inject = [
    '$hookInfo',
    '$scope',
    '$state',
    '$stateParams',
    'factoryWebHooks',
    'serviceToast',
    'serviceDialog',
    '$filter'
];

function wHookDetail(
    $hookInfo,
    $scope,
    $state,
    $stateParams,
    factoryWebHooks,
    serviceToast,
    serviceDialog,
    $filter
) {

    var vm = this;
    var translate = $filter('translate');

    vm.hookInfo = $hookInfo;

    $scope.onBack = function () {
        $state.go('webhookslist');
    };

    vm.onEdit = function () {
        $state.go('editwhook', { id: $stateParams.id});
    };

    vm.sendTestHook = function () {
        factoryWebHooks.testPostRequest( {id: $stateParams.id}).then(function (res) {
            if(res.result === "ok") {
                serviceToast.show('WEBHOOKS.TEST_HOOK_SUCCESS', 'success');
            } else {
                serviceToast.show('WEBHOOKS.TEST_HOOK_ERROR', 'error');
            }
        })
    };

    vm.onDelete = function () {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title:   translate('WEBHOOKS.DEL_HOOK_TITLE'),
            content:  translate('WEBHOOKS.DEL_HOOK_TEXT'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary',
                    text: 'ON_DEL',
                    action: function () {
                        factoryWebHooks.deleteWebHooks({deleted:[$stateParams.id]}).then(function (res) {
                            if (res.result === "ok") {
                                serviceToast.show('WEBHOOKS.DELETED', 'success');
                                $state.go('webhookslist');
                            }
                        })
                    }
                }
            ]
        });
    }


}

export default {
    type: 'controller',
    name: 'wHookDetail',
    value: wHookDetail
};