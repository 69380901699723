/**
 * Created by A.Sirosh on 17.12.2015.
 * @memberof showModalComment
 * @ngdoc directive
 * @name showModalComment
 * @param $stateParams {service} UI Router servise
 * @param $state {service} UI Router servise
 */

import angular from 'angular';

showModalComment.$inject = ["$filter",
    "$http",
    "$state",
    "$stateParams",
    "$compile",
    "serviceToast",
    "saveComment",
    "$templateCache",
    "serviceMenu",
    'lvMixpanel'];

function showModalComment(
    $filter, $http, $state, $stateParams, $compile, serviceToast, saveComment, $templateCache, serviceMenu, lvMixpanel) {
    return {
        restrict: 'A',
        scope: {
            showModalComment: '=',
            edit: "="
        },
        //templateUrl: 'dashboard-page/templates/for-directive/comment-dialog.html',


        /**
         * @param scope
         * @param element
         * @param attrs
         */

        link: function (scope, element, attrs) {

            $(element).on("click", function () {
                lvMixpanel.track('Back office page visit', {"Property": "Feedbacks/Reply on customer feedback"});

                $(this).removeClass('unread');
                $(this).find('.unread-block').remove();

                // serviceMenu.decreaseUnread();

                scope.saveComment = saveComment;

                scope.client = {};

                scope.client.message = '';

                scope.reviewData = scope.showModalComment;
                // console.log("scope.reviewData = ", scope.reviewData);
                scope.onClose = function (event) {

                    if (event.target.getAttribute('id') === 'content') {
                        $('#myModal').modal('hide');
                    } else {
                        return false;
                    }
                };

                scope.dataForViewed = {
                    recallId: scope.reviewData.id,
                    viewed: true
                };

                if (scope.reviewData.viewed === false) {
                    scope.saveComment.setViewed(scope.dataForViewed).then(function (res) {
                    });
                }


                scope.noClickSave = true;
                scope.sendData = function () {
                    if (!scope.edit) {
                        serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
                        return;
                    }
                    if (scope.client.message == '') {
                        return false;
                    }
                    if (scope.noClickSave && scope.reviewData.id && scope.client.message.length > 0) {
                        scope.noClickSave = false;
                        $('#myModal').modal('hide');

                        scope.sendParams = {
                            recallId: scope.reviewData.id,
                            response: scope.client.message
                        };

                        scope.saveComment.setCommentData(scope.sendParams).then(function (res) {
                            // console.log("scope.noClickSave in setCommentData = ", scope.noClickSave);
                            if (angular.isObject(res) && res.result == 'ok') {
                                scope.noClickSave = true;
                            }
                            $state.go($state.current.name, $stateParams, {
                                reload: true
                            });
                        });
                    };

                };



                if (scope.reviewData.client === null) {
                    return false;
                } else {
                    // TODO: Oleksii: test get template. Shestakov: is this directive used at all?
                    $http.get('dashboard-page/templates/for-directive/comment-dialog.html', {cache: $templateCache}).then(
                        function (response) {
                            var templ = $compile(response.data)(scope);

                            var a = document.getElementById('myModal');
                            if (a !== null) {
                                document.getElementById('myModal').parentNode.removeChild(a);
                            }

                            $('body').append(templ);

                            $("#myModal").modal();

                        }, function (error) {
                            console.log('no template');
                        }
                    );
                }

            });
        }
    };
}

export default {
    type: 'directive',
    name: 'showModalComment',
    value: showModalComment
};
