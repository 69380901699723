/**
 * Created by sirosh on 05.02.16.
 * @memberof createEditEmployeeCtrl
 * @ngdoc controller
 * @name createEditEmployeeCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter servise
 * @requires $state {service} UI Router servise
 * @requires $stateParams {service} UI Router servise
 * @requires constTaxOptions {service} Tax types
 * @requires getEmployees {service} Get employees list / operation with employees
 * @requires $employee {service} Get employees list / operation with employees from ui-router resolve
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 * @requires convertPin {service} Work with pin code
 */

import angular from "angular";
import factoryEmployee from "../../factory/factory-employee";

createEditEmployeeCtrl.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$stateParams",
  "$filter",
  "$translate",
  "$employee",
  "getEmployees",
  "serviceDialog",
  "serviceToast",
  "convertPin",
  "factoryProfile",
  "randomFromTo",
  "$shortOutlets",
  "lodash",
  "$timeout",
  "$profile",
  "$employees",
  "factoryBilling",
  "serviceBillingMixpanel",
  "serviceBillingError",
  "serviceBillingCommonMethods",
  "serviceBillingDifferentPlaces",
  "strGwConst",
  "lvMixpanel",
  "dialogs",
  "factoryPopover",
  "$window",
  "vcRecaptchaService",
  "serviceReCaptcha"
];

function createEditEmployeeCtrl(
  $scope,
  $rootScope,
  $state,
  $stateParams,
  $filter,
  $translate,
  $employee,
  getEmployees,
  serviceDialog,
  serviceToast,
  convertPin,
  factoryProfile,
  randomFromTo,
  $shortOutlets,
  lodash,
  $timeout,
  $profile,
  $employees,
  factoryBilling,
  serviceBillingMixpanel,
  serviceBillingError,
  serviceBillingCommonMethods,
  serviceBillingDifferentPlaces,
  strGwConst,
  lvMixpanel,
  dialogs,
  factoryPopover,
  $window,
  vcRecaptchaService,
  serviceReCaptcha
) {
  $scope.profile = angular.copy($profile);

  // блок инициализации данных

  let allRoles;
  let subscriptionType;
  let checkBillingActions = true;

  let stripeGateways = strGwConst;
  let ownerId = $scope.profile.ownerId;
  let initParams;
  $scope.isPoland = $scope.profile.country === "pl";
  $scope.doNotShowEmpIdMess = false;
  $scope.hideIdMess = false;
  $scope.email = {valid: true};
  $scope.uniquePin = {valid: true};
  $scope.role = {};
  $scope.progress = false;

  let brandName = "loyverse";

  let pinCode;
  $scope.options = {};
  $scope.textOptions = {};
  $scope.clicked = true;
  $scope.pin = {value: ["", "", "", ""]};
  $scope.employeeWithoutOutlets = false;
  const maxEmployees = $profile.employeesCountInTrial;

  // код для БИЛЛИНГА
  $scope.emptyPlan = {id: null, cycle: null, unlim: null, price: null};
  let profBillingStatus = $profile.billingInfo.billingStatus;
  let typeBillingPayment = $profile.billingInfo.gw;
  $scope.timeCardEnabled = $profile.timeCardEnabled;

  let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

  function isChanged() {
    let finalParams = {
      name: $scope.employee.name,
      email: $scope.employee.email,
      employeeId: $scope.employee.employeeId,
      pin: $scope.employee.pin,
      phone: $scope.employee.phone || "",
      selectType: $scope.options.selectType,
      emailWasSent: $scope.options.emailWasSent,
      outlets: $scope.outlets.map((item) => {
        return {
          enable: item.enable,
          select: item._select,
          name: item.name,
          outletName: item.outletName,
        };
      }),
    };

    return !angular.equals(initParams, finalParams);
  }

  $scope.couponEmployee = {
    showInput: false,
    error: "",
    name: "",
    tempName: "",
    haveCoupon: false,
    code: null,
  };

  $scope.selectRoleSendEmail = (value) => {
    let val = value !== null ? parseInt(value) : null;

    if (val === -1) {
      $state.go("employees.createpermits");
      $scope.options.selectType = null;
      return;
    }

    for (let i = 0, len = allRoles.length; i < len; i++) {
      if (val === null) {
        $scope.options.allowBackOffice = false;
        $scope.options.allowLPOS = false;
      } else {
        if (allRoles[i].id === val) {
          $scope.options.allowBackOffice = allRoles[i].allowBackOffice;
          $scope.options.allowLPOS = allRoles[i].allowLPOS;
          $scope.options.emailWasSent = allRoles[i].allowBackOffice;
        }
      }
    }

    $scope.showSendEmail = checkSendEmail();
  };

  $scope.nonEmptyRole = (value) => {
    return value !== 1;
  };

  const checkSendEmail = () => $scope.options.type !== "OWNER" && !$scope.textOptions.wasLoggedInOwnerCabinet && $scope.options.allowBackOffice;

  const recaptchaInfo = {
    key: '6LfYS-YpAAAAABHrSlote4ikBuh4AGDMJi3PkBTo',
    widgetId: null,
    verify: false,
    backendVerificationFailed: false
  };

  if ($state.current.name === "employees.createemployee") {
    allRoles = buildRoles($employee.roles, true);
    $scope.selectOptions = buildRoles($employee.roles);

    $scope.options.showDelBtn = false;
    $scope.options.selectType = null;
    $scope.textOptions.emailWasSent = false;
    $scope.textOptions.wasLoggedInOwnerCabinet = false;
    $scope.options.type = $employee.type;
    $scope.editRole = true;
    $scope.employee = {
      name: "",
      phone: "",
      email: "",
      employeeId: null,
      pin: convertPin.convertPinToArray(randomFromTo.getRandomInt(1000, 10000)),
    };
  } else {
    $scope.options.type = $employee.type;
    allRoles = buildRoles($employee.possibleRoles, true);
    $scope.selectOptions = buildRoles($employee.possibleRoles);
    $scope.options.selectType = $employee.roleId;
    $scope.textOptions.emailWasSent = $employee.emailWasSent;
    $scope.textOptions.wasLoggedInOwnerCabinet =
      $employee.wasLoggedInOwnerCabinet;
    $scope.textOptions.firstCabLogin = $filter("formatDateByBrowser")(
      $employee.firstCabLogin,
      "long"
    );
    $scope.editRole =
      $scope.options.type !== "OWNER" && !$employee.currentlyLogged;

    $scope.selectRoleSendEmail($employee.roleId);

    if (!$scope.editRole) {
      for (let i = 0, length = $scope.selectOptions.length; i < length; i++) {
        if ($scope.selectOptions[i].id === $employee.roleId) {
          $scope.roleSelected =
            $scope.selectOptions[i].name ||
            $translate.instant($scope.selectOptions[i].type);
          break;
        } else if (i === length - 1) {
          $scope.roleSelected = $translate.instant("OWNER");
        }
      }
    }

    let name;
    $scope.employee = {};

    if ($scope.options.type === "OWNER") {
      $scope.options.showDelBtn = false;
      if ($employee.name === null) {
        $scope.employee.name = $translate.instant($employee.type);
        name = $translate.instant($employee.type);
      } else {
        $scope.employee.name = $employee.name;
        name = $employee.name;
      }
    } else {
      $scope.options.showDelBtn = true;
      name = $employee.name;
    }

    if ($employee.pin === null) {
      if ($scope.options.type !== "OWNER") {
        pinCode = randomFromTo.getRandomInt(1000, 10000);
      } else {
        pinCode = $employee.pin;
      }
    } else {
      pinCode = $employee.pin;
    }

    $scope.employee = {
      merchantId: $employee.merchantId,
      name: name,
      phone: $employee.phone,
      email: $employee.email || "",
      pin: convertPin.convertPinToArray(pinCode),
      hasOtherMerchants: $employee.hasOtherMerchants,
      byOutlets: $employee.byOutlets,
      allowedForAllOutlets: $employee.allowedForAllOutlets,
      employeeId: $employee.employeeId,
      firstCabLogin: $employee.firstCabLogin,
    };
  }

  $scope.showSendEmail = checkSendEmail();

  function buildRoles(sourceRoles, includeOwner) {
    let result = sourceRoles.map((val) => {
      val.name = val.name || $translate.instant(val.type);
      return val;
    });

    if (!includeOwner) {
      result = result.filter((val) => val.type !== "OWNER");
    }

    if (result.length === 0) {
      result.unshift({
        id: -1,
        name: $translate.instant("EMPLOYEE.CREATE_ROLE"),
      });
    } else {
      result.unshift({
        id: null,
        name: $translate.instant("EMPLOYEE.CHOOSE_ROLE"),
      });
    }

    return result;
  }
  // блок инициализации данных

  // данные для сохранение состояния фильтров
  $scope.stateFromList = $stateParams.stateToSave;
  $scope.checkFromList = $stateParams.checkToSave;
  // данные для сохранение состояния фильтров

  //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ
  $scope.state = $stateParams;
  if ($stateParams.id) {
    $scope.outlets = $scope.employee.byOutlets;
  } else {
    $scope.outlets = $shortOutlets;
  }
  $scope.waresCount = 0;
  $scope.outletSelect = [];
  $scope.allOutlets = {};

  $scope.$watch(
    "outlets",
    (newVal) => {
      for (let j = 0; j < newVal.length; j++) {
        if ($stateParams.id) {
          if (newVal[j].enable === true) {
            $scope.outletSelect.push(newVal[j].outletId);
            $scope.waresCount += newVal[j].waresCount;
          }
        } else {
          if (newVal[j]._select === true) {
            $scope.outletSelect.push(newVal[j].id);
            $scope.waresCount += newVal[j].waresCount;
          }
        }
      }
      $scope.allOutlets._select =
        $scope.outletSelect.length === $scope.outlets.length;
      // ложим айдишники выбранных заведений в свойство обьекта employee для отправки на сервер
      $scope.employee.outletsIds = $scope.outletSelect;
      $scope.employee.allowedForAllOutlets = $scope.allOutlets._select;
      $scope.outletSelect = [];
    },
    true
  );

  for (let i = 0; i < $scope.outlets.length; i++) {
    $scope.outlets[i]["_select"] = true;
  }

  initParams = {
    name: $scope.employee.name,
    email: $scope.employee.email,
    employeeId: $scope.employee.employeeId,
    pin: $scope.employee.pin,
    phone: $scope.employee.phone || "",
    selectType: $scope.options.selectType,
    emailWasSent: $scope.options.emailWasSent,
    outlets: $scope.outlets.map((item) => {
      return {
        enable: item.enable,
        select: item._select,
        name: item.name,
        outletName: item.outletName,
      };
    }),
  };

  $scope.changeAll = () => {
    if ($scope.allOutlets._select === true) {
      for (let k = 0; k < $scope.outlets.length; k++) {
        if ($stateParams.id) {
          $scope.outlets[k]["enable"] = true;
        } else {
          $scope.outlets[k]["_select"] = true;
        }
      }
      $scope.allOutlets._select = true;
    } else {
      for (let m = 0; m < $scope.outlets.length; m++) {
        if ($stateParams.id) {
          $scope.outlets[m]["enable"] = false;
        } else {
          $scope.outlets[m]["_select"] = false;
        }
      }
      $scope.allOutlets._select = false;
    }
    $scope.removeCheckboxError();
  };
  //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ

  //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
  $scope.showLine = true; // показывать линию в главном блоке
  $scope.showAllOutlets = false; // показывать весь блок с заведениями
  $scope.singleDisabledOutlet =
    $scope.outlets.length === 1 && $scope.outlets[0].enable === false;

  $scope.showBlock = (flag) => {
    $scope.showAllOutlets = flag;
    $scope.showLine = !flag;
    $scope.singleDisabledOutlet = !flag;
  };
  //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА

  //** Функция созадние ивентов для Mixpanel - создание и редактирование СОТРУДНИКА (без БИЛЛИНГА)
  $scope.createEditMerchantForMixpanel = (employee, type) => {
    let roleId = parseInt(employee.roleId);
    let employeeProperty = {};

    let roleName = (roleId) => {
      let temp = "Other";
      for (let i = 0; i < $scope.selectOptions.length; i++) {
        if (
          $scope.selectOptions[i].id === roleId &&
          $scope.selectOptions[i].type === "OWNER"
        ) {
          temp = "Owner";
          break;
        }
        if (
          $scope.selectOptions[i].id === roleId &&
          $scope.selectOptions[i].type === "ADMINISTRATOR"
        ) {
          temp = "Administrator";
          break;
        }
        if (
          $scope.selectOptions[i].id === roleId &&
          $scope.selectOptions[i].type === "MANAGER"
        ) {
          temp = "Manager";
          break;
        }
        if (
          $scope.selectOptions[i].id === roleId &&
          $scope.selectOptions[i].type === "CASHIER"
        ) {
          temp = "Cashier";
          break;
        }
      }
      return temp;
    };

    employeeProperty.email =
      typeof employee.email === "string" && employee.email.length > 0
        ? "Filled"
        : "Blank";
    employeeProperty.phone =
      typeof employee.phone === "string" && employee.phone.length > 0
        ? "Filled"
        : "Blank";
    employeeProperty.role = roleName(roleId);

    lvMixpanel.track("Employee created/edited", {
      Email: employeeProperty.email,
      "Business phone number": employeeProperty.phone,
      Role: employeeProperty.role,
      "Event type": type,
    });
  };
  //** Функция созадние ивентов для Mixpanel - создание и редактирование СОТРУДНИКА (без БИЛЛИНГА)

  // функция СОЗДАНИЕ/ИЗМЕНЕНИЕ сотрудника на сервере
  const send = (
    setPlan,
    restartPage,
    couponCode,
    estimate,
    tempPlan,
    tempCoupon,
    trackInfo
  ) => {
    $scope.clicked = false;

    let roleId = $scope.options.selectType;

    let pin;
    //** Функция создания нового сотрудника
    if ($state.current.name === "employees.createemployee") {
      $scope.options.allowLPOS === true
        ? (pin = $scope.employee.pin.join(""))
        : (pin = null);

      let merchantCreate = {
        name: $scope.employee.name,
        phone: $scope.employee.phone,
        email: $scope.employee.email || null,
        pin: pin,
        roleId: roleId,
        sendEmailToMerchant: $scope.options.emailWasSent,
        brandName: brandName,
        outletsIds: $scope.employee.outletsIds,
        allowedForAllOutlets: $scope.allOutlets._select,
        employeeId: $scope.employee.employeeId,
        planId:
          $scope.minAccountInfo &&
          $scope.minAccountInfo.subscriptions[subscriptionType].plan.id != null
            ? $scope.minAccountInfo.subscriptions[subscriptionType].plan.id
            : setPlan
            ? setPlan.id
            : null,
        coupon: couponCode,
        estimate: estimate,
        checkBillingActions: checkBillingActions,
      };

      if (estimate) {
        showEstimatedInvoice(
          merchantCreate,
          setPlan,
          restartPage,
          tempPlan,
          tempCoupon
        );
      } else {
        createNewEmployee(merchantCreate, setPlan, restartPage, trackInfo);
      }
      //** Функция создания нового сотрудника

      //** Функция редактирования сотрудника
    } else {
      let merchantName;
      let sendEmailToMerchant;

      if ($scope.options.type === "OWNER") {
        sendEmailToMerchant = false;
        if ($scope.employee.name === $translate.instant("OWNER")) {
          merchantName = null;
        } else {
          merchantName = $scope.employee.name;
        }
      } else {
        merchantName = $scope.employee.name;
        if ($scope.employee.firstCabLogin === null && !$scope.textOptions.emailWasSent) {
          sendEmailToMerchant = $scope.options.emailWasSent;
        } else {
          sendEmailToMerchant = false;
        }
      }

      if ($scope.employee.pin === null || $scope.options.allowLPOS === false) {
        pin = null;
      } else {
        pin = $scope.employee.pin.join("");
      }

      let merchantEdit = {
        merchantId: $scope.employee.merchantId,
        name: merchantName,
        phone: $scope.employee.phone,
        email: $scope.employee.email || null,
        pin: pin,
        pinLoginOn: true,
        roleId: roleId,
        sendEmailToMerchant: sendEmailToMerchant,
        brandName: brandName,
        outletsIds: $scope.employee.outletsIds,
        allowedForAllOutlets: $scope.allOutlets._select,
        employeeId: $scope.employee.employeeId,
      };

      editEmployee(merchantEdit);
    }
    //** Функция редактирования сотрудника
  };
  // функция СОЗДАНИЕ/ИЗМЕНЕНИЕ сотрудника на сервере

  $scope.valid = () => {
    $timeout(() => {
      if (
        angular
          .element(document.getElementsByName("id"))
          .hasClass("ng-invalid-id")
      ) {
        $scope.employeeForm.id.$setValidity("id", true);
        $scope.employeeForm.$invalid = false;
        $scope.employeeForm.id.$invalid = false;
        $scope.employeeForm.id.$error = {};
        $scope.employeeForm.$error = {};
        $scope.hideIdMess = false;
      }
      $scope.doNotShowEmpIdMess =
        angular.element(document.getElementsByClassName("animate-err-mess"))
          .length > 0 &&
        !(
          $scope.employee.employeeId.length > 0 &&
          $scope.employee.employeeId.length <= 20
        );
    }, 0);
  };

  $scope.checkIfValidMessage = () => {
    $scope.doNotShowEmpIdMess =
      $scope.employee.employeeId != null
        ? $scope.employee.employeeId.length === 0 ||
          $scope.employee.employeeId.length > 20
        : true;
  };

  // функция по нажатию на СОХРАНИТЬ при СОЗДАНИИ, делает проверки и если ОК запускает СОЗДАНИЕ сотрудника
  $scope.onSaveNewUser = () => {
    if ($scope.employeeForm.$invalid) {
      $scope.employeeForm.focusFirstInvalidControl();
      serviceToast.show("INVENTORY.CREATE_ORDER_INVALID", "error");
      return false;
    }

    if ($scope.clicked === false) {
      return false;
    } else if ($scope.options.selectType == null) {
      $scope.role.modelCtrl.$setTouched();
      return false;
    } else if ($scope.employeeForm.$invalid) {
      $scope.employeeForm.markFirstInvalidControl();
      return false;
    } else {
      send();
    }
  };
  // функция по нажатию на СОХРАНИТЬ при СОЗДАНИИ, делает проверки и если ОК запускает СОЗДАНИЕ сотрудника

  // функция по нажатию на СОХРАНИТЬ при РЕДАКТИРОВАНИИ, делает проверки и запускает ИЗМЕНЕНИЕ сотрудника
  $scope.onSaveEditUser = () => {
    if ($scope.employeeForm.$invalid) {
      $scope.employeeForm.focusFirstInvalidControl();
      serviceToast.show("INVENTORY.CREATE_ORDER_INVALID", "error");
      return false;
    }

    if ($scope.clicked === false) {
      return false;
    } else if ($scope.options.selectType == null) {
      $scope.role.modelCtrl.$setTouched();
      return false;
    } else if ($scope.employeeForm.$invalid) {
      $scope.employeeForm.markFirstInvalidControl();
      return false;
    } else {
      send();
    }
  };
  // функция по нажатию на СОХРАНИТЬ при РЕДАКТИРОВАНИИ, делает проверки и запускает ИЗМЕНЕНИЕ сотрудника

  // диалоговое окно <подтверждение оплаты> на сотрудника в статусах ACTIVE или PAST_DUE если тариф поединичный
  const paymentWhenCreate = (plan) => {
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/billing/dialog-billing-payment-when-create.html",
      title: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_PAYMENT_FOR_EMPLOYEE"
      ),
      content1: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_PAYMENT_FOR_EMPLOYEE_TEXT"
      ),
      paymentType: $scope.minAccountInfo.paymentMethod,
      inEU: $scope.minAccountInfo.taxMessage,
      buttons: [
        {
          text: "ON_CANCEL",
          action: () => {
            serviceBillingMixpanel.setMixpanelBillingPaymentWhenCreate(
              "cancel",
              $scope.subscriptionPlan,
              subscriptionType,
              $scope.subscriptionStatus
            );
          },
        },
        {
          text: "ACCOUNT.PAY",
          action: () => {
            checkBillingActions = false;
              send(plan, false, undefined, undefined, undefined, undefined, 'Pay');
          },
        },
      ],
    });
  };
  // диалоговое окно <подтверждение оплаты> на сотрудника в статусах ACTIVE или PAST_DUE если тариф поединичный

  // диалоговое окно <первичная подписка> на сотрудников или <продолжить> в ТРИАЛЕ
  const subscribeEmployees = () => {
    let msgTitle, msgContent1, msgContent2, msgContent3, msgDate;
    let terms = {
      checked: true,
    };

    if ($scope.subscriptionStatus === "NONE") {
      msgTitle = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_EMPLOYEE_TRIAL_FREE_TITLE"
      );
      msgContent1 = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_EMPLOYEE_TRIAL_FREE_TEXT_1"
      );
      msgContent2 = null;
      msgContent3 = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_" +
          subscriptionType +
          "_TRIAL_FREE_TEXT_2"
      );
      msgDate = null;
    } else if ($scope.subscriptionStatus === "INTRIAL_NOSUB_NOACT") {
      msgTitle = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_EMPLOYEE_TITLE"
      );
      msgContent1 = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_EMPLOYEE_TEXT_1"
      );
      msgContent2 = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_EMPLOYEE_TEXT_2"
      );
      msgContent3 = $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_" + subscriptionType + "_TEXT_3"
      );
      msgDate = $scope.minAccountInfo.subscriptions[subscriptionType].trialEnd;
    }
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html",
      title: msgTitle,
      content1: msgContent1,
      content2: msgContent2,
      content3: msgContent3,
      date: msgDate,
      terms: terms,
      priceOne: lodash.find($scope.minAccountInfo.plans[subscriptionType], {
        cycle: 1,
        unlim: false,
      }),
      buttons: [
        {
          text: "ON_CANCEL",
          action: () => {
            if (
              subscriptionType === "EMPLOYEE" ||
              subscriptionType === "EMPSTORE"
            ) {
              serviceBillingMixpanel.setMixpanelBillingInCreateUser(
                "cancel",
                $scope.subscriptionPlan,
                $scope.subscriptionStatus,
                undefined,
                subscriptionType
              );
            }
          },
        },
        {
          text:
            $scope.subscriptionStatus === "NONE"
              ? "START_FREE_TRIAL"
              : "ACCOUNT.CONTINUE",
          class: "md-primary",
          action: () => {
            $scope.minAccountInfo.subscriptions[subscriptionType].plan.id =
              subscriptionType;
            checkBillingActions = false;
            send(
              $scope.minAccountInfo.subscriptions[subscriptionType].plan,
              true,
              undefined,
              undefined,
              undefined,
              undefined
            );
          },
        },
      ],
    });
  };
  // диалоговое окно <первичная подписка> на сотрудников или <продолжить> в ТРИАЛЕ

  // диалоговое окно <подписка на сотрудников сразу с активацией В АКТИВЕ> =>=> будет окно выбора тарифа и дальнейшая активация
  const subscribeActivateEmployees = () => {
    let tempPlan = angular.copy(
      $scope.minAccountInfo.subscriptions[subscriptionType].plan
    );
    let tempCoupon = angular.copy($scope.couponEmployee);
    let couponCode;
    const payPerConst = subscriptionType === "EMPLOYEE" ? "EMPLOYEE" : "STORES";
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html",
      title: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_EMPLOYEE_TITLE"
      ),
      content1: $translate.instant("ACCOUNT.B2_EMPLOYEE_SUBSCRIBE_TEXT"),
      content2: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN"
      ),
      content3: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_" + payPerConst + "_PAY_PER"
      ),
      content4: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_" + payPerConst + "_PAY_PER_MONTH"
      ),
      content5: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_" + payPerConst + "_PAY_PER_YEAR"
      ),
      content6: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_" + payPerConst + "_PAY_PER_UNLIMITED"
      ),
      content7: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH"
      ),
      content8: $translate.instant("ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR"),
      single1: lodash.find($scope.minAccountInfo.plans[subscriptionType], {
        cycle: 1,
        unlim: false,
      }),
      single12: lodash.find($scope.minAccountInfo.plans[subscriptionType], {
        cycle: 12,
        unlim: false,
      }),
      unlim1: lodash.find($scope.minAccountInfo.plans[subscriptionType], {
        cycle: 1,
        unlim: true,
      }),
      unlim12: lodash.find($scope.minAccountInfo.plans[subscriptionType], {
        cycle: 12,
        unlim: true,
      }),
      newPlan: $scope.minAccountInfo.subscriptions[subscriptionType].plan,
      paymentType: $scope.minAccountInfo.paymentMethod,
      inEU: $scope.minAccountInfo.taxMessage,
      couponDate: $scope.couponEmployee,
      buttons: [
        {
          text: "ON_CANCEL",
          action: () => {
            if (
              subscriptionType === "EMPLOYEE" ||
              subscriptionType === "EMPSTORE"
            ) {
              serviceBillingMixpanel.setMixpanelBillingInCreateUser(
                "cancel",
                undefined,
                undefined,
                $scope.minAccountInfo.paymentMethod,
                subscriptionType
              );
            }
            cancelSubscriptionDialog(tempPlan, tempCoupon);
          },
        },
        {
          text:
            $scope.minAccountInfo.paymentMethod === null
              ? "ACCOUNT.CONTINUE"
              : "SUBSCRIBE",
          action: () => {
            checkBillingActions = false;
            $scope.couponEmployee.haveCoupon === true &&
            $scope.couponEmployee.code !== null
              ? (couponCode = $scope.couponEmployee.code)
              : (couponCode = null);
            $scope.minAccountInfo.subscriptions[subscriptionType].plan =
              lodash.find($scope.minAccountInfo.plans[subscriptionType], {
                id: $scope.minAccountInfo.subscriptions[subscriptionType].plan
                  .id,
              });

            if ($scope.minAccountInfo.paymentMethod === null) {
              let roleId = $scope.options.selectType;
              let pin;
              $scope.options.allowLPOS === true
                ? (pin = $scope.employee.pin.join(""))
                : (pin = null);

              let merchantCreateInStateCard = {
                name: $scope.employee.name,
                phone: $scope.employee.phone,
                email: $scope.employee.email || null,
                pin: pin,
                roleId: roleId,
                sendEmailToMerchant: $scope.options.emailWasSent,
                brandName: brandName,
                outletsIds: $scope.employee.outletsIds,
                allowedForAllOutlets: $scope.allOutlets._select,
                planId:
                  $scope.minAccountInfo.subscriptions[subscriptionType].plan,
                coupon: couponCode,
                checkBillingActions: false,
              };
              if (typeBillingPayment === "bt") {
                offStateChangeStart();
                $state.go("addPayMethodBt", {
                  newUser: merchantCreateInStateCard,
                  typeSubscriptions: subscriptionType,
                });
              }
              if (stripeGateways.indexOf(typeBillingPayment) !== -1) {
                offStateChangeStart();
                $state.go("addPayMethodStr", {
                  newUser: merchantCreateInStateCard,
                  typeSubscriptions: subscriptionType,
                });
              }
              if (typeBillingPayment === "bs" || typeBillingPayment === "wc") {
                serviceBillingCommonMethods.getChargebeeHostedPageForAdd(
                  ownerId,
                  $scope.minAccountInfo.subscriptions[subscriptionType].plan.id,
                  couponCode,
                  cancelSubscriptionDialog.bind(null, tempPlan, tempCoupon),
                  null,
                  serviceBillingCommonMethods.createUser.bind(
                    null,
                    merchantCreateInStateCard,
                    $scope.minAccountInfo.subscriptions[subscriptionType].status
                  )
                );
              }
            } else {
              send(
                $scope.minAccountInfo.subscriptions[subscriptionType].plan,
                false,
                couponCode,
                true,
                tempPlan,
                tempCoupon
              );
            }
          },
        },
        {
          text: "APPLY_APPLY",
          closeOnAction: false,
          action: () => {
            serviceBillingDifferentPlaces.addCoupon(
              $scope.minAccountInfo.subscriptions[subscriptionType].plan,
              $scope.couponEmployee,
              $scope.couponEmployee.name
            );
          },
        },
      ],
    });
  };
  // диалоговое окно <подписка на сотрудников сразу с активацией В АКТИВЕ> =>=> будет окно выбора тарифа и дальнейшая активация

  // функция по нажатию на ОТМЕНА, возвращаемся на список сотрудников
  $scope.onCancel = () => {
    $stateParams.page = 0;
    backToList();
  };
  // функция по нажатию на ОТМЕНА, возвращаемся на список сотрудников

  // функция по нажатию на УДАЛИТЬ, сначала диалоговое окно, затем запуск удаления на сервере
  $scope.onDel = (editProfile) => {
    factoryBilling.getMinAccountInfo({}).then((bilData) => {
      $scope.minAccountInfo = angular.copy(bilData);
      initBillingData($scope.minAccountInfo);

      $scope.lastEmployee =
        $employees.merchants.length === 2 &&
        $scope.subscriptionStatus !== "FREE" &&
        $scope.subscriptionStatus !== "NONE";

      if (!editProfile) {
        serviceToast.show("U_DONT_HAVE_PERMITS_EDIT_PROFILE", "error");
        return;
      }

      serviceDialog.add({
        templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
        title: $translate.instant("EMPLOYEE.DELETE_EMPLOYEE"),
        content: $translate.instant("EMPLOYEE.DELETE_EMPLOYEE_TEXT"),
        buttons: [
          {text: "ON_CANCEL"},
          {
            class: "primary",
            text: "ON_DEL",
            action: $scope.onSendData,
          },
        ],
      });
    });

    $scope.onSendData = () => {
      let merchantIds = [$scope.employee.merchantId];
      getEmployees.deleteEmployee({merchantIds: merchantIds}).then((res) => {
        if (
          (subscriptionType === "EMPLOYEE" ||
            subscriptionType === "EMPSTORE") &&
          $scope.lastEmployee
        ) {
          serviceBillingMixpanel.setMixpanelBillingDelSubscriptionInEmployee(
            res.result,
            subscriptionType
          );
        }

        if (res.result === "ok") {
          offStateChangeStart();
          serviceToast.show("EMPLOYEE.EMPLOYEE_DELETED", "success");

          backToList();
        } else if (res.result === "error") {
          if (res.message === "not_permitted_while_past_due") {
            serviceDialog.add({
              templateUrl:
                "dashboard-page/templates/dialog/billing/dialog-billing-base.html",
              class: "billing-base-384",
              content1: $translate.instant("ACCOUNT.NOT_PERMITTED_PAST_DUE"),
              buttons: [{class: "primary", text: "ON_OK", action: () => {}}],
            });
          } else if (res.message === "temporary_unavailable") {
            serviceDialog.add({
              templateUrl:
                "dashboard-page/templates/dialog/billing/dialog-billing-base.html",
              content1: $translate.instant(
                "ACCOUNT.B2_SERVICE_DIALOG.B2_ERROR_CHARGEBEE"
              ),
              buttons: [{class: "primary", text: "ON_OK", action: () => {}}],
            });
          } else if (res.message === "employee_deleted") {
            dialogs.itemDeletedEarlier().then(backToList);
          } else {
            unKnownError(res.message);
          }
        } else {
          unKnownError(res.message);
        }
      });
    };
  };
  // функция по нажатию на УДАЛИТЬ, сначала диалоговое окно, затем запуск удаления на сервере

  // функции по установки ПИН-а
  const setPin = () => {
    $scope.employee.pin = $scope.pin.value;
    $scope.employee.pinLoginOn = true;
  };

  $scope.onSetPin = () => {
    serviceDialog.add({
      templateUrl: "dashboard-page/templates/dialog/dialog-pin.html",
      title: $translate.instant("PIN_CODE"),
      content: $translate.instant("EMPLOYEE.SET_PIN_MSG"),
      pin: $scope.pin,
      buttons: [
        {
          text: "ON_CANCEL",
          action: () => {
            $scope.pin.value = ["", "", "", ""];
            return true;
          },
        },
        {class: "primary", text: "CONTINUE", action: setPin},
      ],
    });
  };
  // функции по установки ПИН-а

  // функции по удалению ПИН-а
  const delPin = () => {
    $scope.employee.pin = null;
    $scope.employee.pinLoginOn = false;
  };

  $scope.onDelPin = () => {
    serviceDialog.add({
      templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
      title: $translate.instant("EMPLOYEE.UNLOCK_PIN_TITLE"),
      content: $translate.instant("EMPLOYEE.UNLOCK_PIN_MSG"),
      buttons: [
        {text: "ON_CANCEL"},
        {class: "primary", text: "CONTINUE", action: delPin},
      ],
    });
  };
  // функции по удалению ПИН-а

  // диалоговое окно на ответ с сервере ERROR с НЕ описанной ошибкой
  const unKnownError = (m) => {
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/billing/dialog-billing-base.html",
      content1: $translate.instant(
        "ACCOUNT.B2_SERVICE_DIALOG.B2_OTHER_ERROR_1"
      ),
      content2:
        $translate.instant("ACCOUNT.B2_SERVICE_DIALOG.B2_OTHER_ERROR_2") + m,
      buttons: [{class: "primary", text: "ON_OK"}],
    });
  };
  // диалоговое окно на ответ с сервере ERROR с НЕ описанной ошибкой

  // отключение диалога выбора подписки
  const cancelSubscriptionDialog = (plan, coupon) => {
    $scope.minAccountInfo.subscriptions[subscriptionType].plan = plan;
    $scope.couponEmployee = coupon;
  };
  // отключение диалога выбора подписки

  // предпросмотр счета к оплате
  const showEstimatedInvoice = (
    merchantCreateEstimate,
    setPlan,
    restartPage,
    tempPlan,
    tempCoupon
  ) => {
    getEmployees.setEmployee(merchantCreateEstimate).then((res) => {
      if (res.result === "ok") {
        offStateChangeStart();
        serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(
          res.data,
          createNewEmployee.bind(
            null,
            merchantCreateEstimate,
            setPlan,
            restartPage
          ),
          cancelEstimatedInvoice.bind(undefined, tempPlan, tempCoupon)
        );
      } else if (res.result === "error") {
        serviceBillingError.knownError(res.message);
      } else {
        serviceBillingError.unKnownError();
      }
    });
  };
  // предпросмотр счета к оплате

  // отмена предпросмотра счета к оплате
  const cancelEstimatedInvoice = (tempPlan, tempCoupon) => {
    $scope.clicked = true;
    cancelSubscriptionDialog(tempPlan, tempCoupon);
    subscribeActivateEmployees();
  };
  // отмена предпросмотра счета к оплате

  // отправка на сервер и обработка ответа запроса на создание нового сотрудника
  const createNewEmployee = (merchantCreate, setPlan, restartPage) => {
    merchantCreate.estimate = false;
    $scope.progress = true;
    getEmployees.setEmployee(merchantCreate).then((res) => {
      if (res.result === "ok") {
        offStateChangeStart();

        if (
          subscriptionType === "EMPLOYEE" ||
          subscriptionType === "EMPSTORE"
        ) {
          if (subscriptionType === "EMPLOYEE") {
            serviceBillingMixpanel.setMixpanelBillingPaymentWhenCreate(
              res.result,
              setPlan,
              subscriptionType,
              $scope.subscriptionStatus
            );
          }
          serviceBillingMixpanel.setMixpanelBillingInCreateUser(
            res.result,
            setPlan,
            $scope.subscriptionStatus,
            undefined,
            subscriptionType
          );
        }

        serviceToast.show("EMPLOYEE.EMPLOYEE_ADDED", "success");
        if (subscriptionType === "EMPLOYEE") {
          $scope.createEditMerchantForMixpanel(
            merchantCreate,
            "Employee created"
          );
        }

        backToList();
      } else if (res.result === "error") {
        $scope.clicked = true;

        if (res.message === "maximum_employees_reached") {
          serviceDialog.add({
            templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
            title: $translate.instant("EMPLOYEE.UNABLE_TO_SAVA_EMPLOYEE"),
            content: $translate.instant("ERROR.maximum_employees_reached", {
              maxEmployees: maxEmployees,
            }),
            buttons: [{class: "primary", text: "ON_OK"}],
          });
        } else if (res.message === "no_stores_available") {
          showNoOutletsWarning();
        } else {
          serviceBillingError.knownError(res.message);
        }
      } else if (res.result === "validation_errors") {
        $scope.clicked = true;
        serviceToast.show("INVENTORY.CREATE_ORDER_INVALID", "error");
        if (res.errors.indexOf("pin_already_exists") > -1) {
          $scope.uniquePin.valid = false;
          $timeout(() => {
            $scope.employeeForm.markFirstInvalidControl();
          }, 100);
        }
        if (res.errors.indexOf("employee_email_exists") > -1) {
          $scope.email.valid = false;
          $timeout(() => {
            $scope.employeeForm.markFirstInvalidControl();
          }, 100);
        }
        if (res.errors.indexOf("merchant_with_this_id_exists") > -1) {
          $scope.employeeForm.id.$setValidity("id", false);
          $scope.employeeForm.id.setAlertMessage(
            "merchant_with_this_id_exists"
          );
          $scope.hideIdMess = true;
        }
      } else {
        let scenarioCode = res.result;
        factoryBilling.getMinAccountInfo({}).then((bilData) => {
          $scope.minAccountInfo = angular.copy(bilData);
          initBillingData($scope.minAccountInfo);
          $scope.clicked = true;
          if (scenarioCode === "notify_need_subscribe") {
            subscribeEmployees();
          } else if (scenarioCode === "notify_billing_charge") {
            paymentWhenCreate($scope.subscriptionPlan);
          } else if (scenarioCode === "notify_select_plan") {
            subscribeActivateEmployees(true);
          } else if (scenarioCode === "attempt_limit_exceeded") {
            serviceBillingError.knownError("attempt_limit_exceeded");
          } else {
            unKnownError();
          }
        });
      }
    })
    .finally(() => {
      $scope.progress = false;
    });
  };
  // отправка на сервер и обработка ответа запроса на создание нового сотрудника

  // sending to server and getting response for editing an employee
  const editEmployee = (merchantEdit) => {
    $scope.progress = true;
    getEmployees.editEmployee(merchantEdit).then((res) => {
      if (res.result === "ok") {
        offStateChangeStart();
        serviceToast.show("EMPLOYEE.EMPLOYEE_EDITED", "success");
        if (subscriptionType === "EMPLOYEE") {
          $scope.createEditMerchantForMixpanel(merchantEdit, "Employee edited");
        }
        factoryProfile.getProfile(true).then(() => {
          backToList();
        });
      } else if (res.result === "error") {
        $scope.clicked = true;

        if (res.message === "no_stores_available") {
          showNoOutletsWarning();
        }

        if (res.message === "employee_deleted") {
          offStateChangeStart();
          dialogs.itemDeletedEarlier().then(backToList);
        }
      } else if (res.result === "validation_errors") {
        $scope.clicked = true;
        serviceToast.show("INVENTORY.CREATE_ORDER_INVALID", "error");
        if (res.errors.indexOf("pin_already_exists") > -1) {
          $scope.uniquePin.valid = false;
          $timeout(() => {
            $scope.employeeForm.markFirstInvalidControl();
          }, 100);
        }
        if (res.errors.indexOf("merchant_with_this_email_exists") > -1) {
          $scope.email.valid = false;
          $timeout(() => {
            $scope.employeeForm.markFirstInvalidControl();
          }, 100);
        }
        if (res.errors.indexOf("merchant_with_this_id_exists") > -1) {
          $scope.employeeForm.id.$setValidity("id", false);
          $scope.employeeForm.id.setAlertMessage(
            "merchant_with_this_id_exists"
          );
          $scope.hideIdMess = true;
        }
      } else {
        $scope.clicked = true;
        unKnownError(res.message);
      }
    })
    .finally(() => {
      $scope.progress = false;
    });
  };
  // sending to server and getting response for editing an employee

  const initBillingData = (minAccountInfo) => {
    minAccountInfo === undefined ||
    minAccountInfo.message === "temporary_unavailable"
      ? ($scope.errorChargebee = true)
      : ($scope.errorChargebee = false);

    let existingSubscriptions = Object.keys(minAccountInfo.subscriptions);
    subscriptionType =
      existingSubscriptions.indexOf("EMPSTORE") !== -1
        ? "EMPSTORE"
        : "EMPLOYEE";

    if (profBillingStatus === "FREE") {
      $scope.minAccountInfo.subscriptions = {};
      $scope.minAccountInfo.subscriptions[subscriptionType] = {};
      $scope.minAccountInfo.subscriptions[subscriptionType].status = "FREE";
      $scope.minAccountInfo.subscriptions[subscriptionType] = {};
      $scope.minAccountInfo.subscriptions[subscriptionType].plan = angular.copy(
        $scope.emptyPlan
      );
      $scope.subscriptionStatus = "FREE";
      $scope.subscriptionPlan = angular.copy($scope.emptyPlan);
    } else {
      if (!$scope.errorChargebee) {
        minAccountInfo.subscriptions[subscriptionType].plan === null
          ? ($scope.minAccountInfo.subscriptions[subscriptionType].plan =
              angular.copy($scope.emptyPlan))
          : "";

        $scope.subscriptionStatus = angular.copy(
          minAccountInfo.subscriptions[subscriptionType].status
        );
        $scope.subscriptionPlan = angular.copy(
          minAccountInfo.subscriptions[subscriptionType].plan
        );
      }
    }
  };

  // dialog window with warning that there's no stores selected for employee
  const showNoOutletsWarning = () => {
    $scope.employeeWithoutOutlets = true;
    serviceDialog.add({
      templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
      content: $translate.instant("EMPLOYEE.NO_ACCESS_TO_STORES"),
      buttons: [
        {
          class: "primary",
          text: "ON_OK",
        },
      ],
    });
  };
  // dialog window with warning that there's no stores selected for employee

  // remove red checkbox color if error exists
  $scope.removeCheckboxError = () => {
    if ($scope.employeeWithoutOutlets) {
      $scope.employeeWithoutOutlets = false;
    }
  };
  // remove red checkbox color if error exists

  $scope.popover = factoryPopover;

  $scope.openPopovers = {
    sendEmail: false,
  };

  $scope.breakpoints = {
    0: "top-right",
    300: "top",
  };

  const onResize = () => {
    $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
  };

  this.$onInit = () => $window.addEventListener("resize", onResize);
  this.$onDestroy = () => $window.addEventListener("resize", onResize);

  const backToList = () => {
    if (lodash.isEmpty($scope.stateFromList)) {
      $state.go("employees.list", {
        page: 0,
        limit: 10,
        checkFromList: $scope.checkFromList,
      });
    } else {
      $state.go("employees.list", {
        page: $scope.stateFromList.page,
        limit: $scope.stateFromList.limit,
        outletsIds: $scope.stateFromList.outletsIds,
        checkFromList: $scope.checkFromList,
      });
    }
  }

  // Recaptcha methods
  const setResponse = response => {
    recaptchaInfo.response = response;
    recaptchaInfo.verify = true;
  }

  const setWidgetId = widgetId => {
    recaptchaInfo.widgetId = widgetId;
  }

  const reloadCaptcha = () => {
    vcRecaptchaService.reload(recaptchaInfo.widgetId);
    recaptchaInfo.verify = false;
  }
  // Recaptcha methods

  $scope.onResendEmail = () => {
    const resendEmail = (recaptchaResponse) => {
      recaptchaInfo.verify = false;
      getEmployees.resendInvitationEmail({merchantId: $scope.employee.merchantId, email: $scope.employee.email, recaptchaResponse}).then((res) => {
        if (res?.result === "ok") {
          serviceDialog.close(recaptchaDialog);
          serviceToast.show("EMPLOYEE.INVITE_SENT", "success");
        } else if (res?.result === "error") {
          switch (res.message) {
            case "attempt_limit_exceeded":
              serviceDialog.close(recaptchaDialog);
              serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                content: $translate.instant("EMPLOYEE.TOO_MANY_ATTEMPTS"),
                buttons: [
                  {
                    class: 'primary',
                    text: 'ON_OK'
                  }
                ]
              });
              break;
            case "recaptcha_not_verified":
              recaptchaInfo.backendVerificationFailed = true;
              reloadCaptcha();
              break;
            default:
              reloadCaptcha();
          }
        }
      }, () => {
        reloadCaptcha();
      });
    }

    const recaptchaDialog = {
      templateUrl: 'dashboard-page/templates/dialog/dialog-recaptcha.html',
      title: 'ACCOUNT.B2_SERVICE_DIALOG.B2_RECAPTCHA_TITLE',
      recaptchaInfo: recaptchaInfo,
      setResponse: setResponse,
      setWidgetId: setWidgetId,
      cbExpiration: reloadCaptcha,
      submit: resendEmail,
      lang: serviceReCaptcha.getLangForReCaptcha($scope.profile.cabinetLang),
      buttons: [
        {
          text: 'ON_CANCEL'
        },
        {
          class: 'md-primary',
          text: 'CONTINUE_CONTINUE'
        }
      ]
    };

    recaptchaInfo.verify = false;
    serviceDialog.add(recaptchaDialog);
  }
}

export default {
  type: "controller",
  name: "createEditEmployeeCtrl",
  value: createEditEmployeeCtrl,
};
