createDirective.$inject = ['$parse'];

function createDirective($parse) {
    return {
        restrict: 'A',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs) {
        if (attrs.lvGetSizeMethod) {
            $parse(attrs.lvGetSizeMethod).assign(scope, function() {
                return element[0].getBoundingClientRect();
            });
        }
    }
}

export default {
    type: 'directive',
    name: 'lvGetSizeMethod',
    value: createDirective
};