/**
 * Created by sirosh on 15.03.16.
 */

getPrimeCostItem.$inject = ['$filter'];

function getPrimeCostItem($filter) {

        var currency = $filter('coinToCurrency');

        function output(val, quantity) {

            var currencyVal = currency(val, '.');


            var primeCost = parseFloat(currencyVal);
            var primeCostQuantity = parseFloat(quantity);

            if (!isNaN(primeCostQuantity) && !isNaN(primeCost)) {
                var result = primeCost * primeCostQuantity;
                return result.toFixed(2);
            }
        }

        return output;
    }

export default {
    type: 'filter',
    name: 'getPrimeCostItem',
    value: getPrimeCostItem
};