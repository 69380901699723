/**
 * Created by sirosh on 11.02.16.
 * @memberof convertPin
 * @ngdoc service
 * @name convertPin
 * @param convertPinToArray {service} - return array pin or null;
 * @this
 */

convertPin.$inject = [];
function convertPin() {

    this.convertPinToArray = convertPinToArray;

    /**
     *
     * @param pin
     * @returns {Object|Null}
     */
    function convertPinToArray(pin){
        var arrPin;
        if(pin !== null){
            arrPin = [];
            var employeePinStr = pin.toString();

            for(var i=0; i < employeePinStr.length; i++){
                arrPin.push(parseInt(employeePinStr[i]));
            }

        } else{
            arrPin = null;
        }

        return arrPin;
    }

}

export default {
    type: 'service',
    name: 'convertPin',
    value: convertPin
};