import angular from 'angular';

createClass.$inject = ['TableViewColumnBase'];
function createClass(TableViewColumnBase) {
    return class TableViewColumnAction extends TableViewColumnBase {
        constructor(title, actionIcon, actionCb, options) {
            super(title, options);
            this.actionIcon = actionIcon;
            this.actionCb = actionCb;
        }

        getBaseElement() {
            return angular.element('<md-icon>');;
        }

        addClass(element) {
            super.addClass(element);
            element.addClass('pointer');

        }

        setAttrs(element) {
            super.setAttrs(element);
            element.attr('md-svg-src', this.actionIcon);
            element.attr('ng-click', 'ctrl.column.actionCb(ctrl.item)');
        }
    }
}

export default {
    type: 'factory',
    name: 'TableViewColumnAction',
    value: createClass
};