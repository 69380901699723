/**
 * Created by kseniya.yarosh on 21.11.16.
 * @memberof editoutletCtrl
 * @ngdoc controller
 * @name editoutletCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter service
 * @requires $state {service} UI Router service
 * @requires $stateParams {service} UI Router service
 * @requires getOutletsList {service} Get outlets list / operations with outlets
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 // * @requires $outlets {service} Get outlets list before load page
 // * @requires $outlet {service} Get a certain outlet before load page
 */

import angular from 'angular'
import { isEqual } from 'lodash';

editoutletCtrl.$inject = [
    "$scope",
    "$filter",
    "$state",
    "$stateParams",
    "getOutletsList",
    "$getOutlet",
    "serviceDialog",
    "serviceToast",
    'promiseOperation',
    "$profile",
    'permissions',
    'localizationUtil',
    'lvCountriesMultiLang',
    'countriesStoreExclude',
    'dialogs',
    "factoryInvoicing",
    "factoryLinks"
];

function editoutletCtrl(
    $scope,
    $filter,
    $state,
    $stateParams,
    getOutletsList,
    $getOutlet,
    serviceDialog,
    serviceToast,
    promiseOperation,
    $profile,
    permissions,
    localizationUtil,
    lvCountriesMultiLang,
    countriesStoreExclude,
    dialogs,
    factoryInvoicing,
    factoryLinks
) {
        let vm          = this,
            translate   = $filter('translate'),
            outlet;


        const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

        vm.outlet       = $getOutlet;
        outlet          = angular.copy($getOutlet);


        vm.countries = lvCountriesMultiLang.filter(country => {
           return !countriesStoreExclude.includes(country.code);
        })
        vm.notSaved     = false;
        vm.showVatNumber = vm.outlet.useVatNumber;
        vm.showVatNumberHint = vm.showVatNumber && $profile.country === "sa";
        let country = vm.outlet.country || $profile.country;
        vm.outletParams = $stateParams.outletParams || {};
        vm.outletParams = {
            outletId:       vm.outlet.id,
            name:           vm.outlet.name,
            address:        vm.outlet.address,
            city:           vm.outlet.city,
            region:         vm.outlet.region,
            postcode:       vm.outlet.postcode,
            buildingNumber: vm.outlet.buildingNumber,
            district:       vm.outlet.district,
            country:        country,
            phone:          vm.outlet.phone,
            comment:        vm.outlet.comment,
            location:       vm.outlet.location,
            receiptFooter:  vm.outlet.receiptFooter,
            receiptHeader:  vm.outlet.receiptHeader,
            receiptLang:    vm.outlet.receiptLang,
            registersCount: vm.outlet.registersCount,
            schedule:       vm.outlet.schedule,
            vatNumber:      vm.outlet.vatNumber,
            enableEInvoicing: vm.outlet.enableEInvoicing,
            organizationUnitName: vm.outlet.organizationUnitName,
            organizationName: vm.outlet.organizationName,
            transactionType: vm.outlet.transactionType,
            industry: vm.outlet.industry,
        };

        vm.cityLabel = localizationUtil.getCityLabel(country);
        vm.provinceLabel = localizationUtil.getProvinceLabel(country);
        vm.postalLabel = localizationUtil.getPostalCodeLabel(country);
        vm.uniqueName = true;
        vm.progress = false;
        vm.onDeleteStore = onDeleteStore;
        vm.onCancelStore = onCancelStore;
        vm.changeLabelsNames = changeLabelsNames;
        
        vm.invoicing = $profile.features.eInvoicing;
        vm.isSaudi = $profile.country === 'sa';
        
        vm.isEdit = true;
        vm.externalButtons = vm.invoicing && vm.isSaudi;
        vm.inlineButtons = !vm.externalButtons;
 
        $scope.helpLink = factoryLinks.help($profile, "help/settings")

        /**
         * @ngdoc function
         * @name onDeleteStore
         * @description
         * Checks quantity of stores and whether any cash register is joined to the store. If quantity's more than 1 and if there's any cash registers bound to the store, deletes the store passing its id to a server
         */

        function onDeleteStore () {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('MULTISHOP.STORES.DELETE_STORE_TITLE'),
                content: translate('MULTISHOP.STORES.DELETE_STORE_TEXT'),
                buttons: [
                    {
                        id: 'store_edit-delete_dialog-cancel-button',
                        text: 'ON_CANCEL'
                    },
                    {
                        id: 'store_edit-delete_dialog-confirm-button',
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: delOutlet
                    }
                ]
            });
        }

        function changeLabelsNames(countryCode) {
            vm.cityLabel = localizationUtil.getCityLabel(countryCode);
            vm.provinceLabel = localizationUtil.getProvinceLabel(countryCode);
            vm.postalLabel = localizationUtil.getPostalCodeLabel(countryCode);
        }

        function delOutlet() {
            let id = [vm.outlet.id],
                deletedMessage = "MULTISHOP.STORES.STORE_DELETED";

            if (vm.outletParams.registersCount > 0) {
                serviceDialog.add({
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: translate("MULTISHOP.STORES.CANNOT_DELETE"),
                    content: translate('MULTISHOP.STORES.CANNOT_DELETE_IF_REGISTER_EXISTS'),
                    buttons: [
                        {
                            class: 'primary',
                            text: 'ON_OK',
                            action: function () {
                            }
                        }
                    ]
                });
            } else {
                getOutletsList.delOutlet({outletsIds: id}).then(function (res) {
                    if (res.result === "ok") {
                        permissions.updatePermissions(true);
                        serviceToast.show(deletedMessage, 'success');
                        offStateChangeStart();
                        $state.go('settings.outlet');
                    }
                    else if (res.result === "error") {
                        if (res.message === "temporary_unavailable") {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-billing-base.html',
                                content1: translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ERROR_CHARGEBEE'),
                                buttons: [{
                                    class: 'primary',
                                    text: 'ON_OK',
                                    action: function () {
                                        return true;
                                    }
                                }]
                            });
                        }
                        if (res.message === "at_least_one_outlet_must_exist") {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                title: translate("MULTISHOP.STORES.DELETION_IS_PROHIBITED"),
                                content: translate('MULTISHOP.STORES.MUST_BE_ONE_STORE'),
                                buttons: [
                                    {
                                        class: 'primary',
                                        text: 'ON_OK',
                                        action: function () {
                                        }
                                    }
                                ]
                            });
                        }
                        if (res.message === "outlets_data_out_of_date") {
                            dialogs.itemDeletedEarlier()
                                .then(() => {
                                    offStateChangeStart();
                                    $state.go('settings.outlet')
                                });
                        }
                    }
                    else if (res.result === "outlet_has_not_closed_order" && Array.isArray(res.type)) {
                        let contents;
                        if ((res.type.indexOf("TRANSFER_ORDER") !== -1) && (res.type.indexOf("PURCHASE_ORDER") !== -1) && (res.type.indexOf("INVENTORY_COUNT_ORDER") !== -1)) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_ORDER'),
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_TRANSFER_ORDER'),
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_COUNT')
                            ];
                        } else if ((res.type.indexOf("TRANSFER_ORDER") !== -1) && (res.type.indexOf("PURCHASE_ORDER") !== -1)) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_ORDER'),
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_TRANSFER_ORDER')
                            ];
                        } else if ((res.type.indexOf("TRANSFER_ORDER") !== -1) && (res.type.indexOf("INVENTORY_COUNT_ORDER") !== -1)) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_TRANSFER_ORDER'),
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_COUNT')
                            ];
                        } else if ((res.type.indexOf("PURCHASE_ORDER") !== -1) && (res.type.indexOf("INVENTORY_COUNT_ORDER") !== -1)) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_ORDER'),
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_COUNT')
                            ];
                        } else if (res.type.indexOf("TRANSFER_ORDER") !== -1) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_TRANSFER_ORDER')
                            ];
                        } else if (res.type.indexOf("PURCHASE_ORDER") !== -1) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_ORDER')
                            ];
                        } else if (res.type.indexOf("INVENTORY_COUNT_ORDER") !== -1) {
                            contents = [
                                translate('MULTISHOP.STORES.CANNOT_DELETE_NOT_CLOSED_COUNT')
                            ];
                        }
                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base-multiline.html',
                            title: translate("MULTISHOP.STORES.CANNOT_DELETE"),
                            contents: contents,
                            buttons: [
                                {
                                    class: 'md-primary',
                                    text: 'ON_OK',
                                    action: function () {
                                        return true;
                                    }
                                }
                            ]
                        });
                    }
                })
            }
        }

        /**
         * @ngdoc function
         * @name onSaveStore
         * @description
         * Checks if all form input fields are valid and saves edited data of the store passing its id and other necessary parameters to a server
         */

        function onSaveStore() {
            let savedMessage = 'MULTISHOP.STORES.STORE_EDITED';
            let phoneWithoutPlus;

            if (vm.outletParams.phone) {
                phoneWithoutPlus = vm.outletParams.phone.replace(/\+/, '');
            } else {
                phoneWithoutPlus = null;
            }

            if (!vm.outletParams.address || vm.outletParams.address === "") {
                vm.outletParams.location = '0.0;0.0';
            }

            //if every field must be required
            if ((vm.outletParams.name && vm.outletParams.name.length < 41) && (!vm.outletParams.comment
              || (vm.outletParams.comment && vm.outletParams.comment.length < 129))
              && (!phoneWithoutPlus || (phoneWithoutPlus && phoneWithoutPlus.length >= 6 && phoneWithoutPlus.length <= 20))
              && (!vm.outletParams.address || (vm.outletParams.address && vm.outletParams.address.length < 256)) ){
                vm.notSaved = true;
                vm.progress = true;
                getOutletsList.editOutlet(vm.outletParams).then(function (res) {
                    if (res.result === 'error') {
                        if (res.message === 'outlet_with_name_exists') {
                            vm.uniqueName = false;
                            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                            vm.progress = false;
                        } else if (res.message === 'outlet_not_found') {
                            dialogs.itemDeletedEarlier()
                                .then(() => {
                                    offStateChangeStart();
                                    $state.go('settings.outlet')
                                        .finally(() => {
                                            vm.progress = false;
                                        })
                                });
                        } else {
                            vm.progress = false;
                        }

                        vm.notSaved = false;

                    } else if (res.result === "ok") {
                        offStateChangeStart();
                        serviceToast.show(savedMessage, 'success');
                        $state.go('settings.outlet')
                            .finally(() => {
                                vm.progress = false;
                            })
                    } else {
                        vm.progress = false;
                    }
                }).catch(() => {
                    vm.progress = false;
                });
            }
        }

        function isChanged() {
            if(vm.outlet.name == null || vm.outlet.address == null || vm.outlet.phone == null || vm.outlet.comment == null){
                for(let property in vm.outletParams){
                    if(vm.outletParams.hasOwnProperty(property)) {
                        if ((property == 'name' ||
                            property == 'address' ||
                            property == 'phone' ||
                            property == 'comment' ||
                            property == 'city' ||
                            property == 'region' ||
                            property == 'postcode' ||
                            property == 'buildingNumber' ||
                            property == 'district' ||
                            property == 'country')) {
                            if (vm.outletParams[property] == "") {
                                vm.outletParams[property] = null;
                            }
                            if (outlet[property] == "") {
                                outlet[property] = null;
                            }
                        }
                    }
                }
            }

            return [
                "name",
                "address",
                "phone",
                "comment",
                "city",
                "region",
                "postcode",
                "buildingNumber",
                "district",
                "enableEInvoicing",
                "organizationUnitName",
                "organizationName",
                "transactionType",
                "industry",
            ].some((k) =>outlet[k] !== vm.outletParams[k]);
        }

        $scope.$on('$destroy', function(){
            offStateChangeStart();
        });

        /**
         * @ngdoc function
         * @name onCancelStore
         * @description
         * Gets a user back to previous state - settings
         */

        function onCancelStore() {
            $state.go('settings.outlet');
        }

  $scope.features = $profile.features;

  $scope.invoicingAuthorized = vm.outletParams.enableEInvoicing;
  $scope.hasNumericInvoicingUnit = factoryInvoicing.hasNumericInvoicingUnit;

  $scope.onToggleInvoicing = () => {
    console.log(vm.outletParams.transactionType)
    if (vm.outletParams.enableEInvoicing && !vm.outletParams.transactionType) {
        vm.outletParams.transactionType = "B2C"
    }
  };

  $scope.onSubmit = () => {
    if ($scope.outletForm.$invalid) {
        $scope.outletForm.focusFirstInvalidControl();
        serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
        return;
    }

    if (vm.outletParams.enableEInvoicing && !$scope.invoicingAuthorized) {
      serviceDialog.add({
        templateUrl:
          "dashboard-page/templates/dialog/dialog-enable-invoicing.html",
        buttons: [
          {
            class: "primary",
            text: "ON_CANCEL",
            action: () => {
              vm.outletParams.enableEInvoicing = false;
            },
          },
          {
            class: "primary l-margin-left-8-i",
            text: "ON_ENABLE",
            action: () => {
              $scope.invoicingAuthorized = true;
              onSaveStore();
            },
          },
        ],
      });
    } else {
      onSaveStore();
    }
  };
}

export default {
    type: 'controller',
    name: 'editoutletCtrl',
    value: editoutletCtrl
};
