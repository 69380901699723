/**
 * Created by sirosh on 25.03.16.
 * @requires promiseOperation {service} - promise operations factory
 * @returns {Promise} getReceiptAttributes - get reciept attributes
 * @returns {Promise} setReceiptAttributes - set reciept attributes
 */

import angular from 'angular';

getReceipt.$inject = ['$q', '$http', 'promiseOperation', 'factoryForms'];

function getReceipt($q, $http, promiseOperation, factoryForms) {


    return {
        getReceiptAttributes: getReceiptAttributes,
        setReceiptAttributes: setReceiptAttributes,
        getShortOutlets: getShortOutlets
    };

    /**
     * Success get receipt attributes handler
     * @param {Object} response
     * @returns {{receiptHeader: String, receiptFooter: String, lang: String }}
     */
    function successGetReceiptAttributes(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                var dataToSend = {};
                for(var key in response.data) {
                    if(key !== 'result') {
                        dataToSend[key] = response.data[key];
                    }
                }
                return dataToSend;
            }
        }
    }

    /**
     * Return receipt attributes promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getReceiptAttributes(params) {
        if(params.outletId == undefined){
            params.outletId = null;
        }
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getReceiptAttributes',
                params,
                successGetReceiptAttributes
            );
    }

    /**
     * Save receipt attributes promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function setReceiptAttributes(params) {
        const body = {
            store_id: params.outletId,
            emailed_receipt_deleted: params.emailLogoDeleted,
            printed_receipt_deleted: params.printLogoDeleted,
            header: params.receiptHeader,
            footer: params.receiptFooter,
            show_customer_info: params.includeCustomerInfo,
            show_comments: params.includeNotes,
            receipt_language: params.lang
        }
        return $q(function (resolve, reject) {
            $http
                .post(
                    '/data/ownercab/set-receipt-attributes',
                    formatReceiptAttributesForm(body, params.emailLogoBlob, params.printLogoBlob),
                    {headers: factoryForms.formDataHeaders}
                ).then(function (d) {
                resolve(d.data);
                }, function (error) {
                    factoryErrorAlert.onError(error);
                    reject(error);
                });
        });
    }

// получаем краткий список торговых точек
    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getshortoutlets',
                params,
                successGetShortOutlets
            );
    }

    function formatReceiptAttributesForm(body, imageEmail, imagePrinted) {
        const form = new FormData();
        form.append("body", JSON.stringify(body));
        form.append("emailed_receipt", imageEmail ?? null);
        form.append("printed_receipt", imagePrinted ?? null);
        return form;
    }
// получаем краткий список торговых точек


}

export default {
    type: 'factory',
    name: 'getReceipt',
    value: getReceipt
};