/*** Created by Alex 23.11.16 ***/

/**
* Created by Alex 23.11.16
* @memberof cashRegisterCtrl
* @ngdoc controller
* @name cashRegisterCtrl
* @requires $scope {service} controllers scope
* @requires $filter {service} filter servise
* @requires $state {service} UI Router servise
* @requires $stateParams {service} UI Router servise
* @requires serviceDialog {service} Add modal dialog
* @requires serviceToast {service} Add toast

* @requires getCashRegister {service} POST operation with CashRegister
* @requires $cashRegisterList {service} Get cashRegister list before load page
* @requires $shortOutlets {service} Get shortOutlets list before load page
*/

import angular from 'angular';

cashRegisterCtrl.$inject = [
    "$scope",
    "$filter",
    "$state",
    "$stateParams",
    "serviceDialog",
    "serviceToast",
    "getCashRegister",
    "$cashRegisterList",
    "$shortOutlets",
    "$profile",
    "siteLangCode",
    '$translate',
];

function cashRegisterCtrl(
    $scope,
    $filter,
    $state,
    $stateParams,
    serviceDialog,
    serviceToast,
    getCashRegister,
    $cashRegisterList,
    $shortOutlets,
    $profile,
    siteLangCode,
    $translate,
){
    // блок инициализации данных
    var translate               = $filter('translate');
    $scope.editTableState       = 'editcashregister';
    $scope.cashRegisters        = $cashRegisterList;
    $scope.shortOutlets         = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.delete               = false;
    $scope.cashRegistersSelect  = [];
    $scope.allcashRegister      = {};
    $scope.selectOutlet         = {id: $stateParams.outletId ? +$stateParams.outletId : null};
    $scope.selectOutletId       = $scope.selectOutlet.id;
    $scope.accountInBlock       = $profile.billingInfo.billingStatus === "BLOCKED";
    // блок инициализации данных


    $scope.$watch('selectOutlet', function(newval, oldval){
        // console.log("newval = ", newval);
        // console.log("oldval = ", oldval);
        if (oldval != newval) {
            // console.log("$scope.selectOutlet.id = ", $scope.selectOutlet.id);
            $state.go('settings.cashregister', {outletId: newval.id}).then(function() {
                $scope.selectOutletId = newval.id;
                // console.log("newval-2 = ", newval);
                // console.log("oldval-2 = ", oldval);
            });
        }
    }, true);



    for(var i = 0; i < $scope.cashRegisters.length; i++){
        $scope.cashRegisters[i]["_select"] = false;
    }

    $scope.$watch('cashRegisters', function(newVal){

        for(var j = 0; j < newVal.length; j++){
            if(newVal[j]._select === true){
                $scope.cashRegistersSelect.push(newVal[j]._select);
            }
        }
        if($scope.cashRegistersSelect.length > 0){
            $scope.delete = true;
        }else{
            $scope.delete = false;
        }

        if($scope.cashRegistersSelect.length === $scope.cashRegisters.length ){
            $scope.allcashRegister._select = true;
        }else{
            $scope.allcashRegister._select = false;
        }

        $scope.cashRegistersSelect = [];

    }, true);



/**
* @ngdoc function
* @name changeAll
* @description
* select all cashRegisters for removing
* @returns {cashRegisters}
*/
    $scope.changeAll = function(){
        if($scope.allcashRegister._select === true){
            // console.log("$scope.cashRegisters = ", $scope.cashRegisters);
            for(var k = 0; k < $scope.cashRegisters.length; k++){
                $scope.cashRegisters[k]["_select"] = true;
            }
            $scope.allcashRegister._select = true;
        }else{
            $scope.allcashRegister._select = false;
            for(var m = 0; m < $scope.cashRegisters.length; m++){
                $scope.cashRegisters[m]["_select"] = false;
            }
        }
    };



/**
* @ngdoc function
* @name onAdd
* @description
* add new cashRegister
* @returns $state.go('createcashregister')
*/
    $scope.onAdd = function(){
        // console.log("$scope.cashRegisters = ", $scope.cashRegisters);
        // console.log("$scope.cashRegisters.length = ", $scope.cashRegisters.length);
        if($scope.cashRegisters.length > 999){
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('NOTIFICATION'),
                content: translate('MULTISHOP.CASH_REGISTER.ONLY_THOUSAND_CASH_REGISTERS'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else{
            $state.go('createcashregister', $stateParams, {});
        }
    };



    $scope.backToBilling = function() {
        $state.go('settings.account', {} );
    }








/**
* @ngdoc function
* @name onDel
* @description
* Send id cashRegister/cashRegisters to a server for delete this cashRegister/cashRegisters
* @param {Object} params {"cashRegistersIds":"777"}
* @returns $state.go('settings.cashregister')
*/
    $scope.onDel = function(){
        // console.log("$scope.cashRegisters = ", $scope.cashRegisters);
        $scope.cashRegistersIds = [];
        for(var i = 0; i < $scope.cashRegisters.length; i++){
            if($scope.cashRegisters[i]._select === true){
                $scope.cashRegistersIds.push($scope.cashRegisters[i].id);
            }
        }

        if($scope.cashRegistersIds.length === 1){
            $scope.msgTitle =  translate('MULTISHOP.CASH_REGISTER.DELETE_CASH_REGISTER');
            $scope.tostTitle = 'MULTISHOP.CASH_REGISTER.TOSTS.DELETED_CASH_REGISTER';
            $scope.contentNoWares = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTER');
            $scope.contentNoWaresNext = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTER_NEXT');
        }else {
            $scope.msgTitle = translate('MULTISHOP.CASH_REGISTER.DELETE_CASH_REGISTERS');
            $scope.tostTitle = 'MULTISHOP.CASH_REGISTER.TOSTS.DELETED_CASH_REGISTERS';
            $scope.contentNoWares = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTERS');
            $scope.contentNoWaresNext = translate('MULTISHOP.CASH_REGISTER.DELETE_MESSAGE_CASH_REGISTERS_NEXT');
        }

        $scope.onSendData = function(){
            getCashRegister.deleteCashRegister({cashRegistersIds: $scope.cashRegistersIds}).then(function(res){
                if(res.result === "ok"){
                    serviceToast.show($scope.tostTitle, 'success');
                    $state.go('settings.cashregister', $stateParams, {
                        reload: true
                    });
                }
            });
        };

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-del-cash-register.html',
            title: $scope.msgTitle,
            content: $scope.contentNoWares,
            contentNext: $scope.contentNoWaresNext,
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: $scope.onSendData
                }
            ]
        });
    };


    //-- Task #25633 Update stubs for empty states
    emptyStateLink();
    $scope.emptyStateLink = emptyStateLink;

    function emptyStateLink() {
        let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
        // url lang where we have info
        let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "it", "pl", "gr", "zh"];
        //emptyStateCategory Learn more
        $scope.emptyStateLearnMorePOS = urlLang.indexOf(siteUrlLang) !== -1
            ? "https://help.loyverse.com/" + siteUrlLang + "/help/setting-your-shop-loyverse-back?utm_source=Back%20Office&utm_medium=POS_devices"
            : "https://help.loyverse.com/help/setting-your-shop-loyverse-back?utm_source=Back%20Office&utm_medium=POS_devices";
    }
    //-- END Task #25633 Update stubs for empty states

    $scope.features = $profile.features;
}

export default {
    type: 'controller',
    name: 'cashRegisterCtrl',
    value: cashRegisterCtrl
};
