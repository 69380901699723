/**
 * Created by mars on 12/10/15.
 */

firstVisit.$inject = ['$timeout', '$q'];
function firstVisit($timeout, $q) {
	return $q( function (resolve, reject) {
		$timeout(function(){
			resolve();
		}, 2000)
	} );
}

export default {
	type: 'factory',
	name: 'firstVisit',
	value: firstVisit
};