/**
 * Created by Nikitin Oleksii on 08/22/23.
 * Utils for feature settings
 */

import angular from "angular";

lvFeatureSettingsUtils.$inject = ['lvMixpanel'];
function lvFeatureSettingsUtils(lvMixpanel) {

    this.buildSettingModel = buildSettingModel;

    this.trackFeatureSettingsEdit = trackFeatureSettingsEdit;

    function buildSettingModel(profile) {
        return {
            shiftsEnabled: profile.shiftsEnabled,
            timeCardEnabled: profile.timeCardEnabled,
            useOpenedReceipts: profile.useOpenedReceipts,
            fiscalEnable: profile.fiscalEnable,
            useKitchenPrinter: profile.useKitchenPrinter,
            useCustomerDisplay: profile.useCustomerDisplay,
            useDiningOptions: profile.useDiningOptions,
            notifyCritical: profile.notifyCritical,
            posInventoryAlertEnabled: profile.posInventoryAlertEnabled,
            weightBarcode: profile.weightBarcode
        };
    }

    function trackFeatureSettingsEdit(prevSettings, newSettings, isOnboarding) {

        const page = isOnboarding ? 'Onboarding dialog' : 'Settings page';

        if (prevSettings.useOpenedReceipts !== newSettings.useOpenedReceipts) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.useOpenedReceipts ? "Settings/Open tickets turned on" : 'Settings/Open tickets turned off',
                "Page": page
            });
        }

        if (prevSettings.shiftsEnabled !== newSettings.shiftsEnabled) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.shiftsEnabled ? "Settings/Shifts turned on" : 'Settings/Shifts turned off',
                "Page": page
            });
        }

        if (prevSettings.notifyCritical !== newSettings.notifyCritical) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.notifyCritical ? "Settings/Low stock notifications turned on" : 'Settings/Low stock notifications turned off',
                "Page": page
            });
        }

        if (prevSettings.useKitchenPrinter !== newSettings.useKitchenPrinter) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.useKitchenPrinter ? "Settings/Kitchen printers turned on" : 'Settings/Kitchen printers turned off',
                "Page": page
            });
        }

        if (prevSettings.useCustomerDisplay !== newSettings.useCustomerDisplay) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.useCustomerDisplay ? "Settings/Customer displays turned on" : 'Settings/Customer displays turned off',
                "Page": page
            });
        }

        if (prevSettings.timeCardEnabled !== newSettings.timeCardEnabled) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.timeCardEnabled ? "Settings/Time clock turned on" : 'Settings/Time clock turned off',
                "Page": page
            });
        }

        if (prevSettings.useDiningOptions !== newSettings.useDiningOptions) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.useDiningOptions ? "Settings/Dining options turned on" : 'Settings/Dining options turned off',
                "Page": page
            });
        }

        if (prevSettings.posInventoryAlertEnabled !== newSettings.posInventoryAlertEnabled) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.posInventoryAlertEnabled ? "Settings/Negative inventory alerts turned on" : 'Settings/Negative inventory alerts turned off',
                "Page": page
            });
        }

        if (prevSettings.weightBarcode !== newSettings.weightBarcode) {
            lvMixpanel.track('Edit setting', {
                "Property": newSettings.weightBarcode ? "Settings/Weight embedded barcodes turned on" : 'Settings/Weight embedded barcodes turned off',
                "Page": page
            });
        }
    }
}

export default {
    type: 'service',
    name: 'lvFeatureSettingsUtils',
    value: lvFeatureSettingsUtils
};
