import angular from 'angular';

factoryTransfer.$inject = ['promiseOperation', '$http', 'factoryErrorAlert', 'serviceFile'];

function factoryTransfer(promiseOperation, $http, factoryErrorAlert, serviceFile){

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getshortoutlets',
            params,
            successGetShortOutlets
        );
    }

    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function getTransferOrderList(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getTransferOrderList',
            params,
            successGetTransferOrderList
        );
    }

    function successGetTransferOrderList(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getTransferOrderDetail(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getTransferOrderDetail',
            params,
            successGetTransferOrderDetail
        );
    }

    function successGetTransferOrderDetail(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function deleteTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/deleteTransferOrder',
            params,
            successDeleteTransferOrder
        );
    }

    function successDeleteTransferOrder(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function receiveTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/receiveTransferOrder',
            params,
            successRecieveTransferOrder
        );
    }

    function approveTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/approveTransferOrder',
                params,
                successRecieveTransferOrder
            );    }

    function successRecieveTransferOrder(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function searchItemsForTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/searchItemsForTransferOrder',
                params,
                successSearchItemsForTransferOrder
            );
    }

    function successSearchItemsForTransferOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function createTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createTransferOrder',
                params,
                successCreateTransferOrder
            );
    }

    function successCreateTransferOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function editTransferOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editTransferOrder',
                params,
                successEditTransferOrder
            );
    }

    function successEditTransferOrder(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function getTransferOrderItemsForOutlet(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getTransferOrderItemsForOutlet',
                params,
                successGetTransferOrderItemsForOutlet
            );
    }

    function successGetTransferOrderItemsForOutlet(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    const getTransferOrderPDF = (data) => {
        return $http.get(`/data/ownercab/downloadTransferPdf/${data.orderId}?tzOffset=${data.tzOffset}`, { responseType: 'arraybuffer' }).then(
            (res) => {
                const contentType = res.headers("Content-Type");
                if (contentType === "application/pdf") {
                    serviceFile.saveFile(contentType, res.headers("Content-Disposition"), res.data);
                    return "";
                } else if (contentType === "application/json") {
                    const jsonString = new TextDecoder().decode(res.data);
                    return JSON.parse(jsonString);
                } else {
                    factoryErrorAlert.onError(res);
                }
            },
            (err) => {
                factoryErrorAlert.onError(err);
            }
        )
    }

    const getTransferOrderCSV = (data) => {
        return $http.get(`/data/ownercab/downloadTransferCsv/${data.orderId}?tzOffset=${data.tzOffset}`, { responseType: 'arraybuffer' }).then(
            (res) => {
                const contentType = res.headers("Content-Type");
                if (contentType === "text/csv") {
                    serviceFile.saveFile(contentType, res.headers("Content-Disposition"), res.data);
                    return "";
                } else if (contentType === "application/json") {
                    const jsonString = new TextDecoder().decode(res.data);
                    return JSON.parse(jsonString);
                } else {
                    factoryErrorAlert.onError(res);
                }
            },
            (err) => {
                factoryErrorAlert.onError(err);
            }
        )
    }

    return {
        getShortOutlets: getShortOutlets,
        getTransferOrderList: getTransferOrderList,
        getTransferOrderDetail: getTransferOrderDetail,
        deleteTransferOrder: deleteTransferOrder,
        receiveTransferOrder: receiveTransferOrder,
        approveTransferOrder: approveTransferOrder,
        searchItemsForTransferOrder: searchItemsForTransferOrder,
        createTransferOrder: createTransferOrder,
        editTransferOrder: editTransferOrder,
        getTransferOrderItemsForOutlet: getTransferOrderItemsForOutlet,
        getTransferOrderPDF: getTransferOrderPDF,
        getTransferOrderCSV: getTransferOrderCSV
    }
}

export default {
    type: 'factory',
    name: 'factoryTransfer',
    value: factoryTransfer
};
