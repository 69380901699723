/**
 * Created by Aleks on 28/02/16.
 */

const lvCountriesMultiLang = [
        { code:"af", name:"AF" },
        { code:"ax", name:"AX" },
        { code:"al", name:"AL" },
        { code:"dz", name:"DZ" },
        { code:"as", name:"AS" },
        { code:"ad", name:"AD" },
        { code:"ao", name:"AO" },
        { code:"ai", name:"AI" },
        { code:"ag", name:"AG" },
        { code:"ar", name:"AR" },
        { code:"am", name:"AM" },
        { code:"aw", name:"AW" },
        { code:"au", name:"AU" },
        { code:"at", name:"AT" },
        { code:"az", name:"AZ" },
        { code:"bs", name:"BS" },
        { code:"bh", name:"BH" },
        { code:"bd", name:"BD" },
        { code:"bb", name:"BB" },
        { code:"by", name:"BY" },
        { code:"be", name:"BE" },
        { code:"bz", name:"BZ" },
        { code:"bj", name:"BJ" },
        { code:"bm", name:"BM" },
        { code:"bt", name:"BT" },
        { code:"bo", name:"BO" },
        { code:"ba", name:"BA" },
        { code:"bw", name:"BW" },
        { code:"br", name:"BR" },
        { code:"io", name:"IO" },
        { code:"vg", name:"VG" },
        { code:"bn", name:"BN" },
        { code:"bg", name:"BG" },
        { code:"bf", name:"BF" },
        { code:"bi", name:"BI" },
        { code:"kh", name:"KH" },
        { code:"cm", name:"CM" },
        { code:"ca", name:"CA" },
        { code:"ic", name:"IC" },
        { code:"cv", name:"CV" },
        { code:"ky", name:"KY" },
        { code:"cf", name:"CF" },
        { code:"td", name:"TD" },
        { code:"cl", name:"CL" },
        { code:"cn", name:"CN" },
        { code:"cx", name:"CX" },
        { code:"cc", name:"CC" },
        { code:"co", name:"CO" },
        { code:"km", name:"KM" },
        { code:"cg", name:"CG" },
        { code:"cd", name:"CD" },
        { code:"ck", name:"CK" },
        { code:"cr", name:"CR" },
        { code:"ci", name:"CI" },
        { code:"hr", name:"HR" },
        { code:"cu", name:"CU" },
        { code:"cw", name:"CW" },
        { code:"cy", name:"CY" },
        { code:"cz", name:"CZ" },
        { code:"dk", name:"DK" },
        { code:"dj", name:"DJ" },
        { code:"dm", name:"DM" },
        { code:"do", name:"DO" },
        { code:"ec", name:"EC" },
        { code:"eg", name:"EG" },
        { code:"sv", name:"SV" },
        { code:"gq", name:"GQ" },
        { code:"er", name:"ER" },
        { code:"ee", name:"EE" },
        { code:"sz", name:"SZ" },
        { code:"et", name:"ET" },
        { code:"fk", name:"FK" },
        { code:"fo", name:"FO" },
        { code:"fj", name:"FJ" },
        { code:"fi", name:"FI" },
        { code:"fr", name:"FR" },
        { code:"gf", name:"GF" },
        { code:"pf", name:"PF" },
        { code:"ga", name:"GA" },
        { code:"gm", name:"GM" },
        { code:"ge", name:"GE" },
        { code:"de", name:"DE" },
        { code:"gh", name:"GH" },
        { code:"gi", name:"GI" },
        { code:"gr", name:"GR" },
        { code:"gl", name:"GL" },
        { code:"gd", name:"GD" },
        { code:"gp", name:"GP" },
        { code:"gu", name:"GU" },
        { code:"gt", name:"GT" },
        { code:"gg", name:"GG" },
        { code:"gn", name:"GN" },
        { code:"gw", name:"GW" },
        { code:"gy", name:"GY" },
        { code:"ht", name:"HT" },
        { code:"hn", name:"HN" },
        { code:"hk", name:"HK" },
        { code:"hu", name:"HU" },
        { code:"is", name:"IS" },
        { code:"in", name:"IN" },
        { code:"id", name:"ID" },
        { code:"ir", name:"IR" },
        { code:"iq", name:"IQ" },
        { code:"ie", name:"IE" },
        { code:"im", name:"IM" },
        { code:"il", name:"IL" },
        { code:"it", name:"IT" },
        { code:"jm", name:"JM" },
        { code:"jp", name:"JP" },
        { code:"je", name:"JE" },
        { code:"jo", name:"JO" },
        { code:"kz", name:"KZ" },
        { code:"ke", name:"KE" },
        { code:"ki", name:"KI" },
        { code:"xk", name:"XK" },
        { code:"kw", name:"KW" },
        { code:"kg", name:"KG" },
        { code:"la", name:"LA" },
        { code:"lv", name:"LV" },
        { code:"lb", name:"LB" },
        { code:"ls", name:"LS" },
        { code:"lr", name:"LR" },
        { code:"ly", name:"LY" },
        { code:"li", name:"LI" },
        { code:"lt", name:"LT" },
        { code:"lu", name:"LU" },
        { code:"mo", name:"MO" },
        { code:"mg", name:"MG" },
        { code:"mw", name:"MW" },
        { code:"my", name:"MY" },
        { code:"mv", name:"MV" },
        { code:"ml", name:"ML" },
        { code:"mt", name:"MT" },
        { code:"mh", name:"MH" },
        { code:"mq", name:"MQ" },
        { code:"mr", name:"MR" },
        { code:"mu", name:"MU" },
        { code:"yt", name:"YT" },
        { code:"mx", name:"MX" },
        { code:"fm", name:"FM" },
        { code:"md", name:"MD" },
        { code:"mc", name:"MC" },
        { code:"mn", name:"MN" },
        { code:"me", name:"ME" },
        { code:"ms", name:"MS" },
        { code:"ma", name:"MA" },
        { code:"mz", name:"MZ" },
        { code:"mm", name:"MM" },
        { code:"na", name:"NA" },
        { code:"nr", name:"NR" },
        { code:"np", name:"NP" },
        { code:"nl", name:"NL" },
        { code:"nc", name:"NC" },
        { code:"nz", name:"NZ" },
        { code:"ni", name:"NI" },
        { code:"ne", name:"NE" },
        { code:"ng", name:"NG" },
        { code:"nu", name:"NU" },
        { code:"nf", name:"NF" },
        { code:"mk", name:"MK" },
        { code:"mp", name:"MP" },
        { code:"no", name:"NO" },
        { code:"om", name:"OM" },
        { code:"pk", name:"PK" },
        { code:"pw", name:"PW" },
        { code:"ps", name:"PS" },
        { code:"pa", name:"PA" },
        { code:"pg", name:"PG" },
        { code:"py", name:"PY" },
        { code:"pe", name:"PE" },
        { code:"ph", name:"PH" },
        { code:"pn", name:"PN" },
        { code:"pl", name:"PL" },
        { code:"pt", name:"PT" },
        { code:"pr", name:"PR" },
        { code:"qa", name:"QA" },
        { code:"re", name:"RE" },
        { code:"ro", name:"RO" },
        { code:"ru", name:"RU" },
        { code:"rw", name:"RW" },
        { code:"ws", name:"WS" },
        { code:"sm", name:"SM" },
        { code:"st", name:"ST" },
        { code:"sa", name:"SA" },
        { code:"sn", name:"SN" },
        { code:"rs", name:"RS" },
        { code:"sc", name:"SC" },
        { code:"sl", name:"SL" },
        { code:"sg", name:"SG" },
        { code:"sx", name:"SX" },
        { code:"sk", name:"SK" },
        { code:"si", name:"SI" },
        { code:"sb", name:"SB" },
        { code:"so", name:"SO" },
        { code:"za", name:"ZA" },
        { code:"kr", name:"KR" },
        { code:"ss", name:"SS" },
        { code:"es", name:"ES" },
        { code:"lk", name:"LK" },
        { code:"bl", name:"BL" },
        { code:"sh", name:"SH" },
        { code:"kn", name:"KN" },
        { code:"lc", name:"LC" },
        { code:"mf", name:"MF" },
        { code:"pm", name:"PM" },
        { code:"vc", name:"VC" },
        { code:"sd", name:"SD" },
        { code:"sr", name:"SR" },
        { code:"sj", name:"SJ" },
        { code:"se", name:"SE" },
        { code:"ch", name:"CH" },
        { code:"sy", name:"SY" },
        { code:"tw", name:"TW" },
        { code:"tj", name:"TJ" },
        { code:"tz", name:"TZ" },
        { code:"th", name:"TH" },
        { code:"tl", name:"TL" },
        { code:"tg", name:"TG" },
        { code:"tk", name:"TK" },
        { code:"to", name:"TO" },
        { code:"tt", name:"TT" },
        { code:"tn", name:"TN" },
        { code:"tr", name:"TR" },
        { code:"tm", name:"TM" },
        { code:"tc", name:"TC" },
        { code:"tv", name:"TV" },
        { code:"um", name:"UM" },
        { code:"vi", name:"VI" },
        { code:"ug", name:"UG" },
        { code:"ua", name:"UA" },
        { code:"ae", name:"AE" },
        { code:"gb", name:"GB" },
        { code:"us", name:"US" },
        { code:"uy", name:"UY" },
        { code:"uz", name:"UZ" },
        { code:"vu", name:"VU" },
        { code:"ve", name:"VE" },
        { code:"vn", name:"VN" },
        { code:"wf", name:"WF" },
        { code:"eh", name:"EH" },
        { code:"ye", name:"YE" },
        { code:"zm", name:"ZM" },
        { code:"zw", name:"ZW" }
];

export default {
    type: 'constant',
    name: 'lvCountriesMultiLang',
    value: lvCountriesMultiLang
}