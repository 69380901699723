/**
 * Created by A.Sirosh on 13.10.2015.
 * @memberof focus
 * @ngdoc directive
 * @name focus
 * @requires $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 */

focus.$inject = ['$timeout'];
function focus($timeout) {
	return {
		restrict: 'A',
		link : function($scope, $element) {
			$timeout(function() {
				$element[0].focus();
			});
		}
	}
}

export default {
	type: 'directive',
	name: 'focus',
	value: focus
};