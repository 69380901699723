/**
 * Created by Kiskenbassker on 07.02.18
 */

import angular from 'angular';

factoryInventoryCount.$inject = [
    'promiseOperation'
];

function factoryInventoryCount (
    promiseOperation
){

    function getInventoryCountOrders(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getInventoryCountOrders',
            params,
            successGetInventoryCountOrders
        );
    }

    function successGetInventoryCountOrders(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function searchItemsForInventoryCount(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/searchItemsForInventoryCount',
                params,
                successSearchItemsForInventoryCount
            );
    }

    function successSearchItemsForInventoryCount(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function getWaresForInvCountByCategories(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getWaresForInvCountByCategories',
                params,
                successGetWaresForInvCountByCategories
            );
    }

    function successGetWaresForInvCountByCategories(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getWaresForInvCountBySuppliers(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getWaresForInvCountBySuppliers',
                params,
                successGetWaresForInvCountBySuppliers
            );
    }

    function successGetWaresForInvCountBySuppliers(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function createInventoryCountOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createInventoryCountOrder',
                params,
                successCreateInventoryCountOrder
            );
    }

    function successCreateInventoryCountOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok" || response.data.result === "error" && response.data.message === "YOU_HAVE_NO_ITEMS") {
                return response.data;
            }
        }
    }

    function editInventoryCountOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editInventoryCountOrder',
                params,
                successEditInventoryCountOrder
            );
    }

    function successEditInventoryCountOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getInventoryCountStockById(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getInventoryCountStockById',
                params,
                successGetInventoryCountStockById
            );
    }

    function successGetInventoryCountStockById(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function editInventoryCountStock(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editInventoryCountStock',
                params,
                successEditInventoryCountStock
            );
    }

    function successEditInventoryCountStock(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if(response.data.result === "error") {
                return {
                    message: response.data.message,
                    result: response.data.result
                }
            }
        }
    }

    function getInventoryCountOrderById(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getInventoryCountOrderById',
                params,
                successGetInventoryCountOrderById
            );
    }

    function successGetInventoryCountOrderById(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getSyncInventoryCount(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/syncInventoryCount',
                params,
                successSyncInventoryCount
            );
    }

    function successSyncInventoryCount(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if(response.data.result === "error") {
                return {
                    message: response.data.message,
                    result: response.data.result
                }
            }
        }
    }

    function deleteInventoryCountOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/deleteInventoryCountOrder',
                params,
                successDeleteInventoryCountOrder
            );
    }

    function successDeleteInventoryCountOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function changeInventoryCountOrderStatus(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/changeInventoryCountOrderStatus',
                params,
                successChangeInventoryCountOrderStatus
            );
    }

    function successChangeInventoryCountOrderStatus(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    return {
        getInventoryCountOrders: getInventoryCountOrders,
        searchItemsForInventoryCount: searchItemsForInventoryCount,
        getWaresForInvCountByCategories: getWaresForInvCountByCategories,
        getWaresForInvCountBySuppliers: getWaresForInvCountBySuppliers,
        createInventoryCountOrder: createInventoryCountOrder,
        editInventoryCountOrder: editInventoryCountOrder,
        deleteInventoryCountOrder: deleteInventoryCountOrder,
        getInventoryCountOrderById: getInventoryCountOrderById,
        getInventoryCountStockById: getInventoryCountStockById,
        editInventoryCountStock: editInventoryCountStock,
        getSyncInventoryCount: getSyncInventoryCount,
        changeInventoryCountOrderStatus: changeInventoryCountOrderStatus
    };
}

export default {
    type: 'factory',
    name: 'factoryInventoryCount',
    value: factoryInventoryCount
};