/*** Created by Alex on 18.11.16. ***/

kitchenCtrl.$inject = [
        "$scope",
        "$filter",
        "$state",
        "$stateParams",
        "getKitchen",
        "serviceDialog",
        '$kitchenList',
        "serviceToast",
        'dialogs'];
function kitchenCtrl (
         $scope,
         $filter,
         $state,
         $stateParams,
         getKitchen,
         serviceDialog,
         $kitchenList,
         serviceToast,
         dialogs) {

    var translate = $filter('translate');

    $scope.editTableState = 'editkitchen';
    $scope.kitchens = $kitchenList;
    $scope.waresCount = 0;
    $scope.delete = false;
    $scope.kitchenSelect = [];
    $scope.allKitchen = {};

    // console.log("$scope.kitchens = ", $scope.kitchens);

    for(var i = 0; i < $scope.kitchens.length; i++){
        $scope.kitchens[i]["_select"] = false;
    }

    $scope.$watch('kitchens', function(newVal){

        for(var j = 0; j < newVal.length; j++){
            if(newVal[j]._select === true){
                $scope.kitchenSelect.push(newVal[j]._select);
                $scope.waresCount += newVal[j].waresCount;
            }
        }
        if($scope.kitchenSelect.length > 0){
            $scope.delete = true;
        }else{
            $scope.delete = false;
        }

        if($scope.kitchenSelect.length === $scope.kitchens.length ){
            $scope.allKitchen._select = true;
        }else{
            $scope.allKitchen._select = false;
        }

        $scope.kitchenSelect = [];

    }, true);


    $scope.changeAll = function(){
        if($scope.allKitchen._select === true){
            // console.log("$scope.kitchens = ", $scope.kitchens);
            for(var k = 0; k < $scope.kitchens.length; k++){
                $scope.kitchens[k]["_select"] = true;
            }
            $scope.allKitchen._select = true;
        }else{
            $scope.allKitchen._select = false;
            for(var m = 0; m < $scope.kitchens.length; m++){
                $scope.kitchens[m]["_select"] = false;
            }
        }
    };


    $scope.onAdd = function(){
        // console.log("$scope.kitchens.length = ", $scope.kitchens.length);
        if($scope.kitchens.length > 99){
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('KITCHEN.LIMIT_KITCHEN_PRINTERS'),
                content: translate('KITCHEN.ONLY_HUNDRED_KITCHEN_PRINTERS'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else{
            $state.go('createkitchen', $stateParams, {});
        }
    };


    $scope.onDel = function(){
        // console.log("$scope.kitchens = ", $scope.kitchens);
        $scope.printerIds = [];
        for(var i = 0; i < $scope.kitchens.length; i++){
            if($scope.kitchens[i]._select === true){
                $scope.printerIds.push($scope.kitchens[i].id);
            }
        }

        if($scope.printerIds.length === 1){
            $scope.msgTitle =  translate('KITCHEN.DELETE_KITCHEN_PRINTER');
            $scope.tostTitle = 'KITCHEN.TOSTS.DELETED_KITCHEN_PRINTER';
            $scope.contentNoWares = translate('KITCHEN.DELETE_MESSAGE_KITCHEN_PRINTER');
        }else {
            $scope.msgTitle = translate('KITCHEN.DELETE_KITCHEN_PRINTERS');
            $scope.tostTitle = 'KITCHEN.TOSTS.DELETED_KITCHEN_PRINTERS';
            $scope.contentNoWares = translate('KITCHEN.DELETE_MESSAGE_KITCHEN_PRINTERS');
        }


        $scope.onSendData = function(){
            getKitchen.deleteKitchen({printerIds: $scope.printerIds}).then(function(res){
                if(res.result === "ok"){
                    serviceToast.show($scope.tostTitle, 'success');
                    refreshList();
                }
                if (res.result === 'error') {
                    if (res.message === 'kitchen_printer_deleted') {
                        dialogs.listItemDeletedEarlier().then(refreshList);
                    }
                }
            });
        };

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: $scope.msgTitle,
            content: $scope.contentNoWares,
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: $scope.onSendData
                }
            ]
        });

    };

    function refreshList() {
        $state.go('settings.kitchen', $stateParams, {
            reload: true
        });
    }
}

export default {
    type: 'controller',
    name: 'kitchenCtrl',
    value: kitchenCtrl
};