settingsControl.$inject = [
		'$scope',
		'serviceOwnerInfo',
		'serviceToast',
		'$stateParams',
		'$state',
		'serviceMenu',
		'getBillingData',
		'getOutletsList',
		'$profile',
		'$window',
		'$timeout',
		'permissions'];
function settingsControl(
	$scope,
	serviceOwnerInfo,
	serviceToast,
	$stateParams,
	$state,
	serviceMenu,
	getBillingData,
	getOutletsList,
	$profile,
	$window,
	$timeout,
	permissions
) {

	permissions.billingUpdate.observe(billingUpdated);
	permissions.profileUpdate.observe(profileUpdated);
	permissions.serviceMenuUpdate.observe(serviceMenuUpdated);

	profileUpdated($profile);
	serviceMenuUpdated();

	$scope.serviceOwnerInfo = serviceOwnerInfo;

	$scope.mobView = $window.innerWidth;
	billingUpdated(getBillingData.getAccessStatus());

	$window.addEventListener('resize', function(){
		$timeout(function() {
			$scope.mobView = $window.innerWidth;
		});
	});

	$scope.$on('revertPageForArab', function (event, val) {
		$scope.arapro = (val === 'ara' || val === 'urd' || val === 'heb');
	});

	function serviceMenuUpdated() {
		$scope.permitSettings = serviceMenu.settingsObj;
		updatePermitsAccount();
	}

	function billingUpdated(accessStatus) {
		$scope.menuIsBlocked = accessStatus.billingStatus === 'BLOCKED' || accessStatus.billingStatus === 'HOLD';
	}

	function profileUpdated(profile) {
		// кухонные принтеры
		$scope.profile = profile;
		$scope.arapro = $scope.profile.cabinetLang ==='ara' || $scope.profile.cabinetLang ==='urd' || $scope.profile.cabinetLang ==='heb';
		// кухонные принтеры
		$scope.multishopEnable = profile.multishopEnable;

		updatePermitsAccount();

		$scope.storesDevicesBlocked = ($scope.profile.billingInfo.blockExcept.indexOf("INVENTORY") === -1
				&& $scope.profile.billingInfo.blockExcept.indexOf("INTEGRATION") === -1
				&& $scope.profile.billingInfo.blockExcept.indexOf("EMPSTORE") === -1
				&& $scope.profile.billingInfo.blockExcept.indexOf("SALESHISTORY") === -1)
			|| $scope.profile.billingInfo.subscriptionPaused;
	}

	function updatePermitsAccount() {
		// КАСТЫЛЬ 01,02,18 ДЛЯ ТОГО ЧТО-БЫ НЕ ПОКАЗЫВАТЬ ПУНКТ МЕНЮ БИЛЛИНГ НЕ СМОТРЯ НА ТО ЧТО ЕСТЬ ПРАВА ДОСТУПА
		if($scope.profile && $scope.permitSettings) {
			(!$scope.profile.billingInfo.hasAccessToBilling) ? $scope.permitSettings.account = false : "";
		}
		// КАСТЫЛЬ 01,02,18 ДЛЯ ТОГО ЧТО-БЫ НЕ ПОКАЗЫВАТЬ ПУНКТ МЕНЮ БИЛЛИНГ НЕ СМОТРЯ НА ТО ЧТО ЕСТЬ ПРАВА ДОСТУПА
	}
}

export default {
	type: 'controller',
	name: 'settingsControl',
	value: settingsControl
};
