/*** Created by Alex 16.11.16 ***/

import angular from "angular";

createKitchenCtrl.$inject = ["$scope", "$state", "$stateParams",
    "$filter", "getKitchen", "serviceToast", '$categoriesList',
	'dialogs'];
function createKitchenCtrl($scope, $state, $stateParams,
						   $filter, getKitchen, serviceToast, $categoriesList,
						   dialogs) {

	var translate = $filter( 'translate' );
	$scope.clicked = true;
	$scope.categoriesList = $categoriesList;
	// console.log("$scope.categoriesList = ", $scope.categoriesList);
	$scope.newKitchenPrint =	{
								name: '',
								wareCategories: []
							};
	$scope.uniqueName = true;
	$scope.progress = false;

	let initParams = {
		name: '',
		wareCategories: []
	};
	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
	function isChanged() {
		let currentParams = {
			name: $scope.newKitchenPrint.name,
			wareCategories: $scope.newKitchenPrint.wareCategories
		};

		return !angular.equals(initParams, currentParams);
	}


	$scope.toggle = function (id, wareCategories) {
	  var idx = wareCategories.indexOf(id);
	  if (idx > -1) {
		wareCategories.splice(idx, 1);
	  }
	  else {
		wareCategories.push(id);
	  }
		// console.log("$scope.newKitchenPrint = ", $scope.newKitchenPrint);
	};

	$scope.exists = function (id, wareCategories) {
		return wareCategories.indexOf(id) > -1;
	};

	$scope.onSave = function () {
		// console.log("$scope.newKitchenPrint = ", $scope.newKitchenPrint);
		if ( $scope.clicked == false ) {
			return false;
		}

		if ($scope.createKitchen.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.createKitchen.focusFirstInvalidControl();
			return false;
		}

		function send() {
			$scope.clicked = false;
			$scope.progress = true;
			getKitchen.setKitchen( $scope.newKitchenPrint ).then( function ( res ) {
				if ( res.result === "ok" && !res.message ) {
					offStateChangeStart();
					serviceToast.show( 'KITCHEN.TOSTS.CREATE_KITCHEN_PRINTER', 'success' );
					$state.go( 'settings.kitchen', $stateParams, {
						reload: true
					} );
				} else if(res.result === "error" && res.message === 'printer with same name already exists'){
					$scope.clicked = true;
					$scope.uniqueName = false;
					serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
				}
			}, function () {
				$scope.clicked = true;
			})
			.finally(() => {
				$scope.progress = false;
			})
		}

		send();
	};

	$scope.onCancel = function () {
		$state.go( 'settings.kitchen', $stateParams, {} );
	};

}

export default {
	type: 'controller',
	name: 'createKitchenCtrl',
	value: createKitchenCtrl
};