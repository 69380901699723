/**
 * Created by mars on 5/12/16.
 */

import angular from 'angular';

factoryDiscount.$inject = ['promiseOperation', '$filter', 'serviceMinMaxValues', 'currencyFormat', '$q'];

function factoryDiscount( promiseOperation, $filter, serviceMinMaxValues, currencyFormat, $q) {
	let coinToCurrency = $filter( 'coinToCurrency' ),
		currencyToCoin = $filter( 'currencyToCoin' );

	return {
		getDiscountList: getDiscountList,
		getDiscountById: getDiscountById,
		createDiscount: createDiscount,
		getNewDiscount: getNewDiscount,
		editDiscount: editDiscount,
		delDiscount: delDiscount,
		getShortOutlets: getShortOutlets
	};

// получаем краткий список торговых точек
	function getShortOutlets(params) {
	   return promiseOperation
		   .getPromiseHTTP(
		   'POST',
		   '/data/ownercab/getshortoutlets',
		   params,
		   successListOutlets
	   );
	}

	function successListOutlets(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === 'ok') {
				return response.data.outlets;
			}
		}
	}
// получаем краткий список торговых точек

	function getDiscountList( params ) {

		if(params.outletsIds == "" || params.outletsIds == undefined){
		  params.outletsIds = null;
		} else {
		  params.outletsIds = [params.outletsIds];
		}

		return promiseOperation.post( '/data/ownercab/getdiscounts', params );
	}

	function getNewDiscount() {
		return promiseOperation.getPromiseWithQ( {
			"_value": "",
			"value": "",
			"name": "",
			"type": "OPENED", //FIXED
			"calculationType": "PERCENT", //AMOUNT
			"limitedAccess": false //AMOUNT
		} );
	}

	function getDiscountById( id ) {

		return currencyFormat.getCurrencyOptionsEdit()
			.then(currencyOptionsEdit => {

				return promiseOperation.post( '/data/ownercab/getdiscountbyid', id, afterResponse);

				function afterResponse(d) {
					if ( d.type === 'OPENED' ) {
						d._value = "";
					} else {
						switch ( d.calculationType ) {
							case 'AMOUNT':
								d._value = serviceMinMaxValues.getValidValue(coinToCurrency( d.value, '.' ), currencyOptionsEdit);
								break;
							case 'PERCENT':
								d._value = parseFloat( coinToCurrency( d.value, '.' ) ) + "";
								break;
						}
					}
				}
			});
	}

	function createDiscount( data ) {
		// console.log("data = ", data);

		data.value = data._value !== "" ? currencyToCoin( data._value ) : null;
		return promiseOperation.post( '/data/ownercab/creatediscount', data );
	}

	function editDiscount( data ) {
		data = angular.copy(data);
		data.value = currencyToCoin( data._value );
		delete data._value;
		return promiseOperation.post( '/data/ownercab/editdiscount', data );
	}

	function delDiscount( arrayId ) {
		return promiseOperation.post( '/data/ownercab/deletediscounts', {discountIds: arrayId} );
	}
}

export default {
	type: 'factory',
	name: 'factoryDiscount',
	value: factoryDiscount
};
