/**
 * Constant containing message texts for errors coming from backend.
 * Used for redirecting user to state.
 * @type {{object}}
 */
const constantStateErrors = {
  "purchase_order_closed": {
    message: "INVENTORY.MULTITENANT_ACCESS_ERRORS.PURCHASE_ORDER_CLOSED",
    redirectState: "inventory.orederdetail",
    redirectType: "ITEM"
  },
  "purchase_order_approved": {
    message: "INVENTORY.MULTITENANT_ACCESS_ERRORS.PURCHASE_ORDER_APPROVED",
    redirectState: "inventory.orederdetail",
    redirectType: "ITEM"
  },
  "purchase_order_deleted": {
    message: "ITEM_DELETED_EARLIER",
    redirectState: "inventory.purchase",
    redirectType: "LIST"
  },
  "purchase_order_obsolete": {
    message: "INVENTORY.MULTITENANT_ACCESS_ERRORS.PURCHASE_ORDER_OBSOLETE",
    redirectState: "inventory_orederdetail",
    redirectType: "ITEM"
  },
  "transfer_order_deleted": {
    message: "ITEM_DELETED_EARLIER",
    redirectState: "inventory.transfer",
    redirectType: "LIST"
  },
  "transfer_order_approved": {
    message: "INVENTORY.MULTITENANT_ACCESS_ERRORS.TRANSFER_ORDER_APPROVED",
    redirectState: "inventory.transferdetail",
    redirectType: "ITEM"
  },
  "transfer_order_transferred": {
    message: "INVENTORY.MULTITENANT_ACCESS_ERRORS.TRANSFER_ORDER_TRANSFERRED",
    redirectState: "inventory.transferdetail",
    redirectType: "ITEM"
  },
  "purchase_order_not_found": {
    message: "INVENTORY.OBJECT_NOT_EXIST",
    redirectState: "inventory.purchase",
    redirectType: "LIST"
  }
}

export default {
  type: "constant",
  name: "constantStateErrors",
  value: constantStateErrors
}
