createDirective.$inject = ['$parse'];

function createDirective($parse) {
    return {
        restrict: 'A',
        require: 'mdVirtualRepeatContainer',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, virtualRepeatContainerCtrl) {
        if (attrs.lvVirtualRepeatSizeMethod) {
            $parse(attrs.lvVirtualRepeatSizeMethod).assign(scope, function(height) {
                element.css('height', '' + height + 'px');
                virtualRepeatContainerCtrl.updateSize(true);
            });
        }
    }
}

export default {
    type: 'directive',
    name: 'lvVirtualRepeatSizeMethod',
    value: createDirective
};