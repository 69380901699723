/**
 * Created by Alex on 22.08.2018.
 */

function unimportantZeroMarginGoods() {

    function format(val, decimal) {

        if(val !== undefined && val !== null && decimal === '.') {
            // console.log("до val = ", val);
            val = val.replace('.00', '');
            val = val.replace('.10', '.1');
            val = val.replace('.20', '.2');
            val = val.replace('.30', '.3');
            val = val.replace('.40', '.4');
            val = val.replace('.50', '.5');
            val = val.replace('.60', '.6');
            val = val.replace('.70', '.7');
            val = val.replace('.80', '.8');
            val = val.replace('.90', '.9');

            // console.log("после val . = ", val);
            return val;
        }
        else if(val !== undefined && val !== null && decimal === ',') {
            // console.log("до val = ", val);
            val = val.replace(',00', '');
            val = val.replace(',10', ',1');
            val = val.replace(',20', ',2');
            val = val.replace(',30', ',3');
            val = val.replace(',40', ',4');
            val = val.replace(',50', ',5');
            val = val.replace(',60', ',6');
            val = val.replace(',70', ',7');
            val = val.replace(',80', ',8');
            val = val.replace(',90', ',9');

            // console.log("после val , = ", val);
            return val;
        }
    }

    return format;
}

export default {
    type: 'filter',
    name: 'unimportantZeroMarginGoods',
    value: unimportantZeroMarginGoods
};