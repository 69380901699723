/**
 * Created by Kiskenbassker on 12.02.18
 */

import angular from 'angular';

countDetailsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'lodash',
    'factoryInventoryCount',
    'serviceDialog',
    'serviceToast',
    '$orderDetails',
    '$countOptionsShow',
    '$countOptionsShowWithPlus',
    '$currencyOptionsShow',
    'resolveTimezone',
    'tableViewColumnFactory',
    '$translate',
    'lvMixpanel',
    '$permitsManager'
];

function countDetailsController(
    $scope,
    $state,
    $stateParams,
    $filter,
    lodash,
    factoryInventoryCount,
    serviceDialog,
    serviceToast,
    $orderDetails,
    $countOptionsShow,
    $countOptionsShowWithPlus,
    $currencyOptionsShow,
    resolveTimezone,
    tableViewColumnFactory,
    $translate,
    lvMixpanel,
    $permitsManager
) {

    var vm = this,
        translate = $filter('translate'),
        wareCount = $filter('wareCount'),
        coinToCurrency = $filter('coinToCurrency');

    // данные для сохранения состояния фильтров
    $scope.stateFromList = $stateParams.stateToSave;
    $scope.id = $stateParams.id;
    $scope.tzOffset = resolveTimezone.timeZoneOffsetForReports();

    vm.details = angular.copy($orderDetails);
    vm.countOptionsShow = $countOptionsShow;
    vm.currencyOptionsShow = $currencyOptionsShow;
    vm.countOptionsShowWithPlus = $countOptionsShowWithPlus;

    vm.items = vm.details.items.map(function (item) {
        return {
            id: item.id,
            wareId: item.wareId,
            name: item.name,
            sku: item.sku,
            expected: wareCount(item.expected, vm.countOptionsShow.decimalCharacter, false),
            count: item.count !== null ? wareCount(item.count, vm.countOptionsShow.decimalCharacter, false) : null,
            difference: item.difference !== null ? wareCount(item.difference, vm.countOptionsShow.decimalCharacter, false) : null,
            costDifference: item.costDifference !== null ? coinToCurrency(item.costDifference, vm.currencyOptionsShow.decimalCharacter) : null
        }
    });

    vm.diffAmount = vm.details.diffAmount !== null ? wareCount(vm.details.diffAmount, vm.countOptionsShow.decimalCharacter, false) : null;
    vm.costDiffAmount = vm.details.costDiffAmount !== null ? coinToCurrency(vm.details.costDiffAmount, vm.currencyOptionsShow.decimalCharacter) : null;

    /* Function for Count Stock button */
    vm.goCountStock = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if(vm.details.status !== 'COMPLETED') {
            if(vm.details.status === 'PENDING') {
                factoryInventoryCount.changeInventoryCountOrderStatus({
                    id: $stateParams.id
                }).then(function (res) {
                    if(res.result === "ok") {
                        $state.go('inventory.countstock', {
                            id: $stateParams.id
                        });
                    }
                })
            } else {
                $state.go('inventory.countstock', {
                    id: $stateParams.id
                });
            }
        }
    };

    //  Triggered when users click on the “Save as PDF” button in
    //  the “Inventory count details” page.
    vm.saveInventoryCountsAsPDFMixpanel = function() {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save inventory counts as PDF"
        });
    }
    //Triggered when users click on the “Save as CSV” button in
    // the “Inventory count details” page.
    vm.saveInventoryCountsAsCSVMixpanel = function() {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save inventory counts as CSV"
        });
    }

    /* Function for All inventory counts button */
    vm.goBack = function() {
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go('inventory.counts', {
                page: 0,
                limit: 10
            });
        } else {
            $state.go('inventory.counts', {
                page: $scope.stateFromList.page,
                limit: $scope.stateFromList.limit,
                outletId: $scope.stateFromList.outletId,
                status: $scope.stateFromList.status
            });
        }
    };

    /* Function for Edit button */

    vm.goEdit = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }
        if(vm.details.status !== 'COMPLETED') {
            $state.go('inventory.editcount', {
                id: $stateParams.id
            });
        }
    };

    /* Function for Delete button */

    vm.deleteItem = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if(vm.details.status !== 'COMPLETED') {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title:  translate('INVENTORY.COUNT.DELETION_TITLE'),
                content: translate('INVENTORY.COUNT.DELETE_QUESTION'),
                buttons: [{
                    text: 'ON_CANCEL',
                    action: function () {
                        return false;
                    }
                }, {
                    text: 'ON_DEL',
                    class: 'md-primary',
                    action: function () {
                        factoryInventoryCount.deleteInventoryCountOrder({
                            id: $stateParams.id
                        }).then(function (res) {
                            if(res.result === "ok") {
                                serviceToast.show('INVENTORY.COUNT.COUNT_DELETED', 'success');

                                if (lodash.isEmpty($scope.stateFromList)) {
                                    $state.go( 'inventory.counts',
                                        {
                                            page: 0,
                                            limit: 10
                                        },
                                        {
                                            reload: true
                                        });
                                } else {
                                    $state.go( 'inventory.counts', {
                                        page: 			$scope.stateFromList.page,
                                        limit: 			$scope.stateFromList.limit,
                                        outletId:		$scope.stateFromList.outletId,
                                        status: 		$scope.stateFromList.status
                                    })
                                }
                            }
                        });
                    }
                }]
            });
        }
    }

    vm.columns = buildColumns();

    function buildColumns() {
        let result = [];

        // Item
        const nameOptions = {
            name: 'name',
            minWidth: 184
        };
        const nameFields = [];
        nameFields.push({
            displayValue: $translate.instant('ARTICLE'),
            valueGetter: 'sku',
            class: 'l-comment'
        });
        result.push(tableViewColumnFactory.createPropertyViewExtendedColumn(
            $translate.instant('INVENTORY.ITEMS_TABLE.ITEM'), 'name', nameFields, nameOptions));

        // Expected stock
        const stockOptions = {
            name: 'expectedStock',
            width: 96,
            class: 'text-right',
            viewFilters: {
                'currencyLocalizationFilter': vm.countOptionsShow
            }
        };
        result.push(tableViewColumnFactory.createPropertyViewColumn(
            $translate.instant('INVENTORY.EXPECKTED'), 'expected', stockOptions));

        // Counter
        const counterOptions = {
            name: 'counted',
            width: 96,
            class: 'text-right',
            viewFilters: {
                'currencyLocalizationFilter': vm.countOptionsShow
            },
            footer: {
                value: $translate.instant('REPORT_OVERVIEW.TOTAL'),
                class: 'text-right'
            }
        };
        function counterValueGetter(item) {
            return item.count !== null ? item.count : '—';
        }
        result.push(tableViewColumnFactory.createPropertyViewColumn(
            $translate.instant('INVENTORY.COUNTED'), counterValueGetter, counterOptions));

        // Difference
        const differenceOptions = {
            name: 'difference',
            width: 96,
            class: 'text-right',
            viewFilters: {
                'currencyLocalizationFilter': vm.countOptionsShow
            },
            footer: {
                id: 'difference_total',
                value: function() {
                    return vm.diffAmount !== null ? vm.diffAmount : '—';
                },
                class: 'text-right',
                viewFilters: {
                    'currencyLocalizationFilter': vm.countOptionsShow
                }
            }
        };
        function differenceValueGetter(item) {
            return item.difference !== null ? item.difference : '—';
        }
        result.push(tableViewColumnFactory.createPropertyViewColumn(
            $translate.instant('CASHBOX.SUBTRACT'), differenceValueGetter, differenceOptions));

        // Cost difference
        if ($permitsManager.costAllowed) {
            const costDifferenceOptions = {
                name: 'costDifference',
                width: 145,
                class: 'text-right nowrap',
                viewFilters: {
                    'currencyLocalizationFilter': vm.currencyOptionsShow
                },
                footer: {
                    id: 'cost_difference_total',
                    value: function() {
                        return vm.costDiffAmount !== null ? vm.costDiffAmount : '—';
                    },
                    class: 'text-right nowrap',
                    viewFilters: {
                        'currencyLocalizationFilter': vm.currencyOptionsShow
                    }
                }
            };
            function costDifferenceValueGetter(item) {
                return item.costDifference !== null ? item.costDifference : '—';
            }
            result.push(tableViewColumnFactory.createPropertyViewColumn(
                $translate.instant('INVENTORY.COST_DIFF'), costDifferenceValueGetter, costDifferenceOptions));
        }

        return result;
    }
}

export default {
    type: 'controller',
    name: 'countDetailsController',
    value: countDetailsController
};
