import angular from 'angular';

createDirective.$inject = [];
function createDirective() {
    return {
        restrict: 'A',
        require: {
            ngModel: 'ngModel'
        },
        controller: CurrencyLocalizationController,
        bindToController: true
    }
}
CurrencyLocalizationController.$inject = ['$scope', '$element', '$attrs', '$parse', '$filter', '$mdUtil', 'currencyLocalizationUtil', '$timeout'];
function CurrencyLocalizationController($scope, $element, $attrs, $parse, $filter, $mdUtil, currencyLocalizationUtil, $timeout) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onDestroy = $onDestroy;

    let inputElement = $element[0];
    let lastSelectionStart = 0;
    let lastSelectionEnd = 0;
    let prevVal;
    let modelVal;
    let currencyOptionsDefault = {
        allowDecimalPadding: true
    };
    let currencyOptions = {};
        // currencySymbol: "$"
        // currencySymbolPlacement: "p"
        // currencySymbolWithSpace: false
        // decimalCharacter: "."
        // decimalCharacterAlternative: ","
        // decimalPlacesOverride: 2
        // digitGroupSeparator: ","
        // digitalGroupSpacing: 3
        // emptyInputBehavior: "press"
        // maximumValue: 999999.99
        // minimumValue: 0
        // roundingMethod: "S"


    let optionsDefault = {
        initChangeModel: false,
        allowOutOfRangeValue: false
    };
    let options = {};

    const negativeSignRegex = RegExp('[\-]', 'g');
    const negativeSignInvertedRegex = RegExp('[^\-]', 'g');
    const numRegex = /\d/;
    let nonNumRegex;
    let decCharAlternativeRegex;
    let digitGroupSeparatorRegex;
    let allowDecimal = false;
    let currencyLength;
    let latestValidVal;
    let debugElTypes = null;
    let debugEl = null;
    let spaceShift;

    let debugMessages = {
        counter: 0,
        prev: [],
        raw: [],
        clear: [],
        formatted: [],
        selection: []
    };
    let skipDeleteContentBackward = false;
    let cursorPositionHiragana = 0;

    // Use 'change' event instead 'input' for safari only
    let inputEvents = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ?
        'change keyup cut paste' : 'input cut paste';

    let isCompositionText = false;
    let recentDeleteContent = false;
    let valBeforeDelete = '';
    let lastInputHiragana = false;
    let rawVal;

    let self = this;

    let onInputHandler = onInput;

    function $onInit() {

        let modelOptions = $attrs['currencyLocalizationOptions'] ?
            $parse($attrs['currencyLocalizationOptions'])($scope) : {};

        $scope.$watch(
            () => $parse($attrs['currencyLocalization'])($scope),
            newVal => {
                init(newVal, modelOptions);
                self.ngModel.$render();
            });

        init($parse($attrs['currencyLocalization'])($scope), modelOptions);
    }

    function init(_currencyOptions, _modelOptions) {

        options = angular.extend({}, optionsDefault, _modelOptions);

        if (_modelOptions.debug) {
            debugEl = angular.element('<div>')
            debugEl.addClass('debugger');
            $element.after(debugEl);
            debugElTypes = angular.element('<div>')
            debugElTypes.addClass('debugger');
            $element.after(debugElTypes);
        }

        currencyOptions = angular.extend({}, currencyOptionsDefault, _currencyOptions);
        spaceShift = currencyOptions.currencySymbolWithSpace ? 1 : 0;

        $element.off(inputEvents, onInputHandler);
        $element.off('keydown select paste focus', onKeyDown);
        $element.off('blur', onBlur);
        $element.off('beforeinput', onBeforeInput);

        allowDecimal = currencyOptions.decimalPlacesOverride > 0;

        decCharAlternativeRegex = RegExp('\\' + currencyOptions.decimalCharacterAlternative, 'g');
        digitGroupSeparatorRegex = RegExp('\\' + currencyOptions.digitGroupSeparator, 'g');

        nonNumRegex = currencyOptions.minimumValue < 0 ?
            RegExp('[^\\-\\.\\d]', 'g') :
            RegExp('[^\\.\\d]', 'g');

        currencyLength = currencyOptions.currencySymbol ? currencyOptions.currencySymbol.length : 0;

        $element.on(inputEvents, onInputHandler);
        $element.on('keydown select paste focus', onKeyDown);
        $element.on('blur', onBlur);
        $element.on('beforeinput', onBeforeInput);
    }

    function $postLink() {
        self.ngModel.$render = function() {

            let viewVal = self.ngModel.$viewValue == null ? '' : self.ngModel.$viewValue;
            viewVal = viewVal.replace(decCharAlternativeRegex, currencyOptions.decimalCharacter);

            modelVal = unFormat(viewVal);

            prevVal = format(modelVal);

            if (!isValidRange(prevVal)) {
                prevVal = '';
                modelVal = ''
            }
            latestValidVal = prevVal;
            $element.val(prevVal);

            if (options.initChangeModel){
                setResult({val: prevVal, skipPosition: true});
            }
        }
    }

    function $onDestroy() {
        $element.off(inputEvents, onInputHandler);
        $element.off('keydown select paste focus', onKeyDown);
        $element.off('blur', onBlur);
    }

    function onBeforeInput(event) {
        if (event.originalEvent.inputType === 'insertCompositionText') {

            // Input Hiragana numbers generate 'insertCompositionText' event (for other languages it is 'insertText')
            // Directive replace Hiragana numbers with standard numbers, it fires extra 'insertText' event.
            // on this event value if broken (duplicated last input symbol, I didn't find clear explanation why it happens)
            // That's why next 'insertText' event should be prevented
            isCompositionText = true;

            // Another trix-fix :(
            // Sometimes (not stable, no logic to reproduce)
            // On input Hiragana numbers extra events generated:
            // 'deleteContentBackward' => original 'insertCompositionText' => 'deleteContentBackward' with broken value
            // This event can't be prevented, it is required to temporary set flag to revert previous value on 'deleteContentBackward' event
            skipDeleteContentBackward = true;
            $timeout(() => {
                skipDeleteContentBackward = false;
            }, 100);

            lastInputHiragana = true;
        }

        if (event.originalEvent.inputType === 'insertText') {
            if (isCompositionText && skipDeleteContentBackward) {
                isCompositionText = false
                event.preventDefault();
            } else {
                lastInputHiragana = false;
            }
        }
    }

    function onInput(event) {

        rawVal = currencyLocalizationUtil.replaceHiraganaToArabic(inputElement.value);

        if (lastInputHiragana && event.originalEvent.inputType === 'deleteContentBackward') {
            if (skipDeleteContentBackward) {
                // Revert previous value
                // This case happens on extra events fired on input Hiragana numbers
                setResult({
                    val: prevVal,
                    pos: cursorPositionHiragana,
                    event: event
                });
                return;
            }

            // Set flag of recently 'deleteContentBackward' to revert previous value on blur
            // 'deleteContentBackward' event fires before 'blur' if language is Hiragana
            valBeforeDelete = prevVal;
            recentDeleteContent = true;
            $timeout(() => {
                recentDeleteContent = false;
            }, 100);
        }

        debugMessages.counter++;
        debugMessages.prev.unshift(prevVal);
        debugMessages.raw.unshift(rawVal);

        if (rawVal == prevVal) {
            return;
        }

        // get inserted chars
        let inserted = findDelta(rawVal, prevVal);
        // get removed chars
        let removed = findDelta(prevVal, rawVal);

        if (inserted.delta === ' ') {
            setResult({
                val: prevVal,
                pos: lastSelectionEnd,
                event: event
            });
            return;
        }

        if (inserted.delta === '0') {
            const beforeCursorPart = prevVal.substring(0, lastSelectionEnd);
            const afterCursorPart = prevVal.substring(lastSelectionEnd);
            if (!numRegex.test(beforeCursorPart) &&
                numRegex.test(afterCursorPart) &&
                !prevVal.includes(options.decimalCharacter)) {
                self.ngModel.$setViewValue(unFormat(prevVal));
                if ($attrs.lvChangeCurrency) {
                    $scope.$eval($attrs.lvChangeCurrency);
                }
                $mdUtil.nextTick(() => {
                    inputElement.value = prevVal;
                    let newCursorPos = currencyOptions.currencySymbolPlacement === 'p' ? currencyLength : 0 + (prevVal[0] === '-' ? 1 : 0);
                    inputElement.setSelectionRange(newCursorPos, newCursorPos);
                });
                return;
            }
        }

        let processResult;

        if (rawVal === '' && prevVal === currencyOptions.currencySymbol) {
            prevVal = rawVal;
            return;
        }

        if (rawVal === currencyOptions.decimalCharacter ||
            rawVal === currencyOptions.decimalCharacterAlternative) {
            let newFormatterVal = format(currencyOptions.decimalCharacter, true);
            let newCursorPosition;
            if(currencyOptions.currencySymbolPlacement === 's') {
                newCursorPosition = inputElement.selectionEnd;
            }
            else {
                newCursorPosition = newFormatterVal.length;
            }
            processResult = {
                val: newFormatterVal,
                pos: newCursorPosition,
                event: event
            };
            setResult(processResult);
            return;
        }

        // Replace alternative separator
        if (inserted.delta.length === 1 && inserted.delta.includes(currencyOptions.decimalCharacterAlternative)) {
            inserted.delta = inserted.delta.replace(decCharAlternativeRegex, currencyOptions.decimalCharacter);
            let valStart = rawVal.substr(0, inserted.position);
            let valEnd = rawVal.substr(inserted.position + inserted.delta.length);
            rawVal = valStart + inserted.delta + valEnd;
        }

        if (inserted.delta === currencyOptions.decimalCharacter) {
            if (getValWithoutSymbol(prevVal).includes(currencyOptions.decimalCharacter) ||
                currencyOptions.decimalPlacesOverride === 0) {
                processResult = {
                    val: prevVal,
                    pos: lastSelectionEnd,
                    event: event
                };
                setResult(processResult);
                return;
            }
        }

        let negativeResult = handleNegative(rawVal);
        rawVal = negativeResult.val;

        if (rawVal === currencyOptions.currencySymbol) {
            prevVal = rawVal;
            processResult = {
                val: rawVal,
                skipPosition: true,
                event: event
            };
            setResult(processResult);
            return;
        }

        let clearValue = unFormat(rawVal, removed);
        debugMessages.clear.unshift(clearValue);

        let formattedVal = format(clearValue, true);
        debugMessages.formatted.unshift(formattedVal);

        let cursorPosition = inputElement.selectionEnd;

        let pasted = (!inserted.delta && !removed.delta) || inserted.delta.length > 1;

        if (formattedVal === '' &&
            currencyOptions.currencySymbol) {
            rawVal = rawVal.replace(decCharAlternativeRegex, currencyOptions.decimalCharacter)
            if (rawVal === currencyOptions.currencySymbol) {
                prevVal = rawVal;
                modelVal = clearValue;
                return;
            }
        }

        processResult = {
            val: formattedVal,
            pos: cursorPosition
        };

        if (pasted) {
            processResult = processPaste();
        }
        else {
            if (removed.delta.length > 0) {
                processResult = processRemove();
            } else if (inserted.delta.length > 0) {
                processResult = processInsert();
            }
        }

        if (isValidRange(processResult.val)) {
            latestValidVal = processResult.val;
        }
        else {
            processResult = {
                val: prevVal,
                pos: lastSelectionEnd
            };
        }

        prevVal = processResult.val;
        processResult.event = event;

        setResult(processResult);

        function getValWithoutSymbol(val) {
            let valWithoutSymbol = val;
            if (currencyOptions.currencySymbol?.includes(currencyOptions.decimalCharacter)) {
                const currSymbolIndex = val.indexOf(currencyOptions.currencySymbol);
                if (currSymbolIndex === 0) {
                    valWithoutSymbol = val.substring(currencyOptions.currencySymbol.length + spaceShift);
                } else {
                    valWithoutSymbol = val.substring(0, val.length - currSymbolIndex - spaceShift);
                }
            }
            return valWithoutSymbol;
        }

        function processInsert() {

            let result = {
                val: formattedVal,
                pos: cursorPosition
            };

            if (inserted.delta.length == 1) {

                let actualLengthChange = formattedVal.length - prevVal.length;

                // Prefix currency sign only
                if (currencyOptions.currencySymbolPlacement === 'p') {

                    let negativeSighShift = formattedVal[0] === '-' ? 1 : 0;
                    let negativeSighShiftPrev = prevVal[0] === '-' ? 1 : 0;

                    if (lastSelectionEnd < currencyLength + negativeSighShiftPrev) {

                        if (inserted.delta === '-') {

                            result = {
                                val: formattedVal,
                                pos: currencyLength + negativeSighShift
                            };
                            return result;
                        }
                        else {

                            result = {
                                val: formattedVal,
                                pos: currencyLength + negativeSighShift + actualLengthChange
                            };
                            return result;
                        }

                    }
                }

                if (currencyOptions.currencySymbolPlacement === 's' && lastSelectionEnd > prevVal.length - currencyLength) {

                    result = {
                        val: formattedVal,
                        pos: formattedVal.length - currencyLength
                    };
                    return result;
                }

                if (inserted.delta === currencyOptions.decimalCharacter) {

                    let partBeforeCursorPrev = prevVal.substring(0, lastSelectionEnd)

                    // Only for cases when currency symbol contains decimal separator
                    let [mainPart, decimalPart] = getValWithoutSymbol(formattedVal).split(currencyOptions.decimalCharacter);
                    if (currencyOptions.currencySymbolPlacement === 'p') {
                        mainPart = currencyOptions.currencySymbol + (spaceShift ? ' ' : '') + mainPart;
                    } else {
                        decimalPart = decimalPart + (spaceShift ? ' ' : '') + currencyOptions.currencySymbol;
                    }
                    if (decimalPart) {
                        let beforeCursorLengthDiff = mainPart.length - partBeforeCursorPrev.length;
                        let newPosition = lastSelectionEnd + beforeCursorLengthDiff + 1;
                        return {
                            val: formattedVal,
                            pos: newPosition
                        };
                    }
                }

                const decCharPos = formattedVal.indexOf(currencyOptions.decimalCharacter);

                if (actualLengthChange !== inserted.delta.length) {
                    if (actualLengthChange === 0 && decCharPos >= 0 &&
                        lastSelectionEnd > decCharPos) {
                        result = {
                            val: formattedVal,
                            pos: inputElement.selectionEnd
                        };
                    }
                    else {
                        let newPosition = lastSelectionEnd + (actualLengthChange - inserted.delta.length) + 1;
                        result = {
                            val: formattedVal,
                            pos: newPosition
                        };
                    }
                }
                else if (actualLengthChange === 0 && decCharPos >= 0 &&
                    lastSelectionEnd > decCharPos) {
                    result = {
                        val: formattedVal,
                        pos: inputElement.selectionEnd
                    };
                }
            }

            if (!isValidRange(result.val)) {
                result = {
                    val: prevVal,
                    pos: lastSelectionStart
                };
            }

            return result;
        }

        function processRemove() {
            let delPressed = lastSelectionEnd == cursorPosition;
            let backspacePressed = cursorPosition == (lastSelectionEnd - 1);

            if (removed.delta === '-') {
                return {
                    val: rawVal,
                    pos: cursorPosition,
                    skipPosition: true
                };
            }

            let absRawVal = rawVal[0] === '-' ? rawVal.substring(1) : rawVal;

            // Removed the last char (except currency symbol and negative sign)
            if (absRawVal === currencyOptions.currencySymbol && prevVal.length > rawVal.length) {
                return {
                    val: rawVal,
                    pos: cursorPosition,
                    skipPosition: true
                };
            }

            let isPrevNegative;
            let absPrevVal;
            let cursorCorrection = 0;
            if (prevVal[0] === '-') {
                absPrevVal = prevVal.substring(1);
                isPrevNegative = true;
                cursorCorrection = 1;
            } else {
                absPrevVal = rawVal;
                isPrevNegative = false;
            }

            // Remove negative sign on backspace pressed while cursor is in currency section
            if (isPrevNegative && backspacePressed &&
                absPrevVal.indexOf(currencyOptions.currencySymbol) === 0 &&
                lastSelectionStart <= currencyOptions.currencySymbol.length + cursorCorrection) {
                return {
                    val: absPrevVal,
                    pos: cursorPosition,
                    skipPosition: false
                };
            }

            let partBeforeCursor = rawVal.substring(0, cursorPosition);
            let partAfterCursor = rawVal.substring(cursorPosition);

            if (!/[0-9]/.test(partBeforeCursor) &&
                partAfterCursor.indexOf(currencyOptions.digitGroupSeparator) === 0) {
                partAfterCursor = partAfterCursor.substring(1);
                return {
                    val: partBeforeCursor + partAfterCursor,
                    pos: cursorPosition,
                    skipPosition: false
                };
            }

            if (!/[1-9]/.test(partBeforeCursor) &&
                (partAfterCursor.indexOf('0') === 0 || partAfterCursor.indexOf(currencyOptions.digitGroupSeparator + '0') === 0)) {
                return {
                    val: rawVal,
                    pos: cursorPosition,
                    skipPosition: true
                };
            }

            if (removed.delta.length == 1) {

                if (currencyOptions.currencySymbolPlacement === 'p') {

                    let clearValueAbs;
                    let isNegative;
                    let prefixLength;
                    if (clearValue[0] === '-') {
                        clearValueAbs = clearValue.substring(1);
                        isNegative = true;
                        prefixLength = currencyLength + 1;
                    } else {
                        clearValueAbs = clearValue;
                        isNegative = false;
                        prefixLength = currencyLength;
                    }

                    if (lastSelectionEnd <= prefixLength) {

                        if (backspacePressed) {
                            cursorPosition = prefixLength;
                            return {
                                val: prevVal,
                                pos: cursorPosition
                            };
                        }
                        if (delPressed && prevVal.length <= prefixLength) {
                            cursorPosition = prefixLength;
                            return {
                                val: currencyOptions.currencySymbol,
                                pos: cursorPosition
                            }
                        }
                    }

                    if (lastSelectionEnd < prefixLength && delPressed) {
                        // Handle delete if cursor inside currency symbol

                        clearValueAbs = clearValueAbs.slice(1);
                        if (clearValueAbs[0] === currencyOptions.digitGroupSeparator) {
                            clearValueAbs = clearValueAbs.slice(1);
                        }
                        let skipFormat = clearValueAbs[0] === '0';
                        let newVal;
                        if (skipFormat) {
                            newVal = clearValueAbs;
                            if (currencyOptions.currencySymbol) {
                                newVal = currencyOptions.currencySymbol + newVal;
                            }
                            if (isNegative) {
                                newVal = '-' + newVal;
                            }
                        } else {
                            if (isNegative) {
                                clearValueAbs = '-' + clearValueAbs;
                            }
                            newVal = format(clearValueAbs, true);
                        }

                        cursorPosition = prefixLength;
                        return {
                            val: newVal,
                            pos: cursorPosition
                        };
                    }
                }

                if (currencyOptions.currencySymbolPlacement === 's') {

                    if (prevVal.length <= currencyLength) {
                        cursorPosition = 0;
                        return {
                            val: prevVal,
                            pos: cursorPosition
                        };
                    }

                    if (backspacePressed && lastSelectionEnd > (prevVal.length - currencyLength)) {
                        prevVal = prevVal.slice(0, prevVal.length - currencyLength - 1);
                        let newFormattedVal = format(unFormat(prevVal), true);
                        cursorPosition = newFormattedVal.length - currencyLength;
                        return {
                            val: newFormattedVal,
                            pos: cursorPosition
                        };
                    }
                }

                if (removed.delta === currencyOptions.digitGroupSeparator) {
                    let part1;
                    let part2;
                    if (delPressed) {
                        part1 = prevVal.slice(0, cursorPosition);
                        part2 = prevVal.slice(cursorPosition + 2);

                        let newVal = format(unFormat(part1 + part2), true);

                        let prevGroupSepNumBefore = getSepNumberBeforeCursor(prevVal, cursorPosition);
                        let newGroupSepNumBefore = getSepNumberBeforeCursor(newVal, cursorPosition);

                        return {
                            val: newVal,
                            pos: cursorPosition + (newGroupSepNumBefore - prevGroupSepNumBefore)
                        };
                    }
                    if (backspacePressed) {
                        part1 = prevVal.slice(0, cursorPosition - 1);
                        part2 = prevVal.slice(cursorPosition + 1);
                        let skipFormat = !/[1-9]/.test(part1) && part2.indexOf('0') === 0;
                        return {
                            val: skipFormat ? (part1 + part2) : format(unFormat(part1 + part2), true),
                            pos: cursorPosition - 1
                        };
                    }
                }
            }

            if (removed.delta.length > 1) {
                if (lastSelectionEnd > prevVal.length - currencyLength && currencyOptions.currencySymbolPlacement === 's') {
                    return {
                        val: formattedVal,
                        pos: clearValue.length
                    };
                }
                let positionFromEnd = prevVal.length - lastSelectionEnd;
                return {
                    val: formattedVal,
                    pos: formattedVal.length - positionFromEnd
                };
            }

            let correction;
            if (delPressed && removed.delta === currencyOptions.decimalCharacter) {
                let prevGroupSepNumBefore = getSepNumberBeforeCursor(prevVal, cursorPosition);
                let newGroupSepNumBefore = getSepNumberBeforeCursor(formattedVal, cursorPosition);
                correction = newGroupSepNumBefore - prevGroupSepNumBefore - 1;
            } else {
                correction = delPressed ? 0 : -1;
            }

            let newCursorPosition = getNewCursorPositionOnRemove(
                cursorPosition, prevVal, formattedVal, removed.delta, correction);

            function getNewCursorPositionOnRemove(currentPosition, prevVal, currVal, deltaVal, correction) {
                let actualLengthChange = prevVal.length - currVal.length;
                if (actualLengthChange !== deltaVal.length) {
                    let newPosition = lastSelectionEnd - (actualLengthChange - deltaVal.length) + correction;
                    let startLimit = currencyOptions.currencySymbolPlacement === 'p' ? currencyLength : 0;
                    if (currVal[0] === '-') {
                        startLimit++;
                    }
                    if (newPosition < startLimit) {
                        newPosition = startLimit;
                    }
                    return newPosition;
                } else {
                    return currentPosition;
                }
            }

            return {
                val: formattedVal,
                pos: newCursorPosition
            };
        }

        function getSepNumberBeforeCursor(val, cursorPos) {
            let valBeforeCursor = val.substr(0, cursorPos);
            return valBeforeCursor
                .split('')
                .filter(char => char === currencyOptions.digitGroupSeparator)
                .length;
        }

        function processPaste() {

                let actualLengthChange = formattedVal.length - prevVal.length;

                let result = {
                    val: formattedVal,
                    pos: lastSelectionEnd + actualLengthChange
                };

                let numVal = parseFloat(unFormat(result.val));
                if (!options.allowOutOfRangeValue &&
                    (numVal > currencyOptions.maximumValue || numVal < currencyOptions.minimumValue)) {
                    result = {
                        val: prevVal,
                        pos: lastSelectionEnd
                    };
                }

                return result;
            }
    }

    function onKeyDown(event) {
        lastSelectionStart = inputElement.selectionStart;
        lastSelectionEnd = inputElement.selectionEnd;
        if (event.keyCode === 13) {
            onBlur();
        }
    }

    function onBlur() {

        // On blur input with last input Hiragana symbol, extra 'deleteContentBackward' generated before 'blur'
        // It is required to revert previous value
        let viewVal;
        if (recentDeleteContent) {
            viewVal = valBeforeDelete;
            recentDeleteContent = false;
        } else {
            viewVal = modelVal;
        }

        viewVal = viewVal.replace('.', currencyOptions.decimalCharacter);
        let clearVal = unFormat(viewVal);
        if (!isValidRange(clearVal)) {
            clearVal = unFormat(latestValidVal);
        }

        if (clearVal[0] === '.') {
            clearVal = '0' + clearVal;
        }

        let formattedVal = format(clearVal);
        $element.val(formattedVal);

        self.ngModel.$setViewValue(clearVal);

        if (!self.ngModel.$options.getOption('updateOnDefault')) {
            self.ngModel.$commitViewValue();
        }

        if ($attrs.lvChangeCurrency) {
            $scope.$eval($attrs.lvChangeCurrency);
        }

        prevVal = formattedVal;
    }

    function unFormat(val) {
        if (val == null) {
            return '';
        }

        let result = val.replace(digitGroupSeparatorRegex, '');
        result = result.replace(currencyOptions.decimalCharacter, '.');
        result = result.replace(currencyOptions.currencySymbol, '');
        result = result.replace(/\s/, '');
        result = result.replace(nonNumRegex, '');

        const containsSeparator = result.includes('.');
        let [mainPart, decimalPart] = result.split('.');

        if (mainPart.length > 1) {
            let startingZeros = 0;
            for (let i = 0; i < mainPart.length - 1; i++) {
                if (mainPart[i] === '0') {
                    startingZeros++;
                }
                else {
                    break;
                }
            }

            if (startingZeros > 0) {
                mainPart = mainPart.substring(startingZeros);
            }
        }

        if (containsSeparator) {
            if (decimalPart) {
                result = mainPart + '.' + decimalPart;
            }
            else {
                result = mainPart + '.';
            }
        }
        else {
            result = mainPart;
        }

        return result;
    }

    function isValidRange(source) {
        let clearVal = unFormat(source);
        if (clearVal.includes('-') && currencyOptions.minimumValue >= 0) {
            return false;
        }
        clearVal = clearVal.replace(currencyOptions.decimalCharacter, '.');
        let numVal = parseFloat(clearVal);
        if (isNaN(numVal)) {
            return true;
        } else {
            return options.allowOutOfRangeValue ? true :
                numVal >= currencyOptions.minimumValue && numVal <= currencyOptions.maximumValue;
        }
    }

    function handleNegative(val) {
        let positionCorrection = 0;
        let negativeSignsCount = val.replace(negativeSignInvertedRegex, '').length;
        if (negativeSignsCount > 1) {
            val = val.replace(negativeSignRegex, '');
            positionCorrection = -negativeSignsCount;
        } else {
            if (negativeSignsCount === 1 && val[0] !== '-') {
                val = val.replace(negativeSignRegex, '');
                val = '-' + val;
            }
        }
        return {
            val: val,
            posCorrection: positionCorrection
        };
    }

    function format(val, progress) {
        return $filter('currencyLocalizationFilter')(val, currencyOptions, progress);
    }

    function findDelta(value, prevValue) {
        let result = {
            delta: '',
            position: 0
        };

        for (let i = 0; i < value.length; i++)
        {
            let str = value.substr(0, i) +
                value.substr(i + value.length - prevValue.length);

            if (str === prevValue)
            {
                result.delta = value.substr(i, value.length - prevValue.length);
                result.position = i;
            }
        }

        return result;
    }

    function setResult(res) {

        // If value was reformatted additional input events is being generated
        // and following 'deleteContentBackward' event should be skipped
        // For japanese hiragana input only
        if(rawVal?.length !== res.val?.length) {
            skipDeleteContentBackward = true;
            $timeout(() => {
                skipDeleteContentBackward = false;
            }, 100);
        }

        modelVal = unFormat(res.val);

        self.ngModel.$setViewValue(modelVal);

        if (!self.ngModel.$options.getOption('updateOnDefault')) {
            self.ngModel.$commitViewValue();
        }

        if ($attrs.lvChangeCurrency) {
            $scope.$eval($attrs.lvChangeCurrency);
        }

        inputElement.value = res.val;

        if (res.event && res.event.originalEvent.inputType === 'insertCompositionText') {
            // For Hiragana language only
            // Save cursor position to correct revert previous value on input
            cursorPositionHiragana = res.pos;
        }

        debugMessages.selection.unshift('' + res.pos + ': ' + res.skipPosition);

        if (!res.skipPosition) {
            setSelection(res.pos, res.pos);
        }

        if (debugEl) {

            let mgs = [];

            for(let key in debugMessages) {
                if (angular.isArray(debugMessages[key])) {
                    if(debugMessages[key].length > 5) {
                        debugMessages[key].length = 5;
                    }
                    mgs.push(key + ': ' + debugMessages[key].join('; '));
                }
                else {
                    mgs.push(key + ': ' + debugMessages[key]);
                }
            }

            debugEl.html(mgs.join('<br/>'));
        }
    }

    function setSelection(start, end) {
        inputElement.setSelectionRange(start, end);
    }
}

export default {
    type: 'directive',
    name: 'currencyLocalization',
    value: createDirective
};