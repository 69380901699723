currencyLocalizationUtil.$inject = [];
function currencyLocalizationUtil() {

    const hiraganaNumRegex = RegExp('[０１２３４５６７８９]', 'g');
    const replacerMap = {
        '０': '0',
        '１': '1',
        '２': '2',
        '３': '3',
        '４': '4',
        '５': '5',
        '６': '6',
        '７': '7',
        '８': '8',
        '９': '9'
    };

    const replaceHiraganaToArabic = (source) => hiraganaNumRegex.test(source)
        ? source.split('').map(char => replacerMap[char] || char).join('')
        : source

    /**
     * @name replaceHiraganaToArabic
     * @desc Replace Japanese Hiragana numbers with standard number characters
     * @param {String} Source string
     * @return {String} Strings with replaces characters
     */
    this.replaceHiraganaToArabic = replaceHiraganaToArabic;
}

export default {
    type: 'service',
    name: 'currencyLocalizationUtil',
    value: currencyLocalizationUtil
};
