export class Paginator {
  constructor(rows, page = 1, size = 10) {
    this.rows = rows;
    this.page = page;
    this.size = size;
  }

  pages() {
    return Math.max(Math.ceil(this.rows.length / this.size), 1);
  }

  isFirst() {
    return this.page <= 1;
  }

  isLast() {
    return this.page >= this.pages();
  }

  isEmpty() {
    return this.rows.length === 0;
  }

  previous() {
    return Math.max(this.page - 1, 1);
  }

  next() {
    return Math.min(this.page + 1, this.pages());
  }

  offset() {
    return this.size * Math.max(this.page - 1, 0);
  }

  current() {
    return this.rows.slice(this.offset(), this.offset() + this.size);
  }

  back() {
    this.page = this.previous();
  }

  forward() {
    this.page = this.next();
  }
}
