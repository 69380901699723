import angular from 'angular';
import * as FullStory from '@fullstory/browser';
import { UnleashClient } from "unleash-proxy-client";

boardControl.$inject = [
    "$scope",
    "serviceDialog",
    "$state",
    "factoryIsEmailConfirmed",
    "$stateParams",
    "factoryProfile",
    "serviceMenu",
    "factoryExit",
    "serviceToast",
    "$timeout",
    "$translate",
    "serviceNavBoard",
    "$mdSidenav",
    "getPermits",
    "constReplaceFont",
    "$window",
    "langCode",
    "factoryEnvironment",
    "lvMixpanel",
    "permissions",
    "serviceSessions",
  ];
  
  function boardControl(
    $scope,
    serviceDialog,
    $state,
    factoryIsEmailConfirmed,
    $stateParams,
    factoryProfile,
    serviceMenu,
    factoryExit,
    serviceToast,
    $timeout,
    $translate,
    serviceNavBoard,
    $mdSidenav,
    getPermits,
    constReplaceFont,
    $window,    
    langCode,
    factoryEnvironment,
    lvMixpanel,
    permissions,
    serviceSessions,
  ) {
	const unsubscribers = [];
	let envValues = {
		sourceLink: window.location.origin + '/#/login',
		mixpanelApiToken: "",
		saltIdLogoutUrl: ""
	}
	// let constantMenu;
	let constantMenu = $scope.constantMenu = [];

	$scope.ownerId = $stateParams.id;

	$scope.timeCardEnabled = false;
	$scope.window = $window;

	let unleash;

	setEnvironment();

	function setEnvironment() {
		factoryEnvironment.getEnv().then(function (data) {
			envValues.sourceLink = data.loginForm;
			envValues.mixpanelApiToken = data.mixpanelApiToken;
			envValues.saltIdLogoutUrl = data.saltIdLogoutUrl;
		}).then(function () {
			isAuthRegister();
			lvMixpanel.init(envValues.mixpanelApiToken);
		})
	}

	function isAuthRegister() {
		factoryIsEmailConfirmed.isAuthRegister().then(function (data) {
			if (data.status === 'false') {
				window.location.href = envValues.sourceLink;
			}
		});
	}

	function initFullstory(profile) {
		factoryEnvironment.getEnv()
			.then(env => {

				unleash = new UnleashClient({
					url: 'https://eu.app.unleash-hosted.com/eubb7019/api/frontend',
					clientKey: env.unleashClientKey,
					appName: 'loyverse-backoffice',
					disableRefresh: true
				});

				unleash.updateContext({
					userId: profile.ownerId,
					country: profile.country,
					daysAfterSignUp: Math.floor((Date.now() - profile.created) / (1000 * 3600 * 24)),
					deviceId: getDeviceId()
				});

				unleash.on('update', () => {
					if (unleash.isEnabled('Record_session_in_Fullstory')) {
						FullStory.init({
							host: 'eu1.fullstory.com',
							script: 'edge.eu1.fullstory.com/s/fs.js',
							orgId: 'o-Y1S-eu1'
						}, () => {

							FullStory.identify(profile.ownerId, {
								displayName: profile.name,
								email: profile.email
							});
							unsubscribers.push(() => {
								FullStory.anonymize();
							});
						});
					}
				});

				unleash.start();

				unsubscribers.push(() => {
					unleash.stop();
				});

				function getDeviceId() {

					let machineId = localStorage.getItem('__DeviceId');

					if (!machineId) {
						machineId = crypto.randomUUID();
						localStorage.setItem('__DeviceId', machineId);
					}

					return machineId;
				}
			});
	}

	$scope.email = {
		value: '...'
	};

	permissions.updatePermissions()
		.then(() => {
			constantMenu = $scope.constantMenu = serviceMenu.constantMenu;
		});

	permissions.profileUpdate.observe(profileUpdated);

	function profileUpdated(profile) {
		/*The function changes the meaning of the style body for the selected languages when comparing
        the cabinet language with the languages from the list. This comes before the whole page loads.*/
		function replaceFontBody(_profile){
			var elem = document.getElementsByTagName('body')[0];
			var cond = constReplaceFont.indexOf(_profile.cabinetLang) !== -1;
			if (cond){
				elem.style.fontFamily = "Noto Sans, sans-serif";
			}
		}
		replaceFontBody(profile);

		/*The function changes the meaning of the style for tags then selected languages comparing
       the cabinet lang*/
		function replaceFontTags(_profile){
			var allElem = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
			var cond = constReplaceFont.indexOf(_profile.cabinetLang) !== -1;
			if (cond){
				for(var i = 0; i < allElem.length; i++){
					allElem[i].style.fontFamily = "Noto Sans, sans-serif";
				}
			}
		}

		setTimeout(function(){
			replaceFontTags(profile);
		}, 2500);

		$scope.technicalWorksMessage = profile.technicalWorksMessage;
		if($scope.technicalWorksMessage) {
			$scope.technicalWorksFrom = profile.technicalWorksFrom;
			$scope.technicalWorksTo = profile.technicalWorksTo;
		}
		$scope.warningMessageEnabled = profile.warningMessageEnabled;
		$scope.lang = langCode[profile.cabinetLang];
		$scope.arapro = profile.cabinetLang === 'ara' || profile.cabinetLang ==='urd' || profile.cabinetLang ==='heb';
		$scope.exitButtonDirection = $scope.arapro ? "" : "target-right target";
		$scope.exitButtonTop = $scope.arapro ? "8 40" : "-8 40";
		$scope.exitButtonDirectionCollapsed = $scope.arapro ? "target-right target" : "";
		$scope.exitButtonTopCollapsed = $scope.arapro ? "-54 0" : "54 0";
		$scope.ownerId = profile.ownerId;

// КОД ПОД БИЛЛИНГ 03-11-17
		$scope.dateBilling = profile.billingInfo;
// КОД ПОД БИЛЛИНГ 03-11-17

		$scope.email.value = profile.email;
		$scope.merchantName = profile.merchantName ? profile.merchantName : 'OWNER';
		$scope.timeCardEnabled = profile.timeCardEnabled;
		$scope.inventoryEnabled = profile.inventoryEnabled;
		$scope.useOpenedReceipts = profile.useOpenedReceipts;
		$scope.useKitchenPrinter = profile.useOpenedReceipts;
		$scope.useDiningOptions = profile.useOpenedReceipts;
		$scope.integrationEnabled = profile.integrationEnabled;

		$scope.noAlertState = $scope.dateBilling.paymentMethod === null && ($window.location.href.indexOf("payment/bt") !== -1 || $window.location.href.indexOf("payment/str") !== -1);
		$scope.chargebeeIncluded = $scope.dateBilling.gw === 'bs' || $scope.dateBilling.gw === 'wc';

		$timeout(function () {
			$scope.showDemoMessage = angular.copy($scope.warningMessageEnabled);
		}, 2000);
	}

	factoryProfile.getProfile()
		.then( function (d) {
			initFullstory(d);
            serviceSessions.onLogin(d);
			
            $scope.$watch('window.location.href', function (newVal, oldVal) {
				if(newVal !== oldVal) {
					$scope.noAlertState = $scope.dateBilling.paymentMethod === null && ($window.location.href.indexOf("payment/bt") !== -1 || $window.location.href.indexOf("payment/str") !== -1);
				}
			});

			$timeout(function() {
				showHiddenSidenav();
			}, 0);
		} )

	$scope.$on('revertPageForArab', function (event, val) {
		$scope.arapro = (val === 'ara' || val === 'urd' || val === 'heb');
		$scope.exitButtonDirection = $scope.arapro ? "" : "target-right target";
		$scope.exitButtonTop = $scope.arapro ? "8 40" : "-8 40";

		$timeout(function() {
			showHiddenSidenav();
		}, 0);

	});

	$scope.$on('setHTMLTeg', function (event, val) {
		$scope.lang = langCode[val];
	});

	$scope.$on('dontShowBanner', function () {
		$scope.technicalWorksMessage = false;
	});

	$scope.$on('changeAccountEmail', function (event, val) {
		$scope.email.value = val;
	});

	$scope.$on('enableIntegrations', function () {
		permissions.enableIntegrations();
	});

	$scope.tryExit = function (skipToast) {

		factoryExit().then(
			function (res) {
				if (!skipToast) {
					serviceToast.show('FINISH_WORK', 'success');
				}
                
                serviceSessions.onLogout();

				$timeout( function () {
					lvMixpanel.track('Sign out (Backoffice)');
					unsubscribe();
					window.location.href = res.saltClientId
						? envValues.saltIdLogoutUrl + "?post_logout_redirect_uri=" + envValues.sourceLink + "&client_id=" + res.saltClientId
						: envValues.sourceLink;
				}, 500 );
			},
			function () {
				if (!skipToast) {
					serviceToast.show( 'FINISH_WORK', 'error' );
				}
				$timeout( function () {
					unsubscribe();
					window.location.href = envValues.sourceLink;
				}, 500);
			}
		);
	};

	function logoutOnRestriction() {
		$scope.tryExit(true);
	}

	let throttledLogout = _.throttle(logoutOnRestriction, 10, {leading: false});

	$scope.$on('logoutRequired', () => {
		throttledLogout();
	});

	$scope.goToMyAccount = function() {
		$state.go('myaccount', {});
	};

	$scope.serviceMenu = serviceMenu;

	$scope.state = $state;


	/**
	 * $scope.deployedNav ->   Адрес темплейта для выпадающей панели навигации
	 */
	$scope.deployedNav = null;

	$scope.boardClick = function () {
		if ( $scope.serviceMenu.navBoard === 'nav-close' ) {
			$scope.serviceMenu.navBoard = 'nav-open';
			var overlay = document.getElementsByClassName('droverOverlay')[0];
			overlay.style.display = 'block';
		} else {
			//$scope.serviceMenu.navBoard ='nav-close';
		}
	};

	$scope.collapseMenu = function ( menu ) {
		var menus = $scope.constantMenu;
		menu.childrenShow = !menu.childrenShow;
		for ( var i = 0; i < menus.length; i++ ) {
			if ( menu !== menus[i] && menus[i].children ) {
				menus[i].childrenShow = false;
			}
		}
	};

	$scope.collapseChildren = function ( menu ) {
		if(menu.uiSref){
			var arr = menu.uiSref.match(/([^\)]+)\((.*)\)/);
			if(arr !== null) {
				var name = arr[1];
				var parm = arr[2];
			} else {
				var name = menu.uiSref;
			}
			var objParm = {};
			if(parm){
				var a = parm.split(',');
				for(var i = 0; i< a.length; i++){
					objParm[a[i].split(':')[0].replace(/[{}\s]/, '')] = a[i].split(':')[1].replace(/[{}\s]/, '');
				}
			}
			$state.go(name, objParm);
		}else{
			menu.childrenShow = !menu.childrenShow;
		}

	};

	function collapseAll( arr ) {
		var i;
		for ( i = 0; i < arr.length; i++ ) {
			if ( arr[i].children && arr[i].children.length ) {
				collapseAll(arr[i].children);
			} else {
				arr[i].active = false;
			}
			if ( arr[i].childrenShow ) {
				arr[i].childrenShow = false;

			}
		}
	}

	function onOpenMenu( parent ) {
		if ( parent ) {
			parent.childrenShow = true;
			if ( parent.parent ) {
				onOpenMenu( parent.parent )
			}
		}
	}

	function unCollapseFind( arr, name ) {
		var i;
		for ( i = 0; i < arr.length; i++ ) {
			if ( arr[i].children ) {
				unCollapseFind( arr[i].children, name );
			}
			if ( arr[i].uiSref ) {
				var state = arr[i].uiSref.replace( /\(.+\)/, "" );
				if ( state === name ) {
					arr[i].active = true;
					onOpenMenu( arr[i].parent );
					break;
				}
			}
		}
	}

	//успешный переход с 1 стр на другую
	$scope.$on( '$stateChangeSuccess', function ( event, toState, toParams, fromState, fromParams ) {
		collapseAll( $scope.constantMenu ); //закрываем все меню
		// $mdSidenav('left').close();//close the side panel to view the check (arab)
		// $mdSidenav('right').close();//закрываем боковую панель для просмотра чека
		if (!$scope.arapro) {
			$mdSidenav('right').close();
		} else {
			$mdSidenav('left').close();
		}
		unCollapseFind( $scope.constantMenu, toState.name ); // открываем нужное
		var overlay = document.getElementsByClassName('droverOverlay')[0];
		if(window.innerWidth < 1600) {
			$scope.serviceMenu.navBoard = 'nav-close';
		}
		if(window.innerWidth < 601) {
			overlay.style.display = 'none';
		}
		clearOpenClass();
		serviceNavBoard.deployMenu = null;
		serviceNavBoard.deployedNav = null;
		$scope.deployedNav = null;


		//--  isbcmob variable checks if there is a class buttons-container-mob and add class mb-68
		$timeout( function () {
			$scope.isbcmob = document.getElementsByClassName('buttons-container-mob')[0];
		}, 500 );

	} );

	function setOpenClass( menu ) {
		clearOpenClass( menu );
		menu.isOpen = "opened";
	}

	function clearOpenClass( menu ) {
		for ( var i = 0; i < constantMenu.length; i++ ) {
			if ( menu ) {
				if ( menu != constantMenu[i] ) {
					menu.isOpen = null;
					angular.element(document.getElementsByClassName('menu-item')[i]).removeClass('hover-color');
				}
			} else {
				constantMenu[i].isOpen = null;
				angular.element(document.getElementsByClassName('menu-item')[i]).removeClass('hover-color');
			}
		}
	}

	$scope.onDeployedNav = function ( menu ) {
		// console.log("menu = ", menu);
		if ( menu.children && menu.children.length ) {
			if(serviceNavBoard.deployMenu === menu) {
				serviceNavBoard.deployedNav = null;
				clearOpenClass();
				serviceNavBoard.deployMenu = null;
			} else {
				setOpenClass(menu);
				// var topPosition = document.getElementsByClassName(menu.name)[0].offsetTop + 47;
				var topPosition = document.getElementsByClassName(menu.name)[0].getBoundingClientRect().top;
				var bottomPosition = document.getElementsByClassName(menu.name)[0].getBoundingClientRect().bottom;
				angular.element(document.getElementsByClassName(menu.name)[0]).parent().addClass('hover-color');
				serviceNavBoard.deployedNav = "dashboard-page/templates/navigator/report.html";
				if(window.innerHeight - topPosition - 126 < 48) {
					document.querySelector('.deploy-nav-container').style.top = (bottomPosition - 126) + "px";
				} else {
					document.querySelector('.deploy-nav-container').style.top = topPosition + 'px';
				}

				serviceNavBoard.deployMenu = menu;
			}
			// serviceMenu.depVenu = true;
		} else {
			$scope.deployedNav = null;
			// serviceMenu.depVenu = false;
			clearOpenClass();
			serviceNavBoard.deployedNav = null;
		}
	};

	$scope.$watch( 'serviceMenu.navBoard', function ( val ) {
		if ( angular.isString( val ) && val == 'nav-open' ) {
			$scope.deployedNav = null;
			clearOpenClass();
		}
	} );

	var settingsMenuIndex = 0;
	for(var i = 0; i < serviceMenu.constantMenu.length; i++){
		if(serviceMenu.constantMenu[i].name === "settings.profile") {
			settingsMenuIndex = i;
			break;
		}
	}

	window.addEventListener('resize', function(){
		serviceMenu.constantMenu[settingsMenuIndex].children = (window.innerWidth > 600) ? [] : serviceMenu.settingsMobileMenu;
		var overlay = document.getElementsByClassName('droverOverlay')[0];
		if(overlay.style.display === 'block' && window.innerWidth > 600) {
			overlay.style.display = 'none';
		}
		// else {
		 //    serviceMenu.showOpenReceipts($scope.useOpenedReceipts);
			// serviceMenu.showKitchenPrinyer($scope.useKitchenPrinter);
			// serviceMenu.showDiningOptins($scope.useDiningOptions);
		// }
	});

	//--  exists class buttons-container-mob or not
	$scope.isbcmob = document.getElementsByClassName('buttons-container-mob')[0];

	function showHiddenSidenav() {
		var sidenav
		if ($scope.arapro) {
			sidenav = document.getElementById('sidenav-left');
		} else {
			sidenav = document.getElementById('sidenav-right');
		}
		var isHidden = angular.element(sidenav).hasClass('hidden');

		if (isHidden) {
			angular.element(sidenav).removeClass('hidden');
		}
	}

	function unsubscribe() {
		unsubscribers.forEach(unsub => unsub());
		unsubscribers.length = 0;
	}
}

export default {
	type: 'controller',
	name: 'boardControl',
	value: boardControl
};