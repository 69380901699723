/**
 * Created by kseniya.yarosh on 15.12.16
 *
 * @memberof lvMerchantOutlets
 * @ngdoc directive
 * @name app.directive:lvMerchantOutlets
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Merchants filter (ul li list)
 *
 * <div lv-merchant-multi="ctrl.merchant"></div>
 */

import angular from 'angular'
import template from '../templates-pug/for-directive/merchant-outlet-filters.pug';

lvMerchantOutletFilters.$inject = [
    '$filter',
    '$state',
    '$timeout'
];

// TODO: Oleksii: controller ???
function lvMerchantOutletFilters($filter, $state, $timeout) {
    return{
        restrict: 'E',
        scope: true,
        controller: lvMerchantOutletFilters,
        controllerAs: 'lvMerchantOutletFilters',
        link: linkFn,
        template: template
    };

    function linkFn ($scope) {

        var translate = $filter("translate");

        $timeout(function(){
            // var isValidForOutlet = document.getElementsByTagName('md-select')[1];
            var mdSelectForOutlet = document.getElementsByTagName('md-select-value')[1];
            var mdSelectLabelForOutlet = document.getElementById('outletForTimeTracking');

            $scope.actualMerchants = angular.copy($scope.$parent.actualMerchants);
            $scope.actualOutlets = angular.copy($scope.$parent.actualOutlets);
            $scope.actualMerchant = {};
            $scope.actualOutlet = {};
            $scope.required = false;

            $scope.selectActualMerchant = selectActualMerchant;
            $scope.selectActualOutlet = selectActualOutlet;
            // $scope.timecardForm = $scope.$parent.timecardForm;

            $scope.actualStaff = [{}, {}];


            if(mdSelectForOutlet && mdSelectLabelForOutlet) {
                var mdSelectIcon = mdSelectForOutlet.childNodes[1];
                // console.log(mdSelectIcon);
                //mdSelectIcon.classList.add('md-select-icon');

                $scope.$watch('actualStaff', function (newValues, oldValues) {
                    if (newValues[0].id !== null && oldValues !== newValues) {
                        for (var merchant in newValues[1].merchants) {
                            if (newValues[0].id === newValues[1].merchants[merchant].id) {
                                $scope.actualOutlet = newValues[1];
                                $scope.$parent.actualOutletId = $scope.actualOutletId = newValues[1].id;
                                mdSelectForOutlet.textContent = newValues[1].name;
                                mdSelectLabelForOutlet.style.display = "inline-block";
                                mdSelectForOutlet.style.color = "initial";
                                $scope.timecardForm0.outlet.$setValidity("outlet", true);
                                // isValidForOutlet.classList.add("ng-valid");
                                // isValidForOutlet.classList.remove("ng-invalid");
                                break;
                            } else {
                                if (newValues[0].id) {
                                    $scope.timecardForm0.outlet.$setValidity("outlet", false);
                                    $scope.$parent.actualOutlet = $scope.actualOutlet = {};
                                    // console.log($scope.actualStaff);
                                    $scope.$parent.actualOutletId = $scope.actualOutletId = null;
                                    mdSelectForOutlet.classList.add('md-select-placeholder');
                                    mdSelectForOutlet.textContent = translate("MENU_GROUP.ESTABLISHMENT");
                                    mdSelectLabelForOutlet.style.display = "none";
                                    mdSelectForOutlet.style.color = "rgba(0,0,0,0.54)";
                                    //mdSelectIcon.classList.add('md-select-icon');
                                    // isValidForOutlet.classList.add("ng-invalid");
                                    // // isValidForOutlet.classList.remove("ng-valid");
                                    // console.log(isValidForOutlet);
                                }
                            }
                        }
                    }
                }, true);

            }

            function selectActualMerchant(actualMerchant){
                $scope.actualStaff[0] = $scope.actualMerchant = angular.copy(actualMerchant);
                $scope.$parent.actualMerchantId = $scope.actualMerchantId = $scope.actualMerchant.id;
            }

            $scope.showInput = $scope.$parent.showInput ? true : ($state.current.name === 'employees.edittimecard') ? true : false;
            $scope.showSelect = $scope.$parent.showSelect ? true : ($state.current.name === 'employees.createtimecard') ? true : false;

            $scope.merchantName = $scope.$parent.merchantName ;
            $scope.outletName = $scope.$parent.outletName;


            function selectActualOutlet(actualOutlet){
                if($scope.actualOutlet !== null ){
                    $scope.actualStaff[1] = $scope.actualOutlet = angular.copy(actualOutlet);
                    $scope.$parent.actualOutletId = $scope.actualOutletId = $scope.actualOutlet.id;
                } else {
                    $scope.$parent.actualOutlet = $scope.actualStaff[1] = $scope.actualOutlet = {};
                    $scope.$parent.actualOutletId = $scope.actualOutletId = null;
                }
            }

            $scope.$watch('actualMerchant', function (newVal, oldVal) {
                //console.log($scope.actualMerchant);
                if(newVal !== null && newVal !== oldVal){
                    $scope.actualMerchant = newVal;
                    $scope.actualMerchantId = $scope.$parent.actualMerchantId = newVal.id;
                    if($scope.$parent.initialOutlets.length > 1){
                        loopingThroughOutletsWithMerchants($scope.$parent.initialOutlets);
                    }
                    watchingOutletsArrow();
                    // $scope.actualOutletId = $scope.actualOutlets[0].id;
                }
            }, true);

            // Костыль Алексея Ш. ддя того, чтобы стрелка в поле аутлетов не пропадала после выбора сотрудника.

            $scope.$watch('actualOutlet', function (newVal, oldVal) {
                if(newVal !== null && newVal !== oldVal){
                    watchingOutletsArrow();
                }
            }, true);

            function watchingOutletsArrow() {
                var outletValue = $('.actual-outlets md-select-value');
                if(outletValue.has('span').length === 0) {
                    outletValue.wrapInner('<span></span>').append('<span class="md-select-icon"></span>');
                }

            // function watchingOutletsArrow() {
            //     var outletValue = $('.actual-outlets md-select-value');
            //     outletValue.has('span').length ? console.log('Hello span!') : outletValue.wrapInner('<span></span>').append('<span class="md-select-icon"></span>');

            }

            // Костыль Алексея Ш. ддя того, чтобы стрелка в поле аутлетов не пропадала после выбора сотрудника.

            function loopingThroughOutletsWithMerchants (arrWithObj) {
                var arrWithOutletsForCurrentMerchant = [];

                arrWithObj.forEach(function(obj){
                    obj.merchants.forEach(function(merch){
                        if($scope.actualMerchant && merch.id === $scope.actualMerchant.id){
                            arrWithOutletsForCurrentMerchant.push(obj);
                        };
                    });

                    $scope.actualOutlets = (arrWithOutletsForCurrentMerchant.length > 0) ? arrWithOutletsForCurrentMerchant : $scope.actualOutlets;

                });
            }

        }, 1);

    }

}

export default {
    type: 'directive',
    name: 'lvMerchantOutletFilters',
    value: lvMerchantOutletFilters
};