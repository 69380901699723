/**
 * Created by yarosh on 06.03.18.
 */

const salesReportTable = {
    saleSum       : 'REPORT_SALES.THE_SALE',
    refundSum     : 'REPORT_SALES.REFUNDS_OLL',
    discountSum   : 'MENU_GROUP.DISCOUNTS',
    netSalesSum   : 'REPORT_SALES.NET_SALES',
    primeCost     : 'REPORT_GOODS.PRIME_COST_ITEM',
    grossProfit   : 'REPORT_GOODS.GROSS_PROFIT',
    margin        : 'MARGIN_PERCENT',
    taxSum        : 'TAX'
};

export default {
    type: 'constant',
    name: 'salesReportTable',
    value: salesReportTable
}