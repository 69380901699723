/**
 * @ngdoc module
 * @name lv.calendar
 * Календарь
 */

import './scss/calendar.scss';

import angular from 'angular';
import ngTranslate from 'angular-translate';
import localDays from './constant/constant-local-days';
import constantCalendar from './service/service-constant-calendar';
import serviceDaysWeek from './service/service-days-week';
import serviceEvents from './service/serviceEvents';
import calendarSettings from './factory/factory-calendar-settings';
import factoryPosition from './factory/factory-position';
import formatMonth from './factory/formatMonth';
import calendar from './directive/directive-calendar';
import calendarView from './directive/directive-calendar-view';
import daysweek from './directive/directive-daysweek';
import myConteinerMonth from './directive/directive-my-conteiner-month';
import validCal from './directive/directive-valid-cal';
import label from './directive/directive-label';
import myDay from './directive/directive-my-day';
import infoLabelBefore from './directive/directive-info-label-before';
import infoLabelAfter from './directive/directive-info-label-after';
import infoLabelExact from './directive/directive-info-label-exact';
import dateAfterValidator from './directive/directive-date-after-validator';
import dateLocalizedValidator from "./directive/directive-date-localized-validator";
import calendarContrl from './controller/calendarContrl';
import timeRangeContrl from './controller/timeRangeContrl';

import calendarModuleRun from './lv-calendar.module.run';
import calendarModuleRunTemplate from './lv-calendar.module.run.template';
import billingMessage from "./directive/directive-billing-message";
import serviceBillingSalesHistory from "../../common/webpack/service/service-billing-saleshistory";

export default angular
	.module( 'lv.calendar', [ngTranslate])
	.constant('localDays', localDays)
	.service('constantCalendar', constantCalendar)
	.service('serviceDaysWeek', serviceDaysWeek)
	.service('serviceEvents', serviceEvents)
	.service('serviceBillingSalesHistory', serviceBillingSalesHistory)
	.factory('calendarSettings', calendarSettings)
	.factory('factoryPosition', factoryPosition)
	.factory('formatMonth', formatMonth)
	.directive('billingMessage', billingMessage)
	.directive('calendar', calendar)
	.directive('calendarView', calendarView)
	.directive('daysweek', daysweek)
	.directive('myConteinerMonth', myConteinerMonth)
	.directive('myDay', myDay)
	.directive('validCal', validCal)
	.directive('labelTemplate', label.labelTemplate)
	.directive('labelTemplateForDay', label.labelTemplateForDay)
	.directive('labelTemplateForMonth', label.labelTemplateForMonth)
	.directive('labelTemplateExact', label.labelTemplateExact)
	.directive('infoLabelBefore', infoLabelBefore)
	.directive('infoLabelAfter', infoLabelAfter)
	.directive('infoLabelExact', infoLabelExact)
	.directive('dateAfter', dateAfterValidator)
	.directive('dateLocalized', dateLocalizedValidator)
	.controller('calendarContrl', calendarContrl)
	.controller('timeRangeContrl', timeRangeContrl)
	.run(calendarModuleRun)
	.run(calendarModuleRunTemplate)
	.name;
