/**
 * Created by sirosh on 22.01.16.
 */
const constTaxTypes = [
    {
        id: 'INCLUDED',
        name: 'SETTINGS.TAX.INCLUDED'
    }, {
        id: 'ADDED',
        name: 'SETTINGS.TAX.ADDED'
    }
];

export default {
    type: 'constant',
    name: 'constTaxTypes',
    value: constTaxTypes
};