// /**
//  * Created by A.Sirosh on 13.10.2015.
//  * @memberof customSearch
//  * @ngdoc directive
//  * @name customSearch
//  * @requires $stateParams {service} UI Router servise
//  */
// angular.module('app')
// 	.directive('customSearch', [ '$stateParams',
// 		function ($stateParams) {
// 			return {
//
// 				restrict: 'A',
// 				/**
// 				 * @param scope
// 				 * @param element
// 				 * @param attrs
// 				 */
// 				link: function (scope, element, attrs) {
//
// 					var titleWrap = element.children('.titleWrap');
// 					var search = element.children('.search');
//
// 					if($stateParams.search === undefined || $stateParams.search === null || $stateParams.search === ''){
// 						search.addClass('disable');
// 						titleWrap.removeClass('disable');
// 					} else{
// 						titleWrap.addClass('disable');
// 						search.removeClass('disable');
// 					}
//
// 					element.on('click', function (event) {
// 						var enableSearch = titleWrap.children('.enableSearch');
// 						var startSearch = search.children('.startSearch');
// 						var input = element.find('input');
//
// 						if (event.target.id == 'enableSearch') {
// 							titleWrap.addClass('disable');
// 							search.removeClass('disable');
// 							element.addClass('activeTableSearch');
// 							input.focus();
// 						} else if(event.target.id == 'searchDone'){
// 							input.val('');
// 							search.addClass('disable');
// 							titleWrap.removeClass('disable');
// 							element.removeClass('activeTableSearch');
// 							input.blur();
// 						}
// 					});
// 				}
// 			};
// 		}
// 	]);

export default null;