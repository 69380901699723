createDirective.$inject = [];

function createDirective() {

    return {
        restrict: 'A',
        controller: AdaptiveContainerController
    };
}

AdaptiveContainerController.$inject = ['$scope', '$element', '$compile', '$mdUtil'];
function AdaptiveContainerController($scope, $element, $compile, $mdUtil) {

    this.registerContent = registerContent;
    this.registerItem = registerItem
    this.registerClone = registerClone;

    this.$onInit = $onInit;
    this.$postLink = $postLink;
    this.$onDestroy = $onDestroy;

    let fontClass = {
        sm: 'fz-16',
        md: 'fz-24',
        lg: 'fz-34'
    };
    let layoutClass = {
        sm: 'adaptive-item-content--sm',
        md: 'adaptive-item-content--md',
        lg: 'adaptive-item-content--lg'
    };

    let currentFont = 0;
    let currentLayout = 0;

    let layoutClasses = [layoutClass.sm, layoutClass.md, layoutClass.lg];
    let fontClasses = [fontClass.sm, fontClass.md, fontClass.lg];
    let originPosition;
    let content;
    let contentClone;
    let items = [];
    let clones = [];
    let throttledResize = _.throttle(onResize, 200, {leading: false});

    function registerContent(contElem, _contentClone) {

        // Save transparent content clone do adjust layout before display

        content = contElem;
        content.css({
            display: 'flex'
        });
        contentClone = _contentClone;
        contentClone.css({
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            'z-index': '-1'
        });
        contentClone.attr({
            'lv-adaptive-content': null
        });

        let cloneItems = contentClone.find('[lv-adaptive-item]')
        cloneItems.attr({
            'lv-adaptive-item': null,
            'lv-adaptive-item-clone': '',
        });

        $element.append(contentClone);

        $compile(contentClone)($scope);
    }

    function registerItem(item) {
        items.push(item);
    }

    function registerClone(cloneItem) {
        clones.push(cloneItem);
    }

    function $onInit() {
        originPosition = $element.css('position');
        $element.css({
            position: 'relative'
        });

        window.addEventListener('resize', throttledResize);
    }

    function $postLink() {
        $mdUtil.nextTick(onResize);
    }

    function $onDestroy() {
        $element.css({
            position: originPosition
        });
        window.removeEventListener('resize', throttledResize);
    }

    function onResize() {
        let result = getCurrentSize(currentFont, currentLayout);
        currentFont = result.font;
        currentLayout = result.layout;
        setFont(result.font, true);
        setLayout(result.layout, true);
    }

    function getCurrentSize(_font, _layout, def) {
        if (isOverflowed()) {
            return reduce(_font, _layout, def);
        } else {
            return increase(_font, _layout, def);
        }
    }

    function increase(fontIndex, layoutIndex) {
        let mode = getIncreaseMode(fontIndex, layoutIndex);

        let result = {
            font: fontIndex,
            layout: layoutIndex
        }

        if (mode.font) {
            if (trySetFont(fontIndex + 1)) {
                result = getCurrentSize(fontIndex + 1, layoutIndex);
            }
        } else if (mode.layout) {
            setLayout(layoutIndex + 1);
            if (!isOverflowed()) {
                result = getCurrentSize(fontIndex, layoutIndex + 1);
            }
        }

        return result;
    }

    function reduce(fontIndex, layoutIndex) {
        let mode = getReduceMode(fontIndex, layoutIndex);

        let result = {
            font: fontIndex,
            layout: layoutIndex
        };

        if (mode.font) {
            setFont(fontIndex - 1);
            result = getCurrentSize(fontIndex - 1, layoutIndex);
        } else if (mode.layout) {
            setLayout(layoutIndex - 1);
            result = getCurrentSize(fontIndex, layoutIndex - 1);
        }

        return result;
    }

    function trySetFont(fontIndex) {
        setFont(fontIndex);
        return !isOverflowed();
    }

    function isOverflowed() {
        return _.some(clones, clone => clone.isOverflowed());
    }

    function setFont(fontIndex, includeContent) {
        clones.forEach(clone => {
            clone.setFont(fontIndex, fontClasses);
        });
        if (includeContent) {
            items.forEach(item => {
                item.setFont(fontIndex, fontClasses);
            });
        }
    }

    function setLayout(layoutIndex, includeContent) {
        currentLayout = layoutIndex;

        layoutClasses.forEach((layoutClass, index) => {
            contentClone.toggleClass(layoutClass, index === currentLayout);
        });

        if (includeContent) {
            layoutClasses.forEach((layoutClass, index) => {
                content.toggleClass(layoutClass, index === currentLayout);
            });
        }
    }

    function getReduceMode(fontIndex, layoutIndex) {
        let result = {
            font: false,
            layout: false
        };

        switch (fontIndex) {
            case 2:
                result.font = true;
                break;
            case 1:
                switch (layoutIndex) {
                    case 2:
                    case 1:
                        result.layout = true;
                        break;
                    case 0:
                        result.font = true;
                        break;
                }
                break;
            case 0:
                switch (layoutIndex) {
                    case 2:
                    case 1:
                        result.layout = true;
                        break;
                }
                break;
        }

        return result;
    }

    function getIncreaseMode(fontIndex, layoutIndex) {
        let result = {
            font: false,
            layout: false
        };

        switch (fontIndex) {
            case 0:
                result.font = true;
                break;
            case 1:
                switch (layoutIndex) {
                    case 0:
                    case 1:
                        result.layout = true;
                        break;
                    case 2:
                        result.font = true;
                        break;
                }
                break;
            case 2:
                switch (layoutIndex) {
                    case 0:
                    case 1:
                        result.layout = true;
                        break;
                }
                break;
        }

        return result;
    }
}

export default {
    type: 'directive',
    name: 'lvAdaptiveContainer',
    value: createDirective
};
