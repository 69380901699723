/**
 * Created yarosh on 31.05.17.
 */

formatDateByBrowser.$inject = ['retrieveLangCode'];
function formatDateByBrowser( retrieveLangCode ) {
    /**
     * Filter format dates according to local standards
     * @param val
     * @returns {String}
     */

    function formatDateByBrowser ( date, option ) {
        return retrieveLangCode.retrieveBrowserDateFormat( date, option );
    }

    formatDateByBrowser.$stateful = true;

    return formatDateByBrowser;
}

export default {
    type: 'filter',
    name: 'formatDateByBrowser',
    value: formatDateByBrowser
};