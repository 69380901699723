factoryLinks.$inject = ["siteLangCode", "langSets"];

function factoryLinks(siteLangCode, langSets) {
  return {
    help: (profile, link) => {
      const code = siteLangCode[profile.cabinetLang];

      return [
        "https://help.loyverse.com",
        langSets.timeClockLangs.includes(code) ? code : "",
        link,
      ]
        .filter((i) => i !== "")
        .join("/");
    },
  };
}

export default {
  type: "factory",
  name: "factoryLinks",
  value: factoryLinks,
};
