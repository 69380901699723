/**
 * Created by sirosh on 04.02.16.
 */

function formatPhone() {
    /**
     * Filter format phone number
     * @param val
     * @returns {String}
     */
    function format(val){
        if(val){
            var result;
            var len = val.length;

            var resultArr = [
                val.substring(0, len-7),
                val.substring(len-7, len-4),
                val.substring(len-4, len)
            ];

            result = resultArr.join(' ');

            return result;
        }
    }
    return format;
}

export default {
    type: 'filter',
    name: 'formatPhone',
    value: formatPhone
};