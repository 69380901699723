timeRangeLabelTemplate.$inject = ['$templateCache', 'calendarSettings'];
function timeRangeLabelTemplate($templateCache, calendarSettings) {
    return {
        restrict: 'C',
        // template: calendarSettings.config.timeRangeLabelTemplate || $templateCache.get('../module/calendar/module/partials/time-range-label-template.html')
        template: calendarSettings.config.timeRangeLabelTemplate // TODO: Oleksii: no templates $templateCache.get('../module/calendar/module/partials/time-range-label-template.html')
    }
}

wholeDayLabelTemplate.$inject = ['$templateCache', 'calendarSettings'];
function wholeDayLabelTemplate($templateCache, calendarSettings) {
    return {
        restrict: 'C',
        // template: calendarSettings.config.wholeDayLabelTemplate || $templateCache.get('../module/calendar/module/partials/time-range-label-template.html')
        template: calendarSettings.config.wholeDayLabelTemplate // TODO: Oleksii: no templates $templateCache.get('../module/calendar/module/partials/time-range-label-template.html')
    }
}

// TODO: Oleksii: no templates
// timeRangeLabelTemplateExact.$inject = ['$templateCache', 'calendarSettings'];
// function timeRangeLabelTemplateExact($templateCache, calendarSettings) {
//     return {
//         restrict: 'C',
//         template: $templateCache.get('../module/calendar/module/partials/time-range-label-template-exact.html')
//     }
// }

export default {
    timeRangeLabelTemplate,
    wholeDayLabelTemplate
}