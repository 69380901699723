/**
 * Created by Alex on 8.11.2016.
 */

function filterTaxPercentFormatSave() {
	function format(string, delimiter){

		if(typeof string != 'string'){
			string = string.toString();
		}
		string = string.replace(',','.');
		var pos = string.indexOf('.');




		// console.log("pos = ", pos);


		if(pos == -1){
			return string+='00000';
		}

		string = string.replace('.', '');
		string+='00000';
		var arr  = string.split('');


		// console.log("arr = ", arr);


		if(0<pos){
			arr.splice(pos+5,0,'.');
		}
		string = arr.join('');
		string = parseFloat(string);
		string = ''+string;
		return string;

	}

	return format;
}

export default {
	type: 'filter',
	name: 'filterTaxPercentFormatSave',
	value: filterTaxPercentFormatSave
};