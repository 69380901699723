calendarSettings.$inject = [];

function calendarSettings() {
     var params = {
         config: {
             labelTemplateForDay: "{{after | formatDateByBrowser:'calendarLabel' }}",
             labelTemplateForMonth: "{{after | formatDateByBrowser:'calendarMonthAndYear' }}",
             labelTemplate: "{{after | formatDateByBrowser:'calendarLabel' }} - {{before | formatDateByBrowser:'calendarLabel' }}",
             labelTemplateExact: "{{ exact | formatDateByBrowser:'calendarLabel' }}",
             timeRangeLabelTemplate: "{{ after | formatDropDownTime }} - {{ before | formatDropDownTime }}",
             wholeDayLabelTemplate: "{{ 'WHOLE_DAY' | translate }}"
         }
     };
    return params
}

export default calendarSettings;