/**
 * Created by A.Sirosh on 30.10.2015.
 */

function coinToCurrencyChart() {
	/**
	 * Filter coin to currency for google  chart
	 * @param val
	 * @returns {Number}
	 */
	function format(val) {

		if (val !== undefined) {

			var modulo = '.';
			var result, lastTwo, first;
			var value = val.toString();
			var length = value.length;


			if (length === 1) {
				value = '00' + value;
				lastTwo = value.substring(length);
				first = value.substring(0, length);
			} else if (length === 2) {
				value = '0' + value;
				lastTwo = value.substring(length - 1);
				first = value.substring(0, length - 1);
			} else {
				lastTwo = value.substring(length - 2);
				first = value.substring(0, length - 2);
			}

			result = parseFloat(first + modulo + lastTwo);
			return result;
		}

	}

	return format;
};

export default {
	type: 'filter',
	name: 'coinToCurrencyChart',
	value: coinToCurrencyChart
};