/**
 * Created by mars on 12/14/15.
 */

lvEditableValueCount.$inject = ['$filter'];
function lvEditableValueCount($filter) {
	return {
		scope: {
			lvEditableValueCount: '@', //редактируемое значение
			lvEditableFooCount: '=', //функция запускаемая по нажатии Ок
			lvEditableFilterCount: '@', //фильтр
			lvCountOptionsEdit: '='
		},
		transclude: true,
		template: 	'<div class="inline-block width-100">' +
						'<div class="number relative width-100 model-value nowrap" ng-click="setEdit(); $event.stopPropagation()">' +
							'<span ng-bind="model.value | weightFormatOnlyShow"></span>' +
						'</div>' +
						'<div class="absolute" lv-editable-container-count ng-if="isEdit" style="margin-left:-80px;" ng-click="$event.stopPropagation()"></div>' +
					'</div>',
		link: function ( scope, el, attr, cntrl ) {

			scope.style = attr.style;
			var modelKey1 = scope.lvEditableValueCount.split( ":" )[0];
			var modelKey2 = scope.lvEditableValueCount.split( ":" )[1];
			var modelValue = scope.$parent[modelKey1][modelKey2];
			scope.model = {
				value: $filter( 'wareCount' )( modelValue, '.', false )};
			scope.$parent.$watch(modelKey1+"."+modelKey2, function (val) {
				scope.model = { value: (val !== "" && val !== null) ? $filter( 'wareCount' )( val, '.' ) : val};
			})

			scope.setEdit = function () {
				scope.isEdit = true;
			};
		},
		controller: lvEditableValueCountController
	};
}

lvEditableValueCountController.$inject = ['$scope', '$filter'];
function lvEditableValueCountController($scope, $filter) {

	this.setValue = function ( value ) {
		$scope.model.value = (value == "")? "0" :  value;

		$scope.$parent[$scope.lvEditableValueCount.split( ":" )[0]][$scope.lvEditableValueCount.split( ":" )[1]] = $filter( 'wareCount' )( value, $scope.lvCountOptionsEdit.decimalCharacter, true );
		$scope.lvEditableFooCount( $scope.$parent[$scope.lvEditableValueCount.split( ":" )[0]] );
		$scope.isEdit = false;
	};
	this.hide = function () {
		$scope.isEdit = false;
	};
}

lvEditableContainerCount.$inject = [];
function lvEditableContainerCount() {
	return {
		template: 	'<div>' + '<form name="lvEditableContainerForm">' +
			'<table class="lv-editable-container">' +
			'<tr>' +
			'<td> <div class="btns btn-cancel" ng-click="onCancel(); $event.stopPropagation()"></div> </td>' +
			'<td> <div class="btns btn-ok" ng-click="onOk($event); $event.stopPropagation()"></div> </td>' +
			'<td>' +
			'<input type="text" name ="editValue" ng-model="model.editValue", currency-localization="lvCountOptionsEdit", ng-click="$event.stopPropagation()">' +
			// '<input type="text" name ="editValue" ng-model="model.editValue" ng-click="$event.stopPropagation()" lv-editable-container-input-count>' +
			'</td>' +
			'</tr>' +
			'</table>' + '</form>' +
			'</div>',
		transclude: true,
		require: '^lvEditableValueCount',
		link: function ( scope, el, attr, cntrl ) {

			scope.model.editValue = scope.model.value;

			scope.onOk = function () {
				if ( scope.lvEditableContainerForm.$invalid ) return;
				cntrl.setValue( scope.model.editValue );
				scope.isEdit = false;
			};

			scope.onCancel = function () {
				cntrl.hide();
			};

			var els = el.find( '*' );

			function click( e ) {
				for ( var i = 0; i < els.length; i++ ) {
					if ( els[i] == e.target ) {
						return;
					}
				}
				scope.onCancel();
				scope.$parent.$digest();
			}

			if ( !el._listiner ) {
				el._listiner = click;
				setTimeout( function () {
					document.body.addEventListener( 'click', click );
				}, 1 );
			}

			scope.$on( '$destroy', function ( e ) {
				document.body.removeEventListener( 'click', click );
				el._listiner = null;
			} );

		}
	};
}

lvEditableContainerInputCount.$inject = ['factoryValidationFormat'];
function lvEditableContainerInputCount( factoryValidationFormat ) {
	return {
		require: 'ngModel',
		link: function ( scope, el, attr, ctrl ) {

			if ( scope.lvEditableFilterCount ) {
				scope.valid = scope.lvEditableFilterCount;
				attr.$set( 'valid', scope.lvEditableFilterCount );
			} else {
				scope.valid = 'defined';
			}
			var v = factoryValidationFormat( scope, el, attr, ctrl );
			/**срабатывает при редактировании*/
			ctrl.$parsers.unshift( v.checkForEven );

		}
	};
}

export default [
	{
		type: 'directive',
		name: 'lvEditableValueCount',
		value: lvEditableValueCount
	},
	{
		type: 'directive',
		name: 'lvEditableContainerCount',
		value: lvEditableContainerCount
	},
	{
		type: 'directive',
		name: 'lvEditableContainerInputCount',
		value: lvEditableContainerInputCount
	}
];
