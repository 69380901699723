/**
 * Created by mars on 3/2/16.
 */

import angular from 'angular';

filterSumPrimeCost.$inject = ['$filter'];
function filterSumPrimeCost($filter) {
    var filterWareCount = $filter('coinToCurrency');
    return function (primeCost, n, arr) {

       var arrSum = 0;
       if(arr && arr.length){
           angular.forEach(arr,function(item){
               if(!isNaN(parseFloat(item._quantity ))){
                   arrSum += parseFloat(item.primeCost) * parseFloat(item._quantity);
               }
           })
       }
        var _primeCost = parseFloat(primeCost);
        var _n = parseFloat(n);

        if (isNaN(_primeCost) || isNaN(_n)) {
            if(arr && arr.length){
                return arrSum;
            }else{
                return '';
            }
        }
        else if (angular.isNumber(_primeCost) && angular.isNumber(_n)) {
            return filterWareCount((_primeCost * _n + (arr ? arrSum : 0)));
        } else {
            return '';
        }
    }
}

export default {
    type: 'filter',
    name: 'filterSumPrimeCost',
    value: filterSumPrimeCost
};