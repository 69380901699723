/**
 * Created by olehkss on 28.05.16.
 * @ngdoc factory
 * @name app.factory:modifiersSummary
 * @description
 * A factory that gets the modifiers reports from server
 * @requires  promiseOperation
 * @returns {{getModifiersSummary: getModifiersSummary}}
 */

import angular from 'angular';

modifiersSummary.$inject = ['promiseOperation', 'serviceReportsError'];

function modifiersSummary(promiseOperation, serviceReportsError) {
    return {
        getModifiersSummary: getModifiersSummary
    };

    /**
     * @ngdoc function
     * @param {Object} params parameters for server request
     * @returns {Promise}
     */
    function getModifiersSummary(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/modifiersreport',
            params,
            successData,
            serviceReportsError.reportsError
        );
    }

    /**
     *@ngdoc function
     * @param {Object} response server answer
     * @returns {Object} preprocessed data from server
     */
    function successData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                let modifiersResponse = {
                    outlets: response.data.fillSelectData.outlets,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    startDateString: response.data.startDateString,
                    endDateString: response.data.endDateString,
                    periodAllowed: response.data.periodAllowed
                };

                if (response.data.periodAllowed) {
                    // an array of modifiers and their options for template will be created here
                    let modifiers = [];
                    for (let i = 0; length = response.data.modifiers.length, i < length; i++) {
                        modifiers.push({
                            name: response.data.modifiers[i].name,
                            count: response.data.modifiers[i].count,
                            amount: response.data.modifiers[i].amount,

                            netSales: response.data.modifiers[i].netSales,
                            countRefund: response.data.modifiers[i].countRefund,
                            amountRefund: response.data.modifiers[i].amountRefund,

                            isOption: false
                        });

                        //adds options to the array of modifiers
                        modifiers = modifiers.concat(response.data.modifiers[i].options);

                        // for each modifier option field isOption = true is added
                        modifiers.forEach(function (item) {
                            if (item.isOption === undefined) {
                                item.isOption = true;
                            }
                        });
                    }

                    modifiersResponse.modifiersData = modifiers;
                    modifiersResponse.pages = response.data.pages;
                }

                return modifiersResponse;
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'modifiersSummary',
    value: modifiersSummary
};
