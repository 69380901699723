import angular from 'angular';

invManagement.$inject = [
    '$accountInfo',
    '$scope',
    'serviceDialog',
    'serviceBillingMixpanel',
    'lodash',
    '$profile',
    'factoryBilling',
    'serviceToast',
    'serviceBillingError',
    'serviceBillingCommonMethods',
    'serviceBillingDifferentPlaces',
    '$filter',
    '$state',
    'factoryErrorAlert',
    '$rootScope',
    'strGwConst',
    '$timeout',
    '$permitsManager',
    'linkUtil',
    'lvMixpanel'
];

function invManagement(
    $accountInfo,
    $scope,
    serviceDialog,
    serviceBillingMixpanel,
    lodash,
    $profile,
    factoryBilling,
    serviceToast,
    serviceBillingError,
    serviceBillingCommonMethods,
    serviceBillingDifferentPlaces,
    $filter,
    $state,
    factoryErrorAlert,
    $rootScope,
    strGwConst,
    $timeout,
    $permitsManager,
    linkUtil,
    lvMixpanel
) {
    var vm = this;
    vm.accountInfo = angular.copy($accountInfo);
    (vm.accountInfo === undefined || vm.accountInfo.message === "temporary_unavailable") ? $scope.errorChargebee = true : $scope.errorChargebee = false;
    if (!$scope.errorChargebee) {
        vm.subscriptionStatus = vm.accountInfo.subscriptions.INVENTORY.status;
        vm.isTrial = vm.subscriptionStatus === 'NONE';
    }
    var translate        = $filter('translate');
    var emptyPlan        = {id:null, cycle:null, unlim:null, price:null};
    var emptyCoupon      = {showInput:false, error:"", name:"", tempName:"", haveCoupon:false, code:null};
    var dateBilling      = $profile.billingInfo;
    var stripeGateways   = strGwConst;
    var ownerId = $profile.ownerId;
    vm.couponInventory   = angular.copy(emptyCoupon);

    vm.learnMoreLink = linkUtil.getLearnMoreLink();

    vm.learnMoreClicked = function() {
        lvMixpanel.track("Help", { "Property": "Advanced inventory" });
    }

    vm.showButton = $permitsManager.hasBillingAccess;

    (!$scope.errorChargebee && vm.accountInfo.subscriptions.INVENTORY && vm.accountInfo.subscriptions.INVENTORY.plan === null) ? vm.accountInfo.subscriptions.INVENTORY.plan = angular.copy(emptyPlan) : "";

    // диалоговое окно СКЛАДСКОЙ УЧЕТ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки
    vm.subscribeInventory = function () {
        // fbq('track', 'Start 14 day trial Inventory');
        var msgTitle     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INVENTORY_TRIAL_FREE_TITLE');
        var msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INVENTORY_TRIAL_FREE_TEXT_1');
        var msgContent2  =  null;
        var msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INVENTORY_TRIAL_FREE_TEXT_2');
        // var msgContent4  =  null;
        var msgDate      =  null;

        var terms = {
            checked: true
        };

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       msgContent2,
            content3:       msgContent3,
            // content4:       msgContent4,
            date:           msgDate,
            priceOne:       lodash.find(vm.accountInfo.plans.INVENTORY, { 'cycle': 1, 'unlim': false }),
            terms:          terms,
            // priceUnlimited: ctrl.accountInfo.plans.INVENTORY.U_INVENTORY_1,
            buttons: [  {   text:  'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp("cancel", false, "INVENTORY", vm.subscriptionStatus, false, null);
                }
            },
                {   text:   'START_FREE_TRIAL',
                    class: 'md-primary',
                    action: function () {
                        vm.accountInfo.subscriptions.INVENTORY.plan.id = "INVENTORY";
                        createSubscribeActivate(vm.accountInfo.subscriptions.INVENTORY.plan, false, null, vm.accountInfo.paymentMethod);
                    }
                }
            ]
        });
    };
    // диалоговое окно СКЛАДСКОЙ УЧЕТ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки



    // диалоговое окно СКЛАДСКОЙ УЧЕТ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация
    function subscribeActivateInventory() {
        var createNewPlan;
        var couponCode;

        var tempCoupon      =  angular.copy(vm.couponInventory);
        var tempPlan        =  angular.copy(vm.accountInfo.subscriptions.INVENTORY.plan);
        var msgTitle        =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_INVENTORY_TITLE');
        var msgContent1     =  translate('ACCOUNT.B2_INVENTORY_SUBSCRIBE_TEXT');
        var msgContent3     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER');
        var msgContent4     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_MONTH');
        var msgContent5     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_YEAR');
        var msgContent6     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_UNLIMITED');
        var msgSingle1      =  lodash.find(vm.accountInfo.plans.INVENTORY, { 'cycle': 1, 'unlim': false });
        var msgSingle12     =  lodash.find(vm.accountInfo.plans.INVENTORY, { 'cycle': 12, 'unlim': false });
        var msgUnlim1       =  lodash.find(vm.accountInfo.plans.INVENTORY, { 'cycle': 1, 'unlim': true });
        var msgUnlim12      =  lodash.find(vm.accountInfo.plans.INVENTORY, { 'cycle': 12, 'unlim': true });
        var msgNewPlan      =  vm.accountInfo.subscriptions.INVENTORY.plan;
        var msgCouponDate   =  vm.couponInventory;

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
            content3:       msgContent3,
            content4:       msgContent4,
            content5:       msgContent5,
            content6:       msgContent6,
            content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
            content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
            single1:        msgSingle1,
            single12:       msgSingle12,
            unlim1:         msgUnlim1,
            unlim12:        msgUnlim12,
            newPlan:        msgNewPlan,
            paymentType:    vm.accountInfo.paymentMethod,
            inEU:           vm.accountInfo.taxMessage,
            couponDate:     msgCouponDate,
            buttons: [  {   text:  'ON_CANCEL',
                            action: function () {
                                serviceBillingMixpanel.setMixpanelBillingActivateInAlert("cancel", emptyPlan, "INVENTORY", vm.accountInfo.paymentMethod);
                                cancelSubscriptionDialog(tempPlan, tempCoupon);
                            }
                        },
                        {   text:   (vm.accountInfo.paymentMethod === null)? 'ACCOUNT.CONTINUE' : 'SUBSCRIBE',
                            action: function () {
                                vm.accountInfo.subscriptions.INVENTORY.plan = lodash.find(vm.accountInfo.plans.INVENTORY, { 'id': vm.accountInfo.subscriptions.INVENTORY.plan.id });
                                createNewPlan = vm.accountInfo.subscriptions.INVENTORY.plan;
                                (vm.couponInventory.haveCoupon === true && vm.couponInventory.code !== null)? couponCode = vm.couponInventory.code : couponCode = null;

                                if (vm.accountInfo.paymentMethod === null) {
                                    var urlWithHash = window.location.href;
                                    if(dateBilling.gw === "bt")  { $state.go( 'addPayMethodBt',  {  addSubscribe        : createNewPlan,
                                                                                                    typeSubscriptions   : "INVENTORY",
                                                                                                    urlForReload        : urlWithHash,
                                                                                                    activate            : true,
                                                                                                    coupon              : couponCode,
                                                                                                    urlForSuccessReload : "inventory.management"}) }
                                    if(stripeGateways.indexOf(dateBilling.gw) !== -1) { $state.go( 'addPayMethodStr', {  addSubscribe        : createNewPlan,
                                                                                                    typeSubscriptions   : "INVENTORY",
                                                                                                    urlForReload        : urlWithHash,
                                                                                                    activate            : true,
                                                                                                    coupon              : couponCode,
                                                                                                    urlForSuccessReload : "inventory.management"}) }
                                    if(dateBilling.gw === "bs" || dateBilling.gw === "wc")  {
                                        serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, createNewPlan.id, couponCode, cancelSubscriptionDialog.bind(null, tempPlan, tempCoupon), null, null);
                                    }
                                }
                                else {
                                    showEstimatedInvoice(createNewPlan, true, couponCode, vm.accountInfo.paymentMethod, tempPlan, tempCoupon);
                                }
                            }
                        },
                        {   text:           'APPLY_APPLY',
                            closeOnAction:  false,
                            action:         function () {
                                serviceBillingDifferentPlaces.addCoupon(vm.accountInfo.subscriptions.INVENTORY.plan, vm.couponInventory, vm.couponInventory.name);
                            }
                        }]
        });
    }
    // диалоговое окно СКЛАДСКОЙ УЧЕТ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация


    // проверка, разрешен ли выбор тарифного плана
    vm.checkIfPlanAllowed = function () {
        serviceBillingDifferentPlaces.checkIfPlanAllowed(vm.accountInfo.paymentMethod, subscribeActivateInventory);
    };
    // проверка, разрешен ли выбор тарифного плана


    function createSubscribeActivate(plan, activate, couponCode, paymentType) {
        var data = {planId: plan.id,
                    activate: activate,
                    coupon: couponCode};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(res.result, plan, "INVENTORY", vm.subscriptionStatus, activate, paymentType);

            if (res.result === "ok") {
                if (activate) {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                } else {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_TRIAL_STARTED', 'success');
                }

                $timeout(function () {
                    window.location.reload(true);
                }, 1000);
            }
            else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            }
            else {
                serviceBillingError.unKnownError();
            }
        });
    }


    // отключение диалога выбора подписки
    function cancelSubscriptionDialog(plan, coupon) {
        vm.couponInventory = coupon;
        vm.accountInfo.subscriptions.INVENTORY.plan = plan;
    }
    // отключение диалога выбора подписки


    // предпросмотр счета к оплате
    function showEstimatedInvoice(plan, activate, couponCode, paymentType, tempPlan, tempCoupon) {
        var data = {planId: plan.id,
                    activate: activate,
                    coupon: couponCode,
                    estimate: true};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            if(res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createSubscribeActivate.bind(null, plan, activate, couponCode, paymentType), cancelEstimatedInvoice.bind(undefined, tempPlan, tempCoupon));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // предпросмотр счета к оплате


    // отмена предпросмотра счета к оплате
    function cancelEstimatedInvoice(tempPlan, tempCoupon) {
        cancelSubscriptionDialog(tempPlan, tempCoupon);
        subscribeActivateInventory();
    }
    // отмена предпросмотра счета к оплате

}

export default {
    type: 'controller',
    name: 'invManagement',
    value: invManagement
};
