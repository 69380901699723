/**
 * Created yarosh on 31.05.17.
 */

formatTimeByBrowser.$inject = ['retrieveLangCode'];
function formatTimeByBrowser(retrieveLangCode) {
    return function (date, option) {
        // if(option === "calendarWeekDay" || option === "weekDay") {
        //     return retrieveLangCode.retrieveWeekDayFormat(date, option);
        // } else {
        return retrieveLangCode.retrieveTimeFormat(date, option);
    }
}

export default {
    type: 'filter',
    name: 'formatTimeByBrowser',
    value: formatTimeByBrowser
};