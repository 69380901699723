/**
 * Created by mars on 12/21/15.
 */
factoryValid.$inject = ['$valid'];

function factoryValid( factoryValidClass ) {

	/**
	 * @ngdoc object
	 * @name companyTypes
	 * @propertyOf app.factory:factoryValid
	 * @kind {String}
	 *
	 * @returns {Object} functions. Имя функции соответсвует значению атрибута valid Каждому полю соответсвует возвращаемый объект содержащий: val - новое  измененное значение (при необходимости), mess - сообщение, какая именно валидация не прошла, st - состояниу true или false (валидное или нет значение)
	 * @description
	 *
	 * @example
	 *
	 *
	 */
	var valid = {};
	var _validClass = factoryValidClass.foo;


	var Login = new _validClass();
	Login.min = 6;
	Login.max = 15;

	//@Override
	Login.replace = function ( val ) {
		return val.replace( /[^\w\-\.\@]/g, '' );
	};
	valid.login = Login.valid;


	/**
	 * @type {_validClass}
	 */
	/*var Pass = new _validClass();
	 Pass.min = 6;
	 Pass.max = 15;
	 Pass.minMessage = 'low_six';*/

	for(var opt in factoryValidClass.$$valid ){
		valid[opt] = factoryValidClass.$$valid[opt].valid;
	}

	valid.passRepeat = valid['pass'];

	var Defined = new _validClass();
	Defined.min = 1;
	Defined.minMessage = 'empty_field';
	valid.defined = Defined.valid;


	//valid.businessName = Defined.valid;



	/**
	 * число с точкой и только одни 0 перед точкой для валидности только CreditRateBonus
	 * */
	var ForCreditRateBonus = new _validClass();
	ForCreditRateBonus.minMessage = 'empty_field';
	ForCreditRateBonus.reforms.push( function ( val ) {
		if ( val ) {
			val = val.replace( /^0{2}/, '0' );

			val = ("" + val).replace( /[^0-9\.]/, '' );

			if ( val.match( /\./ ) ) {
				if ( val.match( /^\.(\d+)?/ ) ) {
					val = '0' + val;
				}
				var arr = val.split( '.' );
			}
			if ( val.match( /\./ ) && 1 < val.match( /\./g ).length ) {
				val = val.replace( /\./, '' )
			}
		}
		return val;
	} );
	/**
	 * число с точкой и только одни 0 перед точкой для валидности только CreditRateBonus
	 * */



	/**
	 CreditRateBonus
	 */
	var CreditRateBonus = new _validClass();
	CreditRateBonus.min = 1;
	CreditRateBonus.max = 6;
	CreditRateBonus.minMessage = 'empty_field';
	var valTemp = '';

	CreditRateBonus.reforms.push( ForCreditRateBonus.reforms[1] );
	CreditRateBonus.reforms.push( function ( val ) {
		var arrzaq = val.split( '.' );

		if (val >= 0 && val <= 999.00 && arrzaq[0].length <= 3) {
			if (arrzaq[1]) {
				if (arrzaq[1].length <= 2) {
					valTemp = val;
					return val;
					} else {
						return valTemp;
					}
			} else {
				valTemp = val;
				return val;
			}
		} else {
			return valTemp;
		}

	} );

	CreditRateBonus.valids.push( function ( val ) {
		this.objRes.val = val;
		if (  100 < parseFloat( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_100_percent';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );

	valid.creditRateBonus = CreditRateBonus.valid;
	/**	 CreditRateBonus  */






	/**
	 * Емаил
	 * @type {_validClass}
	 */
	var Email = new _validClass();
	Email.min = 1;
	Email.max = 50;
	Email.valids.push( function ( val ) {
		var st = false, mess = '';
		var rex = /^(([A-Za-z0-9-+!$&*=^`|~#%'?_{}]+(([A-Za-z0-9-+\.!$&*=^`|~#%'?_{}]+)*))|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if ( !rex.test( val ) ) {
			mess = 'pattern_mail_err'
		} else if ( 50 < val.length ) {
			mess = 'symbol_limit_50'
		} else {
			st = true;
		}
		return {
			st: st,
			val: val,
			mess: mess
		}
	} );
	valid.email = Email.valid;

	/**
	 * Логин (
	 * @type {_validClass}
	 */
	var LoginAsEmail = new _validClass();
	angular.extend( LoginAsEmail, Email );
	valid.loginAsEmail = LoginAsEmail.valid;

	/**
	 * Только целое число
	 * @type {_validClass}
	 */
	var Number = new _validClass();
	Number.reforms.push( function ( val ) {
		// console.log("val = ", val);
		val = parseInt( val );
		if ( isNaN( val ) ) {
			return val = ""
		}
		if ( typeof val == 'number' && !isNaN( val ) ) {
			return parseInt( val );
		} else {
			return val
		}

	} );
	valid.number = Number.valid;







	/** валидация при вводе валюты без десятичных и сотых значений ЗНАЧЕНИЕ ОБЯЗАТЕЛЬНО**/
	var PriceValueZeroSigns = new _validClass();
	PriceValueZeroSigns.min = 1;
	PriceValueZeroSigns.minMessage = 'empty_field';

	PriceValueZeroSigns.reforms.push( function ( val ) {
		val = parseInt( val );
		val = this.cut( val );
		return val.replace( /[^0-9]/g, '' );
	} );

	PriceValueZeroSigns.valids.push( function ( val ) {
		this.objRes.val = val;

		if ( val && 99999999 < parseInt( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_price_value_limit_zero_signs';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );

	valid.priceValueZeroSigns = PriceValueZeroSigns.valid;
	/** валюта без десятичных и сотых значений ЗНАЧЕНИЕ ОБЯЗАТЕЛЬНО**/




	/** валидация при вводе валюты без десятичных и сотых значений ЗНАЧЕНИЕ ОБЯЗАТЕЛЬНО только для опции модификатора (с отрицательной ценой)**/
	var PriceValueZeroSignsModifier = new _validClass();
	PriceValueZeroSignsModifier.min = 1;
	PriceValueZeroSignsModifier.minMessage = 'empty_field';

	PriceValueZeroSignsModifier.reforms.push( function ( val ) {
		val = val.replace(/\-\-/, '-');
		if ((val[0] != '-' && val.length > 1) || (val[0] == '-' && val.length > 2)) {
			val = parseInt( val );
		}
		// console.log("val #2 = ", val);
		val = this.cut( val );
		// return val.replace( /[^0-9]/g, '' );
		return val.replace(/[^\-{0,1}\d{1,8}]/, '');
	} );

	PriceValueZeroSignsModifier.valids.push( function ( val ) {
		this.objRes.val = val;

		if ( val && 99999999 < parseInt( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_option_price_zero_signs';
		} else if (val && -99999999 > parseInt( val )) {
				this.objRes.st = false;
				this.objRes.mess = 'min_option_price_zero_signs';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );

	valid.priceValueZeroSignsModifier = PriceValueZeroSignsModifier.valid;
	/** валидация при вводе валюты без десятичных и сотых значений ЗНАЧЕНИЕ ОБЯЗАТЕЛЬНО только для опции модификатора (с отрицательной ценой)**/





	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО с СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/
	var PriceNotNecessary = new _validClass();
	PriceNotNecessary.minMessage = 'empty_field';

	PriceNotNecessary.reforms.push( function ( val ) {
		val = parseInt( val );
		val = this.cut( val );
		return val.replace( /[^0-9]/g, '' );
	} );

	PriceNotNecessary.valids.push( function ( val ) {
		this.objRes.val = val;
		if ( val && 99999999 < parseInt( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_price_value_limit_zero_signs';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );

	valid.priceNotNecessary = PriceNotNecessary.valid;
	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО с СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/






	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО БЕЗ СООБЩЕНИЕМ об ошибке  (пример - цена товара в списке цен для заведений)*/
	var PriceNotNecessaryNoMessage = new _validClass();
	PriceNotNecessaryNoMessage.minMessage = 'empty_field';

	PriceNotNecessaryNoMessage.reforms.push( function ( val ) {
		val = parseInt( val );
		val = this.cut( val );
		return val.replace( /[^0-9]/g, '' );
	} );

	PriceNotNecessaryNoMessage.valids.push( function ( val ) {
		this.objRes.val = val;
		if ( val && 99999999 < parseInt( val ) ) {
			this.objRes.st = false;
		} else {
			this.objRes.st = true;
		}
		return this.objRes
	} );

	valid.priceNotNecessaryNoMessage = PriceNotNecessaryNoMessage.valid;
	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО БЕЗ СООБЩЕНИЕМ об ошибке  (пример - цена товара в списке цен для заведений)*/















	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО с СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/
	// var PriceNotNecessary = new _validClass();
	// PriceNotNecessary.minMessage = 'empty_field';

	// PriceNotNecessary.reforms.push( function ( val ) {
	// 	if ( val ) {
	// 		val = val.replace( /^0{2}/, '0' );
	// 		val = val.replace(/,/, '.');
	// 		val = ("" + val).replace( /[^0-9]/, '' );

	// 		if ( val.match( /\./ ) ) {
	// 			if ( val.match( /^\.(\d+)?/ ) ) {
	// 				val = '0' + val;
	// 			}
	// 			var arr = val.split( '.' );
	// 			if ( 2 < arr[1].length ) {
	// 				val = val.replace( '.', '' );
	// 				var pos = val.length - 2;
	// 				var _arr = val.split( '' );
	// 				_arr.splice( pos, 0, '.' );
	// 				val = _arr.join( '' );
	// 			}
	// 		}
	// 		if ( val.match( /\./ ) && 1 < val.match( /\./g ).length ) {
	// 			val = val.replace( /\./, '' )
	// 		}
	// 	}
	// 	return val;
	// } );

	// PriceNotNecessary.valids.push( function ( val ) {
	// 	this.objRes.val = val;
	// 	if ( 100000000 - 0.01 < parseFloat( val ) ) {
	// 		this.objRes.st = false;
	// 		this.objRes.mess = 'max_price_value_limit_zero_signs';
	// 	} else {
	// 		this.objRes.st = true;
	// 		this.objRes.mess = null;
	// 	}
	// 	return this.objRes
	// } );

	// valid.priceNotNecessary = PriceNotNecessary.valid;
	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО с СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/





	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО БЕЗ СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/
	// var PriceNotNecessaryNoMessage = new _validClass();
	// PriceNotNecessaryNoMessage.minMessage = 'empty_field';

	// PriceNotNecessaryNoMessage.reforms.push( function ( val ) {
	// 	if ( val ) {
	// 		val = val.replace( /^0{2}/, '0' );
	// 		val = val.replace(/,/, '.');
	// 		val = ("" + val).replace( /[^0-9]/, '' );

	// 		if ( val.match( /\./ ) ) {
	// 			if ( val.match( /^\.(\d+)?/ ) ) {
	// 				val = '0' + val;
	// 			}
	// 			var arr = val.split( '.' );
	// 			if ( 2 < arr[1].length ) {
	// 				val = val.replace( '.', '' );
	// 				var pos = val.length - 2;
	// 				var _arr = val.split( '' );
	// 				_arr.splice( pos, 0, '.' );
	// 				val = _arr.join( '' );
	// 			}
	// 		}
	// 		if ( val.match( /\./ ) && 1 < val.match( /\./g ).length ) {
	// 			val = val.replace( /\./, '' )
	// 		}
	// 	}
	// 	return val;
	// } );

	// PriceNotNecessaryNoMessage.valids.push( function ( val ) {
	// 	this.objRes.val = val;
	// 	if ( 100000000 - 0.01 < parseFloat( val ) ) {
	// 		this.objRes.st = false;
	// 	} else {
	// 		this.objRes.st = true;
	// 	}
	// 	return this.objRes
	// } );

	// valid.priceNotNecessaryNoMessage = PriceNotNecessaryNoMessage.valid;
	/*** валюта  без десятичных и сотых значений КОГДА ЗНАЧЕНИЕ НЕ ОБЯЗАТЕЛЬНО БЕЗ СООБЩЕНИЕМ об ошибке  (пример - цена товара в карточке)*/









	/*** число с точкой  @type {_validClass} ДО МУЛЬТИШОПА */
	var Price = new _validClass();
	//Price.min = 1;
	Price.minMessage = 'empty_field';

	Price.reforms.push( function ( val ) {
		if ( val ) {
			val = val.replace( /^0{2}/, '0' );
			val = val.replace(/,/, '.');
			val = ("" + val).replace( /[^0-9\.]/, '' );

			if ( val.match( /\./ ) ) {
				if ( val.match( /^\.(\d+)?/ ) ) {
					val = '0' + val;
				}
				var arr = val.split( '.' );
				if ( 2 < arr[1].length ) {
					val = val.replace( '.', '' );
					var pos = val.length - 2;
					var _arr = val.split( '' );
					_arr.splice( pos, 0, '.' );
					val = _arr.join( '' );
				}
			}
			if ( val.match( /\./ ) && 1 < val.match( /\./g ).length ) {
				val = val.replace( /\./, '' )
			}
		}
		return val;
	} );

	Price.valids.push( function ( val ) {
		this.objRes.val = val;
		if ( 1000000 - 0.01 < parseFloat( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_price';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );

	valid.price = Price.valid;
	/*** число с точкой  @type {_validClass} ДО МУЛЬТИШОПА */

















	var InitialCreditsBonus = new _validClass();
	InitialCreditsBonus.reforms.push( Price.reforms[1] );
	InitialCreditsBonus.valids.push( function ( val ) {
		this.objRes.val = val;
		if ( 1000000 - 0.01 < parseFloat( val ) ) {
			this.objRes.st = false;
			this.objRes.mess = 'max_point_price';
		} else {
			this.objRes.st = true;
			this.objRes.mess = null;
		}
		return this.objRes
	} );
	valid.initialCreditsBonus = InitialCreditsBonus.valid;


	/**
	 * Штрихкод только цифры до 128
	 * @type {_validClass}
	 */
	var Barcode = new _validClass();
	Barcode.max = 128;
	Barcode.maxMessage = 'max_128';
	Barcode.reforms.push( function ( val ) {
		if ( val ) {
			val = ("" + val).replace( /[^0-9]/, '' );
		}
		return val;
	} );
	valid.barcode = Barcode.valid;


	/**
	 * Социальные сети
	 */
	var SocialMedia = new _validClass();
	SocialMedia.max = 255;
	SocialMedia.maxMessage = 'max_255';
	valid.socialMedia = SocialMedia.valid;

	/**
	 * Настройка профиля - описание
	 */
	valid.profileDescription = SocialMedia.valid;

	/**
	 * Заведение - описание
	 */
	valid.shopDescription = SocialMedia.valid;

	/**
	 * Сайт
	 */
	valid.profileSite = SocialMedia.valid;

	/**
	 * Название заведения
	 * @type {_validClass}
	 */
	var ShopName = new _validClass();
	ShopName.max = 70;
	ShopName.min = 1;
	ShopName.minMessage = 'empty_field';
	ShopName.maxMessage = 'max_70';
	valid.shopName = ShopName.valid;

	/**
	 * @type {_validClass}
	 */
	var CategoryName = new _validClass();
	CategoryName.min = 1;
	CategoryName.max = 64;
	CategoryName.minMessage = 'cannot_empty_field';
	CategoryName.maxMessage = 'max_64';
	valid.categoryName = CategoryName.valid;

	/**
	 * Остатки  число макс 9999
	 * @type {_validClass}
	 */
	var WareQuantity = new _validClass();
	WareQuantity.min = 1;
	WareQuantity.reforms.push( function ( val ) {
		if ( val ) {
			val = ("" + val).replace( /[^0-9\-]/, '' );
			val = ("" + val).replace( /\-{2}/, '-' );
			var regExp = /(\-*\d+)?(.*)/;
			var match = regExp.exec( val );
			val = match[1] || match[0];
		}
		if ( /-/.test( val ) ) {
			val = ("" + val).substr( 0, 7 );
		} else {
			val = ("" + val).substr( 0, 6 );
		}
		return val;
	} );
	valid.wareQuantity = WareQuantity.valid;

	/**
	 *    телефон. Только цифры
	 * @type {_validClass}
	 */
	var Phone = new _validClass();
	Phone.max = 15;
	Phone.reforms.push( function ( val ) {
		val = val.replace( /\+{2}/, '+' );
		val = val.replace( /[^0-9\+]/, '' );
		var arr = [];
		if ( /\+/.test( val ) ) {
			arr = val.split( '+' );
			val = '+' + arr.join( '' );
			val = this.cut( val, 16 )
		} else {
			val = this.cut( val )
		}
		return val;
	} );
	valid.phone = Phone.valid;

	return valid;
}

export default factoryValid;