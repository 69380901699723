/**
 * Created by kseniya.yarosh on 15.12.16
 *
 * @memberof lvMerchant
 * @ngdoc directive
 * @name app.directive:lvMerchant
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Merchants filter (ul li list)
 *
 * <div lv-merchant-multi="ctrl.merchant"></div>
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/merchant-filter-multi.pug';

lvMerchantMulti.$inject = [
    '$state',
    '$stateParams',
    'filtersData',
    'lvMixpanel',
    'domUtils'
];

function lvMerchantMulti($state, $stateParams, filtersData, lvMixpanel, domUtils) {
    return{
        restrict: 'A',
        scope: true,
        controller: lvMerchantCtrlMulti,
        controllerAs: 'lvMerchantCtrlMulti',
        link: linkFn,
        template: template
    };

    function linkFn ($scope) {

        /**
         * @ngdoc function
         * @description
         * send $stateParams to a server, when menu is closed (an array with Ids).
         */

        $scope.$on("$mdMenuClose", () => {
            if ($stateParams.merchantsIds !== $scope.previousStateIds) {
                sendParams();
            }
        });

        /**
         * @ngdoc function
         * @description
         * Sent an array with Ids when dropdown list is collapsed.
         */

        function sendParams () {
            $stateParams.receiptId = null;

            const savedStateParams = filtersData.getStateParams();
            $stateParams.serverChartType = savedStateParams.serverChartType;
            $stateParams.page = 0;

            const stateParams = filtersData.setStateParams($stateParams);
            if($state.current.name === 'inventory.history' || $state.current.name === 'goods.itemHistory') {
                lvMixpanel.track('Inventory history', {"Property": "Inventory history/Filter by employee"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Filter by employee"});
            }

            domUtils.removeBackdrop(stateParams);
        }
    }
}

lvMerchantCtrlMulti.$inject = ['$scope', '$state', '$stateParams', '$filter'];
function lvMerchantCtrlMulti($scope, $state, $stateParams, $filter) {

    var self = this;
    var translate = $filter('translate');
    var parsedIds = [];
    var body = document.querySelector('body');
    $scope.arab = !body.classList.contains('aranav') ? "" : "target-right target";


    /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don't need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet below in a linked function with the watch cycle */

    if(($stateParams.merchantsIds) && ($stateParams.merchantsIds !=='all' && $stateParams.merchantsIds !== 'clear')) {
        $scope.previousStateIds = $stateParams.merchantsIds.split(',');
        $scope.previousStateIds.forEach( function(val) { parsedIds.push(parseInt(val))} );
        $scope.previousStateIds = parsedIds.sort(function(a, b){return a-b});
        $scope.previousStateIds = ($scope.previousStateIds.length > 1) ? $scope.previousStateIds.join(',') : $scope.previousStateIds;
    } else {
        $scope.previousStateIds =  $stateParams.merchantsIds;
    }


    self.loopingThroughArrWithObj = loopingThroughArrWithObj;
    self.getExistingIds = getExistingIds;
    self.getUniqueIds = getUniqueIds;
    self.selectAll = selectAll;
    self.selectItem = selectItem;
    self.selectOutCheckbox = selectOutCheckbox;
    self.selectAllOutCheckbox = selectAllOutCheckbox;
    $scope.$parent.isMerchantFilter = true;

    var firstMerchant = [
        {
            merchantId: null,
            merchantName: translate('ALL_MERCHANTS')
        }
    ];
    var merchantsIdsArray = [];
    var forModulus = 10;
    var originatorEv;

    self.openMenu = function($mdMenu, ev) {
        originatorEv = ev;
        $mdMenu.open(ev);
        var timeView = document.querySelector('#timeView');
        var calendarView = document.querySelector('.calendar-view');
        if(timeView && !timeView.classList.contains('ng-hide')) {
            timeView.classList.add('ng-hide');
        }
        if(calendarView && !calendarView.classList.contains('ng-hide')) {
            calendarView.classList.add('ng-hide');
        }
    };

    merchantsIdsArray = ($stateParams.merchantsIds) ? $stateParams.merchantsIds.split(',') : merchantsIdsArray;

    self.outlets = ($scope.$parent.outlets);

    if($stateParams.outletsIds && $stateParams.outletsIds != "clear"){
        self.currentOutletId = $stateParams.outletsIds.split(',');
    } else {
        self.currentOutletId = null;
    }

    if(!self.currentOutletId || self.currentOutletId == "all" || self.currentOutletId == "clear" || self.currentOutletId.length === self.outlets.length){
        self.merchants = firstMerchant.concat(self.loopingThroughArrWithObj($scope.$parent.merchants));
    } else {
        var pickedMerchants = [];
        for(var i = 0; i < self.currentOutletId.length; i++) {
            for (var k = 0; k < self.outlets.length; k++) {
                if (self.currentOutletId[i] == self.outlets[k].outletId) {
                    (self.outlets[k].merchants).forEach(function(val){
                        pickedMerchants.push(val);
                    })
                }
            }
        }
        self.merchants = firstMerchant.concat(self.loopingThroughArrWithObj(pickedMerchants));
    }

    //in order not to pass any employees from the fillSelectedData object which is back from a server into ng-repeat if the filter of outlets was nullified,
    // until it will be activated again
    if($stateParams.outletsIds == "clear") {
        self.merchants = firstMerchant;
    }

    self.merchantsIds = [];
    self.merchants[0]._select = false;

    markInputs();
    displayMerchantNames();

    /**
     * @ngdoc function
     * @description
     * mark the inputs according to ids which are present in state params since it's important because browser refreshes after each request to the server
     */
    function markInputs() {
        if ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") {
            self.stateParamsMerchantsIds = $stateParams.merchantsIds.split(',');
            if (self.stateParamsMerchantsIds.length === (self.merchants.length - 1)) {
                self.merchants[0]._select = true;
            }

            merchantsIdsArray = [];
            self.getExistingIds(self.stateParamsMerchantsIds, self.merchants, merchantsIdsArray);

            for (var i = 0; i < self.merchants.length; i++) {
                var currentMerchantId = self.merchants[i].merchantId;

                for (var l = 0; l < self.stateParamsMerchantsIds.length; l++) {
                    if (currentMerchantId === parseInt(self.stateParamsMerchantsIds[l])) {
                        self.merchants[i]._select = true;
                    }
                }
            }
        } else if ($stateParams.merchantsIds == "clear") {
            putAll(false);
        } else if (!$stateParams.merchantsIds || $stateParams.merchantsIds == "all") {
            putAll(true);
        }
    }

    /**
     * @ngdoc function
     * @description
     * looping through merchants to display the current name in the filter for merchants according to state params ids array length
     */
    function displayMerchantNames() {
        if ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") {
            var merchantsDeclension;


            var existingMerchantsIds = [];

            self.getExistingIds(self.stateParamsMerchantsIds, self.merchants, existingMerchantsIds);


            var merchantsQuantity = existingMerchantsIds.length;


            var modulus = existingMerchantsIds.length % forModulus;
            var allMerchants;
            var merchantsDeclensionSingle;
            var merchantsDeclensionPlural;
            var merchantsDeclensionPlural2;
            if (existingMerchantsIds.length <= 20) {
                allMerchants = (self.merchants.length === 1) || (merchantsQuantity === self.merchants.length - 1);
                merchantsDeclensionSingle = (merchantsQuantity === 1);
                merchantsDeclensionPlural = (merchantsQuantity > 1 && merchantsQuantity < 5);
                merchantsDeclensionPlural2 = (merchantsQuantity >= 5 && merchantsQuantity <= 20);
            } else {
                allMerchants = (self.merchants.length === 1) || (modulus === self.merchants.length - 1);
                merchantsDeclensionSingle = (modulus === 1);
                merchantsDeclensionPlural = (modulus > 1 && modulus < 5);
            }


            switch (true) {
                case (allMerchants):
                    self.name = self.merchants[0].merchantName;
                    self.merchants[0]._select = true;

                    break;

                case (merchantsDeclensionSingle):
                    var singleName;
                    self.merchants.forEach(function (val) {
                        if (val.merchantId == $stateParams.merchantsIds) {
                            singleName = val.merchantName;
                            if (val.merchantName === null) {
                                singleName = translate("OWNER");
                            }
                        }
                    });
                    merchantsDeclension = translate("MULTISHOP.REPORTS.SINGLE_MERCHANT");
                    self.name = singleName || (merchantsQuantity + " " + merchantsDeclension);

                    break;

                case (merchantsDeclensionPlural):
                    merchantsDeclension = translate("MULTISHOP.REPORTS.PLURAL_MERCHANTS_DECLENSION_1");
                    self.name = merchantsQuantity + " " + merchantsDeclension;

                    break;

                case (merchantsDeclensionPlural2):
                    merchantsDeclension = translate("MULTISHOP.REPORTS.PLURAL_MERCHANTS_DECLENSION_2");
                    self.name = merchantsQuantity + " " + merchantsDeclension;

                    break;

                default:
                    merchantsDeclension = translate("MULTISHOP.REPORTS.PLURAL_MERCHANTS_DECLENSION_2");
                    self.name = merchantsQuantity + " " + merchantsDeclension;

            }
        } else if ($stateParams.merchantsIds == "clear") {
            self.name = translate("MULTISHOP.REPORTS.NONE_MERCHANTS");
        } else {
            self.name = self.merchants[0].merchantName;
        }
    }


    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of the certain merchant(s) in list using checkbox. These merchants will be added and deleted
     * from vm.selectedMerchants array.
     */
    function selectOutCheckbox(merchant) {
        (!merchant._select || merchant._select == "false") ? merchant._select = true : merchant._select = false;
        selectItem(merchant);
    }

    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of all merchants in list using checkbox. These merchants will be added and deleted
     * from vm.selectedMerchants array.
     */
    function selectAllOutCheckbox() {
        (!self.merchants[0]._select || self.merchants[0]._select == "false") ? self.merchants[0]._select = true : self.merchants[0]._select = false;
        selectAll();
    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all merchants in list using checkbox. These merchants will be added and deleted
     * from vm.selectedMerchants array.
     */
    function selectItem(merchant) {
        if (merchant._select) {
            merchantsIdsArray.push(merchant.merchantId);
        } else {
            //if parameter _selected is false, then override by a new array which filter method returns with only those merchantIds that don't equal to selected Ids
            merchantsIdsArray = merchantsIdsArray.filter(function (val) {
                if(val != merchant.merchantId) {
                    return val
                }
            });

            if (self.merchants[0]._select) {
                self.merchants[0]._select = false;
                $stateParams.merchantsIds = "clear";
            }


        }

        //after selecting eventual set of ids we need to remove stateParameter "clear" or "all"
        if(merchantsIdsArray.length > 1) {
            if(merchantsIdsArray[0] == "clear" || merchantsIdsArray[0] == "all") {
                merchantsIdsArray.shift();
            }
        }


        //in order to check the input with all merchants if each merchant was checked
        if ($stateParams.merchantsIds == "all" || merchantsIdsArray.length == self.merchants.length-1) {
            self.merchants[0]._select = true;
            $stateParams.merchantsIds  = "all";
        } else if ($stateParams.merchantsIds == "clear" && merchantsIdsArray.length <= 1 && !merchantsIdsArray[0]) {
            self.merchants[0]._select = false;
            $stateParams.merchantsIds  = "clear";
        } else {
            self.merchantsIds = (angular.copy(merchantsIdsArray)).sort();

            $stateParams.merchantsIds = self.merchantsIds.join(',');
            if($stateParams.merchantsIds.length == 0) {
                $stateParams.merchantsIds  = "clear";
            }
        }

    }



    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all merchants in list using checkbox. These merchants will be added and deleted
     * from vm.selectedMerchants array.
     */
    function selectAll() {
        if (self.merchants[0]._select) {
            putAll(true);
        } else if (!self.merchants[0]._select) {
            putAll(false);
        }
    }


    /**
     * @ngdoc function
     * @description
     * If the first element of select was chosen we put a boolean value into object property _select an put object's id into an array or just remove all ids from the array if the boolean vale of the first option is false
     */
    function putAll(param) {
        merchantsIdsArray = [];
        var checked;
        var checkedId;

        if(param === false) {
            merchantsIdsArray = [];
            $stateParams.merchantsIds = "clear";
        }

        for (var i = 0; i < self.merchants.length; i++) {
            self.merchants[i]._select = param;
            checked = param;
            checkedId = self.merchants[i].merchantId;
            if(param === true && checkedId) {
                merchantsIdsArray.push(parseInt(self.merchants[i].merchantId));
            } else if (param === true && !checkedId && self.merchants[i].merchantName) {
                $stateParams.merchantsIds  = "all";
            }
        }

        if((merchantsIdsArray.length == self.merchants.length-1) && merchantsIdsArray.length != 0) {
            $stateParams.merchantsIds = "all";
            self.merchants[0]._select = true;
        } else if ($stateParams.merchantsIds == "all" && self.merchants.length <= 1) {
            self.merchants[0]._select = true;
            $stateParams.merchantsIds  = "all";
        } else if ($stateParams.merchantsIds == "clear" && self.merchants.length <= 1) {
            self.merchants[0]._select = false;
            $stateParams.merchantsIds  = "clear";
        }


    }


    /**
     * @ngdoc function
     * @description
     * looping through an array with objects in order to return an array just with unique objects according its properties
     */
    function loopingThroughArrWithObj (arrWithObj) {

        if (!arrWithObj) {
            arrWithObj = [];
        }

        var arrayWithIds = [];
        var arrayWithUniqueIds;
        var arrWithUniqueObjects = [];

        for(var i = 0; i < arrWithObj.length; i ++) {
            arrayWithIds.push(arrWithObj[i].merchantId);
        }

        arrayWithUniqueIds = self.getUniqueIds(arrayWithIds);

        for(var l = 0; l < arrayWithUniqueIds.length; l ++) {
            for (var m = 0; m < arrWithObj.length; m ++) {

                if (arrayWithUniqueIds[l] === arrWithObj[m].merchantId) {
                    arrWithUniqueObjects.push(arrWithObj[m]);
                    break;
                }
            }
        }

        return arrWithUniqueObjects;
    }

    /**
     * @ngdoc function
     * @description
     * looping through two arrays and push into the third one only existing Ids
     */

    function getExistingIds(arr1, arr2, arr3) {
        for(var k = 0; k < arr1.length; k++) {
            for(var m = 0; m < arr2.length; m++) {
                if (arr1[k] == arr2[m].merchantId) {
                    arr3.push(arr1[k]);
                }
            }
        }
    }


    /**
     /**
     * @ngdoc function
     * @description
     * looping through an array and return only unique Ids
     */
    function getUniqueIds (arrayWithIds) {
        return arrayWithIds.filter(function(val, index,arr){
            return arr.indexOf(val) === index;
        });
    }
}

export default {
    type: 'directive',
    name: 'lvMerchantMulti',
    value: lvMerchantMulti
};
