content.$inject = [];

function content() {
    return {
        restrict: 'C',
        require: '^dialogsContainer',
        link: function(scope, el, attr){
            var y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;
            el.css('maxHeight', y*0.7);
        }
    };
}

export default content;