import angular from 'angular';

inventoryHistory.$inject = [
    '$scope',
    'filtersData',
    '$inventHistory',
    '$countOptionsEdit',
    '$state',
    '$statusConst',
    '$stateParams',
    '$filter',
    'lvMixpanel'
];

function inventoryHistory(
    $scope,
    filtersData,
    $inventHistory,
    $countOptionsEdit,
    $state,
    $statusConst,
    $stateParams,
    $filter,
    lvMixpanel
) {
    var vm = this;
    var originatorEv;
    var translate = $filter('translate');
    var reasonsArray = [];
    reasonsArray = ($stateParams.reasons) ? $stateParams.reasons.split(',') : reasonsArray;
    var variantsArray = [];
    variantsArray = ($stateParams.variantId) ? $stateParams.variantId.split(',') : variantsArray;
    var forModulus = 10;
    var body = document.querySelector('body');
    $scope.arab = !body.classList.contains('aranav') ? "" : "target-right target";
    vm.startDate = $inventHistory.startDate;
    vm.endDate = $inventHistory.endDate;
    vm.selectAll = selectAll;
    vm.selectItem = selectItem;
    vm.selectAllVariants = selectAllVariants;
    vm.selectVariant = selectVariant;

    //Task # 19957 Change stubs on Advanced inventory screens
    vm.plugsSearch = !!$stateParams.search;

    vm.downloadHrefBase ='/data/ownercab/downloadInventoryHistory';
    if ($state.current.name === 'inventory.history'){
        $scope.inventorySearch = true;
    } else if($state.current.name === 'goods.itemHistory') {
        $scope.inventorySearch = false;
        //여기부터 내꺼 시작
        var elemT = document.getElementById("filters");
        elemT.style.clear = 'none';


        //일단 여기까지
        //add class to that object and put it in right.
    }



// данные для сохранение состояния фильтров в списке товаров
    $scope.stateFromList = $stateParams.stateToSave;
    $scope.checkFromList = $stateParams.checkToSave;
// данные для сохранение состояния фильтровв списке товаров

    $scope.inventoryHistory = true;
    $scope.history = angular.copy($inventHistory);
    $scope.countOptionsEdit = $countOptionsEdit;
    $scope.inventoryEvents = $scope.history.items;
    $scope.outlets = $scope.history.fillSelectData.outlets;
    $scope.getMerchantsList = function () {
        var outletsList = $scope.outlets;
        var merchantsList = [];
        var merchantsInOutlet={};
        for(var i = 0; i < outletsList.length; i++) {
            merchantsInOutlet = outletsList[i].merchants;
            for(var l = 0; l < merchantsInOutlet.length; l++){
                merchantsList.push(merchantsInOutlet[l]);
            }
        }
        return merchantsList;
    };

    if($scope.history.variants.length > 0) {
        for (var i = 0; i < $scope.inventoryEvents.length; i++) {
            for (var j = 0; j < $scope.history.variants.length; j++) {
                var wareNameParts = $scope.inventoryEvents[i].name.split("(");
                for (var v = 0; v < wareNameParts.length; v++) {
                    if (wareNameParts[v].replace(")", "") === $scope.history.variants[j].name) {
                        $scope.inventoryEvents[i].variants = $scope.history.variants[j].name;
                    }
                }
            }
        }
    }

    this.openMenu = function($mdOpenMenu, ev) {
        originatorEv = ev;
        $mdOpenMenu(ev);
    };

    $scope.merchants = $scope.getMerchantsList();
    $scope.constStatuses = angular.copy($statusConst.inventoryReasons);
    var firstAdjustment = [{name:'all'}];
    var firstVariant = [{
        id: null,
        name: translate('VARIATIONS.ALL_VARIANTS')
    }];
    vm.adjustments = firstAdjustment.concat($scope.constStatuses);
    vm.variants = firstVariant.concat($scope.history.variants);
    // console.log('$scope.constStatuses:', $scope.constStatuses);
    // console.log(vm.adjustments);
    vm.reasons = [];
    vm.adjustments[0]._select = false;
    vm.variants[0]._select = false;
    $scope.itemName = $stateParams.wareName;


    if(($stateParams.reasons) && ($stateParams.reasons !=='all' && $stateParams.reasons !== 'clear')) {
        $scope.previousState = $stateParams.reasons.split(',');
        $scope.previousState = ($scope.previousState.length > 1) ? $scope.previousState.join(',') : $scope.previousState;
        // console.log($scope.previousState);
    } else {
        $scope.previousState =  $stateParams.reasons;
    }

    if(($stateParams.variantId) && ($stateParams.variantId !=='all' && $stateParams.variantId !== 'clear')) {
        $scope.previousStateVar = $stateParams.variantId.split(',');
        $scope.previousStateVar = ($scope.previousStateVar.length > 1) ? $scope.previousStateVar.join(',') : $scope.previousStateVar;
        // console.log($scope.previousState);
    } else {
        $scope.previousStateVar =  $stateParams.variantId;
    }

    if($stateParams.reasons && $stateParams.reasons != "clear" && $stateParams.reasons != "all") {
        vm.stateParamsAdjustment = $stateParams.reasons.split(',');
        if(vm.stateParamsAdjustment.length === (vm.adjustments.length-1)){
            vm.adjustments[0]._select = true;
        }

        for(var  i = 0; i < vm.adjustments.length; i++) {
            var currentAdjustments = vm.adjustments[i].name;
            if(vm.stateParamsAdjustment.length === (vm.adjustments.length-1)) {
                vm.adjustments[0]._select = true;
            }

            for(var l = 0; l < vm.stateParamsAdjustment.length; l ++) {
                if (currentAdjustments === vm.stateParamsAdjustment[l]) {
                    vm.adjustments[i]._select = true;
                }
            }
        }
    } else if ($stateParams.reasons === "clear") {
        putAll(false);
    } else if ($stateParams.reasons === "all") {
        putAll(true);
    }

    if($stateParams.variantId && $stateParams.variantId != "clear" && $stateParams.variantId != "all") {
        vm.stateParamsVariants = $stateParams.variantId.split(',');
        if(vm.stateParamsVariants.length === (vm.variants.length-1)){
            vm.variants[0]._select = true;
        }

        for(var  i = 0; i < vm.variants.length; i++) {
            var currentVariant = vm.variants[i].id;
            if(vm.stateParamsVariants.length === (vm.variants.length-1)) {
                vm.variants[0]._select = true;
            }

            for(var l = 0; l < vm.stateParamsVariants.length; l++) {
                if (currentVariant === parseInt(vm.stateParamsVariants[l])) {
                    vm.variants[i]._select = true;
                }
            }
        }
    } else if ($stateParams.variantId === "clear") {
        putAllVariants(false);
    } else if ($stateParams.variantId === "all") {
        putAllVariants(true);
    }

    if($stateParams.reasons && $stateParams.reasons != "clear" && $stateParams.reasons != "all"){
        var adjustmentDeclension;

        var checkedAdjustments = $stateParams.reasons.split(',');

        switch(true) {
            case (checkedAdjustments.length === vm.adjustments.length-1):
                vm.name = translate("INVENTORY.ALL_REASONS");
                vm.adjustments[0]._select = true;

                break;

            case (checkedAdjustments.length === 1):
                var singleAdjust;
                vm.adjustments.forEach(function(val){
                    if(val.name == $stateParams.reasons){
                        // var inv = translate('INVENTORY')
                        singleAdjust = translate('INVENTORY.HISTORY.' + val.name);
                    }
                });

                vm.name = singleAdjust;

                break;

            case (checkedAdjustments.length > 1 && checkedAdjustments.length < vm.adjustments.length-1):
                adjustmentDeclension = translate("INVENTORY.PLURAL_ADJUSTMENTS");
                vm.name = checkedAdjustments.length + " " + adjustmentDeclension;

                break;

            // case (checkedAdjustments.length < vm.adjustments.length-1 && checkedAdjustments.length >= 5):
            //     adjustmentDeclension = translate("INVENTORY.FOR_FILTER.PLURAL_ADJUSTMENTS_2");
            //     vm.name = checkedAdjustments.length + " " + adjustmentDeclension;

            //     break;

            default:
                adjustmentDeclension = translate("INVENTORY.PLURAL_ADJUSTMENTS");
                vm.name = checkedAdjustments.length + " " + adjustmentDeclension;

        }

    }
    else if ($stateParams.reasons == "clear") {
        vm.name = 0 + " " + translate("INVENTORY.PLURAL_ADJUSTMENTS");
    }
    else {
        vm.name = translate("INVENTORY.ALL_REASONS");
    }

    if($stateParams.variantId && $stateParams.variantId != "clear" && $stateParams.variantId != "all"){
        var variantDeclension;

        var checkedVariants = $stateParams.variantId.split(',');

        var modulus = checkedVariants.length % forModulus;
        var allVariants;
        var variantDeclensionSingle;
        var variantDeclensionPlural;
        var variantDeclensionPlural2;
        if(checkedVariants.length <=20) {
            allVariants = (vm.variants.length === 1) || (checkedVariants.length === vm.variants.length -1);
            variantDeclensionSingle = (checkedVariants.length === 1);
            variantDeclensionPlural = (checkedVariants.length > 1 && checkedVariants.length < 5);
            variantDeclensionPlural2 = (checkedVariants.length >= 5 && checkedVariants.length <= 20);
        } else {
            allVariants = (vm.variants.length === 1) || (modulus === vm.variants.length -1);
            variantDeclensionSingle = (modulus === 1);
            variantDeclensionPlural = (modulus > 1 && modulus < 5);
        }


        switch(true) {
            case (allVariants):
                vm.varName = vm.variants[0].name;
                vm.variants[0]._select = true;

                break;

            case (variantDeclensionSingle):
                var singleName;
                vm.variants.forEach(function(val){
                    if(val.id == $stateParams.variantId){
                        singleName =  val.name;
                    }
                });
                vm.varName = singleName || (checkedVariants.length + " " + variantDeclension);

                break;

            case (variantDeclensionPlural):
                variantDeclension = translate("INVENTORY.FOR_VARIANTS_FILTER.PLURAL_VARIANTS_1");
                vm.varName = checkedVariants.length + " " + variantDeclension;

                break;

            case (variantDeclensionPlural2):
                variantDeclension = translate("INVENTORY.FOR_VARIANTS_FILTER.PLURAL_VARIANTS_2");
                vm.varName = checkedVariants.length + " " + variantDeclension;

                break;

            default:
                variantDeclension = translate("INVENTORY.FOR_VARIANTS_FILTER.PLURAL_VARIANTS_2");
                vm.varName = checkedVariants.length + " " + variantDeclension;

        }

    } else if ($stateParams.variantId == "clear") {
        vm.varName = 0 + " " + translate("INVENTORY.FOR_VARIANTS_FILTER.PLURAL_VARIANTS_2");
    } else {
        vm.varName = translate("VARIATIONS.ALL_VARIANTS");
    }

    function selectItem(reason) {
        if (reason._select) {
            reasonsArray.push(reason.name);
        } else {
            //if parameter _selected is false, then override by a new array which filter method returns with only those merchantIds that don't equal to selected Ids
            reasonsArray = reasonsArray.filter(function (val) {
                if(val != reason.name) {
                    return val
                }
            });

            if (vm.adjustments[0]._select) {
            vm.adjustments[0]._select = false;
            $stateParams.reasons = "clear";
            }

        }

        // vm.reasons = (angular.copy(reasonsArray));

        if ($stateParams.reasons == "all" || reasonsArray.length == vm.adjustments.length-1) {
            vm.adjustments[0]._select = true;
            $stateParams.reasons  = "all";
        } else if ($stateParams.reasons == "clear" && reasonsArray.length < 1) {
            vm.adjustments[0]._select = false;
            $stateParams.reasons  = "clear";
        } else {
            $stateParams.reasons = reasonsArray.sort().join(',');
            if($stateParams.reasons.length == 0) {
                $stateParams.reasons  = "clear";
            }
        }

    }

    function selectVariant(variant) {
        if (variant._select) {
            variantsArray.push(variant.id);
        } else {
            //if parameter _selected is false, then override by a new array which filter method returns with only those merchantIds that don't equal to selected Ids
            variantsArray = variantsArray.filter(function (val) {
                if(val != variant.id) {
                    return val
                }
            });

            if (vm.variants[0]._select) {
                vm.variants[0]._select = false;
                $stateParams.variantId = "clear";
            }

        }

        // vm.reasons = (angular.copy(reasonsArray));

        if ($stateParams.variantId == "all" || variantsArray.length == vm.variants.length-1) {
            vm.variants[0]._select = true;
            $stateParams.variantId  = "all";
        } else if ($stateParams.variantId == "clear" && variantsArray.length < 1) {
            vm.variants[0]._select = false;
            $stateParams.variantId = "clear";
        } else {
            $stateParams.variantId = variantsArray.sort().join(',');
            if($stateParams.variantId.length == 0) {
                $stateParams.variantId  = "clear";
            }
        }

    }

    function selectAll() {
        if (vm.adjustments[0]._select) {
            putAll(true);
        } else if (!vm.adjustments[0]._select) {
            putAll(false);
        }
    }

    function selectAllVariants() {
        if (vm.variants[0]._select) {
            putAllVariants(true);
        } else if (!vm.variants[0]._select) {
            putAllVariants(false);
        }
    }

    function putAll(param) {
        reasonsArray = [];
        var checked;

        if(param === false) {
        // если чекбокс пустой, то очищаем массив выбранных и в передаем в урл clear
            reasonsArray = [];
            $stateParams.reasons = "clear";
            vm.adjustments[0]._select = false;
        } else {
            $stateParams.reasons  = "all";
            vm.adjustments[0]._select = true;
        }

        for (var i = 1; i < vm.adjustments.length; i++) {
            // переприсваеваем значения всех чекбоксов в списке кроме первого
            vm.adjustments[i]._select = param;
            checked = param;
            if(param === true) {
                // если чекбокс клацнутый, ты мы складываем все элементы в массив выбранных
                reasonsArray.push(vm.adjustments[i].name);
            }
        }

    }

    function putAllVariants(param) {
        variantsArray = [];
        var checked;

        if(param === false) {
        // если чекбокс пустой, то очищаем массив выбранных и в передаем в урл clear
            variantsArray = [];
            $stateParams.variantId = "clear";
            vm.variants[0]._select = false;
        } else {
            $stateParams.variantId  = "all";
            vm.variants[0]._select = true;
        }

        for (var i = 1; i < vm.variants.length; i++) {
            // переприсваеваем значения всех чекбоксов в списке кроме первого
            vm.variants[i]._select = param;
            checked = param;
            if(param === true) {
                // если чекбокс клацнутый, ты мы складываем все элементы в массив выбранных
                variantsArray.push(vm.variants[i].id);
            }
        }

    }

    $scope.$watch(
        function () {
            var dropdownMenu3=document.getElementById('dropdownMenu3');
            if(dropdownMenu3) {
                return dropdownMenu3.attributes['aria-expanded'].nodeValue;
            }
        },
        function (newValue, oldValue) {

            /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don\'t need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet above in the controller */

            if ((newValue !== oldValue && newValue == "false") && ($stateParams.reasons !== $scope.previousState)) {
                lvMixpanel.track('Inventory history', {"Property": "Inventory history/Filter by reason"});
                sendParams();
            }
        }
    );

    $scope.$watch(
        function () {
            var dropdownMenu4=document.getElementById('dropdownMenu4');
            if(dropdownMenu4) {
                return dropdownMenu4.attributes['aria-expanded'].nodeValue;
            }
        },
        function (newValue, oldValue) {

            /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don\'t need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet above in the controller */

            if ((newValue !== oldValue && newValue == "false") && ($stateParams.variantId !== $scope.previousStateVar)) {
                lvMixpanel.track('Inventory history', {"Property": "Inventory history/Filter by variant"});
                sendParams();
            }
        }
    );

    function sendParams () {
        // var stateParams = filtersData.setStateParams($stateParams);
        // console.log(stateParams);
        // console.log($stateParams);
        $state.go($state.current.name, {page:   0,
                                        limit:  $stateParams.limit,
                                        merchantsIds: $stateParams.merchantsIds,
                                        outletsIds: $stateParams.outletsIds,
                                        wareId: $stateParams.wareId,
                                        reasons: $stateParams.reasons,
                                        startDate: $stateParams.startDate,
                                        endDate: $stateParams.endDate,
                                        variantId: $stateParams.variantId,
                                        stateToSave: $scope.stateFromList,
                                        checkToSave: $scope.checkFromList}, {reload: true});
    }


    $scope.onBack = function () {
        $state.go('goods.edit', {
            id: $stateParams.wareId,
            stateToSave: $scope.stateFromList,
            checkToSave: $scope.checkFromList
        }, {reload: true});
    };

    $scope.seeDetails = function(e) {
        var eventForDeletedItem = false;
        if(e.target.attributes['eventfordeleteditem'].nodeValue === "true") {
            eventForDeletedItem = true;
        }
        var orderType = e.target.attributes['ordertype'].nodeValue;
        var id = e.target.attributes['id'].nodeValue;
        var orderCode = e.target.attributes['ordercode'].nodeValue;
        if(orderType === 'PURCHASE') {
            $state.go('inventory.orederdetail', {
                id:id
            });
        }
        if (orderType === 'TRANSFER' && !eventForDeletedItem) {
            $state.go('inventory.transferdetail', {
                id:id
            });
        }
        if (orderType === 'ADJUSTMENT') {
            $state.go('inventory.adjustdetails', {
                id:id
            });
        }
        if (orderType === 'COUNT') {
            $state.go('inventory.countdetail', {
                id:id
            });
        }
        if (orderType === 'PRODUCTION') {
            $state.go('inventory.productiondetail', {
                id:id
            });
        }
        if (orderType === 'RECEIPT') {
            var stateParams = filtersData.getStateParams();
            stateParams.receiptId = id;
            stateParams.search = orderCode;
            filtersData.setStateParams(stateParams);
            $state.go('report.receipts',
                stateParams
            );
        }
    }

}

export default {
    type: 'controller',
    name: 'inventoryHistory',
    value: inventoryHistory
};
