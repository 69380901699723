noStoresCtrl.$inject = ['$profile', 'resolveTimezone', 'serviceStrings', '$translate', '$state'];

function noStoresCtrl($profile, resolveTimezone, serviceStrings, $translate, $state) {

    let vm = this;
    let blockStarted = $profile.blockedForNoStores;
    vm.daysLeft = 0;

    if (blockStarted) {
        let blockEnd = blockStarted + 1000 * 60 * 60 * 24 * 30 // 30 days from blocking start date
        let now = new Date().getTime();
        let tzOffset = resolveTimezone.timeZoneOffsetForReports();

        vm.daysLeft = parseInt(((blockEnd - now + tzOffset) / 86400000).toString());
        vm.daysLeftDeclensions = $translate.instant('DECLENSIONS.DAYS.' + serviceStrings.getDeclensions(vm.daysLeft));
    } else {
        $state.go('home');
    }

    vm.isOwner = $profile.isOwner;

    vm.goAddStore = function () {
        $state.go('createoutlet');
    }

}

export default {
    type: 'controller',
    name: 'noStoresCtrl',
    value: noStoresCtrl
}
