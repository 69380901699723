/**
 * Created by Alex on 25.10.2017.
 */

hoursFormatOnlyShow.$inject = ['currencyFormat'];
function hoursFormatOnlyShow(currencyFormat) {

    let hoursOptionsShow = {};
    let parameters = {
        symbol: ''
    };

    currencyFormat.observable.hoursOptionsShow.observe(result => {
        hoursOptionsShow = result;
        parameters = {
            symbol: '',	// дефолтный символ валюты - нам не нужен
            decimal: hoursOptionsShow.decimalCharacter,  	// разделитель десятичных
            group: hoursOptionsShow.digitGroupSeparator	// разделитель тысяч и миллионов
        };
    }, true);

    format.$stateful = true;
    function format(val) {

        val = parseFloat(val);
        var formatVal = val.toString();

        var arr = formatVal.split(".");
        // console.log("arr = ", arr);

        if (arr.length == 1) {
          parameters.pattern = (hoursOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.' : '#,##0.';
          // parameters.pattern = '#,##0.';
        }
        if (arr.length == 2 && arr[1].length == 1) {
          parameters.pattern = (hoursOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.0' : '#,##0.0';
          // parameters.pattern = '#,##0.0';
        }
        if (arr.length == 2 && arr[1].length == 2) {
          parameters.pattern = (hoursOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.00' : '#,##0.00';
          // parameters.pattern = '#,##0.00';
        }
        if (arr.length == 2 && arr[1].length == 3) {
          parameters.pattern = (hoursOptionsShow.digitalGroupSpacing == 2)? '#,##,##0.000' : '#,##0.000';
          // parameters.pattern = '#,##0.000';
        }

        val = OSREC.CurrencyFormatter.format(val, parameters);
        // console.log("OSREC val = ", val);

        return val;
    }

    return format;
};

export default {
    type: 'filter',
    name: 'hoursFormatOnlyShow',
    value: hoursFormatOnlyShow
};
