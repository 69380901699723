/**
 * Created by mars on 11/4/15.
 */

import angular from 'angular';

loyaltyControl.$inject = [
	'$scope',
	'factoryProfile',
	'serviceToast',
	'serviceDialog',
	'$filter',
	'$rootScope',
	'$state',
	'$timeout',
	'$profile',
	'$currencyOptionsEdit',
	'lvMixpanel'];

function loyaltyControl( $scope,
		   factoryProfile,
		   serviceToast,
		   serviceDialog,
		   $filter,
		   $rootScope,
		   $state,
		   $timeout,
		   $profile,
		   $currencyOptionsEdit,
		 lvMixpanel) {
	$scope.profile = null;

	var profile;

	$scope.profile = angular.copy( $profile );
	profile = angular.copy( $profile );

	/*** формат ввода валюты ***/
	$scope.currencyOptionsEdit = $currencyOptionsEdit;


	$scope.onCancel = function () {
		factoryProfile.getProfile( true ).then( function ( profile ) {
			$scope.profile = profile;
		} )
	};


	$scope.editLoyaltyForMixpanel = function (profileBefore, profileAfter) {
		if (profileBefore._creditRate !== profileAfter._creditRate) {
			lvMixpanel.track('Edit setting', {"Property": "Settings/Edit loyalty percentage"});
		}
		if (profileBefore._initialCredits !== profileAfter._initialCredits) {
			lvMixpanel.track('Edit setting', {"Property": "Settings/Edit reward points for Loyalty Ocean app installation"});
		}
	};


	$scope.onSave = function () {
		if ( !$scope.permits.editProfile ) {
			serviceToast.show( 'NO_ACCESS', 'error' );
			return;
		}
		factoryProfile.setProfile( $scope.profile ).then( function ( d ) {
			if ( angular.isObject( d ) && d.result == 'ok' ) {
				serviceToast.show( 'LOYALTY_EDITED_OK', 'success' );
				$scope.editLoyaltyForMixpanel(profile, $scope.profile);
				profile = angular.copy( $scope.profile );
			} else {
				serviceToast.show( 'SAVE_ERROR', 'error' );
			}
		} )
	};

	var stateChangeStart;
	stateChangeStart = $rootScope.$on( '$stateChangeStart', function ( event, _toState, _toParam, fromSate, fromParam ) {
		if( (!angular.equals(profile._creditRate, $scope.profile._creditRate)) ||
			(!angular.equals( Number(profile._initialCredits),  Number($scope.profile._initialCredits)))	){
			$timeout(function(){
				serviceDialog.add({
					templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
					title: $filter('translate')('UNSAVED_TITLE'),
					content: $filter('translate')('UNSAVED_LEAVE'),
					buttons: [

						{
							text: 'UNSAVED_CONTINUE',
							action: function(){

							}
						},
						{
							class: 'primary',
							text: 'UNSAVED_DISCARD',
							action: function () {
								stateChangeStart();
								$state.go(_toState, _toParam );
							}
						}
					]
				});
			});

			event.preventDefault();
		} else {
			stateChangeStart();
		}
	})

}

export default {
	type: 'controller',
	name: 'loyaltyControl',
	value: loyaltyControl
};
