myConteinerMonth.$inject = ['serviceEvents'];

function myConteinerMonth(serviceEvents) {
    return {
        restrict: 'A',
        //controller: 'calendarContrl',
        link: function ( scope, el ) {
            this.params = {el: el};
            scope.myConteinerMonth = {
                el: el,
                mouse: false
            };
            el
                .on( 'mousedown', function ( e ) {
                    serviceEvents.mouseDown = true;
                    scope.$apply()
                } )
                .on( 'mouseup', function ( e ) {
                    serviceEvents.mouseDown = false;
                    serviceEvents.startDay = null;
                    scope.$apply()
                } )
                .on( 'mouseleave', function ( e ) {
                    serviceEvents.mouseDown = false;
                    serviceEvents.startDay = null;
                    scope.$apply()
                } );


            scope.$on( "$destroy", function () {
                el.off( 'mousedown mouseup mouseleave' )
            } );

        }
    }
}

export default myConteinerMonth;