config.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];
function config($stateProvider, $urlRouterProvider, $locationProvider) {
    var parentViewTemplate = '<ui-view/>';

    $locationProvider.hashPrefix('');

    profile.$inject = ['factoryProfile'];
    function profile(factoryProfile) {
        return factoryProfile.getProfile();
    }

    lang.$inject = ['serviceLang'];
    function lang(serviceLang) {
        return serviceLang.onLoad();
    }

    environment.$inject = ['factoryEnvironment'];
    function environment(factoryEnvironment) {
        return factoryEnvironment.getEnv();
    }

    currencyOptionsEdit.$inject = ['currencyFormat'];
    function currencyOptionsEdit(currencyFormat) {
        return currencyFormat.getCurrencyOptionsEdit();
    }

    currencyOptionsShow.$inject = ['currencyFormat'];
    function currencyOptionsShow(currencyFormat) {
        return currencyFormat.getCurrencyOptionsShow();
    }

    countOptionsShow.$inject = ['currencyFormat'];
    function countOptionsShow(currencyFormat) {
        return currencyFormat.getCountOptionsShow();
    }

    countOptionsEdit.$inject = ['currencyFormat'];
    function countOptionsEdit(currencyFormat) {
        return currencyFormat.getCountOptionsEdit();
    }

    critCountOptionsEdit.$inject = ['currencyFormat'];
    function critCountOptionsEdit(currencyFormat) {
        return currencyFormat.getCritCountOptionsEdit();
    }

    quantOptionsEdit.$inject = ['currencyFormat'];
    function quantOptionsEdit(currencyFormat) {
        return currencyFormat.getQuantOptionsEdit();
    }

    countOptionsEditDivisible.$inject = ['currencyFormat'];
    function countOptionsEditDivisible(currencyFormat) {
        return currencyFormat.getCountOptionsEditDivisible();
    }

    countQuantityForOrder.$inject = ['currencyFormat'];
    function countQuantityForOrder(currencyFormat) {
        return currencyFormat.getCountQuantityForOrder();
    }

    currencyOptionsEditModif.$inject = ['currencyFormat'];
    function currencyOptionsEditModif(currencyFormat) {
        return currencyFormat.getCurrencyOptionsEditModif();
    }

    countQuantityForOrderDivisible.$inject = ['currencyFormat'];
    function countQuantityForOrderDivisible(currencyFormat) {
        return currencyFormat.getCountQuantityForOrderDivisible();
    }

    hoursOptionsShow.$inject = ['currencyFormat'];
    function hoursOptionsShow(currencyFormat) {
        return currencyFormat.getHoursOptionsShow();
    }

    pointsCurrencyShow.$inject = ['currencyFormat'];
    function pointsCurrencyShow(currencyFormat) {
        return currencyFormat.getPointsCurrencyShow();
    }

    countQuantityForLabels.$inject = ['currencyFormat'];
    function countQuantityForLabels(currencyFormat) {
        return currencyFormat.getCountQuantityForLabels();
    }

    countOptionsShowWithPlus.$inject = ['currencyFormat'];
    function countOptionsShowWithPlus( currencyFormat ) {
        return currencyFormat.getCountOptionsShowWithPlus();
    }

    permitsManager.$inject = ['getPermits'];
    function permitsManager(getPermits) {
        return getPermits.getPermitsManager();
    }

    const date = new Date();
    const fromDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    fromDate.setDate(fromDate.getDate() - 6);
    const from = formatDate(fromDate, true);

    const toDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    toDate.setDate(toDate.getDate() + 1);
    toDate.setHours(0);
    toDate.setMinutes(-1);
    const to = formatDate(toDate, false);

    function formatDate(date, isFrom) {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if(isFrom)
            return [year, month, day].join('-') + " 00:00:00";
        else
            return [year, month, day].join('-') + " 23:59:59";
    }

    function parseToDec(val) {
        if (val == null) {
            return val;
        }
        val = val.toString();
        if (val.indexOf('0x') === 0) {
            val = parseInt(val, 16);
        }
        return val;
    }

    const limit = 10;

    const tz = jstz.determine();

    const accessMap = {
        'report': 'ACCESS_REPORTS',
        'goods': 'ACCESS_WARES',
        'inventory': 'ACCESS_INVENTORY',
        'employees': 'ACCESS_MERCHANTS',
        'clients': 'ACCESS_CLIENTS',
        'settings.account': 'ACCESS_BILLING',
        'settings.paytypes': 'ACCESS_EDIT_PAYMENT_TYPE',
        'paymenttypes': 'ACCESS_EDIT_PAYMENT_TYPE',
        'settings.loyalty': 'ACCESS_EDIT_LOYALTY',
        'settings.tax': 'ACCESS_TAXES',
        'createtax': 'ACCESS_TAXES',
        'edittax': 'ACCESS_TAXES',
        'settings.kitchen': 'ACCESS_KITCHEN_PRINTER',
        'createkitchen': 'ACCESS_KITCHEN_PRINTER',
        'editkitchen': 'ACCESS_KITCHEN_PRINTER',
        'settings.dining': 'ACCESS_DINING_OPTIONS',
        'createdining': 'ACCESS_DINING_OPTIONS',
        'editdining': 'ACCESS_DINING_OPTIONS',
        'settings.cashregister': 'ACCESS_CASH_REGISTERS',
        'createcashregister': 'ACCESS_CASH_REGISTERS',
        'editcashregister': 'ACCESS_CASH_REGISTERS',
        'settings.profile': 'ACCESS_EDIT_PROFILE',
        'settings.reciepts': 'ACCESS_EDIT_PROFILE',
        'settings.predefined': 'ACCESS_EDIT_PROFILE'
    };
    const defaultState = 'home';

    function accessRight(stateName) {
        accessRightFn.$inject = ['$state', 'getPermits', '$q'];
        function accessRightFn($state, getPermits, $q) {
            let _accessRight = null;
            while(true) {
                _accessRight = accessMap[stateName];
                if (_accessRight) {
                    break;
                }
                let parts = stateName.split('.');
                if (parts.length > 1) {
                    parts.pop();
                    stateName = parts.join('.');
                }
                else {
                    break;
                }
            }
            if (_accessRight) {
                return getPermits.getPermitsList( {} )
                    .then(permits => {
                        if(!permits.includes(_accessRight)) {
                            $state.go(defaultState);
                        }
                        else {
                            return $q.when();
                        }
                    })
            }
            else {
                return $q.when();
            }
        }

        return accessRightFn;
    }

    $urlRouterProvider
        .otherwise( function () {
            return '/default/000/';
        } );
    $stateProvider
        .state( "home", {
            url: "/home",
            template: require('../templates-pug/home/home.pug').default,
            controller: 'homeControl',
            resolve: {
                $profile: ['factoryProfile', function(factoryProfile) {
                    return factoryProfile.getProfile(true);
                }],
                $staffInfo: ['getEmployees', 'getPermits', function(getEmployees, getPermits) {
                    return getPermits.getPermitsList({})
                        .then(permits => {
                            return permits.includes("ACCESS_MERCHANTS")
                                ? getEmployees.getEmployeesListData({limit: 10, offset: 0})
                                : false;
                        })
                }],
            }
        } )
        /*.state( 'profile', {
         url: "/profile/:id",
         template: require('../templates-pug/profile/profile.pug').default,
         controller: 'profileControl'
         } )*/
        .state('accountdeleted', {
            url: "/account-deleted",
            template: require('../templates-pug/account-deleted.pug').default,
        })
        .state( 'settings', {
            url: "/settings",
            template: require('../templates-pug/settings/settings.pug').default,
            controller: 'settingsControl',
            resolve: {
                lang: lang,
                $profile: profile
            }
        } )
        .state( 'settings.outlet', {
            url: "/outlets?{reloadToUnblock:.*}",
            views: {
                type: {
                    // template: require('../templates-pug/settings/outlets/outlets-list.pug').default,
                    controller: 'outletsCtrl',
                    controllerAs: 'outletsCtrl',
                    templateProvider: ['$templateFactory', 'factoryGoods', 'factoryCategories', '$stateParams',
                        function ( $templateFactory, factoryGoods, factoryCategories, $stateParams ) {
                            return factoryGoods
                                .getWaresProcessStatus()
                                .then( function ( d ) {
                                    // console.log("d in .state = ", d);
                                    if ( d.exists && d.isDone && d.success === true ) {
                                        factoryGoods.dialogEndProcessShow( false, d.inputFileName )
                                        .then( function ( d ) {
                                            return require('../templates-pug/goods/process-exists-for-outlets.pug').default;
                                        } );
                                    }
                                    else if ( d.exists && d.isDone && d.success === false ) {
                                        factoryGoods.dialogEndProcessShowError();
                                    }
                                    else if ( d.exists && !d.isDone ) {
                                        factoryGoods.getTimePromiseWhenImports();
                                        return require('../templates-pug/goods/process-exists-for-outlets.pug').default;
                                    }
                                    else {
                                        return require('../templates-pug/settings/outlets/outlets-list.pug').default;
                                    }
                                }, function ( err ) {
                                    $state.go( 'settings.outlet', $stateParams, {reload: true} );
                                    console.log("err = ", err );
                                } );
                    }],
                    resolve: {
                        $outlets: ['$stateParams', 'getOutletsList', function ($stateParams, getOutletsList) {
                            return getOutletsList.getOutlets({
                                limit: 10,
                                offset: null
                                // limit: limit,
                                // offset: $stateParams.page * limit
                            });
                        }],
                        $profile: profile
                    }
                }
            }
        } )
        .state( 'createoutlet', {
            url: "/settings/createoutlet",
            template: require('../templates-pug/settings/outlets/create-edit-outlet.pug').default,
            controller: 'createoutletCtrl',
            controllerAs: 'createEditOutletCtrl',
            resolve: {
                lang: lang,
                $outlets: ['$stateParams', 'getOutletsList', function ($stateParams, getOutletsList) {
                    return getOutletsList.getOutlets({});
                }],
                $minAccountInfo: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.getMinAccountInfo({});
                }],
                $profile: profile
            }
        } )
        .state( 'editoutlet', {
            url: "/settings/editoutlet?{outletId:int}&{uuid:.*}",
            template: require('../templates-pug/settings/outlets/create-edit-outlet.pug').default,
            controller: 'editoutletCtrl',
            controllerAs: 'createEditOutletCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $getOutlet: ['$stateParams', 'getOutletsList', function ($stateParams, getOutletsList) {
                    return getOutletsList.getOutletById({'outletId': $stateParams.outletId, 'uuid': $stateParams.uuid});
                }]
            },
            params: {
                outletParams: {},
                outletId: null
            }
        } )
        .state( 'settings.loyalty', {
            url: "/loyalty",
            views: {
                type: {
                    template: require('../templates-pug/settings/settings-loyalty.pug').default,
                    controller: 'loyaltyControl',
                    resolve: {
                        $profile: profile,
                        $currencyOptionsEdit: currencyOptionsEdit,
                        accessRight: accessRight('settings.loyalty')
                    }
                }
            }
        } )
        .state( 'settings.profile', {
            url: "/profile",
            views: {
                type: {
                    template: require('../templates-pug/settings/settings-profile.pug').default,
                    controller: 'profileControl',
                    resolve: {
                        $profile: profile,
                        $updatePermissions: ['getPermits', function(getPermits) {
                            return getPermits.getPermitsList({});
                        }],
                        $staffInfo: ['getEmployees', '$updatePermissions', function(getEmployees, $updatePermissions) {
                            // console.log("$updatePermissions = ", $updatePermissions);
                            return ($updatePermissions.some(function (permit) {return permit === "ACCESS_MERCHANTS";}))
                                ? getEmployees.getEmployeesListData({limit: 10, offset: 0})
                                : false;
                        }],
                        $setSignForShowPosBuner: ['getEmployees', function(getEmployees){
                            return getEmployees.setDesableSettinsHintOnPos({});
                        }],
                        accessRight: accessRight('settings.profile')
                        //                    $staffInfo: ['getEmployees', function(getEmployees) {
                        //                        return getEmployees.getEmployeesListData({
                        // limit: 10,
                        // offset: 0
                        //                        });
                        //                    }],
                    }
                }
            }
        } )
        .state( 'settings.reciepts', {
            url: "/reciepts?{outletId:int}&{outletName:.*}",
            views: {
                type: {
                    template: require('../templates-pug/settings/settings-reciepts.pug').default,
                    controller: 'recieptsCtrl',
                    controllerAs: 'recCtrl',
                    resolve: {
                        $profile: profile,
                        $reciepts: ["getReceipt", "$stateParams", function ( getReceipt, $stateParams ) {
                            return getReceipt.getReceiptAttributes( {outletId: $stateParams.outletId} );
                        }],
                        $shortOutlets: ['getReceipt', function (getReceipt) {
                            return getReceipt.getShortOutlets( {} );
                        }],
                        accessRight: accessRight('settings.reciepts')
                    }
                }
            }
        } )

        // предустановленные чеки
        .state( 'settings.predefined', {
            url: "/predefined?{outletId:int}&{outletName:.*}",
            views: {
                type: {
                    template: require('../templates-pug/settings/settings-predefined.pug').default,
                    controller: 'predefinedController',
                    controllerAs: "predefinedCtrl",
                    resolve: {
                        $predefinedTickets: ["getTickets", "$stateParams", function ( getTickets, $stateParams ) {
                            return getTickets.getPredefinedTickets( {outletId: $stateParams.outletId} );
                        }],
                        $shortOutlets: ['getTickets', function (getTickets) {
                            return getTickets.getShortOutlets( {} );
                        }],
                        accessRight: accessRight('settings.predefined')
                    }
                }
            }
        } )

        // список кухонных принтеров
        .state( 'settings.kitchen', {
            url: "/kitchen",
            views: {
                type: {
                    template: require('../templates-pug/settings/kitchen/settings-kitchen.pug').default,
                    controller: 'kitchenCtrl',
                    controllerAs: 'kitchen',
                    resolve: {
                        $profile: profile,
                        $kitchenList: ['getKitchen', function (getKitchen) {
                            return getKitchen.getKitchenList( {} );
                        }],
                        accessRight: accessRight('settings.kitchen')
                    }
                }
            }
        } )
        // создание кухонного принтера
        .state( 'createkitchen', {
            url: "/settings/kitchen/create",
            template: require('../templates-pug/settings/kitchen/kitchen-create.pug').default,
            controller: 'createKitchenCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $categoriesList: ['getKitchen', function (getKitchen) {
                    return getKitchen.getCategoriesForPrinter( {} );
                }],
                accessRight: accessRight('createkitchen')
            }
        } )
        // редактирование кухонного принтера
        .state( 'editkitchen', {
            url: "/settings/kitchen/edit/?{id:int}",
            template: require('../templates-pug/settings/kitchen/kitchen-edit.pug').default,
            controller: 'editKitchenCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $kitchenForEdit: ['getKitchen', '$stateParams', 'dialogs', 'stateGo', '$state', '$q', function (getKitchen, $stateParams, dialogs, stateGo, $state, $q) {
                    return getKitchen.getKitchenById( {printerId: $stateParams.id} )
                        .then(data => {
                            if (data.result === 'error') {
                                if (data.message === 'kitchen_printer_deleted') {
                                    const dialogPromise = dialogs.listItemDeletedEarlier();
                                    if ($state.current.name === 'settings.kitchen') {
                                        dialogPromise.then(() => {
                                            stateGo.refresh();
                                        });
                                    } else {
                                        dialogPromise.finally(() => {
                                            stateGo.goToState('settings.kitchen');
                                        });
                                    }
                                }

                                return $q.reject(data);
                            }
                            return data;
                        });
                }],
                accessRight: accessRight('editkitchen')
            }
        } )

        // список Опции оформления заказа
        .state( 'settings.dining', {
            url: "/dining?{outletId:.*}",
            views: {
                type: {
                    template: require('../templates-pug/settings/dining/dining-list.pug').default,
                    controller: 'diningListCtrl',
                    resolve: {
                        $profile: profile,
                        $shortOutlets: ['getCashRegister', function (getCashRegister) {
                            return getCashRegister.getShortOutlets( {} );
                        }],
                        $diningList: ['getDining', '$stateParams', function (getDining, $stateParams) {
                            return getDining.getDiningList(  {outletId: $stateParams.outletId}  );
                        }],
                        $profileNew: ['factoryProfile', function (factoryProfile) {
                            return factoryProfile.getProfile(true);
                        }],
                        accessRight: accessRight('settings.dining')
                    },
                    params: {
                        outletId: ''
                    }
                }
            }
        } )

        // создание Опции оформления заказа
        .state( 'createdining', {
            url: "/settings/dining/create",
            template: require('../templates-pug/settings/dining/dining-create.pug').default,
            controller: 'creatediningCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $shortOutlets: ['getTaxList', function (getTaxList) {
                    return getTaxList.getShortOutlets( {} );
                }],
                accessRight: accessRight('createdining')
            }
        } )

        // редактирование Опции оформления заказа
        .state( 'editdining', {
            url: "/settings/dining/edit/?{id:int}",
            template: require('../templates-pug/settings/dining/dining-edit.pug').default,
            controller: 'editdiningCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $diningById: ['getDining', '$stateParams', '$state', 'dialogs', 'stateGo', '$q', function (getDining, $stateParams, $state, dialogs, stateGo, $q) {
                    return getDining.getDiningById(  {id: $stateParams.id}  )
                        .then(data => {
                            if (data.result === 'error') {
                                if (data.message === 'dining_option_deleted') {
                                    const dialogPromise = dialogs.listItemDeletedEarlier();
                                    if ($state.current.name === 'settings.dining') {
                                        dialogPromise.then(() => {
                                            stateGo.refresh();
                                        });
                                    } else {
                                        dialogPromise.finally(() => {
                                            stateGo.goToState('settings.dining');
                                        });
                                    }
                                }
                                return $q.reject(data);
                            } else {
                                return data;
                            }
                        });
                }],
                accessRight: accessRight('editdining')
            }
        } )

        // стейты для АККАУНТА
        .state( 'settings.account', {
            url: '/account?{reloadToUnblock:.*}',
            views: {
                type: {
                    template: require('../templates-pug/settings/billing/billing-main.pug').default,
                    controller: 'accountController',
                    resolve: {
                        $profile: profile,
                        $environment: environment,
                        $permits: ['getPermits', function ( getPermits ) {
                            return getPermits.getPermitsList( {} );
                        }],
                        lang: lang,
                        $accountInfo: ['factoryBilling', function(factoryBilling) {
                            return factoryBilling.getAccountInfo( );
                        }],
                        $currencyOptionsShow: currencyOptionsShow,
                        accessRight: accessRight('settings.account')
                    },
                    params: {
                        reloadToUnblock: false
                    }

                }
            }
        } )
        .state( 'accountblock', {
            url: '/accountblock',
            template: require('../templates-pug/settings/billing/billing-block.pug').default,
            controller: 'accountBlockController',
            resolve: {
                lang: lang
            }
        })

        .state( 'myaccount', {
            url: "/myAccount",
            template: require('../templates-pug/myaccount/my-account.pug').default,
            controller: 'myAccountCTRL',
            controllerAs: 'myAccCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $environment: environment,
                $accInfo: ['accountInfo', function ( accountInfo ) {
                    return accountInfo.getAccountInfo({});
                }],
                $currencies: ['accountInfo', function(accountInfo) {
                    return accountInfo.getCurrencies({});
                }]
            }
        } )

        .state( 'webhookslist', {
            url: "/webhooks",
            template: require('../templates-pug/webhooks/webhooks-list.pug').default,
            controller: 'webHList',
            controllerAs: 'webHList',
            resolve: {
                lang: lang,
                $profile: profile,
                $hooksList: ['$stateParams', 'factoryWebHooks', function ( $stateParams, factoryWebHooks ) {
                    return factoryWebHooks.getWebHooksList( {} );
                }]
            }
        } )
        .state( 'createwhook', {
            url: "/createWHook",
            template: require('../templates-pug/webhooks/create-edit-webhook.pug').default,
            controller: 'createWHook',
            controllerAs: 'crtWHookCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $hookInfo: function() {
                    return false;
                }
            }
        } )
        .state( 'editwhook', {
            url: "/editWHook?{id:.*}",
            template: require('../templates-pug/webhooks/create-edit-webhook.pug').default,
            controller: 'createWHook',
            controllerAs: 'crtWHookCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $hookInfo: ['$stateParams', 'factoryWebHooks', function ( $stateParams, factoryWebHooks ) {
                    return factoryWebHooks.getWebHookById( {
                        id: $stateParams.id
                    } );
                }]
            }
        } )
        .state( 'whookdetails', {
            url: "/wHookDetails?{id:.*}",
            template: require('../templates-pug/webhooks/whook-detail.pug').default,
            controller: 'wHookDetail',
            controllerAs: 'wHookDetailCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $hookInfo: ['$stateParams', 'factoryWebHooks', function ( $stateParams, factoryWebHooks ) {
                    return factoryWebHooks.getWebHookById( {
                        id: $stateParams.id
                    } );
                }]
            }
        } )

        .state( 'addPayMethodStr', {
            url: '/settings/account/payment/str',
            template: require('../templates-pug/settings/billing/billing-payment-method-str.pug').default,
            controller: 'payMethodControllerStr',
            resolve: {
                lang: lang,
                $profile: profile,
                $environment: environment,
                $token: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.getToken();
                }],
                $minAccountInfo: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.getMinAccountInfo();
                }]
            },
            params: {
                newUser: {},
                addSubscribe: {},
                trialStatus: "",
                typeSubscriptions: "",
                urlForReload: "",
                activate: "",
                coupon: null,
                urlForSuccessReload: "",
                subscriptionId: "",
                source: null
            }
        } )
        .state( 'addPayMethodBt', {
            url: '/settings/account/payment/bt',
            template: require('../templates-pug/settings/billing/billing-payment-method-bt.pug').default,
            controller: 'payMethodControllerBt',
            resolve: {
                lang: lang,
                $profile: profile,
                $token: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.getToken();
                }],
                $minAccountInfo: ['factoryBilling', function(factoryBilling) {
                    return factoryBilling.getMinAccountInfo();
                }]
            },
            params: {
                newUser: {},
                addSubscribe: {},
                trialStatus: "",
                typeSubscriptions: "",
                urlForReload: "",
                activate: "",
                coupon: null,
                urlForSuccessReload: "",
                subscriptionId: ""
            }
        } )
        .state( 'editBillingDetails', {
            url: '/settings/account/editdetails',
            template: require('../templates-pug/settings/billing/billing-edit-details.pug').default,
            controller: 'editDetailsController',
            resolve: {
                lang: lang,
                $profile: profile,
                $billingDetails: ['factoryBilling', function(factoryBilling) {
                    return factoryBilling.getBillingDetails();
                }]
            }
        } )
        // стейты для АККАУНТА


        // стейты для налогов
        .state( 'settings.tax', {
            url: "/tax?{outletsIds:.*}&{outletName:.*}",
            views: {
                type: {
                    template: require('../templates-pug/settings/tax/tax.pug').default,
                    controller: 'taxCtrl',
                    resolve: {
                        $tax: ['getTaxList', '$stateParams', function(getTaxList, $stateParams) {
                            return getTaxList.getTaxListData( { outletsIds: $stateParams.outletsIds } );
                        }],
                        $profile: profile,
                        $shortOutlets: ['getTaxList', function(getTaxList) {
                            return getTaxList.getShortOutlets( {} );
                        }],
                        $percentShowTax: ['currencyFormat', function(currencyFormat) {
                            return currencyFormat.getPercentShow(false, false, false, true);
                        }],
                        accessRight: accessRight('settings.tax')
                    }
                }
            }
        } )

        .state( 'createtax', {
            url: "/createtax",
            template: require('../templates-pug/settings/tax/tax-create.pug').default,
            controller: 'createtaxCtrl',
            controllerAs: 'credtaxCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $tax: ['getTaxList', function (getTaxList) {
                    return getTaxList.getTaxListData( {} );
                }],
                $taxById: ['getTaxList', function(getTaxList) {
                    return getTaxList.getTaxListData( {} );
                }],
                $shortOutlets: ['getTaxList', function(getTaxList) {
                    return getTaxList.getShortOutlets( {} );
                }],
                $percentShowTax: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, false, false, true);
                }],
                $categoriesList: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getCategoriesForAddingItems({onlyStock:false});
                }],
                accessRight: accessRight('createtax')
            }
        } )
        .state( 'edittax', {
            url: "/edittax/?{id:int}",
            template: require('../templates-pug/settings/tax/tax-create.pug').default,
            controller: 'createtaxCtrl',
            controllerAs: 'credtaxCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $tax: ['getTaxList', function(getTaxList) {
                    return getTaxList.getTaxListData( {} );
                }],
                $taxById: ['getTaxList', '$stateParams', '$q', '$state', 'dialogs', 'stateGo', function(getTaxList, $stateParams, $q, $state, dialogs, stateGo) {
                    return getTaxList.getTaxById( {id: $stateParams.id} )
                        .then(data => {
                            if(data.result === 'error' && data.message === 'tax_deleted') {
                                const dialogPromise = dialogs.listItemDeletedEarlier();
                                if ($state.current.name === 'settings.tax') {
                                    dialogPromise.then(() => {
                                        stateGo.refresh();
                                    });
                                } else {
                                    dialogPromise.finally(() => {
                                        stateGo.goToState('settings.tax');
                                    });
                                }
                                return $q.reject(data);
                            }
                            return data;
                        });
                }],
                $shortOutlets: ['getTaxList', function(getTaxList) {
                    return getTaxList.getShortOutlets( {} );
                }],
                $percentShowTax: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, false, false, true);
                }],
                accessRight: accessRight('edittax')
            }
        } )
        // стейты для налогов


        .state( 'report', {
            abstract: true,
            url: '/report',
            template: parentViewTemplate,
            resolve: {
                lang: lang,
                accessRight: accessRight('report'),
                $miniBillingInfo: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.getMinAccountInfo({});
                }],
                $reportCopyAllowed: ['factoryBilling', function (factoryBilling) {
                    return factoryBilling.isSalesHistoryAllowed();
                }]
            }
        } )
        .state( 'report.goods', {
            url: '/goods?{page:int}&{limit:.*}&{chart:.*}&{group:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-goods.pug').default,
            controller: 'reportGoodsControl',
            resolve: {
                $profile: profile,
                $tableProp: ['tableColumnProp', function(tableColumnProp){
                    return tableColumnProp.getProps({"reportType":"WARES"});
                }],
                $report: ['factoryReportGoods', 'filtersData', '$stateParams', '$rootScope', 'serviceReportsError',
                    function ( factoryReportGoods, filtersData, $stateParams, $rootScope, serviceReportsError ) {
                    var stateParams;
                    stateParams = filtersData.getStateParams();

                    if (stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                        $stateParams.tzName = tz.name();
                        $stateParams.group = stateParams.group || $stateParams.group;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.limit = parseToDec($stateParams.limit);
                    }

                    return factoryReportGoods.getWaresReport($stateParams).then(
                        function (res) {
                            return res;
                        },
                        function (err) {
                            return serviceReportsError.reportsError(err);
                        });
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowInfinityToHundred: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, true, false, false);
                }],
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        })
        .state( 'report.sales', {
            url: '/sales?{page:int}&{limit:.*}&{group:.*}&{serverChartType:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-sales.pug').default,
            controller: 'reportSalesControl',
            resolve: {
                $tableProp: ['tableColumnProp', function(tableColumnProp){
                    return tableColumnProp.getProps({"reportType":"SALES"});
                }],
                $profile: profile,
                $proceeds: ['$stateParams', '$rootScope', 'filtersData', 'resolveTimezone', 'getProceeds',
                    function ( $stateParams, $rootScope, filtersData, resolveTimezone, getProceeds ) {
                        var stateParams;
                        stateParams = filtersData.getStateParams();

                        if(stateParams) {
                            $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds;
                            $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds;
                            $stateParams.from = stateParams.from || $stateParams.from || from;
                            $stateParams.to = stateParams.to || $stateParams.to || to;
                            $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                            $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                            $stateParams.group = stateParams.group || $stateParams.group;
                            $stateParams.serverChartType = stateParams.serverChartType || $stateParams.serverChartType;
                            $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                            $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                            $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                            $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                            $stateParams.predefinedPeriod = {};
                            $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                            $stateParams.predefinedPeriod.period = $stateParams.arg;
                            $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                            // $stateParams.page = stateParams.page || $stateParams.page;
                            // $stateParams.limit = stateParams.limit || limit;
                        }

                        $stateParams.tzOffset = resolveTimezone.timeZoneOffsetForReports();
                        $stateParams.tzName =  tz.name();

                        let limit = parseToDec($stateParams.limit);

                        return getProceeds.getProceedsData( {
                            merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                            outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null,
                            startDate: $stateParams.from,
                            endDate: $stateParams.to,
                            startWeek: $stateParams.startWeek,
                            tzOffset: $stateParams.tzOffset,
                            tzName: $stateParams.tzName,
                            startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                            endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                            customPeriod: $stateParams.customPeriod,
                            predefinedPeriod: $stateParams.predefinedPeriod,
                            divider: $stateParams.group,
                            limit: 		limit,
                            offset:		$stateParams.page * limit
                        } );
                    }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowInfinityToInfinity: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(true, false, false, false);
                }],
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.employee', {
            url: '/employee?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-employee.pug').default,
            controller: 'reportEmployeeController',
            controllerAs: 'vmReportEmployeeCtrl',
            resolve: {
                $employee: ['factoryEmployeeReport', 'filtersData', 'resolveTimezone', '$stateParams', '$rootScope', function ( factoryEmployeeReport, filtersData, resolveTimezone, $stateParams, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                        // $stateParams.page = stateParams.page || $stateParams.page;
                        // $stateParams.group = stateParams.group ||$stateParams.group;
                        // $stateParams.limit = stateParams.limit || limit;
                    }

                    let limit = parseToDec($stateParams.limit);

                    return factoryEmployeeReport.getEmployeeReport( {
                        fromDate: $stateParams.from,
                        toDate: $stateParams.to,
                        tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                        endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                        divider: $stateParams.group,
                        offset: $stateParams.page * limit,
                        limit: limit,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null,
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod
                    } );
                }],
                $profile: profile,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds:'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.receipts', {
            url: '/average?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{type:.*}&{outletsIds:.*}&{merchantsIds:.*}&{search:.*}',
            template: require('../templates-pug/report/sales/report-receipts.pug').default,
            controller: 'reportReceiptsControl',
            controllerAs: 'vmReportReceipts',
            resolve: {
                $profile: profile,
                $report: ['filtersData', 'factoryReceipts', '$stateParams', '$rootScope', 'serviceReportsError',
                    function ( filtersData, factoryReceipts, $stateParams, $rootScope, serviceReportsError ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || "all";
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || "all";
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        // $stateParams.page = stateParams.page || $stateParams.page;
                        $stateParams.group = stateParams.group ||$stateParams.group;
                        $stateParams.tzName = tz.name();
                        $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.receiptId = stateParams.receiptId || null;
                        $stateParams.limit = parseToDec($stateParams.limit || limit);
                    }
                    //temporary solution, functioning of factory should be changed
                    $stateParams.offset = $stateParams.page * parseToDec($stateParams.limit);
                    return factoryReceipts.getReceipts( $stateParams ).then(function(res) {
                        return res;
                    }, function(err) {
                        return serviceReportsError.reportsError(err);
                    });
                }],
                $exportParams: ['factoryReceipts', function(factoryReceipts) {
                    //temporary solution, functioning of factory should be changed
                    return factoryReceipts.exportParams;
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowTax: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, false, false, true);
                }],
                $permitsManager: permitsManager
            },
            onExit: ['shareReceipt', function (shareReceipt) {
                shareReceipt.setReceipt(null);
                shareReceipt.setCurrencyShow(null);
                shareReceipt.setPercentShowTax(null);
            }],
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.tax', {
            url: '/tax?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template : require("../templates-pug/report/sales/report-tax.pug").default,
            controller: "reportTaxController",
            controllerAs: "vmReportTaxCtrl",
            resolve: {
                $profile: profile,
                $reportTax: ['filtersData', 'getReportTax', 'resolveTimezone', '$stateParams', '$rootScope', 'serviceReportsError',
                    function (filtersData, getReportTax, resolveTimezone, $stateParams, $rootScope, serviceReportsError) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        // $stateParams.page = stateParams.page || $stateParams.page;
                        $stateParams.group = stateParams.group ||$stateParams.group;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                        // $stateParams.limit = stateParams.limit || limit;
                    }

                    let limit = parseToDec($stateParams.limit);
                    return getReportTax.getReportTaxData( {
                        fromDate: $stateParams.from,
                        toDate: $stateParams.to,
                        limit: limit,
                        offset: $stateParams.page * limit,
                        tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                        endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                    } ).then(function(res) {
                        return res;
                    }, function(err) {
                        return serviceReportsError.reportsError(err);
                    });
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowTax: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, false, false, true);
                }],
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.shift', {
            url: '/shift?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-shift.pug').default,
            controller: "reportShiftsCtrl",
            controllerAs: "vmRepShiftsCtrl",
            resolve: {
                $profile: profile,
                $reportShift: ['filtersData', '$stateParams', 'getShifts', 'resolveTimezone', '$rootScope',
                    function ( filtersData, $stateParams, getShifts, resolveTimezone, $rootScope ) {
                        var stateParams = filtersData.getStateParams();
                        if(stateParams) {
                            $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || "all";
                            $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || "all";
                            $stateParams.from = stateParams.from || $stateParams.from || from;
                            $stateParams.to = stateParams.to || $stateParams.to || to;
                            $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                            $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                            // $stateParams.page = stateParams.page || $stateParams.page;
                            $stateParams.group = stateParams.group ||$stateParams.group;
                            $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                            $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                            $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                            $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                            $stateParams.predefinedPeriod = {};
                            $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                            $stateParams.predefinedPeriod.period = $stateParams.arg;
                            $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                            // $stateParams.limit = stateParams.limit || limit;
                        }
                        let limit = parseToDec($stateParams.limit);
                        return getShifts.getShiftsList( {
                            fromDate: $stateParams.from,
                            toDate: $stateParams.to,
                            tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                            startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                            endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                            limit: limit,
                            offset: $stateParams.page * limit,
                            tzName: tz.name(),
                            startWeek: $stateParams.startWeek,
                            customPeriod: $stateParams.customPeriod,
                            predefinedPeriod: $stateParams.predefinedPeriod,
                            merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                            outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                        } );
                    }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowTax: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, false, false, true);
                }],
                $permitsManager: permitsManager
            },
            onExit: ['shareCashboxShift', function (shareCashboxShift) {
                shareCashboxShift.setCashboxShift(null);
                shareCashboxShift.setCurrencyShow(null);
            }],
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.discounts', {
            url: '/discounts?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-discounts.pug').default,
            controller: 'reportDiscountsController',
            controllerAs: 'vmRepDiscCtrl',
            resolve: {
                $profile: profile,
                $discounts: ['filtersData', '$stateParams', 'discountsSummary', 'resolveTimezone', '$rootScope', function ( filtersData, $stateParams, discountsSummary, resolveTimezone, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || "all";
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || "all";
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        // $stateParams.page = stateParams.page || $stateParams.page;
                        $stateParams.group = stateParams.group ||$stateParams.group;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                        // $stateParams.limit = stateParams.limit || limit;
                    }
                    let limit = parseToDec($stateParams.limit);
                    return discountsSummary.getDiscountsSummary( {
                        fromDate: $stateParams.from,
                        limit: limit,
                        offset: $stateParams.page * limit,
                        toDate: $stateParams.to,
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                        endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                    } )
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )
        .state( 'report.modifiers', {
            url: '/modifiers?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-modifiers.pug').default,
            controller: 'reportModifiersController',
            controllerAs: 'vmRepModCtrl',
            resolve: {
                $profile: profile,
                $tableProp: ['tableColumnProp', function(tableColumnProp){
                    return tableColumnProp.getProps({"reportType":"MODIFIERS"});
                }],
                $modifiers: ['filtersData', '$stateParams', 'modifiersSummary', 'resolveTimezone', '$rootScope', function ( filtersData, $stateParams, modifiersSummary, resolveTimezone, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || "all";
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || "all";
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                        $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                        // $stateParams.page = stateParams.page || $stateParams.page;
                        $stateParams.group = stateParams.group ||$stateParams.group;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                        // $stateParams.limit = stateParams.limit || limit;
                    }
                    let limit = parseToDec($stateParams.limit);
                    return modifiersSummary.getModifiersSummary( {
                        fromDate: $stateParams.from,
                        limit: limit,
                        offset: $stateParams.page * limit,
                        toDate: $stateParams.to,
                        tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                        endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                    } );
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        } )

        .state( 'goods', {
            url: '/goods',
            abstract: true,
            template: parentViewTemplate,
            resolve: {
                lang: lang
            }
        } )

        // СТЕЙТЫ ДЛЯ ТОВАРОВ
        .state( 'goods.price', {
            url: '/price?{page:int}&{limit:.*}&{search:.*}&{inventory:.*}&{category:([\-0-9].+)||null}&{outletId:([\-0-9].+)||null}',
            // template: require('../templates-pug/goods/goods-list.pug').default,
            controller: 'goodsListControl',
            controllerAs: 'vmGoodsListCtrl',
            templateProvider: ['$templateFactory', 'factoryGoods', 'factoryCategories',
                function ( $templateFactory, factoryGoods, factoryCategories ) {

                    return factoryGoods
                        .getWaresProcessStatus()
                        .then( function ( d ) {
                            if ( d.exists && d.isDone && d.success === true ) {
                                factoryGoods.dialogEndProcessShow( false, d.inputFileName )
                                .then( function ( d ) {
                                    return require('../templates-pug/goods/process-exists.pug').default;
                                } );
                            }
                            else if ( d.exists && d.isDone && d.success === false ) {
                                factoryGoods.dialogEndProcessShowError();
                            }
                            else if ( d.exists && !d.isDone ) {
                                factoryGoods.getTimePromiseWhenImports();
                                return require('../templates-pug/goods/process-exists.pug').default;
                            }
                            else {
                                return require('../templates-pug/goods/goods-list.pug').default;
                            }
                        }, function ( err ) {
                            $state.go( 'goods.price', {page: 0, limit: 10, inventory:'all', outletId: $stateParams.outletId}, {reload: true} );
                            console.log("err = ", err );
                        } );
            }],
            resolve: {
                /* $wares: function (factoryGoods, $stateParams) {
                 return factoryGoods.getWares($stateParams);
                 },*/
                $shortOutlets: ['factoryGoods', function(factoryGoods) {
                    return factoryGoods.getShortOutlets( {} );
                }],
                $categories: ['factoryCategories', function(factoryCategories) {
                    return factoryCategories.getCategories();
                }],
                $wares: ['$stateParams', 'factoryGoods',
                    function ( $stateParams, factoryGoods ) {
                        // console.log("$stateParams in STATE = ", $stateParams);
                        let limit = parseToDec($stateParams.limit);
                        return factoryGoods.getWaresWithCategories( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            inventory: 	$stateParams.inventory,
                            category: 	$stateParams.category,
                            outletId: 	$stateParams.outletId
                        } );
                    }

                ],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $countOptionsEdit: countOptionsEdit,
                $percentInfinityToHundred: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPercentShow(false, true, false, false);
                }],
                $critCountOptionsEdit: critCountOptionsEdit,
                $quantOptionsEdit: quantOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('goods')
            },
            params: {
                checkFromList: {}
                // checkToSave: {}
                // expandFromList: {},
                // expandedToSave: {}
            }
        } )
        .state( 'goods.create', {
            url: '/new',
            template: require('../templates-pug/goods/create-edit.pug').default,
            controller: 'createEditWareControl',
            resolve: {
                $ware: ['factoryGoods', '$stateParams', function(factoryGoods, $stateParams) {
                    return factoryGoods.getWare($stateParams.id);
                }],
                $categoriesData: ['factoryCategories', function(factoryCategories) {
                    return factoryCategories.getCategoriesListForItemCard();
                }],
                $shortOutlets: ['factoryGoods', function(factoryGoods) {
                    return factoryGoods.getShortOutlets( {} );
                }],
                $suppliers: ['factoryGoods', function(factoryGoods) {
                    return factoryGoods.getSuppliers({});
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsEdit: countOptionsEdit,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $quantOptionsEdit: quantOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
                // expandFromList: {},
                // expandedToSave: {}
            },
            data: {
                title: 'CREATE_GOODS'
            }
        } )
        .state( 'goods.edit', {
            url: '/edit/:id',
            template: require('../templates-pug/goods/create-edit.pug').default,
            controller: 'createEditWareControl',
            resolve: {
                $ware: ['factoryGoods', 'dialogs', '$stateParams', '$state', '$q', 'stateGo', function(factoryGoods, dialogs, $stateParams, $state, $q, stateGo) {
                    return factoryGoods.getWare( $stateParams.id )
                        .catch(data => {
                            if(data.result === 'error' && data.message === 'item_deleted') {
                                const dialogPromise = dialogs.listItemDeletedEarlier();
                                if ($state.current.name === 'goods.price') {
                                    dialogPromise.then(() => {
                                        stateGo.refresh();
                                    });
                                } else {
                                    dialogPromise.finally(() => {
                                        stateGo.goToState('goods.price');
                                    });
                                }
                            }
                            return $q.reject(data);
                        });
                }],
                $categoriesData: ['factoryCategories', function(factoryCategories) {
                    return factoryCategories.getCategoriesListForItemCard();
                }],
                $shortOutlets: ['factoryGoods', function(factoryGoods) {
                    return factoryGoods.getShortOutlets( {} );
                }],
                $suppliers: ['factoryGoods', function(factoryGoods) {
                    return factoryGoods.getSuppliers({});
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsEdit: countOptionsEdit,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $quantOptionsEdit: quantOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
                // expandFromList: {},
                // expandedToSave: {}
            },
            data: {
                title: 'EDIT_GOODS'
            }
        } )
        // СТЕЙТЫ ДЛЯ ТОВАРОВ


        // СТЕЙТЫ ДЛЯ КАТЕГОРИЙ
        .state( 'goods.categories', {
            url: '/categories?{page:int}&{limit:.*}&{search:.*}',
            template: require('../templates-pug/goods/categories-list.pug').default,
            controller: 'categoriesListCtrl',
            resolve: {
                $profile: profile,
                $categories: ['factoryCategories', '$stateParams', function(factoryCategories, $stateParams) {
                    $stateParams.limit = parseToDec($stateParams.limit);
                    return factoryCategories.getCategoriesByPage( $stateParams );
                }],
                $countOptionsShow: countOptionsShow,
                accessRight: accessRight('goods')
            },
            params: {
                checkFromList: {}
            }
        } )
        .state( 'goods.categoryedit', {
            url: '/categoryedit/:id',
            template: require('../templates-pug/goods/create-edit-category.pug').default,
            controller: 'createEditCategoryControl',
            resolve: {
                $profile: profile,
                $category: ['factoryCategories', '$stateParams', '$q', 'dialogs', 'stateGo', '$state', function(factoryCategories, $stateParams, $q, dialogs, stateGo, $state) {
                    return factoryCategories.getCategoryById($stateParams.id)
                        .then(data => {
                            if (data.result === 'error' && data.message === 'category_deleted') {
                                const dialogPromise = dialogs.listItemDeletedEarlier();
                                if ($state.current.name === 'goods.categories') {
                                    dialogPromise.then(() => {
                                        stateGo.refresh();
                                    });
                                } else {
                                    dialogPromise.finally(() => {
                                        stateGo.goToState('goods.categories');
                                    });
                                }
                                return $q.reject(data);
                            }
                            return $q.resolve(data);
                        });
                }],
                $countOptionsShow: countOptionsShow,
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'goods.categorycreate', {
            url: '/categorycreate',
            template: require('../templates-pug/goods/create-edit-category.pug').default,
            controller: 'createEditCategoryControl',
            resolve: {
                $profile: profile,
                $category: () => ({ name: "", colorName: "GREY" }),
                $countOptionsShow: countOptionsShow,
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        // СТЕЙТЫ ДЛЯ КАТЕГОРИЙ

        .state( 'goods.export', {
            url: '/export',
            template: 'Экспорт'
        } )
        .state( 'goods.import', {
            url: '/import?{outletId:.*}',
            controller: 'goodsImportControl',
            controllerAs: 'model',
            resolve: {
                $profile: profile,
                accessRight: accessRight('goods')
            },
            templateProvider: ['$templateFactory', 'factoryGoods', '$state', '$stateParams', 'factoryCategories',
                function ( $templateFactory, factoryGoods, $state, $stateParams, factoryCategories ) {
                    return factoryGoods
                        .getWaresProcessStatus()
                        .then( function ( d ) {
                            //todo убрать
                            //factoryGoods.dialogEndProcessShow(false,'ololo csv');

                            if ( d.exists && d.isDone ) {
                                factoryGoods.dialogEndProcessShow( false, d.inputFileName );
                                factoryCategories
                                    .getCategories( true )
                                    .then( function ( d ) {
                                        return require('../templates-pug/goods/process-exists.pug').default;
                                    } );
                            } else if ( d.exists && !d.isDone ) {
                                factoryGoods.getTimePromiseWhenImports();
                                return require('../templates-pug/goods/process-exists.pug').default;
                            } else {
                                return require('../templates-pug/goods/goods-import.pug').default;
                            }
                        }, function ( err ) {
                            $state.go( 'goods.import', { outletId: $stateParams.outletId }, {reload: true} );
                            console.log( err );
                        } );
            }]
        } )

        .state( 'clients', {
            url: '/clients',
            abstract: true,
            template: parentViewTemplate,
            resolve: {
                lang: lang,
                accessRight: accessRight('clients')
            }
        } )

        .state( 'clients.database', {
            url: '/database?{page:int}&{limit:.*}&{search:.*}',
            template: require('../templates-pug/clients/clients-database.pug').default,
            controller: 'clientsDBControl',
            controllerAs: 'clientsDBCtrl',
            resolve: {
                $profile: profile,
                $getClientsDB: ['$stateParams', 'clientsDB',
                    function ( $stateParams, clientsDB ) {
                        let limit = parseToDec($stateParams.limit);
                        return clientsDB.getClientsDBData( {
                            limit: limit,
                            offset: $stateParams.page * limit,
                            search: $stateParams.search
                        } );
                    }],
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $pointsCurrencyShow: pointsCurrencyShow
            }
        } )

        .state( 'clients.import', {
            url: '/database/import',
            controller: 'clientBaseImportControl',
            controllerAs: 'model',
            resolve: {
                $profile: profile
            },
            templateProvider: ['clientsDB', '$state',
                function (clientsDB, $state) {
                    return clientsDB
                        .getClientsProcessStatus()
                        .then( function ( d ) {
                            //todo убрать
                            //factoryGoods.dialogEndProcessShow(false,'ololo csv');

                            if ( d.exists && d.isDone && d.success ) {
                                clientsDB.dialogEndProcessShow( false, d.inputFileName );
                            } else if ( d.exists && d.isDone && !d.success ) {
                                clientsDB.dialogEndProcessShowError( false, d.inputFileName );
                            } else if ( d.exists && !d.isDone ) {
                                clientsDB.getTimePromise();
                                return require('../templates-pug/import-entity/process-exists.pug').default;
                            } else {
                                return require('../templates-pug/import-entity/import-entity.pug').default;
                            }
                        }, function ( err ) {
                            $state.go( $state.current.name, {reload: true} );
                            console.log( err );
                        } );
            }]
        } )

        .state( 'clients.createcustomer', {
            url: '/createcustomer',
            template: require('../templates-pug/clients/create-edit-customer.pug').default,
            controller: 'createEditCustomerController',
            controllerAs: 'customerCardCtrl',
            resolve: {
                $profile: profile,
                $clientDetail: function() {
                    return false;
                }
            },
            params: {
                stateToSave: {}
            }
        })

        .state( 'clients.editcustomer', {
            url: '/editcustomer?{id:int}',
            template: require('../templates-pug/clients/create-edit-customer.pug').default,
            controller: 'createEditCustomerController',
            controllerAs: 'customerCardCtrl',
            resolve: {
                $profile: profile,
                $clientDetail: ['$stateParams', 'clientsDB', 'dialogs', '$state', 'stateGo', '$q', function ( $stateParams, clientsDB, dialogs, $state, stateGo, $q) {
                    return clientsDB.getClientById( {
                        id: $stateParams.id
                    } ).then(data => {
                        if(data.result === 'error' && data.message === 'customer_deleted') {
                            const dialogPromise = dialogs.listItemDeletedEarlier();
                            if ($state.current.name === 'clients.database') {
                                dialogPromise.then(() => {
                                    stateGo.refresh();
                                });
                            } else {
                                dialogPromise.finally(() => {
                                    stateGo.goToState('clients.database');
                                });
                            }
                            return $q.reject(data);
                        } else {
                            return data;
                        }
                    });
                }]
            },
            params: {
                stateToSave: {}
            }
        })

        .state( 'clients.customerdetail', {
            url: '/detailcustomer?{id:int}',
            template: require('../templates-pug/clients/customer-details.pug').default,
            controller: 'customerDetailsController',
            controllerAs: 'customerDetailsCtrl',
            resolve: {
                $profile: profile,
                $pointsCurrencyEdit: ['currencyFormat', function(currencyFormat) {
                    return currencyFormat.getPointsCurrencyEdit();
                }],
                $pointsCurrencyShow: pointsCurrencyShow,
                $currencyOptionsShow: currencyOptionsShow,
                $clientDetail: ['$stateParams', 'clientsDB', 'dialogs', '$state', 'stateGo', '$q', function ( $stateParams, clientsDB, dialogs, $state, stateGo, $q) {
                    return clientsDB.getClientById( {
                        id: $stateParams.id
                    }).then(data => {
                        if(data.result === 'error' && data.message === 'customer_deleted') {
                            const dialogPromise = dialogs.listItemDeletedEarlier();
                            if ($state.current.name === 'clients.database') {
                                dialogPromise.then(() => {
                                    stateGo.refresh();
                                });
                            } else {
                                dialogPromise.finally(() => {
                                    stateGo.goToState('clients.database');
                                });
                            }
                            return $q.reject(data);
                        } else {
                            return data;
                        }
                    });
                }]
            },
            params: {
                stateToSave: {}
            }
        })

        .state( 'integrations', {
            url: '/integrations',
            abstract: true,
            template: parentViewTemplate,
            resolve: {
                lang: lang,
                accessRight: ['factoryProfile', '$q', '$state', function(factoryProfile, $q, $state) {
                    return factoryProfile.getProfile()
                        .then(res => {
                            if(res.isOwner) {
                                return $q.when();
                            }
                            else {
                                $state.go(defaultState);
                            }
                        });
                }]
            }
        } )
        .state( 'integrations.management', {
            url: '/management',
            template: require('../templates-pug/API/set-integrations.pug').default,
            controller: 'setIntegrations',
            controllerAs: 'setIntegCtrl',
            resolve: {
                $profile: profile,
                $accountInfo: ['factoryBilling', '$state', function (factoryBilling, $state) {
                    return factoryBilling.getMinAccountInfo({})
                        .then(function(res) {
                            if(res.result === "ok" && res.subscriptions.INTEGRATION
                                && (res.subscriptions.INTEGRATION.plan !== null || res.subscriptions.INTEGRATION.status === "INTRIAL_SUB_NOACT")) {
                                $state.go('integrations.apps', {});
                            } else {
                                return res;
                            }
                        });
                }],
                $permitsForUser: ['getPermits', function ( getPermits ) {
                    return getPermits.getPermitsList( {} );
                }]
            }
        })
        .state( 'integrations.tokens', {
            url: '/tokens',
            template: require('../templates-pug/API/tokens-list.pug').default,
            controller: 'tokensList',
            controllerAs: 'tokensCtrl',
            resolve: {
                $profile: profile,
                $tokensList: ['factoryTokens', function(factoryTokens) {
                    return factoryTokens.getApiAuthTokens({});
                }]
            },
            params: {
                checkFromList: {}
            }
        })
        .state( 'integrations.createToken', {
            url: '/createtoken',
            template: require('../templates-pug/API/create-token.pug').default,
            controller: 'createEditToken',
            controllerAs: 'ceTokenCTRL',
            resolve: {
                $profile: profile,
                $tokenInfo: function() {
                    return false;
                }
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })
        .state( 'integrations.editToken', {
            url: '/edittoken?{id:int}',
            template: require('../templates-pug/API/create-token.pug').default,
            controller: 'createEditToken',
            controllerAs: 'ceTokenCTRL',
            resolve: {
                $profile: profile,
                $tokenInfo: ['$stateParams', 'factoryTokens', function ( $stateParams, factoryTokens ) {
                    return factoryTokens.getApiAuthTokenById( {
                        authTokenId: $stateParams.id
                    } );
                }],
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })
        .state( 'integrations.tokenDetail', {
            url: '/tokendetail?{id:int}',
            template: require('../templates-pug/API/token-detail.pug').default,
            controller: 'tokenDetail',
            controllerAs: 'tokDetailCTRL',
            resolve: {
                $profile: profile,
                $tokenInfo: ['$stateParams', 'factoryTokens', function ( $stateParams, factoryTokens ) {
                    return factoryTokens.getApiAuthTokenById( {
                        authTokenId: $stateParams.id
                    } );
                }],
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })
        .state( 'integrations.apps', {
            url: '/apps',
            template: require('../templates-pug/API/apps-list.pug').default,
            controller: 'appsList',
            controllerAs: 'appsListCtrl',
            resolve: {
                $profile: profile,
                $environment: environment,
                $appsList: ['factoryApps', function(factoryApps) {
                    return factoryApps.getApiApps({});
                }]
            }
        })
        .state( 'integrations.appDetails', {
            url: '/appdetails?{id:int}',
            template: require('../templates-pug/API/app-details.pug').default,
            controller: 'appDetail',
            controllerAs: 'appDetailCtrl',
            resolve: {
                $profile: profile,
                $appInfo: ['$stateParams', 'factoryApps', function ( $stateParams, factoryApps ) {
                    return factoryApps.getApiAppById( {
                        id: $stateParams.id
                    } );
                }],
            }
        })

        .state( 'inventory', {
            url: '/inventory',
            abstract: true,
            template: parentViewTemplate,
            resolve: {
                lang: lang
            }
        } )
        .state( 'inventory.management', {
            url: '/invmanagement',
            template: require('../templates-pug/inventory/inventory-management.pug').default,
            controller: 'invManagement',
            controllerAs: 'invMenegCtrl',
            resolve: {
                $profile: profile,
                $accountInfo: ['factoryBilling', '$state', function (factoryBilling, $state) {
                    return factoryBilling.getMinAccountInfo({})
                        .then(function(res) {
                            if(res.result === "ok" && (res.subscriptions.INVENTORY.plan !== null || res.subscriptions.INVENTORY.status === "INTRIAL_SUB_NOACT")) {
                                $state.go('inventory.purchase', {page:0,limit:10});
                            } else {
                                return res;
                            }
                        });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.purchase', {
            url: '/purchase?{page:int}&{limit:.*}&{search:.*}&{status:.*}&{supplier:([\\-0-9]+)|null}&{outletId:.*|null}',
            template: require('../templates-pug/inventory/purchase/purchase-orders.pug').default,
            controller: 'purchaseListControl',
            controllerAs: 'purchListCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $shortSuppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliersData({});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $purchaseList:['$stateParams', 'factoryInventory',
                    function ( $stateParams, factoryInventory ) {
                        // console.log("$stateParams in STATE = ", $stateParams);
                        let limit = parseToDec($stateParams.limit);
                        return factoryInventory.getOrdersListData( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            outletId: 	$stateParams.outletId === 'all'? null : $stateParams.outletId,
                            supplier: 	$stateParams.supplier,
                            status: 	$stateParams.status
                        } );
                    }

                ],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.createorder', {
            url: '/createorder',
            template: require('../templates-pug/inventory/purchase/create-edit-order.pug').default,
            controller: 'createEditOrderController',
            controllerAs: 'ceOrderCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $getSuppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliers({
                        search: null
                    });
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsEdit: countOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $quantOptionsEdit: quantOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $ordersDetail: function() {
                    return false;
                },
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                purchaseDocData: {},
                outletId: null,
                newItems: []
            }
        } )
        .state( 'inventory.editorder', {
            url: '/editorder?{id:int}',
            template: require('../templates-pug/inventory/purchase/create-edit-order.pug').default,
            controller: 'createEditOrderController',
            controllerAs: 'ceOrderCtrl',
            resolve: {
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $getSuppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliers( {
                        search: null
                    });
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsEdit: countOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $quantOptionsEdit: quantOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $ordersDetail: ['$stateParams', 'factoryInventory', 'dialogs', function ($stateParams, factoryInventory, dialogs) {
                    return factoryInventory.getOrderDetail( {
                        id: $stateParams.id,
                        isEdit: true
                    })
                      .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id, "REFRESH"));
                }],
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                purchaseDocData: {},
                outletId: null,
                newItems: []
            }
        })
        .state( 'inventory.duplicateorder', {
            url: '/duplicateorder?{id:int}',
            template: require('../templates-pug/inventory/purchase/create-edit-order.pug').default,
            controller: 'createEditOrderController',
            controllerAs: 'ceOrderCtrl',
            resolve: {
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $getSuppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliers( {
                        search: null
                    });
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsEdit: countOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $quantOptionsEdit: quantOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $ordersDetail: ['$stateParams', 'factoryInventory', 'dialogs',
                    function ($stateParams, factoryInventory, dialogs) {
                        return factoryInventory.getOrderDetail( {
                            id: $stateParams.id,
                            isEdit: false
                        })
                          .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id));
                }],
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                purchaseDocData: {},
                outletId: null,
                newItems: []
            }
        })
        .state( 'inventory.orederdetail', {
            url: '/orderdetail?{id:int}',
            template: require('../templates-pug/inventory/purchase/order-detail.pug').default,
            controller: 'orderDetail',
            resolve:{
                $profile: profile,
                $critCountOptionsEdit: critCountOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $ordersDetail: ['$stateParams', 'factoryInventory', 'dialogs', function ($stateParams, factoryInventory, dialogs) {
                    return factoryInventory.getOrderDetail( { id: $stateParams.id })
                      .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id, "REFRESH"));
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.receiveorder', {
            url: '/receiveorder?{id:int}',
            template: require('../templates-pug/inventory/purchase/receive-order.pug').default,
            controller: 'receiveOrder',
            controllerAs: 'receiveOrderCtrl',
            resolve:{
                $profile: profile,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $currencyOptionsShow: currencyOptionsShow,
                $receiveOrder: ['$stateParams', 'factoryInventory', 'dialogs', function ($stateParams, factoryInventory, dialogs) {
                    return factoryInventory.getOrderInfoForReceive( {
                        id: $stateParams.id
                    }).then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id, "REFRESH"));
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            }
        })

        .state( 'inventory.purchaseImport', {
            url: '/purchaseImport?{id:([\-0-9]+)|null}&{outletId:([\-0-9]+)|null}&{state:.*}',
            template: require('../templates-pug/import-entity/import-entity.pug').default,
            controller: 'purchaseItemsImportControl',
            controllerAs: 'model',
            resolve: {
                $profile: profile,
                accessRight: accessRight('inventory')
            },
            params: {
                purchaseDocData: {}
            }
        } )

        .state( 'inventory.supplierlist', {
            url: '/supplierlist?{page:int}&{limit:.*}&{search:.*}',
            template: require('../templates-pug/inventory/suppliers-list.pug').default,
            controller: 'supplierListContr',
            controllerAs: 'supplierCtrl',
            resolve: {
                $profile: profile,
                $supplierslistData: ['$stateParams','factorySuppliers',
                    function ( $stateParams, factorySuppliers ) {
                        let limit = parseToDec($stateParams.limit);
                        return factorySuppliers.getSuppliers( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search
                        } );
                    }
                ],
                accessRight: accessRight('inventory')
            },
            params: {
                checkFromList: {}
            }
        })
        .state( 'inventory.suppliercreate', {
            url: '/suppliercreate',
            template: require('../templates-pug/inventory/supplier-create-edit.pug').default,
            controller: 'supplierCreateEdit',
            resolve: {
                $profile: profile,
                $suppliersEdit: function(){ /**it is still necessary just for launching a mutual controller for both states**/ },
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })
        .state( 'inventory.supplieredit', {
            url: '/supplieredit?{id:int}',
            template: require('../templates-pug/inventory/supplier-create-edit.pug').default,
            controller: 'supplierCreateEdit',
            resolve: {
                $profile: profile,
                $suppliersEdit: ['$stateParams', 'factorySuppliers', function ( $stateParams, factorySuppliers ) {
                    return factorySuppliers.getSupplierById( {
                        id: $stateParams.id
                    } );
                }],
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })
        .state( 'inventory.transfer', {
            url: '/transfer?{page:int}&{limit:.*}&{search:.*}&{status:.*}&{outletFrom:([\-0-9]+)|null}&{outletTo:([\-0-9]+)|null}',
            template: require('../templates-pug/inventory/transfer/transfer-orders.pug').default,
            controller: 'transferOrders',
            controllerAs: 'transListCtrl',
            resolve: {
                $shortOutlets: ['factoryTransfer', function(factoryTransfer) {
                    return factoryTransfer.getShortOutlets( {} );
                }],
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $transfersList:['$stateParams', 'factoryTransfer',
                    function ( $stateParams, factoryTransfer ) {
                        // console.log("$stateParams in STATE = ", $stateParams);
                        let limit = parseToDec($stateParams.limit);
                        return factoryTransfer.getTransferOrderList( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            outletFrom: $stateParams.outletFrom,
                            outletTo: 	$stateParams.outletTo,
                            status: 	$stateParams.status
                        } );
                    }

                ],
                $profile: profile,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.transferdetail', {
            url: '/transferdetail?{id:int}',
            template: require('../templates-pug/inventory/transfer/transfer-detail.pug').default,
            controller: 'transferDetail',
            resolve: {
                $profile: profile,
                $critCountOptionsEdit: critCountOptionsEdit,
                $transferDetail: ['$stateParams', 'factoryTransfer', 'dialogs',
                    function ($stateParams, factoryTransfer, dialogs) {
                        return factoryTransfer.getTransferOrderDetail( {id: $stateParams.id, isEdit: false})
                            .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id));
                        }],
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.createtransfer', {
            url: '/createtransfer',
            template: require('../templates-pug/inventory/transfer/transfer-create-edit.pug').default,
            controller: 'createEditTransferController',
            controllerAs: 'ceTransferCtrl',
            resolve: {
                $sourceOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $destinationOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({showAllOutlets: true})
                }],
                $countOptionsEdit: countOptionsEdit,
                $transferDetail: function() {
                    return { isCreate: true };
                },
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $profile: profile,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                transferDocData: {},
                newItems: [],
                sourceOutletId: null,
                destinationOutletId: null
            }
        })
        .state( 'inventory.edittransfer', {
            url: '/edittransfer?{id:int}',
            template: require('../templates-pug/inventory/transfer/transfer-create-edit.pug').default,
            controller: 'createEditTransferController',
            controllerAs: 'ceTransferCtrl',
            resolve: {
                $sourceOutlets: function () {
                    return {};
                },
                $destinationOutlets: function () {
                    return {};
                },
                $countOptionsEdit: countOptionsEdit,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $transferDetail: ['$stateParams', 'factoryTransfer', 'dialogs',
                    function ($stateParams, factoryTransfer, dialogs) {
                        return factoryTransfer.getTransferOrderDetail( {id: $stateParams.id, isEdit: true})
                            .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id));
                    }],
                $profile: profile,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                transferDocData: {},
                newItems: [],
                sourceOutletId: null,
                destinationOutletId: null
            }
        })

        .state( 'inventory.duplicatetransfer', {
            url: '/duplicatetransfer?{id:int}',
            template: require('../templates-pug/inventory/transfer/transfer-create-edit.pug').default,
            controller: 'createEditTransferController',
            controllerAs: 'ceTransferCtrl',
            resolve: {
                $sourceOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $destinationOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({showAllOutlets: true})
                }],
                $countOptionsEdit: countOptionsEdit,
                $transferDetail: ['$stateParams', 'factoryTransfer', 'dialogs',
                    function ($stateParams, factoryTransfer, dialogs) {
                        return factoryTransfer.getTransferOrderDetail( {id: $stateParams.id, isEdit: false})
                            .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.id));
                    }],
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $profile: profile,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {},
                transferDocData: {},
                newItems: [],
                sourceOutletId: null,
                destinationOutletId: null
            }
        })

        .state( 'inventory.transferImport', {
            url: '/transferImport?{id:([\-0-9]+)|null}&{sourceOutletId:([\-0-9]+)|null}&{destinationOutletId:([\-0-9]+)|null}&{state:.*}',
            template: require('../templates-pug/import-entity/import-entity.pug').default,
            controller: 'trasferItemsImportControl',
            controllerAs: 'model',
            resolve: {
                $profile: profile,
                accessRight: accessRight('inventory')
            },
            params: {
                transferDocData: {}
            }
        } )

        .state( 'inventory.adjustment', {
            url: '/adjustment?{page:int}&{limit:.*}&{search:.*}&{reason:.*}&{outletId:([\-0-9]+)|null}',
            template: require('../templates-pug/inventory/stock-adjustment.pug').default,
            controller: 'stockAdjustment',
            controllerAs: 'stockAdjustmentCtrl',
            resolve: {
                $shortOutlets: ['factoryAdjustment', function (factoryAdjustment ) {
                    return factoryAdjustment.getShortOutlets( {} );
                }],
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $adjustmentsList:['$stateParams', 'factoryAdjustment',
                    function ( $stateParams, factoryAdjustment ) {
                        // console.log("$stateParams in STATE = ", $stateParams);
                        let limit = parseToDec($stateParams.limit);
                        return factoryAdjustment.getAdjustmentOrdersList( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            outletId: 	$stateParams.outletId,
                            reason: 	$stateParams.reason
                        } );
                    }

                ],
                $profile: profile,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.adjustdetails', {
            url: '/adjustdetails?{id:int}',
            template: require('../templates-pug/inventory/adjustment-details.pug').default,
            controller: 'adjustDetails',
            resolve: {
                $critCountOptionsEdit: critCountOptionsEdit,
                $currencyOptionsShow: currencyOptionsShow,
                $adjustmentDetail: ['$stateParams', 'factoryAdjustment', function ( $stateParams, factoryAdjustment ) {
                    return factoryAdjustment.getAdjustmentOrderDetails( {
                        id: $stateParams.id
                    } );
                }],
                $profile: profile,
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.createadjustment', {
            url: '/createadjustment',
            template: require('../templates-pug/inventory/create-adjustment.pug').default,
            controller: 'createAdjustment',
            controllerAs: 'createAdjust',
            resolve: {
                accessRight: accessRight('inventory'),
                $shortOutlets: ['factoryInventory', function (factoryInventory ) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $quantOptionsEdit: quantOptionsEdit,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsEdit: countOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $countQuantityForOrder: countQuantityForOrder,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $profile: profile,
                $permitsManager: permitsManager
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.history', {
            url: '/history?{page:int}&{limit:.*}&{search:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{outletsIds:.*}&{merchantsIds:.*}&{reasons:.*}',
            template: require('../templates-pug/inventory/inventory-history.pug').default,
            controller: 'inventoryHistory',
            controllerAs: 'invHistCtrl',
            resolve: {
                $countOptionsEdit: countOptionsEdit,
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $inventHistory: ['factoryInventoryHistory', '$stateParams', 'filtersData', '$rootScope', function (factoryInventoryHistory, $stateParams, filtersData, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        // console.log("есть IF");
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                    }

                    // console.log("после IF");
                    let limit = parseToDec($stateParams.limit);
                    return factoryInventoryHistory.getInventoryHistoryItems({
                        limit		    : limit,
                        startDate	    : $stateParams.from,
                        endDate		    : $stateParams.to,
                        customPeriod    : $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        offset 		    : $stateParams.page * limit,
                        tzName          : tz.name(),
                        startWeek       : $stateParams.startWeek,
                        search		    : $stateParams.search,
                        merchantsIds    : ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds	    : ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null,
                        reasons		    : ($stateParams.reasons && $stateParams.reasons != "clear" && $stateParams.reasons != "all") ? $stateParams.reasons.split(',') : ($stateParams.reasons == "clear") ? [] : ($stateParams.reasons == "all") ? "all" : "all"
                    });
                }],
                $profile: profile,
                accessRight: accessRight('inventory')
            }
        })
        .state('inventory.printlabels', {
            url: '/createprint?{orderId:int}&{orderType:.*}',
            template: require('../templates-pug/inventory/create-labels.pug').default,
            controller: 'createLabels',
            controllerAs: 'cLabels',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory ) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $countQuantityForLabels: countQuantityForLabels,
                $categories: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getCategoriesForAddingItems({
                        onlyStock: false
                    });
                }],
                $suppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliersForAddingItems({});
                }],
                $printParams: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getReportColumns({
                        reportType: "LABELS"
                    });
                }],
                $preloadedData: [
                    'factoryInventory',
                    'factoryTransfer',
                    'factoryAdjustment',
                    'factoryProduction',
                    '$stateParams',
                    'dialogs',
                    (factoryInventory, factoryTransfer, factoryAdjustment, factoryProduction, $stateParams, dialogs) => {
                        if ($stateParams.orderId) {
                            switch ($stateParams.orderType) {
                                case "PURCHASE_ORDER":
                                    return factoryInventory.getOrderDetail({ id: $stateParams.orderId, isEdit: false })
                                      .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.orderId));
                                case "TRANSFER_ORDER":
                                    return factoryTransfer.getTransferOrderDetail({id: $stateParams.orderId, isEdit: false})
                                      .then(data => dialogs.goStateOrShowRefreshDialog(data, $stateParams.orderId));
                                case "STOCK_ADJUSTMENT":
                                    return factoryAdjustment.getAdjustmentOrderDetails({id: $stateParams.orderId});
                                case "PRODUCTION":
                                case "DISASSEMBLY":
                                    return factoryProduction.getProductionOrderDetail({id: $stateParams.orderId});
                                default:
                                    return false;
                            }
                        } else {
                            return false;
                        }
                    }],
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })

        .state('goods.printlabels', {
            url: '/createprint',
            template: require('../templates-pug/inventory/create-labels.pug').default,
            controller: 'createLabels',
            controllerAs: 'cLabels',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory ) {
                    return factoryInventory.getShortOutlets( {} );
                }],
                $countQuantityForLabels: countQuantityForLabels,
                $categories: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getCategoriesForAddingItems({
                        onlyStock: false
                    });
                }],
                $suppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliersForAddingItems({});
                }],
                $printParams: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getReportColumns({
                        reportType: "LABELS"
                    });
                }],
                $preloadedData: function() {
                    return false;
                },
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('goods.itemHistory', {
            url: '/itemhistory?{page:int}&{limit:.*}&{search:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{wareId:.*}&{wareName:.*}&{outletsIds:.*}&{merchantsIds:.*}&{reasons:.*}&{variantId:.*}',
            template: require('../templates-pug/inventory/inventory-history.pug').default,
            controller: 'inventoryHistory',
            controllerAs: 'invHistCtrl',
            resolve: {
                $countOptionsEdit: countOptionsEdit,
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $inventHistory: ['factoryInventoryHistory', '$stateParams', 'filtersData', '$rootScope', function (factoryInventoryHistory, $stateParams, filtersData, $rootScope ) {

                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        // console.log("есть IF");
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                    }

                    let limit = parseToDec($stateParams.limit);
                    return factoryInventoryHistory.getInventoryHistoryItems({

                        startDate	    : $stateParams.from,
                        endDate		    : $stateParams.to,
                        customPeriod    : $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        offset 		    : $stateParams.page * limit,
                        limit		    : limit,
                        search		    : $stateParams.search,
                        wareId		    : $stateParams.wareId,
                        wareName 	    : $stateParams.wareName,
                        tzName          : tz.name(),
                        startWeek       : $stateParams.startWeek,
                        merchantsIds    : ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds	    : ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null,
                        reasons		    : ($stateParams.reasons && $stateParams.reasons != "clear" && $stateParams.reasons != "all") ? $stateParams.reasons.split(',') : ($stateParams.reasons == "clear") ? [] : ($stateParams.reasons == "all") ? "all" : null,
                        variantId       : ($stateParams.variantId && $stateParams.variantId != "clear" && $stateParams.variantId != "all") ? $stateParams.variantId.split(',') : ($stateParams.variantId == "clear") ? [] : ($stateParams.variantId == "all") ? "all" : null
                    });
                }],
                $profile: profile,
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        })

        .state( 'inventory.valuation', {
            url: '/valuation?{page:int}&{limit:.*}&{outletsIds:.*}&{categoryIds:.*}&{date:.*}',
            template: require('../templates-pug/inventory/inventory-valuation.pug').default,
            controller: 'inventoryValuation',
            controllerAs: 'valuationCtrl',
            resolve: {
                $profile: profile,
                $countOptionsShow: countOptionsShow,
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowInfinityToHundred: ['currencyFormat', function ( currencyFormat ) {
                    return currencyFormat.getPercentShow(false, true, false, false);
                }],
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $categories: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getWareCategories({});
                }],
                $valuation: ['$stateParams', 'factoryInventory', 'filtersData',
                    function ($stateParams, factoryInventory, filtersData) {
                        var stateParams = filtersData.getStateParams();
                        var date = stateParams.date ? stateParams.date :
                            null;

                        if(stateParams) {
                            // console.log("есть IF");
                            $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                            $stateParams.categoryIds = stateParams.categoryIds || 'all';
                            $stateParams.date = stateParams.date || null;
                        }
                        let limit = parseToDec($stateParams.limit);
                        return factoryInventory.getInventoryCost({
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds !== "clear" && $stateParams.outletsIds !== "all")
                                ? $stateParams.outletsIds.split(',')
                                : ($stateParams.outletsIds === "clear")
                                    ? []
                                    : ($stateParams.outletsIds === "all") ? "all" : null,
                            categoryIds: ($stateParams.categoryIds && $stateParams.categoryIds !== "clear" && $stateParams.categoryIds !== "all")
                                ? $stateParams.categoryIds.split(',')
                                : ($stateParams.categoryIds === "clear")
                                    ? []
                                    : ($stateParams.categoryIds === "all") ? "all" : null,
                            date: date
                        });
                    }
                ],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.createcount', {
            url: '/createcount',
            template: require('../templates-pug/inventory/inventory-count-create-edit.pug').default,
            controller: 'createEditInvCountController',
            controllerAs: 'ceInvCountCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $countOptionsShow: countOptionsShow,
                $categories: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getCategoriesForAddingItems({
                        onlyStock: true
                    });
                }],
                $suppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliersForAddingItems({});
                }],
                $orderDetails: function () {
                    return false;
                },
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.editcount', {
            url: '/editcount?{id:int}',
            template: require('../templates-pug/inventory/inventory-count-create-edit.pug').default,
            controller: 'createEditInvCountController',
            controllerAs: 'ceInvCountCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $countOptionsShow: countOptionsShow,
                $categories: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getCategoriesForAddingItems({
                        onlyStock: true
                    });
                }],
                $suppliers: ['factoryInventory', function(factoryInventory) {
                    return factoryInventory.getSuppliersForAddingItems({});
                }],
                $orderDetails: ['$stateParams', 'factoryInventoryCount', function ($stateParams, factoryInventoryCount) {
                    return factoryInventoryCount.getInventoryCountOrderById({
                        id: $stateParams.id
                    });
                }],
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state( 'inventory.countstock', {
            url: '/countstock?{id:int}',
            template: require('../templates-pug/inventory/count-stock.pug').default,
            controller: 'countStock',
            controllerAs: 'countStock',
            resolve: {
                $profile: profile,
                $countOptionsEdit: countOptionsEdit,
                $countOptionsShow: countOptionsShow,
                $countOptionsShowWithPlus: countOptionsShowWithPlus,
                $critCountOptionsEdit: critCountOptionsEdit,
                $countOptionsEditDivisible: countOptionsEditDivisible,
                $countCard: ['$stateParams', 'factoryInventoryCount',
                    function ( $stateParams, factoryInventoryCount ) {
                        return factoryInventoryCount.getInventoryCountStockById( {
                            id: $stateParams.id
                        } );
                    }
                ],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.counts', {
            url: '/counts?{page:int}&{limit:.*}&{search:.*}&{status:.*}&{outletId:([-0-9]+)|null}',
            template: require('../templates-pug/inventory/inventory-count-list.pug').default,
            controller: 'countListController',
            controllerAs: 'countListCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $statusConst: ['orderStatus', function (orderStatus) {
                    return orderStatus;
                }],
                $invCountList:['$stateParams', 'factoryInventoryCount',
                    function ( $stateParams, factoryInventoryCount ) {
                        let limit = parseToDec($stateParams.limit);
                        return factoryInventoryCount.getInventoryCountOrders( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            outletId: 	$stateParams.outletId,
                            status: 	$stateParams.status
                        } );
                    }
                ],
                accessRight: accessRight('inventory')
            }
        })
        .state( 'inventory.countdetail', {
            url: '/countdetail?{id:int}',
            template: require('../templates-pug/inventory/inventory-count-detail.pug').default,
            controller: 'countDetailsController',
            controllerAs: 'countDetailsCtrl',
            resolve:{
                $profile: profile,
                $countOptionsShow: countOptionsShow,
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShowWithPlus: countOptionsShowWithPlus,
                $orderDetails: ['$stateParams', 'factoryInventoryCount', function ($stateParams, factoryInventoryCount) {
                    return factoryInventoryCount.getInventoryCountOrderById({
                        id: $stateParams.id
                    });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.createproduction', {
            url: '/create-production',
            template: require('../templates-pug/inventory/production/create-production.pug').default,
            controller: 'createProductionController',
            controllerAs: 'cProductionCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $countQuantityForOrder: countQuantityForOrder,
                $orderData: function () {
                    return false;
                },
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.createdisassembly', {
            url: '/create-disassembly',
            template: require('../templates-pug/inventory/production/create-production.pug').default,
            controller: 'createProductionController',
            controllerAs: 'cProductionCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $countQuantityForOrder: countQuantityForOrder,
                $orderData: function () {
                    return false;
                },
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.productions', {
            url: '/productionslist?{page:int}&{limit:.*}&{search:.*}&{type:.*}&{outletId:([-0-9]+)|null}',
            template: require('../templates-pug/inventory/production/productions-list.pug').default,
            controller: 'productionListController',
            controllerAs: 'prodListCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $countOptionsShow: countOptionsShow,
                $prodactList:['$stateParams', 'factoryProduction',
                    function ( $stateParams, factoryProduction ) {
                        let limit = parseToDec($stateParams.limit);
                        return factoryProduction.getProductionsList( {
                            limit: 		limit,
                            offset: 	$stateParams.page * limit,
                            search:		$stateParams.search,
                            outletId: 	$stateParams.outletId,
                            type: 		$stateParams.type
                        } );
                    }
                ],
                accessRight: accessRight('inventory')
            }
        })
        .state('inventory.productiondetail', {
            url: '/productiondetail?{id:int}',
            template: require('../templates-pug/inventory/production/productions-detail.pug').default,
            controller: 'productionDetailController',
            controllerAs: 'prodDetailCtrl',
            resolve: {
                $profile: profile,
                $countOptionsShow: countOptionsShow,
                $currencyOptionsShow: currencyOptionsShow,
                $orderDetails: ['$stateParams', 'factoryProduction', function ($stateParams, factoryProduction) {
                    return factoryProduction.getProductionOrderDetail({
                        id: $stateParams.id
                    });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.disassemblydetail', {
            url: '/desassendancydetail?{id:int}',
            template: require('../templates-pug/inventory/production/productions-detail.pug').default,
            controller: 'productionDetailController',
            controllerAs: 'prodDetailCtrl',
            resolve: {
                $profile: profile,
                $countOptionsShow: countOptionsShow,
                $currencyOptionsShow: currencyOptionsShow,
                $orderDetails: ['$stateParams', 'factoryProduction', function ($stateParams, factoryProduction) {
                    return factoryProduction.getProductionOrderDetail({
                        id: $stateParams.id
                    });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.productionduplicate', {
            url: '/productionduplicate?{id:int}',
            template: require('../templates-pug/inventory/production/create-production.pug').default,
            controller: 'createProductionController',
            controllerAs: 'cProductionCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $countQuantityForOrder: countQuantityForOrder,
                $orderData: ['$stateParams', 'factoryProduction', function ($stateParams, factoryProduction) {
                    return factoryProduction.getProductionOrderDetail({
                        id: $stateParams.id
                    });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })
        .state('inventory.disassemblyduplicate', {
            url: '/disassemblyduplicate?{id:int}',
            template: require('../templates-pug/inventory/production/create-production.pug').default,
            controller: 'createProductionController',
            controllerAs: 'cProductionCtrl',
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryInventory', function (factoryInventory) {
                    return factoryInventory.getShortOutlets({});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $countQuantityForOrderDivisible: countQuantityForOrderDivisible,
                $countQuantityForOrder: countQuantityForOrder,
                $orderData: ['$stateParams', 'factoryProduction', function ($stateParams, factoryProduction) {
                    return factoryProduction.getProductionOrderDetail({
                        id: $stateParams.id
                    });
                }],
                $permitsManager: permitsManager,
                accessRight: accessRight('inventory')
            },
            params: {
                stateToSave: {}
            }
        })

        .state( 'employees', {
            url: '/employees',
            abstract: true,
            template: parentViewTemplate,
            resolve: {
                lang: lang,
                accessRight: accessRight('employees')
            }
        } )
        .state( 'employees.list', {
            url: '/list?{page:int}&{limit:.*}&{search:.*}&{outletsIds:.*}',
            template: require('../templates-pug/employees/employee-list.pug').default,
            controller: 'employeeListCtrl',
            resolve: {
                $profile: profile,
                $permits: ['getPermits', function ( getPermits ) {
                    return getPermits.getPermitsList( {} );
                }],
                $employees: ['$stateParams', 'getEmployees', function ( $stateParams, getEmployees ) {
                    let limit = parseToDec($stateParams.limit);
                    return getEmployees.getEmployeesListData( {
                        limit: 		limit,
                        offset: 	$stateParams.page * limit,
                        search:     $stateParams.search,
                        outletsIds: $stateParams.outletsIds
                    } );
                }],
                $accountInfo: ['factoryBilling', function(factoryBilling) {
                    return factoryBilling.getMinAccountInfo( );
                }],
                $shortOutlets: ['getEmployees', function ( getEmployees ) {
                    return getEmployees.getShortOutlets( {} );
                }],
                isBannerDisplayed: ['getEmployees', function (getEmployees) {
                    return getEmployees.isEmployeesPromoBannerDisplayed();
                }]
            },
            params: {
                checkFromList: {},
                reloadToUnblock: false
            }
        } )
        .state( 'employees.timecards', {
            url: '/timecards?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/report-timecards.pug').default,
            controller: 'reportTimeCardsController',
            resolve: {
                $profile: profile,
                $timeCards: ['timeTrackingFactory', 'filtersData', '$stateParams', '$rootScope', function ( timeTrackingFactory, filtersData, $stateParams, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        // console.log("есть IF");
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                    }
                    // console.log("после IF");
                    let limit = parseToDec($stateParams.limit);
                    return timeTrackingFactory.getTimeCards({
                        startDate: $stateParams.from,
                        endDate: $stateParams.to,
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        offset: $stateParams.page * limit,
                        limit: limit,
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                    });
                }],
                $hoursOptionsShow: hoursOptionsShow,
                isBannerDisplayed: ['getEmployees', function (getEmployees) {
                    return getEmployees.isEmployeesPromoBannerDisplayed();
                }]
            },
            params: {
                checkFromList: {}
            }
        } )
        .state( 'employees.timeclockreport', {
            url: '/timeclock?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/report-timeclock.pug').default,
            controller: 'reportTimeClockRecordController',
            resolve: {
                $profile: profile,
                $timeCardsReport: ['timeTrackingFactory', 'filtersData', '$stateParams', '$rootScope', function ( timeTrackingFactory, filtersData, $stateParams, $rootScope ) {
                    var stateParams = filtersData.getStateParams();
                    if(stateParams) {
                        $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                        $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                        $stateParams.from = stateParams.from || $stateParams.from || from;
                        $stateParams.to = stateParams.to || $stateParams.to || to;
                        $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                        $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                        $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                        $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                        $stateParams.predefinedPeriod = {};
                        $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                        $stateParams.predefinedPeriod.period = $stateParams.arg;
                        $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                    }
                    let limit = parseToDec($stateParams.limit);
                    return timeTrackingFactory.getTimeCardsReport({
                        startDate: $stateParams.from,
                        endDate: $stateParams.to,
                        customPeriod: $stateParams.customPeriod,
                        predefinedPeriod: $stateParams.predefinedPeriod,
                        offset: $stateParams.page * limit,
                        limit: limit,
                        tzName: tz.name(),
                        startWeek: $stateParams.startWeek,
                        merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                        outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                    });
                }],
                $hoursOptionsShow: hoursOptionsShow,
                isBannerDisplayed: ['getEmployees', function (getEmployees) {
                    return getEmployees.isEmployeesPromoBannerDisplayed();
                }]
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all'
            }
        } )
        .state( 'employees.createemployee', {
            url: '/createemployee',
            template: require('../templates-pug/employees/create-edit-employee.pug').default,
            controller: 'createEditEmployeeCtrl',
            // controllerAs: 'ctrl',
            resolve: {
                $profile: profile,
                $employee: ['$stateParams', 'getPermits', 'getEmployees', function ( $stateParams, getPermits, getEmployees ) {
                    return getPermits.getEmployeeRolesList( {} );
                }],
                $shortOutlets: ['getEmployees', function ( getEmployees ) {
                    return getEmployees.getShortOutlets( {} );
                }],
                $employees: ['$stateParams', 'getEmployees', function ( $stateParams, getEmployees ) {
                    let limit = parseToDec($stateParams.limit);
                    return getEmployees.getEmployeesListData( {
                        limit: 		limit,
                        offset: 	$stateParams.page * limit,
                        outletsIds: $stateParams.outletsIds
                    } );
                }]
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'employees.editemployee', {
            url: "/editemployee/?{id:int}",
            template: require('../templates-pug/employees/create-edit-employee.pug').default,
            controller: 'createEditEmployeeCtrl',
            // controllerAs: 'ctrl',
            resolve: {
                $profile: profile,
                $employee: ['$stateParams', 'getEmployees', '$state', 'dialogs', 'stateGo', '$q', function ( $stateParams, getEmployees, $state, dialogs, stateGo, $q ) {
                    return getEmployees.getEmployeeById( {
                        merchantId: $stateParams.id
                    } ).then(data => {
                        if(data.result === 'error' && data.message === 'employee_deleted') {
                            const dialogPromise = dialogs.listItemDeletedEarlier();
                            if ($state.current.name === 'employees.list') {
                                dialogPromise.then(() => {
                                    stateGo.refresh();
                                });
                            } else {
                                dialogPromise.finally(() => {
                                    stateGo.goToState('employees.list');
                                });
                            }
                            return $q.reject(data);
                        } else {
                            return data;
                        }
                    });;
                }],
                $shortOutlets: ['getEmployees', function ( getEmployees ) {
                    return getEmployees.getShortOutlets( {} );
                }],
                $employees: ['$stateParams', 'getEmployees', function ( $stateParams, getEmployees) {
                    let limit = parseToDec($stateParams.limit);
                    return getEmployees.getEmployeesListData({
                        limit: 		limit,
                        offset: 	$stateParams.page * limit,
                        outletsIds: $stateParams.outletsIds
                    })
                }]
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'employees.createtimecard', {
            url: '/createtimecard',
            template: require('../templates-pug/employees/create-edit-timecard.pug').default,
            controller: 'createEditTimeCardCtrl',
            controllerAs: 'createEditTimeCardCtrl',
            resolve: {
                $profile:profile,
                $previousStateParams: ['filtersData', function(filtersData){
                    var stateParams = filtersData.getStateParams();
                    return stateParams;
                }],
                $getActualStaff: ['getEmployees', function( getEmployees ){
                    return getEmployees.getActualStaffReport( {} );
                }],
                $hoursOptionsShow: hoursOptionsShow,
                $getTimeCardById: function(){ /**it is still necessary just for launching a mutual controller for both states**/ }
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'employees.edittimecard', {
            url: "/edittimecard?{id:int}",
            template: require('../templates-pug/employees/create-edit-timecard.pug').default,
            // controller: 'editTimeCardCtrl',
            controller: 'createEditTimeCardCtrl',
            controllerAs: 'createEditTimeCardCtrl',
            resolve: {
                $profile:profile,
                $previousStateParams: ['filtersData', function(filtersData) {
                    var stateParams = filtersData.getStateParams();
                    return stateParams;
                }],
                // TODO: Oleksii: minify
                $getTimeCardById: ['$stateParams', 'timeTrackingFactory', function($stateParams, timeTrackingFactory){
                    return timeTrackingFactory.getTimeCardById({
                        id: $stateParams.id
                    });
                }],
                $getActualStaff: ['getEmployees', function( getEmployees ){
                    return getEmployees.getActualStaffReport( {} );
                }],
                $hoursOptionsShow: hoursOptionsShow
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'employees.permitslist', {
            url: '/permitslist?{page:int}&{limit:.*}',
            template: require('../templates-pug/employees/employee-permits-list.pug').default,
            controller: 'employeePermitsListCtrl',
            resolve: {
                $profile: profile,
                $permitsList: ['$stateParams', 'getPermits',
                    function ( $stateParams, getPermits ) {
                        let limit = parseToDec($stateParams.limit);
                        return getPermits.getEmployeeRolesList( {
                            limit: 	limit,
                            offset: $stateParams.page * limit
                        } );
                    }],
                isBannerDisplayed: ['getEmployees', function (getEmployees) {
                    return getEmployees.isEmployeesPromoBannerDisplayed();
                }]
            }
        } )
        .state( 'employees.createpermits', {
            url: '/createpermits',
            template: require('../templates-pug/employees/employee-permits-create-edit.pug').default,
            controller: 'employeePermitsCtrl',
            controllerAs: 'permCtrl',
            resolve: {
                $permits: [
                    function () {
                    }],
                $permitsForUser: ['getPermits', function ( getPermits ) {
                    return getPermits.getPermitsList( {} );
                }],
                $profile: profile
            },
            params: {
                stateToSave: {}
            }
        } )
        .state( 'employees.editpermits', {
            url: '/editpermits/?{id:int}',
            template: require('../templates-pug/employees/employee-permits-create-edit.pug').default,
            controller: 'employeePermitsCtrl',
            controllerAs: 'permCtrl',
            resolve: {
                $permits: ['$stateParams', 'getPermits',
                    function ( $stateParams, getPermits ) {
                        return getPermits.getPermitsByRoleId( {
                            id: $stateParams.id
                        } );
                    }],
                $profile: profile
            },
            params: {
                stateToSave: {}
            }
        } )


        // СТЕЙТЫ ДЛЯ СКИДОК
        .state( 'goods.discountlist', {
            url: '/discountlist?{page:int}&{limit:.*}&{outletsIds:.*}',
            controller: 'discountListCtrl',
            // controllerAs: 'discountList',
            template: require('../templates-pug/goods/discount-list.pug').default,
            resolve: {
                $profile: profile,
                $shortOutlets: ['factoryDiscount', function ( factoryDiscount ) {
                    return factoryDiscount.getShortOutlets( {} );
                }],
                $getDiscounts: ['$stateParams', 'factoryDiscount', function ( $stateParams, factoryDiscount ) {
                    // console.log("$stateParams =", $stateParams);
                    let limit = parseToDec($stateParams.limit);
                    return factoryDiscount.getDiscountList( {
                        limit: limit,
                        offset: $stateParams.page * limit,
                        outletsIds: $stateParams.outletsIds
                    } );
                }

                ],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowZeroToHundred: ['currencyFormat', function ( currencyFormat ) {
                    return currencyFormat.getPercentShow(false, false, true, false);
                }],
                accessRight: accessRight('goods')
            },
            params: {
                checkFromList: {}
            }
        } )
        .state( 'goods.discountcreate', {
            url: '/discountcreate',
            controller: 'discountCreateEditControl',
            // controllerAs: 'discount',
            template: require('../templates-pug/goods/discount-create-edit.pug').default,
            resolve: {
                $profile: profile,
                $discount: ['factoryDiscount', function ( factoryDiscount ) {
                    return factoryDiscount.getNewDiscount();
                }],
                $shortOutlets: ['factoryDiscount', function ( factoryDiscount ) {
                    return factoryDiscount.getShortOutlets( {} );
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $percentShowZeroToHundred: ['currencyFormat', function ( currencyFormat ) {
                    return currencyFormat.getPercentShow(false, false, true, false);
                }],
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            }
        } )
        .state( 'goods.discountedit', {
            url: '/discountedit/?{id:int}&{uuid:.*}',
            controller: 'discountCreateEditControl',
            // controllerAs: 'discount',
            resolve: {
                $profile: profile,
                $discount: ['factoryDiscount', '$stateParams', 'dialogs', '$state', 'stateGo', '$q', function ( factoryDiscount, $stateParams, dialogs, $state, stateGo, $q ) {
                    return factoryDiscount.getDiscountById( {'discountId': $stateParams.id, 'uuid': $stateParams.uuid} )
                        .then(data => {
                            if (data.result === 'error' && data.message === 'discount_deleted') {
                                const dialogPromise = dialogs.listItemDeletedEarlier();
                                if ($state.current.name === 'goods.discountlist') {
                                    dialogPromise.then(() => {
                                        stateGo.refresh();
                                    });
                                } else {
                                    dialogPromise.finally(() => {
                                        stateGo.goToState('goods.discountlist');
                                    });
                                }
                                return $q.reject(data);
                            }
                            return $q.resolve(data);
                        });
                }],
                $shortOutlets: ['factoryDiscount', function ( factoryDiscount ) {
                    return factoryDiscount.getShortOutlets( {} );
                }],
                $currencyOptionsEdit: currencyOptionsEdit,
                $percentShowZeroToHundred: ['currencyFormat', function ( currencyFormat ) {
                    return currencyFormat.getPercentShow(false, false, true, false);
                }],
                accessRight: accessRight('goods')
            },
            params: {
                stateToSave: {},
                checkToSave: {}
            },
            templateProvider: ['$templateFactory', '$discount', '$state', function ( $templateFactory, $discount, $state ) {
                switch ( $discount.result ) {
                    case 'ok' :
                        return require('../templates-pug/goods/discount-create-edit.pug').default;
                    case 'error' :
                        $state.go( 'goods.discountcreate', {}, {location: 'replace'} );
                        return null;
                    default:
                        $state.go( 'goods.discountcreate', {}, {location: 'replace'} );
                        return null;
                }
            }]
        } )
        // СТЕЙТЫ ДЛЯ СКИДОК


        .state( 'goods.modifiercreate', {
            url: '/modifiercreate',
            controller: 'modifierCardCreateCtrl',
            controllerAs: 'modifierCard',
            template: require('../templates-pug/modifiers/create-modifier.pug').default,
            resolve: {
                $profile: profile,
                $currencyOptionsEditModif: currencyOptionsEditModif,
                $modificator: [function () {
                    return {
                        name: '',
                        options: [
                            {
                                priority: 1,
                                name: '',
                                price: '0.00'
                            }
                        ],
                        outletsIds: []
                    };
                }],
                $shortOutlets: ['modificatorsOperation', function ( modificatorsOperation ) {
                    return modificatorsOperation.getShortOutlets( {} );
                }],
                accessRight: accessRight('goods')
            }
        } )
        .state( 'goods.modifiers-list', {
            url: '/modifiers/list?{page:int}&{outletsIds:.*}',
            template: require('../templates-pug/modifiers/modifiers-list.pug').default,
            controller: 'modifiersListController',
            controllerAs: 'vmModListCtrl',
            resolve: {
                $profile: profile,
                $modifiersList: ['$state', '$stateParams', 'modificatorsOperation', function ( $state, $stateParams, modificatorsOperation ) {
                    return modificatorsOperation.getModifiersList( {outletsIds: $stateParams.outletsIds} );
                }],
                $shortOutlets: ['modificatorsOperation', function ( modificatorsOperation ) {
                    return modificatorsOperation.getShortOutlets( {} );
                }],
                accessRight: accessRight('goods')
            }
        } )
        .state( 'goods.modifieredit', {
            url: '/modifieredit?{id:int}',
            controller: 'modifierCardEditCtrl',
            controllerAs: 'modifierCard',
            template: require('../templates-pug/modifiers/edit-modifier.pug').default,
            resolve: {
                $profile: profile,
                $modificator: [
                    '$stateParams',
                    'modificatorsOperation',
                    '$q',
                    'dialogs',
                    'stateGo',
                    '$state',
                    function ( $stateParams, modificatorsOperation, $q, dialogs, stateGo, $state ) {
                        return modificatorsOperation.getModifierById( {modifierId: $stateParams.id} )
                            .then(data => {
                                if(data.result === 'error') {
                                    if(data.message === 'modifier_deleted') {
                                        const dialogPromise = dialogs.listItemDeletedEarlier();
                                        if ($state.current.name === 'goods.modifiers-list') {
                                            dialogPromise.then(() => {
                                                stateGo.refresh();
                                            });
                                        } else {
                                            dialogPromise.finally(() => {
                                                stateGo.goToState('goods.modifiers-list');
                                            });
                                        }
                                    }
                                    return $q.reject(data);
                                }
                                return $q.resolve(data);
                            });
                    }],
                $currencyOptionsEditModif: currencyOptionsEditModif,
                accessRight: accessRight('goods')
            }
        } )
        .state('report.payTypes', {
            url: '/pay-types?{page:int}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{outletsIds:.*}&{merchantsIds:.*}',
            template: require('../templates-pug/report/sales/report-pay-types.pug').default,
            controller: 'reportPayTypesController',
            controllerAs: 'vmRepPayTypesCtrl',
            resolve: {
                $profile: profile,
                $payTypes: [
                    'filtersData',
                    '$stateParams',
                    'payTypesSummary',
                    'resolveTimezone',
                    '$rootScope',
                    function (filtersData, $stateParams, payTypesSummary, resolveTimezone, $rootScope) {
                        var stateParams = filtersData.getStateParams();
                        if(stateParams) {
                            $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || "all";
                            $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || "all";
                            $stateParams.from = stateParams.from || $stateParams.from || from;
                            $stateParams.to = stateParams.to || $stateParams.to || to;
                            $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                            $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                            // $stateParams.page = stateParams.page || $stateParams.page;
                            $stateParams.group = stateParams.group ||$stateParams.group;
                            $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                            $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                            $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                            $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                            $stateParams.predefinedPeriod = {};
                            $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                            $stateParams.predefinedPeriod.period = $stateParams.arg;
                            $stateParams.startWeek = stateParams.startWeek || $rootScope.startWeek || 0;
                            // $stateParams.limit = stateParams.limit || limit;
                        }
                        return payTypesSummary.getPayTypesSummary({
                            fromDate: $stateParams.from,
                            toDate: $stateParams.to,
                            tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                            tzName: tz.name(),
                            startWeek: $stateParams.startWeek,
                            customPeriod: $stateParams.customPeriod,
                            predefinedPeriod: $stateParams.predefinedPeriod,
                            startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                            endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                            merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                            outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null
                        });
                    }],
                $currencyOptionsShow: currencyOptionsShow,
                $countOptionsShow: countOptionsShow,
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        })
        .state('report.categories', {
            url: '/categories?{page:int}&{limit:.*}&{periodName:.*}&{periodLength:.*}&{arg:.*}&{from:.*}&{to:.*}&{fromHour:int}&{toHour:int}&{merchantsIds:.*}&{outletsIds:.*}',
            template: require('../templates-pug/report/sales/report-categories.pug').default,
            controller: 'reportCategoriesController',
            controllerAs: 'vmRepCatCtrl',
            resolve: {
                $profile: profile,
                $categories: [
                    '$stateParams',
                    'filtersData',
                    'categoriesSummary',
                    'resolveTimezone',
                    '$rootScope',
                    function ($stateParams, filtersData, categoriesSummary, resolveTimezone, $rootScope) {
                        var stateParams = filtersData.getStateParams();
                        if(stateParams) {
                            $stateParams.outletsIds = stateParams.outletsIds || $stateParams.outletsIds || null;
                            $stateParams.merchantsIds = stateParams.merchantsIds || $stateParams.merchantsIds || null;
                            $stateParams.from = stateParams.from || $stateParams.from || from;
                            $stateParams.to = stateParams.to || $stateParams.to || to;
                            $stateParams.fromHour = (stateParams.fromHour) ? parseFloat(stateParams.fromHour) : $stateParams.fromHour;
                            $stateParams.toHour = (stateParams.toHour) ? parseFloat(stateParams.toHour) : $stateParams.toHour;
                            // $stateParams.page = stateParams.page || $stateParams.page;
                            $stateParams.group = stateParams.group ||$stateParams.group;
                            $stateParams.periodName = stateParams.periodName || $stateParams.periodName || null;
                            $stateParams.arg = stateParams.arg  || stateParams.arg === 0 ? stateParams.arg :  $stateParams.arg ? $stateParams.arg : stateParams.predefinedPeriod && stateParams.predefinedPeriod.period ? stateParams.predefinedPeriod.period : null;
                            $stateParams.periodLength = stateParams.periodLength || $stateParams.periodLength || "7d";
                            $stateParams.customPeriod = !$stateParams.periodName && $stateParams.from !== null && $stateParams.to !== null;
                            $stateParams.predefinedPeriod = {};
                            $stateParams.predefinedPeriod.name = $stateParams.periodName ? $stateParams.periodName : ($stateParams.from && $stateParams.to) ? null : "sevenDays";
                            $stateParams.predefinedPeriod.period = $stateParams.arg;
                            $stateParams.startWeek = $rootScope.startWeek || stateParams.startWeek || 0;
                            // $stateParams.limit = stateParams.limit || limit;
                        }
                        let limit = parseToDec($stateParams.limit);
                        return categoriesSummary.getCategoriesSummary({
                            from: $stateParams.from,
                            to: $stateParams.to,
                            tzOffset: resolveTimezone.timeZoneOffsetForReports(),
                            startWeek: $stateParams.startWeek,
                            tzName: tz.name(),
                            startTime: ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.fromHour,
                            endTime:  ($stateParams.fromHour === 0 && $stateParams.toHour === 0) ? null : $stateParams.toHour,
                            limit: limit,
                            customPeriod: $stateParams.customPeriod,
                            predefinedPeriod: $stateParams.predefinedPeriod,
                            offset: limit * $stateParams.page,
                            merchantsIds: ($stateParams.merchantsIds && $stateParams.merchantsIds != "clear" && $stateParams.merchantsIds != "all") ? $stateParams.merchantsIds.split(',') : ($stateParams.merchantsIds == "clear") ? [] : ($stateParams.merchantsIds == "all") ? "all" : null,
                            outletsIds: ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") ? $stateParams.outletsIds.split(',') : ($stateParams.outletsIds == "clear") ? [] : ($stateParams.outletsIds == "all") ? "all" : null,
                            sortBy: "name"
                        });
                    }],
                $tableProp: ['tableColumnProp', function(tableColumnProp){
                    return tableColumnProp.getProps({"reportType":"CATEGORIES"});
                }],
                $currencyOptionsShow: currencyOptionsShow,
                $percentShowInfinityToHundred: ['currencyFormat', function ( currencyFormat ) {
                    return currencyFormat.getPercentShow(false, true, false, false);
                }],
                $permitsManager: permitsManager
            },
            params: {
                outletsIds: 'all',
                merchantsIds: 'all',
                fromHour: 0,
                toHour: 0
            }
        })
        .state('settings.paytypes', {
            url: '/paytypes?{outletId:.*}',
            views: {
                type: {
                    template: require('../templates-pug/settings/payment-types/settings-pay-types.pug').default,
                    controller: 'settingsPayTypes',
                    controllerAs: 'vmSettingsPayTypes',
                    resolve: {
                        $profile: profile,
                        $paytypePermissions: ['getPermits', function (getPermits) {
                            return getPermits.getPermitsList({});
                        }],
                        $paymentTypesInfo: ['$stateParams', 'payTypesOperationsFactory', function ($stateParams, payTypesOperationsFactory) {
                            return payTypesOperationsFactory.getPayTypes({
                                outletId: $stateParams.outletId
                            });
                        }],
                        $shortOutlets: ['getOutletsList', function ( getOutletsList ) {
                            return getOutletsList.getShortOutlets( {} );
                        }],
                        accessRight: accessRight('settings.paytypes')
                    }
                }
            }
        })

        .state('paymenttypes', {
            abstract: true,
            url: '/paymenttypes',
            template: '<ui-view></ui-view>',
            controller: 'paymentTypePermissions',
            controllerAs: 'payPermissions',
            resolve: {
                $profile: profile,
                lang: lang,
                $paytypePermissions: ['getPermits', function (getPermits) {
                    return getPermits.getPermitsList({});
                }],
                $payConst: ['paymentConst', function (paymentConst) {
                    return paymentConst;
                }],
                $paymentTypesInfo: ['$stateParams', 'payTypesOperationsFactory', function ($stateParams, payTypesOperationsFactory) {
                    return payTypesOperationsFactory.getPayTypes({});
                }],
                $shortOutlets: ['getOutletsList', function ( getOutletsList ) {
                    return getOutletsList.getShortOutlets( {} );
                }],
                accessRight: accessRight('paymenttypes')
            },
            params: {
                paymentTypeId: '',
                paymentTypeMethod: '',
                paymentTypeName: ''
            }
        })
        .state('paymenttypes.createpaymenttype', {
            url: "/createpaymenttype",
            template: require('../templates-pug/settings/payment-types/edit-payment-type.pug').default,
            controller: 'createPaymentTypeControl',
            controllerAs: 'createEditPayTypeCtrl'
        })
        .state('paymenttypes.editpaymenttype', {
            url: "/editpaymenttype?{paymentTypeId:$stateParams.paymentTypeId}&{uuid:.*}",
            template: require('../templates-pug/settings/payment-types/edit-payment-type.pug').default,
            controller: 'editPaymentTypeControl',
            controllerAs: 'createEditPayTypeCtrl',
            resolve: {
                $tipsData: ['$stateParams', 'payTypesOperationsFactory', '$q', '$state', 'dialogs', 'stateGo',
                    function ($stateParams, payTypesOperationsFactory, $q, $state, dialogs, stateGo) {
                        return payTypesOperationsFactory.getPayTypeById({
                            'paymentTypeId': $stateParams.paymentTypeId, 'uuid': $stateParams.uuid
                        }).then(data => {
                            if(data.result === 'error' && data.message === 'payment_type_deleted') {
                                const dialogPromise = dialogs.listItemDeletedEarlier();
                                if ($state.current.name === 'settings.paytypes') {
                                    dialogPromise.then(() => {
                                        stateGo.refresh();
                                    });
                                } else {
                                    dialogPromise.finally(() => {
                                        stateGo.goToState('settings.paytypes');
                                    });
                                }
                                return $q.reject(data);
                            }
                            return data;
                        });
                }],
                $currencyOptionsShow: currencyOptionsShow
            },
            params: {
                paymentTypeId: '',
                paymentTypeMethod: '',
                paymentTypeName: ''
            }
        })
        .state('vantiv', {
            abstract: true,
            url: '/vantiv',
            template: '<ui-view></ui-view>',
            resolve: {
                lang: lang
            }
        })
        // .state('vantiv.authentication', {
        //     url: '/authentication',
        //     template: require('../templates-pug/settings/terminal-integration/authentication.pug').default,
        //     controller: 'vantivAuthenticationController',
        //     params: {
        //         paymentTypeId: 0
        //     }
        // })
        .state('vantiv.registration', {
            url: '/registration',
            template: require('../templates-pug/settings/terminal-integration/registration.pug').default,
            controller: 'vantivRegistrationController',
            controllerAs: 'vmVantivRegistrationCtrl',
            resolve: {
                $ownerData: ['terminalOperationsFactory', function (terminalOperationsFactory) {
                    return terminalOperationsFactory.getTerminalOwnerData({
                        "terminalType":"ECONDUIT"
                    });
                }]
            },
            params: {
                paymentTypeId: 0,
                merchantNumber: null,
                wsPassword: null
            }
        })
        .state('vantiv.terminal', {
            url: '/terminal',
            template: require('../templates-pug/settings/terminal-integration/terminal.pug').default,
            controller: 'vantivTerminalController',
            controllerAs: 'vmVantivTerminalCtrl',
            resolve: {
                $cashboxesList: ['cashBoxesListFactory', function (cashBoxesListFactory) {
                    return cashBoxesListFactory.getCashBoxesList({});
                }]
            },
            params: {
                terminalData: {}
            }
        })
        .state('vantiv.cashbox', {
            url: '/cashbox',
            template: require('../templates-pug/settings/terminal-integration/vantiv-cashbox.pug').default,
            controller: 'vantivCashboxController',
            controllerAs: 'vmVantivCashboxCtrl',
            resolve: {
                $cashboxesList: ['cashBoxesListFactory', function (cashBoxesListFactory) {
                    return cashBoxesListFactory.getCashBoxesList({});
                }]
            },
            params: {
                cashboxParams: {},
                terminalData: {}
            }
        })
        .state('vantiv.notification', {
            url: '/notification',
            template: require('../templates-pug/settings/terminal-integration/vantiv-notification.pug').default,
            controller: 'notificationController',
            controllerAs: 'notifyCtrl',
            params: {
                result: '',
                previousState: '',
                cashboxParams: {},
                terminalData: {}
            }
        })


        // список касс
        .state( 'settings.cashregister', {
            url: "/cashregister?{outletId:.*}",
            views: {
                type: {
                    template: require('../templates-pug/settings/cash-register/cash-register-list.pug').default,
                    controller: 'cashRegisterCtrl',
                    resolve: {
                        $cashRegisterList: ['getCashRegister', '$stateParams', function ( getCashRegister, $stateParams ) {
                            return getCashRegister.getCashRegisterList( {outletId: $stateParams.outletId} );
                        }],
                        $shortOutlets: ['getCashRegister', function ( getCashRegister ) {
                            return getCashRegister.getShortOutlets( {} );
                        }],
                        $profile: profile
                    },
                    params: {
                        outletId: ''
                    }
                }
            },
            resolve: {
                accessRight: accessRight('settings.cashregister')
            }
        })
        // создание кассы
        .state( 'createcashregister', {
            url: "/settings/cashregister/create",
            template: require('../templates-pug/settings/cash-register/cash-register-create.pug').default,
            controller: 'createCashRegisterCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $shortOutlets: ['getCashRegister', function ( getCashRegister ) {
                    return getCashRegister.getShortOutlets( {} );
                }],
                accessRight: accessRight('createcashregister')
            }
        })
        // редактирование кассы
        .state( 'editcashregister', {
            url: "/settings/cashregister/edit/?{id:int}",
            template: require('../templates-pug/settings/cash-register/cash-register-edit.pug').default,
            controller: 'editCashRegisterCtrl',
            resolve: {
                lang: lang,
                $profile: profile,
                $cashRegisterForEdit: ['getCashRegister', '$stateParams', function ( getCashRegister, $stateParams ) {
                    return getCashRegister.getCashRegisterById( {cashRegisterId: $stateParams.id} );
                }],
                $shortOutlets: ['getCashRegister', function ( getCashRegister ) {
                    return getCashRegister.getShortOutlets( {} );
                }],
                accessRight: accessRight('editcashregister'),
                outlet: ['getOutletsList', "$cashRegisterForEdit", function (getOutletsList, $cashRegisterForEdit) {
                    return getOutletsList.getOutletById({'outletId': $cashRegisterForEdit.outletId});
                }],
            }
        })
        .state('nostores', {
            url: '/nostores',
            template: require('../templates-pug/no-stores.pug').default,
            controller: 'noStoresCtrl',
            controllerAs: 'noStores',
            resolve: {
                $profile: profile
            }
        })
}

export default {
    type: 'config',
    value: config
};

