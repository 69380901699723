const { wrap } = require('./../../common/strings/wrap');

lvBind.$inject = ["$parse"];

function lvBind($parse) {
    return {
        restrict: 'A',
        link: function ( scope, el, attrs) {

            let val = '';
            let limit = $parse(attrs.lvBindLimit)(scope) ?? 0;

            attrs.$observe("lvBindLimit", _limit => {
                limit = $parse(attrs.lvBindLimit)(scope) ?? 0;
                if (val) {
                    updateElementText();
                }
            });

            scope.$watch(attrs.lvBind, value => {
                val = value ?? "";
                updateElementText();
            });

            function updateElementText() {
                const result = limit > 0 ? wrap(val, limit) : val;
                el.text(result);
            }
        }
    }
}

export default {
    type: 'directive',
    name: 'lvBind',
    value: lvBind
};