/**
 * Created by sirosh on 03.06.16.
 */

import angular from 'angular';

modifierCardEditCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$filter',
    '$state',
    '$modificator',
    'serviceToast',
    'serviceDialog',
    'promiseOperation',
    'modificatorsOperation',
    '$currencyOptionsEditModif',
    '$timeout',
    '$mdUtil',
    'dialogs'
];

function modifierCardEditCtrl(
    $scope,
    $rootScope,
    $filter,
    $state,
    $modificator,
    serviceToast,
    serviceDialog,
    promiseOperation,
    modificatorsOperation,
    $currencyOptionsEditModif,
    $timeout,
    $mdUtil,
    dialogs) {

    let toastNotName,
        toastMax100Options,
        toastUnableToSaveChanges;

    const translate = $filter('translate');

    $scope.options = {
        showDelBtn: true
    };

    /*** формат ввода валюты ***/
    $scope.currencyOptionsEditModif = $currencyOptionsEditModif;

// читаем колличество знаков после запятой в валюте
    $scope.numberOfSigns = $scope.currencyOptionsEditModif.decimalPlacesOverride;
    $scope.modificator = $modificator;
    const modificator = angular.copy($modificator);

    //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ
    $scope.outlets = $scope.modificator.byOutlets;
    $scope.waresCount = 0;
    $scope.outletSelect = [];
    $scope.allOutlets = {};
    $scope.uniqueName = true;
    $scope.progress = false;

    $scope.$watch('outlets', (newVal) => {
        for (let j = 0; j < newVal.length; j++){
            if (newVal[j].enable === true){
                $scope.outletSelect.push(newVal[j].outletId);
                $scope.waresCount += newVal[j].waresCount;
            }
        }

        $scope.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;

        // ложим айдишники выбранных заведений в свойство обьекта modificator для отправки на сервер
        $scope.modificator.outletsIds = $scope.outletSelect;
        $scope.modificator.allowedForAllOutlets = $scope.allOutlets._select;
        $scope.outletSelect = [];
    }, true);

    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop
    $scope.sortableOptions = {
        handle: '.grab-handle',
        animation: 50,
        forceFallback: true
    };
    // ФУНКЦИОНАЛ ДЛЯ drag’n’drop


    $scope.changeAll = () => {
        if ($scope.allOutlets._select === true){
            for (let k = 0; k < $scope.outlets.length; k++){
                $scope.outlets[k]["enable"] = true;
            }
            $scope.allOutlets._select = true;
        } else {
            for (let m = 0; m < $scope.outlets.length; m++){
                $scope.outlets[m]["enable"] = false;
            }
            $scope.allOutlets._select = false;
        }
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - СЕЛЕКТ


    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА
    $scope.showLine = true; // показывать линию в главном блоке
    $scope.showAllOutlets = false;   // показывать весь блок с заведениями
    $scope.singleDisabledOutlet = $scope.outlets.length === 1 && $scope.outlets[0].enable === false;

    $scope.showBlock = (flag) => {
        $scope.showAllOutlets = flag;
        $scope.showLine = !flag;
        $scope.singleDisabledOutlet = !flag;
    };
    //** Контейнер для ЗАВЕДЕНИЙ  - ПОКАЗ ВСЕГО СПИСКА



    const offStateChangeStart = $scope.$on( '$stateChangeStart', function (
        event,
        toState,
        toParam,
        fromSate,
        fromParam ){

        if( (!angular.equals(modificator.byOutlets, $scope.modificator.byOutlets))
            || (!angular.equals(modificator.name, $scope.modificator.name))
            || (!angular.equals(modificator.options, $scope.modificator.options)) ){
            event.preventDefault();
            promiseOperation
                .getPromiseWithQ({
                    toState: toState,
                    toParam: toParam
                })
                .then(unSaveAlert);
        }
    });

    const unSaveAlert = (d) => {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('UNSAVED_TITLE'),
            content: translate('UNSAVED_LEAVE'),
            buttons: [
                {
                    text: 'UNSAVED_CONTINUE'
                },
                {
                    class: 'primary',
                    text: 'UNSAVED_DISCARD',
                    action: function () {
                        offStateChangeStart();
                        $state.go( d.toState, d.toParam);
                    }
                }
            ]
        });
    }

    $scope.$on('$destroy', function(){
        offStateChangeStart();
    });

    $('form').keydown(event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    $scope.addOption = () => {

        if ($scope.modificator.options.length === 100){
            if (toastMax100Options) {
                serviceToast.hide(toastMax100Options);
            }
            toastMax100Options = serviceToast.show('MODIFIERS.MAX_100_OPTIONS', 'success');
            return;
        }

        const lastIndex = $scope.modificator.options.length - 1;
        const priority = $scope.modificator.options[lastIndex] ? $scope.modificator.options[lastIndex].priority + 1 : 1;

        const option = {
            priority: priority,
            name: '',
            price: '0.00'
        };
        if ($scope.modifierForm.$invalid) {
            $mdUtil.nextTick(() => {
                $scope.modifierForm.validateControls();
                $scope.modifierForm.setInvalidTouchedControls();
            });
            if ($scope.optionsForm.$error.required) {
                if (toastNotName) {
                    serviceToast.hide(toastNotName);
                }
                toastNotName = serviceToast.show('MODIFIERS.SET_OPTION_NAME', 'success');
                $scope.$broadcast('validate', {});
            }
        }
        else {
            $scope.modificator.options.push(option);
        }
    };

    $scope.delOption = index => {
        $scope.modificator.options.splice(index, 1);
        if ($scope.optionsForm.$invalid) {
            $mdUtil.nextTick(() => {
                $scope.validateOptions();
            });
        }
    };


    $scope.onSaveEditModifier = (editProfile) => {
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
        } else if ($scope.modifierForm.$invalid || $scope.modificator.options.length === 0){
            if (toastUnableToSaveChanges) {
                serviceToast.hide(toastUnableToSaveChanges);
            }
            // “Unable to save changes. Please fix the errors and try again”.
            toastUnableToSaveChanges = serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
        } else {
            $scope.progress = true;
            $timeout(() => {
                modificatorsOperation
                    .editModificator($scope.modificator)
                    .then((res) => {
                        if (res.result === 'error') {
                            if (res.message === 'modifier_exists') {
                                $scope.progress = false;
                                $scope.uniqueName = false;
                                serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                            } else if (res.message === 'modifier_deleted') {
                                dialogs.itemDeletedEarlier()
                                    .then(() => {
                                        offStateChangeStart();
                                        $state.go('goods.modifiers-list').finally(() => {
                                            $scope.progress = false;
                                        });
                                    });
                            } else {
                                $scope.progress = false;
                            }

                        } else {
                            offStateChangeStart();
                            serviceToast.show('MODIFIERS.EDIT_MODIFIER', 'success');
                            $state.go('goods.modifiers-list').finally(() => {
                                $scope.progress = false;
                            });
                        }
                    });
            }, 0);
        }
    };

    $scope.onCancelEditModifier = () => {
        $state.go('goods.modifiers-list');
    };

    $scope.onDeleteModifier = (editProfile) => {
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        let ids = [$scope.modificator.id];

        function del(){
            modificatorsOperation.deleteModifier({modifierIds: ids}).then(function(res){
                if (res.result === 'ok'){
                    serviceToast.show('MODIFIERS.DELETE_MODIFIER', 'success');
                    $state.go('goods.modifiers-list');
                } else if (res.result === 'error' && res.message === 'modifier_deleted') {
                    dialogs.itemDeletedEarlier()
                        .then(() => {
                            offStateChangeStart();
                            $state.go('goods.modifiers-list');
                        });
                }
            });
        }

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('MODIFIERS.DELETE_MODIFIER_TITLE'),
            content: translate('MODIFIERS.DELETE_MODIFIER_TEXT'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'CONTINUE',
                    action: del
                }
            ]
        });
    };

    $scope.modifierOptionsGetter = () => $scope.modificator.options.map(modifier => modifier.name);

    $scope.validateOptions = () => {
        $scope.optionsForm.validateControls();
        $scope.optionsForm.setInvalidTouchedControls();
    }
}

export default {
    type: 'controller',
    name: 'modifierCardEditCtrl',
    value: modifierCardEditCtrl
};
