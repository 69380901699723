/**
 * Created by mars on 11/20/15.
 */

floatToString.$inject = ['$filter'];
function floatToString($filter) {
	var formatDigit = $filter('formatDigit');
	var currencyToCoin = $filter('currencyToCoin');
	function format (val) {
		if(val!=undefined && typeof val !=String){
			val = new String(val);
		}

		if(val.indexOf('.')!=-1 && val.length<val.indexOf('.')+3){
			var n = val.indexOf('.') - val.length +3;
			for(var i = 0; i<n; i++){
				val+='0'
			}
		}
		return formatDigit(val);
	}
	return format;

};

export default {
	type: 'filter',
	name: 'floatToString',
	value: floatToString
};