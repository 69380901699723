/**
 * Created by olehkss on 29.03.16.
 *
 * @ngdoc directive
 * @name app.directive:tableSearch
 * @scope
 * @params {number} pages number of pages
 * @params {string} pageTitle A string represents desired page title
 * @params {string} placeHolder A string represents placeholder in a search input
 * @restrict E
 * @requires $state
 * @requires $stateParams
 * @requires stateGo
 * @description
 * 'tableSearch' directive adds search field to the top of the report table.
 * Search field can be opened or closed.
 *
 * @example
 * <table-search page-title = "pageTitle"
 * place-holder = "placeHolder"></table-search>
 */

// it work in Customer base
//            Notifications
//            Receipts

import template from '../templates-pug/for-directive/table-search.pug';

tableSearchDirective.$inject = ['$state', '$stateParams', 'factoryPermits'];
function tableSearchDirective ($state, $stateParams, factoryPermits) {
    return {
        restrict: 'E',
        controller: tableSearchController,
        link: link,
        controllerAs: 'vmTS',
        template: template,
        scope: {
            pageTitle: '@',
            placeHolder: '@',
            pageTitleRendered: '@',
            buttonName: '@',
            linkToAdd: '@',
            exportBtn1: '@',
            exportBtn2: '@',
            disabledState: '<?',
            unlimitedSalesHistory: '<?',
            showSearch: '<?'
        }
    };

    function link (scope, element, attrs, vm) {

        vm.search = $stateParams.search || null;
        vm.title = scope.pageTitle;
        vm.placeHolder = scope.placeHolder;
        vm.disabledState = scope.disabledState;
        vm.showSearch = angular.isDefined(scope.showSearch) ? scope.showSearch : true;
        scope.buttonsList = [];

        if (scope.exportBtn1 && scope.exportBtn2) {
            scope.buttonsList[0] = {text: 'RECEIPTS_CSV', href: scope.exportBtn1};
            scope.buttonsList[1] = {text: 'RECEIPT_ITEM_CSV', href: scope.exportBtn2};
        }

        scope.onDel = function () {
            scope.$parent.onDel();
        };

        scope.onAdd = function() {
            if(factoryPermits.editProfile) {
                $state.go(scope.linkToAdd);
            }
        };

        scope.$watch(function() {
            return scope.showDel = scope.$parent.showDel;
        });

        var titleWrap = element.find('.titleWrap');
        var search = element.find('.search');
        var head = element.find('.tableHead');
        scope.shadow = false;

        if($stateParams.search === undefined || $stateParams.search === null || $stateParams.search === '') {
            search.addClass('disable');
            titleWrap.removeClass('disable');
        } else {
            titleWrap.addClass('disable');
            search.removeClass('disable');

            //lost open field after search process
            search.addClass('shadow');
        }

        element.on('click', function (event) {
            var input = element.find('input');

            // Task #20033 on click on icon search open search form where input is activated, on focus
            if (event.target.id === 'enableSearch'|| event.target.id === 'startSearch' ) {
                titleWrap.addClass('disable');
                search.removeClass('disable');
                head.addClass('activeTableSearch');
                input.focus();

                setTimeout(function() {
                    search.addClass('shadow');
                }, 10);

                scope.shadow = true;

            } else if(event.target.id === 'searchDone'){
                input.val('');

                search.removeClass('shadow');

                setTimeout(function() {
                    search.addClass('disable');
                    titleWrap.removeClass('disable');
                    head.removeClass('activeTableSearch');
                    input.blur();
                    scope.shadow = false;

                }, 200);

            }
        });

    }
}

tableSearchController.$inject = ['$state', 'stateGo', '$stateParams'];
function tableSearchController($state, stateGo, $stateParams) {
    var vm = this;

    vm.startSearch = startSearch;
    vm.endSearch = endSearch;

    function startSearch () {
        if (vm.search === null || vm.search === '' || vm.search === ' ') {
            return false;
        } else {
            var toURI;
            if(vm.search.indexOf("%") > -1) {
                var pos = vm.search.indexOf("%");
                var str1 = vm.search.substr(0,pos+1);
                var str2 = vm.search.substr(pos+1);
                toURI = str1 + "25" + str2
            }
            toURI = (toURI) ? toURI : vm.search;
            $stateParams.search = vm.search ? decodeURIComponent(toURI) : vm.search;
            $stateParams.page = 0;

            stateGo.go($state, $stateParams, true);
        }
    }

    function endSearch () {
        $stateParams.search = null;
        // add this decision if in open search input empty or click on icon close cross search do not do load page
        if (!(vm.search == null || vm.search === '' || vm.search === ' ')) {
            stateGo.go($state, $stateParams, true);
        }
    }
}

export default {
    type: 'directive',
    name: 'tableSearch',
    value: tableSearchDirective
};
