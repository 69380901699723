import angular from 'angular';

orderDetail.$inject = [
	'$scope',
	'$stateParams',
	'$ordersDetail',
	'$state',
	'lodash',
	'$critCountOptionsEdit',
	'$currencyOptionsShow',
	'serviceDialog',
	'$filter',
	'factoryInventory',
	'serviceToast',
	'$profile',
	'$countOptionsShow',
	'resolveTimezone',
	'declensionsConst',
	'serviceStrings',
	'lvMixpanel',
	'$permitsManager',
	'dialogs',
	'factoryPurchaseOrders',
	'constantStateErrors',
	'stateGo'
];

function orderDetail(
	$scope,
	$stateParams,
	$ordersDetail,
	$state,
	lodash,
	$critCountOptionsEdit,
	$currencyOptionsShow,
	serviceDialog,
	$filter,
	factoryInventory,
	serviceToast,
	$profile,
	$countOptionsShow,
	resolveTimezone,
	declensionsConst,
	serviceStrings,
	lvMixpanel,
	$permitsManager,
	dialogs,
	factoryPurchaseOrders,
	constantStateErrors,
	stateGo
) {

	var vm = this;
	var translate = $filter('translate');
	var declensions = angular.copy(declensionsConst);
	$scope.displayCost = $permitsManager.costAllowed;
	$scope.profile = angular.copy($profile);
	// данные для сохранение состояния фильтров
	$scope.stateFromList = $stateParams.stateToSave;
	// данные для сохранение состояния фильтров

	vm.profile = $profile;

	vm.orderDetails = angular.copy($ordersDetail);
	$scope.orderData = vm.orderDetails.orderData;
	$scope.supplierData = vm.orderDetails.supplier;
	$scope.itemsData = vm.orderDetails.items;
	$scope.landedCosts = vm.orderDetails.landedCosts;
	$scope.critCountOptionsEdit = $critCountOptionsEdit;
	$scope.currencyOptionsShow = $currencyOptionsShow;
	$scope.countOptionsShow = $countOptionsShow;
	$scope.progress = false;

	if($scope.orderData.status === 'CANCELLED') {
		$scope.orderData.status = 'CLOSED';
	}

	$scope.tzOffset = resolveTimezone.timeZoneOffsetForReports();

	vm.numberOf = [];
	vm.numberOfDeleted = [];

	for (var i=0; i<$scope.itemsData.length; i++) {
		if ($scope.itemsData[i].keepCount === false && $scope.itemsData[i].deleted === false) {
			vm.numberOf.push($scope.itemsData[i]);
		}
		if ($scope.itemsData[i].deleted === true) {
			vm.numberOfDeleted.push($scope.itemsData[i]);
		}
	}

	const backToList = () => {
		factoryPurchaseOrders.backToPurchaseOrdersList($scope.stateFromList);
	}

	$scope.onBack = backToList;

	$scope.showMoreFunk = function() {
		if(angular.element('.drop-buttons-menu').hasClass('open')){
			angular.element('.drop-buttons-menu').addClass('close');
			angular.element('.drop-buttons-menu').removeClass('open');
		}else{
			angular.element('.drop-buttons-menu').removeClass('close');
			angular.element('.drop-buttons-menu').addClass('open');
		}
	}

	document.addEventListener('click', function(e){
		if(!angular.element(e.target).hasClass('button') || !angular.element(e.target).hasClass('href')) {
			angular.element('.drop-buttons-menu').addClass('close');
			angular.element('.drop-buttons-menu').removeClass('open');
		}
	})

	function totalAmount(){
		var totalItems = 0;
		var totalLands = 0;
		for (var i = 0; i < $scope.itemsData.length; i++) {
			if($scope.itemsData[i].status !== 'CANCELED') {
				totalItems += $scope.itemsData[i].amount;
			}
		}
		for (var i = 0; i < $scope.landedCosts.length; i++) {
			if($scope.landedCosts[i].status !== 'CANCELED') {
				totalLands += $scope.landedCosts[i].amount;
			}
		}
		return totalItems + totalLands;
	}
	$scope.totalAmount = totalAmount();
	$scope.orderData.barValue = vm.orderDetails.orderData.receivedCurrent/vm.orderDetails.orderData.receivedTotal * 100;

	$scope.onReceive = function() {
		if(vm.numberOf.length > 0
			|| vm.numberOfDeleted.length > 0
		) {
			$scope.progress = true;
			factoryInventory.getReportColumns({
				"reportType":"INVENTORY_MESSAGES"
			}).then(function(data){
				if(data.columnsView[0] && data.columnsView[0].value === true
				&& data.columnsView[1] && data.columnsView[1].value === true
					|| (data.columnsView[0] && data.columnsView[0].value === true && vm.numberOfDeleted.length < 1)
					|| (data.columnsView[1] && data.columnsView[1].value === true && vm.numberOf.length < 1)
				)
				{
					$state.go('inventory.receiveorder', {
						id:$stateParams.id
					});
				} else {
					// vm.checked = data.columnsView;
					vm.noTrackStrings = itemsStringFromNumber(vm.numberOf.length);
					vm.deletedStrings = itemsStringFromNumber(vm.numberOfDeleted.length);
					vm.checked = {value: false};
					serviceDialog.add( {
						templateUrl: 'dashboard-page/templates/dialog/order-receiving-mesage.html',
						// content1: 'INVENTORY.COST_AND_STOCK_MESAGE',
						content2: translate('INVENTORY.DONT_SHOW'),
						number: vm.numberOf,
						numberDeleted: vm.numberOfDeleted,
						noTrackStrings: vm.noTrackStrings,
						deletedStrings: vm.deletedStrings,
						noCount: data.columnsView[0],
						noDeleted: data.columnsView[1],
						checked: vm.checked,
						buttons: [
							{
								text: 'ON_CANCEL',
								action: () => {
									$scope.progress = false;
								}
							},
							{
								class: 'md-primary',
								text: 'PAYMENT_TYPES.CONTINUE',
								action: function () {
									var checked = vm.checked,
									numberOf = vm.numberOf,
									numberDeleted = vm.numberOfDeleted,
									dontShow = {
										"reportType": "INVENTORY_MESSAGES",
										"columnsView": [
											{
												"name": "dontShowMessageAccountItems",
												"value": data.columnsView[0] && data.columnsView[0].value === true
													? true
													: numberOf.length !== 0
														? checked.value
														: false,
												// "value": checked.value,
												"id": 1
											},
											{
												"name": "dontShowMessageDeletedItems",
												"value": data.columnsView[1] && data.columnsView[1].value === true
													? true
													: numberDeleted.length !== 0
														? checked.value
														: false,
												"id": 2
											}
										]
									};
									factoryInventory.setReportColumns(dontShow)
										.then(function(data){
											if (data.result === "ok") {
												$state.go('inventory.receiveorder', {
													id:$stateParams.id
												});
											} else {
												$scope.progress = false;
											}
										})
										.catch(() => {
											$scope.progress = false;
										})
								}
							}
						]
					});
				}
			})

		} else {
			$state.go('inventory.receiveorder', {
				id:$stateParams.id
			});
		}
	};

	$scope.onEdit = function () {
		$state.go('inventory.editorder', {
			id:$stateParams.id
		});
	};

	$scope.onDuplicate = function () {
		//Triggered when clicking on the “Duplicate” button in
		// the “Purchase order details” page.
		lvMixpanel.track( 'Back office page visit', {
			"Property": "Inventory management/Duplicate purchase order"
		});
		$state.go('inventory.duplicateorder', {
			id:$stateParams.id
		});
	};

	vm.emailInfo = {
		from: vm.orderDetails.orderData.merchantEmail,
		to: $scope.supplierData.email,
		cc: "",
		subject: translate('INVENTORY.ORDER_FROM') + " " + vm.profile.name + " " + "(" + vm.orderDetails.orderData.order + ")",
		note: "",
		order: vm.orderDetails.orderData.order
	};

	$scope.toSendDialog = function() {
		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/send-purchase-order.html',
			title: translate('INVENTORY.SEND_PURCHASE'),
			emailInfo: vm.emailInfo,
			buttons: [
				{
					text: 'ON_CANCEL',
					action: () => stateGo.refresh()
				},
				{
					class: 'primary',
					text: 'INVENTORY.SEND',
					action: function () {
						var emailInfo = vm.emailInfo,
						toSend = {
							orderId:$stateParams.id,
							emailTo: emailInfo.to,
							emailCc: emailInfo.cc || "",
							subject: emailInfo.subject,
							message: emailInfo.note || "",
							tzOffset: $scope.tzOffset
						}
						factoryInventory.sendOrderByEmail(toSend).then(function(data){
							if (data.result === "ok") {
								lvMixpanel.track('Back office page visit', {
									"Property": "Inventory management/Send purchase order by email"
								});
								serviceToast.show('INVENTORY.EMAIL_SENT', 'success');
							} else if (data.result === "error" && data.message === "purchase_order_deleted") {
								dialogs.itemDeletedEarlier().then(backToList);
							}
						})
					}
				}
			]
		} );
	};

	$scope.onApprove = function( editProfile ){
		//permission to edit profile, false for manager
		if ( !editProfile ) {
			serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
			return;
		}
		$scope.progress = true;
		factoryInventory.approvePurchaseOrder({
			id: $stateParams.id
		}).then(function(data){
			if (data.result === "ok") {
				serviceToast.show('INVENTORY.ORDER_APPROVED', 'success');
				stateGo.refresh()
			} else {
				$scope.progress = false;
			}
			if (data.result === "error") {
				if (data.message === "purchase_order_deleted") {
					dialogs.itemDeletedEarlier().then(backToList);
				} else if (data.message === "purchase_order_approved") {
					dialogs.purchaseOrderApproved().then(stateGo.refresh);
				}
			}
		}).catch(() => {
			$scope.progress = false;
		})
	};

	$scope.onCancelOrder = function() {
		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate('INVENTORY.ARE_YOU_SURE_CANCEL_TITLE'),
			content: translate( 'INVENTORY.ARE_YOU_SURE_CANCEL' ),
			buttons: [
				{
					text: 'ON_CANCEL'
				},
				{
					class: 'primary',
					text: 'CONFIRM',
					action: function () {
						factoryInventory.cancelOrderRemainingItems({
							id: $stateParams.id
						}).then(function(data){
							if (data.result === "ok") {
								serviceToast.show('INVENTORY.ORDER_CANCELLED', 'success');
								stateGo.refresh();
							} else if (data.result === "error") {
								if (data.message === "purchase_order_deleted") {
									dialogs.itemDeletedEarlier().then(backToList);
								} else if (data.message === "purchase_order_closed") {
									dialogs.purchaseOrderClosed().then(stateGo.refresh);
								}
							}
						})
					}
				}
			]
		} );
	};


	$scope.onDelete = function(editProfile){
		//permission to edit profile, false for manager
		if ( !editProfile ) {
			serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
			return;
		}
		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate('INVENTORY.ARE_YOU_SURE_PURCHASE_TITLE'),
			content: translate( 'INVENTORY.ARE_YOU_SURE_PURCHASE' ),
			buttons: [
				{
					text: 'ON_CANCEL'
				},
				{
					class: 'primary',
					text: 'ON_DEL',
					action: function () {
						factoryInventory.deletePurchaseOrderDraft({
							id: $stateParams.id
						}).then(function(data){
							if (data.result === "ok") {
								serviceToast.show('INVENTORY.DELETE_ORDER', 'success');

								if (lodash.isEmpty($scope.stateFromList)) {
									$state.go( 'inventory.purchase', {	page:			0,
																		limit: 			10}, {reload: true} );
								} else {
									$state.go( 'inventory.purchase', {	page: 			$scope.stateFromList.page,
																		limit: 			$scope.stateFromList.limit,
																		outletId:		$scope.stateFromList.outletId,
																		supplier: 		$scope.stateFromList.supplier,
																		status: 		$scope.stateFromList.status}, {reload: true} );
								}
							} else if (data.result === "error") {
								if (data.message === "purchase_order_deleted") {
									dialogs.itemDeletedEarlier().then(backToList);
								} else if (data.message === "purchase_order_closed") {
									dialogs.purchaseOrderClosed().then(stateGo.refresh);
								} else if (data.message === "purchase_order_obsolete") {
									dialogs.purchaseOrderObsolete().then(stateGo.refresh);
								}
							}
						});
					}
				}
			]
		} );
	};

	$scope.goPrintLabels = function () {
		// Triggered when users visit the “Create labels” page by clicking on the “Print labels” button in the purchase order details.
		lvMixpanel.track('Back office page visit', {
			"Property": "Inventory management/Create labels for purchase order"
		});
		$state.go('inventory.printlabels', {orderId: $stateParams.id, orderType: "PURCHASE_ORDER"});
	};

	const savePurchasePdfForMixpanel = () => {
		lvMixpanel.track('Export', {
			"Property": "Inventory management/Save purchase order as PDF"
		});
	};

	const savePurchaseCsvForMixpanel = () => {
		lvMixpanel.track('Export', {
			"Property": "Inventory management/Save purchase order as CSV"
		});
	};

	const downloadData = {
		orderId: $scope.orderData.id,
		tzOffset: $scope.tzOffset
	}

	$scope.downloadPurchaseOrderPDF = () => {
		savePurchasePdfForMixpanel();

		factoryPurchaseOrders.getPurchaseOrderPDF(downloadData).then((res) => {
			if (res.result === "error" && res.message === "purchase_order_deleted") {
				dialogs.itemDeletedEarlier().then(backToList);
			}
		})
	}

	$scope.downloadPurchaseOrderCSV = () => {
		savePurchaseCsvForMixpanel();

		factoryPurchaseOrders.getPurchaseOrderCSV(downloadData).then((res) => {
			if (res.result === "error" && res.message === "purchase_order_deleted") {
				dialogs.itemDeletedEarlier().then(backToList);
			}
		})
	}

	function itemsStringFromNumber(number) {

		var currentDeclension = serviceStrings.getDeclensions(number);

		switch (currentDeclension) {
			case declensions.twoToFour:
				return [
					translate("ITEMS_DECLINE.ITEMS_2_TO_4"),
					translate("INVENTORY.DELETED_MANY"),
					translate("INVENTORY.DISABLED_MANY")
				];
			case declensions.everyFirst:
				return [
					translate("ITEMS_DECLINE.ITEMS_1"),
					translate("INVENTORY.DELETED_ONE"),
					translate("INVENTORY.DISABLED_MANY")
				];
			case declensions.onlyOne:
				return [
					translate("ITEMS_DECLINE.ONE_ITEM"),
					translate("INVENTORY.DELETED_ONE"),
					translate("INVENTORY.DISABLED_ONE")
				];
			default:
				return [
					translate("ITEMS_DECLINE.ITEMS_5_TO_10"),
					translate("INVENTORY.DELETED_MANY"),
					translate("INVENTORY.DISABLED_MANY")
				];
		}
	}
}

export default {
	type: 'controller',
	name: 'orderDetail',
	value: orderDetail
};
