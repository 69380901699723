/**
 * Created by Olga G on 11/17.
 */

import angular from 'angular';

factoryInventory.$inject = [
    'promiseOperation'];

function factoryInventory (
                      promiseOperation){

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getshortoutlets',
            params,
            successGetShortOutlets
        );
    }

    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function getSuppliersData(params) {
        // console.log("params = ", params);
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getSuppliers',
                params,
                successGetSuppliersData
            );
    }

    function successGetSuppliersData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.suppliers;
            }
        }
    }

    function getOrdersListData(params) {

        // if(params.outletId == undefined || params.outletId == ''){
        //     params.outletId = null;
        // }
        // if(params.search == undefined){
        //     params.search = null;
        // }

        // console.log("params = ", params);

        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getPurchaseOrderList',
                params,
                successGetOrdersListData
            );
    }

    function getSuppliers(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getSuppliers',
                params,
                successGetSuppliers
            );
    }

    function setOrdersListData(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getPurchaseOrderList',
                params,
                successSetOrdersListData
            );
    }

    function searchItemsForPurchaseOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/searchItemsForPurchaseOrder',
                params,
                successSearchItemsForPurchaseOrder
            );
    }

    function successGetOrdersListData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }


    function successSetOrdersListData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function successGetSuppliers(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function successSearchItemsForPurchaseOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function getOrderDetail(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getPurchaseOrderDetail',
            params,
            successGetPurchaseOrderDetail
        );
    }

    function successGetPurchaseOrderDetail(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function deletePurchaseOrderDraft(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/delete-purchase-order-draft',
            params,
            successDeletePurchaseOrderDraft
        );
    }

    function successDeletePurchaseOrderDraft(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function approvePurchaseOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/approveOrder',
            params,
            successApprovePurchaseOrder
        );
    }

    function successApprovePurchaseOrder(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function createPurchaseOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/createPurchaseOrder',
            params,
            successCreatePurchaseOrder
        );
    }

    function successCreatePurchaseOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function editPurchaseOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/editPurchaseOrder',
            params,
            successEditPurchaseOrder
        );
    }

    function successEditPurchaseOrder(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function getOrderInfoForReceive(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getOrderInfoForReceive',
            params,
            successgetOrderInfoForReceive
        );
    }

    function successgetOrderInfoForReceive (response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function receiveOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/receiveOrder',
            params,
            successReceiveOrder
        );
    }

    function successReceiveOrder (response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function cancelOrderRemainingItems(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/cancelOrderRemainingItems',
            params,
            successCancelOrderRemainingItems
        );
    }

    function successCancelOrderRemainingItems (response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function getOrderItemsForOutlet(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getOrderItemsForOutlet',
            params,
            successGetOrderItemsForOutlet
        );
    }

    function successGetOrderItemsForOutlet(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getReportColumns(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getreportcolumns',
            params,
            successGetReportColumns
        );
    }

    function successGetReportColumns(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function setReportColumns(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/setreportcolumns',
            params,
            successSetReportColumns
        );
    }

    function successSetReportColumns(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getInventoryCost(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getInventoryCost',
            params,
            successGetInventoryCost
        );
    }

    function successGetInventoryCost(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getWareCategories(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getwarecategories',
            params,
            successGetWareCategories
        );
    }

    function successGetWareCategories(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.categories;
            }
        }
    }

    function getCategoriesForAddingItems(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getCateroriesForAddingItems',
            params,
            successGetCategoriesForAddingItems
        );
    }

    function successGetCategoriesForAddingItems(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.categories;
            }
        }
    }

    function getSuppliersForAddingItems(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getSuppliersForAddingItems',
            params,
            successGetSuppliersForAddingItems
        );
    }

    function successGetSuppliersForAddingItems(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.suppliers;
            }
        }
    }

    function sendOrderByEmail(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/sendPurchaseOrderToEmail',
            params,
            successSendOrderByEmail
        );
    }

    function successSendOrderByEmail(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function sendTransfer(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/sendTransferOrderToEmail',
            params,
            successSendTransfer
        );
    }

    function successSendTransfer(response) {
        if (angular.isObject(response.data)) {
            return response.data;
        }
    }

    function searchItemsForPrintLabels(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/searchItemsForPrintLabels',
            params,
            successSearchItemsForPrintLabels
        );
    }

    function successSearchItemsForPrintLabels(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function getWaresByCategories(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getWaresByCategories',
            params,
            successGetWaresByCategories
        );
    }

    function successGetWaresByCategories(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getWaresBySuppliers(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getWaresBySuppliers',
            params,
            successGetWaresBySuppliers
        );
    }

    function successGetWaresBySuppliers(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function searchItemsForAutofillPurchaseOrder(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/searchItemsForAutofillPurchaseOrder',
            params,
            successSearchItemsForAutofillPurchaseOrder
        );
    }

    function successSearchItemsForAutofillPurchaseOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    const isInventoryOrderDeleted = (inventoryOrder) => {
        return promiseOperation.getPromiseHTTP(
          "POST",
          "/data/ownercab/is-inventory-order-deleted",
          inventoryOrder,
          (res) => {
              if (angular.isObject(res.data) && res.data.result === "ok") {
                  return res.data.deleted;
              }
          }
        )
    }

    return {
        getShortOutlets: getShortOutlets,
        getOrdersListData: getOrdersListData,
        setOrdersListData: setOrdersListData,
        getSuppliersData: getSuppliersData,
        getSuppliers: getSuppliers,
        searchItemsForPurchaseOrder: searchItemsForPurchaseOrder,
        getOrderDetail: getOrderDetail,
        deletePurchaseOrderDraft: deletePurchaseOrderDraft,
        approvePurchaseOrder: approvePurchaseOrder,
        createPurchaseOrder: createPurchaseOrder,
        editPurchaseOrder: editPurchaseOrder,
        getOrderItemsForOutlet: getOrderItemsForOutlet,
        getOrderInfoForReceive: getOrderInfoForReceive,
        receiveOrder: receiveOrder,
        cancelOrderRemainingItems: cancelOrderRemainingItems,
        getReportColumns: getReportColumns,
        setReportColumns: setReportColumns,
        getInventoryCost: getInventoryCost,
        getWareCategories: getWareCategories,
        getCategoriesForAddingItems: getCategoriesForAddingItems,
        getSuppliersForAddingItems: getSuppliersForAddingItems,
        sendOrderByEmail: sendOrderByEmail,
        sendTransfer: sendTransfer,
        searchItemsForPrintLabels: searchItemsForPrintLabels,
        getWaresByCategories: getWaresByCategories,
        getWaresBySuppliers: getWaresBySuppliers,
        searchItemsForAutofillPurchaseOrder: searchItemsForAutofillPurchaseOrder,
        isInventoryOrderDeleted: isInventoryOrderDeleted
    };

}

export default {
    type: 'factory',
    name: 'factoryInventory',
    value: factoryInventory
};
