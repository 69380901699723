/**
 * Created by sirosh on 24.02.16.
 */

const permitsMenu = [
/**
 * 0: Отчеты
 */
    {
        stateName: 'report',
        permit: "ACCESS_REPORTS"
    },
/**
 * 1: Товары
 */

    {
        stateName: 'goods',
        permit: "ACCESS_WARES"
    },

/**
 * 2: Сотрудники
 */
    {
        stateName: 'employees',
        permit:  "ACCESS_MERCHANTS"
    },

/**
 * 3: Клиенты
 */
    {
        stateName: 'clients',
        permit:  "ACCESS_CLIENTS"
    },

/**
 * 4: Отзывы
 */
    {
        stateName: 'recall',
        permit:  "ACCESS_BACK_CLIENT_RECALLS"
    },

/**
 * 5: Редактировать профиль
 */
    {
        stateName: 'settings.profile',
        permit:  "ACCESS_EDIT_PROFILE"
    },
/* 6: Доступ с страницы билинга в настройках (Аккаунт владельца) */
    {
        stateName: 'settings.account',
        permit: "ACCESS_BILLING"
    },

/**
 * 7: Менять настройки программы лояльности
 */
    {
        stateName: 'settings.loyalty',
        permit:  "ACCESS_EDIT_LOYALTY"
    },

/**
 * 8: Настраивать налоги
 */
    {
        stateName: 'settings.tax',
        permit:  "ACCESS_TAXES"
    },


/* 9: Настраивать кухонные принтеры */
    {
        stateName: 'settings.kitchen',
        permit:  "ACCESS_KITCHEN_PRINTER"
    },


/* 10: Настраивать кассы */
    {
        stateName: 'settings.cashregister',
        permit:  "ACCESS_CASH_REGISTERS"
    },


/**
 * 11: Менять настройки заведения(й)
 */
    {
        stateName: 'settings.outlet',
        permit:  "ACCESS_OUTLETS"
    },

/**
 * 12: Доступ к Vantiv
 */
    {
        stateName: 'settings.vantiv',
        permit: "ACCESS_VANTIV"
    },

/**
* 13: Менять настройки типов оплат
*/

    {
        stateName: 'settings.paytypes',
        permit: "ACCESS_EDIT_PAYMENT_TYPE"
    },

/**
 * 14:настройки чеков
 */
    // {
    //     stateName: 'settings.reciepts',
    //     permit:  "ACCESS_EDIT_PROFILE"
    // },

/* 15: Настраивать Типы заказа */
    {
        stateName: 'settings.dining',
        permit:  "ACCESS_DINING_OPTIONS"
    },

/* 16: Управлять inventory management */
    {
        stateName: 'inventory',
        permit:  "ACCESS_INVENTORY"
    },

/* 17: Управлять inventory management когда нет подписки*/
    {
        stateName: 'inventory.management',
        permit:  "ACCESS_INVENTORY"
    },

/* 18: Клиенты при отключенном Лойалти*/
    {
        stateName: 'clients.database',
        permit:  "ACCESS_CLIENTS"
    },

/* 19: токены для API*/
    {
        stateName: 'integrations',
        permit:  "ACCESS_API_AUTH_TOKENS"
    },
/* 20: токены для API*/
    {
        stateName: 'integrations.management',
        permit:  "ACCESS_API_AUTH_TOKENS"
    },
/* 21: Переход на страницу help сфйта, community или живая поддержка */
    {
        stateName: 'help',
        permit:  "ACCESS_BACK_OFFICE"
    },
/* 22: Чат поддержки */
    {
        stateName: 'help.chat',
        permit:  "ACCESS_BACK_OFFICE_SUPPORT"
    }

];

export default {
    type: 'constant',
    name: 'permitsMenu',
    value: permitsMenu
};
