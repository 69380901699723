import angular from 'angular';

lvResizText.$inject = ['$timeout'];

function lvResizText($timeout) {
	return {
		restrict: 'E',
		template: '<span id ="invisible_span" class="fz-34">{{nameCopy}}</span>',
		transclude: true,
		scope: {element: '=',
				key: '=',
				id: '='},
		link: function (scope, el, attr, cntrl){
			// console.log("scope = ", scope.$parent.tax);
			// console.log("scope.element = ", scope.element);
			// console.log("scope.attr = ", scope.attr);

			var key = scope.key;
			scope.nameCopy = scope.element[key];
			// console.log("key = ", key);
			// console.log("scope.test.key = ", scope.test[key]);
			scope.$watch('element', function(newVal, oldVal){
					// console.log("oldVal = ", oldVal);
					// console.log("newVal = ", newVal);
				if ((oldVal[key] != newVal[key]) && (newVal[key] != undefined) && (oldVal[key] != undefined)) {
					// console.log(typeof(newVal[key]));
					scope.nameCopy = newVal[key];
					var newLengthInp = newVal[key].length;
					var oldLengthInp = oldVal[key].length;
					var span = document.querySelector( '#invisible_span' ) ;
					var result = span.offsetWidth;
					// console.log("span = ", span);
					// console.log("newLengthInp = ", newLengthInp);
					// console.log("ширина имени = ", result);
					var inp = document.querySelector(scope.id);
					// console.log("scope.id = ", scope.id);
					var widthInp = inp.offsetWidth;
					var fontSizeInp = angular.element( document.querySelector( scope.id ) ).css("font-size");
					var elemInp = angular.element( document.querySelector( scope.id ) );
					var elemSpan = angular.element( document.querySelector( '#invisible_span' ) );
					// console.log("widthInp #name = ", widthInp);

					if (result >= widthInp-18 && fontSizeInp == "34px" && newLengthInp > oldLengthInp) {
						elemInp.css({"font-size":"24px"});
						elemSpan.css("font-size","24px");

					}
					if (result >= widthInp-18 && fontSizeInp == "24px" && newLengthInp > oldLengthInp ) {
						elemInp.css("font-size","14px");
						elemSpan.css("font-size","14px");
					}
					if (result <= 3*widthInp/4 && fontSizeInp == "14px" && newLengthInp < oldLengthInp ) {
						elemInp.css("font-size","24px");
						elemSpan.css("font-size","24px");
					}
					if (result <= 3*widthInp/4 && fontSizeInp == "24px" && newLengthInp < oldLengthInp ) {
						elemInp.css({"font-size":"34px"});
						elemSpan.css("font-size","34px");
					}
				}
			}, true);




			scope.fontLoadResize = function() {

				var span = document.querySelector( '#invisible_span' ) ;
				var widthSpan = span.offsetWidth;
				// console.log("widthSpan = ", span);
				var inp = document.querySelector(scope.id);
				var widthInp = inp.offsetWidth;
				var fontSizeInp = angular.element( document.querySelector(scope.id) ).css("font-size");
				var elemInp = angular.element( document.querySelector( scope.id) );
				var elemSpan = angular.element( document.querySelector( '#invisible_span' ) );

				if (window.innerWidth < 601) {
					elemInp.css({"font-size":"16px"});
					elemSpan.css("font-size","16px");
				} else{
					if (widthSpan >= 1.5*widthInp && fontSizeInp == "34px") {
						elemInp.css({"font-size":"14px"});
						elemSpan.css("font-size","14px");
					} else if (widthSpan >= widthInp && fontSizeInp == "34px") {
						elemInp.css({"font-size":"24px"});
						elemSpan.css("font-size","24px");
					}
					if (widthSpan >= widthInp && fontSizeInp == "24px") {
						elemInp.css("font-size","14px");
						elemSpan.css("font-size","14px");
					}
				}
			};


			$timeout( function () {
				scope.fontLoadResize();
			}, 0 );


		}
	};
}

export default {
	type: 'directive',
	name: 'lvResizText',
	value: lvResizText
};