lvProgressBar.$inject = ['unLoader', '$interval'];
function lvProgressBar( unLoader, $interval ) {
	return {
		restrict: 'A',
		link: function ( scope, el, attr ) {
			if(el[0]._lvProgressBar){
				return
			}
			el[0]._lvProgressBar = true;
			scope.unLoader = unLoader;
			// var x = 0.1;
			// var y = 0;
			var perc;
			var interval;
			el.css('display', 'none');

			function start() {
				// x = 0;
				// y = 0;
				// el.css( 'width', 100 + '%' );
				el.css('display', 'block');
				interval = $interval( function () {
					// x += 0.14;
					// y = 1 / (x);
					// perc = 95 - (y * 10);
					// perc = Math.round( perc );
					//	console.log(perc);
					// el.css( 'width', perc + '%' );
					// if ( 100 <= perc ) {
						interval && $interval.cancel( interval );
					// }
				}, 100 );
			}

			var stopTime;
			function stop() {
				// el.css( 'width', 100 + '%' );
				interval && $interval.cancel( interval );
				// x = 0.4;
				// y = 0;
				stopTime && clearInterval(stopTime);
				stopTime = setTimeout(function(){
					el.css('display', 'none');
					// el.css( 'width', 100 + '%' );
					// el.css( 'width', 10 + '%' );
					// setTimeout(function(){
					// 	// el.css( 'width', 10 + '%' );
					// },200)

				}, 200)
			}

			scope.$watch( 'unLoader.isDoing', function ( val ) {
				if ( val ) {
					start();
				} else {
					stop();
				}
			} )
		}
	}
}

export default {
	type: 'directive',
	name: 'lvProgressBar',
	value: lvProgressBar
};