/**
 * Created by mars on 11/2/15.
 */
import angular from 'angular';


imgSrc.$inject = ['$compile', '$filter', 'serviceCropper', 'serviceDialog', '$timeout'];
function imgSrc($compile, $filter, serviceCropper, serviceDialog, $timeout) {
    function createElementAlert( parent, scope ) {
        parent.addClass( 'relative' );
        var template = "<div class='err-mess in-top animate-err-mess ' ng-if='alertMessage && imgSrc'>{{alertMessage}}</div>";
        var linkFn = $compile( template );
        var content = linkFn( scope );
        parent.append( content );
        scope.$on( '$destroy', function ( val ) {
            content.alertMessage = null;
            angular.element( content[0].nextSibling ).remove();
        } );
        return content;
    }

    return {
        require: ['?ngModel', '^containerImgLoad'],
        scope: {
            imgSrc: '=',
            prefix: '@',
            parentWidth: '@',
            validRatioSize: "=",
            validMessage: '@',
            maxSize: '@',
            valid:'@imgSrc',
            opts: '=',
            modelKey: '=',
            uploader: '=',
            minSize: '=',
            autoUpload: '='
        },
        link: function ( scope, el, attr, ctrl ) {
            const autoUpload = scope.autoUpload ?? true;

            let imgLoadContainer = ctrl[1];
            let ngModelCtrl = ctrl[0];

            if(el[0].dirSet){
                return;
            }
            el[0].dirSet = true;

            if ( attr.cropperNeeded !== undefined ) {
                serviceCropper.onCreate( scope );
            }

            scope.cropperIsNeeded = false;

            var _img;
            var translate = $filter('translate');
            var validRatioSize;
            var validMessage = eval( scope.validMessage );
            var maxSize = scope.maxSize ? parseInt( scope.maxSize ) : null;
            var receiptsLoader = attr.class.indexOf("receipts-loader") !== -1;
            var blackWhite = attr.class.indexOf("black-white") !== -1;
            var tooLarge;
            var imgDeleted = "";
            var loader = el[0].nextElementSibling && el[0].nextElementSibling.className.indexOf('mask-loader') !== -1 ? el[0].nextElementSibling : null;


            if ( scope.validRatioSize ) {
                validRatioSize = parseInt( scope.validRatioSize );
            }
            var imgForEven;
            if ( validRatioSize !== undefined && ngModelCtrl !== null ) {
                if ( validMessage ) {
                    new createElementAlert( el.parent(), scope, ngModelCtrl );
                }

                function checkForEven( value ) {
                    if(value !== 'unprocessed') {
                        if (value) {
                            if(!value.split(';')[0].match(/.(x-png|jpeg|png|gif|pjpeg|bmp|jpg)$/i) && !receiptsLoader) {
                                serviceDialog.add({
                                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                    title: translate('ERROR.upload_error'),
                                    content: translate('ERROR.uploaded_img_not_valid'),
                                    buttons: [
                                        {
                                            text: 'ON_OK',
                                            class: 'md-primary',
                                            action: function () {
                                                return true;
                                            }
                                        },
                                    ]
                                });
                            } else if (!value.split(';')[0].match(/.(x-png|jpeg|png|gif|pjpeg|bmp|jpg)$/i) && receiptsLoader) {
                                var oldValKey = scope.modelKey.indexOf(_) === 0 ? scope.modelKey.substr(1) + "Copy" : scope.modelKey + "Copy";
                                var oldVal = scope.$parent[oldValKey];
                                scope.imgSrc = oldVal !== null && oldVal.indexOf("http") === -1 ? oldVal : null;
                                console.log(scope.imgSrc);
                                serviceDialog.add({
                                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                    title: translate('ERROR.upload_error'),
                                    content: translate('ERROR.upload_error_text'),
                                    buttons: [
                                        {
                                            text: 'ON_OK',
                                            class: 'md-primary',
                                            action: function () {
                                                return true;
                                            }
                                        },
                                    ]
                                });
                            } else {
                                imgForEven = new Image();
                                imgForEven.onload = function () {
                                    imgForEven.style.display = 'block';
                                    var aspectRatio = this.naturalWidth / this.naturalHeight;
                                    var minDopusk = validRatioSize - 0.1;
                                    var maxDopusk = validRatioSize + 0.1;
                                    var valueBegins = value.indexOf(",") + 1;
                                    var base64str = value.substr(valueBegins);
                                    var decoded = atob(base64str);
                                    var size = decoded.length;
                                    if (!receiptsLoader) {
                                        if (this.naturalWidth < 16 || this.naturalHeight < 16 || size > 8388608) {
                                            scope.imgSrc = null;
                                            serviceDialog.add({
                                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                                title: translate('ERROR.upload_error'),
                                                content: translate('ERROR.uploaded_img_not_valid'),
                                                buttons: [
                                                    {
                                                        text: 'ON_OK',
                                                        class: 'md-primary',
                                                        action: function () {
                                                            return true;
                                                        }
                                                    },
                                                ]
                                            });
                                        } else if (maxSize < this.naturalWidth) {
                                            ngModelCtrl.$setValidity(scope.valid, false);
                                            el.parent().addClass("has-error");
                                            scope.cropperIsNeeded = true;
                                            scope.alertMessage = $filter('translate')('IMAGE_WIDTH_IS_MORE_THAN') + " " + maxSize + 'px. ' + $filter('translate')('TRIM_IMAGE');
                                        } else if (minDopusk < aspectRatio && aspectRatio < maxDopusk) {
                                            if (imgLoadContainer.isGifImg) {
                                                setImg();
                                            } else {
                                                ngModelCtrl.$setValidity(scope.valid, true);
                                                el.parent().removeClass("has-error");
                                                scope.cropperIsNeeded = false;
                                                scope.alertMessage = null;
                                                var file = scope.base64ToBlob(value, 'image/jpeg');
                                                scope.uploader.addToQueue(file);

                                                if (autoUpload) {
                                                    scope.uploader.uploadAll();
                                                }
                                                setImg();
                                            }
                                        } else {
                                            // Show cropper dialog
                                            scope.cropperIsNeeded = true;
                                        }

                                        scope.$apply();
                                    } else {

                                        if (size > 10485760) {
                                            serviceDialog.add({
                                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                                title: translate('ERROR.upload_error'),
                                                content: translate('ERROR.upload_error_text'),
                                                buttons: [
                                                    {
                                                        text: 'ON_OK',
                                                        class: 'md-primary',
                                                        action: function () {
                                                            return true;
                                                        }
                                                    },
                                                ]
                                            });
                                        } else {
                                            setImg();
                                        }

                                        tooLarge = (size > 10485760)
                                    }
                                };
                                imgForEven.src = value;
                            }
                        } else {
                            hideImg();
                            imgForEven && (imgForEven.style.display = 'none');
                            scope.cropperIsNeeded = false;
                            ngModelCtrl.$setValidity(scope.valid, true);
                        }
                        return value;
                    }
                }

                ngModelCtrl.$formatters.unshift( checkForEven );
                ngModelCtrl.$parsers.unshift( checkForEven );
            }


            var parentWidth = eval( scope.parentWidth );


            function hideImg() {
                if ( _img ) {
                    _img.style.display = 'none';
                }
            }


            function setImg() {
                if ( _img && _img.parentElement ) {
                    _img.parentElement.removeChild( _img );
                }

                if(receiptsLoader && imgDeleted === null) {
                    imgDeleted = "";
                    return;
                }

                _img = new Image();

                var mask = document.createElement( 'div' );
                if(!receiptsLoader) {
                    var t1 = document.createElement('div'), t2 = document.createElement('div'),
                        t3 = document.createElement('div');
                    mask.appendChild(t1);
                    t1.appendChild(t2);
                    t2.appendChild(t3);
                }
                mask.className = 'mask-preload';
                mask.style.position = 'absolute';
                if(receiptsLoader) {
                    mask.style.width = '100%';
                    mask.style.height = '100%';
                    mask.style.background = '#fff';
                }
                el[0].appendChild( mask );
                if(loader) {
                    loader.style.display = "block";

                }

                mask.className += " show";

                _img.onload = function () {
                    var aspectRatio;
                    if(receiptsLoader && !tooLarge) {
                        aspectRatio = _img.naturalWidth / _img.naturalHeight;
                        var biggerThanMax = maxSize < _img.naturalWidth || maxSize < _img.naturalHeight;
                        var loadedImg = _img.src.indexOf("http") === -1;
                        if(biggerThanMax || (blackWhite && loadedImg)) {
                            var imgProceeded, imgProceededWidth, imgProceededHeight;
                            var canvas = document.createElement('canvas');
                            if (maxSize < _img.naturalWidth && aspectRatio > 1) {
                                imgProceededWidth = maxSize;
                                imgProceededHeight = parseInt((maxSize * _img.naturalHeight / _img.naturalWidth).toFixed(0));
                            } else if (maxSize < _img.naturalHeight && aspectRatio < 1) {
                                imgProceededHeight = maxSize;
                                imgProceededWidth = parseInt((maxSize * _img.naturalWidth / _img.naturalHeight).toFixed(0));
                            } else if(biggerThanMax) {
                                imgProceededWidth = imgProceededHeight = maxSize;
                            } else {
                                imgProceededWidth = _img.naturalWidth;
                                imgProceededHeight = _img.naturalHeight;
                            }
                            canvas.width = imgProceededWidth;
                            canvas.height = imgProceededHeight;
                            var ctx = canvas.getContext('2d');
                            ctx.drawImage(imgForEven, 0, 0, imgProceededWidth, imgProceededHeight);
                            if(blackWhite) {
                                var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
                                var middle;
                                var totalBright = 0;
                                var points = [];
                                for (var i = 0, length = imgPixels.data.length; i < length; i += 4){
                                    points.push((imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2])/3);
                                }
                                for (var i = 0; i < points.length; i++){
                                    totalBright += points[i];
                                }
                                let averageBright = totalBright / points.length;
                                points.sort((a,b)=> a - b);
                                let middleBright = (points[0] + points[points.length - 1]) / 2;
                                middle = (middleBright + averageBright) / 2;

                                for (var i = 0, n = imgPixels.data.length; i < n; i += 4) {
                                    // var grayscale = imgPixels.data[i] * .3 + imgPixels.data[i+1] * .59 + imgPixels.data[i+2] * .11;
                                    var transparent = imgPixels.data[i] === 0 && imgPixels.data[i + 1] === 0 && imgPixels.data[i + 2] === 0 && imgPixels.data[i + 3] === 0;
                                    var color = 0;
                                    if(((imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2])/3) > middle || transparent) {
                                        color = 255;
                                    }
                                    imgPixels.data[i] = color;        // red
                                    imgPixels.data[i + 1] = color;        // green
                                    imgPixels.data[i + 2] = color;        // blue
                                    imgPixels.data[i + 3] = 255;
                                }

                                ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
                            }
                            imgProceeded = canvas.toDataURL();
                            if(_img.src !== imgProceeded) {
                                _img.src = imgProceeded;
                            }
                        }
                    } else if(tooLarge) {
                        var oldValKey = scope.modelKey.indexOf(_) === 0 ? scope.modelKey.substr(1) + "Copy" : scope.modelKey + "Copy";
                        var oldVal = scope.$parent[oldValKey];
                        if(_img.src !== oldVal && oldVal !== null) {
                            _img.src = oldVal;
                        } else if(oldVal === null) {
                            _img.remove();
                        }
                        if(scope.$parent[scope.opts][scope.modelKey] !== oldVal) {
                            scope.$parent[scope.opts][scope.modelKey] = oldVal !== null && oldVal.indexOf("http") === -1 ? oldVal : "unprocessed";
                        }
                        tooLarge = false;
                        if(oldVal === null) {
                            el[0].removeChild( mask );
                            return;
                        }
                    } else if (imgLoadContainer.isGifImg) {
                        var canvas = document.createElement('canvas');
                        canvas.width = _img.naturalWidth;
                        canvas.height = _img.naturalHeight;
                        var ctx = canvas.getContext( '2d' );
                        ctx.drawImage( imgForEven, 0, 0, _img.naturalWidth, _img.naturalHeight );
                        let b64 = canvas.toDataURL();
                        if(scope.imgSrc !== b64) {
                            scope.$applyAsync(() => {
                                scope.imgSrc = b64;
                                imgLoadContainer.isGifImg = false;
                            });
                        }
                    }

                    var eljs = el[0];
                    if ( eljs.childNodes.length ) {
                        for ( var i = 0; i < eljs.childNodes.length; i++ ) {
                            if ( eljs.childNodes[i] != mask ) {
                                eljs.removeChild( eljs.childNodes[i] );
                            }
                        }
                    }
                    if ( parentWidth ) {
                        function getStyle( oElm, strCssRule ) {
                            var strValue = "";
                            if ( strCssRule == "padding" ) {
                                strCssRule = "padding-left"
                            }
                            if ( document.defaultView && document.defaultView.getComputedStyle ) {
                                strValue = document.defaultView.getComputedStyle( oElm, "" ).getPropertyValue( strCssRule );
                            }
                            else if ( oElm.currentStyle ) {
                                strCssRule = strCssRule.replace( /\-(\w)/g, function ( strMatch, p1 ) {
                                    return p1.toUpperCase();
                                } );
                                strValue = oElm.currentStyle[strCssRule];
                            }
                            return strValue;
                        }

                        el.css( 'width', el.parent()[0].offsetWidth - 2 * parseInt( getStyle( el.parent()[0], 'padding' ) ) + 'px' )
                    }
                    while (el[0].firstChild) {
                        el[0].removeChild(el[0].firstChild);
                    }

                    var stringShow = mask.className;
                    stringShow = stringShow.replace( 'show', '' );
                    mask.className = stringShow;

                    el[0].appendChild(_img);
                    if(receiptsLoader) {
                        var file = scope.base64ToBlob(_img.src, 'image/png');
                        scope.uploader.addToQueue(file);

                    }

                    setTimeout( function () {
                        try {
                            el[0].removeChild( mask );

                        } catch ( err ) {

                        }
                    }, 1000 );
                };
                _img.onerror = function () {
                    var k = scope.imgSrc;
                    scope.imgSrc = 'data:image/jpeg;base64,' + k;
                    el[0].removeChild( mask );

                };
                if ( 1000 < scope.imgSrc.toString().length ) {
                    if ( !/data:image/.test( scope.imgSrc ) ) {
                        var k = scope.imgSrc;
                        scope.imgSrc = 'data:image/jpeg;base64,' + k;
                        el[0].removeChild( mask );

                    } else {
                        _img.src = scope.imgSrc;
                    }
                } else {
                    _img.src = scope.prefix ? '' + scope.prefix + scope.imgSrc : scope.imgSrc
                }
            }

            scope.base64ToBlob = function (base64Data, contentType) {
                contentType = contentType || '';
                var sliceSize = 1024;
                var byteCharacters = atob(base64Data.substr(base64Data.indexOf(",") + 1));
                var bytesLength = byteCharacters.length;
                var slicesCount = Math.ceil(bytesLength / sliceSize);
                var byteArrays = new Array(slicesCount);

                for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                    var begin = sliceIndex * sliceSize;
                    var end = Math.min(begin + sliceSize, bytesLength);

                    var bytes = new Array(end - begin);
                    for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
                        bytes[i] = byteCharacters[offset].charCodeAt(0);
                    }
                    byteArrays[sliceIndex] = new Uint8Array(bytes);
                }
                return new Blob(byteArrays, { type: contentType });
            };

        }
    }
}

export default imgSrc;
