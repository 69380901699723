appFilereader.$inject = ['$q', 'serviceDialog', '$filter'];

function appFilereader($q, serviceDialog, $filter) {

    var slice = Array.prototype.slice;

    return {

        restrict: 'A',
        require: ['?ngModel', '?^containerImgLoad'],
        link: function ( scope, element, attrs, ctrl ) {
            let ngModel = ctrl[0];
            let containerImgLoad = ctrl[1];
            if ( !ngModel ) return;

            ngModel.$render = function () {

            };
            scope.$watch( function(){
                return ngModel.$viewValue
            }, function( val, oldVal){
                if(oldVal &&  val!=oldVal){
                    element.val('')
                }
            });

            function a(e){
                var _element = element[0];
                var translate = $filter('translate');

                $q.all( slice.call( _element.files, 0 ).map( readFile ) )
                    .then( function ( values ) {
                        if ( _element.multiple ) ngModel.$setViewValue( values );
                        else ngModel.$setViewValue( values.length ? values[0] : null );
                    } );

                function readFile( file ) {
                    if (file.type.match(/.(gif)$/i) && containerImgLoad)
                        containerImgLoad.isGifImg = true;
                    var deferred = $q.defer();

                    var reader = new FileReader();
                    reader.onload = function (e) {
                        deferred.resolve(e.target.result);
                    };
                    reader.onerror = function (e) {
                        deferred.reject(e);
                    };
                    reader.readAsDataURL(file);

                    return deferred.promise;
                }
            }

            element.bind( 'change', a ); //change
        }
    };
}

export default appFilereader;