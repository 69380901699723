linkUtil.$inject = ['$translate', 'siteLangCode'];
function linkUtil($translate, siteLangCode) {

    this.getLearnMoreLink = getLearnMoreLink;

    const linkStart = 'https://help.loyverse.com';
    const linkEnd = 'help/advanced-inventory-management';
    const linkLangPartDefault = ['ms', 'ro'];

    function getLearnMoreLink() {
        const langLinkPart = siteLangCode[$translate.use()];
        if (langLinkPart && !linkLangPartDefault.includes(langLinkPart)) {
            return linkStart + '/' + langLinkPart + '/' + linkEnd;
        }
        return linkStart + '/' + linkEnd;
    }
}

export default {
    type: 'service',
    name: 'linkUtil',
    value: linkUtil
};
