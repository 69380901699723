/**
 * Created by mars on 10/16/15.
 */

import angular from 'angular';

profileControl.$inject = [
	'$scope',
	'$rootScope',
	'constantGroupTypeOwner',
	'$state',
	'$stateParams',
	'serviceOwnerInfo',
	'factoryProfile',
	'serviceToast',
	'$timeout',
	'serviceDialog',
	'$filter',
	'constantLangCollection',
	'serviceLang',
	'$translate',
	'localStorageService',
	'$profile',
	'$window',
	'serviceMenu',
	'timeZones',
	'getKitchen',
	'$updatePermissions',
	'retrieveLangCode',
	'$staffInfo',
	'getEmployees',
	'getDining',
	'siteLangCode',
	'constReplaceFont',
	'$setSignForShowPosBuner',
	'getPermits',
	'lvMixpanel',
	'lvFeatureSettingsUtils'
];

function profileControl(
	$scope,
	$rootScope,
	constantGroupTypeOwner,
	$state,
	$stateParams,
	serviceOwnerInfo,
	factoryProfile,
	serviceToast,
	$timeout,
	serviceDialog,
	$filter,
	constantLangCollection,
	serviceLang,
	$translate,
	localStorageService,
	$profile,
	$window,
	serviceMenu,
	timeZones,
	getKitchen,
	$updatePermissions,
	retrieveLangCode,
	$staffInfo,
	getEmployees,
	getDining,
	siteLangCode,
	constReplaceFont,
	$setSignForShowPosBuner,
	getPermits,
	lvMixpanel,
	lvFeatureSettingsUtils
) {

	let translate = $filter('translate');
	let brandName = 'loyverse';


	$scope.types = constantGroupTypeOwner;
	$scope.constantLangCollection = constantLangCollection;
	$scope.timeZones = timeZones.slice();
	$scope.showLowStockNotification = serviceMenu.showLowStockNotification;
	$scope.staffInfo = $staffInfo;
	$scope.owner = {};


	// console.log("$scope.staffInfo = ", $scope.staffInfo);
	if ($staffInfo !== false) {
		$staffInfo.merchants.some(function (person) {
			if (person.roleType === 'OWNER') {
				$scope.owner = person;
			}
		});
	}


	$scope.pin = {value: ['', '', '', '']};


	// временное решение - нужно доработать и вынести в директиву.
	if ($window.innerWidth > 480) {
		$scope.top = 'top';
		$scope.mouseenter = 'mouseenter';
	} else {
		$scope.top = 'right';
		$scope.mouseenter = 'click';
	}

	// var cabinetLang;

	localStorageService.get('lang');

	let isSaved = false;

	let offStateChangeStart;

	$scope.profile = angular.copy($profile);
	let initProfile = angular.copy($profile);
	let hasPin = $profile.hasPin;


	resetFeaturesModel($profile);

	$scope.featureEditorOptions = {
		cabinetLang: $profile.cabinetLang,
		permitsForFiscalPrinters: $updatePermissions.includes("ACCESS_FISCAL"),
		permitsForTimeTracking: $updatePermissions.includes("ACCESS_MERCHANTS"),
		specialWeightIcon: ($profile.country === "us" || $profile.country === "lr" || $profile.country === "mm")
	};

	_init();

	/*The function changes the meaning of the style for body when comparing
	the cabinet language with the languages from the list*/
	function replaceFontBody(val) {
		var elem = document.getElementsByTagName('body')[0];
		var cond = constReplaceFont.indexOf(val) !== -1;
		if (cond) {
			elem.style.fontFamily = "Noto Sans, sans-serif";
		} else {
			elem.style.fontFamily = "Roboto, 'Helvetica Neue', sans-serif";
		}
	}


	/*The function changes the meaning of the style for tags then selected languages comparing
	the cabinet lang*/
	function replaceFontTags(val) {
		var allElem = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
		var cond = constReplaceFont.indexOf(val) !== -1;
		if (cond) {
			for (var i = 0; i < allElem.length; i++) {
				allElem[i].style.fontFamily = "Noto Sans, sans-serif";
			}
		} else {
			for (var i = 0; i < allElem.length; i++) {
				allElem[i].style.fontFamily = "Roboto, 'Helvetica Neue', sans-serif";
			}
		}
	}


	function _init() {
		offStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, _toState, _toParam, fromSate, fromParam) {
			let newProfile = angular.extend({}, initProfile, $scope.featuresModel);
			if (angular.equals(initProfile, newProfile)) {
				offStateChangeStart();
			} else {
				event.preventDefault();
				$timeout(function () {
					serviceDialog.add({
						templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
						title: $filter('translate')('UNSAVED_TITLE'),
						content: $filter('translate')('UNSAVED_LEAVE'),
						buttons: [

							{
								text: 'UNSAVED_CONTINUE',
								action: function () {

								}
							},
							{
								class: 'primary',
								text: 'UNSAVED_DISCARD',
								action: function () {
									offStateChangeStart();
									$state.go(_toState, _toParam);
								}
							}
						]
					});
				});
			}
		});
	}


	$scope.onSave = function () {

		approvePinCode();

		function setPin() {
			const toastTitle = 'EMPLOYEE.PIN_SAVED';
			if ($scope.owner) {
				const params = { pin: $scope.pin.value.join('') };
				getEmployees.setOwnerPin(params).then((res) => {
					if (res.result === "ok") {
						hasPin = true;
						serviceToast.show(toastTitle, 'success');
					}
				}).then(() => {
					shiftsFiscalsDependency();
				});
			}
		}


		function setProfile() {
			let newProfile = angular.extend({}, initProfile, $scope.featuresModel)
			factoryProfile.setProfile(newProfile).then(function (d) {
				if (angular.isObject(d)) {
					if (d.result === 'ok') {
						lvFeatureSettingsUtils.trackFeatureSettingsEdit(initProfile, newProfile, false);
						getPermits.getPermitsList({}).then(function (res) {
							serviceMenu.setAccess(res);
						});
						serviceToast.show('SETTING_PROFILE_CHANGE_SUCCESS', 'success');
						isSaved = true;
						initProfile = angular.copy(newProfile);
						serviceMenu.showCashBox(newProfile.shiftsEnabled);
						serviceMenu.showTimeTracking(newProfile.timeCardEnabled);
						serviceMenu.showOpenReceipts(newProfile);
						serviceMenu.showKitchenPrinyer(newProfile);
						serviceMenu.showDiningOptins(newProfile);
						serviceMenu.helpCenterLang(newProfile);
						serviceMenu.isSupportAvailable(newProfile);

						$state.go($state.current.name, {reload: true});
					} else if (d.result === "error" && d.message === "cannot_enable_fiscal_without_shifts") {
						showFiscalWithoutShiftsWarning();
					}
				} else {
					serviceToast.show('ERROR_SAVE', 'error');
				}
			});
		}


		function shiftsFiscalsDependency() {
			if (initProfile.shiftsEnabled && !$scope.featuresModel.shiftsEnabled && $scope.featuresModel.fiscalEnable) {
				showFiscalWithoutShiftsWarning();
			} else {
				if (!initProfile.fiscalEnable && $scope.featuresModel.fiscalEnable && !$scope.featuresModel.shiftsEnabled) {
					$scope.featuresModel.shiftsEnabled = true;
				}
				offOptionsOnSettings();
			}
		}

		function offOptionsOnSettings() {
			// статус свича кухонного принтера: включен, выключен или не менялся
			var kitchenPrinterOn = !initProfile.useKitchenPrinter && $scope.featuresModel.useKitchenPrinter,
				kitchenPrinterOff = initProfile.useKitchenPrinter && !$scope.featuresModel.useKitchenPrinter,
				kitchenPrinterUntouched = initProfile.useKitchenPrinter === $scope.featuresModel.useKitchenPrinter;

			// статус свича дисплея покупателя: включен, выключен или не менялся
			var customerDisplayOn = !initProfile.useCustomerDisplay && $scope.featuresModel.useCustomerDisplay,
				customerDisplayOff = initProfile.useCustomerDisplay && !$scope.featuresModel.useCustomerDisplay,
				customerDisplayUntouched = initProfile.useCustomerDisplay === $scope.featuresModel.useCustomerDisplay;

			// статус свича типов заказа: включен, выключен или не менялся
			var diningOptionsOn = !initProfile.useDiningOptions && $scope.featuresModel.useDiningOptions,
				diningOptionsOff = initProfile.useDiningOptions && !$scope.featuresModel.useDiningOptions,
				diningOptionsUntouched = initProfile.useDiningOptions === $scope.featuresModel.useDiningOptions;

			var message;

			// ОТКЛЮЧАЕМ ТОЛЬКО КУХОННЫЕ ПРИНТЕРЫ
			if (kitchenPrinterOff && (customerDisplayUntouched || customerDisplayOn) && (diningOptionsUntouched || diningOptionsOn)) {
				message = {
					title: translate('KITCHEN.DELETE_KITCHEN_PRINTERS_TITLE'),
					content: translate('KITCHEN.DELETE_KITCHEN_PRINTERS_TEXT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ ТОЛЬКО ДИСПЛЕИ ПОКУПАТЕЛЯ
			} else if (customerDisplayOff && (kitchenPrinterUntouched || kitchenPrinterOn) && (diningOptionsUntouched || diningOptionsOn)) {
				message = {
					title: translate('CUSTOMER_DISP.CUSTOMER_DISP_OFF_TITLE'),
					content: translate('CUSTOMER_DISP.CUSTOMER_DISP_OFF_CONTENT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ ТОЛЬКО ТИПЫ ЗАКАЗОВ
			} else if (diningOptionsOff && (kitchenPrinterUntouched || kitchenPrinterOn) && (customerDisplayUntouched || customerDisplayOn)) {
				message = {
					title: translate('DINING.DINING_OPTIONS_OFF_TITLE'),
					content: translate('DINING.DINING_OPTIONS_OFF_CONTENT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ КУХОННЫЕ ПРИНТЕРЫ !!!!! И !!!!! ДИСПЛЕИ ПОКУПАТЕЛЯ
			} else if (customerDisplayOff && kitchenPrinterOff && (diningOptionsUntouched || diningOptionsOn)) {
				message = {
					title: '',
					content: translate('CUSTOMER_DISP.CUSTOMER_DISP_AND_KITCHEN_PRINT_OFF_CONTENT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ КУХОННЫЕ ПРИНТЕРЫ !!!!! И !!!!! ТИПЫ ЗАКАЗОВ
			} else if (diningOptionsOff && kitchenPrinterOff && (customerDisplayUntouched || customerDisplayOn)) {
				message = {
					title: '',
					content: translate('DINING.DINING_OPTIONS_AND_KITCHEN_PRINT_OFF_CONTENT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ ДИСПЛЕИ ПОКУПАТЕЛЯ !!!!! И !!!!! ТИПЫ ЗАКАЗОВ
			} else if (customerDisplayOff && diningOptionsOff && (kitchenPrinterUntouched || kitchenPrinterOn)) {
				message = {
					title: '',
					content: translate('DINING.DINING_OPTIONS_AND_CUSTOMER_DISP_OFF_CONTENT')
				};
				showErrorAlert(message);
				// ОТКЛЮЧАЕМ КУХОННЫЕ ПРИНТЕРЫ !!!!! И !!!!! ДИСПЛЕИ ПОКУПАТЕЛЯ !!!!! И !!!!! ТИПЫ ЗАКАЗОВ
			} else if (customerDisplayOff && kitchenPrinterOff && diningOptionsOff) {
				message = {
					title: '',
					content: translate('DINING.DINING_OPTIONS_AND_CUSTOMER_DISP_AND_KITCHEN_PRINT_OFF')
				};
				showErrorAlert(message);
			} else {
				save();
			}
		}


		function approvePinCode() {
			if ($scope.featuresModel.timeCardEnabled && ($staffInfo && ($staffInfo.merchants.length === 1)) && $scope.owner && !hasPin) {
				serviceDialog.add({
					templateUrl: 'dashboard-page/templates/dialog/dialog-pin.html',
					title: translate('EMPLOYEE.SET_PIN'),
					content: translate('EMPLOYEE.SET_PIN_CLOCK'),
					pin: $scope.pin,
					buttons: [
						{
							text: 'ON_CANCEL',
							action: function () {
								$scope.featuresModel = angular.copy($scope.featuresModel);
								$scope.featuresModel.timeCardEnabled = false;
								$scope.pin.value = ['', '', '', ''];
								shiftsFiscalsDependency();
								return true;
							}
						},
						{
							class: 'primary',
							text: 'CONTINUE',
							action: function () {
								setPin();
							}
						}
					]
				});
			} else {
				shiftsFiscalsDependency();
			}
		}

		function save() {
			if (initProfile.useKitchenPrinter && !$scope.featuresModel.useKitchenPrinter) {
				getKitchen.deleteKitchen({
					printerIds: [],
					deleteAll: true
				}).then(function (res) {
					if (res.result === "ok") {
					}
				});
			}

			// удаляем все опции оформления заказа, если выключили свичер
			if (initProfile.useDiningOptions && !$scope.featuresModel.useDiningOptions) {
				getDining.deleteDiningOll({deleteAll: true})
					.then(function (res) {
						if (res.result === "ok") {
						}
					});
			}
			setProfile();
		}


		function showErrorAlert(message) {
			serviceDialog.add({
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: message.title,
				content: message.content,
				buttons: [{
					text: 'ON_CANCEL',
					action: function () {
						restoreDefault()
					}
				},
					{
						class: 'primary',
						text: 'KITCHEN.UNLOCK_KITCHEN_PRINTERS',
						action: save
					}
				]
			});
		}

		function showFiscalWithoutShiftsWarning() {
			serviceDialog.add({
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate('FISCAL_TURN_OFF'),
				content: translate('FISCAL_TURN_OFF_MESAGE'),
				buttons: [{
					class: 'primary',
					text: 'ON_OK',
					action: function () {
						$scope.featuresModel.shiftsEnabled = true;
						offOptionsOnSettings();
					}
				}
				]
			});
		}

		function restoreDefault() {
			$scope.featuresModel = angular.copy($scope.featuresModel);
			$scope.featuresModel.useKitchenPrinter = initProfile.useKitchenPrinter;
			$scope.featuresModel.useCustomerDisplay = initProfile.useCustomerDisplay;
			$scope.featuresModel.useDiningOptions = initProfile.useDiningOptions;
			setProfile();
		}


		if (!$scope.permits.editProfile) {
			serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
		}

	};


	$scope.onCancel = function () {
		factoryProfile.getProfile(true).then(function (_profile) {
			resetFeaturesModel(_profile);
			initProfile = angular.copy(_profile);
		});
	};

	function resetFeaturesModel(profileModel) {
		$scope.featuresModel = {
			shiftsEnabled: profileModel.shiftsEnabled,
			timeCardEnabled: profileModel.timeCardEnabled,
			useOpenedReceipts: profileModel.useOpenedReceipts,
			fiscalEnable: profileModel.fiscalEnable,
			useKitchenPrinter: profileModel.useKitchenPrinter,
			useCustomerDisplay: profileModel.useCustomerDisplay,
			useDiningOptions: profileModel.useDiningOptions,
			notifyCritical: profileModel.notifyCritical,
			posInventoryAlertEnabled: profileModel.posInventoryAlertEnabled,
			weightBarcode: profileModel.weightBarcode
		};
	}

	$scope.weightEmbeddedBarcodesHelp = function () {
		lvMixpanel.track('Help', {"Property": "Weight embedded barcodes"});
	};
}

export default {
	type: 'controller',
	name: 'profileControl',
	value: profileControl
};
