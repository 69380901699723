/**
 * Created by A.Sirosh on 08.10.2015.
 * @memberof createOutlet
 * @ngdoc factory
 * @name createOutlet
 * @param $http {service} http servise
 * @param $q {service} promise servise
 * @param $location {service} location servise
 * @returns createOutletData {Promise} outlets and merchants
 */

import angular from 'angular';

createOutlet.$inject = ["$http", "$q", 'factoryErrorAlert'];

function createOutlet($http, $q, factoryErrorAlert) {

	function success(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === "ok") {
				return response.data;
			} else {
				return $q.reject(response.data);
			}
		} else {
			return $q.reject(response.data);
		}
	}

	function createOutletData(params) {
		return $http.post('/data/ownercab/createoutlet', params).then(success, factoryErrorAlert.onError);
	}

	return {
		createOutletData: createOutletData
	};

}

export default {
	type: 'factory',
	name: 'createOutlet',
	value: createOutlet
};