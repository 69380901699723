createDirective.$inject = [];

function createDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, ngModelCtrl) {
        if (!ngModelCtrl) {
            return;
        }

        attrs.$observe('disabled', function(isDisabled) {
            if (isDisabled) {
                ngModelCtrl.suspendValidation();
            } else {
                ngModelCtrl.restoreValidation();
            }
        });
    }
}

export default {
    type: 'directive',
    name: 'lvSuspendValidationForDisabled',
    value: createDirective
};