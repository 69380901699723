infoLabelExact.$inject = [];
function infoLabelExact() {
    return {
        restrict: 'C',
        require: 'ngModel',
        link: function ( $scope, $element, attr, ngModel ) {
            $scope.$watch( function () {
                return ngModel.$viewValue
            }, function ( val, oldVal ) {
                $element.on('blur', function (e) {
                    if (val != oldVal) {
                        if (val && ngModel.$valid) {
                            $scope.$parent.setDateValue('exact')
                        }
                    }
                })
            } )
        }
    }
}

export default infoLabelExact;