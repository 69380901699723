serviceSessions.$inject = ["$translate", "serviceDialog"];

function serviceSessions($translate, serviceDialog) {
  const key = "authentication:id";

  this.id = null;
  this.interval = null;

  this.onLogin = (profile) => {
    this.id = profile.email;
    localStorage.setItem(key, profile.email);

    if (!this.interval) {
      this.interval = setInterval(() => {
        const stored = localStorage.getItem(key, this.id);
        if (this.id !== stored) {
          serviceDialog.add({
            templateUrl: "dashboard-page/templates/dialog/dialog-base.html",
            title: $translate.instant("SESSION_EXPIRED"),
            content: $translate.instant("SIGNED_IN_TO_ANOTHER_ACCOUNT"),
            buttons: [
              {
                class: "primary",
                text: "REFRESH",
                action: () => {
                  this.id = stored;
                  window.location.assign("/");
                },
              },
            ],
          });
        }
      }, 1000);
    }
  };

  this.onLogout = () => {
    clearInterval(this.interval);
  };
}

export default {
  type: "service",
  name: "serviceSessions",
  value: serviceSessions,
};
