/**
 * Created by Alex on 15.12.2016.
 * @memberof divideByThousand
 * @ngdoc filter
 * @name divideByThousand
 */

function divideByThousand() {
    function format(val) {

        if (val !== undefined && val !== null) {
            var result;
            result = val/1000;
            return result;
        }
    }

    return format;

};

export default {
    type: 'filter',
    name: 'divideByThousand',
    value: divideByThousand
};