/**
 * Minimum and maximum values for fields
 * Created by Shestakov on 9.03.22
 */

const constantMinMaxValues = {
    currency: {
        maxEditCoins: 999999.99,
        maxEditNoCoins: 99999999,
        minEditCoins: 0.00,
        minEditNoCoins: 0,
        minEditModifCoins: -999999.99,
        minEditModifNoCoins: -99999999,
        minShowCoins: -9999999999999999.99,
        maxShowNoCoins: 9999999999999999,
        maxShowCoins: 9999999999999999.99,
        minShowNoCoins: -9999999999999999
    },
    count: {
        minEdit: -9999999.999,
        maxEdit: 9999999.999,
        minOrder: 0.000,
        maxOrder: 999999.999,
        minLabels: 0,
        maxLabels: 9999,
        minShow: -99999999999999.999,
        maxShow: 99999999999999.999
    },
    hours: {
        min: -99999999999999.99,
        max: 99999999999999.99
    },
    percent: {
        min: 0,
        max: 100,
        minInfinity: -9999999999999.99,
        maxInfinity: 9999999999999.99
    }
}

export default {
    type: 'constant',
    name: 'constantMinMaxValues',
    value: constantMinMaxValues
}
