createDirective.$inject = ['InputContainerStateManager'];

function createDirective(InputContainerStateManager) {
    return {
        restrict: 'A',
        require: ['?ngModel', '?lvInputContainerAdapter'],
        compile: compileDirective
    }

    function compileDirective(tElem) {
        tElem.addClass('lv-underlined-input');
        return linkDirective;
    }

    function linkDirective(scope, elem, attrs, ctrls) {
        const ngModelCtrl = ctrls[0];
        const inputContainerAdapterCtrl = ctrls[1];

        const stateManager = new InputContainerStateManager(elem, attrs, ngModelCtrl,
            inputContainerAdapterCtrl);

        elem.bind('focus', onElementFocus).bind('blur', onElementBlur);

        function onElementFocus() {
            stateManager.setFocused(true);
        }

        function onElementBlur() {
            stateManager.setFocused(false);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvUnderlinedInput',
    value: createDirective
};