serviceLang.$inject = ['langCode', '$q', '$rootScope', '$translate', 'localStorageService', 'constantLangCollection'];

function serviceLang(langCode, $q, $rootScope, $translate, localStorageService, constantLangCollection) {

	let defaultLang = 'eng';

	const tryDecode = (val) => {
		val === 'ua' && (val = 'uk');
		val === 'kr' && (val = 'ko');
		val === 'jp' && (val = 'ja');
		for (let opt in langCode) {
			if (val === langCode[opt]) {
				return opt;
			}
		}
		return defaultLang;
	}

	const getSystemLang2 = () => window.navigator.userLanguage || window.navigator.language;

	const from3to2 = (key) => {
		switch (key) {
			case 'ukr':
				return 'ua';
			case 'kau':
				return 'ko';
			//we use portuguese language for Brazil branch of loyverse.com, so we need to redirect user to brazilian
			//branch
			case 'por':
				return 'br';
			default :
				return langCode[key] || 'en';
		}
	};

	const getLocalStorageLang = () => {
		let lang = localStorageService.get('lang');
		if ( !lang ) {
			return defaultLang;
		}
		if (lang && lang.length < 3) {
			lang = tryDecode(lang);
		}
		return lang || defaultLang;
	};

	const getSystemLang3 = () => tryDecode(getSystemLang2());

	const langs = {};
	const arrDefer = [];

	$rootScope.$on( '$translateChangeSuccess', ( e, val ) => {
		if ( arrDefer.length ) {
			do {
				onResolve(arrDefer.splice(arrDefer.length - 1, 1)[0]);
			} while (arrDefer.length);
		}
		langs[val.language] = true;
	});

	const onResolve = (obj) => {
		if (obj instanceof Resolve){
			obj.resolve();
		} else {
			$translate(obj.value).then( function (val) {
				obj.defer.resolve(val)
			}, function(err){
				obj.defer.resolve(obj.value);
			});
		}
	}
	const translate = (val) => {
		if (langs[$translate.use()] && $translate.use() !== 'empty') {
			return $translate(val);
		} else {
			const defer = $q.defer();
			const obj = {
				defer: defer,
				value: val
			};
			arrDefer.push(obj);

			return defer.promise;
		}
	}

	class Resolve {
		constructor() {
			this._resolve = null;
		}
		setResolve(resolve) {
			this._resolve = resolve;
		}
		resolve(){
			this._resolve();
		}
	}

	const onLoad = () => {
		if (langs[$translate.use()] && $translate.use() !== 'empty') {
			return $q(function (resolve) {
				resolve();
			});
		} else {
			let resolveInstance;
			try {
				resolveInstance = new Resolve();
			} catch (err){
				console.log(err);
			}
			const promiseF = (resolve) => {
				resolveInstance.setResolve(resolve);
			}
			arrDefer.push(resolveInstance);

			return $q(promiseF);
		}
	}

	// getting language for pages of login form app, containing language switcher, and for error page in that app
	const getLang = (val) => {
		let lang = val || localStorageService.get('lang');
		if (lang) {
			if (lang.length < 3) {
				lang = tryDecode(lang);
			}
			lang = ((l) => {
				const entry = constantLangCollection.find((langEntry) => langEntry.key === l);
				return entry ? entry.key : defaultLang;
			})(lang);

		} else {
			lang = getSystemLang3();
		}

		return lang;
	}

	this.onLoad = onLoad;
	this.translate = translate;
	this.tryDecode = tryDecode;
	this.getSystemLang2 = getSystemLang2;
	this.from3to2 = from3to2;
	this.getLocalStorageLang = getLocalStorageLang;
	this.getSystemLang3 = getSystemLang3;
	this.getLang = getLang;
	this.defaultLang = defaultLang;

}

export default serviceLang;
