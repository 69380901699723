/**
 * Created by Kiskenbassker on 10.08.18
 * Соответствие языков бэкофиса языкам в url сайта loyverse.com
 */

const siteLangCode = {
    fra: "fr",
    ind: "idn",
    jpn: "jp",
    kor: "ko",
    msa: "ms",
    por: "br",
    ron: "ro",
    rus: "ru",
    spa: "es",
    tha: "th",
    vie: "vn",
    ara: "ar",
    deu: "de",
    pol: "pl",
    ita: "it",
    zho: "zh",
    ell: "gr"
};

export default {
    type: 'constant',
    name: 'siteLangCode',
    value: siteLangCode
};