import angular from 'angular';
import calendarDaysTemplate from '../templates-pug/calendar-days.pug';

daysweek.$inject = ['$compile', '$rootScope', 'constantCalendar', 'serviceDaysWeek', '$timeout'];

function daysweek($compile, $rootScope, constantCalendar, serviceDaysWeek, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            scope: {},
            template: calendarDaysTemplate,
            link: function ( $scope, $element, $attr, $contrl ) {
                $scope.days = [];
                $scope.weekStartsWith = $rootScope.startWeek;

                angular.forEach( constantCalendar.days, function ( day, i ) {
                    $scope.days.push( {
                        text: day,
                        n: (i === 6) ? 0 : i + 1
                    } )
                } );

                $scope.$applyAsync(() => {
                    let weekDaysWidth = 0;
                    let daysElements = $element.find(".weekDay");
                    angular.forEach(daysElements, elem => {
                        weekDaysWidth += elem.offsetWidth;
                    });
                    angular.element(document.querySelector(".perid-points")).css({width: weekDaysWidth + "px"});
                });

                $timeout(function() {

                }, 0);
            },
            controller: ['$scope', function ( $scope ) {

            }]
    }
}

export default daysweek;
