/**
 * Created by mars on 9/11/15.
 */

import angular from 'angular';

lvCheckbox.$inject = [];

function lvCheckbox() {
	return {
		restrict: 'A',
		replace: true,
		require: ['?ngModel', '?^lvReadonly'],
		template: '<div class="lv-checkbox"><i class="material-icons notranslate">check</i></div>',

		link: function ( scope, el, attr, ctrls, $watch) {
			// console.log("el = ", el);
			// console.log("attr = ", attr);
			// console.log("ctrls = ", ctrls);
			// console.log("scope.ware.allowedForSale = ", scope.ware.allowedForSale);

			var getReadonly = ctrls[1] ? ctrls[1].getReadOnly : null;
			var ctrl = ctrls[0];
			var onAction = false;
			var timeout;
			var readonly = attr.readonly;

			attr.$observe( 'class', function ( val ) {
				if(el.hasClass( 'disabled' )){
					el.off( 'click', select )
				}else{
					if ( !readonly )
						el.on( 'click', select );
				}
			} );


// делаем дисейбл для чекбоксов в карточке создания и редактирования товара
			if(attr.ollForSale){
				attr.$observe( 'ollForSale', function (ollForSale) {
					// console.log("ollForSale = ", ollForSale);
					// console.log("typeof ollForSale = ", typeof(ollForSale));
					if ( ollForSale == 'true' ) {
						// console.log(" ollForSale == true");
						el.addClass( 'disabled' );
						attr.disabled = 'disabled';
						el.off( 'click', select );
					} else {
						// console.log(" ollForSale == false");
						el.removeClass( 'disabled' );
						attr.disabled = '';
						el.on( 'click', select );
					}
				});
			}
// делаем дисейбл для чекбоксов в карточке создания и редактирования товара




			function select( event ) {
				event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true);
				if ( onAction ) return;
				if ( getReadonly && getReadonly() ) {
					return
				}
				ctrl.$setViewValue( !ctrl.$modelValue );
				ctrl.$render();
			}

			var blink = angular.element( document.createElement( 'div' ) );
			blink.attr( 'class', 'checker-blink' );
			blink.css( 'background', '#FFF' );
			blink.css( 'width', '0' );
			blink.css( 'height', '0' );
			blink.css( 'position', 'absolute' );
			blink.css( 'left', '7px' );
			blink.css( 'top', '7px' );
			blink.css( 'opacity', '1' );
			blink.css( 'borderRadius', '50%' );

			function checkForEven( value ) {
				onAction = true;
				el.append( blink );
				blink.css( 'width', '0' );
				blink.css( 'height', '0' );
				blink.css( 'position', 'absolute' );
				blink.css( 'left', '8px' );
				blink.css( 'top', '8px' );
				blink.css( 'opacity', '1' );
				blink.css( 'boxShadow', '0 0 0 rgba(0,0,0,0.1)' );


				setTimeout( function () {
					blink.css( 'width', '50px' );
					blink.css( 'height', '50px' );
					blink.css( 'left', '-19px' );
					blink.css( 'top', '-19px' );
					blink.css( 'opacity', '0' );
					blink.css( 'boxShadow', '0 0 5px rgba(0,0,0,0.8)' );
					setTimeout( function () {
						blink.remove()
					}, 300 );
				}, 1 );


				if ( timeout ) clearTimeout( timeout );
				timeout = setTimeout( function () {
					onAction = false;
				}, 200 );

				if ( value ) {
					el.addClass( 'checked' )
				} else {
					el.removeClass( 'checked' )
				}
				ctrl.$setViewValue( value );
				ctrl.$render();
				return value
			}

			if ( attr.lvCheckbox == 'false' ) {
				// console.log("xa-xa-xa = "); // !!!!!!!!!!!!!!!!!
				// el.addClass( 'disabled' );  //!!!!!!!!!!!!!!!!
			} else {
				if ( !readonly )
					el.on( 'click', select );
			}




			/**срабатывает при старте*/
			ctrl.$formatters.push( checkForEven );
			/**срабатывает при редактировании*/
			ctrl.$parsers.push( checkForEven );

			el.on( '$destroy', function () {
				el.off( 'click', select )
			} );
		}
	}
}

export default {
	type: 'directive',
	name: 'lvCheckbox',
	value: lvCheckbox
};