import datePickerLabelTemplate from '../templates-pug/date-picker-label-template.pug';

datePickerLabel.$inject = ['$templateCache', 'datePickerSettings'];

function datePickerLabel($templateCache, datePickerSettings) {
    return {
        restrict: 'C',
        template: datePickerSettings.config.datePickerLabelTemplate || datePickerLabelTemplate
        // TODO: Oleksii: use template instead $templateCache
        // template: datePickerSettings.config.datePickerLabelTemplate || $templateCache.get('../module/lv-date-picker/templates/date-picker-label-template.html')
        // template: $templateCache.get('../module/lv-date-picker/templates/date-picker-label-template.html')
    }
}

export default datePickerLabel;