import angular from 'angular';

adjustDetails.$inject = [
    '$scope',
    '$state',
    '$critCountOptionsEdit',
    '$currencyOptionsShow',
    '$adjustmentDetail',
    '$stateParams',
    'lodash',
    'resolveTimezone',
    'lvMixpanel',
    '$permitsManager'
];

function adjustDetails(
    $scope,
    $state,
    $critCountOptionsEdit,
    $currencyOptionsShow,
    $adjustmentDetail,
    $stateParams,
    lodash,
    resolveTimezone,
    lvMixpanel,
    $permitsManager
) {
    var vm = this;
    $scope.displayCost = $permitsManager.costAllowed;
    $scope.currencyOptionsShow = $currencyOptionsShow;
    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    $scope.tzOffset = resolveTimezone.timeZoneOffsetForReports();

    // данные для сохранение состояния фильтров
    $scope.stateFromList = $stateParams.stateToSave;

    $scope.onBack = function() {
        // $state.go( 'inventory.adjustment', {});
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go( 'inventory.adjustment', {page:           0,
                                                limit:          10} );
        } else {
            $state.go( 'inventory.adjustment', {page:           $scope.stateFromList.page,
                                                limit:          $scope.stateFromList.limit,
                                                outletId:       $scope.stateFromList.outletId,
                                                reason:         $scope.stateFromList.reason} );
        }
    }

    $scope.showMoreFunk = function() {
        if(angular.element('.drop-buttons-menu').hasClass('open')){
            angular.element('.drop-buttons-menu').addClass('close');
            angular.element('.drop-buttons-menu').removeClass('open');
        }else{
            angular.element('.drop-buttons-menu').removeClass('close');
            angular.element('.drop-buttons-menu').addClass('open');
        }
    }

    document.addEventListener('click', function(e){
        if(!angular.element(e.target).hasClass('button')) {
            angular.element('.drop-buttons-menu').addClass('close');
            angular.element('.drop-buttons-menu').removeClass('open');
        }
    });

    vm.adjustment = angular.copy($adjustmentDetail);

    $scope.adjustment = vm.adjustment.adjustData;
    $scope.itemsData = vm.adjustment.items;

    $scope.goPrintLabelsStockAdjustment = function () {
        //Triggered when users visit the “Create labels” page by clicking on
        // the “Print labels” button in the “ Stock adjustment details” page.
        lvMixpanel.track('Back office page visit', {
            "Property": "Inventory management/Create labels for stock adjustment"
        });
        $state.go('inventory.printlabels', {orderId: $stateParams.id, orderType: "STOCK_ADJUSTMENT"});
    };

    $scope.savePdfForMixpanel = function () {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save stock adjustment as PDF"
        });
    };

    $scope.saveCsvForMixpanel = function () {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save stock adjustment as CSV"
        });
    };
}

export default {
    type: 'controller',
    name: 'adjustDetails',
    value: adjustDetails
};
