createClass.$inject = [];

function createClass() {
    return class TableViewColumnBase {
        constructor(title, options) {
            this.title = title;
            this.options = options;
        }

        getElement() {
            const baseElement = this.getBaseElement();
            this.addClass(baseElement);
            this.setAttrs(baseElement);
            return baseElement;
        }

        getStyle() {
            const result = {};
            result['flex-grow'] = this.options.width ? 0 : 1
            if (this.options.width) {
                result['width'] = this.getSize().width + 'px';
                result['min-width'] = this.options.width + 'px';
            }
            if (this.options.minWidth) {
                result['min-width'] = this.options.minWidth + 'px';
            }

            return result;
        }

        getSize() {
            // This method should be defined in table-view header.
            return {};
        }

        getBaseElement() {
            return null;
        }

        addClass(element) {
            if (this.options.class) {
                element.addClass(this.options.class);
            }
        }

        setAttrs(element) {

            if(this.options.name) {
                element.attr('id', '{{ctrl.column.options.name}}_{{ctrl.itemIndex}}');
            }

            if (this.options.attrs) {
                for (let key in this.options.attrs) {
                    element.attr(key, this.options.attrs[key]);
                }
            }

            if (this.options.referenceAttrs) {
                for (let key in this.options.referenceAttrs) {
                    if (angular.isFunction(this.options.referenceAttrs[key])) {
                        element.attr(key, "ctrl.column.options.referenceAttrs['" + key + "'](ctrl.item)")
                    } else {
                        element.attr(key, "ctrl.column.options.referenceAttrs['" + key + "']");
                    }
                }
            }

            if (this.options.isVisible) {
                element.attr('ng-if', 'ctrl.column.options.isVisible(ctrl.item)')
            }
            if (this.options.isDisabled) {
                element.attr('ng-disabled', 'ctrl.column.options.isDisabled(ctrl.item)');
            }
            if (this.options.isRequired) {
                element.attr('ng-required', 'ctrl.column.options.isRequired(ctrl.item)');
            }
            if (this.options.buildClasses) {
                element.attr('ng-class', 'ctrl.column.options.buildClasses(ctrl.item)');
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'TableViewColumnBase',
    value: createClass
};
