deployNavControl.$inject = ['$scope', 'serviceNavBoard', '$state'];
function deployNavControl($scope, serviceNavBoard, $state) {

	this.serviceNavBoard = serviceNavBoard;

	this.collapseChildren = function(menu){
		if(menu.uiSref){
			var arr = menu.uiSref.match(/([^\)]+)\((.*)\)/);
			if(arr !== null) {
				var name = arr[1];
				var parm = arr[2];
			} else {
				var name = menu.uiSref;
			}
			var objParm = {};
			if(parm){
				parm = parm.replace(/[ ]/g, "");
				var a = parm.split(',');
				for(var i = 0; i< a.length; i++){
					objParm[a[i].split(':')[0].replace(/[{}]/, '')] = a[i].split(':')[1].replace(/[{}]/, '');
				}
			}
			$state.go(name, objParm);
			this.serviceNavBoard.deployedNav = null;
		}else{
			menu.childrenShow = !menu.childrenShow;
		}
	}
	this.closeDep = function(){
		this.serviceNavBoard.deployedNav = null;
	}

}

export default {
	type: 'controller',
	name: 'deployNavControl',
	value: deployNavControl
};