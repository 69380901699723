import angular from 'angular';

factoryAdjustment.$inject = ['promiseOperation'];

function factoryAdjustment(promiseOperation) {

    function getShortOutlets(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getshortoutlets',
            params,
            successGetShortOutlets
        );
    }

    function successGetShortOutlets(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.outlets;
            }
        }
    }

    function searchItemsForAdjustmentOrder(params) {
        if (params.search) {
            // Remove control symbols and left-to-right mark
            params.search = params.search.replace(/[\u0000-\u001F\u007F-\u009F\u200E]/g, "")
        }
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/searchItemsForAdjustmentOrder',
            params,
            successSearchItemsForAdjustmentOrder
        );
    }

    function successSearchItemsForAdjustmentOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function getAdjustmentOrdersList(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getAdjustmentOrdersList',
            params,
            successGetAdjustmentOrdersList
        );
    }

    function successGetAdjustmentOrdersList(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getAdjustmentOrderDetails(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/getAdjustmentOrderDetails',
            params,
            successGetAdjustmentOrderDetails
        );
    }

    function successGetAdjustmentOrderDetails(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function createAdjustmentOrder(params) {
        return promiseOperation
            .getPromiseHTTP(
            'POST',
            '/data/ownercab/createAdjustmentOrder',
            params,
            successCreateAdjustmentOrder
        );
    }

    function successCreateAdjustmentOrder(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getAdjustmentOrderItemsForOutlet(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getAdjustmentOrderItemsForOutlet',
            params,
            successGetAdjustmentOrderItemsForOutlet
        );
    }

    function successGetAdjustmentOrderItemsForOutlet(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    return {
        getShortOutlets: getShortOutlets,
        searchItemsForAdjustmentOrder: searchItemsForAdjustmentOrder,
        getAdjustmentOrdersList: getAdjustmentOrdersList,
        getAdjustmentOrderDetails: getAdjustmentOrderDetails,
        createAdjustmentOrder: createAdjustmentOrder,
        getAdjustmentOrderItemsForOutlet: getAdjustmentOrderItemsForOutlet
    }
}

export default {
    type: 'factory',
    name: 'factoryAdjustment',
    value: factoryAdjustment
};