domUtils.$inject = ['factoryDOM', '$state'];
function domUtils(factoryDOM, $state) {

    /**
     * @name observeFocusState
     * @desc Observe focus and blur events on element.
     * @param {Element} The element to be observe.
     * @param {Function} focus event callback.
     * @param {Function} blur event callback.
     * @return {Boolean}
     */
    this.observeFocusState = observeFocusState;

    /**
     * @name isElementOverflowed
     * @desc Returns true if the element's content is overflowed.
     * @param {Element} The element to be checked.
     * @return {Boolean}
     */
    this.isElementOverflowed = isElementOverflowed;

    function observeFocusState(element, focusFn, blurFn) {

        element[0].addEventListener('focus', focusHandler, true);
        element[0].addEventListener('blur', blurHandler, true);

        function focusHandler(event) {
            if (focusFn) {
                focusFn(event);
            }
        }

        function blurHandler(event) {
            if (blurFn) {
                blurFn(event);
            }
        }

        return function() {
            element[0].removeEventListener('focus', focusHandler, true);
            element[0].removeEventListener('blur', blurHandler, true);
        }
    }

    function isElementOverflowed(element) {
        if (element.length === 0)
        {
            return false;
        }
        return element[0].clientWidth < element[0].scrollWidth;
    }

    this.size = function () {
        return document.body.getBoundingClientRect();
      };

    /**
     * @name removeBackdrop
     * @desc Remove manually Angular Material backdrop and scroll mask elements after menu close.
     * @param {Object} stateParams - The state parameters to be passed to $state.go.
     */
    this.removeBackdrop = (stateParams) => {
        const { missing, wait, remove } = factoryDOM;

        wait(() => missing("md-backdrop")).then(() => {
            $state.go($state.current.name, stateParams, {reload: true});

            setTimeout(() => {
                if (missing(".md-open-menu-container")) {
                    remove("md-backdrop");
                    remove(".md-scroll-mask");
                }
            }, 100);
        });
    }
}

export default {
    type: 'service',
    name: 'domUtils',
    value: domUtils
};
