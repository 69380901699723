/**
 * Created by mars on 11/11/15.
 */

factoryPermits.$inject = ['$http', '$q', 'factoryIsManager'];
function factoryPermits($http, $q, factoryIsManager) {
	var permits ={
		editProfile: false
	};

	factoryIsManager().then(function(d){
		if(d===false){
			permits.editProfile = true;
		}

		permits.showExportBtn = true;
	});

	return permits;
}

export default {
	type: 'factory',
	name: 'factoryPermits',
	value: factoryPermits
};