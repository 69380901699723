/**
 * Created by IvanSche by 30.09.2019
 *
 * Языки в которых нужно pаменить шрифт Roboto на Noto Sans:
 Greek
 Georgian
 Urdu
 Arabic
 Hindi
 Bengali
 Thai
 Lao
 Korean
 Japanese
 Chinese traditional

 CSS: .const-replace-font
 */

const constReplaceFont = [
    "ell",
    "gre",
    "geo",
    "kat",
    "urd",
    "ara",
    "hin",
    "ben",
    "tha",
    "lao",
    "kor",
    "jpn",
    "chi",
    "zho",
    "chn"
];

export default {
    type: 'constant',
    name: 'constReplaceFont',
    value: constReplaceFont
};