/**
 * Created by A.Sirosh on 13.10.2015.
 * @memberof toggleClass
 * @ngdoc directive
 * @name toggleClass
 * @requires setChartPeriod {service} Set chart label for different periods
 * @requires $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 */

toggleClass.$inject = ['setChartPeriod', '$stateParams', '$state'];
function toggleClass(setChartPeriod, $stateParams, $state) {
	return {
		restrict: 'A',
		/**
		 *
		 * @param scope
		 * @param element
		 * @param attrs
		 */
		link: function (scope, element, attrs) {

			// btnTool group list
			var buttonsList = [
				setChartPeriod.setButtonPeriod('today'),
				setChartPeriod.setButtonPeriod('yesterday'),
				setChartPeriod.setButtonPeriod('week'),
				setChartPeriod.setButtonPeriod('month')
			];

			var buttonsListLen = buttonsList.length;
			var i;
			var stateStr = $state.current.name;


			// follow the changes in the calendarPeriod parameters
			scope.$watchCollection('calendarPeriod', function(newVal, oldVal){

				for(i = 0; i < buttonsListLen; i++ ) {
					if (buttonsList[i].from == newVal.from.getTime() && buttonsList[i].to == newVal.to.getTime()) {
						element.removeClass(attrs.toggleClass);
						$('#' + buttonsList[i].divider).addClass(attrs.toggleClass);
						$stateParams.from = buttonsList[i].from;
						$stateParams.to = buttonsList[i].to;
						$stateParams.group = buttonsList[i].group;
						if(buttonsList[i].divider == "today"){
							break;
						} else if(buttonsList[i].divider == "week"){
							break;
						}
				}
				}

			});



			// click on btn in btnTool group
				element.on('click', attrs.child,  function(){
					if ($(this).hasClass(attrs.toggleClass) === true) {
						return false;
					}

					element.children(attrs.child).removeClass(attrs.toggleClass);

					$(this).addClass(attrs.toggleClass);

					var divider = $(this).attr('id');

					var c = setChartPeriod.setButtonPeriod(divider);

					$stateParams.from = c.from;

					$stateParams.to = c.to;
					$stateParams.group = c.group;
					$stateParams.page = c.page;

					$state.go(stateStr, $stateParams, {
						reload: true
					});
				});
				i = null;
		}
	};
}

export default {
	type: 'directive',
	name: 'toggleClass',
	value: toggleClass
};