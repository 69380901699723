/**
 * Created by olehkss on 12.05.16.
 *
 * @ngdoc directive
 * @name app.directive:calendarTop
 * @scope
 * @restrict E
 * @description
 * Adds calendar and four buttons(today, yesterday, week, month) to the report page.
 * Uses $stateParams and $state objects in order to reload page with new information.
 * @example
 * <calendar-top></calendar-top>
 */

import template from '../templates-pug/for-directive/calendar-top.pug';
import angular from "angular";

CalendarTopDirective.$inject = ['$stateParams', '$state', 'filtersData', 'changeDivider', 'getChartLabel', 'lvMixpanel'];

function CalendarTopDirective($stateParams, $state, filtersData, changeDivider, getChartLabel, lvMixpanel) {

    return {
        restrict: 'E',
        controller: controller,
        link: link,
        scope: {
            from: '=',
            to: '=',
            unlimitedSalesHistory: '<?'
        },
        controllerAs: 'vmCalendarTop',
        template: template
    };

    function link(scope, element, attrs, vm) {
        vm.calendarAction = calendarAction;
        vm.previousPeriod = previousPeriod;
        vm.nextPeriod = nextPeriod;
        scope.reportShift = scope.$parent.reportShift;
        scope.employeesTimecards = scope.$parent.employeesTimecards;
        scope.inventoryHistory = scope.$parent.inventoryHistory;
        var body = document.querySelector('body');
        scope.topButtonsForArab = body.classList.contains('aranav');

        var stateParams;



        /**
         * @ngdoc function
         * @param {Date} from the start of time period
         * @param {Date} to the end of time period
         * @description
         * After selection of the time period via calendar this function will be triggered. It have been passed as a
         * parameter to calendar directive
         */
        function calendarAction(from, to, exact) {
            var fromString = new Date(from.getFullYear(), from.getMonth(), from.getDate());
            var toString = new Date(to.getFullYear(), to.getMonth(), to.getDate()+1, 0, 0, -1);
            if (( $stateParams.from !== fromString) || ( $stateParams.to !== toString)) {
                var savedStateParams = filtersData.getStateParams();
                $stateParams.page = 0;
                $stateParams.from = getChartLabel.formatDate(fromString, true);
                $stateParams.to = getChartLabel.formatDate(toString, false);

                $stateParams.group = changeDivider.changeDividerLabel();
                $stateParams.periodName = null;
                $stateParams.arg = null;
                $stateParams.serverChartType = savedStateParams.serverChartType;

                var resolveFromDate = new Date(from.getFullYear(), from.getMonth(), from.getDate()).getTime();
                var resolveToDate = new Date(to.getFullYear(), to.getMonth(), to.getDate()).getTime();

                // var fromTZOffset = from.getTimezoneOffset() * 60 *1000;
                // var toTZOffset = to.getTimezoneOffset() * 60 * 1000;
                // if(toTZOffset !== fromTZOffset) {
                //     resolveToDate = resolveToDate - (toTZOffset - fromTZOffset);
                // }

                $stateParams.periodLength = parseInt((resolveToDate - resolveFromDate)/(1000*3600*24)) + 1 + "d";
                $stateParams.receiptId = null;

                stateParams = filtersData.setStateParams($stateParams);

                if($state.current.name === 'goods.itemHistory' || $state.current.name === 'inventory.history') {
                    lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Custom"});
                    lvMixpanel.track('Filter', {"Duration of custom date range": $stateParams.periodLength});
                } else {
                    lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Custom"});
                    lvMixpanel.track('Filter', {"Duration of custom date range": $stateParams.periodLength});
                }

                $state.go($state.current.name, stateParams, {
                    reload: true
                });
            }

        }

        function previousPeriod(from) {
            var diffarence;
            var fromString;
            var savedStateParams = filtersData.getStateParams();
            var toString = getChartLabel.formatDate(new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, -1), false);
            $stateParams.to = toString;
            $stateParams.receiptId = null;
            $stateParams.serverChartType = savedStateParams.serverChartType;
            if($stateParams.periodLength.indexOf("d") > -1) {
                diffarence = parseFloat($stateParams.periodLength.replace("d", ''));
                fromString = new Date(from.getFullYear(), from.getMonth(), from.getDate() - diffarence);
                $stateParams.from = getChartLabel.formatDate(fromString, true);
            } else if($stateParams.periodLength.indexOf("m") > -1) {
                diffarence = parseFloat($stateParams.periodLength.replace("m", ''));
                fromString = new Date(from.getFullYear(), from.getMonth() - diffarence, 1);
                $stateParams.from = getChartLabel.formatDate(fromString, true);
            }
            if($stateParams.periodName !== "month") {
                $stateParams.periodName = null;
                $stateParams.arg = null;
            } else {
                $stateParams.arg = moment($stateParams.from).format("YYYY-MM-DD");
            }
            if(new Date($stateParams.from) < new Date("Jan 1 2000 00:00:00")) {
                $stateParams.from = getChartLabel.formatDate("Jan 1 2000", true);
            }
            var stateParams = filtersData.setStateParams($stateParams);
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Previous date range"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Previous date range"});
            }
            $state.go($state.current.name, stateParams, {
                reload: true
            });
            // console.log("Иду назад");
        }

        function nextPeriod(to) {
            var diffarence;
            var toString;
            var savedStateParams = filtersData.getStateParams();
            $stateParams.from = getChartLabel.formatDate(new Date(to.getFullYear(), to.getMonth(), to.getDate() + 1), true);
            $stateParams.receiptId = null;
            $stateParams.serverChartType = savedStateParams.serverChartType;
            if($stateParams.periodLength.indexOf("d") > -1) {
                diffarence = parseFloat($stateParams.periodLength.replace("d", ''));
                toString = new Date(to.getFullYear(), to.getMonth(), to.getDate() + diffarence + 1, 0, 0, -1);
                $stateParams.to = getChartLabel.formatDate(toString, false);
            } else if($stateParams.periodLength.indexOf("m") > -1) {
                diffarence = parseFloat($stateParams.periodLength.replace("m", ''));
                toString = new Date(to.getFullYear(), to.getMonth() + diffarence +1, 1, 0, 0, -1);
                $stateParams.to = getChartLabel.formatDate(toString, false);
            }
            if(new Date($stateParams.to) > new Date()) {
                var date = new Date();
                toString = new Date( date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, -1 );
                $stateParams.to = getChartLabel.formatDate(toString, false);
            }
            if($stateParams.periodName !== "month") {
                $stateParams.periodName = null;
                $stateParams.arg = null;
            } else {
                $stateParams.arg = moment($stateParams.to).format("YYYY-MM-DD");
            }

            var stateParams = filtersData.setStateParams($stateParams);
            if($state.current.name === 'inventory.history') {
                lvMixpanel.track('Filter', {"Property": "Inventory history/Select date range/Next date range"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Select date range/Next date range"});
            }
            $state.go($state.current.name, stateParams, {
                reload: true
            });
        }
    }
}

controller.$inject = ['$scope', '$stateParams'];
function controller($scope, $stateParams) {
    let vm = this;

    this.$onInit = function() {
        let salesHistoryEnabled = angular.isDefined($scope.unlimitedSalesHistory) ? $scope.unlimitedSalesHistory.enabled : true;

        vm.dateTo = new Date($scope.to);
        vm.dateFrom = new Date($scope.from);
        vm.dateMax = new Date();
        vm.dateMin = salesHistoryEnabled ? new Date("Jan 1 2000 00:00:00") : new Date(vm.dateMax - (60*60*1000*24*31));
        vm.periodLength = $stateParams.periodLength;
        vm.templateView = ($stateParams.periodLength === "1d") || (($scope.to - $scope.from) < 86400000) ? "labelTemplateForDay" :
            $stateParams.periodName === "month" ? "labelTemplateForMonth" : "labelTemplate";
        vm.noNext = $scope.to + 1000 > new Date( vm.dateMax.getFullYear(), vm.dateMax.getMonth(), vm.dateMax.getDate()).getTime();
        vm.noPrev = $scope.from - 1000 < vm.dateMin.getTime();
        vm.noPredefined = false;

        if (angular.isDefined($scope.unlimitedSalesHistory)) {
            vm.unlimitedSalesHistory = $scope.unlimitedSalesHistory;
        }
    }
}

export default {
    type: 'directive',
    name: 'calendarTop',
    value: CalendarTopDirective
};
