/*** Created by Alex 16.06.17 ***/

import angular from 'angular';
import {SubscriptionRenewData} from "./components/billing-subcriptions-comp";
import {Urls} from "../../../service/service-billing-common-methods";

payMethodControllerBt.$inject = [
        "$scope",
        "$state",
        "$stateParams",
        "$filter",
        '$token',
        "lvCountries",
        "factoryBilling",
        "serviceDialog",
        "$timeout",
        "getEmployees",
        "serviceToast",
        "lvCountriesES",
        "lodash",
        "$profile",
        "$minAccountInfo",
        "serviceBillingMixpanel",
        "serviceBillingError",
        "serviceBillingCommonMethods",
        "vcRecaptchaService",
        "serviceReCaptcha",
        "constBillingKnownErrors",
        "lvMixpanel",
        "dialogs"];
function payMethodControllerBt($scope,
          $state,
          $stateParams,
          $filter,
          $token,
          lvCountries,
          factoryBilling,
          serviceDialog,
          $timeout,
          getEmployees,
          serviceToast,
          lvCountriesES,
          lodash,
          $profile,
          $minAccountInfo,
          serviceBillingMixpanel,
          serviceBillingError,
          serviceBillingCommonMethods,
          vcRecaptchaService,
          serviceReCaptcha,
          constBillingKnownErrors,
          lvMixpanel,
           dialogs) {


    // БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ
    let translate               = $filter('translate');
    $scope.profile              = $profile;
    $scope.minAccountInfo       = $minAccountInfo;
    $scope.newUser              = $stateParams.newUser;
    $scope.trialStatus          = $stateParams.trialStatus;
    $scope.addSubscribe         = $stateParams.addSubscribe;
    $scope.typeSubscriptions    = $stateParams.typeSubscriptions;
    $scope.activate             = $stateParams.activate;
    $scope.couponCode           = $stateParams.coupon;
    $scope.countries            = angular.copy(lvCountries);
    $scope.countriesES          = angular.copy(lvCountriesES);
    $scope.isVAT                = false;
    $scope.data                 = { country: "", vat: ""};
    $scope.countryChangeable = $scope.minAccountInfo.countryChangeable;
    $scope.excludedCountries = $scope.minAccountInfo.excludedCountries;
    $scope.subscriptionId = $stateParams.subscriptionId;
    let employeeStat = $scope.minAccountInfo.subscriptions.EMPSTORE
        ? $scope.minAccountInfo.subscriptions.EMPSTORE.status
        : $scope.minAccountInfo.subscriptions.EMPLOYEE.status;
    let inventoryStat = $scope.minAccountInfo.subscriptions.INVENTORY.status;
    let integrationStat = $scope.minAccountInfo.subscriptions.INTEGRATION.status;
    let salesHistoryStat = $scope.minAccountInfo.subscriptions.SALESHISTORY.status;
    const knownErrors             = angular.copy(constBillingKnownErrors);
    const urls = new Urls($stateParams.urlForReload, $stateParams.urlForSuccessReload);
    let initData = null;

    // для рекаптчи
    let useRecaptcha = false;
    let recaptchaInfo = {
        key: '6Lc5I6YUAAAAAAbtpKBX9XK6zEk14wToFRvbcpkB',
        response: null,
        widgetId: null,
        verify: true
    };
    let recaptchaDialog = {
        templateUrl:    'dashboard-page/templates/dialog/dialog-recaptcha.html',
        title: 'ACCOUNT.B2_SERVICE_DIALOG.B2_RECAPTCHA_TITLE',
        recaptchaInfo: recaptchaInfo,
        setResponse: setResponse,
        setWidgetId: setWidgetId,
        cbExpiration: cbExpiration,
        submit: verifyReCaptchaOnSubmit,
        lang: serviceReCaptcha.getLangForReCaptcha($scope.profile.cabinetLang),
        buttons: [
            {
                text: 'ON_CANCEL',
                action: function () {
                    angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                }
            },
            {
                class: 'md-primary',
                text: 'CONTINUE_CONTINUE'
            }
        ]
    };
    // для рекаптчи

    const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {
        return !angular.equals(initData, $scope.data)
    }

    // БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ




    // функция для показа или не показа блока НАЛОГА в зависимости от страны
    $scope.selectedCountrieValue = function (kode) {
        for (let i = 0; i < $scope.countriesES.length; i++) {
            if (kode === $scope.countriesES[i].code || kode === "gb") {
                $scope.isVAT = true;
                $scope.data.vat = $token.vat;
                return;
            }
            else {
                $scope.isVAT = false;
                $scope.data.vat = "";
            }
        }
    };
    // функция для показа или не показа блока НАЛОГА в зависимости от страны



    // функция получения/присваивания кода страны
    $scope.getCountry = function () {
        if ($token.billingCountry !== null) {
            $scope.selectedCountrieValue($token.billingCountry);
            return $token.billingCountry;
        } else {
            return "";
        }
    };
    // функция получения/присваивания кода страны



    // данные - страна и налог(если ЕС)
    $scope.data = { country:            $scope.getCountry(),
                        vat:            serviceBillingCommonMethods.getVat($token),
                    typeBillingPayment: $scope.profile.billingInfo.gw};
    initData = angular.copy($scope.data);
    // данные - страна и налог(если ЕС)



    // показ сервисного диалога при приходе ошибки с БРЕЙНТРИ
    function showErrorAlert(message) {
        if (lodash.has(message, 'details') && message.code === "HOSTED_FIELDS_FIELDS_INVALID") {
            $scope.fildsForTextError = [];
            for (let i = 0; i < message.details.invalidFieldKeys.length; i++) {
                message.details.invalidFieldKeys[i] === "cvv" ? $scope.fildsForTextError.push(translate('ACCOUNT.CVV')) : '';
                message.details.invalidFieldKeys[i] === "expirationDate" ? $scope.fildsForTextError.push(translate('ACCOUNT.EXPIRATION_DATE')) : '';
                message.details.invalidFieldKeys[i] === "number" ? $scope.fildsForTextError.push(translate('ACCOUNT.CARD_NUMBER')) : '';
            }
            $scope.textIfError = translate('ACCOUNT.' + message.code) + $scope.fildsForTextError;
        }
        else {
            $scope.textIfError = translate('ACCOUNT.' + message.code);
        }

        $timeout(function () {
            serviceDialog.add({
                templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                class:          'billing-base-384',
                content1:        $scope.textIfError,
                buttons:        [{ class: 'primary',
                                    text: 'ON_OK' }]
            });
        }, 0);
    }
    // показ сервисного диалога при приходе ошибки с БРЕЙНТРИ



    // инициализация, получение ТОКЕНА и его отправка на сервер при submit
    function initBraintreeClient(token) {

        braintree.client.create({
            authorization: token
        }, function (err, clientInstance) {
            if (err) {
                console.error(err);
                return;
            }

            braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                    'input': {
                        'font-size': '14px',
                        'font-family': 'Roboto, "Helvetica Neue", sans-serif',
                        'color': 'rgba(0, 0, 0, .87)',
                        'line-height': '23px'
                    },
                    'input::placeholder': {
                        'color': 'rgba(0, 0, 0, .38)',
                        'text-align': document.querySelector('body').classList.contains('aranav') ? 'right' : 'left'
                    },
                    ':focus': {
                        'color': 'rgba(0, 0, 0, .87)'
                    },
                    '.valid': {
                        'color': 'rgba(0, 0, 0, .87)'
                    },
                    '.invalid': {
                        'color': 'rgba(0, 0, 0, .87)'
                    }
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        maxlength: 16
                    },
                    cvv: {
                        selector: '#cvv',
                        maxlength: 4
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: translate('ACCOUNT.MM_YY')
                    }
                }
            }, function (err, hostedFieldsInstance) {
                if (err) {
                    return;
                }

                hostedFieldsInstance.on('focus', function (event) {
                    let field = event.fields[event.emittedBy];
                    let container = angular.element(field.container);

                    if(!field.isEmpty && !field.isValid && container.hasClass('braintree-hosted-fields-focused')) {
                        container.removeClass('braintree-hosted-fields-focused').addClass('braintree-hosted-fields-invalid');
                    }
                });

                // Emulates floating label pattern
                hostedFieldsInstance.on('blur', function (event) {
                    let field = event.fields[event.emittedBy];
                    let container = angular.element(field.container);

                    if(!field.isEmpty && !field.isValid && !container.hasClass('braintree-hosted-fields-invalid')) {
                        container.addClass('braintree-hosted-fields-invalid');
                    }
                });

                hostedFieldsInstance.on('empty', function (event) {
                    let container = angular.element(event.fields[event.emittedBy].container);

                    if(container.hasClass('braintree-hosted-fields-invalid')) {
                        container.removeClass('braintree-hosted-fields-invalid');
                    }
                });

                hostedFieldsInstance.on('validityChange', function (event) {
                    let field = event.fields[event.emittedBy];
                    let container = angular.element(field.container);

                    if (field.isValid && container.hasClass('braintree-hosted-fields-invalid')) {
                        container.removeClass('braintree-hosted-fields-invalid');
                    } else if (!field.isValid) {
                        container.addClass('braintree-hosted-fields-invalid');
                    }
                });

                $('#cardForm').submit(function (event) {
                    angular.element(document.querySelector('#btn_main')).attr("disabled", "disabled");

                    event.preventDefault();

                    if ($scope.data.country === "") {
                        angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                    } else if (useRecaptcha) {
                        recaptchaDialog.hostedFieldsInstance = hostedFieldsInstance;
                        recaptchaDialog.clientInstance = clientInstance;
                        serviceDialog.add(recaptchaDialog)
                    } else {
                        submitPaymentInfo(hostedFieldsInstance, clientInstance);
                    }
                });
            });
        });
    }
    // инициализация, получение ТОКЕНА и его отправка на сервер при submit




    // запуск работы системы Braintree
    initBraintreeClient($token.token);
    // запуск работы системы Braintree




    // уход с этой страницы
    $scope.onCancel = function () {
        serviceBillingMixpanel.setMixpanelBillingOnCancelInPaymentMethod($scope.typeSubscriptions, employeeStat, inventoryStat, integrationStat, salesHistoryStat);

        serviceBillingCommonMethods.onCancel($scope.newUser, $scope.addSubscribe, $scope.subscriptionId, urls.urlForReload);

        if ($scope.activate) {
            lvMixpanel.track("Billing", {Value: "Cancel (Add payment method)"});
        }
    };
    // уход с этой страницы


    // submit payment info
    function submitPaymentInfo(hostedFieldsInstance, clientInstance) {
        if ($scope.isVAT && $scope.data.vat.length > 0) {
            let vatInfo = {vat: $scope.data.vat, country: $scope.data.country}
            factoryBilling.verifyVat(vatInfo).then(function (res) {
                if (res.result === 'ok') {
                    if (res.valid) {
                        tokenizeHostedFields(hostedFieldsInstance, clientInstance);
                    } else {
                        serviceBillingError.knownError("vat_invalid");
                        angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                        if ($scope.activate) {
                            lvMixpanel.track("Billing", {Value: "Fail"});
                        }
                    }
                }
            });
        } else {
            tokenizeHostedFields(hostedFieldsInstance, clientInstance);
        }
    }
    // submit payment info


    // подвязка карты Braintree
    function tokenizeHostedFields(hostedFieldsInstance, clientInstance) {
        hostedFieldsInstance.tokenize(function (err, payload) {
            if (err) {
                showErrorAlert(err);
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                useRecaptcha = true;
                if ($scope.activate) {
                    lvMixpanel.track("Billing", {Value: "Fail"});
                }
            } else {
                checkThreeDSecure(payload, clientInstance);
            }
        });
    }
    // подвязка карты Braintree


    // Методы для рекаптчи
    function setResponse(response) {
        recaptchaInfo.response = response;
    }

    function setWidgetId (widgetId) {
        recaptchaInfo.widgetId = widgetId;
    }

    function cbExpiration() {
        vcRecaptchaService.reload(recaptchaInfo.widgetId);
        recaptchaInfo.response = null;
    }
    // Методы для рекаптчи


    // Проверка рекаптчи
    function verifyReCaptchaOnSubmit(recaptchaResponse, hostedFieldsInstance, clientInstance) {
        recaptchaInfo.verify = false;
        factoryBilling.verifyReCaptcha({recaptchaResponse: recaptchaResponse}).then(function (res) {
            if (res.result === "ok") {
                serviceDialog.close(recaptchaDialog);
                $timeout(function() {
                    submitPaymentInfo(hostedFieldsInstance, clientInstance);
                }, 1000);
            } else {
                vcRecaptchaService.reload(recaptchaInfo.widgetId);
            }
            recaptchaInfo.verify = true;
        })
    }
    // Проверка рекаптчи

    // Sending payment nonce to server
    function sendPaymentNonceToServer(nonce) {
        factoryBilling.sendPaymentNonce(nonce, $scope.data).then(function (res) {
            if (res.result === "ok") {
                if ($scope.activate) {
                    const trackVal = $scope.addSubscribe.cycle === 1 ? "Activate (monthly)" : "Activate (annually)";
                    lvMixpanel.track("Billing", {Value: trackVal});
                }
                useRecaptcha = false;
                serviceToast.show('ACCOUNT.CARD_ADDED', 'success');
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                if (!lodash.isEmpty($scope.newUser)) {
                    serviceBillingCommonMethods.showEstimatedInvoiceForEmployees($scope.newUser, employeeStat, $scope.typeSubscriptions);
                }
                else if (!lodash.isEmpty($scope.addSubscribe)) {
                    let addSubscribeInfo = serviceBillingCommonMethods.getAddSubscribeData($scope.addSubscribe, $scope.typeSubscriptions, $scope.activate, $scope.couponCode);
                    let statusesInfo = serviceBillingCommonMethods.getStatuses(employeeStat, inventoryStat, integrationStat, salesHistoryStat);

                    serviceBillingCommonMethods.showEstimatedInvoiceForNewSubscribe(addSubscribeInfo, statusesInfo, urls);
                }
                else if (!lodash.isEmpty($scope.subscriptionId)) {
                    const renewSubscriptionData = new SubscriptionRenewData($scope.subscriptionId, true);
                    serviceBillingCommonMethods.showEstimatedInvoiceForRenew(renewSubscriptionData, urls.urlForSuccessReload);
                }
                else if (res.reloadToUnblock) {
                    offStateChangeStart();
                    $state.go('settings.account', { reloadToUnblock: true });
                }
                else {
                    offStateChangeStart();
                    $state.go('settings.account', $stateParams, {});
                }
            }
            else if (res.result === "error") {
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");

                useRecaptcha = res.message === "payment_method_verification_failed" || knownErrors.indexOf(res.message) === -1;

                serviceBillingError.knownError(res.message);
                if ($scope.activate) {
                    lvMixpanel.track("Billing", {Value: "Fail"});
                }
            }
            else {
                useRecaptcha = true;
                serviceBillingError.unKnownError();
                if ($scope.activate) {
                    lvMixpanel.track("Billing", {Value: "Fail"});
                }
            }
        });
    }
    // Sending payment nonce to server

    // Checking 3DS to send nonce
    function checkThreeDSecure(payload, clientInstance) {
        braintree.threeDSecure.create({
            client: clientInstance,
            version: 2
        }, function (err, threeDSecureInstance) {
            if (err) {
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                useRecaptcha = true;

                $timeout(function () {
                    serviceBillingError.knownError("payment_method_verification_failed");
                }, 0);
                if ($scope.activate) {
                    lvMixpanel.track("Billing", {Value: "Fail"});
                }
                return;
            }

            threeDSecureInstance.on('lookup-complete', function (data, next) {
                next();
            });

            threeDSecureInstance.verifyCard({
                nonce: payload.nonce,
                bin: payload.details.bin,
                amount: $scope.profile.billingInfo.billingCurrency === "USD" ? "1.00" : "100.00"
            }, function (err, payload1) {
                if (err) {
                    angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                    useRecaptcha = true;

                    $timeout(function () {
                        serviceBillingError.knownError("payment_method_verification_failed");
                    }, 0);
                    if ($scope.activate) {
                        lvMixpanel.track("Billing", {Value: "Fail"});
                    }
                    return;
                }

                if(payload1.threeDSecureInfo.liabilityShiftPossible && payload1.threeDSecureInfo.liabilityShifted) {
                    sendPaymentNonceToServer(payload1);
                } else {
                    angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                    useRecaptcha = true;
                    if ($scope.activate) {
                        lvMixpanel.track("Billing", {Value: "Fail"});
                    }
                    $timeout(function () {
                        serviceBillingError.knownError("payment_method_verification_failed");
                    }, 0);
                }
            });
        });
    }
    // Checking 3DS to send nonce
}

export default {
    type: 'controller',
    name: 'payMethodControllerBt',
    value: payMethodControllerBt
};
