config.$inject = ['$translateProvider', '$qProvider'];
function config($translateProvider, $qProvider) {
    $translateProvider.useMissingTranslationHandler('factoryEmptyValue');
    $translateProvider.useLoader('customLoader', {});
    $translateProvider.useLocalStorage();
    $translateProvider.preferredLanguage('eng');
    $translateProvider.fallbackLanguage(['eng']);
    //$translateProvider.onLoad =

    $translateProvider.useSanitizeValueStrategy(null);
}

const translateMainMap = buildMap(require.context('../../../i18/main', false, /\.json$/));
const translateCountriesMap = buildMap(require.context('../../../i18/countries', false, /\.json$/));
const translateScopeMap = buildMap(require.context('../../../i18/scope', false, /\.json$/));
const translateCurrenciesMap = buildMap(require.context('../../../i18/currencies', false, /\.json$/));

function buildMap(ctx) {
    const result = {};
    ctx.keys().forEach(key => {
        const start = key.lastIndexOf('/');
        const end = key.lastIndexOf('.json');
        const newKey = key.substring(start + 1, end);
        result[newKey] = ctx(key);
    });
    return result;
}

customLoader.$inject = ['$translateUrlLoader', '$q', '$http', '$locale', '$rootScope', '$timeout', 'lodash'];
function customLoader($translateUrlLoader, $q, $http, $locale, $rootScope, $timeout, lodash) {
    var langs = {};
    $rootScope.$on('$translateChangeSuccess', function (e, b) {

    });
    $rootScope.$translateLoadSuccess = $rootScope.$translateLoadSuccess || function (json) {
        return json;
    };

    function getRandomArbitary(min, max) {
        return parseInt(Math.random() * (max - min) + min);
    }

    return function (options) {
        var deferred = $q.defer();

        // TODO: Oleksii Nikitin
        // options.key == 'undefined' for unit-testing until resolve translate mocking
        if (options.key == 'empty' || options.key == 'undefined') {
            deferred.resolve({})
        } else {
            let transactionPromiseMain = $http.get(translateMainMap[options.key]);
            let transactionPromiseCountry = $http.get(translateCountriesMap['country_' + options.key]);
            let transactionPromiseScope = $http.get(translateScopeMap['scope_' + options.key]);
            let transactionPromiseCurrency = $http.get(translateCurrenciesMap['currency_' + options.key]);

            $q.all([transactionPromiseMain, transactionPromiseCountry, transactionPromiseScope, transactionPromiseCurrency])
                .then(function (result) {
                let d = lodash.merge(result[0].data, result[1].data, result[2].data, result[3].data);
                langs[options.key] = d;
                $locale.DATETIME_FORMATS.MONTH = d['MONTH'];
                $locale.DATETIME_FORMATS.SHORTMONTH = d['SHORTMONTH'];
                $rootScope.$broadcast('$translateLoadSuccess', d, options.key);
                deferred.resolve( d );
            }, function (err) {
                deferred.reject(err);
            })
        }

        return deferred.promise;
    };
}

factoryEmptyValue.$inject = ['buildConstant'];
function factoryEmptyValue(buildConstant) {
    return function (translationID) {
        /**
         * Значение при отсутвии перевода
         */
        return buildConstant.translateEmptyValue(translationID);
    };
}

export default [
    {
        type: 'config',
        value: config
    },
    {
        type: 'factory',
        name: 'customLoader',
        value: customLoader
    },
    {
        type: 'factory',
        name: 'factoryEmptyValue',
        value: factoryEmptyValue
    }
];
