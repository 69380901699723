

createDirective.$inject = [];

function createDirective() {

    let templateClone;

    return {
        restrict: 'A',
        require: '^lvAdaptiveContainer',
        compile: compileDirective
    };

    function compileDirective(tElem, tAttrs) {
        tElem.addClass('adaptive-item-content');
        templateClone = tElem.clone();
        return linkDirective;
    }

    function linkDirective(scope, elem, attrs, containerCtrl) {
        if (containerCtrl) {
            containerCtrl.registerContent(elem, templateClone);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvAdaptiveContent',
    value: createDirective
};
