/**
 * Created by mars on 10/7/15.
 */

timeRangeView.$inject = ['factoryPosition', '$timeout'];
function timeRangeView(factoryPosition, $timeout){
	return{
		restrict: 'C',

		link: function($scope, el, attr){
			var elParent  = $scope.elParent;

			$scope.$watch( 'show',function(val){
				el.css( 'display', 'block' );
				if ( val ) {
					$timeout(function(){
						var w = window,
							d = document,
							e = d.documentElement,
							g = d.getElementsByTagName('body')[0],
							screenWidth = w.innerWidth || e.clientWidth || g.clientWidth,
							y = w.innerHeight|| e.clientHeight|| g.clientHeight,
							body = document.querySelector('body');
						var calendarWidth = el[0].clientWidth;
						var offsetLeft = factoryPosition( elParent[0] ).x + 100;

						el.css('min-width', '192px');

						if(!body.classList.contains('aranav') && screenWidth - calendarWidth - offsetLeft < 0){
							var dx = -(screenWidth - calendarWidth - offsetLeft);
							el.css('left', 'inherit');
							// el.css('right', '5px');
						} else if (body.classList.contains('aranav') && screenWidth - calendarWidth - offsetLeft < 0) {

						} else if (!body.classList.contains('aranav')) {
							el.css( 'display', 'inherit' );
							el.css( 'left', factoryPosition( elParent[0] ).x + 'px' );
							// el.css( 'top', factoryPosition( elParent[0] ).y + 40 + 'px' );
						} else if (body.classList.contains('aranav')) {
							el.css( 'display', 'inherit' );
							el.css( 'left', 'auto' );
							el.css( 'right', screenWidth - factoryPosition( elParent[0] ).x - elParent[0].offsetWidth + 'px' );
						}
						el.css( 'top', factoryPosition( elParent[0] ).y + 42 + 'px' );
						el.css( 'visibility', 'visible' );
					}, 1);
				}
			});
		}
	};
}

export default timeRangeView;