/**
 * Created by mars on 12/21/15.
 */
factoryValidationFormat.$inject = [
	'$timeout',
	'$compile',
	'factoryValid'
];

function factoryValidationFormat(
	$timeout,
	$compile,
	factoryValid
) {
	function createElementAlert( parent, scope, ifBlur ) {

		parent.addClass( 'relative' );
		let template

		if ( ifBlur ){
			template = "<div class='err-mess animate-err-mess' ng-if='showErrorMessage && alertMessage'>" +
				"{{'ERROR.' + alertMessage | translate}}" +
				"</div>";
		} else {
			template = "<div class='err-mess animate-err-mess' ng-if='focus && alertMessage'>" +
				"{{'ERROR.' + alertMessage | translate}}" +
				"</div>";
		}

		let linkFn = $compile( template );
		let content = linkFn( scope );
		parent.append( content );
		return content;
	}

	function validDD( _scope, _el, _attr, _ctrl ){

		let scope = _scope,
			el =_el,
			attr = _attr,
			ctrl=_ctrl,
			valid = factoryValid,
			caretPosition = _attr.caretPosition !== 'false',
			validOnblur = _attr.validOnblur === "true";

		/**
		 * Отображать ли всплывающюю подсказку
		 * @type {boolean}
		 */
		scope.showErrorMessage = false;

		let functionName = attr.$normalize( scope.valid );
		let validMessage = eval( scope.validMessage );
		if ( validMessage ) {
			if(validOnblur){
				createElementAlert( el.parent(), scope, true );
			}else{
				createElementAlert( el.parent(), scope  );
			}
		}

		ctrl.setAlertMessage = function ( val ) {
			scope.alertMessage = val;
		};

		ctrl.err = {};
		let position;
		let maxLength;

		this.checkForEven = function ( value ) {
                let neWval;
                let _valid;

                if ( value === valid[functionName]( value ).val ) {
                    neWval = value;
                } else {
                    if ( value ) {
                        try {
                            position = doGetCaretPosition( el[0] );
                            _valid = valid[functionName]( value );
                            neWval = _valid.val;
                            maxLength = _valid.maxLength;

                            ctrl.$setViewValue( neWval );
                            ctrl.$render();

                            if ( caretPosition ) {
                                if ( value.length === neWval.length || maxLength < value.length ) {
                                    setCaretPosition( el[0], position );
                                } else if ( neWval.length < value.length ) {
                                    setCaretPosition( el[0], position - 1 );
                                } else if ( value.length < neWval.length ) {
                                    setCaretPosition( el[0], position + 1 );
                                }
                            }
                        } catch ( err ) {
                            console.log( err );
                        }
                    }
                }
                if ( valid[functionName]( neWval ).st ) {
                    ctrl.$setValidity( scope.valid, true );
                    ctrl.err[scope.valid] = null;
                    el.parent().removeClass( "has-error" );
                    scope.alertMessage = null;
                } else {
                    ctrl.$setValidity( scope.valid, false );
                    ctrl.err[functionName] = valid[functionName]( neWval ).mess;
                    scope.alertMessage = ctrl.err[functionName];
                    if(scope.showErrorMessage){
                        el.parent().addClass( "has-error" );
                    }

                }
                return neWval;
		};

		scope.$on('validate', function(event, data){
			ctrl.focus = false;
			scope.focus = false;
			scope.showErrorMessage = true;
			if(ctrl.$invalid){
				el.parent().addClass( "has-error" );
			}
			$timeout( function () {
				scope.$apply();
			} );
		});

		el
			.bind( 'focus', function () {
				ctrl.focus = true;
				scope.focus = true;
				$timeout( function () {
					scope.$apply();
				} );
			} )
			.on( 'blur', function () {
				ctrl.focus = false;
				scope.focus = false;
				scope.showErrorMessage = true;
				if(ctrl.$invalid){
					el.parent().addClass( "has-error" );
				}
				$timeout( function () {
					scope.$apply();
				} );
			} );


		function doGetCaretPosition( element ) {
			let _caretPos = 0;	// IE Support
			if ( document.selection ) {
				element.focus();
				let Sel = document.selection.createRange();
				Sel.moveStart( 'character', -element.value.length );
				_caretPos = Sel.text.length;
			}
			// Firefox support
			else if ( element.selectionStart || element.selectionStart === '0' )
				_caretPos = element.selectionStart;
			return (_caretPos);
		}

		function setCaretPosition( element, pos ) {
			if ( element.setSelectionRange ) {
				element.focus();
				element.setSelectionRange( pos, pos );
			}
			else if ( element.createTextRange ) {
				let range = element.createTextRange();
				range.collapse( true );
				range.moveEnd( 'character', pos );
				range.moveStart( 'character', pos );
				range.select();
			}
		}
	}

	return function( scope, el, attr, ctrl ){
		return new validDD( scope, el, attr, ctrl )
	};
}

export default factoryValidationFormat;
