/**
 * Created by Alex on 8.11.2016.
 */

function taxPercentFormatShowInEdit() {
	/**
	 * Filter coin to currency
	 * @param val
	 * @returns {String}
	 */

	 function catZero(lastTwo) {
		// console.log("xa-xa-xa-xa-");
		var lastTwoTemp  = lastTwo.split('');
		// console.log("arr = ", arr);
		var index = lastTwoTemp.length-1;
		// console.log(index);
		for (index; index > -1; --index) {
			// console.log(arr[index]);
			if(lastTwoTemp[index] == "0"){
				delete lastTwoTemp[index];
			} else {
				break;
			}
		}
		// lastTwoTemp = lastTwoTemp.join('');
		// console.log("arr = ", arr);
		lastTwo = lastTwoTemp.join('');

		return lastTwo;
	 }

	function format(val) {

		if(val !== undefined && val !== null){

			var modulo = '.';
			var result, lastTwo, first;
			var value = val.toString();
			var length = value.length;
			// console.log("length = ", length);

			if(length === 1){
				value = '00000' + value;
				first = value.substring(0, length);

				lastTwo = value.substring(length);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}

			}else if(length === 2){
				value = '0000' + value;
				first = value.substring(0, length - 1);

				lastTwo = value.substring(length - 1);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}
			}else if(length === 3){
				value = '000' + value;
				first = value.substring(0, length - 2);

				lastTwo = value.substring(length - 2);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}
			}else if(length === 4){
				value = '00' + value;
				// console.log("value = ", value);
				first = value.substring(0, length - 3);

				lastTwo = value.substring(length - 3);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}
			}else if(length === 5){
				value = '0' + value;
				first = value.substring(0, length - 4);

				lastTwo = value.substring(length - 4);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}

				// console.log("first = ", first);
				// console.log("lastTwo = ", lastTwo);

			}else{
				// console.log("value = ", value);

				first = value.substring(0, length - 5);

				lastTwo = value.substring(length - 5);
				// console.log("lastTwo = ", lastTwo);
				if(lastTwo[4] == '0'){
					lastTwo = catZero(lastTwo);
				}
			}

			// console.log("lastTwo одни нули = ", lastTwo);

			if(lastTwo == ''){
				result = first;
			} else {
				result = first + modulo + lastTwo;
			}


			return result;
		}

	}

	return format;
}

export default {
	type: 'filter',
	name: 'taxPercentFormatShowInEdit',
	value: taxPercentFormatShowInEdit
};