/**
 * Created by Nikitin Oleksii on 01/26/22.
 * Wrapper for mixpanel
 */

import angular from "angular";

lvMixpanel.$inject = [];
function lvMixpanel() {

    // inserting Mixpanel initialization to <head>
    this.init = init;

    this.track = track;
    this.identify = identify;
    this.people = {
        set: peopleSet
    };

    let initialized = false;
    let scheduled = [];

    function init(token) {
        let script = "<\script>(function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+'=([^&]*)')))?l[1]:null};g&&c(g,'state')&&(i=JSON.parse(decodeURIComponent(c(g,'state'))),'mpeditor'===i.action&&(b.sessionStorage.setItem('_mpcehash',g),history.replaceState(i.desiredHash||'',e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split('.');2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments, 0)))}}var d=a;'undefined'!==typeof f?d=a[f]=[]:f='mixpanel';d.people=d.people||[];d.toString=function(b){var a='mixpanel';'mixpanel'!==f&&(a+='.'+f);b||(a+=' (stub)');return a};d.people.toString=function(){return d.toString(1)+'.people (stub)'};k='disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(' ');\n" +
            "for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement('script');b.type='text/javascript';b.async=!0;b.src='undefined'!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:'file:'===e.location.protocol&&'//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\\/\\//)?'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js':'//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';c=e.getElementsByTagName('script')[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);\n" +
            "mixpanel.init('" + token + "');</\script>";

        let head = document.getElementsByTagName('head');
        angular.element(head).append(script);
        initialized = true;
        runScheduled();
    }

    function track() {
        run(null,'track', arguments);
    }

    function identify() {
        run(null,'identify', arguments);
    }

    function peopleSet() {
        run('people', 'set', arguments);
    }

    function run(propName, fnName, args) {
        let operation = new Operation(propName, fnName, args);
        if (initialized) {
            _run(operation);
        } else {
            scheduled.push(operation);
        }
    }

    function _run(operation) {
        if (operation.propName) {
            mixpanel[operation.propName][operation.fnName].apply(mixpanel[operation.propName], operation.args);
        } else {
            mixpanel[operation.fnName].apply(mixpanel, operation.args);
        }
    }

    function runScheduled() {
        scheduled.forEach(operation => {
            _run(operation);
        });
        scheduled = [];
    }

    function Operation(propName, fnName, args) {
        this.propName = propName;
        this.fnName = fnName;
        this.args = args;
    }
}

export default {
    type: 'service',
    name: 'lvMixpanel',
    value: lvMixpanel
};
