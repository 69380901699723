import angular from 'angular';
import timeRangeLabelTemplate from '../templates-pug/time-range-label.pug';

timeRangeDirective.$inject = [];

function timeRangeDirective(){
	return {
		restrict: 'EA',
		replace: true,
		template: timeRangeLabelTemplate,
		scope: {
			before: '=before',
			after: '=after',
			link: '@link',
			lock: '=',
			applyAction: '=',
			hideBothTimeListsWhenTimeLabelIsClosed: '@hideBothTimeListsWhenTimeLabelIsClosed',
		},
		controller: 'timeRangeContrl',
		link: function ( $scope, $element ) {
			$scope.name = 'timeRange';
			$scope.show = false;
			$scope.lock = eval( $scope.lock );
			$scope.elParent = $element;
		}
	};
}

export default timeRangeDirective;
