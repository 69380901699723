// function by all Inventory
// this search work in Item list Employee list

import template from '../templates-pug/lv-search/lv-search-hide-purchase.pug';

tableSearchDirective.$inject = ['$stateParams'];
function tableSearchDirective ($stateParams) {
    return {
        restrict: 'E',
        controller: tableSearchController,
        link: link,
        controllerAs: 'orderSearchCtrl',
        template: template,
        scope: {
            placeHolder: '@',
        }
    };

    function link (scope, element, attrs, vm) {

        vm.search = $stateParams.search || null;
        vm.placeHolder = scope.placeHolder;

        const searchHide = element.parent().siblings();
        const search = element.find('.search');
        const input = element.find('input');

        if($stateParams.search === undefined || $stateParams.search === null || $stateParams.search === '') {
            search.addClass('disable');
            searchHide.removeClass('disable');
            search.removeClass('shadow');
        } else {
            search.removeClass('disable');
            searchHide.addClass('disable');
            search.addClass('shadow');
        }

        element.on('click', function (event) {
            // Task #20033 on click on icon search open search form where input is activated, on focus
            if (event.target.id === 'enableSearch' || event.target.id === 'startSearch' ) {
                search.removeClass('disable');
                setTimeout(function() {
                    search.addClass('shadow');
                    search.addClass('searchresize');

                    //need wait animation open search block by 0.2s
                    setTimeout(function(){
                        searchBlockResize()
                    }, 300);

                }, 10);

                searchHide.addClass('disable');
                input.focus();

            } else if(event.target.id === 'searchDone'){
                search.removeClass('shadow');

                //need wait wean animation close search block by 0.2s finish
                setTimeout(function() {
                    searchHide.removeClass('disable'); //element.parent().siblings();
                }, 200);


                //give search block width none

                search[0].style.width = "";
                search.removeClass('searchresize');

            }
            input.onblur = function(){
                searchBlockResize();
            };
        });

    }

    function searchBlockResize() {
        const searchResize = document.querySelector('.searchresize');
        const heightmainContent = document.querySelector('.main-content').offsetWidth;

        //check have page scroll or not
        //take a size window
        const visibleDeviseHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
        //take a block content window
        const heightContentBlock = document.querySelector('.h-c-b').offsetHeight;
        const mainContent = document.querySelector('.main-content').offsetWidth;

        //if we have scroll & devise size tablet
        if ((visibleDeviseHeight <= heightContentBlock || heightmainContent <= heightContentBlock) && (460 < mainContent && mainContent <= 962)){
            if (searchResize) {
                const widthContentBlock = document.querySelector('.h-c-b').offsetWidth;
                const different = searchResize.offsetWidth - (mainContent - widthContentBlock);
                searchResize.style.width = different + "px";
            }

        } else if (visibleDeviseHeight > heightContentBlock) {
            return false;
        }
    };
    searchBlockResize();

    window.addEventListener('resize', function(){
        searchBlockResize();
    });
}

tableSearchController.$inject = ['$state', '$stateParams', 'stateGo'];
function tableSearchController($state, $stateParams, stateGo) {
    var vm = this;

    vm.startSearch = startSearch;
    vm.endSearch = endSearch;
    vm.isSearch = false;

    function startSearch () {
        if (vm.search === null || vm.search === '' || vm.search === ' ') {
            vm.isSearch = true;
            return false;
        } else {
            $stateParams.search = vm.search ? decodeURIComponent(vm.search) : vm.search;
            $stateParams.page = 0;
            stateGo.go($state, $stateParams, true);
        }
        // if device size >600px and verticalScroll >>> width -16px
        // on resize if open form
    }

    function endSearch () {
        $stateParams.search = null;
        vm.isSearch = false;
        // add this decision if in open search input empty or click on icon close cross search do not do load page
        if (!(vm.search == null || vm.search === '' || vm.search === ' ')) {
            stateGo.go($state, $stateParams, true);
        }
    }
}

export default {
    type: 'directive',
    name: 'orderSearch',
    value: tableSearchDirective
};