/**
 * Created by mars on 3/10/16.
 */

lvTaxes.$inject = [];
function lvTaxes() {
	return {
		restrict: 'A',
		require: '^ngModel',
		controller: lvTaxesController
	}
}

lvTaxesController.$inject = ['$scope'];
function lvTaxesController($scope) {
	this.scope = $scope;
	this.taxes = $scope.ware._taxes;
}

lvTax.$inject = [];
function lvTax() {
	return {
		controller: lvTaxController
	}
}

lvTaxController.$inject = ['$scope'];
function lvTaxController($scope) {
	this.tax = $scope.tax
}

mdSwitch.$inject = [];
function mdSwitch() {

	function isPrintedToFiscal(taxes){
		var i = 0;
		while (i<taxes.length){
			if(taxes[i]._select && taxes[i].isPrintedToFiscal){
					return taxes[i]
			}
			i++
		}
		return null
	}
	return {
		require: ['?^lvTaxes', '^ngModel', '?^lvTax'],
		link: function ( scope, el, attr, cntrl ) {

			var tax, taxes;
			if(cntrl[2]){
				tax =	cntrl[2].tax;
			}
			if(cntrl[0]){
				taxes = cntrl[0].taxes
			}

			if(cntrl[0]){
				scope.$watch(function(){
					return cntrl[1].$modelValue
				}, function(val, oldVal){
					if(val!=oldVal && !val) {
						if ( isPrintedToFiscal( taxes ) === null ) {
							for (var i = 0 ; i< taxes.length; i++){
								taxes[i]._showWaring = false;
							}
						}
					}
				});
				el.on( 'click', function () {
					var presentFiscal = isPrintedToFiscal(taxes);
					if(presentFiscal!== null && tax!=presentFiscal && tax.isPrintedToFiscal){
						tax._showWaring = true;
					}
				} )
			}
		}
	}
}

export default [
	{
		type: 'directive',
		name: 'lvTaxes',
		value: lvTaxes
	},
	{
		type: 'directive',
		name: 'lvTax',
		value: lvTax
	},
	{
		type: 'directive',
		name: 'mdSwitch',
		value: mdSwitch
	}
];