/**
 *  Directive to validate inputs for non-zero value
 *  Created by Kiskenbassker on 08.12.17, because it was problem to use 'valid' and 'currency-localization' directives together
 */

nonZeroDirective.$inject = [];
function nonZeroDirective() {

    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.nonZero = function(modelValue, viewValue) {

                var value = modelValue || viewValue;

                if (ctrl.$isEmpty(value)) {
                    // consider empty models to be valid
                    return true;
                }

                return value !== 0 && value !== "0";
            };
        }
    }

}

export default {
    type: 'directive',
    name: 'nonZero',
    value: nonZeroDirective
};