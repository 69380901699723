/**
 * Created by A.Sirosh.
 * Edited by kseniya.yarosh on 13.12.2016
 * @memberof reportSalesControl
 * @ngdoc controller
 * @name reportSalesControl
 * @requires $scope {service} controllers scope
 * @requires $state {service} UI Router servise
 * @requires $stateParams {service} UI Router servise
 * @requires getSelectReport {service} Get outlets and merchants
 * @requires groupBy {service} Get divider list
 * @requires stateGo {service} Reload data - use $state.go()
 * @requires changeDivider {service} set possible dividers
 */

import angular from 'angular';
import serviceDomUtils from "../../../service/service-dom-utils";

reportSalesControl.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'groupBy',
    'stateGo',
    'changeDivider',
    'getChartLabel',
    'getGroupBySelect',
    'salesReportTable',
    '$window',
    '$tableProp',
    '$proceeds',
    'filtersData',
    '$currencyOptionsShow',
    '$rootScope',
    '$percentShowInfinityToInfinity',
    'salesIndicatorsAdjustment',
    'tableColumnProp',
    'factoryPermits',
    'constantGreenExport',
    '$profile',
    '$timeout',
    '$permitsManager',
    '$miniBillingInfo',
    'serviceReports',
    'factoryPopover',
    'domUtils',
    '$reportCopyAllowed'
];

function reportSalesControl(
    $scope,
    $state,
    $stateParams,
    $filter,
    groupBy,
    stateGo,
    changeDivider,
    getChartLabel,
    getGroupBySelect,
    salesReportTable,
    $window,
    $tableProp,
    $proceeds,
    filtersData,
    $currencyOptionsShow,
    $rootScope,
    $percentShowInfinityToInfinity,
    salesIndicatorsAdjustment,
    tableColumnProp,
    factoryPermits,
    constantGreenExport,
    $profile,
    $timeout,
    $permitsManager,
    $miniBillingInfo,
    serviceReports,
    factoryPopover,
    domUtils,
    $reportCopyAllowed
) {
    let translate = $filter('translate');

    let taxPercentCommon = $filter('taxPercentCommon');

    $scope.blockDataCopy = !$reportCopyAllowed;

    $scope.displayCost = $permitsManager.costAllowed;
    $scope.isMobile = $rootScope.isMobile;
    $scope.isSafari = $rootScope.isSafari;
    $scope.pageWrapHeight = document.getElementsByClassName('salesReport')[0];

    $scope.reportError = $proceeds.hasOwnProperty('unableToLoadData');
    $scope.reportErrorServer = $scope.reportError && $proceeds.unableToLoadData;

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    $scope.downloadHrefBase = '/data/ownercab/earningsreportdownload';

    $scope.fullJson = $tableProp.dataArr;

    $scope.chart = {};
    $scope.chart.type = ($scope.fullJson && $scope.fullJson[8]) ? $scope.fullJson[8].chartType : "area";
    if ($scope.fullJson && $scope.fullJson.length === 9) {
        $scope.fullJson.splice(8, 1);
    }

    $scope.hiddenColumns = $scope.displayCost ? [] : ['primeCost', 'grossProfit', 'margin'];
    $scope.tableProp = angular.copy($scope.fullJson);

    $scope.tableConfig = angular.extend({}, $tableProp.config);

    $scope.salesReportTable = salesReportTable;

    $scope.reportTypeForColumns = "SALES";

    $scope.stateParams = $stateParams;

    $scope.periodAllowed = !$scope.reportError && $proceeds.periodAllowed;

    if (!$scope.reportError) {

        $scope.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager)

        if ($proceeds.startDateString && $proceeds.endDateString) {
            let fromParse = $proceeds.startDateString.split('-');
            let toParse = $proceeds.endDateString.split('-');
            $scope.calendarPeriod = {
                from: new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime(),
                to: new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime(),
                maxDate: new Date()
            };
        } else {
            $scope.calendarPeriod = {
                from: $proceeds.startDate,
                to: $proceeds.endDate,
                maxDate: new Date()
            };
        }

        if ($scope.periodAllowed) {

            let reverseChart = $rootScope.cabinetLang === 'ara' || $rootScope.cabinetLang === 'urd';

            salesIndicatorsAdjustment.modifyCellsAndContent();

            window.addEventListener('resize', function () {
                salesIndicatorsAdjustment.modifyCellsAndContent();
            });

            $scope.neighboringDates = neighboringDates;

            $scope.size = function (obj) {
                let size = 0, key;
                for (key in obj) {
                    if (obj[key] == true) size++;
                }
                return size;
            };

            $scope.countColums = $scope.size($scope.tableConfig);

            $scope.$on('howManyColumns', function (data) {
                $scope.countColums = $scope.size(data.targetScope.vmTableProps.configValue);
            });

            $stateParams.serverChartType = $stateParams.serverChartType ? $stateParams.serverChartType : 'saleSum';
            $scope.serverChartType = $stateParams.serverChartType;
            $scope.windowWidth = $window.outerWidth;


            $scope.getChartLabel = getChartLabel;
            $scope.changeDivider = changeDivider;
            $scope.outlets = $proceeds.outlets;
            $scope.merchants = getMerchantsList();

            $scope.groupBy = groupBy;

            $scope.pages = $proceeds.pages;

            $scope.stateGo = stateGo;
            $scope.getGroupBySelect = getGroupBySelect;

            $stateParams.group = $scope.changeDivider.changeDividerLabel($scope.groupBy.select, new Date($scope.calendarPeriod.from), new Date($scope.calendarPeriod.to));

            $scope.groupBy.select = $scope.getGroupBySelect.getSelect($scope.groupBy, $stateParams.group);

            $scope.menuWidth = function () {
                let menuButtonWidth = document.getElementsByClassName('chartType')[0].offsetWidth - 10;
                let groupMenulist = document.getElementsByClassName('chooseGroup')[0];
                let chartTypeMenulist = document.getElementsByClassName('chooseChartType')[0];
                if (groupMenulist) angular.element(groupMenulist.parentNode).addClass('noAnimate');

                if (chartTypeMenulist) {
                    chartTypeMenulist.style.width = menuButtonWidth + 32 + 'px';
                    angular.element(chartTypeMenulist.parentNode).addClass('noAnimate');
                }
            };


            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if ($proceeds.tableRows.length === 0 && $stateParams.page > 0 && $scope.pages > 0) {
                $state.go('report.sales', {page: $scope.pages - 1});
            } else if ($proceeds.tableRows.length === 0 && $stateParams.page > 0 && $scope.pages === 0) {
                $state.go('report.sales', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;
            if ($scope.currencyOptionsShow.digitalGroupSpacing == 2) {
                $scope.currencyPattern = '#,##,###.00';
            } else {
                $scope.currencyPattern = ($scope.currencyOptionsShow.decimalPlacesOverride == 0) ? '#,##0' : '#,##0.00';
            }

            $scope.currencyPattern = ($rootScope.indianCurrencyPattern && $rootScope.indianCurrencyPattern !== null) ? $rootScope.indianCurrencyPattern : $scope.currencyPattern;


            /*** формат отображения процентов ***/
            $scope.percentShowInfinityToInfinity = $percentShowInfinityToInfinity;

            $scope.changeGroupBy = function (item) {
                $scope.item = $scope.changeDivider.changeDividerItem(item, $stateParams.from, $stateParams.to);
                if ($scope.item === false) {
                    return false;
                }
                $scope.groupBy.select = $scope.item;
                $stateParams.group = $scope.changeDivider.changeDividerLabel($scope.groupBy.select);
                $stateParams.page = 0;
                filtersData.setStateParams($stateParams);
                $scope.stateGo.go($state, $stateParams, true);
            };

            $scope.setChartType = function (chart) {
                $scope.chart.type = chart;
                $scope.fullJson[8] = {chartType: chart};
                let params = {};
                params.reportType = $scope.reportTypeForColumns;
                params.columnsView = $scope.fullJson;
                if (factoryPermits.editProfile) {
                    tableColumnProp.setProps(params);
                }
                drawChart();
                $('.highcharts-credits').hide();
            };

            $scope.switchChartToKpi = function (kpiType) {
                $scope.serverChartType = kpiType;
                $stateParams.serverChartType = kpiType;
                $scope.kpiType = translate($scope.salesReportTable[kpiType]);
                if ($scope.noDataforChart)
                    return;

                $scope.chart.data = {
                    rows: $proceeds.chartData[kpiType].row,
                    categories: $proceeds.chartData.categories,
                    tooltips: $proceeds.chartData.tooltips
                };
                $scope.data = [];
                for (let i = 0; i < $proceeds.chartData[kpiType].row.length; i++) {
                    $scope.data.push([$scope.chart.data.tooltips[i], $proceeds.chartData[kpiType].row[i]]);
                }
                filtersData.setStateParams($stateParams);

                drawChart();
                $('.highcharts-credits').hide();
            };


            // chart data
            $scope.kpiType = translate($scope.salesReportTable[$stateParams.serverChartType]);
            $scope.earningsRows = $proceeds.chartData;
            // operation
            $scope.totalValues = $proceeds.totalValues;
            $scope.tableRows = $proceeds.tableRows;
            $scope.tableRows.stateParamsFrom = $stateParams.from;
            $scope.tableRows.stateParamsTo = $stateParams.to;
            $scope.noDataforChart = $scope.totalValues.totalPeriodSum === 0 && $scope.totalValues.totalPeriodReturnSum === 0
                && $scope.totalValues.totalPeriodDiscountSum === 0 && $scope.totalValues.totalPeriodEarningsSum === 0
                && $scope.totalValues.totalPeriodGrossProfit === 0;
            $scope.noData = $scope.tableRows.length;
            $scope.totalValuesPercentTotal = taxPercentCommon((parseFloat($scope.totalValues.infoPercentTotal.percent) * 100), $scope.percentShowInfinityToInfinity.decimalCharacter, 2);
            $scope.totalValuesPercentDiscount = taxPercentCommon((parseFloat($scope.totalValues.infoPercentDiscount.percent) * 100), $scope.percentShowInfinityToInfinity.decimalCharacter, 2);
            $scope.totalValuesPercentRefunds = taxPercentCommon((parseFloat($scope.totalValues.infoPercentRefunds.percent) * 100), $scope.percentShowInfinityToInfinity.decimalCharacter, 2);
            $scope.totalValuesPercentNetSales = taxPercentCommon((parseFloat($scope.totalValues.infoPercentNetSales.percent) * 100), $scope.percentShowInfinityToInfinity.decimalCharacter, 2);
            if ($scope.totalValues.infoPercentProfit) {
                $scope.totalValuesPercentProfit = taxPercentCommon((parseFloat($scope.totalValues.infoPercentProfit.percent) * 100), $scope.percentShowInfinityToInfinity.decimalCharacter, 2);
            }

            //	 chart begin
            $scope.rowLength = $proceeds.chartData.saleSum.row.length;

            $scope.$watch('rowLength', function (newVal) {
                $scope.rowLength = newVal;
            });

            $scope.chartTypes = ['area', 'column'];

            $scope.chart.data = {
                rows: $proceeds.chartData[$stateParams.serverChartType].row,
                categories: $proceeds.chartData.categories,
                tooltips: $proceeds.chartData.tooltips
            };
            $scope.data = [];
            for (let i = 0; i < $proceeds.chartData[$stateParams.serverChartType].row.length; i++) {
                $scope.data.push([$scope.chart.data.tooltips[i], $proceeds.chartData[$stateParams.serverChartType].row[i]]);
            }

            drawChart();
            $('.highcharts-credits').hide();

            function drawChart() {
                Highcharts.chart('container', {
                    chart: {
                        type: $scope.chart.type,
                        spacingTop: 51,
                        spacingLeft: 24,
                        spacingRight: 24,
                        spacingBottom: 20
                    },
                    title: {
                        text: ""
                    },
                    xAxis: {
                        type: "category",
                        categories: $scope.chart.data.categories,
                        lineColor: "#EAEAEA",
                        gridLineColor: '#EAEAEA',
                        tickColor: null,
                        tickmarkPlacement: "on",
                        reversed: reverseChart,
                        labels: {
                            style: {
                                fontSize: "12px",
                                fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
                                color: "#333333"
                            }
                        }
                    },
                    yAxis: {
                        title: {
                            text: ""
                        },
                        labels: {
                            formatter: function () {
                                return $filter('currencyFormatOnlyShow')(this.value, ".");
                            },
                            style: {
                                fontSize: "12px",
                                fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
                                color: "#333333"
                            }
                        },
                        opposite: reverseChart
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        area: {
                            fillOpacity: 0.12,
                            // pointPlacement: 'on'
                        },
                        column: {
                            pointPadding: 0.1,
                            groupPadding: 0.01,
                            borderWidth: 0,
                            shadow: false
                        }
                    },
                    tooltip: {
                        hideDelay: 0,
                        borderColor: "#FFFFFF",
                        backgroundColor: "#FFFFFF",
                        borderRadius: 2,
                        padding: 7,
                        height: "48px",
                        useHTML: true,
                        formatter: function () {
                            return '<span style="display: inline-block; padding-bottom: 7px">' + this.key + '</span><br><span style="display: inline-block;"><b>' + $filter('currencyFormatOnlyShow')(this.y, ".") + '</b></span>';
                        },
                        style: {
                            fontFamily: "Roboto, 'Helvetica Neue', sans-serif",
                            lineHeight: 1,
                            fontSize: "12px"
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    series: [{
                        turboThreshold: 0,
                        name: "",
                        data: $scope.data,
                        color: "#8BC34A",
                        marker: {
                            fillColor: 'white',
                            lineWidth: 2,
                            radius: 3,
                            lineColor: "#8BC34A",
                            states: {
                                hover: {
                                    fillColor: "#8BC34A",
                                    radius: 3
                                }
                            }
                        }
                    }]
                });
            }

            function neighboringDates(from) {
                let stateParamsFrom = new Date($stateParams.from);
                let stateParamsTo = new Date($stateParams.to);

                if (stateParamsFrom.getDate() == stateParamsTo.getDate() &&
                    stateParamsFrom.getMonth() === stateParamsTo.getMonth() &&
                    stateParamsFrom.getFullYear() === stateParamsTo.getFullYear() &&
                    $stateParams.fromHour >= $stateParams.toHour &&
                    $stateParams.fromHour !== 0 &&
                    $stateParams.toHour !== 0 &&
                    $stateParams.group === 'hour') {

                    let tableRows = $proceeds.tableRows;
                    // datesAreNotEqual(tableRows);
                    displayDateAndTime(from);

                    return true;
                } else {
                    return false;
                }
            }

            function displayDateAndTime(from) {
                let dateFrom = new Date(from);
                let sourceFrom = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), dateFrom.getHours(), dateFrom.getMinutes());
                return $scope.display = $filter('formatTimeByBrowser')(sourceFrom, 'short') + ' ' + $filter('formatDateByBrowser')(sourceFrom, 'tableLabel');
            }


            function getMerchantsList() {
                let outletsList = $scope.outlets;
                let merchantsList = [];
                let merchantsInOutlet = {};
                for (let i = 0; i < outletsList.length; i++) {
                    merchantsInOutlet = outletsList[i].merchants;
                    for (let l = 0; l < merchantsInOutlet.length; l++) {
                        merchantsList.push(merchantsInOutlet[l]);
                    }
                }
                return merchantsList;
            }

            window.addEventListener('resize', function () {
                changeSelectWidthForMobile();
            });

            changeSelectWidthForMobile();

            function changeSelectWidthForMobile() {

                $timeout(function () {
                    let selects = document.querySelector(".chartPanel");
                    let title = document.querySelector(".chartTitle");
                    let groupBy = document.querySelector(".groupBy");
                    let chartType = document.querySelector(".chartType");
                    let asSelect = document.getElementsByClassName("as-select");
                    let arrowIcon = document.getElementsByClassName("caret-in-selects");
                    let textGroupBySpan = document.querySelector(".groupBy .selects-text-span");
                    let textChartTypeSpan = document.querySelector(".chartType .selects-text-span");
                    let textTitleSpan = document.querySelector(".chartPanelText");

                    if (textGroupBySpan && textChartTypeSpan && textTitleSpan) {
                        let textTitle = textTitleSpan.offsetWidth;
                        let textGroupBy = textGroupBySpan.offsetWidth;
                        let textChartType = textChartTypeSpan.offsetWidth;
                        let selectsWidth = selects.offsetWidth;
                        let groupByWidth = groupBy.offsetWidth;
                        let chartTypeWidth = chartType.offsetWidth;
                        if (window.innerWidth > 599) {
                            if ((selectsWidth / 2 > textGroupBy + 72 + textTitle / 2) && (selectsWidth / 2 > textChartType + 72 + textTitle / 2)) {
                                angular.element(title).css({display: "inline-block", marginBottom: "0px"});
                                if (!reverseChart) {
                                    angular.element(chartType).css({width: "auto", paddingRight: "0px"});
                                    angular.element(groupBy).css({
                                        width: "auto",
                                        paddingLeft: "24px",
                                        minWidth: "152px"
                                    });
                                } else {
                                    angular.element(chartType).css({width: "auto", paddingLeft: "0px"});
                                    angular.element(groupBy).css({
                                        width: "auto",
                                        paddingRight: "24px",
                                        minWidth: "152px"
                                    });
                                }
                                for (let i = 0; i < asSelect.length; i++) {
                                    asSelect[i].style.height = 28 + "px";
                                    arrowIcon[i].style.marginTop = "auto";
                                }
                            } else if (selectsWidth < textTitle + groupByWidth + chartTypeWidth + 96) {
                                angular.element(title).css({display: "block", marginBottom: "8px"});
                                if (!reverseChart) {
                                    angular.element(chartType).css({width: "50%", paddingRight: "8px"});
                                    angular.element(groupBy).css({width: "50%", paddingLeft: "8px", minWidth: "128px"});
                                } else {
                                    angular.element(chartType).css({width: "50%", paddingLeft: "8px"});
                                    angular.element(groupBy).css({
                                        width: "50%",
                                        paddingRight: "8px",
                                        minWidth: "128px"
                                    });
                                }
                                for (let i = 0; i < asSelect.length; i++) {
                                    asSelect[i].style.height = 48 + "px";
                                    arrowIcon[i].style.marginTop = 22 + "px";
                                }
                            }
                        } else {
                            angular.element(title).css({display: "block", marginBottom: "8px"});
                            for (let i = 0; i < asSelect.length; i++) {
                                asSelect[i].style.height = 48 + "px";
                                arrowIcon[i].style.marginTop = 22 + "px";
                            }
                            if ((selectsWidth / 2 < textGroupBy + 48) || (selectsWidth / 2 < textChartType + 48)) {
                                if (!reverseChart) {
                                    angular.element(chartType).css({width: "100%", paddingRight: "0px", float: "none"});
                                    angular.element(groupBy).css({
                                        width: "100%",
                                        paddingLeft: "0px",
                                        minWidth: "128px",
                                        float: "none"
                                    });
                                } else {
                                    angular.element(chartType).css({width: "100%", paddingLeft: "0px", float: "none"});
                                    angular.element(groupBy).css({
                                        width: "100%",
                                        paddingRight: "0px",
                                        minWidth: "128px",
                                        float: "none"
                                    });
                                }
                            } else {
                                if (!reverseChart) {
                                    angular.element(chartType).css({width: "50%", paddingRight: "8px", float: "right"});
                                    angular.element(groupBy).css({
                                        width: "50%",
                                        paddingLeft: "8px",
                                        minWidth: "128px",
                                        float: "right"
                                    });
                                } else {
                                    angular.element(chartType).css({width: "50%", paddingLeft: "8px", float: "left"});
                                    angular.element(groupBy).css({
                                        width: "50%",
                                        paddingRight: "8px",
                                        minWidth: "128px",
                                        float: "left"
                                    });
                                }
                            }
                        }
                    }
                }, 0)
            }
        }
    }

    // Tooltips
    $scope.popover = factoryPopover;

    $scope.openPopovers = {
        grossSales: false,
        refunds: false,
        discounts: false,
        netSales: false,
        grossProfit: false
    }

    /**
     * Updates texts for Gross Sales and Refunds tooltips
     */
    const getTooltipsText = (tooltipsData) => {

        let grossSalesText;
        let refundsText;

        if ((tooltipsData.hasAddedTaxes || tooltipsData.hasSalesWithAddedTaxes) && !tooltipsData.hasIncludedTaxes && !tooltipsData.hasSalesWithIncludedTaxes) {
            grossSalesText = "SALES_SUMMARY_TOOLTIPS.GROSS_SALES_ADDED";
            refundsText = "SALES_SUMMARY_TOOLTIPS.REFUNDS_ADDED";
        } else if ((tooltipsData.hasIncludedTaxes || tooltipsData.hasSalesWithIncludedTaxes) && !tooltipsData.hasAddedTaxes && !tooltipsData.hasSalesWithAddedTaxes) {
            grossSalesText = "SALES_SUMMARY_TOOLTIPS.GROSS_SALES_INCLUDED";
            refundsText = "SALES_SUMMARY_TOOLTIPS.REFUNDS_INCLUDED";
        } else if (tooltipsData.hasIncludedTaxes || tooltipsData.hasSalesWithIncludedTaxes || tooltipsData.hasAddedTaxes || tooltipsData.hasSalesWithAddedTaxes) {
            grossSalesText = "SALES_SUMMARY_TOOLTIPS.GROSS_SALES_ADDED_INCLUDED";
            refundsText = "SALES_SUMMARY_TOOLTIPS.REFUNDS_ADDED_INCLUDED";
        } else {
            grossSalesText = "SALES_SUMMARY_TOOLTIPS.GROSS_SALES_NO_TAXES";
            refundsText = "SALES_SUMMARY_TOOLTIPS.REFUNDS_NO_TAXES"
        }

        if (tooltipsData.isUsingTips) {
            grossSalesText += "_TIPS";
            refundsText += "_TIPS";
        }

        return {
            grossSales: grossSalesText,
            refunds: refundsText,
            discounts: "SALES_SUMMARY_TOOLTIPS.DISCOUNTS",
            netSales: "SALES_SUMMARY_TOOLTIPS.NET_SALES",
            grossProfit: "SALES_SUMMARY_TOOLTIPS.GROSS_PROFIT"
        };

    }

    const onResize = () => {
        $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };

    this.$onInit = () => {
        $window.addEventListener("resize", onResize);
        if ($scope.periodAllowed) {
            $scope.tooltipsText = getTooltipsText($proceeds.tooltipsData);
        }
    };

    this.$onDestroy = () => $window.addEventListener("resize", onResize);

    $scope.breakpoints = {
        960: "bottom"
    }

    $scope.getTooltipPlacement = (event) => {
        const size = domUtils.size();
        const tabindexValue = parseInt(event.currentTarget.getAttribute("tabindex"));
        const index = isNaN(tabindexValue) ? 0 : tabindexValue;

        if (size.width < 960 && size.width >= 600) {
            $scope.breakpoints["600"] = index >= 2 && index <= 4 ? "bottom-right" : "bottom-left";
        }

        if (size.width < 600) {
            const tooltipIcon = document.getElementById(`tooltipIcon${index}`);
            const rect = tooltipIcon.getBoundingClientRect();
            $scope.breakpoints["0"] = rect.left < 144 ? "bottom-left" : "bottom";
        }
    }

}

export default {
    type: 'controller',
    name: 'reportSalesControl',
    value: reportSalesControl
};
