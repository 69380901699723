/**
 * Created by yarosh on 22.11.17.
 */

chipsValidation.$inject = ['$timeout', '$filter', '$compile', 'getCombinationsOfMultidimensionalArrValues', 'arrayOperation'];

function chipsValidation($timeout, $filter, $compile, getCombinationsOfMultidimensionalArrValues, arrayOperation) {

    return {
        restrict: 'A',
        link: linkFn,
        require: '^ngModel'
        // require: '^?form'
    };

    function linkFn(scope, element, attr, controller) {

        // console.log(controller);
        // console.log(scope);

        var translate = $filter('translate');

        scope.alertMessage = null;
        scope.valid = "variant-option-value";



        $timeout(function(){

            var value = controller.$viewValue,
                alert,
                currentCombinations,
                concatenated = [],
                alertChipMessage = {},
                chipsWrapInvalid,
                mdChipInputContainers = document.getElementsByClassName('md-chip-input-container'),
                mdChips = document.getElementsByClassName('md-chips'),
                // elementIndex = parseInt(scope.$parent.$index, 10),
                // currentInput = mdChipInputContainers[parseInt(scope.$parent.$index, 10)].firstElementChild,
                currentInput = mdChipInputContainers[parseInt(scope.$parent.$index, 10)].getElementsByTagName('input')[0],
                optionsArr = (Array.isArray(controller.$viewValue)) ? controller.$viewValue : JSON.parse(controller.$viewValue) ? controller.$viewValue : [],
                chipMessId = 'chipErrorMess' + parseInt(scope.$parent.$index, 10);
                // saveButton = document.getElementById('saveButton');



            if(optionsArr.length < 1){
                controller.$setValidity('currentInput', false);
            }

            function createEl ( scope, parentElement, messId, mess) {

                    if(alert && alert !== null){
                        removeElement();
                    }

                    var str = '<div id = ' + messId  + ' class = "form-hint is-error">' +
                        '<span translate = ' + mess  + '>' +
                        '</span>' +
                        '</div>';


                    alertChipMessage.val = $compile( str )( scope );
                    $(parentElement).append( alertChipMessage.val );

                    alert = document.getElementById(chipMessId);


            }

            function applyErrorStyle(elementManipulated){
                chipsWrapInvalid = $(element).find('md-chips-wrap');
                chipsWrapInvalid[0].classList.add("md-focus-error");
                // console.log(chipsWrapInvalid);
                if (!controller.$isEmpty(controller.$viewValue)) {
                    controller.$setTouched(true);
                }
                controller.$setValidity('chipValue', false);
                elementManipulated.classList.remove('ng-valid', 'ng-pristine');
                elementManipulated.classList.add('ng-invalid', 'ng-valid-parse', 'ng-invalid-required', 'ng-invalid-required');
            }

            function removeErrorStyle(elementManipulated){
                chipsWrapInvalid = $(element).find('md-chips-wrap');
                chipsWrapInvalid[0].classList.remove("md-focus-error");
                controller.$setValidity('chipValue', true);
                elementManipulated.classList.remove('ng-invalid', 'ng-valid-parse', 'ng-invalid-required', 'ng-invalid-required', 'ng-empty');
                elementManipulated.classList.add('ng-valid');
            }

            function throwMessageInRespondOnLengthOrSimilarity(mess, event) {
                var messageText = (mess === "long") ? 'VARIANT_OPTION_VALUE_LENGTH' : 'VARIANT_OPTION_VALUE_EXISTS',
                    messageLangKey = (mess === "long") ? 'ERROR.VARIANT_OPTION_VALUE_LENGTH' : 'ERROR.VARIANT_OPTION_VALUE_EXISTS',
                    eventTargetValueExists = (event.target.value && event.target.value !=="");
                applyErrorStyle(currentInput);

                if( controller.$valid === false && (optionsArr.length > 0 || eventTargetValueExists) && (scope.alertMessage === null || scope.alertMessage === "")){
                    scope.alertMessage = messageText;
                    createEl( scope, element, chipMessId, messageLangKey );
                } else if(controller.$valid === false && (optionsArr.length > 0 || eventTargetValueExists) && (scope.alertMessage !== null || scope.alertMessage !== "")){
                    scope.alertMessage = messageText;
                }
            }

            function removeElement(){

                scope.alertMessage = null;

                if(alert && alert!== null){
                    alert.parentNode.removeChild(alert);
                    delete alertChipMessage.val;
                    removeErrorStyle(currentInput);
                }

            }

            function validateChipsForValueNamesAndLength(event) {



                var currentTypedValue = (event.target.value) ? event.target.value : "",
                    eventTargetValueExists = (event.target.value && event.target.value !=="");


                    alert = document.getElementById(chipMessId);

                    if( (optionsArr.length < 1 && !eventTargetValueExists) && alert && alert !== null &&
                        (scope.alertMessage === 'VARIANT_OPTION_VALUE_LENGTH' ||
                        scope.alertMessage === 'VARIANT_OPTION_VALUE_EXISTS')){
                        removeElement();
                    }

                    if( optionsArr.length < 1 && !eventTargetValueExists && scope.message !== "at_least_one_option_value" && scope.message !== "cannot_empty_field"){
                        // removeElement();

                        // if( (!alert || alert === null) && scope.alertMessage === null){
                        createEl( scope, element, chipMessId, 'ERROR.at_least_one_option_value' );
                        scope.alertMessage = 'at_least_one_option_value';
                        applyErrorStyle(currentInput);
                        // }

                    } else if(optionsArr.length < 1 && eventTargetValueExists){

                        //!!!!! this block of code is hidden in a comment since we don't need to show it while this element is being changed, unlike onblur event which is 'described' in a code snippet below

                        // removeElement();

                        // scope.alertMessage = 'at_least_one_option_value';
                        // createEl( scope, element, chipMessId, 'ERROR.' + scope.alertMessage );
                        // applyErrorStyle(currentInput);

                    }

                    else if( alert && alert !== null){
                        removeElement();

                    }


                    for (var i = 0, len = optionsArr.length; i < len; i++) {

                        alert = document.getElementById(chipMessId);
                        if (Array.isArray(optionsArr)) {
                            if (optionsArr[i].length > 20) {
                                throwMessageInRespondOnLengthOrSimilarity("long", event);
                                break;

                            }

                        } else {
                            removeErrorStyle(currentInput);
                            removeElement();
                        }
                    }


                    if (Array.isArray(optionsArr) || eventTargetValueExists){
                        if (optionsArr.indexOf(currentTypedValue.toLowerCase()) !== -1) {
                            throwMessageInRespondOnLengthOrSimilarity("similar", event);
                        }

                        if(currentTypedValue.length > 20) {
                            throwMessageInRespondOnLengthOrSimilarity("long", event);
                        }

                        if(arrayOperation.checkDuplicateOptions(optionsArr, 'inSensitive') === true){
                            throwMessageInRespondOnLengthOrSimilarity("similar", event);
                        }
                    }
                    // else if(scope.alertMessage !== 'cannot_empty_field' || scope.alertMessage !== 'at_least_one_value_option'){
                    else if(scope.alertMessage !== 'cannot_empty_field' && scope.alertMessage !== 'at_least_one_value_option'){
                        removeElement();
                    }


            }

            for(var i = 0; i < mdChips.length; i++ ){
                var errorId = (mdChips[i].parentNode.lastChild.id) ? mdChips[i].parentNode.lastChild.id : null;

                if(mdChips[i].parentNode.lastChild.id && errorId.match(/^#chipErrorMess/)){
                    mdChips[i].parentNode.lastChild.id = '#chipErrorMess' + parseInt(i);
                }

            }


            element.on('click', function () {

                currentInput.addEventListener('keyup', validateChipsForValueNamesAndLength);
                currentInput.addEventListener('blur', function(event) {

                    //!!!!! this block of code is placed here since we need to show the validation message while after element was changed, at the moment of the onblur event
                    if(optionsArr.length < 1 && scope.alertMessage !== "cannot_empty_field" && scope.alertMessage !== 'at_least_one_value_option'){
                        // removeElement();
                        scope.alertMessage = null;
                        createEl( scope, element, chipMessId, 'ERROR.at_least_one_option_value' );
                        applyErrorStyle(currentInput);
                    } else if(optionsArr.length > 0 && scope.alertMessage !== "VARIANT_OPTION_VALUE_LENGTH" && scope.alertMessage !== "VARIANT_OPTION_VALUE_EXISTS"){
                        removeElement();
                        controller.$setValidity('currentInput', true);
                    }
                    validateChipsForValueNamesAndLength(event);
                });

            });


            element.on('keydown', function(event){
                if(event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 13 || event.keyCode === 9){
                    validateChipsForValueNamesAndLength(event);
                    if(optionsArr.length > 0 && scope.alertMessage !== "VARIANT_OPTION_VALUE_LENGTH" && scope.alertMessage !== "VARIANT_OPTION_VALUE_EXISTS"){
                        controller.$setValidity('currentInput', true);
                    }
                }
            });


        }, 0)}


}

export default {
    type: 'directive',
    name: 'chipsValidation',
    value: chipsValidation
};