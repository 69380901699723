lvToast.$inject = ['serviceToast'];

function lvToast(serviceToast) {
    return {
        template: '<div class="l-toast"><div class="animate-repeat {{toast._class}}" ng-repeat="toast in serviceToast.arrayToast | orderBy:\'index\':true">{{(toast.mess | translate)=="..." ? toast.mess : (toast.mess | translate)}}</div></div>',
        replace: true,
        link: function ( $scope, el, attr ) {
            $scope.serviceToast = serviceToast
        }
    }
}

export default lvToast;