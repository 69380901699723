/**
 * Created by K.Yarosh on 20.11.2017.
 * @memberof getCombinationsOfMultidimensionalArrValues
 * @ngdoc service
 * @name getCombinationsOfMultidimensionalArrValues
 * @description create a combination for each value of the first array among the entire multidimensional array, depending on values of the rest arrays
 * var first  = ['a', 'b', 'c'],
 *     second = ['d', 'e'],
 *     third  = ['f', 'g', 'h'];
 *
 * getCombination([first, second, third]) // a,d,f,a,d,g,a,d,h,a,e,f,a,e,g,a,e,h,b,d,f,b,d,g,b,d,h,b,e,f,b,e,g,b,e,h,c,d,f,c,d,g,c,d,h,c,e,f,c,e,g,c,e,h
 */

getCombinationsOfMultidimensionalArrValues.$inject = [];
function getCombinationsOfMultidimensionalArrValues() {
    var	nextArrays,
        copy;

    function getCombinations (arrays, combine, finalList) {
        combine = (combine) ? combine : [];
        finalList = (finalList) ? finalList : [];
        if (!arrays.length) {
            finalList.push(combine);
        } else {
            arrays[0].forEach(function(currentVal) {
                nextArrays = arrays.slice(1);
                copy = combine.slice();
                copy.push(currentVal);
                getCombinations(nextArrays, copy, finalList);
            });
        }

        return finalList;
    }

    this.getCombinations = getCombinations;

}

export default {
    type: 'service',
    name: 'getCombinationsOfMultidimensionalArrValues',
    value: getCombinationsOfMultidimensionalArrValues
};