import angular from 'angular';

factoryInventoryHistory.$inject = [
    'promiseOperation'];

function factoryInventoryHistory (
                      promiseOperation){

    function getInventoryHistoryItems(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getInventoryHistoryItems',
            params,
            successGetInventoryHistoryItems
        );
    }

    function successGetInventoryHistoryItems(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    return {
        getInventoryHistoryItems: getInventoryHistoryItems
    }
}

export default {
    type: 'factory',
    name: 'factoryInventoryHistory',
    value: factoryInventoryHistory
};