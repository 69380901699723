formatDatePickerMonth.$inject = [];

function formatDatePickerMonth() {
	function formatDatePickerMonth(date, params) {
		var dateNowValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
		var afterValue = params.after ? params.after.getTime() : null;

		// закомментировано из-за спорности условия || в случае, когда будущие даты должны быть активными 4.12.2017
		// var maxDate = params.maxDate || new Date();

		var maxDate = params.maxDate;
		// console.log(params.maxDate);

		var minDate = params.minDate;
        var startWeek = params.startWeek;
		var exactValue  = params.exact ?   params.exact.getTime() : null;

		function checkCurrentDate(d) {
			var value = '';

			if (dateNowValue == d.getTime()) {
				value += 'current'
			}

            if (!exactValue && afterValue === d.getTime()) {
                value += ' select'
            }

			if(new Date(exactValue ).getDate()  == d.getDate() &&  new Date(exactValue ).getFullYear()  == d.getFullYear() && new Date(exactValue ).getMonth()  == d.getMonth()){
				value += ' select'
			}
			if (getBlocked(d)) {
				value += ' blocked'
			}
			return value
		}

		function getBlocked(d) {
			if (maxDate && maxDate.getTime() < d.getTime()) {
				return true
			}

			if (minDate && d.getTime() < new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()).getTime()) {
				return true
			}
			return false
		}

		var array = [];
		for (var i = 0; i < 50; i++) {
			var d = new Date(date.getFullYear(), date.getMonth(), i);
			if (d.getMonth() == date.getMonth()) {
				array.push({
					date: d,
					value: d.getTime(),
					datePickerDayWeek: d.getDay(),
					blocked: getBlocked(d),
					class: checkCurrentDate(d)
				})
			}
		}
		var fillBefore, fillAfter;
		if (startWeek == 0) {
			fillBefore = array[0].datePickerDayWeek;
			fillAfter = 6 - array[array.length - 1].datePickerDayWeek;
		} else {
			fillBefore = (array[0].datePickerDayWeek == 0) ? 6 : array[0].datePickerDayWeek - 1;
			fillAfter = (array[array.length - 1].datePickerDayWeek == 0) ? 0 : 7 - array[array.length - 1].datePickerDayWeek;
		}
		for (var i = 0; i < fillBefore; i++) {
			array.unshift(null)
		}
		for (var i = 0; i < fillAfter; i++) {
			array.push(null)
		}

		var rows = parseFloat((array.length / 7).toFixed(0));
		var arrMonth = [];
		var k = 0;
		for (var i = 0; i < rows; i++) {
			arrMonth[i] = [];
			for (var d = 0; d < 7; d++) {
				arrMonth[i].push(array[k])
				k++;
			}

		}
		return arrMonth
	}
	return {
		formatDatePickerMonth:formatDatePickerMonth
	}
}

export default formatDatePickerMonth;