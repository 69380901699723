moduleRun.$inject = ['$rootScope', 'constantDatePicker', 'localDays', '$filter'];

function moduleRun($rootScope, constantDatePicker, localDays, $filter) {
    $rootScope.$on('$translateChangeSuccess', function (a,b,c) {
        var i = constantDatePicker.days.length;
        //for auto determination of weekdays shift by a browser
        // var weekStartsWith = (new Date(localDays.weekDays[0])).getDay();
        // var weekEndsWith = (new Date(localDays.weekDays[i-1])).getDay();
        // $rootScope.startWeek = weekStartsWith;
        // $rootScope.endWeek = weekEndsWith;
        while(0<i--){
            if($rootScope.localeSupport === false){
                constantDatePicker.days[i] = localDays[b.language] ? localDays[b.language][i] : localDays['eng'][i];
            } else {
                // constantDatePicker.days[i] = $filter('formatDateByBrowser')(new Date(localDays.weekDays[i]).setHours(0,0,0,0), 'calendarWeekDay');
                constantDatePicker.days[i] = localDays[b.language] ? localDays[b.language][i] : localDays['eng'][i];
            }

            if($rootScope.localeSupport === false || $rootScope.startWeek === 1){
                //the obsolete option for static days translated into two languages - Russian and English
                // constantDatePicker.days[i] = localDays[b.language] ? localDays[b.language][i] : localDays['eng'][i];
                constantDatePicker.days[i] = $filter('translate')('WEEKDAYS.' + localDays.weekDaysFromMondayForTranslate[i]);
            } else {
                //for auto determination of weekdays shift by a browser
                // constantDatePicker.days[i] = $filter('formatDateByBrowser')(new Date(localDays.weekDays[i]).setHours(0,0,0,0), 'calendarWeekDay');
                constantDatePicker.days[i] = $filter('translate')('WEEKDAYS.' + localDays.weekDaysFromSundayForTranslate[i]);
            }
        }
    });
}

export default moduleRun;