/**
 * Created by mars on 2/8/16.
 * Edited by olehkss 13.07.2016
 * Edited by kseniya.yarosh 13.12.2016
 */

import angular from 'angular';

reportEmployeeController.$inject = [
	'$employee',
	'$profile',
	'$scope',
	'$stateParams',
	'$state',
	'$currencyOptionsShow',
	'constantGreenExport',
	'$countOptionsShow',
	'lodash',
	'$miniBillingInfo',
	'serviceReports',
	'$permitsManager',
	'$reportCopyAllowed'];

function reportEmployeeController(
	$employee,
	$profile,
	$scope,
	$stateParams,
	$state,
	$currencyOptionsShow,
	constantGreenExport,
	$countOptionsShow,
	lodash,
	$miniBillingInfo,
	serviceReports,
	$permitsManager,
	$reportCopyAllowed) {

	let vm = this;
	$scope.blockDataCopy = !$reportCopyAllowed;
	vm.reportError = $employee.hasOwnProperty('unableToLoadData');
	vm.reportErrorServer = vm.reportError && $employee.unableToLoadData;

	$scope.profile = angular.copy($profile);
	vm.downloadHrefBase = '/data/ownercab/merchantsreportdownload';

	$scope.isGreen = constantGreenExport.indexOf($scope.profile.cabinetLang) !== -1;

	if (!vm.reportError) {
		vm.periodAllowed = $employee.periodAllowed;
		vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

		if ($employee.startDateString && $employee.endDateString) {
			let fromParse = $employee.startDateString.split('-');
			let toParse = $employee.endDateString.split('-');
			vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
			vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
		} else {
			vm.startDate = $employee.startDate;
			vm.endDate = $employee.endDate;
		}

		$scope.outlets = $employee.outlets;
		$scope.merchants = serviceReports.getMerchantsList($scope.outlets);

		if (vm.periodAllowed) {
			vm.report = $employee.report;
			vm.pages = $employee.pages;

			const tipsCountries = ["us", "be", "br", "cl", "de", "es", "fr", "ie", "it", "nl", "at", "pt", "pl", "ch", "se", "gb", "ca", "nz", "au", "za", "dk", "fi", "mx", "no"];
			vm.showTip = lodash.includes(tipsCountries, $scope.profile.country);

			/*** формат отображения валюты ***/
			$scope.currencyOptionsShow = $currencyOptionsShow;

			/*** формат отображения колличества ***/
			$scope.countOptionsShow = $countOptionsShow;

			// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
			if (vm.report.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
				$state.go('report.employee', {page: vm.pages - 1});
			} else if (vm.report.length === 0 && $stateParams.page > 0 && vm.pages === 0) {
				$state.go('report.employee', {page: 0});
			}

			// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
		}
	}
}

export default {
	type: 'controller',
	name: 'reportEmployeeController',
	value: reportEmployeeController
};
