/*
 * Created by Olya 24.03.2018
 */

import angular from 'angular';

createEditCustomerController.$inject = [
    '$state',
    '$scope',
    'clientsDB',
    '$clientDetail',
    'serviceDialog',
    'serviceToast',
    '$timeout',
    '$filter',
    '$stateParams',
    '$profile',
    'lvCountriesMultiLang',
    'lvMixpanel',
    'localizationUtil',
    'dialogs'
];

function createEditCustomerController(
    $state,
    $scope,
    clientsDB,
    $clientDetail,
    serviceDialog,
    serviceToast,
    $timeout,
    $filter,
    $stateParams,
    $profile,
    lvCountriesMultiLang,
    lvMixpanel,
    localizationUtil,
    dialogs
){
    let vm = this;
    let requiredGroup = document.getElementsByClassName('one-of-required');
    const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);;
    const translate = $filter('translate');

    if($state.current.name === 'clients.createcustomer') {
        vm.clientData = {
            nameByOwner: '',
            email: null,
            freeNum: null,
            comment: null,
            taxId: null,
            city: null,
            country: null,
            postalCode: null,
            province: null,
            address: null,
            customerCode: null
        };

    } else if ($state.current.name === 'clients.editcustomer') {
        vm.clientData = angular.copy($clientDetail.client);
    }

    vm.isPoland = $profile.country === 'pl';
    vm.isSaudi = $profile.country === 'sa';

    vm.name = (!vm.clientData.name) ? "" : vm.clientData.name;
    vm.email = (vm.clientData.email) ? vm.clientData.email : '';
    vm.freeNum = (vm.clientData.freeNum) ? vm.clientData.freeNum : '';
    vm.comment = (vm.clientData.comment) ? vm.clientData.comment : null;
    vm.taxId = ((vm.isPoland || vm.isSaudi) && vm.clientData.taxId) ? vm.clientData.taxId : '';
    vm.nameEditable = $state.current.name === 'clients.editcustomer' && vm.clientData.nameEditable !== undefined ? vm.clientData.nameEditable : true;
    vm.emailEditable = $state.current.name === 'clients.editcustomer' && vm.clientData.emailEditable !== undefined ? vm.clientData.emailEditable : true;
    vm.city = vm.clientData.city ? vm.clientData.city : null;
    vm.selectedCountry = vm.clientData.country ? vm.clientData.country : null;
    vm.postalCode = vm.clientData.postalCode ? vm.clientData.postalCode : null;
    vm.province = vm.clientData.province ? vm.clientData.province : null;
    vm.address = vm.clientData.address ? vm.clientData.address : null;
    vm.countries = angular.copy(lvCountriesMultiLang);
    vm.customerCode = vm.clientData.customerCode ? vm.clientData.customerCode : null;

    let countryCode = vm.clientData.country ? vm.clientData.country : $profile.country;


    vm.citylabel = localizationUtil.getCityLabel(countryCode);
    vm.provincelabel = localizationUtil.getProvinceLabel(countryCode);
    vm.postallabel = localizationUtil.getPostalCodeLabel(countryCode);

    vm.startparams = {
        name: vm.name,
        email: vm.email,
        freeNum: vm.freeNum,
        address: vm.address,
        city: vm.city,
        province: vm.province,
        postalCode: vm.postalCode,
        country: vm.selectedCountry,
        customerCode: vm.customerCode,
        comment: vm.comment,
        taxId: vm.taxId
    };
    $scope.allEmpty = false;
    $scope.uniqueEmail = true;
    $scope.isSave = false;

    vm.changeLabelsNames = changeLabelsNames;

    function changeLabelsNames(countryCode) {
        vm.citylabel = localizationUtil.getCityLabel(countryCode);
        vm.provincelabel = localizationUtil.getProvinceLabel(countryCode);
        vm.postallabel = localizationUtil.getPostalCodeLabel(countryCode);
    }

    vm.onCancelCustomer = function () {
        if($state.current.name === 'clients.createcustomer') {
            backToList();
        } else if ($state.current.name === 'clients.editcustomer') {
            $state.go('clients.customerdetail', {
                id: $stateParams.id
            });
        }
    };

    vm.onSaveCustomer = function(){
        if ($scope.customerCardForm.$invalid) {
            $scope.customerCardForm.focusFirstInvalidControl();
            return serviceToast.show("INVENTORY.CREATE_ORDER_INVALID", "error");
        }

        $scope.isSave = true;
        vm.forSave = {
            nameByOwner: vm.name || '',
            email: vm.email || null,
            freeNum: vm.freeNum || null,
            address: vm.address,
            city: vm.city,
            province: vm.province,
            postalCode: vm.postalCode,
            country: vm.selectedCountry,
            customerCode: vm.customerCode || null,
            comment: vm.comment || null,
            taxId: vm.taxId || null,
        };


        let regExpEmail = /^(([A-Za-z0-9-+!$&*=^`|~#%'?_{}]+(([A-Za-z0-9-+\.!$&*=^`|~#%'?_{}]+)*))|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let fieldsInvalid = vm.name.length > 64 || vm.email.length > 100 || vm.freeNum.length > 15 || vm.isPoland && vm.taxId.length > 20 || vm.isSaudi && vm.taxId.length > 15
            || vm.comment === undefined || (vm.email && !regExpEmail.test(vm.email));
        if (vm.forSave.nameByOwner === '' && vm.forSave.email === null && vm.forSave.freeNum === null) {
            $scope.allEmpty = true;
            for (let i = 0; i < requiredGroup.length; i++) {
                angular.element(requiredGroup[i]).addClass('md-input-invalid');
            }
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.isSave = false;
        } else if (fieldsInvalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.isSave = false;
        } else {
            if($state.current.name === 'clients.createcustomer') {
                clientsDB.addCustomerCard( vm.forSave ).then(function ( data ) {
                    if (data.result === 'ok') {
                        offStateChangeStart();
                        serviceToast.show('CLIENTS.ADDED_CLIENT', 'success');
                        $state.go('clients.customerdetail', {
                            id: data.newClientId
                        });

                        //-- These events triggered when users save "Create customer" card
                        lvMixpanel.track('Customer created/edited', {
                            "Event type" : "Customer created",
                            "Name" : vm.forSave.nameByOwner ? "Filled" : "Blank",
                            "Email" : vm.forSave.email ? "Filled" : 'Blank',
                            "Number" : vm.forSave.freeNum ? "Filled" : 'Blank',
                            "Address" : vm.forSave.address ? "Filled" : 'Blank',
                            "City" : vm.forSave.city ? "Filled" : 'Blank',
                            "Region" : vm.forSave.province ? "Filled" : 'Blank',
                            "Postal code" : vm.forSave.postalCode ? "Filled" : 'Blank',
                            "Country" : vm.forSave.country ? "Filled" : 'Blank',
                            "Customer code" : vm.forSave.customerCode ? "Filled" : 'Blank',
                            "Note" : vm.forSave.comment ? "Filled" : 'Blank',
                            "VAT number" : vm.forSave.taxId ? "Filled" : 'Blank',
                        });
                    } else if (data.result === 'error' && data.message === 'forbidden_for_support_team') {
                        serviceToast.show('NO_EDIT_PERMISSION', 'error');
                    } else {
                        showResultNotOk( data );
                    }
                })
            } else if ( $state.current.name === 'clients.editcustomer' ) {
                vm.forSave.id = $stateParams.id;
                vm.forSave.name = vm.name || '';

                clientsDB.editcustomercard( vm.forSave ).then(function ( data ) {
                    if (data.result === 'ok') {
                        offStateChangeStart();
                        let idPath = data.newClientId === null ? $stateParams.id : data.newClientId;
                        serviceToast.show('CLIENTS.PROFILE_EDITED', 'success');
                        $state.go('clients.customerdetail', {
                            id: idPath
                        });

                        //-- These events triggered when users save edit customer card
                        lvMixpanel.track('Customer created/edited', {
                            "Event type": "Customer edited",
                            "Name" : vm.forSave.name ? "Filled" : "Blank",
                            "Email" : vm.forSave.email ? "Filled" : "Blank",
                            "Number" : vm.forSave.freeNum ? "Filled" : "Blank",
                            "Address" : vm.forSave.address ? "Filled" : "Blank",
                            "City" : vm.forSave.city ? "Filled" : "Blank",
                            "Region" : vm.forSave.province ? "Filled" : "Blank",
                            "Postal code" : vm.forSave.postalCode ? "Filled" : "Blank",
                            "Country" : vm.forSave.country ? "Filled" : "Blank",
                            "Customer code" : vm.forSave.customerCode ? "Filled" : "Blank",
                            "Note" : vm.forSave.comment ? "Filled" : "Blank",
                            "VAT number" : vm.forSave.taxId ? "Filled" : "Blank"
                        });
                    } else if (data.result === 'error') {
                        if (data.message === 'customer_deleted') {
                            offStateChangeStart();
                            dialogs.itemDeletedEarlier().then(backToList);
                        } else if (data.message === 'forbidden_for_support_team') {
                            serviceToast.show('NO_EDIT_PERMISSION', 'error');
                        } else {
                            showResultNotOk(data);
                        }
                    } else {
                        showResultNotOk(data);
                    }
                });
            }
        }
    };

    vm.offError = function(){
        $scope.allEmpty = false;
        if(vm.name !== '' || (vm.email !== '' && vm.email !== null) || (vm.freeNum !== '' && vm.freeNum !== null)) {
            for (var i = 0; i < requiredGroup.length; i++) {
                angular.element(requiredGroup[i]).removeClass('md-input-invalid');
            }
        }
    };

    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    function isChanged() {
        vm.endParams = {
            name: vm.name,
            email: vm.email,
            freeNum: vm.freeNum,
            comment: vm.comment,
            taxId: vm.taxId,
            city: vm.city,
            country: vm.selectedCountry,
            postalCode: vm.postalCode,
            province: vm.province,
            address: vm.address,
            customerCode: vm.customerCode
        };
        return !angular.equals(vm.startparams, vm.endParams);
    }

    function showUnableToSaveError(messageCode) {
        var message = 'CLIENTS.' + messageCode;
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('CLIENTS.UNABLE_TO_SAVE'),
            content: translate(message),
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK'
                }
            ]
        });
    }

    function showResultNotOk( data ) {
        switch (data.message) {
            case "client_email_already_exists":
                $scope.uniqueEmail = false;
                serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
                break;
            case "customer_code_exists":
                showUnableToSaveError('SAME_CUSTOMER_CODE');
                break;
            default:
                serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
        }
        $scope.isSave = false;
    }

    function backToList() {
        $state.go('clients.database', {
            page: 0,
            limit: 10
        }, {reload: true});
    }
}

export default {
    type: 'controller',
    name: 'createEditCustomerController',
    value: createEditCustomerController
};
