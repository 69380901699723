/**
 * Created by olehkss on 25.08.16.
 * @ngdoc factory
 * @name app.factory: terminalOperationsFactory
 * @description
 * Links terminal to a specific user or gives back information about previously linked user
 * @requires promiseOperation
 * @returns {{linkTerminal: linkTerminal, getTerminalOwnerData: getTerminalOwnerData}}
 */

import angular from 'angular';

terminalOperationsFactory.$inject = ['promiseOperation'];

function terminalOperationsFactory(promiseOperation) {

    return {
        linkTerminal: linkTerminal,
        getTerminalOwnerData: getTerminalOwnerData,
        linkingTerminalToCashbox: linkingTerminalToCashbox,
        cancelLinkingTerminalToCashbox: cancelLinkingTerminalToCashbox,
        initTestTransaction: initTestTransaction,
        checkAuthentication: checkAuthentication
    };

    /**
     * @ngdoc function
     * @param {Object} params parameters of request, examples below
     * {
     * "terminalType":"CAYAN",
     * "merchantName":"Test Loyverse",
     * "merchantSiteId":"WJNFC7UF",
     * "merchantKey":"HPPHI-TFL80-NKXNT-724NX-JSK0F",
     * "Dba":"Loyverse"
     * "serialNumber":"111-111-111"
     *  }
     *
     * {
     *  "terminalType":"ECONDUIT",
     *  "serialNumber":"111-111-111",
     *  "businessName":"Test",
     *  "contactName":"Test Loyv Inc",
     *  "address":"1st street",
     *  "city":"New York",
     *  "state":"New York",
     *  "zipCode":"3333",
     *  "email":"test@test.com",
     *  "phone":"+380980290982"
     * }
     * @returns {Promise}
     */
    function linkTerminal(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/linkterminal',
            params,
            successLinkTerminal
        );
    }

    function successLinkTerminal(response) {

        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data;

            }
        }

    }

    /**
     * @ngdoc function
     * @param {Object} params { "terminalType":"ECONDUIT" || "CAYAN"}
     * @returns {Promise}
     */
    function getTerminalOwnerData(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getterminalownerdata',
            params,
            successGetTerminalOwnerData
        );
    }

    function successGetTerminalOwnerData(response) {

        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data.terminalData;

            }
        }

    }

    /**
     * @ngdoc function
     * @param {Object} params   {"terminalId":11, "cashRegisterId":11}
     * @returns {Promise}
     * @description
     * Links terminal to selected cashbox using theirs ids
     */
    function linkingTerminalToCashbox(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/terminaltocashregister',
            params,
            successLinkingTerminalToCashbox
        );
    }

    function successLinkingTerminalToCashbox(response) {
        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                // return response.data.terminalData;
                return response.data;

            }
        }
    }

    /**
     * @ngdoc function
     * @param {Object} params   {""terminalId": 11, "linkResponseStatus":null, "linkedCashRegisterId":null}
     * @returns {Promise}
     * @description
     * Detach terminal from a selected cashbox using their ids
     */
    function cancelLinkingTerminalToCashbox(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/rollbackterminallink',
            params,
            successCancelLinkingTerminalToCashbox
        );
    }

    function successCancelLinkingTerminalToCashbox(response) {
        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data;

            }
        }
    }

    /**
     * @ngdoc function
     * @param {Object} params   {""terminalId": 11, "cashRegisterId":11}
     * @returns {Promise}
     * @description
     * initiate test transaction with current terminal
     */
    function initTestTransaction(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/runinittransaction',
            params,
            successInitTestTransaction
        );
    }

    function successInitTestTransaction(response) {
        if (angular.isObject(response.data)) {

            if (response.data.result === 'ok') {

                return response.data;

            }
        }
    }



    function checkAuthentication(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/checkpaymentsystemcredentials',
            params,
            successcheckAuthentication
        );
    }
    function successcheckAuthentication(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data;
            }
        }
    }


}

export default {
    type: 'factory',
    name: 'terminalOperationsFactory',
    value: terminalOperationsFactory
};