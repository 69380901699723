/**
 * Created by K.Yarosh on 16.11.2017.
 * @description helps to share $scope between controllers, e.g. if we want to split too massive controller into several ones but still for one state;
 * @memberof shareScope
 * @ngdoc service
 * @name shareScope
 * @this
 */

shareScope.$inject = ['$rootScope'];
function shareScope($rootScope) {

    var sharingScopeService = {};

    sharingScopeService.sharedScope = '';

    sharingScopeService.prepForBroadcast = function(scope){
        this.sharedScope = scope;
        this.broadCastScope();
    };

    sharingScopeService.broadCastScope = function(){
        $rootScope.$broadcast('handleBroadCast');
    }

    return sharingScopeService;

}

export default {
    type: 'service',
    name: 'shareScope',
    value: shareScope
};