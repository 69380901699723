/**
 * Created by sirosh on 25.01.16.
 * @memberof getReportTax
 * @ngdoc factory
 * @name getReportTax
 * @requires $http {service} http service
 * @requires $q {service} promise service
 * @requires factoryErrorAlert {service} error handler service
 * @returns setCommentData{Promise} set comment data
 * @returns setViewed{Promise} set comment as viewed
 */

getReportTax.$inject = ["$http", "$q", "factoryErrorAlert"];

function getReportTax($http, $q, factoryErrorAlert) {

    var data = {};

    function success(response) {
        data = response.data;
    }

    function getReportTaxData(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/gettaxreport', params).then(function (d) {
                    if(d.data.result === "ok"){
                        success(d);
                        resolve(data);
                    } else{
                        factoryErrorAlert.onError(d);
                        reject(d);
                    }

            }, function (error) {
                reject(error);
            });
        });
    }

    return {
        getReportTaxData: getReportTaxData
    };


}

export default {
    type: 'factory',
    name: 'getReportTax',
    value: getReportTax
};