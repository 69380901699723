/**
 * Created by Kiskenbassker on 4.06.18
 */

import template from '../templates-pug/directive-img-delete.pug';

imgDelete.$inject = [];
function imgDelete() {

    return {
        restrict: 'E',
        scope: {
            imageData: "=",
            isImageDeleted: "=",
        },
        template: template,
        controller: imgDeleteController
    };


}

imgDeleteController.$inject = ['$scope'];
function imgDeleteController($scope) {

    $scope.onDelete = function (ev) {
        ev.stopPropagation();
        $scope.imageData = null;
        $scope.isImageDeleted = true;
    }
}

export default imgDelete;
