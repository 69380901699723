/**
 * Created by mars on 11/12/15.
 * Edited by kseniya.yarosh 13.12.2016
 */

import angular from 'angular';

factoryReceipts.$inject = ['$http', '$q', 'factoryErrorAlert', 'resolveTimezone'];

function factoryReceipts($http, $q, factoryErrorAlert, resolveTimezone) {
	var promise;

	var exportParams = {};

	var report = {
		req: {
			limit: 10,
			offset: 0,
			receiptType: null,
			payType: null,
			startDate: null,
			endDate: null,
			// type: null,
			search: null
		},
		res: {},
		pager: {
			limit: 10,
			page: 0
		},
		loading: false,
		isLoad: false

	};

	var receiptType = {
		all: null,
		sale: "SALE",
		refund: "REFUND",
		loyalty: "LOYALTY",
		discount: "DISCOUNT"
	};

	function success( resolve, res ) {
		if ( report.res.receipts )  report.res.receipts.length = 0;
		for ( var opt in res.data ) {
			report.res[opt] = res.data[opt];
		}
		report.res.offset = report.req.offset;


		var receipts = report.res.receipts;

		var i = receipts ? receipts.length : 0;
		while ( 0 < i-- ) {
			receipts[i]._classes = [];

			if (receipts[i].type === 'SALE'){
				receipts[i]._classes.push('sale');
				if (receipts[i].clientId){
					receipts[i]._classes.push('loyalty');
				}
			}

			if (receipts[i].type === 'REFUND'){
				receipts[i]._classes.push('refund');
				if (receipts[i].clientId){
					receipts[i]._classes.push('loyalty');
				}
			}
		}


		resolve( report.res );
		report.isLoad = true
	}


	var tz = jstz.determine();

	function getReceipts(stateParams){
		return $q(function(resolve, reject){

			report.pager.page = parseInt(stateParams.offset/report.pager.limit)+1;

			report.req.limit 		= stateParams.limit;
			report.req.page 		= stateParams.page || report.req.page;
			report.req.startDate 	= stateParams.from || report.req.startDate;
			report.req.endDate		= stateParams.to || report.req.endDate;
			report.req.tzOffset 	=  resolveTimezone.timeZoneOffsetForReports();
			report.req.tzName 		=  tz.name();
			report.req.startTime	= (stateParams.fromHour === 0 && stateParams.toHour === 0) ? null : stateParams.fromHour;
			report.req.endTime 		= (stateParams.fromHour === 0 && stateParams.toHour === 0) ? null : stateParams.toHour;
			report.req.receiptType	= stateParams.type ? receiptType[stateParams.type] : report.req.receiptType;
			report.req.startWeek	= stateParams.startWeek;
			report.req.receiptId	= stateParams.receiptId;
			//my line
			if (stateParams.type === "all" || stateParams.type === "loyalty") {
				exportParams.type = null;
			} else {
				exportParams.type = stateParams.type ? receiptType[stateParams.type] : report.req.receiptType;
			}

			report.req.offset = (typeof stateParams.offset == 'number') ? stateParams.offset : report.req.offset;

			//my line
			exportParams.loyalty = (report.req.receiptType === "LOYALTY" || stateParams.type === "loyalty") ? true : null;

			report.req.predefinedPeriod = stateParams.predefinedPeriod;
			report.req.customPeriod = stateParams.customPeriod;

			report.req.merchantsIds = (stateParams.merchantsIds && stateParams.merchantsIds !== "clear" && stateParams.merchantsIds !== "all") ? stateParams.merchantsIds.split(',') : (stateParams.merchantsIds === "clear") ? [] : (stateParams.merchantsIds === "all") ? "all" : null;
			report.req.outletsIds = (stateParams.outletsIds && stateParams.outletsIds !== "clear" && stateParams.outletsIds !== "all") ? stateParams.outletsIds.split(',') : (stateParams.outletsIds === "clear") ? [] : (stateParams.outletsIds === "all") ? "all" : null;

			if( typeof stateParams.search!== 'undefined' ){
				report.req.search =  stateParams.search;
			} else {
				report.req.search = null;
			}

			var _data = angular.copy( report.req );


			for ( var opt in _data ) {
				if ( opt.match( /^_/ ) ) {
					delete  _data[opt];
				}
			}

			if(_data.offset >= 0 || _data.offset === -10){
				if(_data.offset === -10){
					_data.offset = 0;
				}

				$http
					.post('/data/ownercab/getreceiptsarchive',_data)
					.then(function(res){
						success(resolve, res);
					}, function(err){
						reject(err);
					});
			}
		});

	}

	return {
		page: report.req.offset / report.req.limit,
		getReceipts: getReceipts,
		pager: report.pager,
		report: report.res,
		//my line
		exportParams: exportParams
	}

}

export default {
	type: 'factory',
	name: 'factoryReceipts',
	value: factoryReceipts
};
