/**
 * Сервис содержит методы, имеющие отношение к работе со строками
 * Created by Shestakov on 15.01.20
 */

import angular from 'angular';

serviceStrings.$inject = ['declensionsConst'];

function serviceStrings(declensionsConst) {

    var declensions = angular.copy(declensionsConst);

    /**
     * Метод возвращает константу с информацией о склонении слова согласно правилам русского языка, в зависимости от введенного числа
     * @param number число, в зависимости от которого вычисляется склонение (например, 2 товарА, 15 товарОВ)
     * @returns константа
     */
    function getDeclensions(number) {
        var numberLastTwoSymbols,
            numStr = number.toString(),
            count = numStr.length,
            numberLastSymbol = parseInt(numStr.substring(count - 1), 10);

        if(numStr.length > 1) {
            numberLastTwoSymbols = parseInt(numStr.substring(count - 2), 10);
        } else {
            numberLastTwoSymbols = numberLastSymbol;
        }

        var twoToFourCondition = numberLastSymbol > 1 && numberLastSymbol < 5 && (numberLastTwoSymbols > 20 || numberLastTwoSymbols < 5);

        if(number === 1) {
            return declensions.onlyOne;
        }
        if(numberLastSymbol === 1 && number !== 1) {
            return declensions.everyFirst;
        }
        if(twoToFourCondition) {
            return declensions.twoToFour;
        }

        return declensions.fiveToTen;
    }

    this.getDeclensions = getDeclensions;
}

export default {
    type: 'service',
    name: 'serviceStrings',
    value: serviceStrings
};