function filterPercent() {
	var $delimiter = ".";
	return function ( val, delimiter ) {
		val = parseFloat( val );
		val = val / 100;
		val = val.toString().replace(/,|\./, delimiter || $delimiter);
		return val;
	}
}

export default {
	type: 'filter',
	name: 'filterPercent',
	value: filterPercent
};