createDirective.$inject = ['$parse'];

function createDirective($parse) {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, ngModelCtrl) {
        if (!ngModelCtrl) {
            return;
        }

        let isValidFn = $parse(attrs['lvCustomValidator']);

        ngModelCtrl.$validators.custom = function(modelValue, viewValue) {
            let value = modelValue || viewValue;
            return isValidFn(scope, { value: value, viewValue: viewValue });
        };
    }
}

export default {
    type: 'directive',
    name: 'lvCustomValidator',
    value: createDirective
};