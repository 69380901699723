/**
 * Created by kseniya.yarosh on 30.08.17.
 * @memberof employees
 * @ngdoc controller
 * @name createEmployeeTrackCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter service
 * @requires $state {service} UI Router service
 * @requires $stateParams {service} UI Router service
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 */

import angular from 'angular';
import * as Sentry from "@sentry/browser";

createEditTimeCardCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$filter",
    "$state",
    "$stateParams",
    "promiseOperation",
    "serviceDialog",
    "serviceToast",
    '$getActualStaff',
    'timeTrackingFactory',
    '$getTimeCardById',
    '$previousStateParams',
    'filtersData',
    'lodash',
    '$timeout',
    'dateCustomMethods',
    '$hoursOptionsShow',
    'retrieveLangCode',
    'dialogs'
];

function createEditTimeCardCtrl($scope,
                                $rootScope,
                                $filter,
                                $state,
                                $stateParams,
                                promiseOperation,
                                serviceDialog,
                                serviceToast,
                                $getActualStaff,
                                timeTrackingFactory,
                                $getTimeCardById,
                                $previousStateParams,
                                filtersData,
                                lodash,
                                $timeout,
                                dateCustomMethods,
                                $hoursOptionsShow,
                                retrieveLangCode,
                                dialogs
) {

    let vm = this,
        newDate = dateCustomMethods.setTimeToZero(new Date()),
        translate = $filter('translate'),
        secondsToHours = $filter('secondsToHours'),
        id,
        deletedMessage,
        params,
        goBackToTimeCards;

    const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    vm.onCancel = onCancel;
    vm.onSave = onSave;
    vm.onDel = onDel;
    vm.clockInFormatted = null;
    vm.clockOutFormatted = null;
    $scope.maxDate = new Date();

    // console.log("$previousStateParams =", $previousStateParams);

    // данные для сохранение состояния чекбоксов и фильтров
    vm.stateFromList = $stateParams.stateToSave;
    // console.log("stateFromList =", vm.stateFromList);
    vm.checkFromList = $stateParams.checkToSave;
    // console.log("checkFromList =", vm.checkFromList);
    // данные для сохранение состояния чекбоксов и фильтров

    //for staff filters
    $scope.actualMerchants = angular.copy($getActualStaff.merchants);
    $scope.initialOutlets = angular.copy($getActualStaff.outlets);
    $scope.actualOutlets = $scope.initialOutlets;
    $scope.actualOutlet = {};
    $scope.startDate = new Date(newDate);
    $scope.endDate = new Date(newDate);


    //for date-picker
    $scope.startDateId = document.getElementById('startDateInput').id || "startDateInput";
    $scope.endDateId = document.getElementById('endDateInput').id || "endDateInput";

    $scope.hoursOptionsShow = $hoursOptionsShow;

    //depending on a current state
    if ($state.current.name === "employees.createtimecard") {

        $scope.actualMerchantId = null;
        $scope.actualOutletId = null;
        $scope.clockInTime = null;
        $scope.clockOutTime = null;
        $scope.showSelect = true;

    } else if ($state.current.name === "employees.edittimecard") {

        vm.timeCardData = angular.copy($getTimeCardById);
        id = vm.timeCardData.id;
        vm.historyEvents = vm.timeCardData.historyEvents;
        vm.isShowHistory = vm.timeCardData.isShowHistory;

        // var dateInputPicker = angular.element(document).find('date-picker-input').childScope;
        // console.log(dateInputPicker);
        $scope.startDate = (vm.timeCardData.clockIn && vm.timeCardData.clockIn !== null) ? new Date(vm.timeCardData.clockIn) : new Date(newDate);
        $scope.endDate = (vm.timeCardData.clockOut && vm.timeCardData.clockOut !== null) ? new Date(vm.timeCardData.clockOut) : new Date(newDate);
        // $scope.clockInTime = vm.timeCardData.clockIn !== null ? new Date(vm.timeCardData.clockIn) : new Date(newDate);
        $scope.clockInTime = vm.timeCardData.clockIn !== null ? (new Date($scope.startDate)) : null;
        // $scope.clockOutTime = vm.timeCardData.clockOut !== null ? new Date(vm.timeCardData.clockOut) : new Date(newDate);
        $scope.clockOutTime = vm.timeCardData.clockOut !== null ? (new Date($scope.endDate)) : null;
        $scope.merchantName = ($getTimeCardById.merchantName !==null) ? $getTimeCardById.merchantName : translate('OWNER');
        $scope.outletName = $getTimeCardById.outletName;
        $scope.actualOutletId = $getTimeCardById.outletId;
        $scope.actualMerchantId = $getTimeCardById.merchantId;
        $scope.showInput = true;
    }

    $scope.isMobile = $rootScope.isMobile;
    // $scope.isMobile = false;
    $rootScope.is12HourFormat =  $scope.is12HourFormat = retrieveLangCode.defineWhetherItIs12HourFormat($scope.startDate);

    if ($scope.is12HourFormat === true) {

        $scope.timeFormatSuffixClockIn = retrieveLangCode.retrieveDayPart($scope.startDate);
        $scope.timeFormatSuffixClockOut = retrieveLangCode.retrieveDayPart($scope.endDate);

    } else {
        $scope.timeFormatSuffixClockIn = null;
        $scope.timeFormatSuffixClockOut = null;
    }


    if($scope.is12HourFormat === true){
        $scope.timeValid = "time12H";
    } else {
        $scope.timeValid = "time24H";
    }


    // $scope.clockInFormattedTime = angular.isDate($scope.clockInTime) ? $filter('formatTimeByBrowser')($scope.clockInTime, 'short') : $scope.clockInTime;
    // $scope.clockOutFormattedTime = angular.isDate($scope.clockOutTime) ? $filter('formatTimeByBrowser')($scope.clockOutTime, 'short') : $scope.clockOutTime;
    if($scope.isMobile === false){
        $scope.clockInFormattedTime = $filter('formatTimeByBrowser')($scope.clockInTime, 'short');
        $scope.clockOutFormattedTime = $filter('formatTimeByBrowser')($scope.clockOutTime, 'short');
    } else if($scope.isMobile === true) {
        if($scope.clockInTime){
            $scope.clockInTime.setMilliseconds(0);
            $scope.clockInTime.setSeconds(0);
        }

        if($scope.clockOutTime){
            $scope.clockOutTime.setMilliseconds(0);
            $scope.clockOutTime.setSeconds(0);
        }

        $scope.clockInTimeForMobile = $scope.clockInTime ? new Date($scope.clockInTime) : null;
        $scope.clockOutTimeForMobile = $scope.clockOutTime ? new Date($scope.clockOutTime) : null;
    }



    if($scope.clockInFormattedTime  && $scope.isMobile === false){
        if($scope.clockInFormattedTime.indexOf("AM") !== -1) {
            $scope.clockInFormattedTime = $scope.clockInFormattedTime.replace(" AM", "");
        } else if($scope.clockInFormattedTime.indexOf("am") !== -1){
            $scope.clockInFormattedTime = $scope.clockInFormattedTime.replace(" am", "");
        } else if($scope.clockInFormattedTime.indexOf("PM") !== -1) {
            $scope.clockInFormattedTime = $scope.clockInFormattedTime.replace(" PM", "");
        } else if($scope.clockInFormattedTime.indexOf("pm") !== -1){
            $scope.clockInFormattedTime = $scope.clockInFormattedTime.replace(" pm", "");
        } else {
            $scope.clockInFormattedTime = $scope.clockInFormattedTime.replace(/[^\\x00-\\xFF][\u0250-\ue007]\s/g, "");
            // console.log($scope.clockOutFormattedTime);
        }
    }

    if($scope.clockOutFormattedTime && $scope.isMobile === false){
        if($scope.clockOutFormattedTime.indexOf("AM") !== -1) {
            $scope.clockOutFormattedTime = $scope.clockOutFormattedTime.replace(" AM", "");
        } else if($scope.clockOutFormattedTime.indexOf("am") !== -1){
            $scope.clockOutFormattedTime = $scope.clockOutFormattedTime.replace(" am", "");
        } else if($scope.clockOutFormattedTime.indexOf("PM") !== -1) {
            $scope.clockOutFormattedTime = $scope.clockOutFormattedTime.replace(" PM", "");
        } else if($scope.clockOutFormattedTime.indexOf("pm") !== -1){
            $scope.clockOutFormattedTime = $scope.clockOutFormattedTime.replace(" pm", "");
        } else {
            $scope.clockOutFormattedTime = $scope.clockOutFormattedTime.replace(/[^\\x00-\\xFF][\u0250-\ue007]\s/g, "");
            // console.log($scope.clockOutFormattedTime);
        }
    }


    if($state.current.name === "employees.edittimecard" && $scope.isMobile === false && ($scope.clockInFormattedTime && $scope.clockInFormattedTime.split(":")[0].length === 1)) {
        var minutes;
        minutes = (parseInt($scope.clockInFormattedTime.split(":")[1]) === 0) ? "00" : $scope.clockInFormattedTime.split(":")[1];
        $scope.clockInFormattedTime = "0"+ parseInt($scope.clockInFormattedTime.split(":")[0]) + ":" + minutes;
    }

    if($state.current.name === "employees.edittimecard" && $scope.isMobile === false && ($scope.clockOutFormattedTime && $scope.clockOutFormattedTime.split(":")[0].length === 1)) {
        var minutes;
        minutes = (parseInt($scope.clockOutFormattedTime.split(":")[1]) === 0) ? "00" : $scope.clockOutFormattedTime.split(":")[1];
        $scope.clockOutFormattedTime = "0"+ parseInt($scope.clockOutFormattedTime.split(":")[0]) + ":" + minutes;
    }

    if($scope.isMobile === false){
        $scope.clockInTimeMilliseconds = ($scope.clockInFormattedTime !== null) ? dateCustomMethods.convertTimeStringToMilliseconds($scope.clockInFormattedTime, $scope.timeFormatSuffixClockIn) : 0;
        $scope.clockOutTimeMilliseconds = ($scope.clockOutFormattedTime !== null) ? dateCustomMethods.convertTimeStringToMilliseconds($scope.clockOutFormattedTime, $scope.timeFormatSuffixClockOut) : 0;
    } else if($scope.isMobile === true){
        $scope.clockInTimeMilliseconds = ($scope.clockInTimeForMobile !== null) ? $scope.clockInTimeForMobile - dateCustomMethods.setTimeToZero((new Date($scope.clockInTimeForMobile))) : 0;
        $scope.clockOutTimeMilliseconds = ($scope.clockOutTimeForMobile !== null) ? $scope.clockOutTimeForMobile - dateCustomMethods.setTimeToZero((new Date($scope.clockOutTimeForMobile))) : 0;
    }


    vm.clockInFormatted = dateCustomMethods.setTimeToZero(Date.parse($scope.startDate)) + $scope.clockInTimeMilliseconds;
    vm.clockOutFormatted = dateCustomMethods.setTimeToZero(Date.parse($scope.endDate)) + $scope.clockOutTimeMilliseconds;


    // console.log(new Date(vm.clockInFormatted));
    // alert(new Date(vm.clockInFormatted));
    // console.log(new Date(vm.clockOutFormatted));
    // alert(new Date(vm.clockOutFormatted));


    vm.initialTimeCardData = {
        actualMerchantId: $scope.actualMerchantId,
        actualOutletId: $scope.actualOutletId,
        startDate: $scope.startDate,
        endDate: $scope.endDate,
        clockInFormattedTime: $scope.clockInFormattedTime,
        clockOutFormattedTime: $scope.clockOutFormattedTime,
        clockInTimeForMobile: $scope.clockInTimeForMobile,
        clockOutTimeForMobile: $scope.clockOutTimeForMobile,
        clockInFormatted: $scope.clockInFormatted,
        clockOutFormatted: $scope.clockOutFormatted,
        clockInTimeMilliseconds: $scope.clockInTimeMilliseconds,
        clockOutTimeMilliseconds: $scope.clockOutTimeMilliseconds
    };

    countTotalWorkedHours();

    $scope.$watch('dateFrom', function(newVal, oldVal){
        if(newVal!== undefined && newVal !== oldVal){
            if($scope.startDateId && ($scope.datePickerId === $scope.startDateId)){
                $scope.startDate = newVal;
                recountClockInTime();
                compareClockInAndOutDateAndTime(vm.clockInFormatted, vm.clockOutFormatted);
                // compareClockInAndOutTime();
                // console.log($scope.startDate);
            } else if($scope.endDateId && ($scope.datePickerId === $scope.endDateId)){
                $scope.endDate = newVal;
                recountClockOutTime();
                compareClockInAndOutDateAndTime(vm.clockInFormatted, vm.clockOutFormatted);
                // compareClockInAndOutTime();
                // console.log($scope.endDate);
            }
        }
    });

    $scope.$watch('clockInFormattedTime', function (newVal, oldVal) {
        if (newVal!== undefined && newVal!== null && newVal !== ''){
            $scope.clockInFormattedTime = newVal;
        } else {
            $scope.clockInFormattedTime = null;
        }
        recountClockInTime();
    }, true);


    $scope.$watch('clockOutFormattedTime', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && newVal !== ''){
            $scope.clockOutFormattedTime = newVal;
        } else {
            $scope.clockOutFormattedTime = null;
        }
        recountClockOutTime();
    }, true);


    $scope.$watch('clockInTimeForMobile', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && newVal !== oldVal){
            // alert(newVal);
            $scope.clockInTimeForMobile = newVal;
            recountClockInTime();
        }
    }, true);


    $scope.$watch('clockOutTimeForMobile', function (newVal, oldVal) {
        // alert(oldVal);
        // alert(newVal);
        if(newVal!== undefined && newVal!== null && newVal !== oldVal){
            $scope.clockOutTimeForMobile = newVal;
            recountClockOutTime();
            // console.log(vm.clockOutFormatted);
        }
    }, true);

    $scope.$watch('clockInFormatted', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && newVal !== oldVal){
            vm.clockInFormatted = newVal;
            recountClockInTime();
            // console.log(vm.clockInFormatted);
        }
    }, true);


    $scope.$watch('clockOutFormatted', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && newVal !== oldVal){
            vm.clockOutFormatted = newVal;
            recountClockOutTime();
            // console.log(vm.clockOutFormatted);
        }
    }, true);

    $scope.$watch('timeFormatSuffixClockIn', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && (newVal !== oldVal) && (newVal!==oldVal)){
            recountClockInTime();
            // console.log(vm.clockInFormatted);
        }
    }, true);

    $scope.$watch('timeFormatSuffixClockOut', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && (newVal !== oldVal) && (newVal!==oldVal)){
            recountClockOutTime();
            // console.log(vm.clockOutFormatted);
        }
    }, true);


    $scope.$watch('actualOutlet', function (newVal, oldVal) {
        if(newVal!== undefined && newVal!== null && newVal !== oldVal){
            $scope.actualOutlet = newVal;
            $scope.actualOutletId = $scope.actualOutlet.id;
            // console.log($scope.actualOutlet);
        }
    }, true);

    if ($previousStateParams) {
        vm.stateParams = {
            startDate: $previousStateParams.from,
            endDate: $previousStateParams.to,
            limit: $previousStateParams.limit,
            offset: $previousStateParams.offset,
            page: $previousStateParams.page,
            merchantsIds:$previousStateParams.merchantsIds,
            outletsIds: $previousStateParams.outletsIds
        };
    }


    function countTotalWorkedHours() {
        $scope.totalWorkedHours = (($scope.isMobile === false && ($scope.clockInFormattedTime !== null && $scope.clockOutFormattedTime !== null) &&
                                    ($scope.clockInFormattedTime !== "" && $scope.clockOutFormattedTime !== "")) ||
                                    ($scope.isMobile === true && ($scope.clockInTimeForMobile !== null && $scope.clockOutTimeForMobile !== null) &&
                                    ($scope.clockInTimeForMobile !== "" && $scope.clockOutTimeForMobile !== "")))
                                    ? secondsToHours( (vm.clockOutFormatted - vm.clockInFormatted), "." )
                                    : null;
        return $scope.totalWorkedHours;
    }

    function recountClockInTime(){
        if($scope.isMobile === false){
            $scope.clockInTimeMilliseconds = ($scope.clockInFormattedTime !== null && $scope.clockInFormattedTime !== '')
                ? dateCustomMethods.convertTimeStringToMilliseconds($scope.clockInFormattedTime, $scope.timeFormatSuffixClockIn)
                : 0;
        } else if($scope.isMobile === true) {
            $scope.clockInTimeMilliseconds = ($scope.clockInTimeForMobile !== null && $scope.clockInTimeForMobile !== '')
                ?  $scope.clockInTimeForMobile - dateCustomMethods.setTimeToZero((new Date($scope.clockInTimeForMobile)))
                : 0;
        }

        let zeroDateTimeMs = dateCustomMethods.setTimeToZero(Date.parse($scope.startDate));
        let dateTimeMs = zeroDateTimeMs + $scope.clockInTimeMilliseconds;
        let timeShiftMin = new Date(dateTimeMs).getTimezoneOffset() - new Date(zeroDateTimeMs).getTimezoneOffset();

        if (timeShiftMin !== 0) {
            // Handle summertime shift
            dateTimeMs += timeShiftMin * 60000;
        }

        vm.clockInFormatted = dateTimeMs;
        // compareClockInAndOutTime(vm.clockInFormatted, vm.clockOutFormatted);
        compareClockInAndOutDateAndTime(vm.clockInFormatted, vm.clockOutFormatted);
        countTotalWorkedHours();
        // console.log("recountClockInTime + $scope.totalWorkedHours ", $scope.totalWorkedHours);
        // console.log("vm.clockInFormatted " + vm.clockInFormatted);
        // console.log("$scope.clockInTimeMilliseconds "+$scope.clockInTimeMilliseconds);
    }

    function recountClockOutTime() {
        // compareClockInAndOutTime($scope.clockInFormattedTime, $scope.clockOutFormattedTime, vm.clockInFormatted, vm.clockOutFormatted);
        if($scope.isMobile === false){
            $scope.clockOutTimeMilliseconds = ($scope.clockOutFormattedTime !== null) ? dateCustomMethods.convertTimeStringToMilliseconds($scope.clockOutFormattedTime, $scope.timeFormatSuffixClockOut) : 0;
        } else if($scope.isMobile === true) {
            $scope.clockOutTimeMilliseconds = ($scope.clockOutTimeForMobile !== null) ? $scope.clockOutTimeForMobile - dateCustomMethods.setTimeToZero((new Date($scope.clockOutTimeForMobile))) : 0;

            // alert('$scope.clockOutTimeMilliseconds ' + $scope.clockOutTimeMilliseconds);
            // console.log('$scope.clockOutTimeMilliseconds ' + $scope.clockOutTimeMilliseconds);
        }

        let zeroDateTimeMs = dateCustomMethods.setTimeToZero(Date.parse($scope.endDate));
        let dateTimeMs = zeroDateTimeMs + $scope.clockOutTimeMilliseconds;
        let timeShiftMin = new Date(dateTimeMs).getTimezoneOffset() - new Date(zeroDateTimeMs).getTimezoneOffset();

        if (timeShiftMin !== 0) {
            // Handle summertime shift
            dateTimeMs += timeShiftMin * 60000;
        }

        vm.clockOutFormatted = dateTimeMs;
        // console.log(vm.clockOutFormatted);
        // compareClockInAndOutTime(vm.clockInFormatted, vm.clockOutFormatted);
        compareClockInAndOutDateAndTime(vm.clockInFormatted, vm.clockOutFormatted);
        countTotalWorkedHours();
        // console.log("recountClockOutTime + $scope.totalWorkedHours ", $scope.totalWorkedHours);
        // console.log("vm.clockOutFormatted " + vm.clockOutFormatted);
        // console.log("$scope.clockOutTimeMilliseconds " + $scope.clockOutTimeMilliseconds);
    }


    function compareClockInAndOutDateAndTime(clockIn, clockOut) {

            $scope.errorDate = false;
            $scope.errorTime = false;
            var clockInFormattedDate = dateCustomMethods.setTimeToZero(Date.parse($scope.startDate));
            var clockOutFormattedDate = dateCustomMethods.setTimeToZero(Date.parse($scope.endDate));
            var clockInTime = $scope.clockInTimeMilliseconds;
            var clockOutTime = $scope.clockOutTimeMilliseconds;
            var reducedClockIn;
            var increasedClockOut;
            var clockInCertainDay;
            var clockOutCertainDay;
            var reducedClockInCertainDay;
            var increasedClockOutCertainDay;

            if (clockInFormattedDate > clockOutFormattedDate) {
                $scope.errorDate = true;
            } else if ((clockInFormattedDate === clockOutFormattedDate || clockInFormattedDate > clockOutFormattedDate) &&
                ($scope.isMobile === false && ($scope.clockInFormattedTime !== null && $scope.clockOutFormattedTime !== null) &&
                ($scope.clockInFormattedTime !== "" && $scope.clockOutFormattedTime !== "") ||
                ($scope.isMobile === true && ($scope.clockInTimeForMobile !== null && $scope.clockOutTimeForMobile !== null))&&
                ($scope.clockInTimeForMobile !== "" && $scope.clockOutTimeForMobile !== ""))){
                    if(clockInTime > clockOutTime){
                        $scope.errorTime = true;
                    } else if(clockInTime === clockOutTime){
                           reducedClockIn = clockIn - 1;
                           increasedClockOut = clockOut +1;
                           clockInCertainDay = new Date(clockIn).getDate();
                           clockOutCertainDay = new Date(clockOut).getDate();
                           reducedClockInCertainDay = new Date(reducedClockIn).getDate();
                           increasedClockOutCertainDay = new Date(increasedClockOut).getDate();
                           // console.log(clockInCertainDay);
                           // console.log(clockOutCertainDay);
                           // console.log(vm.clockInFormatted);
                           // console.log(vm.clockOutFormatted);
                           if (increasedClockOutCertainDay !== clockInCertainDay) {
                               if (reducedClockInCertainDay !== clockOutCertainDay) {
                                   $scope.errorTime = false;
                               } else {
                                   vm.clockInFormatted = reducedClockIn;
                                   $scope.errorTime = false;
                               }
                           } else {
                               vm.clockOutFormatted = increasedClockOut;
                               $scope.errorTime = false;
                           }
                    }
            } else {
                $scope.errorTime = false;
            }

    }


    function onDel() {

        deletedMessage = "EMPLOYEE.TIMECARD_DELETED";

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate('EMPLOYEE.DELETE_TIMECARD_TITLE'),
            content: translate('EMPLOYEE.DELETE_TIMECARD_TEXT'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary',
                    text: 'CONTINUE',
                    action: delTimeCard
                }
            ]
        });
    }

    function delTimeCard() {
        timeTrackingFactory.deleteTimeCards({ids: [id]}).then(function (data) {
            if (data.result === "ok") {
                serviceToast.show(deletedMessage, 'success');

                if (lodash.isEmpty(vm.stateFromList)) {
                    $state.go('employees.timecards', {
                        page: 0,
                        limit: 10,
                        checkFromList: vm.checkFromList
                    });
                } else {
                    $state.go('employees.timecards', {
                        page: vm.stateFromList.page,
                        limit: vm.stateFromList.limit,
                        outletsIds: vm.stateFromList.outletsIds,
                        merchantsIds: vm.stateFromList.merchantsIds,
                        checkFromList: vm.checkFromList
                    });
                }

            }
        })
    }

    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    function checkBeforeSave() {
        let element;
        if($scope.timecardForm0.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            return false;
        }
        $scope.$broadcast('validate', {});
        if (!$scope.isMobile && !$scope.clockInFormattedTime){
            element = document.getElementById('startTimeInput');
            angular.element(element.parentNode).addClass('md-input-invalid');
            return false;
        } else if ($scope.isMobile && !$scope.clockInTimeForMobile) {
            element = document.getElementById('startTimeMobile');
            angular.element(element.parentNode).addClass('md-input-invalid');
            return false;
        }
        if (!$scope.isMobile && !$scope.clockOutFormattedTime) {
            element = document.getElementById('endTimeInput');
            angular.element(element.parentNode).addClass('md-input-invalid');
            return false;
        } else if ($scope.isMobile && !$scope.clockOutTimeForMobile) {
            element = document.getElementById('endTimeMobile');
            angular.element(element.parentNode).addClass('md-input-invalid');
            return false;
        }
        return true;
    }

    $scope.isSavingNow = false;


    function onSave() {
        if (!checkBeforeSave()) {
            return;
        }

        vm.finalTimeCardData = {
            actualMerchantId: $scope.actualMerchantId,
            actualOutletId: $scope.actualOutletId,
            startDate: $scope.startDate,
            endDate: $scope.endDate,
            clockInFormattedTime: $scope.clockInFormattedTime,
            clockOutFormattedTime: $scope.clockOutFormattedTime,
            clockInTimeForMobile: $scope.clockInTimeForMobile,
            clockOutTimeForMobile: $scope.clockOutTimeForMobile,
            clockInFormatted: $scope.clockInFormatted,
            clockOutFormatted: $scope.clockOutFormatted,
            clockInTimeMilliseconds: $scope.clockInTimeMilliseconds,
            clockOutTimeMilliseconds: $scope.clockOutTimeMilliseconds
        };

        vm.notSaved = false;
        var clicked = false;

        $scope.outletIdDependingOnMultiSingleShop = ($scope.initialOutlets.length > 1) ? $scope.actualOutletId : $scope.actualOutlets[0].id; //in the case of a single shop

        params = {
            id: ($state.current.name === "employees.edittimecard") ? id : null,
            merchantId: $scope.actualMerchantId,
            outletId: $scope.outletIdDependingOnMultiSingleShop,
            clockIn: vm.clockInFormatted,
            clockOut: vm.clockOutFormatted,
            offsetTS: $rootScope.offsetTS
        };

        if ($scope.actualMerchantId && $scope.outletIdDependingOnMultiSingleShop &&
            (($scope.isMobile === false && ($scope.clockInFormattedTime !== null && $scope.clockOutFormattedTime !== null) &&
            ($scope.clockInFormattedTime !== "" && $scope.clockOutFormattedTime !== "")) ||
            ($scope.isMobile === true && ($scope.clockInTimeForMobile !== null && $scope.clockOutTimeForMobile !== null) &&
            ($scope.clockInTimeForMobile !== "" && $scope.clockOutTimeForMobile !== "") &&
            (vm.clockInFormatted <= vm.clockOutFormatted)))) {
            if($state.current.name === "employees.edittimecard" && angular.equals(vm.initialTimeCardData, vm.finalTimeCardData)){
                goBackToTimeCards = {
                    startDate: $previousStateParams.from,
                    endDate: $previousStateParams.to || 0,
                    limit: $previousStateParams.limit || 10,
                    offset: $previousStateParams.offset,
                    page: $previousStateParams.page,
                    merchantsIds:$previousStateParams.merchantsIds,
                    outletsIds: $previousStateParams.outletsIds
                };

                $state.go('employees.timecards', goBackToTimeCards);
            } else {
                $scope.isSavingNow = true;
                vm.notSaved = true;
                timeTrackingFactory.isTimeCardConflicted(params).then(function (response) {
                    if (response.isTimecardConflicted === true) {
                        serviceDialog.add({
                            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                            title: translate("EMPLOYEE.TIMECARD_CREATION"),
                            content: translate("EMPLOYEE.TIMECARD_CREATION_TEXT"),
                            buttons: [
                                {
                                    text: 'ON_CANCEL',
                                    action: function() {
                                        $scope.isSavingNow = false;
                                    }
                                },
                                {
                                    class: 'primary',
                                    text: 'ON_CREATE',
                                    action: function(){
                                        clicked = true;
                                        $scope.isSavingNow = true;
                                        save()
                                    }
                                }
                            ]
                        });

                    } else {
                        save();
                    }
                });
            }
        }
    }

    function save() {
        if (lodash.isEmpty(vm.stateFromList)) {
            goBackToTimeCards = {
                page: 0,
                limit: 10,
                checkFromList: vm.checkFromList,
                startDate: $previousStateParams.from,
                endDate: $previousStateParams.to,
                offset: $previousStateParams.offset
            }
        } else {
            goBackToTimeCards = {
                page: vm.stateFromList.page,
                limit: vm.stateFromList.limit,
                outletsIds: vm.stateFromList.outletsIds,
                merchantsIds: vm.stateFromList.merchantsIds,
                checkFromList: vm.checkFromList,
                startDate: $previousStateParams.from,
                endDate: $previousStateParams.to,
                offset: $previousStateParams.offset
            }
        }

        // Sentry message to debug situation when clockIn time is bigger then clockOut time
        if (params.clockIn > params.clockOut) {
            Sentry.captureMessage("ClockIn time " + params.clockIn + "must be less then clockOut time " + params.clockOut);
        }

        let savedMessage;
        if ($state.current.name === "employees.createtimecard") {
            savedMessage = 'EMPLOYEE.TIMECARD_CREATED';
            timeTrackingFactory.createTimeCard(params).then(function (data) {
                if (data.result === 'ok') {
                    offStateChangeStart();
                    serviceToast.show(savedMessage, 'success');
                    $state.go('employees.timecards', goBackToTimeCards );
                } else {
                    $scope.isSavingNow = false;
                }
            });
        } else if ($state.current.name === "employees.edittimecard") {
            savedMessage = 'EMPLOYEE.TIMECARD_EDITED';
            timeTrackingFactory.editTimeCard(params).then(function (data) {
                if (data.result === 'ok') {
                    offStateChangeStart();
                    serviceToast.show(savedMessage, 'success');
                    $state.go('employees.timecards', goBackToTimeCards);
                } else {
                    $scope.isSavingNow = false;
                }
            });
        }
    }

    function onCancel() {
        function goToTimeCards(){
            if (lodash.isEmpty(vm.stateFromList)) {
                $state.go('employees.timecards', {
                    page: 0,
                    limit: 10,
                    checkFromList: vm.checkFromList
                });
            } else {
                $state.go('employees.timecards', {
                    page: vm.stateFromList.page,
                    limit: vm.stateFromList.limit,
                    outletsIds: vm.stateFromList.outletsIds,
                    merchantsIds: vm.stateFromList.merchantsIds,
                    checkFromList: vm.checkFromList
                });
            }
        }
            goToTimeCards();
    }



    function isChanged() {
        vm.finalTimeCardData = {
            actualMerchantId: $scope.actualMerchantId,
            actualOutletId: $scope.actualOutletId,
            startDate: $scope.startDate,
            endDate: $scope.endDate,
            clockInFormattedTime: $scope.clockInFormattedTime,
            clockOutFormattedTime: $scope.clockOutFormattedTime,
            clockInTimeForMobile: $scope.clockInTimeForMobile,
            clockOutTimeForMobile: $scope.clockOutTimeForMobile,
            clockInFormatted: $scope.clockInFormatted,
            clockOutFormatted: $scope.clockOutFormatted,
            clockInTimeMilliseconds: $scope.clockInTimeMilliseconds,
            clockOutTimeMilliseconds: $scope.clockOutTimeMilliseconds
        };
        return !angular.equals(vm.initialTimeCardData, vm.finalTimeCardData);
    }

    $scope.$on('$destroy', function(){
        offStateChangeStart();
    });
}

export default {
    type: 'controller',
    name: 'createEditTimeCardCtrl',
    value: createEditTimeCardCtrl
};