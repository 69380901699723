
factoryForms.$inject = [];
function factoryForms() {

    // Allow the browser to set the boundary type: https://stackoverflow.com/a/39281156
    const formDataHeaders = {
        "Content-Type": undefined
    };

    return {
        formDataHeaders: formDataHeaders
    }
}

export default {
    type: 'factory',
    name: 'factoryForms',
    value: factoryForms
};