/**
 * Created by sirosh on 25.02.16.
 * Permissions data factory
 * @param {service} promiseOperation - promise operations factory
 * @returns {Promise} getEmployeeRolesList - data for employee role list
 * @returns {Promise} getPermitsList - data for permissions operations
 * @returns {Promise} getPermitsByRoleId- data for employee role card
 * @returns {Promise} getPermitsCreate - create permits role
 * @returns {Promise} getPermitsEdit - edit permits role
 * @returns {Promise} getPermitsDelete - delete permits role
 */

import angular from 'angular';

getPermits.$inject = ['promiseOperation', 'PermitsManager'];

function getPermits(promiseOperation, PermitsManager) {

    return {
        getEmployeeRolesList: getEmployeeRolesList,
        getPermitsList: getPermitsList,
        getPermitsByRoleId: getPermitsByRoleId,
        getPermitsCreate: getPermitsCreate,
        getPermitsEdit: getPermitsEdit,
        getPermitsDelete: getPermitsDelete,
        getPermitsManager: getPermitsManager
    };

    /**
     * Success employees roles list handler
     * @param {Object} response
     * @returns {{roles: Array, pages: Number}} - employees role list
     */
    function successEmployeeRoles(response){
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return {
                    roles: response.data.roles,
                    pages: response.data.pages,
                    employeeId: response.data.autoId
                };
            }
        }
    }


    /**
     * Success permits list handler
     * @param {Object} response
     * @returns {Array} - permissions list
     */
    function successPermitsList(response){
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.permissions;
            }
        }
    }

    /**
     * Success permits by id
     * @param {Object} response
     * @returns {{
     *              id: Number,
     *              name: String | null,
     *              type: String,
     *              permissions: Array,
     *              merchantsCount: Number,
     *              currentlyLogged: Boolean
     *           }}
     */
    function successPermitsById(response){
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {

                return {
                    id: response.data.id,
                    name: response.data.name,
                    type: response.data.type,
                    permissions: response.data.permissions,
                    merchantsCount: response.data.merchantsCount,
                    currentlyLogged: response.data.currentlyLogged
                };
            }
        }
    }

    /**
     * Create permits handler
     * @param {Object} response
     * @returns {{ error: String | null }}
     */
    function successCreatePole(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return {
                    error: response.data.error
                };
            }
        }
    }


    /**
     * Edit permits handler
     * @param {Object} response
     * @returns {{error: String | null }}
     */
    function successEditPole(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return {
                    error: response.data.error
                };
            }
        }
    }

    /**
     * Delete permits handler
     * @param {Object} response
     * @returns {{error: String | null}}
     */
    function successDeletePole(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    /**
     * Return employees roles list promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getEmployeeRolesList(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getmerchantsroles',
                params,
                successEmployeeRoles
            );
    }


    /**
     * Return employees roles list promise
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPermitsList(params) {
        return promiseOperation
            .getPromiseData(
                'POST',
                '/data/ownercab/getaccpermissions',
                params,
                successPermitsList
            );
    }

    /**
     * Return role params by id
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPermitsByRoleId(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getrolebyid',
                params,
                successPermitsById
            );
    }

    /**
     * Return create result
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPermitsCreate(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createrole',
                params,
                successCreatePole
            );
    }

    /**
     * Return edit result
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPermitsEdit(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editrole',
                params,
                successEditPole
            );
    }


    /**
     * Return delete result
     * @param {Object} params -  http query params object
     * @returns {Promise}
     */
    function getPermitsDelete(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/deleterole',
                params,
                successDeletePole
            );
    }

    function getPermitsManager() {
        return getPermitsList({})
            .then(list => new PermitsManager(list));
    }
}

export default {
    type: 'factory',
    name: 'getPermits',
    value: getPermits
};
