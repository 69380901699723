/**
 * Created by Alex on 24.04.2017.
 * @recording and retrieving data for formatting currency at a location owner
 * @memberof
 * @ngdoc service
 * @name currencyFormat
 */

currencyFormat.$inject = ['constantMinMaxValues', '$q', 'Observable'];
function currencyFormat(constantMinMaxValues, $q, Observable) {

    let self = this;
    let currencyOptionsEditDefer = null;
    let currencyOptionsEditModifDefer = null;
    let currencyOptionsShowDefer = null;
    let countOptionsEditDefer = null;
    let critCountOptionsEditDefer = null;
    let quantOptionsEditDefer = null;
    let countOptionsShowDefer = null;
    let countOptionsShowWithPlusDefer = null;
    let hoursOptionsShowDefer = null;
    let countOptionsEditDivisibleDefer = null;
    let countQuantityForOrderDefer = null;
    let countQuantityForOrderDivisibleDefer = null;
    let countQuantityForLabelsDefer = null;
    let currencyShowBillingDefer = null;
    let pointsCurrencyEditDefer = null;
    let pointsCurrencyShowDefer = null;
    let percentShowInfinityToInfinity = {};
    let percentShowInfinityToHundred = {};
    let percentShowZeroToHundred = {};
    let percentShowTax = {};

    let minMaxValues = constantMinMaxValues;

    reset();

    // Observable Settings
    // Returns observable settings
    // Should be used in services that not updated on state change (for example filters)
    this.observable = {
        currencyOptionsShow: new Observable(),
        countOptionsShow: new Observable(),
        hoursOptionsShow: new Observable(),
        countQuantityForOrderDivisible: new Observable(),
        currencyShowBilling: new Observable(),
    };

     // Settings getters
     // Returns settings promises
     // Can be used in state resolve

    this.getCurrencyOptionsShow = function()    {
        return currencyOptionsShowDefer.promise;
    };

    this.getCurrencyOptionsEdit = function()    {
        return currencyOptionsEditDefer.promise;
    };

    this.getCurrencyOptionsEditModif = function()    {
        return currencyOptionsEditModifDefer.promise;
    };

    this.getCountOptionsEdit = function()    {
        return countOptionsEditDefer.promise;
    };

    this.getCritCountOptionsEdit = function()    {
        return critCountOptionsEditDefer.promise;
    };

    this.getQuantOptionsEdit = function()    {
        return quantOptionsEditDefer.promise;
    };

    this.getCountOptionsShow = function()    {
        return countOptionsShowDefer.promise;
    };

    this.getCountOptionsShowWithPlus = function()    {
        return countOptionsShowWithPlusDefer.promise;
    };

    this.getHoursOptionsShow = function()    {
        return hoursOptionsShowDefer.promise;
    };

    this.getCountOptionsEditDivisible = function()    {
        return countOptionsEditDivisibleDefer.promise;
    };

    this.getCountQuantityForOrder = function()    {
        return countQuantityForOrderDefer.promise;
    };

    this.getCountQuantityForOrderDivisible = function()    {
        return countQuantityForOrderDivisibleDefer.promise;
    };

    this.getCountQuantityForLabels = function()    {
        return countQuantityForLabelsDefer.promise;
    };

    this.getPointsCurrencyEdit = function()    {
        return pointsCurrencyEditDefer.promise;
    };

    this.getPointsCurrencyShow = function () {
        return pointsCurrencyShowDefer.promise;
    };

    this.getPercentShow = function(infinityToInfinity, infinityToHundred, zeroToHundred, tax) {
        return infinityToInfinity
            ? percentShowInfinityToInfinity
            : infinityToHundred
                ? percentShowInfinityToHundred
                : zeroToHundred
                    ? percentShowZeroToHundred
                    : tax
                        ? percentShowTax
                        : {};
    };

     // Settings reset
     // Should be called before set new settings
    this.reset = reset;

     // Created by Oleksii on 10.06.2022.
     // Settings setters
    this.setCurrencyOptionsEdit = setCurrencyOptionsEdit;
    this.setCurrencyOptionsEditModif = setCurrencyOptionsEditModif;
    this.setCountOptionsEdit = setCountOptionsEdit;
    this.setCountOptionsEditDivisible = setCountOptionsEditDivisible;
    this.setCountQuantityForOrder = setCountQuantityForOrder;
    this.setCountQuantityForOrderDivisible = setCountQuantityForOrderDivisible;
    this.setCritCountOptionsEdit = setCritCountOptionsEdit;
    this.setQuantOptionsEdit = setQuantOptionsEdit;
    this.setCountQuantityForLabels = setCountQuantityForLabels;
    this.setCountOptionsShow = setCountOptionsShow;
    this.setCountOptionsShowWithPlus = setCountOptionsShowWithPlus;
    this.setHoursOptionsShow = setHoursOptionsShow;
    this.setCurrencyOptionsShow = setCurrencyOptionsShow;
    this.setCurrencyShowBilling = setCurrencyShowBilling;
    this.setPointsCurrencyEdit = setPointsCurrencyEdit;
    this.setPointsCurrencyShow = setPointsCurrencyShow;
    this.setPercentShow = setPercentShow;

    function reset() {

        currencyOptionsShowDefer = $q.defer();
        currencyOptionsShowDefer.promise.then(result => {
            self.observable.currencyOptionsShow.notify(result);
        });
        countOptionsShowDefer = $q.defer();
        countOptionsShowDefer.promise.then(result => {
            self.observable.countOptionsShow.notify(result);
        });
        hoursOptionsShowDefer = $q.defer();
        hoursOptionsShowDefer.promise.then(result => {
            self.observable.hoursOptionsShow.notify(result);
        });
        countQuantityForOrderDivisibleDefer = $q.defer();
        countQuantityForOrderDivisibleDefer.promise.then(result => {
            self.observable.countQuantityForOrderDivisible.notify(result);
        });
        currencyShowBillingDefer = $q.defer();
        currencyShowBillingDefer.promise.then(result => {
            self.observable.currencyShowBilling.notify(result);
        });

        currencyOptionsEditDefer = $q.defer();
        currencyOptionsEditModifDefer = $q.defer();
        countOptionsEditDefer = $q.defer();
        critCountOptionsEditDefer = $q.defer();
        quantOptionsEditDefer = $q.defer();
        countOptionsShowWithPlusDefer = $q.defer();
        countOptionsEditDivisibleDefer = $q.defer();
        countQuantityForOrderDefer = $q.defer();
        countQuantityForLabelsDefer = $q.defer();
        pointsCurrencyEditDefer = $q.defer();
        pointsCurrencyShowDefer = $q.defer();
    }

    function setCurrencyOptionsEdit(profile)  {

        let currencyOptionsEdit = {};

        currencyOptionsEdit.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        currencyOptionsEdit.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        currencyOptionsEdit.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        currencyOptionsEdit.decimalPlacesOverride = profile.cashFractionDigits;                                     // кол-во знаков после запятой
        currencyOptionsEdit.minimumValue = (currencyOptionsEdit.decimalPlacesOverride === 0)? minMaxValues.currency.minEditNoCoins : minMaxValues.currency.minEditCoins;   // минимальное значение
        currencyOptionsEdit.maximumValue = (currencyOptionsEdit.decimalPlacesOverride === 0)? minMaxValues.currency.maxEditNoCoins : minMaxValues.currency.maxEditCoins;  // максимальное значение
        currencyOptionsEdit.roundingMethod = 'S';
        currencyOptionsEdit.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером
        currencyOptionsEdit.currencySymbol = getCurrencySymbol(profile);  // символ валюты
        currencyOptionsEdit.currencySymbolPlacement = profile.moneyFormat.currency.onTheLeft ? 'p' : 's'; // позиция символа валюты
        currencyOptionsEdit.currencySymbolWithSpace = profile.moneyFormat.currency.withSpace;
        currencyOptionsEdit.emptyInputBehavior = "press"; // символ валюты появляется после нажатия на кнопку в поле

        currencyOptionsEditDefer.resolve(currencyOptionsEdit);
    };

    function setCurrencyOptionsEditModif(profile)  {

        let currencyOptionsEditModif = {};

        currencyOptionsEditModif.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        currencyOptionsEditModif.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        currencyOptionsEditModif.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        currencyOptionsEditModif.decimalPlacesOverride = profile.cashFractionDigits;                                     // кол-во знаков после запятой
        currencyOptionsEditModif.minimumValue = (currencyOptionsEditModif.decimalPlacesOverride === 0)? minMaxValues.currency.minEditModifNoCoins : minMaxValues.currency.minEditModifCoins; // минимальное значение
        currencyOptionsEditModif.maximumValue = (currencyOptionsEditModif.decimalPlacesOverride === 0)? minMaxValues.currency.maxEditNoCoins : minMaxValues.currency.maxEditCoins;  // максимальное значение
        currencyOptionsEditModif.roundingMethod = 'S';
        currencyOptionsEditModif.decimalCharacterAlternative = (profile.moneyFormat.decSeparator == '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером
        currencyOptionsEditModif.currencySymbol = getCurrencySymbol(profile);  // символ валюты
        currencyOptionsEditModif.currencySymbolPlacement = profile.moneyFormat.currency.onTheLeft ? 'p' : 's'; // позиция символа валюты
        currencyOptionsEditModif.currencySymbolWithSpace = profile.moneyFormat.currency.withSpace;
        currencyOptionsEditModif.emptyInputBehavior = "press";

        currencyOptionsEditModifDefer.resolve(currencyOptionsEditModif);
    };

    function setCountOptionsEdit(profile)  {
        let countOptionsEdit = {};
        countOptionsEdit.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countOptionsEdit.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        countOptionsEdit.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countOptionsEdit.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countOptionsEdit.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countOptionsEdit.minimumValue = minMaxValues.count.minEdit;                                              // минимальное значение
        countOptionsEdit.maximumValue = minMaxValues.count.maxEdit;                                              // максимальное значение
        countOptionsEdit.showWarnings = false;                                                                   // показывать ли предупреждения
        countOptionsEdit.roundingMethod = 'S';
        // countOptionsEdit.decimalCharacterAlternative = ',';                                                      // альтернативный разделитель десятичных для ввода юзером
        countOptionsEdit.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        countOptionsEditDefer.resolve(countOptionsEdit);
    };

    function setCountOptionsEditDivisible(profile)  {
        let countOptionsEditDivisible = {};
        countOptionsEditDivisible.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countOptionsEditDivisible.allowDecimalPadding = true;                                                            // заполнять ли десятичные знаки нулями
        countOptionsEditDivisible.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countOptionsEditDivisible.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countOptionsEditDivisible.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countOptionsEditDivisible.minimumValue = minMaxValues.count.minEdit;                                                              // минимальное значение
        countOptionsEditDivisible.maximumValue = minMaxValues.count.maxEdit;                                                             // максимальное значение
        countOptionsEditDivisible.showWarnings = false;                                                                   // показывать ли предупреждения
        countOptionsEditDivisible.roundingMethod = 'S';
        // countOptionsEdit.decimalCharacterAlternative = ',';                                                      // альтернативный разделитель десятичных для ввода юзером
        countOptionsEditDivisible.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        countOptionsEditDivisibleDefer.resolve(countOptionsEditDivisible);
    };

    function setCountQuantityForOrder(profile)  {
        let countQuantityForOrder = {};
        countQuantityForOrder.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countQuantityForOrder.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        countQuantityForOrder.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countQuantityForOrder.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countQuantityForOrder.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countQuantityForOrder.minimumValue = minMaxValues.count.minOrder;                                             // минимальное значение
        countQuantityForOrder.maximumValue = minMaxValues.count.maxOrder;                                            // максимальное значение
        countQuantityForOrder.showWarnings = false;                                                                   // показывать ли предупреждения
        countQuantityForOrder.roundingMethod = 'S';
        countQuantityForOrder.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        countQuantityForOrderDefer.resolve(countQuantityForOrder);
    };

    function setCountQuantityForOrderDivisible(profile)  {

        let countQuantityForOrderDivisible = {};

        countQuantityForOrderDivisible.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countQuantityForOrderDivisible.allowDecimalPadding = true;                                                            // заполнять ли десятичные знаки нулями
        countQuantityForOrderDivisible.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countQuantityForOrderDivisible.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countQuantityForOrderDivisible.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countQuantityForOrderDivisible.minimumValue = minMaxValues.count.minOrder;                                             // минимальное значение
        countQuantityForOrderDivisible.maximumValue = minMaxValues.count.maxOrder;                                                           // максимальное значение
        countQuantityForOrderDivisible.showWarnings = false;                                                                   // показывать ли предупреждения
        countQuantityForOrderDivisible.roundingMethod = 'S';
        countQuantityForOrderDivisible.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        countQuantityForOrderDivisibleDefer.resolve(countQuantityForOrderDivisible);
    };

    function setCritCountOptionsEdit(profile)  {
        let critCountOptionsEdit = {};
        critCountOptionsEdit.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        critCountOptionsEdit.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        critCountOptionsEdit.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        critCountOptionsEdit.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        critCountOptionsEdit.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        critCountOptionsEdit.minimumValue = minMaxValues.count.minOrder;                                             // минимальное значение
        critCountOptionsEdit.maximumValue = minMaxValues.count.maxEdit;                                              // максимальное значение
        critCountOptionsEdit.showWarnings = false;                                                                   // показывать ли предупреждения
        critCountOptionsEdit.roundingMethod = 'S';
        critCountOptionsEdit.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        critCountOptionsEditDefer.resolve(critCountOptionsEdit);
    };

    function setQuantOptionsEdit(profile)  {
        let quantOptionsEdit = {};
        quantOptionsEdit.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        quantOptionsEdit.allowDecimalPadding = true;                                                            // заполнять ли десятичные знаки нулями
        quantOptionsEdit.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        quantOptionsEdit.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        quantOptionsEdit.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        quantOptionsEdit.minimumValue = minMaxValues.count.minOrder;                                             // минимальное значение
        quantOptionsEdit.maximumValue = minMaxValues.count.maxEdit;                                              // максимальное значение
        quantOptionsEdit.showWarnings = false;                                                                   // показывать ли предупреждения
        quantOptionsEdit.roundingMethod = 'S';
        quantOptionsEdit.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        quantOptionsEditDefer.resolve(quantOptionsEdit);
    };

    function setCountQuantityForLabels(profile)  {
        let countQuantityForLabels = {};
        countQuantityForLabels.decimalPlacesOverride = 0;                                                              // кол-во знаков после запятой
        countQuantityForLabels.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        countQuantityForLabels.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countQuantityForLabels.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first == 3 && profile.moneyFormat.grSeparator.other == 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countQuantityForLabels.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countQuantityForLabels.minimumValue = minMaxValues.count.minLabels;                                       // минимальное значение
        countQuantityForLabels.maximumValue = minMaxValues.count.maxLabels;                                       // максимальное значение
        countQuantityForLabels.showWarnings = false;                                                                   // показывать ли предупреждения
        countQuantityForLabels.roundingMethod = 'S';
        countQuantityForLabels.decimalCharacterAlternative = (profile.moneyFormat.decSeparator == '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        countQuantityForLabelsDefer.resolve(countQuantityForLabels);
    };

    function setCountOptionsShow(profile)  {

        let countOptionsShow = {};

        countOptionsShow.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countOptionsShow.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        countOptionsShow.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countOptionsShow.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countOptionsShow.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countOptionsShow.minimumValue = minMaxValues.count.minShow;                                              // минимальное значение
        countOptionsShow.maximumValue = minMaxValues.count.maxShow;                                              // максимальное значение
        countOptionsShow.showWarnings = false;                                                                   // показывать ли предупреждения
        countOptionsShow.roundingMethod = 'S';
        countOptionsShow.overrideMinMaxLimits = "ignore";                                                        // любое максимальное и минимальное значение

        countOptionsShowDefer.resolve(countOptionsShow);
    };

    function setCountOptionsShowWithPlus(profile)  {
        let countOptionsShowWithPlus = {};
        countOptionsShowWithPlus.decimalPlacesOverride = 3;                                                              // кол-во знаков после запятой
        countOptionsShowWithPlus.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        countOptionsShowWithPlus.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        countOptionsShowWithPlus.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first == 3 && profile.moneyFormat.grSeparator.other == 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        countOptionsShowWithPlus.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        countOptionsShowWithPlus.minimumValue = minMaxValues.count.minShow;                                              // минимальное значение
        countOptionsShowWithPlus.maximumValue = minMaxValues.count.maxShow;                                              // максимальное значение
        countOptionsShowWithPlus.showWarnings = false;                                                                   // показывать ли предупреждения
        countOptionsShowWithPlus.roundingMethod = 'S';
        countOptionsShowWithPlus.overrideMinMaxLimits = "ignore";                                                        // любое максимальное и минимальное значение
        countOptionsShowWithPlus.showPositiveSign = true; // показывать плюс при позитивном значении

        countOptionsShowWithPlusDefer.resolve(countOptionsShowWithPlus);
    };

    function setHoursOptionsShow(profile)  {

        let hoursOptionsShow = {};

        hoursOptionsShow.decimalPlacesOverride = 2;                                                              // кол-во знаков после запятой
        hoursOptionsShow.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        hoursOptionsShow.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        hoursOptionsShow.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        hoursOptionsShow.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        hoursOptionsShow.minimumValue = minMaxValues.hours.min;                                                  // минимальное значение
        hoursOptionsShow.maximumValue = minMaxValues.hours.max;                                                  // максимальное значение
        hoursOptionsShow.showWarnings = false;                                                                   // показывать ли предупреждения
        hoursOptionsShow.roundingMethod = 'S';
        hoursOptionsShow.overrideMinMaxLimits = "ignore";                                                        // любое максимальное и минимальное значение

        hoursOptionsShowDefer.resolve(hoursOptionsShow);
    };

    function setCurrencyOptionsShow(profile)  {

        let currencyOptionsShow = {};
        // разделитель тысяч и миллионов
        currencyOptionsShow.digitGroupSeparator     = profile.moneyFormat.grSeparator.symbol;
        // цифровая групировка и размещение разделителя тысяч
        currencyOptionsShow.digitalGroupSpacing     = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;
        // разделитель десятичных
        currencyOptionsShow.decimalCharacter        = profile.moneyFormat.decSeparator;
        // кол-во знаков после запятой
        currencyOptionsShow.decimalPlacesOverride   = profile.cashFractionDigits;
        // минимальное значение
        currencyOptionsShow.minimumValue            = (currencyOptionsShow.decimalPlacesOverride === 0)? minMaxValues.currency.minShowNoCoins : minMaxValues.currency.minShowCoins;
        // максимальное значение
        currencyOptionsShow.maximumValue            = (currencyOptionsShow.decimalPlacesOverride === 0)? minMaxValues.currency.maxShowNoCoins : minMaxValues.currency.maxShowCoins;
        // показывать ли предупреждения
        currencyOptionsShow.showWarnings            = false;
        // метод округления
        currencyOptionsShow.roundingMethod          = 'S';
        // любое максимальное и минимальное значение
        currencyOptionsShow.overrideMinMaxLimits    = "ignore";
        // символ валюты
        currencyOptionsShow.currencySymbol = getCurrencySymbol(profile);
        // позиция символа валюты
        currencyOptionsShow.currencySymbolPlacement = profile.moneyFormat.currency.onTheLeft ? 'p' : 's';
        // есть ли пробел перед / после символа валюты
        currencyOptionsShow.currencySymbolWithSpace = profile.moneyFormat.currency.withSpace;

        currencyOptionsShow.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.') ? ',' : '.';

        currencyOptionsShowDefer.resolve(currencyOptionsShow);
    };

    function setCurrencyShowBilling(profile)  {

        let currencyShowBilling = {};
        // разделитель тысяч и миллионов *
        currencyShowBilling.groupSeparator          = profile.moneyFormat.grSeparator.symbol;
        // разделитель десятичных *
        currencyShowBilling.decimalSeparator        = profile.moneyFormat.decSeparator;
        // цифровая групировка и размещение разделителя тысяч *
        currencyShowBilling.noIndia                 = !(profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2);
        // расположение символа валюты относительно числа слева или нет
        currencyShowBilling.symbolPlaceLeft         = profile.moneyFormat.currency.onTheLeft;

        // показывать ли копейки в диалоговых окнах для этой вылюты *
        currencyShowBilling.decimalDigits           = (profile.billingInfo.billingCurrencyFormat && profile.billingInfo.billingCurrencyFormat.withDecimals)? profile.billingInfo.billingCurrencyFormat.withDecimals : '';
        // буквенное обозначение валюты для показа *
        currencyShowBilling.billingCurrency         = (profile.billingInfo.billingCurrency)? profile.billingInfo.billingCurrency : '';
        // символ валюты для показа *
        currencyShowBilling.billingSymbol           = (profile.billingInfo.billingCurrencyFormat && profile.billingInfo.billingCurrencyFormat.symbol)? profile.billingInfo.billingCurrencyFormat.symbol : '';

        currencyShowBillingDefer.resolve(currencyShowBilling);
    };

    function setPointsCurrencyEdit(profile)  {
        let pointsCurrencyEdit = {};
        pointsCurrencyEdit.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        pointsCurrencyEdit.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        pointsCurrencyEdit.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        pointsCurrencyEdit.decimalPlacesOverride = profile.cashFractionDigits;                                     // кол-во знаков после запятой
        pointsCurrencyEdit.minimumValue = (pointsCurrencyEdit.decimalPlacesOverride === 0)? minMaxValues.currency.minEditNoCoins : minMaxValues.currency.minEditCoins;  // минимальное значение
        pointsCurrencyEdit.maximumValue = (pointsCurrencyEdit.decimalPlacesOverride === 0)? minMaxValues.currency.maxShowNoCoins : minMaxValues.currency.maxShowCoins;  // максимальное значение
        pointsCurrencyEdit.roundingMethod = (pointsCurrencyEdit.decimalPlacesOverride === 0)? 'D' : 'S';
        pointsCurrencyEdit.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.') ? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        pointsCurrencyEditDefer.resolve(pointsCurrencyEdit);
    };

    function setPointsCurrencyShow(profile)  {
        let pointsCurrencyShow = {};
        pointsCurrencyShow.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        pointsCurrencyShow.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        pointsCurrencyShow.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        pointsCurrencyShow.decimalPlacesOverride = profile.cashFractionDigits;                                     // кол-во знаков после запятой
        pointsCurrencyShow.minimumValue = (pointsCurrencyShow.decimalPlacesOverride === 0)? minMaxValues.currency.minEditNoCoins : minMaxValues.currency.minEditCoins;  // минимальное значение
        pointsCurrencyShow.maximumValue = (pointsCurrencyShow.decimalPlacesOverride === 0)? minMaxValues.currency.maxShowNoCoins : minMaxValues.currency.maxShowCoins;  // максимальное значение
        pointsCurrencyShow.roundingMethod = (pointsCurrencyShow.decimalPlacesOverride === 0)? 'D' : 'S';
        pointsCurrencyShow.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.') ? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером
        pointsCurrencyShow.showWarnings = false; // показывать предупреждения в консоли
        pointsCurrencyShow.overrideMinMaxLimits = "ignore";     // игнорировать максимальные и минимальные значения

        pointsCurrencyShowDefer.resolve(pointsCurrencyShow);
    };

    function setPercentShow(profile, infinityToInfinity, infinityToHundred, zeroToHundred, tax)  {
        var returnedObj = infinityToInfinity
            ? percentShowInfinityToInfinity
            : infinityToHundred
                ? percentShowInfinityToHundred
                : zeroToHundred
                    ? percentShowZeroToHundred
                    : tax ? percentShowTax : {};

        returnedObj.decimalPlacesOverride = tax ? 5 : 2;                                                              // кол-во знаков после запятой
        returnedObj.allowDecimalPadding = false;                                                            // заполнять ли десятичные знаки нулями
        returnedObj.digitGroupSeparator = profile.moneyFormat.grSeparator.symbol;                           // разделитель тысяч и миллионов
        returnedObj.digitalGroupSpacing = (profile.moneyFormat.grSeparator.first === 3 && profile.moneyFormat.grSeparator.other === 2)? 2 : 3;  // цифровая групировка и размещение разделителя тысяч
        returnedObj.decimalCharacter = profile.moneyFormat.decSeparator;                                    // разделитель десятичных
        returnedObj.minimumValue = infinityToInfinity || infinityToHundred ? minMaxValues.percent.minInfinity : minMaxValues.percent.min;         // минимальное значение
        returnedObj.maximumValue =  infinityToInfinity ? minMaxValues.percent.maxInfinity : minMaxValues.percent.max;    // максимальное значение
        returnedObj.showWarnings = false;                                                                   // показывать ли предупреждения
        returnedObj.roundingMethod = 'S';
        returnedObj.decimalCharacterAlternative = (profile.moneyFormat.decSeparator === '.')? ',' : '.';     // альтернативный разделитель десятичных для ввода юзером

        return returnedObj;
    };

    // Returns currency symbol with left or right space if needed
    function getCurrencySymbol(profile) {
        let symbol;

        if (profile.moneyFormat.currency.onTheLeft && profile.moneyFormat.currency.withSpace) {
            symbol = `${profile.moneyFormat.currency.symbol} `;
        } else if (!profile.moneyFormat.currency.onTheLeft && profile.moneyFormat.currency.withSpace) {
            symbol = ` ${profile.moneyFormat.currency.symbol}`;
        } else {
            symbol = profile.moneyFormat.currency.symbol;
        }

        return symbol;
    }
}

export default {
    type: 'service',
    name: 'currencyFormat',
    value: currencyFormat
};
