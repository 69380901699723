/**
 * Created by mars on 9/9/15.
 */

const groupBy = [
	{
		key: 'hour',
		text: 'по часам',
		format: 'HH:mm'
	},
	{
		key: 'day',
		text: 'по дням',
		format: 'dd.MM'
	},
	{
		key: 'week',
		text: 'по неделям',
		format: 'dd.MM.yyyy'
	},
	{
		key: 'month',
		text: 'по месяцам',
		format: 'MM.yyyy'
	},
	{
		key: 'quarter',
		text: 'по кварталу',
		format: 'MM.yyyy'
	},
	{
		key: 'year',
		text: 'по годам',
		format: 'yyyy'
	}
];

export default {
	type: 'constant',
	name: 'groupBy',
	value: groupBy
};