/**
 * Created by Alex 23.11.16
 * @memberof getCashRegister
 * @ngdoc factory
 * @name getCashRegister
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param factoryErrorAlert {service} error handler service
 */

import angular from 'angular';

getCashRegister.$inject = ["$http", "$q", "factoryErrorAlert"];

function getCashRegister($http, $q, factoryErrorAlert) {


// получаем список всех касс
    function getCashRegisterList(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getcashregisters', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.registers);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем список всех касс


// получаем краткий список торговых точек
    function getShortOutlets(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getshortoutlets', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data.outlets);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// получаем краткий список торговых точек


// создаем новую кассу
    function setCashRegister(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/createcashregister', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result) {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// создаем новую кассу



// Удаление кассы
    function deleteCashRegister(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deletecashregisters', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Удаление кассы



// Получение кассы по ID
    function getCashRegisterById(params){
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/getcashregisterbyid', params).then(function (response) {

                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Получение кассы по ID




// Редактирование кассы
    function editCashRegister(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/editcashregister', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Редактирование кассы



// Deactivation of POS
    function deactivateCashRegister(params) {
        return $q(function (resolve, reject) {
            $http.post('/data/ownercab/deactivateCashRegister', params).then(function (response) {
                if (angular.isObject(response.data)) {
                    if (response.data.result === "ok") {
                        resolve(response.data);
                    }
                }
            }, function (error) {
                factoryErrorAlert.onError(error);
                reject(error);
            });
        });
    }
// Deactivation of POS


    return {
        getCashRegisterList: getCashRegisterList,
        getShortOutlets: getShortOutlets,
        setCashRegister: setCashRegister,
        deleteCashRegister: deleteCashRegister,
        getCashRegisterById: getCashRegisterById,
        editCashRegister: editCashRegister,
        deactivateCashRegister: deactivateCashRegister
    };

}

export default {
    type: 'factory',
    name: 'getCashRegister',
    value: getCashRegister
};
