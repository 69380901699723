/**
 * Created by mars on 11/5/15.
 */

factoryIsManager.$inject = ['$http', '$q', 'factoryErrorAlert'];
function factoryIsManager($http, $q, factoryErrorAlert) {

	var isManager;
	return function () {
		if ( typeof isManager != 'undefined' ) {
			return $q( function ( resolve, reject ) {
				resolve( isManager );
			} );
		} else {
			return $q( function ( resolve, reject ) {
				$http.get( '/data/ownercab/ismanager' ).then( function ( d ) {
						isManager = d.data;
						//isManager = true;
						resolve( isManager );
					}, function ( err ) {
						reject(err);
						factoryErrorAlert.onError( err );
					}
				);
			} );
		}
	};
}

export default {
	type: 'factory',
	name: 'factoryIsManager',
	value: factoryIsManager
};