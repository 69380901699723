/**
 * Created by mars on 3/25/16.
 */

boardMainMenu.$inject = ['serviceNavBoard', '$document', '$timeout'];
function boardMainMenu(serviceNavBoard, $document, $timeout) {
	return {
		controller: 'boardControl',
		link: function ( scope, el, attr, cntrl ) {
			var arrEls; //#array
			var watchParam = function () {
				return scope.serviceMenu.navBoard;
			};
			scope.$watch( watchParam, function ( val ) {

				switch ( val ) {
					case 'nav-open':
						$timeout( onEventListener );
						break;
					default:
						offEventListener();
				}
			} );

			function checkForClose( elTarget ) {
				arrEls = el.find( '*' );
				arrEls.push( el[0] );
				var i = 0;
				do {
					if ( arrEls[i] == elTarget ) {
						i = null;
						return false;
					}
				} while ( i++ < arrEls.length );
				i = null;
				return true;
			}

			function click( e ) {
				if ( checkForClose( e.target ) ) {
					var overlay = document.getElementsByClassName('droverOverlay')[0];
					if(window.innerWidth < 1600) {
						scope.serviceMenu.navBoard = 'nav-close';
					}
					if(window.innerWidth < 601) {
						overlay.style.display = 'none';
					}
					scope.$digest();
				}
			}

			function onEventListener() {
				$document.on( 'click', click );
			}

			function offEventListener() {
				$document.off( 'click', click );
			}

			scope.$on( '$destroy', offEventListener );
		}
	}
}

export default {
	type: 'directive',
	name: 'boardMainMenu',
	value: boardMainMenu
};