/**
 * Created by sirosh on 23.03.16.
 * Edited by kseniya.yarosh on 14.12.16
 */

reportShiftsCtrl.$inject = [
    '$reportShift',
    '$mdSidenav',
    'shareCashboxShift',
    '$scope',
    '$stateParams',
    '$state',
    'constantGreenExport',
    '$profile',
    '$currencyOptionsShow',
    '$percentShowTax',
    '$filter',
    '$permitsManager',
    '$miniBillingInfo',
    'serviceReports',
    '$reportCopyAllowed'
];

function reportShiftsCtrl(
    $reportShift,
    $mdSidenav,
    shareCashboxShift,
    $scope,
    $stateParams,
    $state,
    constantGreenExport,
    $profile,
    $currencyOptionsShow,
    $percentShowTax,
    $filter,
    $permitsManager,
    $miniBillingInfo,
    serviceReports,
    $reportCopyAllowed
) {
    let vm = this;

    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.reportError = $reportShift.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && $reportShift.unableToLoadData;

    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;
    vm.buttonsList = [
        {text: "SHIFTS_SUMMARY", href: "/data/ownercab/ShiftReportDownload"},
        {text: "PAY_INS_OUTS", href: "/data/ownercab/shiftExportPayInPayOut"}
    ]

    if (!vm.reportError) {
        vm.periodAllowed = $reportShift.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($reportShift.startDateString && $reportShift.endDateString) {
            let fromParse = $reportShift.startDateString.split('-');
            let toParse = $reportShift.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $reportShift.startDate;
            vm.endDate = $reportShift.endDate;
        }
        $scope.outlets = $reportShift.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        if (vm.periodAllowed) {
            vm.pages = ($reportShift.pages === 0) ? 1 : $reportShift.pages;
            vm.reportShifts = $reportShift.shifts;
            vm.selectCashboxShift = selectCashboxShift;
            let taxPercentCommon = $filter('taxPercentCommon');
            $scope.reportShift = true;

            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.reportShifts.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
                $state.go('report.shift', {page: vm.pages - 1});
            } else if ((vm.reportShifts.length === 0 && $stateParams.page > 0 && vm.pages === 0)) {
                $state.go('report.shift', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            /*** формат отображения процентов ***/
            $scope.percentShowTax = $percentShowTax;

            vm.reportShifts.forEach(
                function (shift) {
                    if (shift.taxes.length > 0) {
                        shift.taxes.forEach(
                            function (tax) {
                                tax.value = taxPercentCommon(tax.value, $scope.currencyOptionsShow.decimalCharacter);
                            }
                        )
                    }
                }
            );

            function selectCashboxShift(cashboxShift) {
                if (!$scope.arapro) {
                    $mdSidenav('right').open();
                } else {
                    $mdSidenav('left').open();
                }
                shareCashboxShift.setCashboxShift(cashboxShift);
                shareCashboxShift.setCurrencyShow($scope.currencyOptionsShow);
                shareCashboxShift.setReceiptFormat($profile.receiptFormat);
                shareCashboxShift.setPercentShowTax($scope.percentShowTax);
            }
        }
    }
}

export default {
    type: 'controller',
    name: 'reportShiftsCtrl',
    value: reportShiftsCtrl
};
