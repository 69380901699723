import billingMessageTemplate from '../templates-pug/billing-message.pug';
import angular from "angular";

billingMessage.$inject = [];

function billingMessage() {
    return {
        restrict: 'E',
        template: billingMessageTemplate,
        scope: {
            unlimitedSalesHistory: '<?',
            source: '@lvSource'
        },
        bindToController: true,
        controller: billingMessageCtrl,
        controllerAs: 'billingMessageCtrl'
    }
}

billingMessageCtrl.$inject = ['serviceBillingSalesHistory'];

function billingMessageCtrl(serviceBillingSalesHistory) {
    let self = this;

    self.$onInit = function () {
        if (!self.unlimitedSalesHistory.enabled) {
            self.billingData = angular.copy(self.unlimitedSalesHistory.billingData);

            self.errorChargebee = !self.billingData || self.billingData.message === "temporary_unavailable";

            let subscriptionStatus = self.billingData.subscriptions.SALESHISTORY.status;
            self.beforeTrial = subscriptionStatus === 'NONE';

            const emptyPlan= {id:null, cycle:null, unlim:null, price:null};

            (!self.errorChargebee && self.billingData.subscriptions.SALESHISTORY && self.billingData.subscriptions.SALESHISTORY.plan === null) ? self.billingData.subscriptions.SALESHISTORY.plan = angular.copy(emptyPlan) : "";

            serviceBillingSalesHistory.setBillingData(self.billingData);
            self.subscribeSalesHistory = serviceBillingSalesHistory.subscribeSalesHistory
            self.checkIfPlanAllowed = serviceBillingSalesHistory.checkIfPlanAllowed;
        }
    }
}

export default billingMessage;
