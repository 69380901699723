/**
 * Created by sirosh on 25.01.16.
 * Edited by olehkss 13.07.2016
 * Edited by kseniya.yarosh 13.12.2016
 * @memberof reportTaxCtrl
 * @ngdoc controller
 * @name app.controller: reportTaxController
 * @requires $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 * @requires $reportTax {service} get data from ui router resolve
 * @requires constTaxDigitOptions {service} Get options for resize digit pixels size
 */

reportTaxCtrl.$inject = [
    '$reportTax',
    '$scope',
    '$stateParams',
    '$state',
    '$currencyOptionsShow',
    '$timeout',
    'constantGreenExport',
    '$profile',
    'arrayOperation',
    '$percentShowTax',
    '$filter',
    'serviceReports',
    '$permitsManager',
    '$miniBillingInfo',
    '$reportCopyAllowed'
];

function reportTaxCtrl(
    $reportTax,
    $scope,
    $stateParams,
    $state,
    $currencyOptionsShow,
    $timeout,
    constantGreenExport,
    $profile,
    arrayOperation,
    $percentShowTax,
    $filter,
    serviceReports,
    $permitsManager,
    $miniBillingInfo,
    $reportCopyAllowed
) {
    let vm = this;

    $scope.blockDataCopy = !$reportCopyAllowed;
    vm.reportTax = $reportTax;
    vm.reportError = vm.reportTax.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && vm.reportTax.unableToLoadData;

    vm.downloadHrefBase = '/data/ownercab/taxesreportdownload';

    let taxPercentFormatShowInEdit = $filter('taxPercentFormatShowInEdit');

    //change color by btn Export
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    if (!vm.reportError) {
        vm.periodAllowed = $reportTax.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($reportTax.startDateString && $reportTax.endDateString) {
            let fromParse = $reportTax.startDateString.split('-');
            let toParse = $reportTax.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $reportTax.startDate;
            vm.endDate = $reportTax.endDate;

        }
        $scope.outlets = $reportTax.fillSelectData.outlets;
        $scope.merchants = serviceReports.getMerchantsList($scope.outlets);

        if (vm.periodAllowed) {
            if ($reportTax.taxInfo) {
                vm.taxInfo = $reportTax.taxInfo.map(function (item) {
                    return {
                        name: item.name,
                        taxCollected: item.taxCollected,
                        taxSales: item.taxSales,
                        value: parseFloat(taxPercentFormatShowInEdit(item.value))
                    }
                });
            } else {
                vm.taxInfo = $reportTax.taxInfo;
            }

            vm.pages = $reportTax.pages;

            // Локализация процентов для налогов
            $scope.percentShowTax = $percentShowTax;

            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.reportTax.taxInfo && (vm.reportTax.taxInfo.length === 0 && $stateParams.page > 0 && vm.pages > 0)) {
                $state.go('report.tax', {page: vm.pages - 1});
            } else if (vm.reportTax.taxInfo && (vm.reportTax.taxInfo.length === 0 && $stateParams.page > 0 && vm.pages === 0)) {
                $state.go('report.tax', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

            vm.digits = [
                $reportTax.netSales,
                $reportTax.nonTaxableSales,
                $reportTax.taxableSales
            ];

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;
        }
    }
}

export default {
    type: 'controller',
    name: 'reportTaxController',
    value: reportTaxCtrl
};
