/**
 * Created by mars on 11/3/15.
 */

cropperControl.$inject = ['$scope'];
function cropperControl($scope) {

	$scope.bitmap = {};

	$scope.onCancel = function () {
		$scope.$parent.imgSrc = null;
		const loader = document.getElementsByClassName('mask-loader')[0];
		if (loader && angular.isObject(loader.style)) {
			loader.style.display = "none";
		}
	};

	var toSize = parseInt($scope.maxSize);
	var factor = parseInt( $scope.validRatioSize );
	var fromSize = parseInt($scope.minSize);
	var areaSizeWidth;
	var scale;

	var canvas;
	$scope.onOk = function () {

		areaSizeWidth = $scope.bitmap.areaSize.width;
		scale = $scope.bitmap.img.clientWidth / $scope.bitmap.img.naturalWidth;

		if ( canvas ) {
			//document.body.removeChild( canvas )
		}
		canvas = document.createElement( 'canvas' );

		var ctx = canvas.getContext( '2d' );
		canvas.width = parseInt( $scope.bitmap.areaSize.width / scale);
		canvas.height = parseInt( $scope.bitmap.areaSize.height / scale);
		// $scope.bitmap.img.setAttribute( 'crossorigin', 'anonymous' );

		// var opts = [
		// 	$scope.bitmap.img,
		// 	parseInt( (-1) * $scope.bitmap.areaPosition.left / scale ),
		// 	parseInt( (-1) * $scope.bitmap.areaPosition.top / scale ),
		// 	$scope.bitmap.naturalWidth,
		// 	$scope.bitmap.naturalHeight
		// ];

		// ctx.drawImage.apply(ctx, opts);
		ctx.drawImage($scope.bitmap.img, $scope.bitmap.areaPosition.left / scale, $scope.bitmap.areaPosition.top / scale, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
		var rr = canvas.toDataURL();
		var divContainer = document.createElement('div' );
		divContainer.style.position='absolute';
		divContainer.style.top='0';
		divContainer.style.left='0';
		divContainer.style.zIndex = '40';
		divContainer.appendChild(canvas);
		divContainer.appendChild($scope.bitmap.img);


		var toSizeImg = new Image();
		toSizeImg.onload = function () {
			var _canvas = document.createElement( 'canvas' );
			_canvas.style.position = 'absolute';
			_canvas.style.top = '0';
			_canvas.style.zIndex = '40';
			var b64;
			if ( this.naturalWidth < fromSize) {
				_canvas.width = fromSize;
				_canvas.height = fromSize / factor;
				var ctx = _canvas.getContext( '2d' );
				ctx.drawImage( toSizeImg, 0, 0, fromSize, fromSize / factor );
				b64 = _canvas.toDataURL();
			} else if ( this.naturalWidth < toSize ) {
				//serviceToast.show( 'Размер не может быть меньше ' + toSize + 'px', 'error' );
				_canvas.width = this.naturalWidth;
				_canvas.height = this.height;
				var ctx = _canvas.getContext( '2d' );
				ctx.drawImage( toSizeImg, 0, 0, _canvas.width, _canvas.height );
				b64 = _canvas.toDataURL();
			} else {
				_canvas.width = toSize;
				_canvas.height = toSize / factor;
				var ctx = _canvas.getContext( '2d' );
				ctx.drawImage( toSizeImg, 0, 0, toSize, toSize / factor );
				b64 = _canvas.toDataURL();
			}
			$scope.$parent.imgSrc = b64;
			$scope.$parent.$parent.$parent.isGifImg = false;
			$scope.$parent.$parent.$digest();

			//document.body.appendChild(canvas);
		};
		toSizeImg.src = rr;


	};
}

export default cropperControl;
