/**
 * Created by A.Sirosh on 16.10.2015.
 * @memberof formatDigit
 * @ngdoc filter
 * @name formatDigit
 */

function formatDigit() {
    function format(val, delimiter) {

        if (val !== undefined && val !== null) {
            var delimeterDef = delimiter || ',';
            var first, second, third, modulo, result, len, minus = '';
            var formatVal = val.toString();
            if (delimiter === ',') {
                formatVal = formatVal.replace('.', ',');
            }

            var separator = ' ';

            if (formatVal.indexOf(delimeterDef) !== -1) {
                var arr = formatVal.split(delimeterDef);

                //if we have negative value of number we will trim the minus
                if (arr[0].indexOf('-') === 0) {
                    minus = '-';
                    formatVal = arr[0].substr(1);
                } else {
                    formatVal = arr[0];
                }

                modulo = delimeterDef + arr[1];
            } else {
                modulo = '';
            }

            len = formatVal.length;

            if (len === 4) {
                first = formatVal.slice(0, 1);
                second = formatVal.substr(1);
                result = first + separator + second + modulo;
            } else if (len === 5) {
                first = formatVal.slice(0, 2);
                second = formatVal.substr(2);
                result = first + separator + second + modulo;
            } else if (len === 6) {
                first = formatVal.slice(0, 3);
                second = formatVal.substr(3);
                result = first + separator + second + modulo;
            } else if (len === 7) {
                first = formatVal.slice(0, 1);
                second = formatVal.slice(1, 4);
                third = formatVal.substr(4);
                result = first + separator + second + separator + third + modulo;
            } else if (len === 8) {
                first = formatVal.slice(0, 2);
                second = formatVal.slice(2, 5);
                third = formatVal.substr(5);
                result = first + separator + second + separator + third + modulo;
            } else {
                result = formatVal + modulo;
            }

            return minus + result;

        }
    }

    return format;
};

export default {
    type: 'filter',
    name: 'formatDigit',
    value: formatDigit
};