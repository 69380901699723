/**
 * @ngdoc module
 * @name lv.datePicker
 * Дейт пикер
 */

import angular from 'angular';
import ngTranslate from 'angular-translate';
import localDays from './constant/lv-constant-local-days';
import constantDatePicker from './service/lv-constant-date-picker';
import serviceDatePickerDaysWeek from './service/service-date-picker-days-week';
import serviceDatePickerEvents from './service/serviceDatePickerEvents';
import factoryDatePickerPosition from './factory/factory-datePicker-position';
import datePickerSettings from './factory/factory-datePicker-settings';
import formatDatePickerMonth from './factory/format-datePicker-Month';
import datePickerDaysweek from './directive/directive-date-picker-daysweek';
import myDatePickerMonthContainer from './directive/directive-date-picker-month-container';
import datePickerDay from './directive/directive-date-picker-day';
import datePicker from './directive/directive-date-picker';
import datePickerLabelTemplate from './directive/directive-date-picker-label';
import datePickerValid from './directive/directive-date-picker-valid';
import datePickerView from './directive/directive-date-picker-view';
import dpInfoLabelAfter from './directive/directive-dp-info-label-after';
import dpInfoLabelExact from './directive/directive-dp-info-label-exact';
import datePickerContrl from './controller/datePickerContrl';
import moduleRun from './lv-date-picker.run';

import './scss/date-picker.scss';

export default angular
    .module( 'lv.datePicker', [ngTranslate] )
    .constant('localDays', localDays)
    .service('constantDatePicker', constantDatePicker)
    .service('serviceDatePickerDaysWeek', serviceDatePickerDaysWeek)
    .service('serviceDatePickerEvents', serviceDatePickerEvents)
    .factory('factoryDatePickerPosition', factoryDatePickerPosition)
    .factory('datePickerSettings', datePickerSettings)
    .factory('formatDatePickerMonth', formatDatePickerMonth)
    .directive('datePickerDaysweek', datePickerDaysweek)
    .directive('myDatePickerMonthContainer', myDatePickerMonthContainer)
    .directive('datePickerDay', datePickerDay)
    .directive('datePicker', datePicker)
    .directive('datePickerLabelTemplate', datePickerLabelTemplate)
    .directive('datePickerValid', datePickerValid)
    .directive('datePickerView', datePickerView)
    .directive('dpInfoLabelAfter', dpInfoLabelAfter)
    .directive('dpInfoLabelExact', dpInfoLabelExact)
    .controller('datePickerContrl', datePickerContrl)
	.run(moduleRun)
    .name;