/**
 * Created by Kiskenbassker on 19.02.18
 */

import angular from 'angular';

itemsAutocomplete.$inject = ['serviceDialog', '$filter', '$q'];

function itemsAutocomplete(serviceDialog, $filter, $q) {

    var searchDefer,
        translate = $filter('translate');

    this.searchModels = {
        searchText: null,
        selectedItem: null
    };

    this.getMatches = function (query, outletId, factoryCallback) {
       searchDefer = $q.defer();
        factoryCallback({
               search: query,
               outletId: outletId
           })
           .then(function (items) {
               searchDefer.resolve(items);
           });
       return searchDefer.promise;
    };

    this.selectedItemChange = function(item, itemObjCallback, items, sameText) {
       if(item) {
           var itemsLength = items.length;
           var itemObj = itemObjCallback(item);
           if(itemsLength !== 0) {
               for (var i = 0; i < itemsLength; i++) {
                   if (item.id === items[i].wareId) {
                       serviceDialog.add({
                           templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                           content: sameText,
                           buttons: [
                               {
                                   text: 'ON_OK',
                                   action: function () {
                                       return true;
                                   }
                               }
                           ]
                       });
                       break;
                   } else if (i === itemsLength - 1) {
                       pushItemToArray(item, itemObj, items);
                   }
               }
           } else {
               pushItemToArray(item, itemObj, items);
           }
           $('[name="autocompleteItems"]').blur();
           this.searchModels.selectedItem = null;
           this.searchModels.searchText = null;
       }
    };

   function pushItemToArray(item, itemObj, items) {
       items.push(itemObj);
   }

   this.deleteItem = function (item, items, deletedItems) {
       angular.forEach(items, function (val, i) {
           if (item == val) {
               if(deletedItems && item.id && item.id !== null) {
                   deletedItems.push(item.id);
               }
               items.splice(i, 1);
           }
       });
   };
}

export default {
    type: 'service',
    name: 'itemsAutocomplete',
    value: itemsAutocomplete
};