import angular from 'angular';

areAllDifferentService.$inject = [];

function areAllDifferentService() {
    function isAllDifferent(array) {
        var sortedArray = angular.copy(array);
        sortedArray.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
        for (var i = 1; i < sortedArray.length; i++) {
            if (sortedArray[i].name === sortedArray[i - 1].name) {
                return false;
            }
        }
        return true;
    }

    this.isAllDifferent = isAllDifferent;
}

export default {
    type: 'service',
    name: 'areAllDifferentService',
    value: areAllDifferentService
};