/**
 * Created by sirosh on 11.05.16.
 */

import angular from 'angular';

discountListCtrl.$inject = [
    '$stateParams',
    '$state',
    '$filter',
    '$getDiscounts',
    'serviceDialog',
    'serviceToast',
    'factoryDiscount',
    '$shortOutlets',
    '$scope',
    '$profile',
    'siteLangCode',
    '$rootScope',
    '$currencyOptionsShow',
    '$percentShowZeroToHundred',
    '$translate',
    'lvMixpanel',
    'factoryPopover',
    '$window',
    'dialogs'
];

function discountListCtrl(
    $stateParams,
    $state,
    $filter,
    $getDiscounts,
    serviceDialog,
    serviceToast,
    factoryDiscount,
    $shortOutlets,
    $scope,
    $profile,
    siteLangCode,
    $rootScope,
    $currencyOptionsShow,
    $percentShowZeroToHundred,
    $translate,
    lvMixpanel,
    factoryPopover,
    $window,
    dialogs
) {
    var translate = $filter('translate');
    $scope.discounts = $getDiscounts.discounts;
    $scope.editTableState = 'goods.discountedit';
    $scope.delete = false;
    $scope.discountsSelect = [];
    $scope.allDiscounts = {};
    $scope.pages = $getDiscounts.pages;


    //*** currency display format
    $scope.currencyOptionsShow = $currencyOptionsShow;

    //*** percentage display format
    $scope.percentShowZeroToHundred = $percentShowZeroToHundred;

    // data for saving the state of filters and CHECKBOXES
    $scope.stateFromList = $state.params;
    $scope.checkFromList = $stateParams.checkFromList;

    // IF THERE ARE NO DISCOUNTS ON THE PAGE THEN GO TO THE LAST PAGE WHERE THEY ARE
    if ($scope.discounts.length == 0 && $scope.stateFromList.page > 0) {
        $scope.amountPagesWithDiscounts = $getDiscounts.pages;
        $state.go( 'goods.discountlist', {
            page: $scope.amountPagesWithDiscounts-1,
            outletsIds: $scope.stateFromList.outletsIds
        });
    }

    if ($scope.checkFromList.length == undefined || $scope.checkFromList.length == 0) {
        for (var i = 0; i < $scope.discounts.length; i++) {
            $scope.discounts[i]._select = false;
        }
    } else {
        for (var i = 0; i < $scope.checkFromList.length; i++) {

            for (var q = 0; q < $scope.discounts.length; q++) {
                ($scope.checkFromList[i] == $scope.discounts[q].id)? $scope.discounts[q]._select = true : "";
                (!$scope.discounts[q]._select)? $scope.discounts[q]._select = false: "";
            }
        }
    }
    // END ata for saving the state of filters and CHECKBOXES

    //-- CODE to save the array of IDs of the selected DISCOUNTS
    $scope.checkDiscountList = [];

    $scope.$watch('discounts', function(newval, oldval){
        for (var k = 0; k < newval.length; k++) {
            if (newval[k]._select == true) {
                var idx = $scope.checkDiscountList.indexOf(newval[k].id);
                if (idx > -1) {
                    $scope.checkDiscountList.splice(idx, 1);
                }
                $scope.checkDiscountList.push(newval[k].id);
            } else {
                var idx = $scope.checkDiscountList.indexOf(newval[k].id);
                if (idx > -1) {
                $scope.checkDiscountList.splice(idx, 1);
                }
            }
        }
        ($scope.checkDiscountList.length == 0)? $scope.delete = false : $scope.delete = true;
        ($scope.checkDiscountList.length == $scope.discounts.length)? $scope.allDiscounts._select = true : $scope.allDiscounts._select = false;

    }, true);
    //-- END CODE to save the array of IDs of the selected DISCOUNTS


    //-- BLOCK WRITTEN UNDER MULTISHOP
    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });

    $scope.selectOutlet = {id: $stateParams.outletsIds ? +$stateParams.outletsIds : null };
    $scope.selectOutletId = $scope.selectOutlet.id;

    //  state what check load content or not for show empty state between chose shop
    $scope.dataIsLoading = false;

    //-- BLOCK FOLLOWING SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            $scope.dataIsLoading = true;
            $state.go('goods.discountlist', {outletsIds: newval.id}).then(function() {
                $scope.selectOutletId = newval.id;
                $scope.dataIsLoading = false;
            });
        }
    }, true);
    //-- END BLOCK FOLLOWING SELECT OF THE INSTITUTION AND THE TRANSITION WHEN SELECTING THE INSTITUTION
    //-- END BLOCK WRITTEN UNDER MULTISHOP


    $scope.changeItem = function () {
        for (var j = 0; j < $scope.discounts.length; j++) {
            if ($scope.discounts[j]._select === true) {
                $scope.discountsSelect.push($scope.discounts[j]._select);
            }
        }
        $scope.delete = ($scope.discountsSelect.length > 0);
        $scope.allDiscounts._select = ($scope.discountsSelect.length === $scope.discounts.length) && ($scope.discounts.length > 1);
        $scope.discountsSelect = [];
    };


    $scope.changeAll = function () {
        if ($scope.allDiscounts._select === true) {
            for (var k = 0; k < $scope.discounts.length; k++) {
                $scope.discounts[k]._select = true;
            }
            $scope.allDiscounts._select = true;
        } else {
            $scope.allDiscounts._select = false;
            for (var m = 0; m < $scope.discounts.length; m++) {
                $scope.discounts[m]._select = false;
            }
        }

        for (var j = 0; j < $scope.discounts.length; j++) {
            if ($scope.discounts[j]._select === true) {
                $scope.discountsSelect.push($scope.discounts[j]._select);
            }
        }

        $scope.delete = ($scope.discountsSelect.length > 0);
        $scope.discountsSelect = [];
    };

    $scope.onAdd = function () {
        // console.log("$scope.stateFromList in onAdd = ", $scope.stateFromList);
        $state.go('goods.discountcreate', {stateToSave: $scope.stateFromList, checkToSave: $scope.checkDiscountList});
    };

    $scope.onDel = function () {
        var discountIds = [];
        var settings = {};

        for (var i = 0; i < $scope.discounts.length; i++) {
            if ($scope.discounts[i]._select === true) {
                discountIds.push($scope.discounts[i].id);
            }
        }

        if (discountIds.length === 1) {
            settings.msgTitle = translate('DISCOUNT_LIST.DELETE_DISCOUNT');
            settings.tostTitle = 'DISCOUNT_LIST.DELETE_DISCOUNT_SUCCESS';
            settings.content = translate('DISCOUNT_LIST.DELETE_DISCOUNT_TEXT');
        } else {
            settings.msgTitle = translate('DISCOUNT_LIST.DELETE_DISCOUNTS');
            settings.tostTitle = 'DISCOUNT_LIST.DELETE_DISCOUNTS_SUCCESS';
            settings.content = translate('DISCOUNT_LIST.DELETE_DISCOUNTS_TEXT');
        }

        function sendData() {
            factoryDiscount.delDiscount(discountIds).then(function (res) {
                if (res.result === "ok") {
                    serviceToast.show(settings.tostTitle, 'success');
                    refreshList();
                }
                if (res.result === 'error') {
                    if (res.message === 'discount_deleted') {
                        dialogs.listItemDeletedEarlier().then(refreshList);
                    }
                }
            });
        }

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: settings.msgTitle,
            content: settings.content,
            buttons: [
                {
                    text: 'ON_CANCEL'
                }, {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: sendData
                }
            ]
        });
    };



    //-- Task #25633 Update stubs for empty states
    emptyStateLink();
    $scope.emptyStateLink = emptyStateLink;

    function emptyStateLink() {
        let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
        // url lang where we have info
        let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "it", "pl", "gr", "zh"];
        //emptyStateCategory Learn more
        $scope.emptyStateLearnMoreDiscount = urlLang.indexOf(siteUrlLang) !== -1
            ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-create-and-configure-discounts?utm_source=Back%20Office&utm_medium=Discounts"
            : "https://help.loyverse.com/help/how-create-and-configure-discounts?utm_source=Back%20Office&utm_medium=Discounts";
    }
    //-- END Task #25633 Update stubs for empty states

    //-- Task #25853 Add events to Mixpanel for transitions to help
    $scope.trackDiscountsHelp = function () {
        lvMixpanel.track('Help', {"Property": "Discounts"});
    };
    //-- END Task #25853 Add events to Mixpanel for transitions to help

    function refreshList() {
        $state.go('goods.discountlist', {   page:   $scope.stateFromList.page,
            limit:  $scope.stateFromList.limit,
            checkFromList: []}, {
            reload: true
        });
    }


    $scope.popover = factoryPopover;
    $scope.openPopovers = {
        tax: false,
    };
    const onResize = () => {
        $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
    };
    this.$onInit = () => $window.addEventListener("resize", onResize);
    this.$onDestroy = () => $window.addEventListener("resize", onResize);
}


export default {
    type: 'controller',
    name: 'discountListCtrl',
    value: discountListCtrl
};
