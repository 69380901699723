/**
 * Created by K.Yarosh on 07.02.2017.
 * @description Setting and getting stateParams for passing between states, in particular for reports
 * @memberof
 * @ngdoc service
 * @name filtersData
 */

import angular from 'angular';

filtersData.$inject = [];
function filtersData() {

    var date = new Date();

    var stateParams = {
        page: 0,
        // from: new Date( date.getFullYear(), date.getMonth(), date.getDate() - 29 ).getTime(),
        // to: new Date( date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, -1 ).getTime(),
        // fromHour: 0,
        // toHour: 0,
        // group: 'day',
        // outletsIds: 'all',
        // merchantsIds: 'all',
        // categoryIds: 'all',
        limit: 10,
        offset: 0
    };

        this.getStateParams = function()
        {
            stateParams = (sessionStorage['stateParams']) ? JSON.parse(sessionStorage['stateParams']) : stateParams;
            return stateParams;
        };

        this.setStateParams = function(params)
        {
            stateParams = angular.copy(params);
            stateParams.page = 0;
            sessionStorage['stateParams'] = JSON.stringify(stateParams);
            return sessionStorage['stateParams'];
        };
    }

export default {
    type: 'service',
    name: 'filtersData',
    value: filtersData
};