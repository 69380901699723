/**
 * Created by mars on 9/30/15.
 */

factoryIsEmailConfirmed.$inject = ['$http', '$q'];

function factoryIsEmailConfirmed($http, $q) {

	function isAuthRegister() {
		return $q(function (resolve, reject) {
			$http.get('/data/iscabinetlogged').then(function (d) {
				resolve(d.data);
			}, function (err) {
				reject(err.data);
			});
		});

	}

	return {
		isAuthRegister: isAuthRegister
	}

}

export default {
	type: 'factory',
	name: 'factoryIsEmailConfirmed',
	value: factoryIsEmailConfirmed
};
