/**
 * Created by Alex on 13.04.2017.
 */

currencyFormatOnlyShow.$inject = [ 'currencyFormat','$rootScope' ];
function currencyFormatOnlyShow( currencyFormat, $rootScope ) {

    let country = $rootScope.country;
    let findZeroInArray = [];
    let currencyOptionsShow = {};
    let parameters = {};

    currencyFormat.observable.currencyOptionsShow.observe( result => {
        currencyOptionsShow = result;
        parameters = {
            symbol: currencyOptionsShow.currencySymbol,		// Overrides the currency's default symbol
            decimal: currencyOptionsShow.decimalCharacter,	// Overrides the locale's decimal character
            group: currencyOptionsShow.digitGroupSeparator	// Overrides the locale's group character (thousand separator)
        };
    }, true);

    format.$stateful = true;
    // added a new parameter to control currency symbol display
    function format( val, showCurrency = true ) {

        let pattern;
        if ( currencyOptionsShow.digitalGroupSpacing === 2 ) {
            // Overrides the locale's default display pattern
            pattern = (currencyOptionsShow.decimalPlacesOverride === 0) ? '#,##,###.' : '#,##,###.00';
        } else {
            pattern = (currencyOptionsShow.decimalPlacesOverride === 0) ? '#,##0.' : '#,##0.00';
        }

        // So in order not to display the symbol, it is enough not to modify the pattern.
        // And yourself, do not beat the function
        if ( showCurrency ) {
            parameters.pattern = getPatternWithCurrencySymbol(pattern, currencyOptionsShow.currencySymbolPlacement);
        } else parameters.pattern = pattern;


        findZeroInArray.push( val );
        let isZero = findZeroInArray.some(findZero);

        function findZero( val ) {
            if(val === 0 || val === '0.00') return true;
        }

        if( val !== undefined && val !== null ){
            if( isZero && ( val === 0 || val === '0.00' ) && country === 'in' ){
                let parametersForZero =	{
                    // Overrides the currency's default symbol
                    symbol: currencyOptionsShow.currencySymbol,
                    // Overrides the locale's decimal character
                    decimal:currencyOptionsShow.decimalCharacter,
                    // Overrides the locale's group character (thousand separator)
                    group: currencyOptionsShow.digitGroupSeparator
                };
                let patternIndianWithoutSign = (currencyOptionsShow.decimalPlacesOverride === 0) ? '#,##,###.' : '#,##,###.00';
                let patternIndian = getPatternWithCurrencySymbol(patternIndianWithoutSign, currencyOptionsShow.currencySymbolPlacement);
                parametersForZero.pattern = patternIndian;

                if ( showCurrency ) {
                    parametersForZero.pattern = patternIndian;
                } else parametersForZero.pattern = patternIndianWithoutSign;

                $rootScope.indianCurrencyPattern = patternIndian;
                val = OSREC.CurrencyFormatter.format(val, parametersForZero);
            } else {
                $rootScope.indianCurrencyPattern = null;
                val = OSREC.CurrencyFormatter.format(val, parameters);
            }

            return val;
        }
    }

    // the function modifies the pattern in such a way that it starts to display the symbol.
    // The sign of the call in the pattern, its is currency symbol.
    function getPatternWithCurrencySymbol( pattern, currencySymbolPlacement ) {
        let patternWithCurrency;

        if (currencySymbolPlacement === 'p') {
            // ! mark what show currency
            patternWithCurrency = `!${pattern}`
        } else {
            patternWithCurrency = `${pattern}!`
        }

        return patternWithCurrency;
    }

    return format;
}

export default {
    type: 'filter',
    name: 'currencyFormatOnlyShow',
    value: currencyFormatOnlyShow
};
