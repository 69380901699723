import angular from "angular";
import insertPhoto48pxUrl from "../../../img/insert_photo_48px.svg";
import FroalaEditor from "froala-editor";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/ar.js";
import "froala-editor/js/languages/zh_cn.js";
import "froala-editor/js/languages/zh_tw.js";
import "froala-editor/js/languages/hr.js";
import "froala-editor/js/languages/nl.js";
import "froala-editor/js/languages/de.js";
import "froala-editor/js/languages/el.js";
import "froala-editor/js/languages/et.js";
import "froala-editor/js/languages/fi.js";
import "froala-editor/js/languages/fr.js";
import "froala-editor/js/languages/hu.js";
import "froala-editor/js/languages/id.js";
import "froala-editor/js/languages/it.js";
import "froala-editor/js/languages/ja.js";
import "froala-editor/js/languages/ko.js";
import "froala-editor/js/languages/nb.js";
import "froala-editor/js/languages/pl.js";
import "froala-editor/js/languages/pt_pt.js";
import "froala-editor/js/languages/ro.js";
import "froala-editor/js/languages/ru.js";
import "froala-editor/js/languages/es.js";
import "froala-editor/js/languages/th.js";
import "froala-editor/js/languages/tr.js";
import "froala-editor/js/languages/vi.js";

createEditWareControl.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "factoryGoods",
  "constantColorsGoodsCollection",
  "constantShapeGoodsCollection",
  "$timeout",
  "serviceToast",
  "serviceDialog",
  "$filter",
  "$q",
  "$rootScope",
  "$ware",
  "$categoriesData",
  "$shortOutlets",
  "$currencyOptionsEdit",
  "$currencyOptionsShow",
  "$countOptionsEdit",
  "$critCountOptionsEdit",
  "$countOptionsShow",
  "$quantOptionsEdit",
  "$countOptionsEditDivisible",
  "lodash",
  "shareScope",
  "$controller",
  "$suppliers",
  "$profile",
  "setChartPeriod",
  "filtersData",
  "FileUploader",
  "$translate",
  "froalaLang",
  "lvMixpanel",
  "$permitsManager",
  "factoryCategories",
  "$window",
  "factoryPopover",
  "dialogs",
];

function createEditWareControl(
  $scope,
  $state,
  $stateParams,
  factoryGoods,
  constantColorsGoodsCollection,
  constantShapeGoodsCollection,
  $timeout,
  serviceToast,
  serviceDialog,
  $filter,
  $q,
  $rootScope,
  $ware,
  $categoriesData,
  $shortOutlets,
  $currencyOptionsEdit,
  $currencyOptionsShow,
  $countOptionsEdit,
  $critCountOptionsEdit,
  $countOptionsShow,
  $quantOptionsEdit,
  $countOptionsEditDivisible,
  lodash,
  shareScope,
  $controller,
  $suppliers,
  $profile,
  setChartPeriod,
  filtersData,
  FileUploader,
  $translate,
  froalaLang,
  lvMixpanel,
  $permitsManager,
  factoryCategories,
  $window,
  factoryPopover,
  dialogs
) {

	$scope.costAllowed = $permitsManager.costAllowed;

	//!!!!!!!!!!! Don't wrap this scope into a variable,
	// otherwise it will stop to interact with createEditVariantControl!!!!!!!!!!!
	$scope.insertPhotoIcon = 'url(' + insertPhoto48pxUrl + ')';

	$scope.catChanged = function() {
		if ($scope.ware.useDiningOptions){
			const selectedCategory = $scope.categories.find(cat => {
				return cat.id === $scope.ware.wareCategoryId;
			});
			taxExemtionsRecheck(selectedCategory);
		}
	}

	var profile = angular.copy($profile);
	var filterRepairCurrenFormatWhenFocus = $filter('filterRepairCurrenFormatWhenFocus');
	$scope.arapro = profile.cabinetLang === 'ara' || profile.cabinetLang === 'urd' || profile.cabinetLang === 'heb';

	// currency input format
	$scope.currencyOptionsEdit = $currencyOptionsEdit;

	// формат ввода остатка товара (число + - )
	$scope.countOptionsEdit = $countOptionsEdit;

	// формат ввода критического остатка товара (только положительное чисто)
	$scope.critCountOptionsEdit = $critCountOptionsEdit;

	// формат отображения любого остатка товара
	$scope.countOptionsShow = $countOptionsShow;

	// формат отображения валюты
	$scope.currencyOptionsShow = $currencyOptionsShow;

	// формат отображения количества
	$scope.quantOptionsEdit = $quantOptionsEdit;

    $scope.zeroLocalized = $filter('currencyLocalizationFilter')('0', {...$quantOptionsEdit, decimalCharacter: '.'});

	// установка формата отображения остатка элемента составного весового товара
	$scope.countOptionsEditDivisible = $countOptionsEditDivisible;

	// Inventory enabled flag - if user has inventory subscription
	$scope.inventoryEnabled = $profile.inventoryEnabled;

	// Inventory allowed flag - if user has right to manage inventory
	$scope.inventoryAllowed = $permitsManager.hasInventoryAccess;

	//-- number of decimal places and from this we change maxNumber
	$scope.numberOfSigns = $scope.currencyOptionsEdit.decimalPlacesOverride;
	if ($scope.numberOfSigns === 0) {
		$scope.maxNumber = 99999999999;
	} else {
		$scope.maxNumber = 999999999;
	}
	//-- END number of decimal places and from this we change maxNumber


	$scope.numberOfSigns = $scope.currencyOptionsEdit.decimalPlacesOverride;
	const amountNotLessThen = ($scope.numberOfSigns === 0) ?
		1 : $filter('coinToCurrency')(1, $scope.currencyOptionsEdit.decimalCharacter);
	$scope.amount = {
		min: 0.00,
		max: $scope.currencyOptionsEdit.maximumValue,
		minMsg: $translate.instant(
			'ERROR.discount_amount_min', {amount: amountNotLessThen}),
		maxMsg: $scope.numberOfSigns === 0 ?
			$translate.instant('ERROR.max_price_value_limit_zero_signs') :
			$translate.instant('ERROR.max_price_value_limit')
	};



	$scope.showDel = $state.current.name !== 'goods.create';
	$scope.title = $state.current.data.title;
	$scope.variantsSelect = 0;
	$scope.itemsSelect = 0;
	$scope.progress = false;
	let translate = $filter('translate');
	let currencyToCoin = $filter('currencyToCoin');
	let coinToCurrency = $filter('coinToCurrency');
	let wareCount = $filter('wareCount');
	let vm = this;
	let addedImages = [];
	$scope.pager = factoryGoods.pager;
	$scope.visibleFormAdd = true;
	let focusAddForm = false;
	$scope.setFocusAddForm = function(val) {
		focusAddForm = val;
	};

	$scope.colorsGoods = angular.copy(constantColorsGoodsCollection);
	$scope.shapeGoods = angular.copy(constantShapeGoodsCollection);

	let barCode;
	let ware;
	const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope, onDiscardCb);
	let counterForVariantsWithOutlets = 0;

	$scope.ware = $ware;

	$scope.ware.useDiningOptions = $profile.useDiningOptions;

	$scope.ware.wareCategoryId = $scope.ware.wareCategoryId ? $scope.ware.wareCategoryId : 0;
	$scope.categories = angular.copy($categoriesData.categories);

	const setEmptyCategory = () => {
		const emptyCategory = $scope.categories.find(cat => !cat.id);
		if (emptyCategory) {
			emptyCategory.name = $translate.instant('WITHOUT_CATEGORY');
			emptyCategory.id = 0;
		}
	}
	setEmptyCategory();

	let toastUnableToSaveChanges = null;
	const showUnableToSaveToast = () => {
		if (toastUnableToSaveChanges)
			serviceToast.hide(toastUnableToSaveChanges)

		toastUnableToSaveChanges = serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
	}

	if ($state.$current.name === 'goods.create') {
		$scope.ware.variations = {};
	}

	$scope.initialVariations = angular.copy($scope.ware.variations);

	$scope.isEdit = ($state.current.name === 'goods.edit');
	$scope.isEditVariants = ($state.current.name === 'goods.edit' && $scope.ware.variations.variants.length !== 0);

	$scope.outlets = $shortOutlets;
	if (!$scope.showDel && $state.$current.name === 'goods.create' && $scope.ware.variations.length === 0) {
		$scope.ware._outlets = angular.copy($scope.outlets);
	}

	$scope.outlet = null;

	$scope.ware.allowedForSale = ($state.current.name === 'goods.create' && $scope.outlets.length === 1);

	$controller('createEditVariantControl', {
		$scope: $scope,
		$currencyOptionsEdit: $currencyOptionsEdit,
		$countOptionsEdit: $countOptionsEdit,
		$critCountOptionsEdit: $critCountOptionsEdit,
		$quantOptionsEdit: $quantOptionsEdit,
		$countOptionsEditDivisible: $countOptionsEditDivisible,
		$permitsManager: $permitsManager});

	$scope.includeVariants = 'dashboard-page/templates/goods/create-edit-variants.html';
	$scope.topValueCategory = function() {
		$timeout(function() {
			angular.element(document.querySelector('.md-select-menu-container.md-green-theme-theme.md-active.md-clickable')).addClass("topValueForCategory");
		}, 300);
	};


	//-- data to save the state of the filters
	$scope.stateFromList = $stateParams.stateToSave;
	$scope.checkFromList = $stateParams.checkToSave;
	//-- END data to save the state of the filters



	//-- display and hide the entire list of yawns
	// show the line only if there are more than 4 establishments
	$scope.viewLineAllOutlets;
	// how many establishments to display by default
	$scope.showLine = ($scope._variantsByOutLets) ? 5 : 5;
	// $scope.showLine = 5;
	$scope.viewMore = ($scope.ware.variations.variants.length > 0) ? translate('VIEW_MORE') : translate('MULTISHOP.GOODS.VIEW_ALL_STORES');
	$scope.viewLess = ($scope.ware.variations.variants.length > 0) ? translate('VIEW_LESS') : translate('MULTISHOP.GOODS.VIEW_LESS_STORES');

	$scope.viewLineAllOutlets = $scope.isEditVariants ? ($scope.ware.byOutlets.length > $scope.showLine) : ($scope.outlets.length > $scope.showLine);

	//-- if true - see VIEW ALL, if false - see HIDE ALL
	$scope.showAllOutlets = false;

	$scope.suppliers = angular.copy($suppliers);

	$scope.isMobile = $rootScope.isMobile;

	$('form').keydown(function(event) {
		if (event.keyCode === 13 && event.target.nodeName !== 'TEXTAREA') {
			event.preventDefault();
			return false;
		}
	});

	if (!$scope.ware.description)
		document.getElementById("edit").classList.add("empty");

	vm.urlParams = {
		url: null,
		urlText: null,
		inOtherTab: false
	}

	var showInsertLinkDialog = function () {
		froala.selection.save();
		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-insert-link.html',
			title: translate('FROALA_EDITOR.INSERT_LINK'),
			label1: translate('FROALA_EDITOR.URL'),
			label2: translate('FROALA_EDITOR.TEXT_TO_DISPLAY'),
			params: vm.urlParams,
			checkLabel: translate('FROALA_EDITOR.OPEN_IN_NEW_TAB'),
			buttons: [{
					text: 'ON_CANCEL',
					action: function() {
						vm.urlParams.url = null;
						vm.urlParams.urlText = null;
						vm.urlParams.inOtherTab = false;
						froala.selection.restore();
					}
				},
				{
					class: 'primary',
					text: 'FROALA_EDITOR.INSERT',
					action: function() {
						froala.selection.restore();
						vm.isNewTab = vm.urlParams.inOtherTab ? "_blank" : "";
						var text = vm.urlParams.urlText ? vm.urlParams.urlText : "";
						var myValue = '<a href="'+vm.urlParams.url+'" target="'+vm.isNewTab+'">'+text+'</a>';
						froala.html.insert(myValue);
						vm.urlParams.url = null;
						vm.urlParams.urlText = null;
						vm.urlParams.inOtherTab = false;
					}
				}]
		});
	}

	var showEditLinkDialog = function () {
		froala.selection.save();
		console.log(froala.link.allSelected());
		var selectedLink = froala.link.allSelected();
		vm.urlParams.url = selectedLink[0].href;
		vm.urlParams.urlText = selectedLink[0].innerText;
		vm.urlParams.inOtherTab = !!selectedLink[0].target;
		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-insert-link.html',
			title: translate('FROALA_EDITOR.EDIT_LINK'),
			label1: translate('FROALA_EDITOR.URL'),
			label2: translate('FROALA_EDITOR.TEXT_TO_DISPLAY'),
			params: vm.urlParams,
			checkLabel: translate('FROALA_EDITOR.OPEN_IN_NEW_TAB'),
			buttons: [{
				text: 'ON_CANCEL',
				action: function() {
					froala.selection.restore();
				}
			},
				{
					class: $scope.arapro ? "primary arabic-save" : "primary",
					text: 'ON_SAVE',
					action: function() {
						froala.selection.restore();
						selectedLink[0].href = vm.urlParams.url;
						selectedLink[0].innerText = vm.urlParams.urlText;
						selectedLink[0].target = vm.urlParams.inOtherTab ? "_blank" : "";
					}
				}]
		});
	}

	FroalaEditor.DefineIcon('link', {NAME: 'Insert Link', SVG_KEY: 'insertLink'});
	FroalaEditor.RegisterCommand('link', {
		title: 'Insert Link',
		focus: false,
		undo: false,
		callback: showInsertLinkDialog
	});

	FroalaEditor.DefineIcon('editLink', {NAME: 'Insert Link', SVG_KEY: 'edit'});
	FroalaEditor.RegisterCommand('editLink', {
		title: 'Edit Link',
		focus: false,
		undo: false,
		callback: showEditLinkDialog
	});

	var froala = new FroalaEditor('#edit', {
		key: "aLF3c1A7D6C4F3D2E2D1xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2B5D6B3C3E4D2F2==",
		attribution: false,
		placeholderText: translate('ITEM_DESCRIPTION'),
		linkEditButtons: ['linkOpen', 'linkStyle', 'editLink', 'linkRemove'],
		toolbarButtons: [['paragraphFormat'], '|', ['bold', 'italic'],
			'|', ['align'], '|', ['formatOL', 'formatUL'], '|', ['link']],
		paragraphFormat: {
			N: translate('FROALA_EDITOR.NORMAL_TEXT'),
			H1: 'Heading 1',
			H2: 'Heading 2',
			H3: 'Heading 3',
			H4: 'Heading 4',
			H5: translate('FROALA_EDITOR.HEADING')+' 5',
			H6: translate('FROALA_EDITOR.HEADING')+' 6'
		},
		paragraphFormatSelection: true,
		paragraphDefaultSelection: translate('FROALA_EDITOR.NORMAL_TEXT'),
		charCounterCount: false,
		heightMax: 274,
		htmlExecuteScripts: false,
		htmlAllowedTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'strong', 'em'],
		imageUpload: false,
		videoUpload: false,
		fileUpload: false,
		htmlRemoveTags: ['script'],
		language: froalaLang[profile.cabinetLang],
		events: {
			'focus': function () {
				document.getElementById("edit").classList.add("focused");
				document.getElementById("edit").classList.remove("empty");
				if (document.getElementById("edit").classList.contains("error"))
					document.getElementById("edit").classList.remove("error");
			},
			'blur': function () {
				document.getElementById("edit").classList.remove("focused");
				if(!froala.html.get())
					document.getElementById("edit").classList.add("empty");
			}
		}
	},
	function () {
		froala.html.set($scope.ware.description);
	});

	if ($state.$current.name === 'goods.edit' && $scope.ware.defaultSupplier !== 0) {
		for (let i = 0, suppliersLength = $scope.suppliers.length; i < suppliersLength; i++) {
			if ($scope.suppliers[i].id === $scope.ware.defaultSupplier) {
				$scope.suppliersSelectedItem = {
					value: $scope.suppliers[i].name.toLowerCase(),
					display: $scope.suppliers[i].name,
					id: $scope.suppliers[i].id
				};
				$scope.suppliersSelectedItemId = $scope.suppliersSelectedItem.id;
				break;
			} else if (i === suppliersLength - 1) {
				$scope.suppliersSelectedItem = null;
				$scope.suppliersSelectedItemId = null;
			}
		}
	} else {
		$scope.suppliersSelectedItem = null;
		$scope.suppliersSelectedItemId = null;
	}

	$scope.selectedItemChange = function(selectedItem) {
		if (angular.isObject(selectedItem)) {
			$scope.suppliersSelectedItem = selectedItem;
			$scope.suppliersSelectedItemId = selectedItem.id;
		} else {
			$scope.suppliersSelectedItem = null;
			$scope.suppliersSelectedItemId = null;
		}
	};

	// Purchase cost by default - added by Kiskenbassker
	if ($state.$current.name === 'goods.edit' && $scope.ware.purchaseCost !== null) {
		$scope.ware.purchaseCost = coinToCurrency($scope.ware.purchaseCost, ".");
	} else {
		$scope.ware.purchaseCost = null;
	}

	// Ideal stock by default - added by Kiskenbassker
	if ($scope.outlets.length === 1 && $scope.ware.variations.variants.length === 0) {
		if ($state.$current.name === 'goods.edit' && $scope.ware.byOutlets[0].idealStock !== null) {
			$scope.ware._outlets[0].idealStock = wareCount($scope.ware.byOutlets[0].idealStock, ".", false);
		} else {
			$scope.ware._outlets[0].idealStock = null;
		}
	} else if ($scope.outlets.length > 1 && $scope.ware.variations.variants.length === 0) {
		$scope.ware._outlets.forEach(function(item, i) {
			item.idealStock = $state.$current.name === 'goods.edit' && $scope.ware.byOutlets[i].idealStock !== null ?
				wareCount($scope.ware.byOutlets[i].idealStock, ".", false) :
				null;
		})
	}

	if ($scope.ware._salePrice === "") {
		$scope.ware._salePrice = null
	}

	$scope.ware._outlets.forEach(function(outlet) {
		if (outlet.salePrice === "") {
			outlet.salePrice = null;
		}
	});

	//-- check if there are exceptions to the application of taxes to
	// this product for various types of orders
	if ($state.$current.name === 'goods.edit') {
		if ($scope.ware.taxExemptIds.length) {
			$scope.ware._taxes.forEach(function(tax) {
				tax.isExemtion = false;
				for (var i = 0; i < $scope.ware.taxExemptIds.length; i++) {
					if (tax.id === $scope.ware.taxExemptIds[i]) {
						tax.isExemtion = true;
					}
				}
			});
		}
	} else if ($state.$current.name === 'goods.create') {
		if ($scope.categories[0].taxExemptForNewItems && $scope.categories[0].taxExemptForNewItems.length) {
			$scope.ware._taxes.forEach(function(tax) {
				tax.isExemtion = false;
				for (var i = 0; i < $scope.categories[0].taxExemptForNewItems.length; i++) {
					if (tax.id === $scope.categories[0].taxExemptForNewItems[i]) {
						tax.isExemtion = true;
					}
				}
			});
		}
	}
	//-- END check if there are exceptions to the application of
	// taxes to this product for various types of orders

	//-- BOGDANOV FUNCTION, WORKS ONLY WHEN EDITING
	$scope.ware._salePriceSetter = function(newPrice) {

		if (newPrice === '') {
			newPrice = null;
		}

		if (angular.isDefined(newPrice)) {


			$scope.ware._outlets.forEach(function(wareOutlet) {


				if (!angular.isDefined(wareOutlet.salePrice) ||
					((wareOutlet.salePrice === "" || wareOutlet.salePrice === null) && ($scope.ware._salePrice === null || $scope.ware._salePrice === "")) ||
					(angular.isDefined(wareOutlet.salePrice) && wareOutlet.salePrice === $scope.ware._salePrice)
				) {
					wareOutlet.salePrice = newPrice;
				}

			});

			$scope.ware._salePrice = newPrice;
		}

		$scope.ware._salePrice = (!$scope.ware._salePrice) ? null : ($scope.ware._salePrice === "") ? null : $scope.ware._salePrice;

		return $scope.ware._salePrice;
	};
	// END BOGDANOV FUNCTION, WORKS ONLY WHEN EDITING

	//-- FUNCTION NOT BOGDANOV, WORK ONLY WHEN CREATING
	$scope.ware._salePriceSetterSingle = function(newPrice) {

		if (newPrice === '') {
			newPrice = null;
		}

		if (angular.isDefined(newPrice)) {

			$scope.ware._outlets.forEach(function(wareOutlet) {

				if (!angular.isDefined(wareOutlet.salePrice) ||
					((wareOutlet.salePrice === "" || wareOutlet.salePrice === null) && ($scope.ware._salePrice === null || $scope.ware._salePrice === "")) ||
					(angular.isDefined(wareOutlet.salePrice) && wareOutlet.salePrice === $scope.ware._salePrice)
				) {
					wareOutlet.salePrice = newPrice;
				}
			});
			$scope.ware._salePrice = newPrice;
		}
		$scope.ware._salePrice = (!$scope.ware._salePrice) ? null : ($scope.ware._salePrice === "") ? null : $scope.ware._salePrice;

		return $scope.ware._salePrice;
	};
	//-- END FUNCTION NOT BOGDANOV, WORK ONLY WHEN CREATING

	//an alternative way for price interaction between a ware's sale price and outlets' prices
	$scope.upDateWareOutletSalePriceOnTheFly = function upDateWareOutletSalePriceOnTheFly(ware) {

		if (ware._salePrice === '') {
			ware._salePrice = null;
		} else if (ware.salePrice === '') {
			ware.salePrice = null;
		}


		var outlets = (ware._outlets) ? ware._outlets : [],
			salePrice = (ware._salePrice) ? ware._salePrice : ware.salePrice;

		if (outlets) {
			outlets.forEach(function(outletWithWare) {

				updateOutlets(outletWithWare);

			});
		}


		function updateOutlets(outletWithWare) {

			if (ware._oldPrice === outletWithWare.salePrice) {
				outletWithWare.salePrice = salePrice;
			} else if (!ware._oldPrice && (outletWithWare.salePrice === null)) {
				outletWithWare.salePrice = salePrice;
			}

			if (outletWithWare.salePrice === "") {
				outletWithWare.salePrice = null;
			}
		}
		ware._oldPrice = salePrice;
	}

	// display and hide the entire list of yawns
	$scope.handlingDataDisplay = function(flag) {

		$scope.showAllOutlets = flag;
		if ($scope._variantsByOutlets && $scope._variantsByOutlets.length > 0) {

			if (flag === true) {
				var entireLength = $scope._variantsByOutlets.length;

				if ($scope.showOutletsWithVariants < entireLength) {

					$scope.showOutletsWithVariants = counterForVariantsWithOutlets += 100;
					$scope.showAllOutlets = ($scope.showOutletsWithVariants >= $scope._variantsByOutlets.length);
					$scope.showLine = $scope.showOutletsWithVariants;
				}

			} else {
				$scope.showOutletsWithVariants = $scope.showLine = counterForVariantsWithOutlets = 5;
				$scope.showAllOutlets = ($scope.showOutletsWithVariants >= $scope._variantsByOutlets.length);
			}
		} else {
			if (flag === true) {
				$scope.showLine = $scope.outlets.length;
			} else {
				$scope.showLine = 5;
			}
		}
	};
	//-- END display and hide the entire list of yawns


	//-- opening the history of changes in the quantity of goods
	$scope.viewHistory = function() {
		var dates = setChartPeriod.setButtonPeriod('thirtyDays');
		var stateParams = filtersData.getStateParams();
		if ($scope.ware.variations.variants.length === 0) {
			$state.go('goods.itemHistory', {
				page: 0,
				limit: 10,
				wareId: $stateParams.id,
				wareName: $scope.ware.name,
				reasons: "all",
				from: stateParams.from || dates.from,
				to: stateParams.to || dates.to,
				stateToSave: $stateParams.stateToSave,
				checkToSave: $stateParams.checkToSave,
				outletsIds: stateParams.outletsIds || null,
				merchantsIds: stateParams.merchantsIds || null
			});
		} else if ($scope.ware.variations.variants.length > 0) {
			$state.go('goods.itemHistory', {
				page: 0,
				limit: 10,
				wareId: $stateParams.id,
				wareName: $scope.ware.name,
				reasons: "all",
				variantId: "all",
				from: stateParams.from || dates.from,
				to: stateParams.to || dates.to,
				stateToSave: $stateParams.stateToSave,
				checkToSave: $stateParams.checkToSave,
				outletsIds: stateParams.outletsIds || null,
				merchantsIds: stateParams.merchantsIds || null
			});
		}
	}
	// END opening the history of changes in the quantity of goods

	barCode = $scope.ware.barcode;
	var searchDefer;

	$scope.autocomplete = {
		selectedItem: null,
		searchText: null,
		_quantity: 0,
		getMatches: function(query) {
			searchDefer = $q.defer();
			factoryGoods
				.searchWare({
					val: query,
					id: $stateParams.id || null
				})
				.then(function(wares) {
					searchDefer.resolve(wares);
				});
			return searchDefer.promise;
		},
		selectedItemChange: function(item) {
			if (!$scope.autocomplete.selectedItem || $scope.goodsWaresForm.goodsWaresForm1.goodsWaresForm2.$invalid)
				return;

			var itemsLength = $scope.ware.ingredients.length;
			if (itemsLength !== 0) {
				for (var i = 0; i < itemsLength; i++) {
					if (item.id === $scope.ware.ingredients[i].id) {
						serviceDialog.add({
							templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
							content: translate('HAS_COMPONENT'),
							buttons: [{
								text: 'ON_OK',
								action: function() {
									return true;
								}
							}]
						});
						break;
					} else if (i === itemsLength - 1) {
						item._newItem = true;
						if ($scope.ware.ingredients.length > 0) {
							$scope.goodsWaresForm1.setInvalidTouchedControls();
						}
						$scope.ware.ingredients.push(item);
						item._quantity = $scope.autocomplete._quantity;
					}
				}
			} else {
				item._newItem = true;
				if ($scope.ware.ingredients.length > 0) {
					$scope.goodsWaresForm1.setInvalidTouchedControls();
				}
				$scope.ware.ingredients.push(item);
				item._quantity = $scope.autocomplete._quantity;
			}

			$('[name="autocompleteItems"]').blur();
			this.selectedItem = null;
			this.searchText = null;
		}

	};

	$scope.delIngradient = function(item) {
		angular.forEach($scope.ware.ingredients, function(val, i) {
			if (item === val) {
				$scope.ware.ingredients.splice(i, 1);
				return;
			}
		});
	};

	$scope.$watch(function() {
		return $scope.ware._complex;
	}, function(val) {
		if (!val) {
			$scope.autocomplete.searchText = null;
		}
	});

	function getIsPrintedToFiscal() {
		var taxes = $scope.ware._taxes;
		var i = 0;
		while (i < taxes.length) {
			if (taxes[i].isPrintedToFiscal && taxes[i]._select) {
				return taxes[i];
			}
			i++;
		}
		return null;
	}

	$scope.setDisabledDax = function(tax) {
		var taxes = $scope.ware._taxes;
		var i = 0;
		while (i < taxes.length) {
			if (taxes[i] === tax) {
				if (getIsPrintedToFiscal() && tax.isPrintedToFiscal && getIsPrintedToFiscal() !== tax) {
					return true;
				}
			}
			i++;
		}
		return false;
	};



	function _init() {
		if ($stateParams.id) $scope.ware.id = $stateParams.id;

		$scope.ware._radio = $scope.ware.imgUrl ? 'img' : 'color';

		$scope.setColorName($scope.ware.colorName);

		if ($scope.ware.shape) {
			for (var i = 0; i < $scope.shapeGoods.length; i++) {
				if ($scope.shapeGoods[i].value === $scope.ware.shape) {
					$scope.shapeGoods[i]._select = true;
				}
			}
		} else {
			$scope.setShape($scope.shapeGoods[0]); //установка формы по умолчанию
		}

		$scope.ware.imageDeleted = $scope.ware._radio === 'img' && $scope.ware.imgUrl === null;

		$scope.imageHovered = false;

		const hideDelButton = (image, imageUrl) => !image && !imageUrl;
		$scope.hideImageDeleteButton = hideDelButton($scope.ware._logo, $scope.ware.imgUrl);

		$scope.$watch('ware._logo', function(newVal, oldVal) {
			if (newVal !== oldVal) {
				$scope.hideImageDeleteButton = hideDelButton(newVal, $scope.ware.imgUrl);
			}
		});

		$scope.$watch('ware._radio', function(val) {
			if (val === 'color') {
				$scope.setColorName($scope.ware.colorName);

				$scope.ware._logo = null;

				const goodsColor = document.getElementsByClassName('goodsColors');
				const goodsShape = document.getElementsByClassName('goodsShape');
				if (goodsColor[0]) {
					let elWidth = goodsColor[0].offsetWidth;
					[...goodsColor].forEach(color => { color.style.height = elWidth });
					[...goodsShape].forEach(shape => { shape.style.height = elWidth });
				}
			}
		});

		$scope.$watch('ware.keepCount', function(val) {
			if (val) {
				if (!$scope.ware.count) {
					$scope.ware.count = '0';
				}
			} else {
				$scope.ware.count = '';
			}
		});

		$scope.$watch('ware.useProduction', function(val) {
			if (val) {
				if (!$scope.ware.count) {
					$scope.ware.count = '0';
				}
			} else {
				$scope.ware.count = '';
			}
		});

		$scope.$watch('ware.imageDeleted', (newVal, oldVal) => {
			if (newVal !== oldVal && newVal) {
				$scope.hideImageDeleteButton = true;
				$scope.ware.imageBlob = null;
				$scope.ware.imgUrl = null;
			}
		});

		$timeout(function() {
			ware = angular.copy($scope.ware);
		});

	}

	$scope.notApplicableOptions = function(dOptions) {
		if (dOptions.length === 1) {
			if (dOptions[0].name === null) dOptions[0].name = translate("DINING." + dOptions[0].type);
			return [dOptions[0].name];
		} else if (dOptions.length === 2) {
			dOptions.forEach(function(option) {
				if (option.name === null) option.name = translate("DINING." + option.type);
			});
			return [dOptions[0].name, dOptions[1].name];
		} else {
			return [dOptions.length];
		}
	}

	$scope.setColorName = function(colorName, verify) {
		if (verify && $scope.ware._radio === 'img') {
			return;
		}
		$scope.ware.colorName = colorName ?? "GREY";
	};


	$scope.setShape = function(shape, verify) {

		if (verify && $scope.ware._radio == 'img') return;
		for (var i = 0; i < $scope.shapeGoods.length; i++) {
			$scope.shapeGoods[i]._select = false;
			if ($scope.shapeGoods[i] == shape) {
				$scope.shapeGoods[i]._select = true;
				$scope.ware.shape = shape.value;
			}
		}
	};

	// TODO: remove
	$scope.taxExemtionsRecheck = taxExemtionsRecheck;

	function taxExemtionsRecheck(category) {
		if (category.taxExemptForNewItems && category.taxExemptForNewItems.length && $scope.ware.taxExemptIds && $scope.ware.taxExemptIds.length) {
			var newTaxArray = lodash.union($scope.ware.taxExemptIds, category.taxExemptForNewItems);
		} else if (category.taxExemptForNewItems && category.taxExemptForNewItems.length) {
			var newTaxArray = category.taxExemptForNewItems;
		} else if ($scope.ware.taxExemptIds && $scope.ware.taxExemptIds.length) {
			var newTaxArray = $scope.ware.taxExemptIds;
		}
		if (newTaxArray) {
			$scope.ware._taxes.forEach(function(tax) {
				tax.isExemtion = false;
				for (var i = 0; i < newTaxArray.length; i++) {
					if (tax.id === newTaxArray[i]) {
						tax.isExemtion = true;
					}
				}
			});
		}
	};






	$scope.onCancelWare = function() {
		backToList();
	};

	function backToList() {
		if (lodash.isEmpty($scope.stateFromList)) {
			return $state.go('goods.price', {
				page: 0,
				limit: 10,
				inventory: 'all',
				checkFromList: $scope.checkFromList
				// expandFromList:	$scope.expandFromList}
			});
		} else {
			return $state.go('goods.price', {
				page: $scope.stateFromList.page,
				limit: $scope.stateFromList.limit,
				outletId: $scope.stateFromList.outletId,
				category: $scope.stateFromList.category,
				inventory: $scope.stateFromList.inventory,
				checkFromList: $scope.checkFromList
				// expandFromList: $scope.expandFromList
			});
		}
	}

	var timeoutShowCanNotBeComponentWare;
	$scope.onShowCanNotBeComponentWare = function() {
		if ($scope.ware._limitComponent) {
			$scope._showCanNotBeComponentWare = true;
			timeoutShowCanNotBeComponentWare && $timeout.cancel(timeoutShowCanNotBeComponentWare);
			timeoutShowCanNotBeComponentWare = $timeout(function() {
				$scope._showCanNotBeComponentWare = false;
			}, 7000);
		}
	};


	// для mixpanel - проверяем имеет ли обьект модификатор или налог
	$scope.hasModifier = function(ware) {
		var temp = "No";
		for (var i = 0; i < ware.length; i++) {
			if (ware[i].selected == true) {
				temp = "Yes";
				break;
			}
		}
		return temp;
	};
	$scope.hasTax = function(ware) {
		var temp = "No";
		for (var i = 0; i < ware.length; i++) {
			if (ware[i]._select == true) {
				temp = "Yes";
				break;
			}
		}
		return temp;
	};


	// для mixpanel - проверяем имеет ли обьект модификатор или налог
	$scope.createEditWareForMixpanel = function(ware, type) {
		var itemProperty = {};
		itemProperty.soldBy = (ware.divisible == true) ? "Weight" : "Each";
		if (ware.barcode) itemProperty.barcode = (ware.barcode.length > 0) ? "Filled" : "Blank";
		itemProperty.composite = (ware._complex == true) ? "Yes" : "No";
		// itemProperty.composite = ware._complex;
		itemProperty.trackStock = (ware._complex == true) ? "No" : ((ware.keepCount == true) ? "Yes" : "No");
		// itemProperty.trackStock = (ware._complex == true)? "No" : ware.keepCount;
		itemProperty.modifiers = $scope.hasModifier(ware.modifiers);
		itemProperty.taxes = $scope.hasTax(ware._taxes);
		itemProperty.image = (ware._radio == "img") ? "Image" : "Color";
		itemProperty.variations = ware.variations.variants.length > 0 ? "Yes" : "No";

		if (ware._radio === "color") {
			itemProperty.color = getColorForMixpanel(ware.colorName)
		}

		if (ware._radio == "color" && ware.shape == "ROUNDED_SQUARE") { itemProperty.form = "Rounded square"; }
		if (ware._radio == "color" && ware.shape == "CIRCLE") { itemProperty.form = "Circle"; }
		if (ware._radio == "color" && ware.shape == "HEXAGON") { itemProperty.form = "Hexagon"; }
		if (ware._radio == "color" && ware.shape == "SUN_SHAPE") { itemProperty.form = "Sun shape"; }

		lvMixpanel.track('Item created/edited', {
			"Sold by": itemProperty.soldBy,
			"Barcode": itemProperty.barcode,
			"Composite item": itemProperty.composite,
			"Track stock": itemProperty.trackStock,
			"Modifier applied": itemProperty.modifiers,
			"Tax applied ": itemProperty.taxes,
			"Representation on POS": itemProperty.image,
			"Color": itemProperty.color,
			"Form": itemProperty.form,
			"Event type": type,
			"Variants": itemProperty.variations
		});
	};

	if ($scope._variantsByOutlets && $scope.outlets.length > 1) {
		for (var i = 0; i < $scope._variantsByOutlets.length; i++) {
			if ($scope._variantsByOutlets[i].enable === true) {
				$scope.variantsSelect++;
			}
		}
		if ($scope.variantsSelect === $scope._variantsByOutlets.length) {
			$scope.ware.variations.allowedForAllOutlets = true;
		} else {
			$scope.ware.variations.allowedForAllOutlets = false;
		}
	}

	if ($state.current.name === 'goods.create' && $scope.outlets.length > 1) {
		$scope.ware.allowedForSale = true;
		$scope.itemsSelect = $scope.outlets.length;
	} else if ($scope.ware._outlets) {
		for (var i = 0; i < $scope.ware._outlets.length; i++) {
			if ($scope.ware._outlets[i].enable === true) {
				$scope.itemsSelect++;
			}
		}
		if ($scope.itemsSelect === $scope.ware._outlets.length) {
			$scope.ware.allowedForSale = true;
		} else {
			$scope.ware.allowedForSale = false;
		}
	}

	$scope.selectVariant = function(pair) {
		if (pair.enable) {
			$scope.variantsSelect++;

			if ($scope.variantsSelect === $scope._variantsByOutlets.length) {
				$scope.ware.variations.allowedForAllOutlets = true;
			}
		} else {
			$scope.variantsSelect--;

			if ($scope.ware.variations.allowedForAllOutlets === true) $scope.ware.variations.allowedForAllOutlets = false;
		}
	}

	$scope.changeAllVariants = function() {
		if ($scope.ware.variations.allowedForAllOutlets) {
			for (var j = 0; j < $scope._variantsByOutlets.length; j++) {
				$scope._variantsByOutlets[j].enable = true;
			}
			$scope.variantsSelect = $scope._variantsByOutlets.length;
		} else {
			for (var j = 0; j < $scope._variantsByOutlets.length; j++) {
				$scope._variantsByOutlets[j].enable = false;
			}
			$scope.variantsSelect = 0;
		}
	}

	$scope.selectItem = function(outlet) {
		if (outlet.enable) {
			$scope.itemsSelect++;

			if ($scope.itemsSelect === $scope.ware._outlets.length) {
				$scope.ware.allowedForSale = true;
			}
		} else {
			$scope.itemsSelect--;

			if ($scope.ware.allowedForSale === true) $scope.ware.allowedForSale = false;
		}
	}

	$scope.changeAllItems = function() {
		if ($scope.ware.allowedForSale) {
			for (var j = 0; j < $scope.ware._outlets.length; j++) {
				$scope.ware._outlets[j].enable = true;
			}
			$scope.itemsSelect = $scope.ware._outlets.length;
		} else {
			for (var j = 0; j < $scope.ware._outlets.length; j++) {
				$scope.ware._outlets[j].enable = false;
			}
			$scope.itemsSelect = 0;
		}
	};

	$scope.onSaveWare = function() {
		if ($scope.goodsWares.$invalid) {
			$scope.goodsWares.focusFirstInvalidControl();
			showUnableToSaveToast();
			return false;
		}

		if ($scope.ware._complex) {
			$scope._variantsByOutlets = false;
			$scope.ware.variations.variants = [];
			$scope.ware.variations.options = [];
		} else {
			$scope.ware.useProduction = false;
		}

		var wareObj;

		if ($scope._variantsByOutlets && $scope._variantsByOutlets.length > 0) {
			$scope.ware._variantsByOutlets = $scope._variantsByOutlets;
		} else {
			$scope.ware._variantsByOutlets = angular.copy($scope.ware._outlets);
		}

		if ($scope.outlets.length === 1) {
			$scope.ware._salePrice = ($scope.ware._variantsByOutlets[0].salePrice && $scope.ware._variantsByOutlets[0].salePrice !== "" && $scope.ware._variantsByOutlets[0].salePrice !== null) ? $scope.ware._variantsByOutlets[0].salePrice : null;
		}

		$scope.ware.description = froala.html.get() ? froala.html.get() : null;

		if ($scope.ware.description !== null && $scope.ware.description.length > 32768) {
		// 	if ($scope.ware.description.length > 32) {
			showUnableToSaveToast();
			document.getElementById("edit").classList.add("error");
			return;
		}


		if ($scope.containerForChangedArticles.length > 0) {

			var i,
				k,
				outletsData = angular.copy($scope.ware._variantsByOutlets),
				outletsLen = outletsData.length,
				varArtArr = angular.copy($scope.containerForChangedArticles),
				varArtLen = varArtArr.length,
				propNames,
				propName;


			for (i = 0; i < outletsLen; i++) {
				for (k = 0; k < varArtLen; k++) {
					propNames = Object.getOwnPropertyNames(varArtArr[k]);
					propName = propNames[0];
					var variantName = "";
					if ($scope.ware._variantsByOutlets[i].values) {
						$scope.ware._variantsByOutlets[i].values.forEach(function(value) {
							if (variantName === "")
								variantName = "" + value;
							else
								variantName = variantName + "/" + value;
						});
					} else {
						$scope.ware._variantsByOutlets[i].variantValues.forEach(function(value) {
							if (variantName === "")
								variantName = "" + value;
							else
								variantName = variantName + "/" + value;
						});
					}
					if (variantName === propName) {
						$scope.ware._variantsByOutlets[i].variantArticle = varArtArr[k][propName];
					}
				}

			}
			$scope.containerForChangedArticles = [];
		}



		$scope.variantsWithStockAndCurrencyValuesProcessedForServer = angular.copy($scope.ware.variations.variants);
		$scope.variantsWithStockAndCurrencyValuesProcessedForServer.forEach(function(variant) {
			variant.salePrice = (variant.salePrice && variant.salePrice !== null && variant.salePrice !== "") ? currencyToCoin(filterRepairCurrenFormatWhenFocus(variant.salePrice)) : null;
			variant.freePrice = (variant.salePrice === null || variant.salePrice === "");

			if (variant.primeCost && variant.primeCost !== null) {
				variant.primeCost = variant.primeCost ? currencyToCoin(variant.primeCost) : ($scope.inventoryEnabled) ? '000' : null;
			}

			if (variant.purchaseCost && variant.purchaseCost !== null) {
				variant.purchaseCost = (variant.purchaseCost && $scope.ware.keepCount) ? currencyToCoin(variant.purchaseCost) : null;
			}

		});

		if ($scope.outlets.length < 2 && $scope.ware.variations.variants.length > 0) {
			for (let i = 0; i < $scope._variantsByOutlets.length; i++) {
				$scope._variantsByOutlets[i].variantArticle = $scope.ware.variations.variants[i].article;
				$scope._variantsByOutlets[i].enable = $scope.ware.variations.variants[i].enable;
				$scope._variantsByOutlets[i].salePrice = $scope.ware.variations.variants[i].salePrice;
			}
		} else if ($scope.outlets.length < 2 && $scope.ware.variations.variants.length === 0) {
			$scope.ware._variantsByOutlets[0].enable = ($scope.ware._outlets && $state.current.name === 'goods.edit') ? $scope.ware._outlets[0].enable : $scope.ware.allowedForSale;
		}


		if (!$scope.permits.editProfile) {
			serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
			return;
		}

		// validation (check) for correct input of price values in all
		// 3 display options
		if (!$scope.showDel) {
			if (($scope.ware._salePrice !== null)
				&& (currencyToCoin($scope.ware._salePrice) > $scope.maxNumber)
				|| (!$scope.ware._complex && $scope.ware._primeCost !== null
					&& currencyToCoin($scope.ware._primeCost) > $scope.maxNumber)) {
				return;
			}
			for (let i in $scope.ware._outlets) {
				if ($scope.ware._outlets[i].salePrice
					&& (currencyToCoin($scope.ware._outlets[i].salePrice) > $scope.maxNumber)) {
					return;
				}
			}
		}
		if ($scope.showDel && ($scope.outlets.length < 2)) {
			if (($scope.ware._outlets[0].salePrice !== null)
				&& (currencyToCoin($scope.ware._outlets[0].salePrice) > $scope.maxNumber)
				|| (!$scope.ware._complex && $scope.ware._primeCost !== null
					&& (currencyToCoin($scope.ware._primeCost) > $scope.maxNumber))) {
				return;
			}
		}
		if ($scope.showDel && ($scope.outlets.length > 1)) {
			if (($scope.ware._salePrice !== null) && (currencyToCoin($scope.ware._salePrice) > $scope.maxNumber) || (!$scope.ware._complex && $scope.ware._primeCost !== null && (currencyToCoin($scope.ware._primeCost) > $scope.maxNumber))) {
				return;
			}
			for (let i in $scope.ware._outlets) {
				if ($scope.ware._outlets[i].salePrice && (currencyToCoin($scope.ware._outlets[i].salePrice) > $scope.maxNumber)) {
					return;
				}
			}
		}
		//-- END validation (check) for correct input of price values in all 3 display options

		//-- check if there are components, if the product is composite
		if (($scope.ware._complex === true && !$scope.ware.ingredients.length) ||
			$scope.ware.name === undefined || $scope.ware.name.length > 64 ||
			($scope.ware.variations.variants.length === 0 && !$scope.ware.article)) {
			showUnableToSaveToast();
			$scope.$broadcast('validate', {});
			return;
		}
		//-- END check if there are components, if the product is composite

		var fiscalTaxesCount = 0;
		for (let i = 0; i < $scope.ware._taxes.length; i++) {
			if ($scope.ware._taxes[i]._select && $scope.ware._taxes[i].taxGroup) {
				fiscalTaxesCount++;
			}
		}
		if (fiscalTaxesCount > 1) {
			serviceDialog.add({
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate('ATTENTION'),
				content: translate('CANNOT_BE_APPLIED_TO_ITEM'),
				buttons: [{
					class: 'primary',
					text: 'ON_OK'
				}]
			});
			return;
		}

		$scope.ware.defaultSupplier = $scope.suppliersSelectedItemId;

		if (addedImages.length > 0 && addedImages.indexOf($scope.ware._imgName) > -1) {
			var usedImg = addedImages.indexOf($scope.ware._imgName);
			addedImages.splice(usedImg, 1);
			$scope.ware.unUsedImgs = addedImages.length > 0 ? addedImages.length : null;
		} else {
			$scope.ware.unUsedImgs = null;
		}

		if ($scope.ware.variations.options.length > 0) {
			for(let i = 0; i < $scope.ware._variantsByOutlets.length; i++) {
				let prevWareState = ware._outlets.find(outlet => {
					return outlet.variantArticle === $scope.ware._variantsByOutlets[i].variantArticle &&
						outlet.outletId === $scope.ware._variantsByOutlets[i].outletId;
				});

				$scope.ware._variantsByOutlets[i].stockChanged =
					!prevWareState || $scope.ware._variantsByOutlets[i].count !== prevWareState.count
			}
		} else {
			for(let i = 0; i < $scope.ware._outlets.length; i++) {
				$scope.ware._outlets[i].stockChanged = $scope.ware._outlets[i].count !== ware._outlets[i].count;
			}
		}

		if ($state.current.name === 'goods.create') {

			$scope.progress = true;
			$timeout(function() {
                wareObj = angular.copy($scope.ware);
				wareObj.variations.variants = $scope.variantsWithStockAndCurrencyValuesProcessedForServer;
				factoryGoods
					.createWare(wareObj)
					.then(function(res) {
						switch (res.result) {
							case 'ok':
								//- март 2017 обьекта res.error НЕ СУЩЕСТВУЕТ
								if (res.error) {
									if (res.error === 'article_exists') {
										serviceToast.show('ARTICLE_EXIST', 'error');
									} else if (res.error === 'variant_article_exists') {
										serviceToast.show('VARIANT_ARTICLE_EXISTS', 'error');
									}
									$scope.progress = false;
								} else {
									$scope.createEditWareForMixpanel($scope.ware, "Item created");
									ware = angular.copy($scope.ware);
									serviceToast.show('CREATE_GOODS_SUCCESS', 'success');
									backToList().finally(() => {
										$scope.progress = false;
									});
								}
								break;

							case 'error':
								if (res.message === 'article_exists') {
									var sameSku = ($scope.ware.variations.variants.length < 1 || ($scope.ware.variations.variants.length > 0 && res.articleExists.length === 1)) ? translate('ERROR_CREATE_GOODS_TEXT') : translate('ERROR_CREATE_GOODS_SKU_TEXT');
									var numbersOfSku = ($scope.ware.variations.variants.length > 0 && res.articleExists.length === 1) ? translate('ARTICLE') + ": " + res.articleExists[0] : ($scope.ware.variations.variants.length > 0 && res.articleExists.length > 1) ? translate('ARTICLES') + ": " + res.articleExists.join(', ') : "";
									serviceDialog.add({
										templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
										title: translate('ERROR_CREATE_GOODS'),
										content: sameSku,
										content1: numbersOfSku,
										buttons: [{
											class: 'primary',
											text: 'ON_OK',
											action: function() {}
										}]
									});
								}
								if (res.message === 'forbidden_for_support_team') {
									serviceToast.show('NO_EDIT_PERMISSION', 'error');
								}
								$scope.progress = false;
								break;
						}

					}, function(err) {
						serviceToast.show('ERROR_CREATE_GOODS', 'error');
						$scope.progress = false;
					});

			});
		} else {
			$scope.progress = true;
			$timeout(function() {
				wareObj = angular.copy($scope.ware);
				wareObj.variations.variants = $scope.variantsWithStockAndCurrencyValuesProcessedForServer;
				$scope.progress = true;
				factoryGoods
					.editWare(wareObj)
					.then(res => {
						switch (res.result) {
							case 'ok':
								//- март 2017 обьекта res.error НЕ СУЩЕСТВУЕТ
								if (res.error) {
									switch (res.error) {
										case 'no_ware_category_id':
											serviceToast.show('no_ware_category_id', 'error');
											$scope.progress = false;
											break;
										default:
										{
											serviceToast.show('ARTICLE_EXIST', 'error');
											$scope.progress = false;
										}
									}
								} else {
									$scope.createEditWareForMixpanel($scope.ware, "Item edited");
									serviceToast.show('WARE_EDIT_SUCCESS', 'success');
									ware = angular.copy($scope.ware);
									offStateChangeStart();
									backToList().finally(() => {
										$scope.progress = false;
									});
								}
								break;

							case 'error':
								if (res.message === 'article_exists') {
									$scope.progress = false;
									var sameSku = ($scope.ware.variations.variants.length < 1 || ($scope.ware.variations.variants.length > 0 && res.articleExists.length === 1)) ? translate('ERROR_CREATE_GOODS_TEXT') : translate('ERROR_CREATE_GOODS_SKU_TEXT');
									var numbersOfSku = ($scope.ware.variations.variants.length > 0 && res.articleExists.length === 1) ? translate('ARTICLE') + ": " + res.articleExists[0] : ($scope.ware.variations.variants.length > 0 && res.articleExists.length > 1) ? translate('ARTICLES') + ": " + res.articleExists.join(',') : "";
									serviceDialog.add({
										templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
										title: translate('ERR_EDIT_WARE'),
										content: sameSku,
										content1: numbersOfSku,
										buttons: [{
											class: 'primary',
											text: 'ON_OK',
											action: function() {}
										}]
									});
								} else if(res.message === 'item_deleted') {
									dialogs.itemDeletedEarlier()
										.then(() => {
											offStateChangeStart();
											backToList().finally(() => {
												$scope.progress = false;
											});
										})
								} else {
									$scope.progress = false;
								}
								break;
						}
					}, function(err) {
						serviceToast.show('ERROR_CREATE_GOODS', 'error');
						$scope.progress = false;
					});
			});
		}
	};

	_init();

	$scope.onDeleteWare = function() {

		if (!$scope.permits.editProfile) {
			serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
			return;
		}

		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate('DEL_WARE'),
			content: translate('CONFIRM_DEL_WARE'),
			buttons: [{
				text: 'ON_CANCEL'
			},
				{
					class: 'primary l-margin-left-8-i',
					text: 'ON_DEL',
					action: function() {
						factoryGoods
							.deleteWares($stateParams.id)
							.then(res => {
								if (res.result === 'error' && res.message === 'item_deleted') {
									dialogs.itemDeletedEarlier().then(backToList);
								} else {
									ware = angular.copy($scope.ware);

									if (addedImages.length > 0) factoryGoods.deleteUnusedImages({ imgsNames: addedImages });

									backToList();

									serviceToast.show('WARE_DEL_SUCCESS', 'success');
								}
							}, function(err) {
								switch (err.result) {
									case 'empty_del':
										console.log('empty_del');
										break;
									default:
										serviceToast.show('ERROR_DEL_WARE', 'error');
								}
							});
					}
				}
			]
		});
	};

	function isChanged() {
		$scope.ware._outlets.forEach(function(outlet) {
			if (outlet.salePrice === "") {
				outlet.salePrice = null;
			}
		});

		return !angular.equals($scope.ware, ware);
	}


	function onDiscardCb() {
		if (addedImages.length > 0) {
			factoryGoods.deleteUnusedImages({ imgsNames: addedImages });
		}
	}

	$scope.showAverageCostEdit = true;
	$scope.averageCostDisabled = true;
	$scope.averageCostEdit = function(){

		if (!$scope.permits.editProfile) {
			serviceToast.show('U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error');
			return;
		}

		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-edit-average-cost.html',
			title: translate('EDIT_AVERAGE_COST'),
			content: translate('EDIT_AVERAGE_COST_TEXT'),
			buttons: [{
				text: 'ON_CANCEL'
			},
				{
					class: 'primary',
					text: 'WEBHOOKS.EDIT',
					action: function() {
						$scope.averageCostDisabled = false;
						$scope.showAverageCostEdit = false;
					}
				}
			]
		});
	}

	shareScope.prepForBroadcast($scope);

	$scope.uploader = new FileUploader({autoUpload: false});

	$scope.uploader.onAfterAddingFile = (item) => {
			$scope.ware.imageBlob = item._file;
			$scope.ware.imgUrl = URL.createObjectURL(item._file);
			$scope.ware.imageDeleted = false;
			$scope.$apply();
	}

	// Adding category in item card
	const addedCategory = {
		name: ''
	};

	// Create category
	const createCategory = form => {
		if (form.$invalid) {
			showUnableToSaveToast();
		} else {
			factoryCategories.createCategoryInItemCard(addedCategory).then(savedCategoryResponse => {
				if (savedCategoryResponse.result === 'ok') {
					factoryCategories.getCategoriesListForItemCard().then(categoriesResponse => {
						if (categoriesResponse.result === 'ok') {
							$scope.categories = categoriesResponse.categories;
							setEmptyCategory();
							$scope.ware.wareCategoryId = savedCategoryResponse.id;
							serviceDialog.close(categoryDialog);
							serviceToast.show('CATEGORY_CREATE_SUCCESS', 'success');
						}
					});
				} else if (savedCategoryResponse.message === 'category_name_exists') {
					form.categoryName.$setValidity('exists', false);
					showUnableToSaveToast();
				}
			});
		}
	}

	// Validation change when text is modified
	const onCategoryNameChange = form => {
		if (form.categoryName.$error.exists) {
			form.categoryName.$setValidity('exists', true);
		}
	}

	const categoryDialog = {
		templateUrl: 'dashboard-page/templates/dialog/dialog-create-category.html',
		category: addedCategory,
		createCategory: createCategory,
		onCategoryNameChange: onCategoryNameChange,
		buttons: [
			{
				action: () => {
					addedCategory.name = '';
				}
			}
		]
	}

	// Add category button data
	$scope.addCategoryButton = {
		text: 'ON_ADD_CATEGORY',
		onClickFn: function () {
			addedCategory.name = '';
			serviceDialog.add(categoryDialog);
		}
	}


  $scope.popover = factoryPopover;

  $scope.openPopovers = {
    productContain: false,
    productExplanation: false,
  };

  $scope.breakpoints = {
    0: "top-right",
    300: "top",
  };

  const onResize = () => {
    $scope.$apply(() => factoryPopover.disable($scope.openPopovers));
  };

  this.$onInit = () => $window.addEventListener("resize", onResize);
  this.$onDestroy = () => $window.addEventListener("resize", onResize);

	const getColorForMixpanel = colorName => colorName ? colorName.charAt(0).toUpperCase() + colorName.slice(1).toLowerCase() : 'Grey';

  return this;
}

export default {
  type: "controller",
  name: "createEditWareControl",
  value: createEditWareControl,
};
