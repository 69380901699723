/**
 * Created by yarosh on 22.11.17.
 */

propertyNameError.$inject = [];

function propertyNameError() {

    return {
        restrict: 'A',
        link: linkFn,
        require: '^ngModel'
    };

    function linkFn(scope, element, attr, controller) {

        //the first option in attr.propertyNameError is an exact name of a property inside an object that we are passing like an entity for comparison, the second one - is a camelCase variant of a             //key for i18 files to be translated after it is transformed into a constant notation.
        var adjacentValidationMessage = attr.valid, //get a name of a certain validation option, passed to our lv-valid directive, working along with this current directive
            arrayForVariableMessageFromDirAttr = attr.propertyNameError.replace(/\s/, "").split(","),
            arrayForI18Key,
            variableMessageKeyForTranslate;

        if ( Array.isArray(arrayForVariableMessageFromDirAttr) ){
            //use regex to split a string by capitalized letters and then transform each element of an array into uppercase words
            //in order to join it with an underscore symbol, having eventually a key for i18 files and further translations

            arrayForI18Key = arrayForVariableMessageFromDirAttr[1].split(/(?=[A-Z])/);
            variableMessageKeyForTranslate = arrayForI18Key.map(function(particle){
               return particle.toUpperCase();
            });
            variableMessageKeyForTranslate = variableMessageKeyForTranslate.join("_");


            element.on('blur', function () {

                // console.log(controller);
                // console.log(element);

                var currentElementIndex = scope.$parent.$index;

                var value = $(element).val();
                value = value.trim();
                var inputName = attr.name;

                var obj = JSON.parse(attr.options);

                if(obj instanceof Object){

                    for (var i = 0, len = obj.length; i < len; i++) {
                        if(obj[i][arrayForVariableMessageFromDirAttr[0]]){
                            if (parseInt(currentElementIndex) !== i && obj[i][arrayForVariableMessageFromDirAttr[0]].toLowerCase().trim() === value.toLowerCase() && controller.$error.hasOwnProperty(adjacentValidationMessage) !== true) {
                                controller.$setValidity(inputName, false);
                                controller.setAlertMessage(variableMessageKeyForTranslate);
                                break;
                            } else if (obj[i][arrayForVariableMessageFromDirAttr[0]].toLowerCase().trim() !== value.toLowerCase() && value !== undefined && value !== "" && controller.$error.hasOwnProperty(adjacentValidationMessage) !== true) {
                                controller.$setValidity(inputName, true);
                                controller.$invalid = false;
                                controller.$error = {};
                                controller.err = {};
                                controller.$valid = true;
                                $(element).parent().removeClass('has-error');
                                $(element).removeClass('ng-invalid ng-invalid-name');
                                controller.setAlertMessage(null);
                            }
                        }
                    }

                }

            });
        }

    }
}

export default {
    type: 'directive',
    name: 'propertyNameError',
    value: propertyNameError
};