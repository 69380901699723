/**
 * Created by sirosh on 09.08.16.
 */

const waresReportTable = {
    category      : 'CATEGORY',
    discountSum   : 'REPORT_SALES.PAYMENT_OF_BONUSES',
    name          : 'INVENTORY.ITEMS_TABLE.ITEM',
    profitSum     : 'REPORT_SALES.NET_SALES',
    refundQuantity: 'REPORT_CATEGOR.ITEMS_REFUNDED',
    refundSum     : 'REPORT_SALES.REFUNDS',
    saleQuantity  : 'REPORT_GOODS.GOODS_SOLD',
    saleSum       : 'REPORT_SALES.GROSS_SALES',
    taxSum        : 'REPORT_GOODS.TAX_SUM',
    sku           : 'ARTICLE',
    primeCost     : 'REPORT_GOODS.PRIME_COST_ITEM',
    grossProfit   : 'REPORT_GOODS.GROSS_PROFIT',
    margin        : 'MARGIN_PERCENT'
};

export default {
    type: 'constant',
    name: 'waresReportTable',
    value: waresReportTable
}