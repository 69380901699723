/**
 * Created by A.Sirosh on 11.11.2015.
 * @memberof tooltipDirective
 * @ngdoc directive
 * @name tooltipDirective
 * @parequiresram $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 */

tooltipDirective.$inject = ["$filter"];
function tooltipDirective($filter) {
	return {
		restrict: 'A',
		/**
		 * @param scope
		 * @param element
		 * @param attrs
		 */
		link: function (scope, element, attrs) {
			if (attrs.toggle == "tooltip") {

				scope.$watch("translateIssue.tooltip", function (val) {
					if (val !== undefined) {
						scope.compare = val;
						$(element).attr("data-original-title", scope.compare);
						$(element).tooltip();
					}
				});
			}
		}
	};
}

export default {
	type: 'directive',
	name: 'tooltipDirective',
	value: tooltipDirective
};