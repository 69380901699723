/**
 * Created by Alex on 03.11.17
 */

import template from '../templates-pug/for-directive/technical-report-message.pug';

technicalReportMessage.$inject = [];
function technicalReportMessage() {
    return {
        restrict: 'E',
        template: template,
        scope: {
            from: '=',
            to: '='
        },
        controller: technicalReportMessageController,
        controllerAs: 'maintenanceBanner',
        link: function(scope){

            // console.log(scope.from, scope.to);

        }
    };
}

technicalReportMessageController.$inject = ['$scope', '$rootScope', 'tableColumnProp'];
function technicalReportMessageController($scope, $rootScope, tableColumnProp) {
    var vm = this;
    $scope.permits = $rootScope.permits;
    vm.dontShowbanner = function () {
        tableColumnProp.hideMaintenanceBanner({}).then(function (data) {
            if (data.result === 'ok') {
                $scope.$emit('dontShowBanner');
            }
        });
    }
}

export default {
    type: 'directive',
    name: 'technicalReportMessage',
    value: technicalReportMessage
};