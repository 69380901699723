import angular from 'angular';

currencyLocalizationFilter.$inject = [];
function currencyLocalizationFilter() {

    let optionsDefault = {
        allowDecimalPadding: true
    };

    format.$stateful = true;
    function format(val, _options, progress){

        let options = angular.extend({}, optionsDefault, _options);
        
        if (angular.isNumber(val)) {
            val = val.toString();
        }

        if (val === '' || angular.isUndefined(val)) {
            return '';
        }

        if (val === options.decimalCharacter || val === '.') {
            if (options.currencySymbol) {
                if (options.currencySymbolPlacement === 'p') {
                    return options.currencySymbol + options.decimalCharacter;
                } else {
                    return options.decimalCharacter + options.currencySymbol;
                }
            }
            else {
                return options.decimalCharacter;
            }
        }

        val = val.replace(options.decimalCharacter, '.');
        val = val.replace(/\s/, '');

        const minusOnly = val === '-';
        const minusSeparatorOnly = val === '-.';
        if ((minusOnly || minusSeparatorOnly) && !progress) {
            return '';
        }

        let numVal = parseFloat(val);
        if (isNaN(numVal) && !minusOnly && !minusSeparatorOnly) {
            return val;
        }

        let res = [];

        let trailingDecChar = val[val.length - 1] === options.decimalCharacter;
        let startingDecChar = val[0] === options.decimalCharacter;

        let parts;

        if (!progress) {
            // Rounding

            let isNegative = numVal < 0;
            let mainNumAbs = Math.abs(numVal);

            let mainNum = Math.trunc(mainNumAbs);
            let decimalNum = mainNumAbs - mainNum;
            if (decimalNum > 0) {
                let multiplier = Math.pow(10, options.decimalPlacesOverride);
                if (options.roundingMethod === 'S') {
                    mainNumAbs = Math.round((mainNum + decimalNum) * multiplier) / multiplier;
                } else if (options.roundingMethod === 'D') {
                    mainNumAbs = Math.floor((mainNum + decimalNum) * multiplier) / multiplier;
                }
            }

            numVal = isNegative ? mainNumAbs * (-1) : mainNumAbs;

            parts = numVal.toString().split('.');
        }
        else {
            parts = val.split('.');
        }

        if (parts[0] === '0' && startingDecChar) {
            parts[0] = '';
        }

        if (trailingDecChar && parts.length < 2) {
            parts.push('');
        }

        let main = parts[0];

        let isNegative = main[0] === '-';
        if (isNegative) {
            main = main.slice(1);
        }

        res.unshift(formatMain(main, options, progress));

        if (options.allowDecimalPadding && parts.length === 1 && !progress) {
            parts.push('');
        }

        let decimalPart = parts.length > 1 ? formatDecimal(parts[1], options, progress) : null;

        if (decimalPart !== null) {
            res.push(options.decimalCharacter);
            res.push(decimalPart);
        }

        if (options.currencySymbol) {
            if (options.currencySymbolPlacement === 'p') {
                res.unshift(options.currencySymbol);
            } else {
                res.push(options.currencySymbol);
            }
        }

        if (isNegative) {
            res.unshift('-');
        }

        return res.join('');
    }

    function formatMain(source, options, progress) {
        let result = [];
        while(source[0] === '0' && source.length > 1) {
            source = source.substring(1);
        }
        for (let i = source.length - 1; i >= 0; i--) {
            let reverseIndex = source.length - 1 - i;
            if(reverseIndex > 2){
                if (((reverseIndex - 3) % options.digitalGroupSpacing) === 0) {
                    result.unshift(options.digitGroupSeparator);
                }
            }
            result.unshift(source[i]);
        }
        if (result.length === 0 && !progress) {
            result.push('0');
        }
        return result.join('');
    }

    function formatDecimal(source, options, progress) {

        let result = source;

        if (result.length > options.decimalPlacesOverride) {
            result = result.slice(0, options.decimalPlacesOverride);
        }

        if (!progress) {

            if (options.allowDecimalPadding) {
                let emptyCharsNum = options.decimalPlacesOverride - result.length;
                if (emptyCharsNum > 0) {
                    for (let i = 0; i < emptyCharsNum; i++) {
                        result += '0';
                    }
                }
            }
            else {
                // Remove trailing zero characters on complete format
                let trailingZeroCount = 0;
                for (let i = result.length - 1; i >= 0; i--) {
                    if (result[i] === '0') {
                        trailingZeroCount ++;
                    } else {
                        break;
                    }
                }
                if (trailingZeroCount > 0) {
                    result = result.slice(0, result.length - trailingZeroCount);
                }
            }

            if (result.length === 0) {
                result = null;
            }
        }

        return result;
    }

    return format;
};

export default {
    type: 'filter',
    name: 'currencyLocalizationFilter',
    value: currencyLocalizationFilter
};
