import angular from 'angular';

createDirective.$inject = ['$parse'];

function createDirective($parse) {
    return {
        restrict: 'A',
        require: 'mdVirtualRepeat',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, virtualRepeatCtrl) {

        let cb = $parse(attrs['lvVirtualRepeatEmitter'])(scope);
        if (!angular.isFunction(cb)) {
            return;
        }

        function updateScopeDecorator(fn) {
            return function(scope_, index) {
                fn.call(this, scope_, index);
                cb();
            }
        }

        virtualRepeatCtrl.updateScope_ = updateScopeDecorator(virtualRepeatCtrl.updateScope_);
    }
}

export default {
    type: 'directive',
    name: 'lvVirtualRepeatEmitter',
    value: createDirective
};
