/**
 * Created by ALEX on 2.10.17.
 */

import angular from 'angular';

editdiningCtrl.$inject = [
		"$scope",
		"$state",
		"$stateParams",
		"$filter",
		"serviceDialog",
		"serviceToast",
		"$profile",
		"$diningById",
		"getDining",
		'dialogs'];
function editdiningCtrl(
			$scope,
			$state,
			$stateParams,
			$filter,
			serviceDialog,
			serviceToast,
			$profile,
			$diningById,
			getDining,
			dialogs) {


// БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ
	$scope.profile = angular.copy($profile);
	var translate 		= $filter( 'translate' );
	$scope.clicked 		= true;
	$scope.useMultishop = $profile.multishopEnable;
	$scope.diningEdit   = angular.copy($diningById);

	if ($scope.diningEdit["name"] == null) {
		$scope.diningEdit["name"] = translate("DINING." + $scope.diningEdit["type"]);
	}


	$scope.outlets 		= angular.copy($diningById.outlets);

	$scope.waresCount 	= 0;
	$scope.outletSelect = [];
	$scope.allOutlets 	= {};
	$scope.uniqueName = true;
	$scope.progress = false;
// БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ

	let initParams = {
		name: $scope.diningEdit.name,
		outlets: $scope.outlets.map(item => {
			return {
				name: item.name,
				available: item.available
			};
		})
	};
	let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
	function isChanged() {
		let currentParams = {
			name: $scope.diningEdit.name,
			outlets: $scope.outlets.map(item => {
				return {
					name: item.name,
					available: item.available
				};
			})
		};

		return !angular.equals(initParams, currentParams);
	}


//** БЛОК СПИСКА ЗАВЕДЕНИЙ  - СЕЛЕКТ
	$scope.$watch('outlets', function(newVal){
		for(var j = 0; j < newVal.length; j++){
			if(newVal[j].available === true){
				$scope.outletSelect.push(newVal[j].outletId);
				$scope.waresCount += newVal[j].waresCount;
			}
		}
		$scope.allOutlets._select = $scope.outletSelect.length === $scope.outlets.length;
		$scope.diningEdit.outletsIds = $scope.outletSelect;
		$scope.diningEdit.allowedForAllOutlets = $scope.allOutlets._select;
		$scope.outletSelect = [];
	}, true);


	$scope.changeAll = function(){
		if($scope.allOutlets._select === true) {
			$scope.outlets.forEach((outlet) => {
				outlet["available"] = true;
			});
			$scope.allOutlets._select = true;
		} else {
			$scope.outlets.forEach((outlet) => {
				outlet["available"] = false;
			});
			$scope.allOutlets._select = false;
		}
	};
//** БЛОК СПИСКА ЗАВЕДЕНИЙ  - СЕЛЕКТ



//** Контейнер для СПИСКА ЗАВЕДЕНИЙ  - ПОКАЗЫВАЕМ/ПРЯЧЕМ  ВЕСЬ СПИСОК
	$scope.showLine = true; // показывать линию в главном блоке
	$scope.showAllOutlets = false;   // показывать весь блок с заведениями

	$scope.showBlock = function (flag) {
		$scope.showAllOutlets = flag;
		$scope.showLine = !flag;
	};
//** Контейнер для СПИСКА ЗАВЕДЕНИЙ  - ПОКАЗЫВАЕМ/ПРЯЧЕМ  ВЕСЬ СПИСОК

	$scope.onCancel = function () {
		backToList();
	};

	$scope.onDel = function () {

		$scope.onSendData = function () {
			getDining.deleteDining( {id: $scope.diningEdit.id} ).then( function ( res ) {
				if ( res.result === "ok" ) {
					offStateChangeStart();
					serviceToast.show( 'DINING.TOSTS.DELETED_DINING', 'success' );
					backToList();
				} else if (res.result === "error" && res.message === 'dining_option_deleted') {
					offStateChangeStart();
					dialogs.itemDeletedEarlier().then(backToList);
				} else {
					serviceToast.show( 'DINING.TOSTS.ERROR_DEL_DINING', 'error' );
					console.log( res );
				}
			} );
		};

		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate( 'DINING.DELETE_DINING_OPTIONS_TITLE' ),
			content: translate( 'DINING.DELETE_DINING_OPTIONS_MESSAGE' ),
			buttons: [
				{	text 	: 'ON_CANCEL'	},
				{	class 	: 'primary l-margin-left-8-i',
					text 	: 'ON_DEL',
					action 	: $scope.onSendData	}
			]
		} );
	};

	$scope.onSave = function () {

		if ( $scope.clicked === false ) {
			return false;
		}

		if($scope.setDining.$invalid) {
			$scope.setDining.focusFirstInvalidControl();
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			return false;
		}

		function send() {
			$scope.clicked = false;
			$scope.diningEditForSave   = angular.copy($scope.diningEdit);

			if ($scope.diningEditForSave["name"] === translate("DINING." + $scope.diningEditForSave["type"])) {
				$scope.diningEditForSave["name"] = null;
			}
			delete $scope.diningEditForSave.outlets;
			$scope.progress = true;
			getDining.editDining( $scope.diningEditForSave ).then( function ( res ) {
				if ( res.result === "ok" && !res.message ) {
					offStateChangeStart();
					serviceToast.show( 'DINING.TOSTS.EDIT_DINING', 'success' );
					backToList();
				} else if(res.result === "error"){
					if (res.message === "option_exist") {
						$scope.uniqueName = false;
						$scope.clicked = true;
						serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
					} else if (res.message === "dining_option_deleted") {
						offStateChangeStart();
						dialogs.itemDeletedEarlier().then(backToList);
					} else {
						serviceToast.show( 'DINING.TOSTS.ERROR_EDIT_DINING', 'error' );
						console.log( res );
						$scope.clicked = true;
					}
				}
			}, function () {
				$scope.clicked = true;
			} )
			.finally(() => {
				$scope.progress = false;
			});
		}

		send();

	};

	function backToList() {
		$state.go( 'settings.dining', $stateParams, {
			reload: true
		});
	}
}

export default {
	type: 'controller',
	name: 'editdiningCtrl',
	value: editdiningCtrl
};
