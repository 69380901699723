import angular from 'angular';

inputContainerDirective.$inject = ['$mdUtil'];

function inputContainerDirective($mdUtil) {

    let UNDERLINED_INPUT_TYPES = [undefined, 'text', 'email', 'password'];

    return {
        restrict: 'E',
        controller: InputContainerController,
        compile: compileDirective
    }

    function compileDirective(tElement) {
        tElement.addClass('lv-input-container');

        // Initially untouched state
        tElement.addClass('is-untouched');

        let elementChildren = tElement.children();
        // Do nothing for empty container.
        if (elementChildren.length === 0) {
            return;
        }

        let isAdaptive = false;
        let iconElement;
        let labelElement;
        let editorElement;
        let ngMessagesElement;

        // Initialize children,
        for (let i = 0; i < elementChildren.length; i++) {
            let elementChild = elementChildren[i];

            if (elementChild.tagName === 'MD-ICON' || elementChild.tagName === 'I') {
                iconElement = angular.element(elementChild);
                continue;
            }

            if (elementChild.tagName === 'LABEL') {
                labelElement = angular.element(elementChild);
                // Mark first immediate label as label element.
                labelElement.addClass('lv-input-container__label');
                continue;
            }

            if (elementChild.getAttribute('ng-model')) {
                editorElement = angular.element(elementChild);
                isAdaptive = angular.isDefined(editorElement.attr('lv-adaptive-input'));

                applyEditorAttributes(editorElement);

                // Generate editor id and link label with editor.
                if (labelElement) {
                    // Generate editor id if necessary.
                    if (!editorElement.attr('id')) {
                        // Generate and set new id for editor.
                        editorElement.attr('id', 'editor_' + $mdUtil.nextUid());
                    }
                    // Set link between label and editor element.
                    labelElement.attr('for', editorElement.attr('id'));
                }
                continue;
            }

            if (elementChild.getAttribute('ng-messages')) {
                ngMessagesElement = angular.element(elementChild);
            }
        }

        if (iconElement) {
            const wrapperElement = angular.element('<div>');
            wrapperElement.addClass('lv-input-container__wrapper');
            wrapperElement.append(iconElement);

            const editorContainerElement = angular.element('<div>');
            editorContainerElement.addClass('lv-input-container__editor-container');
            wrapperElement.append(editorContainerElement);
            if (labelElement){
                labelElement.detach();
                editorContainerElement.append(labelElement);
            }
            tElement.append(wrapperElement);
            wrapEditorAndMessages(editorContainerElement);
        } else {
            wrapEditorAndMessages(tElement);
        }
        function applyEditorAttributes(editorElement) {
            // Mark editor element with adapter.
            editorElement.attr('lv-input-container-adapter', '');

            // Mark input editor as underlined element.
            if (editorElement[0].tagName === 'INPUT') {
                let inputType = editorElement.attr('type');

                if (UNDERLINED_INPUT_TYPES.indexOf(inputType) !== -1) {
                    editorElement.attr('lv-underlined-input', '');
                }
            }
        }

        function wrapEditorAndMessages(container) {
            let containerContentElement = angular.element('<div>');
            containerContentElement.addClass('lv-input-container__content');
            if (!labelElement) {
                containerContentElement.addClass('no-label');
            }

            // Replace editor element to container.
            if (editorElement) {
                editorElement.detach();

                if (isAdaptive) {
                    const inputBoxElement = angular.element('<div>');
                    inputBoxElement.addClass('lv-input-container__input-box');

                    inputBoxElement.append(editorElement);
                    containerContentElement.append(inputBoxElement);
                } else {
                    containerContentElement.append(editorElement);
                }
            }
            // Replace ngMessages element to container
            if (ngMessagesElement) {
                ngMessagesElement.detach();
                containerContentElement.append(ngMessagesElement);
            }

            container.append(containerContentElement);
        }
    }
}

InputContainerController.$inject = ['$element'];
function InputContainerController($element) {

    this.element = $element;
    this.setDisabled = setDisabled;
    this.setFocused = setFocused;
    this.setInvalid = setInvalid;
    this.setTouched = setTouched
    this.setReadonly = setReadonly;
    this.setHasValue = setHasValue;
    this.setLayoutSm = setLayoutSm;
    this.setLayoutLg = setLayoutLg;
    this.toggleContainerClass = toggleContainerClass;

    function setReadonly(isReadonly) {
        this.element.toggleClass('is-readonly', isReadonly);
    }

    function setDisabled(isDisabled) {
        this.element.toggleClass('is-disabled', isDisabled);
    }

    function setFocused(isFocused) {
        this.element.toggleClass('is-focused', isFocused);
    }

    function setInvalid(isInvalid) {
        this.element.toggleClass('is-invalid', isInvalid);
    }

    function setTouched(isTouched) {
        this.element.toggleClass('is-touched', isTouched);
        this.element.toggleClass('is-untouched', !isTouched);
    }

    function setHasValue(isHasValue) {
        this.element.toggleClass('is-has-value', isHasValue);
    }

    function setLayoutSm() {
        this.element.addClass('lv-input-container--sm');
        this.element.removeClass('lv-input-container--lg');
    }

    function setLayoutLg() {
        this.element.removeClass('lv-input-container--sm');
        this.element.addClass('lv-input-container--lg');
    }

    function toggleContainerClass(className, enable) {
        this.element.toggleClass(className, enable)
    }
}

export default {
    type: 'directive',
    name: 'lvInputContainer',
    value: inputContainerDirective
};