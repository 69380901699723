import * as Sentry from "@sentry/browser";

run.$inject =[
    '$q',
    '$stateParams',
    '$rootScope',
    '$state',
    'stateGo',
    'serviceOwnerInfo',
    'factoryProfile',
    '$translate',
    'serviceIntercom',
    'factoryPermits',
    'serviceMenu',
    '$cookies',
    'firstVisit',
    'serviceLang',
    'localStorageService',
    'getPermits',
    'promiseOperation',
    'deviceDetector',
    'getBillingData',
    'terminalOperationsFactory',
    'currencyFormat',
    'langCode',
    'retrieveLangCode',
    'resolveTimezone',
    'factoryEnvironment',
    'lvMixpanel'
];

function run($q,
             $stateParams,
             $rootScope,
             $state,
             stateGo,
             serviceOwnerInfo,
             factoryProfile,
             $translate,
             serviceIntercom,
             factoryPermits,
             serviceMenu,
             $cookies,
             firstVisit,
             serviceLang,
             localStorageService,
             getPermits,
             promiseOperation,
             deviceDetector,
             getBillingData,
             terminalOperationsFactory,
             currencyFormat,
             langCode,
             retrieveLangCode,
             resolveTimezone,
             factoryEnvironment,
             lvMixpanel
             ) {
    $rootScope.translateIssue = {};
    $rootScope.isMobile = deviceDetector.isMobile();
    $rootScope.offsetTS = resolveTimezone.timeZoneOffsetForReports();
    $rootScope.params = {ownerId: null};
    $rootScope.permits = factoryPermits;
    $rootScope.localeSupport = retrieveLangCode.toLocaleStringSupportsLocales();
    $rootScope.isIE = ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)); //IF IE > 10
    $rootScope.isEdge = (!$rootScope.isIE && !!window.StyleMedia);
    $rootScope.isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS');
    $rootScope.currentBrowserVersion = navigator.appVersion;

    (function (){
        factoryProfile.getProfile().then( function ( profile ) {
            $rootScope.cabinetLang = profile.cabinetLang;
            $rootScope.decSeparator = profile.moneyFormat.decSeparator;
        })
    })();

        $translate.use('empty');
        var permissions,
            homePage,
            attr = 'home';

        getPermits.getPermitsList({}).then(function (res) {
            serviceMenu.setAccess(res);
            permissions = res;
            return factoryProfile.getProfile();
        }).then(function (profile) {
            $rootScope.country = profile.country;
            serviceIntercom.setup(profile);
            /*** Старт интеркома ***/

            serviceMenu.showLowStockNotification = permissions.includes("ACCESS_WARES");

            serviceMenu.setGrayWhenBlock(profile);

            serviceMenu.setVisible();

            serviceMenu.showCashBox(profile.shiftsEnabled);

            serviceMenu.showOpenReceipts(profile);
            serviceMenu.showKitchenPrinyer(profile);
            serviceMenu.showDiningOptins(profile);

            serviceMenu.helpCenterLang(profile);
            serviceMenu.isSupportAvailable(profile);

            /*** установка языка ***/
            $translate.use(profile.cabinetLang || serviceLang.getLocalStorageLang() || serviceLang.getSystemLang3());

            homePage = serviceMenu.setDefaultPages(profile);

            getBillingData.setAccessStatus(profile.billingInfo);

            if (homePage === 'settings.account') {
                $state.go(homePage, $stateParams);
            }

            return promiseOperation.getPromiseDataFromQ(homePage, attr);
        });
``
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

            //remember the current name of any state and give it an alias as the previous state name when you go to the next one. It's made just in order to have an opportunity of retrieving this name and redirecting to it when it's necessary
            var fromStateName = fromState.name;
            if (fromStateName && fromStateName !== "") {
                stateGo.giveCurrentStateAliasPrevious(fromStateName);
            }


            if ((fromState.name === "" || fromState.name === "goods.create" || fromState.name === "goods.edit") && (toState.name === "goods.price")) {
                toParams.checkFromList = $cookies.getObject('checkWaresList');
                // toParams.checkFromList = $cookies.getObject('expandedWaresList');
            }

            var resultFromNotify = $cookies.getObject('resultFromNotifyCtrl'),
                cashboxParamsFromNotification = $cookies.getObject('cashboxParamsFromNotifyCtrl'),
                cashboxParamsFromVantivTerminalCtrl = $cookies.getObject('cashboxParamsFromVantivTerminalCtrl'),
                cashboxParamsFromVantivCashboxCtrl = $cookies.getObject('cashboxParamsFromVantivCashboxCtrl');

            //в случае обновления страницы браузера на состоянии vantivAuthentication (аутентификация на ВАНТИВЕ) возвращаем на настройки оплат
            // if ((fromState.name == "") && (toState.name == "vantiv.authentication")) {
            //     // console.log("this state was updated", toState.name);
            //     event.preventDefault();
            //     $state.go('settings.paytypes');
            // }
            //в случае обновления страницы браузера на состоянии registration (анкета торговца) возвращаем на настройки оплат
            if ((fromState.name === "") && (toState.name === "vantiv.registration")) {
                // console.log("this state was updated", toState.name);
                event.preventDefault();
                $state.go('settings.paytypes');
            }

            //в случае обновления страницы браузера в момент настройки терминала и при условии дефолтной привязки единственного кассового места владельца (сразу после серийного номера и нажатия "Продолжить", до подтверждения или опровержения тестовой транзакции), отвязываем кассовое место и отправляем пользователя на страницу с настройками оплат
            if ((fromState.name === "") && (toState.name === "vantiv.terminal") && (cashboxParamsFromVantivTerminalCtrl)) {
                // console.log("this state was update", toState.name);
                {
                    terminalOperationsFactory.cancelLinkingTerminalToCashbox({
                        'linkResponseStatus': cashboxParamsFromVantivTerminalCtrl.linkResponseStatus,
                        'linkedCashRegisterId': cashboxParamsFromVantivTerminalCtrl.linkedCashRegisterId,
                        'terminalId': cashboxParamsFromVantivTerminalCtrl.terminalId
                    });
                    event.preventDefault();
                    $state.go('settings.paytypes');
                }
            } else if ((fromState.name === "") && (toState.name === "vantiv.terminal")) {
                event.preventDefault();
                $state.go('settings.paytypes');
            }

            //в случае обновления страницы браузера на состоянии cashbox после выбора кассового места из списка доступных и нажатия кнопки "Продолжить" (до реакции торговца на тестовую транзакцию), отвязываем только что выбранное кассовое место и возвращаем на настройки оплат
            if ((fromState.name === "") && (toState.name === "vantiv.cashbox")) {
                {
                    terminalOperationsFactory.cancelLinkingTerminalToCashbox({
                        'linkResponseStatus': cashboxParamsFromVantivCashboxCtrl.linkResponseStatus,
                        'linkedCashRegisterId': cashboxParamsFromVantivCashboxCtrl.linkedCashRegisterId,
                        'terminalId': cashboxParamsFromVantivCashboxCtrl.terminalId
                    });
                }
                // console.log("this state was update", toState.name);
                event.preventDefault();
                $state.go('settings.paytypes');
            }

            //в случае обновления страницы браузера в момент повторной тестовой транзакции, после того, как торговец получает окно о неудачном соединении, мы проводим отвязку терминала от выбранного кассового места, а также отправляем пользователя на страницу с настройками оплат
            if ((fromState.name === "") && ((toState.name === "vantiv.notification") && (resultFromNotify === "failure"))) {
                terminalOperationsFactory.cancelLinkingTerminalToCashbox({
                    'linkResponseStatus': cashboxParamsFromNotification.linkResponseStatus,
                    'linkedCashRegisterId': cashboxParamsFromNotification.linkedCashRegisterId,
                    'terminalId': cashboxParamsFromNotification.terminalId
                });
                // console.log("this state was update", toState.name);
                event.preventDefault();
                $state.go('settings.paytypes');
            }

            //в случае обновления страницы браузера на окне об успешном подключении терминала к кассовому месту, мы отправляем его на страницу с настройками оплат
            if ((fromState.name === "") && ( ((toState.name === "vantiv.notification") && (resultFromNotify === "success")))) {
                event.preventDefault();
                $state.go('settings.paytypes');
            }

            // конец блока с требуемыми действиями при рефреше браузера на url-ах с настройками терминала Вантив
            if (toState.name === 'createemployee') {
                factoryProfile.getProfile().then(function (profile) {
                    if (profile.hasPin === false) {
                        $state.go('employees', {page: 0});
                    }
                });
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.params.ownerId = $stateParams.id;
            $rootScope.params.currentState = toState;
            $rootScope.params.stateParams = toParams;
            serviceIntercom.update();

            //-STATE Account
            //This event should be triggered when users visit the "Account" page.
            if (toState.name === "myaccount") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Account settings"
                });
            }

            //-STATE Reports
            if (toState.name === "report.sales" && fromState.name === "home") {
                lvMixpanel.track('Back office page visit', {"Property": "Home/Sales summary"});
            }
            if (toState.name === "report.sales" && fromState.name !== "home" && fromState.name !== "report.sales" ||
                toState.name === "report.sales" && fromState.name !== "home" && fromState.name === "report.sales" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales summary"});
            }
            if (toState.name === "report.goods" && fromState.name !== "report.goods" ||
                toState.name === "report.goods" && fromState.name === "report.goods" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales by item"});
            }
            if (toState.name === "report.categories" && fromState.name !== "report.categories" ||
                toState.name === "report.categories" && fromState.name === "report.categories" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales by category"});
            }
            if (toState.name === "report.employee" && fromState.name !== "report.employee" ||
                toState.name === "report.employee" && fromState.name === "report.employee" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales by employee"});
            }
            if (toState.name === "report.receipts" && fromState.name !== "report.receipts" ||
                toState.name === "report.receipts" && fromState.name === "report.receipts" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Receipts"});
            }
            if (toState.name === "report.tax" && fromState.name !== "report.tax" ||
                toState.name === "report.tax" && fromState.name === "report.tax" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Taxes"});
            }

            if (toState.name === "report.shift" && fromState.name !== "report.shift" ||
                toState.name === "report.shift" && fromState.name === "report.shift" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Shifts"});
            }

            if (toState.name === "report.manual" && fromState.name !== "report.manual" ||
                toState.name === "report.manual" && fromState.name === "report.manual" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Notifications"});
            }

            if (toState.name === "report.discounts" && fromState.name !== "report.discounts" ||
                toState.name === "report.discounts" && fromState.name === "report.discounts" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Discounts"});
            }

            if (toState.name === "report.modifiers" && fromState.name !== "report.modifiers" ||
                toState.name === "report.modifiers" && fromState.name === "report.modifiers" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales by modifier"});
            }

            if (toState.name === "report.payTypes" && fromState.name !== "report.payTypes" ||
                toState.name === "report.payTypes" && fromState.name === "report.payTypes" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Reports/Sales by payment type"});
            }

            //-  STATE Items
            if (toState.name === "goods.price" && fromState.name !== "goods.price" ||
                toState.name === "goods.price" && fromState.name === "goods.price" && fromParams.page !== toParams.page) {
                    lvMixpanel.track('Back office page visit', {"Property": "Items/Item list"});
            }
            if (toState.name === "goods.create") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Create item"});
            }
            if (toState.name === "goods.edit") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Edit item"});
            }
            if (toState.name === "goods.categories") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Categories"});
            }
            if (toState.name === "goods.categorycreate") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Create category"});
            }
            if (toState.name === "goods.categoryedit") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Edit category"});
            }
            if (toState.name === "goods.discountlist") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Discounts"});
            }
            if (toState.name === "goods.discountcreate") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Create discount"});
            }
            if (toState.name === "goods.discountedit") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Edit discount"});
            }
            if (toState.name === "goods.modifiers-list") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Modifiers"});
            }
            if (toState.name === "goods.modifiercreate") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Create modifier"});
            }
            if (toState.name === "goods.modifieredit") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Edit modifier"});
            }
            if (toState.name === "goods.import") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Import items"});
            }
            if (toState.name === "goods.printlabels") {
                lvMixpanel.track('Back office page visit', {"Property": "Items/Print labels"});
            }

            //-STATE Inventory
            if (toState.name === "inventory.purchase" && fromState.name !== "inventory.purchase"
                || toState.name === "inventory.purchase" && fromState.name === "inventory.purchase" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Purchase orders"});
            }
            if (toState.name === "inventory.createorder") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Create purchase order"});
            }
            if (toState.name === "inventory.orederdetail") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Purchase order details"});
            }
            if (toState.name === "inventory.editorder") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Edit purchase order"});
            }
            if (toState.name === "inventory.receiveorder") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Receive purchase order"});
            }
            if (toState.name === "inventory.purchaseImport") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Import purchase order"});
            }
            if (toState.name === "inventory.transferImport") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Import transfer order"});
            }
            if (toState.name === "inventory.supplierlist" && fromState.name !== "inventory.supplierlist"
                || toState.name === "inventory.supplierlist" && fromState.name === "inventory.supplierlist" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Suppliers"});
            }
            if (toState.name === "inventory.suppliercreate") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Create supplier"});
            }
            if (toState.name === "inventory.supplieredit") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Edit supplier"});
            }
            if (toState.name === "inventory.transfer" && fromState.name !== "inventory.transfer"
                || toState.name === "inventory.transfer" && fromState.name === "inventory.transfer" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Transfer orders"});
            }
            if (toState.name === "inventory.createtransfer") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Create transfer order"});
            }
            if (toState.name === "inventory.transferdetail") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Transfer order details"});
            }
            if (toState.name === "inventory.edittransfer") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Edit transfer order"});
            }
            if (toState.name === "inventory.adjustment" && fromState.name !== "inventory.adjustment"
                || toState.name === "inventory.adjustment" && fromState.name === "inventory.adjustment" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Stock adjustments"});
            }
            if (toState.name === "addPayMethodStr") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Add-Update payment method"});
            }
            if (toState.name === "addPayMethodBt") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Add-Update payment method"});
            }
            if (toState.name === "editBillingDetails") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit billing details"});
            }
            if (toState.name === "inventory.createadjustment") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Create stock adjustment"});
            }
            if (toState.name === "inventory.adjustdetails") {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Stock adjustment details"});
            }
            if (toState.name === "inventory.history" && fromState.name !== "inventory.history"
                || toState.name === "inventory.history" && fromState.name === "inventory.history" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Inventory history"});
            }
            if (toState.name === "inventory.valuation" && fromState.name !== "inventory.valuation"
                || toState.name === "inventory.valuation" && fromState.name === "inventory.valuation" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Inventory valuation"});
            }
            if (toState.name === "goods.itemHistory"
                && fromState.name !== "goods.itemHistory"
                || toState.name === "goods.itemHistory"
                && fromState.name === "goods.itemHistory"
                && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Inventory management/Item inventory history"});
            }
            //  This event will be triggered when visiting a promo page for Advanced inventory.
            if (toState.name === "inventory.management") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Promo page"
                });
            }
            //  Triggered when users visit the “Inventory counts” page
            if (toState.name === "inventory.counts") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Inventory counts"
                });
            }
            //  Triggered when users visit the “Inventory counts” page
            if (toState.name === "inventory.createcount") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Create inventory count"
                });
            }
            //  Triggered when users visit the “Edit inventory count” page.
            if (toState.name === "inventory.editcount") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Edit inventory count"
                });
            }
            //  Triggered when users visit the “Inventory count details” page.
            if (toState.name === "inventory.countdetail") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Inventory count details"
                });
            }
            //  Triggered when users visit the “Productions” page.
            if (toState.name === "inventory.productions") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Productions"
                });
            }
            //  Triggered when users visit the “Production details” page.
            if (toState.name === "inventory.productiondetail") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Production details"
                });
            }
            //  Triggered when users visit the “Create production” page
            if (toState.name === "inventory.createproduction") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Create production"
                });
            }
            //Triggered when users visit the “Create disassembly” page
            if (toState.name === "inventory.createdisassembly") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Create disassembly"
                });
            }
            //Triggered when users visit the “Duplicate disassembly” page
            if (toState.name === "inventory.disassemblyduplicate") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Duplicate disassembly"
                });
            }
            //Triggered when users visit the “Disassembly details” page
            if (toState.name === "inventory.disassemblydetail") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Inventory management/Disassembly details"
                });
            }

            //-STATE Employees
            if (toState.name === "employees.list") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Employee list"});
            }
            if (toState.name === "employees.createemployee") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Create employee"});
            }
            if (toState.name === "employees.editemployee") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Edit employee"});
            }
            if (toState.name === "employees.permitslist") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Access rigths"});
            }
            if (toState.name === "employees.createpermits") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Create role"});
            }
            if (toState.name === "employees.editpermits") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Edit role"});
            }
            if (toState.name === "employees.createtimecard") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Create timecard"});
            }
            if (toState.name === "employees.edittimecard") {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Edit timecard"});
            }
            if (toState.name === "employees.timecards" && fromState.name !== "employees.timecards" ||
                toState.name === "employees.timecards" && fromState.name === "employees.timecards" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Timecards"});
            }
            if (toState.name === "employees.timeclockreport" && fromState.name !== "employees.timeclockreport" ||
                toState.name === "employees.timeclockreport" && fromState.name === "employees.timeclockreport" && fromParams.page !== toParams.page) {
                lvMixpanel.track('Back office page visit', {"Property": "Employees/Total hours worked"});
            }


            //-STATE Customers
            if (toState.name === "clients.push.ios") {
                lvMixpanel.track('Back office page visit', {"Property": "Customers/Push-notifications for iOS"});
            }
            if (toState.name === "clients.push.android") {
                lvMixpanel.track('Back office page visit', {"Property": "Customers/Push-notifications for Android"});
            }
            if (toState.name === "clients.database") {
                lvMixpanel.track('Back office page visit', {"Property": "Customers/Customer base"});
            }
            if (toState.name === "clients.import") {
                lvMixpanel.track('Back office page visit', {"Property": "Customers/Import customer base"});
            }

            //-STATE integrations
            //--  Triggered when visiting a promo page for Integrations
            if (toState.name === "integrations.management") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Integrations/Promo page"
                });
            }
            //--  Triggered when users visit the “Apps” page.
            if (toState.name === "integrations.apps") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Integrations/Apps"
                });
            }
            //--  Triggered when users visit the “Access tokens” page
            if (toState.name === "integrations.tokens") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Integrations/Access tokens"
                });
            }
            //--  Triggered when users visit the “Create access token” page.
            if (toState.name === "integrations.createToken") {
                lvMixpanel.track('Back office page visit', {
                    "Property": "Integrations/Create access token"
                });
            }

            //-STATE Settings
            if (toState.name === "settings.outlet") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Stores"});
            }
            if (toState.name === "createoutlet") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create store"});
            }
            if (toState.name === "editoutlet") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit store"});
            }
            if (toState.name === "settings.loyalty") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Loyalty"});
            }
            if (toState.name === "settings.profile") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/General settings"});
            }
            if (toState.name === "settings.reciepts") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Receipt"});
            }
            if (toState.name === "settings.kitchen") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Kitchen printers"});
            }
            if (toState.name === "createkitchen") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create printer group"});
            }
            if (toState.name === "editkitchen") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit printer group"});
            }
            if (toState.name === "settings.tax") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Taxes"});
            }
            if (toState.name === "createtax") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create tax"});
            }
            if (toState.name === "edittax") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit tax"});
            }
            if (toState.name === "settings.paytypes") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Payment types"});
            }
            if (toState.name === "paymenttypes.editpaymenttype") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit payment type"});
            }
            if (toState.name === "paymenttypes.createpaymenttype") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create payment type"});
            }
            if (toState.name === "vantiv.registration") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Set terminal/Contact information"});
            }
            if (toState.name === "vantiv.terminal") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Set terminal/Terminal serial number"});
            }
            if (toState.name === "vantiv.cashbox") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Set terminal/Assign terminal to POS"});
            }
            if (toState.name === "vantiv.notification") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Set terminal/Terminal connected or Connection failed"});
            }
            if (toState.name === "settings.cashregister") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/POS devices"});
            }
            if (toState.name === "createcashregister") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create POS"});
            }
            if (toState.name === "editcashregister") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit POS"});
            }
            if (toState.name === "settings.predefined") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Open tickets"});
            }
            if (toState.name === "settings.dining") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Dining options"});
            }
            if (toState.name === "createdining") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Create dining option"});
            }
            if (toState.name === "editdining") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit dining option"});
            }
            if (toState.name === "settings.account") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Billing"});
            }
            if (toState.name === "addPayMethod") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Add-Update payment method"});
            }
            if (toState.name === "editBillingDetails") {
                lvMixpanel.track('Back office page visit', {"Property": "Settings/Edit billing details"});
            }

            //-STATE Feedbacks
            if (toState.name === "recall") {
                lvMixpanel.track('Back office page visit', {"Property": "Feedbacks"});
            }

        });

        $rootScope.$on('$translateChangeSuccess', function () {
            $translate('OWNER').then(function (translation) {
                $rootScope.translateIssue.compare = translation;
            });

            $translate('ADMINISTRATOR').then(function (translation) {
                $rootScope.translateIssue.admin = translation;
            });

            $translate('MANAGER').then(function (translation) {
                $rootScope.translateIssue.manager = translation;
            });

            $translate('CASHIER').then(function (translation) {
                $rootScope.translateIssue.cashier = translation;
            });

            $translate('REPORT_AUTOPILOT.COMPARED_PREVIOUS_PERIOD').then(function (translation) {
                $rootScope.translateIssue.tooltip = translation;
            });

        window.sentry?.then(() => {
            angular.module("app").requires.push("ngSentry");
        });
        
        factoryProfile
            .getProfile()
            .then(({ ownerId }) => Sentry.setUser({ id: ownerId }));
    } );
}


	/* env:dev */
	// angular.module( 'app' ).requires.push( 'imgMaintenance' );
	/* env:dev:end */

export default run;
