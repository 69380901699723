/**
 * Created by mars on 9/8/15.
 * @ngdoc service
 * @name factoryReportGoods
 * @description
 *
 * выдает диалоговое окно с данными ошибок
 */

factoryErrorAlert.$inject = ['serviceDialog', '$filter'];

function factoryErrorAlert(serviceDialog, $filter) {

	var filterTranslate = $filter( 'translate' );

	var dialogErrorNetConnection = null;

	function onError( d, mustReload ) {

		if ( d && d.status === -1 && !dialogErrorNetConnection ) {
			dialogErrorNetConnection = serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-error-network-connection.html',
				title: filterTranslate( 'ERROR_NET_CONNECTION' ), //"Ошибка получения данных",
				content: filterTranslate( 'CHECK_CONNECTION_AND_REFRESH' ),
				buttons: [
					{
						class: 'primary',
						text: 'ON_OK',
						closeOnAction: false,
						action: function () {
							serviceDialog.remove(this);
							dialogErrorNetConnection = null;
							(mustReload)? reloadRoute() : "";
						}
					}
				]
			} );

		}
		else if(!d || (d && d.status !== -1 && d.status !== 423 && d.status !== 403)){
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-request-error.html',
				title: 'ERROR_LOAD_DATA',//"Ошибка получения данных",
				content: 'ACCOUNT.B2_SERVICE_DIALOG.B2_OTHER_ERROR_1',
				buttons: [
					{
						class: 'primary',
						text: 'ON_OK',
						closeOnAction: false,
						action: function () {
							serviceDialog.remove(this);
							(mustReload)? reloadRoute() : "";
						}
					}
				]
			} );
		}
	}


	// перезагрузка страницы после запуска повторного платежа и не только
	function reloadRoute() {
		setTimeout(function () {
			//получаем адрес без параметров
			var uriWithoutHash = window.location.href.split('?')[0];
			//и заменяем текущую страницу без сохранения истории
			window.location.replace(uriWithoutHash);
			// и перезагружаем ее
			window.location.reload(true);
		}, 0);
	}
	// перезагрузка страницы после запуска повторного платежа и не только

	return {
		onError: onError
	};
}

export default {
	type: 'factory',
	name: 'factoryErrorAlert',
	value: factoryErrorAlert
};