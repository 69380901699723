dateLocalizedValidator.$inject = ['$rootScope', '$filter', '$parse', '$translate', 'langCode'];

function dateLocalizedValidator($rootScope, $filter, $parse, $translate, langCode) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {

            let eventualPattern = null;

            const displayPattern = $rootScope.localeSupport && !$rootScope.isIE && !$rootScope.isEdge;
            const currentDateForStandardizedPattern = $filter( 'formatDateByBrowser' )( new Date(), '' );
            const dateParams = stringToDate(currentDateForStandardizedPattern);

            if (displayPattern) {
                const patternOrder = [];
                patternOrder.splice(dateParams.indexOfDay, 0, $translate.instant("date_format.dd"));
                patternOrder.splice(dateParams.indexOfMonth, 0, $translate.instant("date_format.mm"));
                patternOrder.splice(dateParams.indexOfYear, 0, $translate.instant("date_format.yyyy"));
                eventualPattern = patternOrder.join(dateParams.dateFormatDivider);
            }

            let message = $translate.instant('ERROR.pattern_localized_date_err');
            if (eventualPattern) {
                message += ' ' + eventualPattern;
            }

            if (attrs.dateLocalizedMessage) {
                $parse(attrs.dateLocalizedMessage).assign(scope, message);
            }

            modelCtrl.$validators.dateLocalized = val => {
                let isValid = true;

                if (!val) {
                    isValid = false;
                } else {

                    // Remove right-to-left markers for arabic language
                    if ($rootScope.langCountry === langCode.ara) {
                        val = val.replace(/\u200F/gi, '');
                    }

                    let regExpPattern;
                    const valDateParams = stringToDate(val);

                    const localeDivider = valDateParams.dateFormatDivider;
                    const symbols = localeDivider[0].split(' ').join('');
                    const chars  = valDateParams.charsInDate.join('');

                    if (displayPattern) {
                        if(val.split(localeDivider).some(correspondToRexEx)){
                            regExpPattern = '[0-9' + symbols + '\s]{' + valDateParams.firstPartForRegex2.length +'}' +
                                '\\'+localeDivider + '{1}' + '[0-9' + symbols + '\s]{' + valDateParams.secondPartForRegex2.length + '}' +
                                '\\'+ localeDivider + '{1}' + '[0-9' + symbols + '\s]{' + valDateParams.thirdPartForRegex2.length + '}';
                        } else {
                            regExpPattern = '[0-9' + chars + ']{' + valDateParams.firstPartForRegex1.length +',' + valDateParams.firstPartForRegex2.length + '}'
                                + '\\'+ localeDivider + '{1}' + '[0-9' + chars + ']{' + valDateParams.secondPartForRegex1.length + ','+ valDateParams.secondPartForRegex2.length + '}'
                                + '\\'+ localeDivider + '{1}' + '[0-9' + chars + ']{' + valDateParams.thirdPartForRegex1.length + ',' + valDateParams.thirdPartForRegex2.length + '}';
                        }
                    } else {
                        regExpPattern = '[0-9]{2}\/[0-9]{2}\/[0-9]{4}';
                    }

                    const dateRegExp = (regExpPattern) ? new RegExp(regExpPattern) : false;
                    const dateArray = val.split(""+localeDivider+"");
                    const indexOdDay = dateParams.indexOfDay;
                    const indexOfMonth = dateParams.indexOfMonth;
                    const indexOfYear = dateParams.indexOfYear;
                    const dateForCheck = new Date(parseInt( dateArray[indexOfYear] ), parseInt( dateArray[indexOfMonth] ), 0).getDate();

                    if (!val.match(dateRegExp) ||
                        (dateArray[indexOfMonth] > 12 || parseInt(dateArray[indexOfMonth]) === 0) ||
                        (dateArray[indexOdDay] > dateForCheck || parseInt(dateArray[indexOdDay]) === 0)) {
                        isValid = false;
                    }

                    function correspondToRexEx(element) {
                        const specialLocalDateFormat = new RegExp('[0-9]' + '[' + symbols + ']');
                        if(element.match(specialLocalDateFormat)){
                            return true;
                        }
                    }
                }

                return isValid;
            };

            function stringToDate(val) {
                const fixedLocaleDate = $filter('formatDateByBrowser')(new Date(1525737600000), 'calendarInput');
                const dateFormatDivider = fixedLocaleDate.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/);
                const fixedLocaleDateFormat = fixedLocaleDate.split(dateFormatDivider);
                const refinedFixedLocaleDateFormat = [];
                fixedLocaleDateFormat.forEach(function(val){
                    refinedFixedLocaleDateFormat.push(parseInt(val) + '');
                });
                const indexOfDay = (fixedLocaleDateFormat.indexOf("8") !== -1) ? fixedLocaleDateFormat.indexOf("8") : (fixedLocaleDateFormat.indexOf("7") !== -1) ? fixedLocaleDateFormat.indexOf("7") : (fixedLocaleDateFormat.indexOf("9") !== -1) ? fixedLocaleDateFormat.indexOf("9") : (refinedFixedLocaleDateFormat.indexOf("8") !== -1) ? refinedFixedLocaleDateFormat.indexOf("8") : (refinedFixedLocaleDateFormat.indexOf("7") !== -1) ? refinedFixedLocaleDateFormat.indexOf("7") : refinedFixedLocaleDateFormat.indexOf("9");
                const indexOfMonth = (fixedLocaleDateFormat.indexOf("5") !== -1) ? fixedLocaleDateFormat.indexOf("5") : refinedFixedLocaleDateFormat.indexOf("5");
                const fixedYear = $rootScope.langCountry === langCode.tha ? "2561" : "2018";
                const indexOfYear = (fixedLocaleDateFormat.indexOf(fixedYear)!== -1) ? fixedLocaleDateFormat.indexOf(fixedYear) : refinedFixedLocaleDateFormat.indexOf(fixedYear);
                const splitVal = fixedLocaleDate.split(dateFormatDivider);
                const charsInDate = [];
                splitVal.forEach(function(arrElement){
                    if(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)){
                        charsInDate.push(arrElement.match(/[a-zA-ZäöüßÄÖÜ\u0430-\u044f-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/\s]{1,10}/)[0]);
                    }
                });

                return {
                    firstPartForRegex1: refinedFixedLocaleDateFormat[0],
                    firstPartForRegex2: fixedLocaleDateFormat[0],
                    secondPartForRegex1: refinedFixedLocaleDateFormat[1],
                    secondPartForRegex2: fixedLocaleDateFormat[1],
                    thirdPartForRegex1: refinedFixedLocaleDateFormat[2],
                    thirdPartForRegex2: fixedLocaleDateFormat[2],
                    dateFormatDivider: dateFormatDivider,
                    charsInDate: charsInDate,
                    indexOfDay: indexOfDay,
                    indexOfMonth: indexOfMonth,
                    indexOfYear: indexOfYear,
                    dd: parseInt( val[indexOfDay] ),
                    MM: parseInt( val[indexOfMonth] ),
                    yyyy: parseInt( val[indexOfYear] )
                };
            }
        }
    };
}

export default dateLocalizedValidator;
