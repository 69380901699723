import angular from 'angular';

factoryPrintLabels.$inject = [
    'promiseOperation'
];

function factoryPrintLabels(
    promiseOperation
) {
    function searchItemsForPrintLabels(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/searchItemsForPrintLabels',
            params,
            successSearchItemsForPrintLabels
        );
    }

    function successSearchItemsForPrintLabels(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data.items;
            }
        }
    }

    function getWaresByCategories(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getWaresByCategories',
            params,
            successGetWaresByCategories
        );
    }

    function successGetWaresByCategories(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getWaresBySuppliers(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getWaresBySuppliers',
            params,
            successGetWaresBySuppliers
        );
    }

    function successGetWaresBySuppliers(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function createLabels(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/createLabels',
            params,
            successCreateLabels
        );
    }

    function successCreateLabels(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function getWaresByIdForLabels(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getWaresByIdForLabels',
            params,
            successGetWaresByIdForLabels
        );
    }

    function successGetWaresByIdForLabels(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    return {
        searchItemsForPrintLabels: searchItemsForPrintLabels,
        getWaresByCategories: getWaresByCategories,
        getWaresBySuppliers: getWaresBySuppliers,
        createLabels: createLabels,
        getWaresByIdForLabels: getWaresByIdForLabels
    }
}

export default {
    type: 'factory',
    name: 'factoryPrintLabels',
    value: factoryPrintLabels
};