/**
 * Created by mars on 12/15/15.
 */

factoryCategories.$inject = [
	'factoryErrorAlert',
	'$q',
	'$http',
	'lodash'];

function  factoryCategories(factoryErrorAlert,
							$q,
							$http,
							lodash){

	const pager = {
		offset: 0,
		limit: 10,
		page: 0,
		pages: null
	};


	const _loading = {
		req: 0,
		res: 0,
		process: false
	};


	let promiseCategories,
		promise,
		categories = [],
		categoriesByPage = [];
	let isCategoriesListLoaded = false;


	function getLoadPromise() {
		return $q((resolve, reject) => {
			$http.post('/data/ownercab/getwarecategories', {}).then((d) => {
				categories = lodash.cloneDeep(d.data.categories);
				isCategoriesListLoaded = false;
				resolve(categories);
			}, (err) => {
				factoryErrorAlert.onError(err);
				reject(err);
			});
		});
	}

	const getCategoriesListForItemCard = () => {
		return $q( function ( resolve, reject ) {
			$http.post( '/data/ownercab/getwarecategoriesforitemcard', {} ).then( function ( d ) {
				resolve( d.data );
			}, function ( err ) {
				factoryErrorAlert.onError( err );
				reject( err );
			} );
		} );
	}


	function getCategories(reload) {
		if (reload) {
			isCategoriesListLoaded = false;
		}
		if (isCategoriesListLoaded) {
			promiseCategories = (!promiseCategories || promiseCategories.$$state.status === 1) ?
				$q((resolve, reject) => resolve(categories)) : promiseCategories;
		} else {
			promiseCategories = (!promiseCategories || promiseCategories.$$state.status === 1) ?
				getLoadPromise() : promiseCategories;
		}
		return promiseCategories;
	}



	function getCategoriesByPage( stateParams ) {
		if(_loading.req == _loading.res){
			_loading.process = true;
		}
		_loading.req++;

		promise = $q( function ( resolve, reject ) {
			pager.offset = stateParams.page * stateParams.limit;

			$http.post( '/data/ownercab/getwarecategories', {
						offset: pager.offset,
						limit: stateParams.limit
					} || {} )
				.then( function ( d ) {
					pager.pages = d.data.pages;
					categoriesByPage.length = 0;
					for ( var i = 0; i < d.data.categories.length; i++ ) {
						d.data.categories[i]._select = false;
						categoriesByPage.push( d.data.categories[i] );
					}
					_loading.res++;
					if(_loading.res == _loading.req){
						_loading.process = false;
						resolve( categoriesByPage );
					}else{
						reject();
					}
				}, function ( err ) {
					factoryErrorAlert.onError( err );
					reject( err );
				} );

		} );

		return promise;
	}

	function getCategoryById(id) {
		return $q((resolve, reject) => {
			$http
				.post( '/data/ownercab/getwarecategorybyid', {
					id: id
				})
				.then((d) => {
					resolve( d.data );
				}, (d) => {
					factoryErrorAlert.onError(d);
					reject(d);
				});
		});

	}

	function editCategory( category ) {
		return $q( function ( resolve, reject ) {
			$http.post( '/data/ownercab/editwarecategory', category)
				.then( function ( d ) {
					// getCategories(true);
					resolve( d.data );
				}, function ( err ) {
					console.log( err );
					factoryErrorAlert.onError( err );
					reject( err );
				} );

		} );
	}


	function delCategory( ids ) {
		var _ids = [];
		if ( typeof  ids == 'number' || typeof ids == 'string' ) {
			_ids.push( parseInt( ids ) );
		} else {
			_ids = ids;
		}
		return $q( function ( resolve, reject ) {
			$http
				.post( '/data/ownercab/deletewarecategory', {
					deleted: _ids
				} )
				.then( function ( d ) {
					const res = d.data;

					if (res.result === 'error' && res.message === 'category_deleted') {
						reject( {result: 'category_deleted'} );
					}

					categoriesByPage = categoriesByPage.filter(cat => !res.deleted.includes(cat.id));

					getCategories(true);
					resolve(d.data);

				}, function ( err ) {
					factoryErrorAlert.onError( err );
					reject( err );
				} );
		} );
	}

	function createCategory( category ) {
		return $q( function ( resolve, reject ) {
			$http.post( '/data/ownercab/createwarecategory', category)
				.then( function ( d ) {
					resolve( d.data );
				}, function ( err ) {
					factoryErrorAlert.onError( err );
					reject( err );
				} );
		} );
	}

	const createCategoryInItemCard = (category) => {
		return $q((resolve, reject) => {
			$http.post('/data/ownercab/createCategoryInItemCard', category).then((d) => {
				resolve(d.data);
			}, (err) => {
				factoryErrorAlert.onError(err);
				reject(err);
			});
		} );
	}

	return {
		getCategoriesListForItemCard: getCategoriesListForItemCard,
		getCategoriesByPage: getCategoriesByPage,
		getCategoryById: getCategoryById,
		createCategory: createCategory,
		createCategoryInItemCard: createCategoryInItemCard,
		getCategories: getCategories,
		editCategory: editCategory,
		delCategory: delCategory,
		categories: categories,
		loading: _loading,
		pager: pager
	};

}

export default {
	type: 'factory',
	name: 'factoryCategories',
	value: factoryCategories
};
