/**
 * Created by Kiskenbassker on 24.12.17, based on K. Yarosh directive
 *
 * @memberof lvOutlet
 * @ngdoc directive
 * @name app.directive:lvOutlet
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Outlet filter (ul li list)
 *
 * <div lv-category-multi="ctrl.categories"></div>
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/category-filter-multi.pug';

lvCategoryMulti.$inject = [
    '$state',
    '$stateParams',
    'filtersData',
    'lvMixpanel'
];

function lvCategoryMulti($state, $stateParams, filtersData, lvMixpanel){
    return{
        restrict: 'A',
        scope: {
            lvCategoryMulti:'='
        },
        controller: lvCategoryControllerMulti,
        controllerAs: 'lvCategoryCtrlMulti',
        link: linkFn,
        template: template
    };

    function linkFn ($scope) {

        /**
         * @ngdoc function
         * @description
         * watching for changes of a DOM element's attribute "aria-expanded", in particular its value, and if the value was changed and it equals "false", then the current function must invoke another one in order to send $stateParams to a server (an array with Ids).
         */


        $scope.$watch(
            function () {
                var dropdownMenuCategories=document.getElementById('dropdownMenuCategories');
                if (dropdownMenuCategories){
                    return dropdownMenuCategories.attributes['aria-expanded'].nodeValue;
                }
            },
            function (newValue, oldValue) {
                /* отслеживает изменения в атрибуте aria-expanded и в зависамомти от этого меняет содержимое отчета*/
                /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don't need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet above in the controller */

                if ((newValue !== oldValue && newValue == "false") && ($stateParams.categoryIds !== $scope.previousStateIds)) {
                    sendParams();
                }
            }
        );


        /**
         * @ngdoc function
         * @description
         * Sent an array with Ids when dropdown list is collapsed.
         */
        function sendParams () {
            var stateParams = filtersData.setStateParams($stateParams);

            // lvMixpanel.track('Filter', {"Property": "Reports/Filter by store"});
            $state.go($state.current.name, stateParams, true);
        }
    }
}

lvCategoryControllerMulti.$inject = ['$scope', '$stateParams', '$filter'];
function lvCategoryControllerMulti($scope, $stateParams, $filter) {

    var self = this;
    var translate = $filter('translate');
    var parsedIds = [];
    var body = document.querySelector('body');
    $scope.arab = !body.classList.contains('aranav') ? "" : "target-right target";

    /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don't need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet below in a linked function with the watch cycle */

    if($stateParams.categoryIds && ($stateParams.categoryIds !=='all' && $stateParams.categoryIds !== 'clear')) {
        $scope.previousStateIds = $stateParams.categoryIds.split(',');
        $scope.previousStateIds.forEach( function(val) { parsedIds.push(parseInt(val))} );
        $scope.previousStateIds = parsedIds.sort(function(a, b){return a-b});
        $scope.previousStateIds = ($scope.previousStateIds.length > 1) ? $scope.previousStateIds.join(',') : $scope.previousStateIds;
    } else {
        $scope.previousStateIds =  $stateParams.categoryIds;
    }


    // $stateParams.categoryIds = $scope.outletsIds;
    // $stateParams.merchantsIds = $scope.merchantsIds;

    self.getExistingIds = getExistingIds;
    self.selectAll = selectAll;
    self.selectItem = selectItem;
    self.selectOutCheckbox = selectOutCheckbox;
    self.selectAllOutCheckbox = selectAllOutCheckbox;

    $scope.$parent.$parent.multiFilter = true;

    var firstCategory = [
        {
            categoryId: null,
            categoryName: translate('INVENTORY.VALUATION.ALL_CATEGORIES')
        }
    ];

    var lastCategory = [
        {
            categoryId: -1,
            categoryName: translate('WITHOUT_CATEGORY')
        }
    ]
    var categoriesIdsArray = [];
    var forModulus = 10;
    var originatorEv;

    this.openMenu = function($mdOpenMenu, ev) {
        originatorEv = ev;
        $mdOpenMenu(ev);
    };

    $stateParams.categoryIds = ($stateParams.categoryIds) ? $stateParams.categoryIds : null;

    categoriesIdsArray = ($stateParams.categoryIds) ? $stateParams.categoryIds.split(',') : categoriesIdsArray;

    self.categories = firstCategory.concat($scope.$parent.categories);
    self.categories = self.categories.concat(lastCategory);
    self.categories._select = false;

    markInputs();
    displayCategoryNames();

    /**
     * @ngdoc function
     * @description
     * mark the inputs according to ids which are present in state params since it's important because a browser refreshes after each request to the server
     */

    function markInputs() {
        if ($stateParams.categoryIds && $stateParams.categoryIds != "clear" && $stateParams.categoryIds != "all") {
            self.stateParamsCategoriesIds = $stateParams.categoryIds.split(',');
            if (self.stateParamsCategoriesIds.length === (self.categories.length - 1)) {
                self.categories[0]._select = true;
            }

            for (var i = 0; i < self.categories.length; i++) {
                var currentCategoryId = self.categories[i].categoryId;
                if (self.stateParamsCategoriesIds.length === (self.categories.length - 1)) {
                    self.categories[0]._select = true;
                }

                for (var l = 0; l < self.stateParamsCategoriesIds.length; l++) {
                    if (currentCategoryId === parseInt(self.stateParamsCategoriesIds[l])) {
                        self.categories[i]._select = true;
                    }
                }
            }
        } else if ($stateParams.categoryIds == "clear") {
            putAll(false);
        } else if (!$stateParams.categoryIds || $stateParams.categoryIds == "all") {
            putAll(true);
        }
    }


    /**
     * @ngdoc function
     * @description
     * looping through categories to display the current name in the filter for categories according to state params ids array length
     */
    function displayCategoryNames() {
        if ($stateParams.categoryIds && $stateParams.categoryIds != "clear" && $stateParams.categoryIds != "all") {
            var categoriesDeclension;
            var existingCategoryIds = [];

            self.getExistingIds(self.stateParamsCategoriesIds, self.categories, existingCategoryIds);

            var categoriesQuantity = existingCategoryIds.length;


            var modulus = existingCategoryIds.length % forModulus;
            var allCategories;
            var categoriesDeclensionSingle;
            var categoriesDeclensionPlural;
            var categoriesDeclensionPlural2;
            if (existingCategoryIds.length <= 20) {
                allCategories = (self.categories.length === 1) || (categoriesQuantity === self.categories.length - 1);
                categoriesDeclensionSingle = (categoriesQuantity === 1);
                categoriesDeclensionPlural = (categoriesQuantity > 1 && categoriesQuantity < 5);
                categoriesDeclensionPlural2 = (categoriesQuantity >= 5 && categoriesQuantity <= 20);
            } else {
                allCategories = (self.categories.length === 1) || (modulus === self.categories.length - 1);
                categoriesDeclensionSingle = (modulus === 1);
                categoriesDeclensionPlural = (modulus > 1 && modulus < 5);
            }


            switch (true) {
                case (allCategories):
                    self.name = self.categories[0].categoryName;
                    self.categories[0]._select = true;

                    break;

                case (categoriesDeclensionSingle):
                    var singleName;
                    self.categories.forEach(function (val) {
                        if (val.categoryId == $stateParams.categoryIds) {
                            singleName = val.categoryName;
                        }
                    });
                    categoriesDeclension = translate("INVENTORY.VALUATION.SINGLE_CATEGORY");
                    self.name = singleName || (categoriesQuantity + " " + categoriesDeclension);

                    break;

                case (categoriesDeclensionPlural):
                    categoriesDeclension = translate("INVENTORY.VALUATION.PLURAL_CATEGORIES_DECLENSION_1");
                    self.name = categoriesQuantity + " " + categoriesDeclension;

                    break;

                case (categoriesDeclensionPlural2):
                    categoriesDeclension = translate("INVENTORY.VALUATION.PLURAL_CATEGORIES_DECLENSION_2");
                    self.name = categoriesQuantity + " " + categoriesDeclension;

                    break;

                default:
                    categoriesDeclension = translate("INVENTORY.VALUATION.PLURAL_CATEGORIES_DECLENSION_2");
                    self.name = categoriesQuantity + " " + categoriesDeclension;

            }

        } else if ($stateParams.categoryIds == "clear") {
            self.name = translate("INVENTORY.VALUATION.NONE_CATEGORIES");
        } else {
            self.name = self.categories[0].categoryName;
        }
    }

    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of the certain outlet(s) in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectOutCheckbox(category) {
        (!category._select || category._select == "false") ? category._select = true : category._select = false;
        selectItem(category);
    }

    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of all outlets in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectAllOutCheckbox() {
        (!self.categories[0]._select || self.categories[0]._select == "false") ? self.categories[0]._select = true : self.categories[0]._select = false;
        selectAll();
    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of the certain outlet(s) in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectItem(category) {

        if (category._select) {
            categoriesIdsArray.push(category.categoryId);

        } else {
            //if parameter _selected is false, then override by a new array which filter method returns with only those merchantIds that don't equal to selected Ids
            categoriesIdsArray = categoriesIdsArray.filter(function (val) {
                if(val != category.categoryId) {
                    return val;
                }
            });

            if (self.categories[0]._select) {
                self.categories[0]._select = false;
            }
        }


        //after selecting eventual set of ids we need to remove stateParameter "clear" or "all"

        if(categoriesIdsArray.length > 1) {
            if(categoriesIdsArray[0] == "clear" || categoriesIdsArray[0] == "all") {
                categoriesIdsArray.shift();
            }
        }

        self.categoriesIds = (angular.copy(categoriesIdsArray)).sort();

        if(categoriesIdsArray.length == 0){
            $stateParams.categoryIds = "clear";
        } else {
            $stateParams.categoryIds = self.categoriesIds.join(',');
        }

        //in order to check the input with all outlets if each outlet was checked
        if ($stateParams.categoryIds == "all" || categoriesIdsArray.length === self.categories.length-1) {
            self.categories[0]._select = true;
            $stateParams.categoryIds  = "all";
        }

    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all outlets in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectAll() {
        if (self.categories[0]._select) {
            putAll(true);

            if(categoriesIdsArray.length == self.categories.length - 1) {
                $stateParams.categoryIds = "all";
            }

        } else if (!self.categories[0]._select) {
            putAll(false);
            $stateParams.categoryIds = "clear";
        }

    }


    /**
     * @ngdoc function
     * @description
     * If the first element of select was chosen we put a boolean value into object property _select an put object's id into an array or just remove all ids from the array if the boolean vale of the first option is false
     */
    function putAll(param) {
        categoriesIdsArray = [];
        var checked;
        var checkedId;

        if(param === false) {
            categoriesIdsArray = [];
        }

        for (var i = 0; i < self.categories.length; i++) {
            self.categories[i]._select = param;
            checked = param;
            checkedId = self.categories[i].categoryId;
            if(param === true && checkedId) {
                categoriesIdsArray.push(parseInt(self.categories[i].categoryId));
            }
        }

    }

    /**
     * @ngdoc function
     * @description
     * looping through two arrays and push into the third one only existing Ids
     */

    function getExistingIds(arr1, arr2, arr3) {
        for(var k = 0; k < arr1.length; k++) {
            for(var m = 0; m < arr2.length; m++) {
                if (arr1[k] == arr2[m].categoryId) {
                    arr3.push(arr1[k]);
                }
            }
        }
    }

}

export default {
    type: 'directive',
    name: 'lvCategoryMulti',
    value: lvCategoryMulti
};
