/**
 * Created by yarosh on 05.12.16.
 *
 * @memberof lvOutlet
 * @ngdoc directive
 * @name app.directive:lvOutlet
 * @require $state {service}
 * @require $stateParams {service}
 * @require $filter {service}
 * @require stateGo {service}
 *
 * @scope
 * @restrict A
 *
 * @description
 * Outlet filter (ul li list)
 *
 * <div lv-outlet-multi="ctrl.outlets"></div>
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/outlet-filter-multi.pug';

lvOutletMulti.$inject = [
    '$state',
    '$stateParams',
    '$filter',
    'filtersData',
    'lvMixpanel',
    'domUtils'
];

function lvOutletMulti($state, $stateParams, $filter, filtersData, lvMixpanel, domUtils) {
    return{
        restrict: 'A',
        scope: {
            lvOutletMulti:'='
        },
        controller: lvOutletControllerMulti,
        controllerAs: 'lvOutletCtrlMulti',
        link: linkFn,
        template: template
    };

    function linkFn ($scope) {

        /**
         * @ngdoc function
         * @description
         * send $stateParams to a server, when menu is closed (an array with Ids).
         */

        $scope.$on('$mdMenuClose', () => {
            if ($stateParams.outletsIds !== $scope.previousStateIds) {
                sendParams();
            }
        })


        /**
         * @ngdoc function
         * @description
         * Sent an array with Ids when dropdown list is collapsed.
         */
        function sendParams () {
            $stateParams.receiptId = null;
            const savedStateParams = filtersData.getStateParams();

            $stateParams.serverChartType = savedStateParams.serverChartType;
            $stateParams.page = 0;
            const stateParams = filtersData.setStateParams($stateParams);

            if($state.current.name === 'inventory.history' || $state.current.name === 'goods.itemHistory') {
                lvMixpanel.track('Inventory history', {"Property": "Inventory history/Filter by store"});
            } else {
                lvMixpanel.track('Filter', {"Property": "Reports/Filter by store"});
            }

            domUtils.removeBackdrop(stateParams);
        }
    }
}

lvOutletControllerMulti.$inject = ['$scope', '$stateParams', '$filter'];
function lvOutletControllerMulti($scope, $stateParams, $filter) {

    var self = this;
    var translate = $filter('translate');
    var parsedIds = [];
    var body = document.querySelector('body');
    $scope.arab = !body.classList.contains('aranav') ? "" : "target-right target";

    /* comparing of stateParams data and multi selected filter data before sending to a server, in order to avoid unnecessary trigger of function, if nothing has changed. It helps to avoid reset of the current page number which we don't need to save in order not to drag it into another request, either for the current report or any one else. See a related snippet below in a linked function with the watch cycle */

    if($stateParams.outletsIds && ($stateParams.outletsIds !=='all' && $stateParams.outletsIds !== 'clear')) {
        $scope.previousStateIds = $stateParams.outletsIds.split(',');
        $scope.previousStateIds.forEach( function(val) { parsedIds.push(parseInt(val))} );
        $scope.previousStateIds = parsedIds.sort(function(a, b){return a-b});
        $scope.previousStateIds = ($scope.previousStateIds.length > 1) ? $scope.previousStateIds.join(',') : $scope.previousStateIds;
    } else {
        $scope.previousStateIds =  $stateParams.outletsIds;
    }


    self.getExistingIds = getExistingIds;
    self.selectAll = selectAll;
    self.selectItem = selectItem;
    self.selectOutCheckbox = selectOutCheckbox;
    self.selectAllOutCheckbox = selectAllOutCheckbox;

    $scope.$parent.$parent.multiFilter = true;

    var firstOutlet = [
        {
            outletId: null,
            outletName: translate('MULTISHOP.REPORTS.ALL_STORES')
        }
    ];
    var outletsIdsArray = [];
    var forModulus = 10;
    var originatorEv;

    self.openMenu = function($mdMenu, ev) {
        originatorEv = ev;
        $mdMenu.open(ev);
        var timeView = document.querySelector('#timeView');
        var calendarView = document.querySelector('.calendar-view');
        if(timeView && !timeView.classList.contains('ng-hide')) {
            timeView.classList.add('ng-hide');
        }
        if(calendarView && !calendarView.classList.contains('ng-hide')) {
            calendarView.classList.add('ng-hide');
        }
    };

    $stateParams.outletsIds = ($stateParams.outletsIds) ? $stateParams.outletsIds : null;

    outletsIdsArray = ($stateParams.outletsIds) ? $stateParams.outletsIds.split(',') : outletsIdsArray;

    self.outlets = firstOutlet.concat($scope.$parent.outlets);
    self.outlets._select = false;

    markInputs();
    displayOutletNames();


    /**
     * @ngdoc function
     * @description
     * mark the inputs according to ids which are present in state params since it's important because a browser refreshes after each request to the server
     */
    function markInputs() {
        if ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") {
            self.stateParamsOutletsIds = $stateParams.outletsIds.split(',');
            if (self.stateParamsOutletsIds.length === (self.outlets.length - 1)) {
                self.outlets[0]._select = true;
            }

            for (var i = 0; i < self.outlets.length; i++) {
                var currentOutletId = self.outlets[i].outletId;
                if (self.stateParamsOutletsIds.length === (self.outlets.length - 1)) {
                    self.outlets[0]._select = true;
                }

                for (var l = 0; l < self.stateParamsOutletsIds.length; l++) {
                    if (currentOutletId === parseInt(self.stateParamsOutletsIds[l])) {
                        self.outlets[i]._select = true;
                    }
                }
            }
        } else if ($stateParams.outletsIds == "clear") {
            putAll(false);
        } else if (!$stateParams.outletsIds || $stateParams.outletsIds == "all") {
            putAll(true);
        }
    }


    /**
     * @ngdoc function
     * @description
     * looping through outlets to display the current name in the filter for outlets according to state params ids array length
     */
    function displayOutletNames() {
        if ($stateParams.outletsIds && $stateParams.outletsIds != "clear" && $stateParams.outletsIds != "all") {
            var outletsDeclension;
            var existingOutletIds = [];

            self.getExistingIds(self.stateParamsOutletsIds, self.outlets, existingOutletIds);

            var outletsQuantity = existingOutletIds.length;


            var modulus = existingOutletIds.length % forModulus;
            var allOutlets;
            var outletsDeclensionSingle;
            var outletsDeclensionPlural;
            var outletsDeclensionPlural2;
            if (existingOutletIds.length <= 20) {
                allOutlets = (self.outlets.length === 1) || (outletsQuantity === self.outlets.length - 1);
                outletsDeclensionSingle = (outletsQuantity === 1);
                outletsDeclensionPlural = (outletsQuantity > 1 && outletsQuantity < 5);
                outletsDeclensionPlural2 = (outletsQuantity >= 5 && outletsQuantity <= 20);
            } else {
                allOutlets = (self.outlets.length === 1) || (modulus === self.outlets.length - 1);
                outletsDeclensionSingle = (modulus === 1);
                outletsDeclensionPlural = (modulus > 1 && modulus < 5);
            }


            switch (true) {
                case (allOutlets):
                    self.name = self.outlets[0].outletName;
                    self.outlets[0]._select = true;

                    break;

                case (outletsDeclensionSingle):
                    var singleName;
                    self.outlets.forEach(function (val) {
                        if (val.outletId == $stateParams.outletsIds) {
                            singleName = val.outletName;
                        }
                    });
                    outletsDeclension = translate("MULTISHOP.REPORTS.SINGLE_OUTLET");
                    self.name = singleName || (outletsQuantity + " " + outletsDeclension);

                    break;

                case (outletsDeclensionPlural):
                    outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_1");
                    self.name = outletsQuantity + " " + outletsDeclension;

                    break;

                case (outletsDeclensionPlural2):
                    outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_2");
                    self.name = outletsQuantity + " " + outletsDeclension;

                    break;

                default:
                    outletsDeclension = translate("MULTISHOP.REPORTS.PLURAL_OUTLETS_DECLENSION_2");
                    self.name = outletsQuantity + " " + outletsDeclension;

            }

        } else if ($stateParams.outletsIds == "clear") {
            self.name = translate("MULTISHOP.REPORTS.NONE_STORES");
        } else {
            self.name = self.outlets[0].outletName;
        }
    }

    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of the certain outlet(s) in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectOutCheckbox(outlet) {
        (!outlet._select || outlet._select == "false") ? outlet._select = true : outlet._select = false;
        selectItem(outlet);
    }

    /**
     * @ngdoc function
     * @description
     * tweak checkbox if user clicks out of it and drop selection of all outlets in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectAllOutCheckbox() {
        (!self.outlets[0]._select || self.outlets[0]._select == "false") ? self.outlets[0]._select = true : self.outlets[0]._select = false;
        selectAll();
    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of the certain outlet(s) in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectItem(outlet) {

        if (outlet._select) {
            outletsIdsArray.push(outlet.outletId);

        } else {
            //if parameter _selected is false, then override by a new array which filter method returns with only those merchantIds that don't equal to selected Ids
            outletsIdsArray = outletsIdsArray.filter(function (val) {
                if(val != outlet.outletId) {
                    return val;
                }
            });

            if (self.outlets[0]._select) {
                self.outlets[0]._select = false;
            }
        }


        //after selecting eventual set of ids we need to remove stateParameter "clear" or "all"

        if(outletsIdsArray.length > 1) {
            if(outletsIdsArray[0] == "clear" || outletsIdsArray[0] == "all") {
                outletsIdsArray.shift();
            }
        }

        self.outletsIds = (angular.copy(outletsIdsArray)).sort();

        if(outletsIdsArray.length == 0){
            $stateParams.outletsIds = "clear";
        } else {
            $stateParams.outletsIds = self.outletsIds.join(',');
        }

        //in order to check the input with all outlets if each outlet was checked
        if ($stateParams.outletsIds == "all" || outletsIdsArray.length === self.outlets.length-1) {
            self.outlets[0]._select = true;
            $stateParams.outletsIds  = "all";
        }

    }

    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all outlets in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function selectAll() {
        if (self.outlets[0]._select) {
            putAll(true);

            if(outletsIdsArray.length == self.outlets.length - 1) {
                $stateParams.outletsIds = "all";
            }

        } else if (!self.outlets[0]._select) {
            putAll(false);
            $stateParams.outletsIds = "clear";
        }

    }


    /**
     * @ngdoc function
     * @description
     * If the first element of select was chosen we put a boolean value into object property _select an put object's id into an array or just remove all ids from the array if the boolean vale of the first option is false
     */
    function putAll(param) {
        outletsIdsArray = [];
        var checked;
        var checkedId;

        if(param === false) {
            outletsIdsArray = [];
        }

        for (var i = 0; i < self.outlets.length; i++) {
            self.outlets[i]._select = param;
            checked = param;
            checkedId = self.outlets[i].outletId;
            if(param === true && checkedId) {
                outletsIdsArray.push(parseInt(self.outlets[i].outletId));
            }
        }

    }

    /**
     * @ngdoc function
     * @description
     * looping through two arrays and push into the third one only existing Ids
     */

    function getExistingIds(arr1, arr2, arr3) {
        for(var k = 0; k < arr1.length; k++) {
            for(var m = 0; m < arr2.length; m++) {
                if (arr1[k] == arr2[m].outletId) {
                    arr3.push(arr1[k]);
                }
            }
        }
    }

}

export default {
    type: 'directive',
    name: 'lvOutletMulti',
    value: lvOutletMulti
};
