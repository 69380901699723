/**
 * Created by A.Sirosh on 13.10.2015.
 * @memberof toggleType
 * @ngdoc directive
 * @name toggleType
 * @requires $stateParams {service} UI Router servise
 * @requires $state {service} UI Router servise
 */

toggleType.$inject = ['$stateParams', '$state'];
function toggleType($stateParams, $state) {
	return {
		restrict: 'A',
		/**
		 * @param scope
		 * @param element
		 * @param attrs
		 */
		link: function (scope, element, attrs) {


			$('#' + $stateParams.type).addClass(attrs.toggleType);

			element.on('click', attrs.child, function (e) {


					if ($(this).hasClass(attrs.toggleType) === true) {
						return false;
					}

					element.children(attrs.child).removeClass(attrs.toggleType);

					var stateStr = $state.current.name;

					$(this).addClass(attrs.toggleType);

					$stateParams.type = $(this).attr('id');
					$stateParams.page = 0;

					$state.go(stateStr, $stateParams, {
						reload: true
					});

			});

		}
	};
}

export default {
	type: 'directive',
	name: 'toggleType',
	value: toggleType
};