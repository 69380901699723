/**
 * Created by mars on 9/4/15.
 *
 * английский, русский, испанский, французский, польский, румынский, украинский, корейский
 *
 *
 *

 */

const constantLangCollection = [
    {
        key: "ind",
        value: 'Bahasa Indonesia'
    },
    {
        key: "msa",
        value: "Bahasa Melayu"
    },
    {
        key: "deu",
        value: "Deutsch"
    },
    {
        key: "est",
        value: 'Eesti'
    },
    {
        key: "eng",
        value: "English"
    },
    {
        key: "spa",
        value: "Español"
    },
    {
        key: "fra",
        value: "Français"
    },
    {
        key: "hrv",
        value: "Hrvatski"
    },
    {
        key: "ita",
        value: "Italiano"
    },
    {
        key: "lav",
        value: 'Latviešu'
    },
    {
        key: "hun",
        value: "Magyar"
    },
    {
        key: "nld",
        value: 'Nederlands'
    },
    {
        key: "nor",
        value: "Norsk bokmål"
    },
    {
        key: "pol",
        value: "Polski"
    },
    {
        key: "por",
        value: "Português"
    },
    {
        key: "ron",
        value: "Română"
    },
    {
        key: "fin",
        value: "Suomi"
    },
    {
        key: "vie",
        value: "Tiếng Việt"
    },
    {
        key: "tur",
        value: "Türkçe"
    },
    {
        key: "ell",
        value: 'Ελληνικά'
    },
    {
        key: "bul",
        value: 'Български'
    },
    {
        key: "mkd",
        value: "Македонски"
    },
    {
        key: "rus",
        value: "Русский"
    },
    {
        key: "kat",
        value: "ქართული"
    },
    {
        key: "urd",
        value: "اردو"
    },
    {
        key: "ara",
        value: 'اللغة العربية‎'
    },
    {
        key: "hin",
        value: "हिन्दी"
    },
    {
        key: "ben",
        value: "বাংলা"
    },
    {
        key: "tha",
        value: 'ภาษาไทย'
    },
    {
        key: "lao",
        value: "ພາສາລາວ"
    },
    {
        key: "kor",
        value: "한국어"
    },
    {
        key: "jpn",
        value: "日本語"
    },
    {
        key: "chn",
        value: '中文 (简体)'
    },
    {
        key: "zho",
        value: '中文 (繁體)'
    }
];

export default {
    type: 'constant',
    name: 'constantLangCollection',
    value: constantLangCollection
};