/**
 * Created by galkina 5.05.2022.
 */

trasferItemsImportControl.$inject = [
    'FileUploader',
    '$state',
    '$stateParams',
    'stateGo',
    '$filter',
    'serviceDialog',
    'clientsDB',
    'serviceToast',
    '$translate',
    '$timeout',
    '$profile',
    'siteLangCode',
    'lvMixpanel'
];

function trasferItemsImportControl(
                     FileUploader,
                     $state,
                     $stateParams,
                     stateGo,
                     $filter,
                     serviceDialog,
                     clientsDB,
                     serviceToast,
                     $translate,
                     $timeout,
                     $profile,
                     siteLangCode,
                     lvMixpanel) {

    var model = this;
    model.previousState = stateGo.getPreviousStateAlias();
    var filterTranslate = $filter( 'translate' );
    if(!model.previousState) {
        return $state.go( $stateParams.state, {
            id: $stateParams.id ? $stateParams.id : null
        }, { reload: true } );
    }

    var translate = $filter( 'translate' );
    var sourceOutletId = ($stateParams.sourceOutletId) ? $stateParams.sourceOutletId : null;
    var destinationOutletId = ($stateParams.destinationOutletId) ? $stateParams.destinationOutletId : null;
    var langCode = siteLangCode[$profile.cabinetLang];
    var plugProductions = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];

    model.helpLink = plugProductions.indexOf(langCode) !== -1
        ? "https://help.loyverse.com/" + langCode + "/help/how-work-transfer-orders?utm_source=Back%20Office&utm_medium=Importing%20Transfer%20order"
        : "https://help.loyverse.com/help/how-work-transfer-orders?utm_source=Back%20Office&utm_medium=Importing%20Transfer%20order";

    model.title = translate('INVENTORY.IMPORT.TRANSFER_TITLE');
    model.fillInData = translate('INVENTORY.IMPORT.FILLED_WITH_DATA');
    model.expTemplate = '/data/ownercab/exporttemplatetransferorder';
    var addedItems = [];

    model.willBeCreated = {
        one: 'INVENTORY.IMPORT.WILL_BE_ADDED_ONE',
        first: 'INVENTORY.IMPORT.WILL_BE_ADDED_ONE',
        second: 'INVENTORY.IMPORT.WILL_BE_ADDED_TWO',
        third: 'INVENTORY.IMPORT.WILL_BE_ADDED_FEW'
    };

    model.willBeEdited = {
        one: 'INVENTORY.IMPORT.WILL_BE_EDITED_ONE',
        first: 'INVENTORY.IMPORT.WILL_BE_EDITED_ONE',
        second: 'INVENTORY.IMPORT.WILL_BE_EDITED_TWO',
        third: 'INVENTORY.IMPORT.WILL_BE_EDITED_FEW'
    };



    var includeTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/download-start.html',
        onAttach: 'dashboard-page/templates/import-entity/include/on-attach.html',
        onError: 'dashboard-page/templates/import-entity/include/download-error.html',
        formatError: 'dashboard-page/templates/import-entity/include/format-error.html',
        onLoading: 'dashboard-page/templates/import-entity/include/preloader.html',
        onParserSuccess: 'dashboard-page/templates/inventory/transfer/include/parser-success.html', //не переносить в отдельную фабрику
        onParserError: 'dashboard-page/templates/inventory/transfer/include/parser-error.html', // не переносить в отдельную фабрику
        badEncoding: 'dashboard-page/templates/import-entity/include/bad-encoding.html',
        onConfirmLoading: 'dashboard-page/templates/import-entity/include/confirm-preloader.html'
    };

    var includeHeadTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/head-start.html',
        onSuccess: 'dashboard-page/templates/import-entity/include/head-success.html',
        onParserError: 'dashboard-page/templates/import-entity/include/head-parser-error.html'
    };

    var primaryAction = {
        onStart: function () {
            model.response = null;
            model.includeTemplate = includeTemplate.onStart;
            model.includeHeadTemplate = includeHeadTemplate.onStart;
            model.primaryButtonText = 'DOWNLOAD';
            model.primaryAction = primaryAction.onStart;
            model.onCancel = cancelAction.onStart;
            model.primaryButtonDisabled = true;
            model.templateWidth = null;
        },
        onAttach: function () {
            if ( !checkFileNameValid() ) {
                uploader.removeFromQueue( 0 );
                model.includeTemplate = includeTemplate.formatError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
            } else if ( !checkFileSize() ) {
                uploader.removeFromQueue( 0 );
                model.templateWidth = null;
                model.includeTemplate = includeTemplate.onError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
                model.errorText = {
                    code: "INVENTORY.IMPORT.LARGER_THAN_FIVE_MB"
                };
            } else {
                model.includeTemplate = includeTemplate.onLoading;
                uploader.uploadAll();
                model.primaryButtonDisabled = true;
            }

        },
        onUpdateGoods: function () {
            model.includeTemplate = includeTemplate.onConfirmLoading;
            model.primaryButtonDisabled = true;

            $state.go( model.previousState, {
                id: $stateParams.id ? $stateParams.id : null,
                sourceOutletId: sourceOutletId,
                destinationOutletId: destinationOutletId,
                transferDocData: ($stateParams.transferDocData) ? $stateParams.transferDocData : {},
                newItems: addedItems
            } );
        }
    };

    var cancelAction = {
        onStart: function () {
            $state.go( model.previousState, {
                id: $stateParams.id ? $stateParams.id : null,
                sourceOutletId: sourceOutletId,
                destinationOutletId: destinationOutletId,
                transferDocData: ($stateParams.transferDocData) ? $stateParams.transferDocData : {}
            } );
        }
    };

    primaryAction.onStart();

    var uploader = this.uploader = new FileUploader( {
        url: '/data/ownercab/validateTransferOrderFile',
        sourceOutletId: sourceOutletId,
        destinationOutletId: destinationOutletId,
        queueLimit: 1
    } );

    uploader.onAfterAddingFile = function ( fileItem ) {
        var addedFile = fileItem._file;
        model.includeTemplate = includeTemplate.onAttach;
        model.primaryButtonDisabled = false;
        model.primaryAction = primaryAction.onAttach;
        model.onAttachFileName = addedFile.name;
        model.fileSize = addedFile.size;
        model.sourceOutletId = sourceOutletId;
        model.destinationOutletId = destinationOutletId;

    };
    uploader.onAfterAddingAll = function ( addedFileItems ) {
        if ( addedFileItems.length ) {
            model.primaryButtonDisabled = false;
            model.primaryButtonText = 'DOWNLOAD'
        }

    };
    uploader.onBeforeUploadItem = function ( item ) {
        uploader.formData = [{sourceOutletId: sourceOutletId},{destinationOutletId: destinationOutletId}];
        Array.prototype.push.apply(item.formData, uploader.formData);
    };

    uploader.onSuccessItem = function ( fileItem, response ) {

        var res = null;
        try {
            res = JSON.parse( response );
        } catch ( err ) {
            console.error( err );
        }
        if ( !res ) {
            return;
        }

        errorImportClientsForMixpanel(res);

        if ( res.result === 'error' ) {
            wrongFileFormat();
        } else if (res.error) {
            switch ( res.error ) {
                case "wrong_header":
                    errorIncludeData("INVENTORY.IMPORT.INVALID_COLUMN_FORMAT");
                    break;
                case "limit_5mb":
                    errorIncludeData("INVENTORY.IMPORT.LARGER_THAN_FIVE_MB");
                    break;
                case "limit_10000":
                    errorIncludeData("INVENTORY.IMPORT.MORE_THAN_10000");
                    break;
                case "empty_file":
                    errorIncludeData("INVENTORY.IMPORT.NO_ITEMS_DATA");
                    break;
                case "not_csv_file":
                    errorIncludeData("PLEASE_ADD_CSV");
                    break;
                default:
                    wrongFileFormat();
            }
        } else if (!res.canProcess) {
            downloadError( res );
        } else {
            downloadSuccess( res );
        }


        model.primaryButtonDisabled = false;

    };

    uploader.onErrorItem = function ( fileItem, response, status, headers ) {

        // var res = JSON.parse( response );

        uploader.removeFromQueue(0);
        // console.log("response= "+response);

        if (response.indexOf("Error occured while trying to proxy") > -1 || response==="") {
            model.response = null;
            model.includeTemplate = includeTemplate.onStart;
            model.includeHeadTemplate = includeHeadTemplate.onStart;
            model.primaryButtonText = 'DOWNLOAD';
            model.primaryAction = primaryAction.onStart;
            model.onCancel = cancelAction.onStart;
            model.primaryButtonDisabled = true;
            model.templateWidth = null;

            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-error-network-connection.html',
                title: filterTranslate('ERROR_NET_CONNECTION'), //"Ошибка получения данных",
                content: filterTranslate('CHECK_CONNECTION_AND_REFRESH'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else {
            model.includeTemplate = includeTemplate.onError;
            model.primaryButtonText = 'REPEAT_LOADING';
            model.primaryAction = primaryAction.onStart;
            model.primaryButtonDisabled = false;
        }
        //console.info( 'onErrorItem', fileItem, response, status, headers );
    };


    //console.info( 'uploader', uploader );

    function errorIncludeData(errorText) {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.onCancel = cancelAction.onStart;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: errorText
        };

    }


    function downloadSuccess( res ) {

        model.includeTemplate = includeTemplate.onParserSuccess;
        model.includeHeadTemplate = includeHeadTemplate.onSuccess;
        model.confirmText = { code: 'INVENTORY.IMPORT.CONFIRM_CHANGES_TRANSFER'};
        model.added = 0;
        model.edited = 0;

        if($stateParams.transferDocData.items.length > 0) {
            var itemsInExistingDocument = $stateParams.transferDocData.items.map(val => {
                return val.wareId;
            });

            var i = 0;
            while ( i < res.items.length ) {
                (function (i) {
                    if (itemsInExistingDocument.indexOf(res.items[i].id) > -1) {
                        model.edited ++
                    }
                })(i);
                i++;
            }
        }

        model.added = res.items.length - model.edited;
        model.response = res;
        model.primaryButtonText = 'IMPORT_ENTITY.REFRESH_DATA';
        model.onCancel = cancelAction.onStart;
        model.templateWidth = 'max-width-710';
        model.primaryAction = primaryAction.onUpdateGoods;

        addedItems = res.items;

        successImporttransferOrderForMixpanel(res);
    }

    function wrongFileFormat() {
        serviceDialog.add( {
            templateUrl: 'dashboard-page/templates/import-entity/include/dialog-list-imported-data-update-error.html',
            mainText: "IMPORT_FAILED_TEXT_5",
            buttons: [
                {
                    class: 'primary',
                    text: 'ON_OK',
                    action: function () {
                        $state.go( $state.current.name, { sourceOutletId: sourceOutletId,
                                destinationOutletId: destinationOutletId,
                            transferDocData: $stateParams.transferDocData}, {reload: true} );
                    }
                }
            ]
        } );
    }

    function downloadError( res ) {

        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onParserError;
        model.includeHeadTemplate = includeHeadTemplate.onParserError;
        model.templateWidth = 'max-width-900';
        var i = 0;
        while ( i < res.errorsList.length ) {
            (function ( _i ) {
                $translate( (res.errorsList[_i].columnName)?(res.errorsList[_i].columnName):(res.errorsList[_i].columnError) ? ('ERROR.' + res.errorsList[_i].columnError) : false)
                    .then( function ( val ) {
                        if ( /^\s$/.test( val ) ) {
                            res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} );
                        } else {
                            for(var key in res.errorsList[_i]) {
                                if ( res.errorsList[_i].hasOwnProperty( key ) ) {
                                    if( key && res.errorsList[_i][key] != null) {
                                        res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + val + '"'} );
                                    }
                                }
                            }
                        }
                    }, function ( err ) {
                        res.errorsList[_i]._error = translate( 'ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
                    } );
            })( i );
            i++
        }

        i = null;
        model.response = res;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
    }

    function checkFileSize() {
        if ( 5 * 1000 * 1000 <= model.fileSize ) {
            return false
        }
        return true;
    }


    function checkFileNameValid() {
        if ( /\.csv$/.test( model.onAttachFileName ) ) {
            return true
        }
        return false
    }


//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ
    function errorImportClientsForMixpanel(resError) {
        if ((resError.result == "ok" && resError.error != null) || (resError.result == "ok" && resError.error == null && resError.errorsList.length > 0)) {

            lvMixpanel.track('Import', {  "Event type"        : "Import transfer order",
                                        "Event result"     : "Transfer order import error",
                                        "Number of errors transfer order"  : resError.errorsList.length});
        }
    }

    function successImporttransferOrderForMixpanel(resSuccess) {
        if (resSuccess.result == "ok" && resSuccess.error == null && resSuccess.errorsList.length == 0) {
            // console.log("successImportClientsForMixpanel, resSuccess = ", resSuccess);
            lvMixpanel.track('Import', {  "Event type"            : "Import transfer order",
                                        "Event result"         : "Transfer order imported",
                                        "Number of items transfer order"   : resSuccess.items.length});
        }
    }

    model.importHelpForMixpanel = function() {
        lvMixpanel.track('Help', {'Property': 'Import transfer order'});
    }
//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ



}

export default {
    type: 'controller',
    name: 'trasferItemsImportControl',
    value: trasferItemsImportControl
};
