/**
 * Created by Shestakov on 29.04.21
 *
 * @ngdoc directive
 * @name app.directive:messageBottom
 * @restrict E
 *
 * @description
 * Directive for bottom message under content
 *
 * @example
 * <message-bottom></message-bottom>
 */

import template from '../templates-pug/for-directive/message-bottom.pug';

messageBottom.$inject = [];
function messageBottom() {
    return {
        restrict: 'E',
        template: template,
        scope: {
            message: "@"
        }
    };
}

export default {
    type: 'directive',
    name: 'messageBottom',
    value: messageBottom
};