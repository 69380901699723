/**
 * Created by Oleksii Nikitin on 15/03/2023.
 */

permissions.$inject = ['factoryProfile', 'getPermits', 'serviceMenu', 'getBillingData', 'Observable', 'permissionObserver'];
function permissions(factoryProfile, getPermits, serviceMenu, getBillingData, Observable, permissionObserver) {
    let self = this;

    this.profileUpdate = new Observable();
    this.billingUpdate = new Observable();
    this.serviceMenuUpdate = new Observable();
    this.accountUpdate = new Observable()

    permissionObserver.updateRequired.observe(() => {
        updatePermissions(true);
    });

    this.updatePermissions = updatePermissions;

    this.updateAccountInfo = updateAccountInfo;

    this.enableIntegrations = enableIntegrations;

    this.setProfile = setProfile;

    function updatePermissions(reloadProfile) {
        let permits;
        return getPermits.getPermitsList({})
            .then(permissions => {
                permits = permissions;
                serviceMenu.reset();
                serviceMenu.setAccess(permits);
                serviceMenu.showLowStockNotification = permits.includes("ACCESS_WARES");
                return factoryProfile.getProfile(reloadProfile);
            })
            .then(profile => {
                setProfile(profile, permits);
            });
    }

    function setProfile(profile, permits) {

        if(profile.timeCardEnabled || profile.inventoryEnabled) {
            if(profile.timeCardEnabled){
                permitsForTimeTracking(permits, profile.billingInfo.billingStatus);
            }
            if(profile.inventoryEnabled){
                permitsForInventory(permits);
            }
        }

        if(!profile.inventoryEnabled){
            for (let key in serviceMenu.constantMenu){
                if (serviceMenu.constantMenu[key].name === "inventory"){
                    serviceMenu.constantMenu[key].name = "inventory.management";
                    serviceMenu.constantMenu[key].uiSref = "inventory.management";
                    serviceMenu.constantMenu[key].children = [];
                }
            }
        }

        if(!profile.integrationEnabled) {
            for (let key in serviceMenu.constantMenu){
                if(serviceMenu.constantMenu[key].name === "integrations"){
                    serviceMenu.constantMenu[key].name = "integrations.management";
                    serviceMenu.constantMenu[key].uiSref = "integrations.management";
                    serviceMenu.constantMenu[key].children = [];
                }
            }
        }

        getBillingData.setAccessStatus(profile.billingInfo);

        serviceMenu.setGrayWhenBlock(profile);
        serviceMenu.setVisible();
        serviceMenu.showCashBox(profile.shiftsEnabled);
        serviceMenu.showOpenReceipts(profile);
        serviceMenu.showKitchenPrinyer(profile);
        serviceMenu.showDiningOptins(profile);
        serviceMenu.helpCenterLang(profile);
        serviceMenu.isSupportAvailable(profile);

        self.billingUpdate.notify(getBillingData.getAccessStatus());
        self.profileUpdate.notify(profile);
        self.serviceMenuUpdate.notify();
    }

    function permitsForTimeTracking(permits, billingStatus) {
        permits.forEach(permit => {
            if(permit === "ACCESS_MERCHANTS"){
                for (let key in serviceMenu.constantMenu){
                    if(serviceMenu.constantMenu[key].name === "employees"){
                        let timeReports = serviceMenu.constantMenu[key].children;
                        timeReports.forEach(function(childObj){
                            for(let childProperty in childObj){
                                if(	(childProperty === "name" && billingStatus !== "BLOCKED" && billingStatus !== "HOLD")
                                    && (childObj[childProperty] === "employees.timecards" || childObj[childProperty] === "employees.timeclockreport"))
                                {
                                    childObj.hide = false;
                                }
                            }
                        });
                    }
                }
            }
        })
    }

    function permitsForInventory(permits) {
        permits.forEach(permit => {
            if(permit === "ACCESS_INVENTORY"){
                for (let key in serviceMenu.constantMenu){
                    if(serviceMenu.constantMenu[key].name === "inventory"){
                        let invOptions = serviceMenu.constantMenu[key].children;
                        invOptions.forEach(function(option){
                            option.hide = false;
                        });
                    }
                }
            }
        })
    }

    function updateAccountInfo() {
        self.accountUpdate.notify();
    }

    function enableIntegrations() {
        for (let key in serviceMenu.constantMenu){
            if(serviceMenu.constantMenu[key].name === "integrations.management"){
                serviceMenu.constantMenu[key].name = "integrations";
                serviceMenu.constantMenu[key].uiSref = "";
                serviceMenu.constantMenu[key].children = serviceMenu.integrationsMenuAfterStarted;
            }
        }
    }
}

export default {
    type: 'service',
    name: 'permissions',
    value: permissions
};
