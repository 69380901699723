/**
 * Created by  Alex on 31.05.17.
 */

import angular from 'angular';
import template from '../../../../templates-pug/settings/billing/components/billing-subcriptions-comp.pug';

accountLicencesInfoCtrl.$inject = [
    '$filter',
    'serviceDialog',
    'factoryBilling',
    'serviceToast',
    '$state',
    '$stateParams',
    'lodash',
    'serviceBillingMixpanel',
    'serviceBillingError',
    'serviceBillingCommonMethods',
    'serviceBillingDifferentPlaces',
    'serviceStrings',
    '$rootScope',
    'strGwConst',
    'declensionsConst',
    'constBillingSubscriptions',
    'lvMixpanel',
    '$scope'
];

function accountLicencesInfoCtrl(
    $filter,
    serviceDialog,
    factoryBilling,
    serviceToast,
    $state,
    $stateParams,
    lodash,
    serviceBillingMixpanel,
    serviceBillingError,
    serviceBillingCommonMethods,
    serviceBillingDifferentPlaces,
    serviceStrings,
    $rootScope,
    strGwConst,
    declensionsConst,
    constBillingSubscriptions,
    lvMixpanel,
    $scope
) {

    // блок инициализации данных
    let ctrl                = this;
    let translate           = $filter('translate');
    let typeBillingPayment;
    let tempPlan;
    let tempCoupon;
    let tempPaymentType;
    let couponCode;
    let stripeGateways = strGwConst;
    let ownerId;
    let currencyFormatOnlyBilling = $filter('currencyFormatOnlyBilling');
    let formatDateByBrowser = $filter('formatDateByBrowser');

    ctrl.subscriptionsInfo = {};

    this.$onInit = function(){
        ownerId = ctrl.ownerId;
        updateProfileBilling();
        updateAccountInfo();
    }

    this.$onChanges = function(changes) {
        if(changes.accountInfo) {
            updateAccountInfo();
        }
        if(changes.profileBillInfo) {
            updateProfileBilling();
        }
    }

    function updateAccountInfo() {
        const existingSubscriptions = Object.keys(ctrl.accountInfo.subscriptions);
        const subscriptions = serviceBillingDifferentPlaces.getSubscriptionConstants(existingSubscriptions);

        // data initialization in one object
        ctrl.subscriptionsInfo = {};

        subscriptions.forEach(function (subscription) {

            // If it's one of free subscriptions, that we need to hide, don't add it to subscriptionsInfo
            const hiddenSubscriptions = ['SALESHISTORY', 'INTEGRATION'];
            const status = ctrl.accountInfo.subscriptions[subscription].status;
            const isFree = status === "FREE";
            if (isFree && hiddenSubscriptions.includes(subscription)) {
                return;
            }

            const subscrObj = {};
            const textConst = subscription === 'EMPSTORE' ? 'EMPLOYEE' : subscription;
            subscrObj.status = status;
            subscrObj.plan = angular.copy(ctrl.accountInfo.subscriptions[subscription].plan);
            subscrObj.isNonRenewing = subscrObj.status === 'ACTIVE' && ctrl.accountInfo.subscriptions[subscription].endsOn !== null;
            subscrObj.count = ctrl.accountInfo.subscriptions[subscription].unitQty;
            subscrObj.canChangePlan = ctrl.accountInfo.subscriptions[subscription].canChangePlan;
            subscrObj.blockRetryBtn = false;
            subscrObj.isResumable = ctrl.accountInfo.subscriptions[subscription].resumable;
            subscrObj.coupon = new Coupon(false, "", "", "", false, null);
            subscrObj.declensions = (subscrObj.plan.id && !subscrObj.plan.unlim) || subscrObj.isNonRenewing ? getDeclensionsForEmployeesAndStores(subscrObj.count, subscription) : null;
            subscrObj.isFree = isFree;
            subscrObj.unitPrice = ctrl.accountInfo.subscriptions[subscription].unitPrice;
            subscrObj.amount = ctrl.accountInfo.subscriptions[subscription].amount;

            // set subscription icon
            switch (subscription) {
                case 'EMPLOYEE':
                case 'EMPSTORE':
                    subscrObj.iconPath = require('../../../../../img/ic_employees_24px.svg');
                    break;
                case 'INVENTORY':
                    subscrObj.iconPath = require('../../../../../img/inventory-black54.svg');
                    break;
                case 'INTEGRATION':
                    subscrObj.iconPath = require('../../../../../img/ic_extension_nocolor.svg');
                    break;
                case 'SALESHISTORY':
                    subscrObj.iconPath = require('../../../../../img/ic_unlimited_sales_history.svg');
                    break;
                default:
                    subscrObj.iconPath = '';
            }

            // set texts
            subscrObj.texts = {};
            subscrObj.texts.name = 'ACCOUNT.B2_SUBSCRIPTION_NAME_' + textConst;
            subscrObj.texts.subscribeText = 'ACCOUNT.B2_' + textConst + '_SUBSCRIBE_TEXT';
            subscrObj.texts.unlimitedText = 'ACCOUNT.B2_' + textConst + '_UNLIMITED';
            subscrObj.texts.perUnit = subscription === 'EMPLOYEE' ? 'ACCOUNT.PER_EMPLOYEE' : 'ACCOUNT.B2_PER_STORE';

            // set conditions for displaying texts in the first block
            const planExists = subscrObj.plan.id !== null && !subscrObj.isNonRenewing;
            subscrObj.conditions = {};
            subscrObj.conditions.showSubscribeText = subscrObj.isFree || (!planExists && !subscrObj.isNonRenewing);
            subscrObj.conditions.showUnlimitedText = planExists && subscrObj.plan.unlim;
            subscrObj.conditions.showCount = ((planExists && !subscrObj.plan.unlim) || subscrObj.isNonRenewing) && subscrObj.declensions;
            subscrObj.conditions.showPricePerUnit = ((planExists && !subscrObj.plan.unlim) || subscrObj.isNonRenewing) && ctrl.accountInfo.subscriptions[subscription].unitPrice !== null;

            // set conditions for displaying trial / billing cycle data
            subscrObj.conditions.showPriceForAllUnits = (subscrObj.status === 'INTRIAL_SUB_ACT' || subscrObj.status === 'ACTIVE' || subscrObj.status === 'PAST_DUE' || subscrObj.status === 'PAUSED') && planExists;
            subscrObj.conditions.showBeforeTrialInfo = subscrObj.status === 'NONE';
            subscrObj.conditions.showNextBillingDate = (subscrObj.status === 'INTRIAL_SUB_ACT' || subscrObj.status === 'ACTIVE') && planExists && ctrl.accountInfo.subscriptions[subscription].endsOn === null;
            subscrObj.conditions.showExpirationDate = subscrObj.isNonRenewing || (subscrObj.status === 'UNRESOLVED' && ctrl.accountInfo.subscriptions[subscription].endsOn !== null);
            subscrObj.conditions.showTrialEndInfo = subscrObj.status === 'INTRIAL_SUB_NOACT' || subscrObj.status === 'INTRIAL_NOSUB_NOACT';
            subscrObj.conditions.showPaymentFailedBlockInfo = (subscrObj.status === 'PAST_DUE' || subscrObj.status === 'PAUSED') && planExists && !subscrObj.isResumable;
            subscrObj.conditions.showTrialEndedBlockInfo = subscrObj.status === 'TRIALEND_SUB_NOACT';

            // `Change plan` button display
            subscrObj.conditions.showChangePlan = planExists && !(ctrl.accountInfo.subscriptions[subscription].cycleLock || subscrObj.conditions.showExpirationDate);

            // set conditions for displaying coupons data
            subscrObj.conditions.showCouponsInfo = ctrl.accountInfo.subscriptions[subscription].coupons && ctrl.accountInfo.subscriptions[subscription].coupons.length > 0;
            subscrObj.conditions.showFirstCouponName = subscrObj.conditions.showCouponsInfo && ctrl.accountInfo.subscriptions[subscription].coupons[0].name.length > 0;
            subscrObj.conditions.showSecondCoupon = subscrObj.conditions.showCouponsInfo && ctrl.accountInfo.subscriptions[subscription].coupons.length > 1;
            subscrObj.conditions.showSecondCouponName = subscrObj.conditions.showSecondCoupon && ctrl.accountInfo.subscriptions[subscription].coupons[1].name.length > 0;

            // set conditions for displaying buttons
            const statusesForUnsubscribe = ['INTRIAL_SUB_NOACT', 'INTRIAL_SUB_ACT', 'ACTIVE', 'TRIALEND_SUB_NOACT', 'PAST_DUE', 'PAUSED', 'UNRESOLVED'];
            if (subscription === "EMPLOYEE" || subscription === "EMPSTORE") {
                subscrObj.conditions.showSubscribeButtonForTrial = subscrObj.status === 'NONE' || subscrObj.status === 'INTRIAL_NOSUB_NOACT' || subscrObj.status === 'TRIALEND_NOSUB_NOACT';
                subscrObj.conditions.showSubscribeButtonForTrialEnd = false;
            } else {
                subscrObj.conditions.showSubscribeButtonForTrial = subscrObj.status === 'NONE' || subscrObj.status === 'INTRIAL_NOSUB_NOACT';
                subscrObj.conditions.showSubscribeButtonForTrialEnd = subscrObj.status === 'TRIALEND_NOSUB_NOACT';
            }
            subscrObj.conditions.showActivateButton = subscrObj.status === 'INTRIAL_SUB_NOACT' || subscrObj.status === 'TRIALEND_SUB_NOACT';
            subscrObj.conditions.showRetryPaymentButton = subscrObj.status === 'PAST_DUE';
            subscrObj.conditions.showReactivateButtonPaused = subscrObj.status === 'PAUSED';
            subscrObj.conditions.showReactivateButtonNonRenewing = subscrObj.isNonRenewing;
            subscrObj.conditions.showUnsubscribeButton = statusesForUnsubscribe.includes(subscrObj.status) && !(subscription === 'SALESHISTORY' && subscrObj.isNonRenewing);
            subscrObj.conditions.showSelectPlanButton = subscrObj.status === 'UNRESOLVED';

            // set methods for different subscriptions types
            subscrObj.methods = {};
            if (subscription === "EMPLOYEE" || subscription === "EMPSTORE") {
                subscrObj.methods.subscribeInTrial = function () {
                    subscribeEmployeesNoEmployee();
                };
            } else {
                subscrObj.methods.subscribeInTrial = function () {
                    subscribeInventoryOrIntegrations(subscription, subscrObj.status);
                };
            }

            ctrl.subscriptionsInfo[subscription] = subscrObj;
        })
        // data initialization in one object
    }

    function updateProfileBilling() {
        typeBillingPayment  = ctrl.profileBillInfo.gw;
        ctrl.isOnHold = ctrl.profileBillInfo.billingStatus === "HOLD";
    }

    // диалоговое окно СОТРУДНИКИ, при <старте> или <продолжить> в ТРИАЛЕ или <подписаться после трала> =>=> будет окно о добавлении сотрудника
    function subscribeEmployeesNoEmployee () {
        // fbq('track', 'Employee management subscribe');
        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
            class:          'billing-base-384',
            content1:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ADD_EMPLOYEE_TO_SUBSCRIBE'),
            buttons:   [{   text:  'ON_CANCEL',
                action: function () {}
            },
                {   text:  'EMPLOYEE.ADD_EMPLOYEE',
                    class: 'md-primary',
                    action: function () {
                        $state.go('employees.list', {});
                    }
                }]
        });
    }
    // диалоговое окно СОТРУДНИКИ, при <старте> или <продолжить> в ТРИАЛЕ или <подписаться после трала> =>=> будет окно о добавлении сотрудника




    // диалоговое окно СКЛАДСКОЙ УЧЕТ или ИНТЕГРАЦИИ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки
    function subscribeInventoryOrIntegrations (type, status) {
        let msgTitle, msgContent1, msgContent2, msgContent3, msgDate;

        // показ чекбокса согласия с условиями, только для интеграций до триала
        let terms = {};
        terms.show = type === "INTEGRATION" && status === "NONE";
        terms.url = serviceBillingDifferentPlaces.getTermsUrl();
        terms.checked = !terms.show;

        if (status === "NONE") {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_' + type + '_TRIAL_FREE_TITLE');
            msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_' + type + '_TRIAL_FREE_TEXT_1');
            msgContent2  =  null;
            msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_' + type + '_TRIAL_FREE_TEXT_2');
            msgDate      =  null;
        }
        else if (status === "INTRIAL_NOSUB_NOACT") {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_' + type + '_TITLE');
            msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_' + type + '_TEXT_1');
            msgContent2  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_' + type + '_TEXT_2');
            msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CONTINUE_' + type + '_TEXT_3');
            msgDate      =  ctrl.accountInfo.subscriptions[type].trialEnd;
        }

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       msgContent2,
            content3:       msgContent3,
            date:           msgDate,
            priceOne:       lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 1, 'unlim': false }),
            terms:          terms,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                            "cancel", false, type, ctrl.subscriptionsInfo[type].status, false,
                            null, undefined, null, 'Billing page');
                    }
                },
                {
                    text: status === "NONE" ? 'START_FREE_TRIAL' : 'ACCOUNT.CONTINUE',
                    class: 'md-primary',
                    action: function () {
                        ctrl.accountInfo.subscriptions[type].plan.id = type;
                        ctrl.createSubscribeActivate(ctrl.accountInfo.subscriptions[type].plan, type, status, false, null, false);
                    }
                }
            ]
        });
    }
    // диалоговое окно СКЛАДСКОЙ УЧЕТ или ИНТЕГРАЦИИ, при <старте> или <продолжить> в ТРИАЛЕ =>=> будет окно запуска или продолжения подписки



    // диалоговое окно СОТРУДНИКИ, СКЛАДСКОЙ УЧЕТ или ИНТЕГРАЦИИ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация
    function subscribeActivate (type, status) {
        let msgTitle, msgButtonText, msgSubscribeText, coupon, textConst, payPerConst, place;

        tempPlan        = angular.copy(ctrl.accountInfo.subscriptions[type].plan);
        tempCoupon = angular.copy(ctrl.subscriptionsInfo[type].coupon);
        tempPaymentType = ctrl.accountInfo.paymentMethod;
        coupon = ctrl.subscriptionsInfo[type].coupon;
        textConst = type === "EMPSTORE" ? "EMPLOYEE" : type;
        msgSubscribeText = translate('ACCOUNT.B2_' + textConst + '_SUBSCRIBE_TEXT');
        payPerConst = type === "EMPLOYEE" ? "EMPLOYEE" : "STORES";

        // activation in trial, or when account is blocked after trial, or wnen account is blocked after the end of non-renewing subscription
        if (status === "INTRIAL_SUB_NOACT" || status === "TRIALEND_SUB_NOACT" || status === "UNRESOLVED") {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + textConst + '_ACTIVATE');
            msgButtonText =  'ACCOUNT.B2_ACTIVATE';
            place = "Billing page";
        }
        else if (status === "TRIALEND_NOSUB_NOACT" || ctrl.subscriptionsInfo[type].isNonRenewing) {
            msgTitle = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_' + textConst + '_TITLE');
            msgButtonText =  'SUBSCRIBE';
            place = "Billing page";
        }



        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
            title:          msgTitle,
            content1:       msgSubscribeText,
            content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
            content3:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER'),
            content4:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_MONTH'),
            content5:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_YEAR'),
            content6:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_UNLIMITED'),
            content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
            content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
            single1:        lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 1, 'unlim': false }),
            single12:       lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 12, 'unlim': false }),
            unlim1:         lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 1, 'unlim': true }),
            unlim12:        lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 12, 'unlim': true }),
            newPlan:        ctrl.accountInfo.subscriptions[type].plan,
            paymentType:    tempPaymentType,
            inEU:           ctrl.accountInfo.taxMessage,
            couponDate:     coupon,
            showFeeText:    type === 'INTEGRATION',
            buttons: [  {   text:  'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                        "cancel", false, type, ctrl.subscriptionsInfo[type].status,
                        false, tempPaymentType, place, ctrl.accountInfo.subscriptions[type].plan.cycle, 'Billing page');
                    cancelSubscribeDialog(type, tempPlan, tempCoupon);
                }
            },
                {   text:   (tempPaymentType === null)? 'ACCOUNT.CONTINUE' : msgButtonText,
                    action: function () {
                        (ctrl.subscriptionsInfo[type].coupon.haveCoupon === true && ctrl.subscriptionsInfo[type].coupon.code !== null)? couponCode = ctrl.subscriptionsInfo[type].coupon.code : couponCode = null;

                        ctrl.accountInfo.subscriptions[type].plan = lodash.find(ctrl.accountInfo.plans[type], { 'id': ctrl.accountInfo.subscriptions[type].plan.id });

                        if (tempPaymentType === null) {
                            const stateParams = {
                                addSubscribe: ctrl.accountInfo.subscriptions[type].plan,
                                typeSubscriptions: type,
                                activate: true,
                                coupon: couponCode,
                                source: 'Billing page'
                            };

                            if(typeBillingPayment === "bt")  { $state.go( 'addPayMethodBt',  stateParams) }
                            if(stripeGateways.indexOf(typeBillingPayment) !== -1) { $state.go( 'addPayMethodStr', stateParams) }
                            if(typeBillingPayment === "bs" || typeBillingPayment === "wc")  {
                                serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, ctrl.accountInfo.subscriptions[type].plan.id, couponCode, cancelSubscribeDialog.bind(null, type, tempPlan, tempCoupon), setOldPlan.bind(null, type, tempPlan), null);
                            }
                        } else {
                            showEstimatedInvoiceForActivate(ctrl.accountInfo.subscriptions[type].plan, type, status, couponCode, tempPaymentType, tempPlan, tempCoupon);
                        }
                    }
                },
                {   text:           'APPLY_APPLY',
                    closeOnAction:  false,
                    action:         function () {
                        addCoupon(ctrl.accountInfo.subscriptions[type].plan, ctrl.subscriptionsInfo[type].coupon, ctrl.subscriptionsInfo[type].coupon.name);
                    }
                }
            ]
        });
    }
    // диалоговое окно СОТРУДНИКИ, СКЛАДСКОЙ УЧЕТ или ИНТЕГРАЦИИ, при <активир в триале> или <активир в триале при блокировке аккаунта> или <подписаться после трала> =>=> будет окно выбора тарифа и дальнейшая активация




    // отправка на сервер создание подписки на услугу и/или активация ЛЮБОЙ услуги (перезагружаем всегда страницу)
    ctrl.createSubscribeActivate = function (plan, type, status, activate, couponCode, paymentType) {
        let data = {planId: plan.id,
            activate: activate,
            coupon: couponCode,
            estimate: false};

            
        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {

            serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                res.result, plan, type, status, activate, paymentType, undefined, undefined, 'Billing page');

            if (res.result === "ok") {
                if (status === "NONE") {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_TRIAL_STARTED', 'success');
                } else if (status === "INTRIAL_NOSUB_NOACT") {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_TRIAL_RESUMED', 'success');
                } else {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                }
                serviceBillingError.reloadRoute();
            }
            else if (res.result === "error") {

                setOldPlan(type, tempPlan);

                if (res.message === "must_add_employees") {
                    serviceDialog.add({
                        templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        class:          'billing-base-384',
                        content1:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ADD_EMPLOYEE_TO_SUBSCRIBE'),
                        buttons:    [ { text:   'ON_CANCEL'},
                            { class:  'primary',
                                text:   'EMPLOYEE.ADD_EMPLOYEE',
                                action: function () {
                                    $state.go('employees.list', { page: 0, limit: 10}, { reload: true });
                                }
                            }]
                    });
                }
                else {
                    serviceBillingError.knownError(res.message);
                }
            }
            else {
                setOldPlan(type, tempPlan);
                serviceBillingError.unKnownError();
            }
        });
    };
    // отправка на сервер создание подписки на услугу и/или активация ЛЮБОЙ услуги (перезагружаем всегда страницу)



    // estimated invoice when subscription is activated
    function showEstimatedInvoiceForActivate(plan, type, status, couponCode, paymentType, tempPlan, tempCoupon) {
        let data = {planId: plan.id,
            activate: true,
            coupon: couponCode,
            estimate: true};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            if (res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, ctrl.createSubscribeActivate.bind(null, plan, type, status, true, couponCode, paymentType), cancelEstimatedInvoice.bind(null, type, status, tempPlan, tempCoupon));
            } else if (res.result === "error") {
                setOldPlan(type, tempPlan);
                serviceBillingError.knownError(res.message);
                lvMixpanel.track("Billing", {Value: "Fail"});
            } else {
                setOldPlan(type, tempPlan);
                serviceBillingError.unKnownError();
                lvMixpanel.track("Billing", {Value: "Fail"});
            }
        });
    }
    // estimated invoice when subscription is activated



    // estimated invoice when plan changes
    function showEstimatedInvoiceForPlanChange(data, type, planCycle) {
        factoryBilling.changeNewPlanSubscription(data).then(function (res) {
            if (res.result === "ok") {
                const changePlanData = new SubscriptionEditData(data.planId, data.subscriptionId, data.coupon, false);
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, changePlanSubscription.bind(null, changePlanData, type, planCycle), cancelEstimatedInvoiceForPlanChange.bind(null, type));
            }
        });
    }
    // estimated invoice when plan changes



    // отмена предпросмотра счета к оплате
    function cancelEstimatedInvoice(type, status, tempPlan, tempCoupon) {
        cancelSubscribeDialog(type, tempPlan, tempCoupon);
        subscribeActivate(type, status);
    }
    // отмена предпросмотра счета к оплате



    // отмена предпросмотра счета к оплате при смене плана
    function cancelEstimatedInvoiceForPlanChange(type) {
        setOldPlan(type, tempPlan);
        setOldCoupon(type, tempCoupon);
        ctrl.changePlan(type);
    }
    // отмена предпросмотра счета к оплате при смене плана

    // cancel estimated invoice for renewing subscription
    const cancelEstimatedInvoiceForRenew = (type) => {
        ctrl.subscriptionsInfo[type].blockRetryBtn = false;
    }



    // отправка на сервер отписки от СОТРУДНИКОВ или СКЛАДСКОГО УЧЕТА или ИНТЕГРАЦИЙ (перезагружаем всегда страницу)
    ctrl.deleteSubscription = function (planForDel, type) {

        factoryBilling.deleteSubscription({planId: planForDel}).then(function (res) {

            serviceBillingMixpanel.setMixpanelBillingDelSubscriptionInComp(res.result, type);

            if (res.result === "ok") {
                serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_DELETED', 'success');
                serviceBillingError.reloadRoute();
            }
            else {
                serviceBillingError.unKnownError();
            }
        });
    };
    // отправка на сервер отписки от СОТРУДНИКОВ или СКЛАДСКОГО УЧЕТА или ИНТЕГРАЦИЙ (перезагружаем всегда страницу)



    // диалоговое окно отписка от СОТРУДНИКОВ или СКЛАДСКОГО УЧЕТА или ИНТЕГРАЦИЙ
    ctrl.unsubscribe = function (plan, type, status) {
        let planForDel, title, msgContent2, msgContent3, textConst;
        (plan.id != null)? planForDel = plan.id : planForDel = type;
        textConst = type === "EMPSTORE" ? "EMPLOYEE" : type;

        title = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_UNSUBSCRIBE_' + textConst + '_TITLE');
        msgContent2 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_UNSUBSCRIBE_' + textConst + '_TEXT_1_1');

        if (status === "INTRIAL_SUB_NOACT" || status === "INTRIAL_SUB_ACT") {
            msgContent3 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_UNSUBSCRIBE_' + textConst + '_TEXT_2_1');
        }
        else if (status === "ACTIVE" || status === "PAST_DUE" || status === "PAUSED" || status === "TRIALEND_SUB_NOACT") {
            msgContent3  =  null;
        }

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
            title:          title,
            content1:       null,
            content2:       msgContent2,
            content3:       msgContent3,
            buttons:        [  {   text:  'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingDelSubscriptionInComp("cancel", type);
                }
            },
                {   text:  'ACCOUNT.UNSUBSCRIBE_SUBSCRIBE',
                    class: 'md-primary',
                    action: function () {
                        ctrl.deleteSubscription(planForDel, type);
                    }
                }
            ]
        });
    };
    // диалоговое окно отписка от СОТРУДНИКОВ или СКЛАДСКОГО УЧЕТА или ИНТЕГРАЦИЙ



    // отправка на сервер нового тарифного плана для любого варианта подписки (перезагрузка страницы всегда)
    function changePlanSubscription(data, type, planCycle) {
        factoryBilling.changeNewPlanSubscription(data).then(function (res) {
            serviceBillingMixpanel.setMixpanelBillingChangePlanInComp(res.result, planCycle, type);

            if (res.result === "ok") {
                serviceToast.show('ACCOUNT.B2_TOSTS.B2_PLAN_CHANGE', 'success');
                serviceBillingError.reloadRoute();
            }
            else if (res.result === "error") {
                setOldPlan(type, tempPlan);
                serviceBillingError.knownError(res.message);
            }
            else {
                setOldPlan(type, tempPlan);
                serviceBillingError.unKnownError();
            }
        });
    }
    // отправка на сервер нового тарифного плана для любого варианта подписки (перезагрузка страницы всегда)



    // диалоговое окно изменения тарифного плана для СОТРУДНИКОВ И СКЛАДСКОГО УЧЕТА (есть безлимиты и купоны)
    ctrl.changePlan = function (type) {
        if (!constBillingSubscriptions.includes(type)) {
            return;
        }

        let msgContent3, msgContent4, msgContent5, msgContent6, msgSingle1, msgSingle12, msgUnlim1, msgUnlim12, msgNewPlan, coupon;
        tempPlan    = angular.copy(ctrl.accountInfo.subscriptions[type].plan);

        if(!lodash.some(ctrl.accountInfo.plans[type],{"unlim":true}) && ctrl.accountInfo.subscriptions[type].plan.unlim) {
            ctrl.accountInfo.subscriptions[type].plan.id = null;
        }

        msgSingle1   =  lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 1, 'unlim': false });
        msgSingle12  =  lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 12, 'unlim': false });
        msgUnlim1    =  lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 1, 'unlim': true });
        msgUnlim12   =  lodash.find(ctrl.accountInfo.plans[type], { 'cycle': 12, 'unlim': true });
        msgNewPlan   =  ctrl.accountInfo.subscriptions[type].plan;

        tempCoupon  = angular.copy(ctrl.subscriptionsInfo[type].coupon);
        coupon = ctrl.subscriptionsInfo[type].coupon;

        const payPerConst = type === 'EMPLOYEE' ? 'EMPLOYEE' : 'STORES';

        msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER');
        msgContent4  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_MONTH');
        msgContent5  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_YEAR');
        msgContent6  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_' + payPerConst + '_PAY_PER_UNLIMITED');

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
            title:          translate('ACCOUNT.B2_CHANGE_PLAN'),
            content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
            content3:       msgContent3,
            content4:       msgContent4,
            content5:       msgContent5,
            content6:       msgContent6,
            content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
            content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
            single1:        msgSingle1,
            single12:       msgSingle12,
            unlim1:         msgUnlim1,
            unlim12:        msgUnlim12,
            newPlan:        msgNewPlan,
            paymentType:    ctrl.accountInfo.paymentMethod,
            inEU:           ctrl.accountInfo.taxMessage,
            couponDate:     coupon,
            showFeeText:    type === 'INTEGRATION',
            buttons: [  {   text:  'ON_CANCEL',
                action: function () {
                    serviceBillingMixpanel.setMixpanelBillingChangePlanInComp("cancel", undefined, type);
                    setOldPlan(type, tempPlan);
                    setOldCoupon(type, tempCoupon);
                }
            },
                {   text:   'ON_SAVE',
                    class: $scope.arapro ? "arabic-save" : "",
                    action: function () {
                        (ctrl.subscriptionsInfo[type].coupon.haveCoupon === true && ctrl.subscriptionsInfo[type].coupon.code !== null)? couponCode = ctrl.subscriptionsInfo[type].coupon.code : couponCode = null;
                        ctrl.accountInfo.subscriptions[type].plan = lodash.find(ctrl.accountInfo.plans[type], { 'id': ctrl.accountInfo.subscriptions[type].plan.id });

                        // если пользователь изменил план или добавил купон, запускаем предпросмотр счета;
                        // если он просто нажал "Сохранить" без каких-либо изменений, показываем тост об успешном изменении плана, но никаких запросов на сервер не отправляем и страницу не перегружаем
                        if(tempPlan.id !== ctrl.accountInfo.subscriptions[type].plan.id || tempCoupon.code !== couponCode) {
                            const changePlanData = new SubscriptionEditData(ctrl.accountInfo.subscriptions[type].plan.id, ctrl.accountInfo.subscriptions[type].id, couponCode, true);
                            showEstimatedInvoiceForPlanChange(changePlanData, type, ctrl.accountInfo.subscriptions[type].plan.cycle);
                        } else {
                            serviceToast.show('ACCOUNT.B2_TOSTS.B2_PLAN_CHANGE', 'success');
                            setOldPlan(type, tempPlan);
                            setOldCoupon(type, tempCoupon);
                        }
                    }
                },
                {   text:           'APPLY_APPLY',
                    closeOnAction:  false,
                    action:         function () {
                        addCoupon(ctrl.accountInfo.subscriptions[type].plan, ctrl.subscriptionsInfo[type].coupon, ctrl.subscriptionsInfo[type].coupon.name);
                    }
                }
            ]
        });
    };
    // диалоговое окно изменения тарифного плана для СОТРУДНИКОВ И СКЛАДСКОГО УЧЕТА (есть безлимиты и купоны)



    // запуск повторного платежа по СОТРУДНИКАМ или СКЛАДСКОМУ УЧЕТУ или ПРИОРИТЕТНОЙ ПОДДЕРЖКИ
    ctrl.retryPayment = function (type) {

        ctrl.subscriptionsInfo[type].blockRetryBtn = true;

        factoryBilling.retrySubscriptionTransaction({type: type}).then(function (res) {

            serviceBillingMixpanel.setMixpanelBillingRetryPayInComp(res.result, type);

            if (res.result === "ok") {
                serviceToast.show('ACCOUNT.SUCCESS_TO_PROCESS_PAYMENT', 'success');
                serviceBillingError.reloadRoute();
            }
            else if (res.result === "error") {
                if(res.message === "retry_limit_exceeded") {
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-gateway-rejected.html',
                        class: 'billing-base-384',
                        content: 'ACCOUNT.B2_SERVICE_DIALOG.B2_RETRY_LIMIT_EXCEEDED',
                        minutes: res.param,
                        buttons: [
                            {
                                class: 'md-primary',
                                text: 'ON_OK',
                                action: function () {
                                    $state.go('settings.account', $stateParams, {reload: true});
                                }
                            }
                        ]
                    });
                } else {
                    serviceBillingError.knownError(res.message);
                }
            }
            else {
                serviceBillingError.unKnownError();
            }
        }).then(function () {
            ctrl.subscriptionsInfo[type].blockRetryBtn = false;
        });
    };
    // запуск повторного платежа по СОТРУДНИКАМ или СКЛАДСКОМУ УЧЕТУ или ПРИОРИТЕТНОЙ ПОДДЕРЖКИ


    // предпросмотр счета перед снятием подписки с паузы
    ctrl.showEstimatedInvoiceForPaused = function (type) {
        ctrl.subscriptionsInfo[type].blockRetryBtn = true;

        let data = {
            type: type,
            estimate: true
        };

        factoryBilling.resumeSubscription(data).then(function (res) {
            if(res.result === "ok") {
                getReactivateInvoiceMessage(res.data, type);
            } else if(res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        })
    }
    // предпросмотр счета перед снятием подписки с паузы


    // реактивация подписки на паузе, если нет неоплаченных чеков
    function reactivateSubscription (type, blockBtn) {
        factoryBilling.resumeSubscription({type: type}).then(function (res) {
            if(res.result === "ok") {
                serviceBillingError.reloadRoute();
            } else if(res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        }).then(function () {
            blockBtn = false;
        })
    }
    // реактивация подписки на паузе, если нет неоплаченных чеков


    function getReactivateInvoiceMessage(data, type) {
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-estimated-list.html',
            title: translate('ACCOUNT.B2_REACTIVATE_TITLE'),
            invoice: data.invoice,
            invoiceTotal: data.invoice ? currencyFormatOnlyBilling(data.invoice.total, true) : null,
            invoiceDate: data.invoice ? formatDateByBrowser(data.invoice.created, 'tableLabel') : null,
            estimate: data.estimate,
            estimateTotal: data.estimate ? currencyFormatOnlyBilling(data.estimate.total, true) : null,
            estimateNextBillingDate: data.estimate.applicableAt === 'NEXT_BILLING_CYCLE' ? formatDateByBrowser(data.estimate.nextBillingAt, 'tableLabel') : null,
            preview: serviceBillingDifferentPlaces.showEstimatedInvoiceDialog,
            previewReactivate: reactivateSubscription.bind(null, type, ctrl.subscriptionsInfo[type].blockRetryBtn),
            previewCancel: activateRetryButton.bind(null, type),
            backButton: getReactivateInvoiceMessage.bind(null, data, type),
            buttons: [
                {
                    text:  'ON_CANCEL',
                    action: function () {
                        ctrl.subscriptionsInfo[type].blockRetryBtn = false;
                    }
                },
                {
                    text:  'APPLY',
                    class: 'md-primary',
                    action: reactivateSubscription.bind(null, type, ctrl.subscriptionsInfo[type].blockRetryBtn)
                }
            ]
        });
    }

    function activateRetryButton (type) { ctrl.subscriptionsInfo[type].blockRetryBtn = false; }


    // проверка, разрешен ли выбор тарифного плана
    ctrl.checkIfPlanAllowed = function (paymentMethod, type, status) {
        let callback = subscribeActivate.bind(null, type, status, 'Billing page');
        serviceBillingDifferentPlaces.checkIfPlanAllowed(paymentMethod, callback);
    };
    // проверка, разрешен ли выбор тарифного плана


    // установка старого тарифного плана при неудачных подписках/активациях/сменах плана
    function setOldPlan(type, oldPlan) {
        ctrl.accountInfo.subscriptions[type] ? ctrl.accountInfo.subscriptions[type].plan = oldPlan : "";
    }
    // установка старого тарифного плана при неудачных подписках/активациях/сменах плана


    // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ
    function addCoupon(plan, coupon, nameNew) {
        serviceBillingDifferentPlaces.addCoupon(plan, coupon, nameNew);
    }
    // проверка и установка КУПОНА на СОТРУДНИКОВ и СКЛАДСКОЙ УЧЁТ


    // установка старых данных по КУПОНУ
    function setOldCoupon(type, oldCoupon) {
        ctrl.subscriptionsInfo[type].coupon?  ctrl.subscriptionsInfo[type].coupon = oldCoupon : "";
    }
    // установка старых данных по КУПОНУ


    // отключение диалога выбора подписки
    function cancelSubscribeDialog(type, plan, coupon) {
        if(coupon) setOldCoupon(type, coupon);
        ctrl.accountInfo.subscriptions[type].plan = plan;
    }
    // отключение диалога выбора подписки


    // склонения для сотрудников и заведений
    function getDeclensionsForEmployeesAndStores(count, type) {
        let declensionsConstant = serviceStrings.getDeclensions(count);
        let declensions;
        if (type === "EMPLOYEE") {
            switch (declensionsConstant) {
                case declensionsConst.onlyOne:
                case declensionsConst.everyFirst:
                    declensions = "ACCOUNT.B2_EMPLOYEES_1";
                    break;
                case declensionsConst.twoToFour:
                    declensions = "ACCOUNT.B2_EMPLOYEES_2_4";
                    break;
                default:
                    declensions = "ACCOUNT.EMPLOYEES";
            }
        } else {
            switch (declensionsConstant) {
                case declensionsConst.onlyOne:
                case declensionsConst.everyFirst:
                    declensions = "ACCOUNT.B2_INVENTORY_1";
                    break;
                case declensionsConst.twoToFour:
                    declensions = "ACCOUNT.B2_INVENTORY_2_4";
                    break;
                default:
                    declensions = "ACCOUNT.B2_INVENTORY";
            }
        }

        return declensions;
    }
    // склонения для сотрудников и заведений


    // activates subscription renew, go to add payment method page or show estimated invoice depending on if payment method exists or not
    ctrl.startRenewSubscription = (type) => {
        if (ctrl.accountInfo.paymentMethod === null) {
            const stateParams = {
                subscriptionId: ctrl.accountInfo.subscriptions[type].id
            };

            if(typeBillingPayment === "bt")  { $state.go( 'addPayMethodBt',  stateParams) }
            if(stripeGateways.indexOf(typeBillingPayment) !== -1) { $state.go( 'addPayMethodStr', stateParams) }
            if(typeBillingPayment === "bs" || typeBillingPayment === "wc")  {
                serviceBillingCommonMethods.getChargebeeHostedPageForAdd(ownerId, ctrl.accountInfo.subscriptions[type].plan.id, null);
            }
        } else {
            showEstimatedInvoiceForRenew(ctrl.accountInfo.subscriptions[type].id, type);
        }
    }

    // show estimated invoice for renewing subscription
    const showEstimatedInvoiceForRenew = (subscriptionId, type) => {
        const data = new SubscriptionRenewData(subscriptionId, true);

        ctrl.subscriptionsInfo[type].blockRetryBtn = true;

        factoryBilling.renewSubscription(data).then((res) => {
            if (res.result === "ok") {
                const renewSubscriptionData = new SubscriptionRenewData(subscriptionId, false);
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, renewSubscription.bind(null, renewSubscriptionData, type), cancelEstimatedInvoiceForRenew.bind(null, type));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }

    // renew subscription
    const renewSubscription = (data, type) => {
        factoryBilling.renewSubscription(data).then((res) => {
            if (res.result === "ok") {
                serviceBillingError.reloadRoute();
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        }).then(() => ctrl.subscriptionsInfo[type].blockRetryBtn = false);
    }


    // coupon constructor
    class Coupon {
        constructor(showInput, error, name, tempName, haveCoupon, code) {
            this.showInput = showInput;
            this.error = error;
            this.name = name;
            this.tempName = tempName;
            this.haveCoupon = haveCoupon;
            this.code = code;
        }
    }
    // coupon constructor


    // class used for requests, used to edit existing subscription, such as plan change
    class SubscriptionEditData extends SubscriptionRenewData {
        constructor(planId, subscriptionId, coupon, estimate) {
            super(subscriptionId, estimate);
            this.planId = planId;
            this.coupon = coupon;
        }
    }

}

let accountLicencesInfo = {
    template: template,
    bindings: {
        accountInfo: '<',
        profileBillInfo: '=',
        editProfile: '=',
        ownerId: '='
    },
    bindToController: true,
    controller: accountLicencesInfoCtrl
};

export class SubscriptionRenewData {
    constructor(subscriptionId, estimate) {
        this.subscriptionId = subscriptionId;
        this.estimate = estimate;
    }
}

export default {
    type: 'component',
    name: 'accountLicencesInfo',
    value: accountLicencesInfo
};

