/**
 * Created by mars on 11/11/15.
 */

lvReadonly.$inject = [];
function lvReadonly() {
	return {
		restrict: 'A',
		scope: {
			lvReadonly: '='
		},
		controller: lvReadonlyController
	};
}

lvReadonlyController.$inject = ['$scope'];
function lvReadonlyController($scope) {

	$scope.setReadOnly = function ( val ) {
		$scope.lvReadonly = val
	};

	this.getReadOnly = function () {
		return $scope.lvReadonly
	};

	this.setReadOnly = function ( val ) {
		$scope.lvReadonly = val
	};
	this.lvReadonly = $scope.lvReadonly
}

inputDirective.$inject = [];
function inputDirective() {
	return {
		restrict: 'E',
		require: '?^lvReadonly',
		link: function ( scope, el, attr, cntrl ) {
			var readonly = attr.readonly;
			if ( cntrl ) {

				if ( cntrl.getReadOnly() ) {
					el.attr( 'readonly', true );
				} else {
					if (!readonly) el.removeAttr( 'readonly' );
				}

				scope.$watch( function () {
						return cntrl.getReadOnly()
					},
					function ( val ) {
						if ( val ) {
							el.attr( 'readonly', true );
						} else {
							if (!readonly) el.removeAttr( 'readonly' );
						}
					} )
			}
		}
	}
}

mdSelectDirective.$inject = [];
function mdSelectDirective() {
	return {
		require: '?^lvReadonly',
		link: function ( scope, el, attr, cntrl ) {
			if ( cntrl ) {
				if ( cntrl.getReadOnly() ) {
					el.attr( 'disabled', 'true' );
				} else {
					el.removeAttr( 'disabled' );
				}

				scope.$watch( function () {
						return cntrl.getReadOnly()
					},
					function ( val ) {
						if ( val ) {
							el.attr( 'disabled', 'true' );
						} else {
							el.removeAttr( 'disabled' );
						}
					} )
			}
		}
	}
}

export default [
	{
		type: 'directive',
		name: 'lvReadonly',
		value: lvReadonly
	},
	{
		type: 'directive',
		name: 'input',
		value: inputDirective
	},
	{
		type: 'directive',
		name: 'mdSelect',
		value: mdSelectDirective
	}
];