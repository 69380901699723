labelDirective.$inject = [];

function labelDirective() {
    return {
        restrict: 'E',
        require: '^?lvInputContainer',
        compile: compileDirective
    }

    function compileDirective(tElem, tAttrs) {
        tElem.addClass('lv-label');
        return linkDirective;
    }

    function linkDirective(scope, elem, attrs, containerCtrl) {
        if (!containerCtrl) {
            return;
        }
    }
}

export default {
    type: 'directive',
    name: 'lvLabel',
    value: labelDirective
};