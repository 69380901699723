/**
 * Created by kseniya.y on 21.11.16.
 * @memberof outletCtrl
 * @ngdoc controller
 * @name outletCtrl
 * @requires $scope {service} controllers scope
 * @requires $rootScope {service} app scope
 * @requires $filter {service} filter service
 * @requires $state {service} UI Router service
 * @requires $stateParams {service} UI Router service
 * @requires getOutletsList {service} Get outlets list / operations with outlets
 * @requires serviceDialog {service} Add modal dialog
 * @requires serviceToast {service} Add toast
 // * @requires $outlets {service} Get outlets list before load page
 */

import angular from 'angular';

outletsCtrl.$inject = [
    "$scope",
    "$filter",
    "$state",
    "$stateParams",
    "getOutletsList",
    "serviceDialog",
    '$outlets',
    "serviceToast",
    "$profile",
    "serviceBillingError",
    'lvCountriesMultiLang'
];

function outletsCtrl ($scope,
                      $filter,
                      $state,
                      $stateParams,
                      getOutletsList,
                      serviceDialog,
                      $outlets,
                      serviceToast,
                      $profile,
                      serviceBillingError,
                      lvCountriesMultiLang) {
    $scope.profile = angular.copy($profile);
    let vm                  = this;
    let translate           = $filter('translate');
    vm.profileOutletsLimit  = $profile.outletsCountLimit;
    vm.outlets              = $outlets.outlets.map(outlet => {
        if(angular.isString(outlet.address)) {
            outlet.displayAddress = outlet.address;
        } else {
            let addressParts = [];
            if(outlet.address.address) {
                addressParts.push(outlet.address.address);
            }
            if(outlet.address.city) {
                addressParts.push(outlet.address.city);
            }
            if(outlet.address.region) {
                addressParts.push(outlet.address.region);
            }
            if(outlet.address.postcode) {
                addressParts.push(outlet.address.postcode);
            }
            if(outlet.address.country) {
                let countryName;
                let countryObj = lvCountriesMultiLang.find(country => country.code == outlet.address.country);
                if(countryObj) {
                    countryName = translate('COUNTRY.' + countryObj.name);
                }
                else {
                    countryName = outlet.address.country;
                }

                addressParts.push(countryName);
            }
            outlet.displayAddress = addressParts.join(', ');
        }
        return outlet;
    });
    vm.outletParams         = {};
    vm.accountInBlock       = $profile.billingInfo.billingStatus === "BLOCKED";
    let reloadToUnblock = $stateParams.reloadToUnblock;

    vm.invoicing = $profile.features.eInvoicing;
    vm.isSaudi = $profile.country === 'sa';

    if (vm.outlets) {
        vm.outlets._select  = false;
        vm.selectedOutlets  = [];
        vm.showDel          = false;
        vm.translate        = $filter('translate');
        vm.changeAll        = changeAll;
        vm.changeOutlet     = changeOutlet;
        vm.editOutlet       = editOutlet;
        vm.onAdd            = onAdd;
        vm.onDel            = onDel;
        vm.backToBilling    = backToBilling;
        vm.editTableState   = 'editoutlet';
        selectAll(false);
    }

    // reloads page, when needed (now only after adding store in blocked account with no stores)
    if (reloadToUnblock) {
        serviceBillingError.reloadRoute();
    }


    /**
     * @ngdoc function
     * @description
     * Select and drop selection of all outlets in list using checkbox. These outlets will be added and deleted
     * from vm.selectedOutlets array.
     */
    function changeAll() {
        if (vm.outlets._select) {
            selectAll(true);
            vm.showDel = true;
            vm.selectedOutlets = vm.outlets;
        } else {
            selectAll(false);
            vm.showDel = false;
            vm.selectedOutlets = [];
        }
    }

    /**
     * @ngdoc function
     * @description
     * Adds _select property to the vm.outlets with value specified by param.
     * @param {boolean} param value of the _select property
     */
    function selectAll(param) {
        let i,
            l = $outlets.length;

        if (vm.outlets.length) {
            for (i = 0; i < l; i++) {
                vm.outlets[i]._select = param;
            }
        }

    }


    /**
     * @ngdoc function
     * @description
     * Sends delete request to the server with Ids of selected outlets, triggers  success information message and
     * page reload.
     */
    function onDel() {
        let selectedOutletsIds  = vm.selectedOutlets.map(function (outlet) { return outlet.id; });
        let deleteMessage       = (selectedOutletsIds.length > 1) ? 'MULTISHOP.STORE.DELETE_STORES' : 'MULTISHOP.STORE.MODIFIERS.DELETE_STORE';

        getOutletsList.delOutlet({outletsIds: selectedOutletsIds}).then(function (res) {
                if (res.result === "ok") {
                    serviceToast.show(deleteMessage, 'success');
                    $state.go($state.current.name, {}, {
                        reload: true
                    });
                }
                else if (res.result === "error" && res.message === "temporary_unavailable") {
                    serviceDialog.add({
                        templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                        content1:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ERROR_CHARGEBEE'),
                        buttons:     [{ class: 'primary',
                                        text: 'ON_OK',
                                        action: function () {
                                            $state.go($state.current.name, {}, {
                                                reload: true
                                            });
                                        }
                                    }]
                    });
                }
        });
    }

    /**
     * @ngdoc function
     * @description
     * Adds to or removes from vm.selectedOutlets array selected item and controls showing/hiding of the delete
     * button.
     * @param {object} outlet
     */
    function changeOutlet(outlet) {
        if (outlet._select) {
            vm.selectedOutlets.push(outlet);
            vm.showDel = true;

            if (vm.selectedOutlets.length === vm.outlets.length) {
                vm.outlets._select = true;
            }
        } else {
            vm.selectedOutlets = vm.selectedOutlets.filter(function (selectedOutlets) {
                return outlet.id !== selectedOutlets.id;
            });

            if (vm.outlets._select) vm.outlets._select = false;

            if (!vm.selectedOutlets.length) vm.showDel = false;
        }
    }

    /**
     * @ngdoc function
     * @description
     * Adds to or removes from vm.selectedOutlets array selected item and controls showing/hiding of the delete
     * button.
     * @param {object} outlet
     */
    function editOutlet(outlet) {
        vm.outletParams.outletId = outlet.id;
        vm.outletParams.name = outlet.name;
        vm.outletParams.address = outlet.address;
        vm.outletParams.comment = outlet.comment;
        vm.outletParams.location = outlet.location;
        vm.outletParams.phone = outlet.phone;
        vm.outletParams.registersCount = outlet.registersCount;
        vm.outletParams.schedule = outlet.schedule;
        vm.outletParams.receiptHeader = outlet.receiptHeader;
        vm.outletParams.receiptFooter = outlet.receiptFooter;
        vm.outletParams.receiptLang = outlet.receiptLang;
        vm.outletParams.uuid = outlet.uuid;

        if (vm.outletParams.uuid)
            $state.go('editoutlet', { outletParams: vm.outletParams, uuid: vm.outletParams.uuid});
        else
            $state.go('editoutlet', { outletParams: vm.outletParams, outletId: vm.outletParams.outletId});
    }


    // /**
    //  * @ngdoc function
    //  * @description
    //  * Redirects to the outlets creation page.
    //  */
    function onAdd() {
       if (vm.outlets.length >= vm.profileOutletsLimit) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('MULTISHOP.STORES.UNABLE_TO_ADD_STORE'),
                content: translate('MULTISHOP.STORES.ONLY_HUNDRED_STORES'),
                buttons: [
                    {
                        class: 'primary',
                        text: 'ON_OK'
                    }
                ]
            });
        } else {
            $state.go('createoutlet', $stateParams, {});
        }
    }


    function backToBilling() {
        $state.go('settings.account', {} );
    }

}

export default {
    type: 'controller',
    name: 'outletsCtrl',
    value: outletsCtrl
};
