/**
 * Created by A.Sirosh on 17.12.2015.
 * @memberof saveComment
 * @ngdoc factory
 * @name saveComment
 * @requires $http {service} http service
 * @requires $q {service} promise service
 * @requires factoryErrorAlert {service} error handler service
 * @returns setCommentData{Promise} set comment data
 * @returns setViewed{Promise} set comment as viewed
 */

saveComment.$inject = ["$http", "$q", "factoryErrorAlert"];

function saveComment($http, $q, factoryErrorAlert) {

	var data = {};
	var view = {};

	function successSet(response) {
		data = response.data;
	}

	function setCommentData(params) {
		return $q(function (resolve, reject) {
			$http.post('/data/ownercab/setrecallresponse', params).then(function (d) {
				successSet(d);
				resolve(data);
			}, function (error) {
				factoryErrorAlert.onError(error);
				reject(error);
			});
		});
	}

	function successSetViewed(response){
		view = response.data;
	}

	function setViewed(params){
		return $q(function (resolve, reject) {
			$http.post('/data/ownercab/setrecallstatus', params).then(function (d) {
				successSetViewed(d);
				resolve(view);
			}, function (error) {
				factoryErrorAlert.onError(error);
				reject(error);
			});
		});
	}

	return {
		setCommentData: setCommentData,
		setViewed: setViewed
	};

}

export default {
	type: 'factory',
	name: 'saveComment',
	value: saveComment
};