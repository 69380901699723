/**
 * Created yarosh on 31.05.17.
 */

formatDropDownTime.$inject = ['retrieveLangCode'];
function formatDropDownTime(retrieveLangCode, hour) {
    var formattedTime;
    var preFormattedHour;
    var formattedHour;
    return function (hour) {

        defineTimeFormat();

        if(formattedTime) {
            if (parseFloat(hour) >= 1 && parseFloat(hour) <= 11) {
                formattedHour = parseFloat(hour) + ' AM';
                return formattedHour;
            } else if (parseFloat(hour) === 0) {
                formattedHour = '12 AM';
                return formattedHour;
            } else {
                switch (parseFloat(hour)) {
                    case 12:
                        preFormattedHour = 12;
                        break;
                    case 13:
                        preFormattedHour = 1;
                        break;
                    case 14:
                        preFormattedHour = 2;
                        break;
                    case 15:
                        preFormattedHour = 3;
                        break;
                    case 16:
                        preFormattedHour = 4;
                        break;
                    case 17:
                        preFormattedHour = 5;
                        break;
                    case 18:
                        preFormattedHour = 6;
                        break;
                    case 19:
                        preFormattedHour = 7;
                        break;
                    case 20:
                        preFormattedHour = 8;
                        break;
                    case 21:
                        preFormattedHour = 9;
                        break;
                    case 22:
                        preFormattedHour = 10;
                        break;
                    case 23:
                        preFormattedHour = 11;
                        break;
                }
                return formattedHour = preFormattedHour + ' PM';
            }

            return formattedHour;
        } else {
            return hour;
        }

        function defineTimeFormat() {
            formattedTime = (function () {
                return retrieveLangCode.is12HourFormat()
            })();
        }

    }
}

export default {
    type: 'filter',
    name: 'formatDropDownTime',
    value: formatDropDownTime
};