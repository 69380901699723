/**
 * Created by mars on 10/13/15.
 */

const quickGroup = [
	{
		key: 'TODAY',
		text: 'сегодня'
	},
	{
		key: 'YESTERDAY',
		text: 'вчера'
	},
	{
		key: 'CURRENT_WEEK',
		text: 'Эта неделя'
	},
	{
		key: 'CURRENT_MONTH',
		text: 'Этот месяц'
	}
];

export default {
	type: 'constant',
	name: 'quickGroup',
	value: quickGroup
};