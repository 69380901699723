import angular from 'angular';

factorySuppliers.$inject = ['promiseOperation'];

function factorySuppliers(promiseOperation) {

    function getSuppliers(params) {
        // console.log("params = ", params);
        if (params.offset >= 0) {
            return promiseOperation
                .getPromiseHTTP(
                    'POST',
                    '/data/ownercab/getSuppliers',
                    params,
                    successGetSuppliers
                );
        }
        if (params.offset == -10) {
            params.offset = 0;
            return promiseOperation
                .getPromiseHTTP(
                    'POST',
                    '/data/ownercab/getSuppliers',
                    params,
                    successGetSuppliers
                );
        }
    }

    function setSuppliersData(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/getSuppliers',
                params,
                successSetSuppliersData
            );
    }

    function successGetSuppliers(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }


    function successSetSuppliersData(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }

    function createSupplier(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/createSupplier',
                params,
                successCreateSupplier
            );
    }

    function successCreateSupplier(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if (response.data.result === "error") {
                return response.data;
            }
        }
    }

    function editSupplier(params) {
        return promiseOperation
            .getPromiseHTTP(
                'POST',
                '/data/ownercab/editSupplier',
                params,
                successEditSupplier
            );
    }

    function successEditSupplier(response) {
        if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
                return response.data;
            } else if (response.data.result === "error") {
                return response.data;
            }
        }
    }

    function getSupplierById(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getSupplierById',
            params,
            successGetSuppliers
        );
    }

    function deleteSupplier(params){

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/deleteSupplier',
            params,
            successDeleteSupplier
        );

    }

    function successDeleteSupplier(response){
        if (angular.isObject(response.data)) {
            if (response.data.result === 'ok') {
                return response.data.result;
            }
        }
    }


    return {
        getSuppliers: getSuppliers,
        setSuppliersData: setSuppliersData,
        deleteSupplier: deleteSupplier,
        createSupplier: createSupplier,
        getSupplierById: getSupplierById,
        editSupplier: editSupplier
    };

}

export default {
    type: 'factory',
    name: 'factorySuppliers',
    value: factorySuppliers
};