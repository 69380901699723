/**
 * Created by Nikitin Oleksii on 26.08.21   .
 * Contains key codes
 */

const keyCode = {
    BACKSPACE: 8,
    ENTER: 13,
    ESCAPE: 27,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
};

export default {
    type: 'constant',
    name: 'keyCode',
    value: keyCode
};