/**
 * Created by kseniya.yarosh on 07.08.16.
 */

import angular from 'angular';

paymentTypePermissions.$inject = [
    '$scope',
    '$paytypePermissions',
    '$paymentTypesInfo',
    '$payConst',
    'lodash'
];

function paymentTypePermissions(
    $scope,
    $paytypePermissions,
    $paymentTypesInfo,
    $payConst,
    lodash) {


    let noShowPermissions = [
        "CASH",
        "WORLDPAY",
        "CAYENE",
        "COINEY",
        "IZETTLE",
        "SUMUP",
        "TYRO",
        "SMARTPAY",
        "NICEPAY",
        "YOCO",
        "EZETAP",
        "PAYGATE",
        "FIRSTDATA",
        "ONEPAY",
        "SOFTBANK",
        "MERCADOPAGO",
        "KICC",
        "POSLINK"
    ];
    const constMethods = angular.copy($payConst.paymentMethods);

    $scope.payments = angular.copy($paymentTypesInfo.paymentTypes);

    $scope.paymentsMethodsExists = [];
    $scope.payments.forEach(function (method) {
        if(method.method !== "OTHER" && method.method !== "CHEQUE")
            $scope.paymentsMethodsExists.push(method.method)
    });

    $scope.vantivExists = false;
    $scope.vantivId = null;
    $scope.sumupExists = false;
    $scope.sumupId = null;
    $scope.izettleExists = false;
    $scope.izettleId = null;
    $scope.tyroExists = false;
    $scope.tyroId = null;


    $paytypePermissions.forEach(function (val){
        switch (val) {
            case "ACCESS_VANTIV":
                lodash.pull(noShowPermissions, "WORLDPAY");
                break;
            case "ACCESS_CAYENE":
                lodash.pull(noShowPermissions, "CAYENE");
                break;
            case "ACCESS_COINEY":
                lodash.pull(noShowPermissions, "COINEY");
                break;
            case "ACCESS_IZETTLE":
                lodash.pull(noShowPermissions, "IZETTLE");
                break;
            case "ACCESS_SUMUP":
                lodash.pull(noShowPermissions, "SUMUP");
                break;
            case "ACCESS_TYRO":
                lodash.pull(noShowPermissions, "TYRO");
                break;
            case "ACCESS_SMARTPAY":
                lodash.pull(noShowPermissions, "SMARTPAY");
                break;
            case "ACCESS_NICEPAY":
                lodash.pull(noShowPermissions, "NICEPAY");
                break;
            case "ACCESS_YOCO":
                lodash.pull(noShowPermissions, "YOCO");
                break;
            case "ACCESS_EZETAP":
                lodash.pull(noShowPermissions, "EZETAP");
                break;
            case "ACCESS_PAYGATE":
                lodash.pull(noShowPermissions, "PAYGATE");
                break;
            case "ACCESS_FIRSTDATA":
                lodash.pull(noShowPermissions, "FIRSTDATA");
                break;
            case "ACCESS_ONEPAY":
                lodash.pull(noShowPermissions, "ONEPAY");
                break;
            case "ACCESS_SOFTBANK":
                lodash.pull(noShowPermissions, "SOFTBANK");
                break;
            case "ACCESS_MERCADOPAGO":
                lodash.pull(noShowPermissions, "MERCADOPAGO");
                break;
            case "ACCESS_KICC":
                lodash.pull(noShowPermissions, "KICC");
                break;
            case "ACCESS_POSLINK":
                lodash.pull(noShowPermissions, "POSLINK");
                break;
            default:
                break;
        }
    });

    $scope.payMethods = lodash.difference(constMethods, noShowPermissions);


    $scope.payments.forEach(function(obj){
        if (obj.method === "VANTIV") {
            $scope.vantivExists = true;
            $scope.vantivId = obj.id;
        }
        if (obj.method === "SUMUP") {
            $scope.sumupExists = true;
            $scope.sumupId = obj.id;
        }
        if (obj.method === "IZETTLE") {
            $scope.izettleExists = true;
            $scope.izettleId = obj.id;
        }
        if (obj.method === "TYRO") {
            $scope.tyroExists = true;
            $scope.tyro = obj.id;
        }
    });

}

export default {
    type: 'controller',
    name: 'paymentTypePermissions',
    value: paymentTypePermissions
};
