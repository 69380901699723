/**
 * Created by A.Sirosh on 08.10.2015.
 * @memberof timeTrackingFactory
 * @ngdoc factory
 * @name timeTrackingFactory
 * @param $http {service} http service
 * @param $q {service} promise service
 * @param $location {service} location service
 * @returns createOutletData {Promise} outlets and merchants
 * @returns createTimeCard {Promise} outlets and merchants
 * @returns getTimeCardById {Promise} outlets and merchants
 * @returns createTimeCard {Promise} outlets and merchants
 * @returns editTimeCard {Promise} outlets and merchants
 * @returns deleteTimeCards {Promise} outlets and merchants
 * @returns getTimeCards {Promise} outlets and merchants
 */

import angular from 'angular';

timeTrackingFactory.$inject = ["$http", "$q", 'factoryErrorAlert'];

function timeTrackingFactory($http, $q, factoryErrorAlert) {

	function success(response) {
		if (angular.isObject(response.data)) {
			if (response.data.result === "ok") {
				return response.data;
			} else {
				return $q.reject(response.data);
			}
		} else {
			return $q.reject(response.data);
		}
	}

	function createTimeCard(params) {
		return $http.post('/data/ownercab/createtimecard', params).then(success, factoryErrorAlert.onError);
	}

	function getTimeCardById(params) {
		return $http.post('/data/ownercab/gettimecardbyid', params).then(success, factoryErrorAlert.onError);
	}

	function editTimeCard(params) {
		return $http.post('/data/ownercab/edittimecard', params).then(success, factoryErrorAlert.onError);
	}

	function deleteTimeCards(params) {
		return $http.post('/data/ownercab/deletetimecards', params).then(success, factoryErrorAlert.onError);
	}

	function getTimeCards(params) {
		return $http.post('/data/ownercab/gettimecards', params).then(success, factoryErrorAlert.onError);
	}

	function isTimeCardConflicted(params) {
		return $http.post('/data/ownercab/istimecardconflicted', params).then(success, factoryErrorAlert.onError);
	}

	function getTimeCardsReport(params) {
		return $http.post('/data/ownercab/gettimecardsreport', params).then(success, factoryErrorAlert.onError);
	}

	return {
		createTimeCard: createTimeCard,
		getTimeCardById: getTimeCardById,
		editTimeCard: editTimeCard,
		deleteTimeCards: deleteTimeCards,
		getTimeCards: getTimeCards,
		isTimeCardConflicted: isTimeCardConflicted,
		getTimeCardsReport: getTimeCardsReport
	};

}

export default {
	type: 'factory',
	name: 'timeTrackingFactory',
	value: timeTrackingFactory
};