/*** Created by Alex 16.06.17 ***/

import angular from 'angular';
import {loadStripe} from '@stripe/stripe-js/pure';
import {SubscriptionRenewData} from "./components/billing-subcriptions-comp";
import {Urls} from "../../../service/service-billing-common-methods";

payMethodControllerStr.$inject = [
        "$scope",
        "$state",
        "$stateParams",
        "$filter",
        '$token',
        "lvCountries",
        "statesUS",
        "provincesCan",
        "factoryBilling",
        "serviceDialog",
        "$timeout",
        "getEmployees",
        "serviceToast",
        "lvCountriesES",
        "lodash",
        "$profile",
        "$minAccountInfo",
        "serviceBillingMixpanel",
        "serviceBillingError",
        "serviceBillingCommonMethods",
        "serviceReCaptcha",
        "vcRecaptchaService",
        "constBillingKnownErrors",
        "$environment",
        'dialogs',
        'lvMixpanel'];
function payMethodControllerStr(
          $scope,
          $state,
          $stateParams,
          $filter,
          $token,
          lvCountries,
          statesUS,
          provincesCan,
          factoryBilling,
          serviceDialog,
          $timeout,
          getEmployees,
          serviceToast,
          lvCountriesES,
          lodash,
          $profile,
          $minAccountInfo,
          serviceBillingMixpanel,
          serviceBillingError,
          serviceBillingCommonMethods,
          serviceReCaptcha,
          vcRecaptchaService,
          constBillingKnownErrors,
          $environment,
          dialogs,
          lvMixpanel) {


    // БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ
    let translate               = $filter('translate');
    $scope.profile              = $profile;
    $scope.minAccountInfo       = $minAccountInfo;
    $scope.newUser              = $stateParams.newUser;
    $scope.trialStatus          = $stateParams.trialStatus;
    $scope.addSubscribe         = $stateParams.addSubscribe;
    $scope.typeSubscriptions    = $stateParams.typeSubscriptions;
    $scope.activate             = $stateParams.activate;
    $scope.couponCode           = $stateParams.coupon;
    $scope.countries            = angular.copy(lvCountries);
    $scope.countriesES          = angular.copy(lvCountriesES);
    $scope.statesUsa            = angular.copy(statesUS);
    $scope.statesCanada            = angular.copy(provincesCan);
    $scope.isVAT                = false;
    $scope.isHaveState          = false;
    $scope.usa                  = false;
    $scope.canada               = false;
    $scope.data                 = { country: "", vat: ""};
    $scope.cardDateOptions       = { date: true, datePattern: ['m', 'y'] };
    $scope.countryChangeable = $scope.minAccountInfo.countryChangeable;
    $scope.excludedCountries = $scope.minAccountInfo.excludedCountries;
    $scope.subscriptionId = $stateParams.subscriptionId;
    let employeeStat = $scope.minAccountInfo.subscriptions.EMPSTORE
                        ? $scope.minAccountInfo.subscriptions.EMPSTORE.status
                        : $scope.minAccountInfo.subscriptions.EMPLOYEE.status;
    let inventoryStat = $scope.minAccountInfo.subscriptions.INVENTORY.status;
    let integrationStat = $scope.minAccountInfo.subscriptions.INTEGRATION.status;
    let salesHistoryStat = $scope.minAccountInfo.subscriptions.SALESHISTORY.status;
    let knownErrors             = angular.copy(constBillingKnownErrors);
    let stripe;
    const urls = new Urls($stateParams.urlForReload, $stateParams.urlForSuccessReload);
    $scope.showVatText= false;

    // для рекаптчи
    let useRecaptcha = false;
    const recaptchaInfo = {
        key: '6Lc5I6YUAAAAAAbtpKBX9XK6zEk14wToFRvbcpkB',
        response: null,
        widgetId: null,
        verify: true
    };
    let recaptchaDialog = {
        templateUrl:    'dashboard-page/templates/dialog/dialog-recaptcha.html',
        title: 'ACCOUNT.B2_SERVICE_DIALOG.B2_RECAPTCHA_TITLE',
        recaptchaInfo: recaptchaInfo,
        setResponse: setResponse,
        setWidgetId: setWidgetId,
        cbExpiration: cbExpiration,
        submit: verifyReCaptchaOnSubmit,
        lang: serviceReCaptcha.getLangForReCaptcha($scope.profile.cabinetLang),
        buttons: [
            {
                text: 'ON_CANCEL',
                action: function () {
                    angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                }
            },
            {
                class: 'md-primary',
                text: 'CONTINUE_CONTINUE'
            }
        ]
    };
    // для рекаптчи

    let initParams;
    let offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);
    function isChanged() {
        let currentParams = angular.copy($scope.data);
        return !angular.equals(initParams, currentParams);
    }

    // БЛОК ИНИЦИАЛИЗАЦИИ ПЕРЕМЕННЫХ


    // функция для показа или не показа блока ШТАТ и ЗИП-код если США или Канада
    $scope.selectedCountrieValue = function (kode) {
        $scope.data.state   = "";
        $scope.data.zip     = "";
        if (kode === "us") {
            $scope.isHaveState  = true;
            $scope.usa          = true;
            $scope.canada       = false;
            $scope.isVAT        = false;
            return;
        }
        if (kode === "ca") {
            $scope.isHaveState  = true;
            $scope.usa          = false;
            $scope.canada       = true;
            $scope.isVAT        = false;
            return;
        }
        if(lodash.find($scope.countriesES, ['code', kode]) || kode === "gb") {
            $scope.isVAT        = true;
            $scope.isHaveState  = false;
            $scope.usa          = false;
            $scope.canada       = false;
            $scope.data.vat     = "";
        }
        else {
            $scope.isHaveState  = false;
            $scope.usa          = false;
            $scope.canada       = false;
            $scope.isVAT        = false;
        }
        $scope.showVatText = $scope.isVAT && !(kode === "gb" && $scope.profile.billingInfo.gw === "struk");
    };
    // функция для показа или не показа блока ШТАТ и ЗИП-код если США или Канада



    // функция получения/присваивания кода страны
    $scope.getCountry = function () {
        if ($token.billingCountry !== null) {
            if ($token.billingCountry === "us") {
                $scope.isHaveState = true;
                $scope.usa = true;
            }
            if ($token.billingCountry === "ca") {
                $scope.isHaveState = true;
                $scope.canada = true;
            }
            if(lodash.find($scope.countriesES, ['code', $token.billingCountry]) || $token.billingCountry === "gb") {
                $scope.isVAT = true;
            }
            $scope.showVatText = $scope.isVAT && !($token.billingCountry === "gb" && $scope.profile.billingInfo.gw === "struk");
            return $token.billingCountry;
        } else {
            return "";
        }
    };
    // функция получения/присваивания кода страны



    // функция получения/присваивания ШТАТА
    $scope.getState = function () {
        if ($token.hasOwnProperty('state') && $token.state !== null) {
            return $token.state;
        } else {
            return "";
        }
    };
    // функция получения/присваивания ШТАТА



    // функция получения/присваивания ZIP или POST
    $scope.getZip = function () {
        if ($token.hasOwnProperty('zip') && $token.zip !== null) {
            return $token.zip;
        } else {
            return "";
        }
    };
    // функция получения/присваивания ZIP или POST


    // данные - страна и налог(если ЕС)
    $scope.data = { country:    $scope.getCountry(),
                    state:      $scope.getState(),
                    zip:        $scope.getZip(),
                    vat:        serviceBillingCommonMethods.getVat($token),
                    typeBillingPayment: $scope.profile.billingInfo.gw};
    // данные - страна и налог(если ЕС)

    initParams = angular.copy($scope.data);

    // показ сервисного диалога при приходе ошибки с СТРАЙП
    function showErrorAlert(message) {
        switch (message.code) {
            case "incorrect_cvc":
                $scope.textIfError = translate("ACCOUNT.STRIPE_ERRORS.INVALID_CVC");
                break;
            case "invalid_expiry_year_past":
                $scope.textIfError = translate("ACCOUNT.STRIPE_ERRORS.INVALID_EXPIRY_MONTH_PAST");
                break;
            case "invalid_expiry_year":
                $scope.textIfError = translate("ACCOUNT.STRIPE_ERRORS.INVALID_EXPIRY_MONTH");
                break;
            default:
                $scope.textIfError = translate("ACCOUNT.STRIPE_ERRORS." + message.code.toUpperCase());
        }
        // take the Stripe error message if string is untranslated
        let translateWithoutSpaces = $scope.textIfError.replace(/\s/g, '');
        if(!$scope.textIfError || $scope.textIfError.match(/^ACCOUNT.STRIPE_ERRORS./) || !translateWithoutSpaces) {
            $scope.textIfError = message.message;
        }
        useRecaptcha = true;
        $timeout(function () {
            serviceDialog.add({
                templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
                class:          'billing-base-384',
                content1:        $scope.textIfError,
                buttons:        [{ class: 'primary',
                                    text: 'ON_OK' }]
            });
        }, 0);
    }
    // показ сервисного диалога при приходе ошибки с СТРАЙП



    function sendPaymentIntentId(paymentIntentId) {
        factoryBilling.sendPaymentNonce(paymentIntentId, $scope.data).then(function (res) {
            if (res.result === "ok") {
                offStateChangeStart();
                serviceToast.show('ACCOUNT.CARD_ADDED', 'success');
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                if (!lodash.isEmpty($scope.newUser)) {
                    serviceBillingCommonMethods.showEstimatedInvoiceForEmployees($scope.newUser, employeeStat, $scope.typeSubscriptions, true);
                }
                else if (!lodash.isEmpty($scope.addSubscribe)) {
                    let addSubscribeInfo = serviceBillingCommonMethods.getAddSubscribeData($scope.addSubscribe, $scope.typeSubscriptions, $scope.activate, $scope.couponCode);
                    let statusesInfo = serviceBillingCommonMethods.getStatuses(employeeStat, inventoryStat, integrationStat, salesHistoryStat);

                    serviceBillingCommonMethods.showEstimatedInvoiceForNewSubscribe(addSubscribeInfo, statusesInfo, urls, $stateParams.source);
                }
                else if (!lodash.isEmpty($scope.subscriptionId)) {
                    const renewSubscriptionData = new SubscriptionRenewData($scope.subscriptionId, true);
                    serviceBillingCommonMethods.showEstimatedInvoiceForRenew(renewSubscriptionData, urls.urlForSuccessReload);
                }
                else if (res.reloadToUnblock) {
                    $state.go('settings.account', { reloadToUnblock: true });
                }
                else {
                    $state.go('settings.account', $stateParams, {});
                }
            }
            else if (res.result === "error") {
                // По утверждению ДЛ, для СТРАЙПА нет никакой особой обработки, поэтому с сервера должны приходить те же сообщения, что и для БРЕЙНТРИ.
                // Поэтому делаем то же условие для ReCaptcha, что и для БРЕЙНТРИ
                useRecaptcha = res.message === "payment_method_verification_failed" || knownErrors.indexOf(res.message) === -1;
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");

                serviceBillingError.knownError(res.message);
            }
            else {
                useRecaptcha = true;
                serviceBillingError.unKnownError();
            }
        });
    }



    // отправка данных карты на stripe и получение ответа
    $scope.getStripeToken = function () {
        if (stripe) {
            angular.element(document.querySelector('#btn_main')).attr("disabled", "disabled");

            if (useRecaptcha) {
                serviceDialog.add(recaptchaDialog);
            } else {
                submitPaymentInfo();
            }
        }
    };
    // отправка данных карты на stripe и получение ответа


    // submit payment info
    function submitPaymentInfo () {
        if ($scope.isVAT && $scope.data.vat.length > 0) {
            let vatInfo = {vat: $scope.data.vat, country: $scope.data.country}
            factoryBilling.verifyVat(vatInfo).then(function (res) {
                if (res.result === 'ok') {
                    if (res.valid) {
                        checkThreeDSecure();
                    } else {
                        serviceBillingError.knownError("vat_invalid");
                        angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                    }
                }
            });
        } else {
            checkThreeDSecure();
        }
    }
    // submit payment info


    // запуск работы системы stripe
    let cardNumber, cardCvc, cardExpiry;
    async function initStripe() {
        let publishableKey;

        switch ($scope.profile.billingInfo.gw) {
            case "streu":
                publishableKey = $environment.stripePublishableKeyEU;
                break;
            case "struk":
                publishableKey = $environment.stripePublishableKeyUK;
                break;
            default:
                break;
        }

        if (publishableKey) {
            stripe = await loadStripe(publishableKey);

            if (stripe) {
                const elements = stripe.elements({
                    fonts: [{
                        cssSrc: "https://fonts.googleapis.com/css?family=Roboto:500,900,400italic,300,700,500italic,400&amp;subset=latin,cyrillic,cyrillic-ext"
                    }]
                });
                const elementStyles = {
                    style: {
                        base: {
                            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '23px',
                            color: 'rgba(0, 0, 0, .87)',
                            '::placeholder': {
                                color: 'rgba(0, 0, 0, .38)',
                                textAlign: document.querySelector('body').classList.contains('aranav') ? 'right' : 'left'
                            }
                        },
                        invalid: {
                            color: 'rgba(0, 0, 0, .87)'
                        }
                    },
                    classes: {
                        empty: 'stripe-field-empty',
                        focus: 'stripe-field-focused',
                        invalid: 'stripe-field-invalid',
                        complete: 'stripe-field-complete'
                    },
                    placeholder: ''
                };
                cardNumber = elements.create('cardNumber', elementStyles);
                cardNumber.mount('#card-number');

                cardCvc = elements.create('cardCvc', elementStyles);
                cardCvc.mount('#card-cvc');

                elementStyles.placeholder = translate('ACCOUNT.MM_YY');
                cardExpiry = elements.create('cardExpiry', elementStyles);
                cardExpiry.mount('#card-expiry');
            }
        }
    }
    initStripe();
    // запуск работы системы stripe




    // уход с этой страницы
    $scope.onCancel = function () {
        serviceBillingMixpanel.setMixpanelBillingOnCancelInPaymentMethod(
            $scope.typeSubscriptions, employeeStat, inventoryStat, integrationStat, salesHistoryStat, $stateParams.source);

        serviceBillingCommonMethods.onCancel($scope.newUser, $scope.addSubscribe, $scope.subscriptionId, urls);
    };
    // уход с этой страницы




    // Проверка 3DS
    function checkThreeDSecure() {
        stripe.createPaymentMethod({type: 'card', card: cardNumber}).then(function (result) {
            if (result.error) {
                angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                showErrorAlert(result.error);
            } else if (result.paymentMethod) {
                factoryBilling.verifyStripeCardThreeDS({
                    paymentMethodId: result.paymentMethod.id
                }).then(function (res) {
                    if (res.result === "ok") {
                        if (res.stripe3ds.requiresAction && res.stripe3ds.clientSecret) {
                            // вызываем 3DS-аутентификацию, если ответ сервера говорит о том, что она нужна
                            stripe.handleCardPayment(res.stripe3ds.clientSecret).then(function (result) {
                                if(result.paymentIntent && result.paymentIntent.status === "requires_capture") {
                                    sendPaymentIntentId(result.paymentIntent.id);
                                } else {
                                    // добавляем ReCaptcha, если СТРАЙП присылает любую ошибку
                                    useRecaptcha = true;
                                    angular.element(document.querySelector('#btn_main')).removeAttr("disabled");
                                    $timeout(function () {
                                        serviceBillingError.knownError("payment_method_verification_failed");
                                    }, 0);
                                }
                            })
                        } else {
                            sendPaymentIntentId(res.stripe3ds.paymentIntentId)
                        }
                    } else if(res.result === "error") {
                        angular.element(document.querySelector('#btn_main')).removeAttr("disabled");

                        serviceBillingError.knownError(res.message);
                    }
                })
            }
        })
    }
    // Проверка 3DS

    // методы для рекатпчи
    function setResponse(response) {
        serviceReCaptcha.setResponse(response, recaptchaInfo);
    }

    function setWidgetId(widgetId) {
        serviceReCaptcha.setWidgetId(widgetId, recaptchaInfo);
    }

    function cbExpiration() {
        serviceReCaptcha.cbExpiration(recaptchaInfo);
    }
    // методы для рекаптчи


    // Проверка рекаптчи
    function verifyReCaptchaOnSubmit(recaptchaResponse) {
        recaptchaInfo.verify = false;
        factoryBilling.verifyReCaptcha({recaptchaResponse: recaptchaResponse}).then(function (res) {
            if (res.result === "ok") {
                serviceDialog.close(recaptchaDialog);
                $timeout(function() {
                    submitPaymentInfo();
                }, 1000);
            } else {
                vcRecaptchaService.reload(recaptchaInfo.widgetId);
            }
            recaptchaInfo.verify = true;
        })
    }
    // Проверка рекаптчи

}

export default {
    type: 'controller',
    name: 'payMethodControllerStr',
    value: payMethodControllerStr
};
