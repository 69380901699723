import angular from 'angular';

productionDetailController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'lodash',
    '$countOptionsShow',
    '$currencyOptionsShow',
    'resolveTimezone',
    '$permitsManager',
    "serviceToast",
    "lvMixpanel",
    '$orderDetails'
];

function productionDetailController(
    $scope,
    $state,
    $stateParams,
    lodash,
    $countOptionsShow,
    $currencyOptionsShow,
    resolveTimezone,
    $permitsManager,
    serviceToast,
    lvMixpanel,
    $orderDetails
) {
    var vm = this;
    $scope.stateFromList = $stateParams.stateToSave;
    $scope.tzOffset = resolveTimezone.timeZoneOffsetForReports();
    $scope.costAllowed = $permitsManager.costAllowed;
    vm.countOptionsShow = $countOptionsShow;
    vm.currencyOptionsShow = $currencyOptionsShow;

    vm.prodData = angular.copy($orderDetails);
    vm.details = vm.prodData.orderData;
    vm.items = vm.prodData.items;
    vm.goBack = function() {
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go( 'inventory.productions', {
                page:           0,
                limit:          10} );
        } else {
            $state.go( 'inventory.productions', {
                page:           $scope.stateFromList.page,
                limit:          $scope.stateFromList.limit,
                outletId:       $scope.stateFromList.outletId,
                type:           $scope.stateFromList.type} );
        }
    };

    vm.duplicate = function( editProfile ){
        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if( $state.current.name === 'inventory.productiondetail' ) {
            //Triggered when users click on the “Duplicate button
            // in the “Production details” page
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Duplicate production"
            });
            $state.go('inventory.productionduplicate', {
                id: $stateParams.id
            })
        } else if ( $state.current.name === 'inventory.disassemblydetail' ) {
            $state.go('inventory.disassemblyduplicate', {
                id: $stateParams.id
            })
        }
    };


    //Triggered when users click on the “Save as PDF” button in
    // the “Production details” page
    vm.saveProductionAsPDFMixpanel = function () {
        if( $state.current.name === 'inventory.productiondetail' ) {
            lvMixpanel.track('Export', {
                "Property": "Inventory management/Save production as PDF"
            });
        } else if ( $state.current.name === 'inventory.disassemblydetail' ) {
            lvMixpanel.track('Export', {
                "Property": "Inventory management/Save disassembly as PDF"
            });
        }
    }
    // Triggered when users click on the “Save as CSV”
    // button in the “Production details” page
    vm.saveProductionAsCSVMixpanel = function () {

        if( $state.current.name === 'inventory.productiondetail' ) {
            lvMixpanel.track('Export', {
                "Property": "Inventory management/Save production as CSV"
            });
        } else if ( $state.current.name === 'inventory.disassemblydetail' ) {
            lvMixpanel.track('Export', {
                "Property": "Inventory management/Save disassembly as CSV"
            });
        }
    }


    vm.printLabelsProduction = function () {
        if ($state.current.name !== 'inventory.productiondetail' && $state.current.name !== 'inventory.disassemblydetail') {
            return
        }

        let stateParams;

        if ($state.current.name === 'inventory.productiondetail') {
            stateParams = {orderId: $stateParams.id, orderType: "PRODUCTION"};

            // Triggered when users visit the “Create labels” page by clicking
            // on the “Print labels” button in the “Production details” page
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Create labels for production"
            });
        }

        if ($state.current.name === 'inventory.disassemblydetail') {
            stateParams = {orderId: $stateParams.id, orderType: "DISASSEMBLY"};

            // Triggered when users visit the “Create labels” page by clicking
            // on the “Print labels” button in the “Disassembly details” page.
            lvMixpanel.track('Back office page visit', {
                "Property": "Inventory management/Create labels for disassembly"
            });
        }

        $state.go('inventory.printlabels', stateParams);
    };
}

export default {
    type: 'controller',
    name: 'productionDetailController',
    value: productionDetailController
};
