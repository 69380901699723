import angular from 'angular';
const imgMap = buildMap(require.context('../../img/app-buttons', false, /\.png$/));

function buildMap(ctx) {
	const result = {};
	ctx.keys().forEach(key => {
		const start = key.lastIndexOf('-');
		const end = key.lastIndexOf('.png');
		const newKey = key.substring(start + 1, end);
		result[newKey] = ctx(key);
	});
	return result;
}

homeControl.$inject = ['$scope', '$stateParams', '$state', 'factoryProfile', 'getPermits', 'serviceMenu', 'serviceToast',
	'$timeout', 'promiseOperation', 'filtersData', 'serviceDialog', '$profile', '$filter', 'constantAppImages',
	'setChartPeriod', '$rootScope', 'lvMixpanel', '$translate', '$staffInfo', 'getEmployees', 'lvFeatureSettingsUtils'];
function homeControl($scope, $stateParams, $state, factoryProfile, getPermits, serviceMenu, serviceToast,
					 $timeout, promiseOperation, filtersData, serviceDialog, $profile, $filter, constantAppImages,
					 setChartPeriod, $rootScope, lvMixpanel, $translate, $staffInfo, getEmployees, lvFeatureSettingsUtils) {

	const langs = angular.copy(constantAppImages);
	const cabinetLang = $profile.cabinetLang;
	let backgroundImage;
	let homePage, attr = 'home';
	const permitsPromise = getPermits.getPermitsList( {} );
	const firstLogin = $profile.cabLoginCount === 0 && $profile.appType === "CABINET";
	permitsPromise
		.then((res) => {
			// showGreetingDialog();
			serviceMenu.setAccess(res);
			homePage = firstLogin ? 'goods.price' : serviceMenu.setDefaultPages($profile);
			return promiseOperation.getPromiseDataFromQ(homePage, attr);
		})
		.then((res) => {
			const stateParams = getDefaultStateParams($profile.limitedDefaultRequests, $rootScope.startWeek);
			return $state.go(res, stateParams);
		})
		.then(() => {
			// Dialog with App Store and Google Play is shown only for users registered from backoffice
			if (firstLogin) {
				showGreetingDialog();
			}
		});

	function showGreetingDialog() {
		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-after-registration.html',
			backgroundImage: loadImage(),
			appstoreUtm: cabinetLang === "eng" ? "?pt=117811880&ct=Back_Office&mt=8" : "",
			trackButtonsMixpanel: trackButtonsMixpanel,
			buttons: [
				{
					action: function () {
						lvMixpanel.track("Onboarding dialog activity", {"Event type": "Click next button on the onboarding dialog"});
						showFeatureSettingsDialog();
					}
				}
			]
		})
	}

	function showFeatureSettingsDialog() {
		permitsPromise.then(permissions => {

			let featuresModel = lvFeatureSettingsUtils.buildSettingModel($profile);

			serviceDialog.add({
				templateUrl: 'dashboard-page/templates/dialog/dialog-feature-settings.html',
				model: featuresModel,
				profile: angular.copy($profile),
				options: {
					cabinetLang: $profile.cabinetLang,
					permitsForFiscalPrinters: permissions.includes('ACCESS_FISCAL'),
					permitsForTimeTracking: permissions.includes('ACCESS_MERCHANTS'),
					specialWeightIcon: $profile.country === "us" || $profile.country === "lr" || $profile.country === "mm"
				},
				buttons: [
					{
						action: function () {},
						text: 'ON_CANCEL'
					},
					{
						action: dialogFeatureSettingsSave,
						// action: showGreetingDialog,
						class: $scope.arapro ? "arabic-save" : "",
						text: 'ON_SAVE'
					}
				]
			});
		});
	}

	function dialogFeatureSettingsSave(model) {
		let newProfile = angular.extend({}, $profile, model);

		// TIme card switched on
		if(!$profile.timeCardEnabled && newProfile.timeCardEnabled) {
			showPinDialog(newProfile);
		}
		else {
			saveProfile(newProfile);
		}
	}

	function showPinDialog(newProfile) {
		let newPin = {value: ['','','','']};
		serviceDialog.add({
			templateUrl: 'dashboard-page/templates/dialog/dialog-pin.html',
			title: $translate.instant('EMPLOYEE.SET_PIN'),
			content: $translate.instant('EMPLOYEE.SET_PIN_CLOCK'),
			pin: newPin,
			buttons: [
				{
					text: 'ON_CANCEL',
					action: function () {
						newProfile.timeCardEnabled = false;
						saveProfile(newProfile);
					}
				},
				{
					class: 'primary',
					text: 'CONTINUE',
					action: function () {
						savePin(newProfile, newPin);
					}
				}
			]
		});
	}

	function savePin(newProfile, pin) {
		let owner = $staffInfo.merchants.find(person => person.roleType === 'OWNER');
		if (owner) {
			const params = {
				pin: pin.value.join('')
			};
			getEmployees.setOwnerPin(params).then(res => {
				if (res.result === "ok") {
					serviceToast.show('EMPLOYEE.PIN_SAVED', 'success');

					if (!$profile.fiscalEnable && newProfile.fiscalEnable && !newProfile.shiftsEnabled) {
						newProfile.shiftsEnabled = true;
					}

					saveProfile(newProfile);
				}
			});
		}
	}

	function saveProfile(newProfile) {
		const prevSettingsValue = lvFeatureSettingsUtils.buildSettingModel($profile);
		factoryProfile.setProfile(newProfile)
			.then(res => {
				if(res.result === 'ok') {
					lvFeatureSettingsUtils.trackFeatureSettingsEdit(prevSettingsValue, newProfile, true);
					getPermits.getPermitsList({}).then(permits => {
						serviceMenu.setAccess(permits);
					});
					serviceToast.show('SETTING_PROFILE_CHANGE_SUCCESS', 'success');
					serviceMenu.showCashBox(newProfile.shiftsEnabled);
					serviceMenu.showTimeTracking(newProfile.timeCardEnabled);
					serviceMenu.showOpenReceipts(newProfile);
					serviceMenu.showKitchenPrinyer(newProfile);
					serviceMenu.showDiningOptins(newProfile);
					serviceMenu.helpCenterLang(newProfile);
					serviceMenu.isSupportAvailable(newProfile);
				}
			});
	}

	const loadImage = () => {
		for (let i = 0, length = langs.length; i < length; i++) {
			if (langs[i] === cabinetLang) {
				backgroundImage = imgMap[cabinetLang];
				break;
			} else if(i === length - 1 && langs[i] !== cabinetLang) {
				backgroundImage = imgMap['eng'];
			}
		}
		return backgroundImage;
	}

	/**
	 * Track Google Play and App Store buttons in Mixpanel
	 */
	const trackButtonsMixpanel = (store) => {
		lvMixpanel.track('Onboarding dialog activity', {
			'Event type': 'Click download LPOS app button on the onboarding dialog',
			'Property': store === "GOOGLE" ? 'Loyverse POS Google Play' : 'Loyverse POS App Store'
		});
	}

	/**
	 * State params by default
	 */
	const getDefaultStateParams = (limitedDefaultRequests, startWeek) => (
		{
			periodName: limitedDefaultRequests ? "lastSeven" : "lastThirty",
			periodLength: limitedDefaultRequests ? "7d" : "30d",
			page: 0,
			group: "day",
			serverChartType: "saleSum",
			from: null,
			to: null,
			fromHour: null,
			toHour: null,
			outletsIds: 'all',
			merchantsIds: 'all',
			outletId: 'all',
			merchId: 'all',
			limit: 10,
			offset: 0,
			startWeek
		})

	const stateParams = getDefaultStateParams($profile.limitedDefaultRequests, $rootScope.startWeek);
	filtersData.setStateParams(stateParams);
}

export default {
	type: 'controller',
	name: 'homeControl',
	value: homeControl
};
