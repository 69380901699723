/**
 * Created by Kiskenbassker on 23.01.18
 * Any matches to http://plnkr.co/edit/iodg4SqKBXL0V6ZJmRue are purely coincidental.
 * Requires currency-localization attribute.
 */

import angular from 'angular';

inputAutosizeDirective.$inject = ['$mdUtil', '$parse'];

function inputAutosizeDirective($mdUtil, $parse) {
    return {
        require: ['ngModel', '^?lvTableView'],
        restrict: 'A',
        link: function (scope, element, attrs, ctrl) {
            let ngModel = ctrl[0];
            let updateColumnWidth = false;
            const tableView = ctrl[1];
            let columnIndex;
            if (tableView) {
                if (attrs.columnIndex) {
                    columnIndex = parseInt(attrs.columnIndex);
                    updateColumnWidth = !isNaN(columnIndex);
                }
            }
            let span, resize, locObj, valueEdited;

            span = angular.element('<span></span>');
            span.css('display', 'none')
                .css('visibility', 'hidden')
                .css('white-space', 'nowrap')
                .css('width', 'auto');

            element.parent().append(span);

            resize = function (value) {

                function proceedValue(value, locObj) {
                    let valueSplitted = value.split(".");
                    let valueWithoutDecimal = value.includes(".") ? valueSplitted[0] : value;
                    let length = valueWithoutDecimal.length;
                    let valueReversed = valueWithoutDecimal.split("").reverse().join("");
                    let valueEdited = "";

                    for (let i = 0; i < length; i++) {
                        valueEdited = valueReversed[i] + valueEdited;
                        if ((i + 1) % locObj.digitalGroupSpacing === 0 && i !== length - 1) {
                            valueEdited += locObj.digitGroupSeparator !== " " ? locObj.digitGroupSeparator : ".";
                        }
                    }

                    if (locObj.decimalPlacesOverride !== 0 && valueSplitted[1]) {
                        valueEdited += (locObj.decimalCharacter + valueSplitted[1])
                    }

                    if (locObj.currencySymbol) {
                        if (locObj.currencySymbolWithSpace) {
                            valueEdited += ` ${locObj.currencySymbol}`;
                        } else {
                            valueEdited += locObj.currencySymbol;
                        }
                    }

                    return valueEdited;
                }

                if(value) {
                    let style = getComputedStyle(element[0]);
                    span.css('font', style.font)
                        .css('letter-spacing', style.letterSpacing)
                        .css('word-spacing', style.wordSpacing)
                        .css('padding', style.padding);

                    if (value != null && value.length === 0) {
                        value = element.attr('placeholder') || '';
                    }

                    locObj = $parse(attrs.currencyLocalization)(scope);

                    valueEdited = proceedValue(value, locObj);

                    span.text(valueEdited);
                    span.css('display', '');

                    try {
                        const newWidth = span.prop('offsetWidth');
                        element.css('width', newWidth + 'px');
                        if (updateColumnWidth) {
                            tableView.updateColumnWidth(columnIndex, newWidth);
                        }
                    }
                    finally {
                        span.css('display', 'none');
                    }
                }
            };

            ngModel.$parsers.unshift(function (value) {
                reset();
                $mdUtil.nextTick(function(){
                    resize(value);
                });
                return value;
            });

            ngModel.$formatters.unshift(function (value) {
                reset();
                $mdUtil.nextTick(function(){
                    resize(value);
                });
                return value;
            });

            function reset() {
                element.css('width', '');
            }
        }
    };
}

export default {
    type: 'directive',
    name: 'inputAutosize',
    value: inputAutosizeDirective
};
