/**
 * Created by A.Sirosh on 26.10.2015.
 * reload data or page  - use $state.go();
 * @memberof stateGo
 * @ngdoc servise
 * @name stateGo
 * @this
 */

import angular from 'angular';

stateGo.$inject = ['$state', '$stateParams'];
function stateGo($state, $stateParams) {

    let previousState;
    const stateDefaultParams = {
        'goods.price': {
            page: 0,
            limit: 10,
            inventory: 'all',
            checkFromList: []
        },
        'goods.categories': {
            page: 0,
            limit: 10,
            checkFromList: []
        },
        'goods.discountlist': {
            page: 0,
            limit: 10,
            checkFromList: []
        },
        'settings.kitchen': {},
        'employees.list': {
            page:           0,
            limit:          10,
            checkFromList:  []
        },
        'settings.tax': {},
        'settings.dining': {},
        'settings.paytypes': {},
        'clients.database': {
            page: 0,
            limit: 10
        },
        'inventory.transfer': {
            page: 0,
            limit: 10
        },
        'inventory.purchase': {
            page: 0,
            limit: 10
        },
    };

    function go(state, stateParams, flag) {
        state.go(state.current.name, stateParams, {
            reload: flag
        });
    }

    function giveCurrentStateAliasPrevious (params) {
        previousState = angular.copy(params);
        return previousState;
    }

    function getPreviousStateAlias () {
        return previousState;
    }

    function goToState(stateName, stateParams) {
        if (stateName === $state.current.name) {
            refresh();
        } else {
            let newStateParams = stateParams || stateDefaultParams[stateName] || {};
            $state.go(stateName, newStateParams);
        }
    }

    function refresh() {
        $state.go($state.current.name, $stateParams, {reload: true});
    }

    this.go = go;
    this.giveCurrentStateAliasPrevious = giveCurrentStateAliasPrevious;
    this.getPreviousStateAlias = getPreviousStateAlias;
    this.goToState = goToState;
    this.refresh = refresh;
}

export default {
    type: 'service',
    name: 'stateGo',
    value: stateGo
};
