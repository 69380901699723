import angular from "angular";


/**
 *
 */
serviceBillingSalesHistory.$inject = ['serviceDialog',
                                      'lodash',
                                      '$filter',
                                      'strGwConst',
                                      '$state',
                                      'serviceBillingCommonMethods',
                                      'serviceBillingDifferentPlaces',
                                      'factoryBilling',
                                      'serviceBillingError',
                                      'serviceToast',
                                      '$timeout',
                                      'lvMixpanel',
                                        'serviceBillingMixpanel'];

function serviceBillingSalesHistory(serviceDialog,
                                    lodash,
                                    $filter,
                                    strGwConst,
                                    $state,
                                    serviceBillingCommonMethods,
                                    serviceBillingDifferentPlaces,
                                    factoryBilling,
                                    serviceBillingError,
                                    serviceToast,
                                    $timeout,
                                    lvMixpanel,
                                    serviceBillingMixpanel) {

    let translate = $filter('translate');

    const emptyCoupon= {showInput:false, error:"", name:"", tempName:"", haveCoupon:false, code:null};
    const stripeGateways = strGwConst;

    let couponSalesHistory = angular.copy(emptyCoupon);

    let billingData = {};

    function setBillingData (data) {
        billingData = data;
    }

    // SALESHISTORY dialog, when TRIAL is started or continued =>=> there will be dialog to start or continue subscription
    function subscribeSalesHistory (source) {
        let msgTitle     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_SALESHISTORY_TRIAL_FREE_TITLE');
        let msgContent1  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_SALESHISTORY_TRIAL_FREE_TEXT_1');
        let msgContent2 =  null;
        let msgContent3  =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_SALESHISTORY_TRIAL_FREE_TEXT_2');
        let msgDate=  null;

        let terms = {
            checked: true
        };

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       msgContent2,
            content3:       msgContent3,
            date:           msgDate,
            priceOne:       lodash.find(billingData.plans.SALESHISTORY, { 'cycle': 1, 'unlim': false }),
            terms:          terms,
            buttons: [
                {   text:  'ON_CANCEL',
                    action: function () {
                        serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                            'cancel', billingData.subscriptions.SALESHISTORY.plan, 'SALESHISTORY',
                            billingData.subscriptions.SALESHISTORY.status, false,
                            billingData.paymentMethod, undefined, null, source);
                    }
                },
                {   text:  'START_FREE_TRIAL',
                    class: 'md-primary',
                    action: function () {
                        billingData.subscriptions.SALESHISTORY.plan.id = "SALESHISTORY";
                        createSubscribeActivate(billingData.subscriptions.SALESHISTORY.plan, false,
                            null, billingData.paymentMethod, billingData.subscriptions.SALESHISTORY.status, source);
                    }
                }
            ]
        });
    }
    // SALESHISTORY dialog, when TRIAL is started or continued =>=> there will be dialog to start or continue subscription


    // SALESHISTORY dialog, when subscription is activated in trial, or activated in trial for blocked account, or subscribing after trial
    // =>=> there will be window when tariff is chosen with further activation
    function subscribeActivateSalesHistory(source) {
        let createNewPlan;
        let couponCode;

        let tempCoupon      =  angular.copy(couponSalesHistory);
        let tempPlan        =  angular.copy(billingData.subscriptions.SALESHISTORY.plan);
        let msgTitle        =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_SALESHISTORY_TITLE');
        let msgContent1     =  translate('ACCOUNT.B2_SALESHISTORY_SUBSCRIBE_TEXT');
        let msgContent3     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER');
        let msgContent4     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_MONTH');
        let msgContent5     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_YEAR');
        let msgContent6     =  translate('ACCOUNT.B2_SERVICE_DIALOG.B2_STORES_PAY_PER_UNLIMITED');
        let msgSingle1      =  lodash.find(billingData.plans.SALESHISTORY, { 'cycle': 1, 'unlim': false });
        let msgSingle12     =  lodash.find(billingData.plans.SALESHISTORY, { 'cycle': 12, 'unlim': false });
        let msgUnlim1       =  lodash.find(billingData.plans.SALESHISTORY, { 'cycle': 1, 'unlim': true });
        let msgUnlim12      =  lodash.find(billingData.plans.SALESHISTORY, { 'cycle': 12, 'unlim': true });
        let msgNewPlan      =  billingData.subscriptions.SALESHISTORY.plan;
        let msgCouponDate   =  couponSalesHistory;

        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-subscribe-activate.html',
            title:          msgTitle,
            content1:       msgContent1,
            content2:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SELECT_YOUR_PLAN'),
            content3:       msgContent3,
            content4:       msgContent4,
            content5:       msgContent5,
            content6:       msgContent6,
            content7:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_MONTH'),
            content8:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_PAY_PER_YEAR'),
            single1:        msgSingle1,
            single12:       msgSingle12,
            unlim1:         msgUnlim1,
            unlim12:        msgUnlim12,
            newPlan:        msgNewPlan,
            paymentType:    billingData.paymentMethod,
            inEU:           billingData.taxMessage,
            couponDate:     msgCouponDate,
            buttons: [
                {   text:  'ON_CANCEL',
                    action: function () {
                        cancelSubscriptionDialog(tempPlan, tempCoupon);
                        serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                            'cancel', msgNewPlan, 'SALESHISTORY', billingData.subscriptions.SALESHISTORY.status,
                            false, billingData.paymentMethod, undefined, null, source);

                    }
                },
                {   text:   (billingData.paymentMethod === null)? 'ACCOUNT.CONTINUE' : 'SUBSCRIBE',
                    action: function () {
                        billingData.subscriptions.SALESHISTORY.plan = lodash.find(billingData.plans.SALESHISTORY, { 'id': billingData.subscriptions.SALESHISTORY.plan.id });
                        createNewPlan = billingData.subscriptions.SALESHISTORY.plan;
                        (couponSalesHistory.haveCoupon === true && couponSalesHistory.code !== null)? couponCode = couponSalesHistory.code : couponCode = null;

                        if (billingData.paymentMethod === null) {
                            let urlWithHash = window.location.href;
                            if (billingData.gateway === "bt")  { $state.go( 'addPayMethodBt',  {  addSubscribe : createNewPlan,
                                typeSubscriptions   : "SALESHISTORY",
                                urlForReload        : urlWithHash,
                                activate            : true,
                                coupon              : couponCode,
                                urlForSuccessReload : $state.current.name,
                                source              : source}) }
                            if (stripeGateways.includes(billingData.gateway)) { $state.go( 'addPayMethodStr', {  addSubscribe : createNewPlan,
                                typeSubscriptions   : "SALESHISTORY",
                                urlForReload        : urlWithHash,
                                activate            : true,
                                coupon              : couponCode,
                                urlForSuccessReload : $state.current.name,
                                source              : source}) }
                            if (billingData.gateway === "bs")  {
                                serviceBillingCommonMethods.getChargebeeHostedPageForAdd(billingData.ownerId, createNewPlan.id, couponCode, cancelSubscriptionDialog.bind(null, tempPlan, tempCoupon), null, null);
                            }
                        }
                        else {
                            showEstimatedInvoice(createNewPlan, true, couponCode, billingData.paymentMethod, tempPlan, tempCoupon, source);
                        }
                    }
                },
                {   text:           'APPLY_APPLY',
                    closeOnAction:  false,
                    action:         function () {
                        serviceBillingDifferentPlaces.addCoupon(billingData.subscriptions.SALESHISTORY.plan, couponSalesHistory, couponSalesHistory.name);
                    }
                }]
        });
    }
    // SALESHISTORY dialog, when subscription is activated in trial, or activated in trial for blocked account, or subscribing after trial
    // =>=> there will be window when tariff is chosen with further activation


    // check if plan selection allowed
    function checkIfPlanAllowed (source) {
        serviceBillingDifferentPlaces.checkIfPlanAllowed(billingData.paymentMethod, function() {
            subscribeActivateSalesHistory(source)
        });
    }
    // check if plan selection allowed


    // activate subscription
    function createSubscribeActivate(plan, activate, couponCode, paymentType, status, source) {
        let data = {
            planId: plan.id,
            activate: activate,
            coupon: couponCode};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            serviceBillingMixpanel.setMixpanelBillingCreateActivateInComp(
                res.result, plan, 'SALESHISTORY', status, activate,
                paymentType, null, null, source);
            if (res.result === "ok") {
                if (activate) {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_SUBSCRIPTION_ACTIVATED', 'success');
                } else {
                    serviceToast.show('ACCOUNT.B2_TOSTS.B2_TRIAL_STARTED', 'success');
                }

                $timeout(function () {
                    window.location.reload(true);
                }, 1000);
            }
            else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            }
            else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // activate subscription


    // cancel subscription dialog
    function cancelSubscriptionDialog(plan, coupon) {
        couponSalesHistory = coupon;
        billingData.subscriptions.SALESHISTORY.plan = plan;
    }
    // cancel subscription dialog


    // show estimated invoice dialog
    function showEstimatedInvoice(plan, activate, couponCode, paymentType, tempPlan, tempCoupon, source) {
        let data = {
            planId: plan.id,
            activate: activate,
            coupon: couponCode,
            estimate: true};

        factoryBilling.setCreateSubscribeActivate(data).then(function (res) {
            if(res.result === "ok") {
                serviceBillingDifferentPlaces.showEstimatedInvoiceDialog(res.data, createSubscribeActivate.bind(
                    null, plan, activate, couponCode, paymentType, billingData.subscriptions.SALESHISTORY.status, source),
                    cancelEstimatedInvoice.bind(undefined, tempPlan, tempCoupon));
            } else if (res.result === "error") {
                serviceBillingError.knownError(res.message);
            } else {
                serviceBillingError.unKnownError();
            }
        });
    }
    // show estimated invoice dialog


    // cancel estimated invoice dialog
    function cancelEstimatedInvoice(tempPlan, tempCoupon) {
        cancelSubscriptionDialog(tempPlan, tempCoupon);
        subscribeActivateSalesHistory();
    }
    // cancel estimated invoice dialog


    this.subscribeSalesHistory = subscribeSalesHistory;
    this.checkIfPlanAllowed = checkIfPlanAllowed;
    this.setBillingData = setBillingData;
}

export default serviceBillingSalesHistory;
