dateAfterValidator.$inject = ['$parse', 'dateCustomMethods'];

function dateAfterValidator($parse, dateCustomMethods) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
            modelCtrl.$validators.dateAfter = val => {
                const dateValue = dateCustomMethods.fromFormattedStringToDateObject(val);
                if (isNaN(dateValue.getTime())) {
                    return true;
                }
                const minDateString = $parse(attrs['dateAfter'])(scope);
                if (!minDateString) {
                    return true;
                }
                const minDateValue = dateCustomMethods.fromFormattedStringToDateObject(minDateString);
                if (isNaN(minDateValue.getTime())) {
                    return true;
                }
                return dateValue >= minDateValue;
            };
        }
    };
}

export default dateAfterValidator;
