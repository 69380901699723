const isDev = process.env.NODE_ENV === 'development';
const envUrl = isDev ? require('../../../../env.json') : '/env.json';

factoryEnvironment.$inject = [
    '$q',
    '$http'
];

function factoryEnvironment($q, $http) {

    let data = { _load: false }
    let promise;


    function getEnvPromise() {
        return $q(function (resolve, reject) {
            $http.get(envUrl).then(function (res) {
                var d = res.data;

                if (angular.isObject(d)) {
                    for (let key in d) {
                        data[key] = d[key];
                    }
                    data._load = true;

                    resolve(d);
                    promise = null;
                }
            }, function (err) {
                reject(err);
            });
        });
    }


    function getEnv(reload) {
        if (reload) {
            data._load = false;
        }
        if (data._load) {
            promise = (!promise || promise.$$state.status === 1) ? $q(function (resolve) {
                resolve(data);
            }) : promise;
        } else {
            promise = (!promise || promise.$$state.status === 1) ? getEnvPromise() : promise;
        }
        return promise;
    }


    return {
        getEnv: getEnv
    }

}

export default factoryEnvironment;