import angular from 'angular';

createClass.$inject = ['TableViewColumnBase'];

function createClass(TableViewColumnBase) {
    return class TableViewColumnInput extends TableViewColumnBase {
        constructor(title, modelValue, options) {
            super(title, options);
            this.modelValue = modelValue;
        }

        getElement() {
            let resultElement;

            const inputContainer = angular.element('<md-input-container>');
            inputContainer.addClass('material-input');
            inputContainer.addClass('width-100');
            inputContainer.addClass('text-right');

            const inputElement = super.getElement();

            inputContainer.append(inputElement);

            if (this.options.extraField) {
                const extraField = angular.element('<span>');
                if (this.options.extraField.class) {
                    extraField.addClass(this.options.extraField.class);
                }
                if (this.options.extraField.isVisible) {
                    extraField.attr('ng-if', 'ctrl.column.options.extraField.isVisible(ctrl.item)');
                }
                extraField.text(this.options.extraField.value);

                resultElement = angular.element('<div>');
                resultElement.append(inputContainer);
                resultElement.append(extraField);
            } else {
                resultElement = inputContainer;
            }
            resultElement.addClass('width-100');

            return resultElement;
        }

        getBaseElement() {
            return angular.element('<input>');
        }

        addClass(element) {
            super.addClass(element);
            element.addClass('text-right');
        }

        setAttrs(element) {
            super.setAttrs(element);
            element.attr('type', 'text');
            if (angular.isFunction(this.modelValue)) {
                element.attr('ng-model', 'ctrl.column.modelValue(ctrl.item)');
            } else if (angular.isString(this.modelValue)) {
                element.attr('ng-model', 'ctrl.item.' + this.modelValue);
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'TableViewColumnInput',
    value: createClass
};