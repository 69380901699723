import angular from 'angular';

config.$inject = ['$httpProvider', '$qProvider'];
function config($httpProvider, $qProvider) {

	$qProvider.errorOnUnhandledRejections(false);

	//Http Intercpetor to check auth failures for xhr requests



	$httpProvider.defaults.transformResponse = function ( data ) {
		var d;
		if ( angular.isString( data ) ) {
			try {
				d = JSON.parse( data )
			} catch ( err ) {
				d = data
			}
		} else {
			d = data
		}
		return d;
	};
	$httpProvider.interceptors.push('httpResponseInterceptor');
	$httpProvider.interceptors.push('httpRequestInterceptor');
}

httpResponseInterceptor.$inject = ['$q', '$location', '$rootScope', 'unLoader', 'permissionObserver'];
function httpResponseInterceptor($q, $location, $rootScope, unLoader, permissionObserver) {

	return {
		response: function (response) {
			unLoader.onEnd();
			if (response.status === 401) {
				console.log("Response 401");
			}
			return response || $q.when(response);
		},
		responseError: function (rejection) {
			unLoader.onEnd();

			/**
			 * выбрасывает на главную страницу при статус.коде 403
			 */

			if (rejection.status === 403) {
				 window.location.href = '/';
			}

			/**
			 * выбрасывает на главную страницу при статус.коде 423 нет прав для закладки
			 */
			if(rejection.status === 423){

				const logout = rejection.config.url === "/data/ownercab/getprofile" || rejection.config.url === "/data/ownercab/getaccpermissions";
				if (logout) {
					$rootScope.$broadcast('logoutRequired');
				}
				else {
					permissionObserver.updateRequired.notify();
					window.location.href = '#/home';
				}
			}

			return $q.reject(rejection);
		}
	}
}

httpRequestInterceptor.$inject = ['unLoader'];
function httpRequestInterceptor(unLoader) {
	return {
		request: function ( config ) {
			//console.log("httpRequestInterceptor +++");
			unLoader.onStart();
			function refactBase64( str64 ) {
				if ( /data:image.+$/.test( str64 ) ) {
					var str = str64.replace( /^data.+,/, "" );
					str64 = str;
				}
				return str64
			}

			function findImage( obj ) {
				function bust( _obj ) {
					for ( var opt in _obj ) {
						if ( typeof _obj[opt] == 'object' ) {
							bust( _obj[opt] )
						} else if ( opt == 'image' ) {
							_obj[opt] = refactBase64( _obj[opt] )
						}
					}
				}
				bust( obj );
			}
			findImage(config.data);

			return config;
		}
	};
}

unLoader.$inject = ['$timeout'];
function unLoader($timeout) {

	var scope = this;
	scope.isDoing = false;
	var c = 0;

	var timeOut;

	function onStart(){
		c++;
		if(!scope.isDoing){
			/**
			 * запрос пошел
			 * @type {boolean}
			 */
			scope.isDoing = true;
		}
	}
	function onEnd(){
		c--;
		if(c<=0){
			/**
			 * запрос закончен
			 * @type {boolean}
			 */
			onStop();
		}
	}

	function onStop(){
		timeOut && ($timeout.cancel(timeOut));
		timeOut = $timeout(function (){
			if(scope.isDoing){
				scope.isDoing = false;
			}

		}, 10);
	}

	this.onStart = onStart;
	this.onEnd= onEnd;

}

export default [
	{
		type: 'config',
		value: config
	},
	{
		type: 'factory',
		name: 'httpResponseInterceptor',
		value: httpResponseInterceptor
	},
	{
		type: 'factory',
		name: 'httpRequestInterceptor',
		value: httpRequestInterceptor
	},
	{
		type: 'service',
		name: 'unLoader',
		value: unLoader
	}
];