/**
 * Created by mars on 12/25/15.
 */

const langCode = {
	bih: "bh",
	hun: "hu",
	zho: "zh",
	chn: "zh",
	heb: "iw",
	fij: "fj",
	fin: "fi",
	hmo: "ho",
	mol: "mo",
	tuk: "tk",
	bak: "ba",
	bis: "bi",
	mon: "mn",
	oss: "os",
	bam: "bm",
	pus: "ps",
	tur: "tr",
	vie: "vi",
	bre: "br",
	fra: "fr",
	her: "hz",
	oci: "oc",
	deu: "de",
	fao: "fo",
	ssw: "ss",
	fas: "fa",
	tel: "te",
	gla: "gd",
	yor: "yo",
	jav: "jv",
	gle: "ga",
	nav: "nv",
	glg: "gl",
	nau: "na",
	epo: "eo",
	ipk: "ik",
	fry: "fy",
	glv: "gv",
	slk: "sk",
	aym: "ay",
	mya: "my",
	kua: "kj",
	wln: "wa",
	nbl: "nr",
	slv: "sl",
	guj: "gu",
	twi: "tw",
	iii: "ii",
	kur: "ku",
	sme: "se",
	pol: "pl",
	aze: "az",
	sun: "su",
	ces: "cs",
	ara: "ar",
	por: "pt",
	ton: "to",
	uig: "ug",
	aar: "aa",
	smo: "sm",
	yid: "ji",
	mah: "mh",
	arg: "an",
	tgl: "tl",
	mal: "ml",
	tgk: "tg",
	uzb: "uz",
	mar: "mr",
	mri: "mi",
	urd: "ur",
	nld: "nl",
	sna: "sn",
	snd: "sd",
	abk: "ab",
	tha: "th",
	nde: "nd",
	hye: "hy",
	aka: "ak",
	ibo: "ig",
	bul: "bg",
	ndo: "ng",
	asm: "as",
	msa: "ms",
	ful: "ff",
	swa: "sw",
	xho: "xh",
	swe: "sv",
	isl: "is",
	cos: "co",
	est: "et",
	cor: "kw",
	mkd: "mk",
	bel: "be",
	kom: "kv",
	ben: "bn",
	run: "rn",
	kon: "kg",
	hin: "hi",
	kor: "ko",
	dan: "da",
	div: "dv",
	lin: "ln",
	som: "so",
	zul: "zu",
	lim: "li",
	rus: "ru",
	iku: "iu",
	wol: "wo",
	lit: "lt",
	ita: "it",
	dzo: "dz",
	nep: "ne",
	sot: "st",
	hat: "ht",
	tah: "ty",
	lao: "lo",
	pan: "pa",
	tir: "ti",
	cha: "ch",
	hau: "ha",
	ukr: "uk",
	lat: "la",
	ven: "ve",
	lav: "lv",
	che: "ce",
	tam: "ta",
	ile: "ie",
	ell: "el",
	spa: "es",
	tat: "tt",
	mlg: "mg",
	hrv: "hr",
	nno: "nn",
	chv: "cv",
	chu: "cu",
	khm: "km",
	mlt: "mt",
	ido: "io",
	bod: "bo",
	cym: "cy",
	amh: "am",
	nob: "nb",
	que: "qu",
	eus: "eu",
	bos: "bs",
	roh: "rm",
	sqi: "sq",
	kik: "ki",
	ava: "av",
	tsn: "tn",
	nor: "no",
	ron: "ro",
	ave: "ae",
	kin: "rw",
	tso: "ts",
	vol: "vo",
	kir: "ky",
	cat: "ca",
	cre: "cr",
	kal: "kl",
	sag: "sg",
	kan: "kn",
	sin: "si",
	kas: "ks",
	ina: "ia",
	ind: "in",
	kau: "kr",
	eng: "en",
	kat: "ka",
	san: "sa",
	grn: "gn",
	srd: "sc",
	kaz: "kk",
	ori: "or",
	jpn: "ja",
	orm: "om",
	afr: "af",
	pli: "pi",
	oji: "oj",
	srp: "sr",
	ewe: "ee",
	zha: "za",
	ltz: "lb",
	nya: "ny",
	lub: "lu",
	lug: "lg"
};

export default {
	type: 'constant',
	name: 'langCode',
	value: langCode
};