/**
 * Created by Alex on 10.04.17.
 */

const lodash = window._;

export default {
    type: 'constant',
    name: 'lodash',
    value: lodash
};