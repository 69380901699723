/**
 * Created by mars on 12/13/15.
 */

import angular from 'angular';

purchaseListControl.$inject = [
    '$stateParams',
    'serviceToast',
    'factoryPermits',
    '$shortOutlets',
    '$scope',
    '$filter',
    '$currencyOptionsShow',
    '$critCountOptionsEdit',
    '$statusConst',
    '$purchaseList',
    '$state',
    'siteLangCode',
    '$profile',
    '$shortSuppliers',
    '$countOptionsShow',
    '$translate',
    'lvMixpanel',
    '$permitsManager'
];

function purchaseListControl(
    $stateParams,
    serviceToast,
    factoryPermits,
    $shortOutlets,
    $scope,
    $filter,
    $currencyOptionsShow,
    $critCountOptionsEdit,
    $statusConst,
    $purchaseList,
    $state,
    siteLangCode,
    $profile,
    $shortSuppliers,
    $countOptionsShow,
    $translate,
    lvMixpanel,
    $permitsManager
) {
    var vm = this,

    translate = $filter('translate');
    // vm.searchFunction = searchFunction;
    $scope.displayCost = $permitsManager.costAllowed;
    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.shortSuppliers = $shortSuppliers.slice();
    $scope.shortSuppliers.unshift({
        id: null,
        name: $translate.instant('INVENTORY.ALL_SUPPLIERS')
    });
    // console.log("$scope.shortOutlets = ", $scope.shortOutlets);
    $scope.selectOutlet = {id: ($stateParams.outletId && $stateParams.outletId !== 'all') ? +$stateParams.outletId : null};
    $scope.selectOutletId = $scope.selectOutlet.id;
    $scope.selectSuppliers = {id: $stateParams.supplier ? +$stateParams.supplier : null};
    $scope.selectSuppliersId = $scope.selectSuppliers.id;
    // console.log("$scope.selectSuppliers = ", $scope.selectSuppliers);
    $scope.currencyOptionsShow = $currencyOptionsShow;
    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    $scope.countOptionsShow = $countOptionsShow;

    $scope.selectStatuses = {code: $stateParams.status || null};
    $scope.constStatuses = $statusConst.status.map(val => {
        return {
            code: val.code,
            name: $translate.instant('INVENTORY.' + val.code)
        }
    });
    $scope.constStatuses.unshift({
        code: null,
        name: $translate.instant('INVENTORY.STATUS_OLL')
    });

    $scope.selectStatusesCode = $scope.selectStatuses.code;
    // console.log("$scope.constStatuses = ", $scope.constStatuses);
    $scope.editTableState = 'inventory.orederdetail';


    //Task # 19957 Change stubs on Advanced inventory screens,
    vm.plugsFilters = !!(!$scope.selectStatusesCode && !$scope.selectSuppliersId && !$scope.selectOutletId);
    vm.plugsSearch = !!$stateParams.search;
    //Plugs for extended inventory accounting Purchase Orders
    $scope.profile = angular.copy( $profile );
    var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];
    var plugPurchaseOrder = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];
    $scope.plugPurchaseOrderHelpLink = plugPurchaseOrder.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders"
        : "https://help.loyverse.com/help/how-purchase-orders-and-suppliers?utm_source=Back%20Office&utm_medium=Purchase%20orders";

    $scope.trackHelpPurchaiseOrders = function() {
        lvMixpanel.track('Help', {"Property": "Purchase orders"});
    };
    // данные для сохранение состояния фильтров
    $scope.stateFromList = $state.params;
    // console.log("stateParams = ", $stateParams);

    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            // console.log("$scope.selectOutlet.id = ", $scope.selectOutlet.id);
            $state.go('inventory.purchase', {outletId: newval.id || null, page:0}).then(function() {
                $scope.selectOutletId = newval.id;
            });
        }
    }, true);


    $scope.$watch('selectSuppliers', function(newval, oldval){
        if (oldval != newval) {
            $state.go($state.current.name, {supplier: newval.id || null, page:0}).then(function() {
                $scope.selectSuppliersId = newval.id;
            });
        }
    }, true);

    $scope.$watch('selectStatuses', function(newval, oldval){
        if (oldval != newval) {
            $state.go($state.current.name, {status: newval.code, page:0});
        }
    }, true);


    $scope.purchaseList = angular.copy($purchaseList);
    //  $scope.purchaseList.orders = []; -- if need to check empty purchaseList
    for (let i = 0; i < $scope.purchaseList.orders.length; i++){
        $scope.purchaseList.orders[i].barValue = $scope.purchaseList.orders[i].receivedCurrent/$scope.purchaseList.orders[i].receivedTotal * 100;
        if($scope.purchaseList.orders[i].status === 'CANCELLED') {
            $scope.purchaseList.orders[i].status = 'CLOSED';
        }
    }

    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    $scope.pages = $scope.purchaseList.pages;
    if ($scope.purchaseList.orders.length === 0 && $scope.stateFromList.page > 0) {
        $state.go( 'inventory.purchase', {page:         $scope.pages - 1,
                                        outletId:       $scope.stateFromList.outletId,
                                        supplier:       $scope.stateFromList.supplier,
                                        status:         $scope.stateFromList.status} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

    $scope.onAdd = function() {
        $state.go('inventory.createorder', {stateToSave: $scope.stateFromList});
    }



// инлайн редактирование свойств товара в списке товаров

}

export default {
    type: 'controller',
    name: 'purchaseListControl',
    value: purchaseListControl
};
