/**
 * Created by A.Sirosh on 15.12.2015.
 */

import angular from 'angular';

categoriesListCtrl.$inject = [
	'$scope',
	'$stateParams',
	'factoryCategories',
	'$lvPager',
	'$state',
	'$filter',
	'serviceDialog',
	'serviceToast',
	'$categories',
	'siteLangCode',
	'$profile',
	'$countOptionsShow',
	'lvMixpanel',
	'dialogs',
	'constantColorsGoodsCollection'
];
function categoriesListCtrl( $scope,
							 $stateParams,
							 factoryCategories,
							 $lvPager,
							 $state,
							 $filter,
							 serviceDialog,
							 serviceToast,
							 $categories,
							 siteLangCode,
							 $profile,
							 $countOptionsShow,
							 lvMixpanel,
							 dialogs,
							 constantColorsGoodsCollection) {


	var translate = $filter( 'translate' );

	$scope.pages = factoryCategories.pager.pages;


	$scope.stateParams = $stateParams;
	$scope.pager = factoryCategories.pager;
	$lvPager.constructor( $scope, $stateParams.offset );


	$scope.categories = $categories;
	$scope.editTableState = 'goods.categoryedit';

	$scope.showBtnDel = false;


	/*** формат отображения колличества ***/
	$scope.countOptionsShow = $countOptionsShow;

	$scope.categoryColors = constantColorsGoodsCollection;

// данные для сохранение состояния фильтров и ЧЕКБОКСОВ
	$scope.stateFromList = $state.params;
	$scope.checkFromList = $stateParams.checkFromList;


	// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ СКИДОК ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
	if ($scope.categories.length == 0 && $scope.stateFromList.page > 0) {
		$state.go( 'goods.categories', {page: $scope.pages - 1} );
	}
	// ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ СКИДОК ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


	if ($scope.checkFromList.length == undefined || $scope.checkFromList.length == 0) {
		for (var i = 0; i < $scope.categories.length; i++) {
			$scope.categories[i]._select = false;
		}
	} else {
		for (var i = 0; i < $scope.checkFromList.length; i++) {

			for (var q = 0; q < $scope.categories.length; q++) {
				($scope.checkFromList[i] == $scope.categories[q].id)? $scope.categories[q]._select = true : "";
				(!$scope.categories[q]._select)? $scope.categories[q]._select = false: "";
			}
		}
	}
// данные для сохранение состояния фильтров и ЧЕКБОКСОВ


// КОД для сохранения массива ID выбранных СКИДОК
	$scope.checkCategoriesList = [];

	$scope.$watch('categories', function(newval, oldval){
		for (var k = 0; k < newval.length; k++) {
			if (newval[k]._select == true) {
				var idx = $scope.checkCategoriesList.indexOf(newval[k].id);
				if (idx > -1) {
					$scope.checkCategoriesList.splice(idx, 1);
				}
				$scope.checkCategoriesList.push(newval[k].id);
			} else {
				var idx = $scope.checkCategoriesList.indexOf(newval[k].id);
				if (idx > -1) {
				$scope.checkCategoriesList.splice(idx, 1);
				}
			}
		}
		($scope.checkCategoriesList.length == 0)? $scope.delete = false : $scope.showBtnDel = true;
		($scope.checkCategoriesList.length == $scope.categories.length)? $scope.checkerAll = true : $scope.checkerAll = false;

	}, true);
// КОД для сохранения массива ID выбранных СКИДОК



	var watchersCat;
	$scope.$watch( function () {
		return factoryCategories.loading.process
	}, function ( val ) {
		if ( val ) {
			watchersCat && watchersCat()
		} else {
			initWatchers( $categories )
		}
	} );


	function initWatchers( categories ) {
		watchersCat = $scope.$watchCollection( function () {
			var cats = [];
			for ( var i = 0; i < categories.length; i++ ) {
				cats.push( categories[i]._select )
			}
			return cats;
		}, function ( val ) {
			var i = 0;
			for ( i = 0; i < val.length; i++ ) {
				if ( !val[i] ) {
					$scope.checkerAll = false;
					break
				}
			}
			if ( chekSelectPresent( val ) ) {
				$scope.showBtnDel = true;
			} else {
				$scope.showBtnDel = false;
			}
		} );
	}

	function chekSelectPresent( selects ) {
		var i = 0;
		do {
			if ( selects[i] ) {
				return true
			}
		} while ( ++i < selects.length );
		return false
	}



	$scope.selectAll = function (editProfile) {
		//permission to edit profile, false for manager
		if ( !editProfile ) {
			return;
		}

		var i = $scope.categories.length;
		if ( $scope.checkerAll ) {
			$scope.checkerAll = false;
			while ( 0 < i-- ) {
				$scope.categories[i]._select = false;
			}
		} else {
			$scope.checkerAll = true;
			while ( 0 < i-- ) {
				$scope.categories[i]._select = true;
			}
		}
	};

	$scope.onDel = function () {

		var ids = [];
		for ( var i = 0; i < $scope.categories.length; i++ ) {
			if ( $scope.categories[i]._select ) {
				ids.push( $scope.categories[i].id );
			}
		}

		if ( ids.length ) {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'CONFIRM_OPERATION' ),
				content: (function () {
					if ( 1 < ids.length ) {
						return translate( 'CONFIRM_DEL_CATEGORIES' );
					} else {
						return translate( 'CONFIRM_DEL_CATEGORY' );
					}
				})(),
				buttons: [
					{
						text: 'ON_CANCEL'
					},
					{

						class: 'primary l-margin-left-8-i',
						text: 'ON_DEL',
						action: function () {
							factoryCategories
								.delCategory( ids )
								.then( function ( res ) {

										if (res.result === "ok") {
											serviceToast.show(ids.length > 1 ?
												'CATEGORIES_DELETED_SUCCESS' : 'CATEGORY_DELETED_SUCCESS',
												'success');
											refreshList();
										}


									}, function ( err ) {
										switch ( err.result ) {
											case 'category_deleted':
												dialogs.listItemDeletedEarlier().then(refreshList);
												break;
											default :
												serviceToast.show( 'ERROR_DEL_WARE', 'error' );
												console.log( err );
										}
									}
								);
						}
					}
				]
			} );


		} else {
			serviceDialog.add( {
				templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
				title: translate( 'ATTENTION' ),
				content: translate( 'EMPTY_CATEGORY_FOR_DEL' ),
				buttons: [
					{
						class: 'primary',
						text: 'ON_OK',
						action: function () {

						}
					}
				]
			} );
		}

	};

	$scope.onAdd = function () {
		$state.go( 'goods.categorycreate', {stateToSave: $scope.stateFromList, checkToSave: $scope.checkCategoriesList} );
	};


	//-- Task #25633 Update stubs for empty states
	emptyStateLink();
	$scope.emptyStateLink = emptyStateLink;

	function emptyStateLink() {
		let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
		// url lang where we have info
		let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "it", "pl", "gr", "zh"];
		//emptyStateCategory Learn more
		$scope.emptyStateLearnMoreCategory = urlLang.indexOf(siteUrlLang) !== -1
			? "https://help.loyverse.com/" + siteUrlLang + "/help/how-add-items-loyverse-back-office?utm_source=Back%20Office&utm_medium=Categories"
			: "https://help.loyverse.com/help/how-add-items-loyverse-back-office?utm_source=Back%20Office&utm_medium=Categories";
	}
	//-- END Task #25633 Update stubs for empty states
	//-- Task #25853 Add events to Mixpanel for transitions to help
	$scope.trackCategoriesHelp = function () {
		lvMixpanel.track('Help', {"Property": "Categories"});
	};
	//-- END Task #25853 Add events to Mixpanel for transitions to help

	function refreshList() {
		$state.go('goods.categories', {	page: 			$scope.stateFromList.page,
			limit: 			$scope.stateFromList.limit,
			checkFromList: 	[]}, {
			reload: true
		});
	}

}

export default {
	type: 'controller',
	name: 'categoriesListCtrl',
	value: categoriesListCtrl
};
