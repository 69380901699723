dialogScope.$inject = ['$rootScope'];

function dialogScope($rootScope) {
    return {
        restrict: 'A',
        link: function ( scope ) {

            //just in order to pass a form from a scope of serviceDialod and manipulate it within a scope of a current controller of our module "app"
            $rootScope.$broadcast('dialogScopeData', scope);
            //todo роверить ли корректно работает. Если нет - вернуть нижний коментарий
            angular.forEach(scope.$parent.dialog, function(dialog, opt){
                scope[opt] = dialog;
            });
            /*for ( var opt in scope.$parent.dialog ) {
                scope[opt] = scope.$parent.dialog[opt]
            }*/
        }
    };
}

export default dialogScope;