labelTemplate.$inject = ['$templateCache', 'calendarSettings', 'serviceDaysWeek'];
function labelTemplate($templateCache, calendarSettings, serviceDaysWeek) {
    return {
        restrict: 'C',
        // template: calendarSettings.config.labelTemplate || $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        template: calendarSettings.config.labelTemplate // TODO: Oleksii: no template $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        // template: $templateCache.get('../module/lv-calendar/templates/calendar-label-template.html')
    }
}

labelTemplateForDay.$inject = ['$templateCache', 'calendarSettings'];
function labelTemplateForDay($templateCache, calendarSettings) {
    return {
        restrict: 'C',
        // template: calendarSettings.config.labelTemplateForDay || $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        template: calendarSettings.config.labelTemplateForDay // TODO: Oleksii: no template $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        // template: $templateCache.get('../module/lv-calendar/templates/calendar-label-template.html')
    }
}

labelTemplateForMonth.$inject = ['$templateCache', 'calendarSettings'];
function labelTemplateForMonth($templateCache, calendarSettings) {
    return {
        restrict: 'C',
        // template: calendarSettings.config.labelTemplateForMonth || $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        template: calendarSettings.config.labelTemplateForMonth // TODO: Oleksii: no template $templateCache.get('../module/calendar/module/partials/calendar-label-template.html')
        // template: $templateCache.get('../module/lv-calendar/templates/calendar-label-template.html')
    }
}

labelTemplateExact.$inject = ['$templateCache', 'calendarSettings'];
function labelTemplateExact($templateCache, calendarSettings) {
    return {
        restrict: 'C',
        // template: $templateCache.get('../module/lv-calendar/templates/calendar-label-template-exact.html')
        // TODO: Oleksii; use template instead of $templateCache
        template: calendarSettings.config.labelTemplateExact
    }
}

export default {
    labelTemplate,
    labelTemplateForDay,
    labelTemplateForMonth,
    labelTemplateExact
}