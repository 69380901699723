/**
 * Created by mars on 11/2/15.
 */

// TODO: Oleksii: retest

import './css/img-maintenance.scss';

import angular from 'angular';
import fileReader from '../file-reader/file-reader';
import lvDialog from '../lv-dialog/lv-dialog';
import serviceCropper from './service/service-cropper';
import cropperControl from './controller/cropper-control';
import imgCropper from './directive/directive-cropper';
import cropperToCenter from './directive/directive-cropper-to-center';
import imgDelete from './directive/directive-img-delete';
import imgSrc from './directive/directive-img-src';
import containerImgLoad from './directive-container-img-load';

export default angular
    .module('imgMaintenance', [fileReader, lvDialog] )
    .service('serviceCropper', serviceCropper)
    .controller('cropperControl', cropperControl)
    .directive('imgCropper', imgCropper)
    .directive('cropperToCenter', cropperToCenter)
    .directive('imgDelete', imgDelete)
    .directive('imgSrc', imgSrc)
    .directive('containerImgLoad', containerImgLoad)
    .name;






