import angular from 'angular';

supplierCreateEdit.$inject = [
    "lvCountriesMultiLang",
    "$scope",
    "$state",
    "$stateParams",
    "lodash",
    "factorySuppliers",
    "serviceToast",
    "serviceDialog",
    "$translate",
    "$filter",
    "$suppliersEdit",
    "$rootScope",
    "$timeout",
    'dialogs'
];

function supplierCreateEdit(
    lvCountriesMultiLang,
    $scope,
    $state,
    $stateParams,
    lodash,
    factorySuppliers,
    serviceToast,
    serviceDialog,
    $translate,
    $filter,
    $suppliersEdit,
    $rootScope,
    $timeout,
    dialogs
) {
    var vm = this;
    var incomingSupplierParams = angular.copy($suppliersEdit);
    let initParams;
    var translate = $filter('translate');
    let offStateChangeStart;
    $scope.isSavingNow = false;
    $scope.countries = angular.copy(lvCountriesMultiLang);
    $scope.showDelBucket = $state.current.name == 'inventory.supplieredit' ? true : false;

    // console.log("$scope.countries =", $scope.countries)


    if ($state.current.name === 'inventory.suppliercreate') {

        $scope.supplierParams = {
            name:'',
            contact:null,
            email: '',
            phone: null,
            site: null,
            address1: null,
            address2: null,
            city: null,
            zip: null,
            region: null,
            comment: null
        }
        $scope.supplierParams.country = $scope.countries.code || null;
        // console.log($scope.supplierParams);
    } else {

        $scope.countries.code = incomingSupplierParams.country;

        $scope.supplierParams = {
            name:   incomingSupplierParams.name,
            contact:incomingSupplierParams.contact,
            email:  (incomingSupplierParams.email === null ? '': incomingSupplierParams.email),
            phone:  incomingSupplierParams.phone,
            site:   incomingSupplierParams.site,
            address1:incomingSupplierParams.address1,
            address2:incomingSupplierParams.address2,
            city:   incomingSupplierParams.city,
            zip:    incomingSupplierParams.zip,
            region: incomingSupplierParams.region,
            comment:incomingSupplierParams.comment,
            country:$scope.countries.code
        }
    }

    initParams = angular.copy($scope.supplierParams);

// данные для сохранение состояния списка
        $scope.stateFromList = $stateParams.stateToSave;
        // console.log("stateFromList =", $scope.stateFromList);
        $scope.checkFromList = $stateParams.checkToSave;
        // console.log("checkFromList =", $scope.checkFromList);
// данные для сохранение состояния списка


    $scope.onCancel = function () {
        $stateParams.page = 0;
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go( 'inventory.supplierlist', {  page:           0,
                                                    limit:          10,
                                                    checkFromList:  $scope.checkFromList}, { reload: true });
        } else {
            $state.go( 'inventory.supplierlist', {  page:           $scope.stateFromList.page,
                                                    limit:          $scope.stateFromList.limit,
                                                    checkFromList:  $scope.checkFromList}, { reload: true });
        }
    };

    $scope.onSave = function(editProfile){
        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if ($scope.supplierForm.$invalid) {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            $scope.supplierForm.focusFirstInvalidControl();
            return;
        }

        $scope.isSavingNow = true;
        vm.finalSupplierParams = {
            name:       $scope.supplierParams.name,
            contact:    $scope.supplierParams.contact || null,
            email:      $scope.supplierParams.email || null,
            phone:      $scope.supplierParams.phone || null,
            site:       $scope.supplierParams.site || null,
            address1:   $scope.supplierParams.address1 || null,
            address2:   $scope.supplierParams.address2 || null,
            city:       $scope.supplierParams.city || null,
            zip:        $scope.supplierParams.zip || null,
            region:     $scope.supplierParams.region || null,
            comment:    $scope.supplierParams.comment || null,
            country:    $scope.countries.code || null
        }

        if ($state.current.name === 'inventory.suppliercreate') {

            var savedMessage = 'INVENTORY.SUPPLIER_CREATED';

            factorySuppliers.createSupplier(vm.finalSupplierParams).then(function(data){
                if (data.result === 'ok') {
                    offStateChangeStart();
                    serviceToast.show(savedMessage, 'success');
                    if (lodash.isEmpty($scope.stateFromList)) {
                        $state.go( 'inventory.supplierlist', {  page:           0,
                                                                limit:          10,
                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                    } else {
                        $state.go( 'inventory.supplierlist', {  page:           $scope.stateFromList.page,
                                                                limit:          $scope.stateFromList.limit,
                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                    }
                } else if (data.result === 'error' && data.message == 'name_exist') {
                    $scope.isSavingNow = false;
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        content: translate('INVENTORY.SUPPLIER_NAME_ALREADY_EXISTS'),
                        buttons: [
                            {
                                class: 'primary',
                                text: 'ON_OK'
                            }
                        ]
                    });
                }
            });
        } else {

            var savedMessage = 'INVENTORY.SUPPLIER_EDITED';

            vm.finalSupplierParams.id = incomingSupplierParams.id;

            factorySuppliers.editSupplier(vm.finalSupplierParams).then(function(data){
                if (data.result === 'ok') {
                    offStateChangeStart();
                    serviceToast.show(savedMessage, 'success');
                    if (lodash.isEmpty($scope.stateFromList)) {
                        $state.go( 'inventory.supplierlist', {  page:           0,
                                                                limit:          10,
                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                    } else {
                        $state.go( 'inventory.supplierlist', {  page:           $scope.stateFromList.page,
                                                                limit:          $scope.stateFromList.limit,
                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                    }
                } else if (data.result === 'error' && data.message == 'name_exist') {
                    $scope.isSavingNow = false;
                    serviceDialog.add({
                        templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                        content: translate('INVENTORY.SUPPLIER_NAME_ALREADY_EXISTS'),
                        buttons: [
                            {
                                class: 'primary',
                                text: 'ON_OK'
                            }
                        ]
                    });
                }
            });

        }
    }

    $scope.onDel = function(editProfile){
        //permission to edit profile, false for manager
        if ( !editProfile ) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        var ids = [incomingSupplierParams.id]
        serviceDialog.add( {
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: translate( 'INVENTORY.DELETE_SUPPLIER_TITLE' ),
            content: translate( 'INVENTORY.DELETE_SUPPLIER_TEXT' ),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: function () {
                        offStateChangeStart();
                        factorySuppliers.deleteSupplier( {id: ids
                        } ).then( function ( result ) {
                                 if (result === 'ok') {
                                    serviceToast.show( 'INVENTORY.DELETE_SUPPLIER', 'success' );

                                    if (lodash.isEmpty($scope.stateFromList)) {
                                        $state.go( 'inventory.supplierlist', {  page:           0,
                                                                                limit:          10,
                                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                                    } else {
                                        $state.go( 'inventory.supplierlist', {  page:           $scope.stateFromList.page,
                                                                                limit:          $scope.stateFromList.limit,
                                                                                checkFromList:  $scope.checkFromList}, { reload: true });
                                    }
                                }
                            });
                    }
                }
            ]
        } );
    };

    offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

    function isChanged() {
        vm.finalSupplierParams = {
            name:       $scope.supplierParams.name,
            contact:    $scope.supplierParams.contact || null,
            email:      $scope.supplierParams.email || '',
            phone:      $scope.supplierParams.phone || null,
            site:       $scope.supplierParams.site || null,
            address1:   $scope.supplierParams.address1 || null,
            address2:   $scope.supplierParams.address2 || null,
            city:       $scope.supplierParams.city || null,
            zip:        $scope.supplierParams.zip || null,
            region:     $scope.supplierParams.region || null,
            comment:    $scope.supplierParams.comment || null,
            country:    $scope.countries.code || null
        }
        return !angular.equals(initParams, vm.finalSupplierParams);
    }
}

export default {
    type: 'controller',
    name: 'supplierCreateEdit',
    value: supplierCreateEdit
};