import angular from 'angular';

transferDetail.$inject = [
    '$scope',
    '$critCountOptionsEdit',
    '$state',
    '$transferDetail',
    'lodash',
    '$stateParams',
    'serviceToast',
    'factoryTransfer',
    '$profile',
    'serviceDialog',
    '$filter',
    'resolveTimezone',
    'factoryInventory',
    'lvMixpanel',
    'dialogs',
    'stateGo'
];

function transferDetail(
    $scope,
    $critCountOptionsEdit,
    $state,
    $transferDetail,
    lodash,
    $stateParams,
    serviceToast,
    factoryTransfer,
    $profile,
    serviceDialog,
    $filter,
    resolveTimezone,
    factoryInventory,
    lvMixpanel,
    dialogs,
    stateGo
    ) {
    $scope.profile = angular.copy($profile);
    var vm = this;
    var translate = $filter('translate');
    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    vm.transferDetail = angular.copy($transferDetail);
    // данные для сохранение состояния фильтров
    $scope.stateFromList = $stateParams.stateToSave;

    vm.profile = $profile;

    $scope.orderData = vm.transferDetail.orderData;
    $scope.items = vm.transferDetail.items;
    $scope.tzOffset = resolveTimezone.timeZoneOffsetForReports();

    $scope.showReceiveButton = $scope.orderData.status !== "TRANSFERRED" && $scope.orderData.canReceiveOrder;

    $scope.progress = false;

    $scope.showMoreFunk = function() {
        if(angular.element('.drop-buttons-menu').hasClass('open')){
            angular.element('.drop-buttons-menu').addClass('close');
            angular.element('.drop-buttons-menu').removeClass('open');
        }else{
            angular.element('.drop-buttons-menu').removeClass('close');
            angular.element('.drop-buttons-menu').addClass('open');
        }
    };

    document.addEventListener('click', function(e){
        if(!angular.element(e.target).hasClass('button')) {
            angular.element('.drop-buttons-menu').addClass('close');
            angular.element('.drop-buttons-menu').removeClass('open');
        }
    });

    /**
     * Navigates to the "inventory.transfer" state with specified parameters.
     * If the $scope.stateFromList is empty, it navigates to the state with default parameters.
     * Otherwise, it navigates to the state with the parameters from $scope.stateFromList.
     * @function backToList
     */
    const backToList = () => {
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go("inventory.transfer",
                {
                    page: 0,
                    limit: 10
                },
                {reload: true});
        } else {
            $state.go("inventory.transfer",
                {
                    page: $scope.stateFromList.page,
                    limit: $scope.stateFromList.limit,
                    outletFrom: $scope.stateFromList.outletFrom,
                    outletTo: $scope.stateFromList.outletTo,
                    status: $scope.stateFromList.status
                },
                {reload: true});
        }
    }

    $scope.onBack = () => {
        backToList();
    }

    $scope.onReceive = function(){
        vm.notCounted=0;
        vm.notProduced = 0;
        vm.content = "";
        for(var i=0; i < $scope.items.length; i++){
            if($scope.items[i].keepCount===false && $scope.items[i].type === 'SIMPLE'){
                vm.notCounted++;
            }
            if($scope.items[i].type === 'COMPLEX' && $scope.items[i].useProduction === false) {
                vm.notProduced++;
            }
        }
        if (vm.notCounted === 0 && vm.notProduced === 0) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('INVENTORY.RECEIVE_TR_ORDER'),
                content: translate('INVENTORY.SURE_WANT_TO_RECEIVE'),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'INVENTORY.RECIEVE',
                        action: function () {
                            $scope.progress = true;
                            factoryTransfer.receiveTransferOrder({
                                id: $stateParams.id
                            }).then(function (data) {
                                if (data.result === "ok") {
                                    serviceToast.show('INVENTORY.TANSFER_RECEIVED', 'success');
                                    lvMixpanel.track('Advanced inventory activity', {"Event type": 'Transfer order received'});
                                    $state.go($state.current.name, {}, {reload: true});
                                } else {
                                    $scope.progress = false;
                                }
                                if (data.result === "error") {
                                    if (data.message === "transfer_order_deleted") {
                                        dialogs.itemDeletedEarlier().then(backToList);
                                    }
                                    if (data.message === "transfer_order_transferred") {
                                        dialogs.transferOrderTransferred().then(stateGo.refresh)
                                    }
                                }
                            }).catch(() => {
                                $scope.progress = false;
                            })
                        }
                    }
                ]
            });
        } else {
            if(vm.notCounted !== 0 && vm.notProduced !== 0) {
                vm.content = translate('INVENTORY.NOTCOUNTED_NOTPRODUCED_ITEMS', {
                    number: vm.notCounted,
                    items: willNotUpdate(vm.notCounted)[0],
                    disabled: willNotUpdate(vm.notCounted)[2],
                    number1: vm.notProduced,
                    items1: willNotUpdate(vm.notProduced)[0],
                    composite: willNotUpdate(vm.notProduced)[3]
                });
            } else if (vm.notCounted !== 0) {
                vm.content = (vm.notCounted === 1) ? translate('INVENTORY.NOTCOUNTED_ITEM_IN_TRANSFER') : translate('INVENTORY.NOTCOUNTED_ITEMS_IN_TRANSFER', {number: vm.notCounted, items: willNotUpdate(vm.notCounted)[0]});
            } else if (vm.notProduced !== 0) {
                var text = "";
                if(vm.notProduced % 10 === 1) {
                    text = 'INVENTORY.NOTPRODUCED_ITEM_IN_TRANSFER';
                } else {
                    text = 'INVENTORY.NOTPRODUCED_ITEMS_IN_TRANSFER';
                }
                vm.content = translate(text, {number: vm.notProduced, items: willNotUpdate(vm.notProduced)[0], Its: willNotUpdate(vm.notProduced)[1]});
            }
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('INVENTORY.RECEIVE_TR_ORDER'),
                content: vm.content,
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'ON_OK',
                        action: function () {
                            $scope.progress = true;
                            factoryTransfer.receiveTransferOrder({
                                id: $stateParams.id
                            }).then(function (data) {
                                if (data.result === "ok") {
                                    serviceToast.show('INVENTORY.TANSFER_RECEIVED', 'success');
                                    lvMixpanel.track('Advanced inventory activity', {"Event type": 'Transfer order received'});
                                    $state.go($state.current.name, {}, {reload: true});
                                } else {
                                    $scope.progress = false;
                                }

                                if (data.result === "error") {
                                    if (data.message === "transfer_order_deleted") {
                                        dialogs.itemDeletedEarlier().then(backToList);
                                    }
                                    if (data.message === "transfer_order_transferred") {
                                        dialogs.transferOrderTransferred().then(stateGo.refresh)
                                    }
                                }
                            }).catch(() => {
                                $scope.progress = false;
                            })
                        }
                    }
                ]
            });
        }
    };

    $scope.onApprove = function() {
        $scope.progress = true;
        factoryTransfer.approveTransferOrder({
            id: $stateParams.id
        }).then(function( data ){
            if ( data.result === "ok" ) {
                serviceToast.show('INVENTORY.TRANSFER_ORDER_APPROVED', 'success');
                $state.go( $state.current.name, {}, {reload: true} );
            } else {
                $scope.progress = false;
            }

            if (data.result === "error") {
                if (data.message === "transfer_order_deleted") {
                    dialogs.itemDeletedEarlier().then(backToList);
                }
                if (data.message === "transfer_order_approved") {
                    dialogs.transferOrderApproved().then(stateGo.refresh)
                }
            }
        }).catch(() => {
            $scope.progress = false;
        })
    }

    vm.emailInfo = {
        from: vm.transferDetail.orderData.merchantEmail,
        to: null,
        cc: "",
        subject: translate('INVENTORY.TRANSFER_FROM') + " " + vm.transferDetail.orderData.sourceStore + " " + translate('INVENTORY.WHERE') + " " + vm.transferDetail.orderData.destinationStore + " " + "(" + vm.transferDetail.orderData.order + ")",
        note: null,
        order: vm.transferDetail.orderData.order
    };

    $scope.toSendDialog = function() {
        serviceDialog.add( {
            templateUrl: 'dashboard-page/templates/dialog/send-purchase-order.html',
            title: translate('INVENTORY.SEND_TRANSFER'),
            emailInfo: vm.emailInfo,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: () => stateGo.refresh()
                },
                {
                    class: 'primary',
                    text: 'send_pass',
                    action: function () {
                        var emailInfo = vm.emailInfo,
                        toSend = {
                            orderId:$stateParams.id,
                            emailTo: emailInfo.to,
                            emailCc: emailInfo.cc || "",
                            subject: emailInfo.subject,
                            message: emailInfo.note || "",
                            tzOffset: $scope.tzOffset
                        };
                        factoryInventory.sendTransfer(toSend).then(function(data){
                            if (data.result === "ok") {
                                lvMixpanel.track('Back office page visit',		{"Property": "Inventory management/Send transfer order by email"});
                                serviceToast.show('INVENTORY.EMAIL_SENT', 'success');
                            } else if (data.result === "error" && data.message === "transfer_order_deleted") {
                                dialogs.itemDeletedEarlier().then(backToList);
                            }
                        })
                    }
                }
            ]
        } );
    };

    $scope.onDuplicateTransferOrder = function() {
        //Triggered when clicking on the “Duplicate” button in
        // the “Transfer order details” page.
        lvMixpanel.track( 'Back office page visit', {
            "Property": "Inventory management/Duplicate transfer order"
        });
        $state.go('inventory.duplicatetransfer', {
            id: $stateParams.id
        });
    }

    $scope.onDelete = function(){

        serviceDialog.add( {
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            content: translate( 'INVENTORY.ARE_YOU_SURE_TRANSFER' ),
            buttons: [
                {
                    text: 'ON_NO'
                },
                {
                    class: 'primary',
                    text: 'ON_YES',
                    action: function () {
                        factoryTransfer.deleteTransferOrder({
                            id: $stateParams.id
                        }).then((data) => {
                             if (data.result === 'ok') {
                                serviceToast.show('INVENTORY.TRANSFER_DELETED', 'success');
                                backToList()
                             } else if (data.result === 'error') {
                                  if (data.message === 'transfer_order_deleted') {
                                      dialogs.itemDeletedEarlier().then(backToList);
                                  }
                                  if (data.message === 'transfer_order_transferred') {
                                      dialogs.transferOrderTransferred().then(stateGo.refresh)
                                  }
                             }
                        });
                    }
                }
            ]
        } );
    };

    $scope.onEdit = function () {
        $state.go('inventory.edittransfer', {
            id: $stateParams.id
        });
    };

    $scope.goPrintLabelsTransferOrder = function () {
        // Triggered when users visit the “Create labels” page by clicking on
        // the “Print labels” button in the transfer order details.
        lvMixpanel.track('Back office page visit', {
            "Property": "Inventory management/Create labels for transfer order"
        });
        $state.go('inventory.printlabels', {orderId: $stateParams.id, orderType: "TRANSFER_ORDER"});
    };

    const saveTransferPdfForMixpanel = () => {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save transfer order as PDF"
        });
    };

    const saveTransferCsvForMixpanel = () => {
        lvMixpanel.track('Export', {
            "Property": "Inventory management/Save transfer order as CSV"
        });
    };

    function willNotUpdate(quantity) {
        var modulo = quantity % 10;
        if (quantity === 1) {
            return [
                translate("ITEMS_DECLINE.ONE_ITEM"),
                translate("INVENTORY.QUANT.ITS"),
                translate("INVENTORY.DISABLED_ONE"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEM")
            ];
        } else if (modulo > 1 && modulo < 5) {
            return [
                translate("ITEMS_DECLINE.ITEMS_2_TO_4"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.DISABLED_MANY"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEMS")
            ];
        }else if ((modulo > 4 && modulo < 10) || modulo === 0 || (quantity > 10 && quantity <= 20)) {
            return [
                translate("ITEMS_DECLINE.ITEMS_5_TO_10"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.DISABLED_MANY"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEMS")
            ];
        } else if (quantity > 1 && modulo === 1) {
            return [
                translate("ITEMS_DECLINE.ITEMS_1"),
                translate("INVENTORY.QUANT.THEIR"),
                translate("INVENTORY.DISABLED_MANY"),
                translate("INVENTORY.QUANT.COMPOSIT_ITEM")
            ];
        }
    }

    const downloadData = {
        orderId: $scope.orderData.id,
        tzOffset: $scope.tzOffset
    }

    $scope.downloadPDF = () => {
        saveTransferPdfForMixpanel();

        factoryTransfer.getTransferOrderPDF(downloadData).then((res) => {
            if (res.result === "error" && res.message === "transfer_order_deleted") {
                dialogs.itemDeletedEarlier().then(backToList);
            }
        })
    }

    $scope.downloadCSV = () => {
        saveTransferCsvForMixpanel();

        factoryTransfer.getTransferOrderCSV(downloadData).then((res) => {
            if (res.result === "error" && res.message === "transfer_order_deleted") {
                dialogs.itemDeletedEarlier().then(backToList);
            }
        })
    }
}

export default {
    type: 'controller',
    name: 'transferDetail',
    value: transferDetail
};
