/**
 *  Created by Kiskenbassker on 19.03.18
 */

cardDateValidation.$inject = [];

function cardDateValidation() {

    return {
        require: 'ngModel',
        restrict: 'A',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$validators.cardDate = function(modelValue, viewValue) {

                var value = modelValue || viewValue,
                    date = new Date(),
                    currentMonth = date.getMonth() + 1,
                    currentYear = date.getFullYear() - 2000;

                function isNumeric(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                function parseMonthAndYear(value) {
                    if(isNumeric(value)) {
                        if(typeof value !== 'string') {
                            value.toFixed(0);
                        }
                        return {
                            month: parseInt(value[0] + value[1]),
                            year: parseInt(value[2] + value[3])
                        }
                    } else {
                        return {
                            month: -1,
                            year: -1
                        }
                    }
                }

                var enteredMonth = parseMonthAndYear(value).month,
                    enteredYear = parseMonthAndYear(value).year;

                function checkMonth() {
                    if(enteredYear === currentYear && enteredMonth < currentMonth) {
                        return false;
                    }
                    return (!isNaN(enteredMonth) && enteredMonth >= 1 && enteredMonth <= 12);
                }

                function checkYear() {
                    return !isNaN(enteredYear) && enteredYear >= currentYear && enteredYear <= (currentYear + 50);
                }

                return (value.length === 4) && checkMonth() && checkYear();
            };
        }
    }

}

export default {
    type: 'directive',
    name: 'cardDate',
    value: cardDateValidation
};