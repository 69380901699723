/**
 * Created by kseniya.yarosh on 15/2/17.
 */

import angular from "angular";

clientBaseImportControl.$inject = [
    'FileUploader',
    '$state',
    '$stateParams',
    'stateGo',
    '$filter',
    'serviceDialog',
    'clientsDB',
    'serviceToast',
    '$translate',
    '$timeout',
    '$profile',
    'siteLangCode',
    'lvMixpanel'
];

function clientBaseImportControl(
                     FileUploader,
                     $state,
                     $stateParams,
                     stateGo,
                     $filter,
                     serviceDialog,
                     clientsDB,
                     serviceToast,
                     $translate,
                     $timeout,
                     $profile,
                     siteLangCode,
                     lvMixpanel) {

    var model = this;
    var translate = $filter( 'translate' );
    var outletId = ($stateParams.outletId) ? $stateParams.outletId : null;
    var langCode = siteLangCode[$profile.cabinetLang];
    var plugProductions = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];

    model.helpLink = plugProductions.indexOf(langCode) !== -1
        ? "https://help.loyverse.com/" + langCode + "/help/importing-and-exporting-customers?utm_source=Back%20Office&utm_medium=Importing%20Exporting%20customers"
        : "https://help.loyverse.com/help/importing-and-exporting-customers?utm_source=Back%20Office&utm_medium=Importing%20Exporting%20customers";

    model.previousState = stateGo.getPreviousStateAlias() || 'clients.database';
    model.title = translate('CLIENTS.IMPORT.IMPORT_CLIENTS');
    model.fillInData = translate('CLIENTS.IMPORT.FILLED_WITH_DATA');
    model.expTemplate = '/data/ownercab/exporttemplateclientsfile';

    model.willBeCreated = {
        one: 'CLIENTS.IMPORT.WILL_BE_CREATED_CLIENT_SINGULAR',
        first: 'CLIENTS.IMPORT.WILL_BE_CREATED_CLIENT_SINGULAR',
        second: 'CLIENTS.IMPORT.WILL_BE_CREATED_CLIENT_PAUCAL',
        third: 'CLIENTS.IMPORT.WILL_BE_CREATED_CLIENT_PLURAL'
    };

    model.willBeEdited = {
        one: 'CLIENTS.IMPORT.WILL_BE_EDITED_CLIENT_SINGULAR',
        first: 'CLIENTS.IMPORT.WILL_BE_EDITED_CLIENT_SINGULAR',
        second: 'CLIENTS.IMPORT.WILL_BE_EDITED_CLIENT_PAUCAL',
        third: 'CLIENTS.IMPORT.WILL_BE_EDITED_CLIENT_PLURAL'
    };



    var includeTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/download-start.html',
        onAttach: 'dashboard-page/templates/import-entity/include/on-attach.html',
        onError: 'dashboard-page/templates/import-entity/include/download-error.html',
        formatError: 'dashboard-page/templates/import-entity/include/format-error.html',
        onLoading: 'dashboard-page/templates/import-entity/include/preloader.html',
        onParserSuccess: 'dashboard-page/templates/clients/include/parser-success.html', //не переносить в отдельную фабрику
        onParserError: 'dashboard-page/templates/clients/include/parser-error.html', // не переносить в отдельную фабрику
        badEncoding: 'dashboard-page/templates/import-entity/include/bad-encoding.html'
    };

    var includeHeadTemplate = {
        onStart: 'dashboard-page/templates/import-entity/include/head-start.html',
        onSuccess: 'dashboard-page/templates/import-entity/include/head-success.html',
        onParserError: 'dashboard-page/templates/import-entity/include/head-parser-error.html'
    };

    var primaryAction = {
        onStart: function () {
            model.response = null;
            model.includeTemplate = includeTemplate.onStart;
            model.includeHeadTemplate = includeHeadTemplate.onStart;
            model.primaryButtonText = 'DOWNLOAD';
            model.primaryAction = primaryAction.onStart;
            model.onCancel = cancelAction.onStart;
            model.primaryButtonDisabled = true;
        },
        onAttach: function () {
            if ( !checkFileNameValid() ) {
                uploader.removeFromQueue( 0 );
                model.includeTemplate = includeTemplate.formatError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
            } else if ( !checkFileSize() ) {
                uploader.removeFromQueue( 0 );
                model.includeTemplate = includeTemplate.onError;
                model.primaryButtonText = 'REPEAT_LOADING';
                model.primaryAction = primaryAction.onStart;
                model.primaryButtonDisabled = false;
                model.errorText = {
                    code: "VALIDATE.ERROR.CLIENTS_MAX_FILE_SIZE_5MB"
                }
            } else {
                model.includeTemplate = includeTemplate.onLoading;
                uploader.uploadAll();
                model.primaryButtonDisabled = true;
            }

        },
        onUpdateGoods: function () {
            model.includeTemplate = includeTemplate.onLoading;
            model.primaryButtonDisabled = true;

            clientsDB
                .processClientsFile()
                .then( function ( d ) {
                    if ( d && d.result == 'ok' ) {
                        $timeout( function () {
                            successImportClientsForMixpanel(model.response);
                            $state.go( $state.current.name, {}, {reload: true} );
                            // $state.go( model.previousState, {reload: true} );
                        }, 2000 );
                    } else {
                        serviceToast.show( 'UNKNOWN_ERROR', 'error' );
                        model.primaryButtonDisabled = false;
                        primaryAction.onStart();
                    }
                }, function ( err ) {
                    serviceToast.show( 'UNKNOWN_ERROR', 'error' );
                    primaryAction.onStart();
                } );
        }
    };

    var cancelAction = {
        onStart: function () {
            $state.go( model.previousState, {page: 0, limit: 10, inventory: 'all', outletId: ($stateParams.outletId) ? $stateParams.outletId : null } );
        },
        onParserError: function () {
            serviceDialog.add( {
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate( 'WARNING' ) + "!",
                content: translate( 'DETECT_CHANGES' ),
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    },
                    {
                        class: 'primary',
                        text: 'ON_OK',
                        action: function () {
                            $state.go( model.previousState, {page: 0, limit: 10, inventory: 'all', outletId: ($stateParams.outletId) ? $stateParams.outletId : null} );
                        }
                    }
                ]
            } );
        }
    };

    primaryAction.onStart();

    var uploader = this.uploader = new FileUploader( {
        url: '/data/ownercab/validateclientsfile',
        outletId: outletId,
        queueLimit: 1
    } );

    // CALLBACKS
    uploader.onWhenAddingFileFailed = function ( item /*{File|FileLikeObject}*/, filter, options ) {
        //console.info( 'onWhenAddingFileFailed', item, filter, options );
    };
    uploader.onAfterAddingFile = function ( fileItem ) {
        //console.info( 'onAfterAddingFile', fileItem );
        var addedFile = fileItem._file;
        model.includeTemplate = includeTemplate.onAttach;
        model.primaryButtonDisabled = false;
        model.primaryAction = primaryAction.onAttach;
        model.onAttachFileName = addedFile.name;
        model.fileSize = addedFile.size;
        model.outletId = outletId;
    };
    uploader.onAfterAddingAll = function ( addedFileItems ) {
        //console.info( 'onAfterAddingAll', addedFileItems );
        if ( addedFileItems.length ) {
            model.primaryButtonDisabled = false;
            model.primaryButtonText = 'DOWNLOAD'
        }

    };
    uploader.onBeforeUploadItem = function ( item ) {
        if(outletId) {
            uploader.formData = [{outletId: outletId}];
        }
        Array.prototype.push.apply(item.formData, uploader.formData);

        // console.info('onBeforeUploadItem', item );
    };
    uploader.onProgressItem = function ( fileItem, progress ) {
        //console.info( 'onProgressItem', fileItem, progress );
    };
    uploader.onProgressAll = function ( progress ) {
        //console.info( 'onProgressAll', progress );
    };
    uploader.onSuccessItem = function ( fileItem, response, status, headers ) {

        var res = null;
        try {
            res = JSON.parse( response );
        } catch ( err ) {
            console.error( err );
        }
        if ( !res ) {
            return;
        }

        errorImportClientsForMixpanel(res);

        if ( res.result == 'error' ) {
            if ( res.message == 'file_bad_encoding' ) {
                badEncoding();
            }
            if (res.message == 'forbidden_for_support_team') {
                serviceToast.show('NO_EDIT_PERMISSION', 'error');
            }
        } else if (res.error) {
            switch ( res.error ) {
                case "WRONG_HEADER_FORMAT":
                    wrongFileFormat(res);
                    break;
                case "WRONG_HEADER_LENGTH":
                    wrongHeaderLength(res);
                    break;
                case "WRONG_HEAD":
                    wrongHeader(res);
                    break;
                case "WRONG_COLUMNS_NAMES":  //Файл содержит неправильную информацию в названии столбцов. Убедитесь, что Вы используете тот же формат, как и файл шаблона.
                    wrongColumnsNames();
                    break;
                case "LIMIT_10000":
                    limit10000();
                    break;
                default:
                    wrongFileFormat(res);
            }
        } else if (!res.canProcess) {
            downloadError( res );
        } else {
            downloadSuccess( res );
        }


        model.primaryButtonDisabled = false;
        //console.info( 'onSuccessItem', fileItem, response, status, headers );
    };
    uploader.onErrorItem = function ( fileItem, response, status, headers ) {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.primaryButtonDisabled = false;
        model.errorText = { code: "REPEAT_AFTER" };

        let errorData = angular.isObject(response.data) ? response.data : response;

        if (errorData.result == 'error') {
            if (errorData.message == 'forbidden_for_support_team') {
                serviceToast.show('NO_EDIT_PERMISSION', 'error');
            }
        }

        //console.info( 'onErrorItem', fileItem, response, status, headers );
    };
    uploader.onCancelItem = function ( fileItem, response, status, headers ) {

        //console.log( response );

        //console.info( 'onCancelItem', fileItem, response, status, headers );
    };
    uploader.onCompleteItem = function ( fileItem, response, status, headers ) {

        ////console.info( 'onCompleteItem', fileItem, response, status, headers );
    };
    uploader.onCompleteAll = function () {

        if ( model.includeTemplate != includeTemplate.onError ) {
            //model.includeTemplate = includeTemplate.onStart;
            //model.includeHeadTemplate = includeHeadTemplate.onSuccess
        }

        //console.info( 'onCompleteAll' );
    };

    //console.info( 'uploader', uploader );

    function wrongColumnsNames() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.onCancel = cancelAction.onStart;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: "VALIDATE.ERROR.WRONG_COLUMNS_NAMES"
        };

    }

    function limit10000() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.onCancel = cancelAction.onStart;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: "CLIENTS.IMPORT.LIMIT_10000"
        };
    }


    function downloadSuccess( res ) {



        var i = 0;
        while ( i < res.warnings.length ) {
            (function ( i ) {
                $translate( (res.warnings[i].columnName) ? 'VALIDATE.ERROR.' + res.warnings[i].columnName : (res.warnings[i].columnError) ? 'VALIDATE.ERROR.' + res.warnings[i].columnError : false )
                    .then( function ( val ) {
                        if ( /^\s$/.test( val ) ) {
                            res.warnings[i]._error = translate( 'VALIDATE.ERROR.' + res.warnings[i].columnError, {name: translate((res.warnings[i].columnName) ? '"' + res.warnings[i].columnName + '"' : false)} )
                        } else {
                            for(var key in res.warnings[i]) {
                                if ( res.warnings[i].hasOwnProperty( key ) ) {
                                    if( key && res.warnings[i][key] ) {
                                        res.warnings[i]._error = translate( 'VALIDATE.ERROR.' + res.warnings[i].columnError, {name: translate('"' + val + '"')} );
                                    }
                                }
                            }
                        }
                    }, function ( err ) {
                        res.warnings[i]._error = translate( 'VALIDATE.ERROR.' + res.warnings[i].columnError, { name: (res.warnings[i].columnName) ? translate('"' + res.warnings[i].columnName + '"') : false })
                    })
            })( i );
            i++;
        }
        i = null;



        model.includeTemplate = includeTemplate.onParserSuccess;
        model.includeHeadTemplate = includeHeadTemplate.onSuccess;
        model.response = res;
        model.primaryButtonText = 'IMPORT_ENTITY.REFRESH_DATA';
        model.onCancel = cancelAction.onStart;
        model.primaryAction = primaryAction.onUpdateGoods;
        model.confirmText = { code: 'IMPORT_ENTITY.PLEASE_CONFIRM_LIST_CHANGES'};
    }

    function wrongFileFormat() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: "WRONG_HEADER_FORMAT"
        };
    }

    function wrongHeaderLength() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: "DOWNLOAD_WRONG_HEADER"
        };
    }

    function wrongHeader() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onError;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.errorText = {
            code: "DOWNLOAD_WRONG_HEADER"
        };
    }

    function downloadError( res ) {


        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.onParserError;
        model.includeHeadTemplate = includeHeadTemplate.onParserError;
        var i = 0;
        while ( i < res.errorsList.length ) {
            (function ( _i ) {
                $translate( (res.errorsList[_i].columnName)?('VALIDATE.ERROR.' + res.errorsList[_i].columnName):(res.errorsList[_i].columnError) ? ('VALIDATE.ERROR.' + res.errorsList[_i].columnError) : false)
                    .then( function ( val ) {
                        if ( /^\s$/.test( val ) ) {
                            res.errorsList[_i]._error = translate( 'VALIDATE.ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} );
                        } else {
                            for(var key in res.errorsList[_i]) {
                                if ( res.errorsList[_i].hasOwnProperty( key ) ) {
                                    if( key && res.errorsList[_i][key] != null) {
                                        res.errorsList[_i]._error = translate( 'VALIDATE.ERROR.' + res.errorsList[_i].columnError, {name: '"' + val + '"'} );
                                    }
                                }
                            }
                        }
                    }, function ( err ) {
                        res.errorsList[_i]._error = translate( 'VALIDATE.ERROR.' + res.errorsList[_i].columnError, {name: '"' + res.errorsList[_i].columnName + '"'} )
                    } );
            })( i );
            i++
        }

        i = null;
        model.response = res;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
    }

    function badEncoding() {
        uploader.removeFromQueue( 0 );
        model.includeTemplate = includeTemplate.badEncoding;
        model.primaryButtonText = 'REPEAT_LOADING';
        model.primaryAction = primaryAction.onStart;
        model.primaryButtonDisabled = false;
    }

    function checkFileSize() {
        if ( 5 * 1000 * 1000 <= model.fileSize ) {
            return false
        }
        return true;
    }


    function checkFileNameValid() {
        if ( /\.csv$/.test( model.onAttachFileName ) ) {
            return true
        }
        return false
    }


//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ
    function errorImportClientsForMixpanel(resError) {
        if ((resError.result === "ok" && resError.error != null) || (resError.result === "ok" && resError.error == null && resError.errorsList.length > 0)) {
            // console.log("errorImportClientsForMixpanel resError = ", resError);
            lvMixpanel.track('Import', {  "Event type"        : "Import customer base",
                                        "Event result"     : "Customer base import error",
                                        "Number of errors"  : resError.totalError});
        }
    }

    function successImportClientsForMixpanel(resSuccess) {
        if (resSuccess.result === "ok" && resSuccess.error == null && resSuccess.errorsList.length === 0) {
            // console.log("successImportClientsForMixpanel, resSuccess = ", resSuccess);
            lvMixpanel.track('Import', {  "Event type"            : "Import customer base",
                                        "Event result"         : "Customer base imported",
                                        "Number of customers"   : resSuccess.willBeCreated + resSuccess.willBeEdited,
                                        "Number of warnings"    : resSuccess.warnings.length});
        }
    }

    model.importHelpForMixpanel = function() {
        lvMixpanel.track( 'Help', {'Property': 'Import customers'} );
    }
//*** ФУНКЦИИ ДЛЯ МИКСПАНЕЛИ



}

export default {
    type: 'controller',
    name: 'clientBaseImportControl',
    value: clientBaseImportControl
};
