/**
 * Created by mars on 12/13/15.
 */

import angular from "angular";

factoryGoods.$inject = [
  "$q",
  "$http",
  "factoryErrorAlert",
  "promiseOperation",
  "getTaxList",
  "$filter",
  "$timeout",
  "serviceDialog",
  "$state",
  "$stateParams",
  "factoryCategories",
  "getOutletsList",
  "$rootScope",
  "currencyFormat",
  "serviceMinMaxValues",
  "factoryForms"
];

function factoryGoods(
  $q,
  $http,
  factoryErrorAlert,
  promiseOperation,
  getTaxList,
  $filter,
  $timeout,
  serviceDialog,
  $state,
  $stateParams,
  factoryCategories,
  getOutletsList,
  $rootScope,
  currencyFormat,
  serviceMinMaxValues,
  factoryForms
) {
  let currencyToCoin = $filter("currencyToCoin");
  let coinToCurrency = $filter("coinToCurrency");
  let filterRepairCurrenFormatWhenFocus = $filter(
    "filterRepairCurrenFormatWhenFocus",
  );

  let wares = [];
  let promise;
  let pager = {
    offset: 0,
    limit: 10,
    page: 0,
    pages: null,
  };
  let initialStateOfWare;

  let _loading = {
    req: 0,
    res: 0,
    process: false,
  };

  function success(resolve, reject, d) {
    let data = {};

    _loading.res++;
    pager.pages = d.data.pages;
    let i = d.data.wares.length;
    while (0 < i--) {
      wares.unshift(d.data.wares[i]);
    }
    wares.splice(d.data.wares.length, wares.length);

    data.wares = wares;

    if (_loading.res === _loading.req) {
      _loading.process = false;
      resolve(data);
    } else {
      reject();
    }
  }

  // получение списка всех товаров для отображения в списке товаров
  function getWaresWithCategories(stateParams) {
    let defer = $q.defer();
    $q.all([getWares(stateParams), factoryCategories.getCategories()]).then(
      function (arr) {
        angular.forEach(arr[0].wares, function (ware) {
          ware._categories = getCategory(ware.categoryId, arr[1]);
        });

        defer.resolve(arr[0]);
      },
    );

    return defer.promise;
  }
  // получение списка всех товаров для отображения в списке товаров

  function getCategory(id, categories) {
    let i = 0;
    while (i < categories.length) {
      if (id === categories[i].id) {
        return [categories[i]];
      }
      i++;
    }

    return [null];
  }

  function formatWareForm(body, image) {
    const form = new FormData();
    form.append("body", JSON.stringify(body));
    form.append("image", image ?? null);
    return form;
  }

  function getWares(stateParams) {
    if (_loading.res == _loading.req) {
      _loading.process = true;
    }
    _loading.req++;
    pager.offset = stateParams.offset;

    promise = $q(function (resolve, reject) {
      //if in some controllers the page parameter will be passed instead of offset
      if (stateParams.page && Number.isFinite(stateParams.page))
        pager.offset = stateParams.page * 10;
      if (stateParams.outletId === "null") {
        stateParams.outletId = null;
      }

      if (pager.offset == -10) {
        pager.offset = 0;
      }

      if (pager.offset >= 0) {
        $http
          .post("/data/ownercab/getwares", {
            offset: pager.offset,
            limit: stateParams.limit,
            search: stateParams.search || null,
            outletId:
              stateParams.outletId && stateParams.outletId !== "all"
                ? stateParams.outletId
                : null,
            filters: {
              inventory:
                !stateParams.inventory
                  ? "ALL"
                  : stateParams.inventory.toUpperCase(),
              category:
                !stateParams.category
                  ? null
                  : parseFloat(stateParams.category),
            },
          })
          .then(
            function (d) {
              success(resolve, reject, d);
            },
            function (err) {
              factoryErrorAlert.onError(err);
              reject(err);
            },
          );
      }
    });
    return promise;
  }

  function getVariantsByWareId(params) {
    return promiseOperation.getPromiseHTTP(
      "POST",
      "/data/ownercab/getwaresvariantsbyid",
      params,
      successGetVariants,
    );
  }

  function successGetVariants(response) {
    if (angular.isObject(response.data)) {
      if (response.data.result === "ok") {
        return response.data.variants;
      }
    }
  }

  function editVariantInline(params) {
    return promiseOperation.getPromiseHTTP(
      "POST",
      "/data/ownercab/editvariantinline",
      params,
      successEditVariantInline,
    );
  }

  function successGetAutoArticles(response) {
    if (angular.isObject(response.data)) {
      if (response.data.result === "ok") {
        return response.data;
      }
    }
  }

  function getAutoArticles(params) {
    return promiseOperation.getPromiseHTTP(
      "POST",
      "/data/ownercab/getautoarticles",
      params,
      successGetAutoArticles,
    );
  }

  function successEditVariantInline(response) {
    if (angular.isObject(response.data)) {
      if (response.data.result === "ok") {
        return response.data;
      }
    }
  }

  function searchWare(obj) {
    return $q(function (resolve, reject) {
      $http
        .post("/data/ownercab/searchware", {
          limit: pager.limit,
          offset: 0,
          wareId: obj.id,
          search: obj.val || null,
        })
        .then(
          function (d) {
            resolve(d.data);
          },
          function (err) {},
        );
    });
  }

  function deleteWares(wares) {
    let wrIds = [];
    if (typeof wares == "number" || typeof wares == "string") {
      wrIds.push(wares);
    } else {
      let i = wares.length;
      while (0 < i--) {
        if (wares[i]._select) {
          wrIds.push(wares[i].id);
        }
      }
    }

    return $q(function (resolve, reject) {
      if (wrIds.length) {
        $http
          .post("/data/ownercab/deletewares", {
            deleted: wrIds,
          })
          .then(
            function (d) {
              resolve(d.data);
            },
            function (err) {
              factoryErrorAlert.onError(err);
              reject(err);
            },
          );
      } else {
        reject({ result: "empty_del" });
      }
    });
  }

  // инлайн редактирование свойств товара в списке товаров
  function editWareInline(itemEditData) {
    return $q(function (resolve, reject) {
      $http
        .post("/data/ownercab/editwareinline", itemEditData)
        .then(
          d => {
            resolve(d.data);
          },
          err => {
            factoryErrorAlert.onError(err);
            reject(err);
          },
        );
    });
  }
  // инлайн редактирование свойств товара в списке товаров

  function createWare(ware) {
    let taxIds = [];
    for (let i = 0; i < ware._taxes.length; i++) {
      if (ware._taxes[i]._select) {
        taxIds.push(ware._taxes[i].id);
      }
    }

    let byOutletsShop = [];

    if (ware._variantsByOutlets) {
      angular.forEach(ware._variantsByOutlets, function (outlet, i) {
        byOutletsShop.push({
          variantArticle: outlet.variantArticle
            ? outlet.variantArticle
            : outlet.article,
          variantValues: outlet.values,
          outletId: outlet.outletId || outlet.id,
          enable: outlet.enable,
          modified: outlet.modified,
          salePrice:
            outlet.salePrice &&
            outlet.salePrice !== "" &&
            outlet.salePrice !== null
              ? currencyToCoin(outlet.salePrice)
              : null,
          freePrice: outlet.salePrice === null || outlet.salePrice === "",
          count:
            ((!ware._complex && ware.keepCount) || ware.useProduction) &&
            outlet.count
              ? $filter("wareCount")(
                  filterRepairCurrenFormatWhenFocus(outlet.count),
                  ".",
                  true,
                )
              : 0,
          criticalCount:
            (ware.keepCount || ware.useProduction) && outlet.criticalCount
              ? $filter("wareCount")(
                  filterRepairCurrenFormatWhenFocus(outlet.criticalCount),
                  ".",
                  true,
                )
              : null,
          idealStock:
            (ware.keepCount || ware.useProduction) && outlet.idealStock
              ? $filter("wareCount")(
                  filterRepairCurrenFormatWhenFocus(outlet.idealStock),
                  ".",
                  true,
                )
              : null,
        });
      });
    }

    return $q(function (resolve, reject) {
      const body = {
        colorName: ware.colorName ?? "GREY",
        representationOnPOS:
          ware._radio === "color" ? "COLOR_AND_SHAPE" : "IMAGE",
        name: ware.name,
        salePrice:
          ware._salePrice !== "" && ware._salePrice !== null
            ? currencyToCoin(ware._salePrice)
            : null,
        shape: ware.shape ?? "ROUNDED_SQUARE",
        wareCategoryId: ware.wareCategoryId === 0 ? null : ware.wareCategoryId,
        keepCount:
          (!ware._complex && ware.keepCount) ||
          (ware._complex && ware.useProduction)
            ? 1
            : 0,
        useProduction: ware.useProduction,
        article: ware.article || null,
        autoWareArticle: ware.autoWareArticle || null,
        barcode: ware.barcode,
        defaultSupplier:
          ware.keepCount && ware.defaultSupplier ? ware.defaultSupplier : null,
        purchaseCost:
          ware.purchaseCost !== null && ware.keepCount
            ? currencyToCoin(parseFloat(ware.purchaseCost), 10)
            : null,
        waresCount: null,
        limit: pager.limit,
        taxIds: taxIds.length ? taxIds : null,
        freePrice: ware._salePrice === "" || ware._salePrice === null,
        divisible: ware.divisible,
        primeCost: angular.isEmpty(ware._primeCost)
          ? 0
          : currencyToCoin(ware._primeCost),
        type: ware._complex ? "COMPLEX" : "SIMPLE",
        description: ware.description,
        byOutlets: byOutletsShop,
        variations: ware.variations,
        useDiningOptions: ware.useDiningOptions,
        modifierIds: (function () {
          let arr = [];
          let i = 0;
          while (i < ware.modifiers.length && angular.isArray(ware.modifiers)) {
            if (ware.modifiers[i].selected) {
              arr.push(ware.modifiers[i].id);
            }
            i++;
          }
          return arr;
        })(),
        ingredients: (function () {
          let ingredients = [];
          angular.forEach(ware.ingredients, function (ingredient, i) {
            ingredients.push({
              id: ingredient.id,
              quantity: ingredient._quantity
                ? $filter("wareCount")(ingredient._quantity, ".", true)
                : null,
            });
          });
          return ingredients;
        })(),
      };

      $http
        .post(
          "/data/ownercab/createware",
          formatWareForm(body, ware.imageBlob),
          { headers: factoryForms.formDataHeaders },
        )
        .then(
          function (d) {
            resolve(d.data);
          },
          function (err) {
            factoryErrorAlert.onError(err);
            if (angular.isObject(err.data)) {
              resolve(err.data);
            } else {
              resolve(err);
            }
          },
        );
    });
  }

  function getWareById(id) {
    let a;
    let cloneByOutletsShop = [];
    let goodsById;

    if (id) {
      a = currencyFormat
        .getCurrencyOptionsEdit()
        .then((currencyOptionsEdit) => {
          return promiseOperation
            .post("/data/ownercab/getwarebyid", { id: id }, afterResponse)
            .then((ware) => {
              return ware.result === "error" ? $q.reject(ware) : ware;
            });

          function afterResponse(ware) {
            if (ware.result === "error") {
              return;
            }

            if (ware.variations.options.length) {
              if (ware.byOutlets.length > 1) {
                goodsById = function () {
                  ware.byOutlets.forEach((outlet) => {
                    cloneByOutletsShop.push({
                      variantArticle: outlet.article,
                      variantValues: outlet.variantValues,
                      outletId: outlet.outletId,
                      outletName: outlet.outletName,
                      enable: outlet.enable,
                      modified: outlet.modified,
                      freePrice: outlet.freePrice,
                      salePrice: !outlet.freePrice
                        ? serviceMinMaxValues.getValidValue(
                            coinToCurrency(outlet.salePrice, "."),
                            currencyOptionsEdit,
                          )
                        : null,
                      count: angular.isNumber(outlet.count)
                        ? $filter("wareCount")(
                            outlet.count,
                            $rootScope.decSeparator,
                            false,
                          )
                        : 0,
                      criticalCount: angular.isNumber(outlet.criticalCount)
                        ? $filter("wareCount")(
                            outlet.criticalCount,
                            $rootScope.decSeparator,
                            false,
                          )
                        : null,
                    });
                  });
                  return cloneByOutletsShop;
                };
                goodsById();
              } else {
                cloneByOutletsShop[0] = {
                  variantArticle: ware.byOutlets[0].article,
                  variantValues: ware.byOutlets[0].variantValues,
                  outletId: ware.byOutlets[0].outletId,
                  outletName: ware.byOutlets[0].outletName,
                  enable: ware.byOutlets[0].enable,
                  modified: ware.byOutlets[0].modified,
                  freePrice: ware.byOutlets[0].freePrice,
                  salePrice:
                    angular.isNumber(ware.byOutlets[0].salePrice) &&
                    ware.byOutlets[0].salePrice >= 0 &&
                    !ware.byOutlets[0].freePrice
                      ? serviceMinMaxValues.getValidValue(
                          coinToCurrency(ware.byOutlets[0].salePrice, "."),
                          currencyOptionsEdit,
                        )
                      : null,
                  count: ware.byOutlets[0].count
                    ? $filter("wareCount")(
                        ware.byOutlets[0].count,
                        $rootScope.decSeparator,
                        false,
                      )
                    : 0,
                  criticalCount: angular.isNumber(
                    ware.byOutlets[0].criticalCount,
                  )
                    ? $filter("wareCount")(
                        ware.byOutlets[0].criticalCount,
                        $rootScope.decSeparator,
                        false,
                      )
                    : null,
                };
              }
            } else {
              if (ware.byOutlets.length > 1) {
                goodsById = function () {
                  angular.forEach(ware.byOutlets, function (outlet, i) {
                    cloneByOutletsShop.push({
                      variantArticle: outlet.article,
                      variantValues: outlet.variantValues,
                      outletId: outlet.outletId,
                      outletName: outlet.outletName,
                      enable: outlet.enable,
                      modified: outlet.modified,
                      freePrice: outlet.freePrice,
                      salePrice: !outlet.freePrice
                        ? serviceMinMaxValues.getValidValue(
                            coinToCurrency(outlet.salePrice, "."),
                            currencyOptionsEdit,
                          )
                        : null,
                      count: angular.isNumber(outlet.count)
                        ? $filter("wareCount")(outlet.count, ".", false)
                        : 0,
                      criticalCount: angular.isNumber(outlet.criticalCount)
                        ? $filter("wareCount")(outlet.criticalCount, ".", false)
                        : null,
                    });
                  });
                  return cloneByOutletsShop;
                };
                goodsById();
              } else {
                cloneByOutletsShop[0] = {
                  variantArticle: ware.byOutlets[0].article,
                  variantValues: ware.byOutlets[0].variantValues,
                  outletId: ware.byOutlets[0].outletId,
                  outletName: ware.byOutlets[0].outletName,
                  enable: ware.byOutlets[0].enable,
                  modified: ware.byOutlets[0].modified,
                  freePrice: ware.byOutlets[0].freePrice,
                  salePrice:
                    angular.isNumber(ware.byOutlets[0].salePrice) &&
                    ware.byOutlets[0].salePrice >= 0 &&
                    !ware.byOutlets[0].freePrice
                      ? serviceMinMaxValues.getValidValue(
                          coinToCurrency(ware.byOutlets[0].salePrice, "."),
                          currencyOptionsEdit,
                        )
                      : null,
                  count: ware.byOutlets[0].count
                    ? $filter("wareCount")(ware.byOutlets[0].count, ".", false)
                    : 0,
                  criticalCount: angular.isNumber(
                    ware.byOutlets[0].criticalCount,
                  )
                    ? $filter("wareCount")(
                        ware.byOutlets[0].criticalCount,
                        ".",
                        false,
                      )
                    : null,
                };
              }
            }

            ware._salePrice = ware.freePrice
              ? ""
              : serviceMinMaxValues.getValidValue(
                  coinToCurrency(ware.salePrice, "."),
                  currencyOptionsEdit,
                );
            ware._limitComponent = 3 < ware.ingredientLevel;
            ware._showCanNotBeComponentWare = false;
            ware.keepCount = !!ware.keepCount;
            ware._primeCost =
              ware.primeCost !== null
                ? serviceMinMaxValues.getValidValue(
                    coinToCurrency(ware.primeCost, ".", false),
                    currencyOptionsEdit,
                  )
                : "0.00";
            ware._complex = ware.type === "COMPLEX";
            ware.useProduction = ware._complex ? ware.useProduction : false;
            ware.didntProductedBefore = !angular.copy(ware.useProduction);
            ware._outlets = cloneByOutletsShop;

            ware.colorName = ware.colorName.toUpperCase();

            if (ware.ingredients) {
              angular.forEach(ware.ingredients, function (ingradient) {
                ingradient._quantity = $filter("wareCount")(
                  ingradient.quantity,
                  ".",
                  false,
                );
              });
            }

            initialStateOfWare = angular.copy(ware);
          }
        });
    } else {
      a = currencyFormat
        .getCurrencyOptionsEdit()
        .then((currencyOptionsEdit) => {
          return $q(function (resolve, reject) {
            getOutletsList.getShortOutlets({}).then(function (outlets) {
              let _ware = {
                _radio: "color",
                _salePrice: null,
                _count: "0",
                _primeCost: "0.00",
                _complex: false,
                _criticalCount: null,
                _idealStock: null,
                useProduction: false,
                divisible: false,
                wareCategoryId: null,
                keepCount: false,
                barcode: "",
                article: "",
                count: 0,
                taxIds: null,
                ingredients: [],
              };

              // добалено из-за того, что не правильно сохранялось значение freePrice в мультишопе
              if (outlets.length > 1) {
                for (let i = 0; i < outlets.length; i++) {
                  cloneByOutletsShop.push({
                    variantArticle: null,
                    variantValues: [],
                    outletId: outlets[i].id,
                    outletName: outlets[i].name,
                    enable: true,
                    freePrice: _ware._salePrice === null,
                    salePrice:
                      angular.isNumber(_ware.salePrice) && !_ware.freePrice
                        ? serviceMinMaxValues.getValidValue(
                            coinToCurrency(_ware.salePrice, "."),
                            currencyOptionsEdit,
                          )
                        : null,
                    count: angular.isNumber(_ware._count)
                      ? $filter("wareCount")(_ware._count, ".", false)
                      : 0,
                    criticalCount: angular.isNumber(_ware._criticalCount)
                      ? $filter("wareCount")(_ware._criticalCount, ".", false)
                      : null,
                  });
                }
              } else {
                // добалено из-за того, что не правильно сохранялось значение freePrice в мультишопе
                cloneByOutletsShop[0] = {
                  variantArticle: null,
                  variantValues: [],
                  outletId: outlets[0].id,
                  outletName: outlets[0].name,
                  enable: true,
                  freePrice: _ware._salePrice === null,
                  salePrice:
                    angular.isNumber(_ware.salePrice) && !_ware.freePrice
                      ? serviceMinMaxValues.getValidValue(
                          coinToCurrency(_ware.salePrice, "."),
                          currencyOptionsEdit,
                        )
                      : null,
                  count: angular.isNumber(_ware._count)
                    ? $filter("wareCount")(_ware._count, ".", false)
                    : 0,
                  criticalCount: angular.isNumber(_ware._criticalCount)
                    ? $filter("wareCount")(_ware._criticalCount, ".", false)
                    : null,
                };
              }
              _ware._outlets = cloneByOutletsShop;
              $http.post("/data/ownercab/getautoarticle", {}).then(
                function (d) {
                  if (d.data.result === "ok") {
                    _ware.autoWareArticle = d.data.autoWareArticle;
                    _ware.article = d.data.autoWareArticle;
                  }
                  resolve(_ware);
                },
                function (err) {
                  console.log(err);
                  resolve(_ware);
                },
              );
            });
          });
        });
    }
    let promiseArr = [];
    promiseArr.push(a);

    /*** добавляем список налогов ***/
    let b = getTaxList.getTaxListData({});
    promiseArr.push(b);

    /*** добавляем список заведений ***/
    let c = getShortOutlets({});
    promiseArr.push(c);

    /*** добавляем список модификаторов ***/
    let d;
    if (!id) {
      d = promiseOperation.post("/data/ownercab/getmodifiers", {});
      promiseArr.push(d);
    }

    return $q.all(promiseArr);
  }

  function getWare(id) {
    return getWareById(id).then(function (arr) {
      let taxes = angular.copy(arr[1].taxes);
      let ware = arr[0];
      ware._taxes = taxes;

      ware._outletsList = arr[2];
      ware._multishop = arr[2].length > 1;

      if (arr[3]) {
        ware.modifiers = arr[3].modifiers;
      }
      let i = taxes.length;
      while (0 < i--) {
        taxes[i]._select = false;
        if (angular.isArray(ware.taxIds)) {
          let k = ware.taxIds.length;
          while (0 < k--) {
            if (ware.taxIds[k] == taxes[i].id) {
              taxes[i]._select = true;
            }
          }
        } else if (!id && taxes[i].applyForAllNewWares) {
          taxes[i]._select = true;
        }
      }
      return ware;
    });
  }

  function editWare(ware) {
    let byOutletsShop = [];
    let salePriceForShop;
    let freePriceForShop;

    if (ware.variations.options.length) {
      ware._variantsByOutlets.forEach((outlet) => {
        byOutletsShop.push({
          variantArticle: outlet.variantArticle || outlet.article,
          variantValues: outlet.variantValues || outlet.values,
          outletId: outlet.outletId || outlet.id,
          enable: outlet.enable,
          modified: outlet.modified,
          stockChanged: outlet.stockChanged,
          freePrice: outlet.salePrice === null || outlet.salePrice === "",
          salePrice:
            outlet.salePrice &&
            outlet.salePrice !== "" &&
            outlet.salePrice !== null
              ? currencyToCoin(outlet.salePrice)
              : null,
          count:
            (ware.keepCount || ware.useProduction) && outlet.count
              ? $filter("wareCount")(outlet.count, ".", true)
              : 0,
          criticalCount:
            (ware.keepCount || ware.useProduction) && outlet.criticalCount
              ? $filter("wareCount")(outlet.criticalCount, ".", true)
              : null,
          idealStock:
            (ware.keepCount || ware.useProduction) && outlet.idealStock
              ? $filter("wareCount")(outlet.idealStock, ".", true)
              : null,
        });
      });
      salePriceForShop =
        ware._salePrice && ware._salePrice !== "" && ware._salePrice !== null
          ? currencyToCoin(ware._salePrice)
          : ware._variantsByOutlets[0].salePrice !== "" &&
            ware._variantsByOutlets[0].salePrice !== null &&
            ware._variantsByOutlets.length === 1
          ? currencyToCoin(ware._variantsByOutlets[0].salePrice)
          : null;
      freePriceForShop = salePriceForShop === "" || salePriceForShop === null;
    }
    if (!ware.variations.options.length) {
      ware._outlets.forEach((outlet) => {
        byOutletsShop.push({
          variantArticle: outlet.variantArticle || outlet.article,
          variantValues: outlet.variantValues || outlet.values,
          outletId: outlet.outletId || outlet.id,
          enable: outlet.enable,
          modified: outlet.modified,
          stockChanged: outlet.stockChanged,
          freePrice: outlet.salePrice === null || outlet.salePrice === "",
          salePrice:
            outlet.salePrice &&
            outlet.salePrice !== "" &&
            outlet.salePrice !== null
              ? currencyToCoin(outlet.salePrice)
              : null,
          count:
            (ware.keepCount || ware.useProduction) && outlet.count
              ? $filter("wareCount")(outlet.count, ".", true)
              : 0,
          criticalCount:
            (ware.keepCount || ware.useProduction) && outlet.criticalCount
              ? $filter("wareCount")(outlet.criticalCount, ".", true)
              : null,
          idealStock:
            (ware.keepCount || ware.useProduction) && outlet.idealStock
              ? $filter("wareCount")(outlet.idealStock, ".", true)
              : null,
        });
      });
      salePriceForShop =
        ware._salePrice && ware._salePrice !== "" && ware._salePrice !== null
          ? currencyToCoin(ware._salePrice)
          : ware._outlets[0].salePrice !== "" &&
            ware._outlets[0].salePrice !== null &&
            ware._outlets.length === 1
          ? currencyToCoin(ware._outlets[0].salePrice)
          : null;
      freePriceForShop = salePriceForShop === "" || salePriceForShop === null;
    }

    return $q(function (resolve, reject) {
      let taxIds = [];
      for (let i = 0; i < ware._taxes.length; i++) {
        if (ware._taxes[i]._select) {
          taxIds.push(ware._taxes[i].id);
        }
      }

      const body = {
        salePrice: salePriceForShop,
        freePrice: freePriceForShop,
        byOutlets: byOutletsShop,
        variations: ware.variations,
        id: ware.id,
        colorName: ware.colorName ?? "GREY",
        representationOnPOS:
          ware._radio === "color" ? "COLOR_AND_SHAPE" : "IMAGE",
        defaultSupplier:
          ware.defaultSupplier && ware.keepCount ? ware.defaultSupplier : null,
        purchaseCost:
          ware.purchaseCost !== null &&
          ware.purchaseCost !== "" &&
          ware.keepCount
            ? currencyToCoin(parseFloat(ware.purchaseCost), 10)
            : null,
        name: ware.name,
        shape: ware.shape ?? "ROUNDED_SQUARE",
        article: ware.variations.variants.length > 0 ? null : ware.article,
        wareCategoryId: ware.wareCategoryId === 0 ? null : ware.wareCategoryId,
        keepCount:
          (!ware._complex && ware.keepCount) ||
          (ware._complex && ware.useProduction)
            ? 1
            : 0,
        useProduction: ware.useProduction,
        waresCount: null,
        // average: ware.average,
        barcode: ware.barcode,
        limit: pager.limit,
        taxIds: taxIds.length ? taxIds : null,
        divisible: ware.divisible,
        primeCost: angular.isEmpty(ware._primeCost)
          ? null
          : currencyToCoin(ware._primeCost),
        type: ware._complex ? "COMPLEX" : "SIMPLE",
        description: ware.description,
        useDiningOptions: ware.useDiningOptions,
        modifierIds: (function () {
          let arr = [];
          let i = 0;
          while (i < ware.modifiers.length && angular.isArray(ware.modifiers)) {
            if (ware.modifiers[i].selected) {
              arr.push(ware.modifiers[i].id);
            }
            i++;
          }
          return arr;
        })(),
        ingredients: (function () {
          let ingredients = [];
          angular.forEach(ware.ingredients, function (ingredient, i) {
            ingredients.push({
              id: ingredient.id,
              quantity: ingredient._quantity
                ? $filter("wareCount")(ingredient._quantity, ".", true)
                : null,
            });
          });
          return ingredients;
        })(),
        imageDeleted: ware.imageDeleted
      };

      $http
        .post("/data/ownercab/editware", formatWareForm(body, ware.imageBlob), {
          headers: factoryForms.formDataHeaders,
        })
        .then(
          function (d) {
            resolve(d.data);
          },
          function (err) {
            reject(err);
          },
        );
    });
  }

  // получаем краткий список торговых точек
  function getShortOutlets(params) {
    return $q(function (resolve, reject) {
      $http.post("/data/ownercab/getshortoutlets", params).then(
        function (response) {
          if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
              resolve(response.data.outlets);
            }
          }
        },
        function (error) {
          factoryErrorAlert.onError(error);
          reject(error);
        },
      );
    });
  }
  // получаем краткий список торговых точек

  // получаем список поставщиков
  function getSuppliers(params) {
    return $q(function (resolve, reject) {
      $http.post("/data/ownercab/getSuppliers", params).then(
        function (response) {
          if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
              resolve(response.data.suppliers);
            }
          }
        },
        function (error) {
          factoryErrorAlert.onError(error);
          reject(error);
        },
      );
    });
  }
  // получаем список поставщиков

  // получаем список поставщиков
  function deleteUnusedImages(params) {
    return $q(function (resolve, reject) {
      $http.post("/data/ownercab/deleteWareImgs", params).then(
        function (response) {
          if (angular.isObject(response.data)) {
            if (response.data.result === "ok") {
              resolve(response.data);
            }
          }
        },
        function (error) {
          factoryErrorAlert.onError(error);
          reject(error);
        },
      );
    });
  }
  // получаем список поставщиков

  //!!!!!! БЛОК КОДА ДЛЯ ПОЛУЧЕНИЯ ДАННЫХ ОБ УСПЕШНОМ ИЛИ НЕТ ИМПОРТЕ ТОВАРОВ В БЕК-ОФИСЕ !!!!!!//
  let waresProcessStatus = {
    isDone: null,
    exists: false,
    completed: null,
    waresFileProcessorResult: null,
  };

  function getWaresProcessStatus() {
    return $q(function (resolve, reject) {
      $http
        .post("/data/ownercab/getdataprocessstatus", {
          importFileType: "WARES",
        })
        .then(
          function (d) {
            for (let opt in d.data) {
              waresProcessStatus[opt] = d.data[opt];
            }
            resolve(waresProcessStatus);
          },
          function (err) {
            console.log(err);
            reject(err);
            factoryErrorAlert.onError(err);
          },
        );
    });
  }

  let timePromiseWhenImports;
  function getTimePromiseWhenImports() {
    timePromiseWhenImports = $timeout(function () {
      getWaresProcessStatus().then(
        function (d) {
          // console.log("d in getTimePromiseWhenImports = ", d);

          if (d.exists && !d.isDone) {
            getTimePromiseWhenImports();
          } else if (d.exists && d.isDone && d.success === true) {
            dialogEndProcessShow(true, d.inputFileName);
          } else if (d.exists && d.isDone && d.success === false) {
            dialogEndProcessShowError();
          } else {
            if (
              $state.current.name === "goods.import" ||
              $state.current.name === "goods.price"
            ) {
              $state.go(
                "goods.price",
                {
                  page: 0,
                  limit: 10,
                  inventory: "all",
                  outletId: $stateParams.outletId,
                },
                { reload: true },
              );
            } else if ($state.current.name === "settings.outlet") {
              $state.go("settings.outlet", $stateParams, { reload: true });
            }
          }
        },
        function (err) {
          console.log(err);
          getTimePromiseWhenImports();
        },
      );
    }, 1000);
    return timePromiseWhenImports;
  }

  // диалоговое окно при успешном импорте товаров (d.result === "ok")
  function dialogEndProcessShow(stateGo, fileName) {
    // console.log("dialogEndProcessShow $state.current.name = ", $state.current.name);
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/dialog-list-gods-update.html",
      content: "GODS_SUCCESS_UPDATE",
      contentPrefix: "IMPORT_SUCCESS_PREFIX",
      contentSuffix: "IMPORT_SUCCESS_SUFFIX",
      fileName: fileName,
      class: "success-update-gods",
      buttons: [
        {
          class: "primary",
          text: "ON_OK",
          action: function () {
            if (
              $state.current.name === "goods.import" ||
              $state.current.name === "goods.price"
            ) {
              $state.go(
                "goods.price",
                {
                  page: 0,
                  limit: 10,
                  inventory: "all",
                  outletId: $stateParams.outletId,
                },
                { reload: true },
              );
            } else if ($state.current.name === "settings.outlet") {
              $state.go("settings.outlet", $stateParams, { reload: true });
            }
          },
        },
      ],
    });
  }

  // диалоговое окно при ОШИБКЕ импорта товаров (d.result === "error")
  function dialogEndProcessShowError() {
    serviceDialog.add({
      templateUrl:
        "dashboard-page/templates/dialog/dialog-list-gods-update-error.html",
      buttons: [
        {
          class: "primary",
          text: "ON_OK",
          action: function () {
            if (
              $state.current.name === "goods.import" ||
              $state.current.name === "goods.price"
            ) {
              $state.go(
                "goods.price",
                {
                  page: 0,
                  limit: 10,
                  inventory: "all",
                  outletId: $stateParams.outletId,
                },
                { reload: true },
              );
            } else if ($state.current.name === "settings.outlet") {
              $state.go("settings.outlet", $stateParams, { reload: true });
            }
          },
        },
      ],
    });
  }

  function processWaresFile() {
    return $q(function (resolve, reject) {
      $http.post("/data/ownercab/processwaresfile", null).then(
        function (d) {
          resolve(d.data);
        },
        function (err) {
          reject(err);
        },
      );
    });
  }

  //!!!!!! БЛОК КОДА ДЛЯ ПОЛУЧЕНИЯ ДАННЫХ ОБ УСПЕШНОМ ИЛИ НЕТ ИМПОРТЕ ТОВАРОВ В БЕК-ОФИСЕ !!!!!!//

  return {
    getWaresWithCategories: getWaresWithCategories,
    getWaresProcessStatus: getWaresProcessStatus,
    dialogEndProcessShow: dialogEndProcessShow,
    processWaresFile: processWaresFile,
    getTimePromiseWhenImports: getTimePromiseWhenImports,
    editWareInline: editWareInline,
    deleteWares: deleteWares,
    searchWare: searchWare,
    createWare: createWare,
    getWares: getWares,
    getVariantsByWareId: getVariantsByWareId,
    editWare: editWare,
    editVariantInline: editVariantInline,
    loading: _loading,
    getWare: getWare,
    pager: pager,
    getShortOutlets: getShortOutlets,
    getAutoArticles: getAutoArticles,
    getSuppliers: getSuppliers,
    dialogEndProcessShowError: dialogEndProcessShowError,
    deleteUnusedImages: deleteUnusedImages,
  };
}

export default {
  type: "factory",
  name: "factoryGoods",
  value: factoryGoods,
};
