import angular from 'angular';

imgCropper.$inject = [];
function imgCropper() {
	return {
		restrict: 'A',
		scope: {
			imgSrc: '=imgCropper',
			editBitmap: '=',
			scaleFactor: '='
		},
		link: function ( scope, el, attr, contrl ) {

			var imgArea = new Image();
			scope.bitmap = scope.editBitmap;
			var borderWidth = 4;
			var imgOld;
			var ww = scope.scaleFactor ? scope.scaleFactor * 200 : 400;
			var areaSize = {
				width: ww,
				height: 200,
				factor: scope.scaleFactor || 2,
				offsetTop: 0,
				offsetLeft: 0
			};
			scope.$watch( 'imgSrc', function ( val ) {
				if ( val ) {
					if ( val.toString() === 'untouched' ) {
						val = null;
						scope.imgSrc = null;
						return;
					}
					setImg();
				}
			} );
			function setImg() {
				var img = new Image();

				el[0].style.position = 'relative';
				var mask = document.createElement( 'div' );
				var t1 = document.createElement( 'div' ), t2 = document.createElement( 'div' ), t3 = document.createElement( 'div' );
				mask.appendChild( t1 );
				t1.appendChild( t2 );
				t2.appendChild( t3 );
				mask.className = 'mask-preload';
				mask.style.position = 'absolute';
				el[0].appendChild( mask );

				mask.className += " show";

				img.onload = function () {
					var eljs = el[0];
					if ( eljs.childNodes.length ) {
						for ( var i = 0; i < eljs.childNodes.length; i++ ) {
							if ( eljs.childNodes[i] != mask ) {
								eljs.removeChild( eljs.childNodes[i] );
							}
						}
					}
					el[0].appendChild( img );
					var stringShow = mask.className;
					stringShow = stringShow.replace( 'show', '' );
					mask.className = stringShow;
					setTimeout( function () {
						el[0].removeChild( mask );
					}, 222 );
					imgOld = img;
					//setImgAreaBackground();
					scope.bitmap.naturalHeight = img.naturalHeight;
					scope.bitmap.naturalWidth = img.naturalWidth;
					scope.bitmap.clientWidth = img.clientWidth;
					scope.bitmap.clientHeight = img.clientHeight;
					scope.bitmap.scale = img.clientWidth / img.naturalWidth;
					scope.bitmap.img = img;


					var container  = imgOld.parentNode.parentNode;

					if(img.naturalWidth/img.naturalHeight < container.clientWidth / container.clientHeight   ){
						img.style.height = '100%';
						img.style.width = 'auto';
					}
					setSizers();
					addMask();
					addImgArea();
				};


				function setSizers(){
					areaSize.width = imgOld.clientWidth;
					areaSize.height = areaSize.width/scope.scaleFactor;

					if(imgOld.clientHeight<areaSize.height){
						areaSize.height = imgOld.clientHeight;
						areaSize.width = parseInt(imgOld.clientHeight*scope.scaleFactor)
					}

					areaSize.offsetTop = parseInt((imgOld.clientHeight - areaSize.height)/2);
					areaSize.offsetLeft = parseInt( (imgOld.clientWidth - areaSize.width)/2  );
					var parent = imgOld.parentNode;
					imgOld.parentNode.style.width = imgOld.clientWidth + 'px';
					imgOld.parentNode.style.height = imgOld.clientHeight + 'px';
				}



				img.onerror = function () {
					var k = scope.imgSrc;
					scope.imgSrc = 'data:image/jpeg;base64,' + k;
					el[0].removeChild( mask );
				};
				if ( 1000 < scope.imgSrc.toString().length ) {

					if ( !/data:image/.test( scope.imgSrc ) ) {
						var k = scope.imgSrc;
						scope.imgSrc = 'data:image/jpeg;base64,' + k;
						el[0].removeChild( mask );
					} else {
						img.src = scope.imgSrc;
					}
				} else {
					img.src = scope.prefix ? '' + scope.prefix + scope.imgSrc : scope.imgSrc;
				}

				imgArea.src = scope.imgSrc;
			}


			function addMask() {
				var mask = angular.element( document.createElement( 'div' ) );
				mask.css( 'width', '100%' );
				mask.css( 'height', '100%' );
				mask.css( 'position', 'absolute' );
				mask.css( 'left', '0' );
				mask.css( 'top', '0' );
				mask.css( 'background', '#000' );
				mask.css( 'opacity', '0.7' );
				el.append( mask );
			}

			function addImgArea() {
				var divArea = angular.element( document.createElement( 'div' ) );
				var sizefactor = scope.scaleFactor || 2;





				divArea.css( 'width', areaSize.width + "px" );
				divArea.css( 'height', areaSize.height + 'px' );
				divArea.css( 'position', 'absolute' );
				divArea.css( 'left', areaSize.offsetLeft+ 'px' );
				divArea.css( 'top', areaSize.offsetTop +'px' );
				divArea.css( 'background', "url('" + scope.imgSrc + "') no-repeat" );
				divArea.addClass( 'cursor-move' );
				divArea.css( 'backgroundPosition', "-"+areaSize.offsetLeft +  'px -' +  areaSize.offsetTop + 'px' );


				var borderRight = angular.element( document.createElement( 'div' ) );
				borderRight.css( 'width', borderWidth + "px" );
				borderRight.css( 'height', '100%' );
				borderRight.css( 'position', 'absolute' );
				borderRight.css( 'right', '0' );


				borderRight.css( 'background', 'rgba(255, 255, 255, 0.7)' );
				borderRight.css( 'cursor', "e-resize" );

				var borderLeft = angular.element( document.createElement( 'div' ) );
				borderLeft.css( 'width', borderWidth + "px" );
				borderLeft.css( 'height', '100%' );
				borderLeft.css( 'position', 'absolute' );
				borderLeft.css( 'left', '0' );
				borderLeft.css( 'background', 'rgba(255, 255, 255, 0.7)' );
				borderLeft.css( 'cursor', "w-resize" );


				var borderTop = angular.element( document.createElement( 'div' ) );
				borderTop.css( 'width', '100%' );
				borderTop.css( 'height', borderWidth + 'px' );
				borderTop.css( 'position', 'absolute' );
				borderTop.css( 'left', '0' );
				borderTop.css( 'top', '0' );
				borderTop.css( 'background', 'rgba(255, 255, 255, 0.7)' );
				borderTop.css( 'cursor', 'n-resize' );


				var borderBottom = angular.element( document.createElement( 'div' ) );
				borderBottom.css( 'width', '100%' );
				borderBottom.css( 'height', borderWidth + 'px' );
				borderBottom.css( 'position', 'absolute' );
				borderBottom.css( 'left', '0' );
				borderBottom.css( 'bottom', '0' );
				borderBottom.css( 'background', 'rgba(255, 255, 255, 0.7)' );
				borderBottom.css( 'cursor', 's-resize' );

				var helpText =  angular.element( document.createElement( 'div' ) );
				helpText.css( 'position', 'absolute' );
				helpText.css( 'right', borderWidth+1+"px" );
				helpText.css( 'top', borderWidth+1+"px" );
				helpText.css( 'color', 'white' );
				helpText.css( 'textSize', "12px" );

				var width, height;
				width = imgOld.offsetWidth;
				height = imgOld.offsetHeight;
				divArea.css( 'backgroundSize', width + 'px ' + height + 'px' );
				//divArea.css( 'backgroundSize', width + 'px ' + height + 'px' );

				el.append( divArea );
				divArea.append( borderRight );
				divArea.append( borderLeft );
				divArea.append( borderTop );
				divArea.append( borderBottom );
				divArea.append( helpText );

				var permitsMove = true;
				scope.bitmap.areaPosition = {
					left: areaSize.offsetLeft,
					top: areaSize.offsetTop
				};
				scope.bitmap.areaSize = {
					width: areaSize.width,
					height: areaSize.height
				};

				var actualRatio = imgOld.clientWidth/imgOld.naturalWidth;
				var minSize = parseInt(16*actualRatio +1);
				// console.log("minSize=" + minSize);

				function logSize(){
					var width = parseInt(borderTop[0].clientWidth/actualRatio);
					var height = parseInt(borderLeft[0].clientHeight/actualRatio);
					helpText[0].innerHTML = width +"px/"+height+"px";
				}
				logSize();
				/** События перемещения выбранного пространства*/
				(function ( divArea ) {
					var body = angular.element( document.body );
					var pageX = null;
					var pageY = null;
					var left = parseInt( divArea.css( 'left' ) );
					var top = parseInt( divArea.css( 'top' ) );
					scope.isMobile = scope.$root.isMobile;



					function mouseMove( e ) {
						if(!scope.isMobile) {
							pageX = pageX || e.pageX;
							pageY = pageY || e.pageY;

							//console.log(pageX);

							var deltaX = e.pageX - pageX;
							var deltaY = e.pageY - pageY;
						} else {
							pageX = pageX || e.originalE0vent.targetTouches[0].pageX;
							pageY = pageY || e.originalEvent.targetTouches[0].pageY;

							//console.log(pageX);

							var deltaX = e.originalEvent.targetTouches[0].pageX - pageX;
							var deltaY = e.originalEvent.targetTouches[0].pageY - pageY;
						}
						left += deltaX;
						top += deltaY;
						if ( left < 0 ) {
							left = 0;
						}
						if ( top < 0 ){
							top = 0;
						}
						if ( imgOld.clientWidth < left + divArea[0].clientWidth ) {
							left = imgOld.clientWidth - divArea[0].clientWidth;
						}

						if ( imgOld.clientHeight < top + divArea[0].clientHeight ) {
							top = imgOld.clientHeight - divArea[0].clientHeight;
						}


						divArea[0].style.left = left + "px";
						divArea[0].style.top = top + "px";

						var L;
						var T;
						if ( 0 <= left ) {
							L = "-" + left;
						} else {
							L = -left + "";
						}
						if ( 0 <= top ) {
							T = "-" + top;
						} else {
							T = -top + "";
						}
						divArea.css( 'backgroundPosition', " " + L + 'px ' + T + 'px' );
						if(!scope.isMobile) {
							pageX = e.pageX;
							pageY = e.pageY;
						} else {
							pageX = e.originalEvent.targetTouches[0].pageX;
							pageY = e.originalEvent.targetTouches[0].pageY;
						}

						scope.bitmap.areaPosition.left = left;
						scope.bitmap.areaPosition.top = top;

					}

					function addMouseMove() {
						if ( permitsMove && !scope.isMobile) {
							body.off( 'mouseup', mouseBodyUp );
							body.on( 'mouseup', mouseBodyUp );
							body.on( 'mousemove', mouseMove );
						} else if (permitsMove && scope.isMobile){
							body.off( 'touchend', mouseBodyUp );
							body.on( 'touchend', mouseBodyUp );
							body.on( 'touchmove', mouseMove );
						}
					}

					function startMove(e){
						if(!scope.isMobile) {
							pageY = e.pageY;
							pageX = e.pageX;
						} else {
							pageY = e.originalEvent.targetTouches[0].pageY;
							pageX = e.originalEvent.targetTouches[0].pageX;
						}
						left = parseInt( divArea.css( 'left' ) );
						top = parseInt( divArea.css( 'top' ) );
						addMouseMove();
					}

					function mouseBodyUp() {
						//console.log('mouse up');
						if(!scope.isMobile) {
							body.off('mousemove', mouseMove);
							body.off('mouseup', mouseBodyUp);
							divArea
								.off('mousedown', startMove);
							divArea
								.on('mousedown', startMove);
						} else if (scope.isMobile){
							body.off('touchmove', mouseMove);
							body.off('touchend', mouseBodyUp);
							divArea
								.off('touchstart', startMove);
							divArea
								.on('touchstart', startMove);
						}

					}

					if(!scope.isMobile) {
						divArea
							.on('mousedown', startMove);
					} else if (scope.isMobile) {
						divArea
							.on('touchstart', startMove);
					}


				}( divArea ));

				/** События перемешения правого бордера */
				(function ( el ) {
					var body = angular.element( document.body );

					function listenerUp( e ) {
						permitsMove = false;
						if(!scope.isMobile) {
							divArea.off('mousemove');

							body.on('mouseup', mouseUp);
							body.on('mousemove', mouseMove);
						} else if (scope.isMobile) {
							divArea.off('touchmove');

							body.on('touchend', mouseUp);
							body.on('touchmove', mouseMove);
						}

						function mouseUp() {
							permitsMove = true;
							if(!scope.isMobile) {
								body.off('mouseup', mouseUp);
								body.off('mousemove', mouseMove);
							} else if (scope.isMobile) {
								body.off('touchend', mouseUp);
								body.off('touchmove', mouseMove);
							}
						}

						var pageX;
						var width = parseInt( divArea.css( 'width' ) );
						var height = parseInt( divArea.css( 'height' ) );

						function mouseMove( e ) {
							if(!scope.isMobile) {
								pageX = pageX || e.pageX;
								var dx = e.pageX - pageX;
								pageX = e.pageX;
							} else {
								pageX = pageX || e.originalEvent.targetTouches[0].pageX;
								var dx = e.originalEvent.targetTouches[0].pageX - pageX;
								pageX = e.originalEvent.targetTouches[0].pageX;
							}
							width += dx;

							if ( imgOld.clientWidth < divArea[0].offsetLeft + divArea[0].clientWidth ) {
								width = imgOld.clientWidth - divArea[0].offsetLeft;
							}

							if (width < minSize) {
								width = minSize
							}

							height = (1 / sizefactor) * width;

							if ( imgOld.clientHeight < divArea[0].offsetTop + height ) {
								height = imgOld.clientHeight - divArea[0].offsetTop;
								width = height * sizefactor;
							}

							divArea.css( 'width', width + 'px' );
							divArea.css( 'height', height + 'px' );
							scope.bitmap.areaSize.width = width;
							scope.bitmap.areaSize.height = height;
							// console.log("right=" + width);
							logSize();
						}
					}

					if(!scope.isMobile) {
						el
							.on('mousedown', listenerUp);
					} else if (scope.isMobile){
						el
							.on('touchstart', listenerUp);
					}
				}( borderRight ));

				/** События перемешения левого бордера */
				(function ( el ) {
					var body = angular.element( document.body );
					var pageX;
					var left, width, height, backgroundPosition;
					function listenerUp( e ) {
						permitsMove = false;
						//divArea.off( 'mousemove' );
						if(!scope.isMobile) {
							body.on('mouseup', mouseUp);
						} else {
							body.on('touchend', mouseUp);
						}
						width = parseInt( divArea.css( 'width' ) );
						height = parseInt( divArea.css( 'height' ) );
						left = parseInt( divArea.css( 'left' ) );
						backgroundPosition = {
							x: parseInt( divArea.css( 'backgroundPosition' ).split( " " )[0] ),
							y: parseInt( divArea.css( 'backgroundPosition' ).split( " " )[1] )
						};
						if(!scope.isMobile) {
							pageX = e.pageX;
							body.on('mousemove', mouseMove);
						} else {
							pageX = e.originalEvent.targetTouches[0].pageX;
							body.on('touchmove', mouseMove);
						}
					}
					function mouseMove( e ) {
						if(!scope.isMobile) {
							pageX = pageX || e.pageX;
							var dx = e.pageX - pageX;
							pageX = e.pageX;
						} else {
							pageX = pageX || e.originalEvent.targetTouches[0].pageX;
							var dx = e.originalEvent.targetTouches[0].pageX - pageX;
							pageX = e.originalEvent.targetTouches[0].pageX;
						}
						left += dx;
						if ( left < 0 ) {
							left = 0;
							dx = 0;
							backgroundPosition.x = 0;
						} else {
							backgroundPosition.x -= dx;
						}
						width -= dx;
						if (width < minSize) {
							width = minSize
						}
						height = (1 / sizefactor) * width;
						if ( imgOld.clientHeight < divArea[0].clientHeight + divArea[0].offsetTop ) {
							height = imgOld.clientHeight - divArea[0].offsetTop;
							width = height * sizefactor;
							backgroundPosition.x = -divArea[0].offsetLeft;
							left -= dx;
						}
						divArea.css( 'width', width + 'px' );
						divArea.css( 'height', height + 'px' );
						divArea.css( 'left', left + 'px' );
						divArea.css( 'backgroundPosition', " " + backgroundPosition.x + 'px ' + backgroundPosition.y + 'px' );

						scope.bitmap.areaSize.width = width;
						scope.bitmap.areaSize.height = height;
						scope.bitmap.areaPosition.left = left;
						// console.log("left=" + width);
						logSize();
					}
					function mouseUp() {
						permitsMove = true;
						if(!scope.isMobile) {
							body.off('mouseup', mouseUp);
							body.off('mousemove', mouseMove);
						} else {
							body.off('touchend', mouseUp);
							body.off('touchmove', mouseMove);
						}
					}
					if(!scope.isMobile) {
						el
							.on('mousedown', listenerUp);
					} else {
						el
							.on('touchstart', listenerUp);
					}



				}( borderLeft ));


				/** События перемешения нижнего бордера */
				(function ( el ) {
					var body = angular.element( document.body );
					var pageY, left, width, height, backgroundPosition;
					function listenerUp( e ) {
						permitsMove = false;
						//divArea.off( 'mousemove' );
						width = parseInt( divArea.css( 'width' ) );
						height = parseInt( divArea.css( 'height' ) );
						if(!scope.isMobile) {
							pageY = e.pageY;
							body.on('mouseup', mouseUp);
							body.on('mousemove', mouseMove);
						} else {
							pageY = e.originalEvent.targetTouches[0].pageY;
							body.on('touchend', mouseUp);
							body.on('touchmove', mouseMove);
						}
					}

					function mouseMove( e ) {
						if(!scope.isMobile) {
							pageY = pageY || e.pageY;
							var dy = e.pageY - pageY;
							pageY = e.pageY;
						} else {
							pageY = pageY || e.originalEvent.targetTouches[0].pageY;
							var dy = e.originalEvent.targetTouches[0].pageY - pageY;
							pageY = e.originalEvent.targetTouches[0].pageY;
						}
						height += dy;
						if ( imgOld.clientHeight < height + divArea[0].offsetTop ) {
							height = imgOld.clientHeight - divArea[0].offsetTop;
						}

						if (height < minSize) {
							height = minSize
						}
						width = height * sizefactor;
						if ( imgOld.clientWidth < width + divArea[0].offsetLeft ) {
							width = imgOld.clientWidth - divArea[0].offsetLeft;
							height = width / sizefactor;
						}
						divArea.css( 'width', width + 'px' );
						divArea.css( 'height', height + 'px' );
						scope.bitmap.areaSize.width = width;
						scope.bitmap.areaSize.height = height;
						// console.log("bottom=" + width);
						logSize();
					}
					function mouseUp() {
						permitsMove = true;
						if(!scope.isMobile) {
							body.off('mouseup', mouseUp);
							body.off('mousemove', mouseMove);
						} else {
							body.off('touchend', mouseUp);
							body.off('touchmove', mouseMove);
						}
					}
					if(!scope.isMobile) {
						el
							.on('mousedown', listenerUp);
					} else {
						el
							.on('touchstart', listenerUp);
					}
				}( borderBottom ));


				/** События перемешения верхнего бордера */
				(function ( el ) {
					var body = angular.element( document.body );

					function listenerUp( e ) {
						permitsMove = false;
						var top;
						top = parseInt( divArea.css( 'top' ) );
						if(!scope.isMobile) {
							divArea.off('mousemove');
							body.on('mouseup', mouseUp);
							body.on('mousemove', mouseMove);
						} else {
							divArea.off('touchmove');
							body.on('touchend', mouseUp);
							body.on('touchmove', mouseMove);
						}
						function mouseUp() {
							permitsMove = true;
							if(!scope.isMobile) {
								body.off('mouseup', mouseUp);
								body.off('mousemove', mouseMove);
							} else {
								body.off('touchend', mouseUp);
								body.off('touchmove', mouseMove);
							}
						}

						var pageY;
						var width = parseInt( divArea.css( 'width' ) );
						var height = parseInt( divArea.css( 'height' ) );

						var backgroundPosition = {
							x: parseInt( divArea.css( 'backgroundPosition' ).split( " " )[0] ),
							y: parseInt( divArea.css( 'backgroundPosition' ).split( " " )[1] )
						};

						function mouseMove( e ) {
							if(!scope.isMobile) {
								pageY = pageY || e.pageY;
								var dy = e.pageY - pageY;
								pageY = e.pageY;
							} else {
								pageY = pageY || e.originalEvent.targetTouches[0].pageY;
								var dy = e.originalEvent.targetTouches[0].pageY - pageY;
								pageY = e.originalEvent.targetTouches[0].pageY;
							}
							height -= dy;
							top += dy;
							backgroundPosition.y -= dy;
							if (height < minSize) {
								height = minSize
							}
							width = height * sizefactor;

							if ( top < 0 ) {
								top = 0;
								backgroundPosition.y = 0;
								width = divArea[0].clientHeight * sizefactor;
							}

							if ( imgOld.clientWidth < divArea[0].offsetLeft + width ) {
								width = imgOld.clientWidth - divArea[0].offsetLeft;
								height = width / sizefactor;
							}

							divArea.css( 'width', width + 'px' );
							divArea.css( 'height', height + 'px' );
							divArea.css( 'top', top + "px" );
							divArea.css( 'backgroundPosition', " " + backgroundPosition.x + 'px ' + backgroundPosition.y + 'px' );


							scope.bitmap.areaSize = {
								width: width,
								height: height
							};
							scope.bitmap.areaPosition.top = top;
							// console.log("top=" + width);
							logSize();

						}
					}

					if(!scope.isMobile) {
						el
							.on('mousedown', listenerUp);
					} else {
						el
							.on('touchstart', listenerUp);
					}
				}( borderTop ));
			}
		}
	};
}

export default imgCropper;