/**
 * Created by A.Sirosh on 14.12.2015.
 * Edited by K.Yarosh on 02.05.2017
 */

import angular from 'angular';

clientDBController.$inject = [
    '$getClientsDB',
    'impExpRefs',
    'clientsDB',
    '$scope',
    '$state',
    '$filter',
    'serviceDialog',
    'serviceToast',
    '$stateParams',
    '$currencyOptionsShow',
    '$countOptionsShow',
    '$rootScope',
    '$profile',
    'siteLangCode',
    'factoryPermits',
    '$pointsCurrencyShow',
    'lvMixpanel',
    'dialogs'
];

function clientDBController(
    $getClientsDB,
    impExpRefs,
    clientsDB,
    $scope,
    $state,
    $filter,
    serviceDialog,
    serviceToast,
    $stateParams,
    $currencyOptionsShow,
    $countOptionsShow,
    $rootScope,
    $profile,
    siteLangCode,
    factoryPermits,
    $pointsCurrencyShow,
    lvMixpanel,
    dialogs) {


    var dt = new Date();
    var year = dt.getFullYear();
    var month = dt.getMonth() + 1;
    var date = dt.getDate();
    var translate = $filter('translate');
    var tz = jstz.determine();

    if (month < 10)
        month = '0' + month;
    if (date < 10)
        date = '0' + date;


    var vm = this;

    /*** формат отображения валюты ***/
    vm.currencyOptionsShow = $currencyOptionsShow;

    /*** формат отображения баланса баллов ***/
    vm.pointsCurrencyShow = $pointsCurrencyShow;

    /*** формат отображения колличества ***/
    vm.countOptionsShow = $countOptionsShow;
    $scope.editTableState = 'clients.customerdetail';
    var browserLang = window.navigator.languages && window.navigator.languages.length ? window.navigator.languages[0] : window.navigator.userLanguage ? window.navigator.userLanguage : window.navigator.language;
    if (browserLang.split("-").length === 2) {
        browserLang = browserLang.split("-")[1];
    }

    // данные для сохранение состояния фильтров
    $scope.stateFromList = $state.params;

    //Task # 19957 Change stubs on Advanced inventory screens
    // vm.plugsFilters = !!(!$scope.selectReason && !$scope.selectOutlet.id);
    vm.plugsSearch = !!$stateParams.search;

    vm.clients = $getClientsDB.clients;
    // console.log("vm.clients = ", vm.clients);
    vm.pages = $getClientsDB.pages;
    vm.noData = !vm.clients.length;
    vm.emptyState = !vm.clients.length && $stateParams.search === undefined;;

    vm.clients._select = false;
    vm.selectedClients = [];
    vm.selectId = {};
    vm.change = {};
    vm.dateString = year + "-" + month + "-" + date;
    vm.selectAll = selectAll;
    vm.selectClient = selectClient;
    $scope.showDel = false;
    $scope.onDel = onDel;

    vm.impExpHrefs = {
        impHref: 'clients.import',
        expHref:  '/data/ownercab/clientsbasedownload?dateStr='+ vm.dateString + '&tzName=' + tz.name() + '&browserLocal=' + browserLang
    };

    impExpRefs.setImpExpData(vm.impExpHrefs);


    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if (vm.clients.length == 0 && $stateParams.page > 0) {
        $state.go( 'clients.database', {page: vm.pages - 1} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ


    /**
     * @ngdoc function
     * @description
     * Adds _select property to the vm.clients with value specified by param.
     * @param {boolean} param value of the _select property
     */
    function selectAll() {
        if (vm.clients._select) {
            select(true);
            $scope.showDel = true;
            vm.selectedClients = vm.clients;
        } else {
            select(false);
            $scope.showDel = false;
            vm.selectedClients = [];
        }
    }


    function select(param) {
        var i,
            l = vm.clients.length;

        if (l) {
            for (i = 0; i < l; i++) {
                vm.clients[i]._select = param;
            }
        }

    }

    function selectClient(client) {
        if (client._select) {
            vm.selectedClients.push(client);
            $scope.showDel = true;

            if (vm.selectedClients.length === vm.clients.length) {
                vm.clients._select = true;
            }
        } else {
            vm.selectedClients = vm.selectedClients.filter(function (selectedClients) {
                return client.id !== selectedClients.id;
            });

            if (vm.clients._select) vm.clients._select = false;

            if (!vm.selectedClients.length) $scope.showDel = false;
        }
    }

    /**
     * @ngdoc function
     * @description
     * Sends delete request to the server with Ids of selected clients, triggers  success information message and
     * page reload.
     */
    function onDel() {
        if(!factoryPermits.editProfile) {
            return;
        }
        var selectedClientsIds = vm.selectedClients.map(function (client) {
                return client.id;
            }),
            deleteMessage = (selectedClientsIds.length > 1) ? 'CLIENTS.DB.DELETE_CLIENTS' : 'CLIENTS.DB.DELETE_CLIENT';

        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
            title: (selectedClientsIds.length > 1) ? translate('CLIENTS.DB.DELETE_CLIENTS_TITLE') : translate('CLIENTS.DB.DELETE_CLIENT_TITLE'),
            content: (selectedClientsIds.length > 1) ? translate('CLIENTS.DB.DELETE_CLIENTS_TEXT_NO_LO') :  translate('CLIENTS.DB.DELETE_CLIENT_TEXT_NO_LO'),
            buttons: [
                {
                    text: 'ON_CANCEL'
                },
                {
                    class: 'primary l-margin-left-8-i',
                    text: 'ON_DEL',
                    action: deleteClients
                }
            ]
        });

        function deleteClients() {
            clientsDB.deleteClients({
                clientsIds: selectedClientsIds
            }).then(function (result) {
                    if (result.result === "ok") {
                        serviceToast.show(deleteMessage, 'success');
                        refreshList();
                    }
                    if (result.result === 'error') {
                        if(result.message === 'customer_deleted') {
                            dialogs.listItemDeletedEarlier().then(refreshList);
                        }
                    }
                });
        }
    }


    //  for empty state, in Table button  +Create customer made like directive  table-search(
    $scope.onAdd = function () {
        $state.go('clients.createcustomer', {
            stateToSave: $scope.stateFromList,
            checkToSave: $scope.checkDiscountList
        });
    };


    //-- Task #25633 Update stubs for empty states
    emptyStateLink();
    function emptyStateLink() {
        let siteUrlLang = siteLangCode[angular.copy($profile).cabinetLang];
        // url lang where we have info
        let urlLang = ["ru", "es", "jp", "ko", "idn", "de", "ro", "fr", "ar", "br", "it", "pl", "gr", "zh"];
        //emptyStateCategory Learn more
        $scope.emptyStateLearnMoreCustomers = urlLang.indexOf(siteUrlLang) !== -1
            ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-work-customer-base-back-office?utm_source=Back%20Office&utm_medium=Customers"
            : "https://help.loyverse.com/help/how-work-customer-base-back-office?utm_source=Back%20Office&utm_medium=Customers";
    }
    //-- END Task #25633 Update stubs for empty states
    //-- Task #25853 Add events to Mixpanel for transitions to help
    $scope.trackCustomersHelp = function () {
        lvMixpanel.track('Help', {"Property": "Customers"});
    };
    //-- END Task #25853 Add events to Mixpanel for transitions to help

    function refreshList() {
        $state.go($state.current.name, {}, {
            reload: true
        });
    }
}


export default {
    type: 'controller',
    name: 'clientsDBControl',
    value: clientDBController
};
