/**
 * Created by mars on 12/21/15.
 */

validProvider.$inject = [];

function validProvider() {
	var $$valid = {};

	function toCamel (val){
		return val.replace(/(\-[a-z])/g, function($1){return $1.toUpperCase().replace('-','');});
	};

	return {
		$get: function () {
			return {
				$$valid: $$valid,
				foo: foo
			};
			function foo() {
				var s = this;
				this.min;
				this.max;
				/**
				 * сообщение при длинне меньше минимальной
				 * @type {string}
				 */
				this.minMessage = '';

				/**
				 * сообщение при длинне больше минимальной
				 * @type {string}
				 */
				this.maxMessage = '';

				this.replace = function ( val ) {
					return val;
				};

				this.cut = function ( val, max ) {
					val = val + '';

					if ( (max || s.max)<val ) {
						val = val.substr( 0, max || s.max );
					}
					return val;
				};

				this.int = function ( val ) {
					if ( val ) {
						val = ("" + val).replace( /[^0-9]/, '' );
					}
					return val;
				};


				this.isValidLen = function ( val ) {
					var st = true;
					var mess = '';
					var str = '';

					if ( typeof val == 'boolean' ) {
						if ( val ) {
							st = true
						} else {
							st = false
						}
					} else {
						if ( typeof val != 'undefined' && val !== null ) {
							str = new String( val );
						}
						if ( s.min && str.length < s.min ) {
							st = false;
							mess = s.minMessage
						}
						if ( s.max && s.max < str.length ) {
							st = false;
							mess = s.maxMessage
						}
					}
					return {
						st: st,
						mess: mess
					}
				};

				this.reforms = [
					this.replace
				];
				this.valids = [
					s.isValidLen
				];
				this.valids.objRes = {
					st: true,
					mess: null,
					val: null
				};

				this.valid = function ( val ) {
					var i, st, mess, res;

					for ( i = 0; i < s.reforms.length; i++ ) {
						val = s.reforms[i].call( s, val )
					}
					for ( i = 0; i < s.valids.length; i++ ) {
						res = s.valids[i]( val );
						st = (res) ? res.st : null;
						if ( res && !res.st ) {
							mess = res.mess;
							break;
						}
					}
					return {
						st: st,
						mess: mess,
						val: val
					}
				}
			}
		},
		valid: function ( name, obj, reform, valid ) {
			var foo = this.$get().foo;
			var _name= toCamel(name);
			$$valid[_name] = $$valid[_name] || new foo();
			reform && $$valid[_name].reforms.push( reform );
			valid && $$valid[_name].valids.push( valid );
			if ( obj ) {
				for ( var opt in obj ) {
					$$valid[_name][opt] = obj[opt]
				}
			}
			return this;
		}
	}
}

export default validProvider;