/**
 * Created by Kiskenbassker on 27.12.17
 */

import angular from 'angular';
import deleteImg from '../../../img/ic_delete.svg';

createEditInvCountController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$rootScope',
    '$timeout',
    '$q',
    '$shortOutlets',
    '$filter',
    'serviceDialog',
    'serviceToast',
    '$categories',
    '$suppliers',
    'factoryInventory',
    'factoryInventoryCount',
    'factoryAdjustment',
    '$countOptionsShow',
    'lodash',
    '$orderDetails',
    '$translate',
    'tableViewColumnFactory'
];

function createEditInvCountController(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $timeout,
    $q,
    $shortOutlets,
    $filter,
    serviceDialog,
    serviceToast,
    $categories,
    $suppliers,
    factoryInventory,
    factoryInventoryCount,
    factoryAdjustment,
    $countOptionsShow,
    lodash,
    $orderDetails,
    $translate,
    tableViewColumnFactory
) {
    var vm = this,
        translate = $filter('translate'),
        searchDefer,
        offStateChangeStart,
        itemsFound = null,
        enterKeydown = false,
        searchInput,
        wareCount = $filter('wareCount'),
        deletedItems = [];

    $scope.stateFromList = $stateParams.stateToSave;
    $scope.isSaving = false;

    vm.countOptionsShow = $countOptionsShow;
    vm.outlets = angular.copy($shortOutlets);

    vm.invCountOrderParams = {};

    if($state.current.name === 'inventory.editcount') {
        vm.details = angular.copy($orderDetails);
        vm.invCountOrderParams.actualOutletId = vm.details.outletId;
        vm.invCountOrderParams.notes = vm.details.comment;
        vm.invCountOrderParams.items = vm.details.items.map(
            function (item) {
                return {
                    id: item.id,
                    wareId: item.wareId,
                    name: item.name,
                    sku: item.sku,
                    expected: wareCount(item.expected, $rootScope.decSeparator, false),
                    categoryId: item.categoryId,
                    supplierId: item.supplierId
                }
            }
        );
        vm.actualOutlet = vm.details.outletName;
    } else {
        vm.invCountOrderParams.actualOutletId = vm.outlets.length > 1 ? null : vm.outlets[0].id;
        vm.invCountOrderParams.notes = "";
        vm.invCountOrderParams.type = "PARTIAL";
        vm.invCountOrderParams.items = [];
        vm.actualOutlet = vm.outlets.length > 1 ? {} : vm.outlets[0].name;
    }

    vm.invCountOrderStartParams = angular.copy(vm.invCountOrderParams);

    vm.categories = angular.copy($categories).map(function (item) {
        return {
            id: item.id,
            name: item.name,
            waresAmount: item.waresAmount,
            _checked: false,
            _allAdded: false
        }
    });
    vm.suppliers = angular.copy($suppliers).map(function (item) {
        return {
            id: item.id,
            name: item.name,
            waresAmount: item.waresAmount,
            _checked: false,
            _allAdded: false
        }
    });
    vm.categoriesSelected = [];
    vm.suppliersSelected = [];

    /* Select actual outlet */
    vm.selectActualOutlet = function(actualOutlet){
        if(vm.actualOutlet !== null ){
            vm.actualOutlet = angular.copy(actualOutlet);
            vm.invCountOrderParams.actualOutletId = vm.actualOutlet.id;
        } else {
            vm.actualOutlet = {};
            vm.invCountOrderParams.actualOutletId = null;
        }
    };

    /* Add by categories dialog and functions */
    vm.addByCategory = function () {
        itemsCountBy(vm.categories, vm.invCountOrderParams.items, 'categoryId');
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/inventory/dialog-add-by.html',
            title: translate('INVENTORY.COUNT.ADD_CATEGORY'),
            parents: vm.categories,
            parentsSelected: vm.categoriesSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_CATEGORIES'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        vm.categories.forEach(function (item) {
                            item._checked = false;
                        });
                        vm.categoriesSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(vm.categoriesSelected.length > 0) {
                            var categoriesParams = {
                                outletId: vm.invCountOrderParams.actualOutletId,
                                categoryIds: vm.categoriesSelected
                            };
                            factoryInventoryCount.getWaresForInvCountByCategories(categoriesParams).then(function (data) {
                                if(data.result === "ok") {
                                    vm.categoriesSelected = vm.callbackAddItemsBy(data, vm.categories, vm.categoriesSelected);
                                }
                            })
                        }
                    }
                }
            ]
        })
    };

    vm.addBySupplier = function () {
        itemsCountBy(vm.suppliers, vm.invCountOrderParams.items, 'supplierId');
        serviceDialog.add({
            templateUrl: 'dashboard-page/templates/dialog/inventory/dialog-add-by.html',
            title: translate('INVENTORY.COUNT.ADD_SUPPLIER'),
            parents: vm.suppliers,
            parentsSelected: vm.suppliersSelected,
            noParentsText: translate('INVENTORY.COUNT.NO_SUPPLIERS'),
            searchText: "",
            checkParents: checkItemsList,
            buttons: [
                {
                    text: 'ON_CANCEL',
                    action: function () {
                        vm.suppliers.forEach(function (item) {
                            item._checked = false;
                        });
                        vm.suppliersSelected = [];
                        return false;
                    }
                },
                {
                    text: 'ON_ADD',
                    action: function () {
                        if(vm.suppliersSelected.length > 0) {
                            var suppliersParams = {
                                outletId: vm.invCountOrderParams.actualOutletId,
                                supplierIds: vm.suppliersSelected
                            };
                            factoryInventoryCount.getWaresForInvCountBySuppliers(suppliersParams).then(function (data) {
                                if(data.result === "ok") {
                                    vm.suppliersSelected = vm.callbackAddItemsBy(data, vm.suppliers, vm.suppliersSelected);
                                }
                            })
                        }
                    }
                }
            ]
        })
    };

    vm.callbackAddItemsBy = function (data, parents, parentsSelected) {
        var itemsBy = [];
        data.items.forEach(
            function (item) {
                var itemToAdd;
                var itemsLength = vm.invCountOrderParams.items.length;
                if(itemsLength > 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (vm.invCountOrderParams.items[i].wareId === item.id) {
                            itemToAdd = false;
                            break;
                        } else if (i === itemsLength - 1) {
                            itemToAdd = true;
                        }
                    }
                } else {
                    itemToAdd = true;
                }
                if(itemToAdd) {
                    itemsBy.push({
                        id: null,
                        wareId: item.id,
                        name: item.name,
                        sku: item.sku,
                        expected: wareCount(item.expected, $rootScope.decSeparator, false),
                        categoryId: item.categoryId,
                        supplierId: item.supplierId
                    });
                }
            }
        );
        vm.invCountOrderParams.items = vm.invCountOrderParams.items.concat(itemsBy);
        parents.forEach(function (item) {
            item._checked = false;
        });
        parentsSelected = [];
        return parentsSelected;
    };

    function checkItemsList (item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) {
            list.splice(idx, 1);
        }
        else {
            list.push(item);
        }
    }

    /* Items autocomplete */

    vm.autocompleteItems = {
        searchText: null,
        selectedItem: null,
        getMatches: function (query) {
            searchDefer = $q.defer();
            factoryInventoryCount
                .searchItemsForInventoryCount({
                    search: query,
                    outletId: vm.invCountOrderParams.actualOutletId
                })
                .then(function (items) {
                    searchDefer.resolve(items);
                    itemsFound = angular.copy(items);
                })
                .then(function () {
                    if(enterKeydown) {
                        // angular.element('md-virtual-repeat-container').addClass('hidden');
                        if(Array.isArray(itemsFound) && itemsFound.length === 1 && itemsFound[0].barcode === vm.autocompleteItems.searchText) {
                            vm.autocompleteItems.selectedItemChange(itemsFound[0]);
                            searchInput.focus();
                            enterKeydown = false;
                        }
                    }
                    // else {
                    //     angular.element('md-virtual-repeat-container').removeClass('hidden');
                    // }
                });
            return searchDefer.promise;
        },
        selectedItemChange: function(item) {
            if(item) {
                var itemsLength = vm.invCountOrderParams.items.length;
                if(itemsLength !== 0) {
                    for (var i = 0; i < itemsLength; i++) {
                        if (item.id === vm.invCountOrderParams.items[i].wareId) {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                content: translate('INVENTORY.COUNT.ITEM_ADDED'),
                                buttons: [
                                    {
                                        text: 'ON_OK',
                                        action: function () {
                                            return true;
                                        }
                                    }
                                ]
                            });
                            break;
                        } else if (i === itemsLength - 1) {
                            pushItemToArray(item);
                        }
                    }
                } else {
                    pushItemToArray(item);
                }
                $('[name="autocompleteItems"]').blur();
                this.selectedItem = null;
                this.searchText = null;
            }
        }
    };

    function pushItemToArray(item) {
        var itemObj = {
            id: null, /* Identifies that position is newly added */
            wareId: item.id,
            name: item.name,
            sku: item.sku,
            expected: wareCount(item.expected, $rootScope.decSeparator, false),
            categoryId: item.categoryId,
            supplierId: item.supplierId
        };
        vm.invCountOrderParams.items = vm.invCountOrderParams.items.concat(itemObj);
    }

    /* Function for counting items in count by category or by supplier and comparing them with total amount of supplier's or category's items */
    function itemsCountBy(arrayObj1, arrayObj2, field) {
        var count = 0;
        arrayObj1.forEach(function (obj1) {
            count = 0;
            arrayObj2.forEach(function (obj2) {
                if(obj1.id === obj2[field]) {
                    count += 1;
                }
            });
            obj1._allAdded = (count === obj1.waresAmount);
        })
    }

    /* Refreshing stock when outlet is changed */

    vm.outletChange = function() {
        if(vm.invCountOrderParams.items.length > 0) {
            var outletsData = {
                outletId: vm.invCountOrderParams.actualOutletId,
                items: getItemsIds()
            };
            factoryAdjustment.getAdjustmentOrderItemsForOutlet(outletsData)
                .then(function(data) {
                    data.items.forEach(
                        function(item) {
                            vm.invCountOrderParams.items.forEach(
                                function (product) {
                                    if(item.id === product.wareId) {
                                        product.expected = wareCount(item.inStock, $rootScope.decSeparator, false);
                                    }
                                }
                            )
                        }
                    );
                    // console.log("Items now: ", vm.invCountOrderParams.items);
                })
        }
    };

    /* Get items ids */
    function getItemsIds() {
        return vm.invCountOrderParams.items.map(
            function (item) {
                return item.wareId;
            }
        );
    }
    /* END Get items ids */

    /* Delete item from items table */
    vm.deleteItem = function (item) {
        angular.forEach(vm.invCountOrderParams.items, function (val, i) {
            if (item === val) {
                if($state.current.name === 'inventory.editcount' && item.id !== null) {
                    deletedItems.push(item.id);
                }
                vm.invCountOrderParams.items.splice(i, 1);
            }
        });
        vm.invCountOrderParams.items = vm.invCountOrderParams.items.slice();
    };
    /* END Delete item from items table */

    /* Preventing form from being sent by Enter button */
    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });
    /* END Preventing form from being sent by Enter button */


    /* Checking before saving */
    function checkBeforeSave() {
        if(vm.invCountOrderParams.actualOutletId === null
            || ($state.current.name === 'inventory.createcount' && vm.invCountOrderParams.type === "PARTIAL" || $state.current.name === 'inventory.editcount')
                && vm.invCountOrderParams.items.length < 1
            || vm.invCountOrderParams.notes === undefined
            || vm.invCountOrderParams.notes.length > 500)
        {
            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            return false;
        }
        return true;
    }
    /* END Checking before saving */

    /* Cancel button */
    vm.ceInvCountCancel = function () {
        offStateChangeStart();
        if($state.current.name === 'inventory.createcount') {
            if (lodash.isEmpty($scope.stateFromList)) {
                $state.go('inventory.counts',
                    {
                        page: 0,
                        limit: 10
                    });
            } else {
                $state.go('inventory.counts', {
                    page: $scope.stateFromList.page,
                    limit: $scope.stateFromList.limit,
                    outletId: $scope.stateFromList.outletId,
                    status: $scope.stateFromList.status
                });
            }
        } else {
            $state.go('inventory.countdetail', {
                id: $stateParams.id
            });
        }
    };
    /* END Cancel button */

    /* Creating inventory count order */
    vm.cInvCountOrderSave = function (saveForCount, editProfile) {
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if(checkBeforeSave()) {
            $scope.isSaving = true;
            var cInvCountOrderParams = {
                outletId: vm.invCountOrderParams.actualOutletId,
                comment: vm.invCountOrderParams.notes,
                type: vm.invCountOrderParams.type,
                saveForCount: saveForCount,
                items: (vm.invCountOrderParams.type === 'PARTIAL')
                    ?
                    vm.invCountOrderParams.items.map(function (item) {
                        return {
                            wareId: item.wareId
                        }
                    })
                    :
                    []
            };
            factoryInventoryCount.createInventoryCountOrder(cInvCountOrderParams).then(
                function (res) {
                    if (res.result === "ok") {
                        offStateChangeStart();
                        serviceToast.show('INVENTORY.COUNT.CREATE_ORDER_SUCCESS', 'success');
                        if(saveForCount) {
                            $state.go('inventory.countstock', {
                                id: res.newId
                            })
                        } else {
                            $state.go('inventory.countdetail', {
                                id: res.newId
                            })
                        }
                    } else if(res.result === "error" && res.message === "YOU_HAVE_NO_ITEMS") {
                        serviceToast.show('INVENTORY.COUNT.NO_COUNT_ITEMS_ERROR', 'error');
                    } else {
                        serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                    }
                });
        }
    };
    /* END Creating inventory count order */

    /* Editing inventory count order */
    vm.eInvCountOrderSave = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        if(vm.details.status === 'COMPLETED') {
            $state.go('inventory.countdetail', {
                id: $stateParams.id
            })
        } else if(checkBeforeSave()) {
            $scope.isSaving = true;
            var eInvCountOrderParams = {
                orderId: $stateParams.id,
                outletId: vm.invCountOrderParams.actualOutletId,
                comment: vm.invCountOrderParams.notes,
                deletedItems: deletedItems,
                items: vm.invCountOrderParams.items.map(
                    function (item) {
                        return {
                            id: item.id,
                            wareId: item.wareId
                        }
                    })
            };
            factoryInventoryCount.editInventoryCountOrder(eInvCountOrderParams).then(
                function (res) {
                    if (res.result === "ok") {
                        offStateChangeStart();
                        serviceToast.show('INVENTORY.COUNT.SAVE_ORDER_SUCCESS', 'success');
                        $state.go('inventory.countdetail', {
                            id: $stateParams.id
                        })
                    } else {
                        serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                    }
                });
        }
    };
    /* END Editing inventory count order */

    /* Comparing start and final params and showing warning about their change */
    offStateChangeStart = $rootScope.$on('$stateChangeStart', function (event, _toState, _toParam, fromState, fromParam) {
        if (!angular.equals(vm.invCountOrderStartParams, vm.invCountOrderParams)) {
            event.preventDefault();
            $timeout(function () {
                serviceDialog.add( {
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: $filter( 'translate' )( 'UNSAVED_TITLE' ),
                    content: $filter( 'translate' )( 'UNSAVED_LEAVE' ),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {
                                return false;
                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go( _toState, _toParam );
                            }
                        }
                    ]
                });
            });
        } else {
            offStateChangeStart();
        }
    });

    vm.addByBarcode = function ($event) {
        if($event.keyCode === 13) {
            enterKeydown = true;
            searchInput = $event.target;
            angular.element(searchInput).focus();
        }
    }

    vm.columns = buildColumns();

    function buildColumns() {
        let result = [];

        // Item
        const nameOptions = {
            minWidth: 184
        };
        const nameFields = [];
        nameFields.push({
            displayValue: $translate.instant('ARTICLE'),
            valueGetter: 'sku',
            class: 'l-comment'
        });
        result.push(tableViewColumnFactory.createPropertyViewExtendedColumn(
            $translate.instant('INVENTORY.ITEMS_TABLE.ITEM'), 'name', nameFields, {name: 'name'}));

        // Expected stock
        const stockOptions = {
            name: 'expectedStock',
            width: 'auto',
            attrs: {
                'name': 'itemExpected',
                'aria-label': 'itemExpected',
                'input-autosize': 'input-autosize',
                'disabled': 'disabled'
            },
            referenceAttrs: {
                'currency-localization': vm.countOptionsShow
            },
            isVisible: () => vm.invCountOrderParams.actualOutletId !== null,
        };
        result.push(tableViewColumnFactory.createInputColumn(
            $translate.instant('INVENTORY.EXPECKTED'),
            'expected', stockOptions));

        // Delete
        const delColumnOptions = {
            width: 48,
            noShrink: true
        };
        result.push(tableViewColumnFactory.createActionColumn(
            null, deleteImg, angular.bind(vm, vm.deleteItem), delColumnOptions));

        return result;
    }
}

export default {
    type: 'controller',
    name: 'createEditInvCountController',
    value: createEditInvCountController
};
