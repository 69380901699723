timeRangeModuleRun.$inject = ['$rootScope', 'constantTimePattern', 'localHoursPattern'];

function timeRangeModuleRun($rootScope, constantTimePattern, localHoursPattern) {
    $rootScope.$on('$translateChangeSuccess', function () {
        var i = localHoursPattern.initialHours.length;
        while(0<i--){
            constantTimePattern.hours[i] = localHoursPattern.initialHours[i];
        }
        constantTimePattern
    });
}

export default timeRangeModuleRun;