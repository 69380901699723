/**
 * Created by yarosh on 27.02.17.
 */

const paymentConst = {
    /**
     * 1: payment methods
     */

    paymentMethods: [
        'CASH',
        'NONINTEGRATEDCARD',
        'CHEQUE',
        'OTHER',
        'WORLDPAY',
        'CAYENE',
        'COINEY',
        'IZETTLE',
        'SUMUP',
        'TYRO',
        'SMARTPAY',
        'NICEPAY',
        'YOCO',
        'EZETAP',
        'PAYGATE',
        'FIRSTDATA',
        'ONEPAY',
        'SOFTBANK',
        'MERCADOPAGO',
        'KICC',
        'POSLINK'
    ],

    /**
     * 2: tips
     */
    tips: ['1000', '1500', '2000']
};

export default {
    type: 'constant',
    name: 'paymentConst',
    value: paymentConst
};
