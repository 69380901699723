/**
 * Created by  Alex on 17.05.18.
 */

serviceBillingError.$inject = ["$state", "$stateParams", "$filter", "serviceDialog", 'permissions'];
function serviceBillingError($state, $stateParams, $filter, serviceDialog, permissions) {

    let translate = $filter('translate');

    // перезагрузка страницы после запуска повторного платежа и не только
    function reloadRoute() {
        let reloadStates = ['settings.account', 'settings.outlet'];
        setTimeout(function () {
            if(reloadStates.includes($state.current.name)) {
                //получаем адрес без параметров
                let uriWithoutHash = window.location.href.split('?')[0];
                //и заменяем текущую страницу без сохранения истории
                window.location.replace(uriWithoutHash);
                // и перезагружаем ее
            }
            permissions.updatePermissions(true);
            permissions.updateAccountInfo();
        }, 1000);
    }
    // перезагрузка страницы после запуска повторного платежа и не только


    // диалоговое окно на ответ с сервере ERROR с описанной ошибкой
    function knownError(m, okAction) {
        let errorDialog = {
            templateUrl: 'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
            class: 'billing-base-384',
            buttons: [
                {
                    class: 'md-primary',
                    text: 'ON_OK'
                }
            ]
        };
        let useUnknownError = false;

        switch (m) {
            case "try_later":
                errorDialog.content1 = translate('ACCOUNT.LATER_PRIORITY_PLAN_ON');
                break;
            case "activation_error":
            case "refresh_and_try_again":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_SUBSCRIBE_ACTIVATION_ERROR');
                errorDialog.buttons[0].action = function() { reloadRoute() };
                errorDialog.buttons[0].text = 'ACCOUNT.B2_SERVICE_DIALOG.B2_REFRESH';
                break;
            case "payment_processing_failed":
                errorDialog.title = translate('ACCOUNT.PAYMENT_DECLIDED');
                errorDialog.content1 = translate('ACCOUNT.UNABLE_TO_PROCESS_PAYMENT');
                break;
            case "payment_method_verification_failed":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_CARD_NOT_VERIFIED');
                break;
            case "payment_method_not_present":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_NO_CARD_ON_FILE');
                break;
            case "temporary_unavailable":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_ERROR_CHARGEBEE');
                errorDialog.class = '';
                break;
            case "invalid_request_coupon":
                errorDialog.content1 = translate('ACCOUNT.B2_COUPON.B2_COUPON_INVALID_COUPON');
                errorDialog.class = '';
                break;
            case "transaction_failed":
                errorDialog.title = translate('ACCOUNT.PAYMENT_DECLIDED');
                errorDialog.content1 = translate('ACCOUNT.UNABLE_TO_PROCESS_PAYMENT');
                errorDialog.class = '';
                break;
            case "card_declined":
                errorDialog.title = translate('ACCOUNT.PAYMENT_DECLIDED');
                errorDialog.content1 = translate('ACCOUNT.CARD_DECLINED');
                errorDialog.class = '';
                break;
            case "vat_invalid":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_INVALID_VAT_NUMBER');
                break;
            case "vat_validation_unavailable":
                errorDialog.content1 = translate('ACCOUNT.B2_SERVICE_DIALOG.B2_VAT_VALIDATION_UNAVAILABLE');
                break;
            case "attempt_limit_exceeded":
                errorDialog.templateUrl = 'dashboard-page/templates/dialog/billing/dialog-billing-gateway-rejected.html';
                errorDialog.content = 'ACCOUNT.B2_SERVICE_DIALOG.B2_TOO_MANY_ATTEMPTS_TO_UPDATE_PAYMENT_METHOD';
                break;
            case "country_not_allowed":
                errorDialog.content1 = translate('ACCOUNT.SERVICE_NOT_AVAILABLE');
                break;
            // Stripe only errors
            case "billing_address_invalid":
                errorDialog.content1 = translate('ACCOUNT.STRIPE_ERRORS.INVALID_BILLING_ADDRESS');
                break;
            case "postal_code_invalid":
                errorDialog.content1 = translate('ACCOUNT.STRIPE_ERRORS.POSTAL_CODE_INVALID');
                break;
            case "incorrect_zip":
                errorDialog.content1 = translate('ACCOUNT.STRIPE_ERRORS.INCORRECT_ZIP');
                break;
            case "processor_declined":
                errorDialog.content1 = translate('ACCOUNT.PAYMENT_ERRORS.DO_NOT_HONOR');
                break;
            case "do_not_honor":
            case "insufficient_funds":
            case "limit_exceeded":
            case "not_allowed":
            case "invalid_transaction":
            case "pickup_card":
            case "restricted_card":
            case "expired_card":
                errorDialog.title = translate('ACCOUNT.PAYMENT_DECLIDED');
                errorDialog.content1 = translate('ACCOUNT.PAYMENT_ERRORS.' + m.toUpperCase());
                break;
            default:
                useUnknownError = true;
        }
        if(useUnknownError) {
            unKnownError(okAction);
        } else {
            let actionCodes = [
                "try_later",
                "payment_processing_failed",
                "payment_method_verification_failed",
                "payment_method_not_present",
                "temporary_unavailable",
                "invalid_request_coupon",
                "attempt_limit_exceeded"];
            if(okAction && actionCodes.indexOf(m) !== -1) {
                errorDialog.buttons[0].action = okAction;
            }
            serviceDialog.add(errorDialog);
        }
    }
    // диалоговое окно на ответ с сервере ERROR с описанной ошибкой


    // диалоговое окно на ответ с сервере ERROR с НЕ описанной ошибкой
    function unKnownError(okAction) {
        serviceDialog.add({
            templateUrl:    'dashboard-page/templates/dialog/billing/dialog-billing-base.html',
            content1:       translate('ACCOUNT.B2_SERVICE_DIALOG.B2_OTHER_ERROR_1'),
            buttons:     [{ class: 'md-primary',
                            text: 'ON_OK',
                            action: okAction ? okAction : function () {} }]
        });
    }
    // диалоговое окно на ответ с сервере ERROR с НЕ описанной ошибкой


    this.reloadRoute    = reloadRoute;
    this.knownError     = knownError;
    this.unKnownError   = unKnownError;

}


export default {
    type: 'service',
    name: 'serviceBillingError',
    value: serviceBillingError
};
