import { Paginator } from "../../../common/containers/Paginator";
import template from "./items-report-table.component.pug";
import { findKey, attempt } from "lodash";

itemsReportTable.$inject = ["factoryItemsReportTable"];

function itemsReportTable(factoryItemsReportTable) {
  this.paginator = new Paginator([], 1, 10);

  this.states = {
    limited: false,
    network: false,
  };

  this.$onInit = () => {
    this.states.limited = !this.periodAllowed;
    this.states.network = this.itemsReport.result !== "ok";

    this.items = this.itemsReport.wares ?? [];

    this.onFilter(this.tableProp);

    const initial = this.columns.find((i) => i.sort.active) ?? this.columns[0];

    if (initial) {
      initial.sort.active = false;
      this.onSort(initial);
    }
  };

  this.rows = () =>
    this.paginator.current().flatMap((row) => {
      if (!row.variations) {
        return [row];
      }

      row.article = "";

      row.variations.forEach((variation) => {
        variation.name = variation.options.join(" / ");
        variation.category = "";
      });

      return [row, ...row.variations];
    });

  this.onSort = (column) => {
    if (column.sort.active) {
      column.sort.ascending = !column.sort.ascending;
    } else {
      this.columns.forEach(({ sort }) => {
        sort.active = false;
      });

      column.sort.active = true;
    }

    const sorted = this.items.sort((a, b) => {
      const ord = column.sort.numeric
        ? column.cell(a) - column.cell(b)
        : (column.cell(a) ?? "").localeCompare(column.cell(b) ?? "");

      return column.sort.ascending ? ord : -ord;
    });

    this.paginator = new Paginator(
      sorted,
      this.paginator.page,
      this.paginator.size,
    );
  };

  this.onFilter = (selected) => {
    const fields = selected.reduce(
      (acc, { name, value: show }) => (show ? acc.concat(name) : acc),
      [],
    );

    const permissions = {
      cost: this.costAllowed,
    };

    this.columns = factoryItemsReportTable
      .columns(fields, permissions)
      .map((column) => {
        column.format.active = false;
        column.format.peek = false;
        return column;
      });
  };

  this.error = () =>
    findKey(
      {
        NETWORK: () => this.states.network,
        LIMITED: () => this.states.limited,
        EMPTY: () => this.paginator.isEmpty(),
      },
      attempt,
    );
}

export default {
  bindings: {
    isGreen: "<",
    itemsReport: "<",
    unlimitedSalesHistory: "<",
    tableProp: "=",
    tableConfig: "<",
    hiddenColumns: "<",
    waresReportTable: "<",
    reportTypeForColumns: "<",
    pageWrapHeigh: "<",
    periodAllowed: "<",
    costAllowed: "<",
    currencyOptionsShow: "<",
    percentShowInfinityToHundred: "<",
  },
  template,
  controller: itemsReportTable,
};
