createDirective.$inject = [];

function createDirective() {
    return {
        restrict: 'A',
        link: linkDirective
    };

    function linkDirective(scope, element, attrs) {

        updateState();

        const onResize = _.throttle(updateState, 200, {leading: false});
        window.addEventListener('resize', onResize);

        function updateState() {
            element.toggleClass('is-overflowed-y', element[0].offsetHeight < element[0].scrollHeight);
        }
    }
}

export default {
    type: 'directive',
    name: 'lvOverflowedContainer',
    value: createDirective
};