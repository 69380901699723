timeRangeContrl.$inject = ['$scope', '$state', '$stateParams', 'filtersData', '$element', 'constantTimePattern', 'lvMixpanel', '$filter', '$mdSelect', 'factoryDOM'];

function timeRangeContrl($scope, $state, $stateParams, filtersData, $element, constantTimePattern, lvMixpanel, $filter, $mdSelect, factoryDOM) {

    var endTime;
    var startTimeList = document.getElementById('startTimeList');
    var endTimeList;
    var formattedTime;

    $scope.startChange = startChange;
    $scope.endChange = endChange;
    $scope.period = period;
    $scope.hideList = hideList;
    $scope.hideBothTimeListsWhenTimeLabelIsClosed = hideBothTimeListsWhenTimeLabelIsClosed;

    $scope.localHours = angular
        .copy(constantTimePattern.hours)
        .map(val => {
            let timeVal = time(val);
            return {
                value: timeVal,
                displayValue: $filter('formatDropDownTime')(timeVal)
            };
    });

    $scope.startTime = ($stateParams.fromHour) ? (time($stateParams.fromHour)) : time($scope.localHours[0].value);
    $scope.endTime = ($stateParams.toHour) ? (time($stateParams.toHour)) : time($scope.localHours[23].value);

    $scope.after = ($stateParams.fromHour) ? (time($stateParams.fromHour)) : parseFloat(time($scope.localHours[0].value));
    $scope.before = ($stateParams.toHour) ? (time($stateParams.toHour)) : parseFloat(time($scope.localHours[0].value));


    if((!$stateParams.fromHour && !$stateParams.toHour) || ($stateParams.fromHour === null && $stateParams.toHour === null)){
        $scope.manageTimePeriod = "day";
        $scope.startTime = time($scope.localHours[0].value);
        $scope.endTime = time($scope.localHours[23].value);
        $scope.after = null;
        $scope.before = null;
    } else {
        $scope.manageTimePeriod = "chosenHours";
        $scope.after = (time($stateParams.fromHour)) || time($scope.localHours[0].value);
        $scope.before = (time($stateParams.toHour)) || time($scope.localHours[0].value);
        $scope.startTime = $scope.after;
        $scope.endTime = $scope.before;
    }

    function period (period) {
           $scope.manageTimePeriod = period;
        if($scope.manageTimePeriod === "day"){
            // $scope.after = time($scope.localHours[0]);
            // $scope.before = time($scope.localHours[23]);
            $scope.after = null;
            $scope.before = null;
            // $scope.startTime = time($scope.localHours[0]);
            // $scope.endTime = time($scope.localHours[23]);
        } else {
            // $scope.after = time($scope.startTime);
            // $scope.before = time($scope.endTime);

            $scope.after = $scope.startTime;
            $scope.before = $scope.endTime;
        }
    }

    function startChange(val) {
        $scope.after = val;
    }

    function endChange(val) {
        $scope.before = val;
    }

    function time (hours) {
            if(typeof(hours) === 'number' && hours > 9) {
                formattedTime = hours + ":00";
                return formattedTime;
            } else if(typeof(hours) !== 'number') {
                return hours;
            } else {
                formattedTime = "0" + hours + ":00";
                return formattedTime;
            }
    }

    function hideList() {
        var timeView =  document.getElementById('timeView');
        timeView.addEventListener('click', closeList);
        function closeList (event) {
            if(!$(event.target).is('#startTime') && !$(event.target).is('#startTimeList') && !$(event.target).is('#endTime') && !$(event.target).is('#endTimeList') )
            {
                hideBothTimeLists();
            }
        }
    }

    function hideBothTimeListsWhenTimeLabelIsClosed(status){
        endTime = document.getElementById('endTime');
        endTimeList = document.getElementById('endTimeList');
        if (startTimeList !== null && endTimeList !== null) {
            startTimeList.classList.add(status);
            endTimeList.classList.add(status);
            hideBothTimeLists();
        }
    }

    function hideBothTimeLists(){
        if (startTimeList && startTimeList.classList.contains('block')) {
            startTimeList.classList.remove('block');
            startTimeList.classList.add('none');
        } else if (endTimeList && endTimeList.classList.contains('block')){
            endTimeList.classList.remove('block');
            endTimeList.classList.add('none');
        } else {
            return;
        }
    }

    $scope.$on('$mdMenuClose', sendParams);

    $scope.$on( '$destroy', function () {
        $scope.show = false;
        $scope.timeRangeElement && $scope.timeRangeElement[0].parentNode.removeChild( $scope.timeRangeElement[0] );
    } );

    function sendParams() {
        if((!$scope.after && !$scope.before) || (parseFloat($scope.after) === 0 && parseFloat($scope.before) === 0)) {
            $stateParams.fromHour = null;
            $stateParams.toHour = null;
        } else {
            $stateParams.fromHour = (parseFloat($scope.after) !== 0 )? parseFloat($scope.after) : 0;
            $stateParams.toHour = (parseFloat($scope.before) !== 0) ? parseFloat($scope.before) : 0;
        }

        $stateParams.receiptId = null;
        var savedStateParams = filtersData.getStateParams();
        $stateParams.serverChartType = savedStateParams.serverChartType;
        var stateParams = filtersData.setStateParams($stateParams);

        lvMixpanel.track('Filter', {"Property": "Reports/Select time of day"});

        const { missing, wait, remove } = factoryDOM;
    
        // This component creates race conditions with the other md-menu controlers.
        // The menu backdrop is not always removed before propogating the state.
        // Additionally, if another menu is opened while the md-menu library is cleaning up,
        // a backdrop will reappear in the DOM without being controlled.
    
        // Step 1: Wait for the current backdrop to be removed from the DOM.
        wait(() => missing("md-backdrop")).then(() => {
          $state.go($state.current.name, stateParams);
    
          // Step 2: Catch a lingering backdrop that has been readded to the DOM.
          setTimeout(() => {
            if (missing(".md-open-menu-container")) {
              remove("md-backdrop");
              remove(".md-scroll-mask");
            }
          }, 100);
        });
    }

}

export default timeRangeContrl;
