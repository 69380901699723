import angular from 'angular';

createClass.$inject = ['$mdUtil'];

function createClass($mdUtil) {
    return class StateManager {
        constructor(element, attrs, ngModelCtrl, inputContainerAdapterCtrl, handleViewChange) {
            this.__element = element;
            this.__attrs = attrs;
            this.__ngModelCtrl = ngModelCtrl;
            this.__inputContainerAdapterCtrl = inputContainerAdapterCtrl;
            this.__handleViewChange = handleViewChange;

            if (this.__ngModelCtrl) {
                this._handleInvalidState();
                this._handleHasValueState();
            }

            this._handleIsDisabledState();
            this._handleIsReadonlyState();
        }

        _handleHasValueState() {
            const ngModelPipelineCheckValue = (arg) => {
                // Define state value.
                let isHasValue = !this.__ngModelCtrl.$isEmpty(arg);

                // Hold value to inner field.
                this.isHasValue = isHasValue;

                // Apply state to element.
                this.__element.toggleClass('is-has-value', isHasValue);

                // Apply state to editor container.
                if (this.__inputContainerAdapterCtrl) {
                    this.__inputContainerAdapterCtrl.setHasValue(isHasValue);
                }
                return arg;
            }
            
            const onValueChanged = () => ngModelPipelineCheckValue(this.__ngModelCtrl.$viewValue);

            if (this.__handleViewChange) {
                // Some components don't trigger parsers/formatters on value change
                this.__ngModelCtrl.$viewChangeListeners.push(onValueChanged);
                $mdUtil.nextTick(onValueChanged);
            } else {
                this.__ngModelCtrl.$parsers.push(ngModelPipelineCheckValue);
                this.__ngModelCtrl.$formatters.push(ngModelPipelineCheckValue);
            }
        }

        _handleInvalidState() {
            let previousInvalidState;
            let previousTouchedState;
            // Handle invalid state.
            // Invalid state defines by model validation and touched states.

            const onValidStateChanged = () => {
                // Define model validation state and update element/controller states.
                // If there is an async validator in the model, the $invalid may be undefined
                // use $error object in this case
                let isError;
                if (angular.isDefined(this.__ngModelCtrl.$invalid)) {
                    isError = this.__ngModelCtrl.$invalid;
                } else {
                    isError = !underscore.isEmpty(this.__ngModelCtrl.$error);
                }
                let isInvalid = isError && this.__ngModelCtrl.$touched;

                if (previousInvalidState !== isInvalid) {
                    previousInvalidState = isInvalid;
                    this.setInvalid(isInvalid);
                }
            }

            const onTouchedStateChange = () => {
                let isTouched = this.__ngModelCtrl.$touched;
                if (previousTouchedState !== isTouched) {
                    previousTouchedState = isTouched;
                    this.setTouched(isTouched);
                }

                onValidStateChanged();
            }

            let modelStatesObserver = {
                onValidChanged: angular.bind(this, onValidStateChanged),
                onUntouchChanged: angular.bind(this, onTouchedStateChange)
            };

            this.__ngModelCtrl.addObserver(modelStatesObserver);
        }

        _handleIsReadonlyState() {
            this.__attrs.$observe('readonly', isReadonly => {
                // Hold value to inner field.
                this.isReadonly = isReadonly;
                // Apply state to element.
                this.__element.toggleClass('is-readonly', isReadonly);

                // Apply state to editor container.
                if (this.__inputContainerAdapterCtrl) {
                    this.__inputContainerAdapterCtrl.setReadonly(isReadonly);
                }
            });
        }

        _handleIsDisabledState() {
            this.__attrs.$observe('disabled', isDisabled => {
                // Hold value to inner field.
                this.isDisabled = isDisabled;
                // Apply state to element.
                this.__element.toggleClass('is-disabled', isDisabled);

                // Apply state to editor container.
                if (this.__inputContainerAdapterCtrl) {
                    this.__inputContainerAdapterCtrl.setDisabled(isDisabled);
                }
            });
        }

        setFocused(isFocused) {
            // Hold value to inner field.
            this.isFocused = isFocused;
            // Apply state to element.
            this.__element.toggleClass('is-focused', isFocused);
            // Apply state to editor container.
            if (this.__inputContainerAdapterCtrl) {
                this.__inputContainerAdapterCtrl.setFocused(isFocused);
            }
        }

        setInvalid(isInvalid) {
            // Hold value to inner field.
            this.isInvalid = isInvalid;
            // Apply state to element.
            this.__element.toggleClass('is-invalid', isInvalid);
            // Apply state to editor container.
            if (this.__inputContainerAdapterCtrl) {
                this.__inputContainerAdapterCtrl.setInvalid(isInvalid);
            }
        }

        setTouched(isTouched) {
            // Apply state to editor container.
            if (this.__inputContainerAdapterCtrl) {
                this.__inputContainerAdapterCtrl.setTouched(isTouched);
            }
        }
    }
}

export default {
    type: 'factory',
    name: 'InputContainerStateManager',
    value: createClass
};