import * as Sentry from "@sentry/browser";

formatMonthFactory.$inject = ['$rootScope'];

function formatMonthFactory($rootScope) {
	function formatMonth(date, params) {
		/* if(params.exact){
		 console.log(params.exact)
		 }*/

		var dateNowValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
		var afterValue = params.after ? params.after.getTime() : null;
		var maxDayStart = params.before ? new Date(params.before.getFullYear(), params.before.getMonth(), params.before.getDate(), 0, 0, 0).getTime() : null;
		var beforeValue = params.before ? params.before.getTime() : null;
		var maxDate = params.maxDate || null;
		var minDate = params.minDate || null;
		var startWeek = params.startWeek;
		var exactValue  = params.exact ?   params.exact.getTime() : null;

		function checkCurrentDate(d) {
			var value = '';

			if (dateNowValue == d.getTime()) {
				value += 'current'
			}
			if (!exactValue && afterValue <= d.getTime() && d.getTime() <= beforeValue) {
				value += ' select'
			}

			if(beforeValue === afterValue || afterValue === maxDayStart) {
                value += ' minMax'
			} else if(afterValue === d.getTime()) {
                value += ' minLimit'
			} else if (d.getTime() === beforeValue || d.getTime() === maxDayStart) {
                value += ' maxLimit'
			}

			if(new Date(exactValue ).getDate() === d.getDate() &&  new Date(exactValue ).getFullYear() === d.getFullYear() && new Date(exactValue ).getMonth() === d.getMonth()){
				value += ' select'
			}
			if (getBlocked(d)) {
				value += ' blocked'
			}
			return value
		}

		function otherMonth(d) {
            var value = checkCurrentDate(d);
            value += ' other-month';
            return value;
		}

		function getBlocked(d) {
			if (maxDate && maxDate.getTime() < d.getTime()) {
				return true
			}

			if (minDate && d.getTime() < minDate.getTime()) {
				return true
			}
			return false
		}

		var array = [];
		for (var i = 0; i < 50; i++) {
			var d = new Date(date.getFullYear(), date.getMonth(), i);
			if (d.getMonth() == date.getMonth()) {
				array.push({
					date: d,
					value: d.getTime(),
					dayWeek: d.getDay(),
					blocked: getBlocked(d),
					class: checkCurrentDate(d)
				})
			}
		}

		// Temporary fix
		// BN-2915 is not reproducible manually, wait for Sentry reports with details to reproduce
		if (array.length === 0) {
			let shiftDate = new Date(date.getFullYear(), date.getMonth(), 0);
			Sentry.captureMessage(`
				Calendar build fail: 
				Date: ${date.toString()} 
				Lang: ${$rootScope.cabinetLang} 
				Year: ${date.getFullYear()} 
				Month: ${date.getMonth()} 
				Shift month: ${shiftDate.getMonth()} 
				`);
			return;
		}

		var fillBefore, fillAfter;
		//  console.log(startWeek)
		if (startWeek == 0) {
			fillBefore = array[0].dayWeek;
			fillAfter = 6 - array[array.length - 1].dayWeek;
		} else {
			fillBefore = (array[0].dayWeek == 0) ? 6 : array[0].dayWeek - 1;
			fillAfter = (array[array.length - 1].dayWeek == 0) ? 0 : 7 - array[array.length - 1].dayWeek;
		}
		for (var i = 1; i <= fillBefore; i++) {
            var d = new Date(date.getFullYear(), date.getMonth(), 1 - i);
            array.unshift({
                date: d,
                value: d.getTime(),
                dayWeek: d.getDay(),
                blocked: getBlocked(d),
                class: otherMonth(d)
            });
		}
		for (var i = 1; i <= fillAfter; i++) {
            var d = new Date(date.getFullYear(), date.getMonth() + 1, i);
			array.push({
				date: d,
				value: d.getTime(),
				dayWeek: d.getDay(),
				blocked: getBlocked(d),
				class: otherMonth(d)
			})
		}



		var rows = parseFloat((array.length / 7).toFixed(0));
		var arrMonth = [];
		var k = 0;
		for (var i = 0; i < rows; i++) {
			arrMonth[i] = [];
			for (var d = 0; d < 7; d++) {
				arrMonth[i].push(array[k]);
				k++;
			}
		}
		return arrMonth
	}
	return {
		formatMonth:formatMonth
	}
}

export default formatMonthFactory;