/**
 * Created by olehkss on 02.07.16.
 * Edited by kseniya.yarosh on 14.12.16
 *
 * @ngdoc factory
 * @name app.factory: payTypesSummary
 * @description
 * Gets the pay-types report from server
 * @requires promiseOperation
 */

import angular from 'angular';

payTypesSummary.$inject = ['promiseOperation', 'serviceReportsError'];

function payTypesSummary(promiseOperation, serviceReportsError) {

    return {
        getPayTypesSummary: getPayTypesSummary
    };

    /**
     * @ngdoc function
     * @param {Object} params parameters for server request
     * @returns {Promise} promise that links the data
     * @description
     * Requests for payment types data from server
     */
    function getPayTypesSummary(params) {
        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/paymentstypesreport',
             params,
            success,
            serviceReportsError.reportsError
        );
    }

    /**
     * @ngdoc function
     * @param {Object} response json object response from server
     * @returns {Object} preprocessed response data
     * @description
     * Changes the pay-types data object structure in order to accommodate
     * angular view building methods
     */
    function success(response) {
        if (angular.isObject(response.data)){
            if (response.data.result === 'ok') {
                let paymentTypesData = {
                    periodAllowed: response.data.periodAllowed,
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    startDateString: response.data.startDateString,
                    endDateString: response.data.endDateString,
                    outlets: response.data.fillSelectData.outlets
                }
                if (response.data.periodAllowed) {
                    paymentTypesData.items = response.data.items;
                    paymentTypesData.total = response.data.total;
                }

                return paymentTypesData;
            }
        }
    }

}

export default {
    type: 'factory',
    name: 'payTypesSummary',
    value: payTypesSummary
};
