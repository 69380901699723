/**
 * Created by mars on 7/2/16.
 */

// config.$inject =['$loadOnDemandProvider', 'buildConstant'];
// function config($loadOnDemandProvider, buildConstant){
// 	$loadOnDemandProvider.config( buildConstant.demandModules );
// }

export default null;

// export default {
// 	type: 'config',
// 	value: config
// };