/**
 * Created by olehkss on 05.07.16.
 *
 * @ngdoc factory
 * @name app.factory: shareCashboxShift
 * @description
 * Shares  selected cashbox shift between cashboxShiftView directive
 * and cashboxShift report
 */

shareCashboxShift.$inject = [];
function shareCashboxShift() {

    let selectedCashboxShift;
    let currencyShow;
    let receiptFormat;
    let percentShowTax;

    return {
        getCashboxShift: getCashboxShift,
        setCashboxShift: setCashboxShift,
        setCurrencyShow: setCurrencyShow,
        getCurrencyShow: getCurrencyShow,
        setReceiptFormat: setReceiptFormat,
        getReceiptFormat: getReceiptFormat,
        setPercentShowTax: setPercentShowTax,
        getPercentShowTax: getPercentShowTax,
    };

    /**
     * @ngdoc function
     * @returns {Object}
     * @description
     * Returns selected cashbox shift
     */
    function getCashboxShift() {
        return selectedCashboxShift;
    }
    function getCurrencyShow () {
        return currencyShow;
    }
    function getReceiptFormat () {
        return receiptFormat;
    }
    function getPercentShowTax () {
        return percentShowTax;
    }

    /**
     * @ngdoc function
     * @param {Object} cashboxShift cashbox shift object
     * @description
     * Sets selected cashbox shift
     */
    function setCashboxShift(cashboxShift) {
        selectedCashboxShift = cashboxShift;
    }
    function setCurrencyShow (res) {
        currencyShow = res;
    }
    function setReceiptFormat(res) {
        receiptFormat = res;
    }
    function setPercentShowTax (res) {
        percentShowTax = res;
    }

}

export default {
    type: 'factory',
    name: 'shareCashboxShift',
    value: shareCashboxShift
};
