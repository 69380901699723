tableViewColumnFactory.$inject = ['TableViewColumnPropertyView', 'TableViewColumnAction', 'TableViewColumnInput', 'TableViewColumnPropertyViewExtended'];

function tableViewColumnFactory(TableViewColumnPropertyView, TableViewColumnAction, TableViewColumnInput, TableViewColumnPropertyViewExtended) {

    this.createPropertyViewColumn = createPropertyViewColumn;
    this.createPropertyViewExtendedColumn = createPropertyViewExtendedColumn
    this.createActionColumn = createActionColumn;
    this.createInputColumn = createInputColumn;


    function createPropertyViewColumn(title, valueGetter, options) {
        return new TableViewColumnPropertyView(title, valueGetter, options);
    }

    function createPropertyViewExtendedColumn(title, propertyName, fields, options) {
        return new TableViewColumnPropertyViewExtended(title, propertyName, fields, options);
    }

    function createActionColumn(title, actionIcon, actionCb, options) {
        return new TableViewColumnAction(title, actionIcon, actionCb, options)
    }

    function createInputColumn(title, propertyName, options) {
        return new TableViewColumnInput(title, propertyName, options);
    }
}

export default {
    type: 'service',
    name: 'tableViewColumnFactory',
    value: tableViewColumnFactory
};