/**
 * Created by sirosh on 02.06.16.
 */

ticketNameError.$inject = [];

function ticketNameError() {

    return {
        restrict: 'A',
        link: linkFn,
        require: '^ngModel'
    };

    function linkFn(scope, element, attr, controller) {

        var input = document.getElementsByTagName('input');

        scope.$parent.$watch('ticketsForm', function(){

                element.on('blur', function (e) {
                    var str = $(element).val();
                    var value = str.replace(/^\s+|\s+$/g, "");
                    var obj = JSON.parse(attr.options);

                    for (var i = 0, len = obj.length; i < len; i++) {
                        if(obj[i].name && value && value.length <=100){
                            if (parseInt(attr.index) !== i && obj[i].name.toLowerCase() === value.toLowerCase()) {
                                controller.$setValidity('name', false);
                                controller.setAlertMessage('NAME_REPEATE');
                                $(element).parent().children('div.err-mess').css('display', 'block');
                                break;
                            } else if (obj[i].name.toLowerCase() !== value.toLowerCase() || parseInt(attr.index) === i) {
                                controller.$setValidity('name', true);
                                controller.$invalid = false;
                                controller.$error = {};
                                controller.err = {};
                                controller.$valid = true;
                                $(element).parent().removeClass('has-error');
                                $(element).removeClass('ng-invalid ng-invalid-name');
                                $(element).parent().children('div.err-mess').css('display', 'none');
                            }
                        }
                    }
                });



                if(scope.$parent!== null && scope.$parent.ticketsForm!== undefined && scope.$parent.ticketsForm.$invalid && scope.$parent.ticketsForm.$invalid === true){

                    simulateOnblur();


                }

        }, true)


        function simulateOnblur() {

            var event = new MouseEvent('blur', {
                view: window,
                bubbles: true,
                cancelable: true
            });

            for(var i = 0; i< input.length; i++){
                if(!document.hasFocus() && input[i].value !== null && input[i].value !== "" && input[i].value !== undefined && input[i].value.length <=100) {
                    input[i].dispatchEvent(event);

                }
            }

        }
    }
}

export default {
    type: 'directive',
    name: 'ticketNameError',
    value: ticketNameError
};