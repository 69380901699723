/**
 * Created by olehkss on 20.07.16.
 *
 * @ngdoc controller
 * @name app.controller: reportCategoriesController
 * @description
 * Binds $categories data to view. Defines pages variable in order to pass it to the table footer directive
 */

reportCategoriesController.$inject = [
    '$categories',
    '$tableProp',
    'waresReportTableCategories',
    '$window',
    '$scope',
    '$stateParams',
    '$state',
    '$currencyOptionsShow',
    '$percentShowInfinityToHundred',
    'constantGreenExport',
    '$profile',
    '$rootScope',
    '$permitsManager',
    '$miniBillingInfo',
    'serviceReports',
    '$reportCopyAllowed'];

function reportCategoriesController(
    $categories,
    $tableProp,
    waresReportTableCategories,
    $window,
    $scope,
    $stateParams,
    $state,
    $currencyOptionsShow,
    $percentShowInfinityToHundred,
    constantGreenExport,
    $profile,
    $rootScope,
    $permitsManager,
    $miniBillingInfo,
    serviceReports,
    $reportCopyAllowed) {

    let vm = this;

    $scope.blockDataCopy = !$reportCopyAllowed;

    vm.displayCost = $permitsManager.costAllowed;
    vm.reportError = $categories.hasOwnProperty('unableToLoadData');
    vm.reportErrorServer = vm.reportError && $categories.unableToLoadData;

    $scope.pageWrapHeight = document.getElementsByClassName('report-categories')[0];
    $scope.isMobile = $rootScope.isMobile;
    $scope.isSafari = $rootScope.isSafari;
    vm.tableProp = $tableProp.dataArr;

    vm.hiddenColumns = vm.displayCost ? [] : ['costOfGoods', 'grossProfit', 'margin'];

    vm.tableConfig = $tableProp.config;
    vm.reportTypeForColumns = "CATEGORIES";
    vm.waresReportTable = waresReportTableCategories;
    vm.downloadHrefBase = '/data/ownercab/categoriesreportdownload';
    vm.windowWidth = $window.outerWidth;
    $scope.isGreen = constantGreenExport.indexOf($profile.cabinetLang) !== -1;

    vm.size = function(obj) {
        let size = 0, key;
        for (key in obj) {
            if (obj[key] == true) size++;
        }
        return size;
    };

    vm.countColums = vm.size(vm.tableConfig);

    $scope.$on('howManyColumns', function(data){
        vm.countColums = vm.size(data.targetScope.vmTableProps.configValue);
    });

    if (!vm.reportError) {

        vm.periodAllowed = $categories.periodAllowed;
        vm.unlimitedSalesHistory = serviceReports.getSalesHistoryData($profile, $miniBillingInfo, $permitsManager);

        if ($categories.startDateString && $categories.endDateString) {
            let fromParse = $categories.startDateString.split('-');
            let toParse = $categories.endDateString.split('-');
            vm.startDate = new Date(fromParse[0], fromParse[1] - 1, fromParse[2]).getTime();
            vm.endDate = new Date(toParse[0], toParse[1] - 1, toParse[2]).getTime();
        } else {
            vm.startDate = $categories.startDate;
            vm.endDate = $categories.endDate;
        }

        if (vm.periodAllowed) {
            vm.categories = $categories.data;
            vm.pages = $categories.pages;
            $scope.outlets = $categories.outlets;
            $scope.merchants = serviceReports.getMerchantsList($scope.outlets);
            vm.dataHover;


            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
            if (vm.categories.length === 0 && $stateParams.page > 0 && vm.pages > 0) {
                $state.go('report.categories', {page: vm.pages - 1});
            } else if (vm.categories.length === 0 && $stateParams.page > 0 && vm.pages === 0) {
                $state.go('report.categories', {page: 0});
            }
            // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ДАННЫХ ДЛЯ ОТОБРАЖЕНИЯ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

            /*** формат отображения валюты ***/
            $scope.currencyOptionsShow = $currencyOptionsShow;

            /*** формат отображения процентов ***/
            $scope.percentShowInfinityToHundred = $percentShowInfinityToHundred;
        }
    }

}

export default {
    type: 'controller',
    name: 'reportCategoriesController',
    value: reportCategoriesController
};
