/**
 * Created by Oleksii Nikitin on 29/03/23.
 */

const countriesStoreExclude = [
    'fr','de','ir','cu','sy','sd','ss','ru','by'
];

export default {
    type: 'constant',
    name: 'countriesStoreExclude',
    value: countriesStoreExclude
}