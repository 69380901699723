serviceToast.$inject = ['$timeout', '$rootScope', '$compile'];

function serviceToast($timeout, $rootScope, $compile) {
    var container;
    //var scope = $rootScope.$new();
    var scope = this;
    scope.arrayToast = [];
    var index = 0;

    /*var init = function () {
     container = '<div class="l-toast"><div class="animate-repeat {{toast._class}}" ng-repeat="toast in arrayToast | orderBy:\'index\':true">{{toast.mess}}</div></div>';
     var linkFn = $compile(container);
     var content = linkFn(scope);
     content.css('position', 'absolute');
     content.css('zIndex', 20);
     content.css('right', '20px');
     content.css('top', '80px');
     document.body.appendChild(content[0]);
     };
     init();*/


    function remove(obj){
        for(var i = 0 ; i< scope.arrayToast.length; i++){
            if(scope.arrayToast[i] == obj){
                scope.arrayToast.splice(i, 1);
                break;
            }
        }
    }


    function delToast( obj ) {
        $timeout( function () {
            remove(obj)
        }, 8000 )
    }

    this.hide = angular.bind(this, remove);
    this.show = function ( mess, _class, translate ) {
        var obj = {
            mess: mess,
            index: index,
            _translate: true,
            _class: _class || 'default'
        };
        index++;
        scope.arrayToast.push( obj );
        delToast( obj )
        return obj
    }
}

export default serviceToast;