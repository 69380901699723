/**
 * Created by A.Sirosh on 17.10.2015.
 * Edited by K.Yarosh on 30.11.2017
 * Different operations with array
 * @memberof arrayOperation
 * @ngdoc servise
 * @name arrayOperation
 */

import angular from 'angular';

arrayOperation.$inject = [];
function arrayOperation() {

     this.getMinOfArray = getMinOfArray;
     this.getMaxOfArray = getMaxOfArray;
     this.getAverageOfArray = getAverageOfArray;
     this.sortArray = sortArray;
     this.checkDuplicateOptions = checkDuplicateOptions;
     this.findArrayAmongObjectsInRecursiveWay = findArrayAmongObjectsInRecursiveWay;

     // for numbers
     function getMinOfArray(numArray) {
         return Math.min.apply(null, numArray);
     }

     // for numbers
     function getMaxOfArray(numArray) {
         return Math.max.apply(null, numArray);
     }

     function getAverageOfArray (numArray){
         return (numArray.reduce(function (accumulator, currentValue) {
             return accumulator + currentValue;
         }, 0))/numArray.length;
     }

     function sortArray(array){

        var sortedArray = angular.copy(array);

        function compare(first, second){

             if(typeof first  !== "string"){

                 return first - second;

             } else {

                 if (first < second) {
                     return -1;
                 }
                 else if (first > second) {
                     return 1;
                 } else {
                     // first must be equal to second
                     return 0;
                 }

             }
         }

        if(Array.isArray(array)){

            sortedArray = sortedArray.sort(compare);
            return sortedArray;
        }

     }

     function checkDuplicateOptions(array, caseSensitivity) {
         var i,
             length = array.length,
             sortedArray,
             result = [];

         sortedArray = sortArray(array);

         if(caseSensitivity === 'inSensitive'){
             sortedArray = sortedArray.map(function(elem){
                return elem.toLowerCase();
             });
         }

         // for ( i = 1; i < length; i++ ){
         //     if(sortedArray[i-1] === sortedArray[i])
         //         return false;
         // }

         sortedArray.forEach(function(element, index) {

             // Find if there is a duplicate or not
             if (sortedArray.indexOf(element, index + 1) > -1) {

                 // Find if the element is already in the result array or not
                 if (result.indexOf(element) === -1) {
                     result.push(element);
                 }
             }
         });

         if(result.length > 0) {
             return true;
         } else {
             return false;
         }
     }

     function findArrayAmongObjectsInRecursiveWay(data){

         var arrayInObj = false;

         function loopThroughInnerObj(obj){
             for (var key2 in obj) {
                 if (obj.hasOwnProperty(key2) && Array.isArray(obj[key2])) {
                     arrayInObj = true;
                     findArrayAmongObjectsInRecursiveWay(obj[key2]);
                     break;
                 }
             }

             if(!arrayInObj){
                 console.log(obj);
                 return obj;
             }


         }

         if(Array.isArray(data)) {
             var nestedArray = false;
             data.forEach(function (item) {
                 if (typeof(item) === 'object') {
                     nestedArray = true;
                     for (var key in item) {
                         if (item.hasOwnProperty(key) && typeof(item[key]) === "object" && !Array.isArray(item[key])) {
                             loopThroughInnerObj(item[key]);
                         } else if(item.hasOwnProperty(key) && Array.isArray(item[key])){
                             arrayInObj = true;
                             findArrayAmongObjectsInRecursiveWay(item[key]);
                             break;
                         }
                     }

                 }
             });


             if(!arrayInObj){
                 console.log(data);
                 return data;
             }

            if(!nestedArray){
                 console.log(data);
                 return data
             }

         } else if(typeof(data) === 'object'){

             loopThroughInnerObj(data);

         }
     }

     //left for tests
     // var array1 = [
     //     {option: "1", id: 1}
     //     // {option: "1", id: 3},
     //     // {option: "1", id: 6},
     //     // {option: "1", id: 8},
     //     // {option: "1", id: 12},
     // ]
     //
     // var array2 = [
     //     {option: {name: "color", values: [1,2,3]}, id: 1}
     //     // {option: {name: "size", values: [1,2,3]}, id: 3},
     //     // {option: {name: "fabric", values: [1,2,3]}, id: 6},
     //     // {option: {name: "complexity", values: [1,2,3]}, id: 8},
     // ]
     //
     // var array3 = [
     //     {option: {name: "color", values: [{prop: 1}, {prop: 2},{prop: 3}]}, id: 1}
     //     // {option: {name: "size", values: [{prop: 1}, {prop: 2},{prop: 3}]}, id: 3},
     //     // {option: {name: "fabric", values: [{prop: 1}, {prop: 2},{prop: 3}]}, id: 6},
     //     // {option: {name: "complexity",values: [{prop: 1}, {prop: 2},{prop: 3}]}, id: 8},
     // ]
     // var array4 = [1,5,8];
     //
     // findArrayAmongObjectsInRecursiveWay(array1);
     // findArrayAmongObjectsInRecursiveWay(array2);
     // findArrayAmongObjectsInRecursiveWay(array3);
     // findArrayAmongObjectsInRecursiveWay(array4);


}

export default {
    type: 'service',
    name: 'arrayOperation',
    value: arrayOperation
};