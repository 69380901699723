/**
 * Created by mars on 11/6/15.
 */

import angular from 'angular';

import icReportsImg from '../../img/ic_reports.svg';
import icShoppingImg from '../../img/ic_shopping.svg';
import inventoryLB600Img from '../../img/inventory-LB600.svg';
import employeesNewImg from '../../img/employees-new.svg';
import icGroupImg from '../../img/ic_group.svg';
import icExtensionBrownImg from '../../img/ic_extension_brown.svg';
import icSettings24pxImg from '../../img/ic_settings_24px.svg';
import helpOutline24pxImg from '../../img/help_outline-24px.svg';

serviceMenu.$inject = [
    'setChartPeriod',
    'permitsMenu',
    'siteLangCode',
    '$filter',
    'serviceDialog',
    '$state',
    'lvMixpanel',
    'factoryProfile'
];
function serviceMenu(
    setChartPeriod,
    permitsMenu,
    siteLangCode,
    $filter,
    serviceDialog,
    $state,
    lvMixpanel,
    factoryProfile
) {

    var s = this;

    var date = new Date();
    var translate = $filter('translate');
    var hasBillingRights = false;

    /**
     * Класс меню
     * @type {string}
     */
    this.navBoard = 'nav-close';

    this.settingsMenu = (window.innerWidth > 600);

    /**
     * If user is blocked then menu will turn out grey
     */
    this.menuIsBlocked = false;

    this.showInfo = false;

    /**
     * Открылто или закрыто выпадающий список свернутого меню
     * @type {boolean}
     */
    this.depVenu = false;
    s.permitsConstMenu = [];

    var settingsObj = {};

    var reportDefaultDate = setChartPeriod.setButtonPeriod('thirtyDays');
    var from1 = reportDefaultDate.from;
    var to1 = reportDefaultDate.to;


    this.settingsMobileMenu = [
        {
            name: 'settings.profile',
            key: 'FEATURES',
            uiSref: "settings.profile",
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.account',
            key: 'ACCOUNT',
            uiSref: 'settings.account',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.paytypes',
            key: 'PAYMENT_TYPES',
            uiSref: 'settings.paytypes',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.loyalty',
            key: 'LOYALTY',
            uiSref: 'settings.loyalty',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.tax',
            key: 'TAX',
            uiSref: 'settings.tax',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.reciepts',
            key: 'CHECK',
            uiSref: 'settings.reciepts',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.predefined',
            key: 'OPEN_TICKETS',
            uiSref: 'settings.predefined',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.kitchen',
            key: 'KITCHEN_PRINTERS',
            uiSref: 'settings.kitchen',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.dining',
            key: 'DINING_OPTIONS',
            uiSref: 'settings.dining',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.outlet',
            key: 'STORES',
            uiSref: 'settings.outlet',
            grayWhenBlock: true,
            hide: true
        },
        {
            name: 'settings.cashregister',
            key: 'CASH_REGISTERS',
            uiSref: 'settings.cashregister',
            grayWhenBlock: true,
            hide: true
        }

    ];

    this.integrationsMenuAfterStarted = [
        {
            name: 'integrations.apps',
            key: 'APPS_LIST',
            uiSref: "integrations.apps",
            grayWhenBlock: true,
            hide: false
        },
        {
            name: 'integrations.tokens',
            key: 'ACCESS_TOKENS',
            uiSref: "integrations.tokens",
            grayWhenBlock: true,
            hide: false
        }

    ];

    //https://www.youtube.com/watch?v=iBHr8gKc5L8
    /**
     * Структура меню навигации
     * @type {*[]}
     */
    let initConstantMenu = [
        {
            id: 0,
            name: "report",
            key: "REPORT",
            value: "ОТЧЕТЫ",
            childrenShow: false,
            grayWhenBlock: true,
            icon: icReportsImg,
            children: [
                {
                    name: 'report.sales',
                    key: 'SALES_SUMMARY',
                    uiSref: "report.sales({page:0,limit:10,group:day})",
                    disabled: false,
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.goods',
                    key: 'ITEMS_SALES',
                    uiSref: "report.goods({page:0,limit:10,chart:bar,group:day})",
                    disabled: false,
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.categories',
                    key: 'CATEGORIES_SALES',
                    uiSref: "report.categories({page:0,limit:10})",
                    disabled: false,
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.employee',
                    key: 'EMPLOYEE_SALES',
                    uiSref: "report.employee({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.payTypes',
                    key: 'PAYMENT_TYPES_SALES',
                    uiSref: "report.payTypes({page:0})",
                    disabled: false,
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.receipts',
                    value: "Операции",
                    key: 'TRANSACTIONS',
                    uiSref: "report.receipts({page:0,limit:10,type:all})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.modifiers',
                    value: 'MODIFIERS_SALES',
                    key: 'MODIFIERS_SALES',
                    uiSref: "report.modifiers({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.discounts',
                    value: 'Скидки',
                    key: 'DISCOUNTS',
                    uiSref: "report.discounts({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.tax',
                    value: "Налоги",
                    key: 'TAX',
                    uiSref: "report.tax({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'report.shift',
                    value: "SHIFT",
                    key: 'SHIFT',
                    uiSref: "report.shift({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: true
                }
            ]
        },
        {
            id: 1,
            name: "goods",
            key: 'GOODS',
            childrenShow: false,
            grayWhenBlock: true,
            icon: icShoppingImg,
            children: [
                {
                    name: 'goods.price',
                    key: 'ITEMS_LIST',
                    uiSref: "goods.price({page:0,limit:10,inventory:all})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'goods.categories',
                    key: 'CATEGORY',
                    uiSref: "goods.categories({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'goods.modifiers-list',
                    key: 'MODIFIERS',
                    uiSref: 'goods.modifiers-list({page:0})',
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'goods.discount',
                    key: 'DISCOUNTS',
                    uiSref: "goods.discountlist({page:0,limit:10})",
                    grayWhenBlock: true,
                    hide: false
                }
            ]
        },
        {
            id: 2,
            name: "inventory",
            key: 'INVENTORY',
            childrenShow: false,
            grayWhenBlock: true,
            icon: inventoryLB600Img,
            children: [
                {
                    name: 'inventory.purchase',
                    key: 'PURCHASE_ORDERS',
                    uiSref: "inventory.purchase({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.transfer',
                    key: 'TRANSFER_LIST',
                    uiSref: "inventory.transfer({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.adjustment',
                    key: 'ADJASTMENT_LIST',
                    uiSref: "inventory.adjustment({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.counts',
                    key: 'INVENTORY_COUNT',
                    uiSref: "inventory.counts({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.productions',
                    key: 'PRODUCTIONS_LIST',
                    uiSref: "inventory.productions({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.supplierlist',
                    key: 'SUPPLIER_LIST',
                    uiSref: "inventory.supplierlist({page:0,limit:10})",
                    hide: true
                },
                {
                    name: 'inventory.history',
                    key: 'INVENTORY_HISTORY',
                    uiSref: "inventory.history({page:0,limit:10,reasons:all})",
                    hide: true
                },
                {
                    name: 'inventory.valuation',
                    key: 'INVENTORY_VALUATION',
                    uiSref: "inventory.valuation({page:0,limit:10})",
                    hide: true
                }
            ]
        },
        {
            id: 3,
            name: "employees",
            key: 'EMPLOYEES',
            css: 'employee',
            childrenShow: false,
            grayWhenBlock: true,
            icon: employeesNewImg,
            children: [
                {
                    name: "employees.list",
                    key: 'EMPLOYEES_LIST',
                    uiSref: "employees.list({page: 0,limit:10})",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: "employees.permitslist",
                    key: 'EMPLOYEES_PERMITS',
                    uiSref: "employees.permitslist({page:0,limit:10})",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: "employees.timecards",
                    key: 'EMPLOYEES_TIMECARDS',
                    uiSref: "employees.timecards({page:0,limit:10,outletId:null,merchantId:null})",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: true
                },
                {
                    name: "employees.createtimecard",
                    key: 'CREATE_TIMECARD',
                    uiSref: "employees.createtimecard",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: true
                },
                {
                    name: "employees.edittimecard",
                    key: 'EDIT_TIMECARD',
                    uiSref: "employees.edittimecard",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: true
                },
                {
                    name: "employees.timeclockreport",
                    key: 'EMPLOYEES_TIMECLOCKREPORT',
                    uiSref: "employees.timeclockreport({page:0,limit:10,outletId:null,merchantId:null})",
                    css: 'employee',
                    grayWhenBlock: true,
                    hide: true
                }
            ]
        },
        {
            id: 4,
            name: "clients.database",
            key: 'CLIENTS',
            childrenShow: false,
            grayWhenBlock: true,
            uiSref: "clients.database({page: 0,limit:10})",
            icon: icGroupImg
        },
        {
            id: 5,
            name: "integrations",
            key: 'ACCESS_TOKENS_INT',
            grayWhenBlock: true,
            childrenShow: false,
            icon: icExtensionBrownImg,
            children: [
                {
                    name: 'integrations.apps',
                    key: 'APPS_LIST',
                    uiSref: "integrations.apps",
                    grayWhenBlock: true,
                    hide: false
                },
                {
                    name: 'integrations.tokens',
                    key: 'ACCESS_TOKENS',
                    uiSref: "integrations.tokens",
                    grayWhenBlock: true,
                    hide: false
                }

            ]
        },
        {
            id: 6,
            name: "settings.profile",
            key: 'SETTINGS',
            childrenShow: false,
            grayWhenBlock: true,
            value: "НАСТРОЙКИ",
            uiSref: "settings.profile",
            css: 'dark-gray',
            icon: icSettings24pxImg,
            children: s.settingsMenu ? [] : s.settingsMobileMenu
        },
        {
            id: 7,
            name: "help",
            key: "HELP",
            childrenShow: false,
            icon: helpOutline24pxImg,
            unreadMessage: false,
            children: [
                {
                    name: 'help.center',
                    key: 'HELP_CENTER',
                    uiSref: null,
                    href: "https://help.loyverse.com/?utm_source=Backoffice&utm_medium=Help",
                    hide: false
                },
                {
                    name: 'help.community',
                    key: 'HELP_COMMUNITY',
                    uiSref: null,
                    href: "https://loyverse.town/?utm_source=Backoffice&utm_medium=Help",
                    hide: false
                },
                {
                    name: 'help.chat',
                    id: 'intercom-launcher',
                    key: 'HELP_CHAT',
                    uiSref: "",
                    variable: 0,
                    hide: true
                }
            ]
        }
    ];

    // Add unique ids to all menu items
    initConstantMenu.forEach(menuItem => {
        menuItem.uid = 'lv_menu_item_' + menuItem.name;

        if (menuItem.children) {
            menuItem.children.forEach(subMenuItem => {
                subMenuItem.uid = menuItem.uid + '__' + subMenuItem.name;
            });
        }
    });

    s.constantMenu = angular.copy(initConstantMenu);

    reset();

    function setVisible() {
        for (let i = 0; i < s.constantMenu[0].children.length; i++) {
            s.constantMenu[0].children[i].hide = false;
        }
    };

    function setGrayWhenBlock(profile) {
        let hideStoresAndPOS = profile.billingInfo.blockExcept.indexOf("INVENTORY") === -1
            && profile.billingInfo.blockExcept.indexOf("INTEGRATION") === -1
            && profile.billingInfo.blockExcept.indexOf("EMPSTORE") === -1;

        let hasBillingAccess = profile.billingInfo.hasAccessToBilling;
        let isBlockedByBilling = profile.billingInfo.billingStatus === "BLOCKED";
        let isOnHold = profile.billingInfo.billingStatus === "HOLD";
        let isBlockedByStores = profile.blockedForNoStores;

        if (isBlockedByStores) {
            this.menuIsBlocked = true;

            // Help menu isn't deactivated
            for (let i = 0; i < s.constantMenu.length - 1; i++) {
                s.constantMenu[i].uiSref = ''
            }
        } else if ((isBlockedByBilling || isOnHold) && hasBillingAccess) {

            s.constantMenu[6].grayWhenBlock = false;
            s.constantMenu[6].name = "settings.profile";
            s.constantMenu[6].uiSref = "settings.account";
            s.constantMenu[2].uiSref = '';
            s.constantMenu[4].uiSref = '';
            s.constantMenu[5].uiSref = '';

            if (profile.billingInfo.blockExcept.indexOf( "EMPLOYEE" ) !== -1 && hasBillingAccess) {
                s.constantMenu[3].grayWhenBlock = profile.billingInfo.subscriptionPaused;
                s.constantMenu[3].children[0].grayWhenBlock = false;
                s.constantMenu[3].children[0].hide = profile.billingInfo.subscriptionPaused;
                s.constantMenu[3].children[1].hide = true;
                s.constantMenu[3].children[2].hide = true;
                s.constantMenu[3].children[3].hide = true;
                s.constantMenu[3].children[4].hide = true;
                s.constantMenu[3].children[5].hide = true;
            }
            this.settingsMobileMenu[0].hide = true;
            this.settingsMobileMenu[2].hide = true;
            this.settingsMobileMenu[3].hide = true;
            this.settingsMobileMenu[4].hide = true;
            this.settingsMobileMenu[5].hide = true;
            this.settingsMobileMenu[6].hide = true;
            this.settingsMobileMenu[7].hide = true;
            this.settingsMobileMenu[8].hide = true;
            this.settingsMobileMenu[9].hide = hideStoresAndPOS;
            this.settingsMobileMenu[10].hide = hideStoresAndPOS;
        } else if ((isBlockedByBilling || isOnHold) && !hasBillingAccess) {
            s.constantMenu[5].uiSref = '';
            s.constantMenu[6].uiSref = '';
            s.constantMenu[2].uiSref = '';
            s.constantMenu[4].uiSref = '';
        }
    };

    function mobileMenusShow (stateName) {
        for(var g = 0, gLen = s.settingsMobileMenu.length; g < gLen; g++) {
            if(s.settingsMobileMenu[g].name === stateName) {
                s.settingsMobileMenu[g].hide = false;
                break;
            }
        }
    }

    function setAccess(resPermits) {

        for (var i = 0, len = resPermits.length; i < len; i++) {
            for (var j = 0, jLen = permitsMenu.length; j < jLen; j++) {
                if (permitsMenu[j].permit === resPermits[i]) {
                    s.permitsConstMenu.push(permitsMenu[j]);
                    if (permitsMenu[j].permit === 'ACCESS_EDIT_PROFILE') {
                        settingsObj.profile = permitsMenu[j].stateName;
                        mobileMenusShow('settings.profile');
                        mobileMenusShow('settings.reciepts');
                    } else if (permitsMenu[j].permit === 'ACCESS_EDIT_LOYALTY') {
                        settingsObj.loyalty = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === 'ACCESS_TAXES') {
                        settingsObj.tax = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === 'ACCESS_OUTLETS') {
                        settingsObj.outletsName = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === "ACCESS_BILLING") {
                        settingsObj.account = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                        hasBillingRights = true;
                    } else if (permitsMenu[j].permit === "ACCESS_EDIT_PAYMENT_TYPE") {
                        settingsObj.paymenttypes = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === "ACCESS_DINING_OPTIONS") {
                        settingsObj.dining = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === "ACCESS_KITCHEN_PRINTER") {
                        settingsObj.kitchen = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === "ACCESS_CASH_REGISTERS") {
                        settingsObj.cashregister = permitsMenu[j].stateName;
                        mobileMenusShow(permitsMenu[j].stateName);
                    } else if (permitsMenu[j].permit === "ACCESS_BACK_OFFICE_SUPPORT") {
                        s.constantMenu[7].children[2].hide = false;
                    }
                }
            }
        }

        var settingsMenuIndex = 0;
        for(var i = 0; i < s.constantMenu.length; i++){
            if(s.constantMenu[i].name && s.constantMenu[i].name.indexOf("settings") > -1) {
                settingsMenuIndex = i;
                break;
            }
        }

        s.constantMenu[settingsMenuIndex].uiSref = settingsObj.profile
                                                || settingsObj.loyalty
                                                || settingsObj.tax
                                                || settingsObj.dining
                                                || settingsObj.kitchen
                                                || settingsObj.reciepts
                                                || settingsObj.cashregister
                                                || settingsObj.outletsName
                                                || settingsObj.account;
        s.constantMenu[settingsMenuIndex].name   = settingsObj.profile
                                                || settingsObj.loyalty
                                                || settingsObj.tax
                                                || settingsObj.dining
                                                || settingsObj.kitchen
                                                || settingsObj.reciepts
                                                || settingsObj.cashregister
                                                || settingsObj.outletsName
                                                || settingsObj.account
                                                || "settings.profile";

        for (var k = 0, kLen = s.constantMenu.length; k < kLen; k++) {
            for (var m = 0, mLen = s.permitsConstMenu.length; m < mLen; m++) {
                if (s.permitsConstMenu[m].stateName === s.constantMenu[k].name) {
                    s.constantMenu[k].hide = false;
                    break;
                } else {
                    s.constantMenu[k].hide = true;
                }
            }
        }
    };

    this.settingsObj = settingsObj;

    function showCashBox(shiftsEnabled) {
        s.constantMenu[0].children[9].hide = !shiftsEnabled;
    }

    function showOpenReceipts(profile) {
        let hasBillingAccessAndBlocked = (profile.billingInfo.billingStatus === "BLOCKED" || profile.billingInfo.billingStatus === "HOLD") && profile.billingInfo.hasAccessToBilling;
        s.settingsMobileMenu[6].hide = hasBillingAccessAndBlocked || !profile.useOpenedReceipts;
    }

    function showKitchenPrinyer(profile) {
        let hasBillingAccessAndBlocked = (profile.billingInfo.billingStatus === "BLOCKED" || profile.billingInfo.billingStatus === "HOLD") && profile.billingInfo.hasAccessToBilling;
        s.settingsMobileMenu[7].hide = hasBillingAccessAndBlocked || !(s.settingsObj.kitchen && profile.useKitchenPrinter);
    }

    function showDiningOptins(profile) {
        let hasBillingAccessAndBlocked = (profile.billingInfo.billingStatus === "BLOCKED" || profile.billingInfo.billingStatus === "HOLD") && profile.billingInfo.hasAccessToBilling;
        s.settingsMobileMenu[8].hide = hasBillingAccessAndBlocked || !(s.settingsObj.dining && profile.useDiningOptions);
    }

    function showTimeTracking(timeCardsEnabled) {
        s.constantMenu[3].children[2].hide = !timeCardsEnabled;
        s.constantMenu[3].children[5].hide = !timeCardsEnabled;
    }

    function helpCenterLang( profile ) {
        var siteUrlLang = siteLangCode[profile.cabinetLang] ? siteLangCode[profile.cabinetLang]+'/help' : '';
        s.constantMenu[7].children[0].href = "https://help.loyverse.com/"+siteUrlLang+"?utm_source=Backoffice&utm_medium=Help";
    }

    function setUnreadCount(val, profile) {
        if(profile.billingInfo.supportAvailable) {
            s.constantMenu[7].children[2].variable = val;
            s.constantMenu[7].unreadMessage = val > 0;
        } else {
            s.constantMenu[7].children[2].variable = 0;
            s.constantMenu[7].unreadMessage = false;
        }
    }


    // show stock notification in profile
    this.showLowStockNotification = false;

    function reset() {
        s.constantMenu.forEach((menuItem, index) => {
            let initItem = initConstantMenu.find(initItem => initItem.id === menuItem.id)
            for (let key in menuItem) {
                menuItem[key] = initItem[key];
            }
        });
    }

    function setDefaultPages(profile) {

        let state;
        // If owner was registered from POS, Sales Summary is shown to him at the first login
        let hasLogged = profile.cabLoginCount > 0 || profile.appType === "LPOS";
        let isBlockedByBilling = profile.billingInfo.billingStatus === "BLOCKED";
        let isOnHold = profile.billingInfo.billingStatus === "HOLD";
        let hasBillingAccess = profile.billingInfo.hasAccessToBilling;
        let isBlockedByStores = profile.blockedForNoStores;

        if (hasLogged && !isBlockedByBilling && !isOnHold && !isBlockedByStores) {
            for (let i = 0, len = s.constantMenu.length; i < len; i++) {
                if (s.constantMenu[i].hide === false) {
                    if (s.constantMenu[i].children && s.constantMenu[i].children.length) {
                        if (s.constantMenu[i].children[0].children) {
                            state = s.constantMenu[i].children[0].children[0].name;
                            break;
                        } else {
                            state = s.constantMenu[i].children[0].name;
                            break;
                        }
                    } else {
                        state = s.constantMenu[i].name;
                        break;
                    }
                } else {
                    state = 'settings.profile';
                }
            }
        } else if (isBlockedByStores) {
            state = 'nostores';
        } else if (hasBillingAccess && (hasLogged && isBlockedByBilling || isOnHold)) {
            state = 'settings.account';
        } else if (!hasBillingAccess && (hasLogged && isBlockedByBilling || isOnHold)) {
            state = 'accountblock';
        } else {
            state = 'settings.profile';
        }

        return state;
    }

    function isSupportAvailable( profile ) {
        // s.constantMenu[7].children[2].id = 'intercom-message';
        if( !profile.billingInfo.supportAvailable ) {
            s.constantMenu[7].children[2].id = 'intercom-message';
        }
    }

    function openChatMixpanel() {
        lvMixpanel.track('Help', {
            "Property": "Live chat",
            "Value": "Open Intercom chat"
        });
        // console.log('Open Intercom chat')
    }

    function chatNotAvailable() {
        // openChatMixpanel()
        factoryProfile.getProfile().then( function( profile ) {

            let employeePrice = profile.billingInfo.plans.EMPLOYEE ? profile.billingInfo.plans.EMPLOYEE[0].price : null;
            let empstorePrice = profile.billingInfo.plans.EMPSTORE ? profile.billingInfo.plans.EMPSTORE[0].price : null;
            let price = (profile.billingInfo.plans != null && empstorePrice != null) ?
                (employeePrice != null ? employeePrice : empstorePrice) : 500;
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-live-chat-not-available.html',
                title: translate('LIVE_CHAT_UNAVAILABLE'),
                contents: [
                        hasBillingRights ? translate('SUBSCRIBE_TO_PAID_SERVICE', {value: $filter('currencyFormatOnlyBilling')(price, false)}) : translate('ASC_OWNER_TO_SUBSCRIBE', {value: $filter('currencyFormatOnlyBilling')(price, false)}),
                        translate('ALTERNATIVE_ANSWER')
                ],
                buttons: hasBillingRights ? [
                    {
                        text: 'GOT_IT',
                        action: function () {
                            lvMixpanel.track('Help', {
                                "Property": "Live chat",
                                "Value": "Got it"
                            });
                        }
                    },{
                        class: 'primary',
                        text: 'GO_TO_SUBSCRIPTIONS',
                        action: function () {
                            lvMixpanel.track('Help', {
                                "Property": "Live chat",
                                "Value": "Go to subscriptions"
                            });
                            $state.go('settings.account', {} );
                        }
                    }
                ] : [
                    {
                        class: 'primary',
                        text: 'GOT_IT',
                        action: function () {
                            lvMixpanel.track('Help', {
                                "Property": "Live chat",
                                "Value": "Got it"
                            });
                        }
                    }
                ]
            });
        });
    }


    this.setVisible = setVisible;
    // выбипраем какие пункты меню в свернутом виде надо оставить для показа и сделать активными
    this.setGrayWhenBlock = setGrayWhenBlock;
    // выбираем какие пункты меню в свернутом виде надо оставить для показа и сделать активными
    this.setAccess = setAccess;
    this.showCashBox = showCashBox;
    this.showTimeTracking = showTimeTracking;
    this.showOpenReceipts = showOpenReceipts;
    this.showKitchenPrinyer = showKitchenPrinyer;
    this.showDiningOptins = showDiningOptins;
    this.helpCenterLang = helpCenterLang;
    this.setUnreadCount = setUnreadCount;
    this.isSupportAvailable = isSupportAvailable;
    this.openChatMixpanel = openChatMixpanel;
    this.setDefaultPages = setDefaultPages;
    this.chatNotAvailable = chatNotAvailable;
    this.reset = reset;


    /**
     * Каждоме дочернему элементу меню делаем ссылку на родителя
     */
    (function a(arr, parent) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].children) {
                a(arr[i].children, arr[i])
            }
            if (parent) {
                arr[i].parent = parent
            }
        }
    }(this.constantMenu));
}

export default {
    type: 'service',
    name: 'serviceMenu',
    value: serviceMenu
};
