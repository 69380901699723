/**
 * Created by sirosh on 09.08.16.
 * @memberof tableProps
 * @ngdoc directive
 * @name app.directive:tableProps
 * @restrict E
 *
 * @scope
 * @param configLang {Object} - constant with translation key.
 * @param data {Array} - data with columns properties
 * @param configValue {Object} - object config for dependencies between directive and table view properties
 *
 * @require tableColumnProp {service} - set/get data to the server
 *
 * @description
 *
 * This directive to help users configure table columns view
 *
 * @example
 *
 * jade
 * table-props(data='tableProp', config-lang='waresReportTable', config-val='tableConfig' )
 *
 * html
 * <table-props data="tableProp" config-lang="waresReportTable" config-val="tableConfig"></table-props>
 *
 */

import angular from 'angular';
import template from '../templates-pug/for-directive/table-props.pug';

tableProps.$inject = [];
function tableProps() {
    return {
        restrict        : 'E',
        scope           : { configLang  : "=configLang",
                            data        : "=",
                            hiddenColumns: '<',
                            configValue : "=configVal",
                            repType     : "=reportType",
                            filter      : "<"
                            },
        bindToController: true,
        controller      : tablePropsCtrl,
        controllerAs    : 'vmTableProps',
        template     : template
    };
}

tablePropsCtrl.$inject = ['$scope', '$rootScope', '$state', 'tableColumnProp'];
function tablePropsCtrl($scope, $rootScope, $state, tableColumnProp) {
    let vm = this;
    vm.visibleColumns = [];
    vm.allColumns = [];
    $scope.columns = false;
    $scope.showIcon = true;

    this.$postLink = $postLink;

    vm.arab = ($rootScope.cabinetLang === 'ara' || $rootScope.cabinetLang === 'urd' || $rootScope.cabinetLang === 'heb') ? "" : "target-right target";

    if($state.current.name === "report.sales"){
        $scope.columns = true;
    }

    vm.hideShowColumn = function(value, name){
        if(angular.isDefined(vm.data)) {
            tableColumnProp.setProp(value, name, vm.configValue, vm.allColumns, vm.repType);
            $scope.$emit('howManyColumns', vm.allColumns);
        }

        this.filter(vm.data)
    };

    function $postLink() {
        if(angular.isDefined(vm.data)) {
            vm.allColumns = vm.data.slice();
            if (vm.hiddenColumns) {
                vm.visibleColumns = vm.data.filter(col => !vm.hiddenColumns.includes(col.name));
            }
            else {
                vm.visibleColumns = vm.data.slice();
            }
        }
    }
}

export default {
    type: 'directive',
    name: 'tableProps',
    value: tableProps
};