import angular from 'angular';

tableViewFooterCellDirective.$inject = [];
function tableViewFooterCellDirective(){
    return {
        restrict: 'E',
        controller: TableViewFooterCellController,
        controllerAs: 'ctrl',
        bindToController: true,
        scope: {
            item: '<lvItem'
        }
    }
}

TableViewFooterCellController.$inject=['$element', '$scope',  '$compile'];
function TableViewFooterCellController($element, $scope, $compile) {

    this.$onInit = $onInit;
    this.$postLink = $postLink;

    const self = this;

    function $onInit() {
        $element.addClass('lv-table-view__footer-cell');
    }

    function $postLink() {

        if (!self.item) {
            return;
        }

        let cellElement = angular.element('<div>');

        let bindValue = angular.isFunction(self.item.value) ? 'ctrl.item.value()' : 'ctrl.item.value';
        if (self.item.viewFilters) {
            for (let key in self.item.viewFilters) {
                bindValue += ' | ' + key;
                if (self.item.viewFilters[key]) {
                    bindValue += ` : ctrl.item.viewFilters[\'${key}\']`;
                }
            }
        }
        cellElement.attr('ng-bind', bindValue);

        if(self.item.id) {
            cellElement.attr('id', self.item.id);
        }

        if (self.item.class) {
            cellElement.addClass(self.item.class);
        }

        if (self.item.attrs) {
            for (let key in self.item.attrs) {
                cellElement.attr(key, self.item.attrs[key]);
            }
        }
        if (self.item.referenceAttrs) {
            for (let key in self.item.referenceAttrs) {
                if (angular.isFunction(self.item.referenceAttrs[key])){
                    cellElement.attr(key, "ctrl.item.referenceAttrs['" + key + "']()");
                } else {
                    cellElement.attr(key, "ctrl.item.referenceAttrs['" + key + "']");
                }
            }
        }

        $element.append(cellElement);
        $compile(cellElement)($scope);
    }
}

export default {
    type: 'directive',
    name: 'lvTableViewFooterCell',
    value: tableViewFooterCellDirective
};
