import angular from 'angular';

datePickerContrl.$inject = ['$scope', '$rootScope', '$element', '$attrs', '$timeout', '$templateCache',
	'serviceDatePickerDaysWeek', 'constantDatePicker', 'formatDatePickerMonth', 'serviceDatePickerEvents',
	'$filter', 'factoryDatePickerPosition', 'retrieveLangCode', 'dateCustomMethods'];
function datePickerContrl($scope, $rootScope, $element, $attrs, $timeout, $templateCache,
						  serviceDatePickerDaysWeek, constantDatePicker, formatDatePickerMonth, serviceDatePickerEvents,
						  $filter, factoryDatePickerPosition, retrieveLangCode, dateCustomMethods) {

	var link = $scope.link == 'true' ? true : false;
	// var _viewMonths = $scope.viewMonths = parseFloat( $scope.viewMonths ) || 3;
	var localeDate = $filter('formatDateByBrowser')(new Date(), '');
	var dateFormatDivider = localeDate.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/);
	var parsedDate;

	// if(window.innerWidth<600){
	var _viewMonths = 1;
	// }


	if ( $scope.exact ) {
		serviceDatePickerDaysWeek.exact = true
	}
	$scope.show = false;
	$scope.constantDatePicker = constantDatePicker;
	$scope.months = [];
	$scope.afterLabelValue = $scope.after || new Date();

	// render the calendar view depending on the middle date in a requested range based on afterLabelValue and beforeLabelValue
	// var currentViewDate = new Date( $scope.afterLabelValue.getTime() + (($scope.beforeLabelValue.getTime() - $scope.afterLabelValue.getTime()) / 2) );   // $scope.date;
	var currentViewDate = new Date( $scope.afterLabelValue.getTime() );   // $scope.date;

	$scope.includeMonths = 'modules/lv-date-picker/templates-pug/date-picker-months-container.html';

	function render( date ) {
		function getdate( i ) {
			var k = (-1 * Math.ceil( _viewMonths / 2 )) + i + 1;
			return new Date( date.getFullYear(), date.getMonth() + k );
		}

		for ( var i = 0; i < _viewMonths; i++ ) {
			var d = getdate( i );
			var btnBack = false, btnForward = false;
			if ( i === 0 ) {
				btnBack = true
			}
			if ( i === _viewMonths - 1 ) {
				btnForward = true
			}


			var month = {
				formatDatePickerMonth: formatDatePickerMonth.formatDatePickerMonth( d, {
					after: $scope.afterLabelValue,
					maxDate: $scope.maxDate,
					minDate: $scope.minDate,
					startWeek: $rootScope.startWeek,
					endWeek: $rootScope.endWeek
				} ),
				value: d.getTime(),
				btnBack: btnBack,
				btnForward: btnForward
			};

			if ( $scope.months[i] ) {
				var oldMonth = $scope.months[i];

				for ( var opt in oldMonth ) {
					// switch ( opt ) {
					// 	case 'formatDatePickerMonth':
					// 		comparisonWeek( oldMonth[opt], month[opt] )
					// 		break;
					// 	default :
							if ( oldMonth[opt] != month[opt] ) {
								oldMonth[opt] = month[opt]
							}
					// }
				}
			} else {
				$scope.months[i] = month;
			}
		}
	}


	function comparisonWeek( oldWeeks, newWeeks ) {
		for ( var a = 0; a < newWeeks.length; a++ ) {
			oldWeeks[a] = oldWeeks[a] ? oldWeeks[a] : [];
			for ( var b = 0; b < newWeeks[a].length + 2; b++ ) {
				if ( newWeeks[a][b] != oldWeeks[a][b] ) {
					oldWeeks[a][b] = newWeeks[a][b]
				}
			}
		}
		var length = newWeeks.length;
		oldWeeks.splice( length, 2 );

	}

	render( currentViewDate );

	$scope.stepBack = function () {
		var date = new Date( currentViewDate.getFullYear(), currentViewDate.getMonth() - 1, currentViewDate.getDate() )
		currentViewDate = date;
		render( date );
	};
	$scope.stepForward = function () {
		var date = new Date( currentViewDate.getFullYear(), currentViewDate.getMonth() + 1, currentViewDate.getDate() )
		currentViewDate = date;
		render( date )
	};

	$scope.serviceDatePickerEvents = serviceDatePickerEvents;
	var startDay = null;
	$scope.$watch( 'serviceDatePickerEvents.hoverDay', function ( day ) {
		if ( !day ) return;

		if ( $$scope.afterLabelValue && $scope.serviceDatePickerEvents.startDay ) {
			if ( $scope.serviceDatePickerEvents.startDay.value < day.value ) {
				$scope.afterLabelValue = new Date( $scope.serviceDatePickerEvents.startDay.value );
			} else if ( day.value < $scope.serviceDatePickerEvents.startDay.value ) {
				$scope.afterLabelValue = new Date( $scope.serviceDatePickerEvents.startDay.value ) || new Date( day.value );
			}
		}

	} );
	$scope.$watch( 'serviceDatePickerEvents.startDay', function ( day, oldVal ) {
		if ( day ) {
			$scope.selectEvent( day )
		}
	} );
	$scope.input = {};
	$scope.selectEvent = function ( day ) {
		if ( !day || day.blocked ) {
			return
		}
		if ( $scope.exactLabelValue !== undefined ) {
			$scope.exactLabelValue = new Date( day.value );
			if ( link ) {
				$scope.exact = $scope.exactLabelValue;
			}
			return
		}
		if ( $scope.beforeLabelValue && $scope.beforeLabelValue.getTime() < day.value ) {
		} else if ( $scope.afterLabelValue && day.value < $scope.afterLabelValue.getTime() ) {
			$scope.afterLabelValue = new Date( day.value );

			link && ($scope.after = $scope.afterLabelValue );
		} else {
			$scope.afterLabelValue = new Date( day.value );

			if ( link ) {
				$scope.after = $scope.afterLabelValue;
			}
		}
		apply();
	};

	function apply() {
		$scope.$parent.dateFrom = $scope.after = $scope.afterLabelValue;
		$scope.exact = $scope.exactLabelValue;
		$scope.click();
		if($scope.applyAction){
			$scope.applyAction($scope.after)
		}
	};


	$scope.$watch( 'after', function ( val ) {
		if ( val && val != $scope.afterLabelValue ) {
			$scope.afterLabelValue = val;
		}
		render( currentViewDate )
	} );


	$scope.$watch( 'exactLabelValue', function ( val ) {
		if ( val != $scope.exactLabelValue ) {
			$scope.exactLabelValue = val;
		}
		if ( val && angular.isDate( val ) ) {
			$scope.input.exact = $filter( 'formatDateByBrowser' )( val, 'calendarInput' );
		}
		render( currentViewDate )
	} );

	$scope.$watch( 'afterLabelValue', function ( val, oldVal ) {
		if(val){
			dateCustomMethods.saveAfterDateString(val.setHours(0,0,0,0));
			$scope.input.after =  $filter( 'formatDateByBrowser' )( dateCustomMethods.getAfterDateString(), 'calendarInput' );
		}
	} );


	$scope.$watch( 'exact', function ( val ) {
		$scope.exactLabelValue = val;
		render( currentViewDate )
	} );

	$scope.valid = {};
	$scope.setDateValue = function ( param ) {

			var D;

			switch ( param ) {
				case 'exact':
					$scope.exactLabelValue = dateCustomMethods.fromFormattedStringToDateObject($scope.input.exact);
					break;
				case 'after':
					$scope.afterLabelValue = dateCustomMethods.fromFormattedStringToDateObject($scope.input.after);
					break;
			}

		}



	$scope.$watchCollection( '[afterLabelValue, maxDate, minDate]',
		function () {
			render( currentViewDate )
		} );

	function listen( e ) {
		var val = false;
		angular.forEach( $scope.calendarElement.find( '*' ), function ( el ) {
			if ( el == e.target ) {
				val = true;
				return;
			}
		} );

		if ( !val ) {
			angular.forEach( $element.find( '*' ), function ( el ) {
				if ( el == e.target ) {
					val = true;
					return;
				}
			} )
		}
		if ( !val ) {
			($scope.show = false)
			$scope.$apply()
		}
	}

	function cleanup() {
		window.document.removeEventListener( 'click', listen );
		scrollOn();
	}


	function scrollMove(e){
		e.preventDefault();
	}

	function scrollOff(){
		document.addEventListener('mousewheel', scrollMove)
		document.addEventListener('DOMMouseScroll', scrollMove)
	}

	function scrollOn(){
		document.removeEventListener('mousewheel', scrollMove )
		document.removeEventListener('DOMMouseScroll', scrollMove )
	}


	$scope.$watch( 'show', function ( val, newVal ) {
		if ( val ) {
			scrollOff();
			window.document.addEventListener( 'click', listen );

		} else {

			if ( $scope.exact !== undefined && $scope.exact === null ) {
				$scope.input.exact = null
			}else if($scope.exact){
				$scope.exactLabelValue = $scope.exact;
				$scope.input.exact = $filter( 'formatDateByBrowser' )( $scope.exactLabelValue, 'calendarInput' );
			}


			$scope.afterLabelValue = $scope.after;

			if($scope.afterLabelValue){
				$scope.input.after = $filter( 'formatDateByBrowser' )( $scope.afterLabelValue, 'calendarInput' );
			}

			scrollOn();
			window.document.removeEventListener( 'click', listen );

		}
	} );
	$scope.$on( '$destroy', function () {
		$scope.show = false;
		$scope.calendarElement && $scope.calendarElement[0].parentNode.removeChild( $scope.calendarElement[0] );
		cleanup();
	} );

}

export default datePickerContrl;