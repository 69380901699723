/**
 * Константа хранит известные коды ошибок сервера для биллинга
 * Created by Shestakov on 6.06.20
 */

const constBillingKnownErrors = [
    "try_later",
    "activation_error",
    "payment_processing_failed",
    "payment_method_verification_failed",
    "payment_method_not_present",
    "temporary_unavailable",
    "invalid_request_coupon",
    "transaction_failed",
    "card_declined",
    "vat_invalid",
    "attempt_limit_exceeded"
];

export default {
    type: 'constant',
    name: 'constBillingKnownErrors',
    value: constBillingKnownErrors
};