/**
 * Created by Nikitin Oleksii
 *
 * @ngdoc directive
 * @name app.directive:mdVirtualRepeatContainerApi
 * @restrict A
 *
 * @description
 *
 * Directive for publish to scope directive controller.
 *
 *@example
 *
 * <md-virtual-repeat-container md-virtual-repeat-container-api="api"></md-virtual-repeat-container>
 *
 */

createDirective.$inject = ['directiveApi'];

function createDirective(directiveApi) {
    return directiveApi('mdVirtualRepeatContainer')
}

export default {
    type: 'directive',
    name: 'mdVirtualRepeatContainerApi',
    value: createDirective
};