/**
 * Created by sirosh on 27.02.16.
 */

employeePermitsListCtrl.$inject = [
    "$scope",
    "$filter",
    "$state",
    "$stateParams",
    "serviceDialog",
    '$permitsList',
    'getPermits',
    "serviceToast",
    '$profile',
    'isBannerDisplayed'
];

function employeePermitsListCtrl(
          $scope,
          $filter,
          $state,
          $stateParams,
          serviceDialog,
          $permitsList,
          getPermits,
          serviceToast,
          $profile,
          isBannerDisplayed) {

    var translate = $filter('translate');
    $scope.editTableState = 'employees.editpermits';
    $scope.roles = $permitsList.roles;
    $scope.pages = $permitsList.pages;
    $scope.delete = false;
    $scope.employeesSelect = [];
    $scope.allEmployees = {};

    $scope.user = {
        page: $stateParams.page + 1
    };

    // employee management promo banner data
    $scope.bannerOptions = {
        lang: $profile.cabinetLang,
        show: isBannerDisplayed
    }

    for (var i = 0; i < $scope.roles.length; i++) {
        $scope.roles[i]["_select"] = false;
    }



    // данные для сохранение состояния фильтров и ЧЕКБОКСОВ
    $scope.stateFromList = $state.params;


    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ГРУППЫ ПРАВ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    if ($scope.roles.length == 0 && $scope.stateFromList.page > 0) {
        $state.go( 'employees.permitslist', {page: $scope.pages - 1} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ ГРУППЫ ПРАВ ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ

    $scope.changeItem = function () {

        for (var j = 0; j < $scope.roles.length; j++) {
            if ($scope.roles[j]._select === true) {
                $scope.employeesSelect.push($scope.roles[j]._select);
            }
        }

        $scope.delete = ($scope.employeesSelect.length > 0);

        $scope.allEmployees._select = ($scope.employeesSelect.length === $scope.roles.length - 1) && ($scope.roles.length > 1);

        $scope.employeesSelect = [];
    };


    $scope.changeAll = function () {
        if ($scope.allEmployees._select === true) {
            for (var k = 0; k < $scope.roles.length; k++) {
                if ($scope.roles[k]["type"] !== 'OWNER') {
                    $scope.roles[k]["_select"] = true;
                }
            }
            $scope.allEmployees._select = true;

        } else {
            $scope.allEmployees._select = false;
            for (var m = 0; m < $scope.roles.length; m++) {
                $scope.roles[m]["_select"] = false;
            }
        }

        for (var j = 0; j < $scope.roles.length; j++) {
            if ($scope.roles[j]._select === true) {
                $scope.employeesSelect.push($scope.roles[j]._select);
            }
        }

        $scope.delete = ($scope.employeesSelect.length > 0);

        $scope.employeesSelect = [];
    };

    $scope.onAdd = function () {
        $state.go('employees.createpermits', {stateToSave: $scope.stateFromList});
    };


    $scope.onDel = function () {
        var rolesIds = [];
        var employeesCount = 0;

        for (var i = 0; i < $scope.roles.length; i++) {
            if ($scope.roles[i]._select === true) {
                rolesIds.push($scope.roles[i].id);
                employeesCount += $scope.roles[i].merchantsCount;
            }
        }

        function sendData() {
            getPermits.getPermitsDelete({ids: rolesIds}).then(function (res) {
                if (res.result === "ok") {
                    serviceToast.show($scope.tostTitle, 'success');
                    $state.go('employees.permitslist', {page:           $scope.stateFromList.page,
                                                        limit:          $scope.stateFromList.limit}, {
                        reload: true
                    });
                }
            });
        }

        if (employeesCount > 0) {
            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: translate('PERMITS.CANNOT_ROLE_DELETE_TITLE'),
                content: translate('PERMITS.CANNOT_ROLE_DELETE_TEXT'),
                buttons: [
                    {
                        text: 'ON_OK'
                    }
                ]
            });
        } else {
            if (rolesIds.length === 1) {
                $scope.msgTitle = translate('PERMITS.DELETED_GROUP_TITLE');
                $scope.tostTitle = 'PERMITS.DELETED_GROUP';
                $scope.content = translate('PERMITS.YOU_WANT_DEL_GROUP');
            } else {
                $scope.msgTitle = translate('PERMITS.DELETED_GROUPS_TITLE');
                $scope.tostTitle = 'PERMITS.DELETED_GROUPS';
                $scope.content = translate('PERMITS.YOU_WANT_DEL_GROUPS');
            }


            serviceDialog.add({
                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                title: $scope.msgTitle,
                content: $scope.content,
                buttons: [
                    {
                        text: 'ON_CANCEL'
                    }, {
                        class: 'primary l-margin-left-8-i',
                        text: 'ON_DEL',
                        action: sendData
                    }
                ]
            });
        }


    };


}

export default {
    type: 'controller',
    name: 'employeePermitsListCtrl',
    value: employeePermitsListCtrl
};
