createDirective.$inject = ['InputContainerStateManager', '$timeout'];

function createDirective(InputContainerStateManager, $timeout) {
    let resultDirective = {
        restrict: 'E',
        require: ['ngModel', '?lvInputContainerAdapter', '?^lvInputContainer'],
        link: linkDirective
    };

    function linkDirective(scope, element, attrs, ctrls) {

        const [ngModel, inputContainerAdapter, containerCtrl] = ctrls;
        let inputElement;

        const inputContainerStateManager = new InputContainerStateManager(
            element, attrs, ngModel, inputContainerAdapter, true);

        $timeout(() => {
            inputElement = element.find('INPUT');
            inputElement.on('focus', onFocus);
            inputElement.on('blur', onBlur);

            if (containerCtrl) {
                const model = inputElement.controller('ngModel');
                model.$viewChangeListeners.push(() => {
                    containerCtrl.toggleContainerClass('is-shift-label', !model.$isEmpty(model.$viewValue));
                });
            }

        }, 500)

        scope.$on("$destroy", () => {
            inputElement.off('focus', onFocus);
            inputElement.off('blur', onBlur);
        });

        function onFocus() {
            inputContainerStateManager.setFocused(true);
        }

        function onBlur() {
            inputContainerStateManager.setFocused(false);
        }
    }

    return resultDirective;
}

export default {
    type: 'directive',
    name: 'mdChips',
    value: createDirective
};
