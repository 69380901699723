/**
 * Created by sirosh on 09.02.16.
 */

inputFocusNext.$inject = [];
function inputFocusNext() {
    return {
        restrict: 'A',
        link: function(scope, element, attr) {

            $(element).keyup(function(event){
                // if(event.keyCode === 8 || event.keyCode === 67 || event.keyCode === 229){
                if(event.keyCode === 8) {
                    if ($(element).val().length === 0) {
                        $(element).prev().focus();
                    } else{
                        $(element).val($(element).val().slice(0, 1));
                    }
                }
                else if($(element).val().length > attr.maxlength){
                    $(element).val($(element).val().slice(1, attr.maxlength));
                }
                else if(event.keyCode === 39){
                        $(element).next().focus();
                }else if(event.keyCode === 37){
                        $(element).prev().focus();
                } else{
                    if ($(element).val().length == attr.maxlength) {
                        $(element).next().focus();
                    } else if($(element).val().length === 0){
                        $(element).prev().focus();
                    }
                }
            });
        }
    };
}

export default {
    type: 'directive',
    name: 'inputFocusNext',
    value: inputFocusNext
};