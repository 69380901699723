import angular from 'angular';

stockAdjustment.$inject = [
    '$shortOutlets',
    '$stateParams',
    '$scope',
    '$statusConst',
    '$critCountOptionsEdit',
    '$state',
    '$adjustmentsList',
    'siteLangCode',
    '$profile',
    '$countOptionsShow',
    '$translate',
    'lvMixpanel'
];

function stockAdjustment(
    $shortOutlets,
    $stateParams,
    $scope,
    $statusConst,
    $critCountOptionsEdit,
    $state,
    $adjustmentsList,
    siteLangCode,
    $profile,
    $countOptionsShow,
    $translate,
    lvMixpanel
    ) {

    var vm = this;
    $scope.shortOutlets = $shortOutlets.slice();
    $scope.shortOutlets.unshift({
        id: null,
        name: $translate.instant('MULTISHOP.CASH_REGISTER.OLL_OUTLETS')
    });
    $scope.selectOutlet = {id: $stateParams.outletId ? +$stateParams.outletId : null};
    $scope.selectOutletId = $scope.selectOutlet.id;

    $scope.selectReason = {code: $stateParams.reason || null};
    $scope.constReason = $statusConst.reason.map(val => {
        return {
            code: val.code,
            name: $translate.instant('INVENTORY.' + val.code)
        };
    });
    $scope.constReason.unshift({
        code: null,
        name: $translate.instant('INVENTORY.ALL_REASONS')
    });
    $scope.selectStatusesCode = $scope.selectReason.code;

    // $scope.constStatuses = angular.copy($statusConst.reason);

    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    $scope.adjustList = angular.copy($adjustmentsList);
    // if need to check empty purchaseList
    // $scope.adjustList.orders = [];

    $scope.editTableState = 'inventory.adjustdetails';
    $scope.countOptionsShow = $countOptionsShow;

    //Task # 19957 Change stubs on Advanced inventory screens
    vm.plugsFilters = !!(!$scope.selectReason.code && !$scope.selectOutlet.id);
    vm.plugsSearch = !!$stateParams.search;
    //Plugs for extended inventory accounting Stock Adjustments
    $scope.profile = angular.copy( $profile );
    var siteUrlLang = siteLangCode[$scope.profile.cabinetLang];
    var plugStockAdjustments = ["ru", "es", "jp", "ko", "idn", "de", "ro", "th", "vn", "fr", "ar", "br", "pl", "it", "gr", "zh"];
    $scope.plugStockAdjustmentsHelpLink = plugStockAdjustments.indexOf(siteUrlLang) !== -1
        ? "https://help.loyverse.com/" + siteUrlLang + "/help/how-work-stock-adjustments?utm_source=Back%20Office&utm_medium=Stock%20adjustments"
        : "https://help.loyverse.com/help/how-work-stock-adjustments?utm_source=Back%20Office&utm_medium=Stock%20adjustments";

    $scope.trackHelpAdjustmentOrders = function() {
        lvMixpanel.track('Help', {"Property": "Stock adjustments"});
    };

    // данные для сохранение состояния фильтров
    $scope.stateFromList = $state.params;


    $scope.$watch('selectOutlet', function(newval, oldval){
        if (oldval != newval) {
            // console.log("$scope.selectOutlet.id = ", $scope.selectOutlet.id);
            $state.go('inventory.adjustment', {outletId: newval.id || null, page:0}).then(function() {
                $scope.selectOutletId = newval.id;
            });
        }
    }, true);

    $scope.$watch('selectReason', function(newval, oldval){
        if (oldval != newval) {
            $state.go($state.current.name, {reason: newval.code, page:0});
        }
    }, true);

    $scope.onAdd = function() {
        $state.go('inventory.createadjustment', {stateToSave: $scope.stateFromList});
    }

    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
    $scope.pages = $scope.adjustList.pages;
    if ($scope.adjustList.orders.length == 0 && $scope.stateFromList.page > 0) {
        $state.go( 'inventory.adjustment', {page:        $scope.pages - 1,
                                            outletId:    $scope.stateFromList.outletId,
                                            reason:      $scope.stateFromList.reason} );
    }
    // ЕСЛИ ВДРУГ НА СТРАНИЦЕ НЕТ заказов ТО ПЕРЕХОДИМ НА ПОСЛЕДНЮЮ СТРАНИЦУ ГДЕ ОНИ ЕСТЬ
}

export default {
    type: 'controller',
    name: 'stockAdjustment',
    value: stockAdjustment
};
