/**
 * Created by mars on 6/15/16.
 * @memberof equallyDist
 * @name app.directive: equallyDist
 * @ngdoc directive,
 * @restrict 'A'
 *
 <example module="app">
	 <div equally-dist='colors'>
		 <div ng-repeat='color in colors' equally-dist-child='$index' >

		 </div>
	 </div>
 </example>
 */

equallyDist.$inject = [];
function equallyDist() {
	return {
		restrict: 'A',
		controller: equallyDistController,
		link: function ( scope, el, attr, contrl ) {
			contrl.setEl( el );
			contrl.setEquallyDist( scope[attr.equallyDist] );
		}
	};
}

equallyDistController.$inject = ['$scope'];
function equallyDistController($scope) {
	const arrFoo = [];

	this.el = null;
	this.equallyDist = null;
	this.setEl = function ( el ) {
		this.el = el;
	};
	this.setEquallyDist = function ( equallyDist ) {
		this.equallyDist = equallyDist;
	};
	this.addSetMargin = function(foo){
		arrFoo.push(foo);
	};
	const resizeFoo = () => {
		let i = 0;
		while (i < arrFoo.length){
			arrFoo[i]();
			i++;
		}
	}
	window.addEventListener( 'resize', resizeFoo, false );
	$scope.$on( '$destroy', function () {
		window.removeEventListener( 'resize', resizeFoo );
	} );
}

equallyDistChild.$inject = [];
function equallyDistChild() {
	return {
		restrict: 'A',
		require: '^equallyDist',
		link: function ( scope, el, attr, cntrl ) {

			cntrl.addSetMargin(setMargin);
			function setMargin() {
				const elWidth = el[0].offsetWidth;

				el[0].style.height = elWidth + 'px';
			}
			setMargin();
		}
	};
}

export default [
	{
		type: 'directive',
		name: 'equallyDist',
		value: equallyDist
	},
	{
		type: 'directive',
		name: 'equallyDistChild',
		value: equallyDistChild
	}
];
