/**
 * Created by mars on 12/15/15.
 */

import angular from 'angular';
import factoryValidationFormat from "../../../common/webpack/lv-validation/factory/factory-validation-format";

createEditCategoryControl.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'factoryCategories',
	'constantColorsGoodsCollection',
	'$timeout',
	'serviceToast',
	'serviceDialog',
	'$filter',
	'$rootScope',
	'$category',
	'lodash',
	'$countOptionsShow',
	'dialogs',
	'lvMixpanel'
];

function createEditCategoryControl(
	$scope,
	$state,
	$stateParams,
	factoryCategories,
	constantColorsGoodsCollection,
	$timeout,
	serviceToast,
	serviceDialog,
	$filter,
	$rootScope,
	$category,
	lodash,
	$countOptionsShow,
	dialogs,
	lvMixpanel) {

	$scope.showDel = $state.current.name === 'goods.categoryedit';
	$scope.title = ($state.current.name === 'goods.categoryedit') ? 'EDIT_CATEGORY' : 'CREATE_CATEGORY';
	const translate = $filter( 'translate' );
	let category;

	const isChanged = () => !angular.equals($scope.category, category);
	const offStateChangeStart = dialogs.initUnsaveAlertDialog(isChanged, $scope);

	$scope.colorsGoods = angular.copy( constantColorsGoodsCollection );

	/*** формат отображения колличества ***/
	$scope.countOptionsShow = $countOptionsShow;


	$scope.category = $category;
	if (!$scope.category.colorName) {
		$scope.category.colorName = 'GREY';
	}

	$scope.uniqueName = true;
	$scope.progress = false;

	_init();


	function _init() {
		$timeout( function () {
			category = angular.copy( $scope.category )
		} )
	}

	$scope.setColorByValue = value => {
		$scope.category.colorName = value;
	}


	// data to save the state of the filters
	$scope.stateFromList = $stateParams.stateToSave;
	$scope.checkFromList = $stateParams.checkToSave;
	// END data to save the state of the filters

	$scope.onSaveCategory = () => {

		if ($scope.categoryForm.$invalid) {
			serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			$scope.categoryForm.focusFirstInvalidControl();
			return false;
		}

		if ( $state.current.name === 'goods.categoryedit' ) {
			sendEdit();
		} else {
			sendNew();
		}
	};


	$scope.onCancelCategory = function () {
		backToList();
	};

	$scope.onDeleteCategory = function () {

		serviceDialog.add( {
			templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
			title: translate( 'CONFIRM_OPERATION' ),
			content: translate( 'CONFIRM_DEL_CATEGORY' ),
			buttons: [
				{
					class: 'md-flat',
					text: 'ON_CANCEL'
				},
				{
					class: 'md-flat md-primary l-margin-left-8-i',
					text: 'ON_DEL',
					action: function () {
						factoryCategories
							.delCategory( $stateParams.id )
							.then( function ( res ) {
									offStateChangeStart();
									serviceToast.show( 'CATEGORY_DELETED_SUCCESS', 'success' );
									backToList();
								}, function ( err ) {
									switch ( err.result ) {
										case  'category_deleted':
											offStateChangeStart();
											dialogs.itemDeletedEarlier().then(backToList);
											break;
										case 'empty_del':
											console.log( 'empty_del' );
											break;
										default :
											serviceToast.show( 'ERROR_DEL_WARE', 'error' );
											console.log( err );
									}
								}
							);
					}
				}
			]
		} );
	};

	function backToList() {
		if (lodash.isEmpty($scope.stateFromList)) {
			return $state.go( 'goods.categories', {
				page: 			0,
				limit: 			10,
				checkFromList:	$scope.checkFromList} );
		} else {
			return $state.go( 'goods.categories', {
				page: 			$scope.stateFromList.page,
				limit: 			$scope.stateFromList.limit,
				checkFromList:	$scope.checkFromList} );
		}
	}

	function sendEdit() {
		$scope.progress = true;
		factoryCategories.editCategory( $scope.category ).then( function ( res ) {
			if ( res.result === "ok" && !res.message ) {
				lvMixpanel.track('Category created/edited', {
					"Color": getColor($scope.category.colorName),
					"Event type": 'Category edited'
				});
				category = angular.copy( $scope.category );
				serviceToast.show( 'CATEGORY_EDIT_SUCCESS', 'success' );
				backToList().finally(() => {
					$scope.progress = false;
				});
			} else if(res.result === "error"){
				if (res.message === 'category_name_exists') {
					$scope.progress = false;
					$scope.uniqueName = false;
					serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
				} else if (res.message === 'category_deleted') {
					offStateChangeStart();
					dialogs.itemDeletedEarlier()
						.then(() => backToList())
						.finally(() => {
							$scope.progress = false;
						});
				} else {
					$scope.progress = false;
				}
			}
		}, function () {
			$scope.progress = false;
		});
	}

	function sendNew() {
		$scope.progress = true;
		factoryCategories.createCategory( $scope.category ).then( function ( res ) {
			if ( res.result === "ok" && !res.error ) {
				lvMixpanel.track('Category created/edited', {
					"Color": getColor($scope.category.colorName),
					"Event type": 'Category created'
				});
				category = angular.copy( $scope.category );
				serviceToast.show( 'CATEGORY_CREATE_SUCCESS', 'success' );
				backToList().finally(() => {
					$scope.progress = false;
				});
			} else if(res.result === "error" && res.message === 'category_name_exists'){
				$scope.progress = false;
				$scope.uniqueName = false;
				serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
			} else {
				$scope.progress = false;
			}
		}, function () {
			$scope.progress = false;
		});
	}

	const getColor = colorName => colorName ? colorName.charAt(0).toUpperCase() + colorName.slice(1).toLowerCase() : 'Grey';

}

export default {
	type: 'controller',
	name: 'createEditCategoryControl',
	value: createEditCategoryControl
};
