serviceFile.$inject = [];

/**
 * Service containing functions working with files
 */
function serviceFile() {

  /**
   * Function saves file programmatically, imitating click to link
   * @param contentType - content-type header
   * @param contentDisposition - content-disposition header
   * @param fileData - file data
   */
  this.saveFile = (contentType, contentDisposition, fileData) => {
    const match = contentDisposition.match(/filename="(?<fileName>.+)"/);
    const filename = match?.groups.fileName ?? ""

    const blob = new Blob([fileData], {type: contentType});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}

export default {
  type: 'service',
  name: 'serviceFile',
  value: serviceFile
}
