createDirective.$inject = ['directiveApi'];

function createDirective(directiveApi) {
    return directiveApi('ngModel')
}

export default {
    type: 'directive',
    name: 'ngModelApi',
    value: createDirective
};