import angular from 'angular';

createAdjustment.$inject = [
    '$shortOutlets',
    '$scope',
    '$statusConst',
    '$timeout',
    '$q',
    'factoryAdjustment',
    '$currencyOptionsEdit',
    '$quantOptionsEdit',
    '$critCountOptionsEdit',
    'serviceDialog',
    '$filter',
    'lodash',
    '$stateParams',
    '$state',
    '$countOptionsEdit',
    '$countOptionsEditDivisible',
    'serviceToast',
    '$rootScope',
    '$countOptionsShow',
    '$countQuantityForOrder',
    '$countQuantityForOrderDivisible',
    '$permitsManager'
];

function createAdjustment(
        $shortOutlets,
        $scope,
        $statusConst,
        $timeout,
        $q,
        factoryAdjustment,
        $currencyOptionsEdit,
        $quantOptionsEdit,
        $critCountOptionsEdit,
        serviceDialog,
        $filter,
        lodash,
        $stateParams,
        $state,
        $countOptionsEdit,
        $countOptionsEditDivisible,
        serviceToast,
        $rootScope,
        $countOptionsShow,
        $countQuantityForOrder,
        $countQuantityForOrderDivisible,
        $permitsManager
    ) {
    let vm = this;
    let translate = $filter('translate');
    let searchDefer;
    let offStateChangeStart;
    let enterKeydown = false;
    let searchInput;
    let itemsFound = null;
    let wareCount = $filter('wareCount');

    $scope.displayCost = $permitsManager.costAllowed;
    $scope.currencyOptionsEdit = $currencyOptionsEdit;
    $scope.quantOptionsEdit = $quantOptionsEdit;
    $scope.critCountOptionsEdit = $critCountOptionsEdit;
    $scope.countOptionsEdit = $countOptionsEdit;
    $scope.countOptionsEditDivisible = $countOptionsEditDivisible;
    $scope.countOptionsShow = $countOptionsShow;
    $scope.countQuantityForOrder = $countQuantityForOrder;
    $scope.countQuantityForOrderDivisible = $countQuantityForOrderDivisible;
    $scope.countQuantityForOrderRecount = angular.copy($countQuantityForOrder);
    $scope.countQuantityForOrderRecountDivisible = angular.copy($countQuantityForOrderDivisible);
    $scope.countQuantityForOrderRecount.maximumValue = 9999999.999;
    $scope.countQuantityForOrderRecountDivisible.maximumValue = 9999999.999;
    $scope.outlets = angular.copy($shortOutlets);
    $scope.isSaving = false;

    $scope.reasons = angular.copy($statusConst.reason);

    // данные для сохранение состояния фильтров
    $scope.stateFromList = $stateParams.stateToSave;

    vm.items = [];
    vm.comment = '';
    $scope.showCost = true;
    $scope.changingStock = 'INVENTORY.ADD_STOCK';
    $scope.inStock = 'INVENTORY.ITEMS_TABLE.STOCK';
    $scope.after = true;
    vm.actualOutlet = null;
    if ($scope.outlets.length === 1) {
        vm.actualOutlet = $scope.outlets[0].id;
    }
    vm.selectedReason = "RECEIVE_ITEMS";

    vm.start = {
        reason: "RECEIVE_ITEMS",
        outletId: null,
        comment: '',
        items: []
    };

    vm.amountAfterCount = function(item) {
        if (item.quantity === null || item.quantity === undefined)
            return item.stock;
        return vm.amountAfter(parseFloat((item.stock*1000).toFixed(3)), parseFloat(wareCount(item.quantity, $scope.countQuantityForOrder.decimalCharacter, true)));
    };

    vm.amountAfter = function (stock, quantity) {
        if (stock || quantity) {
            let after;
            if (vm.selectedReason === "RECEIVE_ITEMS") {
                if (quantity === null || quantity === undefined){
                    after = (stock + 0);
                    return parseFloat(wareCount(after, ".", false));
                } else {
                    after = (stock + quantity);
                    if (after <= 9999999999) {
                        return parseFloat(wareCount(after, ".", false));
                    } else {
                        return parseFloat(wareCount(9999999999, ".", false));
                    }
                }
            } else {
                after = (stock - quantity);
                if (after >= -9999999999) {
                    return parseFloat(wareCount(after, ".", false));
                } else {
                    return parseFloat(wareCount(-9999999999, ".", false));
                }
            }
        } else {
            return parseFloat(wareCount(0, ".", false));
        }
    };


    $scope.changeItemTable = function(changeStockReason) {
        switch (changeStockReason) {
            case "RECEIVE_ITEMS":
                $scope.changingStock = 'INVENTORY.ADD_STOCK';
                $scope.inStock = 'INVENTORY.ITEMS_TABLE.STOCK';
                $scope.showCost = true;
                $scope.after = true;
                break;
            case "RE_COUNT":
                $scope.changingStock = 'INVENTORY.COUNTED_STOCK';
                $scope.inStock = 'INVENTORY.EXPECTED_STOCK';
                $scope.showCost = false;
                $scope.after = false;
                break;
            default:
                $scope.changingStock = 'INVENTORY.REMOVE_STOCK';
                $scope.inStock = 'INVENTORY.ITEMS_TABLE.STOCK';
                $scope.showCost = false;
                $scope.after = true;
        }

        const notChangingReasons = ['LOSS', 'DAMAGED'];
        if (vm.items.length > 0 && !(notChangingReasons.includes(changeStockReason) && notChangingReasons.includes(vm.selectedReason))) {
            vm.items.forEach(function (item) {
                item.quantity = 0;
                item.amountAfter = parseFloat(vm.amountAfter(item.inStock, null)) || 0;
            });
        }

        vm.selectedReason = changeStockReason;
    };

    vm.autocompleteItems = {
        searchText: null,
        selectedItem: null,
        getMatches: function ( query ) {
            searchDefer = $q.defer();
            factoryAdjustment
                .searchItemsForAdjustmentOrder( {
                    search: query,
                    outletId: vm.actualOutlet || null
                })
                .then(function (items) {
                    searchDefer.resolve(items);
                    itemsFound = angular.copy(items);
                })
                .then(function () {
                    if(enterKeydown) {
                        if(Array.isArray(itemsFound) && itemsFound.length === 1 && itemsFound[0].barcode === vm.autocompleteItems.searchText) {
                            vm.autocompleteItems.selectedItemChange(itemsFound[0]);
                            searchInput.focus();
                            enterKeydown = false;
                        }
                    }
                });
            return searchDefer.promise;
        },
        selectedItemChange: function(item) {
            if(item) {
                let itemsLength = vm.items.length;
                if(itemsLength !== 0) {
                    for (let i = 0; i < itemsLength; i++) {
                        if (item.id === vm.items[i].wareId) {
                            serviceDialog.add({
                                templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                                content: translate('INVENTORY.ADDING_SAME_ADJUST_MESSAGE'),
                                buttons: [
                                    {
                                        text: 'ON_OK',
                                        action: function () {
                                        return true;
                                        }
                                    }
                                ]
                            });
                            break;
                        } else if (i === itemsLength - 1) {
                            pushItemToArray(item);
                        }
                    }
                } else {
                    pushItemToArray(item);
                }
                $('[name="autocompleteItems"]').blur();
                this.reset();
            }
        },
        reset: function () {
            this.selectedItem = null;
            this.searchText = null;
        }
    };
    function pushItemToArray(item) {
        let itemObj = {
            wareId: item.id,
            name: item.name,
            sku: item.sku,
            stock: parseFloat(wareCount(item.inStock, ".", false)) || 0,
            divisible: item.divisible,
            keepCount: item.keepCount,
            quantity: null,
            cost: parseFloat($filter('coinToCurrency')(item.primeCost, ".")),
            amountAfter: parseFloat(vm.amountAfter(item.inStock, null)) || 0
        };
        vm.items.push(itemObj);
    }
    $scope.onCancel = function() {
        offStateChangeStart();
        if (lodash.isEmpty($scope.stateFromList)) {
            $state.go( 'inventory.adjustment', {page:           0,
                                                limit:          10} );
        } else {
            $state.go( 'inventory.adjustment', {page:           $scope.stateFromList.page,
                                                limit:          $scope.stateFromList.limit,
                                                outletId:       $scope.stateFromList.outletId,
                                                reason:         $scope.stateFromList.reason} );
        }
    };
    $scope.deleteItem = function (item) {
        angular.forEach(vm.items, function ( val, i ) {
            if (item == val) {
                vm.items.splice(i, 1);
                return;
            }
        });
    };

    function getItemsIds() {
        return vm.items.map(
            function (item) {
                return item.wareId;
            }
        );
    }

    $scope.changeOutlet = function(actualOutlet){
        if(vm.items.length > 0) {
            let outletsData = {
                outletId: actualOutlet,
                items: getItemsIds()
            };
            factoryAdjustment.getAdjustmentOrderItemsForOutlet(outletsData)
                .then(function(data) {
                    data.items.forEach(
                        function(item, i, data) {
                            vm.items.forEach(
                                function (product, j, arr) {
                                    if(item.id === product.wareId) {
                                        product.stock = parseFloat(wareCount(item.inStock, ".", false));
                                        product.amountAfter = parseFloat(vm.amountAfter(item.inStock, product.quantity*1000));
                                    }
                                }
                            )
                        }
                    );
                })
        }
    };

    function itemsForSend() {
        for (let i = 0; i < vm.items.length; i++) {
            if(vm.items[i].quantity === undefined)
                return false;
            vm.toSend.items[i] = {
                wareId: vm.items[i].wareId,
                quantity: wareCount(vm.items[i].quantity, ".", true),
                cost: (vm.items[i].cost) ? $filter( 'currencyToCoin' )(vm.items[i].cost, ".") : null
            }
        }
        return true;
    }

    function checkEmptyNumberInput(item) {
        return item.quantity !== null
            && item.quantity !== undefined
            && item.cost !== undefined
            && item.cost !== null;
    }

    function checkBeforeSave() {
        vm.errItemsNo = vm.items.every(checkEmptyNumberInput);

        if(($scope.outlets.length > 1 && vm.actualOutlet === null)
            || (vm.items.length < 1)
            || !vm.errItemsNo
            || vm.comment.length > 500) {

            serviceToast.show('INVENTORY.CREATE_ORDER_INVALID', 'error');
            return false;
        }
        return true;
    }

    /* Preventing form from being sent by Enter button */

    $('form').keydown(function(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    $scope.onAdjust = function(editProfile){
        //permission to edit profile, false for manager
        if (!editProfile) {
            serviceToast.show( 'U_DONT_HAVE_PERMITS_EDIT_PROFILE', 'error' );
            return;
        }

        vm.toSend = {
            reason:vm.selectedReason,
            outletId: vm.actualOutlet,
            comment: vm.comment,
            items: []
        }

        if ($scope.outlets.length === 1) {
            vm.toSend.outletId = $scope.outlets[0].id;
        }

        itemsForSend();

        if(!itemsForSend())
            return;

        if(checkBeforeSave()){
            $scope.isSaving = true;
            factoryAdjustment.createAdjustmentOrder(vm.toSend).then(function(data){
                 if (data.result === 'ok') {
                    offStateChangeStart();
                    $state.go('inventory.adjustdetails', {
                        id: data.newId
                    })
                } else {
                    serviceToast.show('INVENTORY.CREATE_ORDER_ERROR', 'error');
                }
            })
        }
    }

    offStateChangeStart = $rootScope.$on( '$stateChangeStart', function ( event, _toState, _toParam, fromSate, fromParam ) {
        vm.toSend = {
            reason:vm.selectedReason,
            outletId: vm.actualOutlet,
            comment: vm.comment,
            items: []
        }
        itemsForSend();

        if ( !angular.equals( vm.start, vm.toSend ) ) {
            event.preventDefault();
            $timeout( function () {
                serviceDialog.add( {
                    templateUrl: 'dashboard-page/templates/dialog/dialog-base.html',
                    title: translate( 'UNSAVED_TITLE' ),
                    content: translate( 'UNSAVED_LEAVE' ),
                    buttons: [
                        {
                            text: 'UNSAVED_CONTINUE',
                            action: function () {

                            }
                        },
                        {
                            class: 'primary',
                            text: 'UNSAVED_DISCARD',
                            action: function () {
                                offStateChangeStart();
                                $state.go( _toState, _toParam );
                            }
                        }
                    ]
                } );
            } );
        } else {
            offStateChangeStart();
        }

    } );

    vm.addByBarcode = function ($event) {
        if($event.keyCode === 13) {
            enterKeydown = true;
            searchInput = $event.target;
            angular.element(searchInput).focus();
        }
    }
}

export default {
    type: 'controller',
    name: 'createAdjustment',
    value: createAdjustment
};
