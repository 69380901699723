/**
 * Created by Alex on 8.11.2016.
 */

function taxPercentFormatShow() {
	/**
	 * Filter tax percent format
	 * @param val
	 * @returns {String}
	 */

	function taxFormat(val) {
		// console.log("val = ", val);

		if(val !== undefined && val !== null){

			val = val/1000;
			// console.log("val = ", val);
			// console.log("typeof = ", typeof(val));

			val = parseFloat(val.toFixed(5));
			// console.log("val = ", val);
			// console.log("typeof = ", typeof(val));


			return val;
		}

	}

	return taxFormat;
}

export default {
	type: 'filter',
	name: 'taxPercentFormatShow',
	value: taxPercentFormatShow
};