import template from "../templates-pug/for-directive/limited-sales-history.pug";
import angular from "angular";

limitedSalesHistory.$inject = ['$filter',
                               'strGwConst',
                               'serviceDialog',
                               'lodash',
                               'serviceBillingCommonMethods',
                               'serviceBillingDifferentPlaces',
                               'factoryBilling',
                               'serviceToast',
                               '$timeout',
                               'serviceBillingError',
                               'serviceBillingSalesHistory'];
function limitedSalesHistory($filter,
                             strGwConst,
                             serviceDialog,
                             lodash,
                             serviceBillingCommonMethods,
                             serviceBillingDifferentPlaces,
                             factoryBilling,
                             serviceToast,
                             $timeout,
                             serviceBillingError,
                             serviceBillingSalesHistory)
{
    return {
        restrict: 'E',
        template: template,
        transclude: true,
        scope: {
            billingData: '<',
            hasBillingAccess: '<',
            borderTop: '@',
            borderBottom: '@'
        },
        link: link
    };

    function link(scope) {
        let billingData = angular.copy(scope.billingData);
        let errorChargebee = !billingData || billingData.message === "temporary_unavailable";

        if (!errorChargebee) {
            let subscriptionStatus = billingData.subscriptions.SALESHISTORY.status;
            scope.beforeTrial = subscriptionStatus === 'NONE';
        }
        const emptyPlan= {id:null, cycle:null, unlim:null, price:null};

        (!errorChargebee && billingData.subscriptions.SALESHISTORY && billingData.subscriptions.SALESHISTORY.plan === null) ? billingData.subscriptions.SALESHISTORY.plan = angular.copy(emptyPlan) : "";

        serviceBillingSalesHistory.setBillingData(billingData);

        scope.subscribeSalesHistory = serviceBillingSalesHistory.subscribeSalesHistory

        scope.checkIfPlanAllowed = serviceBillingSalesHistory.checkIfPlanAllowed;
    }
}

export default {
    type: 'directive',
    name: 'limitedSalesHistory',
    value: limitedSalesHistory
};
