/**
 * Created by A.Sirosh on 15.12.2015.
 * @ngdoc factory
 * @name app.factory: goodsCategories
 * @returns {{getCategoriesList: getCategoriesList}}
 * @describe
 * Returns list of goods categories
 */

import angular from 'angular';

goodsCategories.$inject = ['promiseOperation'];

function goodsCategories(promiseOperation) {

    return {
        getCategoriesList: getCategoriesList
    };

    /**
     * @ngdoc function
     * @param {Object} params
     * @returns {Promise}
     */
    function getCategoriesList(params) {

        return promiseOperation.getPromiseHTTP(
            'POST',
            '/data/ownercab/getwarecategories',
            params,
            success
        );
    }

    /**
     * @ngdoc function
     * @param response
     * @return {Object}
     */
    function success(response) {

        if (angular.isObject(response.data)) {

            if (response.data.result === "ok") {
                return response.data;
            }
        }
    }


}

export default {
    type: 'factory',
    name: 'goodsCategories',
    value: goodsCategories
};